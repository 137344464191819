import { rootActions } from '@dominos/business'
import { noop, useBreakpoints, useFetchProducts, useKiosk, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, useParams } from '@reach/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { extractProductCodesFromOffer, getBasketLinesToAdd, getCouponsToAdd } from './utils'

const isAutoPopupOffer = (offer: Bff.Offers.Offer, locationCode: string) => {
  const location = offer.locations.find((l) => l.locationCode.toLowerCase() === locationCode.toLowerCase())

  return location?.autoPopUp
}

export const usePopup = (offer?: Bff.Offers.Offer) => {
  const { products, fetchProducts } = useFetchProducts(extractProductCodesFromOffer(offer))
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const { reportVoucherUsage } = useReport()

  const [offerItems, acceptOfferItems] = useState<Bff.Offers.Item[]>()
  const [isShowPopup, setShowPopup] = useState<boolean>(false)

  const params = useParams()
  const dispatch = useDispatch()

  const markOfferAsViewed = (offerShown?: Bff.Offers.Offer) => {
    const locationCode = `Menu.${params.menuCategory ?? 'Pizza'}`

    if (offerShown && isAutoPopupOffer(offerShown, locationCode)) {
      dispatch(rootActions.setOfferToShown(offerShown.offerId))
    }
  }

  useEffect(() => {
    if (offer) {
      fetchProducts(extractProductCodesFromOffer(offer))
    }
  }, [offer])

  useEffect(() => {
    if (offerItems) {
      const lineItemsToAdd = getBasketLinesToAdd(offerItems, products)
      if (lineItemsToAdd.length > 0) {
        dispatch(rootActions.addLinesToBasket({ add: lineItemsToAdd }))
      }

      const couponsToAdd = getCouponsToAdd(offerItems)
      if (couponsToAdd.length > 0) {
        couponsToAdd.forEach((code) => reportVoucherUsage(code, 'Menu Set'))
        dispatch(rootActions.addCoupons(couponsToAdd))
      }

      const handleCoupon = async () => {
        if (couponsToAdd.length > 0 && (isMobile || isKioskOrder)) {
          await navigate(NavigationConstants.basket)
        }
      }

      handleCoupon().then(noop)

      markOfferAsViewed(offer)
    }
  }, [offerItems])

  const closePopup = () => {
    setShowPopup(false)
  }

  const openPopup = () => {
    markOfferAsViewed(offer)
    setShowPopup(true)
  }

  const acceptItems = (items: Bff.Offers.Item[]) => {
    acceptOfferItems(items)
    setShowPopup(false)
  }

  return { isShowPopup, closePopup, openPopup, acceptItems }
}
