import { TFunction } from 'i18next'
import { DateTime } from 'luxon'

export const mapPointStatusTranslation = (status: BffContext.PointsStatuses, t: TFunction) => {
  switch (status) {
    case 'RewardsPendingPoints':
      return t('RewardsPendingPoints', {
        defaultValue: 'Pending',
      })
    case 'RewardsRedeemedPoints':
      return t('RewardsRedeemedPoints', {
        defaultValue: 'Redeemed',
      })
    case 'RewardsEarnedPoints':
      return t('RewardsEarnedPoints', {
        defaultValue: 'Earned',
      })
    case 'AccountClosed':
      return t('AccountClosed', {
        defaultValue: 'Closed',
      })
    case 'RewardsCustomerSupport':
      return t('RewardsCustomerSupport', {
        defaultValue: 'Customer Support',
      })
    default:
      return t('RewardStatusLabelUnknown', {
        defaultValue: 'Unknown',
      })
  }
}

export const getDate = (dateStr: string, locale: string, format: string, timezone?: string) =>
  DateTime.fromISO(dateStr, { zone: timezone }).setLocale(locale).toFormat(format)

export const formatLoyaltyPointsText = (points: number) => (points > 0 ? `+${points}` : points.toString())
