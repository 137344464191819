type ProviderLoggingName = 'Facebook' | 'Google' | 'Apple' | 'Email' | 'Unknown'

export const getProviderLoggingName = (provider: string | undefined): ProviderLoggingName => {
  if (!provider) return 'Unknown'

  switch (provider.toLocaleLowerCase()) {
    case 'facebook':
      return 'Facebook'
    case 'google':
      return 'Google'
    case 'apple':
      return 'Apple'
    case 'email':
    case 'dominos':
    case 'emailpassword':
      return 'Email'
    default:
      return 'Unknown'
  }
}
