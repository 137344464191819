import { AuthenticationSource } from '@dominos/interfaces'

export type SocialProviders = BffContext.SocialLogins.Providers

export interface RequestedFields {
  name?: string
  id: string
  provider: SocialProviders
  email?: string
}

export type AdditionalUserDetails = {
  name?: string
  alternateName?: string
  email?: string
  phoneNumber?: string
  identityProvider: Bff.Customers.UpsertCustomer['identityProvider']
  authenticationSource?: AuthenticationSource
}

export type UniversalAuthProps = BaseProps & {
  onComplete: (provider: SocialProviders | 'Email') => void
  onEmail: () => void
  onRegister: (props: Partial<AdditionalUserDetails>) => void
  onPopupBlocked: () => void
}

export enum SocialLoginError {
  Unknown = 'unknown',
  Failed = 'failed',
  PopupBlocked = 'popup-blocked',
}

export type SocialUserDetails = Partial<Bff.SocialLogins.SocialLogin> & {
  name?: string
}

export type AuthButtonProps = BaseProps & {
  loading?: boolean
  onReady: (provider?: SocialProviders | 'Email') => void
  onToken: (provider: SocialProviders, token: string, details?: SocialUserDetails) => void
  onError: (provider: SocialProviders, reason?: string, error?: string) => void
}
