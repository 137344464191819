import { DetailRow } from '@dominos/components/basket/detail-row'
import React from 'react'

interface OrderTaxProps {
  taxes: BasketOrderTax[]
  testId?: string
}

const OrderTaxes = ({ taxes, testId }: OrderTaxProps) => (
  <>
    {taxes.map((tax) => (
      <DetailRow
        key={tax.name}
        testID={`${testId}.order-tax.${tax.code.replace(/\s/g, '')}`}
        label={tax.name}
        price={tax.amount}
        fontWeight={'normal'}
      />
    ))}
  </>
)

export { OrderTaxes }
