import { CollapsableTitledCard, LoyaltyRedeem } from '@dominos/components'
import { useLoyalty } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'

const LoyaltyCard = () => {
  const { t } = useTranslation('loyalty')
  const { isEnrolled, pointsUntilRedeemable, redeemPizza, loading, isAvailable, totalAvailablePizzas } = useLoyalty()

  if (isEnrolled) {
    return (
      <CollapsableTitledCard
        primaryTitle={t('LoyaltyRedeemTitle', { defaultValue: "Domino's rewards" })}
        startCollapsed={false}
        noPadding={true}
        testID={'loyalty-redeem-card'}
        style={{ margin: '0px 0px 8px' }}
      >
        <LoyaltyRedeem
          freePizzas={totalAvailablePizzas}
          pointsToNextFreePizza={pointsUntilRedeemable}
          onRedeem={redeemPizza}
          loading={loading}
          redeemable={isAvailable}
        />
      </CollapsableTitledCard>
    )
  }

  return null
}

export { LoyaltyCard }
