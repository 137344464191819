import { useEffect, useMemo, useState } from 'react'
import { useFeatures } from '../features'
import { useReport } from '../logging'
import { usePreOrderEta } from './use-pre-order-eta'
import { useCustomerAddress } from '../selectors'
import { formatETA } from '@dominos/business/functions'
import { TFunction } from 'i18next'
import { getETAForPreOrder } from '@dominos/business/functions'

export interface UseEstimatedTimeInOrderProps {
  serviceMethod: BffContext.ServiceMethods | undefined
  currentStore: Bff.Stores.Store | undefined
  serviceMethodTitle: string
  loading: boolean
  t: TFunction
}

export const useEstimatedTimeInOrder = ({
  serviceMethodTitle,
  serviceMethod,
  currentStore,
  loading,
  t,
}: UseEstimatedTimeInOrderProps) => {
  const [eptEnabled, edtEnabled] = useFeatures('EstimatedPickupTimeInOrderTime', 'EstimatedDeliveryTimeInOrderTime')
  const { reportEstimatedOrderTime } = useReport()
  const { fetchPreOrderEstimate, results, loading: loadingPreOrder, called } = usePreOrderEta()
  const customerAddress = useCustomerAddress()
  const [estimatedTimeInOrderText, setEstimatedTimeInOrderText] = useState(
    formatETA(getETAForPreOrder, undefined, undefined, true, t).text,
  )

  const estimatedTimeInOrderTimeEnabled = useMemo(
    () => getETAEnabled(serviceMethod, currentStore?.features, eptEnabled, edtEnabled),
    [currentStore?.features, serviceMethod, eptEnabled, edtEnabled],
  )

  useEffect(() => {
    if (serviceMethod && currentStore && estimatedTimeInOrderTimeEnabled) {
      fetchPreOrderEstimate(currentStore.storeNo, serviceMethod, customerAddress)
    }
  }, [serviceMethod, currentStore])

  useEffect(() => {
    if (!estimatedTimeInOrderTimeEnabled) {
      return
    }

    const eta = formatETA(getETAForPreOrder, results?.min, results?.max, loading || loadingPreOrder, t)
    setEstimatedTimeInOrderText(eta.text)

    if (!loading && !loadingPreOrder && called) {
      reportEstimatedOrderTime(eta.reportLabel, null)
    }
  }, [estimatedTimeInOrderTimeEnabled, loading, loadingPreOrder, results])

  const estimatedTimeInOrderTitle = t('preOrderEtaTitle', {
    method: serviceMethodTitle,
    defaultValue: 'Estimated {{method}} Duration',
  })

  return {
    estimatedTimeInOrderTitle,
    estimatedTimeInOrderTimeEnabled,
    loadingEstimatedTimeInOrder: loadingPreOrder,
    estimatedTimeInOrderText,
  }
}

const getETAEnabled = (
  serviceMethod: BffContext.ServiceMethods | undefined,
  features: Bff.Stores.StoreFeature[] | undefined,
  eptEnabled: boolean,
  edtEnabled: boolean,
): boolean => {
  if (!serviceMethod || (serviceMethod === 'Pickup' && !eptEnabled) || (serviceMethod === 'Delivery' && !edtEnabled)) {
    return false
  }

  return (features ?? []).find((feature) => feature.featureName === 'EstimatedTimeInOrderTime')?.isEnabled ?? false
}
