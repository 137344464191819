export const NavigationConstants: NavigationRouteRecord = {
  home: '/',
  storeSearch: '/store-search',
  timedOrder: '/order-time',
  menu: '/menu',
  product: '/product',
  basket: '/basket',
  varietyMenu: '/variety',
  comingSoon: '/coming-soon',
  hardUpsell: '/product-recommendations',
  confirmAddress: '/confirm-address',
  confirmPickupAddress: '/confirm-pickup-address',
  deliveryAddressAutoComplete: '/delivery-address-auto-complete',
  pickupAddressAutoComplete: '/pickup-address-auto-complete',
  deliveryAddressList: '/delivery-address-list',
  deliveryAddress: '/delivery-address',
  deliveryAddressAddress: '/delivery-address/address',
  deliveryAddressSearch: '/delivery-address/search',
  deliveryAddressSearchList: '/delivery-address/searchList',
  addressSearchResults: '/delivery-address-search-result',
  checkout: '/checkout',
  checkoutDetails: '/checkout/details',
  checkoutPayment: '/checkout/payment',
  checkoutProcessing: '/checkout/processing',
  checkoutPlaceOrder: '/checkout/place-order',
  nativeAppCheckout: '/nativeAppCheckout',
  nativeAppCheckoutDetails: '/nativeAppCheckout/details',
  nativeAppCheckoutPayment: '/nativeAppCheckout/payment',
  nativeAppCheckoutPlaceOrder: '/nativeAppCheckout/place-order',
  nativeAppCheckoutProcessing: '/nativeAppCheckout/processing',
  processing: '/processing',
  tracker: '/tracker',
  nativeAppTracker: '/nativeAppTracker',
  login: '/login',
  nativeAppLogin: '/nativeAppLogin',
  createAccount: '/create-account',
  nativeAppCreateAccount: '/nativeAppCreateAccount',
  myAccount: '/my-account',
  myRewards: '/my-account/my-rewards',
  savedPayment: '/my-account/payment-details',
  nativeAppMyAccount: '/nativeAppMyAccount',
  nativeAppSavedPayment: '/nativeAppMyAccount/payment-details',
  resetPassword: '/reset-password',
  formResolver: 'form/resolve',
  oopsApp: '/oops-app',
  authCallback: '/callback',

  // unimplemented - see type definition for reasons for the existence
  topLevelMenu: 'topLevelMenu',
  mainMenu: 'mainMenu',
  editProduct: 'editProduct',
  menuTab: 'menuTab',
  menuNavigation: 'menuNavigation',
  menuProduct: 'menuProduct',
  menuTabNavigator: 'menuTabNavigator',
  signup: 'signup',
  customer: 'customer',
  voucherMenu: 'voucherMenu',
  webView: 'webView',
  changePassword: 'changePassword',
  changeName: 'changeName',
  changePhoneNumber: 'changePhoneNumber',
  myRewardsCustomer: 'myRewardsCustomer',
  myRewardsHistory: 'myRewardsHistory',
  joinRewards: 'joinRewards',
  signupEntry: 'signupEntry',
  halfAndHalf: 'halfAndHalf',
  halfAndHalfEdit: 'halfAndHalfEdit',
  halfPizzaList: 'halfPizzaList',
  halfPizzaListEdit: 'halfPizzaListEdit',
  offers: 'offers',
  changeAddress: 'changeAddress',
}
