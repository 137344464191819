class LineItemMedia {
  public name: string
  public base?: string
  public size?: string

  constructor(name: string) {
    this.name = name
  }
}

export class LineItem {
  public readonly productCode: string
  public readonly media: LineItemMedia
  public quantity = 1
  public itemNo = 1
  public type: BasketLine['type'] = 'BasketLine'
  public totalPrice: BasketLine['totalPrice'] = null

  constructor(itemCode: string, productName: string = '') {
    this.productCode = itemCode
    this.media = new LineItemMedia(productName)
  }
}
