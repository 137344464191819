import gql from 'graphql-tag'

export const initiateOrderMutation = gql`
  mutation initiateOrderMutation($input: InitialiseOrderInput!) {
    initialiseOrder(input: $input) {
      orderId
      basketId
      paymentDetails {
        orderPaymentId
        providerCode
        paymentMethod
        transactionToken
        amount
        properties {
          key
          value
        }
      }
    }
  }
`
