import { useCurrentOrderDetails, useFetchProductsBatched, useProductStorage } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'

export const useProductsBatched = () => {
  const { storedProductsByCode, setStoredProductsByCode } = useProductStorage()
  const { fetchProducts, products: fetchedProducts, loading, errors } = useFetchProductsBatched()
  const [products, setProducts] = useState<Map<string, Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>>>(
    new Map<string, Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>>(),
  )
  const [productCodes, setProductCodes] = useState<string[]>([])
  const { basketHeaderData: header } = useCurrentOrderDetails()

  const extractProducts = (productCodes: string[], productDictionary: ProductDictionary) => {
    const productsMap = new Map<string, Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>>()
    productCodes.forEach((productCode) => {
      const product = productDictionary?.[productCode]
      if (product) {
        productsMap.set(productCode, product)
      }
    })

    return productsMap
  }

  const fetchProductsByCodes = (productCodes: string[]) => {
    const missingProductCodes = productCodes.filter((productCode) => !storedProductsByCode?.[productCode])

    if (missingProductCodes.length > 0) {
      setProductCodes(productCodes)
      fetchProductsData(missingProductCodes)
    } else {
      setProducts(extractProducts(productCodes, storedProductsByCode))
    }
  }

  const fetchProductsData = (productCodes: string[]) => {
    if (!header || !header.serviceMethod || !header.storeNo || header.time === undefined) {
      return
    } else {
      fetchProducts(productCodes, header.serviceMethod, header.storeNo, header.time)
    }
  }

  useEffect(() => {
    if (loading || errors) {
      setProducts(new Map<string, Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>>())

      return
    }

    if (fetchedProducts.length > 0) {
      const productDictionary: ProductDictionary = {}
      fetchedProducts.forEach((product) => {
        if (product) {
          productDictionary[product.code] = product
        }
      })
      const updatedProductDictionary = { ...storedProductsByCode, ...productDictionary }
      setStoredProductsByCode(updatedProductDictionary)
      setProducts(extractProducts(productCodes, updatedProductDictionary))
    }
  }, [loading, fetchedProducts])

  return {
    fetchProductsByCodes,
    loading,
    products,
    errors,
  }
}
