import { flattenBasketLines } from './flatten-basket'

/**
 * Higher order wrapper for `countFromBasketLines`. Gets
 * the total count of item quantities, otherwise returns
 * zero by default
 * @author Sam Yang
 * @param basket Basket with optional product line items
 */
export const countFromBasket = (basket: Basket) => {
  if (basket && basket.lines) {
    return countFromBasketLines(basket.lines)
  }

  return 0
}

/**
 * Gets the total count of item quantities,
 * otherwise returns zero by default
 * @author Sam Yang
 * @param lines Basket line items
 */
export const countFromBasketLines = (lines: Basket['lines']) => {
  let count = 0
  if (lines) {
    flattenBasketLines(lines).forEach((line) => {
      count = count + line.quantity
    })
  }

  return count
}
