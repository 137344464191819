import React from 'react'
import { TermsAndConditions } from '../terms-and-conditions'
import css from './offer-single-item.less'

export const OfferSingleItem = ({
  testID,
  title,
  price,
  energy,
  description,
  disclaimers,
}: {
  testID: string
  title: string
  price?: string
  energy?: string
  description: string
  disclaimers: Bff.Offers.Disclaimer[]
}) => (
  <div data-testid={testID} className={css.content}>
    <div data-testid={`${testID}.title`} className={css.title}>
      {title || ''}
    </div>

    <div data-testid={`${testID}.subtitle`} className={css.subtitle}>
      {price ? <span data-testid={`${testID}.subtitle.price`} className={css.price}>{`${price}`}</span> : null}

      {energy ? <span data-testid={`${testID}.subtitle.kj`}>{`${price ? ' | ' : ' '}${energy}`}</span> : null}
    </div>

    {description && (
      <div data-testid={`${testID}.description`} className={css.description}>
        {description}
      </div>
    )}

    <TermsAndConditions disclaimers={disclaimers} />
  </div>
)
