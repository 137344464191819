export const flattenBasketToInitialise = (basket: Basket): Bff.Orders.InitialiseOrderBasket | null => {
  if (!basket || basket.lines.length === 0) {
    return null
  }

  const basketCoupons = flattenBasketCoupons(basket.lines)

  return {
    total: basket.total as number,
    totalDiscount: basket.totalDiscount,
    surcharges: flattenSurcharges(basket.surcharges),
    minimumPrice: 0,
    coupons: basketCoupons.map(
      (coupon): Bff.Orders.InitialiseOrderBasketCoupon => ({
        couponNo: coupon.couponNo || 0,
        code: coupon.code,
        isLoyaltyRedemption: coupon.isLoyaltyRedemption,
      }),
    ),
    lines: flattenBasketLines(basket.lines).map((line) => ({
      lineNo: line.itemNo,
      price: line.totalPrice || 0,
      productCode: line.productCode,
      sizeCode: line.sizeCode || undefined,
      quantity: line.quantity,
      voucherNo: getVoucherNo(basketCoupons, line.itemNo),
      voucherItemNo: getVoucherItemNo(basketCoupons, line.itemNo),
      toppings:
        line.toppings &&
        line.toppings.map((top: BasketLineChange) => ({
          action: top.action,
          code: top.code,
          quantity: top.quantity,
        })),
      options:
        line.options &&
        line.options.map((top: BasketLineChange) => ({
          action: top.action,
          code: top.code,
          quantity: top.quantity,
        })),
      base: line.base ? { add: line.base.add || undefined, remove: line.base.remove } : undefined,
      sauce: line.sauce ? { add: line.sauce.add || undefined, remove: line.sauce.remove } : undefined,
      portions:
        line.portions &&
        line.portions.map((p: BasketLinePortion) => ({
          productCode: p.productCode,
          sauce: p.sauce && {
            add: p.sauce.add || undefined,
            remove: p.sauce.remove,
          },
          toppings:
            p.toppings &&
            p.toppings.map((top) => ({
              action: top.action,
              code: top.code,
              quantity: top.quantity,
            })),
          surcharges:
            p.surcharges &&
            p.surcharges.map((srch) => ({
              code: srch.code,
              price: srch.price,
            })),
        })),
    })),
  }
}

/**
 * Creates a collection of formated surcharge objects by
 * mappying only the required fields. Effectively performs
 * a filter by only passing through valid properties.
 * @param surcharges Array of surcharges to map
 */
const flattenSurcharges = (surcharges: BasketSurcharge[]) =>
  surcharges.map<Omit<BasketSurcharge, 'name'>>((surcharge) => ({
    code: surcharge.code,
    price: surcharge.price,
  }))

export const flattenBasketLines = (lines: BasketLineUnionDependancy[]): BasketLine[] =>
  lines.reduce<BasketLine[]>((memo, line) => {
    if (line.type === 'BasketLine') {
      return [...memo, line]
    }

    if (line.items) {
      let newMemo = [...memo]
      line.items.forEach((item) => {
        newMemo = [...newMemo, ...item.lines]
      })

      return newMemo
    }

    return memo
  }, [])

export const flattenBasketCoupons = (lines: BasketLineUnionDependancy[]): ValidateBasketCoupon[] =>
  lines.reduce<ValidateBasketCoupon[]>((memo, line) => {
    if (line.type === 'BasketCoupon') {
      return [
        ...memo,
        {
          code: line.code,
          couponNo: line.couponNo,
          isLoyaltyRedemption: line.isLoyaltyRedemption,
          items: line.items.map((i) => ({
            voucherItemNo: i.itemNo,
            products: i.lines.map((l) => l.itemNo),
          })),
        },
      ]
    }

    return memo
  }, [])

const getVoucherNo = (coupons: ValidateBasketCoupon[], itemNo: number): number | undefined =>
  coupons.find((coupon) => coupon.items?.some((item) => item.products?.includes(itemNo)))?.couponNo ?? undefined

const getVoucherItemNo = (coupons: ValidateBasketCoupon[], itemNo: number): number | undefined =>
  coupons
    .find((coupon) => coupon.couponNo === getVoucherNo(coupons, itemNo))
    ?.items?.find((item) => item.products?.includes(itemNo))?.voucherItemNo
