import React, { KeyboardEvent } from 'react'

import { onArrowKeys, onButtonClick } from '@dominos/business/functions'

import cssList from './list-group.less'

interface ListGroupCollectionLineWrapperProps extends BaseProps {
  prefix: string
  itemId: string
  itemIndex: number
  children: React.ReactNode
  onPress: (itemId: string) => void
}

export const ListGroupCollectionLineWrapper = ({
  testID,
  prefix,
  itemIndex,
  itemId,
  children,
  onPress,
}: ListGroupCollectionLineWrapperProps) => {
  const onClick = () => {
    onPress(itemId)
  }

  const onKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    onButtonClick(event, () => onPress(itemId))

    if (onArrowKeys(event, prefix, itemIndex, 5)) {
      return
    }
  }

  return (
    <div
      data-testid={testID}
      id={`${prefix}.${itemIndex}`}
      className={cssList.wrapper}
      onClick={onClick}
      role='option'
      tabIndex={0}
      onKeyPress={onKeyUp}
      onKeyDown={onKeyUp}
    >
      {children}
    </div>
  )
}
