import { ActionButton, MDTargetBlankAnchor, Modal } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import Markdown from 'markdown-to-jsx'
import React from 'react'
import { useTranslation } from 'react-i18next'

import css from './about-pricing-popup.less'

const icon = {
  name: 'cross' as svgIconsName,
  width: 21,
  height: 21,
}

const AboutPricingPopup = ({ testID, onClose }: AboutPricingPopupProps) => {
  const { t } = useTranslation('basket')
  const { colours } = useDominosTheme()

  return (
    <Modal testID={testID} onModalClose={onClose}>
      <div className={css.cardContainer}>
        <h4 className={css.cardHeader} data-testid={`${testID}.header`}>
          {t('AboutOurPricing')}
        </h4>
        <div
          data-testid={`${testID}.close-button.top`}
          role='button'
          aria-label={t('surchargeInfoCloseButton', { defaultValue: 'Close Button' })}
          className={css.closeButton}
          onClick={onClose}
        >
          <StaticSvgIcon name={icon.name} width={icon.width} height={icon.height} isUnstyled />
        </div>
        <div className={css.contentContainer}>
          <Markdown options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
            {t('AboutOurPricingText')}
          </Markdown>
          <div className={css.contentFooter}>
            <ActionButton
              text={t('Got it', { defaultValue: 'Got it' })}
              onPress={onClose}
              testID={`${testID}.close-button.bottom`}
              containerStyle={{ width: '100%', backgroundColor: colours.actionStandard }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

interface AboutPricingPopupProps {
  testID: string
  onClose: () => void
}

export default AboutPricingPopup
