import { offersQuery } from '@dominos/business/queries/offers'
import { useLazyQuery } from '@apollo/client'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { useEffect, useMemo } from 'react'

const OFFER_LAYOUTS: BffContext.Layouts[] = ['SingleItem']

export const prepareQueryVariables = (
  header: Partial<BasketData>,
  orderId: string,
  customerAddress: CustomerAddressRequest = {},
) => {
  const { time, storeNo, serviceMethod } = header
  const { buildingName, unitNo, streetName, streetNo, postCode, state, suburb } = customerAddress

  return {
    orderId,
    tradingTime: time ?? new Date().toISOString(),
    storeNo: storeNo,
    serviceMethod: serviceMethod!,
    deliveryAddress: {
      buildingName: buildingName ?? undefined,
      unitNo: unitNo ?? undefined,
      streetNo: streetNo ?? undefined,
      street: streetName ?? undefined,
      suburb: suburb ?? undefined,
      state: state ?? undefined,
      postCode: postCode ?? undefined,
    },
    layouts: OFFER_LAYOUTS,
  } as Bff.Offers.OffersQueryArgs
}

/**
 * Returns the first item when used in `Array.find(anything)`
 */
const anything = (offer: Bff.Offers.Offer) => offer

export const useSingleItemOffer = (matchFunction?: (offer: Bff.Offers.Offer) => boolean) => {
  const { basketHeaderData, orderId, selectedDeliveryAddress } = useCurrentOrderDetails()
  const [sendRequest, { data, loading }] = useLazyQuery<{ offers: Bff.Offers.Offer[] }, Bff.Offers.OffersQueryArgs>(
    offersQuery,
    { fetchPolicy: 'no-cache' },
  )

  const fetchOffers = () => {
    if (basketHeaderData) {
      const variables = prepareQueryVariables(basketHeaderData, orderId, selectedDeliveryAddress?.customerAddress)
      sendRequest({
        variables,
      })
    }
  }

  const offer = useMemo(() => data?.offers.find(matchFunction || anything) || null, [data?.offers, matchFunction])

  useEffect(() => {
    fetchOffers()
  }, [basketHeaderData, selectedDeliveryAddress])

  return {
    loading,
    offer,
  }
}
