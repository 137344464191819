import { RouteComponentProps } from '@reach/router'
import HardUpsellContainer from '@dominos/components/hard-upsell/hard-upsell-container/hard-upsell-container'
import React from 'react'
import { HardUpsellSceneProps } from './hard-upsell-scene.interface'
import { EmptyFooter, SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'

const HardUpsellScene = (props: HardUpsellSceneProps & RouteComponentProps) => (
  <StackNavigationBase
    testID='hardUpsellSceneScene'
    header={<SimpleNavigationTitle showBackButton />}
    footer={<EmptyFooter />}
  >
    <HardUpsellContainer testID='hard-upsell-scene-container' />
  </StackNavigationBase>
)

export { HardUpsellScene }
