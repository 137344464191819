import { isNativeApp } from '@dominos/business/functions/native-app'
import { NavigationConstants } from '@dominos/navigation'

// For now there is nothing specific for AsiaPay, but might need to add when implement the support for the NativeApp
export const getAsiaPayReturnUrl = (
  orderId: string,
  orderPaymentId: string,
  excludeMobileDeepLinks: boolean = false,
  googleId?: string,
) => {
  const url = new URL(`${NavigationConstants.checkoutProcessing}/${orderId}/${orderPaymentId}`, window.location.origin)

  if (googleId) {
    url.searchParams.set('ga_redirect_id', googleId)
  }

  if (isNativeApp()) {
    url.pathname = `${NavigationConstants.nativeAppCheckoutProcessing}/${orderId}/${orderPaymentId}`

    if (!excludeMobileDeepLinks) {
      const searchParams = url.searchParams.toString()

      return `dominos://payment${url.pathname}${searchParams && '?'}${searchParams}`
    }
  }

  return url.toString()
}
