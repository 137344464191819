import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 30,
  height: 30,
}

const SearchResultIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#007AFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 30 30'>
      <g id='Search-Result-Address' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Recent-Icon'>
          <g id='Icon'>
            <circle id='Background' fill={fill} cx='15' cy='15' r='15' />
            <g id='Glyphs/Search' transform='translate(8.000000, 8.000000)' fill='#FFFFFF'>
              <path
                d='M9.91009465,8.73955572 C10.5971654,7.80245935 10.9652265,6.66953223 10.9600947,5.50755572 C10.946985,2.47441147 8.49371664,0.0175744397 5.46059465,5.57178262e-05 C4.00907481,-0.00651157295 2.61521603,0.567712287 1.58953064,1.59480646 C0.56384524,2.62190064 -0.00846492859,4.01654626 9.46502197e-05,5.46805572 C0.0132043609,8.50147611 2.46669652,10.958537 5.50009465,10.9760557 C6.66680484,10.9811124 7.80387114,10.6087552 8.74159465,9.91455572 L8.74559465,9.91155572 L12.5750947,13.7430557 C12.7821435,13.9602692 13.0906604,14.0481921 13.3811096,13.9727584 C13.6715587,13.8973248 13.898302,13.6703873 13.9734871,13.3798737 C14.0486722,13.0893602 13.9604853,12.7809186 13.7430947,12.5740557 L9.91009465,8.73955572 Z M5.49467267,9.48787701 C3.28374692,9.47515862 1.49542344,7.68439651 1.48571789,5.47345548 C1.47975056,4.415586 1.89694226,3.39923176 2.64442447,2.65063722 C3.39190669,1.90204268 4.40763945,1.48334013 5.46551663,1.48773442 C7.67644238,1.50045282 9.46476587,3.29121493 9.47447141,5.50215595 C9.48043874,6.56002544 9.06324705,7.57637968 8.31576483,8.32497422 C7.56828261,9.07356876 6.55254985,9.4922713 5.49467267,9.48787701 Z'
                id='Search'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { SearchResultIcon }
