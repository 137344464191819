import { createCustomAction, createStandardAction } from 'typesafe-actions'

export const retrievingStoreInformation = createStandardAction('STORE:InformationRetrieving')()

export const retrievedStoreInformation = createCustomAction(
  'STORE:InformationRetrieved',
  (type) =>
    (store: Bff.Stores.Store, remember: boolean = true) => ({
      type,
      store,
      remember,
    }),
)

export const storeSelected = createCustomAction(
  'STORE:Selected',
  (type) =>
    (store: Bff.Stores.Store, remember: boolean = true) => ({
      type,
      store,
      remember,
    }),
)

export const alternativeStoreSelected = createCustomAction(
  'STORE:AlternativeSelected',
  (type) => (storeNo: number) => ({
    type,
    storeNo,
  }),
)

export const retrievedStoreAlternativeStores = createCustomAction(
  'STORE:StoreAlternativeStoresRetrieved',
  (type) => (storeAlternativeStores: Bff.Stores.Store[]) => ({
    type,
    storeAlternativeStores,
  }),
)

export const resetCurrentStore = createStandardAction('STORE:ResetCurrentStore')()
export const selectStoreFail = createStandardAction('STORE:ERROR')<string | Error>()
export const clearOrderIssues = createStandardAction('Checkout:ClearOrderIssues')()

export const setSavedStores = createCustomAction(
  'STORE:SetSavedStores',
  (type) => (savedStores: TimeAugmentedStore[]) => ({
    type,
    savedStores,
  }),
)
