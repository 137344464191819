import React from 'react'
import Markdown from 'markdown-to-jsx'
import { MDTargetBlankAnchor } from '@dominos/components'
import { useTranslation } from 'react-i18next'
import styles from './vip-offers.less'

export const VipOffersTC = () => {
  const { t } = useTranslation('checkout')

  return (
    <Markdown
      data-testid='offers-t-and-c'
      className={styles.vipOffersTc}
      options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}
    >
      {t('OffersT&C', {
        defaultValue:
          'By joining, you agree to receive messages from Domino’s, and you agree to our [Terms And Conditions](https://www.dominos.com.au/about-us/contact-us/terms-conditions) and [Privacy Policy](https://www.dominos.com.au/about-us/contact-us/privacy-policy).',
      })}
    </Markdown>
  )
}
