import React, { useState } from 'react'

import { CollapsableTitledCard, TouchableGrid } from '@dominos/components'

import { CustomisationCardProps } from './customisation-card.interface'

export const CustomisationCard = <T extends TouchableGridElement>(props: CustomisationCardProps<T>) => {
  const { items, selectedItemCode, primaryTitle, numColumns, onItemSelected, testID } = props

  const [toggleExpanded, setToggleExpanded] = useState<boolean>(false)

  const selectedItem = items.find((item) => item.code === selectedItemCode)

  const toggleExpandedHandler = (expanded: boolean) => {
    setToggleExpanded(expanded)
  }

  const onPressItemHandler = (item: T) => {
    setToggleExpanded(!toggleExpanded)
    if (onItemSelected) {
      onItemSelected(item.code)
    }
  }

  const renderItem = (item: T) => <props.renderItem {...item} />

  const isCustomizable = items && items.length > 0

  return (
    <>
      {isCustomizable ? (
        <CollapsableTitledCard
          testID={`customisation.${testID}`}
          noPadding={true}
          startCollapsed={!toggleExpanded}
          primaryTitle={primaryTitle}
          secondaryTitle={selectedItem && selectedItem.name}
          onToggleExpanded={toggleExpandedHandler}
        >
          <TouchableGrid
            testID={`customisation.touchable.${testID}`}
            data={items}
            selectedItem={(selectedItem && selectedItem.code) || ''}
            numColumns={numColumns}
            renderItem={renderItem}
            onPressItem={onPressItemHandler}
          />
        </CollapsableTitledCard>
      ) : null}
    </>
  )
}
