import { FancyParagraphTextField } from '@dominos/components'
import { useBreakpoints } from '@dominos/hooks-and-hocs'
import {
  Face1Active,
  Face1Inactive,
  Face2Active,
  Face2Inactive,
  Face3Active,
  Face3Inactive,
  Face4Active,
  Face4Inactive,
  Face5Active,
  Face5Inactive,
} from '@dominos/res/images/icons/components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './face-symbol-feedback.less'

const MAX_CHARS = 260

const ToggleableIcon = (props: {
  icons: { active: JSX.Element; inactive: JSX.Element }
  onClick: (value: number) => void
  active: boolean
  activeValue: number
  questionId: string
}) => {
  const { icons, onClick, active, activeValue, questionId } = props
  const processClick = useCallback(() => {
    onClick(activeValue)
  }, [])

  return (
    <div
      key={`face-symbol-${questionId}-${activeValue}`}
      className={styles.face}
      role={'checkbox'}
      onClick={processClick}
    >
      {active ? icons.active : icons.inactive}
    </div>
  )
}

const FaceSymbolFeedback = (props: {
  question: Bff.Feedback.FeedbackQuestion
  onClick: (val: Bff.Feedback.FeedbackQuestionResponse) => void
  error?: string
}) => {
  const { question, onClick, error } = props
  const { t } = useTranslation('tracker')
  const { isNotMobile } = useBreakpoints()
  const [selectedFace, setSelectedFace] = useState<number>()
  const [selectedReason, setSelectedReason] = useState<Bff.Feedback.FeedbackReasonResponse>()
  const [response, setResponse] = useState<Bff.Feedback.FeedbackQuestionResponse>({
    questionId: question.questionId,
    externalQuestionId: question.externalQuestionId ?? '',
  })
  const [showSubquestion, setShowSubquestion] = useState<boolean>(false)
  const [showFreeText, setShowFreeText] = useState<boolean>(false)

  const faces: Map<number, { active: JSX.Element; inactive: JSX.Element }> = new Map()
  faces.set(1, { active: <Face1Active />, inactive: <Face1Inactive /> })
  faces.set(2, { active: <Face2Active />, inactive: <Face2Inactive /> })
  faces.set(3, { active: <Face3Active />, inactive: <Face3Inactive /> })
  faces.set(4, { active: <Face4Active />, inactive: <Face4Inactive /> })
  faces.set(5, { active: <Face5Active />, inactive: <Face5Inactive /> })

  useEffect(() => {
    if (selectedFace) {
      let showSubQuestion = false
      if (question.feedbackReason && selectedFace < question.feedbackReason.enableOnRatingLowerThan) {
        showSubQuestion = true
      }
      setResponse((prev) => ({
        ...prev,
        feedbackReason: showSubQuestion ? prev.feedbackReason : undefined,
        rating: selectedFace,
      }))
      setShowSubquestion(showSubQuestion)
    }
  }, [selectedFace])

  useEffect(() => {
    if (selectedReason) {
      setResponse((prev) => ({
        ...prev,
        feedbackReason: selectedReason,
      }))
    }
  }, [selectedReason])

  useEffect(() => {
    if (response) {
      onClick(response)
    }
  }, [response])

  const handleFeedbackReason = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const reasonCode = event.target.value
    const selectedReasonOption = question.feedbackReason?.reasonOptions.find((fr) => fr.reasonCode === reasonCode)
    if (selectedReasonOption?.allowFreeText) {
      setShowFreeText(true)
    } else {
      setShowFreeText(false)
      setResponse((prev) => ({
        ...prev,
        comment: undefined,
      }))
    }

    setSelectedReason({
      reasonCode: selectedReasonOption?.reasonCode ?? '',
      externalSubQuestionId: question.feedbackReason?.externalSubQuestionId ?? '',
    })
  }

  const handleFeedbackFreeText = (comment: string) => {
    setResponse((prev) => ({
      ...prev,
      comment,
    }))
  }

  const icons = [1, 2, 3, 4, 5].map((index) => (
    <ToggleableIcon
      key={`face-symbol-${question.questionId}-${index}`}
      icons={faces.get(index)!}
      onClick={setSelectedFace}
      active={selectedFace ? selectedFace === index : true}
      activeValue={index}
      questionId={question.questionId}
    />
  ))

  const textAreaStyles = {
    wrapper: {
      margin: '8px auto 0 auto',
      width: isNotMobile ? '80%' : '100%',
    },
    textArea: {
      height: '100px',
    },
  }

  return (
    <div role='input' className={styles.facesFeedbackComponent}>
      <p>{props.question.questionText}</p>
      <div role={'listbox'} className={styles.facesWrapper}>
        {icons}
      </div>
      {showSubquestion ? (
        <div className={styles.feedbackOptions}>
          <p>{question.feedbackReason?.followupQuestionText}</p>
          <select data-testid={`${question.questionId}-reason`} onChange={handleFeedbackReason}>
            <option value={''} disabled selected>
              {t('OrderFeedbackDropdownPrompt', { defaultValue: 'Please select an option' })}
            </option>
            {question.feedbackReason?.reasonOptions.map((reason) => (
              <option key={`${question.questionId}-${reason.reasonCode}`} value={reason.reasonCode}>
                {reason.reasonText}
              </option>
            ))}
          </select>
          {showFreeText ? (
            <FancyParagraphTextField
              style={textAreaStyles.wrapper}
              textFieldStyle={textAreaStyles.textArea}
              testID={`${question.questionId}-reason-freetext`}
              placeholder={t('OrderFeedbackTextBoxPrompt', { defaultValue: 'Tell us here...' })}
              maxLength={MAX_CHARS}
              onChange={handleFeedbackFreeText}
            />
          ) : null}
        </div>
      ) : null}
      {error ? <p className={styles.validationError}>{error}</p> : null}
    </div>
  )
}

export { FaceSymbolFeedback }
