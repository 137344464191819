import { ApolloError } from '@apollo/client'
import { rootActions } from '@dominos/business'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import {
  ErrorHandler,
  ErrorScope,
  LoyaltyPointsEarned,
  NotificationType,
  PopUpNotification,
  TrackerBar,
  useErrorContext,
} from '@dominos/components'
import {
  NeedETAStatus,
  OrderStatus,
  useDominosTheme,
  useFeatures,
  useOrderStatus,
  WaitingOrderStatus,
} from '@dominos/hooks-and-hocs'
import OrderModel from '@dominos/hooks-and-hocs/order/model/order-model'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import { orderCancelledError, orderUnrecoverableError } from '@dominos/scenes/tracker-scene/tracker-errors'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { OrderEta } from './order-eta'
import { deliveryOrderFlow, pickupOrderFlow } from './order-status-steps'
import css from './order-tracker.less'

interface HandlerProps {
  orderModel?: OrderModel
  error?: ApolloError
}

interface OrderTrackerProps {
  id: string
  orderId: string
  timezone?: string
  serviceMethod: BffContext.ServiceMethods
  pollingInterval?: number
}

const MAX_RETRIES = 3
const POLLING_INTERVAL = 30 * 1000
const RETRYABLE_ERRORS = ['UNABLE_TO_RESOLVE']

export const OrderTracker = ({ id, orderId, serviceMethod, timezone, pollingInterval }: OrderTrackerProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('tracker')
  const retries = useRef(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [orderEta, setOrderEta] = useState<Bff.Orders.OrderEta>()
  const [currentStatus, setCurrentStatus] = useState(OrderStatus.SentToStore)
  const setCurrentStateRef = useRef(setCurrentStatus)
  const [estimatedTimeInTrackerEnabled] = useFeatures('EstimatedTimeInTracker')
  const currentFlow = serviceMethod === 'Delivery' ? deliveryOrderFlow : pickupOrderFlow

  const {
    addStatusHandler,
    stopPolling,
    startPolling,
    addUnknownStatusHandler,
    addNetworkErrorHandler,
    addGqlErrorHandler,
  } = useOrderStatus(orderId, true)

  const dispatch = useDispatch()
  const { notifyError } = useErrorContext()

  const resetRetries = () => {
    retries.current = 0
  }

  const handleUnsuccessfulResponse = ({ error, orderModel }: HandlerProps) => {
    const isRetryable = RETRYABLE_ERRORS.includes(error?.graphQLErrors[0].extensions?.code)
    const hasMaxedOutRetries = retries.current >= MAX_RETRIES

    if (isRetryable && !hasMaxedOutRetries) {
      retries.current = retries.current + 1
    } else {
      setErrorMessage('')
      setCurrentStateRef.current(OrderStatus.Complete)
      stopPolling()

      handleNotifyUnsuccessfulResponse(orderModel)
    }
  }

  const statusErrorHandler = (_status: OrderStatus): ErrorHandler => {
    const handleErrorClosed = () => {
      navigate(NavigationConstants.home)
      dispatch(rootActions.restartOrder())
    }

    return {
      handleErrorClosed,
      handleErrorDisplayed: () => {},
    }
  }

  const handleNotifyUnsuccessfulResponse = (order?: OrderModel) => {
    let errorDefinition = orderUnrecoverableError

    if (order?.id && order.status === OrderStatus.Cancelled) {
      errorDefinition = orderCancelledError
    }

    if (isNativeApp()) {
      if (order?.id && order.status === OrderStatus.Cancelled) {
        notifyNativeApp('error-order-cancelled')
      } else {
        notifyNativeApp('error')
      }
      dispatch(rootActions.restartOrder())
    } else {
      notifyError({
        orderStatus: order?.status || OrderStatus.Unknown,
        definitions: errorDefinition,
        handlers: { statusErrorHandler },
        scope: ErrorScope.Tracker,
      })
    }
  }

  const handleUnsuccessfulNetworkResponse = () => {
    setErrorMessage(t('Network connection lost message'))
  }

  const assignHandlers = useCallback(() => {
    addStatusHandler(WaitingOrderStatus, ({ orderModel }) => {
      resetRetries()
      setErrorMessage('')
      setOrderEta(orderModel?.eta)
      const status = orderModel?.status
      if (status) {
        setCurrentStateRef.current(status)
      }
    })

    addStatusHandler(OrderStatus.Timed, ({ orderModel }) => {
      resetRetries()
      setErrorMessage('')
      setOrderEta(orderModel?.eta)
    })

    addStatusHandler(OrderStatus.Complete, () => {
      resetRetries()
      stopPolling()
      setErrorMessage('')
      setCurrentStatus(OrderStatus.Complete)
    })

    const unsuccessfulStatuses = [OrderStatus.Cancelled, OrderStatus.Unknown]
    addStatusHandler(unsuccessfulStatuses, handleUnsuccessfulResponse)
    // We ignore the Remaking status as per business requirements
    addStatusHandler(OrderStatus.Remaking, () => {})
    addUnknownStatusHandler(handleUnsuccessfulResponse)
    addNetworkErrorHandler(handleUnsuccessfulNetworkResponse)
    addGqlErrorHandler(handleUnsuccessfulResponse)
  }, [addStatusHandler, addUnknownStatusHandler, addNetworkErrorHandler, addGqlErrorHandler])

  useEffect(() => {
    assignHandlers()
    startPolling(pollingInterval || POLLING_INTERVAL)
  }, [])

  const themedCardStyle = {
    boxShadow: theme.defaultShadow,
    borderRadius: theme.webBorderRadius,
    overflow: 'hidden',
  }

  return (
    <>
      <div id={id} className={css.orderTracker} style={themedCardStyle}>
        {errorMessage && (
          <PopUpNotification infinite={true} heading={errorMessage} notificationType={NotificationType.info} />
        )}
        <h2 className={css.orderTrackerTitle}>{t('Order Tracker Title')}</h2>
        <TrackerBar allStatuses={currentFlow} currentStatus={currentStatus} />
        <LoyaltyPointsEarned />
      </div>
      {NeedETAStatus.includes(currentStatus) && estimatedTimeInTrackerEnabled && (
        <OrderEta
          min={orderEta?.min}
          max={orderEta?.max}
          serviceMethod={serviceMethod}
          status={currentStatus}
          timezone={timezone}
        />
      )}
    </>
  )
}
