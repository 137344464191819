import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const LogoutIcon = ({ width = 19, height = 19 }: SvgIconProps) => (
  <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 19 19'>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g
        id='Views-/-Contextual-Menu-/-Light-/-BG---Preview-and-Menu---Left'
        transform='translate(-13.000000, -111.000000)'
        fill='#FF3B30'
        fillRule='nonzero'
      >
        <g id='logout-variant' transform='translate(13.434783, 111.500000)'>
          <path
            d='M11.08,12.59 L13.67,10 L4,10 L4,8 L13.67,8 L11.08,5.41 L12.5,4 L17.5,9 L12.5,14 L11.08,12.59 M16,0 C17.1045695,0 18,0.8954305 18,2 L18,6.67 L16,4.67 L16,2 L2,2 L2,16 L16,16 L16,13.33 L18,11.33 L18,16 C18,17.1045695 17.1045695,18 16,18 L2,18 C0.89,18 0,17.1 0,16 L0,2 C0,0.89 0.89,0 2,0 L16,0 Z'
            id='Shape'
          />
        </g>
      </g>
    </g>
  </svg>
)

export { LogoutIcon }
