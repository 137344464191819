import React from 'react'
import css from './order-details.less'

const OrderDetailsSkeleton = () => (
  <>
    <section className={css.header}>
      <div className={css.placeholderPicker}>&nbsp;</div>
      <div className={css.placeholderPicker}>&nbsp;</div>
    </section>
    <section className={css.placeholderProducts}>
      <div className={css.placeholderProduct}>
        <div className={css.placeholderProductDetails}>
          <div className={css.placeholderProductName}>&nbsp;</div>
          <div className={css.placeholderProductCustomisations}>&nbsp;</div>
        </div>
        <div className={css.placeholderProductPrice}>&nbsp;</div>
      </div>
    </section>
    <section className={css.placeholderTotal}>
      <div>&nbsp;</div>
      <div>&nbsp;</div>
    </section>
  </>
)

export default OrderDetailsSkeleton
