import { AuthMessageHelper, GenericCard } from '@dominos/components'
import { useCurrentOrderDetails, useCustomer, useFeatures, useReport, useSocialAuth } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { ErrorScope, useErrorContext } from '@dominos/components/error'
import { navigate, useLocation } from '@reach/router'
import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'
import React, { useEffect, useRef } from 'react'

import { CreateAccountButton } from './create-account-button'
import { EmailLogin } from './email-login'
import { SignUpMessage } from './signup-message'
import { useLoginTranslations } from './use-login-translations'
import { loginErrors } from './login-errors'

import { AppleIdAuthSuccessUser, AppleNative } from './apple'
import { FacebookNative } from './facebook'
import { GoogleNative } from './google'

const SOCIAL_PROVIDERS = ['Facebook', 'Google', 'Apple']

type ProviderType = BffContext.SocialLogins.Providers
export interface AuthResponse {
  provider: ProviderType
  providerToken: string
  user?: AppleIdAuthSuccessUser
}

const NativeAppLoginContainer = () => {
  const location = useLocation()
  const [appleAuthEnabled, googleAuthEnabled, facebookAuthEnabled] = useFeatures(
    'AppleAuthNativeApp',
    'GoogleAuthNativeApp',
    'FacebookAuthNativeApp',
  )
  const onSignUpClicked = () => navigate(NavigationConstants.nativeAppCreateAccount)
  const userRef = useRef<AuthResponse['user']>()

  const curProviderRef = useRef<ProviderType>()
  const t = useLoginTranslations()
  const otherLoginTexts = t.getOthers()

  const { reportLogin } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const { customer } = useCustomer()
  const { notifyError } = useErrorContext()

  const { signIn, pending, authCustomerInfo, error } = useSocialAuth()
  useEffect(() => {
    const onMessage = (message: MessageEvent) => {
      if (!curProviderRef.current || !SOCIAL_PROVIDERS.includes(curProviderRef.current)) {
        return
      }

      const authResp = AuthMessageHelper.parseAuthResp(message)

      if (authResp?.user) {
        userRef.current = authResp.user
      }
      // only listen to messages upon social auth finished from native
      if (authResp) {
        signIn({
          orderId,
          provider: authResp.provider,
          providerToken: authResp.providerToken,
          enableLongLived: true,
        })
      } else {
        reportLogin({
          enableLongLived: true,
          status: 'fail',
          identityProvider: curProviderRef.current!,
          order_id: orderId,
          url: location.href,
          authenticationSource: 'Legacy',
          customerId: customer?.id,
          status_reason: 'Social: Failed to parse AuthResponse from native sdk',
        })
      }
    }
    window.addEventListener('message', onMessage)

    return () => window.removeEventListener('message', onMessage)
  }, [])

  const getCreateAccountSceneProps = (
    provider: ProviderType,
    customerInfo: Bff.SocialLogins.SocialLogin,
    userDetails: AuthResponse['user'],
  ) => {
    const [title, subtitle] = t.getSocials(provider)

    return {
      // redirect should be handled in native later where the webview is embedded
      title,
      subtitle,
      description: otherLoginTexts.SignUpSocialDescription,
      name: [
        userDetails?.name?.firstName ?? customerInfo.firstName,
        userDetails?.name?.lastName ?? customerInfo.lastName,
      ].join(' '),
      email: customerInfo.email,
      identityProvider: {
        identityProviderId: customerInfo.identityProviderId,
        externalProviders: [
          {
            providerType: provider.toLowerCase(),
            providerId: customerInfo.identityProviderId,
          },
        ],
      },
    } as CreateAccountSceneProps
  }

  useEffect(() => {
    if (authCustomerInfo) {
      // by the time this effect triggered, curProvider state can never be null
      // as we need to click any of the social buttons to get here
      if (authCustomerInfo.customerId) {
        reportLogin({
          enableLongLived: true,
          identityProvider: curProviderRef.current!,
          status: 'success',
          order_id: orderId,
          url: location.href,
          authenticationSource: 'Legacy',
          customerId: customer?.id,
        })
        AuthMessageHelper.postIdToken(authCustomerInfo.idToken)
      } else {
        navigate(NavigationConstants.nativeAppCreateAccount, {
          state: getCreateAccountSceneProps(curProviderRef.current!, authCustomerInfo, userRef.current),
        })
      }
    }
  }, [authCustomerInfo])

  useEffect(() => {
    if (error) {
      reportLogin({
        enableLongLived: true,
        status: 'fail',
        order_id: orderId,
        identityProvider: curProviderRef.current!,
        url: location.href,
        authenticationSource: 'Legacy',
        customerId: customer?.id,
        status_reason: error.message,
      })

      notifyError({
        error,
        definitions: loginErrors,
        handlers: {},
        scope: ErrorScope.CreateAccount,
      })
    }
  }, [error])

  const socialHandler = (provider: ProviderType) => () => {
    curProviderRef.current = provider
    AuthMessageHelper.postAuthRequest(provider)
  }
  const isCurLoading = (provider: ProviderType) => curProviderRef.current === provider && pending

  return (
    <GenericCard testID='Login.main-card' title={otherLoginTexts.LogIn} width={styles.mobile.staticCardWidth}>
      <SignUpMessage onSignUpClicked={onSignUpClicked} />
      <EmailLogin enableLongLived keepMeLoggedIn />

      {appleAuthEnabled ? <AppleNative loading={isCurLoading('Apple')} onSignIn={socialHandler('Apple')} /> : null}
      {googleAuthEnabled && <GoogleNative loading={isCurLoading('Google')} onSignIn={socialHandler('Google')} />}
      {facebookAuthEnabled && (
        <FacebookNative loading={isCurLoading('Facebook')} onSignIn={socialHandler('Facebook')} />
      )}

      <CreateAccountButton onSignUpClicked={onSignUpClicked} />
    </GenericCard>
  )
}

const styles = {
  mobile: {
    staticCardWidth: '360px',
  },
}

export { NativeAppLoginContainer }
