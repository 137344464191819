import LZ from 'lz-string'

export const writeValueToStorage = <T>(
  key: string,
  value: T,
  storedValue: T,
  compress: boolean,
  storeValue: (saveValue: T) => void,
  storageType: StorageTypes = 'sessionStorage',
) => {
  try {
    const valueToStore = value instanceof Function ? value(storedValue) : value
    const valueForLS = compress ? LZ.compressToUTF16(JSON.stringify(valueToStore)) : JSON.stringify(valueToStore)

    storageType === 'localStorage'
      ? window.localStorage.setItem(key as string, valueForLS)
      : window.sessionStorage.setItem(key as string, valueForLS)

    storeValue(valueToStore)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Error setting ${storageType} key “${key}”:`, error)
  }
}
