import { useParams } from '@reach/router'
import { formatCategory } from '@dominos/business/functions/menu'

type MenuPage = Bff.Menu.old.MenuPage

export const useMenuCategory = (page?: MenuPage) => {
  const params = useParams()
  const { menuCategory } = params

  const formattedCategory = (page && formatCategory(page.code)) || 'pizza'

  return menuCategory ?? formattedCategory
}
