import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 35,
  height: 35,
}

const SessionExpiredIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='session-expired-icon' fill={fill}>
          <path
            d='M17.4892944,34.7393097 C26.9100315,34.7393097 34.75,26.9207393 34.75,17.5000022 C34.75,8.07926508 26.8886816,0.26069027 17.4679445,0.26069027 C8.06855731,0.26069027 0.25,8.07926508 0.25,17.5000022 C0.25,26.9207393 8.08995097,34.7393097 17.4892944,34.7393097 Z M17.4892944,23.2464541 C12.5973641,23.2464541 8.8162418,24.8913162 7.04319268,26.813946 C4.84288278,24.3359122 3.53979427,21.088844 3.53979427,17.5000022 C3.53979427,9.72417096 9.71346319,3.50775846 17.4679445,3.50775846 C25.2437757,3.50775846 31.4601882,9.72417096 31.4815819,17.5000022 C31.4815819,21.088844 30.1570997,24.3572621 27.9781397,26.813946 C26.2050906,24.8913162 22.4026184,23.2464541 17.4892944,23.2464541 Z M17.4892944,20.4266029 C20.7150127,20.4693465 23.2570962,17.7136326 23.2570962,14.1033971 C23.2570962,10.7068358 20.7150127,7.90837826 17.4892944,7.90837826 C14.2849698,7.90837826 11.7214926,10.7068358 11.7428863,14.1033971 C11.7642362,17.7136326 14.2636199,20.3839031 17.4892944,20.4266029 Z'
            id='My-Account'
          />
        </g>
      </g>
    </svg>
  )
}

export { SessionExpiredIcon }
