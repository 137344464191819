import { CollapsableTitledCard, FancyParagraphTextField } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

const MAX_LENGTH = 250
export const DeliveryInstructions = (props: DeliveryInstructionsProps) => {
  const { t } = useTranslation('checkout')

  return (
    <div data-testid={props.testID} style={{ marginTop: '9px' }}>
      <CollapsableTitledCard
        primaryTitle={t('Delivery Instructions')}
        noPadding={true}
        startCollapsed={false}
        testID={'delivery-instructions-card'}
      >
        <FancyParagraphTextField
          testID={`${props.testID}.paragraph`}
          placeholder={t('Special requests')}
          maxLength={MAX_LENGTH}
          style={{
            width: '100%',
            paddingTop: '5px',
            paddingBottom: '23.6px',
            paddingLeft: '8px',
            paddingRight: '8px',
          }}
          onChange={props.onChange}
        />
      </CollapsableTitledCard>
    </div>
  )
}
