import { getProductDefaultSize } from '@dominos/business/functions/menu/get-product-default-size'

type RecipeIngredient = Bff.Menu.old.RecipeIngredient

export const getDefaultPizzaToppings = (item?: ChangeableProductItem | ProductMenuItem): RecipeIngredient[] => {
  const size = getProductDefaultSize(item) as ProductSize

  if (!size || !size.recipe || !size.recipe.toppings) {
    return []
  }

  return size.recipe.toppings
}
