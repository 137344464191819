import { useMutation } from '@apollo/client'
import { emailLoginMutation, EmailLoginResponse } from '@dominos/business/queries'
import { useState } from 'react'

export interface LoginCredentials {
  email: string
  password: string
  recaptchaToken?: string
  useRecaptchaV3?: boolean
  enableLongLived?: boolean
  orderId?: string
  recaptchaAction?: BffContext.RecaptchaAction
}

export const useEmailLogin = (onSuccessCallBack: (idToken: string, customerId: string) => Promise<void>) => {
  const [useRecaptchaV2, setUseRecaptchaV2] = useState<boolean>(false)
  const [login, { data, loading, error }] = useMutation<EmailLoginResponse, LoginCredentials>(emailLoginMutation)
  const [shouldResetCheckbox, setShouldResetCheckbox] = useState<boolean>(false)

  return {
    data,
    error,
    useRecaptchaV2,
    shouldResetCheckbox,
    loading,

    login: async (variables: LoginCredentials) => {
      let response: EmailLoginResponse | undefined | null
      try {
        const { data: responseTemp } = await login({
          variables,
        })
        response = responseTemp
      } catch {
        response = null
      } finally {
        setShouldResetCheckbox(false)
        if (!!response) {
          if (response.emailLogin.idToken) {
            await onSuccessCallBack(response.emailLogin.idToken, response.emailLogin.id)
          } else if (response.emailLogin.shouldDisplayRecaptchaV2) {
            useRecaptchaV2 ? setShouldResetCheckbox(true) : setUseRecaptchaV2(true)
          }
        }
      }
    },
  }
}
