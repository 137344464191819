import gql from 'graphql-tag'

export const pickupSearchStoreQuery = gql`
  query pickupSearchStoreQuery($latitude: Float, $longitude: Float) {
    pickupSearchStore(latitude: $latitude, longitude: $longitude) {
      name
      storeType
      storeNo
      locations {
        locationId
        distance
        address {
          geoLocation {
            longitude
            latitude
          }
          countryCode
          attributes {
            key
            value
          }
        }
      }
    }
  }
`
