/**
 * Formats item count text as `"n items"`, where `n`
 * is the provided `itemCount` value.
 * @param itemCount (optional) Number of items
 */
export const getItemCountText = (itemCount?: number, t?: (...params: unknown[]) => string) => {
  let count
  if (!itemCount || itemCount < 0) {
    count = 0
  } else {
    count = Math.round(itemCount)
  }

  return t ? t('itemsWithCount', { count }) : `${count} item${count === 0 || count !== 1 ? 's' : ''}`
}
