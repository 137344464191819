import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation, validate } from '@dominos/business/functions/validation'
import { FancyTextField, PasswordInput } from '@dominos/components'

import styles from './password-fields.less'

export type PasswordFieldValues = Record<string, ValidationField>
interface PasswordFieldsProps {
  onPasswordsChanged: (passwords: Record<string, ValidationField>) => void
  onPasswordsValidation: (isValid: boolean) => void
}

export const PasswordFields = (props: PasswordFieldsProps) => {
  const { isRequired, isPasswordValid } = useValidation()
  const { t } = useTranslation('login')
  const [passwordValues, setPasswordValues] = useState<PasswordFieldValues>({
    oldPassword: {
      value: undefined,
      isValid: false,
    },
    newPassword: {
      value: undefined,
      isValid: false,
    },
  })
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    const passwordsValid = Object.values(passwordValues).reduce((l, r) => l && r.isValid, {}) as boolean
    props.onPasswordsValidation(passwordsValid)

    if (passwordsValid) {
      props.onPasswordsChanged(passwordValues)
    }
  }, [passwordValues])

  const onCurrentPasswordChange = (value: string) => {
    const validationResult = validate(value, ...[isRequired, isPasswordValid])
    setCurrentPasswordErrorMessage(validationResult)

    setPasswordValues({
      ...passwordValues,
      oldPassword: {
        value,
        isValid: validationResult == null,
      },
    })
  }

  const handleNewPassword = (value: string) => {
    setPasswordValues({
      ...passwordValues,
      newPassword: {
        ...passwordValues.newPassword,
        value,
        isValid: true,
      },
    })
  }

  const handleNewPasswordValidation = (isValid: boolean) => {
    setPasswordValues({
      ...passwordValues,
      newPassword: {
        ...passwordValues.newPassword,
        isValid,
      },
    })
  }

  return (
    <>
      <p className={styles.subheading}>{t('CurrentPassword', { defaultValue: 'Current Password' })}</p>
      <div className={styles.input}>
        <FancyTextField
          testID={`CurrentPassword.field`}
          placeholder={t('CurrentPassword', { defaultValue: 'Current Password' })}
          onChange={onCurrentPasswordChange}
          errorMessage={currentPasswordErrorMessage && t(currentPasswordErrorMessage)}
          style={{ width: '100%' }}
          secureTextEntry={true}
        />
      </div>

      <p className={styles.subheading}>{t('NewPassword', { defaultValue: 'New Password' })}</p>
      <div className={styles.input}>
        <PasswordInput
          onPasswordChanged={handleNewPassword}
          onPasswordValid={handleNewPasswordValidation}
          passwordPlaceholder={t('NewPassword', { defaultValue: 'New Password' })}
          passwordConfirmPlaceholder={t('ConfirmPasswordLabel', { defaultValue: 'Confirm New Password' })}
        />
      </div>
    </>
  )
}
