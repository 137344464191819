import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'

import { rootActions } from '@dominos/business'
import { useCountryCode, useReport, useSearchAddress, useStoreSelect } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { mapDeliveryAddressWithAdditionalFieldsToStoredCustomerAddress } from './map-address-line-to-stored-customer-address'

// TODO: better way in olo.mobile `helper`?
const isFormValid = (address: AdditionalFields) => {
  // eslint-disable-next-line guard-for-in
  for (const fieldName in address) {
    const field = address[fieldName as keyof AdditionalFields]

    if (field && !field.isValid) {
      return false
    }
  }

  return true
}

export const useSelectedAddress = () => {
  const dispatch = useDispatch()
  const searchAddress = useSearchAddress()
  const { reportDeliveryAddressSelected, reportStoreSelected } = useReport()
  const { getStore, store } = useStoreSelect()
  const countryCode = useCountryCode()
  const { t } = useTranslation('delivery-address')

  const [additionalFields, setAdditionalFields] = useState<AdditionalFields | undefined>()
  const [isAddressValid, setIsAddressValid] = useState<boolean>(false)

  const setStore = (store: Bff.Stores.Store) => dispatch(rootActions.storeSelected(store, true))

  useEffect(() => {
    if (store) {
      setStore(store)
      reportStoreSelected(store)
      navigate(NavigationConstants.timedOrder)
    }
  }, [store])

  const onInit: AdditionalFieldsOnInit = (initialState) => {
    // will only set the `isValid` state for the entire address
    // individual address components will still have their own `isValue`
    setAdditionalFields(initialState)
    setIsAddressValid(isFormValid(initialState))
  }

  const onChange: AdditionalFieldsOnChange = (updatedFields) => {
    const fields = { ...additionalFields, ...updatedFields }

    setAdditionalFields(fields)
    setIsAddressValid(isFormValid(fields))
  }

  /**
   * Ensure the calling component handles when `searchAddress` or `countryCode` is `undefined`.
   *
   * @example
   * ```
   * const { address } = useSelectedAddress()
   * const countryCode = useCountryCode()
   *
   * useEffect(() => {
   *  if (!address || !countryCode) {
   *    navigate(-1)
   *  }
   * }, [address])
   * ```
   */
  const onSelectAddress = () => {
    // should be handled in calling component
    if (!searchAddress || !countryCode) {
      return
    }

    const customerAddress = mapDeliveryAddressWithAdditionalFieldsToStoredCustomerAddress(
      t,
      searchAddress,
      additionalFields,
      countryCode,
    )

    // setup store
    getStore(searchAddress.storeNo)
    dispatch(rootActions.selectServiceMethod('Delivery'))

    // add customer address
    dispatch(rootActions.addCustomerAddressToOrder(customerAddress))

    reportDeliveryAddressSelected()
  }

  return {
    onInit,
    onChange,
    onSelectAddress,
    isAddressValid,
    address: searchAddress,
  }
}
