import { FancyParagraphTextField } from '@dominos/components'
import { useBreakpoints } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './text-feedback.less'

const MAX_CHARS = 260

export const TextFeedback = ({
  question,
  onEdit,
  error,
}: {
  question: Bff.Feedback.FeedbackQuestion
  onEdit: (val: Bff.Feedback.FeedbackQuestionResponse) => void
  error?: string
}) => {
  const [comment, setComment] = useState('')
  const [lengthWarning, setLengthWarning] = useState('')
  const { isNotMobile } = useBreakpoints()
  const { t } = useTranslation('validation')
  const { t: tTracker } = useTranslation('tracker')

  const handleEdit = (updatedComment: string) => {
    if (updatedComment.length >= MAX_CHARS) {
      setLengthWarning(
        t('MaximumLengthError', {
          length: MAX_CHARS,
          defaultValue: 'Exceeded max length of {{length}}',
        }),
      )
    } else {
      setLengthWarning('')
      setComment(updatedComment)
    }
  }

  useEffect(() => {
    if (comment) {
      onEdit({
        comment,
        externalQuestionId: question.externalQuestionId ?? '',
        questionId: question.questionId,
      })
    }
  }, [comment])

  const textAreaStyles = {
    wrapper: {
      width: isNotMobile ? '80%' : '100%',
    },
    textArea: {
      height: '100px',
    },
  }

  return (
    <div className={styles.comment}>
      <p id={question.questionId}>{question.questionText}</p>
      <FancyParagraphTextField
        style={textAreaStyles.wrapper}
        textFieldStyle={textAreaStyles.textArea}
        testID={`${question.questionId}-text-feedback`}
        placeholder={tTracker('OrderFeedbackTextBoxPrompt', { defaultValue: 'Tell us here...' })}
        maxLength={MAX_CHARS}
        rows={3}
        accessibilityLabel={question.questionId}
        onChange={handleEdit}
      />
      {error ? <p className={styles.validationError}>{error}</p> : null}
      {lengthWarning ? <p className={styles.validationError}>{lengthWarning}</p> : null}
    </div>
  )
}
