import React from 'react'
import css from './loyalty-redeem.less'
import RedeemLoyaltyPoints from './redeem-loyalty-points'
import ViewLoyaltyPoints from './view-loyalty-points'

const LoyaltyRedeem = ({
  freePizzas,
  pointsToNextFreePizza,
  onRedeem,
  loading,
  redeemable,
}: {
  freePizzas: number
  pointsToNextFreePizza?: number
  onRedeem: () => void
  loading: boolean
  redeemable?: boolean
}) => (
  <div className={css.wrapper}>
    {redeemable ? (
      <RedeemLoyaltyPoints freePizzas={freePizzas} onRedeem={onRedeem} loading={loading} />
    ) : (
      <ViewLoyaltyPoints pointsToNextFreePizza={pointsToNextFreePizza} />
    )}
  </div>
)

export { LoyaltyRedeem }
