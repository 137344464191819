import React, { useMemo } from 'react'

import { CollapsableTitledCard, HalfCard, ProductPricingDetails } from '@dominos/components'
import { getProductsByCode } from '../functions'

import css from './half-header.less'

const PORTION_MOBILE_TOP = 30

export const HalfHeader = ({
  items,
  formattedPrice,
  formattedPromoPrice,
  testID,
  isMobileLayout,
  halfLeft,
  halfRight,
  size,
  onHalfProductSet,
  onHalfCustomize,
}: HalfHeaderProps) => {
  const onEditPress = (side: HalfSides) => () => onHalfCustomize(side)
  const onPress = (side: HalfSides) => onHalfProductSet(side)

  const products = useMemo(() => getProductsByCode(items, size.swaps?.portionProductCodes), [size, items])

  return (
    <div
      data-testid={`${testID}.half-header`}
      className={css.headerContainer}
      style={{ marginTop: isMobileLayout ? PORTION_MOBILE_TOP : 0 }}
    >
      <div className={css.headerDetailBox}>
        <div data-testid={`${testID}.half-header-title`} className={css.headerTitle}>
          {size.media?.name}
        </div>
        <div className={css.headerPrice}>
          <ProductPricingDetails
            testID={`${testID}.subtitle`}
            price={formattedPrice}
            promoPrice={formattedPromoPrice}
            showPrice={true}
            showEnergyDividerForPromo={false}
          />
        </div>
      </div>

      <CollapsableTitledCard
        testID={`product-halfs`}
        primaryTitle={''}
        static={true}
        startCollapsed={false}
        noPadding={true}
      >
        <div className={css.halvesContainer}>
          <div className={css.halfLeftContainer}>
            <HalfCard
              side={'left'}
              onProductChange={onPress('left')}
              products={products}
              item={halfLeft}
              onEditPress={onEditPress('left')}
            />
          </div>
          <div className={css.halfRightContainer}>
            <HalfCard
              side={'right'}
              onProductChange={onPress('right')}
              products={products}
              item={halfRight}
              onEditPress={onEditPress('right')}
            />
          </div>
        </div>
      </CollapsableTitledCard>
    </div>
  )
}
