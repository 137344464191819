import { ActionButton } from '@dominos/components/buttons'
import { GoogleIcon } from '@dominos/res/images/icons/components'
import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'
import { TFunction } from 'i18next'
import React from 'react'
import { CustomLoginButtonProps, RequestedFields } from './google.interface'
import css from './google.less'
import styles from './styles'

const getCreateAccountSceneProps = (
  redirectTo: string | undefined,
  translateFn: TFunction,
  requestedFields: RequestedFields,
) =>
  ({
    redirectTo,
    title: translateFn('signInGoogleTitle', { defaultValue: 'Sign in with Google' }),
    description: translateFn('signInGoogleDescription', {
      defaultValue: 'Almost there! We just need a few more details to create your account',
    }),
    subtitle: translateFn('signInGoogleSubtitle', { defaultValue: 'Sign in with Google' }),
    name: requestedFields.name,
    email: requestedFields.email,
    identityProvider: {
      identityProviderId: requestedFields.id,
      externalProviders: [
        {
          providerType: 'google',
          providerId: requestedFields.id,
        },
      ],
    },
  } as CreateAccountSceneProps)

const createLoginButton = (loading: boolean, label: string) => (renderProps: CustomLoginButtonProps) =>
  (
    <ActionButton
      testID='google-login-button'
      loading={loading}
      disabled={renderProps.disabled}
      onPress={renderProps.onClick}
      containerStyle={styles.button}
      textFontWeight='bold'
    >
      <div className={css.googleIcon}>
        <GoogleIcon fill={'#ffffff'} />
      </div>
      <label className={css.buttonLabel}>{label}</label>
    </ActionButton>
  )

export { getCreateAccountSceneProps, createLoginButton }
