import { ApolloLink } from '@apollo/client'

export const resetIdleTimerLink = new ApolloLink((operation, forward) => {
  /**
   * Skip reset/clear timer for each query to keep the session alive
   * minimal customerDetailsQuery query is made every 10 minutes
   */
  if (operation.operationName === 'keepTokenAliveQuery') {
    return forward(operation)
  }

  /**
   * Reset idle timer on each graph call except "logout"
   */
  if (window.__idle_timer__) {
    if (operation.operationName !== 'logout') {
      window.__idle_timer__.reset()
    } else {
      window.__idle_timer__.clear()
      window.__idle_timer__ = undefined
    }
  }

  if (window.__polling_timer__) {
    if (operation.operationName !== 'logout') {
      window.__polling_timer__.reset()
    } else {
      window.__polling_timer__.clear()
      window.__polling_timer__ = undefined
    }
  }

  return forward(operation)
})
