import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { deviceId } from '@dominos/business/functions/native-app'
import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { ApplicationConfiguration } from '@dominos/interfaces'
import { SHA256 } from 'crypto-js'
import { AnalyticSender, basketToEcommerceCart } from './analytics'
import { getOrderId } from './helpers'
import { SecurityEventNames, SecuritySender } from './security'
import { isKioskOrderType } from '@dominos/business/functions/common'
import { DeliveryAddressSearchVersionType } from '../address'

const { CURRENCY_CODE } = getCountryConfig()

export const reportPage = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  { environment, language, countryCode }: ApplicationConfiguration,
  basket?: Basket,
  menu?: Menu,
  currentStore?: Bff.Stores.Store,
  currentOrder?: CurrentOrderDetailsReducerProps,
  deliveryAddressSearchVersion?: DeliveryAddressSearchVersionType,
) => {
  const orderId = getOrderId(currentOrder)

  const reportTrackerView = (
    eventCode: SecurityEventNames,
    payload: {
      email?: string
      customerId?: string
      storeNo?: number
      serviceMethod?: BffContext.ServiceMethods
    },
  ) => {
    sendSecurity(eventCode, { ...payload, 'Order ID': orderId })
  }

  const reportPageLoad = (url: string, userId?: string, userEmail?: string, userPhone?: string) => {
    const { basketHeaderData, paymentMethod } = currentOrder || {}

    sendAnalytics('Page Loaded', {
      environment: environment === 'PROD' ? 'prod' : 'staging',
      'Full Page Url': url,
      'Page Title': document.title,
      'App Device Id': deviceId,
      'User Id': userId,
      'User Hashed Email': userEmail ? SHA256(userEmail.toLowerCase()).toString() : undefined,
      'User Hashed Phone': userPhone ? SHA256(userPhone.toLowerCase()).toString() : undefined,
      Market: countryCode!,
      Platform: isKioskOrderType() ? 'kiosk' : 'web',
      'UI Language': language.toUpperCase() as `${Uppercase<BffContext.Languages>}`,
      'Store Name': currentStore?.media?.name,
      'Store Id': currentStore?.storeNo,
      'Service Method': basketHeaderData?.serviceMethod,
      'Service Method Timing': basketHeaderData?.time ? new Date(basketHeaderData?.time).getTime() : 'ASAP',
      'Payment Method': paymentMethod?.paymentMethod,
      'Service Method Subtype': basketHeaderData?.serviceMethodSubType,
      'Payment Provider': paymentMethod?.providerCode,
      'Ecommerce Cart': basketToEcommerceCart(basket, menu?.pages, CURRENCY_CODE),
      'Page Timestamp': new Date().getTime(),
      deliveryAddressSearchVersion: deliveryAddressSearchVersion,
    })
  }

  return {
    reportPageLoad,
    reportTrackerView,
  }
}
