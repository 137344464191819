import React from 'react'
import css from './adyen-text-field.less'

interface AdyenTextFieldProps extends BaseProps {
  id: string
  name: string
  label: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

export const AdyenTextField = ({ id, name, label, onChange, placeholder, testID }: AdyenTextFieldProps) => (
  <div className={css['adyen-input']} data-testid={testID}>
    <label htmlFor={id}>
      <span>{label}</span>
      <div>
        <input placeholder={placeholder} id={id} name={name} onChange={onChange} />
      </div>
    </label>
  </div>
)
