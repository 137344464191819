import { useMemo } from 'react'
import { useMenu } from '@dominos/hooks-and-hocs/menu'

/**
 * Hook to get products for portions
 *
 * @param portions Portions to get products for
 * @returns portionProducts Array of products
 */
export const usePortionProducts = (portions: BasketLineData[]): ChangeableProductItem[] => {
  const { itemsByCode } = useMenu()

  return useMemo(
    () =>
      portions.map((portion) => portion.productCode || '').map((code) => itemsByCode?.[code] as ChangeableProductItem),
    [portions],
  )
}
