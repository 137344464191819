import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { MDTargetBlankAnchor } from '@dominos/components'
import { DetailRow } from '@dominos/components/basket/detail-row'
import { useCountryCode, usePaymentsAvailableAtStore } from '@dominos/hooks-and-hocs'
import Markdown from 'markdown-to-jsx'

import { getRoundUpForCharityImages, isTrackerBannerHalfWidth } from '../get-round-up-for-charity-images'
import css from './round-up-for-charity-tracker.less'

interface RoundUpForCharityProps {
  testID: string
  amount?: number
  payments?: Bff.Orders.OrderDetails.Payment[]
  storeNo?: number
  serviceMethod?: BffContext.ServiceMethods
  serviceMethodSubType?: Bff.Stores.ServiceMethodSubTypes
  orderTime?: string
}

const RoundUpForCharityTracker = (props: RoundUpForCharityProps) => {
  const { t } = useTranslation('tracker')
  const countryCode = useCountryCode()

  const testID = `${props.testID}.round-up-for-charity`
  const withRoundUp = !!props.amount
  const image = getRoundUpForCharityImages(countryCode, 'trackerBanner')
  const isHalfWidth = isTrackerBannerHalfWidth(countryCode, 'isTrackerBannerHalfWidth')

  return (
    <section>
      <div data-testid={testID} className={css.container}>
        {withRoundUp && (
          <DetailRow testID={`${testID}.value`} label={t('roundUpForCharityLabel')} price={props.amount} />
        )}
        <div className={css.body}>
          {image && <img data-testid={`${testID}.image`} className={isHalfWidth ? css.halfWidth : ''} src={image} />}
          <p className={css.title}>{t(`roundUpForCharityOpt${withRoundUp ? 'In' : 'Out'}Title`)}</p>
          <Markdown options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
            {t(`roundUpForCharityOpt${withRoundUp ? 'In' : 'Out'}Description`)}
          </Markdown>
        </div>
      </div>
    </section>
  )
}

export const RoundUpForCharityTrackerOptIn = (props: RoundUpForCharityProps) => (
  <RoundUpForCharityTracker testID={props.testID} amount={props.amount} />
)

export const RoundUpForCharityTrackerOptOut = (props: RoundUpForCharityProps) => {
  const { paymentSettings, loading } = usePaymentsAvailableAtStore(
    props.storeNo,
    props.serviceMethod,
    props.serviceMethodSubType,
    props.orderTime,
  )

  const display = useMemo(
    () =>
      !loading &&
      paymentSettings &&
      props?.payments?.some(
        (p) => paymentSettings?.find((s) => s.paymentMethod === p.media.displayName)?.donationEnabled,
      ),
    [loading, paymentSettings],
  )

  return display ? <RoundUpForCharityTracker testID={props.testID} /> : null
}
