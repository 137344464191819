import { ApolloError } from '@apollo/client'

export const getErrorMessageFromApolloError = (error?: ApolloError): string | null => {
  if (!error) return null

  if (error.graphQLErrors?.length > 0) {
    return error.graphQLErrors
      .reduce<string[]>((result, { message }) => {
        result.push(message)

        return result
      }, [])
      .join(' ')
  }

  return error.message
}
