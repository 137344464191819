/**
 * Renders a slider element with stepped labels.
 * Automatically allocating the slide range and steps
 * based on label inputs.
 * @example
 * ```tsx
 * <AdvancedSlider
 *  labels={[{ title:'Label One' }, { title:'Label Two', subtitle:'Subtitle' }]}
 *  initialValue={1}
 *  onSelection={(value) => handleChangeWithValue(value)}
 * />
 * ```
 * @author Mitch Pierias
 */
export const AdvancedSlider = (props: AdvancedSliderProps) => null
