const defaultValues: Record<string, string> = {
  LoyaltyTitle: 'Join Domino’s Rewards to earn points toward free pizza with every eligible order!',
  'LoyaltyT&C': `By checking this, I'm choosing to join Domino’s Rewards and agree with the [Terms and Conditions](https://www.dominosrewards.com.au/termsandconditions) of the program`,
  JoinLoyaltySwitch: 'Join Domino’s Rewards!',
  LoyaltyUpdatesText:
    'Yes, I would like to receive newsletters and updates & offers regarding the Domino’s Rewards program',
  ReceiveUpdatesSwitch: 'Receive Updates & Offers',
  AvailablePizzasLabel: 'Free Pizzas Available',
  RedeemLoyaltyButton: 'Redeem',
}

export const getLoyaltyTranslations = (t: import('i18next').TFunction): Record<string, string> =>
  Object.keys(defaultValues).reduce((o, key) => ({ ...o, [key]: t(key, { defaultValue: defaultValues[key] }) }), {})
