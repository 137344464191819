import {
  Configuration,
  EventCallbackFunction,
  EventType,
  PopupRequest,
  PublicClientApplication,
} from '@azure/msal-browser'

interface IB2BAuth {
  onCallback: EventCallbackFunction
  instance?: PublicClientApplication
  init(
    msalConfig: Configuration,
    loginConfig: PopupRequest,
    loginCallback: (loggedIn: boolean) => void,
  ): Promise<string | undefined>
  startAuthentication(): void
  available: boolean
  loggedInCallback?: (loggedIn: boolean) => void
  loginConfig?: PopupRequest
  getToken(): Promise<string | undefined>
  currentToken?: string
  logout(): void
  acquireToken(): void
  wasLoggedOut: boolean
}

export const B2BAuth: IB2BAuth = {
  init(msalConfig, loginConfig, loggedInCallback) {
    this.loggedInCallback = loggedInCallback
    this.loginConfig = loginConfig
    this.instance = new PublicClientApplication(msalConfig)
    this.instance.addEventCallback(this.onCallback.bind(this))
    this.wasLoggedOut = false

    return this.instance.initialize().then(() => this.getToken())
  },
  onCallback(event) {
    switch (event.eventType) {
      case EventType.ACQUIRE_TOKEN_SUCCESS:
        this.loggedInCallback?.(true)
        break
      case EventType.LOGOUT_SUCCESS:
      case EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE:
      case EventType.LOGIN_FAILURE:
        this.loggedInCallback?.(false)
    }
  },
  get available() {
    return !!this.instance
  },
  startAuthentication() {
    if (!this.loginConfig) return

    this.instance?.loginPopup(this.loginConfig).then(() => {
      this.getToken()
    })
  },
  async getToken() {
    if (!this.instance || !this.loginConfig) return
    const accounts = this.instance.getAllAccounts()
    if (!accounts?.[0]) return

    return this.instance
      .acquireTokenSilent({
        scopes: this.loginConfig.scopes,
        account: accounts[0],
      })
      .then(({ accessToken }) => {
        this.currentToken = accessToken
        this.wasLoggedOut = false

        const activeAccount = accounts[0]
        this.instance!.setActiveAccount(activeAccount)

        return accessToken
      })
  },
  logout() {
    this.wasLoggedOut = true
    this.instance?.logout({ onRedirectNavigate: () => false })
  },
  async acquireToken() {
    if (this.wasLoggedOut && this.loginConfig) {
      this.loginConfig.prompt = 'select_account'
    }

    if (!this.instance || !this.loginConfig) return

    const activeAccount = await this.instance.getActiveAccount()

    if (activeAccount != null) {
      const account = await this.instance.getAccountByUsername(activeAccount!.username)

      if (account != null) {
        await this.instance
          .acquireTokenSilent({
            scopes: this.loginConfig.scopes,
            account: account!,
          })
          .then(({ accessToken }) => {
            this.currentToken = accessToken
            this.wasLoggedOut = false

            return accessToken
          })

        return
      }

      return
    }

    this.startAuthentication()
  },
  wasLoggedOut: false,
}
