import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 26,
  height: 25,
}

const GMOLinPayIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-28.000000, -368.000000)' fill={fill}>
          <g transform='translate(12.000000, 358.000000)'>
            <g transform='translate(16.240106, 10.000000)'>
              <path
                d='M8.327.001H7.2c-.173 0-.314.14-.314.314v7.014c0 .173.14.314.314.314h1.127c.173 0 .313-.14.313-.314V.315C8.64.142 8.5 0 8.327 0M5.602 5.889H2.565V.315c0-.173-.14-.314-.313-.314H1.124c-.173 0-.313.14-.313.314v7.014c0 .173.14.314.313.314h4.478c.174 0 .314-.14.314-.314V6.202c0-.173-.14-.313-.314-.313M22.357 1.755c.173 0 .313-.14.313-.313V.315c0-.173-.14-.314-.313-.314h-4.478c-.173 0-.313.14-.313.314v7.014c0 .173.14.314.313.314h4.478c.173 0 .313-.14.313-.314V6.202c0-.173-.14-.313-.313-.313h-3.038v-1.19h3.038c.173 0 .313-.14.313-.313V3.258c0-.172-.14-.313-.313-.313h-3.038v-1.19h3.038zM16.062.001h-1.127c-.173 0-.313.14-.313.314v4.059L11.672.155c-.084-.12-.209-.154-.347-.154-.018-.003-.036 0-.054 0h-1.128c-.172 0-.313.14-.313.314v7.014c0 .173.14.314.313.314h1.128c.173 0 .313-.14.313-.314V3.09l3.076 4.398c.011.016.023.03.036.044.053.062.13.105.217.11h1.149c.173 0 .314-.14.314-.313V.315c0-.173-.14-.314-.314-.314M18.002 22.509H16.62c-.1 0-.17-.102-.133-.195l.728-1.833-1.963-4.786c-.038-.093.031-.196.132-.196h1.382c.087 0 .166.052.198.133l1.106 2.695 1.069-2.693c.032-.082.111-.135.2-.135h1.381c.1 0 .17.101.132.195l-2.65 6.68c-.033.081-.112.135-.2.135m-3.748-1.96h-1.342c-.079 0-.143-.065-.143-.143v-.2c-.347.318-.81.479-1.376.479-1.355 0-2.417-1.169-2.417-2.661 0-1.493 1.062-2.662 2.417-2.662.567 0 1.029.161 1.376.48v-.2c0-.08.064-.143.143-.143h1.342c.079 0 .143.064.143.142v4.765c0 .078-.064.142-.143.142m-8.142-2.15h-.946v1.909c0 .133-.108.241-.241.241H3.682c-.133 0-.24-.108-.24-.241v-6.522c0-.134.107-.241.24-.241h2.43c1.389 0 2.476 1.065 2.476 2.426 0 1.361-1.087 2.427-2.476 2.427m16.627-8.199H.859c-.475 0-.859.384-.859.858v13.086c0 .474.384.858.858.858h21.88c.475 0 .86-.384.86-.858V11.056c0-.474-.385-.858-.86-.858'
                transform='translate(-31 -511) translate(7.5 208) translate(8.5 290) translate(15 13) translate(1.201)'
              />
              <path
                d='M11.686 16.911c-.648 0-1.083.447-1.083 1.113 0 .665.435 1.112 1.083 1.112.648 0 1.083-.447 1.083-1.112 0-.666-.435-1.113-1.083-1.113M6.112 15.17h-.946v1.6h.946c.428 0 .75-.344.75-.8 0-.455-.322-.8-.75-.8'
                transform='translate(-31 -511) translate(7.5 208) translate(8.5 290) translate(15 13) translate(1.201)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { GMOLinPayIcon }
