/* eslint-disable @typescript-eslint/no-namespace */
import { Config } from '../common/get-config'
import { LoadScriptProps } from '../common/load-script'

import { processScriptForOneTrust } from '../common/process-script-for-one-trust'

export const setupRokt = (applicationConfig: Config, loadScript: (props: LoadScriptProps) => void) => {
  if (applicationConfig.ROKT_TAG_ID !== '' && applicationConfig.ROKT_TAG_ID !== 'null') {
    window._ROKT_ = 'rokt'
    window.rokt = {
      id: applicationConfig.ROKT_TAG_ID,
      lc: [
        (rokt) =>
          rokt.init({
            skipInitialSelection: true,
            sandbox: applicationConfig.ROKT_SANDBOX === 'true',
          }),
        (rokt) => (window.roktClient = rokt),
      ],
      it: new Date(),
    }

    loadScript({
      id: 'rokt',
      setupScriptFunc: (script) => {
        processScriptForOneTrust(script, '4', applicationConfig.ONE_TRUST_KEY)
        script.text = `(function (w, d, s) {
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s)
            j.async = true
            j.src = 'https://apps.rokt.com/wsdk/integrations/snippet.js'
            f.parentNode.insertBefore(j, f)
          })(window, document, 'script')`
        script.crossOrigin = 'anonymous'
      },
    })
  }
}
