import gql from 'graphql-tag'

export const offersQuery = gql`
  query offersQuery(
    $storeNo: Int!
    $tradingTime: String
    $serviceMethod: ServiceMethodEnum!
    $orderId: String
    $deliveryAddress: DeliveryAddressInput
    $layouts: [Layouts]
  ) {
    offers(
      storeNo: $storeNo
      tradingTime: $tradingTime
      serviceMethod: $serviceMethod
      orderId: $orderId
      deliveryAddress: $deliveryAddress
      layouts: $layouts
    ) {
      offerId
      source
      name
      disclaimers {
        code
        text
      }
      isPretotypeOffer
      items {
        id
        name
        price
        energy
        image {
          url
          altText
        }
        placementCode
        linkedItem {
          itemType
          itemCode
          subItemCode
          componentType
        }
      }
      layout
      locations {
        locationType
        locationCode
        showBanner
        showPopUp
        autoPopUp
      }
      media {
        name
        description
        banner {
          url
          altText
        }
        popUp {
          url
          altText
        }
        tile {
          url
          altText
        }
      }
    }
  }
`
