declare global {
  interface Window {
    nativeAppPlatform?: string
  }
}

export const appPlatform = () => {
  const { nativeAppPlatform } = window

  return (nativeAppPlatform ?? 'web') as BffContext.ApplicationTypes
}
