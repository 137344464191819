import { FosConstants } from '@dominos/business/functions/fos/fos-constants'
import { CustomerInitialState } from 'packages/business/reducers/customer.reducer'
import { OrderHistoryProps } from 'packages/interfaces/customer'

export const addOrderToLocalHistory = (
  state: CustomerInitialState,
  orderHistory: OrderHistoryProps,
): CustomerInitialState => {
  if (!state.localOrderHistory.some((x) => x.orderId === orderHistory.orderId)) {
    const minCreatedOn = Date.now() - FosConstants.LocalOrderHistoryTtlInMilliseconds

    return {
      ...state,
      localOrderHistory: [...state.localOrderHistory.filter((x) => x.createdOn > minCreatedOn), orderHistory],
    }
  }

  return state
}
