import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 40,
  height: 40,
}

const StoreDetailsDelivery = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 40 40'>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='store-details-icon'>
          <circle id='Oval' fill='#007AAF' cx='20' cy='20' r='20'></circle>
          <g
            id='store-details-icon-delivery'
            transform='translate(8.857758, 12.006115)'
            fill='#FFFFFF'
            fillRule='nonzero'
          >
            <g id='eBike'>
              <path
                d='M22.9697268,14.2597673 C22.494374,12.6473554 21.000408,11.4884344 19.2008581,11.4884344 C18.6745746,11.4884344 18.1822449,11.5892101 17.7408459,11.7571697 C17.8596841,11.270087 17.9275916,10.7662083 17.9275916,10.2455336 C17.9275916,8.9690409 17.5371233,7.79332394 16.8920016,6.81915844 C17.0108398,6.83595439 17.129678,6.85275035 17.2654931,6.85275035 L17.9615454,6.85275035 L17.9615454,3.107252 L17.2654931,3.107252 C16.382695,3.107252 15.6526889,3.69511049 15.4319894,4.48452043 L13.2419711,4.48452043 C12.953364,4.48452043 12.7326645,4.70286789 12.7326645,4.98839915 L12.7326645,4.98839915 C12.7326645,5.27393042 12.953364,5.49227784 13.2419711,5.49227784 L15.4150125,5.49227784 C15.4489663,5.62664549 15.4998969,5.76101315 15.5678045,5.87858485 L15.5678045,13.1176422 C15.5678045,13.6383169 15.1433823,14.0750118 14.6170989,14.0750118 L12.9363872,14.0750118 C10.271016,14.0750118 10.4068311,11.5388223 10.3559005,10.2119417 L5.24585777,10.2119417 C3.46328475,10.2119417 0.797913642,12.4793958 0.0848844302,15.4186883 L1.86745746,15.4186883 L2.24094895,15.4186883 L3.85375314,15.4186883 L6.65493932,15.4186883 L8.26774348,15.4186883 L8.65821187,15.4186883 L14.888729,15.4186883 L16.3996719,15.4186883 C16.3487412,15.6370357 16.3317643,15.8553832 16.3317643,16.0737306 C16.3317643,17.7365303 17.6899153,19.0970028 19.3876038,19.0970028 C21.0852924,19.0970028 22.4434433,17.7533263 22.4434433,16.0737306 C22.4434433,15.3850964 22.2057669,14.763646 21.8152985,14.2597673 L22.9697268,14.2597673 L22.9697268,14.2597673 Z M19.3706269,17.5349788 C18.5727133,17.5349788 17.9106147,16.8967325 17.9106147,16.0905266 C17.9106147,15.2843206 18.5557364,14.6460743 19.3706269,14.6460743 C20.1855175,14.6460743 20.8306391,15.2843206 20.8306391,16.0905266 C20.8306391,16.8967325 20.1855175,17.5349788 19.3706269,17.5349788 L19.3706269,17.5349788 Z'
                id='Shape'
              ></path>
              <path
                d='M1.06954382,8.16558198 C1.06954382,8.73918619 1.54282254,9.20418427 2.11675552,9.20418427 L9.30868878,9.20418427 C9.88704781,9.20418427 10.3559005,8.73680548 10.3559005,8.16558198 L10.3559005,1.0721942 C10.3559005,0.498589997 9.88262177,0.0335919135 9.30868878,0.0335919135 L2.11675552,0.0335919135 C1.53839647,0.0335919135 1.06954382,0.500970709 1.06954382,1.0721942 L1.06954382,8.16558198 Z M6.39925566,2.77409149 C6.17365825,2.99728463 6.17365825,3.36042871 6.39925566,3.58362184 C6.62485308,3.80577445 6.99190901,3.80577445 7.21698056,3.58362184 C7.44205211,3.36042871 7.44205211,2.99728463 7.21698056,2.77409149 C6.99190901,2.55141862 6.62485308,2.55141862 6.39925566,2.77409149 Z M4.65652878,3.11590476 L6.53913658,1.25284038 C6.64956888,1.143585 6.82783816,1.143585 6.93827047,1.25284038 L8.75567062,3.05139206 C8.86610292,3.16012717 8.86610292,3.33701684 8.75567062,3.44627222 L6.87306282,5.3093366 L4.65652878,3.11590476 Z M2.96063553,5.32618756 C2.73503811,5.54886043 2.73503811,5.91252477 2.96063553,6.13519764 C3.18570707,6.35735025 3.55328888,6.35735025 3.77836042,6.13467738 C4.0029061,5.91200451 4.00343197,5.54886043 3.77888629,5.32618756 C3.55328888,5.10299442 3.18570707,5.10299442 2.96063553,5.32618756 Z M4.54402438,5.35116022 C4.3194787,5.57383309 4.3194787,5.9364569 4.54402438,6.16069056 C4.76909593,6.38232291 5.1372036,6.38232291 5.36174928,6.15965004 C5.58734669,5.9364569 5.58734669,5.57383309 5.36174928,5.35116022 C5.1372036,5.12796708 4.76909593,5.12796708 4.54402438,5.35116022 Z M6.39876117,5.71586508 L4.37679832,7.71679935 C4.26689189,7.82553447 4.08809674,7.82553447 3.97766444,7.71679935 L2.15973841,5.91824767 C2.04983198,5.80951255 2.04983198,5.63210262 2.15973841,5.52336751 L4.18170126,3.52243324 L6.39876117,5.71586508 Z'
                id='Shape'
              ></path>
              <path
                d='M6.58703179,16.4432416 C6.34935537,16.9303244 5.85702569,17.2662435 5.26283465,17.2662435 C4.66864364,17.2662435 4.17631396,16.9303244 3.93863755,16.4432416 L2.27490273,16.4432416 C2.56350979,17.8205101 3.78584557,18.8450635 5.26283465,18.8450635 C6.73982376,18.8450635 7.96215954,17.8037141 8.2507666,16.4432416 L6.58703179,16.4432416 L6.58703179,16.4432416 Z'
                id='Shape'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { StoreDetailsDelivery }
