import { rootActions } from '@dominos/business'
import { DeliveryAddressListContainer, SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { useDeliveryAddresses, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

export const DeliveryListScene = () => {
  const dispatch = useDispatch()
  const { reportDeliveryAddressSelected } = useReport()
  const { t } = useTranslation('delivery-address')
  const [autoCompleteDeliveryEnabled] = useFeatures('AutoCompleteDeliveryAddress')

  const newAddressItem: AddressLine = {
    icon: 'newAddressIcon',
    name: '',
    address: t('Add New Address'),
    addressId: '',
    suffix: undefined,
    uid: 0,
  }

  const addresses = useDeliveryAddresses()

  useEffect(() => {
    dispatch(rootActions.resetCurrentStore())
  }, [])

  const onDeliveryLinePress = useCallback(
    (addressLine: AddressLine) => {
      switch (addressLine.icon) {
        case 'newAddressIcon':
          dispatch(rootActions.resetAutoCompleteScenario())
          navigate(
            autoCompleteDeliveryEnabled
              ? NavigationConstants.deliveryAddressAutoComplete
              : NavigationConstants.deliveryAddress,
          )
          break
        case 'recentIcon':
        case 'savedIcon':
          dispatch(rootActions.addCustomerAddressToOrder(addressLine.additionalData))
          dispatch(rootActions.selectServiceMethod('Delivery'))
          reportDeliveryAddressSelected()
          navigate(NavigationConstants.timedOrder)
          break
      }
    },
    [autoCompleteDeliveryEnabled],
  )

  return (
    <StackNavigationBase testID='delivery-list-scene' header={<SimpleNavigationTitle showAccount showBackButton />}>
      <div style={{ margin: '8px 8px 40px', display: 'flex', justifyContent: 'center' }}>
        <DeliveryAddressListContainer addresses={[...addresses, newAddressItem]} onPress={onDeliveryLinePress} />
      </div>
    </StackNavigationBase>
  )
}
