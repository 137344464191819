import { DEFAULT_LIGHT_COLOUR, DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const FRMedium = ({ color }: SizeSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR
  const selectedColorLight = color ? color : DEFAULT_LIGHT_COLOUR

  return (
    <svg width='70px' height='70px' viewBox='0 0 70 70' version='1.1'>
      <defs>
        <path
          d='M35,18.7158203 C43.9935041,18.7158203 51.2841797,26.0064959 51.2841797,35 C51.2841797,43.9935041 43.9935041,51.2841797 35,51.2841797 C26.0064959,51.2841797 18.7158203,43.9935041 18.7158203,35 C18.7158203,26.0064959 26.0064959,18.7158203 35,18.7158203 Z M35,21.2155561 C27.3870619,21.2155561 21.2155561,27.3870619 21.2155561,35 C21.2155561,42.6129381 27.3870619,48.7844439 35,48.7844439 C42.6129381,48.7844439 48.7844439,42.6129381 48.7844439,35 C48.7844439,27.3870619 42.6129381,21.2155561 35,21.2155561 Z'
          id='path-fr-pizza-medium'
        />
      </defs>
      <g id='UI/Symbols/Product-Size/FR/fr-pizza-medium' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <mask id='mask-fr-pizza-medium' fill='white'>
          <use href='#path-fr-pizza-medium' />
        </mask>
        <use id='M-2' fill={selectedColor} href='#path-fr-pizza-medium' />
        <rect
          id='Colour'
          fill={selectedColorLight}
          mask='url(#mask-fr-pizza-medium)'
          x='0'
          y='0'
          width='70'
          height='70'
        />
      </g>
    </svg>
  )
}
