import React, { useEffect, useRef, useState } from 'react'
import { ErrorContext } from './context'
import { ErrorDisplay } from './components'
import { useTranslation } from 'react-i18next'
import { DisplayableError, ErrorDefinition, NotifiableEvent } from './interfaces'
import { useGenericErrorHandlers, useNetworkErrorHandlers } from './handlers'
import { getErrorDefinition, getErrorHandlers, getErrorVariables } from './helpers'
import { useReport } from '@dominos/hooks-and-hocs/logging'

export const ErrorNotifier = ({
  children,
  namespace = 'validation',
}: {
  children: React.ReactNode
  namespace?: string
}) => {
  const [errorToDisplay, setErrorToDisplay] = useState<DisplayableError | null>(null)
  const errorDisplayedCallback = useRef<{ callback: Function }>()
  const networkErrorHandlers = useNetworkErrorHandlers()
  const genericErrorHandlers = useGenericErrorHandlers()
  const { reportErrorModalDisplay } = useReport()

  const { t } = useTranslation(namespace)

  const handleErrorDisplay = (errorToDisplay: DisplayableError | null) => {
    if (errorToDisplay) {
      reportErrorModalDisplay(errorToDisplay.message, errorToDisplay.code)

      if (errorDisplayedCallback.current?.callback) {
        errorDisplayedCallback.current?.callback()
      }
    }
  }

  const handleErrorClose = (callback?: Function) => () => {
    if (callback) {
      callback()
    }
    errorDisplayedCallback.current = undefined
    setErrorToDisplay(null)
  }

  const notifyError = (notifiableError: NotifiableEvent) => {
    const { translation, displayType, testID, icon, id }: ErrorDefinition = getErrorDefinition(notifiableError)

    const { handleErrorDisplayed, handleErrorClosed } = getErrorHandlers({
      ...notifiableError,
      handlers: {
        ...genericErrorHandlers,
        ...networkErrorHandlers,
        ...notifiableError.handlers,
      },
    })

    const { errorCode, errorTitle, errorMessage, confirmLabel } = getErrorVariables(notifiableError, translation, t)

    if (handleErrorDisplayed) {
      errorDisplayedCallback.current = {
        callback: () => handleErrorDisplayed({ errorTitle, errorMessage, errorCode }),
      }
    }

    setErrorToDisplay({
      testID,
      id,
      code: errorCode,
      displayType,
      onClose: handleErrorClose(handleErrorClosed),
      message: errorMessage,
      ...(confirmLabel ? { confirmLabel } : {}),
      ...(icon ? { icon } : {}),
      ...(errorTitle ? { title: errorTitle } : {}),
    })
  }

  useEffect(() => {
    handleErrorDisplay(errorToDisplay)
  }, [errorToDisplay])

  return (
    <ErrorContext.Provider value={{ notifyError }}>
      {!!errorToDisplay && <ErrorDisplay {...errorToDisplay} />}
      {children}
    </ErrorContext.Provider>
  )
}
