import React from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from '@dominos/components'
import css from './customer-requires-change-checkbox.less'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'

export const CustomerRequiresChangeCheckbox = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('checkout')

  const handleChange = (field: string | null, value: boolean) => {
    dispatch(rootActions.setRequireExtraChange(value))
  }

  return (
    <div className={css.customerRequiresChangeCheckbox}>
      <Checkbox name='customerRequiresChange' testID={`customer-requires-change.field`} onChange={handleChange}>
        {t('CustomerRequiresChange', { defaultValue: 'I require change for $50' })}
      </Checkbox>
    </div>
  )
}
