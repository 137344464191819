import { globalHistory } from '@reach/router'
import { useEffect, useState } from 'react'

export const useLocationListener = () => {
  const [result, setResult] = useState<{ pathname?: string; search?: string }>({})

  useEffect(() => {
    const unsubscribe = globalHistory.listen(({ location }) => {
      setResult({ pathname: location.pathname, search: location.search })
    })

    return unsubscribe()
  }, [])

  return result
}
