import { useState } from 'react'
import { getDimensionFromDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'
import { createDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'
interface DimensionSetState {
  selectedDimensionSet: Bff.Dimensions.DimensionSet | undefined
  getDimensionValuesByType: (dimensionType: DimensionTypeCode) => string[]
  getSelectedDimension: (dimensionType: DimensionTypeCode) => string | undefined
  handleDimensionChange: (dimensionType: DimensionTypeCode, code: string, forced?: boolean) => void
  handleDimensionSetChange: (dimensionSet: DimensionSet | undefined) => void
}

type DimensionSet = Bff.Dimensions.DimensionSet
type DimensionTypeCode = Bff.Common.DimensionTypeCode

const useProductDimensionSetState = (
  isDimensionSetValid: (dimensionSet: DimensionSet) => boolean,
  getDimensionFilteredValues: (
    dimensionType: DimensionTypeCode,
    currentDimensionSet: DimensionSet | undefined,
  ) => string[],
  defaultDimensionSet: DimensionSet | undefined,
): DimensionSetState => {
  const [selectedDimensionSet, setSelectedDimensionSet] = useState<DimensionSet | undefined>()

  const updateSelectedDimensionSet = (dimensionSet: DimensionSet) =>
    isDimensionSetValid(dimensionSet) ? (setSelectedDimensionSet(dimensionSet), true) : false

  return {
    get selectedDimensionSet() {
      return selectedDimensionSet
    },
    getDimensionValuesByType: (dimensionType: DimensionTypeCode) =>
      getDimensionFilteredValues(dimensionType, selectedDimensionSet),
    getSelectedDimension: (dimensionType: DimensionTypeCode) =>
      getDimensionFromDimensionSet(dimensionType, selectedDimensionSet),
    handleDimensionChange: (dimensionType: DimensionTypeCode, code: string, forced: boolean = false) => {
      setSelectedDimensionSet((previous) => {
        if (!previous) {
          return previous
        }
        const updatedDimensionSet = createDimensionSet(
          previous,
          dimensionType,
          code,
          forced,
          defaultDimensionSet,
          isDimensionSetValid,
          getDimensionFilteredValues,
        )

        const isValid = isDimensionSetValid(updatedDimensionSet)

        return isValid ? updatedDimensionSet : previous
      })
    },
    handleDimensionSetChange: (dimensionSet: DimensionSet | undefined) => {
      if (!dimensionSet) {
        setSelectedDimensionSet(undefined)

        return
      }
      updateSelectedDimensionSet(dimensionSet)
    },
  }
}

export { DimensionSetState, useProductDimensionSetState }
