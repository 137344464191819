import { useLazyQuery } from '@apollo/client'
import { menuPageHeadersQuery } from '@dominos/business/queries'
import { GetMenuProps } from '../menu-context'
import { useMenuResponse } from './use-menu-response'

export const useMenuDataPageHeaders = () => {
  const [fetch, { loading, data, error, refetch }] = useLazyQuery<MenuResponse>(menuPageHeadersQuery, {
    notifyOnNetworkStatusChange: true,
  })

  const menuPageHeadersData = useMenuResponse({ loading, data, error })

  const getMenuPageHeaders = ({ storeNo, serviceMethod, requestTime }: GetMenuProps) => {
    fetch({
      variables: {
        storeNo,
        serviceMethod,
        requestTime,
      },
    })
  }

  return {
    getMenuPageHeaders,
    menuPageHeadersData,
    loading,
    error,
    refetch,
  }
}
