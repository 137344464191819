import { LayoutItem } from 'simple-keyboard-layouts/build/interfaces'
import { Language } from '@dominos/hooks-and-hocs/languages'

export interface LayoutItemWithName {
  layoutName: Layouts
  layoutItem: LayoutItem
}

export enum Layouts {
  Default = 'default',
  Shift = 'shift',
  Romaji = 'romaji',
  Hiragana = 'hiragana',
  Katakana = 'katakana',
}

export type LayoutMapping = PartialRecord<Layouts, LayoutItem>

export interface KeyboardLayout extends LayoutItemWithName {
  language: Language
  display: { [button: string]: string }
  displayName: string
}
