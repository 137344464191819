import { FosStore } from 'olo-feature-fos'
import { getCurrentMenu } from '../../menu'

export const createFosStoreMapper =
  ({ storeReducer: { currentStore } }: RootReducer) =>
  (): FosStore => {
    const { itemsByCode } = getCurrentMenu()

    return {
      ...(currentStore
        ? {
            storeNumber: currentStore.storeNo.toString(),
            timeZone: currentStore.timeZone,
          }
        : {}),
      menu: itemsByCode ? Object.values(itemsByCode) : [],
    }
  }
