import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { rootActions } from '@dominos/business/root.actions'
import { storesAlternativeStoresQuery } from '@dominos/business/queries'
import { createStoreAlternativeStores, createStoresAlternativeStoresInput } from '@dominos/business/functions/store'

interface StoresAlternativeResponse {
  storeAlternativeStores: Bff.Stores.StoreAlternativeStoresResponse
}

const useAlternativeStores = () => {
  const dispatch = useDispatch()
  const storesFromRedux = useSelector((store: RootReducer) => store.storeReducer.passiveSales.alternativeStores)
  const currentStore = useSelector((state: RootReducer) => state.storeReducer.currentStore)!
  const selectedDeliveryAddress = useSelector(
    (state: RootReducer) => state.currentOrderDetailsReducer.selectedDeliveryAddress,
  )

  const [fetch, { error, loading }] = useLazyQuery<StoresAlternativeResponse>(storesAlternativeStoresQuery, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const alternativeStores = createStoreAlternativeStores(data.storeAlternativeStores, currentStore)
      dispatch(rootActions.retrievedStoreAlternativeStores(alternativeStores))
    },
  })

  const fetchStores = async () => {
    if (storesFromRedux.length > 0) {
      return
    }

    const input = createStoresAlternativeStoresInput(
      currentStore?.storeNo!,
      { latitude: currentStore?.geo!.latitude!, longitude: currentStore?.geo!.longitude! },
      selectedDeliveryAddress?.customerAddress!,
      selectedDeliveryAddress?.geo!,
    )
    await fetch({ variables: input })
  }

  return {
    fetchStores,
    isLoading: loading,
    stores: error ? [currentStore] : storesFromRedux,
    errors: error,
  }
}

export { useAlternativeStores }
