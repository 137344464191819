/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { Marker } from './marker'
import { FakeStore, MapComponentProps } from '../../map-provider'
import { GoogleMap } from './map'
import { ReactElement } from 'react'
import { sortClosestStoreByDistanceAndReturnedClosest3Coordinates } from '@dominos/hooks-and-hocs/location/functions/distance'
import { getLoaderInstance } from 'olo-feature-address'

const { GOOGLE_MAPS_API_KEY } = getCountryConfig()

const loader = getLoaderInstance(GOOGLE_MAPS_API_KEY)

const GoogleMapWrapper: React.FC<MapComponentProps> = (props) => {
  const {
    testID,
    className,
    styles,
    mapCenterLocation,
    deliveryMarkerLocation,
    zoom,
    stores,
    mapMode,
    boundaries: bdr,
    onMarkerDrop,
  } = props

  const [map, setMap] = useState<google.maps.Map>()
  const [boundaries, setBoundaries] = useState<Array<google.maps.LatLngLiteral>>()

  useEffect(() => {
    if (!bdr || bdr.length === 0) return

    const boundaries: Array<google.maps.LatLngLiteral> = bdr
      .filter((b) => !!b && b.latitude && b.longitude)
      .map((b) => ({ lat: b?.latitude ?? 0, lng: b?.longitude ?? 0 }))

    setBoundaries(boundaries)
  }, [bdr])

  const onMapCreated = (map: google.maps.Map) => {
    if (map) setMap(map)
  }

  const onMarkerDragEnd = async (location: google.maps.LatLngLiteral) => {
    if (typeof onMarkerDrop !== 'function' || !location) return

    onMarkerDrop({ latitude: location.lat, longitude: location.lng })
  }

  const RenderStores = () => {
    const storesTmp: Array<ReactElement> = []

    if (stores) {
      stores.forEach((store) => {
        if (map) {
          storesTmp.push(
            <Marker map={map} position={{ lat: store.lat, lng: store.lng }} onMarkerClick={() => click(store)} />,
          )
        }
      })
    }

    return storesTmp
  }

  /*
    make logic for clicking on one store and create 3 bounds
    + create full map bounds if location is turned off
    + create suburb bounds when user searcher for result without location
  */

  const click = (storeNo: FakeStore) => {
    if (stores) {
      onClickZoomIn3Locations(storeNo)
    }
  }

  const onClickZoomIn3Locations = (clickedStore: FakeStore) => {
    if (stores) {
      const kek = sortClosestStoreByDistanceAndReturnedClosest3Coordinates(clickedStore, stores)
      if (kek && kek.length > 0) {
        setBoundaries(kek)
      }
    }
  }

  const shouldRenderDeliveryMarker = map && mapMode === 'Delivery' && deliveryMarkerLocation

  return (
    <>
      <GoogleMap
        testID={testID}
        className={className}
        styles={styles}
        mapCenter={mapCenterLocation}
        zoom={zoom}
        onMapCreated={onMapCreated}
        boundsCoordinates={boundaries}
      />
      {shouldRenderDeliveryMarker && (
        <Marker
          map={map}
          position={{ lat: deliveryMarkerLocation.latitude, lng: deliveryMarkerLocation.longitude }}
          onMarkerDragEnd={onMarkerDragEnd}
        />
      )}
      {map && mapMode === 'Pickup' && RenderStores()}
    </>
  )
}

export { GoogleMapWrapper, loader }
