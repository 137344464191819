import { useState } from 'react'
import { DateTime } from 'luxon'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { deliverySearchStoreQuery } from '@dominos/business/queries'

import { useAddressSearchQueries } from './use-address-search-queries'

interface DeliveryStoreResponse {
  deliverySearchStore: DeliveryStore
}

const STORE_SEARCH_LIMIT = 10

const transformDeliveryStoreResponse = (response: DeliveryStoreResponse): StoreDetails => {
  const { canDeliver, canNotDeliverCode, deliveryStoresAvailable } = response.deliverySearchStore

  if (!canDeliver && canNotDeliverCode) {
    return {
      errorCode: canNotDeliverCode,
      storeNo: undefined,
    }
  }

  if (!canDeliver && !canNotDeliverCode) {
    return {
      errorCode: 'UnknownError',
      storeNo: undefined,
    }
  }

  if (!deliveryStoresAvailable || deliveryStoresAvailable.length === 0) {
    return {
      errorCode: 'UnknownError',
      storeNo: undefined,
    }
  }

  return {
    errorCode: undefined,
    storeNo: deliveryStoresAvailable[0].storeNo,
  }
}

export const useAddressSearchDetails = (type: BffContext.ServiceMethods) => {
  const [storeDetails, setStoreDetails] = useState<StoreDetails | undefined>(undefined)
  const [pickupStoreDetails, setPickupStoreDetails] = useState<AddressLine[] | undefined>(undefined)
  const [error, setError] = useState<ApolloError | undefined>()

  const addressSearchQueries = useAddressSearchQueries({
    onPickupSearchStoreSuccess: setPickupStoreDetails,
    onPickupSearchStoreError: setError,
    storeSearchLimit: STORE_SEARCH_LIMIT,
  })

  const [deliveryQuery, { loading: deliveryLoading }] = useLazyQuery<DeliveryStoreResponse | undefined>(
    deliverySearchStoreQuery,
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.deliverySearchStore) {
          setStoreDetails(transformDeliveryStoreResponse(data))
        }
      },
      onError: (exception) => {
        setError(exception)
      },
    },
  )

  const reset = () => {
    setStoreDetails(undefined)
    setPickupStoreDetails(undefined)
    setError(undefined)
  }

  const fetchDeliveryStoreDetails = (address: DeliveryAddressRequest | undefined) => {
    if (!address) {
      reset()

      return Promise.resolve(undefined)
    }

    /**
     * Returns current `DateTime` in `ISO` format.
     *
     * @example 2023-07-03T10:14:59.074+10:00 // BNE
     */
    const currentDateTime = DateTime.local().toISO()
    const latitude = address.geo.latitude
    const longitude = address.geo.longitude

    return deliveryQuery({ variables: { latitude, longitude, deliveryLocationDateTime: currentDateTime } })
  }

  const fetchPickupStoreDetails = async (address: DeliveryAddressRequest | undefined) => {
    if (!address) {
      reset()

      return Promise.resolve(undefined)
    }

    const latitude = address.geo.latitude
    const longitude = address.geo.longitude

    return addressSearchQueries.pickupQuery({ variables: { latitude, longitude } })
  }

  return {
    fetchStoreDetails: fetchDeliveryStoreDetails,
    storeDetails,
    isLoading: deliveryLoading || addressSearchQueries.pickupLoading,
    error,
    reset,
    fetchPickupStoreDetails,
    pickupStoreDetails,
  }
}
