import React from 'react'
import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 15,
  height: 13,
}

const ListIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 15 13'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill={fill} fillRule='nonzero'>
          <path
            d='M0.703125,0 C0.313476473,0 0,0.31347692 0,0.703125 L0,2.109375 C0,2.49902308 0.313476473,2.8125 0.703125,2.8125 L2.109375,2.8125 C2.49902353,2.8125 2.8125,2.49902308 2.8125,2.109375 L2.8125,0.703125 C2.8125,0.31347692 2.49902353,0 2.109375,0 L0.703125,0 Z M4.921875,0.703125 C4.53222647,0.703125 4.21875,1.01660192 4.21875,1.40625 C4.21875,1.79589808 4.53222647,2.109375 4.921875,2.109375 L13.828125,2.109375 C14.2177731,2.109375 14.53125,1.79589808 14.53125,1.40625 C14.53125,1.01660192 14.2177731,0.703125 13.828125,0.703125 L4.921875,0.703125 Z M4.921875,5.390625 C4.53222647,5.390625 4.21875,5.70410147 4.21875,6.09375 C4.21875,6.48339808 4.53222647,6.796875 4.921875,6.796875 L13.828125,6.796875 C14.2177731,6.796875 14.53125,6.48339808 14.53125,6.09375 C14.53125,5.70410192 14.2177731,5.390625 13.828125,5.390625 L4.921875,5.390625 Z M4.921875,10.078125 C4.53222647,10.078125 4.21875,10.3916019 4.21875,10.78125 C4.21875,11.1708981 4.53222647,11.484375 4.921875,11.484375 L13.828125,11.484375 C14.2177731,11.484375 14.53125,11.1708981 14.53125,10.78125 C14.53125,10.3916019 14.2177731,10.078125 13.828125,10.078125 L4.921875,10.078125 Z M0,5.390625 L0,6.796875 C0,7.18652308 0.313476473,7.5 0.703125,7.5 L2.109375,7.5 C2.49902353,7.5 2.8125,7.18652308 2.8125,6.796875 L2.8125,5.390625 C2.8125,5.00097647 2.49902353,4.6875 2.109375,4.6875 L0.703125,4.6875 C0.313476473,4.6875 0,5.00097647 0,5.390625 Z M0.703125,9.375 C0.313476473,9.375 0,9.68847692 0,10.078125 L0,11.484375 C0,11.8740231 0.313476473,12.1875 0.703125,12.1875 L2.109375,12.1875 C2.49902353,12.1875 2.8125,11.8740231 2.8125,11.484375 L2.8125,10.078125 C2.8125,9.68847692 2.49902353,9.375 2.109375,9.375 L0.703125,9.375 Z'
            id='Shape'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export { ListIcon }
