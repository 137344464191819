import React from 'react'
import { TFunction } from 'react-i18next'
import { ProductBuildYourOwnView } from './product-build-your-own-view'
import { createProductBuildYourOwnViewModel } from './create-product-build-your-own-view-model'

interface ProductBuildYourOwnProps {
  testID: string
  t: TFunction<'menu'>
  color: string
  onCreateHalfHalfClick: () => void
  onCreateQuattroClick: () => void
  onToggleExpanded?: (expanded: boolean) => void
  isCreateQuattroEnabled: boolean
  isCreateHalfHalfEnabled: boolean
}

const ProductBuildYourOwn: React.FC<ProductBuildYourOwnProps> = (props) => (
  <ProductBuildYourOwnView
    viewModel={createProductBuildYourOwnViewModel({ ...props })}
    onCreateHalfHalfClick={props.onCreateHalfHalfClick}
    onCreateQuattroClick={props.onCreateQuattroClick}
    onToggleExpanded={props.onToggleExpanded}
    testID={props.testID}
  ></ProductBuildYourOwnView>
)

export default ProductBuildYourOwn
