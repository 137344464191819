import debounce from 'lodash/debounce'
import React, { MouseEvent, useMemo } from 'react'

import { calcWebFontWeight } from '@dominos/business/functions/text/text-functions'
import { Spinner } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

import css from './action-button.less'
import { getBackgroundColor, getTextColor } from './functions'

const ActionButton = (props: ActionButtonProps) => {
  const theme = useDominosTheme()

  // Triggers function immediately (leading), but won't trigger again
  // for repeated clicks within the provided debounce wait period.
  // https://css-tricks.com/debouncing-throttling-explained-examples/
  const onPressDebounced = useMemo(
    () =>
      props.onPress &&
      debounce(props.onPress!, props.debounce ? props.debounce : 0, { leading: true, trailing: false }),
    [props.onPress],
  )

  const styles = {
    container: {
      outline: props.disabled && !props.allowDisabledPress ? 'none' : undefined,
      ...props.containerStyle,
      backgroundColor: getBackgroundColor(theme, props),
      cursor: props.disabled ? 'no-drop' : 'pointer',
    },
    text: {
      fontSize: props.textSize ?? theme.textSizeButton,
      fontWeight: calcWebFontWeight(props.textFontWeight),
      ...props.textStyle,
      color: getTextColor(theme, props),
    },
  }

  const onButtonPress = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const clickAction =
      props.error && props.onErrorPress ? props.onErrorPress : props.debounce ? onPressDebounced : props.onPress
    if ((!props.disabled || props.allowDisabledPress) && clickAction) {
      clickAction()
    }
  }

  const buttonLabel = props.error && props.errorText ? props.errorText : props.text

  return (
    <button
      data-testid={props.testID}
      onClick={onButtonPress}
      className={props.className ? `${props.className} ${css.container}` : css.container}
      style={styles.container}
      disabled={props.disabled && !props.allowDisabledPress}
      aria-label={props.label}
    >
      {props.loading ? (
        <Spinner testID={props.testID} />
      ) : (
        props.children || (
          <div className={css.text} style={styles.text} data-testid={`${props.testID}.text`}>
            {buttonLabel}
          </div>
        )
      )}
    </button>
  )
}

export { ActionButton }
