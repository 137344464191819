import { isProductMenuItemNew } from '@dominos/components'
import { getCountryConfig } from '../common/get-config'

/**
 * Returns two types of images for the provided product in the order as follows:
 * Product image:
 * 1. Large Image
 * 2. Application Image
 * 3. ''
 *
 * Menu image:
 * 1. Small Image
 * 2. Application Image
 * 3. ''
 *
 * @param product MenuItemDependents
 * @returns image url | full image url (includes 'https://order...')
 */
export const getImages = (product: MenuItemDependents) => {
  const media = !isProductMenuItemNew(product) ? product.media : undefined
  const largeImageURL = media && media.largeImage ? media.largeImage.url : null
  const smallImageURL = media && media.smallImage ? media.smallImage.url : null
  const productImage = getUrls(product, largeImageURL)
  const menuImage = getUrls(product, smallImageURL)

  return {
    productImage,
    menuImage,
  }
}

const getUrls = (product: MenuItemDependents, imageURL: string | null) => {
  const media = !isProductMenuItemNew(product) ? product.media : undefined
  const applicationImageURL = media && media.applicationImage ? media.applicationImage.url : null
  const url = imageURL || applicationImageURL || ''
  const fullUrl = getFullUrl(url)

  return {
    url,
    fullUrl,
  }
}

export const getFullUrl = (url: string) => {
  const { PRODUCT_IMAGE_BASE_URL } = getCountryConfig()

  return `${PRODUCT_IMAGE_BASE_URL}${url}`
}
