import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 98,
  height: 77,
}

const FeedbackSubmittedIcon = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 98 77'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <path
              fill='#4A4A4A'
              d='M90.65 7.121c2.76 0 5 2.239 5 5V22c0 2.761-2.24 5-5 5H79.317L73.6 31.5V27c-3.07 0-5.557-2.488-5.557-5.558v-9.32c0-2.762 2.238-5 5-5h17.607zm-5.852 4.859c-.292 0-.59.05-.893.152-.304.101-.587.238-.848.41-.261.171-.486.333-.675.483-.188.151-.367.311-.537.48-.17-.169-.348-.329-.536-.48-.189-.15-.414-.312-.675-.484-.262-.171-.544-.308-.848-.41-.304-.1-.602-.151-.894-.151-1.055 0-1.881.292-2.48.876-.597.584-.896 1.394-.896 2.43 0 .316.055.64.166.975.11.334.236.62.378.855.14.235.3.465.48.689.179.223.31.378.392.462.082.085.147.146.194.184l4.409 4.253c.084.085.188.127.31.127.123 0 .227-.042.311-.127l4.402-4.239c1.078-1.078 1.617-2.138 1.617-3.179 0-1.036-.299-1.846-.897-2.43-.598-.584-1.425-.876-2.48-.876z'
              transform='translate(-141.000000, -72.000000) translate(141.425293, 72.132812)'
            />
            <g transform='translate(-141.000000, -72.000000) translate(141.425293, 72.132812)'>
              <circle cx='34.225' cy='34.225' r='30.525' fill='#56C84D' />
              <path
                fill='#000'
                d='M34.225 0C15.353 0 0 15.353 0 34.225c0 18.871 15.353 34.224 34.225 34.224 18.871 0 34.224-15.353 34.224-34.224C68.45 15.353 53.096 0 34.225 0zm0 62.994c-7.903 0-15.07-3.203-20.275-8.378-2.078-2.067-3.842-4.449-5.216-7.066-2.092-3.986-3.279-8.52-3.279-13.325 0-15.864 12.906-28.77 28.77-28.77 7.524 0 14.382 2.905 19.514 7.651 2.66 2.46 4.858 5.415 6.442 8.717 1.802 3.758 2.813 7.964 2.813 12.402 0 15.863-12.906 28.769-28.77 28.769z'
              />
              <path
                fill='#000'
                d='M16.65 36.186c0 9.713 7.884 17.575 17.575 17.575 9.69 0 17.574-7.862 17.574-17.575h3.7c0 11.794-9.543 21.275-21.274 21.275-11.731 0-21.275-9.481-21.275-21.275zm6.128-15.263c2.043 0 3.7 2.05 3.7 4.577 0 2.528-1.657 4.577-3.7 4.577-2.044 0-3.7-2.05-3.7-4.577 0-2.528 1.656-4.577 3.7-4.577zm22.894 0c2.043 0 3.7 2.05 3.7 4.577 0 2.528-1.657 4.577-3.7 4.577-2.044 0-3.7-2.05-3.7-4.577 0-2.528 1.656-4.577 3.7-4.577z'
              />
            </g>
            <g>
              <path
                fill='#8ACF03'
                d='M7.8 34.275c-1.352-1.345-2.501-2.896-3.395-4.6C3.042 27.08 2.27 24.13 2.27 21 2.27 10.672 10.672 2.27 21 2.27c4.899 0 9.363 1.891 12.704 4.981 1.732 1.602 3.163 3.525 4.194 5.675 1.174 2.446 1.832 5.185 1.832 8.074 0 10.328-8.402 18.73-18.73 18.73-5.145 0-9.811-2.086-13.2-5.455z'
                transform='translate(-141.000000, -72.000000) translate(141.425293, 72.132812) translate(55.149414, 34.433594)'
              />
              <path
                fill='#000'
                d='M0 21c0 11.58 9.42 21 21 21s21-9.42 21-21S32.58 0 21 0 0 9.42 0 21zm8.56 12.512c-1.276-1.268-2.358-2.73-3.201-4.336C4.075 26.73 3.347 23.95 3.347 21c0-9.734 7.92-17.653 17.653-17.653 4.617 0 8.825 1.783 11.974 4.695 1.632 1.51 2.98 3.322 3.952 5.349 1.106 2.305 1.727 4.886 1.727 7.609 0 9.734-7.92 17.653-17.653 17.653-4.849 0-9.247-1.966-12.44-5.141z'
                transform='translate(-141.000000, -72.000000) translate(141.425293, 72.132812) translate(55.149414, 34.433594)'
              />
              <path
                fill='#000'
                d='M27.81 23.747c.523.586 1.212 1.02 1.993 1.229C27.499 27.347 24.313 28.7 21 28.7c-3.313 0-6.5-1.354-8.803-3.725.781-.209 1.47-.643 1.993-1.229 1.843 1.712 4.28 2.683 6.81 2.683 2.53 0 4.967-.97 6.81-2.683zm.214-10.909c1.254 0 2.27 1.258 2.27 2.809 0 1.55-1.016 2.808-2.27 2.808s-2.27-1.257-2.27-2.808 1.016-2.809 2.27-2.809zm-14.048 0c1.254 0 2.27 1.258 2.27 2.809 0 1.55-1.016 2.808-2.27 2.808s-2.27-1.257-2.27-2.808 1.016-2.809 2.27-2.809z'
                transform='translate(-141.000000, -72.000000) translate(141.425293, 72.132812) translate(55.149414, 34.433594)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { FeedbackSubmittedIcon }
