import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './login-container.less'

interface SignUpMessageProps {
  onSignUpClicked: () => void
}

export const SignUpMessage = ({ onSignUpClicked }: SignUpMessageProps) => {
  const { t } = useTranslation('login')

  return (
    <div className={css.signUpMessage}>
      {t('signUpMessage', { defaultValue: `Don't have a Domino's account?` })}
      <span role='button' onClick={onSignUpClicked}>
        {t('signUpButton', { defaultValue: 'Sign up' })}
      </span>
    </div>
  )
}
