import { useState } from 'react'
import { useRememberedCustomerDetails, useRememberMyDetails } from '@dominos/hooks-and-hocs'
import { detailsFieldNames, FormValue } from '@dominos/components'

// TODO: refactor this along with the details form components
export const useCheckoutMyDetails = () => {
  const { customerDetails, saveDetails } = useRememberedCustomerDetails()
  const [isMyDetailsValid, setIsMyDetailsValid] = useState<boolean>(false)
  const { rememberMyDetailsState, setRememberMyDetailsState, saveRememberMyDetailsCheck } = useRememberMyDetails()
  const [myDetails, setMyDetails] = useState<{ [key: string]: FormValue }>({})

  const handleMyDetailsChange = (field: string | null, value: FormValue) => {
    if (field === detailsFieldNames.rememberMyDetails) {
      setRememberMyDetailsState(!!value)
    }
    setMyDetails((prev) => ({ ...prev, [field!]: value }))
  }

  return {
    isMyDetailsValid,
    setIsMyDetailsValid,
    myDetails,
    handleMyDetailsChange,
    rememberMyDetailsState,
    saveRememberMyDetailsCheck,
    customerDetails,
    saveDetails,
  }
}
