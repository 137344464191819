import React from 'react'

const svgPizzaIcon2ClipPath1 = (selectedColor: string) => (
  <g clipPath={'url(#clippath)'}>
    <g>
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M222.6,217.9c-3.4-.8-6.3-2.2-7.7-3-.7-.4-2.2-1-5.5-1.7s-5.6-1.2-9.2-1.7c-.5,0-.9-.5-1-1-.1-.5,0-1,.5-1.3.6-.4,1.2-.9,1.8-1.4,5.7-4.8,10.6-6.8,14.8-5.8s1.4.4,1.8.6c.2-.4.5-.8.8-1.3,2.5-3.6,5.8-5.4,9.3-5,.6,0,1.3.2,1.9.3,4.2,1,6.6,3.7,7.8,6.2.1,0,.2,0,.3,0,.7,0,1.2.7,1.1,1.3-.3,5.8-2.1,9.9-5.2,12.2-2.9,2.1-6.7,2.7-11.5,1.6h0ZM210.1,210.8h0c2.9.7,4.9,1.3,6.1,2,1.1.6,3.8,2,7.1,2.7s7.2.6,9.4-1.1c2-1.5,3.2-4,3.8-7.4,0-.1,0-.2,0-.3,0-.2-.7-6.1-6.9-7.5s-1-.2-1.5-.3c-3.3-.3-5.6,2-7,3.9-.7,1-1,1.9-1.1,1.9,0,.2-.2.3-.3.4-.2.2-.7.5-1.4.3s-.5-.2-.9-.3c-.4-.2-1-.4-1.7-.6-3.2-.8-7.4.9-12.2,5,2.5.4,4.7.9,6.6,1.3h0Z'
      />
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M173.8,208.3c-.5-.1-.9-.5-1-1,0-.1,0-.3,0-.4-.5-2.5.3-3.9,1.2-4.7,1.3-1.3,3.5-1.8,5.8-1.2s3.3,1.3,4.4,2.6c.4.5.9,1.2,1.4,1.8.5.7,1,1.5,1.7,2.2.3.4.4.9.1,1.4-.2.4-.7.7-1.2.6-3.8-.4-7.9-.9-12-1.3,0,0-.1,0-.2,0h0ZM179.1,203.4h0c-1.4-.3-2.8-.1-3.5.6-.5.5-.6,1.1-.5,2,2.8.3,5.6.5,8.2.8-.4-.6-.9-1.2-1.2-1.6-.7-.8-1.8-1.5-3-1.8h0Z'
      />
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M141,207.2c-.2,0-.4-.2-.6-.3-.3-.2-.4-.6-.4-.9,0-2,1.5-3.6,4.4-5.1,5.4-2.7,12.9-5.2,17.8-4s2.8,1.1,3.6,2.1c2.2,2.8,1.1,5.9.1,7.9-.2.5-.7.7-1.2.7-1.5-.1-3.1-.2-4.6-.4-5.6-.4-11.8-.4-18.7,0-.1,0-.2,0-.4,0h0ZM161.6,199.2h0c-3.5-.8-9.8.7-16.1,3.8-1.1.6-1.8,1.1-2.2,1.5,6.2-.3,11.8-.3,17,.1,1.3,0,2.5.2,3.8.3.8-2,.8-3.3-.2-4.5-.5-.6-1.2-1-2.2-1.2h0Z'
      />
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M124.3,208.8c-.3,0-.6-.3-.8-.5-.3-.4-.3-.9,0-1.3.7-1.2,1.9-2.3,3.4-3.4,2.5-1.8,4.4-2.5,5.9-2.1s1.5.7,1.9,1.5c.7,1.3.5,3,0,4.2-.2.5-.6.8-1.1.8-2.8.3-5.7.6-9.1,1-.1,0-.3,0-.4,0h0ZM132.3,203.8h0c-.3,0-1.3-.1-3.9,1.7-.2.1-.4.3-.6.4,1.7-.2,3.3-.4,4.8-.5,0-.5,0-1.1-.1-1.4,0-.2-.2-.2-.3-.2h0Z'
      />
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M98.6,210.6c-.3,0-.6-.2-.9-.3-1.5-.5-2.6-1.5-3.1-2.9-.9-2.5,0-6.1,2.9-10.8.3-.6,1.1-.8,1.7-.5.6.3.8,1,.5,1.6-.4.8-.9,2.1-.3,2.5.7.4,1.4.7,2.4.9,1.5.3,3.1.5,4.7.6,1.6.1,3.2.2,4.7.6,3.3.8,5.1,2.6,5.5,5.6,0,.2,0,.5,0,.7,0,.7-.4,1.3-1.1,1.3-8.2,1-13.7,1.4-17,.7h0ZM97.6,202h0c-.8,2-1,3.5-.6,4.6.2.4.5,1,1.6,1.4.2,0,.4.1.6.2,3.1.7,8.9.2,14.9-.5-.4-1.5-1.4-2.4-3.4-2.8s-2.7-.4-4.3-.5c-1.7-.1-3.4-.2-5.1-.6s-2.2-.7-3.1-1.2c-.2-.1-.4-.3-.6-.4h0Z'
      />
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M218.5,205.4s0,0-.1,0c-.6-.2-1-.9-.8-1.5,0-.2,4.2-17.2-3.1-37.6-5.1-14.4-15.2-23.3-18.1-25.7-.4-.3-.8-.7-1.2-1-1.9-1.5-3.3-2.7-4.3-3.9,0,0,0,0,0,0-2.6-3.1-1.4-5.9,1.3-9.5,4.8-6.3,7.8-8.8,14.1-4.6.7.5,1.5,1,2.2,1.5.6.4.7,1.2.3,1.8-.4.6-1.2.7-1.8.3-.7-.5-1.4-1-2.1-1.5-4.4-2.9-5.9-2.3-10.8,4.1-3,3.8-2.5,5-1.4,6.3,0,0,0,0,0,0,.8,1,2.2,2.1,4,3.6.4.3.8.6,1.2,1,3.1,2.5,13.5,11.8,18.9,26.9,5.1,14.3,4.8,26.8,4.1,33.6,2.1-2,4.7-2.9,7.3-2.7,4.8.5,7.4,2.9,8.9,5.3,4.8-35.1-11.1-58-21.3-68.7-.5-.5-.5-1.3,0-1.8.5-.5,1.3-.5,1.8,0,5.6,5.9,13.3,15.8,18.1,29.7,5,14.3,6,29.7,3,45.8-.1.6-.7,1.1-1.3,1-.6,0-1.1-.5-1.2-1.1,0-.3-.8-7-8.4-7.7-3.3-.3-5.6,2-7,3.9-.7,1-1,1.9-1.1,1.9-.2.6-.9.9-1.4.7h0Z'
      />
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M212.6,129.4c-.2,0-.4-.1-.6-.3-.5-.5-1.1-.9-1.6-1.4-.5-.5-.6-1.2-.1-1.8.5-.5,1.2-.6,1.8-.1.5.5,1.1.9,1.6,1.4.5.5.6,1.3,0,1.8-.3.4-.8.5-1.2.4h0Z'
      />
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M116.1,227.3c-1.1-.3-2.1-.8-2.9-1.7-4-4.3-2.3-7.6-.8-10.6,1-2,2.1-4.1,1.7-6.8-.4-3.3-3.1-3.7-7.8-4-2.8-.2-5.8-.4-8.2-1.8-.9-.5-1.4-1.3-1.5-2.3-.2-1.2-.6-4.8,12.6-17.8.5-.5,1.3-.5,1.8,0,.5.5.5,1.3,0,1.8-9.1,9-12.1,14-11.9,15.7,0,.2,0,.3.3.4,1.9,1.1,4.5,1.3,7.1,1.5,4.4.3,9.4.7,10.1,6.2.5,3.5-.8,6-2,8.2-1.5,2.9-2.4,4.7.4,7.7,1,1.1,2.6,1.4,4.3.6,2.2-.9,4.3-3.4,4.1-7,0-1.2-.3-2.3-.5-3.3-.7-3.4-1.4-6.9,4.1-10.8,3.2-2.3,5.6-2.8,7.1-1.5,1.5,1.3,1.4,3.8.6,5.6-.9,1.8-1.1,3.4-.7,4.5.2.6,1.3,1.3,2.7,1.7.7.2,2.4.5,3.7-.5,1.3-1,.7-3.5.5-3.9v-.2c-1-2.1-2.4-5.3,3.5-8.2,8.1-4.1,17.9-6.3,21.4-1.9,2.7,3.4.6,7.2-.7,9.5-.5.9-.6,1.6-.4,2.3.4,1.2,1.9,2,2.4,2.2,1.7.6,3.1.5,4.2-.4,1.3-1.1,1.8-3.3,1.4-5.6-.5-2.6.3-4.6,2.4-5.6,2.7-1.3,6.8-.3,9,2.2.4.5.9,1.2,1.4,1.8,1.8,2.6,4.1,5.8,7.5,6.2,2.5.3,5.4-.9,8.6-3.7,8.9-7.6,14-6.3,16.3-5.3.8-4.4,2.7-19.1-3.4-36.3-5.1-14.4-15.2-23.3-18.1-25.7-.4-.3-.8-.7-1.2-1-1.6-1.3-2.9-2.3-3.8-3.3-12,3.4-41.2,13.8-70.7,39.1-.5.5-1.3.4-1.8-.1-.5-.5-.4-1.3.1-1.8,13.3-11.4,28.1-21.1,43.9-28.8,13-6.3,23.4-9.6,28.5-11,.5-.1,1,0,1.3.4.8,1,2.2,2.1,4,3.6.4.3.8.6,1.2,1,3.1,2.5,13.5,11.8,18.9,26.9,7.5,21.2,3.4,38.4,3.2,39.1,0,.2-.2.4-.4.6-.8.7-1.7.3-2.3,0-1.7-.8-5.7-2.6-14.2,4.7-3.8,3.3-7.3,4.7-10.5,4.3-4.5-.6-7.3-4.4-9.3-7.2-.5-.6-.9-1.2-1.3-1.7-1.5-1.8-4.4-2.3-5.9-1.6-1,.5-1.3,1.4-1,2.8.7,3.2-.2,6.3-2.2,8.1-1.8,1.5-4.1,1.8-6.7.8-.1,0-3-1.2-3.9-3.6-.3-1-.5-2.5.5-4.4,1.4-2.5,2.4-4.8.9-6.7-2.2-2.7-10-1.6-18.3,2.6-3.7,1.9-3.2,3-2.4,5v.2c.6,1.2,1.4,5-1.2,6.9-1.5,1.2-3.7,1.5-5.9.9-2.1-.6-3.8-1.8-4.4-3.2-.7-1.8-.5-4,.7-6.6.5-1,.5-2.2,0-2.6-.3-.2-1.5-.2-4,1.6-4.2,3-3.8,5-3.1,8.3.2,1.1.5,2.4.6,3.7.2,4.8-2.7,8.1-5.6,9.4-1.4.6-2.9.7-4.2.4h0Z'
      />
      <path
        strokeWidth={'2px'}
        fill={selectedColor}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M114.5,180.1c-.2,0-.5-.2-.6-.4-.5-.5-.4-1.3,0-1.8.6-.5,1.1-1,1.7-1.5.5-.5,1.3-.4,1.8,0,.5.5.4,1.3,0,1.8-.6.5-1.1,1-1.7,1.5-.3.3-.7.4-1.1.3h0Z'
      />
    </g>
  </g>
)

const svgPizzaIcon2ClipPath2 = (selectedColor: string) => (
  <g clipPath={'url(#clippath-1)'}>
    <g>
      <path
        strokeWidth={'5px'}
        fill={'none'}
        stroke={selectedColor}
        strokeMiterlimit={10}
        d='M79,170.9s6.5-20.2,44.7-44.3c38.2-24.1,50.9-22.1,50.9-22.1,0,0,32.4,14,27.7,66.8,0,0-.6,6.3-1.4,6.3s-38.2-2.4-63.1-3c-18.8-.5-47.5,3.2-52.2,3.6s-9.1-2.2-6.7-7.3Z'
      />
      <path
        strokeWidth={'0px'}
        fill={selectedColor}
        d='M95.8,170.5c3-4.8,8.9-6.2,14.2-6.3s13.6,2.4,20.5,3.1,7.6.3,11.2-.9,6.2-2.6,9.4-3.5,3.2-.6,4.9-.3,3.7,1,5.5,1.7c3.1,1.1,6.2,1.5,9.5,1.1s7.2-1.4,10.7-2.1,6.8-.9,9.9.6,3.6-1.9,1.7-2.9c-6.2-3-12.8-.8-19.1.5s-6.6,1.1-9.9.2-7-2.8-10.9-2.5-6.8,1.9-10.1,3.2c-3.7,1.5-7.3,2.1-11.3,1.8-7.3-.5-14.3-3.3-21.7-3.2s-13.5,2-17.2,7.9,1.7,3.5,2.9,1.7h0Z'
      />
      <path
        strokeWidth={'0px'}
        fill={selectedColor}
        d='M103.3,157c3.2-4.3,8.9-6.8,14.2-6s5.9,1.9,8.8,3,5.9,1.6,8.9,1c5.2-1.1,9.1-4.9,13.9-6.9s4.9-1.3,7.4-.6,5.9,2.5,9,3.1,7.9,0,11.8-.7,8.5-1.5,12.7-2.3,1.2-3.6-.9-3.2c-3.9.7-7.8,1.4-11.7,2.1s-7.4,1.7-11,.9-6-2.3-9-3.1-5.6-.7-8.4.3c-5,1.7-8.9,5.7-14.1,7s-6,0-8.8-1.2-5.9-2.5-9.2-2.8c-6.4-.6-12.8,2.5-16.6,7.7s1.6,3.4,2.9,1.7h0Z'
      />
      <path
        strokeWidth={'0px'}
        fill={selectedColor}
        d='M123.8,140.2c1.8-.9,4-1.1,6-.6s3.7,2,5.8,2.5c4.6,1,8.6-2.3,12.5-4.1s8.5-2.8,12.9-2.7,9.3,1.7,14,2.5,9.7.8,13.6-2,0-4.1-1.7-2.9c-3.5,2.5-8,2.3-12,1.5s-9.2-2.4-14-2.5-8.9.8-13,2.5-4,2-6,3-4.2,2-6.3,1.2-2-1.1-3.1-1.6-2.2-.8-3.4-.9c-2.5-.3-4.9.1-7.1,1.2s-.2,3.8,1.7,2.9h0Z'
      />
      <path
        strokeWidth={'0px'}
        fill={selectedColor}
        d='M138.6,127.1c3.7.9,7.4,0,11-1.2s7-2.7,10.6-2.3,7.1,3.4,11.1,3.1,6.8-3.4,9-6.5-1.6-3.4-2.9-1.7c-1.9,2.6-4.3,5.5-8,4.7s-6.5-3-10.3-3-6.7,1.2-9.9,2.3-6.5,2.2-9.8,1.4-3,2.7-.9,3.2h0Z'
      />
      <path
        strokeWidth={'0px'}
        fill={selectedColor}
        d='M163.2,114.9c3.6.4,7.3.8,10.9,1.2s1.7-.8,1.7-1.7-.7-1.6-1.7-1.7c-3.6-.4-7.3-.8-10.9-1.2s-1.7.8-1.7,1.7.7,1.6,1.7,1.7h0Z'
      />
    </g>
  </g>
)

const svgPizzaIcon1 = (selectedColor: string) => (
  <g>
    <path
      strokeWidth={'0.8px'}
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M71.3,132.3c-2.1-.5-3.4-2.6-2.9-4.6s2.6-3.4,4.6-2.9,3.4,2.6,2.9,4.6-2.6,3.4-4.6,2.9h0ZM72.5,127.3h0c-.7-.2-1.4.3-1.6,1s.3,1.4,1,1.6,1.4-.3,1.6-1-.3-1.4-1-1.6h0Z'
    />
    <path
      strokeWidth={'0px'}
      fill={selectedColor}
      d='M97.7,105.2c-2.1-.5-3.4-2.6-2.9-4.6s2.6-3.4,4.6-2.9,3.4,2.6,2.9,4.6-2.6,3.4-4.6,2.9h0ZM98.8,100.1h0c-.7-.2-1.4.3-1.6,1s.3,1.4,1,1.6,1.4-.3,1.6-1-.3-1.4-1-1.6h0Z'
    />
    <path
      strokeWidth={'0px'}
      fill={selectedColor}
      d='M115.4,116.6c-2.1-.5-3.4-2.6-2.9-4.6s2.6-3.4,4.6-2.9,3.4,2.6,2.9,4.6-2.6,3.4-4.6,2.9h0ZM116.6,111.6h0c-.7-.2-1.4.3-1.6,1s.3,1.4,1,1.6,1.4-.3,1.6-1-.3-1.4-1-1.6h0Z'
    />
    <path
      strokeWidth={'0px'}
      fill={selectedColor}
      d='M152.6,129.4c-2.1-.5-3.4-2.6-2.9-4.6s2.6-3.4,4.6-2.9,3.4,2.6,2.9,4.6-2.6,3.4-4.6,2.9h0ZM153.8,124.4h0c-.7-.2-1.4.3-1.6,1s.3,1.4,1,1.6,1.4-.3,1.6-1-.3-1.4-1-1.6h0Z'
    />
    <path
      strokeWidth={'0px'}
      fill={selectedColor}
      d='M136.7,91.8c-2.1-.5-3.4-2.6-2.9-4.6s2.6-3.4,4.6-2.9c2.1.5,3.4,2.6,2.9,4.6s-2.6,3.4-4.6,2.9h0ZM137.9,86.7h0c-.7-.2-1.4.3-1.6,1s.3,1.4,1,1.6,1.4-.3,1.6-1-.3-1.4-1-1.6h0Z'
    />
    <path
      strokeWidth={'0.8px'}
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M160.8,105.8c-2.1-.5-3.4-2.6-2.9-4.6s2.6-3.4,4.6-2.9,3.4,2.6,2.9,4.6-2.6,3.4-4.6,2.9h0ZM161.9,100.8h0c-.7-.2-1.4.3-1.6,1s.3,1.4,1,1.6,1.4-.3,1.6-1-.3-1.4-1-1.6h0Z'
    />
    <path
      strokeWidth={'0.8px'}
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M87,133c-4.3-1-7.4-4.3-7.8-8.3-.4-5.2,4-9.8,9.9-10.4,1.3-.1,2.6,0,3.8.3,4.3,1,7.4,4.3,7.8,8.3.4,5.2-4,9.8-9.9,10.4-1.3.1-2.6,0-3.8-.3h0ZM92.4,117.1h0c-1-.2-2-.3-3-.2-4.5.4-8,3.8-7.6,7.6.2,2.8,2.6,5.3,5.8,6s2,.3,3,.2c4.5-.4,8-3.8,7.6-7.6-.2-2.8-2.6-5.3-5.8-6h0Z'
    />
    <path
      strokeWidth={'0.8px'}
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M126.3,113.9c-.1,0-.3,0-.4-.2-.6-.4-.8-1.1-.4-1.7.6-.9,1.3-1.6,2.1-2.3.6-.4,1.3-.3,1.8.3.4.6.3,1.3-.3,1.8-.6.4-1.1,1-1.5,1.6-.3.5-.8.7-1.3.6h0Z'
    />
    <path
      strokeWidth={'0.8px'}
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M133,128.4c-2.5-.6-4.8-2.1-6.6-4.4-1.4-1.8-2.2-3.9-2.3-6.1,0-.7.5-1.3,1.2-1.4.7,0,1.3.5,1.4,1.2.1,1.7.8,3.3,1.8,4.8,2.7,3.6,7.4,4.8,10.5,2.5,3.1-2.3,3.4-7.1.6-10.8-1.7-2.2-4.1-3.6-6.6-3.7-.7,0-1.2-.6-1.2-1.3,0-.7.6-1.2,1.3-1.2,3.3,0,6.5,1.8,8.6,4.7,3.6,4.8,3,11.2-1.1,14.3-2.1,1.6-4.9,2-7.5,1.4h0Z'
    />
    <path
      strokeWidth={'0.8px'}
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M155.2,94.6c-2.1-.5-4-1.6-5.6-3.1-4.2-4.2-4.7-10.6-1.1-14.3,1.7-1.8,4.1-2.7,6.8-2.6.3,0,.6.1.8.3,2.5,2.3,6.3,6.1,10,11.4.2.3.2.6.2.9-.2,2-1.1,3.7-2.4,5.1-2.2,2.2-5.5,3.1-8.8,2.3h0ZM154.8,77.2h0c-1.7,0-3.3.7-4.5,1.8-2.7,2.7-2.2,7.6,1,10.8,1.2,1.2,2.8,2.1,4.4,2.4,2.5.6,4.8,0,6.4-1.6.8-.9,1.4-2,1.6-3.2-3.3-4.7-6.6-8.1-9-10.2h0Z'
    />
    <path
      strokeWidth={'0.8px'}
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M108.2,97.9c-3.5-.8-6.1-3.2-7-6.3,0-.3-.1-.6-.2-.9,0-.5.1-1,.6-1.3,5-3.1,10.3-6,15.5-8.6.4-.2.9-.2,1.3,0,1.8,1.2,3,2.9,3.5,4.8,1.4,5-2,10.4-7.8,12.1-2,.6-4,.6-5.9.2h0ZM103.6,91.1h0c.7,2.2,2.6,3.8,5.1,4.4s3.1.3,4.7-.1c4.4-1.3,7.1-5.3,6-9-.3-1.2-1-2.2-2-3-4.7,2.4-9.3,5-13.9,7.7h0Z'
    />
    <path
      strokeWidth={'0px'}
      fill={selectedColor}
      d='M70.9,115.1c-.2,0-.5-.2-.6-.4-.5-.5-.4-1.3,0-1.8.6-.5,1.1-1,1.7-1.5.5-.5,1.3-.4,1.8,0,.5.5.4,1.3,0,1.8-.6.5-1.1,1-1.7,1.5-.3.3-.7.4-1.1.3h0Z'
    />
    <path
      strokeWidth={'5px'}
      fill={'none'}
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M53.6,137.2s6.5-20.2,44.7-44.3c38.2-24.1,50.9-22.1,50.9-22.1,0,0,32.4,14,27.7,66.8,0,0-.6,6.3-1.4,6.3s-38.2-2.4-63.1-3c-18.8-.5-47.5,3.2-52.2,3.6s-9.1-2.2-6.7-7.3Z'
    />
  </g>
)

const svgPizzaIcon2 = (selectedColor: string) => (
  <>
    {svgPizzaIcon2ClipPath1(selectedColor)}
    {svgPizzaIcon2ClipPath2(selectedColor)}
  </>
)

export const DoubleDecadence = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : '#454545'

  return (
    <svg width='51px' height='49px' viewBox='0 0 306 306'>
      <defs>
        <clipPath id='clippath'>
          <path
            strokeWidth={'0px'}
            fill={'none'}
            d='M93.3,181.3c-1.4.2,28.6-7.3,28.6-7.3,0,0,70.8,1.8,71.7,1.8s6.5,1.8,7,0,1.7-31.8,1.7-31.8l-7.1-20.2-2.3-14,43.5-5,12.8,29.1.6,51.2-10.5,33.1-54.5,35.7-60,3-51.3-24.1,3.9-22.9,15.8-28.6Z'
          />
        </clipPath>
        <clipPath id='clippath-1'>
          <path
            strokeWidth={'0px'}
            fill={'none'}
            d='M89.9,146.2l13.2-5.2,69.2,2.8s3.8-.2,3.8-1.4,1.6-20.3,1.6-20.3l-3-17.5,38.3-13,5,1.2,25.3,18.3,10.4,25.9,5.8,24.9-70.2,41.3-72.6,4.7h-46.3c0,.1-23.7-15.5-23.7-15.5l14.4-22.1,28.9-24.1Z'
          />
        </clipPath>
      </defs>
      <circle
        strokeWidth={'18px'}
        fill={'none'}
        stroke={selectedColor}
        strokeMiterlimit={10}
        cx='153'
        cy='153'
        r='118.2'
      />
      {svgPizzaIcon1(selectedColor)}
      {svgPizzaIcon2(selectedColor)}
    </svg>
  )
}
