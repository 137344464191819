import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge5 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-5' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M14.676,29.525 L14.676,27.138 C14.676,26.673 14.955,26.239 15.389,26.053 L18.768,24.658 C19.109,24.503 19.481,24.751 19.481,25.123 L19.481,27.386 C19.481,27.479 19.543,27.541 19.636,27.541 L22.364,27.541 C22.457,27.541 22.519,27.479 22.519,27.386 L22.519,22.767 C22.519,22.674 22.457,22.612 22.364,22.612 L15.575,22.612 C15.141,22.612 14.8,22.271 14.8,21.837 L14.8,10.925 C14.8,10.491 15.141,10.15 15.575,10.15 L26.549,10.15 C26.983,10.15 27.324,10.491 27.324,10.925 L27.324,13.684 C27.324,14.118 26.983,14.459 26.549,14.459 L19.76,14.459 C19.667,14.459 19.605,14.521 19.605,14.614 L19.605,17.838 C19.605,17.931 19.667,17.993 19.76,17.993 L24.999,17.993 C26.27,17.993 27.324,19.047 27.324,20.318 L27.324,29.525 C27.324,30.796 26.27,31.85 24.999,31.85 L17.001,31.85 C15.73,31.85 14.676,30.796 14.676,29.525 Z'
          id='5'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
