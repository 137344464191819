import { DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const Deep = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR

  return (
    <svg width='56px' height='25px' viewBox='0 0 56 25' key={color}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-268.000000, -848.000000)' fill={selectedColor} stroke={selectedColor}>
          <g transform='translate(6.000000, 309.000000)'>
            <g transform='translate(1.000000, 474.000000)'>
              <path d='M289.038516,83.4905108 C301.741066,83.4905108 312.038516,79.9214538 312.038516,75.5187988 C312.038516,71.1161439 301.741066,67.5470869 289.038516,67.5470869 C276.335967,67.5470869 266.038516,71.1161439 266.038516,75.5187988 C266.038516,79.9214538 276.335967,83.4905108 289.038516,83.4905108 Z M289.038516,77.4985502 C300.543374,77.4985502 309.869904,75.2549039 309.869904,72.4872259 C309.869904,69.7195478 300.543374,67.4759015 289.038516,67.4759015 C277.533659,67.4759015 268.207129,69.7195478 268.207129,72.4872259 C268.207129,75.2549039 277.533659,77.4985502 289.038516,77.4985502 Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
