import {
  convertSpecialCharacters,
  getPortionSectionList,
  includeVouchersInSubMenu,
} from '@dominos/business/functions/menu'
import { MenuNavigationProps } from '@dominos/components'
import { useHasTopMenuWrapped, useWindowSize } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { getSelectedPage, getSelectedSection } from '../functions'
import less from '../menu-navigation.less'
import { MenuNavSection } from './menu-nav-section'

type MenuPage = Bff.Menu.old.MenuPage
type MenuSection = Bff.Menu.old.MenuSection

const useCheckForListWrapping = (elementName?: string, onWrapChanged?: (wrapped: boolean) => void) => {
  const [innerWidth] = useWindowSize()

  useEffect(() => {
    checkListWrapped()
  }, [innerWidth])

  const checkListWrapped = () => {
    if (elementName && onWrapChanged) {
      const listElement = document.getElementById(elementName)

      onWrapChanged(listElement ? listElement.clientWidth !== listElement.scrollWidth : false)
    }
  }
}

interface RenderSectionProps extends React.HTMLAttributes<HTMLUListElement> {
  name?: string
  onWrapChanged?: (wrapped: boolean) => void
}

export const MenuNavSelections = (props: MenuNavigationProps) => {
  const [isDropdownExpanded, setIsDropdownExpanded] = useState(false)

  const closeDropdown = () => setIsDropdownExpanded(false)
  const toggleDropdown = () => setIsDropdownExpanded(!isDropdownExpanded)

  const selectedSection = getSelectedSection(props)
  const selectedMenuPage = getSelectedPage(props)

  const RenderSections = (elementProps: RenderSectionProps) =>
    Sections(elementProps, props, selectedMenuPage, selectedSection, closeDropdown)

  useLayoutEffect(() => {
    window.addEventListener('toggleNavigationMenuVisibility', closeDropdown)

    return () => window.removeEventListener('toggleNavigationMenuVisibility', closeDropdown)
  }, [])

  const [isWrapped, setIsWrapped] = useState<boolean>()
  const onWrapChanged = (wrapped: boolean) => {
    setIsWrapped(wrapped)
  }

  const topMenuWrapped = useHasTopMenuWrapped()
  const isTopMenuWrappedWithNoCategory = topMenuWrapped && !props.menuCategory

  return (
    <>
      {!isTopMenuWrappedWithNoCategory && props.showMenu && selectedMenuPage && (
        <div data-testid={'sections-menu'}>
          <RenderSections
            name='menu-sections-list-horizontal'
            className={less.sections}
            style={{ visibility: isWrapped ? 'hidden' : 'visible' }}
            onWrapChanged={onWrapChanged}
          />
          <section
            className={less.dropdown}
            style={{
              visibility: isWrapped ? 'visible' : 'hidden',
            }}
          >
            <div onClick={toggleDropdown} className={isDropdownExpanded ? less.active : undefined} role='button'>
              <p>{selectedSection && selectedSection.media.name}</p>
              <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={'white'} direction={'down'} />
              <div>
                <RenderSections />
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  )
}

const Sections = (
  elementProps: RenderSectionProps,
  props: MenuNavigationProps,
  selectedMenuPage?: MenuPage | null | undefined,
  selectedSection?: MenuSection | null | undefined,
  onItemClicked?: (code: string) => void,
) => {
  let selectedCode: string

  useCheckForListWrapping(elementProps.name, elementProps.onWrapChanged)

  const menuNavSectionClicked = (code: string) => {
    selectedCode = code
    if (onItemClicked) {
      onItemClicked(code)
    }
  }

  const includeVouchersSubMenu = includeVouchersInSubMenu(selectedMenuPage)

  return (
    <>
      {selectedMenuPage && selectedMenuPage.sections && (
        <ul className={elementProps.className} style={elementProps.style} id={elementProps.name}>
          {selectedMenuPage.sections
            .filter((section) => section.items && !!section.items.length)
            .map((section, index) => {
              if (props.variety && props.menuCategory) {
                // TODO: Fix when products are in multiple selections, not showing the correct selections
                const formattedVariety = convertSpecialCharacters(props.variety)
                const currentVarietyCategory =
                  props.menu && getPortionSectionList(props.menu, [`Variety.${formattedVariety}`])

                if (
                  !currentVarietyCategory ||
                  !currentVarietyCategory[0] ||
                  currentVarietyCategory[0].section.code !== section.code
                ) {
                  return null
                }
              }

              if (section.items.every((i) => i.type === 'Voucher' && !includeVouchersSubMenu)) {
                return null
              }

              return (
                <li
                  key={index}
                  id={section.code}
                  className={
                    (selectedCode === section.code && less.active) ||
                    (selectedSection && section.code === selectedSection.code ? less.active : undefined)
                  }
                >
                  <MenuNavSection code={section.code} media={section.media} onItemClicked={menuNavSectionClicked} />
                </li>
              )
            })}
        </ul>
      )}
    </>
  )
}
