import { rootActions } from '@dominos/business/root.actions'
import i18next from 'i18next'
import { ActionsObservable, Epic, StateObservable } from 'redux-observable'
import { filter, ignoreElements, tap, withLatestFrom } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

export const setLanguageEpic: Epic = (action$: ActionsObservable<RootActions>, store$: StateObservable<RootReducer>) =>
  action$.pipe(
    filter(
      isActionOf([rootActions.updateLanguage, rootActions.checkoutFromNativeApp, rootActions.appStartedFromNativeApp]),
    ),
    withLatestFrom(store$),
    tap(([action, store]) => {
      i18next.changeLanguage(`${action.payload.language}-${store.applicationReducer.countryCode}`)
      document.documentElement.lang = action.payload.language
    }),
    ignoreElements(),
  )
