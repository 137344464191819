import { FilterProductItem } from '@dominos/business/functions/menu/filter-product-item.interface'
import { MenuPageHeader, useMenu } from '@dominos/hooks-and-hocs'
import { getStateFromNavigation } from '@dominos/navigation'
import { useLocation } from '@reach/router'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFilteredMenuSections } from './filter-menu-by-product-filters'

type Menu = Bff.Menu.old.Menu
type MenuPage = Bff.Menu.old.MenuPage

export const COUPON_MENU_CODE = 'Menu.Coupon'

const getFullMenuPages = (menu: Menu | undefined, menuPages: MenuPageHeader[] | undefined) =>
  menu && menu.pages
    ? menu.pages.map((menuItem) => ({ code: menuItem.code, media: menuItem.media } as MenuPageHeader))
    : menuPages

const getFullMenu = (menu: Menu, productFilters: FilterProductItem[], t: TFunction): Menu => {
  const couponMenu: MenuPage = {
    code: COUPON_MENU_CODE,
    media: { name: t('couponMenu') },
    disclaimers: menu.pages[0]?.disclaimers,
    legends: menu.pages[0]?.legends,
    sections: getFilteredMenuSections(
      menu.pages.flatMap((page) => page.sections!),
      productFilters,
    ),
  }

  return {
    ...menu,
    pages: [...menu.pages, couponMenu],
  }
}

export const useMenuWithCouponPage = () => {
  const { t } = useTranslation('menu')
  const location = useLocation()
  const { loadMenu, menu, menuPages, storeNo } = useMenu()
  const [menuResult, setMenuResult] = useState<Menu>()
  const [menuPagesResult, setMenuPagesResult] = useState<MenuPageHeader[]>()

  useEffect(() => {
    if (menu && menuPages) {
      const filterProductItems = getStateFromNavigation<FilterProductItem[]>(location, 'filterList')

      if (filterProductItems) {
        const fullMenu = getFullMenu(menu, filterProductItems, t)
        const fullMenuPages = getFullMenuPages(fullMenu, menuPages)
        setMenuResult(fullMenu)
        setMenuPagesResult(fullMenuPages)
      } else {
        setMenuResult(menu)
        setMenuPagesResult(menuPages)
      }
    } else {
      setMenuResult(menu)
      setMenuPagesResult(menuPages)
    }
  }, [menu, menuPages, location])

  return {
    loadMenu,
    menu: menuResult,
    menuPages: menuPagesResult,
    storeNo,
  }
}
