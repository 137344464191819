import { StaticSvgIcon } from '@dominos/res/images/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ServiceMethodDetailsProps } from '../../service-method-details-definitions'

import css from './call-store.less'

export const CallStore = ({ storePhoneNo, icon, title, message }: ServiceMethodDetailsProps) => {
  const { t } = useTranslation('tracker')

  return (
    <div className={css.container}>
      <div className={css.messageContainer}>
        <StaticSvgIcon name={icon} isUnstyled fill={'#414141'} />
        <div className={css.messageBody}>
          <p className={css.title}>{t(title.key, { defaultValue: title.defaultValue })}</p>
          <p>{t(message.key, { defaultValue: message.defaultValue })}</p>
        </div>
      </div>
      {storePhoneNo && (
        <div data-testid='service-method-contact'>
          <a href={`tel:${storePhoneNo.replace(/\s+/g, '')}`}>
            <StaticSvgIcon name='callStore' isUnstyled fill={'#1fc36a'} />
          </a>
        </div>
      )}
    </div>
  )
}
