import { useApolloClient } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions/validation'
import { deliveryStreetsQuery, deliverySuburbsQuery } from '@dominos/business/queries'
import { FancyDropdownField, fancyDropdownFieldFetchDataHelper, ValidationTextField } from '@dominos/components'

const STREET_NO_MAX_LENGTH = 12
const POST_CODE = 'postCode'
const STREET = 'street'
const DEFAULT_NO_STREET_FOUND_ERROR = `We are sorry, this delivery address is not recognized by our system. Check the address provided, you can start the search again or order takeout.`

export const DeliveryAddressFrLayout = (props: DeliveryAddressLayoutBaseProps) => {
  const [postCodeSelectedItem, setPostCodeSelectedItem] = useState<Bff.Addresses.DeliverySuburb>()
  const [streetValue, setStreetValue] = useState<string>('')
  const [showStreetError, setShowStreetError] = useState(false)

  const client = useApolloClient()
  const { t } = useTranslation('delivery-address')
  const { isRequired, maxLength } = useValidation()

  useEffect(() => {
    props.onInitialise({
      [POST_CODE]: {
        isValid: false,
      },
      streetNo: {
        isValid: false,
      },
      [STREET]: {
        isValid: false,
      },
    })
  }, [])

  const fetchSuburbData = fancyDropdownFieldFetchDataHelper<Bff.Addresses.DeliverySuburb>(
    client,
    (value: string) => ({
      query: deliverySuburbsQuery,
      variables: {
        name: value,
      },
    }),
    'deliverySuburbs',
    (address) => `${address.postCode} ${address.name}`,
  )

  const fetchStreetData = fancyDropdownFieldFetchDataHelper<Bff.Addresses.DeliveryStreet>(
    client,
    (value: string) => ({
      query: deliveryStreetsQuery,
      variables: {
        suburb: postCodeSelectedItem && postCodeSelectedItem.name,
        postCode: postCodeSelectedItem && postCodeSelectedItem.postCode,
        street: value,
      },
    }),
    'deliveryStreets',
    (street) => street.name,
    (items) => {
      if (items.length === 0) {
        setShowStreetError(true)
      }
    },
  )

  const cityOrPostalCodeOnChange = (value: string) => {
    setPostCodeSelectedItem(undefined)
    setStreetValue('')
    props.onChange({
      [POST_CODE]: {
        value,
        isValid: false,
      },
      [STREET]: {
        value: '',
        isValid: false,
      },
    })
  }

  const cityOrPostalCodeOnItemSelected = (data: KeyValuePair<Bff.Addresses.DeliverySuburb>) => {
    setPostCodeSelectedItem(data.key)
    props.onChange({
      [POST_CODE]: {
        value: data.key.postCode,
        isValid: true,
      },
    })
  }

  const streetOnChange = (value: string) => {
    setShowStreetError(false)
    setStreetValue(value)
    props.onChange({
      [STREET]: {
        value,
        isValid: false,
      },
    })
  }

  const streetOnItemSelect = (data: KeyValuePair<Bff.Addresses.DeliveryStreet>) => {
    setStreetValue(data.value)
    props.onChange({
      [STREET]: {
        value: data.value,
        isValid: true,
      },
    })
  }

  return (
    <>
      <FancyDropdownField
        testID='CityOrPostalCode.field'
        style={{ width: '100%' }}
        fetchData={fetchSuburbData}
        placeholder={t('City or Postal Code')}
        onChange={cityOrPostalCodeOnChange}
        onItemSelect={cityOrPostalCodeOnItemSelected}
      />
      <ValidationTextField
        fieldName='streetNo'
        style={{ width: '100%' }}
        placeholder={t('Street Number')}
        testID={'streetNo.field'}
        showAsteriskWhenRequired
        validationRules={[isRequired, maxLength(STREET_NO_MAX_LENGTH)]}
        onChange={props.onChange}
      />
      <FancyDropdownField
        testID='street.field'
        style={{ width: '100%' }}
        fetchData={fetchStreetData}
        disabled={!postCodeSelectedItem}
        placeholder={t('Street')}
        onChange={streetOnChange}
        onItemSelect={streetOnItemSelect}
        value={streetValue}
        errorMessage={
          showStreetError
            ? t('France Empty Address Result Alert', { defaultValue: DEFAULT_NO_STREET_FOUND_ERROR })
            : undefined
        }
      />
      <label style={styles}>
        {t('FR delivery address info', {
          defaultValue:
            'Delivery Instructions (floor, digital code, etc.) may be added during the validation of your order',
        })}
      </label>
    </>
  )
}

const styles = {
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '13px',
}
