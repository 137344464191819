import { DetailRow } from '@dominos/components/basket/detail-row'
import React from 'react'

interface OrderAdjustmentsProps {
  adjustments: BasketOrderAdjustment[]
  testId?: string
}

const OrderAdjustments = ({ adjustments, testId }: OrderAdjustmentsProps) => (
  <>
    {adjustments.map((adjustment) => (
      <DetailRow
        key={adjustment.name}
        testID={`${testId}.order-adjustment.${adjustment.code.replace(/\s/g, '')}`}
        label={adjustment.name}
        price={adjustment.amount}
        fontWeight={'normal'}
      />
    ))}
  </>
)

export { OrderAdjustments }
