import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import React from 'react'
import styles from './half-half-button-card.less'
interface HalfHalfButtonCardProps {
  buttonTitle: string
  testID: string
  icon?: string
  onClick: () => void
}
const HalfHalfButtonCard: React.FC<React.PropsWithChildren<HalfHalfButtonCardProps>> = (props) => {
  const theme = useDominosTheme()

  return (
    <section
      className={styles.section}
      style={{
        borderRadius: theme.webLargeBorderRadius,
        backgroundColor: theme.colours.defaultBackground,
      }}
      data-testid={`${props.testID || 'HalfHalfButtonCard'}-container`}
    >
      <header
        className={styles.container}
        style={{ cursor: 'pointer' }}
        onClick={props.onClick}
        role={props.buttonTitle}
        data-testid={`${props.testID || 'HalfHalfButtonCard'}-header`}
      >
        <h3
          className={styles.h3}
          data-testid={`${props.testID || 'HalfHalfButtonCard'}-title`}
          style={{ color: theme.colours.hyperlink }}
        >
          {props.buttonTitle}
        </h3>
        <div className={styles.circleHalfEmptyRight}>
          <StaticSvgIcon name={'circleHalfEmptyRight'} width={22} height={22} />
        </div>
      </header>
    </section>
  )
}
export { HalfHalfButtonCard }
