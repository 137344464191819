import { DominosTheme } from '@dominos/hooks-and-hocs/theme'

export const getBackgroundColor = (theme: DominosTheme, props: ActionButtonProps) => {
  let backgroundColor = theme.colours.actionStandard
  if (props.containerStyle && props.containerStyle.backgroundColor) {
    backgroundColor = props.containerStyle.backgroundColor
  }
  if (props.disabled) {
    backgroundColor = props.disabledBackgroundColor || theme.colours.disabled
  }
  if (props.error) {
    backgroundColor = theme.colours.defaultError
  }

  return backgroundColor
}

export const getTextColor = (theme: DominosTheme, props: ActionButtonProps) => {
  let color = 'white'
  if (props.textStyle && props.textStyle.color) {
    color = props.textStyle.color
  }
  if (props.disabled) {
    color = props.disabledTextColor || 'white'
  }
  if (props.error) {
    color = 'white'
  }

  return color
}
