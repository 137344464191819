type NullableNumber = number | null

const getPrecisionFixedValue = (value: number) => parseFloat(value.toPrecision(10))

const getDonationAmountWithNearest = (unit: number) => (total: NullableNumber) => {
  if (!total) {
    return 0
  }

  return Math.floor(total / unit + 1) * unit
}

const getRoundUpAmountWith = (getTotalWithDonation: (total: NullableNumber) => number) => (total: NullableNumber) => {
  if (!total) {
    return 0
  }

  return getPrecisionFixedValue(getTotalWithDonation(total)! - total)
}
const getRoundUpAlgorithm = (unit: number) => getRoundUpAmountWith(getDonationAmountWithNearest(unit))

export { getRoundUpAlgorithm, getRoundUpAmountWith, getDonationAmountWithNearest, getPrecisionFixedValue }
