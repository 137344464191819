import React from 'react'
import { DetailRow } from '../../detail-row'

interface RowProps extends BaseProps {
  description?: string | null
  label?: string
  price?: number
  testID: string
}

const Row = ({ description, label, price, testID }: RowProps) => {
  if (description) {
    return <DetailRow testID={testID} label={label} description={description} price={price} />
  }

  return null
}

export default Row
