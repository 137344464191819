import React, { useState } from 'react'

import { ActionButton, CollapsableTitledCard, Error, PopUpNotification } from '@dominos/components'
import { FeedbackSubmittedIcon } from '@dominos/res/images/icons/components'
import { useTranslation } from 'react-i18next'
import { questionWasAnswered } from '../order-feedback/utils'
import { getValidationErrorMessage, selectFeedbackMechanism } from '../order-feedback'
import { useFeedback } from '@dominos/hooks-and-hocs/feedback/use-feedback'
import { loadingQuestionsErrors, submitQuestionsErrors } from '../order-feedback/order-feedback-errors'
import { useBreakpoints } from '@dominos/hooks-and-hocs'
import styles from './experience-feedback.less'

const errorDefinitions = { ...loadingQuestionsErrors, ...submitQuestionsErrors }

interface ExperienceFeedbackProps extends BaseProps {
  feedbackType: string | undefined
  orderId: string
  serviceMethod?: BffContext.ServiceMethods
}

export const ExperienceFeedbackCard = ({
  testID = 'experience-feedback',
  feedbackType = 'OloExperience',
  orderId,
  serviceMethod,
}: ExperienceFeedbackProps) => {
  const { t } = useTranslation('tracker')
  const { isMobile } = useBreakpoints()
  const [completed, setCompleted] = useState(false)
  const [alertFeedbackSubmitted, setAlertFeedbackSubmitted] = useState<boolean>(false)

  const { questions, feedback, loading, errors, submitFeedback, shouldValidate, selectFeedback } = useFeedback({
    orderId,
    feedbackTypes: [feedbackType],
    serviceMethod,
  })

  const closePopupNotification = () => setAlertFeedbackSubmitted(false)

  const feedbackCardStyle: CommonViewStyle = {
    marginTop: '8px',
    textAlign: isMobile ? 'left' : 'center',
    paddingBottom: '8px',
  }

  const submitButtonStyle: CommonViewStyle = {
    minWidth: '200px',
  }

  const handleSubmit = () => {
    submitFeedback().then((result) => {
      setAlertFeedbackSubmitted(result)
      setCompleted(result)
    })
  }

  if (questions.length === 0) {
    return null
  }

  return (
    <CollapsableTitledCard
      testID={testID}
      primaryTitle={t('ExperienceFeedbackTitle', { defaultValue: `Rate your ordering experience` })}
      style={feedbackCardStyle}
    >
      {alertFeedbackSubmitted && (
        <PopUpNotification
          heading={t('ExperienceFeedbackSubmittedNotification', { defaultValue: 'Feedback Submitted' })}
          onClose={closePopupNotification}
        />
      )}

      {errors.map((error, idx) => (
        <Error tns={t} key={`error-${idx}`} errorDefinitions={errorDefinitions} error={error ?? null} />
      ))}

      {completed ? (
        <div data-testid={`${testID}.success`} className={styles.completed}>
          <FeedbackSubmittedIcon />
          <div className={styles.message}>
            <h3>{t('ExperienceFeedbackSubmittedNotification', { defaultValue: 'Feedback Submitted' })}</h3>
            <p>
              {t('ExperienceFeedbackSubmittedDescription', {
                defaultValue: 'Thanks for taking the time to rate your Domino’s Experience.',
              })}
            </p>
          </div>
        </div>
      ) : (
        questions.map((question) => {
          const errorMessage =
            shouldValidate && !questionWasAnswered(question, feedback[question.questionId])
              ? getValidationErrorMessage(t, question)
              : undefined

          return selectFeedbackMechanism(question, selectFeedback, errorMessage)
        })
      )}

      {!completed && (
        <div className={styles.buttonWrapper}>
          <ActionButton
            testID='experience-feedback-submit-button'
            containerStyle={submitButtonStyle}
            text={t('ExperienceFeedbackButton', { defaultValue: 'Submit Feedback' })}
            loading={loading}
            onPress={handleSubmit}
          />
        </div>
      )}
    </CollapsableTitledCard>
  )
}
