import React from 'react'

import { getFormattedPrice } from '@dominos/business/functions/text'
import css from './size-icon.less'

import { useApplicationSettings } from '@dominos/hooks-and-hocs'
import { DEFAULT_SLATE_COLOUR, FONT_WEIGHT, HIGHLIGHT_COLOUR } from '../style-constants'
import { mapCountryAndSizeCodeToIcon } from './map-size-icons'

export const SizeIcon = ({ code, name, price, selected }: SizeIconProps) => {
  const settings = useApplicationSettings()
  const countryCode = settings.countryCode
  const color = selected ? HIGHLIGHT_COLOUR : DEFAULT_SLATE_COLOUR

  const SizeSvg = mapCountryAndSizeCodeToIcon(countryCode!, code)

  return (
    <div className={css.container}>
      <div className={css.iconContainer}>{SizeSvg && <SizeSvg color={color} />}</div>
      <p
        data-testid={`size.name.${name}`}
        className={css.name}
        style={{ color, fontWeight: selected ? FONT_WEIGHT : 'normal' }}
      >
        {name}
      </p>
      {price && price !== 0 ? (
        <p data-testid={`size.price.${name}`} className={css.price} style={{ color }}>
          {`${price > 0 ? '+' : '-'}${getFormattedPrice(Math.abs(price))}`}
        </p>
      ) : null}
    </div>
  )
}
