import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { detailsFieldNames } from '../details-container-config'
import { VipOffersCheckbox, VipOffersOptOutAllTC, VipOffersProps } from '.'
import styles from './vip-offers.less'

export const VipOffersOptOutAll = (props: VipOffersProps) => {
  const { t } = useTranslation('checkout')

  useEffect(() => {
    props.onChange(detailsFieldNames.vipOffersOptOutAll, !!props.initialVipOffersOptOutAll)
  }, [])

  return (
    <div className={styles.optOutAllContainer}>
      <VipOffersCheckbox
        testID={`vip-offers-opt-out.${detailsFieldNames.vipOffersOptOutAll}`}
        onChange={props.onChange}
        defaultChecked={props.initialVipOffersOptOutAll}
        name={detailsFieldNames.vipOffersOptOutAll}
        label={t('VIPOffersOptOut', { defaultValue: 'Do not get VIP Offers via **email** and **SMS**' })}
        labelClassName={styles.checkboxLabel}
      />
      {props.vipOffersTCEnabled && <VipOffersOptOutAllTC testId={props.testID} />}
    </div>
  )
}
