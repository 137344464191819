import { Layouts } from './layout.interfaces'
import { Language } from '@dominos/hooks-and-hocs/languages'

export const getLayoutDisplayName = (language: Language, layoutName: string) =>
  layoutName === Layouts.Hiragana
    ? { short: 'あ', displayName: `${language.name} (あ)` }
    : layoutName === Layouts.Katakana
    ? { short: 'カ', displayName: `${language.name} (カ)` }
    : {
        short: language.langCode.toUpperCase(),
        displayName: `${language.name} (${language.langCode.toUpperCase()})`,
      }
