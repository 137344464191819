import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 45,
  height: 74,
}

export const Gauge5 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 45 74'>
      <defs>
        <path
          d='M67.7232174,143.37882 L67.7232174,135.67882 C67.7232174,134.17882 68.6232174,132.77882 70.0232174,132.17882 L80.9232174,127.67882 C82.0232174,127.17882 83.2232174,127.97882 83.2232174,129.17882 L83.2232174,136.47882 C83.2232174,136.77882 83.4232174,136.97882 83.7232174,136.97882 L92.5232174,136.97882 C92.8232174,136.97882 93.0232174,136.77882 93.0232174,136.47882 L93.0232174,121.57882 C93.0232174,121.27882 92.8232174,121.07882 92.5232174,121.07882 L70.6232174,121.07882 C69.2232174,121.07882 68.1232174,119.97882 68.1232174,118.57882 L68.1232174,83.3788198 C68.1232174,81.9788198 69.2232174,80.8788198 70.6232174,80.8788198 L106.023217,80.8788198 C107.423217,80.8788198 108.523217,81.9788198 108.523217,83.3788198 L108.523217,92.2788198 C108.523217,93.6788198 107.423217,94.7788198 106.023217,94.7788198 L84.1232174,94.7788198 C83.8232174,94.7788198 83.6232174,94.9788198 83.6232174,95.2788198 L83.6232174,105.67882 C83.6232174,105.97882 83.8232174,106.17882 84.1232174,106.17882 L101.023217,106.17882 C105.123217,106.17882 108.523217,109.57882 108.523217,113.67882 L108.523217,143.37882 C108.523217,147.47882 105.123217,150.87882 101.023217,150.87882 L75.2232174,150.87882 C71.1232174,150.87882 67.7232174,147.47882 67.7232174,143.37882 Z'
          id='gauge-path-5'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Points5' transform='translate(-66.000000, -79.000000)' fillRule='nonzero'>
          <g id='Path'>
            <use fill={fill} href='#gauge-path-5' />
            <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-5' />
          </g>
        </g>
      </g>
    </svg>
  )
}
