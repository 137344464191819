import { Bff as BffErrors } from 'bff-errors'
import { ErrorDefinition, getDefaultValue } from '@dominos/components/error'

export const paymentErrors: PartialRecord<keyof typeof BffErrors.Errors, ErrorDefinition> = {
  [BffErrors.Errors.BAD_USER_INPUT]: {
    displayType: 'modal',
    translation: {
      title: { key: 'BadUserInputTitle', getOptions: getDefaultValue },
      message: { key: 'BadUserInputMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.GRAPHQL_VALIDATION_FAILED]: {
    displayType: 'modal',
    translation: {
      title: { key: 'GraphQlValidationFailedTitle', getOptions: getDefaultValue },
      message: { key: 'GraphQlValidationFailedMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.UNAUTHENTICATED]: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnauthenticatedTitle', getOptions: getDefaultValue },
      message: { key: 'UnauthenticatedMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.UNABLE_TO_PLACE]: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnableToPlaceTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToPlaceMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.UNABLE_TO_INITIALISE_ORDER]: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnableToInitialiseOrderTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToInitialiseOrderMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.REVALIDATE_ORDER]: {
    displayType: 'modal',
    translation: {
      title: { key: 'RevalidateOrderTitle', getOptions: getDefaultValue },
      message: { key: 'RevalidateOrderMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_NOT_APPROVED]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentNotApprovedTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentNotApprovedMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_PROVIDER_UNKNOWN]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentProviderUnknownTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentProviderUnknownMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_TIMEOUT]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentTimeoutTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentTimeoutMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_INSUFFICIENT]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentInsufficientTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentInsufficientMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_VALIDATION]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentValidationTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentValidationMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_NOT_PROCESSED]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentNotProcessedTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentNotProcessedMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_DETAILS_INCORRECT]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentDetailsIncorrectTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentDetailIncorrectMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.UNABLE_TO_CONTACT_PAYMENT_PROVIDER]: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnableToContactPaymentProviderTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToContactPaymentProviderMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.UNABLE_TO_CONTACT_STORE]: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnableToContactStoreTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToContactStoreMessage', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.UNABLE_TO_CONTACT_STORE_TO_PRICE]: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnableToContactStoreToPriceTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToContactStoreToPriceMessage', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.UNABLE_TO_PRICE_AT_STORE]: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnableToPriceAtStoreTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToPriceAtStoreMessage', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.UNABLE_TO_PLACE_AT_STORE]: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnableToPlaceAtStoreTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToPlaceAtStoreMessage', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.ORDER_ALREADY_PLACED]: {
    displayType: 'none',
    translation: {
      title: { key: 'OrderAlreadyPlacedTitle', getOptions: getDefaultValue },
      message: { key: 'OrderAlreadyPlacedMessage', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_DETAILS_EXPIRED]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentDetailsExpiredTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentDetailsExpiredMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
  [BffErrors.Errors.PAYMENT_SESSION_EXPIRED]: {
    displayType: 'modal',
    translation: {
      title: { key: 'PaymentSessionExpiredTitle', getOptions: getDefaultValue },
      message: { key: 'PaymentSessionExpiredMessage', getOptions: getDefaultValue },
      confirmLabel: { key: 'TryAgainConfirm', getOptions: getDefaultValue },
    },
    icon: 'paymentError',
    testID: 'payment-error',
  },
}
