import { MyDetailsModelProps } from '@dominos/interfaces'
import { PayPalButtonsComponentProps } from '@paypal/react-paypal-js'

interface IPayPalState {
  myDetails?: MyDetailsModelProps
  orderId?: string
  paymentToken?: string
  paymentId?: string
  paymentAmount?: number
  error?: boolean
}

interface IOrderFunctions {
  initiateOrder: (payment: Bff.Orders.InitialiseOrderPayment, myDetails: MyDetailsModelProps) => Promise<unknown>
  placeOrder: (orderId: string, payment: Bff.Orders.PlaceOrder.Payment) => Promise<void>
  onError: NonNullable<PayPalButtonsComponentProps['onError']>
}

export interface IPayPalEvents {
  paymentDetails?: { PayerID: string | null | undefined; token: string }
  onApprove: NonNullable<PayPalButtonsComponentProps['onApprove']>
  onError: NonNullable<PayPalButtonsComponentProps['onError']>
  createOrder: () => Promise<string>
  setState: (state: Partial<IPayPalState>) => void
  state: IPayPalState
  payment: Bff.Orders.InitialiseOrderPayment
  onStateChange?: () => void
  placeOrder: () => Promise<void>
  canPlaceOrder: boolean
  orderFunctions: IOrderFunctions
  setOrderFunctions: (param: IOrderFunctions) => void
}

export const createPaypalEvents = (orderFunctions: IOrderFunctions, providerCode: BffContext.PaymentProviders) => {
  const events: IPayPalEvents = {
    orderFunctions: orderFunctions,
    setOrderFunctions(functions): void {
      events.orderFunctions = functions
    },
    state: {},
    payment: {
      paymentMethod: 'PayPal',
      providerCode: providerCode,
      amount: 0,
      // it seems the backend doesn't use this but does require it.
      properties: [
        {
          key: 'returnUrl',
          value: '',
        },
        {
          key: 'cancelUrl',
          value: '',
        },
      ],
    },
    onStateChange: undefined,
    setState(state: Partial<IPayPalState>) {
      this.state = { ...events.state, ...state }
      if (this.onStateChange) this.onStateChange()
    },
    /**
     * Called by the PayPal sdk when the button is clicked
     * Note: Because of how orderInitiation is set up we can not rely on the promise to throw errors.
     * That's why we check the error state here
     */
    createOrder() {
      events.paymentDetails = undefined
      events.payment.amount = events.state.paymentAmount || 0
      const oldPaymentToken = events.state.paymentToken

      return new Promise((resolve, reject) => {
        events.orderFunctions.initiateOrder(events.payment, events.state.myDetails!).catch(reject)

        events.onStateChange = () => {
          if (events.state.error) {
            events.onStateChange = undefined
            reject()

            return
          }
          if (events.state.paymentToken === oldPaymentToken || !events.state.paymentToken) return

          events.onStateChange = undefined

          return resolve(events.state.paymentToken)
        }
        events.onStateChange()
      })
    },

    /**
     * Called by the PayPal sdk when the payment has been approved
     */
    onApprove: ({ orderID, payerID }) => {
      events.paymentDetails = { token: orderID, PayerID: payerID }

      return events.placeOrder()
    },

    get canPlaceOrder() {
      return !!events.paymentDetails
    },

    placeOrder: () =>
      events.orderFunctions.placeOrder(events.state.orderId!, {
        properties: [
          {
            key: 'details',
            value: JSON.stringify(events.paymentDetails),
          },
        ],
        transactionToken: events.state.paymentToken,
        orderPaymentId: events.payment.orderPaymentId || undefined,
        paymentMethod: 'PayPal',
        providerCode: providerCode,
        amount: events.payment.amount,
      }),

    onError: (err) => events.orderFunctions.onError(err),
  }

  return events
}
