export class IdleTimer {
  private timer: NodeJS.Timeout
  private timeout: number
  private cb: () => void

  constructor(callback: () => void, timeoutPeriod: number) {
    this.cb = callback
    this.timeout = timeoutPeriod
    this.timer = this.start()
  }

  public reset() {
    clearTimeout(this.timer)
    this.timer = this.start()
  }

  public start() {
    return setTimeout(this.cb, this.timeout)
  }

  public clear() {
    clearTimeout(this.timer)
  }
}
