import React, { useState } from 'react'
import { CollapsableTitledCard } from '@dominos/components'
import { mockBasketAddons } from '@dominos/components/hard-upsell/__test__/mock-basket-addons-data'
import { ComboUpsellContainerProps, ComboUpsellItem } from './types'
import ComboUpsellProductCard from './combo-upsell-product-card/combo-upsell-product-card'
import { useFeatures } from '@dominos/hooks-and-hocs'
import { productIngredientsTransform } from '@dominos/business/functions'

export const ComboUpsellContainer = ({ testID, item }: ComboUpsellContainerProps) => {
  const [offerItems, setOfferItems] = useState<ComboUpsellItem[]>(mockBasketAddons.items)
  const [isEditToppingExpandedByDefault] = useFeatures('ExpandEditToppingsByDefault')
  const currentSize = item?.sizes?.find(({ code }) => code === item.defaultSize)
  const { currentIngredients: ingredients } = productIngredientsTransform([], currentSize)
  // This will check whether the edit topping is already expanded or not
  const shouldCollapsedByDefault = ingredients.length > 0 && isEditToppingExpandedByDefault

  const handleCheckChange = (index: number) => {
    setOfferItems((prevItems) =>
      prevItems?.map((item, i) => (i === index ? { ...item, isSelected: !item.isSelected } : item)),
    )
  }

  return (
    <CollapsableTitledCard
      testID={`${testID}.card`}
      primaryTitle={'Add ons'}
      noPadding={false}
      startCollapsed={shouldCollapsedByDefault}
    >
      <div data-testid={`${testID}.product-container`}>
        {offerItems?.map((item, index) => (
          <ComboUpsellProductCard
            testID={`${testID}.item.${item.id}`}
            offerItem={item}
            key={index}
            onSelected={() => handleCheckChange(index)}
          />
        ))}
      </div>
    </CollapsableTitledCard>
  )
}
