import { getImages } from '@dominos/business/functions'
import { isProductMenuItem } from '@dominos/components'

export type PortionProductModel = {
  code: string
  imageUri: string
  name: string
}

export const appendMediaToPortionPossibleProducts = (
  itemsByCode: MenuItemDependentsDictionary | null | undefined,
  portionPossibleProducts: Bff.Products.PortionPossibleProduct[],
): PortionProductModel[] => {
  const itemsList: ProductMenuItem[] = Object.values(itemsByCode ?? {}).filter(isProductMenuItem)

  return itemsList
    .filter((item) => portionPossibleProducts.some((portion) => portion.code === item.code))
    .map((item) => ({
      code: item.code,
      name: item.media?.name ?? '',
      imageUri: getImages(item).productImage.fullUrl ?? '',
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
}
