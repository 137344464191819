import React from 'react'

import { MDTargetBlankAnchor, Modal } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import css from './more-info-popup.less'
import { MoreInfoPopupProps } from './more-info-popup.interface'
import Markdown from 'markdown-to-jsx'

const iconSize = 21
const icon = 'cross'

export const MoreInfoPopup = (props: MoreInfoPopupProps) => (
  <Modal testID={props.id} onModalClose={props.onDismiss}>
    <div data-testid={props.testID} className={css.card}>
      <div className={css.layout}>
        <h4 data-testid={`${props.testID}.title`} className={css.title}>
          {props.title}
        </h4>
        <div
          data-testid={`${props.testID}.dismiss`}
          role='button'
          aria-label={props.dismissLabel ?? 'closeButton'}
          className={css.close}
          onClick={props.onDismiss}
        >
          <StaticSvgIcon name={icon} width={iconSize} height={iconSize} isUnstyled />
        </div>

        <Markdown
          data-testid={`${props.testID}.body`}
          className={css.body}
          options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}
        >
          {props.body}
        </Markdown>
      </div>

      {props.media && props.media.src && (
        <div className={css.image}>
          <img data-testid={`${props.testID}.image`} src={props.media.src} alt={props.media.alt} />
        </div>
      )}
    </div>
  </Modal>
)
