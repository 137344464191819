type MenuPage = Bff.Menu.old.MenuPage
type MenuSection = Bff.Menu.old.MenuSection

export const flattenMenuToSections = (pages: MenuPage[]): MenuSection[] => {
  let memo: MenuSection[] = []

  if (pages == null) {
    return []
  }

  pages.forEach((page) => {
    if (page.sections) {
      memo = [...memo, ...page.sections]
    }
  })

  return memo
}

export const getCorrectMenuSectionForMenuIndex = (
  pages: MenuPage[],
  sections: ResolvedMenuSection[] | null,
  pageIndex?: number,
): number => {
  if (!pageIndex) {
    return 0
  }
  const page = pages[pageIndex]
  if (page && page.sections && page.sections[0] && sections) {
    const code = page.sections[0].code

    return sections.findIndex((section: ResolvedMenuSection) => section.code === code)
  }

  return 0
}
