import {
  Badge1,
  Badge2,
  Badge3,
  Badge4,
  Badge5,
  Badge6,
  Badge7,
  Badge8,
  Badge9,
  Badge9Plus,
  Gauge0,
  Gauge1,
  Gauge2,
  Gauge3,
  Gauge4,
  Gauge5,
  Gauge6,
  Gauge7,
  Gauge8,
  Gauge9,
} from '.'

export const rewardsGaugeGlyphs = [Gauge0, Gauge1, Gauge2, Gauge3, Gauge4, Gauge5, Gauge6, Gauge7, Gauge8, Gauge9]

export const rewardsBadgeGlyphs = [Badge1, Badge2, Badge3, Badge4, Badge5, Badge6, Badge7, Badge8, Badge9, Badge9Plus]
