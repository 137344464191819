import { ApolloError } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAlert } from '@dominos/hooks-and-hocs'

import css from './error-handler.less'

export const ErrorHandler = (props: { apolloError?: ApolloError }) => {
  const { t } = useTranslation('login')
  const { showAlert } = useAlert()
  const [showErrorText, setShowErrorText] = useState(false)

  const handleError = (error: ApolloError) => {
    const isBffError = error?.graphQLErrors && error?.graphQLErrors[0]

    if (isBffError) {
      const errorCode = error?.graphQLErrors && error?.graphQLErrors[0]?.extensions?.code

      switch (errorCode) {
        case 'CREATE_ACCOUNT_FAILED':
          setShowErrorText(true)
          break
        case 'UNABLE_TO_RESOLVE':
          showAlert(
            t('CreateAccountGenericErrorText', {
              defaultValue: 'Something has gone wrong. Please try again',
            }),
            'CreateAccountGenericErrorText',
          )
          break

        case 'BAD_USER_INPUT':
          showAlert(
            t('CreateAccountBackEndValidationErrorText', {
              defaultValue: 'You have entered incorrect information. Please change your details and try again',
            }),
            'CreateAccountBackEndValidationErrorText',
          )
          break
      }
    } else {
      showAlert(
        t('CreateAccountGenericErrorText', {
          defaultValue: 'Something has gone wrong. Please try again',
        }),
        'CreateAccountGenericErrorText',
      )
    }
  }

  useEffect(() => {
    if (!!props.apolloError) {
      handleError(props.apolloError)
    } else {
      setShowErrorText(false)
    }
  }, [props.apolloError])

  return (
    <>
      {showErrorText ? (
        <div data-testid='create-account-error-message' className={css.errorMessage}>
          {t('CreateAccountByEmailAccountExistsError', {
            defaultValue: 'Email account may already exist. Please change and try again',
          })}
        </div>
      ) : null}
    </>
  )
}
