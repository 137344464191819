const checkForTextQuestion = (): boolean => true

const checkForNumberQuestion = (
  question: Bff.Feedback.FeedbackQuestion,
  answer?: Bff.Feedback.FeedbackQuestionResponse,
): boolean => {
  if (!answer) {
    return false
  }

  if (answer.rating === undefined) {
    return false
  }

  const { ratingMin, ratingMax } = question.displaySettings
  const rating = answer.rating

  return rating >= Number(ratingMin) && rating <= Number(ratingMax)
}

const checkForFaceSymbolQuestion = (
  question: Bff.Feedback.FeedbackQuestion,
  answer?: Bff.Feedback.FeedbackQuestionResponse,
): boolean => {
  if (!answer) {
    return false
  }

  if (answer.rating === undefined) {
    return false
  }

  const hasFollowUpQuestion = !!question.feedbackReason
  if (hasFollowUpQuestion) {
    const shouldAnswerFollowUpQuestion = answer.rating < question.feedbackReason!.enableOnRatingLowerThan

    if (shouldAnswerFollowUpQuestion) {
      return !!answer.feedbackReason
    }

    return true
  }

  return true
}

const questionWasAnswered = (
  question: Bff.Feedback.FeedbackQuestion,
  answer?: Bff.Feedback.FeedbackQuestionResponse,
): boolean => {
  const type = question.displaySettings.ratingStyle

  switch (type) {
    case 'Text':
      return checkForTextQuestion()
    case 'Number':
      return checkForNumberQuestion(question, answer)
    case 'FaceSymbol':
      return checkForFaceSymbolQuestion(question, answer)
    default:
      return false
  }
}

export { questionWasAnswered }
