import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const BundleIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <defs>
        <polygon id='path-1' points='0 0 23.8149605 0 23.8149605 6.59521984 0 6.59521984' />
        <polygon id='path-3' points='3.55271368e-15 0 21.9999551 0 21.9999551 22.0074 3.55271368e-15 22.0074' />
      </defs>
      <g id='Pizzas-&amp;-Sandwiches-Copy-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-3' transform='translate(0.000000, 1.824041)'>
          <mask id='mask-2' fill='white'>
            <use xlinkHref='#path-1' />
          </mask>
          <g id='Clip-2' />
          <path
            d='M1.22262409,6.59521984 C5.18858962,5.31184373 8.68370612,4.68765626 11.9079803,4.68765626 C15.131421,4.68765626 18.6265376,5.31184373 22.5925031,6.59521984 L23.5525351,4.86682889 C23.8558785,4.32097737 23.8983799,3.6751225 23.670039,3.09426981 C23.4408647,2.51341711 22.9683489,2.06923564 22.3741625,1.87589586 C18.4781993,0.61418714 15.0547518,0 11.9079803,0 C8.76037535,0 5.3369279,0.61418714 1.4409647,1.87672922 C0.846778226,2.06923564 0.374262475,2.51341711 0.14508817,3.09426981 C-0.0832527751,3.6751225 -0.0407513584,4.32097737 0.262592086,4.86682889 L1.22262409,6.59521984 Z'
            id='Fill-1'
            fill={fill}
            mask='url(#mask-2)'
          />
        </g>
        <g id='Group-3' transform='translate(10.000045, 9.992600)'>
          <mask id='mask-4' fill='white'>
            <use xlinkHref='#path-3' />
          </mask>
          <g id='Clip-2' />
          <path
            d='M3.55271368e-15,16.531 C0.0211338886,19.563 2.48613389,22.008 5.51313389,22.008 L5.55213389,22.008 C7.00413389,21.998 8.39413389,21.415 9.41913389,20.387 L20.3501339,9.457 C22.5241339,7.32 22.5531339,3.824 20.4161339,1.65 C19.3351339,0.551 17.9071339,0 16.4791339,0 C15.0811339,0 13.6831339,0.527 12.6081339,1.584 C12.5861339,1.606 12.5641339,1.628 12.5421339,1.65 L12.5421339,1.654 C11.9751339,2.232 11.5431339,2.93 11.2811339,3.697 C12.4941339,4.34 13.5021339,5.311 14.1911339,6.5 C13.1781339,5.976 12.0761339,5.643 10.9421339,5.519 C9.49313389,5.522 8.10413389,6.097 7.07813389,7.119 C6.51013389,7.696 6.07913389,8.392 5.81613389,9.157 C7.02913389,9.801 8.03713389,10.772 8.72613389,11.96 C7.75413389,11.341 6.62913389,11.001 5.47713389,10.979 C2.43113389,11 -0.0208661114,13.486 3.55271368e-15,16.531'
            id='Fill-1'
            fill={fill}
            mask='url(#mask-4)'
          />
        </g>
        <path
          d='M11.594215,8.06997675 C14.7965689,8.06997675 18.1636765,8.71085116 21.695538,9.9926 C21.481538,10.1636 21.270538,10.3386 21.072538,10.5346 C20.300538,11.3186 19.703538,12.2836 19.345538,13.3246 L19.345538,13.3246 L19.105538,14.0226 C16.8289483,14.5944663 14.9671423,16.2086305 14.0471418,18.3216945 C13.886052,17.4087801 13.087183,16.7152649 12.1271542,16.7152649 C11.048785,16.7152649 10.1737558,17.5902941 10.1737558,18.6686634 C10.1737558,19.1626439 10.3570869,19.6136725 10.6593338,19.9575738 C9.23132435,21.126726 8.61360677,23.1095008 8.61360677,24.4527995 L8.61360677,24.4527995 L8.61370109,24.8215471 C8.61243129,26.0803875 8.57026474,25.803196 7.72330729,23.4790627 C7.53227215,22.9548438 1.44686068,9.9926 1.99886068,9.9926 C5.19340974,8.71085116 8.39186119,8.06997675 11.594215,8.06997675 Z M9.27705924,9.76336653 C7.7670089,9.76336653 6.54280143,10.987574 6.54280143,12.4976243 C6.54280143,14.008508 7.7670089,15.2327155 9.27705924,15.2327155 C10.7871096,15.2327155 12.011317,14.008508 12.011317,12.4976243 C12.011317,10.987574 10.7871096,9.76336653 9.27705924,9.76336653 Z M15.4080969,12.2617831 C14.7614087,12.2617831 14.2363912,12.7859673 14.2363912,13.4334889 C14.2363912,14.0810104 14.7614087,14.6051946 15.4080969,14.6051946 C16.0556185,14.6051946 16.5798027,14.0810104 16.5798027,13.4334889 C16.5798027,12.7859673 16.0556185,12.2617831 15.4080969,12.2617831 Z'
          id='Fill-2'
          fill={fill}
        />
      </g>
    </svg>
  )
}
