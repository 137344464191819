import { VoucherProcessingStatus } from '@dominos/hooks-and-hocs/logging/analytics'
import { VoucherError } from '@dominos/interfaces'
import { getDifferenceBetweenPreviousAndCurrentVoucherCodes } from './get-difference-between-previous-and-current-voucher-codes'

const VALIDATION_ERROR_CODES = [
  '1010',
  '1011',
  '1012',
  '1013',
  '1014',
  '1015',
  '1016',
  '1017',
  '1018',
  '1019',
  '1024',
  '1025',
  '1077',
]

const isRecognisableError = ({ code }: ValidateBasketValidationError) => VALIDATION_ERROR_CODES.includes(code as string)
const convertErrorMessage = (error: ValidateBasketValidationError): VoucherError => ({
  processingStatus: (error.code === '1010' ? 'Expired' : 'Not valid') as VoucherProcessingStatus,
  message: error.message || '',
})

export const getDeclinedVouchers = (validationErrors: ValidateBasketValidationError[] = []) =>
  validationErrors.filter(isRecognisableError).map(convertErrorMessage)

export const getAcceptedVouchers = (basketLines: BasketLines[] = [], previousVoucherCodes: string[]) => {
  const basketCoupons = basketLines.filter((line) => line.type === 'BasketCoupon') as BasketCoupon[]
  const currentVoucherCodes = basketCoupons.map(({ code }) => code)

  const newVoucherCodes: string[] = getDifferenceBetweenPreviousAndCurrentVoucherCodes(
    previousVoucherCodes,
    currentVoucherCodes,
  )

  const acceptedVouchers = basketCoupons.filter((bc) => newVoucherCodes.includes(bc.code))

  return { currentVoucherCodes, acceptedVouchers }
}
