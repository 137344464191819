import { TFunction } from 'i18next'
import { getFormattedPrice } from '@dominos/business/functions/text'

interface RoundUpDisplayAmountFormat {
  useCentFormat: boolean
  zeroFraction: boolean
}

type FormatSettingsType = PartialRecord<BffContext.Countries, RoundUpDisplayAmountFormat> &
  Record<'default', RoundUpDisplayAmountFormat>

const FormatSettings: FormatSettingsType = {
  default: { useCentFormat: false, zeroFraction: false },
  AU: { useCentFormat: true, zeroFraction: true },
  NZ: { useCentFormat: true, zeroFraction: true },
  BE: { useCentFormat: true, zeroFraction: false },
  NL: { useCentFormat: true, zeroFraction: false },
  LU: { useCentFormat: true, zeroFraction: false },
}

export const getFormattedRoundUpAmount = (
  amount: number,
  countryCode: BffContext.Countries | undefined,
  t: TFunction,
) => {
  const setting = FormatSettings[countryCode ?? 'default'] ?? FormatSettings.default

  if (amount < 1 && setting.useCentFormat) {
    return getFormattedPriceInCents(amount, t)
  }

  return getFormattedPrice(amount, setting.zeroFraction && amount % 1 === 0)
}

const getFormattedPriceInCents = (amount: number, t: TFunction) =>
  t('priceInCentFormat', { defaultValue: '{{amount}}¢', amount: (amount * 100).toFixed(0) })
