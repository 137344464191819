// dynamically load script
export interface LoadScriptProps {
  id: string
  setupScriptFunc: (scriptElement: HTMLScriptElement) => void
  documentSection?: 'body' | 'head'
}

export const loadScript = ({ id, setupScriptFunc, documentSection = 'body' }: LoadScriptProps) => {
  const scriptExists = document.getElementById(id)

  if (!scriptExists) {
    const script = document.createElement('script')
    script.id = id
    setupScriptFunc(script)

    document[documentSection].appendChild(script)
  }
}
