import React from 'react'
import { useTranslation } from 'react-i18next'

import { getFormattedPrice } from '@dominos/business/functions'
import classnames from 'classnames'
import { formatLoyaltyPointsText, getDate, mapPointStatusTranslation } from './helpers'
import css from './loyalty-history.less'

export const LoyaltyHistoryItem = ({
  testID,
  pointsStatus,
  orderTotal,
  statusDate,
  timezone,
  points,
}: Bff.Customers.CustomerLoyaltyPointsTransaction & { timezone?: string } & BaseProps) => {
  const { t, i18n } = useTranslation('loyalty')

  const dateFormat = t('LoyaltyHistoryDateFormat', { defaultValue: 'd/M/yy' })

  return (
    <div data-testid={testID} className={classnames(css.cell, css.record)}>
      <div data-testid={`${testID}.status`} className={css.status}>
        {mapPointStatusTranslation(pointsStatus, t)}
      </div>
      <div data-testid={`${testID}.total`} className={css.total}>
        {t('LoyaltyHistoryOrderTotalLabel', { defaultValue: 'Order Total' })}
        {': '}
        <span className={css.price}>{getFormattedPrice(orderTotal)}</span>
      </div>
      <div data-testid={`${testID}.date`} className={css.date}>
        {getDate(statusDate, i18n.language, dateFormat, timezone)}
      </div>
      <div data-testid={`${testID}.points`} className={css.points}>
        {formatLoyaltyPointsText(points)}
      </div>
    </div>
  )
}
