import React, { useRef } from 'react'

import { InputField } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'

import css from './standard-input-field.less'

const DEFAULT_CONTAINER_WIDTH = '112px'
const FILL_CONTAINER_WIDTH = '100%'

export const StandardInputField = (props: StandardInputFieldProps) => {
  const { inputStyle, fill, ...inputProps } = props

  const containerStyles = buildContainerStyles(props)
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div data-testid={props.testID} className={css.container} style={containerStyles}>
      {props.icon && (
        <div data-testid={`${props.testID}.icon`} className={css.iconContainer}>
          <StaticSvgIcon isUnstyled name={props.icon} />
        </div>
      )}
      <InputField {...inputProps} testID={`${props.testID}.input`} style={inputStyle} ref={inputRef} />
    </div>
  )
}

const buildContainerStyles = (props: StandardInputFieldProps) => ({
  width: (props.fill && FILL_CONTAINER_WIDTH) || (props.style && props.style.width) || DEFAULT_CONTAINER_WIDTH,
  ...flattenMargins(props.style),
})

// TODO: Make this smart enough to populate all margin when a top level `margin` is provided
const flattenMargins = (style?: React.CSSProperties) =>
  style && {
    marginRight: (style && style.marginRight) || 0,
  }
