import useIsInitiatingOrder from '@dominos/hooks-and-hocs/selectors/use-is-initiating-order'
import { useIsWhitelistedPath, useKiosk } from '@dominos/hooks-and-hocs'
import { WHITELIST_PATHS_INACTIVITY } from '@dominos/components/kiosk/kiosk-inactivity-config'

export const useKioskStartInactivityTimer = () => {
  const { isKioskOrder } = useKiosk()
  const isInitiatingOrder = useIsInitiatingOrder()
  const isWhitelistedPath = useIsWhitelistedPath({ paths: WHITELIST_PATHS_INACTIVITY })

  return isKioskOrder && !isWhitelistedPath && !isInitiatingOrder
}
