import { ActionButton, PickerButton } from '@dominos/components'
import React from 'react'
import externalStyles from '../../../timed-order-picker-container.less'
import { PickerAndActionButtonProps } from '../../../../interfaces'
import { useOrderLaterDescriptions } from '@dominos/hooks-and-hocs/ordering/use-order-later-descriptions'
import { useOrderLaterDateTimeSelector } from '@dominos/hooks-and-hocs/ordering/use-order-later-date-time-selector'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import css from './picker-and-action-buttons.less'
import { useOrderLaterOrNowDescriptions } from '../use-order-later-or-now-description'

export const PickerAndActionButtons = ({
  openingHours,
  onStartOrderAction,
  serviceMethod,
  language,
  t,
  testID,
}: PickerAndActionButtonProps) => {
  const theme = useDominosTheme()

  const { orderDatePickerTitle, orderDatePlaceHolder, orderTimePickerTitle, orderTimePlaceHolder } =
    useOrderLaterDescriptions(serviceMethod, t)
  const { simplifiedOrderTitle } = useOrderLaterOrNowDescriptions(t)

  const {
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    selectedDateOrderTimes,
    orderDates,
    canStartOrder,
  } = useOrderLaterDateTimeSelector(openingHours, serviceMethod, t, language)

  const onOrderLaterClick = () => {
    if (selectedTime) onStartOrderAction(selectedTime)
  }

  const defaultPickerTextColour: PickerButtonStyleOverride = {
    pickerDisableTextColour: theme.colours.web.grey,
  }

  const pickerButtonStyles = {
    titleStyle: {
      fontSize: 14,
      color: theme.colours.black,
      fontWeight: 600,
    },
    pickerTextStyle: {
      fontSize: 15,
      fontWeight: 700,
      color: theme.colours.actionBlue,
    },
  }

  const orderLaterButtonStyle = {
    containerStyle: {
      width: '100%',
      minHeight: '50',
      marginTop: 6,
      backgroundColor: theme.colours.actionGreen,
    },
  }

  return (
    <div className={externalStyles.pickersContainer} data-testid={'picker-and-action-buttons-wrapper'}>
      <PickerButton
        title={orderDatePickerTitle}
        icon={'calendar'}
        items={orderDates}
        selectedValue={selectedDate?.dayOfTrade ?? ''}
        onValueChange={setSelectedDate}
        testID={`${testID}-OrderDate`}
        accessibilityLabel={orderDatePickerTitle}
        placeholderText={orderDatePlaceHolder}
        onlyShowPickerWhenMultipleItems={true}
        pickerButtonStyleOverride={defaultPickerTextColour}
        titleStyle={pickerButtonStyles.titleStyle}
        pickerTextStyle={pickerButtonStyles.pickerTextStyle}
      />
      <PickerButton
        title={orderTimePickerTitle}
        icon={'clock'}
        items={selectedDateOrderTimes}
        selectedValue={selectedTime?.slotTime ?? ''}
        onValueChange={setSelectedTime}
        testID={`${testID}-OrderTime`}
        accessibilityLabel={orderTimePickerTitle}
        placeholderText={orderTimePlaceHolder}
        onlyShowPickerWhenMultipleItems={true}
        titleStyle={pickerButtonStyles.titleStyle}
        pickerTextStyle={pickerButtonStyles.pickerTextStyle}
      />

      <div className={css['action-buttons']}>
        <ActionButton
          text={simplifiedOrderTitle}
          disabled={!canStartOrder}
          onPress={onOrderLaterClick}
          testID='start-order-later-button'
          textFontWeight='bold'
          containerStyle={orderLaterButtonStyle.containerStyle}
        />
      </div>
    </div>
  )
}
