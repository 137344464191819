import React from 'react'

import { PickerButton } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import css from './order-details.less'

export const OrderDetails = ({ testID = 'order-details', selectedStore, currentOrderTime }: OrderDetailsProps) => {
  const theme = useDominosTheme()
  const { t, i18n } = useTranslation('menu')

  const serviceMethod = useSelector(
    (state: RootReducer) =>
      state.currentOrderDetailsReducer.basketHeaderData &&
      state.currentOrderDetailsReducer.basketHeaderData.serviceMethod,
  )

  const selectedDeliveryAddress: StoredCustomerAddress | undefined = useSelector(
    (store: RootReducer) => store.currentOrderDetailsReducer.selectedDeliveryAddress,
  )

  const pickUpOrDeliverIcon = serviceMethod === 'Pickup' ? 'pickUp' : 'delivery'

  const pickUpOrDeliverTitle = serviceMethod === 'Pickup' ? 'Pick up from' : 'Delivery to'

  const storeNameOrDeliveryAddress =
    serviceMethod === 'Pickup'
      ? selectedStore && selectedStore.media?.name
      : selectedDeliveryAddress === undefined || '' || !selectedDeliveryAddress.displayAddress
      ? t('Delivery address not available')
      : selectedDeliveryAddress.displayAddress

  return (
    <div data-testid={testID} className={css.container}>
      <div>
        <PickerButton
          title={t(pickUpOrDeliverTitle)}
          testID={`${testID}.store`}
          icon={pickUpOrDeliverIcon}
          iconWidth={22}
          iconHeight={26}
          iconColor={theme.colours.dominosSlate}
          placeholderText={storeNameOrDeliveryAddress}
        />
      </div>
      <div>
        <PickerButton
          title={t('Order time')}
          testID={`${testID}.time`}
          icon={'clock'}
          iconWidth={22}
          iconHeight={22}
          iconColor={theme.colours.dominosSlate}
          placeholderText={
            currentOrderTime
              ? DateTime.fromISO(currentOrderTime, { setZone: true })
                  .setLocale(i18n.language)
                  .toFormat(t('DATETIME_FORMAT', { defaultValue: 'd MMM h:mm a' }))
              : t('NOW')
          }
        />
      </div>
    </div>
  )
}
