/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import css from './mobile-select-menu.less'
import { StaticSvgIcon } from '@dominos/res'
import { useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import { ActionButton, Modal } from '@dominos/components'

export const MenuSelectModal = (props: MenuSelectModalProps) => {
  const DEBOUNCE = 400
  const {
    options = [],
    testID,
    onChange,
    selectedValue,
    headerText,
    backButtonText,
    isShowPopup,
    closePopup,
    openPopup,
  } = props
  const theme = useDominosTheme()
  const {
    colours: { actionGreen, actionGreenBackgound },
  } = useDominosTheme()
  const [leftNavMenuEnabled] = useFeatures('LeftNavMenu')

  const { t } = useTranslation('half-n-half')
  const styles = {
    title: { color: theme.colours.dominosSlate },
  }

  const onValueChange = (value: string) => {
    closePopup()

    return onChange(value)
  }

  return (
    <>
      <ActionButton
        debounce={DEBOUNCE}
        data-testid={`${testID}.mobile-menu-portion-select-button`}
        testID={`${testID}.mobile-menu-portion-select-button`}
        onPress={openPopup}
        text={selectedValue ? t('Change Pizza') : t('Select Pizza')}
        textFontWeight={'semibold'}
        className={''}
        containerStyle={{
          width: '-webkit-fill-available',
          minHeight: 30,
          padding: 0,
          backgroundColor: actionGreenBackgound,
        }}
        textStyle={{
          fontSize: leftNavMenuEnabled ? '0.75rem' : '1rem',
          color: actionGreen,
        }}
      />

      {isShowPopup && (
        <Modal testID={`${testID}.mobile-select-menu`} childrenContainerClassNames={css.modalContainer}>
          <div className={css.containerView}>
            <div className={css.header}>
              <div
                data-testid={`${testID}.mobile-menu-portion-back-button`}
                className={css.backButtonContainer}
                onClick={closePopup}
              >
                <StaticSvgIcon
                  width={20}
                  height={20}
                  isUnstyled
                  name='chevron'
                  direction='left'
                  fill={theme.colours.actionBlue}
                />

                <p className={css.backButtonText}>{backButtonText || ''}</p>
              </div>
              <p className={css.headerText || ''}>{headerText}</p>
            </div>
            <div className={css.listContainer}>
              <div className={`${css.selectContainer}`} data-testid={`${testID}.mobile-select-menu.list`}>
                {options.map((item: Option, index: number) => (
                  <div key={index}>
                    <p style={styles.title} className={`${css.sectionHeader}`}>
                      {item?.section?.media?.name}
                    </p>
                    <div>
                      {item?.data.map((item: any, index: number) => (
                        <div
                          key={index}
                          className={`${css.sectionTextContainer}`}
                          onClick={() => onValueChange(item?.code)}
                        >
                          <p>{item?.media.name}</p>
                          <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
