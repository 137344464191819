import { FooterColumnProps } from '@dominos/components'
import Markdown from 'markdown-to-jsx'
import React, { CSSProperties } from 'react'
import css from './footer-column.less'

export const FooterColumn = (props: FooterColumnProps) => {
  const { testID, onClick, title, children, marginBottom } = props

  const styles: CSSProperties = { marginBottom, fontWeight: 'bold' }

  return (
    <div data-testid={`${testID}.wrapper`} className={css.column}>
      <Markdown
        data-testid={`${testID}.button`}
        style={onClick ? { ...styles, cursor: 'pointer' } : styles}
        children={title}
        role={onClick ? 'button' : undefined}
        onClick={onClick}
      />
      {children}
    </div>
  )
}
