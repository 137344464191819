import { ErrorCodes } from '@dominos/business'
import {
  PortionProductCard,
  ProductCard,
  ProductCardLoader,
  RecipeProductCard,
  SimpleProductCard,
  useProductContext,
} from '@dominos/components'
import { useAlert, useDevToggles, useFeatures } from '@dominos/hooks-and-hocs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

export const ProductCardSwitcher = ({
  currentProduct,
  addToBasket,
  lineData,
  isEditing,
  onDismiss,
  addDirectlyToBasket,
}: ProductCardSwitcherProps) => {
  const { productData, createBasketLine, isValidForBasket } = useProductContext()
  const { isEnabled } = useDevToggles()
  const { t } = useTranslation('menu')
  const { showAlert } = useAlert(() => alertCallBack())
  const alertCallBack = () => onDismiss()
  const [toggleHalfHalfPortionProductCardEnabled] = useFeatures('HalfHalfPortionProductCardEnabled')

  const isPortionProduct =
    productData?.isPortionProduct &&
    (productData?.defaultPortions?.length === 4 ||
      (productData?.defaultPortions?.length === 2 && toggleHalfHalfPortionProductCardEnabled))

  useEffect(() => {
    if (productData?.loading) {
      return
    }
    if (!isValidForBasket) {
      return
    }
    if (addDirectlyToBasket) {
      addToBasket(createBasketLine(lineData?.quantity || 1))
      onDismiss(true)
    }
  }, [productData?.loading, isValidForBasket, addDirectlyToBasket])

  useEffect(() => {
    productData?.errored && showAlert(`${t('ProductLoadFailedErrorMessage')} [${ErrorCodes.PRODUCT_LOAD_FAILED}]`)
  }, [productData?.errored])

  if (productData?.loading && !addDirectlyToBasket) {
    return <ProductCardLoader testID='product-card-loader' />
  }

  if (!addDirectlyToBasket && !productData?.loading && !productData?.errored) {
    if (isPortionProduct) {
      return (
        <PortionProductCard
          testID='portion-product-card'
          onDismiss={onDismiss}
          isEditing={isEditing}
          addToBasket={addToBasket}
          t={t}
        />
      )
    }

    if (isEnabled['enable-recipe-product-card'] && productData?.type === 'Recipe') {
      return (
        <RecipeProductCard
          testID='recipe-product-card'
          addToBasket={addToBasket}
          onDismiss={onDismiss}
          isEditing={isEditing}
          t={t}
        />
      )
    }

    if (isEnabled['enable-simple-product-card'] && productData?.type === 'Simple') {
      return <SimpleProductCard testID='simple-product-card' onDismiss={onDismiss} addToBasket={addToBasket} t={t} />
    }

    return (
      <ProductCard
        testID='product-card'
        item={currentProduct}
        onDismiss={onDismiss}
        addToBasket={addToBasket}
        lineData={lineData}
        isEditing={isEditing}
      />
    )
  }

  return null
}
