import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'

import { ActionButton, GenericCard } from '@dominos/components'
import { useCountryCode } from '@dominos/hooks-and-hocs'

import { useSelectedAddress } from '@dominos/business/functions/address/autocomplete'
import { AddressInfo } from './address-info'
import css from './confirm-address.less'
import { AdditionalFields } from '@dominos/components/address/additional-fields'

type ConfirmAddressProps = BaseProps

export const ConfirmAddress = ({ testID }: ConfirmAddressProps) => {
  const { t } = useTranslation('delivery-address')
  const { address, isAddressValid, onInit, onChange, onSelectAddress } = useSelectedAddress()
  const countryCode = useCountryCode()

  useEffect(() => {
    if (!address || !countryCode) {
      navigate(-1)
    }
  }, [address])

  if (!address) {
    return null
  }

  return (
    <div data-testid={testID} className={css.wrapper}>
      <GenericCard testID={testID} title={undefined}>
        <AddressInfo testID={`${testID}.info`} address={address} />

        <AdditionalFields
          testID={`${testID}.additionalFields`}
          address={address.addressComponents}
          onInit={onInit}
          onChange={onChange}
        />

        <ActionButton
          testID={`${testID}.button`}
          onPress={onSelectAddress}
          disabled={!isAddressValid}
          text={t('ContinueButton')}
          containerStyle={{ width: '100%', marginTop: '8px' }}
          textFontWeight={'semibold'}
        />
      </GenericCard>
    </div>
  )
}
