import { JPLarge } from './jp-pizza-large'
import { JPMedium } from './jp-pizza-medium'
import { JPRegular } from './jp-pizza-regular'

export const JPSizeIcons: { [code: string]: React.ComponentType<SizeSVGProps> } = {
  'Pizza.Medium.M': JPMedium,
  'Pizza.Regular.R': JPRegular,
  'Pizza.Large.L': JPLarge,
}

export type JPSizeCode = keyof typeof JPSizeIcons
