import { getFormattedPrice } from '@dominos/business/functions/text'
import { ActionButton } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res/images'
import { svgIconsName } from '@dominos/res/images/icons'
import React from 'react'
import css from './pay-button.less'

interface PayButtonProps {
  testID: string
  loading: boolean
  onPress: () => void
  title: string
  includeTotal: boolean
  total?: number
  buttonColor?: string
  icon?: string
  disable?: boolean
}

export const PayButton = React.memo(
  ({
    testID,
    loading,
    disable,
    onPress,
    icon,
    title,
    includeTotal,
    total,
    buttonColor = '#1FC36A',
  }: PayButtonProps) => (
    <ActionButton
      className={css.button}
      loading={loading}
      disabled={loading || disable}
      containerStyle={{ backgroundColor: buttonColor }}
      onPress={onPress}
      testID={testID}
    >
      <div className={css.content}>
        {icon && (
          <div className={css.icon}>
            <StaticSvgIcon name={`${icon}` as svgIconsName} isUnstyled={true} />
          </div>
        )}
        {includeTotal ? (
          <div className={css.splitContent}>
            <p className={css.text}>{getFormattedPrice(total)}</p>
            <p className={css.text}>{title}</p>
          </div>
        ) : (
          <p className={css.text}>{title}</p>
        )}
      </div>
    </ActionButton>
  ),
)
