import React, { useMemo } from 'react'
import { rewardsGaugeGlyphs } from './glyphs'
import { PizzaGaugeBackground } from './pizza-gauge-bg'
import { PizzaGaugeForeground } from './pizza-gauge-fg'

import css from './pizza-gauge.less'

export interface PizzaGaugeProps extends BaseProps {
  size: number
  points?: number
  max?: number
}

export const getCircleSectionCoordinates = (
  origin: { x: number; y: number },
  radius: number,
  percentage: number = 0,
) => {
  const angle = Math.PI * 2 * percentage

  return {
    x: origin.x + Math.round(radius * Math.sin(angle)),
    y: origin.y + Math.round(radius * (1 - Math.cos(angle))) - radius,
  }
}

const getRevealArcPath = (radius: number, points: number, max: number) => {
  const percentage = points / max
  const { x, y } = getCircleSectionCoordinates({ x: 50, y: 50 }, radius, percentage)

  /**
   * M50,50 = Move the pointer to circle center
   * L50,0 = Draw line vertically to bounding top
   * A50,50 = Begin arc with x and y radius
   * -90 = Rotate arc to start from 50,50 instead of 50,100
   * 1/0 = Defines large or small arc (greater or less than 180 degrees)
   * 1 = Invert arc path to draw clockwise
   * x,y = End arc points x and y
   * Z = Close path
   */
  return `M50,50 L50,0 A50,50 -90 ${percentage > 0.5 ? 1 : 0} 1 ${x},${y} Z`
}

export const PizzaGauge = ({ testID, max = 60, points = 0, size }: PizzaGaugeProps) => {
  const digits = useMemo(
    () =>
      points
        .toString()
        .split('')
        .map((num) => rewardsGaugeGlyphs[parseInt(num) || 0]),
    [points],
  )

  const arcPath = useMemo(() => getRevealArcPath(50, points, max), [max, points])

  return (
    <div data-testid={testID} className={css.wrapper}>
      <svg width={size} height={size} viewBox={'0 0 100 100'}>
        <defs>
          <clipPath id={'pieclip'}>
            <path d={arcPath} />
          </clipPath>
        </defs>
        <g>
          <PizzaGaugeBackground />
        </g>
        <g clipPath={'url(#pieclip)'}>
          <PizzaGaugeForeground />
        </g>
      </svg>
      <div className={css.points}>
        {digits?.map((PointIcon, idx) => (
          <PointIcon key={idx} />
        ))}
      </div>
    </div>
  )
}
