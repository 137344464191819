import React, { ReactElement, ReactNode } from 'react'

interface FormValidationState {
  valid: boolean
  runOnce: boolean
}

export interface FormValidationChild {
  onChangeValidation: (valid: boolean) => void
  key: string
}

interface FormValidationProps {
  onChangeValidation: (valid: boolean) => void
  testID: string
  accessibilityLabel: string
}

interface ValidationArray {
  [key: string]: boolean
}

export type ChildrenForValidation = ReactNode & { key: string; props: { onChangeValidation: (valid: boolean) => void } }

export class FormValidation extends React.Component<FormValidationProps, FormValidationState> {
  public validation: ValidationArray = {}
  public newChildren: ReactNode[] = []

  constructor(props: FormValidationProps) {
    super(props)
    const children = this.props.children as ChildrenForValidation[]
    if (children && children.length > 1) {
      children.forEach((child) => {
        this.newChildren.push(this.convertChild(child))
      })
    } else if (children) {
      const child = children as ChildrenForValidation
      this.newChildren.push(this.convertChild(child))
    }

    this.props.onChangeValidation(false)
    this.state = { valid: false, runOnce: false }
  }

  public convertChild = (child: ChildrenForValidation): ReactNode => {
    if (child.key) {
      this.validation[child.key] = false

      return React.cloneElement(child as ReactElement, {
        onChangeValidation: (val: boolean) => {
          this.updateValidation(child.key, val)
        },
      })
    }
    throw Error('if child has onChangeValidation it must have a key')
  }

  public updateValidation = (key: string, valid: boolean) => {
    this.validation[key] = valid

    if (!valid) {
      this.props.onChangeValidation(false)
      this.setState({ ...this.state, runOnce: true })

      return
    }

    let isValid = true

    for (const variableKey in this.validation) {
      if (isValid && !this.validation[variableKey]) {
        isValid = false
        break
      }
    }
    if (this.state.runOnce) {
      this.props.onChangeValidation(isValid)
    } else {
      setTimeout(() => this.props.onChangeValidation(isValid), 0)
      this.setState({ ...this.state, runOnce: true })
    }
  }

  public render() {
    return this.newChildren
  }
}
