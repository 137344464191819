export const failures = ['Failed', 'PaymentFailed']
export const proceeds = ['SentToStore', 'Timed', 'Making', 'Cooking', 'Ready', 'Leaving', 'Complete']

export const getOrderStatusAction = (currentStatus: string): 'Proceed' | 'Failed' | 'Waiting' => {
  if (proceeds.indexOf(currentStatus) > -1) {
    return 'Proceed'
  }
  if (failures.indexOf(currentStatus) > -1) {
    return 'Failed'
  }

  return 'Waiting'
}
