import React from 'react'

import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 75,
  height: 75,
}

export const OrderFailedIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#E21836'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg height={height} viewBox={`0 0 ${width} ${height}`} width={width}>
      <g fill='none' fillRule='evenodd'>
        <circle cx='37.5' cy='37.5' fill={fill} r='37.5' />
        <g fill='#fff' transform='translate(15.872225 21.151466)'>
          <path
            d='m30.4481543 33.8485343h13.3592417c.4663507 0 .8729858-.1075214 1.2199052-.3225641.3469195-.2150427.6170616-.502735.8104266-.8630769.1933649-.3603419.2900474-.7555556.2900474-1.1856411 0-.2034188-.0255925-.4024786-.0767773-.5971794-.0511848-.1947009-.1279621-.3850428-.2303318-.5710257l-6.6881516-12.231282c-.2274882-.4126496-.5189574-.7206838-.8744076-.9241026s-.7293839-.3051282-1.1218009-.3051282c-.3981043 0-.7777252.1017094-1.1388626.3051282s-.6526066.511453-.8744076.9241026l-6.6881516 12.24c-.2047394.3719658-.307109.7584615-.307109 1.1594871 0 .4300855.0981042.8252992.2943127 1.1856411.1962086.3603419.4677726.6480342.814692.8630769s.7507109.3225641 1.2113744.3225641zm6.6966825-6.2420513c-.6199052 0-.9469195-.3167521-.9810427-.9502564l-.1364929-3.7748718c-.0170616-.3254701.0781991-.5899145.285782-.7933333s.4819905-.3051282.8232228-.3051282c.335545 0 .6085308.1031624.8189573.3094872.2104265.2063247.3099526.4722222.2985782.7976923l-.1450237 3.7574359c-.0341232.6393162-.3554502.9589743-.963981.9589743zm0 4.1558975c-.3355451 0-.621327-.1017094-.857346-.3051283-.236019-.2034188-.3540284-.4707692-.3540284-.8020512 0-.3312821.1165876-.5986325.349763-.8020513.2331753-.2034188.5203791-.3051282.8616114-.3051282.3412322 0 .6270142.1017094.8573459.3051282.2303318.2034188.3454977.4707692.3454977.8020513 0 .331282-.1151659.5986324-.3454977.8020512-.2303317.2034189-.5161137.3051283-.8573459.3051283z'
            fillRule='nonzero'
          />
          <path d='m14.4583632 0c.0880095 1.09963988 1.0461124 1.71707187 2.169412 1.71707187 1.1232995 0 2.0814024-.61743199 2.1694119-1.71707187l13.0009645.0002818 1.3296236 5.17439271-2.7367752-.00039271-1.410589 4.45097685 2.361589 7.68874135-4.4149313 8.0814606-.1542981.297846c-.3748672.7691056-.5810149 1.5621794-.6322332 2.3757845l-24.93590449.0005961-.02963291-.1344054-.03830942.1344054-1.13669058-4.9441381 4.14736412-13.50029045-1.41136412-4.45097685-2.60822485.00039271 1.32962352-5.17439271zm1.9759636 10.8189297c-6.54134744 0-11.84415728 3.1667974-11.84415728 7.0732398 0 2.074319 1.49518273 3.9400873 3.87704183 5.2339096l4.24695275-.0000121c-3.40832942-.8650758-5.79843047-2.8827989-5.79843047-5.2338975 0-3.1394244 4.26161927-5.6844307 9.51859317-5.6844307 5.2569738 0 9.5185932 2.5450063 9.5185932 5.6844307 0 2.3510986-2.3901011 4.3688217-5.7984305 5.2338975l4.24644.0002906c2.3821573-1.2938331 3.8775546-3.1597203 3.8775546-5.2341881 0-3.9064424-5.3028099-7.0732398-11.8441573-7.0732398zm.0931984 8.6619812c-.942693 0-1.7068966.5014411-1.7068966 1.12s.7642036 1.12 1.7068966 1.12c.9426929 0 1.7068965-.5014411 1.7068965-1.12s-.7642036-1.12-1.7068965-1.12zm-6.1319284-3.2164196c-.94269291 0-1.70689653.5014411-1.70689653 1.12 0 .618559.76420362 1.12 1.70689653 1.12.942693 0 1.7068966-.501441 1.7068966-1.12 0-.6185589-.7642036-1.12-1.7068966-1.12zm11.8281208 0c-.9426929 0-1.7068965.5014411-1.7068965 1.12 0 .618559.7642036 1.12 1.7068965 1.12.942693 0 1.7068966-.501441 1.7068966-1.12 0-.6185589-.7642036-1.12-1.7068966-1.12zm-5.7893908-2.24c-.9426929 0-1.7068966.5014411-1.7068966 1.12 0 .618559.7642037 1.12 1.7068966 1.12s1.7068965-.501441 1.7068965-1.12c0-.6185589-.7642036-1.12-1.7068965-1.12zm11.4266732-8.8502095h-22.595l1.23607579 4.45097685-.02007579.06902315 20.1436994.00046344.1203006.34353656-.1203006-.41302315z' />
        </g>
      </g>
    </svg>
  )
}
