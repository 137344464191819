import gql from 'graphql-tag'

export const deliverySuburbsQuery = gql`
  query deliverySuburbsQuery($name: String!) {
    deliverySuburbs(name: $name) {
      name
      postCode
      region
      isPickupOnly
    }
  }
`
