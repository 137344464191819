import { useKiosk, useWindowSize } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useHasTopMenuWrapped = () => {
  const [innerWidth] = useWindowSize()
  const { isKioskOrder } = useKiosk()
  const { t } = useTranslation('menu')

  const menuWrapWidthTop = parseInt(t('Menu-Width-Wrap-Top', { defaultValue: '870' }), 10)

  const shouldWrap = () => (innerWidth > 0 && innerWidth < menuWrapWidthTop) || isKioskOrder

  const [topMenuWrapped, setTopMenuWrapped] = useState<boolean>(shouldWrap())

  useEffect(() => {
    setTopMenuWrapped(shouldWrap())
  }, [innerWidth])

  return topMenuWrapped
}

export { useHasTopMenuWrapped }
