import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { rootActions } from '@dominos/business/root.actions'
import { ActionButton } from '@dominos/components/buttons'
import { GenericCardPopup, useDominosTheme, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'

export const ResetOrderPopup = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('tracker')
  const { reportOrderReset } = useReport()

  const onClickNewOrder = () => {
    reportOrderReset()
    dispatch(rootActions.restartOrder())
    navigate(NavigationConstants.home)
  }
  const theme = useDominosTheme()

  const onClickTracker = () => {
    navigate(NavigationConstants.tracker)
  }

  const styles = {
    trackerButton: { width: '100%', backgroundColor: theme.colours.actionAlternative, marginBottom: '8px' },
    newOrderButton: { width: '100%', backgroundColor: theme.colours.actionStandard },
  }

  return (
    <GenericCardPopup testID='reset-order' popupTitle={t('ResetOrderPopupTitle', { defaultValue: 'Welcome Back!' })}>
      <div style={{ padding: '8px 0 8px 0' }}>
        {t('ResetOrderPopupContent', {
          defaultValue: 'Would you like to track your recent order, or start a new order?',
        })}
      </div>
      <ActionButton
        onPress={onClickTracker}
        testID={'tracker.button'}
        containerStyle={styles.trackerButton}
        textStyle={{ color: theme.colours.tooltipText }}
        textFontWeight={'semibold'}
        text={t('TrackMyOrderButton', { defaultValue: 'Track My Order' })}
      />
      <ActionButton
        onPress={onClickNewOrder}
        testID={'new-order.button'}
        containerStyle={styles.newOrderButton}
        textStyle={{ color: theme.colours.tooltipText }}
        textFontWeight={'semibold'}
        text={t('StartNewOrderButton', { defaultValue: 'Start a New Order' })}
      />
    </GenericCardPopup>
  )
}
