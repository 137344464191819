import React from 'react'

export const GarlicCheesy = ({ color }: CrustSVGProps) => {
  const selectedColorLight = color ? color : '#4A4A4A'

  return (
    <svg width='51px' height='49px' viewBox='0 0 250 250'>
      <g stroke={selectedColorLight} fill={'none'} strokeWidth={10}>
        <path d='M111.5,109.8c0,0-16.5,18.9-16.5,47c0,28.4,21.9,42.3,21.9,42.3' />
        <path d='M86.6,122.3c0,0-11.5,10.7-11.5,30.1c0,26.1,17.9,41,17.9,41' />
        <path d='M138.5,109.8c0,0,16.4,18.9,16.4,47c0,28.4-21.9,42.3-21.9,42.3' />
        <path d='M163.4,122.3c0,0,11.5,10.7,11.5,30.1c0,26.1-17.9,41-17.9,41' />
        <path
          d='M166.9,110.5c-16.1-9.8-26.1-27-26.1-45.8V50.5h-9.6L125,63.6l-6.2-13.1h-9.7v14.2c0,18.8-10,36-26.1,45.8
		c-15.1,9.2-24.8,23.2-24.8,38.9c0,27.7,29.9,50.1,66.7,50.1s66.7-22.4,66.7-50.1C191.7,133.7,182,119.7,166.9,110.5z'
        />
      </g>
      <g fill={selectedColorLight}>
        <path
          d='M125,14c61.2,0,111,49.8,111,111s-49.8,111-111,111S14,186.2,14,125S63.8,14,125,14 M125,0
		C56.1,0,0,56.1,0,125c0,68.9,56.1,125,125,125c68.9,0,125-56.1,125-125C250,56.1,193.9,0,125,0L125,0z'
        />
      </g>
    </svg>
  )
}
