import React from 'react'
import { CountryToggle } from '@dominos/components'
import {
  AdditionalFieldsLayoutAu,
  AdditionalFieldsLayoutBe,
  AdditionalFieldsLayoutFr,
  AdditionalFieldsLayoutJp,
  AdditionalFieldsLayoutMy,
  AdditionalFieldsLayoutSg,
} from './countries'
import css from './additional-fields.less'

export type AdditionalFieldsProps = AdditionalFieldsLayoutProps

export const AdditionalFields = ({ testID, address, onInit, onChange }: AdditionalFieldsProps) => (
  <div data-testid={testID} className={css.wrapper}>
    <CountryToggle
      AU={<AdditionalFieldsLayoutAu testID={`${testID}.au`} address={address} onInit={onInit} onChange={onChange} />}
      NZ={<AdditionalFieldsLayoutAu testID={`${testID}.nz`} address={address} onInit={onInit} onChange={onChange} />}
      NL={<AdditionalFieldsLayoutAu testID={`${testID}.nl`} address={address} onInit={onInit} onChange={onChange} />}
      JP={<AdditionalFieldsLayoutJp testID={`${testID}.jp`} address={address} onInit={onInit} onChange={onChange} />}
      FR={<AdditionalFieldsLayoutFr testID={`${testID}.fr`} address={address} onInit={onInit} onChange={onChange} />}
      BE={<AdditionalFieldsLayoutBe testID={`${testID}.be`} address={address} onInit={onInit} onChange={onChange} />}
      DE={<AdditionalFieldsLayoutBe testID={`${testID}.de`} address={address} onInit={onInit} onChange={onChange} />}
      LU={<AdditionalFieldsLayoutBe testID={`${testID}.lu`} address={address} onInit={onInit} onChange={onChange} />}
      MY={<AdditionalFieldsLayoutMy testID={`${testID}.my`} address={address} onInit={onInit} onChange={onChange} />}
      SG={<AdditionalFieldsLayoutSg testID={`${testID}.sg`} address={address} onInit={onInit} onChange={onChange} />}
      default={
        <AdditionalFieldsLayoutAu testID={`${testID}.default`} address={address} onInit={onInit} onChange={onChange} />
      }
    />
  </div>
)
