import React from 'react'
import { createHomeRewardsViewModel, HomeRewards } from 'olo-feature-loyalty'
import { useAuthenticationPopup, useCustomer, useFeatures, useLaunchDarklyFlags } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
export const LoyaltyHomeRewards = () => {
  const { t } = useTranslation('loyalty')
  const { showPopup } = useAuthenticationPopup()
  const { flags } = useLaunchDarklyFlags()
  const [loyaltyV2Enabled] = useFeatures('LoyaltyV2Enabled')
  const { isLoggedIn } = useCustomer()

  if (!loyaltyV2Enabled) return null

  const viewModel = createHomeRewardsViewModel({
    onCreateAccountClick: () => showPopup('register'),
    isLoggedIn: !!isLoggedIn,
    t,
    isFeatureEnabled: (feature: AllowedBooleanFeatures) => flags[feature],
  })

  return <HomeRewards viewModel={viewModel} />
}
