import { getApplicationConfig } from '@dominos/business/functions/common/get-application-config'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'

const isHostNameIncluded = (hostnames: string | undefined | null): boolean =>
  (hostnames ?? '').split(',').includes(window.location.hostname)

export const isB2BOrderType = (): boolean => isHostNameIncluded(getCountryConfig().B2B_APPLICATION_HOSTNAMES)

export const isKioskOrderType = (): boolean =>
  isHostNameIncluded(getCountryConfig().KIOSK_APPLICATION_HOSTNAMES) || isKioskSingleURL()

export const isKioskSingleURL = (): boolean => isHostNameIncluded(getApplicationConfig().KIOSK_SINGLE_URL_HOSTNAMES)
