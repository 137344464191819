export interface StoreCustomerAddressType {
  storeNo: number
  rawAddress?: Address
  name?: string
  address?: string
  buildingName?: string
}

type CustomerAddress = Address & {
  buildingName?: string
}

export const getAddressDisplayName = ({
  postCode,
  state,
  suburb,
  street,
  streetNo,
  buildingName,
}: Partial<CustomerAddress>): string =>
  [postCode, state, suburb, street, streetNo, buildingName].filter((val) => !!val).join(' ')

export const convertToStoredCustomerAddress = ({
  storeNo,
  rawAddress,
  name,
  address,
  buildingName,
}: StoreCustomerAddressType) =>
  ({
    storeNo,
    displayAddress:
      address && buildingName
        ? getAddressDisplayName({
            ...rawAddress,
            buildingName,
            streetNo: address,
          })
        : name,
    customerAddress: {
      buildingName,
      streetNo: address,
      streetName: rawAddress?.street,
      suburb: rawAddress?.suburb,
      postCode: rawAddress?.postCode,
      state: rawAddress?.state,
      unitNo: rawAddress?.unitNo,
      floorNo: rawAddress?.floorNo,
    },
    rawAddress: rawAddress,
  } as unknown as StoredCustomerAddress)

export const getOriginalAddress = ({ customerAddress, rawAddress }: StoredCustomerAddress) => {
  if (rawAddress) {
    return rawAddress
  }

  return {
    street: customerAddress?.streetName,
    suburb: customerAddress?.suburb,
    postCode: customerAddress?.postCode,
    state: customerAddress?.state,
    unitNo: customerAddress?.unitNo,
    floorNo: customerAddress?.floorNo,
    streetNo: customerAddress?.streetNo,
  } as Address
}
