import isEqual from 'lodash/isEqual'

declare global {
  interface Window {
    nativeAppLoadedAt?: string
  }
}

export const isCurrentOrderValid = (store: RootReducer): boolean => {
  const { nativeAppLoadedAt } = window

  const startedAt = nativeAppLoadedAt ? nativeAppLoadedAt : store.currentOrderDetailsReducer.advanceOrder.startedAt
  const basketLoadedMoreThan24hAgo = startedAt ? Math.abs(+new Date() - +new Date(startedAt)) / 3.6e6 >= 24 : false

  return !(
    basketLoadedMoreThan24hAgo ||
    (store.currentOrderDetailsReducer?.basketHeaderData?.serviceMethod === 'Delivery' &&
      !store.currentOrderDetailsReducer?.selectedDeliveryAddress?.customerAddress)
  )
}

export const checkStatesAreEqual = (
  prev: Pick<RootReducer, 'currentOrderDetailsReducer'>,
  next: Pick<RootReducer, 'currentOrderDetailsReducer'>,
) => {
  const startedAtEqual =
    prev.currentOrderDetailsReducer.advanceOrder.startedAt === next.currentOrderDetailsReducer.advanceOrder.startedAt

  const serviceMethodEqual =
    prev.currentOrderDetailsReducer?.basketHeaderData?.serviceMethod ===
    next.currentOrderDetailsReducer?.basketHeaderData?.serviceMethod
  const prevAddress = prev.currentOrderDetailsReducer?.selectedDeliveryAddress?.customerAddress
  const nextAddress = next.currentOrderDetailsReducer?.selectedDeliveryAddress?.customerAddress
  const addressDeepEqual = isEqual(prevAddress, nextAddress)

  return startedAtEqual && serviceMethodEqual && addressDeepEqual
}
