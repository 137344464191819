import { useFeatures } from '../../features'

export type DeliveryAddressSearchVersionType =
  | 'AutoCompleteDeliveryAddress'
  | 'AutoCompleteListMapDeliveryAddress'
  | 'DeliveryAddressSearchFeature'
  | 'ClassicAddressSearch'

export const useDeliveryAddressSearchVersion = () => {
  const [
    deliveryAddressSearchFeatureEnabled,
    autoCompleteListMapDeliveryAddressEnabled,
    autoCompleteDeliveryAddressEnabled,
  ] = useFeatures('DeliveryAddressSearch-Feature', 'AutoCompleteListMapDeliveryAddress', 'AutoCompleteDeliveryAddress')

  const getDeliveryAddressSearchVersion = (): DeliveryAddressSearchVersionType => {
    if (deliveryAddressSearchFeatureEnabled) return 'DeliveryAddressSearchFeature'
    if (autoCompleteDeliveryAddressEnabled && autoCompleteListMapDeliveryAddressEnabled)
      return 'AutoCompleteListMapDeliveryAddress'
    if (autoCompleteDeliveryAddressEnabled) return 'AutoCompleteDeliveryAddress'

    return 'ClassicAddressSearch'
  }

  const deliveryAddressSearchVersion = getDeliveryAddressSearchVersion()

  return {
    deliveryAddressSearchVersion,
  }
}
