import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 37,
  height: 27,
}

const CarParkDelivery = (props: SvgIconProps) => {
  const fill = props.fill || '#414141'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 37 27'>
      <defs>
        <polygon
          id='path-1'
          points='4.68267787 5.19617783 19.5325 5.19617783 19.5325 25.9607623 4.68267787 25.9607623'
        />
      </defs>
      <g id='Icon-/-Car-Park-Delivery' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group' transform='translate(-0.000050, 0.000400)'>
          <g id='Group-3' fill={fill}>
            <path
              d='M11.1585,24.4845 C9.419,24.512 7.964,23.087 7.9375,21.33 C7.911,19.5975 9.327,18.1685 11.107,18.1305 C12.8195,18.094 14.293,19.518 14.3175,21.2325 C14.3425,23.0205 12.9475,24.4565 11.1585,24.4845 M5.732,3.4405 C5.7325,2.945 5.769,2.924 6.2465,3.118 C7.777,3.7395 8.98,4.76 9.921,6.109 C10.375,6.759 10.8535,7.3925 11.317,8.036 C11.3745,8.115 11.41,8.21 11.4905,8.365 C11.2885,8.3775 11.1475,8.394 11.007,8.394 C9.4185,8.3955 7.83,8.3855 6.2415,8.4015 C5.857,8.405 5.7155,8.2955 5.7215,7.8915 C5.7435,6.408 5.731,4.9245 5.732,3.4405 M23.1265,0.61 C21.975,0.7075 20.824,0.8005 19.6735,0.9045 C18.4525,1.014 17.4235,2.1295 16.6485,3.102 C16.153,3.7235 15.6575,4.345 15.1605,4.965 C14.609,5.652 14.056,6.338 13.428,7.1185 C12.9135,6.4255 12.43,5.8335 12.0105,5.199 C10.0935,2.303 7.4335,0.602 3.9655,0.311 C2.644,0.2 1.322,0.102 0,0 L0,2.2355 C1.164,2.3235 2.327,2.42 3.4915,2.501 C3.823,2.5245 3.9295,2.638 3.9275,2.9605 C3.917,4.624 3.9155,6.2875 3.928,7.951 C3.9305,8.317 3.778,8.4055 3.4405,8.401 C2.294,8.385 1.147,8.3945 0,8.395 L0,21.1495 C1.262,21.1495 3.6615,21.148 4.9235,21.1575 C4.964,21.158 4.9935,21.151 5.0285,21.148 C4.917,24.5895 7.6615,27.4185 11.129,27.4325494 C14.499,27.446 17.247,24.689 17.245,21.3355 C17.245,21.2755 17.238,21.2175 17.2365,21.158 C18.0775,21.1455 19.842,21.1775 20.5835,21.139 C21.7545,21.0785 22.5565,20.2225 22.5635,19.052 C22.5745,17.236 22.578,15.4205 22.564,13.605 C22.55,11.7545 21.407,10.2505 19.6135,9.766 C18.718,9.5245 17.7965,9.3815 16.8895,9.1825 C16.2975,9.0525 15.713,8.8945 15.191,8.4935 C16.3175,7.089 17.405,5.7135 18.518,4.3595 C18.8565,3.948 19.3495,3.1655 19.879,3.1225 C21.0305,3.0295 22.181,2.925 23.3315,2.8235 C24.0895,2.757 24.5565,2.2785 24.504,1.6255 C24.449,0.943 23.908,0.544 23.1265,0.61'
              id='Fill-1'
            />
            <path
              d='M11.0348,19.6098 C10.1378,19.6168 9.4218,20.4058 9.42726853,21.3803 C9.4323,22.2638 10.2188,23.0138 11.1373,23.010809 C12.0948,23.0073 12.8683,22.2298 12.8563,21.2828 C12.8438,20.3298 12.0513,19.6018 11.0348,19.6098'
              id='Fill-4'
            />
          </g>
          <g id='Group-10' transform='translate(17.821050, 0.604150)'>
            <path
              d='M11.1939,5.6585 C12.7564,5.6585 14.0234,4.392 14.0234,2.829 C14.0234,1.2665 12.7564,0 11.1939,0 C9.6314,0 8.3644,1.2665 8.3644,2.829 C8.3644,4.392 9.6314,5.6585 11.1939,5.6585'
              id='Fill-6'
              fill={fill}
            />
            <mask id='mask-2' fill='white'>
              <use href='#path-1' />
            </mask>
            <g id='Clip-9' />
            <path
              d='M19.23,12.4369 L16.079,6.5464 C15.4135,5.3024 13.866,4.8334 12.622,5.4984 L12.622,5.4984 C12.123,5.7654 11.756,6.1769 11.5295,6.6509 L10.29,7.1014 C9.9885,7.2109 9.6775,7.2929 9.361,7.3459 L5.685,7.9644 C5.0305,8.0744 4.5895,8.6939 4.6995,9.3479 C4.8095,10.0024 5.429,10.4434 6.0835,10.3334 L9.1885,9.8114 C9.7795,9.7119 10.361,9.5609 10.9255,9.3599 L11.652,9.1014 L14.148,13.7674 L13.6415,17.4099 C13.535,18.1764 13.496,18.9509 13.525,19.7244 L13.691,24.1959 C13.573,25.0444 14.1655,25.8279 15.0135,25.9459 C15.862,26.0634 16.6455,25.4714 16.7635,24.6229 L16.553,19.8164 C16.5345,19.4024 16.554,18.9879 16.6115,18.5774 L16.9435,16.1844 C17.362,16.1904 17.787,16.1054 18.1825,15.8944 L18.1825,15.8944 C19.4265,15.2289 19.8955,13.6809 19.23,12.4369'
              id='Fill-8'
              fill={fill}
              mask='url(#mask-2)'
            />
            <polygon id='Fill-11' fill={fill} points='0 8.15145 8.2705 8.15145 8.2705 6.21745 0 6.21745' />
          </g>
        </g>
      </g>
    </svg>
  )
}
export { CarParkDelivery }
