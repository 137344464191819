import { RouteComponentProps } from '@reach/router'
import React, { useLayoutEffect } from 'react'

export const ScrollToTop: React.FC<RouteComponentProps> = ({ children }) => {
  useLayoutEffect(() => {
    const hash = window.location.hash
    try {
      if (hash) {
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          })
        }
      } else {
        window.scrollTo(0, 0)
      }
    } catch {
      // Better to not scroll than crash the app
    }
  }, [location.pathname])

  return <>{children}</>
}
