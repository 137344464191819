export const staticKioskBgImages = {
  AU: {
    large: require('@dominos/res/assets/kiosk-images/large/au.jpg'),
  },
  BE: {
    large: require('@dominos/res/assets/kiosk-images/large/be.jpg'),
  },
  DE: {
    large: require('@dominos/res/assets/kiosk-images/large/de.jpg'),
  },
  DK: {
    large: require('@dominos/res/assets/kiosk-images/large/dk.jpg'),
  },
  FR: {
    large: require('@dominos/res/assets/kiosk-images/large/fr.jpg'),
  },
  JP: {
    large: require('@dominos/res/assets/kiosk-images/large/jp.jpg'),
  },
  LU: {
    large: require('@dominos/res/assets/kiosk-images/large/lu.jpg'),
  },
  NL: {
    large: require('@dominos/res/assets/kiosk-images/large/nl.jpg'),
  },
  NZ: {
    large: require('@dominos/res/assets/kiosk-images/large/nz.jpg'),
  },
  default: {
    large: require('@dominos/res/assets/kiosk-images/large/default.jpg'),
  },
}
