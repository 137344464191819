import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const EmailIcon = ({ fill = '#ffffff', width = '50px', height = '50px', ...props }: SvgIconProps) => (
  <svg width={width} height={height} viewBox='0 0 50 50' {...props}>
    <g id='Web-/-iOS-/-Android-Universal-Design' stroke='none' fill='none'>
      <g id='iOS-/-Menu-/-Log-In' transform='translate(-48.000000, -218.000000)'>
        <g id='Group-2' transform='translate(17.000000, 114.000000)'>
          <g id='Social-Buttons' transform='translate(31.000000, 104.000000)'>
            <g id='Email' transform='translate(0.000000, 0.000000)'>
              <g id='Email-Icon' transform='translate(12.000000, 15.000000)' fill={fill}>
                <path
                  d='M13.0182499,11.1363636 C13.51708,11.1363636 13.9550772,10.9090909 14.4782405,10.3947368 L24.5278428,0.586124402 C24.077679,0.19138756 23.2746841,0 22.155358,0 L3.58914366,0 C2.59148339,0 1.88582124,0.179425837 1.47215723,0.538277512 L11.5460927,10.3947368 C12.069256,10.9090909 12.5194197,11.1363636 13.0182499,11.1363636 Z M0.316331306,18.0502392 L8.63827796,9.94019139 L0.340664483,1.90191388 C0.121665887,2.27272727 0,2.88277512 0,3.74401914 L0,16.2559809 C0,17.0933014 0.109499298,17.6913876 0.316331306,18.0502392 Z M25.6958353,18.0382775 C25.9026673,17.6794258 26,17.0813397 26,16.2559809 L26,3.74401914 C26,2.90669856 25.8905007,2.284689 25.6593355,1.92583732 L17.3982218,9.94019139 L25.6958353,18.0382775 Z M3.84464202,20 L22.4230229,20 C23.4450164,20 24.1750117,19.8086124 24.6008423,19.4258373 L16.1328966,11.1602871 L15.439401,11.8301435 C14.6364062,12.5956938 13.8699111,12.9545455 13.0182499,12.9545455 C12.1544221,12.9545455 11.387927,12.5956938 10.5849321,11.8301435 L9.89143659,11.1602871 L1.43565746,19.4138756 C1.91015442,19.8086124 2.71314927,20 3.84464202,20 Z'
                  id='Shape'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export { EmailIcon }
