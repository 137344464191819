import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 44,
  height: 44,
}

const CallStore = (props: SvgIconProps) => {
  const fill = props.fill || '#454545'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 44 44'>
      <g id='Call-Button' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          fill={fill}
          fillRule='nonzero'
          d='M22,44 C34.0352941,44 44,34.0352941 44,22 C44,9.98627451 34.0137255,0 21.9784314,0 C9.96470588,0 0,9.98627451 0,22 C0,34.0352941 9.98627451,44 22,44 Z M34.1770622,28.1456445 C35.3440372,29.397072 34.9506744,30.9037179 33.71625,32.0548372 L33.5559352,32.2043332 C29.1312453,36.3304233 21.6322617,32.4349711 16.5604089,26.9960749 C11.4885562,21.5571787 8.12569577,13.8047293 12.5503857,9.6786392 L12.7107005,9.52914319 C13.9451249,8.37802385 15.4755402,8.09071753 16.6425152,9.34214498 C17.5102658,10.2726936 18.4795342,11.4085304 19.6539334,13.7928637 C20.3207441,15.1507559 20.2416119,16.0944175 19.1012359,17.2178263 L18.0741506,18.2055943 C17.8978043,18.3700399 17.8593197,18.5859004 18.0035805,18.9013075 C18.3508768,19.6272911 19.3382946,21.1361448 20.9690673,22.8849344 C22.5998401,24.6337241 24.0361395,25.7239889 24.7361284,26.1210813 C25.0407041,26.2869924 25.2587233,26.2636592 25.4350696,26.0992136 L26.4920774,25.1435334 C27.677337,24.0682564 28.6242199,24.0551435 29.9472511,24.8310955 C32.2438544,26.1689567 33.3093116,27.2150959 34.1770622,28.1456445 Z'
        />
      </g>
    </svg>
  )
}

export { CallStore }
