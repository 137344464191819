import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const SandwichIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <g id='Sandwich' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-7' transform='translate(0.000000, 8.000000)' fill={fill}>
          <path
            d='M27.9683,17 L4.0313,17 C2.9093,17 2.0003,16.09 2.0003,14.968 C2.0003,14.433 2.4333,14 2.9683,14 L29.0313,14 C29.5663,14 30.0003,14.433 30.0003,14.968 C30.0003,16.09 29.0903,17 27.9683,17'
            id='Fill-1'
          />
          <path
            d='M30,12 L2,12 C0.896,12 0,11.104 0,10 C0,8.896 0.896,8 2,8 L23,8 L25,10 L27,8 L30,8 C31.104,8 32,8.896 32,10 C32,11.104 31.104,12 30,12'
            id='Fill-3'
          />
          <path
            d='M24,4 C23.448,4 23,3.552 23,3 C23,2.448 23.448,2 24,2 C24.552,2 25,2.448 25,3 C25,3.552 24.552,4 24,4 M16,4 C15.448,4 15,3.552 15,3 C15,2.448 15.448,2 16,2 C16.552,2 17,2.448 17,3 C17,3.552 16.552,4 16,4 M8,4 C7.448,4 7,3.552 7,3 C7,2.448 7.448,2 8,2 C8.552,2 9,2.448 9,3 C9,3.552 8.552,4 8,4 M24.955,0 L7.045,0 C4.259,0 2,2.259 2,5.045 C2,5.572 2.428,6 2.955,6 L29.045,6 C29.572,6 30,5.572 30,5.045 C30,2.259 27.741,0 24.955,0'
            id='Fill-5'
          />
        </g>
      </g>
    </svg>
  )
}
