import React from 'react'
import { ProductContainer, SplashScreen } from '@dominos/components'

export const ProductCardLoader = ({ testID }: BaseProps) => {
  const styles: React.CSSProperties = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: '100vh',
  }

  return (
    <ProductContainer testID={testID} styles={styles}>
      <SplashScreen testID={`${testID}.splash-screen-logo`} />
    </ProductContainer>
  )
}
