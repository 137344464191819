import { useMemo } from 'react'
import { useCurrentOrderDetails, useLocationExtended } from '@dominos/hooks-and-hocs'
import { isNativeApp } from '@dominos/business/functions/native-app'

export const useCorrectOrderId = () => {
  const { searchParams } = useLocationExtended()
  const { orderId: reduxOrderId } = useCurrentOrderDetails()

  const orderId = useMemo(
    () => (!isNativeApp() ? reduxOrderId : searchParams.get('basketId') ?? reduxOrderId),
    [searchParams, reduxOrderId],
  )

  return {
    orderId,
  }
}
