import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 235,
  height: 236,
}

export const RewardsEnrollDE = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg
      data-testid='MyRewards.Icon.DE'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 211.68 218.51'
    >
      <path
        fill={'#1d1d1b'}
        d='M16.37,110.25c0-14.66,11.25-24.55,25.77-24.55C55,85.7,61.22,93,64.07,99.51l-12.39,5.76a10.16,10.16,0,0,0-9.54-6.9c-6.55,0-11.17,5.26-11.17,11.88s4.62,11.89,11.17,11.89a10.16,10.16,0,0,0,9.54-6.9l12.39,5.69c-2.78,6.34-9.12,13.88-21.93,13.88C27.62,134.81,16.37,124.85,16.37,110.25Z'
      />
      <path fill={'#1d1d1b'} d='M65.7,86.48H80.08v35h18V134H65.7Z' />
      <path
        fill={'#1d1d1b'}
        d='M99.58,86.48h14.59V114c0,4.55,2.35,8.18,8.26,8.18s8.18-3.63,8.18-8.18V86.48h14.6v27.9c0,11.82-6.77,20.43-22.78,20.43s-22.85-8.61-22.85-20.36Z'
      />
      <path
        fill={'#1d1d1b'}
        d='M150,86.48h28.83c9.83,0,14.1,6.55,14.1,12.1,0,5.91-3.49,9.89-8.12,10.89,5.13.78,9,5.48,9,11.6,0,6.62-4.48,12.89-14,12.89H150Zm25.49,17.65a3,3,0,0,0,2.84-3.06,2.91,2.91,0,0,0-2.84-2.92H164.42v6Zm.42,18.15a3.14,3.14,0,0,0,3.35-3.2,3.23,3.23,0,0,0-3.35-3.27H164.42v6.47Z'
      />
      <path
        fill={'#0073ab'}
        d='M75.52,68.45H71.18a.26.26,0,0,0-.26.26v9.77a.26.26,0,0,0,.26.26h4.35c3.42,0,5.63-2,5.63-5.15s-2.22-5.14-5.64-5.14m0,7.58H74V71.17h1.5A2.25,2.25,0,0,1,78,73.59,2.38,2.38,0,0,1,75.52,76'
      />
      <path
        fill={'#0073ab'}
        d='M101.1,71.11a3.2,3.2,0,0,0-2.61,1.31,2,2,0,0,0-2.14-1.31,3.29,3.29,0,0,0-2.47,1v-.59a.26.26,0,0,0-.26-.26H91.37a.26.26,0,0,0-.26.26v6.93a.26.26,0,0,0,.26.26h2.25a.26.26,0,0,0,.26-.26V74.14h0A1.44,1.44,0,0,1,95,73.56a.76.76,0,0,1,.85.83v4.09a.26.26,0,0,0,.26.26h2.25a.26.26,0,0,0,.26-.26V74.14a1.43,1.43,0,0,1,1.14-.58.76.76,0,0,1,.85.83v4.09a.26.26,0,0,0,.26.26h2.26a.26.26,0,0,0,.26-.26V73.34a2,2,0,0,0-2.3-2.23'
      />
      <path
        fill={'#0073ab'}
        d='M107.4,71.29h-2.26a.26.26,0,0,0-.26.26v6.93a.26.26,0,0,0,.26.26h2.26a.26.26,0,0,0,.26-.26V71.55a.26.26,0,0,0-.26-.26'
      />
      <path fill={'#0073ab'} d='M106.34,67.64a1.56,1.56,0,1,0,1.55,1.55,1.55,1.55,0,0,0-1.55-1.55' />
      <path
        fill={'#0073ab'}
        d='M114.52,71.11a3.26,3.26,0,0,0-2.56,1v-.59a.26.26,0,0,0-.26-.26h-2.25a.26.26,0,0,0-.26.26v6.93a.26.26,0,0,0,.26.26h2.25a.26.26,0,0,0,.26-.26V74.14a1.5,1.5,0,0,1,1.19-.58.94.94,0,0,1,1.08,1.06v3.86a.26.26,0,0,0,.26.26h2.25a.26.26,0,0,0,.26-.26v-5a2.21,2.21,0,0,0-2.48-2.34'
      />
      <path
        fill={'#0073ab'}
        d='M86,71.07A3.93,3.93,0,1,0,89.92,75,3.93,3.93,0,0,0,86,71.07m0,5.22A1.34,1.34,0,1,1,87.33,75,1.34,1.34,0,0,1,86,76.29'
      />
      <path
        fill={'#0073ab'}
        d='M122.11,71.08A3.94,3.94,0,1,0,126.05,75a3.93,3.93,0,0,0-3.94-3.93m0,5.22A1.34,1.34,0,1,1,123.45,75a1.34,1.34,0,0,1-1.34,1.34'
      />
      <path
        fill={'#0073ab'}
        d='M128.57,68.9v0h0a1.6,1.6,0,0,0-1.85-1.16,1.51,1.51,0,0,0-1.23,1.74,1.47,1.47,0,0,0,1.39,1.12.92.92,0,0,1-.48.63.16.16,0,0,0,0,.22l.18.25a.16.16,0,0,0,.21.1A2.65,2.65,0,0,0,128.57,68.9Z'
      />
      <path
        fill={'#0073ab'}
        d='M130.92,73.51c0-.28.36-.4.81-.4a3.19,3.19,0,0,1,2,.63.28.28,0,0,0,.2,0,.31.31,0,0,0,.17-.13l.76-1.32a.26.26,0,0,0-.09-.35,5.62,5.62,0,0,0-3.14-.86c-2.3,0-3.53,1.08-3.32,2.63.36,2.74,4.61,1.8,4.54,2.73,0,.22-.39.38-1,.38a5.06,5.06,0,0,1-2.4-.79.24.24,0,0,0-.2,0,.24.24,0,0,0-.16.12l-.88,1.46a.27.27,0,0,0,.08.35,6.6,6.6,0,0,0,3.4,1c2.3,0,3.62-1,3.62-2.61,0-3-4.31-2.06-4.33-2.8'
      />
      <path
        className='cls-3'
        d='M89.63,44.55a3.62,3.62,0,0,0-2.57,1.06A3.66,3.66,0,0,0,86,48.18a3.62,3.62,0,0,0,3.63,3.63,3.63,3.63,0,1,0,0-7.26Z'
      />
      <path fill={'none'} d='M100.3,52a3.64,3.64,0,1,0-2.57-6.2,3.65,3.65,0,0,0,0,5.14A3.66,3.66,0,0,0,100.3,52Z' />
      <path
        className='cls-3'
        d='M111.56,19.1a1.55,1.55,0,0,0-2.18,0L98.85,29.62l-3.06,3.07,14.73,14.74,1.26-1.25,12.33-12.34a1.54,1.54,0,0,0,0-2.19Z'
      />
      <path
        fill={'#fff'}
        d='M125,30.76,112.45,18.21a2.8,2.8,0,0,0-4,0L98,28.74,80.45,46.25a2.82,2.82,0,0,0,0,4L93,62.76a2.8,2.8,0,0,0,4,0l15.7-15.69L125,34.73A2.82,2.82,0,0,0,125,30.76Z'
      />
      <path
        fill={'#dd052b'}
        d='M124.11,31.65,111.56,19.11a1.53,1.53,0,0,0-2.18,0L95.81,32.67l14.74,14.74,13.56-13.57A1.54,1.54,0,0,0,124.11,31.65Zm-11,3.63a3.65,3.65,0,1,1,1.06-2.57A3.62,3.62,0,0,1,113.08,35.28Z'
      />
      <path
        fill={'#0073ab'}
        d='M81.34,47.14a1.56,1.56,0,0,0,0,2.19L93.89,61.87a1.51,1.51,0,0,0,1.09.46,1.54,1.54,0,0,0,1.1-.46l13.56-13.55L94.9,33.58Zm10.87,3.61a3.66,3.66,0,0,1-2.58,1.06A3.62,3.62,0,0,1,86,48.18a3.66,3.66,0,0,1,1.06-2.57,3.66,3.66,0,0,1,5.15,0,3.65,3.65,0,0,1,0,5.14Zm5.52-5a3.65,3.65,0,1,1-1.06,2.57A3.63,3.63,0,0,1,97.73,45.76Z'
      />
      <path
        fill={'#0073ab'}
        d='M136.83,73.58a1.23,1.23,0,1,1,1.23-1.23A1.23,1.23,0,0,1,136.83,73.58Zm0-2.27a1,1,0,0,0-1,1,1,1,0,1,0,1-1Zm.34,1.75-.37-.56h-.24v.56h-.2V71.65h.58a.44.44,0,0,1,.45.43.42.42,0,0,1-.36.42l.38.56Zm-.23-1.24h-.38v.5h.38a.25.25,0,0,0,.25-.24A.26.26,0,0,0,136.94,71.82Z'
      />
      <path
        fill={'#0073ab'}
        d='M102.09,62.63a1.24,1.24,0,1,1,0-2.47,1.24,1.24,0,0,1,0,2.47Zm0-2.28a1,1,0,0,0-1,1,1,1,0,1,0,2.09,0A1,1,0,0,0,102.09,60.35Zm.34,1.75-.37-.55h-.24v.55h-.2V60.69h.58a.44.44,0,0,1,.45.43.42.42,0,0,1-.36.42l.38.56Zm-.23-1.23h-.38v.5h.38a.26.26,0,0,0,.25-.25A.25.25,0,0,0,102.2,60.87Z'
      />
      <path
        fill={'#ca9e67'}
        d='M104.49,216.55a5.37,5.37,0,0,1-4.85-3.05L68.4,148.43a5.38,5.38,0,1,1,9.7-4.66l26.39,55,26.44-55.07a5.38,5.38,0,1,1,9.71,4.65l-31.3,65.18A5.36,5.36,0,0,1,104.49,216.55Z'
      />
      <path
        fill={'#ca9e67'}
        d='M170.67,78.72A5.38,5.38,0,0,1,165.82,71l21.11-44c-11.23-4.42-41-14.33-82.44-14.33A246.3,246.3,0,0,0,22.12,27.19L43.16,71a5.38,5.38,0,0,1-9.7,4.66l-23.54-49a5.38,5.38,0,0,1,2.67-7.25C14.2,18.67,52.56,2,104.49,2c55.74,0,90.64,16.8,92.09,17.51a5.38,5.38,0,0,1,2.49,7.16l-23.55,49A5.37,5.37,0,0,1,170.67,78.72Z'
      />
      <path
        fill={'#ca9e67'}
        d='M164.22,48.64a5.24,5.24,0,0,1-1.17-.13c-1.89-.42-3.85-.89-5.93-1.4a147.77,147.77,0,0,0-23.58-4.28,5.38,5.38,0,1,1,.89-10.73,159.44,159.44,0,0,1,25.23,4.55c2,.49,3.93,1,5.72,1.35a5.38,5.38,0,0,1-1.16,10.64Z'
      />
      <path
        fill={'#ca9e67'}
        d='M44.76,48.64A5.38,5.38,0,0,1,43.6,38c1.78-.39,3.69-.85,5.71-1.35A159.94,159.94,0,0,1,74.56,32.1a5.38,5.38,0,1,1,.89,10.72,149.11,149.11,0,0,0-23.6,4.29c-2.07.51-4,1-5.92,1.4A5.31,5.31,0,0,1,44.76,48.64Z'
      />
    </svg>
  )
}
