import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation, useValidationJP } from '@dominos/business/functions/validation'
import { FancyTextField, MobileField, ValidationTextField } from '@dominos/components'

import styles from './account-details.less'

const maxLengthName = 250

const fieldNames = {
  name: 'name',
  alternateName: 'alternateName',
  phoneNumber: 'phoneNumber',
}

interface AccountDetailsProps {
  onChange: (fieldName: string, value: string | undefined) => void
  onValidationChange: (isValid: boolean) => void
  details?: { [key: string]: string | undefined }
  shouldShowAlternateName?: boolean
}

export const AccountDetails = (props: AccountDetailsProps) => {
  const { t } = useTranslation('login')
  const { isRequired, maxLength, isNameValid } = useValidation()
  const { validateIsKatakana } = useValidationJP()

  const [validationState, setValidationState] = useState(
    (Object.keys(fieldNames) as (keyof typeof fieldNames)[]).reduce(
      (o, key) => ({ ...o, [fieldNames[key]]: true }),
      {},
    ),
  )

  const onChange = (updatedField: { [key: string]: ValidationField }) => {
    const key = Object.keys(updatedField)[0]

    const newValidationState = {
      ...validationState,
      [key]: updatedField[key].isValid,
    }

    setValidationState(newValidationState)

    if (props.onChange) {
      props.onChange(key, updatedField[key].value)
    }
    if (props.onValidationChange) {
      props.onValidationChange(Object.values(newValidationState).reduce((l, r) => l && r, true) as boolean)
    }
  }

  return (
    <>
      <p className={styles.subheading}>{t('PersonalInformationHeading', { defaultValue: 'Personal Information' })}</p>
      <div className={styles.wrapperInner}>
        <ValidationTextField
          key={fieldNames.name}
          fieldName={fieldNames.name}
          defaultValue={props.details?.name}
          placeholder={t('NameLabel', { defaultValue: 'Name' })}
          testID={`${fieldNames.name}.field`}
          validationRules={[isRequired, maxLength(maxLengthName), isNameValid]}
          onChange={onChange}
          style={{ width: '100%' }}
        />
      </div>

      {props.shouldShowAlternateName && (
        <div className={styles.wrapperInner}>
          <ValidationTextField
            key={fieldNames.alternateName}
            fieldName={fieldNames.alternateName}
            defaultValue={props.details?.alternateName}
            placeholder={t('AlternateNameLabel', { defaultValue: 'Alternate Name' })}
            testID={`${fieldNames.alternateName}.field`}
            validationRules={[isRequired, validateIsKatakana]}
            onChange={onChange}
            style={{ width: '100%' }}
          />
        </div>
      )}

      <p className={styles.subheading}>{t('MobileNumberLabel', { defaultValue: 'Mobile Number' })}</p>
      <div className={styles.wrapperInner}>
        <MobileField
          fieldName={fieldNames.phoneNumber}
          style={{ width: '100%' }}
          defaultValue={props.details?.phoneNumber}
          onChange={onChange}
        />
      </div>

      <p className={styles.subheading}>{t('EmailLabel', { defaultValue: 'Email Address' })}</p>
      <div className={styles.wrapperInner}>
        <FancyTextField
          disabled={true}
          placeholder={t('EmailLabel', { defaultValue: 'Email Address' })}
          testID={'Email.field'}
          defaultValue={props.details?.email}
          key={'Email'}
          style={{ width: '100%' }}
        >
          <div className={styles.uneditable}>
            {t('EmailNotEditable', { defaultValue: 'Email address cannot be edited' })}
          </div>
        </FancyTextField>
      </div>
    </>
  )
}
