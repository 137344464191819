import { getDefaultSizeAndEnergy } from '@dominos/business/functions/basket'
import { useFeatures } from '@dominos/hooks-and-hocs'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useBasketLineTitle = (lineData: BasketLineItem | undefined, item?: MenuItemDependents | null) => {
  const { t } = useTranslation('menu')
  const [showEnergy] = useFeatures('ProductKJ')
  const getTitle = () => {
    if (!lineData?.media?.name || lineData.media.name === '') {
      return
    }

    if (item && showEnergy) {
      const { energyKJ } = getDefaultSizeAndEnergy(item)
      if (energyKJ) {
        return `${lineData.media.name} | ${energyKJ}${t('energyKJ-unit', { defaultValue: 'kJ' })}`
      }
    }

    return lineData.media.name
  }

  return useMemo(() => getTitle(), [lineData, item])
}
