import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 75,
  height: 35,
}

export const LoyaltyIconDE = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg
      data-testid='Loyalty.Icon.DE'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 10 10'
    >
      <path
        fill={'#ca9e67'}
        d='M3.71,7a.24.24,0,0,1-.22-.14L.77,1.15a.23.23,0,0,1,0-.2A.25.25,0,0,1,.89.81,11.59,11.59,0,0,1,5.16,0,10.56,10.56,0,0,1,9.45.81a.26.26,0,0,1,.11.34L6.85,6.81a.26.26,0,0,1-.34.11.25.25,0,0,1-.12-.33L9,1.17A10.61,10.61,0,0,0,5.16.5a11.58,11.58,0,0,0-3.82.67l2.6,5.42a.25.25,0,0,1-.12.34Z'
      />
      <path
        fill={'none'}
        d='M4,4a.38.38,0,0,0-.28.11.43.43,0,0,0-.11.28.39.39,0,0,0,.11.27.39.39,0,0,0,.56,0,.35.35,0,0,0,.11-.27A.38.38,0,0,0,4,4Z'
      />
      <path
        fill={'none'}
        d='M5.18,4.78a.39.39,0,0,0,.28-.12.38.38,0,0,0,.11-.28.35.35,0,0,0-.11-.27.39.39,0,0,0-.56,0,.39.39,0,0,0-.11.27.43.43,0,0,0,.11.28A.39.39,0,0,0,5.18,4.78Z'
      />
      <path
        fill={'none'}
        d='M6.4,1.23a.21.21,0,0,0-.12-.05.18.18,0,0,0-.12.05L5,2.37l-.34.33L6.28,4.29l.14-.14L7.75,2.82a.17.17,0,0,0,0-.23Z'
      />
      <path
        fill={'#fff'}
        d='M7.84,2.49,6.49,1.14a.29.29,0,0,0-.21-.09.31.31,0,0,0-.22.09L4.93,2.27,3,4.16a.3.3,0,0,0,0,.43L4.39,5.94A.31.31,0,0,0,4.61,6a.29.29,0,0,0,.21-.09L6.51,4.25,7.84,2.92A.3.3,0,0,0,7.84,2.49Z'
      />
      <path
        fill={'dd052b'}
        d='M7.75,2.59,6.4,1.23a.18.18,0,0,0-.24,0L4.7,2.7,6.29,4.28,7.75,2.82A.17.17,0,0,0,7.75,2.59ZM6.56,3a.43.43,0,0,1-.28.11A.39.39,0,0,1,6,3a.39.39,0,0,1,0-.56.39.39,0,0,1,.27-.11.43.43,0,0,1,.28.11.41.41,0,0,1,0,.56Z'
      />
      <path
        fill={'#0073ab'}
        d='M3.14,4.26a.15.15,0,0,0,0,.23L4.49,5.84a.15.15,0,0,0,.12,0,.16.16,0,0,0,.12,0L6.19,4.38,4.6,2.79Zm1.17.38a.39.39,0,0,1-.56,0,.39.39,0,0,1-.11-.27.43.43,0,0,1,.11-.28.39.39,0,0,1,.67.28A.35.35,0,0,1,4.31,4.64Zm.59-.53a.39.39,0,0,1,.56,0,.35.35,0,0,1,.11.27.38.38,0,0,1-.11.28.39.39,0,0,1-.56,0,.43.43,0,0,1-.11-.28A.39.39,0,0,1,4.9,4.11Z'
      />
      <path
        fill={'#ca9e67'}
        d='M5.16,10h0a.26.26,0,0,1-.22-.14l-1.45-3a.25.25,0,0,1,.11-.33.26.26,0,0,1,.34.11L5.16,9.15,6.39,6.59a.25.25,0,0,1,.34-.12.26.26,0,0,1,.12.34l-1.46,3A.26.26,0,0,1,5.16,10Z'
      />
      <path
        fill={'#ca9e67'}
        d='M7.93,2.11H7.87L7.56,2a.25.25,0,0,1-.2-.29.24.24,0,0,1,.3-.19L8,1.62a.25.25,0,0,1,.19.3A.25.25,0,0,1,7.93,2.11Z'
      />
      <path
        fill={'#ca9e67'}
        d='M2.38,2.14a.25.25,0,0,1-.06-.5A12,12,0,0,1,5,1.29a.26.26,0,0,1,.25.25A.25.25,0,0,1,5,1.79a11,11,0,0,0-2.6.34Z'
      />
    </svg>
  )
}
