import { useEffect, useState } from 'react'

export type SdkNames = 'OneTrust' | 'FB' | 'grecaptcha' | 'AppleID' | 'rokt' | 'default_gsi'
type SdkStatus = 'READY' | 'TIMEOUT' | 'IDLE'

export const useSdkAvailable = (sdkName: SdkNames) => {
  const [sdkStatus, setSdkStatus] = useState<SdkStatus>('IDLE')
  const loopMaxTime = 10000
  const loopIncrement = 500

  const checkSdk = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any)[sdkName] instanceof HTMLScriptElement) {
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)[sdkName] !== undefined
  }

  useEffect(() => {
    let handler: NodeJS.Timeout

    if (!sdkName) {
      setSdkStatus('IDLE')
    } else if (checkSdk()) {
      setSdkStatus('READY')
    } else {
      let loopTimer = 0

      const loop = () => {
        loopTimer += loopIncrement

        if (checkSdk()) {
          clearInterval(handler)
          setSdkStatus('READY')
        } else if (loopTimer === loopMaxTime) {
          clearInterval(handler)
          setSdkStatus('TIMEOUT')
        }
      }

      handler = setInterval(loop, loopIncrement)
    }

    return () => clearInterval(handler)
  }, [sdkName])

  return {
    sdkStatus,
    found: sdkStatus === 'READY',
    checkSdk,
  }
}
