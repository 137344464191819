import { isSearchableMenuItem, isVarietyMenuItem } from '../../menu'

const validItem = (item: Bff.Menu.old.ProductMenuItem | Bff.Menu.old.VarietyItemDependents, searchTerm: string) => {
  const terms = searchTerm.split(' ')

  return terms.every((term) => {
    const regex = RegExp(term, 'i')

    return item.media?.name.match(regex) || item.media?.description?.match(regex)
  })
}

export const getFilteredMenu = (menu: Bff.Menu.old.Menu, searchTerms: string) => {
  const sanitizedSearchTerms = searchTerms.replace(/[^a-zA-Z\s]/g, '')
  const pages = menu.pages.reduce((acc, curr) => {
    const sections =
      curr.sections?.reduce((acc, curr) => {
        const items = curr.items.reduce((acc, curr) => {
          if (isVarietyMenuItem(curr)) {
            const varietyItems = curr.items.filter((varietyItem) => validItem(varietyItem, sanitizedSearchTerms))
            if (varietyItems.length > 0) {
              return [...acc, { ...curr, items: varietyItems }]
            }
          }

          if (isSearchableMenuItem(curr) && validItem(curr, sanitizedSearchTerms)) {
            return [...acc, curr]
          }

          return acc
        }, [] as Bff.Menu.old.MenuItemDependents[])

        if (items.length > 0) return acc.concat({ ...curr, items })
        else return acc
      }, [] as Bff.Menu.old.MenuSection[]) || []

    if (sections.length > 0) return acc.concat({ ...curr, sections })
    else return acc
  }, [] as Bff.Menu.old.MenuPage[])

  return { ...menu, pages }
}
