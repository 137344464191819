import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CountryToggle } from '@dominos/components'
import { TermsAndConditionsGeneric } from '@dominos/components/terms-and-conditions'
import { useDominosTheme } from '@dominos/hooks-and-hocs/theme'
import css from './terms-and-conditions.less'

interface TermsAndConditionsProps {
  setValid: (isValid: boolean) => void
}

export const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const theme = useDominosTheme()
  const [checked, setChecked] = useState(false)
  const [showCheckbox, setShowCheckbox] = useState(false)

  const { t } = useTranslation('login')

  const getInitialState = CountryToggle({
    AU: () => ({ checked: true, showCheckbox: false }),
    BE: () => ({ checked: false, showCheckbox: true }),
    DE: () => ({ checked: false, showCheckbox: true }),
    DK: () => ({ checked: true, showCheckbox: false }),
    FR: () => ({ checked: false, showCheckbox: true }),
    JP: () => ({ checked: false, showCheckbox: true }),
    LU: () => ({ checked: true, showCheckbox: false }),
    NL: () => ({ checked: true, showCheckbox: false }),
    NZ: () => ({ checked: false, showCheckbox: true }),
    SG: () => ({ checked: false, showCheckbox: true }),
    MY: () => ({ checked: false, showCheckbox: true }),
    TW: () => ({ checked: false, showCheckbox: true }),
    KH: () => ({ checked: false, showCheckbox: true }),
  })

  useEffect(() => {
    const initialState = getInitialState()
    setChecked(initialState.checked)
    setShowCheckbox(initialState.showCheckbox)
    props.setValid(initialState.checked)
  }, [])

  const setOnChange = () => {
    setChecked((c: boolean) => {
      props.setValid(!c)

      return !c
    })
  }

  return (
    <div className={css.terms}>
      <TermsAndConditionsGeneric
        testID={`CreateAccount.TermsAndConditions`}
        text={t(`CreateAccountT&C`)}
        textStyle={{ color: theme.colours.web.grey, fontSize: 12 }}
        weight='bold'
        hasCheckedBox={showCheckbox}
        checked={checked}
        onChange={setOnChange}
      />
    </div>
  )
}
