import { getDimensionSetTypeFromProduct } from '.'

type SizeDimensionSet = Bff.Dimensions.SizeDimensionSet
type SizeBaseDimensionSet = Bff.Dimensions.SizeBaseDimensionSet

const getDefaultDimensionSetFromProduct = (
  product:
    | (Bff.Menu.old.ProductMenuItem & {
        category?: string | undefined
      })
    | (Bff.Menu.old.PortionMenuItem & {
        category?: string | undefined
      })
    | null,
) => {
  if (!product?.sizes) return undefined

  const dimensionSetType = getDimensionSetTypeFromProduct(product)
  if (dimensionSetType === 'Size') {
    return product?.defaultSize
      ? ({ type: dimensionSetType, size: product.defaultSize } as SizeDimensionSet)
      : undefined
  }
  if (dimensionSetType === 'SizeBase') {
    const filteredSize = product?.sizes
      ?.map((size) => (size.code === product?.defaultSize ? size : undefined))
      .filter((size) => size)
    const base = filteredSize?.[0]?.recipe?.base?.code

    return product && base
      ? ({ type: dimensionSetType, size: product?.defaultSize, base } as SizeBaseDimensionSet)
      : undefined
  }

  return undefined
}

export { getDefaultDimensionSetFromProduct }
