import { useEffect, useState } from 'react'
import useSimpleOrderStatus from '@dominos/hooks-and-hocs/order/order-status/use-simple-order-status'
import { OrderStatus } from '@dominos/hooks-and-hocs'

const orderStatusPollingInterval = 30 * 1000

const useGPSTracker = (
  shouldDisplayGPSInfo: boolean,
  orderId: string,
  startTracking: OrderStatus[],
  stopTracking?: OrderStatus,
) => {
  const [startTrack, setStartTrack] = useState<boolean>(false)
  const [orderCompleted, setOrderCompleted] = useState<boolean>(false)
  const { orderStatus, stopPolling, startPolling } = useSimpleOrderStatus(orderId)

  useEffect(() => {
    if (shouldDisplayGPSInfo) {
      startPolling(orderStatusPollingInterval)
    }
  }, [])

  useEffect(() => {
    if (orderStatus) {
      const status = orderStatus.status

      if (!startTrack) {
        if (startTracking.find((s) => s === status)) {
          setStartTrack(true)
        }

        return
      }

      if (stopTracking && status === stopTracking) {
        setOrderCompleted(true)
        stopPolling()
      }
    }
  }, [orderStatus])

  return { startTrack, orderCompleted }
}

export { useGPSTracker }
