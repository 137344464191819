import LZ from 'lz-string'

export const readValueFromStorage = <T>(
  key: string,
  defaultValue: T,
  compressed: boolean,
  storageType: StorageTypes = 'sessionStorage',
) => {
  try {
    const item = storageType === 'localStorage' ? window.localStorage.getItem(key) : window.sessionStorage.getItem(key)

    if (item !== null) {
      const valueToRead = compressed ? LZ.decompressFromUTF16(item) : item

      if (valueToRead !== null) {
        return JSON.parse(valueToRead)
      }
    }

    return defaultValue
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Error reading ${storageType} key “${key}”:`, error)

    return defaultValue
  }
}
