import { ErrorDefinition } from '@dominos/components/error'
import { getDefaultValue } from '@dominos/components/error/helpers/get-default-value'

export const loginErrors: PartialRecord<Bff.Errors.Customer | 'UNABLE_TO_RESOLVE' | string, ErrorDefinition> = {
  EMAIL_ALREADY_IN_USE: {
    displayType: 'closable-popup',
    translation: {
      title: { key: 'LoginEmailAlreadyInUseErrorTitle', getOptions: getDefaultValue },
      message: { key: 'LoginEmailAlreadyInUseErrorMessage', getOptions: getDefaultValue },
    },
    icon: 'infoIcon',
    testID: 'login-email-already-in-use-error',
  },
  ALREADY_REGISTERED_WITH_DOMINOS: {
    displayType: 'closable-popup',
    translation: {
      title: { key: 'LoginEmailAlreadyInUseErrorTitle', getOptions: getDefaultValue },
      message: {
        key: 'LoginEmailAlreadyInUseDominosErrorMessage',
        getOptions: getDefaultValue,
      },
    },
    icon: 'infoIcon',
    testID: 'login-email-already-in-use-error-dominos',
  },
  ALREADY_REGISTERED_WITH_APPLE: {
    displayType: 'closable-popup',
    translation: {
      title: { key: 'LoginEmailAlreadyInUseErrorTitle', getOptions: getDefaultValue },
      message: {
        key: 'LoginEmailAlreadyInUseAppleErrorMessage',
        getOptions: getDefaultValue,
      },
    },
    icon: 'infoIcon',
    testID: 'login-email-already-in-use-error-apple',
  },
  ALREADY_REGISTERED_WITH_FACEBOOK: {
    displayType: 'closable-popup',
    translation: {
      title: { key: 'LoginEmailAlreadyInUseErrorTitle', getOptions: getDefaultValue },
      message: {
        key: 'LoginEmailAlreadyInUseFacebookErrorMessage',
        getOptions: getDefaultValue,
      },
    },
    icon: 'infoIcon',
    testID: 'login-email-already-in-use-error-facebook',
  },
  ALREADY_REGISTERED_WITH_GOOGLE: {
    displayType: 'closable-popup',
    translation: {
      title: { key: 'LoginEmailAlreadyInUseErrorTitle', getOptions: getDefaultValue },
      message: {
        key: 'LoginEmailAlreadyInUseGoogleErrorMessage',
        getOptions: getDefaultValue,
      },
    },
    icon: 'infoIcon',
    testID: 'login-email-already-in-use-error-google',
  },
  UNABLE_TO_RESOLVE: {
    displayType: 'closable-popup',
    translation: {
      title: { key: 'UnresolvedLoginErrorTitle', getOptions: getDefaultValue },
      message: { key: 'UnresolvedLoginErrorMessage', getOptions: getDefaultValue },
    },
    icon: 'infoIcon',
    testID: 'unable-to-resolve-error',
  },
  LOGIN_FAILED: {
    displayType: 'closable-popup',
    translation: {
      title: { key: 'UnexpectedErrorOnEmailLoginTitle', getOptions: getDefaultValue },
      message: { key: 'UnexpectedErrorOnEmailLogin', getOptions: getDefaultValue },
    },
    icon: 'infoIcon',
    testID: 'login-failed-error',
  },
}
