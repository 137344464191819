import React, { useState } from 'react'
import { StateController, useStateMachine } from '../../state-machine'

import { StateName, authenticationFlow as states } from './state-data'

export const QuickUniversalAccountComponentContext = React.createContext<
  StateController<StateName> & {
    hasPrevious: boolean
    previousStep: () => void
  }
>({
  current: 'register',
  data: {},
  setData: () => {
    throw new Error(`Can't call "setData" before AuthenticationProvider is mounted`)
  },
  step: states['register'],
  hasAction: () => false,
  hasPrevious: false,
  previousStep: () => {
    throw new Error(`Can't call "previousStep" before AuthenticationProvider is mounted`)
  },
  triggerAction: (action: string) => {
    throw new Error(`"triggerAction" has not been implemented with action "${action}"`)
  },
  reset: () => {
    throw new Error(`Can't call "reset" before AuthenticationProvider is mounted`)
  },
})

export const QuickUniversalAccountComponentProvider = ({
  children,
}: React.PropsWithChildren<{}>): React.ReactElement => {
  const [initial, setInitial] = useState<StateName>('prompt')
  const controller = useStateMachine<StateName>({ initial, states })

  const hasPrevious = controller.current !== initial && controller.hasAction('previous')

  const previousStep = () => controller.triggerAction('previous')

  const reset = (current?: StateName) => {
    if (current) setInitial(current)

    controller.reset(current)
  }

  return (
    <QuickUniversalAccountComponentContext.Provider
      value={{
        ...controller,
        reset,
        hasPrevious,
        previousStep,
      }}
    >
      {children}
    </QuickUniversalAccountComponentContext.Provider>
  )
}

export const useQuickUniversalAccountComponent = () => {
  const context = React.useContext(QuickUniversalAccountComponentContext)

  if (!context) {
    throw new Error("'useQuickUniversalAccountComponent' must be a child of 'QuickUniversalAccountComponentProvider'")
  }

  return context
}
