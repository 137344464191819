import React from 'react'

export const styles = {
  modalContent: {
    zIndex: 5,
    padding: '16px 24px',
    backgroundColor: 'white',
    margin: '20px 20px',
    borderRadius: '16px',
  } as React.CSSProperties,
  loadingContainer: {
    margin: '20px auto',
    textAlign: 'center',
  } as React.CSSProperties,
  messageContainer: {
    margin: '8px 0',
  } as React.CSSProperties,
  processingTitle: { margin: 0, fontSize: '2em' },
  processingSubTitle: { margin: 0 },
}
