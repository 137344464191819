import { BasketLineData, calculateCustomisationDifference } from '@dominos/business/functions/basket'

type ProductSize = Bff.Menu.old.ProductSize

export const getCustomisationDifference = (
  key: 'sauce' | 'base' | 'options',
  change: TouchableGridElement,
  size?: ProductSize,
  currentProduct?: BasketLineData,
) =>
  size && size.recipe && size.recipe[key]
    ? calculateCustomisationDifference(change, size.recipe[key], currentProduct)
    : undefined
