import { DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const FreshThin = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR

  return (
    <svg width='55px' height='21px' viewBox='0 0 55 21' key={color}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-47.000000, -850.000000)' fill={selectedColor} stroke={selectedColor}>
          <g transform='translate(6.000000, 309.000000)'>
            <g transform='translate(1.000000, 474.000000)'>
              <path d='M67.5,81.3562614 C80.2025492,81.3562614 90.5,78.6957928 90.5,75.4139383 C90.5,72.1320839 80.2025492,69.4716152 67.5,69.4716152 C54.7974508,69.4716152 44.5,72.1320839 44.5,75.4139383 C44.5,78.6957928 54.7974508,81.3562614 67.5,81.3562614 Z M67.5,80.0752185 C79.6944473,80.0752185 89.58,77.7240558 89.58,74.8237497 C89.58,71.9234435 79.6944473,69.5722808 67.5,69.5722808 C55.3055527,69.5722808 45.42,71.9234435 45.42,74.8237497 C45.42,77.7240558 55.3055527,80.0752185 67.5,80.0752185 Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
