import React, { useState } from 'react'
import css from './guarantee-disclaimers.less'

export const GuaranteeDisclaimers = ({
  testID,
  title,
  disclaimers,
}: {
  testID: string
  title: string
  disclaimers: Bff.Offers.Disclaimer[]
}) => {
  const [showDetails, setShowDetails] = useState(false)

  const onClick = () => {
    setShowDetails(!showDetails)
  }

  if (disclaimers && disclaimers.length > 0) {
    return (
      <>
        {!showDetails && (
          <div role='button' onClick={onClick} className={css.title}>
            {title}
          </div>
        )}
        {showDetails && (
          <div className={css.details}>
            {(disclaimers ?? []).map((disclaimer) => (
              <div data-testid={`${testID}.${disclaimer.code}`} key={disclaimer.code}>
                {disclaimer.text}
              </div>
            ))}
          </div>
        )}
      </>
    )
  }

  return null
}
