import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 76,
  height: 36,
}

const GMORakutenIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`}>
      <g fill={fill}>
        <g>
          <path d='M71.29,14.08l-4.94,14.96c-0.52,1.59-1.68,2.49-3.17,2.49c-0.94,0-1.79-0.35-2.45-0.98l0.92-1.42 c0.44,0.39,0.85,0.57,1.4,0.57c0.76,0,1.14-0.33,1.51-1.35l0.31-0.83L60.1,14.08h2.03l3.65,10.68l3.45-10.68H71.29z' />
          <path d='M56.25,26.34c-1.07,1.27-2.29,1.86-3.89,1.86c-3.6,0-6.07-2.95-6.07-7.23c0-4.28,2.49-7.21,6.12-7.21 c1.64,0,2.77,0.48,3.87,1.62l0.28-1.29h1.75v13.76h-1.75L56.25,26.34z M48.38,20.96c0,3.12,1.7,5.4,4.02,5.4 c2.32,0,4-2.25,4-5.37c0-3.15-1.68-5.4-4.02-5.4C50.09,15.59,48.38,17.88,48.38,20.96z' />
          <path d='M40.21,10.65c3.08,0,5.26,2.12,5.26,5.15c0,3.04-2.18,5.18-5.26,5.18h-3.74v6.86h-1.97V10.65H40.21z M40.08,19.15c1.92,0,3.3-1.4,3.3-3.34c0-1.94-1.35-3.32-3.3-3.32h-3.6v6.66H40.08z' />
        </g>
        <g>
          <polygon points='14.34,32.28 11.32,29.74 32.49,29.74' />
          <path d='M17.78,27.84v-5.61h2.43l4.21,5.61h4.3l-5.09-6.77c1.59-1.14,2.63-2.99,2.63-5.08 c0-3.44-2.8-6.24-6.24-6.24h-5.68v18.1H17.78z M17.78,13.18h2.24c1.54,0,2.81,1.26,2.81,2.8c0,1.55-1.26,2.81-2.81,2.81h-2.24 V13.18z' />
        </g>
      </g>
    </svg>
  )
}

export { GMORakutenIcon }
