import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ActionButton, ClearButton, GenericCard } from '@dominos/components'

import { AddressInfo } from './address-info'
import css from './confirm-address.less'
import { AdditionalField } from '@dominos/components/address/additional-fields/additional-field'
import { mapAddressAttributesToTranslation } from '@dominos/hooks-and-hocs/location/use-auto-complete/providers/google/validate-google-address-component'
import { AddressValidationError } from '@dominos/hooks-and-hocs/location/use-validate-address'
import { useCountryCode } from '@dominos/hooks-and-hocs'

import { useValidation } from '@dominos/business/functions/validation'

type ConfirmAddressProps = BaseProps & {
  address: DeliveryAddressRequest
  loading: boolean
  addressIsValid?: boolean
  invalidFields?: AddressValidationError[]
  userCorrection?: boolean
  onChange?: AdditionalFieldsLayoutProps['onChange']
  onConfirm: () => void
  onTriggerClick: () => void
}

export const ConfirmAddress = (props: ConfirmAddressProps) => {
  const { testID, address, loading, onConfirm, onTriggerClick } = props
  const { t } = useTranslation('delivery-address')
  const { isRequired } = useValidation()
  const countryCode = useCountryCode()

  const [formInvalidFields, setFormInvalidFields] = useState<string[]>([])

  useEffect(() => {
    if (!props.invalidFields || props.invalidFields.length === 0) return
    const manualRequiredFields = props.invalidFields
      .filter((field) => field.rules.includes('manual') && field.rules.includes('isRequired'))
      .map((field) => field.attribute)

    setFormInvalidFields(manualRequiredFields)
  }, [props.invalidFields])

  const onChange = (input: AdditionalFields) => {
    const addressAttributeName: string = Object.keys(input)[0]

    if (input[addressAttributeName as keyof AdditionalFields]?.isValid) {
      setFormInvalidFields(formInvalidFields.filter((field) => field !== addressAttributeName))
    } else {
      setFormInvalidFields([...formInvalidFields, addressAttributeName])
    }

    if (!props.onChange) return
    props.onChange(input)
  }

  const getFieldValidationRule = (field: AddressValidationError) => {
    const result = []

    if (field.rules.includes('isRequired')) {
      result.push(isRequired)
    }

    return result
  }

  const getTranslation = (field: AddressValidationError) => {
    if ((countryCode === 'BE' || countryCode === 'NL') && field.attribute === 'unitNo') {
      return t('Addition')
    }

    return t(mapAddressAttributesToTranslation[field.attribute])
  }

  return (
    <div data-testid={testID} className={css.wrapper}>
      <GenericCard
        testID={testID}
        title={t('Confirm Address')}
        TitleTriggerComponent={
          <div style={{ marginBottom: '-10px', cursor: 'pointer' }}>
            <ClearButton testID={`${testID}.clearInputButton`} onClick={onTriggerClick} tabIndex={0} />
          </div>
        }
      >
        <AddressInfo testID={`${testID}.info`} address={address} showClearButton />

        {props.invalidFields?.map((field) => (
          <AdditionalField
            key={field.attribute}
            testID={testID}
            fieldName={field.attribute}
            placeholder={getTranslation(field)}
            onChange={onChange}
            validationRules={getFieldValidationRule(field)}
            address={address.addressComponents}
          />
        ))}

        <ActionButton
          testID={`${testID}.button`}
          onPress={onConfirm}
          loading={loading}
          text={t('Select Address')}
          containerStyle={{ width: '100%', marginTop: '8px', height: '40px' }}
          textFontWeight={'semibold'}
          disabled={loading || (props.userCorrection && formInvalidFields.length > 0)}
        />
      </GenericCard>
    </div>
  )
}
