import React from 'react'
import { useTranslation } from 'react-i18next'

import { AuthButton } from '@dominos/components'
import { EmailIcon } from '@dominos/res/images/icons/components'

const ICON_SIZE = 54
export const EmailNativeButton = ({ testID, onPress }: BaseProps & { onPress: () => void }) => {
  const { t } = useTranslation('login')

  return (
    <AuthButton
      testID={testID}
      label={t('signUpEmailTitle', { defaultValue: 'Sign in with email' })}
      onPress={onPress}
      backgroundColor={'#3478F633'}
    >
      <EmailIcon fill={'#007AFF'} data-testid={`${testID}.icon`} width={`${ICON_SIZE}px`} height={`${ICON_SIZE}px`} />
    </AuthButton>
  )
}
