import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { svgIconsName } from 'res'
import { PickerButton } from '../..'

interface ServiceMethodDetailsProps {
  order?: Bff.Orders.Order
}

const ServiceMethodDetails = ({ order }: ServiceMethodDetailsProps) => {
  const { t } = useTranslation('tracker')
  const theme = useDominosTheme()

  const serviceMethod: BffContext.ServiceMethods = order!.details!.header.serviceMethod

  const placeholderText =
    serviceMethod === 'Delivery'
      ? order!.details!.header.deliveryAddress!.displayAddress || ''
      : order!.store?.media?.name ?? t('StoreUnknownError', { defaultValue: 'Store Unknown' })

  return (
    <PickerButton
      testID={'OrderDetails.store'}
      title={t(serviceMethodTitleText[serviceMethod])}
      placeholderText={placeholderText}
      icon={serviceMethodIcon[serviceMethod]}
      iconWidth={22}
      iconHeight={26}
      iconColor={theme.colours.dominosSlate}
    />
  )
}
const serviceMethodTitleText: {
  [method in BffContext.ServiceMethods]: string
} = {
  Pickup: 'Pick up from',
  Delivery: 'Delivery to',
}
const serviceMethodIcon: {
  [method in BffContext.ServiceMethods]: svgIconsName
} = {
  Pickup: 'pickUp',
  Delivery: 'delivery',
}

export { ServiceMethodDetails }
