import { ApolloError } from '@apollo/client'
import { useInitiateOrder, UseInitiateOrderProps, usePlaceOrder } from '@dominos/hooks-and-hocs'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useFosOrderButtonClickedEvent } from '../payment-container/use-fos-order-button-clicked-event'

export const useFreeOrder = (onComplete: () => void, onError: (error: ApolloError | Error) => void) => {
  const sendOrderButtonClickedEvent = useFosOrderButtonClickedEvent()
  const customerCheckoutDetails = useSelector(
    (store: RootReducer) => store.currentOrderDetailsReducer.customerDetailsData,
  )
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)

  const {
    placeOrder,
    loading: placingOrder,
    isPurchaseReported: placeOrderCompleted,
  } = usePlaceOrder({
    handlePlaceOrderError: onError,
    onOrderConfirmed: () => {},
    onPaymentNextStep: () => {},
  })

  const onInitiatedOrder: UseInitiateOrderProps['onReady'] = ({ properties: propertiesFromInitOrder }) => {
    const passingThroughProps = propertiesFromInitOrder?.map(({ key, value }) => ({ key, value }))
    const payment = {
      paymentMethod: 'Cash',
      providerCode: 'Cash',
      properties: passingThroughProps,
      amount: 0,
    } as Bff.Orders.PlaceOrder.Payment
    placeOrder(basketData.id, payment)
  }

  const { initiateOrder, loading: initiatingOrder } = useInitiateOrder({
    onReady: onInitiatedOrder,
    handleInitiateOrderError: () => onError(new Error('error initiating order')),
  })

  useEffect(() => {
    if (placeOrderCompleted) {
      onComplete()
    }
  }, [placeOrderCompleted])

  const process = () => {
    const paymentDetails: Bff.Orders.InitialiseOrderPayment = {
      paymentMethod: 'Cash',
      providerCode: 'Cash',
      amount: 0,
    }

    initiateOrder(paymentDetails, customerCheckoutDetails, undefined)
    sendOrderButtonClickedEvent()
  }

  const isProcessing = useMemo(() => initiatingOrder || placingOrder, [initiatingOrder, placingOrder])

  return {
    process,
    isProcessing,
  }
}
