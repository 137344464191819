import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const fifteen = 15

const defaultDimensions = {
  width: 30,
  height: 30,
}

const GeoIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`}>
      <g fill='none' fillRule='evenodd'>
        <circle fill='#F49934' cx={fifteen} cy={fifteen} r={fifteen} />
        <path fill={fill} d='M7.323 15.193L20.677 9.22 14.71 22.78 14 16.04z' />
      </g>
    </svg>
  )
}

export { GeoIcon }
