import { ActionButton } from '@dominos/components/buttons'
import { FacebookIcon } from '@dominos/res/images/icons/components/facebook-icon'
import React from 'react'

import { useLoginTranslations } from '../use-login-translations'
import css from './facebook.less'

interface FacebookNativeProps {
  loading: boolean
  onSignIn: () => void
}
export const FacebookNative = (props: FacebookNativeProps) => {
  const t = useLoginTranslations()
  const [title] = t.getSocials('Facebook')

  return (
    <ActionButton
      testID='facebook-native-signin'
      loading={props.loading}
      onPress={props.onSignIn}
      containerStyle={styles.btn}
    >
      <div className={css.facebookicon}>
        <FacebookIcon fill={'#ffffff'} />
      </div>
      <label className={css.buttonLabel}>{title}</label>
    </ActionButton>
  )
}

const styles: { [k: string]: CommonViewStyle } = {
  btn: {
    width: '100%',
    height: '54px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '10px 0',
    cursor: 'pointer',
    backgroundColor: '#1778f2',
  },
}
