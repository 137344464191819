import { useAllOffers } from '@dominos/hooks-and-hocs/offers'
import { useEffect, useState } from 'react'
import { useKiosk, useMenu } from '@dominos/hooks-and-hocs'

import { upsellOfferFilter } from './offers-helpers'
import { HardUpsellItem } from '@dominos/components/hard-upsell/types'

const EXCLUDED_CATEGORIES = ['Menu.Pizza', 'Menu.MyBox', 'Menu.MyDominosBox']
const HARDUPSELL_LIMIT_KIOSK = 8
const HARDUPSELL_LIMIT_DEFAULT = 6
const INLINE_UPSELL_LIMIT = 3
interface UpsellProps {
  allowedLayout: string
  offerLocationCode: string
  locationType: string
}

export const useUpsellOffer = ({ allowedLayout, offerLocationCode, locationType }: UpsellProps) => {
  const { offers } = useAllOffers()
  const { itemsByCode } = useMenu()
  const { isKioskOrder } = useKiosk()
  const [offerItems, setOfferItems] = useState<HardUpsellItem[]>([])

  const upsellLimit =
    offerLocationCode === 'Menu.Basket'
      ? INLINE_UPSELL_LIMIT
      : isKioskOrder
      ? HARDUPSELL_LIMIT_KIOSK
      : HARDUPSELL_LIMIT_DEFAULT

  useEffect(() => {
    const filteredOffers = upsellOfferFilter(
      offers,
      itemsByCode,
      allowedLayout,
      offerLocationCode,
      locationType,
      upsellLimit,
      EXCLUDED_CATEGORIES,
    )
    setOfferItems(filteredOffers?.[0]?.items || [])
  }, [JSON.stringify(offers), itemsByCode, isKioskOrder])

  return offerItems
}
