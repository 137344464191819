import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { detailsFieldNames } from '../details-container-config'
import styles from './vip-offers.less'
import { VipOffersCheckbox, VipOffersProps, VipOffersTC } from '.'

export const VipOffersGeneric = (props: VipOffersProps) => {
  const { t } = useTranslation('checkout')

  useEffect(() => {
    props.onChange(detailsFieldNames.vipOffersEmail, !!props.initialVipOffersEmailChecked)
    props.onChange(detailsFieldNames.vipOffersSMS, !!props.initialVipOffersSMSChecked)
  }, [])

  return (
    <div className={styles.container}>
      <VipOffersCheckbox
        testID={`vip-offers-generic.${detailsFieldNames.vipOffersEmail}`}
        onChange={props.onChange}
        defaultChecked={props.initialVipOffersEmailChecked}
        name={detailsFieldNames.vipOffersEmail}
        label={t('VIPOffersEmail', { defaultValue: 'Get VIP Offers via **email**' })}
        labelClassName={styles.checkboxLabel}
      />
      <VipOffersCheckbox
        testID={`vip-offers-generic.${detailsFieldNames.vipOffersSMS}`}
        onChange={props.onChange}
        defaultChecked={props.initialVipOffersSMSChecked}
        name={detailsFieldNames.vipOffersSMS}
        label={t('VIPOffersSMS', { defaultValue: 'Get VIP Offers via **SMS**' })}
        labelClassName={styles.checkboxLabel}
      />

      {props.vipOffersTCEnabled && <VipOffersTC />}
    </div>
  )
}
