import React, { ComponentProps } from 'react'
import {
  CountryToggle,
  DeliveryAddressContainer,
  DeliveryAddressContainerJP,
  SimpleNavigationTitle,
  StackNavigationBase,
} from '@dominos/components'

import css from './delivery-address-scene.less'
import { DeliveryAddressPostCodeContainer } from '@dominos/components/delivery-address/delivery-address-postcode-container/delivery-address-postcode-container'
import { DeliveryAddressContainerMY } from '@dominos/components/delivery-address/delivery-address-container-my/delivery-address-container-my'
import { useValidation } from '@dominos/business/functions'
import { AddressInputFieldSettings } from '@dominos/interfaces/delivery-address/address-fields'
const SG_POSTCODE_LENGTH = 6
const SG_MAX_POSTCODE_RESULTS_DISPLAY = 10

export const DeliveryAddressScene = () => {
  const { isNumeric, isAlphaNumeric, isRequired } = useValidation()

  return (
    <StackNavigationBase testID='deliveryAddressScene' header={<SimpleNavigationTitle showAccount showBackButton />}>
      <div className={css.wrapper}>
        <div id='delivery-address-generic-layout-main-div' className={css.main}>
          <CountryToggle
            default={<DeliveryAddressContainer />}
            JP={<DeliveryAddressContainerJP />}
            MY={<DeliveryAddressContainerMY />}
            SG={
              <DeliveryAddressPostCodeContainer
                {...sgDeliveryAddressContainerProps(isNumeric, isAlphaNumeric, isRequired)}
              />
            }
          />
        </div>
      </div>
    </StackNavigationBase>
  )
}

type DeliveryAddressPostCodeContainerProps = ComponentProps<typeof DeliveryAddressPostCodeContainer>

const addressFieldTypeSelector: DeliveryAddressPostCodeContainerProps['addressFieldTypeOptions'] = [
  {
    optionLabel: 'landed',
    optionValue: 'Landed',
    addressFieldNames: ['buildingName'],
  },
  {
    optionLabel: 'nonLanded',
    optionValue: 'Non-Landed',
    addressFieldNames: ['unitNo', 'floorNo', 'buildingName'],
  },
]

const singaporeAddressSearchResultFields: AddressInputFieldSettings[] = [
  {
    addressFieldName: 'streetNo',
    displayOrder: 1,
    disabled: true,
  },
  {
    addressFieldName: 'street',
    displayOrder: 2,
    disabled: true,
  },
]

const singaporeAdditionalAddressInfoFields = (
  isNumeric: ValidationFunction<string>,
  isAlphaNumeric: ValidationFunction<string>,
  isRequired: ValidationFunction<string>,
): AddressInputFieldSettings[] => [
  {
    addressFieldName: 'floorNo',
    displayOrder: 1,
    validationRules: [isNumeric, isRequired],
  },
  {
    addressFieldName: 'unitNo',
    displayOrder: 2,
    validationRules: [isAlphaNumeric, isRequired],
  },
  {
    addressFieldName: 'buildingName',
    displayOrder: 3,
  },
]

const singaporeAddressSearchResultFieldsGroup =
  (): DeliveryAddressPostCodeContainerProps['addressFieldsFromAddressSearchResult'] => ({
    additionalAddressFields: singaporeAddressSearchResultFields,
  })

const singaporeAdditionalAddressInfoFieldsGroup = (
  isNumeric: ValidationFunction<string>,
  isAlphaNumeric: ValidationFunction<string>,
  isRequired: ValidationFunction<string>,
): DeliveryAddressPostCodeContainerProps['additionalAddressInformationRequired'] => ({
  additionalAddressFields: singaporeAdditionalAddressInfoFields(isNumeric, isAlphaNumeric, isRequired),
})

const sgDeliveryAddressContainerProps = (
  isNumeric: ValidationFunction<string>,
  isAlphaNumeric: ValidationFunction<string>,
  isRequired: ValidationFunction<string>,
): DeliveryAddressPostCodeContainerProps => ({
  postCodeLength: SG_POSTCODE_LENGTH,
  numericValesOnly: true,
  maxNumberPostCodeResults: SG_MAX_POSTCODE_RESULTS_DISPLAY,
  addressFieldTypeOptions: addressFieldTypeSelector,
  addressFieldsFromAddressSearchResult: singaporeAddressSearchResultFieldsGroup(),
  additionalAddressInformationRequired: singaporeAdditionalAddressInfoFieldsGroup(
    isNumeric,
    isAlphaNumeric,
    isRequired,
  ),
  hasClearInputButtonPostcodeSearch: true,
  hasClearInputButtonAdditionalAddressFields: false,
})
