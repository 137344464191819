import { Switch } from '@dominos/components'
import { MessageIcon } from '@dominos/res/images/icons/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getLoyaltyTranslations } from '../loyalty-translations'
import css from '../loyalty.less'

type LoyaltyMarketingProps = BaseProps & { disabled?: boolean; value: boolean; onChange: (checked: boolean) => void }

export const LoyaltyMarketing = ({ testID, disabled, value, onChange }: LoyaltyMarketingProps) => {
  const { t } = useTranslation('loyalty')
  const translations = getLoyaltyTranslations(t)

  const onReceiveUpdatesSwitch = (_: string, checked: boolean) => onChange(checked)

  return (
    <div data-testid={testID} className={css.loyaltySlice}>
      <MessageIcon />
      <div className={css.loyaltySliceText}>
        <span>{translations.LoyaltyUpdatesText}</span>
        <div className={css.joinSwitch}>
          <Switch
            testID={'Loyalty.ReceiveUpdatesSwitch'}
            name='updatesAccepted'
            checked={value}
            onChange={onReceiveUpdatesSwitch}
            disabled={disabled}
          >
            <span className={css.switchLabel}>{translations.ReceiveUpdatesSwitch}</span>
          </Switch>
        </div>
      </div>
    </div>
  )
}
