import { rootActions } from '@dominos/business/root.actions'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs/selectors/use-current-order-details'
import { useBasket } from '@dominos/hooks-and-hocs/selectors/use-basket'
import { NavigationConstants } from '@dominos/navigation/navigation-constants'
import { navigate } from '@reach/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { isSilentRoute } from './silent-routes'
import { isNativeApp } from '@dominos/business/functions'

export const EXPIRE_CUSTOMER_DETAILS_TIMEOUT = 3600 * 1000

export const useExpireCurrentOrder = () => {
  const [notifyOrderExpired, setNotifyOrderExpired] = useState<boolean>(false)
  const dispatch = useDispatch()
  const { customerDetailsData } = useCurrentOrderDetails()
  const { loadedAt } = useBasket()

  let timer: NodeJS.Timeout

  const clearCustomerDetails = () => {
    dispatch(rootActions.clearCustomerDetails())

    if (!isSilentRoute()) {
      setNotifyOrderExpired(true)
    }
  }

  const onExpiryAcknowledgement = () => {
    if (!isNativeApp()) {
      navigate(NavigationConstants.home)
    }
    setNotifyOrderExpired(false)
  }

  useEffect(() => {
    if (!!customerDetailsData) {
      timer = setTimeout(() => {
        clearCustomerDetails()
      }, EXPIRE_CUSTOMER_DETAILS_TIMEOUT)
    }

    return () => clearTimeout(timer)
  }, [customerDetailsData, loadedAt])

  return {
    notifyOrderExpired,
    onExpiry: onExpiryAcknowledgement,
  }
}
