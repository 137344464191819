import React from 'react'
import css from './inline-error.less'

interface InlineErrorProps {
  message: string
  showError?: boolean
}

export const InlineError = ({ message, showError }: InlineErrorProps) =>
  !!showError ? <p className={css.error}>{message}</p> : null
