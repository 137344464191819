import { getFormattedPrice } from '@dominos/business/functions/text'
export const getSurchargeText = (
  lineData: BasketLine | BasketLinePortion | Bff.Orders.OrderDetails.Basket.ProductLine,
  NotAllOtherSurcharges: string[],
  code?: string,
) => {
  if (!lineData.surcharges) {
    return null
  }
  if (code) {
    const found = lineData.surcharges.find((surcharge) => surcharge.code === code)

    return found && `${getFormattedPrice(found.price)}`
  }
  const others = lineData.surcharges.filter((surcharge) => !NotAllOtherSurcharges.includes(surcharge.code))
  const sum = others.reduce((p, c) => p + c.price, 0)

  return `${getFormattedPrice(sum)}`
}
