import React, { useEffect, useRef } from 'react'
import css from './menu-category-tabs.less'
import { MenuNavigationProps } from '@dominos/components'
import { MenuCategoryTab } from './menu-category-tab/menu-category-tab'
import { getSelectedPageIndex } from '../functions'
import { useFeatures } from '@dominos/hooks-and-hocs'
import classnames from 'classnames'

export const MenuCategoryTabs = (props: MenuNavigationProps) => {
  const { menu, showMenu } = props
  const selectedPageIndex = getSelectedPageIndex(props)
  const scrollViewRef = useRef<HTMLUListElement>(null)
  const [leftNavMenuEnabled] = useFeatures('LeftNavMenu')

  useEffect(() => {
    if (selectedPageIndex != null) scrollButtonIntoView(selectedPageIndex)
  }, [selectedPageIndex])

  const scrollButtonIntoView = (index: number) => {
    if (scrollViewRef.current && index !== null) {
      const button = scrollViewRef.current.getElementsByTagName('li')[index]
      if (button && button.offsetLeft) {
        scrollViewRef.current.scrollTo({
          left: button.offsetLeft - (scrollViewRef.current.offsetWidth - button.offsetWidth) / 2,
          behavior: 'smooth',
        })
      }
    }
  }

  return (
    <section
      data-testid='menu-category-tabs-section'
      className={classnames(css.desktopTopSection, leftNavMenuEnabled && css.leftNavMenu)}
    >
      {showMenu && menu && menu.pages && (
        <ul data-testid='menu-category-tabs-list' ref={scrollViewRef} className={css.tabs}>
          {menu.pages.map((page, index) => (
            <MenuCategoryTab key={index} {...props} menuPage={page} />
          ))}
        </ul>
      )}
    </section>
  )
}
