import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 50,
  height: 45,
}

const TrackerReceiptIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 343.4 477'>
      <path
        fill={fill}
        d={`M314.8,28.6L286.2,0l-28.6,28.6L229,0l-28.6,28.6L171.7,0l-28.6,28.6L114.5,0L85.9,28.6L57.2,0L28.6,28.6L0,0
      v477l28.6-28.6L57.2,477l28.6-28.6l28.6,28.6l28.6-28.6l28.6,28.6l28.6-28.6L229,477l28.6-28.6l28.6,28.6l28.6-28.6l28.6,28.6V0
      L314.8,28.6z M199.8,376.8h-28.1v-33.4h28.1V376.8z M286.2,376.8h-74.4v-33.4h74.4V376.8z M286.2,281.4h-229V248h229V281.4z
       M286.2,205.1h-229v-33.4h229V205.1z M286.2,128.8h-229V95.4h229V128.8z`}
      />
    </svg>
  )
}

export { TrackerReceiptIcon }
