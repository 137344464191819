import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'

export const PaymentB2b = (props: PaymentMethodProps) => {
  const { t } = useTranslation('checkout')

  return (
    <PaymentMethod
      methodId={props.paymentSetting.paymentMethod}
      paymentButtonTitle={t('Order Now')}
      paymentButtonIncludeTotal={true}
      {...props}
    />
  )
}
