import React from 'react'
import { PickerButton, TimedOrderPickerContainerProps } from '@dominos/components'
import { useEstimatedTimeInOrder } from '@dominos/hooks-and-hocs/ordering/use-estimated-time-in-order'

interface PreOrderETAProps extends Pick<TimedOrderPickerContainerProps, 'serviceMethod' | 'loading' | 'testID' | 't'> {
  currentStore: Bff.Stores.Store | undefined
  serviceMethodTitle: string
}

export const PreOrderETA = ({ serviceMethod, loading, serviceMethodTitle, currentStore, t }: PreOrderETAProps) => {
  const {
    estimatedTimeInOrderTimeEnabled,
    estimatedTimeInOrderTitle,
    estimatedTimeInOrderText,
    loadingEstimatedTimeInOrder,
  } = useEstimatedTimeInOrder({ serviceMethodTitle, serviceMethod, currentStore, loading, t })

  if (!estimatedTimeInOrderTimeEnabled) {
    return null
  }

  return (
    <PickerButton
      title={estimatedTimeInOrderTitle}
      icon={'clock'}
      placeholderText={estimatedTimeInOrderText}
      placeholderEllipsis={loading || loadingEstimatedTimeInOrder}
      testID={'OrderNowContainer-PreOrderEta'}
      accessibilityLabel={estimatedTimeInOrderTitle}
    />
  )
}
