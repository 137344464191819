import { useAuthenticationPopup, useFeatures, useForgeRock } from '@dominos/hooks-and-hocs'
import { useEffect } from 'react'

export const useTriggerAuthPopup = (showAuthPopup?: 'login' | 'register') => {
  const { showPopup } = useAuthenticationPopup()
  const { loginForgeRock, registerForgeRock } = useForgeRock()
  const [forgeRockIdentityProviderEnabled] = useFeatures('ForgeRockIdentityProvider')

  useEffect(() => {
    switch (showAuthPopup) {
      case 'register':
        forgeRockIdentityProviderEnabled ? registerForgeRock() : showPopup('register')
        break
      case 'login':
        forgeRockIdentityProviderEnabled ? loginForgeRock() : showPopup('login')
        break
    }
  }, [showAuthPopup, forgeRockIdentityProviderEnabled])
}
