import { ActionButton, CollapsableTitledCard, PickerButton } from '@dominos/components'
import React from 'react'
import externalStyles from '../timed-order-picker-container.less'
import css from '../order-later-now-container/partials/picker-and-action-buttons/picker-and-action-buttons.less'
import { OrderLaterContainerProps } from '../../interfaces'
import { useOrderLaterDescriptions } from '@dominos/hooks-and-hocs/ordering/use-order-later-descriptions'
import { useOrderLaterDateTimeSelector } from '@dominos/hooks-and-hocs/ordering/use-order-later-date-time-selector'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

export const OrderLaterContainer = ({
  openingHours,
  expanded,
  onToggleExpanded,
  onStartOrderAction,
  serviceMethod,
  testID,
  language,
  t,
}: OrderLaterContainerProps) => {
  const theme = useDominosTheme()

  const {
    primaryTitle,
    orderDatePickerTitle,
    orderDatePlaceHolder,
    orderTimePickerTitle,
    startOrderButtonText,
    orderTimePlaceHolder,
  } = useOrderLaterDescriptions(serviceMethod, t)

  const {
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
    selectedDateOrderTimes,
    orderDates,
    canStartOrder,
  } = useOrderLaterDateTimeSelector(openingHours, serviceMethod, t, language)

  const onOrderLaterClick = () => {
    if (selectedTime) onStartOrderAction(selectedTime)
  }

  const defaultPickerTextColour: PickerButtonStyleOverride = {
    pickerDisableTextColour: theme.colours.web.grey,
  }

  return (
    <CollapsableTitledCard
      primaryTitle={primaryTitle}
      startCollapsed={!expanded}
      onToggleExpanded={onToggleExpanded}
      noPadding={false}
      testID='order-later'
    >
      <div className={externalStyles.pickersContainer} data-testid={'order-later-content-wrapper'}>
        <PickerButton
          title={orderDatePickerTitle}
          icon={'calendar'}
          items={orderDates}
          selectedValue={selectedDate?.dayOfTrade ?? ''}
          onValueChange={setSelectedDate}
          testID={`${testID}-OrderDate`}
          accessibilityLabel={orderDatePickerTitle}
          placeholderText={orderDatePlaceHolder}
          onlyShowPickerWhenMultipleItems={true}
          pickerButtonStyleOverride={defaultPickerTextColour}
        />
        <PickerButton
          title={orderTimePickerTitle}
          icon={'clock'}
          items={selectedDateOrderTimes}
          selectedValue={selectedTime?.slotTime ?? ''}
          onValueChange={setSelectedTime}
          testID={`${testID}-OrderTime`}
          accessibilityLabel={orderTimePickerTitle}
          placeholderText={orderTimePlaceHolder}
          onlyShowPickerWhenMultipleItems={true}
        />

        <div className={css['action-buttons']}>
          {expanded && (
            <ActionButton
              text={startOrderButtonText}
              disabled={!canStartOrder}
              onPress={onOrderLaterClick}
              testID='start-order-later-button'
              textFontWeight='bold'
              {...magicStyles.orderLaterButtonStyle}
            />
          )}
        </div>
      </div>
    </CollapsableTitledCard>
  )
}

const magicStyles = {
  orderLaterButtonStyle: {
    containerStyle: {
      width: '100%',
      minHeight: '50',
    },
  },
}
