import { AnalyticSender } from './analytics'

export type BannerInteractionDetails = {
  elementType: 'Special Offers' | 'Automatic Popup' | 'OLO Banner Offers'
  elementId: string
  elementName: string
  elementSource: 'User clicked on special offers button' | 'Auto popup' | 'User clicked on the banner'
  interactionType: string
  items?: Bff.Offers.Item[]
}

export const reportEventBanners = (sendAnalytics: AnalyticSender) => {
  const reportBannerInteraction = (bannerInteractionDetails: BannerInteractionDetails) => {
    sendAnalytics('Banner Interaction', {
      event: 'banner_popup_interacted',
      element_type: bannerInteractionDetails.elementType,
      element_id: bannerInteractionDetails.elementId,
      element_name: bannerInteractionDetails.elementName,
      element_source: bannerInteractionDetails.elementSource,
      interaction_type: bannerInteractionDetails.interactionType,
      items: bannerInteractionDetails.items?.map((item) => item.name).join(','),
    })
  }

  return {
    reportBannerInteraction,
  }
}
