import { formatSectionName } from '@dominos/business/functions/menu'
import React from 'react'

type MenuSectionMedia = Bff.Menu.old.MenuSectionMedia

const doScroll = (elementId: string) => {
  const element = document.getElementById(elementId)
  const header = document.getElementById('menu-navigation')
  const headerOffset = header && header.getBoundingClientRect().height

  const elementTop = element && element.getBoundingClientRect().top
  if (elementTop && headerOffset) {
    window.scrollTo({
      behavior: 'smooth',
      top: elementTop - document.body.getBoundingClientRect().top - headerOffset,
    })
  }
}

const MenuNavSection = ({
  code,
  media,
  onItemClicked,
}: {
  code: string
  media: MenuSectionMedia
  onItemClicked?: (code: string) => void
}) => {
  const formatted = formatSectionName(code)

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()

    const sectionTitle = document.getElementById(formatted)

    if (sectionTitle) {
      doScroll(formatted)
    } else {
      // Navigate when on a variety
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    if (onItemClicked) {
      onItemClicked(code)
    }
  }

  return (
    <a
      onClick={onClick}
      role={'navigation'}
      aria-label={'Menu category'}
      data-testid={`section-button-${code}`}
      rel={'noopener noreferrer'}
    >
      {media.name}
    </a>
  )
}

export { MenuNavSection }
