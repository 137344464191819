import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const ForOneIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='0 0 13.9927 0 13.9927 5.937 0 5.937' />
        <polygon id='path-3' points='0 0 13.0168 0 13.0168 2 0 2' />
        <polygon id='path-5' points='0 0 14.0065 0 14.0065 3 0 3' />
        <polygon id='path-7' points='-4.4408921e-16 0 11.355 0 11.355 6.0584 -4.4408921e-16 6.0584' />
        <polygon id='path-9' points='0 0 12.9989 0 12.9989 6.985 0 6.985' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-22' transform='translate(0.000000, 1.000000)'>
          <g id='Group-10' transform='translate(10.009500, 10.056000)'>
            <g id='Group-3' transform='translate(0.013800, 0.000000)'>
              <mask id='mask-2' fill='white'>
                <use xlinkHref='#path-1' />
              </mask>
              <g id='Clip-2' />
              <path
                d='M21.031,0 C20.58,0 20.157,0.108 19.774,0.288 C20.053,0.91 20.159,1.689 19.786,2.909 C19.753,1.298 18.442,0 16.823,0 C16.372,0 15.949,0.108 15.566,0.288 C15.847,0.914 15.956,1.699 15.572,2.936 C15.571,2.851 15.555,2.771 15.547,2.689 C15.542,2.638 15.543,2.586 15.536,2.536 C15.534,2.524 15.53,2.512 15.528,2.5 C15.51,2.386 15.482,2.275 15.451,2.165 C15.434,2.106 15.417,2.048 15.396,1.99 C15.36,1.887 15.321,1.786 15.274,1.689 C15.244,1.626 15.209,1.567 15.175,1.507 C15.127,1.422 15.081,1.336 15.025,1.256 C14.931,1.122 14.828,0.996 14.715,0.879 C14.715,0.88 14.715,0.88 14.715,0.88 C14.177,0.337 13.431,0 12.606,0 C11.998,0 11.433,0.184 10.962,0.498 C11.183,1.097 11.246,1.842 10.892,2.969 C10.892,2.825 10.882,2.684 10.862,2.546 C10.857,2.51 10.839,2.479 10.833,2.443 C10.794,2.229 10.738,2.021 10.656,1.824 C10.649,1.808 10.64,1.793 10.633,1.777 C10.543,1.574 10.428,1.388 10.298,1.212 C10.286,1.196 10.28,1.176 10.267,1.16 C10.265,1.162 10.264,1.165 10.262,1.168 C9.719,0.462 8.875,0 7.917,0 C7.213,0 6.575,0.255 6.066,0.664 C6.237,1.236 6.26,1.945 5.937,2.969 C5.937,2.825 5.927,2.684 5.907,2.546 C5.904,2.52 5.892,2.496 5.887,2.47 C5.859,2.305 5.814,2.146 5.76,1.992 C5.748,1.96 5.742,1.926 5.729,1.894 C5.298,0.787 4.228,0 2.969,0 C1.329,0 0,1.329 0,2.969 C0,4.608 1.329,5.937 2.969,5.937 C2.979,5.937 2.989,5.935 3,5.934 L3,5.937 L21,5.937 L21,5.934 C21.01,5.935 21.02,5.937 21.031,5.937 C22.67,5.937 24,4.608 24,2.969 C24,1.329 22.67,0 21.031,0'
                id='Fill-1'
                fill={fill}
                mask='url(#mask-2)'
              />
            </g>
            <g id='Group-6' transform='translate(0.989700, 10.982300)'>
              <mask id='mask-4' fill='white'>
                <use xlinkHref='#path-3' />
              </mask>
              <g id='Clip-5' />
              <path
                d='M21.381,2 L1.63,2 C0.73,2 0,1.271 0,0.371 L0,0 L23.01,0 L23.01,0.371 C23.01,1.271 22.281,2 21.381,2'
                id='Fill-4'
                fill={fill}
                mask='url(#mask-4)'
              />
            </g>
            <g id='Group-9' transform='translate(-0.000000, 6.994600)'>
              <mask id='mask-6' fill='white'>
                <use xlinkHref='#path-5' />
              </mask>
              <g id='Clip-8' />
              <path
                d='M22.5,0 L21,0 L19.492,1.508 C19.22,1.78 18.78,1.78 18.508,1.508 L17,0 L14,0 L12.498,1.503 C12.223,1.777 11.777,1.777 11.503,1.503 L10,0 L7,0 L5.479,1.522 C5.214,1.786 4.786,1.786 4.522,1.522 L3,0 L1.5,0 C0.672,0 0,0.672 0,1.5 C0,2.328 0.672,3 1.5,3 L22.5,3 C23.328,3 24,2.328 24,1.5 C24,0.672 23.328,0 22.5,0'
                id='Fill-7'
                fill={fill}
                mask='url(#mask-6)'
              />
            </g>
          </g>
          <g id='Group-21'>
            <g id='Group-13' transform='translate(0.998300, 0.000000)'>
              <mask id='mask-8' fill='white'>
                <use xlinkHref='#path-7' />
              </mask>
              <g id='Clip-12' />
              <path
                d='M0.568,6.0584 L10.788,6.0584 C11.101,6.0584 11.355,5.8044 11.355,5.4904 C11.355,5.4034 11.332,5.3234 11.297,5.2494 C11.062,4.4084 10.282,3.7874 9.368,3.7874 C8.973,3.7874 8.607,3.9064 8.297,4.1054 C8.182,3.9834 8.052,3.8764 7.917,3.7764 L9.305,0.8084 C9.438,0.5244 9.315,0.1864 9.031,0.0534 C8.747,-0.0796 8.409,0.0434 8.276,0.3274 L6.887,3.2984 C6.681,3.2504 6.467,3.2194 6.246,3.2194 C6.067,3.2194 5.895,3.2404 5.726,3.2724 C5.242,2.8894 4.639,2.6514 3.975,2.6514 C3.035,2.6514 2.208,3.1134 1.692,3.8174 C0.929,3.9324 0.313,4.4764 0.093,5.1974 C0.04,5.2844 -4.4408921e-16,5.3804 -4.4408921e-16,5.4904 C-4.4408921e-16,5.8044 0.254,6.0584 0.568,6.0584'
                id='Fill-11'
                fill={fill}
                mask='url(#mask-8)'
              />
            </g>
            <path
              d='M13.0061,4.0409 C13.5541,4.0409 13.9981,3.5969 13.9981,3.0489 C13.9981,2.4999 13.5541,2.0559 13.0061,2.0559 C12.4581,2.0559 12.0141,2.4999 12.0141,3.0489 C12.0141,3.5969 12.4581,4.0409 13.0061,4.0409'
              id='Fill-14'
              fill={fill}
            />
            <path
              d='M4.4988,1.0405 C4.7748,1.0405 4.9988,0.8165 4.9988,0.5405 C4.9988,0.2645 4.7748,0.0415 4.4988,0.0415 C4.2228,0.0415 3.9988,0.2645 3.9988,0.5405 C3.9988,0.8165 4.2228,1.0405 4.4988,1.0405'
              id='Fill-16'
              fill={fill}
            />
            <g id='Group-20' transform='translate(0.000000, 7.055800)'>
              <mask id='mask-10' fill='white'>
                <use xlinkHref='#path-9' />
              </mask>
              <g id='Clip-19' />
              <path
                d='M8.0229,5.969 C8.0229,3.234 10.2449,1.008 12.9789,1.002 C12.9829,0.916 12.9969,0.839 12.9989,0.752 C12.9989,0.749 12.9959,0.748 12.9959,0.745 C12.9999,0.36 12.7409,0 12.3699,0 L0.6319,0 C0.2619,0 0.0039,0.356 0.0039,0.739 C0.0039,0.744 -0.0001,0.747 -0.0001,0.752 C0.0389,3.82 1.6259,5.81 3.8849,6.975 C3.8979,6.982 3.9119,6.985 3.9269,6.985 L4.1269,6.985 L8.1419,6.985 C8.0719,6.656 8.0229,6.319 8.0229,5.969'
                id='Fill-18'
                fill={fill}
                mask='url(#mask-10)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
