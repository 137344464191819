import React from 'react'
import { ReactElement } from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { AdjustmentButton } from './adjustment-button'
import { IngredientAdjustmentButtonProps } from '../product-ingredient-item.interface'

export const IncrementButton = ({ onChange, disabled, testID }: IngredientAdjustmentButtonProps): ReactElement => {
  const theme = useDominosTheme()

  const styles = {
    upButton: {
      backgroundColor: theme.colours.actionStandard,
    },
  }

  return (
    <AdjustmentButton
      testID={`${testID}.button`}
      role='increment'
      adjustmentQuantity={1}
      onChange={onChange}
      icon='plus'
      styles={styles.upButton}
      disabled={disabled}
    />
  )
}
