import * as Auth from '@dominos/components/universal-auth'
import React from 'react'
import { SequenceDefinition } from '../../state-machine'

export const mapNameToComponent = {
  prompt: ({ testID }: BaseProps) => <Auth.Prompt testID={`${testID}.prompt`} />,
  login: ({ testID }: BaseProps) => <Auth.AccountSignIn testID={`${testID}.login`} />,
  loginAdditional: ({ testID }: BaseProps) => <Auth.RegisterEmail testID={`${testID}.additional`} />,
  loginEmail: ({ testID }: BaseProps) => <Auth.SignInEmail testID={`${testID}.login-email`} />,
  registerAdditional: ({ testID }: BaseProps) => <Auth.RegisterEmail testID={`${testID}.additional`} />,
  register: ({ testID }: BaseProps) => <Auth.AccountRegister testID={`${testID}.register`} />,
  registerEmail: ({ testID }: BaseProps) => <Auth.RegisterEmail testID={`${testID}.register-email`} />,
  forgotten: ({ testID }: BaseProps) => <Auth.ResetPassword testID={`${testID}.forgot-password`} />,
  loginPopupWarning: ({ testID }: BaseProps) => <Auth.PopupWarning testID={`${testID}.login-popup-warning`} />,
  registerPopupWarning: ({ testID }: BaseProps) => <Auth.PopupWarning testID={`${testID}.register-popup-warning`} />,
  trackerRegisterPopupWarning: ({ testID }: BaseProps) => (
    <Auth.PopupWarning testID={`${testID}.tracker-register-popup-warning`} />
  ),
  success: () => null,
  failed: () => null,
}

export type StateName = keyof typeof mapNameToComponent

export const authenticationFlow: SequenceDefinition<StateName> = {
  prompt: {
    on: {
      login: { target: 'login' },
      register: { target: 'register' },
    },
  },
  login: {
    on: {
      alternative: {
        target: 'register',
      },
      additional: {
        target: 'loginAdditional',
      },
      warning: {
        target: 'loginPopupWarning',
      },
      next: {
        target: 'loginEmail',
      },
      previous: {
        target: 'register',
      },
    },
  },
  loginAdditional: {
    on: {
      previous: {
        target: 'login',
      },
      login: {
        target: 'login',
      },
      failed: { target: 'failed' },
    },
  },
  loginEmail: {
    on: {
      forgot: {
        target: 'forgotten',
      },
      alternative: {
        target: 'register',
      },
      previous: {
        target: 'login',
      },
    },
  },
  register: {
    on: {
      alternative: {
        target: 'login',
      },
      additional: {
        target: 'registerAdditional',
      },
      warning: {
        target: 'registerPopupWarning',
      },
      next: {
        target: 'registerEmail',
      },
      previous: {
        target: 'prompt',
      },
    },
  },
  registerEmail: {
    on: {
      login: {
        target: 'login',
      },
      previous: {
        target: 'register',
      },
      failed: { target: 'failed' },
    },
  },
  registerAdditional: {
    on: {
      previous: {
        target: 'register',
      },
      login: {
        target: 'login',
      },
      failed: { target: 'failed' },
    },
  },
  forgotten: {
    on: {
      previous: {
        target: 'loginEmail',
      },
    },
  },
  loginPopupWarning: {
    on: {},
  },
  registerPopupWarning: {
    on: {},
  },
  trackerRegisterPopupWarning: {
    on: {},
  },
  success: {
    on: {},
  },
  failed: {
    on: {},
  },
}
