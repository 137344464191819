import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 26,
  height: 25,
}

const GMODocomoIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-28.000000, -368.000000)' fill={fill}>
          <g transform='translate(12.000000, 358.000000)'>
            <g transform='translate(16.240106, 10.000000)'>
              <path
                d='M3.684 12.833c0-1.683 1.11-3.332 3.16-3.332s3.123 1.579 3.159 3.245v.174c-.036 1.667-1.108 3.248-3.16 3.248-2.049 0-3.159-1.65-3.159-3.336M9.955 0v6.778c-.89-.535-1.955-.85-3.19-.85C2.35 5.927 0 9.48 0 12.837c0 3.346 2.348 6.901 6.765 6.901 3.319 0 4.558-1.655 6.872-2.751V0H9.955z'
                transform='translate(-31 -337) translate(7.5 208) translate(8.5 116) translate(15 13) translate(5.663 2.63)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { GMODocomoIcon }
