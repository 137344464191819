import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import React from 'react'
import styles from './generic-button-card.less'
import { svgIconsName } from '@dominos/res/images/icons'

interface GenericButtonCardProps {
  buttonTitle: string
  testID: string
  icon?: svgIconsName
  onClick: () => void
}
const GenericButtonCard: React.FC<React.PropsWithChildren<GenericButtonCardProps>> = (props) => {
  const theme = useDominosTheme()

  return (
    <section
      className={styles.section}
      style={{
        borderRadius: theme.webLargeBorderRadius,
        backgroundColor: theme.colours.defaultBackground,
      }}
      data-testid={`${props.testID}-container`}
    >
      <header
        className={styles.container}
        style={{ cursor: 'pointer' }}
        onClick={props.onClick}
        role={props.buttonTitle}
        data-testid={`${props.testID}-header`}
      >
        <h3 className={styles.h3} data-testid={`${props.testID}-title`} style={{ color: theme.colours.hyperlink }}>
          {props.buttonTitle}
        </h3>
        <div className={styles.iconMargin}>
          {props.icon && <StaticSvgIcon name={props.icon} width={22} height={22} />}
        </div>
      </header>
    </section>
  )
}
export { GenericButtonCard }
