export const addItemsToBasket = (
  lines: BasketLineUnionDependancy[],
  newLines: BasketLine[],
  voucherNo?: number,
  voucherItemNo?: number,
): BasketLineUnionDependancy[] => {
  if (!lines) {
    return []
  }
  if (!newLines || newLines.length === 0) {
    return lines
  }
  if (!!voucherNo && !!voucherItemNo) {
    newLines[0].itemNo = getMaxItemNoFromLines(lines) + 1
    ;(lines.filter((l) => l.type === 'BasketCoupon') as BasketCoupon[])
      .find((c) => c.couponNo === voucherNo)
      ?.items.find((i) => i.itemNo === voucherItemNo)
      ?.lines.push(newLines[0])

    return lines.concat(newLines.slice(1))
  }

  return lines.concat(newLines) // adding newlines reference to lines - consider deep copy with a structuredClone(). if required.
}

const getMaxItemNoFromLines = (lines: BasketLineUnionDependancy[]): number =>
  Math.max(
    ...lines.map((line) => {
      if (line.type === 'BasketLine') {
        return line.itemNo
      }

      return Math.max(...[line.couponNo, Math.max(...line.items?.flatMap((i) => i.lines?.map((l) => l.itemNo)))])
    }),
  )
