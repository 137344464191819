import {
  FormattedVarietyItem,
  getBaseProductPrice,
  getMenuItemByCode,
  getSectionsFromCategoryCode,
  getVarieties,
  resolveMenuSections,
} from '@dominos/business/functions/menu'
import { FlatList, MenuColumnLayout, MenuSection } from '@dominos/components'
import {
  COUPON_MENU_CODE,
  useFeatures,
  useFilterProductItems,
  useHasTopMenuWrapped,
  useMenuCategory,
} from '@dominos/hooks-and-hocs'
import { useParams } from '@reach/router'
import React, { useEffect, useState } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { getVisibleSectionCode } from '../menu-page/functions/get-visible-section-code'
import { MenuSectionHeader } from './menu-section-header'

type SwapSetBaseProduct = Bff.Menu.old.SwapSetBaseProduct

const DELAY_INTERVAL = 4000
const DELAY_SCROLL = 200

// Includes 10px of padding
const NAV_HEIGHT_MOBILE = 95
const NAV_HEIGHT_DESKTOP = 107

interface MenuSectionContainerProps extends BaseProps {
  menu?: Menu
  toggleProductCard?: (
    isEditing: boolean,
    item?: MenuItemDependents,
    lineData?: BasketLineItem,
    swapping?: BasketLine,
    addDirectlyToBasket?: boolean,
  ) => void
  setSection?: (item: string) => void
}

const MenuSectionContainer = ({ menu, testID, setSection, toggleProductCard }: MenuSectionContainerProps) => {
  const params = useParams()
  const { selection, variety } = params
  const menuCategory = useMenuCategory(menu?.pages[0])
  const [categoryData, setCategoryData] = useState<ResolvedMenuSection[] | null>(null)
  const [formattedVarietyItems, setFormattedVarietyItems] = useState<FormattedVarietyItem[] | null>(null)
  const isTopMenuHidden = useHasTopMenuWrapped()
  const { isFiltered, voucherNo, voucherItemNo } = useFilterProductItems()
  const [halfHalfMenuWorkaround] = useFeatures('HalfHalfMenuWorkaround')

  useEffect(() => {
    // TODO: move this categoryData code into the useMenu hook.
    if (menu) {
      const currentMenuSection = isFiltered
        ? resolveMenuSections(menu.pages.find((page) => page.code === COUPON_MENU_CODE)?.sections)
        : getSectionsFromCategoryCode(menu, menuCategory)
      setCategoryData(currentMenuSection)
      const varieties = getVarieties(currentMenuSection)
      setFormattedVarietyItems(varieties ? varieties.getFromCode(variety) : null)
    }
  }, [menu, menuCategory, isFiltered])

  const getBaseProductPriceFromMenu = (baseProduct: SwapSetBaseProduct | undefined) =>
    getBaseProductPrice(categoryData, baseProduct)

  const getMenuItemFromProductCode = (productCode: string) => getMenuItemByCode(categoryData, productCode)

  const toggleProductCardMenu = (
    menuItem?: MenuItemDependents,
    lineData?: BasketLineItem,
    swapping?: BasketLine,
    addDirectlyToBasket?: boolean,
  ) => toggleProductCard && toggleProductCard(false, menuItem, lineData, swapping, addDirectlyToBasket)

  const keyExtractor = (item: ResolvedMenuSection, index: number) => item.code

  const renderMenuSection = (menuSection: ResolvedMenuSection, index: number) => {
    const headerOffset = isTopMenuHidden ? NAV_HEIGHT_MOBILE : NAV_HEIGHT_DESKTOP

    const onChange = (isVisible: boolean) =>
      setSection && setSection(getVisibleSectionCode(isVisible, menuSection, categoryData))

    const isJPHalfHalfMenuWorkAround = halfHalfMenuWorkaround && menuSection.code === 'Menu.Pizza.Designa'

    if (menuSection.data === null || isJPHalfHalfMenuWorkAround) {
      return null
    }

    return (
      <div data-testid={`${testID}.${menuSection.code}.menu-section`} key={index}>
        <VisibilitySensor
          onChange={onChange}
          partialVisibility
          scrollCheck
          resizeCheck
          scrollDelay={DELAY_SCROLL}
          intervalDelay={DELAY_INTERVAL}
          resizeDelay={DELAY_INTERVAL}
          offset={{ top: headerOffset || 0, bottom: headerOffset || 0 }}
        >
          <MenuSectionHeader menuSection={menuSection} />
        </VisibilitySensor>

        <MenuColumnLayout testID={`${testID}.menu-data-column`}>
          <MenuSection
            testID={testID}
            menuItemsInSection={menuSection.data}
            index={index}
            key={index}
            toggleProductCard={toggleProductCardMenu}
            menuCategory={menuCategory}
            selection={selection}
            isFiltered={isFiltered}
            voucherNo={voucherNo}
            voucherItemNo={voucherItemNo}
            getBaseProductPrice={getBaseProductPriceFromMenu}
            getMenuItem={getMenuItemFromProductCode}
          />
        </MenuColumnLayout>
      </div>
    )
  }

  return (
    <FlatList
      data={formattedVarietyItems ? formattedVarietyItems : categoryData}
      renderItem={renderMenuSection}
      keyExtractor={keyExtractor}
    />
  )
}

export { MenuSectionContainer }
