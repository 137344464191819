import React from 'react'
import { ActionButton, CardHeader } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import css from './tracker-forgerock-create-account.less'
import { Divider } from '@dominos/components/cards'
import { useTranslation } from 'react-i18next'

interface CreateAccountProps extends BaseProps {
  onLogin?: () => void
  onRegister?: () => void
}

export const TrackerForgeRockCreateAccountNative = ({ testID, onLogin, onRegister }: CreateAccountProps) => {
  const { t } = useTranslation('login')

  const { colours } = useDominosTheme()

  return (
    <div data-testid={testID}>
      <CardHeader testID={`${testID}.header`} title={t('PromptLabel', { defaultValue: `Got a Domino's Account?` })} />
      <div data-testid={`${testID}.section`} className={css.nativeSection}>
        <div data-testid={`${testID}.description`}>
          {t('PromptDescription', {
            defaultValue: `Unlock faster ordering and easier checkout with a Domino's account`,
          })}
        </div>
        <ActionButton
          testID={`${testID}.register`}
          onPress={onRegister}
          text={t('PromptCreateAnAccountButtonLabel', { defaultValue: `Create an Account` })}
        />
        <Divider testID={`${testID}.divider`} label={t('PromptDividerLabel', { defaultValue: `OR` })} />
        <ActionButton
          testID={`${testID}.login`}
          onPress={onLogin}
          containerStyle={{ backgroundColor: colours.actionDeemphasized }}
          textStyle={{ color: '#3478F6' }}
          text={t('PromptLoginButtonLabel', { defaultValue: `Log In` })}
        />
      </div>
    </div>
  )
}
