import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const SnacksSaladIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='0 0 10.096 0 10.096 5.2429 0 5.2429' />
        <polygon id='path-3' points='0 0 15.9992 0 15.9992 12 0 12' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-11' transform='translate(0.000000, 1.000000)'>
          <path
            d='M7.00847993,5.2428 L1.81247993,0.0468 C0.38047993,1.4778 0.35947993,3.7748 1.73047993,5.2428 L7.00847993,5.2428 Z'
            id='Fill-1'
            fill={fill}
          />
          <g id='Group-5' transform='translate(5.598880, 0.000000)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-4' />
            <path
              d='M3.355,5.0699 L3.355,2.9959 C3.355,2.5819 3.69,2.2469 4.104,2.2469 C4.518,2.2469 4.853,2.5819 4.853,2.9959 L4.853,5.1479 L6.57,3.4309 C6.863,3.1379 7.337,3.1379 7.629,3.4309 C7.922,3.7239 7.922,4.1979 7.629,4.4899 L6.877,5.2429 L9.99,5.2429 C10.052,5.0019 10.096,4.7539 10.096,4.4939 C10.096,2.8389 8.755,1.4979 7.1,1.4979 C6.965,1.4979 6.836,1.5199 6.706,1.5379 C6.193,0.6239 5.226,-0.0001 4.104,-0.0001 C2.982,-0.0001 2.015,0.6239 1.502,1.5379 C1.372,1.5199 1.243,1.4979 1.108,1.4979 C0.716,1.4979 0.344,1.5779 0,1.7149 L2.568,4.2829 L3.355,5.0699 Z'
              id='Fill-3'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <g id='Group-8' transform='translate(7.715580, 11.000000)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-7' />
            <path
              d='M8.121,0 L7.878,0 C7.721,0 7.567,0.016 7.412,0.025 C7.883,1.135 8.153,2.365 8.153,3.667 C8.153,4.24 8.098,4.799 8.001,5.341 C7.605,3.34 6.436,1.651 4.85,0.655 C2.002,1.941 0,4.984 0,8.535 L0,9.347 C0,10.812 1.096,12 2.449,12 L13.55,12 C14.903,12 16,10.812 16,9.347 L16,8.535 C16,3.821 12.472,0 8.121,0'
              id='Fill-6'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
          <path
            d='M11.8242799,10.0146 L12.0812799,9.8986 L12.8532799,9.6726 C13.4732799,9.4916 14.1062799,9.3566 14.7462799,9.2716 L15.1222799,9.2216 C15.2782799,9.2096 15.4352799,9.2006 15.5942799,9.2006 L15.8372799,9.2006 C15.9362799,9.2006 16.0322799,9.2126 16.1302799,9.2156 C16.2522799,8.6726 16.3512799,8.1116 16.4072799,7.5256 C16.4462799,7.1056 16.1162799,6.7406 15.6942799,6.7406 L0.71527993,6.7406 C0.29427993,6.7406 -0.0367200702,7.1056 0.00327992975,7.5256 C0.39827993,11.7076 2.40827993,14.9796 6.69427993,14.9796 L6.91327993,14.9796 C7.91827993,12.7926 9.62927993,11.0056 11.8242799,10.0146'
            id='Fill-9'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}
