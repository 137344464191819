import React from 'react'
import { IncrementButton } from './increment-button'
import { IngredientQuantity } from './ingredient-quantity'
import { RightAdjustmentButtonWithQuantityProps } from '../product-ingredient-item.interface'
import { isDisabled } from '../helpers'

export const RightAdjustmentButtonWithQuantity = ({
  quantity,
  isAddAllowed = true,
  maximumReached = false,
  onChange,
  testID,
}: RightAdjustmentButtonWithQuantityProps) => (
  <IngredientQuantity testID={`${testID}.quantity`} quantity={quantity}>
    <IncrementButton
      onChange={onChange}
      testID={`${testID}.increment`}
      disabled={isDisabled(maximumReached, isAddAllowed)}
    />
  </IngredientQuantity>
)
