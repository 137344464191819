import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const ShakeIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <defs>
        <polygon
          id='path-1'
          points='8.8817842e-16 -1.77635684e-15 16.361 -1.77635684e-15 16.361 13.9999 8.8817842e-16 13.9999'
        />
        <polygon id='path-3' points='0 0 19.9998 0 19.9998 14 0 14' />
      </defs>
      <g id='Thick-Shake' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-7' transform='translate(6.000000, 1.000000)'>
          <g id='Group-3' transform='translate(1.819500, 16.000100)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-2' />
            <path
              d='M2.194,13.164 C2.274,13.646 2.692,14 3.18,14 L13.18,14 C13.669,14 14.086,13.646 14.167,13.164 L16.361,-1.77635684e-15 L8.8817842e-16,-1.77635684e-15 L2.194,13.164 Z'
              id='Fill-1'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <g id='Group-6' transform='translate(0.000000, -0.000000)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-5' />
            <path
              d='M12.855,10 L13.799,5.133 C15.452,6.21 16.626,7.962 16.92,10 L12.855,10 Z M10,4 C10.669,4 11.316,4.096 11.928,4.272 L10.818,10 L3.08,10 C3.567,6.613 6.48,4 10,4 L10,4 Z M19,10 L18.941,10 C18.638,7.276 17.112,4.921 14.93,3.484 L16.707,1.707 C17.097,1.316 17.097,0.684 16.707,0.293 C16.317,-0.098 15.683,-0.098 15.293,0.293 L13.046,2.54 C12.094,2.195 11.07,2 10,2 C5.376,2 1.558,5.507 1.059,10 L1,10 C0.448,10 0,10.448 0,11 C0,11.552 0.448,12 1,12 L1.153,12 L1.486,14 L18.513,14 L18.847,12 L19,12 C19.552,12 20,11.552 20,11 C20,10.448 19.552,10 19,10 L19,10 Z'
              id='Fill-4'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
