import { useFeatures } from '@dominos/hooks-and-hocs/features'

export const useShowAccountDetails = (): boolean => {
  const [isShowAccountDetailsEnabled, ...submenuToggles] = useFeatures(
    'ShowAccountDetails',
    'saved-vouchers',
    'loyalty-backend',
    'MyAccountDetails',
    'MyAccountPassword',
    'MyAccountSavedPayments',
    'MyAccountDelete',
  )

  return isShowAccountDetailsEnabled && submenuToggles.some(Boolean)
}
