import { isProductMenuItemNew } from '@dominos/components'
import { includeVouchersInSubMenu } from './index'

type MenuPage = Bff.Menu.old.MenuPage
type MenuSection = Bff.Menu.old.MenuSection
type VarietyMenuItem = Bff.Menu.old.VarietyMenuItem

export const flattenMenu = (pages: MenuPage[] | null): MenuItemDependentsDictionary | null => {
  const memo: MenuItemDependentsDictionary = {}
  if (!pages) {
    return null
  }

  pages.forEach((p) => {
    if (p.sections) {
      const includeVouchersSubMenu = includeVouchersInSubMenu(p)
      p.sections.forEach((section) => {
        addMenuSection(section, memo, p.code, includeVouchersSubMenu)
      })
    }
  })

  return memo
}

const addMenuSection = (
  section: MenuSection | VarietyMenuItem,
  memo: MenuItemDependentsDictionary,
  page: string,
  includeVouchersSubMenu: boolean,
) => {
  if (section.items) {
    section.items.forEach((item) => {
      if (!isProductMenuItemNew(item) && (item.type === 'Product' || item.type === 'Portion')) {
        memo[item.code] = { ...item, category: page }
      }
      if (item.type === 'Voucher' && includeVouchersSubMenu) {
        memo[item.code] = { ...item, category: page }
      }
      if (item.type === 'Variety') {
        addMenuSection(item, memo, page, includeVouchersSubMenu)
      }
    })
  }
}
