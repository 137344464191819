/**
 * Currently the only way to detect if browser is blocking popups is to try opening a new popup window manually.
 * Remove this when we can remove popup for facebook and google login
 * https://stackoverflow.com/questions/668286/detect-blocked-popup-in-chrome
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/vendor
 */

export const safariPopupBlockDetect = () => {
  const isSafari = /Apple/i.test(window.navigator.vendor)

  if (!isSafari) {
    return false
  }

  const detectNewWindow = window.open('/')
  if (!detectNewWindow || !Object.keys(detectNewWindow).length) {
    return true
  }

  detectNewWindow.close()

  return false
}
