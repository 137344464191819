import {
  RoundUpForCharityImageCountries,
  RoundUpForCharityImageResize,
  RoundUpForCharityImages,
  RoundUpForCharityImageType,
} from './round-up-for-charity-static-images'

export const getRoundUpForCharityImages = (
  country: BffContext.Countries | undefined,
  type: RoundUpForCharityImageType,
) => country && RoundUpForCharityImages[country as RoundUpForCharityImageCountries]?.[type]

export const isTrackerBannerHalfWidth = (
  country: BffContext.Countries | undefined,
  resize: RoundUpForCharityImageResize,
) => country && RoundUpForCharityImages[country as RoundUpForCharityImageCountries]?.[resize]
