import { ErrorDefinition, getDefaultValue } from '@dominos/components/error'

type NotificationErrorCodes = 'NOTIFICATION_ALREADY_SENT'

export const notifyStoreErrorDefinitions: PartialRecord<NotificationErrorCodes | string, ErrorDefinition> = {
  NOTIFICATION_ALREADY_SENT: {
    displayType: 'closable-popup',
    translation: {
      title: { key: 'NotifyStoreAlreadySentTitle', getOptions: getDefaultValue },
      message: { key: 'NotifyStoreAlreadySentMessage', getOptions: getDefaultValue },
    },
    icon: 'infoIcon',
    testID: 'tracker-notification-already-sent-error',
  },
}
