import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation, validate } from '@dominos/business/functions/validation'
import { FancyTextField } from '@dominos/components'

const minPasswordLength = 8
const maxPasswordLength = 250
export interface PasswordInputProps {
  onPasswordChanged: (password: string) => void
  onPasswordValid: (isValid: boolean) => void
  passwordPlaceholder: string
  passwordConfirmPlaceholder: string
}

export const PasswordInput = (props: PasswordInputProps) => {
  const { isRequired, maxLength, minLength, isPasswordValid } = useValidation()
  const { t } = useTranslation('login')

  const passwordsMustMatch = (inputValue?: string) => (value?: string | null) =>
    !value || value === inputValue ? null : t('PasswordsMustMatchError')

  const [errorMessagePassword, setErrorMessagePassword] = useState<string | null>(null)
  const [errorMessagePasswordConfirm, setErrorMessagePasswordConfirm] = useState<string | null>(null)

  const [passwordText, setPasswordText] = useState<string | undefined>(undefined)
  const [passwordConfirmText, setPasswordConfirmText] = useState<string | undefined>(undefined)
  const [passwordConfirmTouched, setPasswordConfirmTouched] = useState(false)

  const onPasswordChange = (value: string) => {
    setPasswordText(value)
    onChange(value, passwordConfirmText, passwordConfirmTouched)
  }

  const onPasswordConfirmChange = (value: string) => {
    setPasswordConfirmTouched(true)
    setPasswordConfirmText(value)
    onChange(passwordText, value, true)
  }

  const onChange = (
    password: string | undefined,
    passwordConfirm: string | undefined,
    validateConfirmedField: boolean,
  ) => {
    const passwordValidationRules = [
      isRequired,
      minLength(minPasswordLength),
      maxLength(maxPasswordLength),
      isPasswordValid,
    ]
    const passwordConfirmValidationRules = [isRequired, passwordsMustMatch(password)]

    const passwordValidationResult = validate(password, ...passwordValidationRules)
    setErrorMessagePassword(passwordValidationResult)

    let passwordConfirmationValidationResult: string | null = null
    if (validateConfirmedField) {
      passwordConfirmationValidationResult = validate(passwordConfirm, ...passwordConfirmValidationRules)
      setErrorMessagePasswordConfirm(passwordConfirmationValidationResult)
    }
    const isPasswordsValid =
      passwordValidationResult == null && validateConfirmedField && passwordConfirmationValidationResult == null

    props.onPasswordValid(isPasswordsValid)

    if (isPasswordsValid && password) {
      props.onPasswordChanged(password)
    }
  }

  return (
    <>
      <FancyTextField
        testID={`Password.field`}
        placeholder={props.passwordPlaceholder}
        onChange={onPasswordChange}
        errorMessage={errorMessagePassword}
        style={{ width: '100%' }}
        secureTextEntry={true}
        autoComplete='new-password'
      />
      <FancyTextField
        testID={`PasswordConfirm.field`}
        placeholder={props.passwordConfirmPlaceholder}
        onChange={onPasswordConfirmChange}
        errorMessage={errorMessagePasswordConfirm}
        style={{ width: '100%' }}
        secureTextEntry={true}
        autoComplete='new-password'
      />
    </>
  )
}
