import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge3 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M22.1935,14.459 L15.6215,14.459 C15.1875,14.459 14.8465,14.118 14.8465,13.684 L14.8465,10.925 C14.8465,10.491 15.1875,10.15 15.6215,10.15 L24.8285,10.15 C26.0995,10.15 27.1535,11.204 27.1535,12.475 L27.1535,17.683 C27.1535,18.582 26.7505,19.202 26.0995,19.822 L25.1075,20.752 C25.0455,20.814 25.0455,20.907 25.1075,20.938 L26.0995,21.806 C26.7505,22.364 27.1535,22.984 27.1535,23.821 L27.1535,29.525 C27.1535,30.796 26.0995,31.85 24.8285,31.85 L15.6215,31.85 C15.1875,31.85 14.8465,31.509 14.8465,31.075 L14.8465,28.316 C14.8465,27.882 15.1875,27.541 15.6215,27.541 L22.1935,27.541 C22.2865,27.541 22.3485,27.479 22.3485,27.386 L22.3485,23.077 C22.3485,22.984 22.2865,22.922 22.1935,22.922 L18.1325,22.922 C17.6985,22.922 17.3575,22.581 17.3575,22.147 L17.3575,19.698 C17.3575,19.264 17.6985,18.923 18.1325,18.923 L22.1935,18.923 C22.2865,18.923 22.3485,18.861 22.3485,18.768 L22.3485,14.614 C22.3485,14.521 22.2865,14.459 22.1935,14.459 Z'
          id='3'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
