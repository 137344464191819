export enum OrderStatus {
  Unknown = 'Unknown',
  New = 'New',
  Pending = 'Pending',
  PaymentFailed = 'PaymentFailed',
  Failed = 'Failed',
  SentToStore = 'SentToStore',
  Timed = 'Timed',
  Making = 'Making',
  Cooking = 'Cooking',
  Ready = 'Ready',
  Leaving = 'Leaving',
  Remaking = 'Remaking',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
}

export const SuccessfulOrderStatus: OrderStatus[] = [
  OrderStatus.SentToStore,
  OrderStatus.Timed,
  OrderStatus.Making,
  OrderStatus.Cooking,
  OrderStatus.Ready,
  OrderStatus.Leaving,
  OrderStatus.Remaking,
  OrderStatus.Complete,
]

export const WaitingOrderStatus: OrderStatus[] = [
  OrderStatus.SentToStore,
  OrderStatus.Making,
  OrderStatus.Cooking,
  OrderStatus.Ready,
  OrderStatus.Leaving,
]

export const NeedETAStatus: OrderStatus[] = [
  OrderStatus.New,
  OrderStatus.Pending,
  OrderStatus.PaymentFailed,
  OrderStatus.SentToStore,
  OrderStatus.Timed,
  OrderStatus.Making,
  OrderStatus.Cooking,
  OrderStatus.Ready,
  OrderStatus.Leaving,
  OrderStatus.Remaking,
]
