import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const PizzaSandwichIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <defs>
        <polygon id='path-1' points='0 0 16.5889 0 16.5889 4 0 4' />
        <polygon id='path-3' points='0 0 10.7996 0 10.7996 4 0 4' />
      </defs>
      <g id='Pizzas-&amp;-Sandwiches' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-23' transform='translate(0.000000, 1.000000)'>
          <path
            d='M24,4 C23.448,4 23,3.552 23,3 C23,2.448 23.448,2 24,2 C24.552,2 25,2.448 25,3 C25,3.552 24.552,4 24,4 M16,4 C15.448,4 15,3.552 15,3 C15,2.448 15.448,2 16,2 C16.552,2 17,2.448 17,3 C17,3.552 16.552,4 16,4 M8,4 C7.448,4 7,3.552 7,3 C7,2.448 7.448,2 8,2 C8.552,2 9,2.448 9,3 C9,3.552 8.552,4 8,4 M24.955,0 L7.045,0 C4.259,0 2,2.259 2,5.045 C2,5.572 2.428,6 2.955,6 L29.045,6 C29.572,6 30,5.572 30,5.045 C30,2.259 27.741,0 24.955,0'
            id='Fill-1'
            fill={fill}
          />
          <path
            d='M16.2136,11.8608 C19.1936,14.5318 21.3986,17.1308 22.9556,19.8048 C24.5126,22.4798 25.6816,25.6818 26.5326,29.5918 L28.4296,29.5538 C29.0286,29.5418 29.5856,29.2658 29.9566,28.7948 C30.3286,28.3248 30.4686,27.7178 30.3426,27.1318 C29.5086,23.2898 28.3646,20.1528 26.8446,17.5418 C25.3256,14.9308 23.1626,12.3868 20.2346,9.7638 C19.7876,9.3628 19.1906,9.1858 18.5976,9.2768 C18.0056,9.3678 17.4906,9.7148 17.1836,10.2298 L16.2136,11.8608 Z'
            id='Fill-3'
            fill={fill}
          />
          <path
            d='M18.4775,17 C17.6235,16.006 16.6575,15.009 15.5765,14 L14.9425,14 L13.1575,17 L18.4775,17 Z'
            id='Fill-5'
            fill={fill}
          />
          <path
            d='M21.1782,17 L26.5112,17 C25.8902,15.995 25.1712,14.997 24.3482,14 L18.5502,14 C19.5292,15.002 20.4032,16 21.1782,17'
            id='Fill-7'
            fill={fill}
          />
          <path
            d='M28.5735,16.5361 C28.6325,16.6371 28.6865,16.7431 28.7435,16.8451 C29.4805,16.5401 30.0005,15.8151 30.0005,14.9681 C30.0005,14.4331 29.5665,14.0001 29.0315,14.0001 L26.8875,14.0001 C27.5115,14.8401 28.0775,15.6831 28.5735,16.5361'
            id='Fill-9'
            fill={fill}
          />
          <path
            d='M10.8306,17 L12.6156,14 L2.9686,14 C2.4336,14 1.9996,14.434 1.9996,14.968 C1.9996,16.09 2.9096,17 4.0316,17 L10.8306,17 Z'
            id='Fill-11'
            fill={fill}
          />
          <path
            d='M20.2341,9.7632 C19.8641,9.4322 19.3911,9.2532 18.9031,9.2532 C18.8021,9.2532 18.7001,9.2612 18.5981,9.2772 C18.0051,9.3672 17.4901,9.7152 17.1841,10.2292 L16.2491,11.8012 L16.4681,12.0002 L22.5571,12.0002 C21.8411,11.2582 21.0731,10.5142 20.2341,9.7632'
            id='Fill-13'
            fill={fill}
          />
          <g id='Group-17' transform='translate(0.000000, 8.000400)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-16' />
            <path
              d='M14.4949,2.838 L15.4649,1.207 C15.7539,0.721 16.1419,0.317 16.5889,0 L8.9999,0 L6.9999,2 L4.9999,0 L1.9999,0 C0.8959,0 -0.0001,0.895 -0.0001,2 C-0.0001,3.104 0.8959,4 1.9999,4 L13.8039,4 L14.4949,2.838 Z'
              id='Fill-15'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <g id='Group-20' transform='translate(21.200400, 8.000400)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-19' />
            <path
              d='M0.368,0.273 C1.764,1.523 2.989,2.76 4.072,4 L8.8,4 C9.904,4 10.8,3.104 10.8,2 C10.8,0.895 9.904,0 8.8,0 L0,0 C0.125,0.088 0.253,0.17 0.368,0.273'
              id='Fill-18'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
          <path
            d='M19.8286,28.0977 C19.2916,28.4097 18.6026,28.2277 18.2906,27.6907 C17.9776,27.1537 18.1596,26.4657 18.6966,26.1527 C19.2336,25.8407 19.9226,26.0227 20.2346,26.5597 C20.5476,27.0967 20.3656,27.7857 19.8286,28.0977 M17.3336,23.6437 C16.0806,24.3727 14.4736,23.9477 13.7446,22.6947 C13.0156,21.4417 13.4396,19.8347 14.6926,19.1057 C15.9456,18.3767 17.5526,18.8017 18.2816,20.0547 C19.0116,21.3067 18.5866,22.9137 17.3336,23.6437 M13.4556,28.4957 C12.5606,29.0167 11.4126,28.7137 10.8916,27.8187 C10.3706,26.9237 10.6746,25.7757 11.5696,25.2547 C12.4646,24.7337 13.6126,25.0367 14.1326,25.9317 C14.6536,26.8267 14.3506,27.9747 13.4556,28.4957 M21.4356,20.9377 C19.9976,18.4667 17.9546,16.0487 15.2066,13.5557 L6.1056,28.8527 C5.9656,29.0867 5.9646,29.3777 6.1016,29.6137 C6.2386,29.8487 6.4926,29.9917 6.7656,29.9857 L24.7766,29.9997 C23.9666,26.3797 22.8736,23.4087 21.4356,20.9377'
            id='Fill-21'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}
