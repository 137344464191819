/* eslint-disable max-lines */
import React from 'react'
import css from './product-card-wrapper.less'
import { useFocus } from '@dominos/components/product/product-card/use-focus'
import { useBreakpoints, useEscape, useKiosk } from '@dominos/hooks-and-hocs'
import { useOverflow } from '@dominos/components/product/product-card/use-overflow'

export const ProductCardWrapper = ({ testID, onDismiss, children }: ProductCardWrapperProps) => {
  const { isNotMobile } = useBreakpoints()
  const containerRef = useFocus(onDismiss)
  const { isKioskOrder } = useKiosk()

  const handleCapturedClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    (event.target === containerRef.current && onDismiss()) || event.stopPropagation()

  useEscape(onDismiss)
  useOverflow(isNotMobile && !isKioskOrder)

  return (
    <div
      data-testid={testID}
      className={css.productCardWrapper}
      ref={containerRef}
      role='dialog'
      onClick={handleCapturedClick}
      aria-modal
    >
      {children}
    </div>
  )
}
