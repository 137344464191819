import React from 'react'
import { ProductTermsAndConditionsProps } from './product-terms-and-conditions.interface'

export const ProductTermsAndConditionsView = ({
  viewModel,
  TermsAndConditionsViewContent,
  TermsAndConditionsViewContentWrapper,
}: ProductTermsAndConditionsProps): JSX.Element | null => {
  const { isEnabled, title, content } = viewModel

  if (!content || !isEnabled) {
    return null
  }

  return (
    <TermsAndConditionsViewContentWrapper testID={'product.terms-and-conditions'} title={title}>
      <TermsAndConditionsViewContent content={content} />
    </TermsAndConditionsViewContentWrapper>
  )
}
