import { BasketLineData, OptionBasketLineChange } from '.'
import { NOT_SELECTED_OPTION_CODE } from '..'

type RecipeIngredient = Bff.Menu.old.RecipeIngredient

/**
 * Calculates sauce or crust customizations using the provided
 * product item receipe.
 */
export const calculateCustomisationDifference = (
  selection: TouchableGridItem,
  ingredient?: Ingredient | RecipeIngredient[] | null,
  currentProduct?: BasketLineData,
): undefined | BasketLineSwap | OptionBasketLineChange[] => {
  if (isOptionsCustomisation(ingredient)) {
    return calculateOptionsCustomisationDifference(selection, ingredient, currentProduct)
  }

  if (ingredient) {
    const addCode: string | null = selection.code
    if (ingredient.code !== addCode) {
      return {
        add: addCode,
        remove: ingredient.code,
        media: { add: selection.name ? selection.name : '', remove: ingredient.media ? ingredient.media.name : '' },
      }
    }
  }

  return undefined
}

const isOptionsCustomisation = (
  ingredient?: Ingredient | RecipeIngredient[] | null,
): ingredient is RecipeIngredient[] => Array.isArray(ingredient)

const calculateOptionsCustomisationDifference = (
  selection: TouchableGridItem,
  ingredient: RecipeIngredient[],
  currentProduct?: BasketLineData,
) => {
  if (selection.index !== undefined) {
    const optionChanges = currentProduct?.options?.filter((op) => op.index !== selection.index) || []
    const defaultOptionCode = ingredient[selection.index]?.ingredient.code

    if (selection.code !== defaultOptionCode) {
      if (defaultOptionCode) {
        optionChanges.push({
          action: 'Remove',
          code: ingredient[selection.index].ingredient.code,
          quantity: ingredient[selection.index]?.quantity || 1,
          media: {
            name: ingredient[selection.index]?.ingredient.media?.name || '',
          },
          index: selection.index,
        })
      }

      if (selection.code !== NOT_SELECTED_OPTION_CODE) {
        optionChanges.push({
          action: 'Add',
          code: selection.code,
          quantity: ingredient[selection.index]?.quantity || 1,
          media: {
            name: selection.name,
          },
          index: selection.index,
        })
      }
    }

    return optionChanges
  }

  return undefined
}
