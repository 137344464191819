import { getMenuItemByCode } from './get-menu-item-by-code'

type SwapSetBaseProduct = Bff.Menu.old.SwapSetBaseProduct

export const getBaseProductPrice = (
  sections: ResolvedMenuSection[] | null,
  baseProduct: SwapSetBaseProduct | undefined,
) => {
  if (!baseProduct || !sections?.length) {
    return undefined
  }

  const item = getMenuItemByCode(sections, baseProduct.productCode)

  return item?.sizes?.find((size) => size.code === baseProduct.sizeCode)?.price
}
