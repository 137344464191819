import { getCountryConfig } from '@dominos/business/functions/common'

export const getMapCenterAndZoomLevelByCounty = (
  country: BffContext.Countries = getCountryConfig().COUNTRY,
): MapCenterAndZoomLevelResponse => {
  switch (country) {
    case 'AU':
      return { latitude: -24.7761086, longitude: 134.755, zoomLevel: 4 }
    case 'NZ':
      return { latitude: -41.5000831, longitude: 172.8344077, zoomLevel: 6 }
    case 'FR':
      return { latitude: 46.603354, longitude: 1.8883335, zoomLevel: 6 }
    case 'DE':
      return { latitude: 51.1657, longitude: 10.4515, zoomLevel: 6 }
    case 'BE':
      return { latitude: 50.5039, longitude: 4.4699, zoomLevel: 7 }
    case 'NL':
      return { latitude: 52.132633, longitude: 5.291266, zoomLevel: 7 }
    case 'MY':
      return { latitude: 4.2105, longitude: 101.9758, zoomLevel: 6 }
    case 'JP':
      return { latitude: 36.5748441, longitude: 139.2394179, zoomLevel: 5 }
    case 'SG':
      return { latitude: 1.357107, longitude: 103.819839, zoomLevel: 11 }
    default:
      throw `Map center is not provided for ${country} region`
  }
}

export interface MapCenterAndZoomLevelResponse {
  latitude: number
  longitude: number
  zoomLevel: number
}
