import gql from 'graphql-tag'

export const placeOrderMutation = gql`
  mutation placeOrder($input: PlaceOrder!) {
    placeOrder(input: $input) {
      orderId
      nextStep {
        nextStepType
        nextStepActionPayload
        outstandingBalance
      }
    }
  }
`
