import { Portal } from '@dominos/components'
import React, { PropsWithChildren } from 'react'
import css from './modal.less'
import classNames from 'classnames'

export interface ModalProps extends PropsWithChildren<BaseProps> {
  onModalClose?: () => void
  suppressBackgroundScroll?: boolean
  zIndex?: number
  childrenContainerClassNames?: string
  transparentBackground?: boolean
}

export const Modal = ({
  children,
  onModalClose,
  testID,
  suppressBackgroundScroll,
  zIndex,
  childrenContainerClassNames = '',
  transparentBackground = false,
}: ModalProps) => (
  <Portal id={testID} suppressBackgroundScroll={suppressBackgroundScroll} zIndex={zIndex}>
    <div className={css.modalContainer} data-testid={`${testID}.modal`}>
      <div
        className={classNames(css.background, transparentBackground && css.transparent)}
        data-testid={`${testID}.backdrop`}
        {...(!!onModalClose ? { role: 'close', onClick: onModalClose } : {})}
      />
      <div className={`${css.childrenContainer} ${childrenContainerClassNames}`}>{children}</div>
    </div>
  </Portal>
)
