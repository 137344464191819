import React, { ComponentProps } from 'react'

import { StaticSvgIcon } from '@dominos/res'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { AddressSearchButton, Spinner } from '@dominos/components'

import css from './address-search-error-item.less'

type AddressSearchButtonProps = ComponentProps<typeof AddressSearchButton>
type StaticSvgIconProps = ComponentProps<typeof StaticSvgIcon>

interface SearchErrorProps extends BaseProps {
  title: string
  subtitle: string
  isLoading: boolean
  icon?: StaticSvgIconProps['name']
  buttons: {
    type: AddressSearchButtonProps['type']
    onPress?: AddressSearchButtonProps['onPress']
    secondary?: AddressSearchButtonProps['secondary']
  }[]
}

export const AddressSearchErrorItem = ({ testID, title, subtitle, icon, buttons, isLoading }: SearchErrorProps) => {
  const theme = useDominosTheme()

  if (isLoading) {
    return (
      <div data-testid={testID} className={css.wrapper}>
        <Spinner testID={testID} />
      </div>
    )
  }

  return (
    <div data-testid={testID} className={css.wrapper}>
      {!!icon && (
        <div data-testid={`${testID}.icon`} data-icon={icon} className={css.iconWrapper}>
          <StaticSvgIcon name={icon} isUnstyled />
        </div>
      )}

      <div className={css.textWrapper}>
        {!!title && (
          <h3 data-testid={`${testID}.title`} className={css.title} style={{ color: theme.colours.black }}>
            {title}
          </h3>
        )}
        <p data-testid={`${testID}.subtitle`} className={css.subtitle} style={{ color: theme.colours.black }}>
          {subtitle}
        </p>
      </div>

      <div className={css.buttons}>
        {buttons.map(({ type, secondary, onPress }) => (
          <AddressSearchButton
            key={type}
            testID={`${testID}.button`}
            type={type}
            secondary={secondary}
            isLoading={isLoading}
            onPress={onPress}
          />
        ))}
      </div>
    </div>
  )
}
