export const createStoresAlternativeStoresInput = (
  storeNo: number,
  storeGeo: Bff.Stores.StoreGeo,
  address: CustomerAddressRequest,
  deliveryAddressGeo: Bff.Stores.StoreGeo,
): MutationWrapper<Bff.Stores.StoreAlternativeStoresRequest> => ({
  input: {
    store: {
      storeNo: storeNo,
      geolocation: storeGeo,
    },
    deliveryAddress: {
      geolocation: deliveryAddressGeo,
      addressAttributes: [
        {
          name: 'suburb',
          value: address.suburb!,
        },
        {
          name: 'streetName',
          value: address.streetName!,
        },
        {
          name: 'streetNumber',
          value: address.streetNo!,
        },
        {
          name: 'state',
          value: address.state!,
        },
        {
          name: 'unitNumber',
          value: address.unitNo!,
        },
      ],
    },
  },
})
