import { calculateCustomisationDifference } from '@dominos/business/functions/basket'

export interface CustomisationChanges {
  base?: BasketLineSwap
  sauce?: BasketLineSwap
  quantity?: number
  sizeCode?: string
  options?: BasketLineSwap[]
}

export const getCustomisationChanges = (
  changes?: MenuCustomisationItem[],
  size?: ProductSize,
): CustomisationChanges | undefined => {
  if (!changes || !size) {
    return undefined
  }

  let basketLineSwap = {}

  changes.forEach((change) => {
    switch (change.type) {
      case 'base':
        const resolvedBase = resolvedCustomisationIngredient(change.ingredient)
        if (resolvedBase && size.recipe) {
          basketLineSwap = {
            ...basketLineSwap,
            base: calculateCustomisationDifference(resolvedBase, size.recipe.base),
          }
        }
        break
      case 'sauce':
        const resolvedSauce = resolvedCustomisationIngredient(change.ingredient)
        if (resolvedSauce && size.recipe) {
          basketLineSwap = {
            ...basketLineSwap,
            sauce: calculateCustomisationDifference(resolvedSauce, size.recipe.sauce),
          }
        }
        break
      case 'quantity':
        basketLineSwap = { ...basketLineSwap, quantity: parseInt(change.value) }
        break
      case 'size':
        basketLineSwap = { ...basketLineSwap, sizeCode: size.code }
        break
      case 'option':
        const resolvedOption: TouchableGridItem = {
          ...resolvedCustomisationIngredient(change.ingredient)!,
          index: change.index,
        }

        if (resolvedOption && size.recipe) {
          basketLineSwap = {
            ...basketLineSwap,
            options: calculateCustomisationDifference(resolvedOption, size.recipe.options, basketLineSwap),
          }
        }
        break
    }
  })

  return basketLineSwap
}

export const resolvedCustomisationIngredient = (ingredient?: MenuCustomisationItem['ingredient']) => {
  if (!ingredient) {
    return null
  }

  return {
    code: ingredient.code,
    name: (ingredient.media && ingredient.media.name) || '',
  }
}
