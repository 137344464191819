import { BasketLineData, getBasketLineData } from '@dominos/business/functions/basket'
import { isPortionMenuItem } from '@dominos/components'
import { useMenu } from '@dominos/hooks-and-hocs'
import { getPortionCount } from '@dominos/business/functions'
import { useState } from 'react'

type ProductMenuItem = Bff.Menu.old.ProductMenuItem
type PortionMenuItem = Bff.Menu.old.PortionMenuItem

const useHalfNHalf = (
  item?: ProductMenuItem,
  lineData?: BasketLine,
  isEditing: boolean = false,
): {
  halfLeft: BasketLineData
  halfRight: BasketLineData
  setHalf: (side: HalfSides) => (data: (prev: BasketLineData) => BasketLineData) => void
  halfAndHalfItem: PortionMenuItem | null
  setHalfProduct: (side: HalfSides) => (product?: ProductMenuItem) => void
} => {
  const { itemsByCode } = useMenu()

  const [halfLeft, setHalfLeft] = useState<BasketLineData>(getBasketLineData(itemsByCode, lineData, 'left', item))
  const [halfRight, setHalfRight] = useState<BasketLineData>(() => {
    if (isEditing) {
      return getBasketLineData(itemsByCode, lineData, 'right', item)
    }

    return { item: undefined }
  })

  const getHalfAndHalfItem = (): PortionMenuItem | null => {
    const allProducts = itemsByCode && Object.entries(itemsByCode)
    const portionItem =
      allProducts && allProducts.find(([_, value]) => isPortionMenuItem(value) && getPortionCount(value) == 2)

    return portionItem ? (portionItem[1] as PortionMenuItem) : null
  }

  const setHalf = (side: HalfSides) => (side === 'left' ? setHalfLeft : setHalfRight)
  const setHalfProduct = (side: HalfSides) => (product?: ProductMenuItem) =>
    side === 'left' ? setHalfLeft({ item: product }) : setHalfRight({ item: product })

  return {
    halfLeft,
    halfRight,
    setHalf,
    setHalfProduct,
    halfAndHalfItem: getHalfAndHalfItem(),
  }
}
export { useHalfNHalf }
