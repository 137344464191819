import { Switch } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './save-payment-switch.less'

export const SavePaymentSwitch = ({ testID, onChange }: { testID: string; onChange: (value: boolean) => void }) => {
  const { t } = useTranslation('checkout')

  const onToggle = (_: string, checked: boolean) => onChange(checked)

  return (
    <div className={css.switchContainer}>
      <Switch testID={`${testID}.SavePaymentSwitch`} name='savePayment' onChange={onToggle}>
        <span className={css.switchLabel}>{t('SavePayment', { defaultValue: 'Save payment method' })}</span>
      </Switch>
    </div>
  )
}
