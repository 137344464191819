import React from 'react'
import { ActionButton, Modal } from '@dominos/components'
import PopupWarningIcon from './popup-warning-icon'
import css from './login-popup-warning.less'
import { useTranslation } from 'react-i18next'

interface IProps {
  onClose: () => void
}

export const LoginPopupWarningModal = ({ onClose }: IProps) => {
  const { t } = useTranslation('login')

  return (
    <Modal testID='login-popup-warning' onModalClose={onClose}>
      <div className={css.cardContainer}>
        <div className={css.contentContainer}>
          <div className={css.popupWarningIcon}>
            <PopupWarningIcon />
          </div>
          <h4 className={css.contentBody}>
            {t('LoginPopupWarningMessage', {
              defaultValue:
                'Sign in method uses a popup window to enter your credentials. Please disable content blockers to continue sign in.',
            })}
          </h4>
          <ActionButton
            testID={'login-popup-warning.confirm'}
            text={t('Got it', { defaultValue: 'Got it' })}
            containerStyle={{ width: '100%', backgroundColor: '#007aff' }}
            textFontWeight={'semibold'}
            onPress={onClose}
          />
        </div>
      </div>
    </Modal>
  )
}
