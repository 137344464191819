import { useAuthenticationPopup, useCustomer, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, useLocation } from '@reach/router'
import React from 'react'
import { AuthenticationButtonsContainer } from '../authentication-buttons-container'
import { CustomerAccountButton } from './customer-account-button'
import { LoginButton } from './login-button'

const CustomerAccountContainer = () => {
  const { customer } = useCustomer()
  const { pathname } = useLocation()
  const { reportInteraction } = useReport()
  const { showPopup } = useAuthenticationPopup()

  const [universalAuthenticationEnabled, signInSignUpSeparateButtonsEnabled] = useFeatures(
    'UniversalAuthentication',
    'SignInSignUpSeparateButtons',
  )

  const onLoginClick = () => {
    reportInteraction('Login')
    universalAuthenticationEnabled
      ? showPopup('login')
      : navigate(NavigationConstants.login, { state: { redirectTo: pathname } }) // origin of redirectTo
  }

  const AuthButtonContainer = signInSignUpSeparateButtonsEnabled ? (
    <AuthenticationButtonsContainer testID='authentication-buttons' />
  ) : (
    <LoginButton onClick={onLoginClick} />
  )

  return customer?.id ? <CustomerAccountButton customer={customer} /> : AuthButtonContainer
}

export { CustomerAccountContainer }
