import { getProductItemSignature } from './get-product-item-signature'

export const removeItemFromBasket = (
  basketLines: BasketLineUnionDependancy[],
  itemSignatureToRemove: string,
): BasketLineUnionDependancy[] => {
  if (!basketLines) {
    return []
  }

  // so because we are looping here, removal can repeatedly match items but with coupons
  // we only want to remove the first matching item
  let removedFromCoupon = false

  return basketLines.reduce<BasketLineUnionDependancy[]>((memo, line) => {
    if (line.type === 'BasketLine' && itemSignatureToRemove !== getProductItemSignature(line)) {
      return [...memo, line]
    }

    if (line.type === 'BasketCoupon') {
      const items = line.items.map((item) => {
        // find our first matching item to be removed
        // vouchers do not remove all items, only the first matching one as they are not grouped
        const matchedProduct = item.lines.findIndex((l) => itemSignatureToRemove === getProductItemSignature(l))
        // now just filter that item out
        if (matchedProduct >= 0 && !removedFromCoupon) {
          removedFromCoupon = true
          item.lines.splice(matchedProduct, 1)
        }

        return { ...item, ...item.lines }
      })

      return [...memo, { ...line, items }]
    }

    return memo
  }, [])
}

export const removeItemsFromBasket = (basketLines: BasketLineUnionDependancy[], items: BasketLine[]) =>
  items.reduce((memo, pendingLine) => removeItemFromBasket(memo, getProductItemSignature(pendingLine)), basketLines)
