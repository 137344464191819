import { ErrorDefinitionsType } from '../interfaces'
import { getDefaultValue } from '../helpers'

export const networkErrors: ErrorDefinitionsType = {
  NETWORK_ERROR: {
    translation: {
      title: {
        key: 'NetworkErrorTitle',
        getOptions: () => getDefaultValue('Network Error'),
      },
      message: {
        key: 'NetworkErrorMessage',
        getOptions: () => getDefaultValue('A network error has occurred. We are unable to complete this action'),
      },
    },
    displayType: 'closable-popup',
    icon: 'infoIcon',
    testID: 'network-error',
  },
}
