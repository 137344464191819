import { isProductMenuItemNew } from '@dominos/components'

type Menu = Bff.Menu.old.Menu
type MenuSection = Bff.Menu.old.MenuSection

/**
 * Gets Portion products grouped by menu section
 * @param menu Menu The entire service menu
 * @param codes string[] The array of applicable product codes for the portion
 * @author Shaun Weddell
 * @returns { section: MenuSection; data: MenuItemDependents[]; }[]
 */
export const getPortionSectionList = (menu: Menu, codes: string[]) => {
  const sections = [] as MenuSection[]
  for (const page of menu.pages) {
    for (const section of page.sections || []) {
      sections.push({ ...section }) // need to make sure this is a new object and not impact stored menu
    }
  }

  for (const sect of sections) {
    sect.items = sect.items.filter(
      (item: MenuItemDependents) => !isProductMenuItemNew(item) && codes.includes(item.code),
    )
  }

  const filtered = sections.filter((sect: MenuSection) => sect.items.length > 0)

  return filtered.map((sect: MenuSection) => ({
    section: sect,
    data: sect.items,
  }))
}
