export const oneTrustStyleOverride = () => {
  const preferenceButton = document.getElementById('ot-sdk-btn-floating')
  const buttonStyle = { transform: preferenceButton?.style.transform }

  const moveOTPreferenceBtnToRight = () => {
    if (preferenceButton) {
      preferenceButton.style.transform = 'translateX(calc(100vw - 60px))'
    }
  }

  const removeOverrideOTPreferenceBtnStyle = () => {
    if (preferenceButton) {
      preferenceButton.style.transform = buttonStyle?.transform || ''
    }
  }

  return { moveOTPreferenceBtnToRight, removeOverrideOTPreferenceBtnStyle }
}
