import { useValidation } from '@dominos/business/functions/validation'
import { ValidationTextField } from '@dominos/components/inputs'
import RadioInputField from '@dominos/components/inputs/radio-input-field'
import { ServiceMethodSubTypeProps } from '@dominos/interfaces/checkout'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as CONFIG from './pick-up-type-config'

export const CarParkDelivery = ({
  name,
  value,
  onChecked,
  onChange,
  labelText,
  testID,
  selectedSubType,
  onFormValidationChange,
  initialValue,
}: ServiceMethodSubTypeProps) => {
  const { isRequired, maxLength } = useValidation()

  const defaultValidationState = {
    carMakeAndModel: !!initialValue?.carMakeAndModel,
    carColour: !!initialValue?.carColour,
  }

  const { t } = useTranslation('checkout')
  const [validationState, setValidationState] = React.useState(defaultValidationState)

  const getValidationState = (values: object) => Object.values(values).reduce((l, r) => l && r, true)

  const handleChecked = (targetName: string, targetValue: string | number) => {
    onChecked(targetValue as Bff.Stores.ServiceMethodSubTypes)
    if (onFormValidationChange) {
      onFormValidationChange(getValidationState(validationState))
    }
  }

  const handleChange = (key: string) => (updatedField: { [K: string]: { value?: string; isValid: boolean } }) => {
    if (!!onChange) {
      onChange(key, updatedField[key].value?.trim())
    }
    setValidationState((prevState) => ({ ...prevState, [key]: updatedField[key].isValid }))
  }

  React.useEffect(() => {
    if (value === selectedSubType && onFormValidationChange) {
      onFormValidationChange(getValidationState(validationState))
    }
  }, [validationState.carMakeAndModel, validationState.carColour])

  return (
    <React.Fragment>
      <RadioInputField
        fieldName={name}
        value={value}
        fieldId={testID}
        onChecked={handleChecked}
        labelText={labelText}
        testID={`${testID}.radio-input-field`}
        selectedOption={selectedSubType}
      />
      {value === selectedSubType && (
        <div>
          <ValidationTextField
            fieldName='carMakeAndModel'
            placeholder={t('CarMakeAndModel')}
            testID='car-make-and-model'
            validationRules={[isRequired, maxLength(CONFIG.MAX_LENGTH_CAR_MAKE_AND_MODEL)]}
            defaultValue={initialValue?.carMakeAndModel}
            style={{ width: '100%' }}
            onChange={handleChange('carMakeAndModel')}
          />
          <ValidationTextField
            fieldName='carColour'
            placeholder={t('CarColour')}
            testID='car-colour'
            validationRules={[isRequired, maxLength(CONFIG.MAX_LENGTH_CAR_COLOUR)]}
            defaultValue={initialValue?.carColour}
            style={{ width: '100%' }}
            onChange={handleChange('carColour')}
          />
        </div>
      )}
    </React.Fragment>
  )
}
