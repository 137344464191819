import { nativeAppActions, WKWebViewMessage } from './native-types'

export const notifyNativeApp = (message: nativeAppActions, reportInfo?: (info: string) => void): void => {
  // if it's running in normal browser, we won't have ReactNativeWebView attached as global object
  if (window.ReactNativeWebView) {
    // TODO: Remove after found root of issue for NZ drop off
    reportInfo && reportInfo(`window.ReactNativeWebView: ${message}`)
    window.ReactNativeWebView.postMessage(message)
  }
  // when ApplePay enabled, need different way to post message
  // https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#enableApplePay
  else if (window.webkit?.messageHandlers?.ReactNativeWebView) {
    // TODO: Remove after found root of issue for NZ drop off
    reportInfo && reportInfo(`window.webkit?.messageHandlers?.ReactNativeWebView: ${message}`)
    window.webkit.messageHandlers.ReactNativeWebView.postMessage(message)
  }
}

export const postWebViewMessage = <M extends WKWebViewMessage>(message: M) => {
  const messageToSent = JSON.stringify(message)
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(messageToSent)
  } else if (window.webkit?.messageHandlers?.ReactNativeWebView) {
    window.webkit.messageHandlers.ReactNativeWebView.postMessage(messageToSent)
  }
}
