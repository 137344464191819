import { mapAddressToCustomerAddress } from '@dominos/business/functions'
import { TFunction } from 'i18next'
import { AddressSearchResults } from './interfaces'
import { IAddress } from 'olo-feature-address'

export const mapRecentlySearchedAddressToAddressLine = (
  customerAddresses: StoredCustomerAddress[],
  t: (p: string) => string,
): AddressLine[] => {
  if (!customerAddresses || !customerAddresses[0]) {
    return []
  }

  return customerAddresses.map(
    (address, index): AddressLine => ({
      icon: 'recentIcon',
      suffix: 'caret',
      name: address.nickname || index === 0 ? t('Last Address') : t('Recent Address'),
      uid: address.storeNo!,
      address: address.displayAddress,
      additionalData: address,
    }),
  )
}

export const mapStoredCustomerAddressToAddressLine = (
  customerAddresses: StoredCustomerAddress[] | undefined,
  t: TFunction,
): AddressLine[] => {
  if (!customerAddresses || !customerAddresses[0]) {
    return []
  }

  return customerAddresses.map(
    (address, index): AddressLine => ({
      icon: 'savedIcon',
      suffix: 'caret',
      name: t('SavedAddress', { defaultValue: 'Saved Address' }),
      uid: index,
      address: address.displayAddress,
      additionalData: address,
    }),
  )
}

const getStoreNoAndRawAddressFromResults = (searchResult: { storeNo: number; rawAddress: Bff.Addresses.Address }[]) => {
  if (!searchResult || searchResult.length == 0) {
    return null
  }
  if (searchResult.length == 1) {
    return searchResult[0]
  }
  if (searchResult.every((storeNo) => storeNo.storeNo == searchResult[0].storeNo)) {
    return searchResult[0]
  } else {
    return null
  }
}

const mapSearchAddressAndStoreToStoredCustomerAddress = (
  customerAddress: Bff.Customers.CustomerAddress | StoredCustomerAddress,
  searchResult: { storeNo: number; rawAddress: Bff.Addresses.Address }[],
): StoredCustomerAddress | null => {
  if (!customerAddress) {
    return null
  }

  const storeNoAndRawAddress = getStoreNoAndRawAddressFromResults(searchResult)
  const customerAddressRequest = mapAddressToCustomerAddress(storeNoAndRawAddress?.rawAddress)

  return {
    customerAddress: customerAddressRequest,
    displayAddress: customerAddress.displayAddress,
    storeNo: storeNoAndRawAddress?.storeNo,
    rawAddress: storeNoAndRawAddress?.rawAddress,
    lastUsed: Date.now(),
  } as StoredCustomerAddress
}

export const populateAddressFieldFromInputAddress = (
  outputAddress: StoredCustomerAddress,
  field: keyof Bff.Addresses.Address,
  value: string,
) =>
  ({
    ...outputAddress,
    customerAddress: {
      ...outputAddress?.customerAddress,
      [field]: value,
    },
    rawAddress: {
      ...outputAddress?.rawAddress,
      [field]: value,
    },
  } as StoredCustomerAddress)

export const mapBatchedAddresses = (
  customerAddresses: (StoredCustomerAddress | Bff.Customers.CustomerAddress)[],
  addressSearchQueryResults: AddressSearchResults,
  hasAdditionalAddressFields: boolean,
) =>
  customerAddresses
    .map((inputAddress, index) => ({
      inputAddress,
      mappedResult: mapSearchAddressAndStoreToStoredCustomerAddress(
        inputAddress,
        addressSearchQueryResults[`query${index}`],
      ) as StoredCustomerAddress,
    }))
    .map((result) => {
      let mappedResult = result.mappedResult
      const inputAddress = result.inputAddress
      const customerAddress = (inputAddress as Bff.Customers.CustomerAddress)?.address
      const storedCustomerAddress = (inputAddress as StoredCustomerAddress)?.customerAddress

      const buildingNameValue = hasAdditionalAddressFields
        ? customerAddress?.buildingName ?? storedCustomerAddress?.buildingName ?? undefined
        : undefined

      const floorNoValue = hasAdditionalAddressFields
        ? customerAddress?.floorNo ?? storedCustomerAddress?.floorNo ?? undefined
        : undefined

      const unitNoValue = hasAdditionalAddressFields
        ? customerAddress?.unitNo ?? storedCustomerAddress?.unitNo ?? undefined
        : undefined

      const streetNoValue = hasAdditionalAddressFields
        ? customerAddress?.streetNo ?? storedCustomerAddress?.streetNo ?? undefined
        : undefined

      if (buildingNameValue)
        mappedResult = populateAddressFieldFromInputAddress(mappedResult, 'buildingName', buildingNameValue)

      if (floorNoValue) mappedResult = populateAddressFieldFromInputAddress(mappedResult, 'floorNo', floorNoValue)

      if (unitNoValue) mappedResult = populateAddressFieldFromInputAddress(mappedResult, 'unitNo', unitNoValue)

      if (streetNoValue) mappedResult = populateAddressFieldFromInputAddress(mappedResult, 'streetNo', streetNoValue)

      if (!!inputAddress?.locale) {
        mappedResult.locale = inputAddress.locale
      }

      if (inputAddress.hasOwnProperty('geo') && (inputAddress as StoredCustomerAddress)?.geo !== undefined) {
        mappedResult.geo = (inputAddress as StoredCustomerAddress).geo
      }
      if (
        inputAddress.hasOwnProperty('address') &&
        !!(inputAddress as Bff.Customers.CustomerAddress)?.address?.location
      ) {
        const { latitude, longitude } = (inputAddress as Bff.Customers.CustomerAddress).address.location!
        if (latitude && longitude) {
          mappedResult.geo = { latitude, longitude }
        }
      }

      return { inputAddress, mappedResult }
    })
    .map(({ mappedResult }) => mappedResult)
    .filter((mappedResult) => mappedResult?.storeNo)

export const convertDeliveryAddressRequestToIAddress = (
  address: DeliveryAddressRequest,
  countryCode: BffContext.Countries,
): IAddress => ({
  geoLocation: address.geo,
  countryCode: countryCode,
  locale: address.locale as BffContext.Languages,
  attributes: address.addressComponents?.map((component) => ({
    name: component.type,
    value: component.value,
  })),
  displayAddress: { title: address.displayAddress },
})

export const convertStoredCustomerAddressToIAddress = (
  address: StoredCustomerAddress,
  countryCode: BffContext.Countries,
): IAddress => ({
  geoLocation: address.geo ? { longitude: address.geo.longitude, latitude: address.geo.latitude } : undefined,
  countryCode: countryCode,
  locale: address.locale as BffContext.Languages,
  attributes:
    address.rawAddress ?? address.customerAddress
      ? Object.entries(address.rawAddress ?? address.customerAddress).map(([key, value]) => ({ name: key, value }))
      : [],
  displayAddress: { title: address.displayAddress },
})

export const convertAddressLineToIAddress = (address: AddressLine, countryCode: BffContext.Countries): IAddress => ({
  geoLocation:
    address.geo?.latitude && address.geo.longitude
      ? { longitude: address.geo.longitude, latitude: address.geo.latitude }
      : undefined,
  countryCode: countryCode,
  locale: address.locale as BffContext.Languages,
  attributes: [],
  displayAddress: { title: address.address ?? '' },
})
