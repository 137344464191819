import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './terms-and-conditions.less'

export const TermsAndConditions = ({
  disclaimers,
  containerStyle,
}: {
  disclaimers: Bff.Offers.Disclaimer[]
  containerStyle?: React.CSSProperties
}) => {
  const [showTerms, setShowTerms] = useState(false)
  const { t } = useTranslation('menu')

  const onTermsClick = () => {
    setShowTerms(!showTerms)
  }

  if (disclaimers && disclaimers.length > 0) {
    return (
      <div style={containerStyle}>
        <div role='button' onClick={onTermsClick} className={css.terms}>
          {t('OfferTermsAndConditionsLabel', { defaultValue: 'Terms & Conditions' })}
        </div>
        {showTerms && (
          <div className={css.termsAndConditions}>
            {(disclaimers ?? []).map((disclaimer) => (
              <div data-testid={`termsAndConditions.${disclaimer.code}`} key={disclaimer.code}>
                {disclaimer.text}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }

  return null
}
