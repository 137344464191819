import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 52,
  height: 52,
}

const Face3Active = (props: SvgIconProps) => Face3({ ...props, fill: '#FFCE00' })

const Face3Inactive = (props: SvgIconProps) => Face3({ ...props, fill: '#9B9B9B' })

const Face3 = (props: SvgIconProps) => {
  const fill = props.fill || '#9B9B9B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 52 52'>
      <g id='Face-3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle fill={fill} cx='26.1718318' cy='26.1718318' r='23.3413089' />
        <path
          fill='#000000'
          d='M26.1718318,0 C11.7407009,0 0,11.7407009 0,26.1718318 C0,40.6029626 11.7407009,52.3436635 26.1718318,52.3436635 C40.6029626,52.3436635 52.3436635,40.6029626 52.3436635,26.1718318 C52.3436635,11.7407009 40.6031394,0 26.1718318,0 L26.1718318,0 Z M26.1718318,48.1718318 C20.1289069,48.1718318 14.6478238,45.7224613 10.6677635,41.765068 C9.07840609,40.1847107 7.72921899,38.3633498 6.67870307,36.3616528 C5.07884549,33.31344 4.17183175,29.8468874 4.17183175,26.1718318 C4.17183175,14.0409813 14.0409813,4.17183175 26.1718318,4.17183175 C31.9257523,4.17183175 37.1698317,6.39319874 41.0942245,10.0222537 C43.128922,11.9036156 44.8091141,14.1629831 46.0206325,16.6885214 C47.3989867,19.5615649 48.1718318,22.7781137 48.1718318,26.1718318 C48.1718318,38.3026822 38.3026822,48.1718318 26.1718318,48.1718318 L26.1718318,48.1718318 Z'
        />
        <path
          fill='#000000'
          d='M11.4412443,30.7115936 L40.9115519,30.8295736 L40.9002245,33.659057 L11.4299168,33.541077 L11.4412443,30.7115936 Z M17.4182968,16 C18.9809453,16 20.2477222,17.5670034 20.2477222,19.5 C20.2477222,21.4329966 18.9809453,23 17.4182968,23 C15.8556482,23 14.5888713,21.4329966 14.5888713,19.5 C14.5888713,17.5670034 15.8556482,16 17.4182968,16 Z M34.9253667,16 C36.4880153,16 37.7547922,17.5670034 37.7547922,19.5 C37.7547922,21.4329966 36.4880153,23 34.9253667,23 C33.3627182,23 32.0959413,21.4329966 32.0959413,19.5 C32.0959413,17.5670034 33.3627182,16 34.9253667,16 Z'
        />
      </g>
    </svg>
  )
}

export { Face3Active, Face3Inactive, Face3 }
