import { AddressList, CollapsableTitledCard } from '@dominos/components'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

export const DeliveryAddressListContainer = (props: DeliveryAddressListContainerProps) => {
  const { t } = useTranslation('delivery-address')

  return (
    <div style={{ width: 360 }}>
      <CollapsableTitledCard
        primaryTitle={t('Delivery Address')}
        static
        startCollapsed={false}
        testID={'delivery-address-list-card'}
        noPadding={true}
      >
        <div style={{ padding: '0px 8px' }}>
          <AddressList
            testID={'delivery-address'}
            addresses={props.addresses}
            onPress={props.onPress}
            showBorderOnFirstItem
            swapNameAndAddress
          />
        </div>
      </CollapsableTitledCard>
    </div>
  )
}

interface DeliveryAddressListContainerProps {
  addresses: AddressLine[]
  onPress: (addressLine: AddressLine) => void
}
