import { mapAddressComponentsToDisplayAddress } from '@dominos/business/functions/address/autocomplete/map-address-line-to-stored-customer-address'

export const convertAdditionalFieldsToArray = (correctedFields: AdditionalFields): AddressComponent[] =>
  Object.keys(correctedFields).map((key: string) => ({
    type: key,
    value: correctedFields[key as keyof AdditionalFields]?.value ?? '',
  }))

export const mapAdditionalFieldsToDisplayAddress = (
  correctedFieldsAsArray: AddressComponent[],
  prevAddress: DeliveryAddressRequest,
  countryCode: BffContext.Countries,
): string | undefined =>
  mapAddressComponentsToDisplayAddress([...correctedFieldsAsArray, ...prevAddress.addressComponents], countryCode)

export const validateAddressComponents = (
  prevComponents: AddressComponent[],
  newComponents: AddressComponent[],
  countryCode?: BffContext.Countries,
): string[] => {
  const validationResult: string[] = []

  newComponents.forEach((item) => {
    const component = prevComponents.find((x) => x.type === item.type)

    if (!component) return

    const rule = getComparisonRule(component, countryCode)
    const isEqual = compare(component.value, item.value, rule)
    if (!isEqual) validationResult.push(item.type)
  })

  return validationResult
}

const getComparisonRule = (component: AddressComponent, countryCode?: BffContext.Countries): string =>
  component.type === 'postCode' && countryCode === 'NL' ? 'digitsOnly' : 'default'

const compare = (field1: string, field2: string, rule: string): boolean => {
  if (rule === 'digitsOnly') {
    return compareDigitsOnly(field1, field2)
  }

  return field1 === field2
}

export const compareDigitsOnly = (str1: string, str2: string): boolean => {
  const digitsOnlyRegex = /\d+/g
  const digits1 = str1.match(digitsOnlyRegex)?.join('') ?? ''
  const digits2 = str2.match(digitsOnlyRegex)?.join('') ?? ''

  return digits1 === digits2
}
