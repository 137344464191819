import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 26,
  height: 26,
}

const DominosLogoIcon = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 25 25'>
      <g fill='none' fillRule='evenodd'>
        <path
          fill='#FEFEFE'
          d='M24.228 7.032L17.53.445a1.5 1.5 0 0 0-1.058-.43c-.4 0-.776.152-1.058.43L9.794 5.97l-9.35 9.195a1.456 1.456 0 0 0 0 2.081l6.699 6.588a1.5 1.5 0 0 0 1.058.431c.4 0 .776-.153 1.059-.43l8.382-8.243 6.586-6.477a1.457 1.457 0 0 0 0-2.082'
        />
        <path
          fill={props.fill || '#E21836'}
          d='M17.863 9.405c-.379.372-.877.558-1.372.558-.496 0-.992-.186-1.372-.558a1.888 1.888 0 0 1 0-2.698c.38-.372.876-.56 1.372-.56.495 0 .993.186 1.372.56a1.888 1.888 0 0 1 0 2.698m5.891-1.904L17.055.914a.837.837 0 0 0-1.17 0L8.644 8.036l7.869 7.736 7.242-7.121a.803.803 0 0 0 0-1.15'
        />
        <path
          fill={props.fill || '#007AAF'}
          d='M9.67 14.905c.378-.373.875-.56 1.37-.56.497 0 .995.187 1.372.56a1.878 1.878 0 0 1 0 2.697 1.958 1.958 0 0 1-1.371.559c-.496 0-.993-.188-1.372-.56a1.884 1.884 0 0 1 0-2.696zm-2.951 2.621c-.38.37-.877.557-1.373.557-.497 0-.994-.186-1.372-.557a1.892 1.892 0 0 1 0-2.7 1.954 1.954 0 0 1 1.372-.556c.496 0 .994.185 1.373.558a1.886 1.886 0 0 1 0 2.698zM.918 15.63a.804.804 0 0 0 0 1.15l6.699 6.588a.832.832 0 0 0 .585.238c.211 0 .423-.079.584-.238l7.24-7.12-7.869-7.736-7.24 7.118zM12.275 23.227h.107c.07 0 .121-.01.151-.033a.109.109 0 0 0 .044-.089.111.111 0 0 0-.042-.088c-.03-.023-.076-.033-.143-.033h-.117v.243zm-.246-.413h.443c.125 0 .216.025.272.075a.25.25 0 0 1 .084.2c0 .065-.02.122-.064.169a.374.374 0 0 1-.202.104c.038.015.065.03.084.046.025.022.05.054.079.098.001.003.054.098.16.282h-.292a2.084 2.084 0 0 0-.19-.332c-.033-.036-.065-.054-.098-.054-.007 0-.017 0-.03.003v.383h-.246v-.974zm1.168.487a.706.706 0 0 0-.22-.52.73.73 0 0 0-.528-.216.727.727 0 0 0-.529.216.7.7 0 0 0-.219.52c0 .203.072.376.22.52a.723.723 0 0 0 .528.216.725.725 0 0 0 .529-.216.703.703 0 0 0 .219-.52zm.18 0a.89.89 0 0 1-.125.457.92.92 0 0 1-.803.454.924.924 0 0 1-.927-.911c0-.162.042-.315.125-.455a.924.924 0 0 1 .802-.456.92.92 0 0 1 .927.91z'
        />
      </g>
    </svg>
  )
}

export { DominosLogoIcon }
