import { FORGEROCK_CONSTANT, ForgerockJourney } from '@dominos/business/constants'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'

export const buildForgerockUrl = (urlParams: IForgerockUrlParams, journey: ForgerockJourney): string => {
  const forgeRockAmURL = getCountryConfig().FORGEROCK_AM_URL
  const paramsMap = {
    realm: FORGEROCK_CONSTANT.REALM_PATH,
    authIndexType: FORGEROCK_CONSTANT.INDEX_TYPE,
    authIndexValue: journey,
    market: urlParams.countryCode,
    locale: `${urlParams.language}-${urlParams.countryCode}`,
    application: urlParams.application,
    orderId: urlParams.orderId,
    ForceAuth: 'true',
  }

  const url = new URL(`${forgeRockAmURL}XUI/`)
  Object.entries(paramsMap).forEach(([key, value]) => {
    url.searchParams.append(key, value)
  })

  return url.toString()
}
