import { notifyNativeApp } from '@dominos/business/functions/native-app'
import { ErrorDefinitions } from '@dominos/interfaces'

const nativeAppTrackerErrors: ErrorDefinitions = {
  UNABLE_TO_RESOLVE: {
    translation: {
      key: 'MalformedOrderId',
      options: {
        defaultValue: 'The order ID provided is incomplete or incorrect',
      },
    },
    display: 'alert',
    callback: () => {
      notifyNativeApp('error')
    },
  },
  BAD_USER_INPUT: {
    translation: {
      key: 'OrderNotFound',
      options: {
        defaultValue: 'There is no order associated with this Order ID',
      },
    },
    display: 'alert',
    callback: () => {
      notifyNativeApp('error')
    },
  },
  INTERNAL_SERVER_ERROR: {
    translation: {
      key: 'Order Recoverable Error Message',
      options: {
        defaultValue:
          'Something went wrong when we tried to fetch your order. Please try again in a moment. If the problem persists, please contact us.',
      },
    },
    display: 'alert',
    callback: () => notifyNativeApp('error'),
  },
}

export default nativeAppTrackerErrors
