import { createSendFosEvents } from 'olo-feature-fos'
import { createFosStoreMapper } from './mappers/create-fos-store-mapper'
import { createFosCustomerMapper } from './mappers/create-fos-customer-mapper'
import { createFosCurrentOrderMapper } from './mappers/create-fos-current-order-mapper'
import { createFosLocaleMapper } from './mappers/create-fos-locale-mapper'
import { createSendGraphQLMutation } from './create-send-graphql-mutation'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { createFosSessionMapper } from './mappers/create-fos-session-mapper'
import { FeatureFlagType } from '@dominos/hooks-and-hocs/features'

export const createFosEventsHandler = (
  rootReducer: RootReducer,
  securitySender: SecuritySender,
  features: FeatureFlagType,
  isEnabledFallback?: boolean,
) =>
  createSendFosEvents({
    isEnabled: () => shouldSendFosEvents(rootReducer, features, isEnabledFallback),
    mappers: {
      getStore: createFosStoreMapper(rootReducer),
      getCustomer: createFosCustomerMapper(rootReducer),
      getCurrentOrder: createFosCurrentOrderMapper(rootReducer),
      getLocale: createFosLocaleMapper(rootReducer),
      getSession: createFosSessionMapper(rootReducer),
    },
    transport: createSendGraphQLMutation(rootReducer, securitySender, features),
  })

export const shouldSendFosEvents = (rootReducer: RootReducer, features: FeatureFlagType, isEnabledFallback?: boolean) =>
  !!(
    features['IsFosVersionThreeEnabled'] ||
    (isEnabledFallback && !rootReducer.currentOrderDetailsReducer.advanceOrder.endedAt)
  )
