import { BasketLineData } from '@dominos/business/functions/basket'
import { getIsNewYorker } from '@dominos/business/functions/menu/get-is-new-yorker'

type Menu = Bff.Menu.old.Menu
type ProductMenuItem = Bff.Menu.old.ProductMenuItem
type PortionMenuItem = Bff.Menu.old.PortionMenuItem

export const getVisibilityLogic = (
  menu: Menu,
  productOrPortion: ProductMenuItem | PortionMenuItem,
  item?: { type: string; code: string },
  restrictedHalfHalfEnabled: boolean | undefined = false,
  halfLeft?: BasketLineData,
  halfRight?: BasketLineData,
): {
  CUSTOMIZE_SIZE: boolean
  CUSTOMIZE_SAUCE: boolean
  CUSTOMIZE_PORTION_SAUCE: boolean
  CUSTOMIZE_TOPPINGS: boolean
  OPTIONS: boolean
  PRODUCT_UPSELL: boolean
} => {
  const isProductType = productOrPortion.type === 'Product'

  return {
    CUSTOMIZE_SIZE: !!productOrPortion.sizes && productOrPortion.sizes[0].media.name !== null,
    CUSTOMIZE_SAUCE: isProductType,
    CUSTOMIZE_PORTION_SAUCE: !!restrictedHalfHalfEnabled && !!halfLeft && !!halfRight,
    CUSTOMIZE_TOPPINGS: menu && item && isProductType ? !getIsNewYorker(menu, item) : !!productOrPortion.defaultSize,
    OPTIONS: isProductType,
    PRODUCT_UPSELL: isProductType,
  }
}
