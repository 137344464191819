import classNames from 'classnames'
import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import css from './banner-container.less'
import { BannerOffer } from './banner-offer'

const SLIDE_INTERVAL = 4000

export const BannerContainer = ({ offers, testID }: { offers: Bff.Offers.Offer[]; testID: string }) => {
  if (offers.length === 0) {
    return null
  }

  const isMultiBanner = offers.length > 1

  return (
    <Carousel
      axis={'horizontal'}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      stopOnHover={false}
      dynamicHeight={false}
      interval={SLIDE_INTERVAL}
      showIndicators={isMultiBanner}
      infiniteLoop={isMultiBanner}
      className={classNames(css.slider, isMultiBanner && css.multiItem)}
      autoPlay
      swipeable
    >
      {offers.map((offer) => (
        <BannerOffer testID={`${testID}.offer`} offer={offer} key={offer.offerId} />
      ))}
    </Carousel>
  )
}
