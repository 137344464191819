import { CollapsableTitledCard, GenericCard, LinkButton, MDTargetBlankAnchor } from '@dominos/components'
import Markdown from 'markdown-to-jsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TermsAndConditionsGeneric } from '../'
import css from './terms-and-conditions-with-message.less'

export const TermsAndConditionsWithMessage = (props: TermsAndConditionsWithMessageProps) => {
  const [showHiddenText, setShowHiddenText] = useState(false)
  const onPress = () => setShowHiddenText(true)
  const { t } = useTranslation('checkout')

  return (
    <CollapsableTitledCard
      testID={props.testID}
      primaryTitle={t('Terms and Conditions')}
      noPadding={true}
      startCollapsed={false}
    >
      <div className={css.wrapper} data-testid={`${props.testID}.container`} style={props.textStyle}>
        <div>
          <TermsAndConditionsGeneric
            testID={`${props.testID}.container.upperText`}
            text={t('agree terms and conditions')}
            textStyle={{ fontSize: 13, lineHeight: 1.08 }}
            hyperlinkColor='rgba(0, 0, 0, 0.54)'
          />
        </div>
        <div className={css.lowerText}>
          <div>{t('By submitting your order')}</div>
          <div className={css.lowerParagraph}>
            <OptOut onPress={onPress} testID={props.testID} text={t('option to opt out')} />
          </div>
        </div>
        <HiddenMessage
          testID={props.testID}
          text={t("You will not receive Domino's offers")}
          visible={showHiddenText}
        />
      </div>
    </CollapsableTitledCard>
  )
}

export const OptOut = ({ onPress, testID, text }: TextProps) => (
  <Markdown
    style={{ fontSize: '12px', lineHeight: '1' }}
    children={text}
    data-testid={`${testID}.optout`}
    options={{
      overrides: {
        LinkButton: {
          component: LinkButton,
          props: {
            onPress,
            style: {
              textDecoration: 'underline',
              fontSize: '12px',
              color: 'rgba(0, 0, 0, 0.54)',
            },
            testID: `${testID}.optout.clickHere`,
          },
        },
        a: MDTargetBlankAnchor,
      },
    }}
  />
)

export const HiddenMessage = ({ testID, text, visible }: TextProps) =>
  visible ? (
    <div data-testid={`${testID}.optout.hiddenMessage`}>
      <GenericCard
        testID={testID}
        style={{
          backgroundColor: '#f0f0f0',
          padding: '7px 8px',
          marginTop: '8px',
          borderRadius: 4,
          marginBottom: '0.6px',
        }}
      >
        <div className={css.hiddenText}>{text}</div>
      </GenericCard>
    </div>
  ) : null
