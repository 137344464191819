import { LayoutItemWithName, LayoutMapping, Layouts } from './layout.interfaces'

export const getLayoutItems = (layoutMapping: LayoutMapping, hasMultiLayouts: boolean): LayoutItemWithName[] =>
  Object.entries(layoutMapping ?? []).map(([layoutName, item]) => ({
    layoutName: layoutName as Layouts,
    layoutItem: {
      layout: Object.entries(item.layout).reduce(
        (layoutItem, [layoutKey, layoutKeyItem]) => ({
          ...layoutItem,
          [layoutKey]: [
            ...layoutKeyItem.slice(0, layoutKeyItem.length - 1),
            `${layoutKeyItem.slice(-1)}${hasMultiLayouts ? ' {switchLanguage}' : ''}${' {close}'}`,
          ],
        }),
        {},
      ),
    },
  }))
