import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 26,
  height: 25,
}

const GMOSoftbankIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-28.000000, -368.000000)' fill={fill}>
          <g transform='translate(12.000000, 358.000000)'>
            <g transform='translate(16.240106, 10.000000)'>
              <path
                d='M.5 3.29v6.155h25V3.29H.5zm0 12.265v6.156h25v-6.156H.5z'
                transform='translate(-31 -453) translate(7.5 208) translate(8.5 232) translate(15 13)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { GMOSoftbankIcon }
