import { VoucherError } from '@dominos/interfaces'
import { TFunction } from 'i18next'

const getRewardErrorTranslationKey = (status: VoucherError['processingStatus']) => {
  switch (status) {
    case 'Expired':
      return 'RewardErrorExpired'
    case 'Not valid':
      return 'RewardErrorInvalid'
    default:
      return 'RewardErrorUnknown'
  }
}

export const mapRewardErrorTranslation = ({ processingStatus, message }: VoucherError, t: TFunction) =>
  t(getRewardErrorTranslationKey(processingStatus), {
    defaultValue: message,
  })
