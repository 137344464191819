/**
 * Within each market there may be the requirement to adjust the `types` of address returned from Google,
 * depending on what sorts of locations that country supports.
 *
 * - AU: `premise,street_address` (as users search via street or building)
 * - SG: `postal_code,geocode` (users search via the postcode number)
 *
 * The list of these types and the rules for applying them, can be found here:
 * [Constrain Place Types](https://developers.google.com/maps/documentation/javascript/*place-autocomplete#constrain-place-types)
 *
 * **POTENTIAL ERRORS - NO RESULTS**
 *
 * - If `types` are **NOT** setup correctly (as per Google’s rules in the link above), Google Autocomplete may return **NO** results.
 * - If the `types` are **NOT** set in a comma separated list, as per the standard above (eg. `type[comma]type` etc.) then Autocomplete may return **NO** results.
 */
export const getConfigTypes = (types: string): string[] | undefined => {
  if (types.includes('null') || types.includes(' ')) {
    return undefined
  }

  // is a single type
  if (!types.includes(',')) {
    return [types]
  }

  return types.split(',')
}
