import { AdditionalUserDetails, RequestedFields, SocialUserDetails } from './auth-button-group.interface'
import { AuthResponse } from './native-auth-button-group'

const getCreateAccountSceneProps = (requestedFields: RequestedFields): AdditionalUserDetails => ({
  name: requestedFields.name,
  email: requestedFields.email,
  identityProvider: {
    identityProviderId: requestedFields.id,
    externalProviders: [
      {
        providerType: requestedFields.provider.toLowerCase() as BffContext.ProviderTypes,
        providerId: requestedFields.id,
      },
    ],
  },
})

// putting some logging to track the issue where the user info is not passed from apple when registering a new account, remove this after the issue is resolved
// https://dominos.atlassian.net/browse/CUAC-1494
const getAppleSpecificLoggingPayload = (
  provider: BffContext.SocialLogins.Providers,
  customerInfo: Bff.SocialLogins.SocialLogin,
  userDetails: SocialUserDetails | AuthResponse['user'],
) => {
  if (provider === 'Apple' && (!userDetails || !userDetails.email || !userDetails.name)) {
    return {
      message: 'Apple Registration: User info not passed from Apple SDK',
      email: customerInfo.email,
      note: !userDetails?.name ? 'no name provided' : '',
    }
  }

  return {}
}

export { getCreateAccountSceneProps, getAppleSpecificLoggingPayload }
