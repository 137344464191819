import gql from 'graphql-tag'

export const storesAlternativeDistancesQuery = gql`
  query storesAlternativeDistances($input: StoresAlternativeDistanceRequest!) {
    storesAlternativeDistances(input: $input) {
      alternativeStoreDistances {
        storeNo
        storeName
        distanceInMeters
        timeInSeconds
      }
    }
  }
`
