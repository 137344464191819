import { SecurityContextProps } from '@dominos/hooks-and-hocs/logging'
import {
  setupAI,
  setupAppleId,
  setupGTM,
  setupNewRelic,
  setupOneTrust,
  setupRecaptcha,
  setupRokt,
  setupSnowplow,
  setupVWO,
} from '.'
import { Config, isKioskOrderType, loadScript } from '@dominos/business/functions/common'
import { isNativeApp } from '../native-app/is-native-app'
import { useFeatures } from '@dominos/hooks-and-hocs'

export const useSetupVendors = (config: Config): Record<string, SecurityContextProps> => {
  const [roktEnabled] = useFeatures('RoktAdvertisements')

  setupRecaptcha(config, loadScript)
  setupAppleId(config, loadScript)
  roktEnabled && setupRokt(config, loadScript)

  if (!isKioskOrderType()) {
    setupOneTrust(config, loadScript)
  }

  if (!isNativeApp()) {
    setupVWO(config, loadScript)
    setupGTM(config, loadScript)
    setupSnowplow(config)
  }

  return {
    securityClient: {
      client: setupAI(config),
    },
  }
}

export const setupVendorsPreDOMLoad = (config: Config): void => {
  setupNewRelic(config)
}
