import React from 'react'

export const PayPalReturnUrl = 'https://paypal-return.dominos.com.au/success'
export const PayPalCancelUrl = 'https://paypal-return.dominos.com.au/cancel'

export const usePayPalPayment = () => ({
  authorizeOrder: async (url: string) => Promise.resolve(!!url),
  Modal: () => <></>,
})

export const PayPalPaymentModal = (props: PayPalPaymentModalProps) => null
