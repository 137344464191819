import { TOptions } from 'i18next'

export type NotificationType = 'SignInSuccess' | 'SignUpSuccess'

export interface NotificationDefinition {
  title: {
    key: string
    options: string | TOptions
  }
  message: {
    key: string
    options: string | TOptions
  }
  displayType?: 'popup' | 'alert' | 'errorText'
}

export const AUTHENTICATION_NOTIFICATIONS: Record<NotificationType, NotificationDefinition> = {
  SignInSuccess: {
    title: {
      key: 'SignInSuccessTitle',
      options: 'Logged In',
    },
    message: {
      key: 'SignInSuccessMessage',
      options: 'You have been signed into your account successfully.',
    },
  },
  SignUpSuccess: {
    title: {
      key: 'SignUpSuccessTitle',
      options: 'Account Created',
    },
    message: {
      key: 'SignUpSuccessMessage',
      options: 'Your account has been successfully created.',
    },
  },
}
