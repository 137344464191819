import { useState } from 'react'

export const useHighlightElement = () => {
  const [isHighlight, setIsHighlight] = useState(false)

  const changeZIndex = (element: HTMLElement, zIndex: string) => {
    element.style.zIndex = zIndex
  }

  const elevateElement = (element: HTMLElement) => {
    changeZIndex(element, '6')
  }

  const demoteElement = (element: HTMLElement) => {
    changeZIndex(element, '')
  }

  const highlightSingleElement = (element: HTMLElement) => {
    elevateElement(element)
    setIsHighlight(true)
  }

  const removeHighlightSingleElement = (element: HTMLElement) => {
    if (element) {
      demoteElement(element)
      setIsHighlight(false)
    }
  }

  const highlightElements = (elements: HTMLElement[]) => {
    if (elements) {
      elements.forEach(elevateElement)
      setIsHighlight(true)
    }
  }

  const removeHighlightElements = (elements: HTMLElement[]) => {
    if (elements) {
      elements.forEach(demoteElement)
      setIsHighlight(false)
    }
  }

  return {
    highlightSingleElement,
    removeHighlightSingleElement,
    highlightElements,
    removeHighlightElements,
    isHighlight,
  }
}
