import { rootActions } from '@dominos/business'
import { convertAddressLineToStore } from '@dominos/business/functions/address'
import {
  ErrorNotifier,
  SimpleNavigationTitle,
  StackNavigationBase,
  StoreSearchPickupStoreContainer,
} from '@dominos/components'
import { useGeoLocation, useLanguages, useReport, useStoreSearch, useStoreSelect } from '@dominos/hooks-and-hocs'
import { useOneTrust } from '@dominos/hooks-and-hocs/one-trust/one-trust-provider'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const MINIMUM_CHARS = 3
const MINIMUM_CHARS_FOR_JAPANESE = 2

const StoreSearchSceneWrapper = () => {
  const [searchText, setSearchText] = useState('')
  const { language } = useLanguages()
  const isJapaneseLanguage = !!language && language === 'ja'
  const { location, getLocation, requestLocationPermission } = useGeoLocation()

  const { search, results, loading } = useStoreSearch(
    isJapaneseLanguage ? MINIMUM_CHARS_FOR_JAPANESE : MINIMUM_CHARS,
    location,
  )
  const { getStore, store } = useStoreSelect()
  const { reportStoreSelected } = useReport()
  const dispatch = useDispatch()
  const consents = useOneTrust()

  useEffect(() => {
    dispatch(rootActions.resetCurrentStore())

    const shareLocation = async () => {
      const result = await requestLocationPermission()

      return result && getLocation()
    }

    shareLocation()
  }, [])

  const onSearchTextChanged = (text: string) => {
    search(text)
    setSearchText(text)
  }

  const onLinePress = useCallback(
    (line: AddressLine) => {
      const store = convertAddressLineToStore(line)

      getStore(store.storeNo)
    },
    [consents],
  )

  useEffect(() => {
    if (store) {
      dispatch(rootActions.storeSelected(store, consents.Functional))
      dispatch(rootActions.selectServiceMethod('Pickup'))

      reportStoreSelected(store)

      navigate(NavigationConstants.timedOrder)
    }
  }, [store])

  return (
    <StackNavigationBase testID='store-search-scene' header={<SimpleNavigationTitle showAccount showBackButton />}>
      <div style={{ margin: '8px 8px 40px', display: 'flex', justifyContent: 'center' }}>
        <StoreSearchPickupStoreContainer
          addresses={results}
          loading={loading}
          onSearchTextChanged={onSearchTextChanged}
          onLinePress={onLinePress}
          // autoFocus={savedStores.length === 0}
          searchText={searchText}
        />
      </div>
    </StackNavigationBase>
  )
}

export const StoreSearchScene = () => (
  <ErrorNotifier>
    <StoreSearchSceneWrapper />
  </ErrorNotifier>
)
