import { useEffect, useState } from 'react'
import { mapGoogleGeoAddressToDetectedLocationAddressLine, useLanguages } from '@dominos/hooks-and-hocs'
import { GeoLocationInterface } from '@dominos/interfaces'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { getLoaderInstance } from 'olo-feature-address'

const { GOOGLE_MAPS_API_KEY, DELIVERY_ADDRESS_SEARCH_MATCH_RADIUS } = getCountryConfig()

export const useGeocoder = () => {
  const [addressLine, setAddressLine] = useState<AddressLine>()
  const { language } = useLanguages()
  const getDeliveryAddressSearchMatchRadius: number = parseInt(DELIVERY_ADDRESS_SEARCH_MATCH_RADIUS)

  const [geocoder, setGeocoder] = useState<google.maps.Geocoder>()

  useEffect(() => {
    const loader = getLoaderInstance(GOOGLE_MAPS_API_KEY)
    loader.load().then(async () => {
      const { Geocoder } = (await google.maps.importLibrary('geocoding')) as google.maps.GeocodingLibrary
      setGeocoder(new Geocoder())
    })
  }, [GOOGLE_MAPS_API_KEY])

  const reverseGeocodeLocation = async (request: GeoLocationInterface, filterTypes?: string[]) => {
    if (!geocoder) return
    geocoder.geocode({ location: { lat: request.latitude, lng: request.longitude }, language }).then((response) => {
      const address = mapGoogleGeoAddressToDetectedLocationAddressLine(
        request,
        response,
        getDeliveryAddressSearchMatchRadius,
        filterTypes,
      )
      setAddressLine(address)
    })
  }

  const geocodeAddress = async (address: string) => {
    if (!geocoder) throw 'Geocoder not initialized!'

    return await geocoder.geocode({ address, language })
  }

  return {
    addressLine,
    reverseGeocodeLocation,
    geocodeAddress,
  }
}
