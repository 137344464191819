import { useEffect, useState } from 'react'
import { OrderModel, PaymentDetailModel } from '@dominos/hooks-and-hocs/order/model'

export const useIsFirstPayment = (order: Bff.Orders.Order | undefined) => {
  const [orderTotal, setOrderTotal] = useState<number | undefined>(undefined)
  const [firstPayment, setFirstPayment] = useState<PaymentDetailModel | undefined>(undefined)

  useEffect(() => {
    if (!order) {
      return
    }

    const currentOrder = new OrderModel(order!)

    setOrderTotal(currentOrder?.orderTotal)
    setFirstPayment(currentOrder?.addedFirstPayment)
  }, [order])

  return {
    orderTotal,
    firstPayment,
  }
}
