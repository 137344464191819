import { DEClassic } from './de-pizza-classic'
import { DELarge } from './de-pizza-large'
import { DEMedium } from './de-pizza-medium'

export const DESizeIcons: { [code: string]: React.ComponentType<SizeSVGProps> } = {
  'Pizza.Classic': DEClassic,
  'Pizza.Medium': DEMedium,
  'Pizza.Large': DELarge,
}

export type DESizeCode = keyof typeof DESizeIcons
