import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 22,
  height: 24,
}

const RewardsNavIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#979797'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 22 24'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(1.000000, 0.000000)'>
          <g>
            <path
              d='M9.68903779,19.3754588 C7.12738441,19.3754588 4.57751221,18.9071689 2.03942117,17.970589 C1.26223191,17.683785 0.864682696,16.8212542 1.15147461,16.0440605 C1.17847223,15.9708981 1.21113432,15.8999525 1.24916381,15.831869 L9.74342262,0.624755859 L18.1928223,15.8273675 C18.595244,16.5514723 18.3344931,17.4647095 17.6103992,17.8671508 C17.5418885,17.9052281 17.4704986,17.9378768 17.3968854,17.964797 C14.8252489,18.9052382 12.2559664,19.3754588 9.68903779,19.3754588 Z M9.72019701,10.6965037 C10.8247665,10.6965037 11.720197,9.80107321 11.720197,8.69650371 C11.720197,7.59193421 10.8247665,6.69650371 9.72019701,6.69650371 C8.61562751,6.69650371 7.72019701,7.59193421 7.72019701,8.69650371 C7.72019701,9.80107321 8.61562751,10.6965037 9.72019701,10.6965037 Z M6.35649723,17.093192 C7.46106673,17.093192 8.35649723,16.1977615 8.35649723,15.093192 C8.35649723,13.9886225 7.46106673,13.093192 6.35649723,13.093192 C5.25192773,13.093192 4.35649723,13.9886225 4.35649723,15.093192 C4.35649723,16.1977615 5.25192773,17.093192 6.35649723,17.093192 Z M13.0838968,17.093192 C14.1884663,17.093192 15.0838968,16.1977615 15.0838968,15.093192 C15.0838968,13.9886225 14.1884663,13.093192 13.0838968,13.093192 C11.9793273,13.093192 11.0838968,13.9886225 11.0838968,15.093192 C11.0838968,16.1977615 11.9793273,17.093192 13.0838968,17.093192 Z'
              fill={fill}
            />
            <path
              d='M10.6845191,11.6121568 C9.39875632,14.7906026 8.75587495,17.9690485 8.75587495,21.1474943 C8.75587495,24.3259402 9.39875632,27.5043861 10.6845191,30.6828319'
              stroke={fill}
              strokeWidth='2.25'
              strokeLinecap='round'
              strokeLinejoin='bevel'
              transform='translate(9.720197, 21.147494) rotate(-90.000000) translate(-9.720197, -21.147494)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export { RewardsNavIcon }
