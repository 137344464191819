import { rootActions } from '@dominos/business/root.actions'
import { SwapSetProductCacheState } from '@dominos/interfaces'
import { getType } from 'typesafe-actions'

export interface VoucherReducerState {
  swapSets: SwapSetProductCacheState[]
}

export const voucherReducer: import('redux').Reducer<VoucherReducerState> = (
  state: VoucherReducerState = { swapSets: [] },
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.validateBasket.success):
      const swapSets = getSwapSetProductsNotCached(state.swapSets, action.payload.validateBasket.basket)

      if (swapSets.length) {
        state.swapSets.push(
          ...swapSets.map((swapSet) => ({
            voucherCode: swapSet.voucherCode,
            itemNo: swapSet.itemNo,
          })),
        )
      }

      return state

    case getType(rootActions.swapSetProductsCached):
      if (action.payload.length) {
        action.payload.forEach((cachedSwapSet: SwapSetProductCacheState) => {
          const swapSet = state.swapSets.find(
            (cache) =>
              cache.voucherCode === cachedSwapSet.voucherCode && cache.itemNo === cachedSwapSet.itemNo && !cache.cached,
          )

          if (swapSet) {
            swapSet.cached = true
          }
        })
      }

      return state

    default:
      return state
  }
}

const getSwapSetProductsNotCached = (swapSetProductCache: SwapSetProductCacheState[], basketData: Basket | null) => {
  if (!basketData) {
    return []
  }

  return basketData.lines
    .filter((line) => line.type === 'BasketCoupon' && line.items)
    .flatMap((line) => {
      const coupon = line as BasketCoupon

      return coupon.items
        .filter(
          (item) =>
            !swapSetProductCache.find(
              (cache) => cache.voucherCode === coupon.code && cache.itemNo === item.itemNo && cache.cached,
            ),
        )
        .map((item) => ({
          voucherCode: coupon.code,
          itemNo: item.itemNo,
        }))
    })
}
