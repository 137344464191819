/* eslint-disable @typescript-eslint/no-explicit-any */

import { ApolloClient, OperationVariables, QueryOptions } from '@apollo/client'

// caching to avoid unneccessary re-rendering and to
// address debounce closure issues
const fancyDropdownFieldFetchDataHelperCache: {
  key: QueryOptions<OperationVariables>['query']
  client: ApolloClient<object>
  queryOptions: (value: string) => QueryOptions<OperationVariables>
  dataProp: string
  stringify: (item: any) => string
  dataCallback?: (items: any[]) => void
  fetchDataFunc: (value: string, callback: FancyDropdownFieldFetchDataCallback<any>) => void
}[] = []

export const fancyDropdownFieldFetchDataHelper = <KeyType>(
  client: ApolloClient<object>,
  queryOptions: (value: string) => QueryOptions<OperationVariables>,
  dataProp: string,
  stringify: (item: KeyType) => string,
  dataCallback?: (items: KeyType[]) => void,
) => {
  const cacheKey = queryOptions('').query

  const matchedKey = fancyDropdownFieldFetchDataHelperCache.find((cacheItem) => cacheItem.key === cacheKey)
  if (matchedKey) {
    matchedKey.client = client
    matchedKey.queryOptions = queryOptions
    matchedKey.dataProp = dataProp
    matchedKey.stringify = stringify
    matchedKey.dataCallback = dataCallback

    return matchedKey.fetchDataFunc
  }

  const cache = {
    client,
    queryOptions,
    dataProp,
    stringify,
    dataCallback,
    key: cacheKey,
    fetchDataFunc: (value: string, callback: FancyDropdownFieldFetchDataCallback<KeyType>) => {
      cache.client
        .query<{ [K: string]: KeyType[] }>(cache.queryOptions(value))
        .then((response) => {
          if (response && response.data && response.data[cache.dataProp]) {
            if (cache.dataCallback) {
              cache.dataCallback(response.data[cache.dataProp])
            }
            callback(
              response.data[cache.dataProp].map((key) => ({
                key,
                value: cache.stringify(key),
              })),
            )
          } else {
            callback()
          }
        })
        .catch((err) => {
          callback()
        })
    },
  }

  fancyDropdownFieldFetchDataHelperCache.push(cache)

  return cache.fetchDataFunc
}
