export const getQuantityWithChange = (quantity = 0, change?: BasketLineChange) => {
  if (change?.action === 'Remove') {
    return Math.max(quantity - change?.quantity, 0)
  }

  if (change?.action === 'Add') {
    return quantity + change?.quantity
  }

  return Math.max(quantity, 0)
}
