import { GoogleAddressTypes } from '@dominos/hooks-and-hocs/location/use-auto-complete/providers/google/google.interface'
import { AddressComponentUpdate } from '@dominos/components/address/delivery-address-additional-fields'

export const getGeocoderAddressComponentFromGeocoderAddressComponents = (
  components: google.maps.GeocoderAddressComponent[],
  type: GoogleAddressTypes,
) => {
  const component = components.find((component) => component.types.includes(type))

  if (!component) {
    return undefined
  }

  return component
}

export const getAddressComponentFromDeliveryAddress = (
  address: AddressComponent[],
  type: keyof CustomerAddressRequest,
): AddressComponent | undefined => {
  const component = address.find((item) => type === item.type)

  if (!component) {
    return undefined
  }

  return component
}

export const getAddressComponentsFromCustomerAddressRequest = (request: CustomerAddressRequest): AddressComponent[] => {
  const components: AddressComponent[] = []
  const requestAsAny = request as { [key: string]: string | null | undefined }

  for (const key in requestAsAny) {
    if (requestAsAny.hasOwnProperty(key)) {
      const value = requestAsAny[key]
      if (value !== null && value !== undefined) {
        components.push({
          type: key,
          value: value,
        })
      }
    }
  }

  return components
}

export const getAddressComponentsFromAdditionalFields = (updatedFields: AdditionalFields): AddressComponentUpdate[] => {
  const addressComponents: AddressComponentUpdate[] = []

  for (const fieldName in updatedFields) {
    if (updatedFields.hasOwnProperty(fieldName)) {
      const field = updatedFields[fieldName as keyof AdditionalFields]
      if (field) {
        const addressComponent = {
          type: fieldName,
          value: field.value || '',
          isValid: field.isValid,
        }
        addressComponents.push(addressComponent)
      }
    }
  }

  return addressComponents
}

export const convertAdditionalFieldsOnInitToAdditionalFields = (
  initialState: AdditionalFieldsInit,
  customerAddress: CustomerAddressRequest | undefined,
) => {
  const addressComponents = customerAddress ? getAddressComponentsFromCustomerAddressRequest(customerAddress) : []

  return Object.entries(initialState).reduce((additionalFields, [key, { isValid }]) => {
    additionalFields[key as keyof AdditionalFields] = {
      value: getAddressComponentFromDeliveryAddress(addressComponents, key as keyof AdditionalFields)?.value,
      isValid: isValid ?? false,
    }

    return additionalFields
  }, {} as AdditionalFields)
}
