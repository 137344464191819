import { getGeocoderAddressComponentFromGeocoderAddressComponents } from '@dominos/business/functions/address'
import { AddressMappingConfig } from './google.interface'
import { addressMappingsByCountry } from './address-mapping-by-country'
import { findClosestGeocoderLocation } from '@dominos/hooks-and-hocs'

const unicodeIsJapaneseStreetNumberRegex = /^\p{N}+([−-]\p{N}+)*$/u

export const mapGeocoderAddressComponentToDominosAddressComponent = (
  googleAddressComponent: google.maps.GeocoderAddressComponent[],
  addressAttributesMap: AddressMappingConfig,
): AddressComponent => {
  if (!allRequiredGoogleAddressComponentsProvided(googleAddressComponent, addressAttributesMap))
    return { type: addressAttributesMap.attribute, value: '' }

  const compactString = addressAttributesMap.googleTypes
    .reduce((result, googleTypeWithRule) => {
      const findType = googleTypeWithRule.type
      const rule = googleTypeWithRule.rule
      if (rule) {
        switch (rule) {
          case 'isNumeric':
            const getNumber = googleAddressComponent.find(
              ({ types, short_name }) =>
                types.includes(findType) && short_name.match(unicodeIsJapaneseStreetNumberRegex),
            )?.short_name
            if (getNumber) {
              result.push(getNumber)
            }
            break
          case 'isString':
            const getString = googleAddressComponent.find(
              ({ types, short_name }) =>
                types.includes(findType) && !short_name.match(unicodeIsJapaneseStreetNumberRegex),
            )?.short_name
            if (getString) {
              result.push(getString)
            }
            break
          default:
            return result
        }
      } else {
        const value = googleAddressComponent.find(({ types }) => types.includes(findType))?.short_name
        if (value) {
          result.push(value)
        }
      }

      return result
    }, [] as string[])
    .join(addressAttributesMap.separator ?? ' ')

  let finalString = addressAttributesMap.replaceStringRule
    ? compactString.replace(
        addressAttributesMap.replaceStringRule.searchValue,
        addressAttributesMap.replaceStringRule.replaceValue,
      )
    : compactString

  if (!finalString && !!addressAttributesMap.defaultValue) finalString = addressAttributesMap.defaultValue

  return {
    type: addressAttributesMap.attribute,
    value: finalString,
  }
}

export const convertGoogleAddressComponentToDeliveryAddressComponent = (
  googleAddresssComponents: google.maps.GeocoderAddressComponent[],
  countryCode: BffContext.Countries,
  locale: string,
): AddressComponent[] => {
  const addressTypeMap = addressMappingsByCountry[countryCode]
  if (googleAddresssComponents.length > 0 && addressTypeMap.length > 0) {
    const processedDeliveryAddressComponent = addressTypeMap.map((addressAttribute) =>
      mapGeocoderAddressComponentToDominosAddressComponent(googleAddresssComponents, addressAttribute),
    )

    return overrideDeliveryAddressComponent(
      googleAddresssComponents,
      processedDeliveryAddressComponent,
      countryCode,
      locale,
    )
  }

  return []
}

export const allRequiredGoogleAddressComponentsProvided = (
  googleAddressComponent: google.maps.GeocoderAddressComponent[],
  addressAttributesMap: AddressMappingConfig,
): boolean =>
  addressAttributesMap.googleTypes.every((googleTypeWithRule) =>
    googleTypeWithRule.required
      ? googleAddressComponent.some(({ types }) => types.includes(googleTypeWithRule.type))
      : true,
  )

export const replaceElementInAddressComponent = (addressComponents: AddressComponent[], key: string, value: string) => {
  const index = addressComponents.findIndex(({ type }) => type === key)
  const insertAddressComponent: AddressComponent = {
    type: key,
    value: value,
  }
  if (index >= 0) {
    addressComponents.splice(index, 1, insertAddressComponent)
  } else {
    addressComponents.push(insertAddressComponent)
  }

  return addressComponents
}

export const overrideDeliveryAddressComponent = (
  googleAddresssComponents: google.maps.GeocoderAddressComponent[],
  addressComponents: AddressComponent[],
  countryCode: BffContext.Countries,
  locale: string,
) => {
  if (countryCode === 'NZ') {
    const sublocality = getGeocoderAddressComponentFromGeocoderAddressComponents(
      googleAddresssComponents,
      'sublocality',
    )?.short_name
    if (sublocality) {
      addressComponents = replaceElementInAddressComponent(addressComponents, 'suburb', sublocality)
    }

    addressComponents = replaceElementInAddressComponent(addressComponents, 'state', countryCode)
  }

  if (['SG', 'FR', 'NL', 'BE', 'DE', 'LU'].includes(countryCode)) {
    addressComponents = replaceElementInAddressComponent(addressComponents, 'state', countryCode)
  }

  // TODO: add overrides for BE, JP
  // override deprecated field
  addressComponents = replaceElementInAddressComponent(addressComponents, 'nickname', '')

  return addressComponents
}

export const mapGoogleGeoToDeliveryAddressRequest = (
  details: google.maps.GeocoderResponse | undefined,
  prediction: AddressLine,
  countryCode: BffContext.Countries | undefined,
  deliveryAddressSearchMatchRadius?: number,
): DeliveryAddressRequest | undefined => {
  if (!details || details.results.length === 0 || !countryCode) {
    return undefined
  }

  let result = null
  if (prediction.geo?.id) result = details.results[0]
  else if (!!prediction.geo?.latitude && !!prediction.geo?.longitude)
    result = findClosestGeocoderLocation(
      prediction.geo.latitude,
      prediction.geo.longitude,
      details.results,
      deliveryAddressSearchMatchRadius,
    )

  if (!result) {
    return undefined
  }

  const addressComponents = convertGoogleAddressComponentToDeliveryAddressComponent(
    result.address_components,
    countryCode,
    (prediction?.locale ?? '') as string,
  )

  // TODO: this should be fixed, we don't really want to pull formatted_address from one object and title+subtitle from another, it creates inconsistency
  const title = prediction?.structuredAddress?.title ?? ''
  const subtitle = prediction?.structuredAddress?.subtitle ?? result.formatted_address

  return {
    displayAddress: result.formatted_address,
    structuredAddress: {
      title: title,
      subtitle: subtitle,
    },
    geo: {
      latitude: prediction.geo?.latitude ?? result.geometry.location.lat(),
      longitude: prediction.geo?.longitude ?? result.geometry.location.lng(),
    },
    addressComponents,
    locale: prediction.locale,
  }
}

export const mapGoogleGeoToDeliveryAddressRequestWithEmptyPrediction = (
  details: google.maps.GeocoderResponse | undefined,
  countryCode: BffContext.Countries | undefined,
): DeliveryAddressRequest | undefined => {
  if (!details || details.results.length === 0 || !countryCode) {
    return undefined
  }
  const result = details.results[0]

  const addressComponents = convertGoogleAddressComponentToDeliveryAddressComponent(
    result.address_components,
    countryCode,
    '',
  )

  // TODO: this should be fixed, we don't really want to pull formatted_address from one object and title+subtitle from another, it creates inconsistency
  const title = ''
  const subtitle = result.formatted_address

  return {
    displayAddress: result.formatted_address,
    structuredAddress: {
      title: title,
      subtitle: subtitle,
    },
    geo: {
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
    },
    addressComponents,
    locale: '',
  }
}
