import { RetryLink } from '@apollo/client/link/retry'
import { RetryFunction } from '@apollo/client/link/retry/retryFunction'
import { ServerError } from '@apollo/client/link/utils'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { getCountryConfig } from '@dominos/business/functions'

type ShouldAttemptRetryHandler = (isEnabled: boolean, securitySender: SecuritySender) => RetryFunction

const config = getCountryConfig()
const initialRetryDuration = Number(config.APOLLO_RETRY_DURATION || 300)
const maxRetryAttempts = Number(config.APOLLO_RETRY_ATTEMPTS || 5)

export const shouldAttemptRetry: ShouldAttemptRetryHandler =
  (isEnabled, securitySender: SecuritySender) => (count, operation, error) => {
    if (isEnabled && count <= maxRetryAttempts && !!error) {
      securitySender(
        'ApolloClientError.RetryAttempt',
        {
          graphOperationName: operation.operationName,
          response: (error as ServerError).response,
          statusCode: (error as ServerError).statusCode,
          attempts: count,
          message: error.message,
        },
        true,
      )

      return true
    }

    return false
  }

export const retryLink = (isEnabled: boolean, securitySender: SecuritySender) => {
  const retrySettings: RetryLink.Options = {
    delay: {
      initial: initialRetryDuration,
      max: Infinity,
      jitter: false,
    },
    attempts: shouldAttemptRetry(isEnabled, securitySender),
  }

  return new RetryLink(retrySettings)
}
