import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { GenericCard } from '@dominos/components'
import { useBreakpoints, useFeatures } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, useLocation } from '@reach/router'
import { ErrorNotifier } from '@dominos/components/error'

import { Apple } from './apple'
import { CreateAccountButton } from './create-account-button'
import { EmailLogin } from './email-login'
import { Facebook } from './facebook'
import { Google } from './google'
import { SignUpMessage } from './signup-message'
import { isNativeApp } from '@dominos/business/functions'
import { LoginPopupWarningModal } from './popup/login-popup-warning-modal'

interface RedirectState {
  redirectTo?: string
}

const LoginContainer = () => {
  const { t } = useTranslation('login')
  const { isMobile } = useBreakpoints()
  const { state } = useLocation()
  const [appleAuthEnabled, fbAuthEnabled, googleAuthEnabled] = useFeatures(
    'apple-auth',
    'facebook-auth',
    'google-login',
  )
  const [keepMeLoggedIn, toggleKeepMeLoggedIn] = useState<boolean>(false)
  const [showPopupWarning, setShowPopupWarning] = useState<boolean>(false)
  const isFromNativeApp = isNativeApp()
  const location = useLocation()
  const redirectTo = (location?.state as RedirectState)?.redirectTo

  const onSignUpClicked = () => {
    navigate(NavigationConstants.createAccount, { state: state as RedirectState }) // passing redirectTo
  }

  const onCloseWarningModal = () => setShowPopupWarning(false)
  const handlePopupBlocked = () => setShowPopupWarning(true)
  const handleEmailSuccess = () => {
    redirectTo ? navigate(redirectTo) : navigate(NavigationConstants.home)
  }

  const enableLongLived = isFromNativeApp || keepMeLoggedIn

  return (
    <ErrorNotifier namespace={'login'}>
      {showPopupWarning && <LoginPopupWarningModal onClose={onCloseWarningModal} />}
      <GenericCard
        testID='Login.main-card'
        title={t('LogIn')}
        width={isMobile ? magicStyles.mobile.staticCardWidth : magicStyles.desktop.staticCardWidth}
      >
        <SignUpMessage onSignUpClicked={onSignUpClicked} />
        <EmailLogin
          enableLongLived={enableLongLived}
          keepMeLoggedIn={keepMeLoggedIn}
          toggleKeepMeLoggedIn={toggleKeepMeLoggedIn}
          onSuccess={handleEmailSuccess}
        />
        {appleAuthEnabled ? <Apple enableLongLived={enableLongLived} /> : null}
        {fbAuthEnabled ? <Facebook enableLongLived={enableLongLived} handlePopupBlock={handlePopupBlocked} /> : null}
        {googleAuthEnabled ? <Google enableLongLived={enableLongLived} handlePopupBlock={handlePopupBlocked} /> : null}
        <CreateAccountButton onSignUpClicked={onSignUpClicked} />
      </GenericCard>
    </ErrorNotifier>
  )
}

const magicStyles = {
  mobile: {
    staticCardWidth: '360px',
  },
  desktop: {
    staticCardWidth: '376px',
  },
}

export { LoginContainer }
