import css from './tracker-forgerock-create-account.less'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton, CardHeader } from '@dominos/components'

interface CreateAccountProps extends BaseProps {
  onLogin?: () => void
  onRegister?: () => void
}

export const TrackerForgeRockCreateAccount = ({ testID, onLogin, onRegister }: CreateAccountProps) => {
  const { t } = useTranslation('login')

  return (
    <div data-testid={testID}>
      <CardHeader testID={`${testID}.header`} title={t('SignUpTitle', { defaultValue: `Create Account` })} inline />
      <div data-testid={`${testID}.section`} className={css.section}>
        <div data-testid={`${testID}.description`}>
          {t('SignUpDescription', {
            defaultValue: `Unlock faster ordering and easier checkout with a Domino's account`,
          })}
        </div>
      </div>
      <div data-testid={`${testID}.section`} className={css.section}>
        <ActionButton
          testID={`${testID}.createAccount.button`}
          onPress={onRegister}
          text={t('CreateAccountButton', { defaultValue: 'Create Account' })}
          containerStyle={{ width: 'calc(100% - 16px)', margin: '8px 8px 4px 8px', height: '54px' }}
          textFontWeight='semibold'
        />
        <div data-testid={`${testID}.alternative`} className={css.alternative}>
          {t('SignUpAlternativeLabel', { defaultValue: 'Already got an account?' })}
          <a data-testid={`${testID}.link`} onClick={onLogin}>
            {t('SignUpAlternativeButtonText', { defaultValue: `Log in` })}
          </a>
        </div>
      </div>
    </div>
  )
}
