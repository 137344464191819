import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const strokeWidth = 2.3

const defaultDimensions = {
  width: '132px',
  height: '133px',
}

const PortionEmptyRight = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg data-testid='half-empty-right-icon' width={`${width}`} height={`${height}`} viewBox='0 0 132 133'>
      <g>
        <g transform='translate(66.000000, 66.500000) rotate(-360.000000) translate(-66.000000, -66.500000) '>
          <path
            d='M59.5,131.120452 L59.5,1.19527285 C26.4793675,4.44705382 1,32.4665007 1,66.1578623 C1,99.849224 26.4793675,127.868671 59.5,131.120452 Z'
            stroke='#BFBFBF'
            strokeWidth={strokeWidth}
            fill='none'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M132,132.214529 C98.1296432,129.448272 71.5,100.93029 71.5,66.1578623 C71.5,31.3854351 98.1296432,2.8674522 132,0.101195652 L132,132.214529 Z'
            fill='#FFFFFF'
            transform='translate(101.750000, 66.157862) scale(-1, 1) translate(-101.750000, -66.157862) '
          />
        </g>
      </g>
    </svg>
  )
}

export { PortionEmptyRight }
