import React from 'react'

import { StaticSvgIcon } from '@dominos/res'
import css from './product-card-closer.less'

const CloseButton = ({ testID, onClick }: { testID: string; onClick: () => void }) => (
  <div data-testid={`${testID}.dismiss-button`} role='close' onClick={onClick} className={css.close}>
    <span data-testid={`${testID}.close-button`}>
      <StaticSvgIcon name='cross' direction='left' isUnstyled />
    </span>
  </div>
)

export default CloseButton
