import { ErrorDefinitionCode, ErrorHandler, isNotifiableErrorEvent, NotifiableEvent } from '../interfaces'
import { ApolloError } from '@apollo/client'
import { OrderStatus } from '@dominos/hooks-and-hocs/order'

export const getErrorHandlers = (notifiableError: NotifiableEvent): ErrorHandler => {
  const { handlers } = notifiableError

  if (isNotifiableErrorEvent(notifiableError)) {
    const { error } = notifiableError

    if (error.graphQLErrors && error.graphQLErrors.length > 0 && handlers?.graphErrorHandler) {
      return handlers.graphErrorHandler(error as ApolloError)
    }

    if (error.networkError && handlers?.networkErrorHandler) {
      return handlers.networkErrorHandler(error as ApolloError)
    }

    if (handlers?.genericErrorHandler) {
      return handlers?.genericErrorHandler(error as unknown as Record<ErrorDefinitionCode, unknown>)
    }
  } else {
    const { orderStatus } = notifiableError
    if (orderStatus && handlers?.statusErrorHandler) {
      return handlers?.statusErrorHandler(orderStatus as OrderStatus)
    }
  }

  return {
    handleErrorDisplayed: () => {},
    handleErrorClosed: () => {},
  }
}
