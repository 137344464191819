import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import styles from './new-vouchers-indicator.less'
import { useNewVouchersCount } from '@dominos/hooks-and-hocs'
import { BadgeIndicator } from '@dominos/components/badge-indicator/badge-indicator'

interface NewVouchersIndicatorProps {
  showCount?: boolean
}

export const NewVouchersIndicator = ({ showCount }: NewVouchersIndicatorProps) => {
  const { newVouchersCount } = useNewVouchersCount()

  return newVouchersCount > 0 ? (
    <div className={!showCount ? styles.bellContainer : styles.countContainer}>
      <BadgeIndicator value={newVouchersCount} showValue={showCount} testID='new-vouchers-indicator'>
        {!showCount && <StaticSvgIcon name='bell' isUnstyled />}
      </BadgeIndicator>
    </div>
  ) : null
}
