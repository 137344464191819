import { ErrorDefinitions } from '@dominos/interfaces'

export const genericErrorDefinitions: ErrorDefinitions = {
  NETWORK_ERROR: {
    translation: {
      key: 'NetworkErrorMessage',
      options: {
        defaultValue: 'A network error has occurred. We are unable to complete this action',
      },
    },
    display: 'alert',
  },
  UNKNOWN_ERROR: {
    translation: {
      key: 'UnknownError',
      options: 'Something has gone wrong. Please try again later.',
    },
    display: 'alert',
  },
}
