import { formatSectionName } from '@dominos/business/functions/menu'

/**
 * Returns the code of the visible section, or fallbacks if none in view.
 *
 * @param isVisible whether the current item is in the `Viewport`
 * @param item the current item being viewed
 * @param categoryData current category (for fallbacks)
 * @returns visible section code
 */
export const getVisibleSectionCode = (
  isVisible: boolean,
  item: ResolvedMenuSection,
  categoryData: ResolvedMenuSection[] | null,
) => {
  if (isVisible) {
    if (isScrollTop()) {
      const firstCategoryCode = (categoryData && categoryData[0].code) || ''

      return formatSectionName(firstCategoryCode)
    }

    return formatSectionName(item.code)
  }

  if (isScrollBottom()) {
    const lastCategoryCode = (categoryData && categoryData[categoryData.length - 1].code) || ''

    return formatSectionName(lastCategoryCode)
  }

  return ''
}

/**
 * If within `X` distance from bottom of screen return true.
 *
 * @param offset distance from bottom of screen
 */
const isScrollBottom = (offset: number = 50) => {
  const distanceFromBottom = document.body.scrollHeight - window.innerHeight - window.scrollY

  return isFirstValueLarger(distanceFromBottom, offset)
}

/**
 * If within `X` distance from top of screen return true.
 *
 * @param offset distance from top of screen
 */
const isScrollTop = (offset: number = 50) => {
  const distanceFromTop = document.body.getBoundingClientRect().top * -1

  return isFirstValueLarger(distanceFromTop, offset)
}

const isFirstValueLarger = (larger: number, smaller: number) => larger <= smaller
