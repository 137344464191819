import React, { ComponentProps, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { rootActions } from '@dominos/business'
import {
  AddressSearchErrors,
  AddressSearchResults,
  DeliverySearchInformationContainer,
  ERROR_INVALID_GOOGLE_ADDRESS_COMPONENT,
  ERROR_NO_RESULTS_DELIVERY,
  ERROR_NO_RESULTS_PICKUP,
  ERROR_SERVER_ERROR_DELIVERY,
  ERROR_SERVER_ERROR_PICKUP,
  GenericCard,
  ValidationTextField,
} from '@dominos/components'
import {
  GoogleAutoComplete,
  useAddressSearchDetails,
  useAddressSearchValidation,
  useAutoComplete,
  useCharacterLimit,
  useFeatures,
  useReport,
  useSearchAddress,
  useStoreSelect,
} from '@dominos/hooks-and-hocs'

import css from './address-search.less'
import { PickupStoreSearchResults } from '@dominos/scenes/auto-complete-pickup-scene/pickup-store-search-results'

import { DeliverySearchToolTip } from '@dominos/components'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

export interface AddressSearchProps extends BaseProps {
  type: ComponentProps<typeof AddressSearchResults>['type']
  title: ComponentProps<typeof GenericCard>['title']
  placeholder: ComponentProps<typeof ValidationTextField>['placeholder']
  onCompleteDelivery?: (address: DeliveryAddress, storeDetails: StoreDetails) => void
  onCompletePickup?: () => void
}

const DELIVERY_SERVICE_METHOD = 'Delivery'

// eslint-disable-next-line max-lines-per-function
export const AddressSearch = ({
  testID,
  type,
  title,
  placeholder,
  onCompletePickup,
  onCompleteDelivery,
}: AddressSearchProps) => {
  const dispatch = useDispatch()
  const avgAddressCharacters = useCharacterLimit()
  const searchAddressInput = useSelector((state: RootReducer) => state.customerReducer.searchAddressInput)
  const searchPickupStoreLocation = useSelector((state: RootReducer) => state.customerReducer.searchPickupStoreLocation)
  const [autoCompleteDeliveryHideMaxCharacterError] = useFeatures('AutoCompleteDeliveryHideMaxCharacterError')

  const [selectedPickupStoreLocation, setSelectedPickupStoreLocation] = useState<StoreGeo | undefined>()
  const [selectedAddress, setSelectedAddress] = useState<AddressLine | undefined>()
  const { reportStoreSelected } = useReport()
  const { getStore, store } = useStoreSelect()
  const {
    reportLandedDeliveryAutoCompletePage,
    reportSelectedAutoCompleteAddress,
    reportCompleteCanDeliver,
    reportSelectedAddressIncomplete,
    reportStoreDetailsError,
    reportNoSuggestionsFound,
    reportAddressInput,
  } = useReportAutoCompleteDelivery()
  const setStore = (store: Bff.Stores.Store) => dispatch(rootActions.storeSelected(store, true))
  const isDelivery = type === 'Delivery'

  const {
    getPredictions,
    predictions,
    getAddress,
    address,
    isLoading: isLoadingAutoComplete,
    input,
    error: autoCompleteError,
  } = useAutoComplete(GoogleAutoComplete, type)
  const { validationRules } = useAddressSearchValidation()
  const {
    fetchStoreDetails,
    fetchPickupStoreDetails,
    pickupStoreDetails,
    reset: resetStoreDetails,
    storeDetails,
    isLoading: isLoadingStoreDetails,
    error: storeDetailsServerError,
  } = useAddressSearchDetails(type)

  useEffect(() => {
    dispatch(rootActions.resetCurrentStore())
    dispatch(rootActions.resetAutoCompleteScenario())
    if (type === 'Pickup' && searchPickupStoreLocation) {
      const { longitude, latitude } = searchPickupStoreLocation
      const addressLocation = { geo: { longitude, latitude } } as DeliveryAddressRequest
      fetchPickupStoreDetails(addressLocation)
      setSelectedPickupStoreLocation(addressLocation.geo)
    }
  }, [])

  useEffect(() => {
    reportLandedDeliveryAutoCompletePage()
  }, [])

  useEffect(() => {
    reportAddressInput(input)
  }, [input])

  // Step 1
  useEffect(() => {
    const getStoreDetails = async () => {
      await fetchStoreDetails(address)
    }

    if (type === 'Pickup') {
      fetchPickupStoreDetails(address)
    }

    if (type === 'Delivery') {
      getStoreDetails()
    }
  }, [address, type])

  // Step 2
  useEffect(() => {
    if (!storeDetails?.storeNo || !address) {
      return
    }

    const addressLine: DeliveryAddress = { ...address, storeNo: storeDetails.storeNo }
    if (type === 'Delivery') {
      dispatch(rootActions.addSearchAddress(addressLine))
      dispatch(rootActions.saveSearchState(input))
    }
  }, [address, storeDetails])

  const searchAddress = useSearchAddress()

  // Step 3
  useEffect(() => {
    if (searchAddress && storeDetails && type === 'Delivery' && onCompleteDelivery) {
      reportCompleteCanDeliver(input)
      onCompleteDelivery(searchAddress, storeDetails)
    }
  }, [searchAddress])

  // Step 4
  useEffect(() => {
    if (store && type === 'Pickup' && onCompletePickup) {
      dispatch(rootActions.saveSearchState(input, address?.geo ?? selectedPickupStoreLocation))
      setStore(store)
      reportStoreSelected(store)
      onCompletePickup()
    }
  }, [store])

  const onPickupSearchStoreSelectedAddress = (address: AddressLine) => {
    if (type === 'Pickup') {
      const pickupStoreDetails = address.additionalData as PickupStoreDetails
      const locationId = pickupStoreDetails?.locations[0].locationId
      getStore(pickupStoreDetails?.storeNo ?? 0, null, locationId)
      dispatch(rootActions.savePickupStoreLocationId(locationId))
      dispatch(rootActions.selectServiceMethod('Pickup'))
    }
  }

  const onChange = async (updatedField: { [key: string]: ValidationField }) => {
    const key = Object.keys(updatedField)[0]
    const newSearch = updatedField[key]

    resetStoreDetails()
    setSelectedAddress(undefined)

    await getPredictions(newSearch.value, newSearch.isValid)
  }

  const onSelectAddress = async (address: AddressLine) => {
    setSelectedAddress(address)
    reportSelectedAutoCompleteAddress(address)
    await getAddress(address)
  }

  const onBackToAutoCompleteSearch = () => {
    resetStoreDetails()
    setSelectedAddress(undefined)
  }

  const retrySelectingAddress = useCallback(
    () => selectedAddress && onSelectAddress(selectedAddress),
    [selectedAddress],
  )

  const isLoading = useMemo(
    () => isLoadingAutoComplete || isLoadingStoreDetails,
    [isLoadingAutoComplete, isLoadingStoreDetails],
  )

  const hasNoPredictions = (predictions: AddressLine[] | undefined): boolean => !predictions || predictions.length < 1

  const errorCode = useMemo(() => {
    if (storeDetailsServerError) {
      if (type == 'Delivery') {
        reportStoreDetailsError(input, storeDetailsServerError?.message)

        return ERROR_SERVER_ERROR_DELIVERY
      } else {
        return ERROR_SERVER_ERROR_PICKUP
      }
    }

    if (storeDetails?.errorCode) {
      reportStoreDetailsError(input, storeDetails?.errorCode)

      return storeDetails.errorCode
    }

    if (autoCompleteError) {
      reportSelectedAddressIncomplete(input)

      return ERROR_INVALID_GOOGLE_ADDRESS_COMPONENT + `:${autoCompleteError.reason}`
    }

    if (hasNoPredictions(predictions) && input && input.length >= avgAddressCharacters) {
      if (type == 'Delivery') {
        if (!autoCompleteDeliveryHideMaxCharacterError) {
          reportNoSuggestionsFound(input)

          return ERROR_NO_RESULTS_DELIVERY
        } else {
          reportNoSuggestionsFound(input)

          return undefined
        }
      } else {
        return ERROR_NO_RESULTS_PICKUP
      }
    }

    if (predictions) {
      return undefined
    }

    return undefined
  }, [storeDetailsServerError, storeDetails, predictions, input, autoCompleteError])

  const shouldDisplayPickupSearchStore = type === 'Pickup' && pickupStoreDetails

  return (
    <div data-testid={testID} className={css.wrapper}>
      <DeliverySearchInformationContainer />
      <GenericCard testID={testID} title={title}>
        <ValidationTextField
          testID={testID}
          fieldName={'addressSearch'}
          placeholder={placeholder}
          hasClearInputButton
          hideEmptyErrorContainer
          onChange={onChange}
          validationRules={validationRules}
          initialValue={searchAddressInput}
        />
        {isDelivery && <DeliverySearchToolTip />}
        {!shouldDisplayPickupSearchStore && (
          <>
            <AddressSearchResults
              testID={`${testID}.searchResults`}
              type={type}
              searchValue={input}
              addresses={predictions}
              isLoading={isLoading}
              itemLoadingKey={selectedAddress?.uid}
              hasError={!!errorCode}
              onSelectAddress={onSelectAddress}
            />

            <AddressSearchErrors
              testID={`${testID}.errors`}
              errorCode={errorCode}
              isLoading={false}
              serviceMethod={DELIVERY_SERVICE_METHOD}
              onRetry={retrySelectingAddress}
            />
          </>
        )}
      </GenericCard>
      {shouldDisplayPickupSearchStore && (
        <PickupStoreSearchResults
          testID={`${testID}.pickupStoreSearchResults`}
          isLoading={isLoading}
          addresses={pickupStoreDetails}
          itemLoadingKey={selectedAddress?.uid}
          onSelectAddress={onPickupSearchStoreSelectedAddress}
          onRetry={onBackToAutoCompleteSearch}
        />
      )}
    </div>
  )
}
