import { ApolloError } from '@apollo/client'
import { ErrorScope, paymentErrors, useErrorContext, usePaymentErrorHandlers } from '@dominos/components'
import { useRef } from 'react'

export interface UsePlaceOrderErrorHandlerProps {
  retry: () => void
  noRetry: (error: ApolloError) => void
  canRetry: boolean
}

const PLACE_ORDER_RETRY_INTERVAL = 1000
const MAX_PLACE_ORDER_RETRY_ATTEMPTS = 2

export const usePlaceOrderErrorHandler = ({ retry, noRetry, canRetry }: UsePlaceOrderErrorHandlerProps) => {
  const { notifyError } = useErrorContext()
  const handlers = usePaymentErrorHandlers()
  const retryPlaceOrderCount = useRef(0)

  return (error: ApolloError) => {
    if (!error?.networkError || retryPlaceOrderCount.current >= MAX_PLACE_ORDER_RETRY_ATTEMPTS || !canRetry) {
      noRetry(error)
      notifyError({
        error,
        definitions: paymentErrors,
        handlers,
        scope: ErrorScope.Payment,
      })
    } else if (error?.networkError) {
      retryPlaceOrderCount.current++
      setTimeout(() => {
        retry()
      }, PLACE_ORDER_RETRY_INTERVAL)
    }
  }
}
