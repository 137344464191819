import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const SnacksIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <defs>
        <polygon id='path-1' points='3.55271368e-15 0 21.9999551 0 21.9999551 22.0074 3.55271368e-15 22.0074' />
        <polygon id='path-3' points='0 0 7.33533788 0 7.33533788 9.1039 0 9.1039' />
      </defs>
      <g id='Snacks' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-13' transform='translate(0.000000, 1.000000)'>
          <g id='Group-3' transform='translate(10.029328, 7.668600)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-2' />
            <path
              d='M3.55271368e-15,16.531 C0.0211338886,19.563 2.48613389,22.008 5.51313389,22.008 L5.55213389,22.008 C7.00413389,21.998 8.39413389,21.415 9.41913389,20.387 L20.3501339,9.457 C22.5241339,7.32 22.5531339,3.824 20.4161339,1.65 C19.3351339,0.551 17.9071339,0 16.4791339,0 C15.0811339,0 13.6831339,0.527 12.6081339,1.584 C12.5861339,1.606 12.5641339,1.628 12.5421339,1.65 L12.5421339,1.654 C11.9751339,2.232 11.5431339,2.93 11.2811339,3.697 C12.4941339,4.34 13.5021339,5.311 14.1911339,6.5 C13.1781339,5.976 12.0761339,5.643 10.9421339,5.519 C9.49313389,5.522 8.10413389,6.097 7.07813389,7.119 C6.51013389,7.696 6.07913389,8.392 5.81613389,9.157 C7.02913389,9.801 8.03713389,10.772 8.72613389,11.96 C7.75413389,11.341 6.62913389,11.001 5.47713389,10.979 C2.43113389,11 -0.0208661114,13.486 3.55271368e-15,16.531'
              id='Fill-1'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <path
            d='M19.4046623,2.8084 C19.2006623,2.6874 18.9906623,2.5754 18.7696623,2.4754 C17.2686623,1.8054 15.5956623,1.7584 14.0586623,2.3454 C12.5226623,2.9334 11.3066623,4.0834 10.6346623,5.5844 C10.1236623,6.7294 9.99266227,7.9504 10.1856623,9.1034 L11.1716623,9.1034 L12.1716623,8.1034 L12.2286623,8.1034 C12.3046623,7.7254 12.4126623,7.3494 12.5766623,6.9824 C13.4006623,5.1384 14.9606623,4.2544 16.8406623,4.1054 C16.8496623,4.1074 15.9666623,6.0864 15.0656623,8.1034 L16.1836623,8.1034 C16.6096623,8.1034 17.0226623,8.2034 17.3966623,8.3834 L19.6056623,3.4424 C19.7096623,3.2104 19.6226623,2.9374 19.4046623,2.8084'
            id='Fill-4'
            fill={fill}
          />
          <g id='Group-8' transform='translate(4.876724, -0.000000)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-7' />
            <path
              d='M0.951337884,9.1039 L2.97133788,9.1039 C2.58733788,8.3779 2.35633788,7.5579 2.34733788,6.6799 C2.32733788,4.6609 3.37633788,3.2069 5.02333788,2.2859 C5.03433788,2.2829 5.07633788,6.4339 5.10333788,9.1039 L6.29433788,9.1039 L7.29433788,8.1039 L7.33533788,8.1039 L7.25833788,0.5299 C7.25533788,0.2759 7.06333788,0.0639 6.81133788,0.0379 C6.57633788,0.0119 6.34033788,-0.0021 6.09633788,-0.0001 C4.45133788,0.0169 2.91133788,0.6729 1.76033788,1.8479 C0.608337884,3.0219 -0.0166621158,4.5749 0.000337884199,6.2199 C0.0093378842,7.0929 0.203337884,7.9359 0.555337884,8.7079 L0.951337884,9.1039 Z'
              id='Fill-6'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
          <path
            d='M0.815562275,8.1039 L2.65256227,8.1039 C2.28056227,6.8589 2.47356227,5.5829 3.09156227,4.3629 C3.09856227,4.3549 4.89956227,6.9739 6.36456227,9.1039 L6.27256227,5.0129 L3.96556227,1.6579 C3.82156227,1.4489 3.54456227,1.3809 3.32056227,1.4989 C3.11056227,1.6099 2.90556227,1.7299 2.70556227,1.8679 C0.606562275,3.3109 -0.318437725,5.8329 0.163562275,8.1849 C0.375562275,8.1339 0.593562275,8.1039 0.815562275,8.1039'
            id='Fill-9'
            fill={fill}
          />
          <path
            d='M7.99966227,23.6798 C7.97566227,20.1498 10.4026623,17.1638 13.6846623,16.3398 L13.9246623,15.6418 C14.2826623,14.6008 14.8796623,13.6358 15.6666623,12.8368 C16.0226623,12.4828 16.4166623,12.1828 16.8256623,11.9108 L16.9896623,11.0428 C17.0656623,10.5488 16.6836623,10.1038 16.1836623,10.1038 L12.9996623,10.1038 L11.9996623,11.1038 L5.65866227,11.1038 L4.99966227,11.1038 L3.99966227,10.1038 L0.815662275,10.1038 C0.315662275,10.1038 -0.0663377255,10.5488 0.00966227452,11.0428 L2.68166227,25.1358 C2.78766227,25.6968 3.27866227,26.1038 3.85066227,26.1038 L4.99966227,26.1038 L5.65866227,26.1038 L8.42866227,26.1038 C8.16066227,25.3428 8.00566227,24.5288 7.99966227,23.6798'
            id='Fill-11'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}
