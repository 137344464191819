import { DateTime } from 'luxon'
import { TFunction } from 'react-i18next'
import { OrderStatus } from '@dominos/hooks-and-hocs'
import { isTimeParsable } from './is-time-parsable'
import { getETAReportLabel } from './get-eta-report-label'
import { ETA_UNAVAILABLE } from './eta-constants'

export const getETAForTracker = (
  from: DateTime,
  to: DateTime,
  status: OrderStatus,
  locale: string,
  t: TFunction<'tracker'>,
): { text: string; reportLabel: `${number}-${number}` | null } => {
  const timeFormatMin = t('TimeFormatEtaMin', { defaultValue: 'h:mm' })
  const timeFormatMax = t('TimeFormatEtaMax', { defaultValue: 'h:mm a' })
  const timeFormatFuture = t('TimeFormatEtaFuture', { defaultValue: 'h:mm a d LLL' })

  if (isTimeParsable(from, to)) {
    const displayMin = from.setLocale(locale).toFormat(timeFormatMin)
    const displayMax = to.setLocale(locale).toFormat(timeFormatMax)
    const displayFuture = to.setLocale(locale).toFormat(timeFormatFuture)
    const futureOrderStatusList = [OrderStatus.New, OrderStatus.Pending, OrderStatus.SentToStore, OrderStatus.Timed]

    if (futureOrderStatusList.includes(status)) {
      return { text: displayFuture, reportLabel: null }
    }

    if (from.equals(to)) {
      return { text: displayMax, reportLabel: null }
    }

    return {
      text: `${displayMin} - ${displayMax}`,
      reportLabel: getETAReportLabel(from, to),
    }
  }

  return {
    text: t('ETAUnavailable', { defaultValue: ETA_UNAVAILABLE }),
    reportLabel: null,
  }
}
