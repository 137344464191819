import { ApolloError } from '@apollo/client/errors'
import { getErrorCodeFromApolloError, getErrorMessageFromApolloError } from '@dominos/components/error'

const ERROR_MESSAGES: Record<string, string> = {
  CREATE_ACCOUNT_FAILED: 'Create account failed',
  UNABLE_TO_RESOLVE: 'Unresolved error',
  BAD_USER_INPUT: 'Invalid user input',
  LOGIN_FAILED: 'Login failed',
}

export const getErrorMessageFromCreateAccountError = (error: ApolloError): string => {
  const message = getErrorMessageFromApolloError(error)
  if (message && message !== '') {
    return message
  }

  const code = getErrorCodeFromApolloError(error)

  return (code && ERROR_MESSAGES[code]) ?? ''
}
