import { useApolloClient } from '@apollo/client'
import { addressSearchQuery } from '@dominos/business/queries'
import { GraphQLError } from 'graphql'
import { useState } from 'react'

export interface SearchAddressResponse {
  deliverySearch: SearchAddress[]
}

export const useCustomerAddressSearch = () => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<SearchAddressResponse | undefined>(undefined)
  const [errors, setErrors] = useState<GraphQLError[] | undefined>()

  const getAddress = (address: CustomerAddressRequest) => {
    setLoading(true)
    setErrors(undefined)
    setData(undefined)

    client
      .query<SearchAddressResponse>({ query: addressSearchQuery, variables: address })
      .then((resp) => {
        if (resp.data) {
          setData(resp.data)
        }
        if (resp.errors) {
          setErrors(resp.errors as GraphQLError[])
        }
        setLoading(resp.loading)
      })
      .catch((err) => {
        setErrors(err.graphQLErrors as GraphQLError[])
        setLoading(false)
      })
  }

  return {
    getAddress,
    data,
    loading,
    errors,
  }
}
