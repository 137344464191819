import { ActionButton } from '@dominos/components/buttons'
import { AppleIcon } from '@dominos/res/images/icons/components/apple-icon'
import React from 'react'

import { useLoginTranslations } from '../use-login-translations'
import css from './apple.less'

interface AppleNativeProps {
  loading: boolean
  onSignIn: () => void
}
export const AppleNative = (props: AppleNativeProps) => {
  const t = useLoginTranslations()
  const [title] = t.getSocials('Apple')

  return (
    <ActionButton
      testID='apple-native-signin'
      loading={props.loading}
      onPress={props.onSignIn}
      containerStyle={styles.btn}
    >
      <div className={css.icon}>
        <AppleIcon fill={'#ffffff'} />
      </div>
      <label className={css.buttonLabel}>{title}</label>
    </ActionButton>
  )
}

const styles: { [k: string]: CommonViewStyle } = {
  btn: {
    width: '100%',
    height: '54px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '10px 0',
    cursor: 'pointer',
    backgroundColor: '#000000',
  },
}
