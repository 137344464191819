import { Config } from '../common/get-config'
import { LoadScriptProps } from '../common/load-script'
import { processScriptForOneTrust } from '../common/process-script-for-one-trust'

export const setupGTM = (applicationConfig: Config, loadScript: (props: LoadScriptProps) => void) => {
  if (applicationConfig.GTM_CONTAINER_ID !== '' && applicationConfig.GTM_CONTAINER_ID !== 'null') {
    window._GTM_ = 'gtm'
    loadScript({
      id: 'gtm',
      setupScriptFunc: (script) => {
        processScriptForOneTrust(script, '109', applicationConfig.ONE_TRUST_KEY)
        script.text = `(function (w, d, s, l, i) {
            w[l] = w[l] || []
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : ''
            j.async = true
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
            f.parentNode.insertBefore(j, f)
          })(window, document, 'script', 'dataLayer', '${applicationConfig.GTM_CONTAINER_ID}')`
        script.crossOrigin = 'anonymous'
      },
    })
  }
}
