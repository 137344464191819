import React from 'react'
import css from './product-upsell-card-view.less'
import { StaticSvgIcon } from '@dominos/res'
import { ProductUpsellCardViewProps } from './create-product-upsell-card-view-model'

const ProductUpsellCardView: React.FC<ProductUpsellCardViewProps> = ({
  testID,
  viewModel: { yesButtonText, bannerImageUrl, upsellName },
  onYesClicked,
  onNoClicked,
}) => (
  <div data-testid={testID} className={css.upsellContainer}>
    <div className={css.imgColumn}>
      <img src={bannerImageUrl} className={css.image} />
      <div data-testid='no-button' className={css.iconButtonContainerStyle} onClick={onNoClicked}>
        <StaticSvgIcon name={'crossCloseDark'} isUnstyled />
      </div>
    </div>
    <div className={css.titleContainer}>
      <div className={css.title}>{upsellName}</div>
      <div className={css.upgradeButton} onClick={onYesClicked}>
        <div data-testid='yes-button' className={css.upgradeButtonText}>
          {yesButtonText}
        </div>
      </div>
    </div>
  </div>
)
export { ProductUpsellCardView }
