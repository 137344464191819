import { getExtraSurcharge, getImages, getSelectedProductSize } from '@dominos/business/functions/menu'
import { isPortionMenuItem, Legends } from '@dominos/components'
import { ProductPricingDetails } from '@dominos/components/product/product-pricing-details'
import { useFeatures, usePricing } from '@dominos/hooks-and-hocs'
import classnames from 'classnames'
import React, { useEffect } from 'react'
import { useMenuStyles } from '../menu-card.styles'
import { MinimalistMenuCardProps } from './minimalist-menu-product-card.interfaces'
import css from './minimalist-menu-product-card.less'
import { getLegends } from '@dominos/components/product/product-card/functions/get-legends'
import { useProductMenuItemCustomisations } from '@dominos/components/menu/menu-section/menu-cards/menu-product-card/use-product-menu-item-customisations'
import { getMinimalistMenuComponentText } from './get-minimalist-menu-component-text'
import { useTranslation } from 'react-i18next'
import { getFormattedPrice } from '@dominos/business/functions/text'
import { getDefaultSizeAndEnergy } from '@dominos/business/functions/basket'
import { EnergyKJUnit } from '@dominos/components/product/product-pricing-details/partials/energy-kj-unit'

type SwapSetProductSize = Bff.Menu.old.SwapSetProductSize

export const MinimalistMenuProductCard = (props: MinimalistMenuCardProps) => {
  const { testID, item: menuItem, isFiltered, getBaseProductPrice, onClick } = props
  const styles = useMenuStyles(false)
  const [leftNavMenuEnabled, basesEnabled] = useFeatures('LeftNavMenu', 'menu-card-bases')
  const imageUrl = getImages(menuItem).menuImage.fullUrl
  const kj = getDefaultSizeAndEnergy(menuItem).energyKJ
  const { formattedPrice, formattedSalePrice, formattedPromoPrice, getPrice } = usePricing()
  const legends: MenuLegend[] = getLegends(menuItem)
  const { currentCustomisations, currentAvailableCustomisations } = useProductMenuItemCustomisations(menuItem, {
    basesEnabled,
  })
  const { t } = useTranslation('menu')

  useEffect(() => {
    getPrice({ item: menuItem })
  }, [])

  const extraSurcharge = (() => {
    if (!isPortionMenuItem(menuItem)) {
      const selectedSize = getSelectedProductSize(menuItem, currentCustomisations) as SwapSetProductSize

      return getExtraSurcharge(getBaseProductPrice, menuItem, selectedSize)
    }

    return 0
  })()

  return (
    <div
      data-testid={`${testID}.minimalist-menu-product-card`}
      className={classnames(css.card, leftNavMenuEnabled && css.leftNavMenu)}
      style={styles.card}
      role='button'
      onClick={() => onClick && onClick(menuItem)}
      aria-label={getMinimalistMenuComponentText(menuItem, currentAvailableCustomisations)} // This is for e2e testing
      data-product-code={menuItem.code}
    >
      <div>
        <div className={css.imageContainer}>
          <Legends testID={'menu-legend'} className={css.legends} legends={legends} />
          <img data-testid={`${testID}.image`} className={css.image} style={styles.image} src={imageUrl} alt='' />
        </div>
        <p data-testid={`${testID}.title`} className={css.title}>
          {menuItem.media?.name || ''}
        </p>
        <div className={css.separator} />
        <div data-testid={`${testID}.price-detail-container`} className={css.priceDetailContainer}>
          {isFiltered ? (
            <div className={css.minimalistAdd} data-testid={`${menuItem.media?.name}.minimalist-add`}>
              {`${t('addMinimalistMenu')}`}
              <div
                className={extraSurcharge ? css.minimalistVoucherPriceGreen : css.minimalistVoucherPriceGray}
                data-testid={`${menuItem.media?.name}.minimalistVoucherPrice`}
              >
                {`+${getFormattedPrice(extraSurcharge)}`}
              </div>
              <div className={css.energyInfoContainer}>
                <EnergyKJUnit kj={kj} testID={`${testID}.energy-info`} divider={'|'} showEnergyUnitDivider={true} />
              </div>
            </div>
          ) : (
            <ProductPricingDetails
              testID={`${testID}.subtitle`}
              kj={kj}
              price={formattedPrice}
              salePrice={formattedSalePrice}
              promoPrice={formattedPromoPrice}
              showPrice={true}
              showEnergyDividerForPromo={false}
            />
          )}
        </div>
      </div>
    </div>
  )
}
