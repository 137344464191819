import { ContainerLayout, isProductMenuItemNew } from '@dominos/components'
import { useBreakpoints, useFilterProductItems, useKiosk } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { ProductScene } from '@dominos/scenes'
import { navigate } from '@reach/router'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import React, { useEffect, useRef } from 'react'
import css from './product-card-animator.less'

const mobileNavHeight = '85px'
const desktopNavHeight = '97px'

export const ProductCardAnimator = (props: ProductCardAnimatorProps) => {
  const {
    testID,
    onDismiss,
    isProductVisible,
    currentProduct,
    isMenuHidden,
    currentLine,
    isEditing,
    swapping,
    addDirectlyToBasket,
  } = props
  const { isFiltered, voucherNo, voucherItemNo, getDimensionFilter } = useFilterProductItems()
  const dimensionFilter =
    currentProduct && !isProductMenuItemNew(currentProduct) ? getDimensionFilter(currentProduct.code) : undefined
  const ref = useRef<HTMLDivElement>(null)
  const { isMobile, isNotMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()

  const productCard: Variants = {
    defaultInitial: {
      position: 'fixed',
      left: 0,
      top: '100vh',
      opacity: 0,
      width: '100%',
      justifyContent: 'center',
      display: 'flex',
      zIndex: 4,
    },
    defaultEnter: {
      left: 0,
      top: isMenuHidden ? mobileNavHeight : desktopNavHeight,
      opacity: 1,
      transition: {
        duration: 0.5,
      },
    },
    defaultExit: {
      top: '100vh',
      display: 'fixed',
      opacity: 0,
      transition: {
        duration: 0.5,
      },
      left: 0,
    },
  }

  const transparent: Variants = {
    defaultInitial: {
      position: 'absolute',
      opacity: 0,
      justifySelf: 'center',
      top: 0,
    },
    defaultEnter: {
      top: 0,
      opacity: 1,
      transition: {
        duration: 0.45,
      },
    },
    defaultExit: {
      opacity: 0,
      transition: {
        duration: 2,
      },
    },
  }

  useEffect(() => {
    if ((isMobile || isKioskOrder) && isProductVisible) {
      navigate(
        `${NavigationConstants.product}/${
          currentProduct && !isProductMenuItemNew(currentProduct) ? currentProduct.code : undefined
        }`,
        {
          state: {
            currentProduct: currentProduct && !isProductMenuItemNew(currentProduct) ? currentProduct : undefined,
            currentLine,
            isEditing,
            redirectUrl: isFiltered ? `${NavigationConstants.basket}` : undefined,
            yPosition: window.pageYOffset,
            swapping,
            dimensionFilter,
            addDirectlyToBasket,
          },
        },
      )

      /**
       * If it's desktop and currently on product page (not modal), navigate back to menu
       */
    }
  }, [isMobile, isKioskOrder, isProductVisible])

  // Hides product overlay when navigating
  useEffect(
    () => () => {
      if (isProductVisible) {
        return onDismiss && onDismiss()
      }
    },
    [],
  )

  return (
    <AnimatePresence>
      {isProductVisible && isNotMobile && !isKioskOrder ? (
        <motion.div
          data-testid={`${testID}.animation-div`}
          variants={productCard}
          initial='defaultInitial'
          animate='defaultEnter'
          exit='defaultExit'
          ref={ref}
        >
          <ContainerLayout testID={`${testID}.product-layout`} isSidebarLayout>
            {addDirectlyToBasket ? null : (
              <motion.div
                data-testid={`${testID}.transparent-background`}
                role='transparent'
                className={css.transparent}
                style={{ cursor: 'pointer' }}
                variants={transparent}
                initial='defaultInitial'
                animate='defaultEnter'
                exit='defaultExit'
              />
            )}
            <ProductScene
              onDismiss={onDismiss}
              currentProduct={currentProduct}
              currentLine={currentLine}
              isEditing={isEditing}
              redirectUrl={isFiltered ? `${NavigationConstants.menu}` : undefined}
              voucherNo={voucherNo}
              voucherItemNo={voucherItemNo}
              swapping={swapping}
              dimensionFilter={dimensionFilter}
              addDirectlyToBasket={addDirectlyToBasket}
            />
          </ContainerLayout>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
