export const staticImages = {
  AU: {
    small: require('@dominos/res/assets/header-images/small/au.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/au.jpg'),
    large: require('@dominos/res/assets/header-images/large/au.jpg'),
  },
  BE: {
    small: require('@dominos/res/assets/header-images/small/be.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/be.jpg'),
    large: require('@dominos/res/assets/header-images/large/be.jpg'),
  },
  DE: {
    small: require('@dominos/res/assets/header-images/small/de.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/de.jpg'),
    large: require('@dominos/res/assets/header-images/large/de.jpg'),
  },
  DK: {
    small: require('@dominos/res/assets/header-images/small/dk.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/dk.jpg'),
    large: require('@dominos/res/assets/header-images/large/dk.jpg'),
  },
  FR: {
    small: require('@dominos/res/assets/header-images/small/fr.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/fr.jpg'),
    large: require('@dominos/res/assets/header-images/large/fr.jpg'),
  },
  JP: {
    small: require('@dominos/res/assets/header-images/small/jp.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/jp.jpg'),
    large: require('@dominos/res/assets/header-images/large/jp.jpg'),
  },
  LU: {
    small: require('@dominos/res/assets/header-images/small/lu.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/lu.jpg'),
    large: require('@dominos/res/assets/header-images/large/lu.jpg'),
  },
  MY: {
    small: require('@dominos/res/assets/header-images/small/my.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/my.jpg'),
    large: require('@dominos/res/assets/header-images/large/my.jpg'),
  },
  NL: {
    small: require('@dominos/res/assets/header-images/small/nl.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/nl.jpg'),
    large: require('@dominos/res/assets/header-images/large/nl.jpg'),
  },
  NZ: {
    small: require('@dominos/res/assets/header-images/small/nz.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/nz.jpg'),
    large: require('@dominos/res/assets/header-images/large/nz.jpg'),
  },
  SG: {
    small: require('@dominos/res/assets/header-images/small/sg.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/sg.jpg'),
    large: require('@dominos/res/assets/header-images/large/sg.jpg'),
  },
  default: {
    small: require('@dominos/res/assets/header-images/small/default.jpg'),
    medium: require('@dominos/res/assets/header-images/medium/default.jpg'),
    large: require('@dominos/res/assets/header-images/large/default.jpg'),
  },
}
