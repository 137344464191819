import { ApolloError } from '@apollo/client'
import { useEffect, useState } from 'react'

type Menu = Bff.Menu.old.Menu

export const useMenuResponse = ({
  loading,
  data,
  error,
}: {
  loading: boolean
  data?: MenuResponse
  error?: ApolloError
}) => {
  const [menuResponse, setMenuResponse] = useState<Menu | undefined>(undefined)

  useEffect(() => {
    if (!loading && !error && data) {
      setMenuResponse(data.menuTransitional)
    } else {
      setMenuResponse(undefined)
    }
  }, [loading, error])

  return menuResponse
}
