import React, { useEffect } from 'react'

export default (props: TermsAndConditionsProps) => {
  const { testID, onTermsChange } = props

  useEffect(() => {
    onTermsChange(true)
  }, [])

  return <div data-testid={`${testID}.kiosk`} />
}
