import { DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const ThreeCheese = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR

  return (
    <svg width='56px' height='33px' viewBox='0 0 56 33' key={color}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-48.000000, -1056.000000)'>
          <g transform='translate(6.000000, 309.000000)'>
            <g transform='translate(1.000000, 474.000000)'>
              <g transform='translate(45.783203, 275.562693)'>
                <path
                  d='M23.3398437,23.7692924 C36.042393,23.7692924 46.3398437,21.1088237 46.3398437,17.8269693 C46.3398437,14.5451149 36.042393,11.8846462 23.3398437,11.8846462 C10.6372945,11.8846462 0.33984375,14.5451149 0.33984375,17.8269693 C0.33984375,21.1088237 10.6372945,23.7692924 23.3398437,23.7692924 Z M23.3398438,22.4882495 C35.534291,22.4882495 45.4198438,20.1370868 45.4198438,17.2367806 C45.4198438,14.3364744 35.534291,11.9853117 23.3398438,11.9853117 C11.1453965,11.9853117 1.25984375,14.3364744 1.25984375,17.2367806 C1.25984375,20.1370868 11.1453965,22.4882495 23.3398438,22.4882495 Z'
                  stroke={selectedColor}
                  fill={selectedColor}
                />
                <ellipse fill='#FFFFFF' cx='23.3398438' cy='11.2944575' rx='22.08' ry='5.25146889' />
                <path
                  d='M23.3398437,17.8269693 C36.042393,17.8269693 46.3398437,15.1665006 46.3398437,11.8846462 C46.3398437,8.60279177 36.042393,5.9423231 23.3398437,5.9423231 C10.6372945,5.9423231 0.33984375,8.60279177 0.33984375,11.8846462 C0.33984375,15.1665006 10.6372945,17.8269693 23.3398437,17.8269693 Z M23.3398438,16.5459264 C35.534291,16.5459264 45.4198438,14.1947637 45.4198438,11.2944575 C45.4198438,8.39415134 35.534291,6.04298863 23.3398438,6.04298863 C11.1453965,6.04298863 1.25984375,8.39415134 1.25984375,11.2944575 C1.25984375,14.1947637 11.1453965,16.5459264 23.3398438,16.5459264 Z'
                  stroke={selectedColor}
                  fill={selectedColor}
                />
                <ellipse fill='#FFFFFF' cx='23.3398438' cy='5.25146889' rx='22.08' ry='5.25146889' />
                <path
                  d='M23,11.8846462 C35.7025492,11.8846462 46,9.22417752 46,5.9423231 C46,2.66046867 35.7025492,3.73034936e-14 23,3.73034936e-14 C10.2974508,3.73034936e-14 0,2.66046867 0,5.9423231 C0,9.22417752 10.2974508,11.8846462 23,11.8846462 Z M23,10.6036033 C35.1944473,10.6036033 45.08,8.2524406 45.08,5.35213442 C45.08,2.45182824 35.1944473,0.100665533 23,0.100665533 C10.8055527,0.100665533 0.92,2.45182824 0.92,5.35213442 C0.92,8.2524406 10.8055527,10.6036033 23,10.6036033 Z'
                  stroke={selectedColor}
                  fill={selectedColor}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
