import React, { useState } from 'react'
import { FancyTextField } from '..'
import css from './dropdown-field.less'
import { StaticSvgIcon } from '@dominos/res'
import { DropdownFieldProps, DropDownItem } from './dropdown-field.interface'
import { useValidation } from '@dominos/business/functions'

const getMaxNumberDropDownItems = (dropDownItems: DropDownItem[], maxNumberDropDownItemDisplay: number | undefined) => {
  if (!dropDownItems || !maxNumberDropDownItemDisplay || dropDownItems.length <= maxNumberDropDownItemDisplay)
    return dropDownItems

  return dropDownItems.slice(0, maxNumberDropDownItemDisplay)
}

export const DropdownField = ({
  dropDownItemSelectedCallBack,
  fetchDropdownItems,
  dropDownItems,
  maxNumberDropDownItemDisplay,
  minNumberCharactersFetch,
  placeholder,
  validationRules,
  testID,
  disabled,
  prefix,
  tabIndex,
  style,
  showClearInputButton,
}: DropdownFieldProps) => {
  const { isNumeric, equalLength } = useValidation()
  const [displayDropDownItems, setDisplayDropDownItems] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dropDownItemSelected, setDropDownItemSelected] = useState<DropDownItem | undefined>(undefined)
  const [currentSearchValue, setCurrentSearchValue] = useState<string | undefined>('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const onChange = (newValue: string) => {
    const errorMessage = getErrorMessage(newValue)
    setErrorMessage(errorMessage)
    setDisplayDropDownItems(false)
    setDropDownItemSelected(undefined)
    setCurrentSearchValue(newValue)
    setIsLoading(false)
    dropDownItemSelectedCallBack(undefined)

    if (!errorMessage && (!minNumberCharactersFetch || newValue?.length >= minNumberCharactersFetch)) {
      fetchDropdownItems(newValue)
      setDisplayDropDownItems(true)
      setIsLoading(true)
    }
  }

  const onItemSelected = (dropdownValue: DropDownItem) => () => {
    setDisplayDropDownItems(false)
    setIsLoading(false)
    dropDownItemSelectedCallBack(dropdownValue)
  }

  const getErrorMessage = (value: string) => {
    if (!validationRules) return undefined

    if (validationRules.numericOnly) {
      const errorMessage = isNumeric(value)
      if (errorMessage) return errorMessage
    }

    if (validationRules.numberCharacters) {
      const errorMessage = equalLength(validationRules.numberCharacters)(value)
      if (errorMessage) return errorMessage
    }

    return undefined
  }

  const getDropDownElements = (items: DropDownItem[] | undefined) => {
    if (!displayDropDownItems || dropDownItemSelected || !items) return null

    if (isLoading) setIsLoading(false)

    const maxDropDownItemsDisplay = getMaxNumberDropDownItems(items, maxNumberDropDownItemDisplay)

    return maxDropDownItemsDisplay.map((dropdownItem, index) => (
      <div
        key={index}
        data-testid={`${testID}.dropdown-item`}
        role='listitem'
        className={css.dropDownItem}
        onClick={onItemSelected(dropdownItem)}
        style={dropdownItem.style}
      >
        {dropdownItem.icon && (
          <div data-testid={`${testID}.dropdown-item.icon.${dropdownItem.icon}`} className={css.dropDownItemIcon}>
            <StaticSvgIcon name={dropdownItem.icon} fill={dropdownItem.iconFill} />
          </div>
        )}
        <div data-testid={`${testID}.dropdown-item.text`} className={css.dropDownItemText}>
          {dropdownItem.displayValue}
        </div>
      </div>
    ))
  }

  return (
    <div data-testid={testID}>
      <FancyTextField
        testID={testID}
        disabled={disabled}
        errorMessage={errorMessage}
        isLoading={isLoading}
        onChange={onChange}
        placeholder={placeholder}
        prefix={prefix}
        style={style}
        tabIndex={tabIndex}
        value={currentSearchValue}
        hasClearInputButton={showClearInputButton}
      >
        {getDropDownElements(dropDownItems)}
      </FancyTextField>
    </div>
  )
}
