import React from 'react'
import { DividerProps } from './divider.interface'

import css from './divider.less'

export const Divider = ({ testID, label = null, children }: React.PropsWithChildren<DividerProps>) => (
  <div data-testid={testID} className={css.divider}>
    {children ?? label}
  </div>
)
