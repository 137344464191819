import React, { useMemo } from 'react'

import { getFormattedPrice } from '@dominos/business/functions/text'

import { useApplicationSettings } from '@dominos/hooks-and-hocs'
import { DEFAULT_SLATE_COLOUR, FONT_WEIGHT, HIGHLIGHT_COLOUR } from '../style-constants'
import css from './crust-icon.less'
import { ANZCrustIcons } from './crust-icons/ANZ/index'

export const CrustIcon = ({ code, name, price, selected, image }: CrustIconProps) => {
  const { countryCode } = useApplicationSettings()
  const isANZCountry = useMemo(() => ['AU', 'NZ'].includes(countryCode || ''), [countryCode])
  const color = selected ? HIGHLIGHT_COLOUR : DEFAULT_SLATE_COLOUR

  let CrustSvg: React.ComponentType<CrustSVGProps> | null = null

  if (isANZCountry) {
    CrustSvg = ANZCrustIcons[code]
  }

  return (
    <div className={css.container}>
      <div className={css.iconContainer} data-testid={`crust.image.${name}`}>
        {isANZCountry && CrustSvg ? (
          <CrustSvg color={color} />
        ) : (
          <img alt={`${name} Icon`} className={css.icon} style={{ borderColor: color }} src={image} />
        )}
      </div>
      <p
        data-testid={`crust.name.${name}`}
        className={css.name}
        style={{ color, fontWeight: selected ? FONT_WEIGHT : 'normal' }}
      >
        {name}
      </p>
      {price && price !== 0 ? (
        <p data-testid={`crust.price.${name}`} className={css.price} style={{ color }}>
          {`${price > 0 ? '+' : '-'}${getFormattedPrice(Math.abs(price))}`}
        </p>
      ) : null}
    </div>
  )
}
