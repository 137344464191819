import { getCountryConfig, useValidation } from '@dominos/business/functions'

const DEFAULT_MAX_CHARACTERS = 25

export const useCharacterLimit = (): number => {
  const { DELIVERY_SEARCH_AVG_CHARACTERS } = getCountryConfig()
  const { isNumeric } = useValidation()

  if (DELIVERY_SEARCH_AVG_CHARACTERS === 'null' || !isNumeric(DELIVERY_SEARCH_AVG_CHARACTERS)) {
    return DEFAULT_MAX_CHARACTERS
  }

  return parseInt(DELIVERY_SEARCH_AVG_CHARACTERS)
}
