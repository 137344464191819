import { isProductMenuItemNew } from '@dominos/components'
type Menu = Bff.Menu.old.Menu

/**
 * Filters menu pages to those containing one or more of the
 * product items in the products list.
 *
 * @param menu Menu containing a list of pages with product menu items
 * @param products Collection of product menu items
 */
export const getPagesWithProducts = (menu: Menu, productCodes: string[] = []) =>
  menu.pages.filter(
    (page) => page.sections && page.sections.find((section) => section.items.find(itemMatchingProducts(productCodes))),
  )

/**
 * Thunk which returns a recursive search function for finding
 * product menu items matching any of the products in the
 * provided collection
 *
 * @param products List of products to match
 */
const itemMatchingProducts =
  (productCodes: string[]) =>
  (menuItem: MenuItemDependents): boolean | undefined => {
    if (menuItem.type === 'Variety') {
      return menuItem.items.some(itemMatchingProducts(productCodes))
    }

    return productCodes.some((productCode) => !isProductMenuItemNew(menuItem) && productCode === menuItem.code)
  }
