import React from 'react'

export const PuffPastry = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : '#454545'

  return (
    <svg width={'51px'} height={'49px'} viewBox='0 0 279 279'>
      <defs>
        <clipPath id='clippath'>
          <circle fill='none' cx='139.5' cy='139.5' r='118.2' />
        </clipPath>
      </defs>
      <g>
        <g id='Layer_1'>
          <circle
            fill='none'
            stroke={selectedColor}
            strokeMiterlimit={10}
            strokeWidth={18}
            cx='139.5'
            cy='139.5'
            r='118.2'
          />
          <g clipPath={'url(#clippath)'}>
            <path
              fill={selectedColor}
              d='M102.5,174.5l116.3.4c7.2,0,20.1-19.5,20.1-26.2h0c0-8.1-4.6-15.5-11.8-19.1h0c0-.5,0-1,0-1.5,0-6.2-2.7-12.2-7.4-16.2s-11-5.9-17.1-5c-2.8-8.9-11.1-15-20.4-15-2.6,0-5.3.5-7.7,1.5-7.6-5.8-18.1-5.9-25.8-.1-5.5-2-11.6-1.7-16.8.9-5.2,2.6-9.2,7.2-11,12.8-6.2-.9-12.4.9-17.1,5-4.7,4.1-7.4,10-7.4,16.2s0,1,0,1.5c-7.2,3.6-11.8,11-11.8,19.1,0,.4,0,.8.1,1.3,1.8.1,3.9.1,5.9-.3,0-.4-.1-.7-.1-1,0-6.5,4.1-12.3,10.2-14.6h0c.1,0,9.9-3.6,13,1.9.4.7,1,1.2,1.8,1.4.8.2,1.6.1,2.3-.3.7-.4,1.2-1,1.4-1.8.2-.8,0-1.6-.3-2.3-3.9-6.8-11.8-6.4-16.5-5.5.1-4.8,2.5-9.3,6.4-12.1,3.9-2.8,8.9-3.7,13.6-2.3.3,0,.5.1.8.1,1.5.3,8.7,1.9,8.8,6.7,0,1.6,1.3,2.9,2.9,2.9h0c.8,0,1.5-.3,2.1-.9.5-.6.8-1.3.8-2.1-.2-6.8-6-10.3-11-11.8,1.4-4.1,4.5-7.4,8.5-9.1,4-1.7,8.5-1.6,12.4.1,2.4,1.7,8.1,6.9,9.2,12.5h0c.1.8.6,1.4,1.3,1.9.6.4,1.4.6,2.2.4.8-.2,1.4-.6,1.9-1.3.4-.7.6-1.5.4-2.2-1.4-5.4-4.5-10.2-8.7-13.8,5.7-3,12.7-2.3,17.6,1.9,2.1,3.3,3,7.1,2.6,11-.2.8,0,1.6.3,2.3.4.7,1.1,1.2,1.9,1.3.8.2,1.6,0,2.3-.4.7-.4,1.1-1.1,1.3-1.9.7-4.3,0-8.7-1.9-12.7,4.1-1.1,8.6-.5,12.2,1.8s6.2,5.9,7,10.1c-1,3.3-3.6,5.9-6.9,6.9-1.6.4-2.5,2-2.2,3.6.4,1.6,2,2.5,3.6,2.2,5.1-1.3,9.1-5.1,10.9-10,4.6-.9,9.3.3,12.9,3.2,3.6,3,5.7,7.4,5.7,12,0,.6,0,1.1-.1,1.7-4.8,3.8-10.8,5.7-16.9,5.5-.8,0-1.6.1-2.2.6-.6.5-1,1.2-1.1,2,0,.8.1,1.6.6,2.2.5.6,1.2,1,2,1.1,7.4.4,14.7-1.9,20.6-6.4,5.3,2.6,8.7,8,8.7,13.9,0,4.8-11,19.6-14.2,20.4h0s-15.2,0-15.2,0c.3-.8.4-1.6.4-2.5v-.4c0-15.7-19.1-28.4-42.5-28.4s-42.5,12.7-42.5,28.4v.5c0,.8.1,1.7.4,2.5l-18.3,1.5M198.1,166.5c0,1.3-1.5,2.5-3.3,2.5h-66.7c-1.8,0-3.3-1.1-3.3-2.5v-.5c0-12.4,16.4-22.5,36.6-22.5s36.6,10.1,36.6,22.6v.4Z'
            />
            <path fill={selectedColor} d='M87,146H-228.4c-2.6,0-2.6,4,0,4H87c2.6,0,2.6-4,0-4h0Z' />
            <path
              fill={selectedColor}
              d='M102.5,170.5H-172c-25.8,0-51.6,0-77.4,0s-2.6,4,0,4H102.5c2.6,0,2.6-4,0-4h0Z'
            />
            <path
              fill={selectedColor}
              d='M75.3,141.5c4.5.2,9-.2,13.5,0s2-.9,2-2-.9-1.9-2-2c-4.5-.2-9,.2-13.5,0s-2,.9-2,2,.9,1.9,2,2h0Z'
            />
            <ellipse
              fill='none'
              strokeWidth={'5px'}
              stroke={selectedColor}
              strokeMiterlimit={10}
              cx='33.1'
              cy='136.5'
              rx='48'
              ry='6.3'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
