export const mobileNumber = {
  length: {
    AU: 10,
    BE: {
      min: 9,
      max: 13,
    },
    DE: {
      min: 4,
      max: 20,
    },
    DK: {
      min: 8,
      max: 13,
    },
    FR: {
      min: 10,
      max: 13,
    },
    LU: {
      min: 9,
      max: 13,
    },
    NL: {
      min: 10,
      max: 13,
    },
    NZ: {
      min: 9,
      max: 11,
    },
    SG: 8,
    MY: {
      min: 9,
      max: 14,
    },
  },
}
