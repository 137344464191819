import {
  ChangePasswordPayload,
  CustomerAddressForDisplay,
  CustomerAuthAndPersonalData,
  CustomerAuthError,
  EmailPasswordPayload,
} from '@dominos/interfaces'
import { IDeliveryAddress } from 'olo-feature-address'
import { createAsyncAction, createCustomAction, createStandardAction } from 'typesafe-actions'

export const loginUsingEmailPassword = createAsyncAction(
  'LOGINUSINGEMAILPASSWORD:REQUEST',
  'LOGINUSINGEMAILPASSWORD:SUCCESS',
  'LOGINUSINGEMAILPASSWORD:ERROR',
)<EmailPasswordPayload, CustomerAuthData, CustomerAuthError | string>()

export const loginUsingPayPal = createAsyncAction(
  'LOGINUSINGPAYPAL:REQUEST',
  'LOGINUSINGPAYPAL:SUCCESS',
  'LOGINUSINGPAYPAL:ERROR',
)<void, CustomerAuthAndPersonalData, CustomerAuthError | string>()

export const loginUsingGoogle = createAsyncAction(
  'LOGINUSINGGOOGLE:REQUEST',
  'LOGINUSINGGOOGLE:SUCCESS',
  'LOGINUSINGOOGLE:ERROR',
)<void, CustomerAuthAndPersonalData, CustomerAuthError | string>()

export const loginUsingFacebook = createAsyncAction(
  'LOGINUSINGFACEBOOK:REQUEST',
  'LOGINUSINGFACEBOOK:SUCCESS',
  'LOGINUSINGFACEBOOK:ERROR',
)<void, CustomerAuthAndPersonalData, CustomerAuthError | string>()

export const clearLoginFailed = createStandardAction('LOGIN:FAILED:CLEAR')()

export const logout = createCustomAction('USER:LOGOUT', (type) => (email?: string, customerId?: string) => ({
  type,
  email,
  customerId,
}))

export const joinRewards = createStandardAction('REWARDS:JOIN')()

export const addCustomerAddressToOrder = createCustomAction(
  'CUSTOMER:ADDCUSTOMERADDRESSTOORDER',
  (type) => (address: StoredCustomerAddress) => ({
    type,
    address,
  }),
)

export const saveCustomerAddress = createCustomAction(
  'CUSTOMER:SAVECUSTOMERADDRESS',
  (type) => (address: StoredCustomerAddress) => ({
    type,
    address,
  }),
)

export const deleteCustomerAddress = createCustomAction(
  'CUSTOMER:DELETECUSTOMERADDRESS',
  (type) => (addressToDelete: CustomerAddressForDisplay) => ({
    type,
    addressToDelete,
  }),
)

export const setDeliveryAddresses = createCustomAction(
  'CUSTOMER:SETDELIVERYADDRESSES',
  (type) => (deliveryAddresses: IDeliveryAddress[]) => ({
    type,
    deliveryAddresses,
  }),
)

export const addSearchAddress = createCustomAction(
  'CUSTOMER:ADDSEARCHADDRESS',
  (type) => (address: DeliveryAddress) => ({
    type,
    address,
  }),
)

export const saveSearchState = createCustomAction(
  'CUSTOMER:SAVESEARCHSTATE',
  (type) => (searchInput?: string, searchLocation?: StoreGeo) => ({
    type,
    searchInput,
    searchLocation,
  }),
)

export const savePickupStoreLocationId = createCustomAction(
  'CUSTOMER:SAVEPICKUPSTORELOCATIONID',
  (type) => (locationId: string) => ({
    type,
    locationId,
  }),
)

export const resetAutoCompleteScenario = createStandardAction('CUSTOMER:RESETAUTOCOMPLETESCENARIO')()

export const createUserWithEmailAndPassword = createAsyncAction(
  'CREATEUSERWITHEMAILANDPASSWORD:REQUEST',
  'CREATEUSERWITHEMAILANDPASSWORD:SUCCESS',
  'CREATEUSERWITHEMAILANDPASSWORD:ERROR',
)<NewCustomerSignUp, CustomerAuthData, CustomerAuthError | string>()

export const changeDetailsClear = createStandardAction('CHANGEDETAILS:CLEAR')()

export const changePassword = createAsyncAction(
  'CHANGEPASSWORD:REQUEST',
  'CHANGEPASSWORD:SUCCESS',
  'CHANGEPASSWORD:ERROR',
)<ChangePasswordPayload, undefined, CustomerAuthError>()

export const changePhoneNumber = createAsyncAction(
  'CHANGEPHONENUMBER:REQUEST',
  'CHANGEPHONENUMBER:SUCCESS',
  'CHANGEPHONENUMBER:ERROR',
)<void, undefined, void>()

export const changeName = createAsyncAction('CHANGENAME:REQUEST', 'CHANGENAME:SUCCESS', 'CHANGENAME:ERROR')<
  void,
  undefined,
  void
>()

export const deleteUserAccount = createAsyncAction(
  'DELETEUSERACCOUNT:REQUEST',
  'DELETEUSERACCOUNT:SUCCESS',
  'DELETEUSERACCOUNT:ERROR',
)<string, undefined, CustomerAuthError | string>()

export const updateNewVouchersCount = createStandardAction('CUSTOMER:UPDATE-NEW-VOUCHERS-COUNT')<number>()

export const viewedProductOnMobile = createStandardAction('PRODUCT:VIEWED-ON-MOBILE')<boolean>()
