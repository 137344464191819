import { isPortionMenuItem, isProductMenuItem } from '@dominos/components'

const extractLegendMediaFromProduct = (
  product: ProductMenuItem | PortionMenuItem,
  mediaDictionary: LegendMediaDictionary,
) => {
  product?.legends?.forEach((legend) => {
    if (legend?.code) {
      mediaDictionary[`${legend.code}`] = {
        name: { value: legend.code },
        icon: { uri: legend.image?.url ?? '', altText: '' },
        description: { value: legend.text ?? '' },
      }
    }
  })
}

export const flattenLegendMedia = (menuItems: MenuItemDependentsDictionary | null): LegendMediaDictionary => {
  const legendMediaDictionary: LegendMediaDictionary = {}

  if (menuItems) {
    Object.entries(menuItems).forEach(([, value]) => {
      const filteredProduct = value && (isProductMenuItem(value) || isPortionMenuItem(value)) ? value : null
      if (filteredProduct) {
        extractLegendMediaFromProduct(filteredProduct, legendMediaDictionary)
      }
    })
  }

  return legendMediaDictionary
}
