import {
  CustomerAccountContainer,
  NavigationBackButton,
  NavigationLogo,
  ResetOrderButton,
  SimpleNavigationTitleProps,
} from '@dominos/components'
import { useBreakpoints, useFeatures, useKiosk } from '@dominos/hooks-and-hocs'
import React from 'react'
import css from './simple-navigation-title.less'

export const SimpleNavigationTitle = (props: SimpleNavigationTitleProps) => {
  const [customerEnabled] = useFeatures('customers')
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isMobileLayout = isMobile || isKioskOrder || props.forceMobileLayout

  return (
    <header data-testid='simple-navigation-title' className={css.container}>
      {props.showBackButton && (
        <NavigationBackButton
          backBackground={isMobileLayout ? props.backBackground : true}
          backScreen={props.backScreen}
          showInNavigationBar={isMobileLayout}
          onBack={props.onBack}
        />
      )}

      <NavigationLogo
        showBackButton={props.showBackButton}
        showMobileLogo={isMobileLayout}
        evenLogoWidth={props.evenLogoWidth}
      />

      {props.showAccount && customerEnabled ? <CustomerAccountContainer /> : <div className={css.empty} />}

      {isKioskOrder && (
        <div>
          <ResetOrderButton />
        </div>
      )}
    </header>
  )
}
