import { useCallback, useMemo, useState } from 'react'
import { StateController, StateData, StateMachineConfig } from './state-machine.interface'

export const useStateMachine = <T extends string>({ initial, states }: StateMachineConfig<T>): StateController<T> => {
  const [current, setCurrent] = useState<T>(initial)
  const [data, setData] = useState<StateData>({})

  const currentStep = useMemo(() => states[current], [states, current])

  const hasAction = useCallback((name: string) => !!currentStep.on[name], [currentStep])

  const triggerAction = useCallback(
    (name: string, data?: StateData) => {
      if (!currentStep.on[name]) {
        throw new Error(`Action "${name}" does not exist on state "${current}"`)
      }

      if (data) {
        setData(data)
      }

      setCurrent(currentStep.on[name].target)
    },
    [currentStep, current],
  )

  const reset = (key: T = initial) => setCurrent(key)

  return {
    current,
    step: currentStep,
    data,
    setData,
    hasAction,
    triggerAction,
    reset,
  }
}
