import React, { FunctionComponent } from 'react'

import { calcWebFontWeight } from '@dominos/business/functions/text/text-functions'

import css from './hyperlink.less'

export const Hyperlink: FunctionComponent<HyperlinkProps> = ({
  testID,
  weight,
  style,
  text,
  link,
  notOpenInNewTab = false,
}) => {
  const styles = {
    ...style,
    fontWeight: calcWebFontWeight(weight),
  }

  return (
    <a
      data-testid={testID}
      className={css.link}
      style={styles}
      href={link}
      rel={'noreferrer'}
      target={notOpenInNewTab ? '' : '_blank'}
    >
      {text}
    </a>
  )
}
