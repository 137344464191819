import React from 'react'

export const APP_START_FROM_WEB = 'APP_START_FROM_WEB'
export const APP_START_FROM_NATIVE_APP = 'APP_START_FROM_NATIVE_APP'

export interface CheckoutContextProps {
  appStartFrom: string
}

export const CheckoutContext = React.createContext<CheckoutContextProps>({
  appStartFrom: APP_START_FROM_WEB,
})
