import React, { useEffect, useState } from 'react'
import renderClearCustomerPortal, { EnvironmentType, ICreateIframeOptions } from 'ct-myinvoice'
import { useClearTaxAuthToken } from './use-cleartax-auth-token'
import { useReport } from '@dominos/hooks-and-hocs'

const defaultConfig = {
  width: '100%',
  height: '100%',
  title: 'MY Electronic Invoice',
  styles: { width: '100%', height: '100%', border: 'none' },
  environment: 'sandbox',
}

export const useClearTaxCustomerPortalIframe = (iframeContainer: React.RefObject<HTMLDivElement>) => {
  const { authToken, supplierTin, environment, validUntil, error } = useClearTaxAuthToken()
  const { reportIframeStatus } = useReport()
  const [hasError, setHasError] = useState(false)

  const messageHandler = (event: MessageEvent) => {
    if (event.data.status) {
      reportIframeStatus(event.data.status)
      event.data.status !== 200 && setHasError(true)
    }
  }

  useEffect(() => {
    if (error) {
      setHasError(true)
    }
    if (!authToken || !supplierTin || !environment || !validUntil || !iframeContainer) return
    window.addEventListener('message', messageHandler)
    try {
      const portal = renderClearCustomerPortal({
        ...defaultConfig,
        token: authToken,
        tin: window.btoa(supplierTin),
        environment: environment as EnvironmentType,
      } as ICreateIframeOptions)
      if (iframeContainer.current?.firstChild) {
        return
      }
      iframeContainer.current?.appendChild(portal)
    } catch (clearTaxError) {
      const { message } = clearTaxError as Error
      reportIframeStatus(500, message)
      setHasError(true)
    }

    return () => {
      window.removeEventListener('message', messageHandler)
    }
  }, [authToken, supplierTin, environment, validUntil, error])

  return {
    iframeContainer,
    hasError,
  }
}
