import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 20,
  height: 20,
}

const ExclamationIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#000000'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 17 17`} className={props.className}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(0.000000, 1.000000)' fill={fill}>
          <path
            d='M8.078125,16.234375 C12.5078125,16.234375 16.1640625,12.5625 16.1640625,8.1171875 C16.1640625,3.671875 12.5,0 8.0703125,0 C3.6484375,0 0,3.671875 0,8.1171875 C0,12.5625 3.65625,16.234375 8.078125,16.234375 Z M8.078125,14.5234375 C4.4921875,14.5234375 1.625,11.671875 1.625,8.1171875 C1.625,4.5703125 4.4921875,1.7109375 8.0703125,1.7109375 C11.65625,1.7109375 14.5390625,4.5703125 14.5468908,8.1171875 C14.5546875,11.671875 11.6640625,14.5234375 8.078125,14.5234375 Z M8.0703125,9.375 C8.5,9.375 8.75,9.125 8.7578125,8.6484375 L8.875,5.015625 C8.890625,4.53125 8.546875,4.1953125 8.0625,4.1953125 C7.578125,4.1953125 7.2421875,4.53125 7.2578125,5.0078125 L7.3671875,8.65625 C7.3828125,9.1171875 7.6328125,9.375 8.0703125,9.375 Z M8.0703125,11.984375 C8.578125,11.984375 8.9921875,11.6171875 8.9921875,11.109375 C8.9921875,10.59375 8.5859375,10.234375 8.0703125,10.234375 C7.5625,10.234375 7.1484375,10.6015625 7.1484375,11.109375 C7.1484375,11.609375 7.5703125,11.984375 8.0703125,11.984375 Z'
            id='Shape'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export { ExclamationIcon }
