import { CollapsableTitledCard } from '@dominos/components'
import { useCustomer } from '@dominos/hooks-and-hocs'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { RewardEnrollment } from './enrollment'
import { LoyaltyHistory } from './loyalty-history'
import css from './my-rewards.less'
import { LoyaltyBalanceCard } from '../../loyalty/loyaltyTwo/LoyaltyBalanceCard'

export const MyRewards = ({ testID = 'my-rewards' }: RouteComponentProps<BaseProps>) => {
  const { isLoyaltyEnrolled, getCustomer } = useCustomer()
  const { t } = useTranslation('loyalty')

  return (
    <div data-testid={testID} className={css.wrapper}>
      {isLoyaltyEnrolled ? (
        <>
          <LoyaltyBalanceCard testID={`${testID}.balance`} />
          <CollapsableTitledCard
            testID={`${testID}.t&c`}
            primaryTitle={t('RewardsAboutTitle', { defaultValue: `About Domino's Club` })}
            style={{ padding: 10 }}
            startCollapsed
          >
            {t('RewardsTermsAndConditions', {
              defaultValue: ``,
            })}
          </CollapsableTitledCard>
          <LoyaltyHistory testID={`${testID}.history`} />
        </>
      ) : (
        <RewardEnrollment testID={`${testID}.enrollment`} onRefresh={getCustomer} />
      )}
    </div>
  )
}
