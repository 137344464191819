import React, { useState } from 'react'
import { EmbeddedCampaign } from './embedded-campaign'
import { Modal } from '@dominos/components'
import { useDevToggles } from '@dominos/hooks-and-hocs'
import { ButtonActionType, ICampaign } from './swrve-campaign.interface'

export const SwrveCampaignPopup = ({ swrveCampaign, onClose }: { swrveCampaign: ICampaign; onClose: () => void }) => {
  const { shouldDisplay, campaign } = swrveCampaign
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0)
  const { isEnabled } = useDevToggles()
  const isLastScreen = campaign && currentScreenIndex === campaign?.CampaignSteps.length - 1

  const gotToNextScreen = () => {
    setCurrentScreenIndex((i) => {
      if (isLastScreen) {
        return i
      }

      return i + 1
    })
  }

  const handleCampaignClose = () => {
    onClose()
    setCurrentScreenIndex(0)
  }

  const handleCampaignButtonClick = (buttonAction: ButtonActionType) => {
    if (buttonAction === 'CONTINUE_CONVERSATION') {
      return gotToNextScreen()
    }
    if (buttonAction === 'END_CONVERSATION') {
      return handleCampaignClose()
    }
  }

  const shouldShow =
    shouldDisplay && isEnabled['swrve-embedded-campaign-popup'] && campaign && campaign.CampaignSteps?.length > 0

  if (shouldShow) {
    return (
      <Modal testID='swrve-campaign-popup' onModalClose={handleCampaignClose}>
        <EmbeddedCampaign
          campaign={campaign.CampaignSteps[currentScreenIndex]}
          onCampaignButtonsClicked={handleCampaignButtonClick}
          testID='embedded-campaign'
        />
      </Modal>
    )
  }

  return null
}
