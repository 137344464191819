export const getBasketLineDataForPortion = (
  recipe?: BasketLinePortion,
  defaultSize?: string | null,
): BasketLineData => ({
  productCode: recipe?.productCode,
  toppings: recipe && recipe.toppings,
  base: undefined,
  sauce: recipe && recipe.sauce,
  options: undefined,
  sizeCode: defaultSize ?? undefined,
})
