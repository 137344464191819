import { useState } from 'react'
import { OptionChange } from '../../business/functions'

type ProductRule = Bff.Menu.old.ProductRule
type RecipeIngredient = Bff.Menu.old.RecipeIngredient

export const useOptionChanges = (
  optionsRule: ProductRule | undefined,
  recipeOptions: RecipeIngredient[] | null | undefined,
  optionChangesFromBasket: OptionChange[],
): [boolean, (index: number | undefined, code: string) => void] => {
  const recipeOptionCount = (recipeOptions || []).reduce((total, option) => total + option.quantity, 0)
  const addOptionsCount = optionChangesFromBasket.filter((option) => option.action === 'Add').length
  const removeOptionsCount = optionChangesFromBasket.filter((option) => option.action === 'Remove').length

  const initaliseOptionsChanges = optionsRule ? Array(optionsRule.max).fill('no-option-selected') : []

  const setOptionsChangesWithSelectedOptions = initaliseOptionsChanges.fill(
    'option-selected',
    0,
    Math.max(recipeOptionCount - removeOptionsCount + addOptionsCount, 0),
  )

  const [optionChanges, setOptionChanges] = useState(setOptionsChangesWithSelectedOptions)

  const setOptionAsSelected = (index: number | undefined, code: string): void => {
    const newOptionChanges = [...optionChanges]

    if (index || index == 0) newOptionChanges[index] = code

    setOptionChanges(newOptionChanges)
  }

  const minOptionChanges =
    (optionsRule &&
      optionChanges.filter((option: string) => option !== 'no-option-selected').length >= optionsRule.min) ??
    true

  return [minOptionChanges, setOptionAsSelected]
}
