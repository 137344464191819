type Ingredient = Bff.Menu.old.Ingredient

/**
 * Searches the `recipe` and `swaps` for an ingredient
 * matching the provided ingredient `code`, or returns
 * `undefined` when not found.
 *
 * @param currentSize Current product size
 * @param code Ingredient code to find
 */
export const getIngredientWithCode = (
  currentSize: ProductSize | undefined,
  code: string,
): { ingredient: Ingredient | undefined; isRecipeIngredient: boolean } => {
  const recipe = currentSize?.recipe?.toppings?.find(({ ingredient }) => ingredient.code === code)
  const topping = currentSize?.swaps?.toppings?.ingredients.find((ingredient) => ingredient.code === code)

  return { ingredient: topping || recipe?.ingredient, isRecipeIngredient: !!recipe }
}
