import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React, { useMemo } from 'react'
import { rewardsBadgeGlyphs } from './glyphs'

export const PizzaGaugeBadge = ({ count = 0, size, ...props }: { count: number; size?: number } & SvgIconProps) => {
  const resourceIndex = useMemo(() => Math.max(1, Math.min(count, rewardsBadgeGlyphs.length)) - 1, [count])
  const Badge = rewardsBadgeGlyphs[resourceIndex]

  return <Badge {...props} width={size} height={size} />
}
