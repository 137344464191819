/**
 * Determines if the specified provider and payment
 * method should wait for payment confirmation or
 * skip to the next step.
 * @param provider Payment provider
 * @param paymentMethod Payment method
 */
export const shouldAllowImmediatePayment = (
  provider: BffContext.PaymentProviders,
  paymentMethod: BffContext.PaymentMethods,
) => {
  switch (provider) {
    case 'Adyen':
      return paymentMethod === 'Cash'
    case 'Cash':
      return paymentMethod === 'MobileEftpos' || paymentMethod === 'Cash' || paymentMethod === 'CreditCardLater'
    case 'Internal':
    case 'PayPal':
    case 'GMO':
      return true
    default:
      return false
  }
}
