import { getFullUrl, getProductDefaultSize } from '@dominos/business/functions/menu'

export const getProductData = (item: ProductMenuItem) => {
  const defaultSize = getProductDefaultSize(item)
  const imageUrl = item.media && item.media.applicationImage && getFullUrl(item.media.applicationImage.url)
  const title = item.media && item.media.name
  const price = defaultSize && defaultSize.price
  const kj = defaultSize && defaultSize.nutritionals?.energyKJ

  return {
    title,
    imageUrl,
    price,
    kj,
  }
}
