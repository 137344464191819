import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const PizzaRollsIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <defs>
        <polygon id='path-1' points='0 0 31.9997 0 31.9997 29.9997 0 29.9997' />
      </defs>
      <g id='Pizza-Role' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-3' transform='translate(0.000000, 1.000000)'>
          <mask id='mask-2' fill='white'>
            <use xlinkHref='#path-1' />
          </mask>
          <g id='Clip-2' />
          <path
            d='M7.7817,27.3457 C5.3427,24.9067 3.9997,21.6637 3.9997,18.2147 C3.9997,14.7657 5.3427,11.5237 7.7817,9.0837 C11.8867,4.9787 18.5677,4.9787 22.6737,9.0837 C26.0357,12.4467 26.0357,17.9177 22.6737,21.2807 C21.3327,22.6217 19.5497,23.3607 17.6537,23.3607 C15.7577,23.3607 13.9747,22.6217 12.6337,21.2807 C11.5227,20.1697 10.9117,18.6937 10.9117,17.1227 C10.9117,15.5527 11.5227,14.0757 12.6337,12.9657 C14.5457,11.0537 17.6567,11.0537 19.5687,12.9657 C19.9587,13.3567 19.9587,13.9897 19.5687,14.3797 C19.1777,14.7707 18.5447,14.7707 18.1547,14.3797 C17.0227,13.2477 15.1797,13.2477 14.0477,14.3797 C13.3147,15.1127 12.9117,16.0867 12.9117,17.1227 C12.9117,18.1587 13.3147,19.1337 14.0477,19.8667 C15.0107,20.8297 16.2917,21.3607 17.6537,21.3607 C19.0157,21.3607 20.2967,20.8297 21.2597,19.8667 C23.8427,17.2837 23.8427,13.0807 21.2597,10.4977 C17.9327,7.1727 12.5217,7.1727 9.1957,10.4977 C7.1347,12.5597 5.9997,15.2997 5.9997,18.2147 C5.9997,21.1297 7.1347,23.8697 9.1957,25.9317 C11.8197,28.5547 15.3077,29.9997 19.0187,29.9997 C22.1457,29.9997 25.4857,29.1907 27.5367,27.0677 C30.2957,24.1937 31.9997,20.2987 31.9997,15.9997 C31.9997,7.1637 24.8367,-0.0003 15.9997,-0.0003 C7.1627,-0.0003 -0.0003,7.1637 -0.0003,15.9997 C-0.0003,21.2117 2.4917,25.8427 6.3497,28.7637 C7.3617,29.7367 8.7947,28.3597 7.7817,27.3457'
            id='Fill-1'
            fill={fill}
            mask='url(#mask-2)'
          />
        </g>
      </g>
    </svg>
  )
}
