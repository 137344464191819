import { SecurityContextProps } from '@dominos/hooks-and-hocs/logging'
import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { SecurityContext } from './security-provider'
import { getOrderIdFromQueryString } from '../helpers'

const setApplicationInsightsTraceId = (applicationInsights: SecurityContextProps, orderId: string) => {
  if (!applicationInsights.client) {
    return
  }

  const newTraceId = getOrderIdFromQueryString(window.location.search) || orderId

  if (newTraceId) {
    applicationInsights.client.context.telemetryTrace.traceID = newTraceId.split('-').join('')
    applicationInsights.client.context.telemetryTrace.name = window.location.pathname
  }
}

export const useSecurityContext = () => {
  const applicationInsights = useContext(SecurityContext)
  const orderId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderId)

  useEffect(() => {
    setApplicationInsightsTraceId(applicationInsights, orderId)
  }, [orderId, window.location.search])

  return applicationInsights
}
