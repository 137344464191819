import { createContext } from 'react'

export interface VirtualKeyboardContextProps {
  toggleVirtualKeyboard: (
    displayKeyboard: boolean,
    didChangeTextInput?: ({ target }: React.ChangeEvent<HTMLInputElement>) => void,
    didPressKey?: (event: React.KeyboardEvent<HTMLInputElement> & { target: { value: string } }) => void,
    inputFieldName?: string,
  ) => void
}

export const VirtualKeyboardContext = createContext<VirtualKeyboardContextProps>({
  toggleVirtualKeyboard: () => {},
})
