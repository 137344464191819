import { useAllOffers } from '@dominos/hooks-and-hocs/offers'
import { useEffect, useState } from 'react'
import { isSpecialOffer } from './offers-helpers'

const useSpecialOffer = () => {
  const { offers = [] } = useAllOffers()
  const [offer, setOffer] = useState<Bff.Offers.Offer>()

  useEffect(() => {
    if (offers.length > 0) {
      setOffer(offers.find(isSpecialOffer))
    }
  }, [offers])

  return offer
}

export default useSpecialOffer
