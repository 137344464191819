import { TFunction } from 'react-i18next'
import { parseETATimeRange } from './parse-eta-time-range'
import { isTimeParsable } from './is-time-parsable'
import { getETARangeInMinutes } from './get-eta-range-in-minutes'
import { getETAReportLabel } from './get-eta-report-label'
import { ETA_UNAVAILABLE } from './eta-constants'

export const getETAForPreOrder = (
  from: string | undefined,
  to: string | undefined,
  loading: boolean,
  t: TFunction<'timed-order'>,
): { text: string; reportLabel: `${number}-${number}` | null } => {
  if (loading) {
    return {
      text: t('checking', { defaultValue: 'Checking' }),
      reportLabel: null,
    }
  }

  const { from: parsedFromValue, to: parsedToValue } = parseETATimeRange(from, to)

  if (isTimeParsable(parsedFromValue, parsedToValue)) {
    const { from: fromMinutes, to: toMinutes } = getETARangeInMinutes(parsedFromValue, parsedToValue)

    return {
      text: t('preOrderEtaText', {
        from: fromMinutes,
        to: toMinutes,
        defaultValue: 'Approx {{from}} - {{to}} minutes',
      }),
      reportLabel: getETAReportLabel(parsedFromValue, parsedToValue),
    }
  }

  return {
    text: t('ETAUnavailable', { defaultValue: ETA_UNAVAILABLE }),
    reportLabel: null,
  }
}
