import React, { PropsWithChildren } from 'react'
import { LinkButton } from '@dominos/components'

const styles = {
  button: { color: 'blue', paddingBottom: '10px' } as CommonTextStyle,
  container: {
    zIndex: 6,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '20px',
  } as React.CSSProperties,
}

export const CancelPayment = ({ children, onCancel }: PropsWithChildren<{ onCancel: () => void }>) => (
  <div style={styles.container}>
    {children}
    <LinkButton
      testID={'cancel-payment.button'}
      style={styles.button}
      weight={'bold'}
      onPress={onCancel}
      text='Cancel'
    />
  </div>
)
