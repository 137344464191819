import i18next from 'i18next'
import Backend from 'i18next-chained-backend'
import XhrBackend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import languagesInfo from './languages-info.json'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { getDefaultLanguage } from './helpers/get-default-language'

export const init = () => {
  const applicationConfig = getCountryConfig()
  const countryCode = applicationConfig.COUNTRY.toUpperCase()
  const countryLanguages = applicationConfig.LANGUAGES.split(',')
  const countryLocales = countryLanguages.map((language) => `${language}-${countryCode}`)
  const defaultLocale = applicationConfig.LOCALE_DEFAULT
  const namespaces = [
    'checkout',
    'delivery-address',
    'delivery-details',
    'footer',
    'half-n-half',
    'home',
    'login',
    'menu',
    'saved-vouchers',
    'store-search',
    'timed-order',
    'tracker',
    'translation',
    'validation',
    'navigation',
    'kiosk',
  ]
  const loadPath = applicationConfig.TRANSLATIONS_LOAD_PATH

  const defaultLanguage = getDefaultLanguage(countryLanguages, defaultLocale)

  const availableLanguages = countryLanguages.map((language) => ({
    code: `${language}-${countryCode}`,
    name: (languagesInfo as { [key: string]: string })[language] || language,
  }))

  i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: `${defaultLanguage}-${countryCode}`,
      preload: countryLanguages.concat(countryLocales),
      ns: namespaces,
      fallbackLng: 'en',
      backend: {
        backends: [XhrBackend],
        backendOptions: [
          {
            loadPath,
            crossDomain: true,
          },
        ],
      },
    })

  return { availableLanguages, defaultLanguage }
}
