import { useReport } from '@dominos/hooks-and-hocs'
import React from 'react'

export const useAlert = (callback?: () => void) => {
  const { reportValidationMessage } = useReport()

  const showAlert = React.useCallback(
    (message: string, translationKey?: string) => {
      reportValidationMessage(message, translationKey)
      window.alert(message)

      if (callback !== undefined) {
        callback()
      }
    },
    [callback],
  )

  return {
    showAlert,
  }
}
