import { OrderStatus as OrderStatusType } from '../order-types'
import PaymentDetailModel from './payment-detail-model'

class OrderModel {
  private readonly order

  constructor(order: Bff.Orders.Order) {
    this.order = order
  }

  get serviceMethod() {
    return this.order.details!.header.serviceMethod
  }

  get serviceMethodSubType() {
    return this.order.details?.header.serviceMethodSubType || undefined
  }

  get id() {
    return this.order.id
  }

  get timeElapsed() {
    return this.order.status.elapsedTime * 1000
  }

  get status() {
    return this.order.status.status as OrderStatusType
  }

  public verifyIfStatusExist = () => {
    const status = this.order.status.status as OrderStatusType

    this.order.status.status = Object.values(OrderStatusType).includes(status)
      ? status
      : (OrderStatusType.Failed as OrderStatusType)
  }

  get eta() {
    return this.order.eta
  }

  get basket() {
    return this.order.details?.basket
  }

  get hasNotifiedStore() {
    return this.order.details?.header.arrivalNotificationSent || false
  }

  get orderTotal() {
    const basket = this.basket

    return basket?.total ? basket?.total : 0
  }

  get paymentDetails() {
    return this.order.details?.payments.map((payment) => new PaymentDetailModel(payment)) || []
  }

  public getCurrentPaymentDetailById = (orderPaymentId: string) => {
    const details = this.paymentDetails

    return details.find((p) => p.orderPaymentId === orderPaymentId)
  }

  get addedFirstPayment() {
    const details = this.paymentDetails

    return details.find((p) => p.paymentMethod === 'GiftCard' || p.paymentMethod === 'Edenred')
  }

  get storePhoneNo() {
    return this.order.store?.phoneNo
  }

  get pulseOrderId() {
    return this.order?.details?.header?.pulseId
  }
}

export default OrderModel
