import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import styles from './tool-tip.less'
// import { useAuthenticationButtonToolTip } from '@dominos/hooks-and-hocs'

interface ToolTipProps extends BaseProps {
  title: string
  subTitle: string
  onClose: () => void
}

export const ToolTip = (props: ToolTipProps) => {
  const { title, subTitle, testID, onClose } = props

  const handleClose = () => {
    onClose()
  }

  return (
    <div data-testid={testID} className={styles.wrapper}>
      <span className={styles.arrow} />
      <div className={styles.titleContainer}>
        <div data-testid={`${testID}.title`} className={styles.title}>
          {title}
        </div>
        <div
          data-testid={`${testID}-close-button`}
          className={styles.closeButton}
          role='button'
          aria-label={'Close Button'}
          onClick={handleClose}
        >
          <StaticSvgIcon name={'xMark'} width={16} height={17} fill='#6F6F6F' isUnstyled />
        </div>
      </div>
      <div data-testid={`${testID}.subTitle`} className={styles.subTitle}>
        {subTitle}
      </div>
    </div>
  )
}

export default ToolTip
