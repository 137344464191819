import React, { useState } from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { ClearButtonProps } from './clear-button.interface'

import { StaticSvgIcon } from '@dominos/res'

export const ClearButton = (props: ClearButtonProps) => {
  const { testID, onKeyPress, onClick, tabIndex, includePadding } = props

  const theme = useDominosTheme()
  const [clearIconHovered, setClearIconHovered] = useState<boolean | undefined>(undefined)

  const onHoverClearInputButton = () => {
    setClearIconHovered(true)
  }

  const onHoverOutClearInputButton = () => {
    setClearIconHovered(false)
  }

  return (
    <div
      data-testid={testID}
      onKeyPress={onKeyPress}
      onClick={onClick}
      onMouseOver={onHoverClearInputButton}
      onMouseOut={onHoverOutClearInputButton}
      tabIndex={tabIndex}
    >
      <StaticSvgIcon
        name={'cross'}
        fill={clearIconHovered ? theme.colours.errorMessage : theme.colours.inputIconColor}
        width={30}
        height={30}
        includePadding={includePadding}
      />
    </div>
  )
}
