import { FilterProductItem } from '@dominos/business/functions/menu/'

interface DimensionFilter {
  type: Bff.Common.DimensionTypeCode
  values: string[]
}

const convertFilterProductItemToDimensionFilter = (
  filterProductItem?: FilterProductItem,
): DimensionFilter | undefined =>
  filterProductItem
    ? {
        type: 'Size',
        values: filterProductItem.sizes?.map((size) => size.code ?? '').filter((size) => size) ?? [],
      }
    : undefined

export { DimensionFilter, convertFilterProductItemToDimensionFilter }
