import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge4 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-4' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M21.0385374,24.41 L15.5515374,24.41 C14.2805374,24.41 13.2265374,23.356 13.2265374,22.085 L13.2265374,10.925 C13.2265374,10.491 13.5675374,10.15 14.0015374,10.15 L17.2565374,10.15 C17.6905374,10.15 18.0315374,10.491 18.0315374,10.925 L18.0315374,19.946 C18.0315374,20.039 18.0935374,20.101 18.1865374,20.101 L21.0385374,20.101 C21.1315374,20.101 21.1935374,20.039 21.1935374,19.946 L21.1935374,10.925 C21.1935374,10.491 21.5345374,10.15 21.9685374,10.15 L25.2235374,10.15 C25.6575374,10.15 25.9985374,10.491 25.9985374,10.925 L25.9985374,19.946 C25.9985374,20.039 26.0605374,20.101 26.1535374,20.101 L28.2615374,20.101 C28.6335374,20.101 28.8815374,20.473 28.7265374,20.814 L27.4555374,23.852 C27.3315374,24.193 26.9905374,24.41 26.6495374,24.41 L26.1535374,24.41 C26.0605374,24.41 25.9985374,24.472 25.9985374,24.565 L25.9985374,31.075 C25.9985374,31.509 25.6575374,31.85 25.2235374,31.85 L21.9685374,31.85 C21.5345374,31.85 21.1935374,31.509 21.1935374,31.075 L21.1935374,24.565 C21.1935374,24.472 21.1315374,24.41 21.0385374,24.41 Z'
          id='4'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
