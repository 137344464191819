import { detailsFieldNames } from '../details-container-config'

export const mapEClub = (eClub: { [key: string]: boolean }) => {
  const optInEmails = eClub[detailsFieldNames.vipOffersEmail]
  const optInSMS = eClub[detailsFieldNames.vipOffersSMS]
  if (optInEmails !== undefined && optInSMS !== undefined) {
    return { optInEmails, optInSMS }
  }

  const optOutEmails = eClub[detailsFieldNames.vipOffersOptOutEmail]
  const optOutSMS = eClub[detailsFieldNames.vipOffersOptOutSMS]
  if (optOutEmails !== undefined && optOutSMS !== undefined) {
    return { optInEmails: !optOutEmails, optInSMS: !optOutSMS }
  }

  const optOut = eClub[detailsFieldNames.vipOffersOptOutAll]
  if (optOut !== undefined) {
    return { optInEmails: !optOut, optInSMS: !optOut }
  }

  return undefined
}
