export const nutritionalsMockData = {
  energyKJ: 1,
  energyKcal: 2,
  fat: 3,
  fatSaturated: 4,
  carbohydrate: 5,
  sugars: 6,
  fibre: 7,
  protein: 8,
  sodium: 9,
  weight: 10,
  salt: 11,
}
