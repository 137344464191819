import { getDimensionFromDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'

const mapSauce = ({ add }: Pick<BasketLineSwap, 'add'>): Bff.PriceProduct.PriceProductComponent => ({
  componentCode: add || '',
  quantity: 1,
})

const mapProductContextToPriceProductForPortionProducts = (
  code: string,
  dimensionSet: Bff.Dimensions.DimensionSet | undefined,
  portions: Portion[],
): Bff.PriceProduct.PriceProduct => {
  let priceProduct: Bff.PriceProduct.PriceProduct = {
    quantity: 1,
    productCode: code,
    productSizeCode: getDimensionFromDimensionSet('Size', dimensionSet) || '',
    portions: portions.map((portion) => ({
      productCode: portion.productCode!,
      sauces: portion.sauce ? [mapSauce(portion.sauce)] : [],
      toppings: [],
      options: [],
    })),
  }

  const base = getDimensionFromDimensionSet('Base', dimensionSet)

  if (base) {
    priceProduct = {
      ...priceProduct,
      crusts: [{ componentCode: base, quantity: 1 }],
    }
  }

  return priceProduct
}

//TODO: MAB-3755 Pricing changes for Simple and Recipe cards depend on changes in Sauces, Toppings, and Options
const mapProductContextToPriceProductForNonPortionProducts = (
  code: string,
  dimensionSet: Bff.Dimensions.DimensionSet | undefined,
  // toppings?: Portion[], toppings changes Need to be reflect on price
  // sauces?: BasketLineSwap, BasketLineSwap, Portion changes Need to be reflect on price
  // options?: [], options changes Need to be reflect on price
): Bff.PriceProduct.PriceProduct => {
  let priceProduct: Bff.PriceProduct.PriceProduct = {
    quantity: 1,
    productCode: code,
    productSizeCode: getDimensionFromDimensionSet('Size', dimensionSet) || '',
    portions: [
      {
        productCode: code,
        sauces: [],
        toppings: [],
        options: [],
      },
    ],
  }

  const base = getDimensionFromDimensionSet('Base', dimensionSet)

  if (base) {
    priceProduct = {
      ...priceProduct,
      crusts: [{ componentCode: base, quantity: 1 }],
    }
  }

  return priceProduct
}

export { mapProductContextToPriceProductForPortionProducts, mapProductContextToPriceProductForNonPortionProducts }
