import gql from 'graphql-tag'

export const timedOrderSlotsQuery = gql`
  query timedOrderSlotsQuery($serviceMethod: ServiceMethodEnum!, $storeNo: Int!, $locationId: String) {
    orderingTimeSlots(serviceMethod: $serviceMethod, storeNo: $storeNo, locationId: $locationId) {
      storeNo
      currentStoreStatus
      currentStoreStatusMessage
      currentStoreDivertToStoreNo
      currentStoreDateTime
      nextOpenDateTime
      closesInMinutes
      leadTime
      dayOfTradeTimeSlots {
        dayOfTrade
        tradingDayStatus
        timeSlotAvailabilities {
          slotTime
          timeSlotStatus
          timeSlotDivertToStoreNo
        }
      }
    }
  }
`
