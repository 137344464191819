import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton, BoxedMessage, TimedOrderPickerContainerProps } from '@dominos/components'
import {
  useAlternativeStores,
  useAlternativeStoresPopup,
  useDominosTheme,
  useFeatures,
  useReport,
} from '@dominos/hooks-and-hocs'
import { rootActions } from '@dominos/business'
import { useDispatch } from 'react-redux'
import { StoreStatusMessage } from '@dominos/interfaces/delivery-address'
import styles from './store-details.less'
import { AlternativeStoresPopup } from './alternative-stores-popup'

interface StoreDetailsProps extends Pick<TimedOrderPickerContainerProps, 'serviceMethod' | 'testID'> {
  storeNo: number
  storeAddress: string
  storeInformation: string
  storeStatusMessage: StoreStatusMessage | undefined
  extraStoreStatusDetails: string | undefined
  storeOpensAtMessage: string | undefined
  storeType: StoreType | undefined
  storeTypeMessage: string | undefined
}

export const StoreDetails = ({
  testID,
  storeNo,
  storeAddress,
  serviceMethod,
  storeInformation,
  storeStatusMessage,
  extraStoreStatusDetails,
  storeOpensAtMessage,
  storeType,
  storeTypeMessage,
}: StoreDetailsProps) => {
  const dispatch = useDispatch()
  const { reportAlternativeStoreSelected } = useReport()
  const theme = useDominosTheme()
  const { t } = useTranslation('timed-order')

  const [showAlternativeDeliveryStoresFeatureValue] = useFeatures('ShowAlternativeDeliveryStores')
  const showAlternativeDeliveryStores = showAlternativeDeliveryStoresFeatureValue && serviceMethod == 'Delivery'

  const { fetchStores, isLoading, stores } = useAlternativeStores()
  const { isShowPopup, openPopup, closePopup } = useAlternativeStoresPopup()

  const onChangeButtonClick = async () => {
    openPopup()
    await fetchStores()
  }

  const handleChangeStore = (store: Bff.Stores.Store) => {
    dispatch(rootActions.alternativeStoreSelected(store.storeNo))
    reportAlternativeStoreSelected(store.storeNo)
  }

  const icon =
    serviceMethod === 'Pickup'
      ? storeType === 'Mobile'
        ? 'mobileMPKIcon'
        : 'storeDetailsPickUp'
      : 'storeDetailsDelivery'

  const storeStatusMessageStyle = useMemo(() => {
    // waiting on confirmation on styles for statuses, will return undefined/normal style for now
    switch (storeStatusMessage?.theme) {
      case undefined:
        return undefined
      case 'open':
        return undefined
      case 'alert':
        return styles.alert
      case 'closed':
        return undefined
      case 'closingSoon':
        return undefined

      default:
        return undefined
    }
  }, [storeStatusMessage?.theme])

  return (
    <>
      <div
        data-testid={testID}
        className={styles.storeDetailsContainer}
        style={{
          borderRadius: theme.borderRadius,
        }}
      >
        <BoxedMessage
          testID={`${testID}.details`}
          variant='contained'
          color='secondary'
          icon={icon}
          textSize='regular'
          iconProps={{
            width: 34,
            height: 34,
            defaultFill: true,
          }}
        >
          <>
            <div className={styles.textContainer}>
              <div>
                <span data-testid={`${testID}.store-name`}>{storeInformation}</span>

                <br />

                <div className={styles.status}>
                  {storeStatusMessage ? (
                    <div>
                      <span data-testid={`${testID}.status-text`} className={storeStatusMessageStyle}>
                        {storeStatusMessage.value}
                      </span>
                    </div>
                  ) : (
                    <span className={styles.ellipsis} />
                  )}
                </div>
                {extraStoreStatusDetails && (
                  <div>
                    <span data-testid={`${testID}.extra-status-details-text`}>{extraStoreStatusDetails}</span>
                  </div>
                )}
                {storeOpensAtMessage && (
                  <div>
                    <span data-testid={`${testID}.opensAt-text`}>{storeOpensAtMessage}</span>
                  </div>
                )}
                {storeType && storeAddress && serviceMethod === 'Pickup' && (
                  <div>
                    <span data-testid={`${testID}.store-address`} className={styles.normalText}>
                      {storeAddress}
                    </span>
                  </div>
                )}
              </div>

              {showAlternativeDeliveryStores && (
                <ActionButton
                  text={t('AlternativeStoreChange')}
                  testID='change-store-button'
                  textFontWeight='bold'
                  textSize={14}
                  onPress={() => onChangeButtonClick()}
                  {...buttonStyles.changeButtonStyle}
                />
              )}
            </div>
          </>
        </BoxedMessage>
        {storeTypeMessage && (
          <div className={styles.textContainer && styles.addBorderTop}>
            <div data-testid={`${testID}.store-type-message`} className={styles.messageText}>
              {storeTypeMessage}
            </div>
          </div>
        )}

        {isShowPopup && (
          <AlternativeStoresPopup
            testID={`${testID}.alternative-store-popup`}
            isLoading={isLoading}
            onClose={closePopup}
            storeNo={storeNo}
            stores={stores}
            handleChangeStore={handleChangeStore}
          />
        )}
      </div>
    </>
  )
}

const buttonStyles = {
  changeButtonStyle: {
    containerStyle: {
      backgroundColor: 'gray',
      width: '90px',
      height: '10px',
      minHeight: '35px',
    },
  },
}
