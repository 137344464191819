import React, { useMemo } from 'react'
import { Link, useLocation } from '@reach/router'
import { LogoProps } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import { NavigationConstants } from '@dominos/navigation'
import { useFeatures } from '@dominos/hooks-and-hocs'

const mobile = {
  height: 24,
  width: 121,
}

const desktop = {
  height: 33,
  width: 166,
}

const NAVIGATION_BLACKLIST = [
  NavigationConstants.home,
  NavigationConstants.checkoutProcessing,
  NavigationConstants.nativeAppCheckoutProcessing,
  NavigationConstants.formResolver,
]

const MENU_PATHS = [
  NavigationConstants.variety,
  NavigationConstants.basket,
  NavigationConstants.checkout,
  NavigationConstants.checkoutDetails,
  NavigationConstants.checkoutPayment,
  NavigationConstants.nativeAppCheckout,
  NavigationConstants.nativeAppCheckoutDetails,
  NavigationConstants.nativeAppCheckoutPayment,
]

export const NavigationLogo = ({ showMobileLogo, showBackButton, fill, width, height, evenLogoWidth }: LogoProps) => {
  const { pathname } = useLocation()
  const [navigationEnabled] = useFeatures('HeaderLogoNavigation')
  const computedWidth = width ? width : showMobileLogo ? mobile.width : desktop.width
  const computedHeight = height ? height : showMobileLogo ? mobile.height : desktop.height

  const evenLogoWidthStyle = !showBackButton && evenLogoWidth ? { flex: 1, textAlign: 'center' } : {}

  const navigationPath: string | undefined = useMemo(() => {
    if (!navigationEnabled) {
      return
    }

    if (MENU_PATHS.includes(pathname)) {
      return NavigationConstants.menu
    }

    if (!NAVIGATION_BLACKLIST.includes(pathname)) {
      return NavigationConstants.home
    }
  }, [pathname, navigationEnabled])

  return React.cloneElement(
    navigationPath ? <Link to={navigationPath} /> : <div />,
    {
      key: navigationPath,
      'data-testid': 'navigation-logo',
      style: {
        ...evenLogoWidthStyle,
        display: 'inline-block',
        cursor: navigationPath ? 'pointer' : 'default',
      },
      alt: navigationPath && 'Home',
    },
    <StaticSvgIcon
      name={showBackButton && showMobileLogo ? 'dominosLogoIcon' : 'headerLogo'}
      includePadding={false}
      fill={fill}
      height={computedHeight}
      width={computedWidth}
      focusable={false}
      aria-hidden={true}
    />,
  )
}
