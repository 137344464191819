import { isProductMenuItemNew } from '@dominos/components'

type PortionSize = Bff.Menu.old.PortionSize

export const getDefaultSizeAndEnergy = (
  item?: MenuItemDependents | null,
): {
  energyKJ?: number | null
  defaultSize?: ProductSize | PortionSize | null
} => {
  let energyKJ = null
  let defaultSize: ProductSize | PortionSize | null | undefined = null

  if (item && !isProductMenuItemNew(item) && (item.type === 'Product' || item.type === 'Portion') && item.sizes) {
    const sizes: (ProductSize | PortionSize)[] = item.sizes

    if (item.defaultSize) {
      defaultSize = sizes.find((size: ProductSize | PortionSize) => size.code === item.defaultSize)
    } else {
      defaultSize = sizes[0]
    }

    if (defaultSize) {
      if (defaultSize.nutritionals?.energyKJ) {
        // for all non-pizza products
        energyKJ = defaultSize.nutritionals?.energyKJ
      }

      if (defaultSize.recipe?.base?.nutritionals?.energyKJ) {
        // for pizza products
        energyKJ = Math.round(defaultSize.recipe?.base?.nutritionals?.energyKJ)
      }
    }
  }

  return {
    energyKJ,
    defaultSize,
  }
}
