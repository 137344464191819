import gql from 'graphql-tag'

export const swapSetProductsQuery = gql`
  query swapSetProducts(
    $storeNo: Int!
    $serviceMethod: ServiceMethodEnum!
    $voucherCode: String!
    $itemNo: Int!
    $orderTime: String
  ) {
    swapSetProducts(
      storeNo: $storeNo
      serviceMethod: $serviceMethod
      voucherCode: $voucherCode
      itemNo: $itemNo
      orderTime: $orderTime
    ) {
      code
      type
      defaultSize
      baseProduct {
        productCode
        sizeCode
      }
      sizes {
        code
        hasSurcharge
        surcharge
      }
      hasSurcharge
      filterSizes
      surcharge
    }
  }
`
