import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { mapRecentlySearchedAddressToAddressLine, mapStoredCustomerAddressToAddressLine } from './address-mappings'
import { useSavedAddressesBatch } from './use-saved-addresses-batch'
import { useRecentAddressesBatch } from './use-recent-addresses-batch'

const TOTAL_NUMBER_OF_ADDRESSES = 5

export const useDeliveryAddresses = (returnedAddressCount?: number) => {
  const { t } = useTranslation('delivery-address')

  const savedAddressesBatch = useSavedAddressesBatch()
  const recentAddressesBatch = useRecentAddressesBatch()

  return useMemo(() => {
    const savedCustomerAddresses = mapStoredCustomerAddressToAddressLine(savedAddressesBatch, t)
    const recentlySearchedAddresses = mapRecentlySearchedAddressToAddressLine(recentAddressesBatch || [], t)
    const filteredRecentAddresses = recentlySearchedAddresses.filter(
      (a) => !savedCustomerAddresses.find((s) => a.address === s.address),
    )

    return [...savedCustomerAddresses, ...filteredRecentAddresses].slice(
      0,
      returnedAddressCount || TOTAL_NUMBER_OF_ADDRESSES,
    )
  }, [savedAddressesBatch, recentAddressesBatch])
}
