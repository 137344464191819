import { ActionButton } from '@dominos/components'
import { CollapsableTitledCard } from '@dominos/components/cards/collapsable-titled-card'
import { ErrorCardPopup, useLoyaltyHistory, useOrder } from '@dominos/hooks-and-hocs'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoyaltyHistoryItem } from './loyalty-history-item'
import css from './loyalty-history.less'

export const LoyaltyHistory = ({ testID }: BaseProps) => {
  const { t } = useTranslation('loyalty')
  const { order } = useOrder()
  const [showErrorPopup, setShowErrorPopup] = useState(false)

  const { history, loading, hasMore, loadMore, error } = useLoyaltyHistory()

  useEffect(() => {
    setShowErrorPopup(!!error)
  }, [error])

  const timezone = order?.store?.timeZone

  const onClose = () => {
    setShowErrorPopup(false)
  }

  return (
    <>
      <CollapsableTitledCard
        testID={testID}
        primaryTitle={t('LoyaltyHistoryTitle', { defaultValue: 'Points History' })}
        noPadding
      >
        {history.length > 0 ? (
          history.map(({ pointsStatus, statusDate, orderTotal, minOrderTotalPerPoint, points }) => (
            <LoyaltyHistoryItem
              testID={`${testID}.item`}
              key={statusDate}
              pointsStatus={pointsStatus}
              statusDate={statusDate}
              orderTotal={orderTotal}
              minOrderTotalPerPoint={minOrderTotalPerPoint}
              points={points}
              timezone={timezone}
            />
          ))
        ) : (
          <div data-testid={`${testID}.label`} className={classnames(css.cell, css.empty)}>
            {loading
              ? t('LoyaltyHistoryLoadingLabel', { defaultValue: 'Loading loyalty order history.' })
              : t('LoyaltyHistoryEmptyLabel', {
                  defaultValue: 'Unfortunately, you do not currently have any loyalty order history.',
                })}
          </div>
        )}

        <div data-testid={`${testID}.actions`} className={css.cell}>
          <ActionButton
            testID={`${testID}.more`}
            onPress={loadMore}
            text={t('LoyaltyHistoryMoreButton', { defaultValue: 'More History' })}
            textFontWeight={'bold'}
            containerStyle={{ padding: 0, minHeight: 30 }}
            disabled={!hasMore || loading}
            loading={loading}
          />
        </div>
      </CollapsableTitledCard>
      {showErrorPopup && (
        <ErrorCardPopup
          testID={`${testID}.error-popup`}
          title={t('LoyaltyHistoryErrorTitle', {
            defaultValue: 'Error occurred',
          })}
          message={t('LoyaltyHistoryErrorMessage', {
            defaultValue: 'Sorry, something went wrong. Please try again',
          })}
          confirmLabel={t('LoyaltyHistoryErrorConfirmLabel', {
            defaultValue: 'Okay',
          })}
          onClose={onClose}
        />
      )}
    </>
  )
}
