import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useValidation } from '@dominos/business/functions'
import { AdditionalField } from '../additional-field'

const UNIT_NO_MAX_LENGTH = 9

export const AdditionalFieldsLayoutAu = ({ testID, address, onInit, onChange }: AdditionalFieldsLayoutProps) => {
  const { t } = useTranslation('delivery-address')
  const { maxLength } = useValidation()

  useEffect(() => {
    if (onInit)
      onInit({
        unitNo: {
          isValid: true,
        },
      })
  }, [])

  return (
    <>
      <AdditionalField
        testID={testID}
        fieldName={'unitNo'}
        placeholder={t('Unit Number')}
        onChange={onChange}
        validationRules={[maxLength(UNIT_NO_MAX_LENGTH)]}
        address={address}
      />
    </>
  )
}
