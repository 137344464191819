import { MarketingPreferenceTypes } from './create-account-interfaces'

const DEFAULT_MARKETING_FREQUENCY = 1 // twice per week, see #64240
const DEFAULT_VOUCHER_NOTIFICATION_FREQUENCY = 0 // once per week

interface CreateCustomerQueryTypes {
  email: string
  identityProvider?: Bff.Customers.UpsertCustomerIdentityProvider
  loyaltyEnrolled: boolean
  orderId: string
  name: string
  alternateName?: string
  password?: string
  phoneNumber: string
  subscriptions: Record<string, boolean | undefined>
  token?: string
  useRecaptchaV3: boolean
  recaptchaAction: BffContext.RecaptchaAction
}

export const createCustomerQueryBuilder = ({
  email,
  identityProvider,
  loyaltyEnrolled,
  orderId,
  name,
  alternateName,
  password,
  phoneNumber,
  subscriptions,
  token,
  useRecaptchaV3,
  recaptchaAction,
}: CreateCustomerQueryTypes): Bff.Customers.UpsertCustomer => {
  let variables: Bff.Customers.UpsertCustomer = {
    name,
    password,
    email,
    phoneNumber,
    identityProvider,
    alternateName,
    loyalty: { isEnrolled: loyaltyEnrolled, redeemPointsOnOrderId: orderId },
    recaptchaAction,
  }

  if (token) {
    variables = { ...variables, useRecaptchaV3, recaptchaToken: token }
  }

  if (subscriptions.marketing || subscriptions.vouchers || subscriptions.loyalty) {
    const marketingPreferences: MarketingPreferenceTypes = {
      emailSubscriptions: [],
    }

    if (subscriptions.loyalty) {
      marketingPreferences.emailSubscriptions!.push({
        edmType: 'LoyaltyNotification',
      })
    }

    if (subscriptions.marketing) {
      marketingPreferences.emailSubscriptions!.push({
        edmType: 'SpecialOffers',
        frequency: DEFAULT_MARKETING_FREQUENCY,
      })
      marketingPreferences.smsSubscriptions = [{ smsSubscriptionType: 'smssubscription' }]
    }

    if (subscriptions.vouchers) {
      marketingPreferences.emailSubscriptions!.push({
        edmType: 'VoucherNotification',
        frequency: DEFAULT_VOUCHER_NOTIFICATION_FREQUENCY,
      })
    }

    variables = { ...variables, ...marketingPreferences }
  }

  return variables
}
