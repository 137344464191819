import {
  CreateProductNutritionalViewModelProps,
  ProductNutritionalType,
  ProductNutritionalViewModel,
} from './product-nutritional.interface'

export const createProductNutritionalViewModel = ({
  t,
  nutritionals,
  allergens,
  showContainsAllergensOnly,
  showInfoButton,
}: CreateProductNutritionalViewModelProps): ProductNutritionalViewModel => {
  const title = t('Nutritional & Allergens')
  const description = t('NutritionalInformationDescription', { defaultValue: '' })
  const buttonText = t('View Nutritional Info & Allergens')
  const nutritionalTitle = t('NutritionalValuePer100g')

  const url = t('nutritional information url', {
    defaultValue: 'https://www.dominos.com.au/menu/nutritional-information',
  })

  const productNutritionalTypes: ProductNutritionalType[] = [
    { keyName: 'energyKJ', defaultUnit: 'kJ' },
    { keyName: 'energyKcal', defaultUnit: 'kCal' },
    { keyName: 'fat', defaultUnit: 'g' },
    { keyName: 'fatSaturated', defaultUnit: 'g' },
    { keyName: 'carbohydrate', defaultUnit: 'g' },
    { keyName: 'sugars', defaultUnit: 'g' },
    { keyName: 'fibre', defaultUnit: 'g' },
    { keyName: 'protein', defaultUnit: 'g' },
    { keyName: 'sodium', defaultUnit: 'mg' },
    { keyName: 'weight', defaultUnit: 'g' },
    { keyName: 'salt', defaultUnit: 'g' },
  ]

  return {
    productNutritionalTypes,
    productNutritionals: nutritionals ?? null,
    productAllergens: allergens ?? null,
    showContainsAllergensOnly: showContainsAllergensOnly ?? false,
    title,
    buttonText,
    description,
    nutritionalTitle,
    url,
    showInfoButton: !!showInfoButton,
  }
}
