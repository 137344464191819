import { DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const GarlicBread = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR
  const selectedColorLight = color ? color : '#4A4A4A'

  return (
    <svg width='51px' height='49px' viewBox='0 0 51 49' key={color}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-162.000000, -1048.000000)'>
          <g transform='translate(6.000000, 309.000000)'>
            <g transform='translate(1.000000, 474.000000)'>
              <g transform='translate(160.613281, 267.947339)'>
                <ellipse
                  stroke={selectedColor}
                  strokeWidth='3'
                  cx='19.953125'
                  cy='19.6051924'
                  rx='19.4921875'
                  ry='18.7566333'
                />
                <g
                  transform='translate(25.886719, 17.798681) scale(-1, 1) translate(-25.886719, -17.798681) translate(19.386719, 4.798681)'
                  stroke={selectedColorLight}
                  strokeWidth='2.2'
                >
                  <path d='M12.4575195,3.90798505e-14 C12.4575195,3.57166372 11.7073568,6.35464727 10.2070313,8.34895066 C7.91511705,11.395471 6.22875977,13.6165288 6.22875977,18.2031987 C6.22875977,22.9459783 9.85375977,25.7620366 12.5,25.7620366' />
                  <path d='M12.4575195,0.349927218 C12.1241862,3.40559128 10.0479329,6.42439011 6.22875977,9.4063237 C3.67394347,11.4010729 -7.99360578e-15,13.6165288 -7.99360578e-15,18.2031987 C-7.92338992e-15,22.9459783 3.58251953,25.0974858 6.22875977,25.0974858 C7.99291992,25.0974858 8.8507487,24.8213628 8.80224609,24.2691167' />
                </g>
                <path
                  d='M19.8867188,4.79868072 C19.8867188,8.37034444 19.136556,11.153328 17.6362305,13.1476314 C15.3443163,16.1941517 13.657959,18.4152095 13.657959,23.0018794 C13.657959,27.744659 17.2404785,30.5607173 19.8867188,30.5607173'
                  stroke={selectedColorLight}
                  strokeWidth='2.2'
                />
                <path
                  d='M19.8867188,5.14860794 C19.5533854,8.204272 17.4771322,11.2230708 13.657959,14.2050044 C11.1031427,16.1997536 7.42919922,18.4152095 7.42919922,23.0018794 C7.42919922,27.744659 11.0117187,29.8961665 13.657959,29.8961665 C15.4221191,29.8961665 16.2799479,29.6200435 16.2314453,29.0677974'
                  stroke={selectedColorLight}
                  strokeWidth='2.2'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
