import React from 'react'
import { useForgeRock } from '@dominos/hooks-and-hocs'
import { Card } from '@dominos/components'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'

import { TrackerForgeRockCreateAccount, TrackerForgeRockCreateAccountNative } from './tracker-forgerock-create-account'

interface TrackerCreateAccountProps {
  id?: string
}

export const TrackerCreateAccountForgeRock = ({ id }: TrackerCreateAccountProps) => {
  const { loginForgeRock, registerForgeRock } = useForgeRock()
  const handleLogin = async () => {
    isNativeApp() ? notifyNativeApp('login') : await loginForgeRock()
  }

  const handleRegister = async () => {
    isNativeApp() ? notifyNativeApp('register') : await registerForgeRock()
  }

  return (
    <div id={id} style={{ marginTop: 8 }}>
      <Card testID={`fr-account-register.card`}>
        {isNativeApp() ? (
          <TrackerForgeRockCreateAccountNative
            testID='fr-account-register-native'
            onLogin={handleLogin}
            onRegister={handleRegister}
          />
        ) : (
          <TrackerForgeRockCreateAccount
            testID='fr-account-register'
            onLogin={handleLogin}
            onRegister={handleRegister}
          />
        )}
      </Card>
    </div>
  )
}
