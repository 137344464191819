import { NavigationConstants } from '@dominos/navigation/navigation-constants'

/* What are Silent routes?
 *
 * Sometimes it's not relevant to the user that we have expired
 * their order, e.g. on the tracker page or the processing order page.
 * At these points the order is placed and we use the placed order as
 * a source of truth, rather than their redux store.
 */

export const SILENT_ROUTES = [
  NavigationConstants.checkoutProcessing,
  NavigationConstants.nativeAppCheckoutProcessing,
  NavigationConstants.tracker,
  NavigationConstants.nativeAppTracker,
]

export const isSilentRoute = () => SILENT_ROUTES.some((route) => window.location.pathname.includes(route))
