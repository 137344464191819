import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 44,
  height: 44,
}

const Change = (props: SvgIconProps) => {
  const fill = props.fill || '#454545'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 25'>
      <g id='App-and-Web' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Change-Icon' transform='translate(0.858236, 0.589233)' fill={fill} fillRule='nonzero'>
          <path
            d='M1.16715451,19.416626 C-0.0135095562,19.416626 -0.33138065,20.3248291 0.364908413,21.3087158 L3.99772091,26.5006104 C4.54264279,27.3028564 5.39029904,27.3028564 5.93522091,26.5006104 L9.56803341,21.2935791 C10.2491858,20.3248291 9.91617794,19.416626 8.76578732,19.416626 L6.52555294,19.416626 L6.52555294,8.35168457 C6.52555294,5.8692627 8.05436154,4.27990723 10.1886389,4.27990723 C12.3229162,4.27990723 13.8819983,5.83898926 13.8819983,8.35168457 L13.8819983,18.7052002 C13.8819983,23.2310791 16.7428381,26.2735596 20.6935217,26.2735596 C24.7198889,26.2735596 27.4747717,23.2613525 27.4747717,18.7052002 L27.4747717,7.68566895 L29.7301428,7.68566895 C30.8805334,7.68566895 31.1984045,6.77746582 30.5323889,5.7935791 L26.8995764,0.60168457 C26.3546545,-0.200561523 25.5069983,-0.200561523 24.9469397,0.60168457 L21.3141272,5.7935791 C20.6329748,6.77746582 20.9508459,7.68566895 22.1163733,7.68566895 L24.3566076,7.68566895 L24.3566076,18.9473877 C24.3566076,21.4298096 22.827799,23.019165 20.6935217,23.019165 C18.5592444,23.019165 17.0001623,21.4449463 17.0001623,18.9473877 L17.0001623,8.57873535 C17.0001623,4.05285645 14.1393225,1.01037598 10.1886389,1.01037598 C6.16227169,1.01037598 3.40738888,4.02258301 3.40738888,8.57873535 L3.40738888,19.416626 L1.16715451,19.416626 Z'
            id='Path'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export { Change }
