import { useLazyQuery } from '@apollo/client'
import { mapCustomerAddressToAddress } from '@dominos/business/functions/address/address-mapping'
import { preOrderEstimateQuery } from '@dominos/business/queries'
import { GraphQLError } from 'graphql'
import { useState } from 'react'

export const usePreOrderEta = () => {
  const [results, setResults] = useState<Bff.Orders.OrderEta | undefined>()
  const [error, setError] = useState<GraphQLError[] | Error | undefined>()
  const [query, { loading, called }] = useLazyQuery(preOrderEstimateQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data && data.preOrderEstimate) {
        setResults(data.preOrderEstimate)
      }
      setError(data.errors && data.errors.map((itm: string) => itm))
    },
    onError: (exception) => {
      setError(exception)
    },
  })

  const fetchPreOrderEstimate = async (
    storeNo: number,
    serviceMethod: BffContext.ServiceMethods,
    customerAddress: CustomerAddressRequest | undefined,
  ) => {
    const deliveryAddress = mapCustomerAddressToAddress(customerAddress)

    return query({ variables: { storeNo, serviceMethod, deliveryAddress } })
  }

  return {
    fetchPreOrderEstimate,
    results,
    loading,
    error,
    called,
  }
}
