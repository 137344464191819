import gql from 'graphql-tag'
import {
  INGREDIENT,
  INGREDIENT_MEDIA,
  INGREDIENT_SET,
  LEGEND_FIELDS,
  PORTION_MENU_ITEM,
  PORTION_NUTRITIONAL_FIELDS,
  PRODUCT_MEDIA_FIELDS,
  PRODUCT_MENU_ITEM,
  PRODUCT_NUTRITIONAL_FIELDS,
} from './menu-fragments'

export const menuQuery = gql`
  ${PRODUCT_NUTRITIONAL_FIELDS}
  ${PORTION_NUTRITIONAL_FIELDS}
  ${PRODUCT_MEDIA_FIELDS}
  ${INGREDIENT_SET}
  ${INGREDIENT}
  ${INGREDIENT_MEDIA}
  ${PRODUCT_MENU_ITEM}
  ${PORTION_MENU_ITEM}
  ${LEGEND_FIELDS}
  query menu($storeNo: Int!, $serviceMethod: ServiceMethodEnum!, $requestTime: DateTime) {
    menuTransitional(storeNo: $storeNo, serviceMethod: $serviceMethod, requestTime: $requestTime) {
      pages {
        code
        media {
          name
          description
          colour
          logo {
            url
            altText
          }
          banner {
            url
            altText
          }
        }
        sections {
          code
          media {
            name
            description
            logo {
              url
              altText
            }
            banner {
              url
              altText
            }
          }
          items {
            ... on VoucherMenuItemOld {
              code
              type
              media {
                name
                description
                applicationImage {
                  url
                  altText
                }
              }
            }
            ... on ProductMenuItemOld {
              ...ProductMenuItemFields
            }
            ... on PortionMenuItemOld {
              ...PortionMenuItemFields
            }
            ... on VarietyMenuItemOld {
              code
              type
              media {
                name
                description
                largeImage {
                  url
                  altText
                }
                smallImage {
                  url
                  altText
                }
                applicationImage {
                  url
                  altText
                }
              }
              items {
                ... on ProductMenuItemOld {
                  ...ProductMenuItemFields
                }
                ... on PortionMenuItemOld {
                  ...PortionMenuItemFields
                }
              }
              legends {
                ...LegendFields
              }
              disclaimers
            }
          }
        }
        legends {
          ...LegendFields
        }
        disclaimers
      }
      legends {
        ...LegendFields
      }
      disclaimers
    }
  }
`
