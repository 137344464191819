import { GeoLocationInterface } from '@dominos/interfaces'
import { findClosestGeocoderLocation } from '@dominos/hooks-and-hocs'

export const mapDeliveryLocationRequestToAddressLine = (
  request: DeliveryAddressRequest | undefined,
  locationName?: string,
): AddressLine | undefined => {
  if (!request) return undefined

  return {
    name: locationName ? locationName : '',
    address: request.displayAddress,
    uid: 0,
    addressId: request.displayAddress,
    geo: {
      latitude: request.geo.latitude,
      longitude: request.geo.longitude,
    },
    additionalData: request.addressComponents,
  }
}

export const mapGoogleGeoAddressToAddressLine = (
  geoRequest: GeoLocationInterface,
  geocoderResponse: google.maps.GeocoderResponse | undefined,
  addressName?: string,
  deliveryAddressSearchMatchRadius?: number,
  filterTypes: string[] = [],
): AddressLine | undefined => {
  if (!geocoderResponse?.results || geocoderResponse.results.length === 0) {
    return undefined
  }

  const results =
    filterTypes.length > 0
      ? geocoderResponse.results.filter((result) => result.types.some((type) => filterTypes.includes(type)))
      : geocoderResponse.results

  const result = findClosestGeocoderLocation(
    geoRequest.latitude,
    geoRequest.longitude,
    results,
    deliveryAddressSearchMatchRadius,
  )

  if (!result) {
    return undefined
  }

  return {
    name: addressName ? addressName : '',
    address: result.formatted_address,
    uid: 0,
    addressId: result.place_id,
    geo: {
      id: result.place_id,
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
    },
  }
}

export const mapGoogleGeoAddressToDetectedLocationAddressLine = (
  geoRequest: GeoLocationInterface,
  geocoderResponse: google.maps.GeocoderResponse | undefined,
  deliveryAddressSearchMatchRadius: number,
  filterTypes: string[] = [],
): AddressLine | undefined => {
  const addressLine = mapGoogleGeoAddressToAddressLine(
    geoRequest,
    geocoderResponse,
    'Current Location',
    deliveryAddressSearchMatchRadius,
    filterTypes,
  )
  if (!addressLine) return undefined

  addressLine.icon = 'mapArrowIconSolid'
  addressLine.suffix = 'caret'
  addressLine.structuredAddress = {
    title: addressLine.name,
    subtitle: addressLine.address ?? '',
  }

  return addressLine
}
