import { useLanguages } from '@dominos/hooks-and-hocs'

export interface HandleLanguageDeepLinkHandlerProps {
  language: string
}

export const useLanguageDeepLinkHandler = () => {
  const { setLanguage } = useLanguages()

  const handle = ({ language }: HandleLanguageDeepLinkHandlerProps) => {
    if (language) {
      setLanguage(language)
    }
  }

  return {
    handle,
  }
}
