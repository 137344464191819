import React from 'react'
import { useTranslation } from 'react-i18next'
import { duplicateChanges } from '../basket-line/functions'
import { DetailRowList } from '../detail-row-list'

interface ActionRowProps {
  items?: BasketLineChange[] | Bff.Orders.OrderDetails.Basket.ProductLineChange[] | null
  noLabel?: boolean
  testID: string
}

const ActionRow = ({ items, noLabel, testID }: ActionRowProps) => {
  const { t } = useTranslation('menu')

  if (items) {
    return (
      <DetailRowList
        label={noLabel ? undefined : t('Changes')}
        testID={testID}
        items={duplicateChanges(items).map((item, idx) => ({
          key: `${item.code}.${idx}`,
          label: `${item.action === 'Add' ? t('Add') : t('Remove')} ${item.media.name}`,
        }))}
        expandable
      />
    )
  }

  return null
}

export default ActionRow
