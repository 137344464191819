import { useReport } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'
import { getAcceptedVouchers, getDeclinedVouchers } from './voucher-input-helpers'

export const useVoucherInput = (
  basketLines: BasketLines[] | undefined,
  validationErrors: ValidateBasketValidationError[] | undefined,
) => {
  const { reportVoucherDeclined, reportVoucherAccepted } = useReport()

  const [previousVoucherCodes, setUsedVoucherCodes] = useState<string[]>([])
  const [voucherCode, setVoucherCode] = useState<string>('')
  const [voucherErrors, setVoucherErrors] = useState<string[]>([])
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  useEffect(() => {
    const { currentVoucherCodes, acceptedVouchers } = getAcceptedVouchers(basketLines, previousVoucherCodes)
    const declinedVouchers = getDeclinedVouchers(validationErrors)

    if (!firstLoad) {
      const multipleVouchers = acceptedVouchers.length + declinedVouchers.length > 1

      acceptedVouchers.forEach((voucher) => {
        reportVoucherAccepted(voucher.code, voucher.media.name, voucher.discount, multipleVouchers)
      })

      declinedVouchers.forEach((voucher) => {
        reportVoucherDeclined(voucher.processingStatus, voucher.message, multipleVouchers)
      })
    }

    setUsedVoucherCodes(currentVoucherCodes)
    setVoucherErrors(declinedVouchers.map(({ message }) => message))
  }, [validationErrors, basketLines])

  useEffect(() => {
    if (!(voucherErrors && voucherErrors.length > 0)) {
      // there is an error
      setVoucherCode('')
    }
  }, [voucherErrors])

  useEffect(() => {
    setFirstLoad(false)
  })

  return { voucherCode, setVoucherCode, voucherErrors }
}
