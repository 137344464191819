import { useLDClient } from 'launchdarkly-react-client-sdk'
import React, { useEffect, useState } from 'react'
import { SplashScreen } from '@dominos/components/splash-screen'

interface LaunchDarklyReadinessContextProps {
  isClientReady: boolean
}

const LaunchDarklyReadinessContext = React.createContext<LaunchDarklyReadinessContextProps>({
  isClientReady: false,
})

const INIT_TIMEOUT_IN_SECONDS = 5

export const LaunchDarklyReadiness = ({ children }: PropsWithChildren) => {
  const ldClient = useLDClient()
  const [ready, setReady] = useState(false)

  useEffect(() => {
    ldClient?.waitForInitialization(INIT_TIMEOUT_IN_SECONDS).finally(() => setReady(true))
  }, [ldClient])

  return (
    <LaunchDarklyReadinessContext.Provider value={{ isClientReady: ready }}>
      {!ready ? <SplashScreen testID='launch-darkly-readiness' /> : children}
    </LaunchDarklyReadinessContext.Provider>
  )
}
