import React from 'react'
import { StaticSvgIcon } from '@dominos/res/images'
import css from './portion-product-list.less'

interface PortionProductListProps {
  testID: string
  productList: { code: string; name: string }[]
  onValueChange: (code: string) => void
  t: (key: string, defaultValue?: string) => string
  theme: { colours: { overlayColor: string } }
}

const PortionProductList: React.FC<PortionProductListProps> = ({ testID, productList, onValueChange, t, theme }) => (
  <>
    <div className={css.listHeader}>{t('PortionProductCard.ListTitle', 'Select Portion')}</div>
    <div className={css.listContainer}>
      <div className={css.selectContainer} data-testid={`${testID}.mobile-select-menu.list`}>
        {productList.map((product) => (
          <div className={css.sectionTextContainer} onClick={() => onValueChange(product.code)} key={product.code}>
            <span className={css.sectionHeader}>{product.name}</span>
            <StaticSvgIcon name='chevron' isUnstyled fill={theme.colours.overlayColor} />
          </div>
        ))}
      </div>
    </div>
  </>
)

export default PortionProductList
