type DimensionSet = Bff.Dimensions.DimensionSet
import { DimensionFilter } from '@dominos/business/functions/menu/'

const filterDimensionSets = (dimensionSets: DimensionSet[], dimensionFilter?: DimensionFilter) => {
  if (!dimensionFilter || dimensionFilter.values.length === 0) return dimensionSets

  return dimensionSets.filter((dimensionSet) => {
    if (dimensionFilter.type === 'Size') {
      if (dimensionSet.type === 'Size' || dimensionSet.type === 'SizeBase') {
        return dimensionFilter.values.some((size) => size === dimensionSet.size)
      }
    } else if (dimensionFilter.type === 'Base' && dimensionSet.type === 'SizeBase') {
      return dimensionFilter.values.some((base) => base === dimensionSet.base)
    }

    return true
  })
}

export { DimensionFilter, filterDimensionSets }
