import React, { useCallback } from 'react'
import { StaticSvgIcon } from '@dominos/res'
import { useTranslation } from 'react-i18next'
import css from './card-header.less'
import { CardHeaderProps } from './card-header.interface'
import { isNativeApp } from '@dominos/business/functions'
import { useAuthenticationPopup } from '@dominos/hooks-and-hocs'

const ICON_SIZE = 24

const CardHeader = ({ testID, title, onAction, inline }: CardHeaderProps) => {
  const { t } = useTranslation('login')
  const { popupVisible, hidePopup } = useAuthenticationPopup()

  const handleActionClick = useCallback(() => onAction && onAction(), [onAction])
  const handleDismiss = () => hidePopup()
  const hideDismiss = inline || !popupVisible || isNativeApp()

  return (
    <div data-testid={testID} className={css.header}>
      <div data-testid={`${testID}.accessory`}>
        {onAction ? (
          <button data-testid={`${testID}.action`} className={css.action} onClick={handleActionClick}>
            <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={'#007aff'} direction={'left'} />
            <div>{t('BackButtonLabel', { defaultValue: 'Back' })}</div>
          </button>
        ) : null}
      </div>

      <h2 data-testid={`${testID}.title`} className={css.title}>
        {title}
      </h2>

      <div data-testid={`${testID}.accessory`} className={css.accessory}>
        {!hideDismiss ? (
          <button data-testid={`${testID}.dismiss`} className={css.dismiss} onClick={handleDismiss}>
            <StaticSvgIcon name={'cross'} width={ICON_SIZE} height={ICON_SIZE} isUnstyled={true} fill={'#ffffff'} />
          </button>
        ) : null}
      </div>
    </div>
  )
}

export { CardHeader }
