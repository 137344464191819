import { useMemo } from 'react'
import { PaymentSetting } from '@dominos/hooks-and-hocs'
import { getAdyenPaymentMethods } from './payment-method/adyen'

export const useFilterSavedPaymentSettings = (paymentSettings: PaymentSetting[]) => {
  const filterPaymentSettings = (
    paymentProvider: BffContext.PaymentProviders,
    paymentMethod: BffContext.PaymentMethods,
    adyenPaymentMethod?: string,
  ) =>
    paymentSettings.filter(
      (p) =>
        p.savedPayment &&
        p.providerCode === paymentProvider &&
        p.paymentMethod === paymentMethod &&
        (!adyenPaymentMethod || getAdyenPaymentMethods(p)[0]?.name === adyenPaymentMethod),
    )

  const savedCreditCardSettings = useMemo(() => filterPaymentSettings('Adyen', 'CreditCard'), [paymentSettings])
  const savedMisterCashSettings = useMemo(
    () => filterPaymentSettings('Adyen', 'MisterCash', 'Bancontact card'),
    [paymentSettings],
  )

  return {
    filterPaymentSettings,
    savedCreditCardSettings,
    savedMisterCashSettings,
  }
}
