import { TFunction } from 'i18next'

/**
 * Determines if the quantity of ingredients is within the configured
 * range for the products topping constraints
 * @param rules Ingredient rules
 * @param quantity Total number of ingredients
 * @param previousQuantity Previous total number of ingredients
 * @param currents The array of currents created by `productIngredientsTransform`
 * @param t Translations for the menu scene
 * @returns Validation failure messages or none (success)
 */
// ! From `@dominos/business/functions/menu`
export const getProductRulesLogic = (
  rules: IngredientRules | undefined,
  quantity: number,
  previousQuantity: number,
  currents: IngredientItemProps[],
  t: TFunction,
) => {
  if (!rules) {
    return []
  }
  const totalToppingCount = currents.reduce((a: number, b: IngredientItemProps) => a + b.quantity, 0)

  return [
    {
      expression: () => quantity > rules.maxPerIngredient,
      title: t('MaximumSingleTopping'),
      message: t('MaximumSingleToppingMessage', {
        defaultValue: `Maximum {{maxPerIngredient}} serves of any single topping allowed on each pizza or pizza half`,
        maxPerIngredient: rules.maxPerIngredient,
      }),
    },
    {
      expression: () => previousQuantity < quantity && totalToppingCount >= rules.max,
      title: t('MaximumToppings'),
      message: t('MaximumToppingsMessage', {
        defaultValue: `A maximum of {{max}} topping serves allowed on each pizza or pizza half`,
        max: rules.max,
      }),
    },
    {
      expression: () => previousQuantity > quantity && totalToppingCount <= rules.min,
      title: t('MinimumToppings'),
      message: t('MinimumToppingsMessage', {
        defaultValue: 'A minimum of {{min}} topping serves allowed on each pizza or pizza half',
        min: rules.min,
      }),
    },
  ]
}
