import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { detailsFieldNames } from '../details-container-config'
import styles from './vip-offers.less'
import { VipOffersCheckbox, VipOffersProps, VipOffersTC } from '.'
import { DetailsContainerBanner } from '..'

export const VipOffersContextual = (props: VipOffersProps) => {
  const { t } = useTranslation('checkout')

  useEffect(() => {
    props.onChange(detailsFieldNames.vipOffersEmail, !!props.initialVipOffersEmailChecked)
    props.onChange(detailsFieldNames.vipOffersSMS, !!props.initialVipOffersSMSChecked)
  }, [])

  return (
    <>
      <DetailsContainerBanner />
      <div className={styles.containerContextual}>
        <div className={styles.subHeading}>
          {t('ContexualEmailSubHeading', {
            defaultValue: 'Get exclusive offers and delicious deals, plus lots more benefits, direct to your inbox!',
          })}
        </div>
        <div className={styles.checkbox}>
          <VipOffersCheckbox
            testID={`vip-offers-contextual.${detailsFieldNames.vipOffersEmail}`}
            onChange={props.onChange}
            defaultChecked={props.initialVipOffersEmailChecked}
            name={detailsFieldNames.vipOffersEmail}
            label={t('VIPOffersEmail', { defaultValue: 'Get VIP Offers via **email**' })}
            labelClassName={styles.contextualCheckboxLabel}
          />
        </div>
        <hr className={styles.hr} />
        <div className={styles.checkbox}>
          <VipOffersCheckbox
            testID={`vip-offers-contextual.${detailsFieldNames.vipOffersSMS}`}
            onChange={props.onChange}
            defaultChecked={props.initialVipOffersSMSChecked}
            name={detailsFieldNames.vipOffersSMS}
            label={t('VIPOffersSMS', { defaultValue: 'Get VIP Offers via **SMS**' })}
            labelClassName={styles.contextualCheckboxLabel}
          />
        </div>
        {props.vipOffersTCEnabled && <VipOffersTC />}
      </div>
    </>
  )
}
