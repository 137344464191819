import React from 'react'
import { useTranslation } from 'react-i18next'

import { BasketLine } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import css from './voucher-line.less'
import { getFormattedPrice } from '@dominos/business/functions'

const ComboCouponFormat = 'combo'

export const VoucherLine = ({
  voucherLine,
  testID,
}: {
  voucherLine: Bff.Orders.OrderDetails.Basket.VoucherLine
  testID: string
}) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('basket')
  const isComboVoucher = voucherLine.dealType?.toLocaleLowerCase() === ComboCouponFormat

  return (
    <div
      data-testid={testID}
      style={{ backgroundColor: theme.colours.backgroundColor, borderRadius: theme.webBorderRadius }}
      className={css.container}
    >
      <div data-testid={`${testID}.header`} className={css.header}>
        <div data-testid={`${testID}.title`} className={css.title}>
          <div data-testid={`${testID}.title-text`} className={css.label}>
            {t('voucher', { defaultValue: 'Voucher' })}
          </div>
          {isComboVoucher && (
            <div data-testid={`${testID}.price`} style={{ fontWeight: 'bold' }}>
              {`${getFormattedPrice(voucherLine.discount)}`}
            </div>
          )}
        </div>
        <div data-testid={`${testID}.header-name`} className={css.name}>
          {voucherLine.voucherMedia?.name ? voucherLine.voucherMedia.name : voucherLine.code}
        </div>
      </div>
      {voucherLine.items &&
        voucherLine.items.map((item) => (
          <BasketLine
            key={`${item.productCode}-${item.itemNo}`}
            testID={`${testID}.line`}
            lineData={item}
            hideEditButton
            hideRemoveButton
            hidePrice={isComboVoucher}
          />
        ))}
    </div>
  )
}
