import { CrustIcon, ProductIngredientCard, SauceIcon, SizeIcon } from '@dominos/components'
import { useBreakpoints } from '@dominos/hooks-and-hocs'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getScrollProvider, scrollTo } from '../product-card/functions'
import { CustomisationCard } from './customisation-card'
import { ProductCustomiserProps } from '@dominos/components/product/product-customiser/'

export const NUM_COLUMNS = 3

export const ProductCustomiser = ({
  sizeData,
  onSizeChange,
  baseData,
  onBaseChange,
  sauceData,
  onSauceChange,
  toppingData,
  onToppingChange,
  calculateCurrentIngredientCount,
}: ProductCustomiserProps) => {
  const { t } = useTranslation('menu')
  const { isMobile } = useBreakpoints()
  const scrollRef = useRef<HTMLDivElement>(null)
  const isSizeCustomisable = sizeData && sizeData.items.length > 0
  const isBaseCustomisable = baseData && baseData.items.length > 0
  const isSauceCustomisable = sauceData && sauceData.items.length > 0
  const isToppingCustomisable =
    toppingData && toppingData.currentIngredients.length > 0 && !!calculateCurrentIngredientCount
  const setScrollOffset = (distance: number) => scrollTo(distance, getScrollProvider(isMobile, scrollRef.current))

  return (
    <>
      {isSizeCustomisable && (
        <CustomisationCard
          testID={'Size-Selector'}
          items={sizeData.items}
          selectedItemCode={sizeData.selectedItemCode}
          primaryTitle={t('Size')}
          numColumns={NUM_COLUMNS}
          renderItem={SizeIcon}
          onItemSelected={onSizeChange}
        />
      )}
      {isBaseCustomisable && (
        <CustomisationCard
          testID={'Base-Selector'}
          items={baseData.items}
          selectedItemCode={baseData.selectedItemCode}
          primaryTitle={t('Base')}
          numColumns={NUM_COLUMNS}
          renderItem={CrustIcon}
          onItemSelected={onBaseChange}
        />
      )}
      {isSauceCustomisable && (
        <CustomisationCard
          testID={'Sauce-Selector'}
          items={sauceData.items}
          selectedItemCode={sauceData.selectedItemCode}
          primaryTitle={t('Sauce')}
          numColumns={NUM_COLUMNS}
          renderItem={SauceIcon}
          onItemSelected={onSauceChange}
        />
      )}
      {isToppingCustomisable && (
        <ProductIngredientCard
          primaryTitle={t('Ingredients on this Pizza')}
          recipeIngredientCodes={toppingData?.recipeIngredientCodes}
          currentIngredients={toppingData?.currentIngredients}
          possibleIngredients={toppingData?.possibleIngredients}
          rules={toppingData?.ingredientRules}
          onItemChange={onToppingChange}
          toppingLineChange={toppingData?.changes}
          onSectionChange={setScrollOffset}
          calculateCurrentIngredientCount={calculateCurrentIngredientCount}
        />
      )}
    </>
  )
}
