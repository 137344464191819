import { countFromBasketLines } from '@dominos/business/functions/basket'
import { OrderButton } from '@dominos/components'
import { useFeatures } from '@dominos/hooks-and-hocs'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import css from '../order-details-card.less'
import { ExpressCheckout } from '../../../../checkout'

export interface OrderDetailsOrderButtonProps {
  testID: string
  basketError?: Error | string | null
  onPlaceOrder: () => void
  hideOrderButton?: boolean
  loading?: boolean
}

export const OrderDetailsOrderButton = ({
  testID,
  basketError,
  onPlaceOrder,
  hideOrderButton = false,
  loading,
}: OrderDetailsOrderButtonProps) => {
  const { t } = useTranslation('menu')
  const [expressCheckoutEnabled] = useFeatures('ExpressCheckout')
  const basketData = useSelector((state: RootReducer) => state.basketReducer.basket)

  const itemCount = useMemo(() => countFromBasketLines(basketData.lines), [basketData.lines])
  const enableOrderButton = itemCount > 0 && !basketError

  return (
    <>
      {!hideOrderButton ? (
        <div className={css.stickyButtonContainer}>
          <div>
            <OrderButton
              testID={`${testID}.place-order`}
              itemCount={itemCount}
              price={basketData.total || 0}
              onPress={onPlaceOrder}
              disabled={!enableOrderButton}
              text={t('Place Order')}
              fill
              loading={loading}
            />
          </div>
          {expressCheckoutEnabled && (
            <ExpressCheckout testID={`${testID}.express-checkout`} loading={loading} disabled={!enableOrderButton} />
          )}
        </div>
      ) : (
        <div style={{ paddingBottom: 0 }} />
      )}
    </>
  )
}
