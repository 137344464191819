import { SvgIconsProps } from '@dominos/res/interfaces/icon.interfaces'
import React, { useMemo } from 'react'
import { svgIcons } from './icons'

export const StaticSvgIcon = ({ isUnstyled, scale, selected, includePadding, ...props }: SvgIconsProps) => {
  const Icon = svgIcons[props.name]
  const fillColor = useMemo(() => (selected ? '#007aff' : props.fill), [selected, props.fill])

  if (isUnstyled) {
    return <Icon {...props} fill={fillColor} />
  }

  if (includePadding === false) {
    return (
      <div style={iconStyles.centerIcon}>
        <Icon {...props} fill={fillColor} />
      </div>
    )
  }

  return (
    <div style={{ ...iconStyles.icon, ...iconStyles.centerIcon }}>
      <Icon {...props} fill={fillColor} />
    </div>
  )
}

interface IconStyles {
  icon: React.CSSProperties
  centerIcon: React.CSSProperties
}

const iconStyles: IconStyles = {
  icon: {
    padding: 5,
  },
  centerIcon: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}
