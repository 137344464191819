import { DominosTheme, useDominosTheme } from '@dominos/hooks-and-hocs'
import {
  IAddressPrediction,
  IUseDeliveryAddressInputAssistantComponentProps,
  useDeliveryAddressInputAssistantComponent,
} from 'olo-feature-address'
import React, { useMemo } from 'react'

export type DeliveryAddressInputAssistantProps = IUseDeliveryAddressInputAssistantComponentProps

export const DeliveryAddressInputAssistant = (props: DeliveryAddressInputAssistantProps) => {
  const { suggestionText } = useDeliveryAddressInputAssistantComponent(props)
  const theme = useDominosTheme()

  const style = useMemo(() => styleHelper(theme, hasPredictions(props.predictions)), [theme, props.predictions])

  return (
    <div style={style.componentWrapper}>
      <div style={style.suggestionText}>{suggestionText}</div>
    </div>
  )
}

interface Style {
  componentWrapper: React.CSSProperties
  suggestionText: React.CSSProperties
}

const styleHelper = (theme: DominosTheme, hasPredictions: boolean): Style => {
  const textColour = theme.colours.dominosSlate
  const height = hasPredictions ? 'auto' : '12px'
  const margin = hasPredictions ? '0' : '-4px 0 -4px 0'

  return {
    componentWrapper: {
      display: 'flex',
      width: '100%',
      backgroundColor: 'white',
      padding: '0 10px 0 10px',
      margin: margin,
      height: height,
      alignItems: 'flex-start',
    },
    suggestionText: {
      margin: '0',
      color: textColour,
      fontSize: '12px',
    },
  }
}

const hasPredictions = (predictions: IAddressPrediction[] | undefined): boolean =>
  !!predictions && predictions.length > 0
