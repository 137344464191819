import React from 'react'
import { StrikeThrough } from '@dominos/components/text'
import { ProductPrice } from '../product-price'

interface StrikeThroughPriceProps extends BaseProps {
  price: string
}

export const StrikeThroughPrice = ({ testID, price }: StrikeThroughPriceProps) => {
  const originalPriceDisplay = <ProductPrice key={`${testID}.price`} testID={`${testID}.price`} price={price} />

  return <StrikeThrough testID={`${testID}-price`} text={originalPriceDisplay} />
}
