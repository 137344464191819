import { CountryToggle } from '@dominos/components'
import { useBreakpoints, useCountryCode } from '@dominos/hooks-and-hocs'
import { staticImages } from '@dominos/res/assets/header-images/static-images'
import React from 'react'
import css from './hero-banner.less'

export const HeroBanner = ({ testID }: { testID: string }) => {
  const countryCode = useCountryCode()
  const code = (countryCode || 'default').toLowerCase()
  const heroBanner = CountryToggle(staticImages)
  const { isDesktop, isTablet, isMobile } = useBreakpoints()

  const imagePath = isDesktop ? 'large' : isTablet ? 'medium' : isMobile ? 'small' : 'large'

  return (
    <div className={css.image} data-testid={`${testID}-${code}`}>
      <img src={heroBanner[imagePath]} alt="Domino's home screen" />
    </div>
  )
}
