import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 23,
  height: 22,
}

const AccountIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#979797'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 23 22'>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Native-App-Bottom-Bar-Loyalty-NL'
          transform='translate(-23.000000, -10.000000)'
          fill={fill}
          fillRule='nonzero'
        >
          <g id='Native-App-Bottom-Bar' transform='translate(-1.000000, 0.000000)'>
            <g id='Bottom-Bar' transform='translate(0.500000, 0.000000)'>
              <g id='Regular-S' transform='translate(24.000000, 10.000000)'>
                <path
                  d='M11.066055,21.7663573 C16.987685,21.7663573 21.915685,16.8518075 21.915685,10.9301775 C21.915685,5.0085475 16.974265,0.093995 11.052635,0.093995 C5.144425,0.093995 0.2298835,5.0085475 0.2298835,10.9301775 C0.2298835,16.8518075 5.1578725,21.7663573 11.066055,21.7663573 Z M11.066055,14.5422475 C7.991115,14.5422475 5.6144,15.576165 4.4999075,16.78468 C3.11685,15.2270525 2.2977625,13.18603 2.2977625,10.9301775 C2.2977625,6.0424925 6.17837,2.1350175 11.052635,2.1350175 C15.94032,2.1350175 19.847795,6.0424925 19.8612425,10.9301775 C19.8612425,13.18603 19.0287075,15.2404725 17.65907,16.78468 C16.5445775,15.576165 14.1544425,14.5422475 11.066055,14.5422475 Z M11.066055,12.7697625 C13.0936575,12.79663 14.691545,11.06446 14.691545,8.79516 C14.691545,6.66017 13.0936575,4.9011325 11.066055,4.9011325 C9.0519,4.9011325 7.440565,6.66017 7.4540125,8.79516 C7.4674325,11.06446 9.03848,12.7429225 11.066055,12.7697625 Z'
                  id='Shape'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { AccountIcon }
