import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './tracker-loading.less'

const TrackerLoading = () => {
  const theme = useDominosTheme()
  const { t } = useTranslation()

  const themedCardStyle = {
    boxShadow: theme.defaultShadow,
    borderRadius: theme.webBorderRadius,
  }

  return (
    <div className={styles.orderTracker} style={themedCardStyle}>
      <h2 className={styles.orderTrackerTitle}>{t('OrderTrackerTitle', { defaultValue: 'Live Pizza Tracker' })}</h2>
      <div className={styles.placeholderTracker}>{t('LoadingTracker', { defaultValue: 'Loading tracker' })}</div>
    </div>
  )
}

export default TrackerLoading
