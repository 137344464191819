import { getCustomerId, getFormattedPrice, getPaymentCardFormat, isNativeApp } from '@dominos/business/functions'
import { ActionButton, BoxedMessage, ErrorScope, PaymentCard, useErrorContext } from '@dominos/components'
import usePaymentFeatureToggles from '@dominos/components/checkout/checkout-container/payment-container/use-payment-feature-toggles'
import { paymentBalanceErrors } from '@dominos/components/error/definitions'
import { useEdenredURL, useLocalStorage, usePaymentBalance } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { StaticSvgIcon } from '@dominos/res/images'
import { navigate, useLocation } from '@reach/router'
import { SHA256 } from 'crypto-js'
import { SavedPaymentProps } from 'packages/interfaces'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import uuid from 'uuid'
import css from './my-saved-edenred.less'

interface MySavedEdenredProps {
  paymentMethods?: Bff.Customers.CustomerPaymentMethod[]
  tokenId?: string
  removePaymentMethod?: (token: string, providerCode?: string | undefined, paymentMethod?: string | undefined) => void
  savedPaymentRemoved?: SavedPaymentProps
}

const styles = {
  button: {
    height: 54,
    width: '100%',
    backgroundColor: '#e30613',
  },
}

export const MySavedEdenred = ({
  paymentMethods,
  tokenId,
  removePaymentMethod,
  savedPaymentRemoved,
}: MySavedEdenredProps) => {
  const { t } = useTranslation('checkout')
  const { t: tValidation } = useTranslation('validation')
  const featureToggles = usePaymentFeatureToggles()
  const [authorisationCode, setAuthorisationCode] = useState<string | null>(null)
  const [redirecting, setRedirecting] = useState(false)
  const {
    storedValue: edenredNonce,
    setValue: setEdenredNonce,
    clearValue: clearEdenredNonce,
  } = useLocalStorage<string>({ key: 'edenred-nonce' })
  const { getEdenredURL, isEdenredAuthScope } = useEdenredURL()
  const { origin, pathname, search } = useLocation()
  const { fetchPaymentBalance, paymentBalance, error, loading } = usePaymentBalance()
  const { notifyError } = useErrorContext()

  const customerId = getCustomerId() ?? undefined
  const paymentCardFormat = getPaymentCardFormat('Edenred')

  const onEdenredLogin = () => {
    setRedirecting(true)
    const nonce = SHA256(uuid()).toString()
    setEdenredNonce(nonce)
    const edenredUrl = getEdenredURL(customerId, nonce, 'saved-payment')
    navigate(edenredUrl)
  }

  useEffect(() => {
    if (!search || loading) {
      return
    }

    const searchParams = new URLSearchParams(search)
    if (!isEdenredAuthScope(searchParams.get('scope')) || searchParams.get('state') !== customerId) {
      return
    }

    setAuthorisationCode(searchParams.get('code'))

    if (!isNativeApp()) {
      window.history.replaceState({}, '', pathname)
    }
  }, [search, loading])

  useEffect(() => {
    if (!authorisationCode) {
      fetchPaymentBalance('Edenred', 'Edenred', customerId)

      return
    }

    if (!edenredNonce) {
      return
    }

    const redirectUri = `${origin}${
      isNativeApp() ? NavigationConstants.nativeAppSavedPayment : NavigationConstants.savedPayment
    }`
    fetchPaymentBalance('Edenred', 'Edenred', customerId, authorisationCode, edenredNonce, redirectUri)
    clearEdenredNonce()
  }, [customerId, authorisationCode])

  useEffect(() => {
    if (error) {
      notifyError({ error, handlers: {}, definitions: paymentBalanceErrors, scope: ErrorScope.PaymentBalance })
    }
  }, [error])

  if (!featureToggles.edenredEnabled) {
    return null
  }

  return paymentMethods?.some((pm) => pm.paymentMethod === 'Edenred') ? (
    <div className={css.wrapper}>
      {loading || paymentBalance?.success ? (
        <>
          <PaymentCard
            {...paymentCardFormat}
            key={tokenId}
            testID='savedEdenred'
            label={t('Edenred', { defaultValue: 'Edenred' })}
            price={loading || !paymentBalance?.amount ? 0 : paymentBalance.amount}
            pricePrefix={t('Balance', { defaultValue: 'Balance' })}
            removing={savedPaymentRemoved?.paymentTokenRemoved === tokenId}
            onRemove={!loading ? removePaymentMethod : undefined}
            tokenId={tokenId}
            providerCode='Edenred'
            paymentMethod='Edenred'
            loading={loading}
          />
          {!loading && paymentBalance?.amount === 0 && (
            <BoxedMessage testID='payment-balance-zero-balance' icon='exclamation' role='status' color='secondary'>
              {tValidation('PaymentBalanceZeroBalanceMessage', {
                defaultValue: 'Your balance today is {{amount}}. Please choose another payment method for your order.',
                amount: getFormattedPrice(0),
              })}
            </BoxedMessage>
          )}
        </>
      ) : (
        <>
          <ActionButton
            loading={redirecting}
            disabled={redirecting}
            containerStyle={styles.button}
            onPress={onEdenredLogin}
            testID='edenred-login-btn'
          >
            <div className={css.edenredButtonContent}>
              <div className={css.edenredIcon}>
                <StaticSvgIcon name='edenred' isUnstyled />
              </div>
              <p className={css.edenredButtonText}>{t('LoginEdenred', { defaultValue: 'Sign in to MyEdenred' })}</p>
            </div>
          </ActionButton>
          <div id='payment-balance-error' />
        </>
      )}
    </div>
  ) : null
}
