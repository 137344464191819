import React from 'react'

export const formatSuperscript = (text: string) => {
  const re = /\[(.+?)\]/g
  const matches = Array.from(matchAll(re, text))
  let startIndex = 0

  const result = matches.map((match, i) => {
    const normalText = text.substring(startIndex, match.index)
    const subText = match[0]
    const isLast = i == matches.length - 1
    startIndex = (match.index || 0) + subText.length

    return (
      <>
        {normalText}
        <sup>{subText}</sup>
        {isLast && text.substring(startIndex)}
      </>
    )
  })

  return result.length ? result : text
}

const matchAll = (re: RegExp, str: string) => {
  let match
  const matches = []

  while ((match = re.exec(str))) {
    matches.push(match)
  }

  return matches
}
