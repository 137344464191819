import { useLazyQuery, WatchQueryFetchPolicy } from '@apollo/client'
import { orderQuery } from '@dominos/business/queries'
import { OrderResponse } from '@dominos/interfaces'

export const useOrder = () => {
  const [fetch, { data, error }] = useLazyQuery<OrderResponse>(orderQuery, {
    fetchPolicy: 'no-cache',
  })

  const fetchOrder = (orderId: string, fetchPolicy: WatchQueryFetchPolicy = 'no-cache') => {
    fetch({ context: { fetchPolicy }, variables: { id: orderId } })
  }

  return {
    errors: error,
    fetchOrder,
    order: data?.order,
  }
}
