import React from 'react'
import { ErrorCardPopup } from '@dominos/hooks-and-hocs/card-popups'
import { DisplayableError } from '../interfaces'
import { BoxedMessage, ClosablePopUpNotification, PopUpNotification, Portal } from '@dominos/components'

export const ErrorDisplay = ({
  displayType,
  title = 'Error',
  message,
  onClose,
  icon,
  testID,
  id = 'inline-error',
  confirmLabel,
}: DisplayableError) => {
  if (displayType === 'modal') {
    return (
      <ErrorCardPopup
        title={title}
        message={message}
        onClose={onClose}
        testID={testID}
        icon={icon}
        confirmLabel={confirmLabel}
      />
    )
  }

  if (displayType === 'popup') {
    return <PopUpNotification heading={message} onClose={onClose} notificationType='Info' />
  }

  if (displayType === 'closable-popup') {
    return <ClosablePopUpNotification testID={testID} heading={title} onClose={onClose} message={message} icon={icon} />
  }

  if (displayType === 'error-text') {
    return (
      <Portal id={id}>
        <div data-testid={testID}>
          <p>{message}</p>
        </div>
      </Portal>
    )
  }

  if (displayType === 'boxed-text') {
    return (
      <Portal id={id}>
        <BoxedMessage testID={testID} icon={icon} color='secondary'>
          <span>{message}</span>
        </BoxedMessage>
      </Portal>
    )
  }

  return null
}
