import React from 'react'
import { StaticSvgIcon } from '@dominos/res'

import css from './error.less'

export const ErrorInline = ({ testID, message }: BaseProps & { message: string }) => (
  <div data-testid={testID} className={css.errorInline}>
    <StaticSvgIcon name={'infoIcon'} fill={'#e21836'} isUnstyled width={17} height={17} />
    {message}
  </div>
)
