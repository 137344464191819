import { isProductMenuItemNew } from '@dominos/components'

type Menu = Bff.Menu.old.Menu

export const getIsNewYorker = (menu?: Menu, item?: { type: string; code: string }) =>
  !!item &&
  !!menu &&
  item.type.toLowerCase() === 'product' &&
  !!menu.pages.find(
    (page) =>
      page.code === 'Menu.Pizza' &&
      !!page.sections &&
      !!page.sections.find(
        (section) =>
          section.code.indexOf('NEWYORKER') >= 0 &&
          !!section.items &&
          !!section.items.find((sectionItem) => !isProductMenuItemNew(sectionItem) && sectionItem.code === item.code),
      ),
  )
