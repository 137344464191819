import React, { useEffect, useState } from 'react'
import { useLastOrderCheck } from '@dominos/hooks-and-hocs/last-order-handler'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, RouteComponentProps } from '@reach/router'

export const LastOrderHandler: React.FC<RouteComponentProps> = ({ children }: { children?: React.ReactNode }) => {
  const [waitingForNavigation, setWaitingForNavigation] = useState(true)
  const { startOnMenu, loading } = useLastOrderCheck()

  useEffect(() => {
    if (startOnMenu) {
      navigate(NavigationConstants.menu, { state: { userHasStartedJourneyOnMenu: true } }).then(() =>
        setWaitingForNavigation(false),
      )
    } else if (!loading) {
      setWaitingForNavigation(false)
    }
  }, [startOnMenu, loading])

  return <>{!loading && !waitingForNavigation && <>{children}</>}</>
}
