import { CallStore, NotifyStore } from './sub-type-details'
import { svgIconsName } from '@dominos/res/images/icons'

interface TranslationType {
  key: string
  defaultValue: string
}

export interface ServiceMethodDetailsProps {
  orderId: string
  storePhoneNo: string | undefined
  icon: svgIconsName
  title: TranslationType
  message: TranslationType
}

export interface ServiceMethodDetailsDefinition {
  icon: svgIconsName
  title: TranslationType
  message: TranslationType
  component: React.FC<ServiceMethodDetailsProps>
}

export const ServiceMethodDetailsDefinitions: PartialRecord<
  Bff.Stores.ServiceMethodSubTypes,
  ServiceMethodDetailsDefinition
> = {
  CarParkDelivery: {
    icon: 'carParkDelivery',
    title: {
      key: 'CarParkDelivery',
      defaultValue: 'Car Park Delivery',
    },
    message: {
      key: 'StoreHasBeenNotifiedForCarParkDelivery',
      defaultValue: "We'll bring your order to you",
    },
    component: NotifyStore,
  },
  PickUpPoint: {
    icon: 'outsideStore',
    title: {
      key: 'OutsideStore',
      defaultValue: 'Outside Store',
    },
    message: {
      key: 'StoreHasBeenNotifiedOutsideStore',
      defaultValue: "We'll bring your order out in a moment",
    },
    component: NotifyStore,
  },
  RobotDelivery: {
    icon: 'robotDelivery',
    title: {
      key: 'RobotDelivery',
      defaultValue: 'Robot Delivery',
    },
    message: {
      key: 'RobotDeliveryMessage',
      defaultValue: 'You will receive a text message confirming robot delivery',
    },
    component: CallStore,
  },
}
