import { DateTime } from 'luxon'

// eg. https://gpstracker.dominos.com.sg/#/ordertrack?storeId=96910&cscId=156755&date=20221209
export const getWhensoGpsTrackerUrl = (baseUrl: string, storeNo: number, pulseId: string, orderDate: string) => {
  if (!baseUrl || !storeNo || !pulseId || !orderDate) {
    return
  }

  const formattedDate = DateTime.fromISO(orderDate, { setZone: true }).toFormat('yyyyMMdd')
  const pulseIdNumberOnly = pulseId.split('#')[1]

  return baseUrl
    .replace('{{storeId}}', storeNo.toString())
    .replace('{{orderId}}', pulseIdNumberOnly)
    .replace('{{date}}', formattedDate)
}
