import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { useVendorsContext } from '@dominos/hooks-and-hocs'
import React from 'react'

export interface SecurityContextProps {
  client: ApplicationInsights | null
}

export const SecurityContext = React.createContext<SecurityContextProps>({} as SecurityContextProps)

export const SecurityProvider = ({ children }: { children: React.ReactNode }) => {
  const { securityClient } = useVendorsContext()

  return <SecurityContext.Provider value={securityClient}>{children}</SecurityContext.Provider>
}
