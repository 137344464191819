import { useBreakpoints } from '@dominos/hooks-and-hocs'
import { GetMenuProps } from '../menu-context'
import { MenuPageHeader } from '../menu-context.interface'
import { useMenuDataFull } from './use-menu-data-full'
import { useMenuDataPageHeaders } from './use-menu-data-page-headers'

type Menu = Bff.Menu.old.Menu

const convertToPartialMenuPage = (menuData?: Menu) =>
  menuData?.pages.map((page) => ({ code: page.code, media: page.media })) as MenuPageHeader[]

export const useMenuData = () => {
  const { isMobile } = useBreakpoints()

  const { getMenu, menuData, error: menuDataError, loading: menuDataLoading, refetch: refetchMenu } = useMenuDataFull()

  const {
    getMenuPageHeaders,
    menuPageHeadersData,
    error: menuPageHeadersDataError,
    loading: menuPageHeadersDataLoading,
    refetch: refetchMenuPageHeaders,
  } = useMenuDataPageHeaders()

  const menuPageHeaders = convertToPartialMenuPage(isMobile ? menuPageHeadersData : menuData)

  const fetchData = (menuProps: GetMenuProps) => {
    if (isMobile) {
      getMenuPageHeaders(menuProps)
    }
    getMenu(menuProps)
  }

  const refetchData = () => {
    if (isMobile) {
      refetchMenuPageHeaders()
    }
    refetchMenu()
  }

  return {
    fetchData,
    refetchData,
    menuPageHeaders,
    loading: isMobile ? menuPageHeadersDataLoading : menuDataLoading,
    hasError: !!menuDataError || !!menuPageHeadersDataError,
    fullMenu: menuData,
  }
}
