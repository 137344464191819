export const mapJPSearchAddressToAddressLine = (customerAddresses?: SearchAddress[] | null): AddressLine[] => {
  if (!customerAddresses || customerAddresses.length === 0) {
    return []
  }

  return customerAddresses.map(
    (line): AddressLine => ({
      name: line.media.displayAddress,
      suffix: 'caret',
      uid: line.storeNo,
      additionalData: line,
    }),
  )
}
