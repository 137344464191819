import React from 'react'
import css from './badge-indicator.less'

interface BadgeIndicatorProps extends BaseProps {
  value?: string | number
  showValue?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
  testID: string
}

export const BadgeIndicator = ({ testID, value, showValue, children, style }: BadgeIndicatorProps) => (
  <span className={!children ? css.countWrapper : css.iconWrapper} style={style} data-testid={testID}>
    {showValue ? value : children || ''}
  </span>
)
