import React from 'react'

import css from './generic-card.less'

export const GenericCardTitle = (props: GenericCardTitleProps) => {
  const { title, testID, TitleTriggerComponent } = props

  if (TitleTriggerComponent) {
    return (
      <div className={css.cardTitleContainer}>
        <h2 className={css.cardTitle} data-testid={`generic-card.text.${testID}`}>
          {title}
        </h2>
        <div className={css.triggerContainer} data-testid={`generic-card.trigger.${testID}`}>
          {TitleTriggerComponent}
        </div>
      </div>
    )
  }

  return (
    <h2 className={css.cardTitle} data-testid={`generic-card.text.${testID}`}>
      {title}
    </h2>
  )
}
