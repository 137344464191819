import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge9 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-9' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M16.97,10.15 L25.03,10.15 C26.301,10.15 27.355,11.204 27.355,12.475 L27.355,29.525 C27.355,30.796 26.301,31.85 25.03,31.85 L16.815,31.85 C15.606,31.85 14.645,30.889 14.645,29.68 L14.645,27.944 C14.645,27.479 14.924,27.045 15.327,26.859 L18.706,25.278 C19.047,25.154 19.419,25.371 19.419,25.743 L19.419,27.386 C19.419,27.479 19.481,27.541 19.574,27.541 L22.395,27.541 C22.488,27.541 22.55,27.479 22.55,27.386 L22.55,23.883 C22.55,23.79 22.488,23.728 22.395,23.728 L16.97,23.728 C15.699,23.728 14.645,22.674 14.645,21.403 L14.645,12.475 C14.645,11.204 15.699,10.15 16.97,10.15 Z M19.295,19.419 L22.395,19.419 C22.488,19.419 22.55,19.357 22.55,19.264 L22.55,14.614 C22.55,14.521 22.488,14.459 22.395,14.459 L19.295,14.459 C19.202,14.459 19.14,14.521 19.14,14.614 L19.14,19.264 C19.14,19.357 19.202,19.419 19.295,19.419 Z'
          id='9'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
