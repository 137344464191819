// This function is used for end-to-end (e2e) testing.
// It determines the customization options available for a product (e.g., base, size, sauce, etc.).
//
// Functionality:
// - If the product has no customization options, it returns an empty string.
// - If the product has customization options, it returns a string with the available options in the format:
//   "minimalist-menu-card: customisation.base, customisation.size"
//
// This function is particularly useful in e2e tests to detect the customization options for each product.

type SwapSetProduct = Bff.Menu.old.SwapSetProduct

export const getMinimalistMenuComponentText = (
  menuItem: ProductMenuItem | SwapSetProduct | PortionMenuItem,
  currentAvailableCustomisations: MenuCustomisationItem[][],
) => {
  const inMenuCustomisation = menuItem.features?.inMenuCustomisation
  if (!inMenuCustomisation) {
    return ''
  }

  if (currentAvailableCustomisations?.length) {
    const customisationLabels = currentAvailableCustomisations
      .map((customisation) => {
        if (customisation[0]) {
          return `customisation.${customisation[0].type}`
        }

        return ''
      })
      .filter((label) => label !== '')
      .join(', ')

    return `minimalist-menu-card: ${customisationLabels}`
  }

  return `minimalist-menu-card:`
}
