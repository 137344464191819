import { createContext } from 'react'
import { NotifiableEvent } from '../interfaces'

export interface ErrorContextProps {
  notifyError: (error: NotifiableEvent) => void
}

export const ErrorContext = createContext<ErrorContextProps>({
  notifyError: () => {},
})
