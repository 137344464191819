import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActionButton, Modal } from '@dominos/components'
import { useCountryCode } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'

import { getDropNGoImage } from './drop-n-go-static-images'
import css from './drop-n-go.less'

const iconWidth = 21
const iconHeight = 21
const icon = 'cross'

const defaultContent = {
  title: 'DROP & GO - DELIVERY OPTION',
  body:
    'Our driver will place your order down at your door on a small disposable box ' +
    'and notify you with an intercom when your order is ready to be collected. ' +
    'You can choose to have the driver leave as soon as the driver contact you. Please receive the box together.',
  footer: 'WHAT SHOULD YOUR DRIVER DO?',
  optIn: 'Driver can notify me and leave my order',
  optOut: 'No Thanks',
  imgAlt: "Domino's order delivered to Customer with Drop & Go",
}

export const DropNGoPopup = (props: {
  id: string
  testID: string
  onClose: () => void
  onOptIn: () => void
  onOptOut: () => void
}) => {
  const { t } = useTranslation('checkout')
  const countryCode = useCountryCode()

  const testID = `${props.testID}.popup`
  const image = getDropNGoImage(countryCode)

  return (
    <Modal testID={props.id} onModalClose={props.onClose}>
      <div data-testid={testID} className={css.cardContainer}>
        <div
          data-testid={`${testID}.close-button`}
          role='button'
          aria-label={t('closeButton', { defaultValue: 'Close Button' })}
          className={css.closeButton}
          onClick={props.onClose}
        >
          <StaticSvgIcon name={icon} width={iconWidth} height={iconHeight} isUnstyled />
        </div>
        <div className={css.contentContainer}>
          <h4 className={css.contentHeader}>{t('dropNGoPopupTitle', { defaultValue: defaultContent.title })}</h4>
          <div className={css.contentBody}>
            <div>{t('dropNGoPopupBody', { defaultValue: defaultContent.body })}</div>
            {image && (
              <div data-testid={`${testID}.image`}>
                <img src={image} alt={t('dropNGoPopupImgAlt', { defaultValue: defaultContent.imgAlt })} />
              </div>
            )}
          </div>
          <div className={css.contentFooter}>
            <div>{t('dropNGoPopupFooter', { defaultValue: defaultContent.footer })}</div>
            <ActionButton
              text={t('dropNGoPopupOptIn', { defaultValue: defaultContent.optIn })}
              onPress={props.onOptIn}
              testID={`${testID}.opt-in`}
              containerStyle={{ width: '100%' }}
            />
            <ActionButton
              text={t('dropNGoPopupOptOut', { defaultValue: defaultContent.optOut })}
              onPress={props.onOptOut}
              testID={`${testID}.opt-out`}
              containerStyle={{ width: '100%', backgroundColor: 'transparent' }}
              textStyle={{ color: 'black' }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
