export const quickCalculateDistance = (
  lat1?: number,
  long1?: number,
  lat2?: number,
  long2?: number,
): number | undefined => {
  if (lat1 === undefined || long1 === undefined || lat2 === undefined || long2 === undefined) {
    return undefined
  }
  const p = 0.017453292519943295 // Math.PI / 180
  const c = Math.cos

  const a = 0.5 - c((lat1 - lat2) * p) / 2 + (c(lat2 * p) * c(lat1 * p) * (1 - c((long1 - long2) * p))) / 2

  return 12742 * Math.asin(Math.sqrt(a)) // 2 * R; R = 6371 km
}
