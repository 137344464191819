import { defaultLastOrderDetails } from './default-last-order-details'

export const getLastOrderDetails = (
  storedValue: LastOrderDetails,
  basketHeaderData?: Partial<BasketData>,
  deliveryAddress?: Bff.Orders.OrderDetails.Header.DeliveryAddress | null,
): LastOrderDetails => {
  const { serviceMethod, storeNo } = basketHeaderData ?? {}

  if (!!serviceMethod) {
    return {
      ...storedValue,
      lastServiceUsed: serviceMethod,
      [serviceMethod]: {
        serviceMethod,
        ...(serviceMethod === 'Pickup' ? { storeNo } : { deliveryAddress }),
      },
    }
  }

  return defaultLastOrderDetails
}
