import { BasketLine } from '@dominos/components'
import React from 'react'
import css from './order-details.less'
import { VoucherLine } from './voucher-line'

const LineItems = ({ lines }: { lines: Bff.Orders.OrderDetails.Basket.BasketLineDependancy[] }) => (
  <ul className={css.list}>
    {lines.map((line: Bff.Orders.OrderDetails.Basket.BasketLineDependancy, index: number) => {
      switch (line.type) {
        case 'ProductLine':
          return (
            <li key={`${line.productCode}-${index}`}>
              <BasketLine testID={`basket-container.line`} lineData={line} hideEditButton hideRemoveButton />
            </li>
          )
        case 'CouponLine':
          return (
            <li key={`${line.code}-${index}`} className={css.coupon}>
              <VoucherLine testID={`voucher-line-${line.code}`} voucherLine={line} />
            </li>
          )
        default:
          return null
      }
    })}
  </ul>
)

export { LineItems }
