import React from 'react'
import { createProductNutritionalViewModel } from './create-product-nutritional-view-model'
import { ProductNutritionalView } from './product-nutritional-view'
import { ProductNutritionalCardProps } from './product-nutritional.interface'

export const ProductNutritionalCard = ({
  testID,
  t,
  nutritionals,
  allergens,
  showContainsAllergensOnly,
  showInfoButton,
}: ProductNutritionalCardProps): JSX.Element => {
  const viewModel = createProductNutritionalViewModel({
    t,
    nutritionals,
    allergens,
    showContainsAllergensOnly,
    showInfoButton,
  })

  return <ProductNutritionalView testID={testID} viewModel={viewModel} t={t} />
}
