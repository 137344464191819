import { CollapsableTitledCard } from '@dominos/components'
import { DetailRow } from '@dominos/components/basket/detail-row'
import { useTipTheDriver } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './tip-the-driver.less'
import TipToggles from './tip-toggles'

interface TipTheDriverProps extends BaseProps {
  store?: Bff.Stores.Store
}

const TipTheDriver = ({ testID, store }: TipTheDriverProps) => {
  const { t } = useTranslation('checkout')
  const { setTipValue, tipAmount, shouldHideTipTheDriver, settings } = useTipTheDriver(store)

  if (shouldHideTipTheDriver || !settings) {
    return null
  }

  return (
    <CollapsableTitledCard
      primaryTitle={t('TipDriverTitle')}
      noPadding={true}
      startCollapsed={false}
      testID={`${testID}.card`}
      style={{ margin: '0px 0px 8px' }}
    >
      <div className={css.tipTheDriver}>
        <TipToggles
          testID='tip-toggles'
          values={settings.presets}
          onChange={setTipValue}
          minTip={settings.minimumTip}
          maxTip={settings.maximumTip}
        />
        <div className={css.summary}>
          <DetailRow testID='driver-tip' price={tipAmount} label={t('TipDriverSummaryDriverTip')} />
        </div>
      </div>
    </CollapsableTitledCard>
  )
}

export { TipTheDriver }
