import { MyDetailsModelProps, MyDetailsSectionProps, PaymentDetails, PaymentProvider } from '@dominos/interfaces'
import { flattenBasketCoupons, flattenBasketLines } from './flatten-basket'

const mapBasketLineToValidateBasketLine = (line: BasketLine): ValidateBasketLine => ({
  lineNo: line.itemNo,
  sizeCode: line.sizeCode || null,
  productCode: line.productCode,
  quantity: line.quantity,
  toppings:
    line.toppings &&
    line.toppings.map((top: BasketLineChange) => ({ action: top.action, code: top.code, quantity: top.quantity })),
  base: line.base ? { add: line.base.add, remove: line.base.remove } : undefined,
  sauce: line.sauce ? { add: line.sauce.add, remove: line.sauce.remove } : undefined,
  options: line.options
    ? line.options.map((op) => ({
        action: op.action,
        code: op.code,
        quantity: op.quantity,
      }))
    : undefined,
  portions:
    line.portions &&
    line.portions.map((p: BasketLinePortion) => ({
      productCode: p.productCode,
      sauce: p.sauce && {
        add: p.sauce.add,
        remove: p.sauce.remove,
      },
      toppings:
        p.toppings &&
        p.toppings.map((top) => ({
          action: top.action,
          code: top.code,
          quantity: top.quantity,
        })),
      options:
        p.options &&
        p.options.map((opt) => ({
          action: opt.action,
          code: opt.code,
          quantity: opt.quantity,
        })),
    })),
})

export const mapPaymentProviderToBasketPayment = (providers: PaymentProvider[]) =>
  providers.map<ValidateBasketPayment>(({ provider, paymentMethod }: PaymentProvider) => ({
    paymentMethod,
    providerCode: provider,
  }))

export const mapBasketToValidateBasket = (lines: BasketLineUnionDependancy[]) =>
  flattenBasketLines(lines).map((line) => mapBasketLineToValidateBasketLine(line))

const combineCoupons = (
  basketCoupons: ValidateBasketCoupon[],
  pendingCoupons?: string[],
  countPendingLoyaltyCoupons?: number,
) => {
  const loyaltyCouponCount = countPendingLoyaltyCoupons ? countPendingLoyaltyCoupons : 0
  const pendingLoyaltyCoupons: ValidateBasketCoupon[] = []
  for (let i = 0; i < loyaltyCouponCount; i++) {
    pendingLoyaltyCoupons.push({ code: '', isLoyaltyRedemption: true })
  }
  if (!pendingCoupons) {
    return [...pendingLoyaltyCoupons, ...basketCoupons]
  }

  return [...pendingCoupons.map((code) => ({ code })), ...pendingLoyaltyCoupons, ...basketCoupons]
}

export const mapCustomerToValidateBasket = (
  details: MyDetailsSectionProps | MyDetailsModelProps | undefined,
  customerId: string | undefined,
): ValidateBasketCustomer | undefined => {
  if (!details?.mobileNumber || !details.emailAddress || !details.name) {
    return undefined
  }

  return {
    customerId,
    phoneNumber: details.mobileNumber,
    email: details.emailAddress,
    name: details.name,
    alternateName: details.alternateName,
    organisationName: details.organisationName,
  }
}

interface PendingCoupons {
  pendingCoupons?: string[]
  pendingLoyaltyCoupons?: number
}

interface ValidateBasketRequest extends MutationWrapper<ValidateBasketInput> {
  withSalePrice?: boolean
}

export const buildValidateBasketRequest = (
  orderId: string,
  advanceOrderId: string,
  basketHeaderData?: Partial<BasketData>,
  basket?: Basket & PendingCoupons,
  customerDetails?: MyDetailsSectionProps,
  paymentMethod?: PaymentDetails,
  customerAddress?: StoredCustomerAddress,
  customerId?: string,
): ValidateBasketRequest | null => {
  if (!basketHeaderData || !basket) {
    return null
  }

  const { serviceMethod, time, storeNo } = basketHeaderData
  const provider = customerDetails && customerDetails.provider

  if (!serviceMethod || !storeNo) {
    return null
  }

  const address = customerAddress?.customerAddress

  const processDeliveryInstructions = (address: StoredCustomerAddress) => {
    if (address?.additionalInformation === undefined && address?.deliveryInstructions === undefined) {
      return undefined
    } else {
      return (address?.additionalInformation || '') + (address?.deliveryInstructions || '')
    }
  }

  return {
    input: {
      id: orderId,
      advanceOrderId: advanceOrderId,
      header: {
        serviceMethod,
        storeNo,
        allowAutoExclusiveCoupon: basketHeaderData.allowAutoExclusiveCoupon,
        time: time ?? null,
        deliveryAddress: address
          ? {
              deliveryInstructions: processDeliveryInstructions(customerAddress),
              displayAddress: customerAddress.displayAddress,
              address: {
                buildingName: address.buildingName ?? undefined,
                streetNo: address.streetNo ?? undefined,
                street: address.streetName ?? undefined,
                floorNo: address.floorNo ?? undefined,
                postCode: address.postCode ?? undefined,
                state: address.state ?? undefined,
                suburb: address.suburb ?? undefined,
                unitNo: address.unitNo ?? undefined,
              },
              latitude: customerAddress?.geo?.latitude,
              longitude: customerAddress?.geo?.longitude,
              locale: customerAddress.locale ?? undefined,
            }
          : undefined,
        customer: mapCustomerToValidateBasket(customerDetails, customerId),
      },
      body: {
        lines: mapBasketToValidateBasket(basket.lines),
        coupons:
          basket.pendingCoupons || basket.pendingLoyaltyCoupons
            ? combineCoupons(flattenBasketCoupons(basket.lines), basket.pendingCoupons, basket.pendingLoyaltyCoupons)
            : flattenBasketCoupons(basket.lines),
      },
      payment: (provider && mapPaymentProviderToBasketPayment([provider])) || (paymentMethod && [paymentMethod]) || [],
    },
    withSalePrice: basketHeaderData.withSalePrice,
  }
}
