import { useLazyQuery } from '@apollo/client'
import { offersQuery } from '@dominos/business/queries/offers'
import { useBasket, useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'

const ALLOWED_DELIVERY_LAYOUTS: BffContext.Layouts[] = ['MultiDeliveryGuarantee', 'SingleDeliveryGuarantee']

export const prepareQueryVariables = (
  header: Partial<BasketData>,
  orderId: string,
  customerAddress: CustomerAddressRequest,
) => {
  const { time, storeNo, serviceMethod } = header
  const { buildingName, unitNo, streetName, streetNo, postCode, state, suburb } = customerAddress

  return {
    orderId,
    tradingTime: time ?? new Date().toISOString(),
    storeNo: storeNo!,
    serviceMethod: serviceMethod!,
    deliveryAddress: {
      buildingName: buildingName ?? undefined,
      unitNo: unitNo ?? undefined,
      streetNo: streetNo ?? undefined,
      street: streetName ?? undefined,
      suburb: suburb ?? undefined,
      state: state ?? undefined,
      postCode: postCode ?? undefined,
    },
    layouts: ALLOWED_DELIVERY_LAYOUTS,
  } as Bff.Offers.OffersQueryArgs
}

const useDeliveryGuaranteeOffer = () => {
  const [sendRequest, { data }] = useLazyQuery<{ offers: Bff.Offers.Offer[] }, Bff.Offers.OffersQueryArgs>(
    offersQuery,
    { fetchPolicy: 'no-cache' },
  )
  const { basketHeaderData, orderId, selectedDeliveryAddress } = useCurrentOrderDetails()
  const basket = useBasket()

  const [offer, setOffer] = useState<Bff.Offers.Offer>()

  const fetchOffers = () => {
    const variables = prepareQueryVariables(basketHeaderData!, orderId, selectedDeliveryAddress!.customerAddress)

    sendRequest({
      variables,
    })
  }

  useEffect(() => {
    if (basket && basketHeaderData?.serviceMethod === 'Delivery') {
      fetchOffers()
    }
  }, [basket, basketHeaderData])

  useEffect(() => {
    if (data) {
      setOffer(data?.offers.length > 0 ? data.offers[0] : undefined)
    }
  }, [data])

  return { offer }
}

export default useDeliveryGuaranteeOffer
