import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge7 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-7' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M19.295,14.614 L19.295,17.187 C19.295,17.559 18.923,17.807 18.582,17.652 L15.203,16.257 C14.769,16.071 14.49,15.637 14.49,15.172 L14.49,10.925 C14.49,10.491 14.831,10.15 15.265,10.15 L26.735,10.15 C27.169,10.15 27.51,10.491 27.51,10.925 L27.51,13.374 C27.51,13.808 27.417,14.459 27.262,14.893 L21.899,31.106 C21.744,31.54 21.341,31.85 20.876,31.85 L17.466,31.85 C17.094,31.85 16.815,31.478 16.939,31.106 L22.364,14.614 C22.395,14.521 22.364,14.459 22.271,14.459 L19.45,14.459 C19.357,14.459 19.295,14.521 19.295,14.614 Z'
          id='7'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
