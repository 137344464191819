import React from 'react'
import { ActionButton, CardHeader } from '@dominos/components'
import PopupWarningIcon from './popup-warning-icon'
import css from './popup-warning.less'
import { useTranslation } from 'react-i18next'
import { useQuickUniversalAccountComponent } from '@dominos/hooks-and-hocs'
import { useAuthenticationPopup } from '@dominos/hooks-and-hocs'

type IProps = BaseProps

export const PopupWarning = ({ testID }: IProps) => {
  const { t } = useTranslation('login')
  const { hasPrevious, previousStep } = useQuickUniversalAccountComponent()
  const { hidePopup } = useAuthenticationPopup()

  return (
    <div data-testid={testID}>
      <CardHeader
        testID={`${testID}.header`}
        title={t('PopupBlockedWarningTitle', { defaultValue: 'Attention' })}
        onAction={hasPrevious ? previousStep : undefined}
      />
      <div data-testid={`${testID}.section`} className={css.section}>
        <div className={css.popupWarningIcon}>
          <PopupWarningIcon />
        </div>
        <h4 className={css.contentBody}>
          {t('LoginPopupWarningMessage', {
            defaultValue:
              'Sign in method uses a popup window to enter your credentials. Please disable content blockers to continue sign in.',
          })}
        </h4>
        <ActionButton
          testID={`${testID}.got-it`}
          text={t('Got it', { defaultValue: 'Got it' })}
          containerStyle={{ width: '100%', backgroundColor: '#007aff' }}
          textFontWeight={'semibold'}
          onPress={hasPrevious ? previousStep : hidePopup}
        />
      </div>
    </div>
  )
}
