import gql from 'graphql-tag'

export const b2bConfigQuery = gql`
  query b2BConfig {
    b2BConfig {
      serviceMethods {
        code
      }
      stores {
        storeNo
        serviceMethodOverride {
          code
        }
      }
    }
  }
`
