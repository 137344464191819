import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LinkButton } from '../../buttons/link-button/link-button'
import css from './actions.less'

interface ActionsProps {
  hideEdit: boolean
  hideRemove: boolean
  showSwap?: boolean
  onEdit?: () => void
  onRemove?: () => void
  onSwap?: () => void
  testID: string
}

const Actions = ({ hideEdit, hideRemove, onRemove, onEdit, testID, showSwap, onSwap }: ActionsProps) => {
  const { t } = useTranslation('menu')
  const {
    colours: { actionDanger, actionAlternative },
  } = useDominosTheme()

  return (
    <div data-testid={`${testID}.buttons`} className={css.buttons}>
      {!hideRemove && (
        <LinkButton
          testID={`${testID}.remove`}
          text={t('Remove')}
          weight={'semibold'}
          style={{ color: actionDanger }}
          onPress={onRemove}
        />
      )}
      {!hideEdit && (
        <LinkButton
          testID={`${testID}.edit`}
          text={t('Edit')}
          weight={'semibold'}
          style={{ color: actionAlternative }}
          onPress={onEdit}
        />
      )}
      {showSwap && (
        <LinkButton
          testID={`${testID}.swap`}
          text={t('Swap')}
          weight={'semibold'}
          style={{ color: actionAlternative }}
          onPress={onSwap}
        />
      )}
    </div>
  )
}

export default Actions
