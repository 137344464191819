import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions/validation'
import { ValidationTextField } from '@dominos/components'

const postCodeLength = 4
const streetMaxLength = 50
const streetNoMaxLength = 9

export const DeliveryAddressLuLayout = (props: DeliveryAddressLayoutBaseProps) => {
  const { t } = useTranslation('delivery-address')
  const { equalLength, isNumeric, isRequired, maxLength } = useValidation()

  useEffect(() => {
    props.onInitialise({
      postCode: {
        isValid: false,
      },
      street: {
        isValid: false,
      },
      streetNo: {
        isValid: false,
      },
    })
  }, [])

  return (
    <>
      <ValidationTextField
        key='postCode'
        fieldName='postCode'
        placeholder={t('Post Code')}
        testID={'postCode.field'}
        showAsteriskWhenRequired
        validationRules={[isRequired, isNumeric, equalLength(postCodeLength)]}
        style={{ width: '100%' }}
        onChange={props.onChange}
        prefix='L-'
      />
      <ValidationTextField
        key='street'
        fieldName='street'
        placeholder={t('Street')}
        testID={'street.field'}
        showAsteriskWhenRequired
        validationRules={[isRequired, maxLength(streetMaxLength)]}
        style={{ width: '100%' }}
        onChange={props.onChange}
      />
      <ValidationTextField
        key='streetNo'
        fieldName='streetNo'
        placeholder={t('Street Number')}
        testID={'streetNo.field'}
        showAsteriskWhenRequired
        validationRules={[isRequired, maxLength(streetNoMaxLength)]}
        style={{ width: '100%' }}
        onChange={props.onChange}
      />
    </>
  )
}
