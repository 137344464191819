import { useDevToggles } from '@dominos/hooks-and-hocs'
import { getFullUrl } from '@dominos/business/functions/menu/get-images'

export interface PopupContent {
  imageURL?: string | undefined
  altText?: string
  description?: string | undefined
}

export const usePopupContent = (offer: Bff.Offers.Offer | null): PopupContent => {
  const { isEnabled } = useDevToggles()

  if (!offer?.media) return {}

  const imageURL = offer.media.tile?.url
    ? isEnabled['test-offers-images']
      ? `https://order.dominostest.com.au${offer.media.tile.url}`
      : getFullUrl(offer.media.tile.url)
    : undefined

  return {
    imageURL,
    altText: offer.media.tile?.altText || undefined,
    description: offer.media.description,
  }
}
