import { getImages } from '@dominos/business/functions/menu'

const supportedItemTypes = ['Product', 'Voucher']

export const generalFilter = (offers: Bff.Offers.Offer[], locationCode: string) =>
  offers.filter((offer) => {
    if (offer.items?.length > 0) {
      const matchingLocation = offer.locations.find(
        (location) => location.locationCode.toLowerCase() === locationCode.toLowerCase(),
      )
      const containsOnlySupportedItems = offer.items
        .map((item) => item.linkedItem.itemType)
        .every((itemType) => supportedItemTypes.includes(itemType))

      return matchingLocation && containsOnlySupportedItems && !offer.isPretotypeOffer
    }
  })

export const bannerOfferFilter = (
  offers: Bff.Offers.Offer[],
  locationCode: string,
  allowedLayouts: BffContext.Layouts[],
) =>
  offers.filter(
    (offer: Bff.Offers.Offer) =>
      !!offer.locations.find((location) => {
        const isBanner = location.locationCode.toLowerCase() === locationCode.toLowerCase() && location.showBanner

        if (isBanner && location.showPopUp) {
          return allowedLayouts.includes(offer.layout)
        }

        return isBanner
      }),
  )

export const formatDate = (date: string | undefined | null): string => {
  const asDate = date ?? new Date().toISOString()

  return asDate.substring(0, 10)
}

export const prepareQueryVariables = (header: Partial<BasketData>): Bff.Offers.OffersQueryArgs => {
  const { time, storeNo, serviceMethod } = header

  const result = {
    storeNo: storeNo!,
    serviceMethod: serviceMethod!,
  }

  if (time) {
    return {
      ...result,
      tradingTime: formatTime(time),
    }
  }

  return result
}

export const formatTime = (date: string | undefined | null): string | undefined => {
  if (!date) {
    return new Date().toISOString()
  }

  return date
}

export const isSpecialOffer = (offer: Bff.Offers.Offer) => {
  const locations = offer.locations.filter((loc) => loc.locationCode === 'SpecialOffers' && loc.showPopUp)

  return locations.length > 0
}

type MenuItemDependentCategory = Bff.Menu.old.MenuItemDependents & {
  legends?: (Partial<MenuLegend> | { image: Partial<Image> })[]
  category: string
  sizes?: Bff.Menu.old.ProductSize[]
}

export const upsellOfferFilter = (
  offers: Bff.Offers.Offer[],
  itemsByCode: MenuItemDependentsDictionary | null | undefined,
  allowedLayout: string,
  offerLocationCode: string,
  locationType: string,
  upsellLimit: number,
  excludedCategories: string[],
): Bff.Offers.Offer[] =>
  offers
    .filter(
      (offer) =>
        offer.layout === allowedLayout &&
        offer.locations.some(
          (location) => location.locationCode === offerLocationCode && location.locationType === locationType,
        ),
    )
    .map((offer) => {
      const updatedItems = offer.items
        .flatMap((item) => {
          const selectedProduct = itemsByCode?.[item.linkedItem.itemCode] as MenuItemDependentCategory
          if (!selectedProduct) return []

          const matchingSizes = selectedProduct.sizes?.filter((size) => size.code === item.linkedItem.subItemCode) || []
          if (item.linkedItem.subItemCode && !matchingSizes.length) return []

          if (excludedCategories.includes(selectedProduct.category)) return []

          const imageUrl = getImages(selectedProduct)?.menuImage?.fullUrl

          return { ...item, image: { ...item.image, url: imageUrl }, legends: selectedProduct.legends }
        })
        .slice(0, upsellLimit)

      return { ...offer, items: updatedItems }
    })

export const productUpsellFilter = (
  offers: Bff.Offers.Offer[],
  locationType: string,
  allowedLayout: BffContext.Layouts,
) =>
  offers.find((offer) =>
    offer.locations.find(
      (location) =>
        location.locationType.toLowerCase() === locationType.toLowerCase() &&
        offer.layout === allowedLayout &&
        offer.items[0].linkedItem.itemType === 'Component',
    ),
  )
