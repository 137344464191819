import React from 'react'
import { GenericButtonCard, ProductDescription } from '@dominos/components'

interface PortionProductSummaryProps {
  testID: string
  media: Bff.Products.ProductMedia | undefined
  formattedPrice: string | null | undefined
  formattedPromoPrice: string | null | undefined
  energyKJ?: number | null | undefined
  t: (key: string) => string
  onSwapPortionsClick: () => void
}

const PortionProductSummary: React.FC<PortionProductSummaryProps> = ({
  testID,
  media,
  formattedPrice,
  formattedPromoPrice,
  energyKJ,
  t,
  onSwapPortionsClick,
}) => (
  <>
    <ProductDescription
      testID={testID}
      media={media}
      t={t}
      formattedPrice={formattedPrice}
      formattedPromoPrice={formattedPromoPrice}
      energyKJ={energyKJ}
    />
    <GenericButtonCard
      buttonTitle={t('PortionProductCard.SwapPortions')}
      testID={`${testID}.swap-portions-button-card`}
      onClick={onSwapPortionsClick}
      icon='portionProduct'
    />
  </>
)

export default PortionProductSummary
