import { noSauceCode } from '@dominos/components/product/functions'

const createBasketLineSwap = (selectedCode: string, defaultCode: string | undefined): BasketLineSwap | undefined => {
  if (selectedCode === defaultCode) {
    return undefined
  }

  return {
    add: selectedCode == noSauceCode ? null : selectedCode,
    remove: defaultCode,
  } as BasketLineSwap
}

export { createBasketLineSwap }
