import { BasketLineData } from '@dominos/business/functions/basket'
import { isProductMenuItemNew } from '@dominos/components'

type PortionSize = Bff.Menu.old.PortionSize

export const getSizeFromMenu = (
  product: BasketLineData,
): {
  size?: ProductSize | PortionSize | null
} => {
  let size: ProductSize | PortionSize | null | undefined = null

  if (
    product.item &&
    !isProductMenuItemNew(product.item) &&
    (product.item.type === 'Product' || product.item.type === 'Portion') &&
    product.item.sizes
  ) {
    const sizes: (ProductSize | PortionSize)[] = product.item.sizes
    const selectedSizeCode =
      product.sizeCode || (product.item as ProductMenuItem | PortionMenuItem)?.defaultSize || null

    if (product.item.type === 'Portion' || (!product.base && !product.toppings && !product.sauce)) {
      // no customizations have been done
      size = sizes.find((size: ProductSize | PortionSize) => size.code === selectedSizeCode)
    } else if (product.base && !product.toppings && !product.sauce) {
      // only crust have been changed
      const selectedCrust = (product.base as BasketLineSwap).add
      size = sizes.find((size: ProductSize | PortionSize) => size.code === selectedSizeCode)
      if (size?.recipe?.base?.code !== selectedCrust) {
        size = undefined // menu does not have price for selected combination (crust, size)
      }
    }
  }

  return {
    size,
  }
}
