import React from 'react'

import { useDominosTheme } from '@dominos/hooks-and-hocs/theme'
import { StaticSvgIcon } from '@dominos/res'

import css from './detail-button.less'

export const DetailButton = (props: DetailButtonProps) => {
  const theme = useDominosTheme()
  const { backgroundColor, color, onPress, testID, icon, title, iconTopMargin } = props

  const styles = {
    buttonOuter: {
      backgroundColor,
      borderRadius: theme.borderRadius,
    },
    titleText: {
      color,
    },
    detailText: {
      color,
    },
    icon: {
      marginTop: iconTopMargin,
    },
  }

  return (
    <button data-testid={testID} onClick={onPress} className={css.buttonOuter} style={styles.buttonOuter}>
      <div className={css.buttonInner}>
        <div data-testid={`${testID}.icon`} className={css.icon}>
          {icon}
        </div>
        <div className={css.textContainer}>
          <p data-testid={`${testID}.title`} className={css.titleText} style={styles.titleText}>
            {title}
          </p>
        </div>
      </div>
      <div data-testid={`${testID}.chevron`} className={css.chevronHolder}>
        <StaticSvgIcon name='chevron' isUnstyled={true} />
      </div>
    </button>
  )
}
