import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const SideIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='0 0 5.832 0 5.832 5.9972 0 5.9972' />
        <polygon id='path-3' points='0 -3.55271368e-15 5.4353393 -3.55271368e-15 5.4353393 4.98843877 0 4.98843877' />
        <polygon id='path-5' points='-8.8817842e-16 0 16.4843749 0 16.4843749 16.4906 -8.8817842e-16 16.4906' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-14' transform='translate(0.000000, 1.000000)'>
          <g id='Group-3' transform='translate(3.435200, 0.000000)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-2' />
            <path
              d='M1,5.9972 C0.448,5.9972 0,5.5492 0,4.9972 C0,2.1482 2.077,-0.0008 4.832,-0.0008 C5.384,-0.0008 5.832,0.4472 5.832,0.9992 L5.832,4.9502 C5.832,5.5022 5.384,5.9502 4.832,5.9502 C4.28,5.9502 3.832,5.5022 3.832,4.9502 L3.832,2.1712 C2.729,2.5712 2,3.6382 2,4.9972 C2,5.5492 1.552,5.9972 1,5.9972'
              id='Fill-1'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <g id='Group-6' transform='translate(0.000000, 1.008961)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-5' />
            <path
              d='M4.436,4.98843877 C4.064,4.98843877 3.706,4.77943877 3.534,4.42143877 L2.569,2.41543877 C2.133,2.78643877 1.995,3.23243877 2,3.98143877 C2.004,4.53343877 1.56,4.98443877 1.007,4.98843877 C0.426,4.95843877 0.004,4.54743877 0,3.99543877 C-0.013,2.10543877 0.758,0.938438768 2.577,0.093438768 C3.074,-0.137561232 3.663,0.074438768 3.899,0.567438768 L5.336,3.55443877 C5.576,4.05243877 5.366,4.65043877 4.869,4.88943877 C4.729,4.95643877 4.581,4.98843877 4.436,4.98843877'
              id='Fill-4'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
          <path
            d='M8.266,5.9974 C8.083,5.9974 7.898,5.9474 7.732,5.8414 C7.265,5.5464 7.127,4.9284 7.422,4.4624 C9.001,1.9684 10.835,1.5654 12.094,1.6704 C12.613,1.7124 13.013,2.1464 13.013,2.6674 L13.013,4.9974 C13.013,5.5494 12.565,5.9974 12.013,5.9974 C11.46,5.9974 11.013,5.5494 11.013,4.9974 L11.013,3.7734 C10.487,3.9604 9.814,4.4244 9.112,5.5324 C8.922,5.8324 8.597,5.9974 8.266,5.9974'
            id='Fill-7'
            fill={fill}
          />
          <path
            d='M5.5146,18.9471 C5.4956,16.1631 7.3456,13.7931 9.8876,13.0301 L9.9816,12.7571 C10.2736,11.9071 10.7616,11.1191 11.3916,10.4781 C11.7556,10.1171 12.1546,9.8081 12.5796,9.5521 L12.8376,8.0031 C12.9226,7.4971 12.5326,7.0371 12.0196,7.0371 L9.9996,7.0371 L8.9996,8.0221 L3.9756,8.0011 L2.9966,7.0371 L0.9786,7.0371 C0.4666,7.0371 0.0766,7.4971 0.1606,8.0031 L1.8376,18.0511 C1.9306,18.6111 2.4156,19.0221 2.9836,19.0221 L5.5186,19.0221 C5.5186,18.9971 5.5146,18.9721 5.5146,18.9471'
            id='Fill-9'
            fill={fill}
          />
          <g id='Group-13' transform='translate(7.514496, 6.546400)'>
            <mask id='mask-6' fill='white'>
              <use xlinkHref='#path-5' />
            </mask>
            <g id='Clip-12' />
            <path
              d='M-8.8817842e-16,12.387 C0.0161036734,14.659 1.86210367,16.491 4.13110367,16.491 L4.16010367,16.491 C5.24810367,16.483 6.29010367,16.047 7.05810367,15.276 L15.2481037,7.087 C16.8771037,5.485 16.8991037,2.866 15.2971037,1.236 C14.4881037,0.413 13.4181037,0 12.3481037,0 C11.3011037,0 10.2531037,0.395 9.44710367,1.187 C9.43110367,1.203 9.41410367,1.22 9.39810367,1.236 L9.39810367,1.24 C8.97310367,1.673 8.64910367,2.196 8.45310367,2.77 C9.36210367,3.252 10.1171037,3.98 10.6331037,4.871 C9.87410367,4.478 9.04910367,4.229 8.19910367,4.135 C7.11310367,4.138 6.07310367,4.569 5.30310367,5.334 C4.87810367,5.767 4.55510367,6.289 4.35810367,6.862 C5.26710367,7.344 6.02210367,8.072 6.53810367,8.962 C5.81010367,8.498 4.96710367,8.244 4.10410367,8.227 C1.82210367,8.243 -0.0158963266,10.105 -8.8817842e-16,12.387'
              id='Fill-11'
              fill={fill}
              mask='url(#mask-6)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
