import React from 'react'

import { ActionButton } from '@dominos/components'

import css from './styles/retry-dialog.less'

export const RetryDialog = (props: RetryDialogProps) => {
  const onPress = () => props.onRetry && props.onRetry()

  return (
    <div data-testid={props.testID} className={css.container}>
      <ActionButton
        onPress={onPress}
        text={props.text || 'Loading failed. Press here to retry.'}
        testID={'retry-button'}
      />
    </div>
  )
}
