import { jpMenuDataPortion } from './jp-menu-data-portion'

type Menu = Bff.Menu.old.Menu
type MenuPage = Bff.Menu.old.MenuPage
type MenuSection = Bff.Menu.old.MenuSection
type PortionMenuItem = Bff.Menu.old.PortionMenuItem

// this function is responsible to add a new section with an item (Portion Menu Item) when using the JP working around,
// because JP menu didn't have a PortionMenuItem inside it.

export const addNewSectionIntoTheMenu = (fullMenu: Menu, portionMenuItemData: PortionMenuItem) => {
  const newPages: MenuPage[] | undefined = fullMenu.pages.map((pages) => {
    const newPagesSections: MenuSection[] = []
    Object.assign(newPagesSections, { ...pages.sections })

    if (pages.code === 'Menu.Pizza') {
      newPagesSections.push({ ...jpMenuDataPortion, items: [portionMenuItemData] })
    }

    return {
      ...pages,
      sections: newPagesSections,
    } as MenuPage
  })

  return { ...fullMenu, pages: newPages }
}
