import React from 'react'
import styles from './dropdown.less'

interface DropdownProps {
  listStyles?: React.CSSProperties
  className?: string
  children?: React.ReactNode
}

const closeDropdownEvent = 'close-dropdown'

const Dropdown = ({ listStyles, children, className }: DropdownProps) => {
  const listRef = React.useRef<HTMLUListElement>(null)
  const [visible, setVisible] = React.useState(false)

  const toggleDropdown = ({ target }: Event | MouseEvent | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!listRef.current?.contains(target as Node)) {
      document.removeEventListener('click', toggleDropdown)
      setVisible((prev) => !prev)
    }
  }

  React.useEffect(() => {
    document.addEventListener(closeDropdownEvent, toggleDropdown)

    return () => document.removeEventListener(closeDropdownEvent, toggleDropdown)
  }, [])

  React.useEffect(() => {
    if (visible) {
      document.addEventListener('click', toggleDropdown)

      return () => document.removeEventListener('click', toggleDropdown)
    }
  }, [visible])

  const buttonChildren = filterChildren(Button, children)
  const itemChildren = filterChildren(Item, children)

  return (
    <div className={className ? `${className} ${styles.dropdown}` : styles.dropdown}>
      {buttonChildren && <button onClick={toggleDropdown}>{buttonChildren}</button>}
      {visible && itemChildren ? (
        <ul ref={listRef} style={listStyles}>
          {itemChildren}
        </ul>
      ) : null}
    </div>
  )
}

const Item: React.FC<{
  label?: string
  testId?: string
  onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => Promise<void>
}> = ({ label = '', testId = '', onClick, children }) => {
  const onClickHandler = React.useCallback(
    async (event) => {
      if (onClick) {
        await onClick(event)
      }
      document.dispatchEvent(new Event(closeDropdownEvent))
    },
    [onClick],
  )

  return (
    <li role='button' aria-label={label} data-testid={testId} onClick={onClickHandler}>
      {children}
    </li>
  )
}

Dropdown.Item = Item

const Button: React.FC = ({ children }) => <>{children}</>

Dropdown.Button = Button

const filterChildren = (type: React.ReactElement<unknown>['type'], children?: React.ReactNode) =>
  React.Children?.map(children, (child) =>
    React.isValidElement(child) && (child as React.ReactElement<unknown>).type === type ? child : null,
  )

export { Dropdown }
