type MenuSection = Bff.Menu.old.MenuSection

export const jpMenuDataPortion = {
  __typename: 'MenuSection',
  code: 'Menu.Pizza.Designa',
  media: {
    __typename: 'MenuSectionMedia',
    name: 'Make Your Own',
    description: null,
    logo: null,
    banner: null,
  },
  items: [],
} as unknown as MenuSection
