import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 31,
  height: 31,
  baseScaleFactor: 0.45,
}

const MapArrowIcon = (props: SvgIconProps) => {
  const backgroundFill = 'none'
  const arrowFill = '#007AFF'

  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height
  const scaleFactor = (+width / 500) * defaultDimensions.baseScaleFactor
  const translateFactor = (+width - 500 * scaleFactor) / 2 / scaleFactor

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`}>
      <ellipse cx='15' cy='14.6' fill={backgroundFill} rx='15' ry='14.6' />
      <polygon
        transform={`scale(${scaleFactor}) translate(${translateFactor},${translateFactor})`}
        fill={arrowFill}
        points='445,15 -55,284.9 176.5,283.5 175.1,515'
      />
    </svg>
  )
}

export { MapArrowIcon }
