import { ActionButton } from '@dominos/components'
import React from 'react'
import css from './express-checkout-button.less'
import classnames from 'classnames'

const DEBOUNCE_TIME = 2000

export const ExpressCheckoutButton = ({
  testID,
  loading,
  disabled,
  text,
  onPress,
  fill,
}: ExpressCheckoutButtonProps) => {
  const shouldDisable = disabled || loading

  return (
    <ActionButton
      className={classnames(css.button, fill && css.fill)}
      testID={testID}
      disabled={shouldDisable}
      loading={loading}
      onPress={onPress}
      debounce={DEBOUNCE_TIME}
      textFontWeight={'semibold'}
      containerStyle={{ backgroundColor: 'black' }}
    >
      <div data-testid={`${testID}.container`} className={css.container}>
        <div data-testid={`${testID}.label`}>{text}</div>
      </div>
    </ActionButton>
  )
}
