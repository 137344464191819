/**
 * Please use standard-theme.less where possible
 */
export const standardTheme = {
  colours: {
    backgroundColor: '#f6f6f6',
    defaultError: '#e21836',
    defaultErrorBackground: '#fbdee3',
    deemphasisedBlue: 'rgba(0, 122, 255, 0.2)',
    deemphasisedGrey: 'rgba(74, 74, 74, 0.2)',
    defaultGradientBackground: ['#5f5d5e', '#171717'],
    delivery: '#007AAF',
    dominosSlate: '#454545',
    pickup: '#e21e3d',
    disabled: '#808080',
    lightSlate: '#979797',
    defaultSubtitle: '#8f8e94',
    overlayColor: 'rgba(0, 0, 0, 0.25)',
    selectButtonColor: '#0cb75a',
    sectionBackground: '#efefef',
    sectionBorder: '#979797',
    tooltipText: 'white',
    importantButton: '#ff3b30',
    errorMessage: '#e21e3d',
    defaultBackground: 'white',
    hyperlink: '#007aff',
    creditCardContainerBackground: '#8e8e93',
    quick: '#45964B',
    trackerPrimary: '#cc0000',
    rewardsPrimary: '#e8b96c',
    rewardsSecondary: '#979797',
    rewardsDialogText: '#8F8F8F',
    rewardsDialogBorder: '#A9ADAE',
    rewardsDialogIosText: '#007ff9',
    rewardsDialogAndroidText: '#169689',
    spinnerOnButton: 'white',
    chevronWithBackground: 'white',
    black: '#000000',
    inputBackground: 'rgba(142, 142, 147, 0.14)',
    inputIconColor: '#7F7F7F',
    placeholderTextColor: '#8e8e93',
    loadingTextColor: 'rgba(0, 0, 0, 0.54)',
    web: {
      grey: '#4a4a4a',
      actionDisabled: '#9b9b9b',
    },

    defaultButtonText: '#ffffff', // button-text-default
    buttonDisabledText: '#4A4A4A', // button-text-disabled
    defaultButton: '#efeff4', // button-background-default
    buttonDisabled: '#9B9B9B', // button-background-disabled
    buttonBgBlue: '#3478F6', // button-background-blue

    actionTextDefault: '#282828', // action-text-default
    actionStandard: '#1FC36A', // action-text-standard
    actionAlternative: '#007aaf', // action-text-alternative
    actionDanger: '#e21836', // action-text-danger
    actionDisabled: '#e0e0e0', // action-text-disabled
    actionDeemphasized: 'rgba(52,120,246, 0.2)', // action-text-deemphasized
    actionBlue: '#007AFF', // action-text-blue
    actionGreen: '#5CC073', // action-text-green
    actionBlueBackgound: 'rgba(0, 122, 255, 0.2)', // action-background-blue
    actionGreenBackgound: 'rgba(31, 195, 106, 0.2)', // action-background-green

    alternativeButtonBackground: 'rgba(211, 229, 255)',
    alternativeButtonText: '#3478F6',
  },
  webBorderRadius: 15,
  webLargeBorderRadius: 20,
  webSmallBorderRadius: 8,
  borderRadius: 10,
  lightShadow: '0 0.5px 2px 0 rgba(0, 0, 0, 0.13)',
  defaultShadow: '0 0.5px 10px 0 rgba(0, 0, 0, 0.15)',
  smallBorderRadius: 10,
  textSizeButton: 17,
  marginTop: 10,
}

export type DominosTheme = typeof standardTheme

export type DominosThemeKeys = keyof typeof standardTheme
