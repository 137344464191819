type Separators = '/' | ' ' | ', ' | '-' | '#'
type DisplayAddressConfig = (keyof CustomerAddressRequest | Separators)[]

const AU: DisplayAddressConfig[] = [
  ['unitNo', '/', 'streetNo', ' ', 'streetName', ', '],
  ['suburb', ' ', 'state', ' ', 'postCode'],
]

const NL: DisplayAddressConfig[] = [
  ['streetName', ' ', 'streetNo', '/', 'unitNo', ', '],
  ['postCode', ' ', 'suburb', ' ', 'state'],
]

const DK: DisplayAddressConfig[] = [
  ['streetName', ' ', 'streetNo', ' ', 'unitNo', ', '],
  ['postCode', ' ', 'suburb', ' ', 'state'],
]

const DE: DisplayAddressConfig[] = [
  ['streetName', ' ', 'streetNo', ', '],
  ['postCode', ' ', 'suburb', ' ', 'state'],
]

const FR: DisplayAddressConfig[] = [
  ['streetNo', ' ', 'streetName', ', '],
  ['postCode', ' ', 'suburb', ' ', 'state'],
]

const SG: DisplayAddressConfig[] = [
  ['streetNo', ' ', 'streetName'],
  [', '],
  ['#', 'floorNo', '-', 'unitNo'],
  [', '],
  ['buildingName', ', ', 'suburb', ' ', 'postCode'],
]
const JP: DisplayAddressConfig[] = [
  ['postCode', ' ', 'state', ' ', 'suburb', ' '],
  ['streetName', ' ', 'streetNo', ' ', 'buildingName', ' ', 'unitNo'],
]

const MY: DisplayAddressConfig[] = [
  ['buildingName', ', ', 'unitNo'],
  [', '],
  ['streetNo', ' ', 'streetName'],
  [', '],
  ['suburb', ', ', 'postCode', ' ', 'state'],
]

const addressConfig: Record<BffContext.Countries, DisplayAddressConfig[]> = {
  AU,
  NZ: AU,
  NL,
  BE: NL,
  DK,
  DE,
  FR,
  LU: FR,
  SG,
  JP: JP,
  TW: AU,
  MY,
  KH: AU,
}

export const mapCountryToDisplayAddress = (
  component: { [K in keyof CustomerAddressRequest]?: string },
  country: BffContext.Countries,
) => {
  const result = addressConfig[country]
    .map((section) => {
      if (section.length === 1) return section[0]

      return section
        .map((key, index) => {
          const isSep = key === ' ' || key === '/' || key === ', ' || key === '-' || key === '#'

          const previousKey = section[index - 1]
          const nextKey = section[index + 1]

          const previousWasSep =
            previousKey === ' ' ||
            previousKey === '/' ||
            previousKey === ', ' ||
            previousKey === '-' ||
            previousKey === '#'

          const nextIsSep = nextKey === ' ' || nextKey === '/' || nextKey === ', ' || nextKey === '-' || nextKey === '#'

          if (index === section.length - 1 && previousWasSep) {
            return isSep ? undefined : component[key]
          }

          if (index === section.length - 1 && !previousWasSep) {
            return isSep ? key : undefined
          }

          if (index === 0 && nextIsSep) {
            return isSep ? key : component[key]
          }

          if (index === 0 && !nextIsSep && !!component[nextKey]) {
            return isSep ? key : component[key]
          }

          if (previousWasSep && nextIsSep) {
            return isSep ? undefined : component[key]
          }

          if (!previousWasSep && !nextIsSep && !!component[previousKey] && !!component[nextKey]) {
            return isSep ? key : undefined
          }
        })
        .filter((value) => !!value)
        .join('')
    })
    .filter((value) => !!value)

  const copy = [...result]

  // removes empty starting sections
  for (let index = result.length - 1; index >= 0; index--) {
    const lastElement = result[index]

    if (
      lastElement === ' ' ||
      lastElement === '/' ||
      lastElement === ', ' ||
      lastElement === '-' ||
      lastElement === '#'
    ) {
      copy.pop()
    } else {
      break
    }
  }

  // removes empty ending sections
  for (let index = 0; index < result.length - 1; index++) {
    const firstElement = result[index]

    if (
      firstElement === ' ' ||
      firstElement === '/' ||
      firstElement === ', ' ||
      firstElement === '-' ||
      firstElement === '#'
    ) {
      copy.splice(0, 1)
    } else {
      break
    }
  }

  const updated = copy
    .join('')
    // handles empty middle sections
    .split(' , ')
    .join(' ')

  // handles no results returned
  if (updated === ', ') {
    return undefined
  }

  return updated
}
