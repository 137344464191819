import { useCountryCode, useCustomer, useFeatures, useLanguages } from '@dominos/hooks-and-hocs'
import { useMemo } from 'react'
import { useBatchAddressSearch } from './use-batch-address-search'
import { useCustomerAddressData } from './use-customer-address-data'

const NUMBER_OF_SAVED_ADDRESSES = 3

export const useSavedAddressesBatch = () => {
  const [isSavedAddressesVisible] = useFeatures('SavedAddressEnglishLanguage')
  const { language } = useLanguages()
  const countryCode = useCountryCode()
  const { isLoggedIn: isCustomerLoggedIn } = useCustomer()

  const getNumberOfSavedAddresses = (language: string, isSavedAddressesVisible: boolean) => {
    const hideSavedAddresses = countryCode === 'JP' && language === 'en' && !isSavedAddressesVisible

    return hideSavedAddresses ? 0 : NUMBER_OF_SAVED_ADDRESSES
  }

  const numberOfSavedAddresses = useMemo(
    () => getNumberOfSavedAddresses(language, isSavedAddressesVisible),
    [language, isSavedAddressesVisible],
  )

  const customerAddressData = useCustomerAddressData(isCustomerLoggedIn)

  const savedAddresses = useMemo(
    () => customerAddressData.slice(0, numberOfSavedAddresses),
    [numberOfSavedAddresses, customerAddressData],
  )

  return useBatchAddressSearch([...savedAddresses])
}
