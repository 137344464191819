import { useLocalStorage } from '@dominos/hooks-and-hocs'
import uuid from 'uuid'

export type UseLaunchDarklyIDReturnType = {
  launchDarklyID: string
}

export const useLaunchDarklyID = (): UseLaunchDarklyIDReturnType => {
  const { setValue, storedValue } = useLocalStorage({ key: 'launch-darkly-id' })

  const launchDarklyID = window.nativeAppLaunchDarklyID ?? storedValue

  if (!launchDarklyID) {
    setValue(uuid())
  }

  return { launchDarklyID }
}
