export const RoundUpForCharityImages = {
  AU: {
    logo: require('@dominos/res/assets/round-up-for-charity/au/charity-logo.png'),
    infoBanner: null,
    infoLogo: [require('@dominos/res/assets/round-up-for-charity/au/headspace-logo.png')],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/au/tracker-banner.png'),
    isTrackerBannerHalfWidth: false,
  },
  NZ: {
    logo: require('@dominos/res/assets/round-up-for-charity/nz/charity-logo.png'),
    infoBanner: null,
    infoLogo: [require('@dominos/res/assets/round-up-for-charity/nz/youthline-logo.png')],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/nz/tracker-banner.png'),
    isTrackerBannerHalfWidth: false,
  },
  FR: {
    logo: require('@dominos/res/assets/round-up-for-charity/fr/logo.png'),
    infoBanner: null,
    infoLogo: [
      require('@dominos/res/assets/round-up-for-charity/fr/info-logo-1.png'),
      require('@dominos/res/assets/round-up-for-charity/fr/info-logo-2.png'),
    ],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/fr/tracker-banner.png'),
    isTrackerBannerHalfWidth: false,
  },
  DE: {
    logo: require('@dominos/res/assets/round-up-for-charity/de/dominoid-logo-small.png'),
    infoBanner: null,
    infoLogo: [require('@dominos/res/assets/round-up-for-charity/de/dominoid-banner.png')],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/de/dominoid-banner-small.png'),
    isTrackerBannerHalfWidth: false,
  },
  JP: {
    logo: require('@dominos/res/assets/round-up-for-charity/jp/charity-logo.png'),
    infoBanner: null,
    infoLogo: [require('@dominos/res/assets/round-up-for-charity/jp/charity-logo.png')],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/jp/charity-logo.png'),
    isTrackerBannerHalfWidth: false,
  },
  NL: {
    logo: require('@dominos/res/assets/round-up-for-charity/nl/UNHCR-logo-small.png'),
    infoBanner: null,
    infoLogo: [require('@dominos/res/assets/round-up-for-charity/nl/UNHCR-logo.png')],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/nl/UNHCR-logo.png'),
    isTrackerBannerHalfWidth: false,
  },
  BE: {
    logo: require('@dominos/res/assets/round-up-for-charity/eu/UNHCR-logo-small.png'),
    infoBanner: null,
    infoLogo: [require('@dominos/res/assets/round-up-for-charity/eu/UNHCR-logo.png')],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/eu/UNHCR-logo.png'),
    isTrackerBannerHalfWidth: false,
  },
  DK: {
    logo: require('@dominos/res/assets/round-up-for-charity/dk/UNHCR-logo-small.png'),
    infoBanner: null,
    infoLogo: [require('@dominos/res/assets/round-up-for-charity/dk/UNHCR-logo.png')],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/dk/UNHCR-logo.png'),
    isTrackerBannerHalfWidth: false,
  },
  LU: {
    logo: require('@dominos/res/assets/round-up-for-charity/eu/UNHCR-logo-small.png'),
    infoBanner: null,
    infoLogo: [require('@dominos/res/assets/round-up-for-charity/eu/UNHCR-logo.png')],
    trackerBanner: require('@dominos/res/assets/round-up-for-charity/eu/UNHCR-logo.png'),
    isTrackerBannerHalfWidth: false,
  },
}

export type RoundUpForCharityImageCountries = keyof typeof RoundUpForCharityImages
export type RoundUpForCharityImageType = 'logo' | 'infoBanner' | 'infoLogo' | 'trackerBanner'
export type RoundUpForCharityImageResize = 'isTrackerBannerHalfWidth'
