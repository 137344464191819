import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 36,
  height: 35,
}

export const LoyaltyIconDefault = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={`${width}`} height={`${height}`} viewBox='0 0 35 35'>
      <g data-testid='Loyalty.Icon.Default' fill='none' fillRule='evenodd'>
        <g fill='#E8B96C' fillRule='nonzero'>
          <g>
            <g>
              <path
                d='M17.5 0C27.165 0 35 7.835 35 17.5S27.165 35 17.5 35 0 27.165 0 17.5 7.835 0 17.5 0zM8.277 24.226l-.943 1.634c6.17 3.244 13.765 3.464 20.33-.003l-.94-1.626c-5.95 3.112-12.837 2.915-18.447-.005zM17.5 8.254L8.802 23.318c5.295 2.741 11.783 2.925 17.398.005l-8.7-15.07zm2.62 11.218c.934-.001 1.692.757 1.692 1.692 0 .934-.758 1.691-1.691 1.691-.936 0-1.692-.757-1.692-1.691 0-.935.758-1.692 1.692-1.692zm-5.24-.001c.935 0 1.69.758 1.69 1.691 0 .934-.757 1.692-1.69 1.692-.934 0-1.692-.758-1.692-1.692 0-.933.758-1.691 1.691-1.691zm2.62-4.86c.934 0 1.692.758 1.692 1.691 0 .934-.758 1.692-1.692 1.692-.935 0-1.692-.758-1.692-1.692 0-.935.758-1.691 1.692-1.691zM9.24 5.733l-.933.784L10.44 9.06l.934-.784-2.134-2.543zm16.345-.132l-2.134 2.543.933.784 2.135-2.543-.934-.784zM13.381 3.556l-1.145.417 1.135 3.12 1.146-.418-1.136-3.12zm8.055-.073L20.3 6.603l1.146.416 1.134-3.12-1.145-.416zm-3.433-.565h-1.22v3.32h1.22v-3.32z'
                transform='translate(-429.000000, -170.000000) translate(421.000000, 161.000000) translate(8.000000, 9.000000)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
