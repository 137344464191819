import React from 'react'
import css from './product-editor-content-wrapper.less'
import classnames from 'classnames'
import { ProductTermsAndConditions } from '@dominos/components'

export const ProductEditorContentWrapper = ({ testID, className, children }: ProductEditorContentWrapperProps) => (
  <div data-testid={testID} className={classnames(css.productEditorContent, className)}>
    {children}
    <ProductTermsAndConditions />
  </div>
)
