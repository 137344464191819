import { getFormattedPrice } from '@dominos/business/functions'
import classnames from 'classnames'
import React from 'react'
import css from './sauce-icon.less'

export const SauceIcon = ({ selected, name, color, price }: SauceIconProps) => {
  const renderIcon = () => {
    if (color) {
      return <div data-testid={`crust.color.${color}`} className={css.innerCircle} style={{ backgroundColor: color }} />
    }

    return <div data-testid={`crust.color.${name}`} className={css.innerCross} />
  }

  return (
    <div data-testid={`sauce.wrapper.${name}`} className={classnames(css.wrapper, selected && css.selected)}>
      <div data-testid={`sauce.circle.${name}`} className={css.outerCircle}>
        {renderIcon()}
      </div>
      <p className={css.name}>
        <span data-testid={`sauce.name.${name}`}>{name}</span>
        {price && price !== 0 ? (
          <span data-testid={`sauce.price.${name}`} className={css.price}>
            {`${price > 0 ? '+' : '-'}${getFormattedPrice(Math.abs(price))}`}
          </span>
        ) : null}
      </p>
    </div>
  )
}
