import { useSessionStorage } from '@dominos/hooks-and-hocs'

const useProductStorage = () => {
  const { storedValue: storedProductsByCode, setValue: setStoredProductsByCode } = useSessionStorage<ProductDictionary>(
    {
      key: 'products',
      compress: true,
    },
  )

  return { storedProductsByCode, setStoredProductsByCode }
}

export { useProductStorage }
