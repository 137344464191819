import { RoundUpForCharityDetails } from 'packages/components'

const ELIGIBLE_DONATION_STATUS = ['pending', 'authorised', 'failed']

const isDonationEligible = (status: string | null | undefined): boolean =>
  !!status && ELIGIBLE_DONATION_STATUS.includes(status)

export const getDonation = (order?: Bff.Orders.Order, donationDetails?: RoundUpForCharityDetails): number | undefined =>
  !order
    ? donationDetails?.amount
    : order.details?.donations?.find((donation) => isDonationEligible(donation.status))?.amount
