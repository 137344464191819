/**
 * Manages a single topping modification to a product item.
 * Generates an array of topping changes by updating any
 * existing changes for the given topping or adding a new
 * modification.
 *
 * @example
 * ```typescript
 * // Generate new changes and capture
 * const changes = getChangesWithProductToppingQuantity(item, quantity, ingredient, toppings);
 * ```
 *
 * @param item product item
 * @param quantity (optional) Product topping total quantity
 * @param ingredient Ingredient item for change
 * @param toppings The topping that is being added/removed from product
 * @returns The toppings that are being added or removed as `BasketLineChange[]`
 */
// ! From `@dominos/business/functions`

// * Turns the topping that is being added or removed into a `BasketLineChange[]`
export const productChangesTransform = (
  quantity: number,
  ingredientCode: string,
  ingredientName: string | undefined,
  isRecipeIngredient: boolean,
  toppings: BasketLineChange[] = [],
) => {
  const newChanges = [...toppings]
  const action = quantity > 0 ? 'Add' : 'Remove'
  let adjustedQuantity = quantity

  if ((!isRecipeIngredient && quantity > 0) || (isRecipeIngredient && quantity !== 1)) {
    if (isRecipeIngredient) {
      if (action === 'Add') {
        adjustedQuantity = quantity - 1
      } else {
        adjustedQuantity = 1 - quantity
      }
    }

    const existingChange = newChanges.find(({ code }) => ingredientCode === code)

    if (existingChange) {
      existingChange.quantity = adjustedQuantity
      existingChange.media = { name: ingredientName ?? '' }
    } else {
      newChanges.push({
        action,
        quantity: adjustedQuantity,
        code: ingredientCode,
        media: { name: ingredientName ?? '' },
      })
    }
  } else {
    newChanges.splice(
      newChanges.findIndex(({ code }) => ingredientCode === code),
      1,
    )
  }

  return newChanges
}
