import React from 'react'

import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 200,
  height: 138.8,
}

const BouncingDots = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg
      preserveAspectRatio='none'
      width={width}
      height={height}
      viewBox='0 0 200 138.8'
      style={{ backgroundColor: fill, overflow: 'visible' }}
    >
      <title>A</title>
      <svg x='40' y='100' width='0.01' height='0.01' style={{ overflow: 'visible' }} opacity='1'>
        <animate
          attributeName='y'
          calcMode='spline'
          dur='2s'
          fill='freeze'
          keySplines='0.645 0.045 0.355 1;0.645 0.045 0.355 1;0.42 0 0.58 1'
          keyTimes='0;0.165;0.335;1'
          repeatCount='indefinite'
          values='100;22;100;100'
        />
        <g transform='scale(1 1)'>
          <g transform='rotate(0)'>
            <svg x='-22' y='-22' width='44' height='44' style={{ overflow: 'visible' }} opacity='1'>
              <g clipPath=''>
                <g filter=''>
                  <path
                    d='M22,44c12.15,0,22,-9.85,22,-22 0,-12.15,-9.85,-22,-22,-22 -12.15,0,-22,9.85,-22,22 0,12.15,9.85,22,22,22zM22,44'
                    fill='#007AAF'
                    id='b1'
                    stroke='#00000000'
                    strokeDasharray='0'
                    strokeDashoffset='0'
                    strokeMiterlimit='10'
                    strokeWidth='0'
                  />
                </g>
              </g>
            </svg>
          </g>
        </g>
      </svg>

      <svg x='100' y='100' width='0.01' height='0.01' style={{ overflow: 'visible' }} opacity='1'>
        <animate
          attributeName='y'
          calcMode='spline'
          dur='2s'
          fill='freeze'
          keySplines='0 0 1 1;0.645 0.045 0.355 1;0.645 0.045 0.355 1;0.42 0 0.58 1'
          keyTimes='0;0.335;0.5;0.665;1'
          repeatCount='indefinite'
          values='100;100;22;100;100'
        />
        <g transform='scale(1 1)'>
          <g transform='rotate(0)'>
            <svg x='-22' y='-22' width='44' height='44' style={{ overflow: 'visible' }} opacity='1'>
              <g clipPath=''>
                <g filter=''>
                  <path
                    d='M22,44c12.15,0,22,-9.85,22,-22 0,-12.15,-9.85,-22,-22,-22 -12.15,0,-22,9.85,-22,22 0,12.15,9.85,22,22,22zM22,44'
                    fill='#007AAF'
                    id='b2'
                    stroke='#00000000'
                    strokeDasharray='0'
                    strokeDashoffset='0'
                    strokeMiterlimit='10'
                    strokeWidth='0'
                  />
                </g>
              </g>
            </svg>
          </g>
        </g>
      </svg>

      <svg x='160' y='100' width='0.01' height='0.01' style={{ overflow: 'visible' }} opacity='1'>
        <animate
          attributeName='y'
          calcMode='spline'
          dur='2s'
          fill='freeze'
          keySplines='0 0 1 1;0.645 0.045 0.355 1;0.645 0.045 0.355 1'
          keyTimes='0;0.665;0.835;1'
          repeatCount='indefinite'
          values='100;100;22;100'
        />
        <g transform='scale(1 1)'>
          <g transform='rotate(0)'>
            <svg x='-22' y='-22' width='44' height='44' style={{ overflow: 'visible' }} opacity='1'>
              <g clipPath=''>
                <g filter=''>
                  <path
                    d='M22,44c12.15,0,22,-9.85,22,-22 0,-12.15,-9.85,-22,-22,-22 -12.15,0,-22,9.85,-22,22 0,12.15,9.85,22,22,22zM22,44'
                    fill='#E21836'
                    id='r1'
                    stroke='#00000000'
                    strokeDasharray='0'
                    strokeDashoffset='0'
                    strokeMiterlimit='10'
                    strokeWidth='0'
                  />
                </g>
              </g>
            </svg>
          </g>
        </g>
      </svg>
    </svg>
  )
}

export { BouncingDots }
