import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions/validation'
import { ValidationTextField } from '@dominos/components'
import { ActionButton } from '@dominos/components/buttons'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

export const ResetEmailForm = ({
  onReset,
  onChange,
  isLoading,
  renderRecaptchaCheckbox,
  shouldRenderRecaptcha,
}: {
  onReset: () => void
  onChange: (updatedField: { [key: string]: ValidationField }) => void
  isLoading?: boolean
  renderRecaptchaCheckbox?: (recaptchaAction: BffContext.RecaptchaAction, containerId: string) => void
  shouldRenderRecaptcha?: boolean
}) => {
  const { t } = useTranslation('login')
  const theme = useDominosTheme()
  const { isEmailValid, isRequired } = useValidation()
  const [isValid, setIsValid] = useState<boolean>()

  const styles = {
    resetButton: { width: '100%' },
    contentMessage: { padding: '8px 0', fontWeight: 400, lineHeight: 1 } as React.CSSProperties,
    socialsContentMessage: {
      padding: '0 0 14px 0',
      fontSize: '9pt',
      color: theme.colours.web.grey,
      textAlign: 'center',
    } as React.CSSProperties,
  }

  const onEmailChange = (updatedField: { [key: string]: ValidationField }) => {
    if (onChange) {
      setIsValid(updatedField.email.isValid)
      onChange(updatedField)
    }
  }

  return (
    <>
      <div style={styles.contentMessage}>
        {t('ForgottenPasswordPopupContent', {
          defaultValue: 'Please enter your email address and we will email you instructions to reset your password.',
        })}
      </div>
      <ValidationTextField
        testID={`email.field`}
        fieldName={'email'}
        placeholder={t('Email')}
        validationRules={[isRequired, isEmailValid]}
        onChange={onEmailChange}
        style={{ width: '100%' }}
        autoComplete='username'
      />
      <div style={styles.socialsContentMessage}>
        {t('ForgottenPasswordPopupSocialsContent', {
          defaultValue:
            'If you signed up using Apple, Facebook, Google or PayPal you will need to reset your password with that service.',
        })}
      </div>
      {shouldRenderRecaptcha && (
        <>
          <div id='forgotPassword-recaptcha-container' />
          {renderRecaptchaCheckbox?.('ResetPassword', 'forgotPassword-recaptcha-container')}
        </>
      )}
      <ActionButton
        onPress={onReset}
        testID={'reset.button'}
        containerStyle={styles.resetButton}
        textStyle={{ color: theme.colours.tooltipText }}
        textFontWeight={'semibold'}
        text={t('ForgottenPasswordPopupButtonText', { defaultValue: 'Reset Password' })}
        disabled={isLoading || !isValid}
        loading={isLoading}
      />
    </>
  )
}
