type SwapSetProduct = Bff.Menu.old.SwapSetProduct

export const getSelectedProductSizeByCode = (
  product?: ProductMenuItem | SwapSetProduct | PortionMenuItem,
  sizeCode?: string | null,
) => {
  if (!product || !product.sizes || product.sizes.length === 0) {
    return null
  }

  for (const size of product.sizes) {
    if (size.code === sizeCode) {
      return size
    }
  }

  return product.sizes[0]
}
