import { getAlcoholRulesError, getMinimumOrderError } from '@dominos/business/functions/basket'
import { useAlert, useBasket, useCurrentStore, useFeatures, useServiceMethod } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import { TFunction } from 'i18next'
import { areVouchersComplete } from '@dominos/business/functions/basket/are-vouchers-complete'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface UseBasketValidationResponse {
  isBasketValid: () => boolean
}

enum BasketValidationErrorType {
  MinimumOrder,
  AlcoholRules,
  IncompleteVoucher,
}

interface BasketValidationError {
  type: BasketValidationErrorType
  message: string
}

export const useBasketValidation = (t: TFunction): UseBasketValidationResponse => {
  const { t: checkoutTrans } = useTranslation('checkout')
  const { showAlert } = useAlert()
  const [isAlcoholRuleRestrictionEnabled] = useFeatures('AlcoholRulesRestriction')
  const { basket, validationErrors } = useBasket()
  const currentStore = useCurrentStore()
  const serviceMethod = useServiceMethod()

  const [basketValidationError, setBasketValidationError] = useState<BasketValidationError>()

  const isBasketValid = (): boolean => {
    const minimumOrderError = getMinimumOrderError(basket.total, serviceMethod, currentStore, basket.minimumPrice, t)
    if (minimumOrderError) {
      setBasketValidationError({ type: BasketValidationErrorType.MinimumOrder, message: minimumOrderError })

      return false
    }

    const alcoholRulesError = getAlcoholRulesError(isAlcoholRuleRestrictionEnabled, validationErrors, checkoutTrans)
    if (alcoholRulesError) {
      setBasketValidationError({ type: BasketValidationErrorType.AlcoholRules, message: alcoholRulesError })

      return false
    }

    if (!areVouchersComplete(basket.lines as BasketCoupon[])) {
      setBasketValidationError({
        type: BasketValidationErrorType.IncompleteVoucher,
        message: checkoutTrans('VoucherItemsMissing'),
      })

      return false
    }

    setBasketValidationError(undefined)

    return true
  }

  useEffect(() => {
    if (!basketValidationError) {
      return
    }

    showAlert(basketValidationError.message)

    if (basketValidationError.type === BasketValidationErrorType.AlcoholRules) {
      navigate(NavigationConstants.menu)
    }
  }, [basketValidationError])

  return { isBasketValid }
}
