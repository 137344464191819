import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 26,
  height: 25,
}

const GMOAuMobileIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox={`0 0 ${width} ${height}`}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-28.000000, -368.000000)' fill={fill}>
          <g transform='translate(12.000000, 358.000000)'>
            <g transform='translate(16.240106, 10.000000)'>
              <path
                d='M6.4 4.323c-.137-.515-.343-.984-.611-1.394-.803-1.228-1.867-1.7-2.608-1.743-.802.039-.886.512-.562 1.72 1.117 4.177 3.634 5.121 4.839 5.37L6.4 4.322zm18.214 1.916c-1.076-1.644-2.313-3.376-3.32-4.324-.66-.623-2.052-1.507-1.494.58l1.836 6.859c-.45 0-2.688.121-3.436-2.67l-.677-2.518c-.136-.454-.326-.87-.566-1.237-.803-1.228-1.867-1.7-2.608-1.743-.435.02-.644.175-.719.487-.074.304.075.927.156 1.231l1.726 6.45c-.24.003-2.66.226-3.436-2.67l-.818-3.039c-.054-.185-.194-.562-.429-.9-.524-.755-1.455-1.56-1.949-1.56-.351 0-.49.403-.248 1.31l1.835 6.859h-.814c-2.878 0-4.929-.464-6.453-1.46C.645 6.227 0 2.924 0 2.245 0 1.517.223 0 2.739 0c1.57 0 2.678.825 3.344 1.585l.076.088c.053-.358.178-.748.446-1.061C6.937.226 7.564 0 8.165 0c1.325 0 2.456.889 3.058 1.671V1.67C11.38.92 11.95 0 13.909 0c1.57 0 2.678.825 3.344 1.585l.075.087c.053-.357.178-.746.446-1.06l.028-.03.008-.009c.33-.353.976-.571 1.5-.571 1.139 0 2.26.65 3.205 1.646C23.739 2.942 25.35 5.272 26 6.24'
                transform='translate(-31 -395) translate(7.5 208) translate(8.5 174) translate(15 13) translate(0 7.82) matrix(1 0 0 -1 0 9.36)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { GMOAuMobileIcon }
