import React, { forwardRef } from 'react'
import { SpotlightKey } from './spotlight-keys'

interface SpotlightElementProps {
  spotlightKey: SpotlightKey
  children: React.ReactNode
}

export const SpotlightElement: React.ForwardRefExoticComponent<
  SpotlightElementProps & React.RefAttributes<HTMLDivElement>
> = forwardRef(({ spotlightKey, children }, ref) => (
  <div ref={ref} style={{ position: 'relative' }} data-spotlight-key={spotlightKey}>
    {children}
  </div>
))
