import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 31, // 33
  height: 37, // 28
}

const PickUpIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg
      data-testid='pick-up-icon'
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 31 37`}
      version='1.1'
      className={props.className}
    >
      <g id='Current-Revision' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Start-Active-Order' transform='translate(-28.000000, -545.000000)' fill={fill} fillRule='nonzero'>
          <g id='Group-2' transform='translate(26.858062, 544.700195)'>
            <g id='New-Store' transform='translate(0.962587, 0.011719)'>
              <path
                d='M19.7133496,4.8093865 C19.1778791,5.34485691 19.1778791,6.21608849 19.7133496,6.7515589 C20.24882,7.28453295 21.1200515,7.28453295 21.6542738,6.7515589 C22.188496,6.21608849 22.188496,5.34485691 21.6542738,4.8093865 C21.1200515,4.27516427 20.24882,4.27516427 19.7133496,4.8093865 Z M15.5768718,5.62944258 L20.0453662,1.15970006 C20.3074846,0.897581679 20.7306185,0.897581679 20.9927369,1.15970006 L25.3064566,5.47466792 C25.568575,5.73553812 25.568575,6.15992026 25.3064566,6.42203865 L20.8379622,10.8917812 L15.5768718,5.62944258 Z'
                id='Stroke-1'
              />
              <path
                d='M11.5697806,10.9672453 C11.0343102,11.5014676 11.0343102,12.3739473 11.5697806,12.9081696 C12.1040028,13.4411436 12.9764826,13.4411436 13.5107048,12.9069214 C14.0436789,12.3726991 14.0449271,11.5014676 13.511953,10.9672453 C12.9764826,10.4317749 12.1040028,10.4317749 11.5697806,10.9672453 Z M15.328059,11.0271581 C14.7950849,11.5613803 14.7950849,12.4313637 15.328059,12.9693305 C15.8622812,13.5010564 16.7360091,13.5010564 17.2689832,12.9668341 C17.8044536,12.4313637 17.8044536,11.5613803 17.2689832,11.0271581 C16.7360091,10.4916877 15.8622812,10.4916877 15.328059,11.0271581 Z M19.7303996,11.9021342 L14.9311368,16.7026452 C14.6702666,16.9635154 14.2458845,16.9635154 13.9837661,16.7026452 L9.66879824,12.3876773 C9.40792804,12.1268071 9.40792804,11.7011768 9.66879824,11.4403066 L14.468061,6.63979565 L19.7303996,11.9021342 Z'
                id='Stroke-3'
              />
              <g id='store' transform='translate(0.671897, 10.520864)'>
                <path
                  d='M15.0184948,23.362103 L5.00616492,23.362103 L5.00616492,16.6872164 L15.0184948,16.6872164 L15.0184948,23.362103 Z M30.0369895,16.6872164 L30.0369895,13.3497731 L28.3682679,5.00616492 L16.8661987,5.00616492 C16.1811609,5.76752984 15.5975069,6.37360995 15.1152368,6.82440528 C14.6659408,7.31497827 14.2215049,7.56026477 13.7819287,7.56026477 C13.3423527,7.56026477 12.9146103,7.31497827 12.4987017,6.82440528 C12.1370684,6.54456651 11.5443391,5.93848639 10.7205136,5.00616492 L1.66872164,5.00616492 L0,13.3497731 L0,16.6872164 L1.66872164,16.6872164 L1.66872164,26.6995463 L18.3559381,26.6995463 L18.3559381,16.6872164 L25.0308246,16.6872164 L25.0308246,26.6995463 L28.3682679,26.6995463 L28.3682679,16.6872164 L30.0369895,16.6872164 Z M8.5603438,1.27353534e-13 L1.66872164,5.86197757e-14 L1.66872164,3.33744328 L9.17856909,3.33744328 C8.49916883,2.61080384 8.06274163,2.0545633 7.86928753,1.66872164 C7.67583342,1.28287999 7.90618551,0.726639437 8.5603438,1.27353534e-13 Z M28.3181843,-5.5067062e-14 L22.4226843,1.36666964e-14 L18.7258265,3.33744328 L28.3181843,3.33744328 L28.3181843,-5.5067062e-14 Z'
                  id='Shape'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { PickUpIcon }
