import { SecuritySender } from './security'

const iframeStatus: { [key: number]: keyof MyInvoiceEvents } = {
  200: 'Flow.MyInvoice.IframeStatus.Success',
  401: 'Flow.MyInvoice.IframeStatus.Unauthorized',
  500: 'Flow.MyInvoice.IframeStatus.ServerError',
}

export const reportMyInvoiceIframeStatus = (sendSecurity: SecuritySender) => {
  const reportIframeStatus = (statusCode: number, message?: string | null | undefined) => {
    sendSecurity(
      iframeStatus[statusCode] || 'Flow.MyInvoice.IframeStatus.Unknown',
      {
        statusCode,
        message: message ?? '',
      },
      true,
    )
  }

  return {
    reportIframeStatus,
  }
}
