import React, { useState } from 'react'

export const AuthContext = React.createContext<{
  setLoggedInUser: (isLoggedIn: boolean) => void
  isUserLoggedIn: boolean
}>({
  isUserLoggedIn: false,
  setLoggedInUser: () => {},
})

export const AuthenticationProvider = ({ children }: React.PropsWithChildren<{}>): React.ReactElement => {
  const [isLoggedInProvider, setLoggedIn] = useState<boolean>()

  return (
    <AuthContext.Provider
      value={{
        isUserLoggedIn: !!isLoggedInProvider,
        setLoggedInUser: setLoggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthentication = () => {
  const context = React.useContext(AuthContext)

  if (!context) {
    throw new Error("'useAuthentication' must be a child of 'AuthenticationProvider'")
  }

  return context
}
