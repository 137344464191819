import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { Config } from '../common/get-config'

export const getAllowedOrigins = (allowedOrigins: string) =>
  !allowedOrigins || allowedOrigins.toLowerCase() === 'null' ? '[]' : JSON.stringify(allowedOrigins.split(/,|;/))

export const setupNewRelic = (applicationConfig: Config) => {
  if (!!applicationConfig.NEW_RELIC_APPLICATION_ID && applicationConfig.NEW_RELIC_APPLICATION_ID !== 'null') {
    // Populate using values in copy-paste JavaScript snippet.
    const options = {
      init: {
        distributed_tracing: {
          enabled: true,
          cors_use_newrelic_header: false,
          cors_use_tracecontext_headers: true,
          allowed_origins: `${getAllowedOrigins(applicationConfig.NEW_RELIC_ALLOWED_ORIGINS)}`,
        },
        privacy: { cookies_enabled: false },
        ajax: { deny_list: ['bam-cell.nr-data.net'] },
      }, // NREUM.init
      info: {
        beacon: 'bam-cell.nr-data.net',
        errorBeacon: 'bam-cell.nr-data.net',
        licenseKey: `${applicationConfig.NEW_RELIC_LICENSE_KEY}`,
        applicationID: `${applicationConfig.NEW_RELIC_APPLICATION_ID}`,
        sa: 1,
      }, // NREUM.info
      loader_config: {
        accountID: `${applicationConfig.NEW_RELIC_ACCOUNT_ID}`,
        trustKey: `${applicationConfig.NEW_RELIC_TRUST_KEY}`,
        agentID: `${applicationConfig.NEW_RELIC_APPLICATION_ID}`,
        licenseKey: `${applicationConfig.NEW_RELIC_LICENSE_KEY}`,
        applicationID: `${applicationConfig.NEW_RELIC_APPLICATION_ID}`,
      }, // NREUM.loader_config
    }

    // The agent loader code executes immediately on instantiation.
    new BrowserAgent(options)
  }
}
