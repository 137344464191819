import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 135,
  height: 135,
}

const PizzaSlice6 = (props: SvgIconProps = defaultDimensions) => {
  const { width, height } = props

  return (
    <svg width={width} height={height} viewBox='0 0 135 135' version='1.1'>
      <title>Pizza Copy</title>
      <g id='Payment-Loader-|-Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Pizza-Ghost-Copy-4' transform='translate(0.013179, 0.000000)'>
          <g id='Slice-6' transform='translate(0.001196, 69.000906)' fill='#E21836'>
            <path
              d='M2.38781541,0 C1.05601414,-0.00238827553 -0.0459210755,1.10039749 0.0014739876,2.44239925 C0.520449928,17.1470068 6.05145379,31.7033004 16.5968553,43.2526489 C17.4997313,44.240611 19.0590288,44.2430032 20.0021906,43.2957078 L25,38.2769561 C14.5873046,27.7131775 9.40228475,13.8577868 9.43783104,0.0167490045 L2.38781541,0 Z'
              id='Fill-32'
            />
            <path
              d='M45.0630166,7.86821705 C44.3749716,7.03505522 44.4900453,5.80091926 45.3219325,5.10941895 C46.1538197,4.42031968 47.3860676,4.53556973 48.0741126,5.36873156 C48.764555,6.20189339 48.6494813,7.43602936 47.8151967,8.12752967 C46.9833095,8.81662894 45.7510616,8.70137888 45.0630166,7.86821705 M26.0758506,18.8625918 C24.4672157,16.9177471 24.7381185,14.0388969 26.6799877,12.4277972 C28.6194596,10.8166975 31.4963029,11.0880154 33.1049378,13.03286 C34.7111753,14.9753036 34.44267,17.8565548 32.5008007,19.4676545 C30.5589315,21.0763532 27.6844855,20.8074364 26.0758506,18.8625918 M32.412098,4.50675722 C33.800175,3.35665775 35.8547206,3.54874117 37.0030605,4.93654387 C38.1514005,6.32674762 37.9572136,8.38444124 36.571534,9.53213967 C35.1858545,10.6846402 33.1313089,10.4901557 31.9829689,9.10235302 C30.834629,7.71455032 31.0264185,5.65685669 32.412098,4.50675722 M23.7120444,8.84304041 C23.0239993,8.00987858 23.1390731,6.77334157 23.9709602,6.0842423 C24.8028474,5.39274199 26.0350953,5.51039308 26.7255377,6.34355492 C27.4135828,7.17671675 27.298509,8.41085271 26.4666218,9.09995198 C25.6347346,9.79145229 24.4024867,9.67380119 23.7120444,8.84304041 M59.32257,0.110447966 L14.0001496,0 C13.9691349,12.6534952 18.7590791,25.3213967 28.3749279,35 L60.5020758,2.98449612 C61.5617131,1.92803732 60.8185285,0.115250051 59.32257,0.110447966'
              id='Fill-34'
            />
          </g>
          <g id='Slice-5' transform='translate(21.001196, 74.000906)' fill='#007AAF'>
            <path
              d='M0.709950548,40.9567798 C-0.240978995,41.8925105 -0.243374283,43.4387053 0.743484588,44.3367239 C11.5510264,54.1890014 25.8245507,60.4091355 41.5400387,60.9983866 C42.8837955,61.0478836 44,59.9518767 44,58.6272403 L44,51.6151528 C29.0797478,51.6151528 15.5487629,45.6425042 5.74724231,36 L0.709950548,40.9567798 Z'
              id='Fill-1'
            />
            <path
              d='M36.5491004,16.0646135 C35.4699217,15.9663298 34.6767614,15.0146562 34.7753056,13.93833 C34.8738497,12.8644011 35.8280456,12.0709402 36.9048208,12.1692239 C37.9839995,12.2675075 38.7795632,13.2191812 38.6810191,14.2955073 C38.5824749,15.3718334 37.6282791,16.1628972 36.5491004,16.0646135 M30.924873,37.2651217 C28.4083917,37.0349941 26.5552809,34.8152213 26.7836149,32.3053918 C27.0143524,29.7955623 29.2400082,27.9473497 31.7564895,28.1774773 C34.2729707,28.407605 36.1260816,30.6273778 35.895344,33.1372073 C35.66701,35.6470368 33.4389507,37.4952493 30.924873,37.2651217 M25.2165225,22.6640028 C25.3823651,20.870925 26.9734927,19.5500883 28.7689191,19.7154925 C30.5667491,19.8784996 31.8886829,21.4630242 31.7252438,23.256102 C31.5618047,25.0491798 29.9706771,26.3700166 28.1752507,26.2046123 C26.3774207,26.0416052 25.0530834,24.4546834 25.2165225,22.6640028 M22.1424255,31.8834911 C21.0632468,31.7852074 20.2700865,30.8335338 20.3686307,29.7572077 C20.4671748,28.6808815 21.4213707,27.8898178 22.4981459,27.9881015 C23.5773245,28.0863851 24.3728883,29.0380588 24.2743442,30.1143849 C24.1758,31.190711 23.2216042,31.9817748 22.1424255,31.8834911 M41.1302019,0.495040797 L9,32.5403137 C17.9579041,41.4745396 30.3312045,47 44,47 L44,1.68163641 C44,0.18580679 42.1877489,-0.562108018 41.1302019,0.495040797'
              id='Fill-3'
            />
          </g>
          <g id='Slice-4' transform='translate(67.001196, 74.000906)' fill='#007AAF'>
            <path
              d='M0.0144392336,58.1739088 C0.0168457726,59.7621356 1.35728796,61.072305 2.97688867,60.9968995 C17.4450008,60.3182507 31.7181832,54.8725649 43.0987059,44.6621986 C44.2971622,43.5876712 44.2899426,41.7284561 43.139617,40.604444 L38.4179876,36 C27.8388424,46.4012363 13.9242343,51.6183494 0,51.6466264 L0.0144392336,58.1739088 Z'
              id='Fill-5'
            />
            <path
              d='M8.81563031,15.9077002 C7.98560992,16.6004513 6.75257385,16.4901864 6.05929092,15.6631998 C5.36600799,14.8338161 5.47635752,13.5993288 6.30637791,12.9065777 C7.1339994,12.2162237 8.36943438,12.3240915 9.06271731,13.1534752 C9.75600024,13.9828589 9.64565071,15.2149491 8.81563031,15.9077002 M19.8865671,34.8420982 C17.9506524,36.4577184 15.071969,36.2012327 13.4551085,34.2692002 C11.8382479,32.3347706 12.0949305,29.4582954 14.0284462,27.8426752 C15.9643609,26.2246579 18.8430443,26.4811436 20.4599048,28.4155732 C22.0791643,30.3500027 21.8224817,33.2264779 19.8865671,34.8420982 M5.51473997,28.5713822 C4.35846878,27.1906741 4.54318431,25.1339944 5.92495236,23.9810072 C7.30672041,22.825623 9.36258017,23.0101969 10.5188514,24.3909049 C11.6727236,25.771613 11.490407,27.8258957 10.108639,28.98128 C8.72687091,30.1342671 6.66861225,29.9520903 5.51473997,28.5713822 M9.88554099,37.2511461 C9.0579195,37.9415002 7.82248453,37.8336324 7.1292016,37.0042487 C6.43591867,36.174865 6.5462682,34.9427748 7.37628859,34.2500237 C8.20391008,33.5572726 9.43934506,33.6675375 10.132628,34.4969211 C10.8259109,35.3239078 10.7155614,36.558395 9.88554099,37.2511461 M1.00000238,1.68592739 L1.09596088,47 C13.738179,46.9736323 26.3732004,42.1291687 36.0000024,32.4690061 L3.86429479,0.492190185 C2.80397973,-0.562517388 0.997605866,0.190160289 1.00000238,1.68592739'
              id='Fill-7'
            />
          </g>
          <g id='Slice-3' transform='translate(73.001196, 68.000906)' fill='#007AAF'>
            <path
              d='M40.0999972,43.2810247 C41.062031,44.2376571 42.6597379,44.2472474 43.5925454,43.2642417 C53.8242017,32.491937 60.3148858,18.2335582 60.9992693,2.50546592 C61.057722,1.16042632 59.9276367,0.0383612007 58.5588695,0.0335660506 L51.3131715,0 C51.2449767,14.9344949 45.0100231,28.4496255 35,38.2149486 L40.0999972,43.2810247 Z'
              id='Fill-9'
            />
            <path
              d='M16.0356123,8.48789346 C15.9325494,9.56032222 14.9786191,10.3478871 13.9024514,10.2449531 C12.8286806,10.1420191 12.0401301,9.18928102 12.1431929,8.11445845 C12.2462557,7.04202969 13.2001861,6.25446482 14.2763538,6.35739883 C15.3525214,6.46033284 16.1386751,7.4154647 16.0356123,8.48789346 M37.2066345,14.1875651 C36.9669536,16.6914947 34.7379203,18.5275502 32.2308571,18.285775 C29.7213971,18.0463935 27.8830438,15.8201463 28.1251217,13.3162167 C28.3648026,10.812287 30.5938359,8.97623156 33.1008991,9.21561298 C35.6103591,9.45738821 37.4487124,11.6812416 37.2066345,14.1875651 M22.5813002,19.8034532 C20.7908832,19.6310985 19.4774314,18.0416059 19.6500017,16.2534267 C19.8201752,14.4628537 21.411657,13.1534373 23.2044708,13.3257919 C24.9948879,13.4957528 26.3083397,15.0852454 26.1357694,16.8758184 C25.9655959,18.6639976 24.3741141,19.9758078 22.5813002,19.8034532 M31.7850504,22.9082302 C31.6819876,23.9806589 30.7280573,24.7682238 29.6542864,24.6652898 C28.5781187,24.5623558 27.7919651,23.6096177 27.8950279,22.5347952 C27.9980907,21.4623664 28.9520211,20.6748015 30.0257919,20.7777355 C31.1019596,20.8806695 31.8881133,21.8358014 31.7850504,22.9082302 M0.489902936,3.85586299 L32.3818561,36 C41.3555126,27.1189493 46.9376829,14.819532 47,1.20591068 L1.68830791,1.00002127 C0.192698502,0.992861217 -0.562296632,2.79540331 0.489902936,3.85586299'
              id='Fill-12'
            />
          </g>
          <g id='Slice-2' transform='translate(73.001196, 22.000906)' fill='#007AAF'>
            <path
              d='M57.6121846,44 C58.9439859,44.0023883 60.0459211,42.8996025 59.998526,41.5576008 C59.4795501,26.8529932 53.9485462,12.2966996 43.4031447,0.747351063 C42.5002687,-0.24061102 40.9409712,-0.24300318 39.9978094,0.704292183 L35,5.72304387 C45.4126954,16.2868225 50.5977153,30.1422132 50.562169,43.983251 L57.6121846,44 Z'
              id='Fill-14'
            />
            <path
              d='M15.9369834,36.1317829 C16.6250284,36.9649448 16.5099547,38.1990807 15.6780675,38.8905811 C14.8461803,39.5796803 13.6139324,39.4644303 12.9258874,38.6312684 C12.235445,37.7981066 12.3505187,36.5639706 13.1848033,35.8724703 C14.0166905,35.1833711 15.2489384,35.2986211 15.9369834,36.1317829 M34.9241494,25.1374082 C36.5327843,27.0822529 36.2618815,29.9611031 34.3200123,31.5722028 C32.3805404,33.1833025 29.5036971,32.9119846 27.8950622,30.96714 C26.2888247,29.0246964 26.55733,26.1434452 28.4991993,24.5323455 C30.4410685,22.9236468 33.3155145,23.1925636 34.9241494,25.1374082 M28.587902,39.4932428 C27.199825,40.6433423 25.1452794,40.4512588 23.9969395,39.0634561 C22.8485995,37.6732524 23.0427864,35.6155588 24.428466,34.4678603 C25.8141455,33.3153598 27.8686911,33.5098443 29.0170311,34.897647 C30.165371,36.2854497 29.9735815,38.3431433 28.587902,39.4932428 M37.2879556,35.1569596 C37.9760007,35.9901214 37.8609269,37.2266584 37.0290398,37.9157577 C36.1971526,38.607258 34.9649047,38.4896069 34.2744623,37.6564451 C33.5864172,36.8232833 33.701491,35.5891473 34.5333782,34.900048 C35.3652654,34.2085477 36.5975133,34.3261988 37.2879556,35.1569596 M1.67742998,43.889552 L46.9998504,44 C47.0308651,31.3465048 42.2409209,18.6786033 32.6250721,9 L0.497924217,41.0155039 C-0.561713076,42.0719627 0.181471451,43.8847499 1.67742998,43.889552'
              id='Fill-16'
            />
          </g>
          <g id='Slice-1' transform='translate(68.001196, 0.000000)' fill='#007AAF'>
            <path
              d='M43.2900495,20.0439468 C44.240979,19.1081822 44.2433743,17.5619314 43.2565154,16.6638803 C32.4489736,6.81124551 18.1754493,0.590885913 2.45996131,0.00161349916 C1.11620445,-0.0478853836 0,1.04816131 0,2.37284569 L0,9.38518741 C14.9202522,9.38518741 28.4512371,15.3580526 38.2527577,25.0009064 L43.2900495,20.0439468 Z'
              id='Fill-18'
            />
            <path
              d='M7.4508996,44.9362929 C8.53007829,45.0345766 9.32323857,45.9862502 9.22469441,47.0625763 C9.12615025,48.1365053 8.1719544,48.9299662 7.09517923,48.8316825 C6.01600055,48.7333988 5.22043675,47.7817252 5.31898091,46.7053991 C5.41752507,45.629073 6.37172092,44.8380092 7.4508996,44.9362929 M13.075127,23.7357847 C15.5916083,23.9659123 17.4447191,26.1856851 17.2163851,28.6955146 C16.9856476,31.2053441 14.7599918,33.0535567 12.2435105,32.823429 C9.72702925,32.5933014 7.87391842,30.3735286 8.10465595,27.8636991 C8.33298997,25.3538696 10.5610493,23.5056571 13.075127,23.7357847 M18.7834775,38.3369036 C18.6176349,40.1299814 17.0265073,41.4508181 15.2310809,41.2854139 C13.4332509,41.1224068 12.1113171,39.5378822 12.2747562,37.7448044 C12.4381953,35.9517265 14.0293229,34.6308898 15.8247493,34.7962941 C17.6225793,34.9593011 18.9469166,36.5462229 18.7834775,38.3369036 M21.8575745,29.1174153 C22.9367532,29.215699 23.7299135,30.1673726 23.6313693,31.2436987 C23.5328252,32.3200248 22.5786293,33.1110886 21.5018541,33.0128049 C20.4226755,32.9145212 19.6271117,31.9628476 19.7256558,30.8865215 C19.8242,29.8101953 20.7783958,29.0191316 21.8575745,29.1174153 M2.8697981,60.5058656 L35,28.4605927 C26.0420959,19.5263667 13.6687955,14.0009064 0,14.0009064 L0,59.31927 C0,60.8150996 1.81225106,61.5630144 2.8697981,60.5058656'
              id='Fill-21'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PizzaSlice6
