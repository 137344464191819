import React from 'react'
import { RadioInputFieldProps } from './radio-input-field.interface'
import css from './radio-input-field.less'

export const RadioInputField = ({
  value,
  onChecked,
  selectedOption,
  labelText,
  testID,
  fieldName,
  fieldId,
  inputStyle,
  labelStyle,
  ...rest
}: RadioInputFieldProps) => {
  const handleChecked = (event: React.MouseEvent): void => {
    const { name: targetName, value: targetValue } = event.currentTarget as HTMLInputElement
    onChecked(targetName, targetValue)
    event.preventDefault()
  }

  return (
    <div className={css.radioInputContainer}>
      <input
        type='radio'
        value={value}
        id={fieldId}
        name={fieldName}
        defaultChecked={value === selectedOption}
        onClick={handleChecked}
        data-testid={`${testID}.input`}
        style={inputStyle}
        {...rest}
      />
      <label htmlFor={fieldId} style={labelStyle} data-testid={`${testID}.label`}>
        {labelText}
      </label>
    </div>
  )
}
