import React from 'react'
import { ReactElement } from 'react'
import { DecrementButton } from './decrement-button'
import { IncrementButton } from './increment-button'
import { IngredientPrice } from './ingredient-price'
import { LeftAdjustmentButtonWithPriceProps } from '../product-ingredient-item.interface'
import { isDisabled } from '../helpers'

export const LeftAdjustmentButtonWithPrice = ({
  onChange,
  isAddAllowed = true,
  isRemoveAllowed = true,
  minimumReached = false,
  maximumReached = false,
  value,
  showPrice,
  showMinimumQuantity,
  testID,
}: LeftAdjustmentButtonWithPriceProps): ReactElement => (
  <IngredientPrice value={value} showPrice={showPrice} testID={`${testID}.price`}>
    {!minimumReached || showMinimumQuantity ? (
      <DecrementButton
        onChange={onChange}
        testID={`${testID}.decrement`}
        disabled={isDisabled(minimumReached, isRemoveAllowed)}
      />
    ) : (
      <IncrementButton
        onChange={onChange}
        testID={`${testID}.increment`}
        disabled={isDisabled(maximumReached, isAddAllowed)}
      />
    )}
  </IngredientPrice>
)
