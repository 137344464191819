import { useState } from 'react'
import { ToggleDictionary } from './interfaces'
import { resetToggleValues } from './reset-toggle-values'

export const useMultiToggleExclusiveSelector = () => {
  const [toggles, setToggles] = useState<ToggleDictionary>()

  const reset = () => {
    setToggles(resetToggleValues(toggles))
  }

  const initialize = (initializationData: ToggleDictionary) => {
    setToggles(initializationData)
  }

  const set = (name: string, value: boolean) => {
    if (value) {
      const newToggles = resetToggleValues(toggles)
      newToggles[name] = { ...newToggles[name], value }
      setToggles(newToggles)
    } else {
      reset()
    }
  }

  return { initialize, set, reset, toggles }
}
