import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'
import { AuthResponse } from './auth-message-helper'

interface AuthTranslations {
  title: string
  subtitle: string
  description: string
}

export const mapToCreateAccountProps = (
  provider: BffContext.SocialLogins.Providers,
  customerInfo: Bff.SocialLogins.SocialLogin,
  userDetails: AuthResponse['user'],
  getAuthTranslations: (provider: BffContext.SocialLogins.Providers) => AuthTranslations,
) => {
  const { title, subtitle, description } = getAuthTranslations(provider)
  const { firstName, lastName, identityProviderId, email } = customerInfo

  let name = userDetails?.name && `${userDetails.name.firstName} ${userDetails.name.lastName}`

  if (!name && firstName && lastName) {
    name = `${firstName} ${lastName}`
  }

  return {
    // redirect should be handled in native later where the webview is embedded
    title,
    subtitle,
    description,
    name,
    email,
    identityProvider: {
      identityProviderId,
      externalProviders: [
        {
          providerType: provider.toLowerCase(),
          providerId: identityProviderId,
        },
      ],
    },
  } as CreateAccountSceneProps
}
