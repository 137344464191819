/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import { allergensMockData, nutritionalsMockData } from '@dominos/business/functions/nutritional/index'
import {
  BottomBar,
  CountryToggle,
  GenericBottomBarButton,
  ProductEditor,
  ProductEditorContentWrapper,
  ProductImage,
  useProductContext,
} from '@dominos/components'
import {
  createBasketLineSwap,
  findSauceSwapFromPortions,
  getCommonIngredients,
  getCommonMinQuantityRule,
  useBaseData,
  useSauceData,
  useSizeData,
} from '@dominos/components/product/functions'
import { ProductCustomiser } from '@dominos/components/product/product-customiser'
import { ProductNutritionalCard } from '@dominos/components/product/product-nutritional-feature-product'
import {
  ProductAllergen,
  ProductNutritionals,
} from '@dominos/components/product/product-nutritional-feature-product/product-nutritional.interface'
import {
  useBreakpoints,
  useDominosTheme,
  useFeatures,
  useKiosk,
  useProductIngredientsDictionary,
} from '@dominos/hooks-and-hocs'
import { isEqual, sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import PortionProductDetails from '../portion-product-details/portion-product-details'
import PortionProductList from '../portion-product-list/portion-product-list'
import PortionProductSummary from '../portion-product-summary/portion-product-summary'
import { PortionProductEditorProps } from './portion-product-editor.interface'
import css from './portion-product-editor.less'

const applySauceToPortions = (
  selectedSauce: string,
  portions: Portion[],
  sauceIngredientsMap: Map<string, IngredientModel[]>,
  handlePortionCustomisations: (
    index: number,
    key: 'sauce' | 'toppings',
    changes: BasketLineChange[] | BasketLineSwap | undefined,
  ) => void,
) => {
  portions.forEach((portion, index) => {
    if (!portion.productCode) return undefined
    const defaultSauce = sauceIngredientsMap.get(portion.productCode)?.find((ingredient) => ingredient.inRecipe)
    const swap = createBasketLineSwap(selectedSauce, defaultSauce?.code)
    handlePortionCustomisations(index, 'sauce', swap)
  })
}

//TODO: MAB-2600 getNutritionalInfo() functionality needed to added with state menagement card
const getNutritionalInfo = (
  nutritionalEnabled: boolean,
): { productNutritionals: ProductNutritionals | null; productAllergens: ProductAllergen[] } =>
  nutritionalEnabled
    ? {
        productNutritionals: nutritionalsMockData as ProductNutritionals,
        productAllergens: allergensMockData as ProductAllergen[],
      }
    : { productNutritionals: null, productAllergens: [] }

export const PortionProductEditor = ({
  testID,
  onDismiss,
  isEditing,
  onPortionCustomise,
  addToBasket,
  t,
  allowSauceCustomisation,
}: PortionProductEditorProps) => {
  const theme = useDominosTheme()
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isMobileLayout = isMobile || isKioskOrder
  const {
    createBasketLine,
    dimensionSetState,
    portions,
    handlePortionChange,
    isValidForBasket,
    initialQuantity,
    resetPortion,
    formattedPrice,
    formattedPromoPrice,
    productData,
    handlePortionCustomisations,
  } = useProductContext()
  const showContainsAllergensOnly = CountryToggle({
    FR: true,
    default: false,
  })
  const [nutritionalEnabled, nutritionalInfoButtonEnabled, showPortionProductSummary] = useFeatures(
    'nutritionals',
    'ProductNutritionalInformationButton',
    'ShowPortionProductSummary',
  )
  const [selectingProductIndex, setSelectingProductIndex] = useState<number | undefined>(undefined)
  const [isSwapPortionsActive, setIsSwapPortionsActive] = useState<boolean>(false)
  const hasAllDefaultPortions = productData?.defaultPortions?.every((portion) => !!portion.defaultProductCode) ?? false
  const showSummary = showPortionProductSummary && !isSwapPortionsActive && isValidForBasket && hasAllDefaultPortions
  const [productCodes, setProductCodes] = useState<string[]>([])
  const [selectedSauce, setSelectedSauce] = useState<string | undefined | null>()
  const { getIngredients, getIngredientTypeRule } = useProductIngredientsDictionary(productCodes)
  useEffect(() => {
    if (!isValidForBasket) {
      setProductCodes([])

      return
    }
    if (portions && allowSauceCustomisation) {
      const newProductCodes: string[] = portions.map((portion) => portion.productCode ?? '')
      if (!isEqual(sortBy(newProductCodes), sortBy(productCodes))) {
        setProductCodes(newProductCodes)
      }
    }
  }, [isValidForBasket, portions, allowSauceCustomisation])
  const sauceIngredientsMap = getIngredients('Sauce', dimensionSetState?.selectedDimensionSet)
  const sauceRulesMap = getIngredientTypeRule('Sauce', dimensionSetState?.selectedDimensionSet)
  const commonSauces = getCommonIngredients(sauceIngredientsMap)
  const sauceSwap = findSauceSwapFromPortions(portions)
  const commonMinQuantityRule = getCommonMinQuantityRule(sauceRulesMap)
  const sizeData = useSizeData(productData, dimensionSetState)
  const baseData = useBaseData(productData, dimensionSetState)
  const sauceData = useSauceData(commonSauces, sauceSwap, commonMinQuantityRule === 0)
  const isAllIngredientsFetched =
    portions && portions.map((portion) => portion.productCode || '').every((code) => sauceIngredientsMap.has(code))
  const { productNutritionals, productAllergens } = getNutritionalInfo(nutritionalEnabled)

  useEffect(() => {
    if (!(sauceData.selectedItemCode && portions && isAllIngredientsFetched)) return
    const selectedSauceCode =
      selectedSauce && sauceData.items.some((sauce) => sauce.code === selectedSauce)
        ? selectedSauce
        : sauceData.selectedItemCode
    applySauceToPortions(selectedSauceCode, portions, sauceIngredientsMap, handlePortionCustomisations)
  }, [isAllIngredientsFetched])

  const onSizeChange = (sizeCode: string) => {
    dimensionSetState?.handleDimensionChange('Size', sizeCode, true)
  }
  const onBaseChange = (baseCode: string) => {
    dimensionSetState?.handleDimensionChange('Base', baseCode)
  }
  const onSauceChange = (sauceCode: string) => {
    if (portions) {
      setSelectedSauce(sauceCode)
      applySauceToPortions(sauceCode, portions, sauceIngredientsMap, handlePortionCustomisations)
    }
  }
  const addToBasketHandler = (quantity: number) => {
    addToBasket(createBasketLine(quantity))
    onDismiss(true)
  }

  const onProductListOpen = (index: number) => {
    setSelectingProductIndex(index)
  }

  const onValueChange = (value: string) => {
    if (selectingProductIndex !== undefined) {
      handlePortionChange(selectingProductIndex, value)
    }
    setSelectingProductIndex(undefined)
  }

  if (selectingProductIndex !== undefined) {
    return (
      <ProductEditor
        testID={`${testID}.product-editor-portion-list`}
        onDismiss={onDismiss}
        showBackButton={false}
        showCloseButton={false}
      >
        <PortionProductList
          testID={testID}
          productList={productData?.getPossiblePortionProducts(dimensionSetState?.selectedDimensionSet) || []}
          onValueChange={onValueChange}
          t={t}
          theme={theme}
        />
        <GenericBottomBarButton
          testID={`${testID}.generic-to-basket-portion`}
          buttonText={t('PortionProductCard.ListCancel', 'Cancel')}
          onClick={() => setSelectingProductIndex(undefined)}
        />
      </ProductEditor>
    )
  }

  return (
    <ProductEditor
      testID={`${testID}.product-editor`}
      onDismiss={onDismiss}
      showBackButton={!!isMobileLayout}
      showCloseButton={!isMobileLayout}
    >
      {showSummary && <ProductImage testID={testID} uri={productData?.media?.largeImage.uri ?? ''}></ProductImage>}
      <ProductEditorContentWrapper
        testID={`${testID}.product-editor-content`}
        className={showSummary ? css.portionProductEditorContent : undefined}
      >
        {showSummary ? (
          <PortionProductSummary
            testID={testID}
            media={productData?.media}
            t={t}
            formattedPrice={formattedPrice}
            formattedPromoPrice={formattedPromoPrice}
            //TODO: MAB-2600
            //energyKJ={12}
            onSwapPortionsClick={() => setIsSwapPortionsActive(true)}
          />
        ) : (
          <PortionProductDetails
            testID={testID}
            productData={productData}
            formattedPrice={formattedPrice}
            formattedPromoPrice={formattedPromoPrice}
            dimensionSetState={dimensionSetState}
            portions={portions}
            onProductListOpen={onProductListOpen}
            resetPortion={resetPortion}
            onPortionCustomise={onPortionCustomise}
            t={t}
          />
        )}
        <ProductCustomiser
          sizeData={sizeData}
          onSizeChange={onSizeChange}
          baseData={baseData}
          onBaseChange={onBaseChange}
          sauceData={sauceData}
          onSauceChange={onSauceChange}
        />
        <ProductNutritionalCard
          nutritionals={productNutritionals}
          allergens={productAllergens}
          t={t}
          showContainsAllergensOnly={showContainsAllergensOnly}
          testID='nutritional-card'
          showInfoButton={nutritionalInfoButtonEnabled}
        />
      </ProductEditorContentWrapper>
      <BottomBar
        testID={`${testID}.bottom-bar`}
        onComplete={addToBasketHandler}
        isEditing={isEditing}
        initialQuantity={initialQuantity}
        isPortionProduct={false}
        forceDisabled={!isValidForBasket}
      />
    </ProductEditor>
  )
}
