import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'
import { Observable, Subscriber } from 'rxjs'
import { apolloClient } from '../apollo'
import { FeatureFlagType } from '@dominos/hooks-and-hocs/features'

export const graphQLQuery = <U>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: any,
  config: GraphConfig,
  securitySender: SecuritySender,
  features: FeatureFlagType,
) =>
  new Observable((observer: Subscriber<U>) => {
    apolloClient(config, securitySender, features)
      .query<U>({ query, fetchPolicy: 'no-cache', variables: config.variables })
      .then((out) => {
        out && out.data ? observer.next(out.data) : observer.error(out.errors)
      })
      .catch((err) => {
        observer.error(err)
      })
  })
