import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 46,
  height: 73,
}

export const Gauge7 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 46 73'>
      <defs>
        <path
          d='M17.6425527,15.9 L17.6425527,24.2 C17.6425527,25.4 16.4425527,26.2 15.3425527,25.7 L4.4425527,21.2 C3.0425527,20.6 2.1425527,19.2 2.1425527,17.7 L2.1425527,4 C2.1425527,2.6 3.2425527,1.5 4.6425527,1.5 L41.6425527,1.5 C43.0425527,1.5 44.1425527,2.6 44.1425527,4 L44.1425527,11.9 C44.1425527,13.3 43.8425527,15.4 43.3425527,16.8 L26.0425527,69.1 C25.5425527,70.5 24.2425527,71.5 22.7425527,71.5 L11.7425527,71.5 C10.5425527,71.5 9.6425527,70.3 10.0425527,69.1 L27.5425527,15.9 C27.6425527,15.6 27.5425527,15.4 27.2425527,15.4 L18.1425527,15.4 C17.8425527,15.4 17.6425527,15.6 17.6425527,15.9 Z'
          id='gauge-path-7'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='7' fillRule='nonzero'>
          <use fill={fill} href='#gauge-path-7' />
          <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-7' />
        </g>
      </g>
    </svg>
  )
}
