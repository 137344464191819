const getDimensionSetTypeFromProduct = (
  product:
    | (Bff.Menu.old.ProductMenuItem & {
        category?: string | undefined
      })
    | (Bff.Menu.old.PortionMenuItem & {
        category?: string | undefined
      })
    | null,
): Bff.Common.DimensionSetTypeCode => {
  if (product?.sizes?.[0]?.recipe?.base?.code) return 'SizeBase'
  if ((product?.sizes?.length ?? 0) > 0) return 'Size'

  return 'NonDimensional'
}

export { getDimensionSetTypeFromProduct }
