export const getProviderType = (
  identityProvider: Bff.Customers.UpsertCustomerIdentityProvider | undefined | null,
): BffContext.ProviderTypes | undefined => {
  if (identityProvider?.externalProviders?.length) {
    return identityProvider.externalProviders[0]?.providerType
  }

  // if there's no identityProvider then it's a dominos account
  return 'dominos'
}

export const getProviderId = (
  identityProvider: Bff.Customers.UpsertCustomerIdentityProvider | undefined | null,
): string | undefined => identityProvider?.externalProviders?.[0]?.providerId
