import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 23,
  height: 24,
}

const GoogleIcon = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 23 24' {...props}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-25.000000, -313.000000)'>
          <g transform='translate(12.000000, 302.000000)'>
            <g transform='translate(13.335092, 11.000000)'>
              <path
                d='M4.65682418,11.7446809 C4.65682418,10.9987631 4.77678802,10.2836426 4.9909007,9.61289078 L1.24316959,6.65740709 C0.512756836,8.18891348 0.101235308,9.91459858 0.101235308,11.7446809 C0.101235308,13.5731972 0.51225066,15.2978383 1.24165106,16.8283007 L4.98735746,13.8670752 C4.77526949,13.1994553 4.65682418,12.4869447 4.65682418,11.7446809'
                id='Fill-1'
                fill='#FBBC05'
              />
              <path
                d='M11.2371192,4.95886525 C12.8062665,4.95886525 14.2235608,5.53304965 15.3371492,6.47262411 L18.5766791,3.13191489 C16.6025905,1.35716312 14.0717078,0.260992908 11.2371192,0.260992908 C6.83642036,0.260992908 3.05426925,2.85996028 1.24316959,6.65740709 L4.9909007,9.61289078 C5.85443787,6.90587234 8.31597439,4.95886525 11.2371192,4.95886525'
                id='Fill-2'
                fill='#EA4335'
              />
              <path
                d='M11.2371192,18.5304965 C8.31597439,18.5304965 5.85443787,16.5834894 4.9909007,13.8764709 L1.24316959,16.8314326 C3.05426925,20.6294014 6.83642036,23.2283688 11.2371192,23.2283688 C13.9532625,23.2283688 16.546405,22.2324199 18.4926538,20.3663206 L14.935245,17.5261957 C13.9314969,18.1792 12.6675741,18.5304965 11.2371192,18.5304965'
                id='Fill-3'
                fill='#34A853'
              />
              <path
                d='M21.8668266,11.7446809 C21.8668266,11.0660993 21.7655913,10.3353191 21.6137383,9.65673759 L11.2371192,9.65673759 L11.2371192,14.093617 L17.2100024,14.093617 C16.9113582,15.6063319 16.0984387,16.7693163 14.935245,17.5261957 L18.4926538,20.3663206 C20.5371008,18.4067858 21.8668266,15.4878411 21.8668266,11.7446809'
                id='Fill-4'
                fill='#4285F4'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { GoogleIcon }
