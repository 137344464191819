import { DEFAULT_LIGHT_COLOUR, DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const DEClassic = ({ color }: SizeSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR
  const selectedColorLight = color ? color : DEFAULT_LIGHT_COLOUR

  return (
    <svg width='70px' height='70px' viewBox='0 0 70 70' version='1.1'>
      <defs>
        <path
          d='M35,17 C25.0588745,17 17,25.0588745 17,35 C17,44.9411255 25.0588745,53 35,53 C44.9411255,53 53,44.9411255 53,35 C53,25.0588745 44.9411255,17 35,17 Z M35,20 C43.2842712,20 50,26.7157288 50,35 C50,43.2842712 43.2842712,50 35,50 C26.7157288,50 20,43.2842712 20,35 C20,26.7157288 26.7157288,20 35,20 Z'
          id='path-de-pizza-classic'
        />
      </defs>
      <g id='UI/Symbols/Product-Size/DE/de-pizza-classic' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <mask id='mask-de-pizza-classic' fill='white'>
          <use href='#path-de-pizza-classic' />
        </mask>
        <use id='M' fill={selectedColor} fillRule='nonzero' href='#path-de-pizza-classic' />
        <rect
          id='Colour'
          fill={selectedColorLight}
          mask='url(#mask-de-pizza-classic)'
          x='0'
          y='0'
          width='70'
          height='70'
        />
      </g>
    </svg>
  )
}
