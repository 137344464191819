import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthButton } from '@dominos/components'
import { useLazyLoadSdk } from '@dominos/hooks-and-hocs'
import { FacebookIcon } from '@dominos/res/images/icons/components'

import { FacebookButtonProps, FBLoginResponse, FBRequestedFields } from './facebook-button.interface'
import { safariPopupBlockDetect, setupFacebook } from '@dominos/business/functions'
import { SocialLoginError } from '../auth-button-group.interface'

export const FacebookButton = ({ testID, customer, loading, onReady, onToken, onError }: FacebookButtonProps) => {
  const { t } = useTranslation('login')

  const { lazyLoadSdk, isLazyLoading, sdk } = useLazyLoadSdk('FB', {
    onSdkReady: (sdk) => {
      attemptLogin(sdk)
    },
    loadSdk: setupFacebook,
  })

  const handleFBLogin = (response: FBLoginResponse) => {
    if (response.status === 'connected') {
      onToken('Facebook', response.authResponse.accessToken)
    } else if (response.status === 'unknown') {
      onError('Facebook', SocialLoginError.Unknown, 'Failed to sign in using Facebook')
    }
  }

  useEffect(() => {
    if (!!customer && !customer.customerId) {
      sdk?.api('/me', { fields: 'name,email' }, (requestedFields: FBRequestedFields) => {
        onToken('Facebook', requestedFields.id, {
          email: requestedFields.email,
          name: requestedFields.name,
        })
      })
    }
  }, [customer, sdk])

  const handlePress = () => {
    onReady()
    lazyLoadSdk()
  }

  const attemptLogin = (facebookInstance: Window['FB']) => {
    facebookInstance?.login(handleFBLogin, { scope: 'public_profile,email' })

    const popupBlockerDetect = safariPopupBlockDetect()
    if (popupBlockerDetect) {
      onError('Facebook', SocialLoginError.PopupBlocked, 'Failed to sign in using Facebook')
    }
  }

  return (
    <AuthButton
      testID={testID}
      backgroundColor={'#1778f2'}
      label={t('signUpFacebookTitle', { defaultValue: 'Sign in with Facebook' })}
      loading={loading || isLazyLoading}
      onPress={handlePress}
    >
      <FacebookIcon data-testid={`${testID}.icon`} fill={'#ffffff'} />
    </AuthButton>
  )
}
