import { ActionButton, DeliveryAddressMyLayout, GenericCard } from '@dominos/components'
import { useAlert, useBreakpoints, useCustomerAddressSearch } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  MYAddress,
  MYDeliveryAddressOnChange,
  MYDeliveryAddressOnInitialise,
} from '@dominos/components/delivery-address/delivery-address-container-my'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

const isFormValid = (address: TAddress) => {
  // eslint-disable-next-line guard-for-in
  for (const fieldName in address) {
    const field = address[fieldName as keyof DeliveryAddressToValidate]

    if (field && !field.isValid) {
      return false
    }
  }

  return true
}

function combineUnitNoFields(address: MYAddress): string | undefined {
  if (address?.blockNo?.value || address?.floorNo?.value) {
    return `${address?.blockNo?.value ?? '-'}/${address?.floorNo?.value ?? '-'}/${address?.unitNo?.value ?? '-'}`
  }

  return address?.unitNo?.value ?? ''
}

function combineStreetFields(address: MYAddress): string {
  return `${address?.streetType?.value ?? ''} ${address?.street?.value ?? ''}`.trim()
}

export const DeliveryAddressContainerMY = () => {
  const [address, setAddress] = useState<MYAddress>({})
  const [errorDisplayed, setErrorDisplayed] = useState(false)
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation('delivery-address')
  const { getAddress, data, loading, errors } = useCustomerAddressSearch()
  const { reportFallBackAddressSearchAddressSearched, reportFallBackAddressSearchNoAddressFound } =
    useReportAutoCompleteDelivery()

  const { showAlert } = useAlert()

  const onInitialise: MYDeliveryAddressOnInitialise = (initialState) => {
    setAddress(initialState)
  }

  const onFindAddress = () => {
    setErrorDisplayed(false)
    const addressMapped = {
      unitNo: combineUnitNoFields(address),
      streetNo: (address.streetNo && address.streetNo.value) || '',
      streetName: combineStreetFields(address),
      postCode: (address.postCode && address.postCode.value) ?? '',
      suburb: (address.suburb && address.suburb.value) ?? '',
      buildingName: (address.buildingName && address.buildingName.value) || '',
    }
    getAddress(addressMapped)
    reportFallBackAddressSearchAddressSearched(addressMapped)
  }

  const onChange: MYDeliveryAddressOnChange = (updatedFields: Partial<MYAddress>) => {
    setAddress({
      ...address,
      ...updatedFields,
    })
  }

  useEffect(() => {
    if (data) {
      if (data.deliverySearch.length === 0) {
        reportFallBackAddressSearchNoAddressFound(address)
        if (!errorDisplayed) {
          showAlert(
            t('Empty Address Result Alert', {
              defaultValue:
                'We’re sorry, this delivery address is not recognised in our system. Please check the address you have entered and try searching again. You can also call us on 131 888 to place an order.',
            }),
            'Empty Address Result Alert',
          )
          setErrorDisplayed(true)
        }
      } else {
        navigate(NavigationConstants.addressSearchResults, {
          state: data,
        })
      }
    }

    if (errors && errors.length > 0 && !errorDisplayed) {
      showAlert(errors[0].message)
      setErrorDisplayed(true)
    }
  }, [data, errors])

  return (
    <>
      <GenericCard
        testID='delivery-address.Card'
        title={t('Delivery Address')}
        width={isMobile ? magicStyles.mobile.staticCardWidth : magicStyles.desktop.staticCardWidth}
      >
        <div style={{ marginTop: '14px' }}>
          <DeliveryAddressMyLayout onChange={onChange} onInitialise={onInitialise} />
        </div>
      </GenericCard>
      <ActionButton
        testID='find-address-button'
        onPress={onFindAddress}
        text={t('Find Address')}
        containerStyle={{ width: '100%', marginTop: '8.6px' }}
        textFontWeight='semibold'
        disabled={!isFormValid(address)}
        loading={loading}
      />
    </>
  )
}

const magicStyles = {
  mobile: {
    staticCardWidth: '360px',
  },
  desktop: {
    staticCardWidth: '376px',
  },
}
