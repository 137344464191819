import gql from 'graphql-tag'

export const orderDetailTerminalPaymentQuery = gql`
  query orderDetailTerminalPaymentQuery($id: String!) {
    order(id: $id) {
      id
      status {
        status
      }
      terminalPaymentInfo {
        paymentStatus
      }
    }
  }
`
