import { FRLarge } from './fr-pizza-large'
import { FRMedium } from './fr-pizza-medium'
import { FRExtraLarge } from './fr-pizza-xl'

export const FRSizeIcons: { [code: string]: React.ComponentType<SizeSVGProps> } = {
  'Pizza.Medium': FRMedium,
  'Pizza.Large': FRLarge,
  'Pizza.ExtraLarge': FRExtraLarge,
}

export type FRSizeCode = keyof typeof FRSizeIcons
