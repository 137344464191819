import React, { PropsWithChildren, useEffect, useState } from 'react'
import css from './checkbox.less'

const NUMFORWIDTH = 1.67
const NUMFORHEIGHT = 3.33
const WIDTHRATIO = 1.167
const HEIGHTRATIO = 1.67
const TWO = 2

export const calculateTickStyle = (diameter: number) => {
  const tickWidth = diameter / NUMFORWIDTH
  const tickHeight = diameter / NUMFORHEIGHT
  const tickLeft = (diameter - WIDTHRATIO * tickWidth) / TWO
  const tickTop = (diameter - HEIGHTRATIO * tickHeight) / TWO

  return {
    tickWidth,
    tickHeight,
    tickLeft,
    tickTop,
  }
}

export const Checkbox = (props: PropsWithChildren<CheckboxProps>) => {
  const DEFAULTCHECKBOXSIZE = 20

  const [isChecked, setIsChecked] = useState(!!props.defaultChecked)

  useEffect(() => {
    if (props.checked !== undefined) {
      setIsChecked(props.checked)
    }
  }, [props.checked])

  const {
    diameter = DEFAULTCHECKBOXSIZE,
    checkboxColor = '#007aff',
    tickColor = '#fff',
    onChange,
    ignoreLabelOnClick = false,
    name = null,
    unCheckedBorderColor = '#454545',
    unCheckedCheckboxColor,
  } = props

  const { tickWidth, tickHeight, tickLeft, tickTop } = calculateTickStyle(diameter)

  const containerStyles = {
    width: diameter,
    height: diameter,
    borderColor: isChecked ? checkboxColor : unCheckedBorderColor,
    backgroundColor: isChecked ? checkboxColor : unCheckedCheckboxColor,
  }

  const tickClass = isChecked ? css.checkmark : undefined

  const tickStyles = {
    top: tickTop,
    left: tickLeft,
    width: tickWidth,
    height: tickHeight,
    borderColor: tickColor,
  }

  const onClick = () => {
    if (props.checked === undefined) {
      setIsChecked(!isChecked)
    }
    onChange(name, !isChecked)
  }

  const nameFormat = `.${name}`

  return (
    <div className={css.wrapper} data-testid={props.testID}>
      <div className={css.checkbox} data-testid={`${props.testID}${name ? nameFormat : ''}.checkbox`}>
        <div
          className={css.container}
          onClick={onClick}
          style={containerStyles}
          role='checkbox'
          data-testid={`${props.testID}.checkbox.container`}
          aria-checked={isChecked}
        >
          <span className={tickClass} style={tickStyles} data-testid={`${props.testID}.checkbox.container.tick`} />
        </div>
      </div>
      <div
        data-testid={`${props.testID}.label`}
        onClick={!ignoreLabelOnClick ? onClick : undefined}
        role='button'
        className={css.label}
      >
        {props.children}
      </div>
    </div>
  )
}
