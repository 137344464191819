import { BasketCoupon } from '@dominos/interfaces/basket/props'
/**
 * Removes a single instance of the specified coupon from
 * the collection of basket items, attaching the coupons
 * selected line items to the basket items
 * @note Iteratate calls to remove multiple instances of a coupon
 * @param basketLines Collection of basket line items
 * @param couponCode Coupon code to remove
 */
export const removeCouponFromBasket = (
  basketLines: BasketLineUnionDependancy[],
  couponCode: BasketCoupon,
): BasketLineUnionDependancy[] => {
  let foundOne = false

  return basketLines.reduce<BasketLineUnionDependancy[]>((memo, basketLine) => {
    if (
      foundOne ||
      basketLine.type === 'BasketLine' ||
      basketLine.code !== couponCode.code ||
      basketLine.isLoyaltyRedemption !== couponCode.isLoyaltyRedemption
    ) {
      return [...memo, basketLine]
    }
    // Attach coupon line items to basket
    let lines: BasketLineUnionDependancy[] = []
    basketLine.items.forEach((item) => {
      lines = [...lines, ...item.lines]
    })

    foundOne = true

    return [...memo, ...lines]
  }, [])
}
