import { useDominosTheme } from '@dominos/hooks-and-hocs/theme'
import React from 'react'
import css from './voucher-input.less'

export const VoucherErrors = ({ voucherErrors, testID }: { voucherErrors: string[]; testID: string }) => {
  const theme = useDominosTheme()

  return (
    <>
      {voucherErrors && voucherErrors.length > 0 && (
        <div data-testid={`${testID}.errors`} className={css.errorContainer}>
          {voucherErrors.map((errorMessage) => (
            <div
              key={errorMessage}
              className={css.error}
              style={{ color: theme.colours.defaultError }}
              data-testid={`${testID}.error-text`}
            >
              {errorMessage}
            </div>
          ))}
        </div>
      )}
    </>
  )
}
