import React, { useMemo } from 'react'

import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon, svgIcons } from '@dominos/res'

import css from './picker-button.less'

const PickerButton = ({
  testID,
  items = [],
  selectedValue,
  onValueChange,
  title,
  icon,
  iconColor,
  containerStyle,
  accessibilityLabel,
  placeholderText,
  placeholderEllipsis,
  iconWidth,
  iconHeight,
  onlyShowPickerWhenMultipleItems,
  pickerButtonStyleOverride,
  titleStyle,
  pickerTextStyle,
}: PickerButtonProps) => {
  const theme = useDominosTheme()

  const enablePicker = useMemo(
    () =>
      (!onlyShowPickerWhenMultipleItems && items.length > 0) || (onlyShowPickerWhenMultipleItems && items.length > 1),
    [items],
  )

  const pickerTextColour = useMemo(
    () =>
      enablePicker
        ? pickerTextStyle?.color || theme.colours.actionAlternative
        : pickerButtonStyleOverride?.pickerDisableTextColour ?? theme.colours.actionTextDefault,
    [enablePicker, pickerButtonStyleOverride?.pickerDisableTextColour],
  )

  const selectedIndex = items.findIndex((item: PickerButtonItem) => item.value === selectedValue)

  const PrefixIcon = icon ? svgIcons[icon] : () => <></>

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => onValueChange && onValueChange(event.target.value)

  return (
    <div
      className={css.container}
      style={{
        borderRadius: theme.borderRadius,
        backgroundColor: theme.colours.defaultButton,
        ...containerStyle,
      }}
      data-testid={testID}
    >
      <main className={css.main}>
        {icon && (
          <div role={'prefix-icon'} data-testid={`${testID}.icon`} className={css.prefixIcon}>
            <PrefixIcon fill={iconColor} width={iconWidth} height={iconHeight} />
          </div>
        )}
        <section className={css.section}>
          <h4 className={css.h4} style={titleStyle} data-testid={`${testID}.title`}>
            {title}
          </h4>
          <h5
            data-testid={`${testID}.placeholderWrapper`}
            className={css.h5}
            style={{ ...pickerTextStyle, color: pickerTextColour }}
          >
            {selectedIndex > -1 && items[selectedIndex].label
              ? items[selectedIndex].label
              : (placeholderText && (
                  <div
                    data-testid={`${testID}.placeholder`}
                    className={[css.placeholder, placeholderEllipsis ? css.withEllipsis : ''].join(' ')}
                  >
                    {placeholderText}
                  </div>
                )) || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
          </h5>
        </section>
        {enablePicker && (
          <StaticSvgIcon name={'chevron'} isUnstyled={true} fill={theme.colours.overlayColor} direction={'right'} />
        )}
      </main>
      {enablePicker && (
        <select
          className={css.select}
          onChange={onChange}
          value={selectedValue}
          data-testid={`${testID}-select`}
          aria-label={accessibilityLabel}
        >
          {items.map((item, index) => (
            <option
              key={index}
              value={item.value}
              disabled={item.disabled ?? false}
              data-testid={`${testID}-option-${item.value}`}
            >
              {item.label}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

export { PickerButton }
