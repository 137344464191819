import React from 'react'
import { useVipOffers, VipOffersProps } from '.'

export const VipOffers = (props: VipOffersProps) => {
  const { VipOffersComponent, vipOffersComponentProps } = useVipOffers()

  if (!VipOffersComponent) {
    return null
  }

  return <VipOffersComponent {...props} {...vipOffersComponentProps} />
}
