import { Buffer } from 'buffer'

interface Store {
  latitude?: number
  longitude?: number
  number?: number
}

const encodeParameter = (data: string) =>
  encodeURIComponent(Buffer.from(encodeURIComponent(data), 'binary').toString('base64'))

const getGPSTrackerUrl = (
  baseUrl: string,
  address: Bff.Addresses.Address | undefined,
  store: Store,
  orderId: string,
  market: string | undefined,
  language: string,
  polling: boolean,
  gpsDriverTrackerProgressBarTracker: boolean,
): string => {
  const encodedAddress = encodeParameter(JSON.stringify(address, null, 0))
  const encodedStore = encodeParameter(JSON.stringify(store, null, 0))

  return `${baseUrl}?deliveryAddress=${encodedAddress}&store=${encodedStore}&orderId=${orderId}&market=${market}&lang=${language}&polling=${polling}&progressBarTracking=${gpsDriverTrackerProgressBarTracker}`
}

export { getGPSTrackerUrl }
