import React, { useEffect, useState } from 'react'
import styles from './number-feedback.less'

const makeRange = (min: number, max: number): number[] => {
  if (min >= max) {
    return []
  }

  const array = []
  for (let i = min; i <= max; i++) {
    array.push(i)
  }

  return array
}

export const NumberFeedback = ({
  question,
  onClick,
  error,
}: {
  question: Bff.Feedback.FeedbackQuestion
  onClick: (val: Bff.Feedback.FeedbackQuestionResponse) => void
  error?: string
}) => {
  const [selectedTick, setSelectedTick] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (selectedTick !== undefined) {
      onClick({
        externalQuestionId: question.externalQuestionId ?? '',
        rating: selectedTick,
        questionId: question.questionId,
      })
    }
  }, [selectedTick])

  const scaleTicks = () => {
    const { ratingMin, ratingMax } = question.displaySettings
    const range = makeRange(Number(ratingMin), Number(ratingMax))

    return range.map((value, i) => (
      <li
        role='radio'
        onClick={() => setSelectedTick(i)}
        tabIndex={-1}
        className={[
          styles.tick,
          // @ts-ignore
          selectedTick === undefined || selectedTick === i ? styles[`tick-${i}`] : styles.inactive,
        ].join(' ')}
        data-testid={`tick-${i}`}
        key={`${question.questionId}-${i}`}
      >
        {i}
      </li>
    ))
  }

  return (
    <div className={styles.scale}>
      <p>{question.questionText}</p>
      <div className={styles.centre}>
        <ol role='radiogroup' className={styles.ticks}>
          {scaleTicks()}
        </ol>
        {error ? <p className={styles.validationError}>{error}</p> : null}
      </div>
    </div>
  )
}
