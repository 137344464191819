import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { AnalyticSender } from './analytics'
import { SecuritySender } from './security'

export const AddressNotFound = 'Address not found'
export const ASAPNotAvailable = 'ASAP not available'
export const ConsentNotGiven = 'Consent not given'
export const DeeplinkNotSupported = 'Deeplink not supported'
export const ErrorOccurred = 'Error occurred'
export const FeatureNotEnabled = 'Feature not enabled'
export const LastOrderNotFound = 'Last order not found'
export const NavigationPathNotSupported = 'Navigation path not supported'
export const StoreNotFound = 'Store not found'
export const StoreOnlineOrderingDisabled = 'Store online ordering disabled'
export const UserChangedOrderDetails = 'User changed order details'

export type SJMFailedReason =
  | typeof AddressNotFound
  | typeof ASAPNotAvailable
  | typeof ConsentNotGiven
  | typeof DeeplinkNotSupported
  | typeof ErrorOccurred
  | typeof FeatureNotEnabled
  | typeof LastOrderNotFound
  | typeof NavigationPathNotSupported
  | typeof StoreNotFound
  | typeof StoreOnlineOrderingDisabled
  | typeof UserChangedOrderDetails

export const reportStartJourneyOnMenu = (
  sendSecurity: SecuritySender,
  sendAnalytics: AnalyticSender,
  currentOrder?: CurrentOrderDetailsReducerProps,
) => {
  const reportStartJourneyOnMenuSuccessful = (serviceMethod: BffContext.ServiceMethods) => {
    sendSecurity('Flow.StartUserJourneyOnMenu.Followed', {
      'Order ID': currentOrder?.orderId,
      OrderDetails: JSON.stringify(currentOrder),
      ServiceMethod: serviceMethod,
    })
  }

  const reportStartJourneyOnMenuUnsuccessful = (reason: SJMFailedReason) => {
    sendSecurity('Flow.StartUserJourneyOnMenu.Ignored', {
      'Order ID': currentOrder?.orderId,
      OrderDetails: JSON.stringify(currentOrder),
      FailedReason: reason,
    })
  }

  const reportStartJourneyOnMenuOrderDetailsChanged = () => {
    sendSecurity('Flow.StartUserJourneyOnMenu.OrderDetailsChanged', {
      'Order ID': currentOrder?.orderId,
      OrderDetails: JSON.stringify(currentOrder),
      ServiceMethod: currentOrder?.basketHeaderData?.serviceMethod,
    })
  }

  const reportReturningCustomerOrder = (action: string) => {
    sendAnalytics('Returning Customer Order Details', {
      Action: action,
    })
  }

  return {
    reportStartJourneyOnMenuSuccessful,
    reportStartJourneyOnMenuUnsuccessful,
    reportStartJourneyOnMenuOrderDetailsChanged,
    reportReturningCustomerOrder,
  }
}
