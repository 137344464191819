import BackButton from '@dominos/components/product/product-card-closer/back-button'
import CloseButton from '@dominos/components/product/product-card-closer/close-button'
import React from 'react'
import { ProductContainer } from '@dominos/components'

export const ProductEditor = ({
  testID,
  children,
  showBackButton = false,
  showCloseButton = false,
  onDismiss,
}: ProductEditorProps) => {
  const handleDismiss = () => onDismiss(false)

  return (
    <ProductContainer testID={testID}>
      {showBackButton && <BackButton testID={testID} onClick={handleDismiss} />}
      {showCloseButton && <CloseButton testID={testID} onClick={handleDismiss} />}
      {children}
    </ProductContainer>
  )
}
