import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge8 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-8' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M16.3655,20.442 L15.5285,19.636 C15.0015,19.14 14.6295,18.365 14.6295,17.559 L14.6295,12.475 C14.6295,11.204 15.6835,10.15 16.9545,10.15 L25.0455,10.15 C26.3165,10.15 27.3705,11.204 27.3705,12.475 L27.3705,17.559 C27.3705,18.365 26.9985,19.109 26.4405,19.636 L25.6035,20.442 C25.5415,20.504 25.5415,20.597 25.6035,20.628 L26.7505,21.651 C27.5255,22.333 27.9905,23.015 27.9905,24.038 L27.9905,29.525 C27.9905,30.796 26.9365,31.85 25.6655,31.85 L16.3345,31.85 C15.0635,31.85 14.0095,30.796 14.0095,29.525 L14.0095,24.007 C14.0095,23.015 14.4745,22.302 15.2185,21.651 L16.3655,20.628 C16.4275,20.597 16.4275,20.504 16.3655,20.442 Z M23.3095,22.829 L18.6595,22.829 C18.5665,22.829 18.5045,22.891 18.5045,22.984 L18.5045,27.541 C18.5045,27.634 18.5665,27.696 18.6595,27.696 L23.3095,27.696 C23.4025,27.696 23.4645,27.634 23.4645,27.541 L23.4645,22.984 C23.4645,22.891 23.4025,22.829 23.3095,22.829 Z M22.7205,14.304 L19.2795,14.304 C19.1865,14.304 19.1245,14.366 19.1245,14.459 L19.1245,18.365 C19.1245,18.458 19.1865,18.52 19.2795,18.52 L22.7205,18.52 C22.8135,18.52 22.8755,18.458 22.8755,18.365 L22.8755,14.459 C22.8755,14.366 22.8135,14.304 22.7205,14.304 Z'
          id='8'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
