import { useState } from 'react'

export const useMenuSelectPopup = () => {
  const [isShowPopup, setShowPopup] = useState<boolean>(false)

  const closePopup = () => {
    setShowPopup(false)
  }
  const openPopup = () => {
    setShowPopup(true)
  }

  return { isShowPopup, closePopup, openPopup }
}
