import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const strokeWidth = 2.3

const defaultDimensions = {
  width: '132px',
  height: '133px',
}

const PortionEmptyTopLeft = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg data-testid='portion-empty-top-left' width={width} height={height} viewBox='0 0 94 95' {...props}>
      <title>{'Portion Empty Top Left'}</title>
      <g fill='none' fillRule='evenodd' strokeWidth={strokeWidth} transform='rotate(-360.000000)'>
        <path
          stroke='#BFBFBF'
          d='M52.372 2.524c10.624 1.36 19.691 5.812 27.2 13.363 7.517 7.56 12.044 16.718 13.59 27.472h-40.79Z'
        />
        <path
          fill='#FFF'
          stroke='#FFF'
          d='M42.654 2.524c-10.624 1.36-19.692 5.812-27.2 13.363-7.518 7.56-12.045 16.718-13.592 27.472h40.792Z'
        />
        <path
          stroke='#BFBFBF'
          d='M52.372 93.896c10.624-1.36 19.691-5.812 27.2-13.362 7.517-7.56 12.044-16.719 13.59-27.473h-40.79ZM42.654 93.896c-10.624-1.36-19.692-5.812-27.2-13.362C7.936 72.974 3.409 63.815 1.862 53.06h40.792Z'
        />
      </g>
    </svg>
  )
}

export { PortionEmptyTopLeft }
