import React from 'react'

const SavedIcon = () => (
  <svg height='31' viewBox='0 0 31 31' width='31' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd' transform='translate(.5)'>
      <ellipse cx='15' cy='14.634146' fill='#007aff' rx='15' ry='14.634146' />
      <path
        d='m15 18.7804878-4.9961746 2.56258.9541844-5.4276315-4.04199019-3.8438699 5.58589309-.7918808 2.4980873-4.93822219 2.4980873 4.93822219 5.5858931.7918808-4.0419902 3.8438699.9541844 5.4276315z'
        fill='#fff'
      />
    </g>
  </svg>
)

export { SavedIcon }
