import { ApolloError } from '@apollo/client'
import { ErrorHandler } from '@apollo/client/link/error'
import { AtLeastOneErrorHandlers, ErrorScope, NotifiableEvent } from '../../../error'
import { paymentErrors, processingStatusErrors } from '../payment-container'
import OrderModel from '@dominos/hooks-and-hocs/order/model/order-model'

type NotifyErrorData = {
  handlers: AtLeastOneErrorHandlers
  scope: ErrorScope.Processing
}

export const getNotifiableEventData = (
  response: {
    orderModel?: OrderModel
    error?: ApolloError
  },
  handlers: Partial<ErrorHandler>,
): NotifiableEvent => {
  const notifyErrorData: NotifyErrorData = {
    handlers,
    scope: ErrorScope.Processing,
  }
  if (response.orderModel) {
    return {
      orderStatus: response.orderModel.status,
      definitions: processingStatusErrors(response.orderModel.status),
      ...notifyErrorData,
    }
  }

  return {
    error: response.error!,
    definitions: paymentErrors,
    ...notifyErrorData,
  }
}
