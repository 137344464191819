import { useLazyQuery } from '@apollo/client'
import { useMemo, useState } from 'react'

import { socialLoginQuery } from '@dominos/business/queries'
import { useSetLoggedInUser } from '@dominos/hooks-and-hocs'
import { SocialUserDetails } from 'packages/components/auth-button-group/auth-button-group.interface'

export const useSocialAuth = (
  onSuccessCallBack?: (
    provider: BffContext.SocialLogins.Providers,
    authCustomerInfo: Bff.SocialLogins.SocialLogin,
    userDetails: SocialUserDetails,
  ) => void,
) => {
  const [sendRequest, { data, error, loading }] = useLazyQuery<
    { socialLogin: Bff.SocialLogins.SocialLogin },
    Bff.SocialLogins.ProviderInfoInput
  >(socialLoginQuery, { fetchPolicy: 'no-cache', notifyOnNetworkStatusChange: true })
  const { setLoggedInUser } = useSetLoggedInUser()
  const [provider, setProvider] = useState<BffContext.SocialLogins.Providers>()
  const authCustomerInfo = useMemo(() => data?.socialLogin, [data?.socialLogin])

  const signIn = async (payload: Bff.SocialLogins.ProviderInfoInput, userDetails?: SocialUserDetails) => {
    setProvider(payload.provider)
    const { data: authResp } = await sendRequest({ variables: payload })

    if (!authResp || !authResp.socialLogin) {
      return
    }
    const { idToken, customerId } = authResp.socialLogin

    onSuccessCallBack?.(payload.provider, authResp.socialLogin, userDetails ?? {})

    if (customerId) {
      await setLoggedInUser(idToken, customerId, false, payload.provider)
    }

    return Promise.resolve(authResp.socialLogin)
  }

  return {
    signIn,
    provider,
    authCustomerInfo,
    pending: loading,
    error,
  }
}
