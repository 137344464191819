import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 45,
  height: 73,
}

export const Gauge6 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 45 73'>
      <defs>
        <path
          d='M35.6925527,71.5 L9.6925527,71.5 C5.5925527,71.5 2.1925527,68.1 2.1925527,64 L2.1925527,9 C2.1925527,4.9 5.5925527,1.5 9.6925527,1.5 L36.1925527,1.5 C40.0925527,1.5 43.1925527,4.6 43.1925527,8.5 L43.1925527,14.1 C43.1925527,15.6 42.2925527,17 40.9925527,17.6 L30.0925527,22.7 C28.9925527,23.1 27.7925527,22.4 27.7925527,21.2 L27.7925527,15.9 C27.7925527,15.6 27.5925527,15.4 27.2925527,15.4 L18.1925527,15.4 C17.8925527,15.4 17.6925527,15.6 17.6925527,15.9 L17.6925527,27.2 C17.6925527,27.5 17.8925527,27.7 18.1925527,27.7 L35.6925527,27.7 C39.7925527,27.7 43.1925527,31.1 43.1925527,35.2 L43.1925527,64 C43.1925527,68.1 39.7925527,71.5 35.6925527,71.5 Z M28.1925527,41.6 L18.1925527,41.6 C17.8925527,41.6 17.6925527,41.8 17.6925527,42.1 L17.6925527,57.1 C17.6925527,57.4 17.8925527,57.6 18.1925527,57.6 L28.1925527,57.6 C28.4925527,57.6 28.6925527,57.4 28.6925527,57.1 L28.6925527,42.1 C28.6925527,41.8 28.4925527,41.6 28.1925527,41.6 Z'
          id='gauge-path-6'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='6' fillRule='nonzero'>
          <use fill={fill} href='#gauge-path-6' />
          <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-6' />
        </g>
      </g>
    </svg>
  )
}
