import React from 'react'

import { SearchBarProps, StandardInputField } from '@dominos/components'

const TAB_INDEX = 1

export const SearchBar = (props: SearchBarProps) => {
  const styles = getStyles()

  const changeInput = (value: string) => {
    if (props.onChangeText) {
      props.onChangeText(value)
    }
  }

  return (
    <div style={styles.inputContainer} data-testid={`${props.testID}.container`}>
      <StandardInputField
        icon={'magnifyingGlassIcon'}
        aria-label={'search-bar'}
        value={props.value}
        inputStyle={styles.input}
        onChange={changeInput}
        onSubmitEditing={changeInput}
        placeholder={props.placeholder}
        testID={props.testID}
        tabIndex={TAB_INDEX}
        type='text'
        fill
      />
    </div>
  )
}

const getStyles = () => ({
  inputContainer: {
    marginTop: 8,
    backgroundColor: 'rgb(223, 223, 223)',
    borderRadius: '1rem',
  },
  input: {
    border: 0,
    fontSize: '1rem',
    letterSpacing: 0.12,
  },
})
