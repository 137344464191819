import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const fifteen = 15

const defaultDimensions = {
  width: 30,
  height: 30,
}

const NewAddressIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#1FC36A'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`}>
      <g fill='none' fillRule='evenodd'>
        <circle cx={fifteen} cy={fifteen} r={fifteen} fill={fill} />
        <path fill='#FFF' d='M16.763 16.701v4.773h-3.526v-4.773H8.756v-3.425h4.48v-4.75h3.527v4.75h4.481v3.425z' />
      </g>
    </svg>
  )
}

export { NewAddressIcon }
