import { isNativeApp } from '@dominos/business/functions/native-app/is-native-app'
import { notifyNativeApp, postWebViewMessage } from '@dominos/business/functions/native-app/notify-native-app'
import { useNavigate } from '@reach/router'

export const useApplyCoupon = () => {
  const navigate = useNavigate()

  return (coupon: string) => {
    if (isNativeApp()) {
      // Both of these are needed for backwards compatibility
      // This will be used in the newer apps:
      postWebViewMessage({ type: 'coupon', coupon })
      // This will be ignored by the newer apps and used by older ones:
      notifyNativeApp('redeem-loyalty-reward')
    } else {
      navigate(`/?vc=${coupon}`)
    }
  }
}
