import { TFunction } from 'i18next'

export const useOrderLaterDescriptions = (serviceMethod: BffContext.ServiceMethods | undefined, t: TFunction) => {
  const serviceMethodTitle = serviceMethod === 'Pickup' ? t('Pick Up') : t('Deliver')

  const orderDatePickerTitle = t('Order Date')
  const orderDatePlaceHolder = t('ChooseDate')
  const orderTimePickerTitle = t('Order Time')
  const orderTimePlaceHolder = t('ChooseTime')
  const startOrderButtonText = t('Start Order')
  const primaryTitle = serviceMethod
    ? t('(method) Later', {
        method: serviceMethodTitle,
        interpolation: { escapeValue: false },
      })
    : t('Later Time')

  return {
    primaryTitle,
    orderDatePickerTitle,
    orderDatePlaceHolder,
    orderTimePickerTitle,
    startOrderButtonText,
    orderTimePlaceHolder,
  }
}
