import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const SaladAndSoupIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <defs>
        <polygon id='path-1' points='0 -1.77635684e-15 20 -1.77635684e-15 20 8.9999 0 8.9999' />
      </defs>
      <g id='Salads-and-Soups' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-16' transform='translate(1.000000, 3.000000)'>
          <g id='Group-3' transform='translate(11.000000, 18.000100)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-2' />
            <path
              d='M0,0.956 C0,5.392 3.609,9 8.044,9 L11.956,9 C16.391,9 20,5.392 20,0.956 L20,-1.77635684e-15 L0,-1.77635684e-15 L0,0.956 Z'
              id='Fill-1'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <path
            d='M3,8 C3.552,8 4,7.552 4,7 C4,5.803 4.367,5.252 4.832,4.555 C5.352,3.774 6,2.803 6,1 C6,0.448 5.552,-3.55271368e-15 5,-3.55271368e-15 C4.448,-3.55271368e-15 4,0.448 4,1 C4,2.197 3.633,2.748 3.168,3.445 C2.648,4.226 2,5.197 2,7 C2,7.552 2.448,8 3,8'
            id='Fill-4'
            fill={fill}
          />
          <path
            d='M8,8 C8.552,8 9,7.552 9,7 C9,5.803 9.367,5.252 9.832,4.555 C10.352,3.774 11,2.803 11,1 C11,0.448 10.552,-3.55271368e-15 10,-3.55271368e-15 C9.448,-3.55271368e-15 9,0.448 9,1 C9,2.197 8.633,2.748 8.168,3.445 C7.648,4.226 7,5.197 7,7 C7,7.552 7.448,8 8,8'
            id='Fill-6'
            fill={fill}
          />
          <path
            d='M13,8 C13.552,8 14,7.552 14,7 C14,5.803 14.367,5.252 14.832,4.555 C15.352,3.774 16,2.803 16,1 C16,0.448 15.552,-3.55271368e-15 15,-3.55271368e-15 C14.448,-3.55271368e-15 14,0.448 14,1 C14,2.197 13.633,2.748 13.168,3.445 C12.648,4.226 12,5.197 12,7 C12,7.552 12.448,8 13,8'
            id='Fill-8'
            fill={fill}
          />
          <path
            d='M9,15 C9,14.448 8.552,14 8,14 L2,14 L2,12 L15,12 C15.552,12 16,11.552 16,11 C16,10.448 15.552,10 15,10 L1,10 C0.448,10 0,10.448 0,11 L0,14 C0,18.411 3.589,22 8,22 C8.552,22 9,21.552 9,21 C9,20.448 8.552,20 8,20 C5.394,20 3.176,18.327 2.35,16 L8,16 C8.552,16 9,15.552 9,15'
            id='Fill-10'
            fill={fill}
          />
          <path
            d='M20.8552,12 L22.2002,12 C26.7532,12 30.4992,8.542 30.9532,4.109 C31.0032,3.625 30.6302,3.2 30.1452,3.2 L28.8002,3.2 C24.2472,3.2 20.5012,6.658 20.0462,11.091 C19.9972,11.574 20.3692,12 20.8552,12'
            id='Fill-12'
            fill={fill}
          />
          <path
            d='M30,14 L12,14 C11.448,14 11,14.448 11,15 C11,15.552 11.448,16 12,16 L30,16 C30.552,16 31,15.552 31,15 C31,14.448 30.552,14 30,14'
            id='Fill-14'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}
