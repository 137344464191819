import { OrderStatus } from '@dominos/hooks-and-hocs/order'
import { svgIconsName } from '@dominos/res'

interface TrackerOrderStep {
  id: OrderStatus
  icon: svgIconsName
  displayTextTranslationKey: string
}

export const pickupOrderFlow: TrackerOrderStep[] = [
  {
    id: OrderStatus.SentToStore,
    icon: 'trackerReceipt' as svgIconsName,
    displayTextTranslationKey: 'Order Status Sent To Store',
  },
  {
    id: OrderStatus.Making,
    icon: 'trackerChef' as svgIconsName,
    displayTextTranslationKey: 'Order Status Making',
  },
  {
    id: OrderStatus.Cooking,
    icon: 'trackerCooking' as svgIconsName,
    displayTextTranslationKey: 'Order Status Cooking',
  },
  {
    id: OrderStatus.Ready,
    icon: 'trackerStore' as svgIconsName,
    displayTextTranslationKey: 'Order Status Ready',
  },
  {
    id: OrderStatus.Complete,
    icon: 'trackerStar' as svgIconsName,
    displayTextTranslationKey: 'Order Status Complete',
  },
]

export const deliveryOrderFlow: TrackerOrderStep[] = [
  ...pickupOrderFlow.slice(
    0,
    pickupOrderFlow.findIndex((i) => i.id === OrderStatus.Complete),
  ),
  {
    id: OrderStatus.Leaving,
    icon: 'trackerScooter' as svgIconsName,
    displayTextTranslationKey: 'Order Status Leaving',
  },
  {
    id: OrderStatus.Complete,
    icon: 'trackerStar' as svgIconsName,
    displayTextTranslationKey: 'Order Status Complete',
  },
]
