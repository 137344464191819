import { useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { feedbackQuestionsQuery, submitFeedbackMutation } from '@dominos/business/queries'
import { questionWasAnswered } from '@dominos/components/order-feedback/utils'

type UseFeedbackProps = {
  orderId: string
  serviceMethod?: BffContext.ServiceMethods
  feedbackTypes: string[]
}

export const useFeedback = ({ serviceMethod, orderId, feedbackTypes }: UseFeedbackProps) => {
  const [customerFeedback, setCustomerFeedback] = useState<Record<string, Bff.Feedback.FeedbackQuestionResponse>>({})
  const [validateQuestions, setValidateQuestions] = useState(false)
  const {
    data,
    loading: questionsLoading,
    error: fetchQuestionsError,
  } = useQuery<{
    feedbackQuestions: Bff.Feedback.FeedbackQuestion[]
  }>(feedbackQuestionsQuery, {
    variables: {
      serviceMethod,
      feedbackTypes,
    },
    fetchPolicy: 'no-cache',
  })

  const [sendFeedback, { loading: submitLoading, error: submitError, data: submitResponse }] = useMutation<{
    submitFeedback: boolean
  }>(submitFeedbackMutation, { errorPolicy: 'all', onError: () => {} })

  const feedbackQuestions = useMemo(
    () => data?.feedbackQuestions?.sort((q1, q2) => q1.sortSequence - q2.sortSequence),
    [data],
  )

  const isValid = useMemo(
    () => feedbackQuestions?.every((question) => questionWasAnswered(question, customerFeedback[question.questionId])),
    [feedbackQuestions, customerFeedback],
  )

  const errors = useMemo(
    () => [fetchQuestionsError, submitError].filter((err) => !!err),
    [fetchQuestionsError, submitError],
  )

  const selectFeedback = (val: Bff.Feedback.FeedbackQuestionResponse) => {
    setCustomerFeedback((existingFeedback) => ({
      ...existingFeedback,
      [val.questionId]: val,
    }))
  }

  const submitFeedback = () => {
    setValidateQuestions(true)

    if (isValid) {
      const questionSetId = feedbackQuestions?.find((fq) => fq.questionSetId)?.questionSetId ?? ''
      const mutationVars: Bff.Feedback.FeedbackResponse = {
        questionSetId,
        orderId,
        feedbackType: feedbackTypes[0],
        feedback: Object.values(customerFeedback),
      }

      return sendFeedback({
        variables: { input: mutationVars },
      }).then((data) => !!data.data?.submitFeedback)
    }

    return Promise.resolve(false)
  }

  return {
    response: submitResponse,
    questions: feedbackQuestions || [],
    isValid: !!isValid,
    loading: submitLoading || questionsLoading,
    errors,
    feedback: customerFeedback,
    shouldValidate: validateQuestions,
    selectFeedback,
    submitFeedback,
  }
}
