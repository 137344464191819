import { TFunction } from 'react-i18next'
export interface PortionProductCardBannerProps {
  testID: string
  viewModel: PortionProductCardBannerViewModel
}

export interface PortionProductCardBannerViewModelProps {
  t: TFunction<'menu'>
  isKioskOrder?: boolean
}

export interface PortionProductCardBannerViewModel {
  bannerText: string
  isKioskOrder?: boolean
}

export const createPortionProductCardBannerViewModel = ({
  t,
  isKioskOrder,
}: PortionProductCardBannerViewModelProps): PortionProductCardBannerViewModel => {
  const bannerText = t('PortionProductCard.Banner', '')

  return {
    bannerText,
    isKioskOrder,
  }
}
