import { isProductMenuItem } from '@dominos/components'

type IngredientType = Bff.Common.IngredientTypeCode

const flattenIngredientMedia = (menuItems: MenuItemDependentsDictionary | null) => {
  const ingredientMediaDictionary: IngredientMediaDictionary = {}

  if (menuItems) {
    Object.entries(menuItems).forEach(([, value]) => {
      const filteredProduct = value && isProductMenuItem(value) ? value : null
      if (filteredProduct) {
        extractIngredientMediaFromProduct(filteredProduct, ingredientMediaDictionary)
      }
    })
  }

  return ingredientMediaDictionary
}

const extractIngredientMediaFromProduct = (product: ProductMenuItem, mediaDictionary: IngredientMediaDictionary) => {
  product.sizes?.forEach((size) => {
    size.swaps?.sauces?.ingredients?.forEach((ingredient) => addMediaToDictionary(ingredient, mediaDictionary, 'Sauce'))
    size.swaps?.toppings?.ingredients?.forEach((ingredient) =>
      addMediaToDictionary(ingredient, mediaDictionary, 'Topping'),
    )
    size.recipe?.toppings
      ?.map((topping) => topping.ingredient)
      .forEach((ingredient) => addMediaToDictionary(ingredient, mediaDictionary, 'Topping'))
    if (size.recipe?.sauce) addMediaToDictionary(size.recipe.sauce, mediaDictionary, 'Sauce')
  })
}

const addMediaToDictionary = (
  ingredient: Ingredient,
  mediaDictionary: IngredientMediaDictionary,
  prefix: IngredientType,
) => {
  if (ingredient.code) {
    const baseKey = `${prefix}-${ingredient.code}`
    const media: IngredientMedia = {
      name: { value: ingredient.media?.name ?? '' },
      image: { uri: ingredient.media?.image ?? '', altText: '' },
      icon: { uri: ingredient.media?.icon ?? '', altText: '' },
      colour: { value: ingredient.media?.colour ?? '' },
    }
    mediaDictionary[baseKey] = media
  }
}

export { flattenIngredientMedia }
