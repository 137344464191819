import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 14,
  height: 14,
}

const MagnifyingGlassIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#007AFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='7.5 7.5 15 15'>
      <defs>
        <filter id='a' width='112.8%' height='107.9%' x='-6.4%' y='-4%' filterUnits='objectBoundingBox'>
          <feOffset dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='5' />
          <feColorMatrix
            in='shadowBlurOuter1'
            result='shadowMatrixOuter1'
            values='0 0 0 0 0.537335824 0 0 0 0 0.537335824 0 0 0 0 0.537335824 0 0 0 0.257675918 0'
          />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g fill='none' fillRule='evenodd' filter='url(#a)' transform='translate(1)'>
        <path
          fill={fill}
          d='M17.313 16.74a5.552 5.552 0 0 0 1.015-3.232c-.013-3.034-2.384-5.49-5.316-5.508A5.167 5.167 0 0 0 9.27 9.595a5.534 5.534 0 0 0-1.537 3.873c.013 3.033 2.385 5.49 5.317 5.508a5.113 5.113 0 0 0 3.134-1.061l.003-.003 3.702 3.831c.2.217.499.305.78.23.28-.076.5-.303.572-.593a.845.845 0 0 0-.223-.806l-3.705-3.834zm-4.268.748c-2.137-.013-3.866-1.804-3.875-4.015a4.035 4.035 0 0 1 1.12-2.822 3.767 3.767 0 0 1 2.727-1.163c2.137.012 3.866 1.803 3.875 4.014a4.035 4.035 0 0 1-1.12 2.823 3.767 3.767 0 0 1-2.727 1.163z'
        />
      </g>
    </svg>
  )
}

export { MagnifyingGlassIcon }
