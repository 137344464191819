import React from 'react'

import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 25,
  height: 25,
}

const Cross = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 25 25' fillOpacity='0.899999976'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M18.3786797,7.43933983 C18.9644661,8.02512627 18.9644661,8.97487373 18.3786797,9.56066017 L15.03,12.909 L18.3786797,16.2573593 C18.9644661,16.8431458 18.9644661,17.7928932 18.3786797,18.3786797 C17.7928932,18.9644661 16.8431458,18.9644661 16.2573593,18.3786797 L12.909,15.03 L9.56066017,18.3786797 C8.97487373,18.9644661 8.02512627,18.9644661 7.43933983,18.3786797 C6.85355339,17.7928932 6.85355339,16.8431458 7.43933983,16.2573593 L10.788,12.909 L7.43933983,9.56066017 C6.85355339,8.97487373 6.85355339,8.02512627 7.43933983,7.43933983 C8.02512627,6.85355339 8.97487373,6.85355339 9.56066017,7.43933983 L12.909,10.788 L16.2573593,7.43933983 C16.8431458,6.85355339 17.7928932,6.85355339 18.3786797,7.43933983 Z'
          fill={fill}
        />
      </g>
    </svg>
  )
}

export { Cross }
