import { AppleIcon } from '@dominos/res/images/icons/components/apple-icon'
import React from 'react'

import { useLoginTranslations } from '../use-login-translations'
import { AuthButton } from '@dominos/components'

interface AppleNativeProps {
  loading: boolean
  onSignIn: () => void
  testID: string
}

export const AppleNative = ({ loading, onSignIn, testID }: AppleNativeProps) => {
  const t = useLoginTranslations()
  const [title] = t.getSocials('Apple')

  return (
    <AuthButton
      testID={testID}
      backgroundColor={'#000000'}
      label={title}
      loading={loading}
      onPress={onSignIn}
      isNative={true}
    >
      <AppleIcon data-testid={`${testID}.icon`} fill={'#ffffff'} />
    </AuthButton>
  )
}
