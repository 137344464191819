import { useState } from 'react'
import { ApolloError } from '@apollo/client'
import {
  AddressSearchQueriesProps,
  AddressSearchQueriesResponse,
} from '@dominos/hooks-and-hocs/address/functions/use-address-search-queries'
import { GeoLocationInterface } from '../../../interfaces/services/geo'

const NEARBY_LIMIT = 3

interface StoresPreviewResult {
  nearByStores: AddressLine[] | undefined
  error: ApolloError | undefined
  reset: () => void
  resetNearByStores: () => void
  requestNearByStores: (geoLocation: GeoLocationInterface) => void
}

export interface StoresPreviewHookProps {
  useAddressSearchQueriesHook(props: AddressSearchQueriesProps): AddressSearchQueriesResponse
}

export const useStoresPreview = (props: StoresPreviewHookProps) => {
  const [nearByStores, setNearByStores] = useState<AddressLine[] | undefined>(undefined)
  const [error, setError] = useState<ApolloError | undefined>()

  const reset = () => {
    setNearByStores(undefined)
    setError(undefined)
  }

  const resetNearByStores = () => {
    setNearByStores(undefined)
  }

  const handleNearbySearchComplete = (data: AddressLine[] | undefined) => {
    setNearByStores(data)
  }

  const addressSearchQueries = props.useAddressSearchQueriesHook({
    onPickupSearchStoreSuccess: handleNearbySearchComplete,
    onPickupSearchStoreError: setError,
    storeSearchLimit: NEARBY_LIMIT,
  })

  const requestNearByStores = (geoLocation: GeoLocationInterface) => {
    addressSearchQueries.pickupQuery({
      variables: { latitude: geoLocation.latitude, longitude: geoLocation.longitude },
    })
  }

  return {
    nearByStores,
    error,
    reset,
    resetNearByStores,
    requestNearByStores,
  } as StoresPreviewResult
}
