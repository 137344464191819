import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { PickerButton } from '../..'

const PaymentMethodDetails = ({ paymentMethods }: { paymentMethods?: Bff.Orders.OrderDetails.Payment[] }) => {
  const { t } = useTranslation('tracker')
  const theme = useDominosTheme()

  return (
    <PickerButton
      testID={'OrderDetails.paymentMethod'}
      title={t('PaymentMethod')}
      placeholderText={paymentMethods ? paymentMethods.map((method) => t(method.media.displayName)).join(', ') : ''}
      icon={'upgradeIcon'}
      iconWidth={26}
      iconHeight={26}
      iconColor={theme.colours.dominosSlate}
    />
  )
}

export { PaymentMethodDetails }
