import { isPortionMenuItem, isProductMenuItem } from '@dominos/components'

const flattenDimensionMedia = (menuItems: MenuItemDependentsDictionary | null) => {
  const dimensionMediaDictionary: DimensionMediaDictionary = {}

  if (menuItems) {
    Object.entries(menuItems).forEach(([, value]) => {
      const filteredProduct = value && (isProductMenuItem(value) || isPortionMenuItem(value)) ? value : null
      if (filteredProduct) {
        extractDimensionMediaFromProduct(filteredProduct, dimensionMediaDictionary)
      }
    })
  }

  return dimensionMediaDictionary
}

const extractDimensionMediaFromProduct = (
  product: ProductMenuItem | PortionMenuItem,
  mediaDictionary: DimensionMediaDictionary,
) => {
  product.sizes?.forEach((size) => {
    const sizeKey = `Size-${size.code}`
    if (size.code) {
      const media = {
        name: {
          value: size.media?.name ?? '',
        },
      }
      mediaDictionary[sizeKey] = media
    }
    size.swaps?.bases?.ingredients.forEach((ingredient) => {
      const baseKey = `Base-${ingredient.code}`
      if (ingredient.code) {
        const media = {
          name: { value: ingredient.media?.name ?? '' },
          iconImage: { uri: ingredient.media?.image ?? '', altText: '' },
        }
        mediaDictionary[baseKey] = media
      }
    })
  })
}

export { flattenDimensionMedia }
