import { getFormattedPrice } from '@dominos/business/functions/text/text-functions'
import { Spinner } from '@dominos/components'
import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './payment-card.less'

interface PaymentCardProps {
  testID: string
  label: string
  price: number | null
  icon: svgIconsName
  iconWidth?: number
  iconHeight?: number
  iconColor?: string
  containerStyle?: CommonViewStyle
  pricePrefix?: string
  onRemove?: (token: string, providerCode?: string, paymentMethod?: string) => void
  removing?: boolean
  tokenId?: string
  providerCode?: string
  paymentMethod?: string
  loading?: boolean
}

export const PaymentCard = (props: PaymentCardProps) => {
  const { t } = useTranslation('login')

  const handleRemove = () => {
    if (props.onRemove && props.tokenId) {
      props.onRemove(props.tokenId, props.providerCode, props.paymentMethod)
    }
  }

  return (
    <div data-testid={`${props.testID}.payment-card`} className={styles.container} style={props.containerStyle}>
      <div className={styles.icon}>
        <StaticSvgIcon
          name={props.icon}
          width={props.iconWidth}
          height={props.iconHeight}
          fill={props.iconColor}
          isUnstyled
        />
      </div>
      <div className={styles.content}>
        <div data-testid={`${props.testID}.payment-card.label`} className={styles.contentLabel}>
          {props.label}
        </div>
        <div className={styles.contentPrice}>
          {props.loading ? (
            <Spinner testID={`${props.testID}.loading-spinner`} color='rgb(0, 122, 175)' />
          ) : (
            <>
              {props.pricePrefix && (
                <span data-testid={`${props.testID}.payment-card.price-prefix`} className={styles.contentPricePrefix}>
                  {props.pricePrefix}
                </span>
              )}
              <span
                data-testid={`${props.testID}.payment-card.price`}
                className={props.price === 0 ? styles.zeroBalance : ''}
              >{`${getFormattedPrice(props.price!)}`}</span>
            </>
          )}
        </div>
      </div>
      {props.onRemove && (
        <button
          data-testid={`saved-payment-remove-${props.paymentMethod}`}
          onClick={handleRemove}
          className={styles.remove}
          disabled={props.removing}
        >
          {props.removing ? (
            <Spinner testID={`${props.testID}.removing-spinner`} color='rgb(0, 122, 175)' />
          ) : (
            t('RemoveButton', { defaultValue: 'Remove' })
          )}
        </button>
      )}
    </div>
  )
}
