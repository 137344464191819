import { convertStoreToAddressLine } from '@dominos/business/functions/address'
import { GeoLocationInterface } from '@dominos/interfaces'

export const convertStoresToAddressLines = (
  stores: Bff.Stores.Store[],
  icon: 'searchResultIcon' | 'recentIcon' | 'savedIcon' = 'searchResultIcon',
  location?: GeoLocationInterface,
  maxAddresses?: number,
): AddressLine[] =>
  (maxAddresses && maxAddresses > 0 ? stores.splice(0, maxAddresses) : stores).map(
    (store): AddressLine => convertStoreToAddressLine(icon, store, location),
  )

export const limitStores = (
  stores: Bff.Stores.Store[],
  limit?: number,
  location?: GeoLocationInterface,
  icon: 'searchResultIcon' | 'recentIcon' | 'savedIcon' = 'searchResultIcon',
) => {
  const result = convertStoresToAddressLines(stores, icon, location)

  if (limit && limit > 0) {
    return result.slice(0, limit)
  }

  return result
}
