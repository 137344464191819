import gql from 'graphql-tag'

export const validateBasketMutation = gql`
  mutation validateBasket($input: ValidateBasketInput!, $withSalePrice: Boolean = false) {
    validateBasket(input: $input) {
      basket {
        id
        advanceOrderId
        lines {
          ... on BasketLine {
            type
            itemNo
            productCode
            sizeCode
            media {
              name
              base
              size
            }
            totalPrice
            quantity
            base {
              add
              remove
              media {
                add
                remove
              }
            }
            sauce {
              add
              remove
              media {
                add
                remove
              }
            }
            toppings {
              action
              code
              quantity
              media {
                name
              }
            }
            options {
              action
              code
              quantity
              media {
                name
              }
            }
            surcharges {
              code
              name
              price
            }
            portions {
              productCode
              media {
                name
                base
                size
              }
              sauce {
                add
                remove
                media {
                  add
                  remove
                }
              }
              toppings {
                action
                code
                quantity
                media {
                  name
                }
              }
              options {
                action
                code
                quantity
                media {
                  name
                }
              }
              surcharges {
                code
                name
                price
              }
            }
          }
          ... on BasketCoupon {
            type
            couponNo
            code
            status
            discount
            displayFormat
            media {
              name
              description
            }
            isLoyaltyRedemption
            items {
              itemNo
              hasPrompt
              hasSwaps
              media {
                prompt
              }
              lines {
                type
                itemNo
                productCode
                sizeCode
                media {
                  name
                  base
                  size
                }
                totalPrice
                quantity
                base {
                  add
                  remove
                  media {
                    add
                    remove
                  }
                }
                sauce {
                  add
                  remove
                  media {
                    add
                    remove
                  }
                }
                toppings {
                  action
                  code
                  quantity
                  media {
                    name
                  }
                }
                options {
                  action
                  code
                  quantity
                  media {
                    name
                  }
                }
                surcharges {
                  code
                  name
                  price
                }
                portions {
                  productCode
                  media {
                    name
                    base
                    size
                  }
                  sauce {
                    add
                    remove
                    media {
                      add
                      remove
                    }
                  }
                  toppings {
                    action
                    code
                    quantity
                    media {
                      name
                    }
                  }
                  options {
                    action
                    code
                    quantity
                    media {
                      name
                    }
                  }
                  surcharges {
                    code
                    name
                    price
                  }
                }
              }
            }
          }
        }
        surcharges {
          code
          name
          price
        }
        total
        totalDiscount
        minimumPrice
        priceExcludingTaxes
        taxes {
          code
          name
          amount
        }
        adjustments {
          code
          name
          amount
        }
      }
      validationErrors {
        code
        message
      }
      showSalePrice @include(if: $withSalePrice)
    }
  }
`
