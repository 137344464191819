import {
  AddBasketLinesProps,
  BasketCoupon,
  CustomerCarDetails,
  RemoveBasketProps,
  ReplaceBasketLineProps,
} from '@dominos/interfaces'
import { createAsyncAction, createCustomAction, createStandardAction } from 'typesafe-actions'

export const validateBasket = createAsyncAction(
  'BASKET:validateBasket:REQUEST',
  'BASKET:validateBasket:SUCCESS',
  'BASKET:validateBasket:ERROR',
)<undefined, ValidBasketSubResponse, Error | string>()

export const removeLineFromBasket = createStandardAction('BASKET:remove-line')<RemoveBasketProps>()
export const addLinesToBasket = createStandardAction('BASKET:add-lines')<AddBasketLinesProps>()
export const addCoupons = createStandardAction('BASKET:add-coupons')<string[]>()
export const addLoyaltyCoupon = createStandardAction('BASKET:add-loyalty-coupon')<void>()
export const replaceBasketLine = createStandardAction('BASKET:replace-line')<ReplaceBasketLineProps>()
export const restartOrder = createStandardAction('BASKET:restart-order')()
export const selectServiceMethod = createStandardAction('BASKET:ServiceMethod')<BffContext.ServiceMethods>()
export const selectServiceMethodSubType = createStandardAction('BASKET:ServiceMethodSubType')<
  Bff.Stores.ServiceMethodSubTypes | undefined
>()
export const updateCarDetails = createStandardAction('BASKET:car-details')<CustomerCarDetails>()
export const selectOrderTime = createStandardAction('BASKET:order-time')<{
  orderTime: string | null
}>()
export const removeCoupon = createStandardAction('BASKET:remove-coupon')<BasketCoupon>()
export const storeDeepLinkCoupon = createStandardAction('BASKET:store-deep-link-coupon')<string>()
export const addDeepLinkCoupon = createStandardAction('BASKET:add-deep-link-coupon')()
export const basketFatal = createStandardAction('BASKET:FATAL')()
export const clearValidationErrors = createStandardAction('BASKET:clear-validation-errors')()
export const clearError = createStandardAction('BASKET:clear-error')()
export const replaceBasket = createStandardAction('BASKET:replace')<Basket>()
export const addHardUpsellOrderId = createCustomAction(
  'BASKET:add-hard-upsell-details',
  (type) => (orderId: string) => ({
    type,
    orderId,
  }),
)
