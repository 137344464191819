import {
  EmptyFooter,
  ErrorNotifier,
  KioskContainer,
  KioskNavigationTitle,
  StackNavigationBase,
} from '@dominos/components'
import React from 'react'

export const KioskWelcomeScene = () => (
  <StackNavigationBase
    modification='map-view'
    testID='kiosk-welcome-scene'
    header={<KioskNavigationTitle />}
    footer={<EmptyFooter />}
  >
    <ErrorNotifier namespace={'kiosk'}>
      <KioskContainer />
    </ErrorNotifier>
  </StackNavigationBase>
)
