import React from 'react'
import { RouteComponentProps } from '@reach/router'

import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'

import { ConfirmPickup } from './confirm-pickup'
import css from './confirm-pickup-scene.less'

export interface ConfirmPickupSceneLocation {
  location: {
    state: {
      address: DeliveryAddressRequest
    }
  }
}

export const ConfirmPickupScene = ({ location }: RouteComponentProps<ConfirmPickupSceneLocation>) => (
  <StackNavigationBase testID={'confirm-pickup-scene'} header={<SimpleNavigationTitle showAccount />}>
    <div className={css.wrapper}>
      <ConfirmPickup testID={'confirm-pickup'} address={location?.state.address} />
    </div>
  </StackNavigationBase>
)
