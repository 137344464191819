import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 40,
  height: 40,
}

const StoreDetailsPickUp = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 40 40'>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='store-details-icon'>
          <circle id='Oval' fill='#CF343D' cx='20' cy='20' r='20'></circle>
          <g id='Store' transform='translate(8.857758, 5.891953)' fill='#FFFFFF'>
            <path
              d='M10.7956142,23.427668 L3.59853807,23.427668 L3.59853807,18.6774337 L10.7956142,18.6774337 L10.7956142,23.427668 Z M21.5912284,18.6774337 L21.5912284,16.3023166 L20.3917157,10.3645238 L12.1237832,10.3645238 C11.6313634,10.906355 11.2118205,11.3376765 10.8651544,11.6584885 C10.5421909,12.0076085 10.2227209,12.1821685 9.90674416,12.1821685 C9.59076754,12.1821685 9.2832972,12.0076085 8.98433323,11.6584885 C8.72438352,11.4593391 8.29831701,11.0280175 7.70613375,10.3645238 L1.19951269,10.3645238 L9.12180618e-14,16.3023166 L9.12180618e-14,18.6774337 L1.19951269,18.6774337 L1.19951269,25.8027851 L13.1946396,25.8027851 L13.1946396,18.6774337 L17.9926903,18.6774337 L17.9926903,25.8027851 L20.3917157,25.8027851 L20.3917157,18.6774337 L21.5912284,18.6774337 Z M6.15335761,6.80184807 L1.19951269,6.80184807 L1.19951269,9.1769652 L6.59775113,9.1769652 C6.10938374,8.65984666 5.79567057,8.26399381 5.65661163,7.98940663 C5.51755269,7.71481946 5.68313469,7.3189666 6.15335761,6.80184807 Z M20.3557146,6.80184807 L16.1179035,6.80184807 L13.4605233,9.1769652 L20.3557146,9.1769652 L20.3557146,6.80184807 Z'
              id='Store-Shell'
            ></path>
            <path
              d='M13.6874022,2.73723129 C13.3024946,3.11830291 13.3024946,3.73832155 13.6874022,4.11939318 C14.0723097,4.49868825 14.6985696,4.49868825 15.0825799,4.11939318 C15.4665902,3.73832155 15.4665902,3.11830291 15.0825799,2.73723129 C14.6985696,2.35704794 14.0723097,2.35704794 13.6874022,2.73723129 Z M10.7140138,3.32083049 L13.9260628,0.139903919 C14.1144791,-0.0466346398 14.4186368,-0.0466346398 14.6070531,0.139903919 L17.7078467,3.21068391 C17.896263,3.3963342 17.896263,3.69834901 17.7078467,3.88488756 L14.4957977,7.06581414 L10.7140138,3.32083049 Z'
              id='Tile-Top'
            ></path>
            <path
              d='M7.83363121,7.11951879 C7.44872367,7.49970214 7.44872367,8.12060906 7.83363121,8.50079241 C8.21764154,8.88008748 8.84479859,8.88008748 9.22880892,8.49990413 C9.61192202,8.11972078 9.61281924,7.49970214 9.22970614,7.11951879 C8.84479859,6.73844717 8.21764154,6.73844717 7.83363121,7.11951879 Z M10.5351618,7.16215618 C10.1520487,7.54233953 10.1520487,8.16146989 10.5351618,8.54431807 C10.9191721,8.92272487 11.5472264,8.92272487 11.9303395,8.54254152 C12.3152471,8.16146989 12.3152471,7.54233953 11.9303395,7.16215618 C11.5472264,6.78108455 10.9191721,6.78108455 10.5351618,7.16215618 Z M13.6996581,7.78483966 L10.2498457,11.2011601 C10.0623267,11.3868104 9.75727173,11.3868104 9.56885544,11.2011601 L6.46716456,8.13038013 C6.2796455,7.94472985 6.2796455,7.64182676 6.46716456,7.45617648 L9.91697696,4.03985601 L13.6996581,7.78483966 Z'
              id='Tile-Btm'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { StoreDetailsPickUp }
