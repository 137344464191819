export const validateProductBase = (
  crusts: Bff.Menu.old.Ingredient[],
  defaultCrust: string | undefined,
  selectedCrust: string | null | undefined,
): BasketLineSwap | undefined => {
  if (defaultCrust === selectedCrust) {
    return undefined
  }

  const selectedCrustIngredient = crusts.find((crust) => crust.code === selectedCrust)
  const defaultCrustIngredient = crusts.find((crust) => crust.code === defaultCrust)

  if (!defaultCrustIngredient || !selectedCrustIngredient) {
    return undefined
  }

  return {
    add: selectedCrustIngredient.code,
    media: {
      add: selectedCrustIngredient.media?.name ?? '',
      remove: defaultCrustIngredient?.media?.name ?? '',
    },
    remove: defaultCrustIngredient?.code ?? '',
  }
}
