import { ErrorDefinitions } from '@dominos/interfaces'

export const MySavedVouchersErrors: ErrorDefinitions = {
  UNABLE_TO_RESOLVE: {
    translation: {
      key: 'DownstreamFailure',
      options: {},
    },
    display: 'errorText',
  },
  INTERNAL_SERVER_ERROR: {
    translation: {
      key: 'CreateAccountGenericErrorText',
      options: {},
    },
    display: 'errorText',
  },
  NETWORK_ERROR: {
    translation: {
      key: 'NetworkErrorMessage',
      options: {},
    },
    display: 'errorText',
  },
}
