import { rootActions } from '@dominos/business'
import { useIsValidatingBasket, useNativeAppStoredData, useOrder } from '@dominos/hooks-and-hocs'
import { NativeAppBasketData, NativeAppStartupData } from '@dominos/interfaces'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export const getBasketId = (search: string): string | undefined => {
  const params = new URLSearchParams(search)

  return params.get('basketId') ?? undefined
}

declare global {
  interface Window {
    nativeAppStartupData?: NativeAppBasketData | NativeAppStartupData
  }
}

export const useNativeAppBasketData = (event: string) => {
  const nativeAppStartupData = window.nativeAppStartupData!
  const orderId = getBasketId(window.location.search)
  const [initialLoading, setInitialLoading] = useState<boolean>(false)

  const { isValidating, waitForValidation } = useIsValidatingBasket()
  const { fetchOrder, order, errors } = useOrder()
  const dispatch = useDispatch()
  const { retrieveNativeAppCustomerDetails } = useNativeAppStoredData()

  const { customerDetails } = retrieveNativeAppCustomerDetails()

  useEffect(() => {
    waitForValidation()
    setInitialLoading(true)
    document.body.parentElement?.classList.toggle('native-app', true)
  }, [])

  useEffect(() => {
    if (initialLoading && (!orderId || !isValidating || !!errors)) {
      setInitialLoading(false)
    }
  }, [orderId, isValidating, errors])

  useEffect(() => {
    if (orderId && !order) {
      const { language, analytics } = nativeAppStartupData
      const nativeAppData: NativeAppBasketData = {
        language,
        analytics,
        event,
        basketId: orderId,
      }
      dispatch(rootActions.checkoutFromNativeApp(nativeAppData))
      fetchOrder(orderId, 'cache-first')
    }
  }, [orderId])

  useEffect(() => {
    if (orderId && order) {
      dispatch(rootActions.saveOrder({ order, customer: customerDetails }))
    }
  }, [order])

  // Please make sure Edenred is tested on both
  // iOS and Android apps if any changes.
  return {
    initialLoading,
  }
}
