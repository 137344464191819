import React from 'react'
import { Checkbox, MDTargetBlankAnchor } from '@dominos/components'
import Markdown from 'markdown-to-jsx'

export interface VipOffersCheckboxProps {
  onChange: (name: string | null, checked: boolean) => void
  defaultChecked?: boolean
  name: string
  label: string
  labelClassName: string
  testID?: string
}

export const VipOffersCheckbox = ({
  onChange,
  defaultChecked,
  name,
  label,
  labelClassName,
  testID,
}: VipOffersCheckboxProps) => (
  <Checkbox testID={`${testID}.field`} onChange={onChange} defaultChecked={defaultChecked} name={name}>
    <Markdown
      className={labelClassName}
      options={{
        forceBlock: true,
        overrides: {
          a: MDTargetBlankAnchor,
        },
      }}
    >
      {label}
    </Markdown>
  </Checkbox>
)
