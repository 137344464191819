import { getType } from 'typesafe-actions'
import { rootActions } from '../root.actions'
import uuid from 'uuid'

export interface SessionState {
  sessionID: string
  loadedAt: string
}

const setInitState = () => ({
  sessionID: uuid(),
  loadedAt: new Date().toJSON(),
})

export const sessionReducer: import('redux').Reducer<SessionState> = (
  state: SessionState = setInitState(),
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.validateBasket.failure):
    case getType(rootActions.validateBasket.success):
      return {
        ...state,
        loadedAt: new Date().toJSON(),
      }
    default:
      return state
  }
}
