import React from 'react'
import styles from '../details-container.less'
import { useTranslation } from 'react-i18next'
import { getCountryConfig } from '@dominos/business/functions'

const DetailsContainerBanner = () => {
  const { t } = useTranslation('checkout')
  const { COUNTRY } = getCountryConfig()

  const images = {
    NZ: require('../../../../../../res/assets/pngs/ContexualEmail-NZ.png'),
    NL: require('../../../../../../res/assets/pngs/ContexualEmail-NL.png'),
  }

  return (
    <div className={styles.imageContainer} data-testid='details-container-banner'>
      <p className={styles.subTitledImage} data-testid='details-container-banner-image-subtitle'>
        {t('ContexualEmailImageLine1', { defaultValue: "DON'T MISS OUT ON MORE" }).toUpperCase()}
      </p>
      <br />
      <p className={styles.titledImage} data-testid='details-container-banner-image-title'>
        {t('ContexualEmailImageLine2', { defaultValue: 'GREAT DEALS' }).toUpperCase()}
      </p>

      <img
        className={styles.image}
        // @ts-ignore
        src={images[COUNTRY] || require('../../../../../../res/assets/pngs/ContexualEmail.png')}
        data-testid='details-container-banner-image'
      />
    </div>
  )
}

export { DetailsContainerBanner }
