import { ApolloError, LazyQueryResult, OperationVariables, QueryLazyOptions, useLazyQuery } from '@apollo/client'
import { pickupSearchStoreQuery } from '@dominos/business/queries/pickup-search-store'
import { transformPickupSearchResponse } from './transform-pickup-search-response'
import { useTranslation } from 'react-i18next'

interface PickupStoreResponse {
  pickupSearchStore: PickupStoreDetails[]
}

export interface AddressSearchQueriesProps {
  onPickupSearchStoreSuccess: (value: AddressLine[] | undefined) => void
  // TODO: use internal error interface instead of ApolloError, create this interface in shared lib
  onPickupSearchStoreError: (value: ApolloError | undefined) => void
  storeSearchLimit: number
}

export interface AddressSearchQueriesResponse {
  pickupQuery: (
    options?: QueryLazyOptions<OperationVariables> | undefined,
  ) => Promise<LazyQueryResult<PickupStoreResponse | undefined, OperationVariables>>
  pickupLoading: boolean
}

// TODO: move to shared lib, rename appropriately
export const useAddressSearchQueries = (props: AddressSearchQueriesProps): AddressSearchQueriesResponse => {
  const { t } = useTranslation('menu')

  const handlePickupQuerySuccess = (data: PickupStoreResponse | undefined) => {
    if (data?.pickupSearchStore) {
      const transformedData = transformPickupSearchResponse(t, data.pickupSearchStore, props.storeSearchLimit)
      props.onPickupSearchStoreSuccess(transformedData)
    }
  }

  const handlePickupQueryError = (exception: ApolloError) => {
    props.onPickupSearchStoreError(exception)
  }

  const [pickupQuery, { loading: pickupLoading }] = useLazyQuery<PickupStoreResponse | undefined>(
    pickupSearchStoreQuery,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      onCompleted: handlePickupQuerySuccess,
      onError: handlePickupQueryError,
    },
  )

  return {
    pickupQuery,
    pickupLoading,
  }
}
