import { NLFamilyXXL } from './nl-pizza-familyxxl'
import { NLItalian } from './nl-pizza-italian'
import { NLLarge } from './nl-pizza-large'
import { NLMedium } from './nl-pizza-medium'

export const NLSizeIcons: { [code: string]: React.ComponentType<SizeSVGProps> } = {
  'Pizza.25CM': NLMedium,
  'Pizza.30CM': NLItalian,
  'Pizza.35CM': NLLarge,
  'Pizza.40CM': NLFamilyXXL,
}

export type NLSizeCode = keyof typeof NLSizeIcons
