export const createStoreAlternativeStores = (
  storeAlternatives: Bff.Stores.StoreAlternativeStoresResponse,
  currentStore: Bff.Stores.Store | undefined,
): Bff.Stores.Store[] => {
  if (!currentStore) {
    return storeAlternatives.stores?.length ? [...storeAlternatives.stores] : []
  }

  const filteredAlternatives = storeAlternatives.stores?.filter((store) => store.storeNo !== currentStore.storeNo) || []

  return [currentStore, ...filteredAlternatives]
}
