import { GenericCard } from '@dominos/components'
import { AddressList } from '../address-list'
import * as React from 'react'
import { rootActions } from '@dominos/business'
import {
  convertStoredCustomerAddressToIAddress,
  useCountryCode,
  useDeliveryAddresses,
  useReport,
} from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import styles from './address-search-stored-list.less'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

export const AddressSearchStoredList = ({
  testID,
  location,
  type,
  onSelectCurrentLocation,
}: AddressSearchStoredListContainerProps) => {
  const dispatch = useDispatch()
  const countryCode = useCountryCode()
  const { reportDeliveryAddressSelected } = useReport()
  const { reportSelectedRecentLocation } = useReportAutoCompleteDelivery()
  const [locationAddressLine, setLocationAddressLine] = useState<AddressLine[] | undefined>()
  const [storedAddresses, setStoredAddresses] = useState<AddressLine[] | undefined>()

  const TOTAL_NUMBER_OF_ADDRESSES = 5
  const addresses = useDeliveryAddresses(TOTAL_NUMBER_OF_ADDRESSES)

  useEffect(() => {
    dispatch(rootActions.resetCurrentStore())
  }, [])

  useEffect(() => {
    if (!location) return

    const arr: AddressLine[] = [location]
    setLocationAddressLine(arr)
  }, [location])

  useEffect(() => {
    if (!addresses) return

    if (location && addresses.length >= TOTAL_NUMBER_OF_ADDRESSES) {
      setStoredAddresses([...addresses].slice(0, -1))
    } else {
      setStoredAddresses(addresses)
    }
  }, [location, addresses])

  const onDeliveryLinePress = (addressLine: AddressLine) => {
    switch (addressLine.icon) {
      case 'mapArrowIconSolid':
        onSelectCurrentLocation(addressLine)
        break
      case 'recentIcon':
      case 'savedIcon':
        reportSelectedRecentLocation(
          convertStoredCustomerAddressToIAddress(addressLine.additionalData, countryCode || 'AU'),
        )
        dispatch(rootActions.addCustomerAddressToOrder(addressLine.additionalData))
        dispatch(rootActions.selectServiceMethod('Delivery'))
        reportDeliveryAddressSelected()
        navigate(NavigationConstants.timedOrder)
        break
    }
  }

  const recentClassName = locationAddressLine ? styles.addressListWrapperWithBorder : styles.addressListWrapper
  const showLocation = locationAddressLine && type === 'Delivery'
  const showRecents = storedAddresses && storedAddresses.length > 0
  const showLists = showLocation || showRecents

  return (
    <>
      {showLists ? (
        <div className={styles.addressStoredListWrapper}>
          <GenericCard testID={`${testID}.storedAddressListContainer`} noPadding>
            {showLocation ? (
              <div className={styles.addressListWrapper}>
                <AddressList
                  testID={`${testID}.currentLocationAddressList`}
                  addresses={locationAddressLine}
                  onPress={onDeliveryLinePress}
                  narrowHeight
                  swapNameAndAddress
                />
              </div>
            ) : null}

            {showRecents ? (
              <div className={recentClassName}>
                <AddressList
                  testID={`${testID}.recentSavedAddressList`}
                  listTitle={'Recent'}
                  addresses={storedAddresses}
                  onPress={onDeliveryLinePress}
                  narrowHeight
                  swapNameAndAddress
                />
              </div>
            ) : null}
          </GenericCard>
        </div>
      ) : null}
    </>
  )
}

export interface AddressSearchStoredListContainerProps extends BaseProps {
  location: AddressLine | null | undefined
  type: BffContext.ServiceMethods
  onSelectCurrentLocation: (address: AddressLine) => void
}
