import { rootActions } from '@dominos/business'
import { BasketLineData, createBasketLine } from '@dominos/business/functions/basket'
import {
  formatProductCode,
  getCustomisationChanges,
  getSelectedProductSize,
  getSwapType,
} from '@dominos/business/functions/menu'
import { isMenuSet, isPortionMenuItem, isProductMenuItem, isProductMenuItemNew } from '@dominos/components'
import { useBreakpoints, useCoupons, useFeatures, useFos, useKiosk, useReport } from '@dominos/hooks-and-hocs'
import { getStateFromNavigation, NavigationConstants } from '@dominos/navigation'
import { navigate, useLocation } from '@reach/router'
import {
  FosEventInfoType,
  FosInteractionEventParameter,
  FosInteractionEventParameterConstants,
  FosInteractionEventType,
} from 'olo-feature-fos'
import React from 'react'
import { useDispatch } from 'react-redux'
import { MenuCardDefault, MenuProductCard, MenuSetCard } from './menu-cards'
import { MinimalistMenuProductCard } from './menu-cards/minimalist-menu-product-card'

type SwapSetBaseProduct = Bff.Menu.old.SwapSetBaseProduct
type SwapSetProductSize = Bff.Menu.old.SwapSetProductSize

interface MenuSectionProps extends BaseProps {
  menuItemsInSection?: readonly MenuItemDependents[]
  index: number
  toggleProductCard?: (
    item?: MenuItemDependents,
    lineData?: BasketLine,
    swapping?: BasketLine,
    addDirectlyToBasket?: boolean,
  ) => void
  /**
   * Examples include 'pizza' and 'drinks'.
   * - Obtained from Router url parameter `:menuCategory`
   */
  menuCategory?: string
  /**
   * Sub-category within the menu (eg. 'value' and 'premium').
   * - Obtained from Router url parameter `:selection`
   */
  selection?: string
  isFiltered?: boolean
  voucherNo?: number
  voucherItemNo?: number
  getBaseProductPrice: (baseProduct: SwapSetBaseProduct | undefined) => number | undefined | null
  getMenuItem: (productCode: string) => ProductMenuItem | undefined
}

export const MenuSection = ({
  testID,
  menuItemsInSection,
  toggleProductCard,
  menuCategory,
  isFiltered,
  voucherNo,
  voucherItemNo,
  getBaseProductPrice,
  getMenuItem,
}: MenuSectionProps) => {
  const dispatch = useDispatch()
  const { isMobile } = useBreakpoints()
  const { reportProductAddToCart, reportProductReplaceInCart, reportVoucherUsage } = useReport()
  const appliedCoupons = useCoupons()
  const location = useLocation()
  const { sendFosEvent } = useFos()
  const { isKioskOrder } = useKiosk()

  if (!menuItemsInSection) {
    return <span data-testid={`${testID}.empty`} />
  }

  const swapping = getStateFromNavigation<BasketLine>(location, 'swapping')

  const goToVariety = (product: MenuItemDependents) => {
    if (isProductMenuItemNew(product)) {
      return
    }

    const productCode = formatProductCode(product.code)

    navigate(`${NavigationConstants.menu}/${menuCategory}/${productCode}`)
  }

  const addMenuSetVoucher = (voucherCode: string) => {
    dispatch(rootActions.addCoupons([voucherCode]))
    reportVoucherUsage(voucherCode, 'Menu Set')
    if (isMobile || isKioskOrder) {
      navigate(NavigationConstants.basket)
    }
  }

  const addToBasket = (
    product: ProductMenuItem | PortionMenuItem,
    productCustomisation?: MenuCustomisationItem[],
    extraSurcharge?: number,
  ) => {
    const size = getSelectedProductSize(product, productCustomisation) as SwapSetProductSize
    const customisationChanges = getCustomisationChanges(productCustomisation, size)

    if (customisationChanges) {
      const value = createBasketLine(customisationChanges.quantity, {
        item: product,
        base: customisationChanges.base,
        sauce: customisationChanges.sauce,
        sizeCode: customisationChanges.sizeCode,
        options: customisationChanges.options,
      } as BasketLineData)

      if (value) {
        if (swapping) {
          dispatch(rootActions.replaceBasketLine({ remove: swapping, add: value, voucherNo, voucherItemNo }))
          reportProductReplaceInCart(
            [value],
            [swapping],
            appliedCoupons[0]?.code,
            getSwapType(extraSurcharge, swapping, getMenuItem, getBaseProductPrice),
          )
        } else {
          dispatch(rootActions.addLinesToBasket({ add: [value], voucherNo, voucherItemNo }))
          reportProductAddToCart([value], appliedCoupons[0]?.code)
        }
      }

      if (isFiltered) {
        if (isMobile || isKioskOrder) {
          navigate(NavigationConstants.basket)
        } else {
          navigate(NavigationConstants.menu)
        }
      }
    }
  }

  const clickProduct = (product: MenuItemDependents, lineData?: BasketLine, addDirectlyToBasket?: boolean) => {
    if (!toggleProductCard || isProductMenuItemNew(product)) {
      return
    }

    toggleProductCard(product, lineData, swapping, addDirectlyToBasket)

    const eventAdditionalInfo = {
      [FosInteractionEventParameter.Popup]: FosInteractionEventParameterConstants.ProductDetailsPopup,
      [FosInteractionEventParameter.ProductCode]: product.code,
    }

    if (!isMobile) {
      sendFosEvent({
        type: FosEventInfoType.InteractionEvent,
        locationPath: location.pathname,
        eventType: FosInteractionEventType.ProductDetailsButtonClicked,
        additionalInfo: eventAdditionalInfo,
      })
    }
  }

  let menuCards = menuItemsInSection.map(
    (menuItem: MenuItemDependents) =>
      !isProductMenuItemNew(menuItem) && (
        <MenuCardRenderer
          testID={testID}
          key={menuItem.code}
          menuItem={menuItem}
          addMenuSetVoucher={addMenuSetVoucher}
          addToBasket={addToBasket}
          clickProduct={clickProduct}
          goToVariety={goToVariety}
          isFiltered={isFiltered}
          getBaseProductPrice={getBaseProductPrice}
        />
      ),
  )

  menuCards = menuCards.filter((x) => x)

  if (menuCards && menuCards.length === 0) {
    return <span data-testid={`${testID}.empty`} />
  }

  return <>{menuCards}</>
}

export const MenuCardRenderer = React.memo(
  ({
    testID,
    menuItem,
    addMenuSetVoucher,
    addToBasket,
    clickProduct,
    goToVariety,
    isFiltered,
    getBaseProductPrice,
  }: {
    testID: string
    menuItem: MenuItemDependents
    addMenuSetVoucher?: (voucherCode: string) => void
    addToBasket?: (
      product: ProductMenuItem | PortionMenuItem,
      productCustomisation: MenuCustomisationItem[],
      extraSurcharge?: number,
    ) => void
    clickProduct?: (product: MenuItemDependents, lineData?: BasketLine | undefined) => void
    goToVariety?: (product: MenuItemDependents) => void
    isFiltered?: boolean
    getBaseProductPrice: (baseProduct: SwapSetBaseProduct | undefined) => number | undefined | null
  }) => {
    const [minimalistMenuEnabled] = useFeatures('MinimalistMenuEnabled')
    if (isProductMenuItemNew(menuItem)) {
      return null
    }

    const itemTestId = `${testID}.${menuItem.type}.${menuItem.code}`

    if (isMenuSet(menuItem)) {
      return <MenuSetCard key={menuItem.code} item={menuItem} addToBasket={addMenuSetVoucher} testID={itemTestId} />
    }

    if (isProductMenuItem(menuItem) || isPortionMenuItem(menuItem)) {
      return !minimalistMenuEnabled ? (
        <MenuProductCard
          key={menuItem.code}
          testID={itemTestId}
          item={menuItem}
          addToBasket={addToBasket}
          onClick={clickProduct}
          isFiltered={isFiltered}
          getBaseProductPrice={getBaseProductPrice}
        />
      ) : (
        <MinimalistMenuProductCard
          testID={itemTestId}
          item={menuItem}
          isFiltered={isFiltered}
          getBaseProductPrice={getBaseProductPrice}
          onClick={clickProduct}
        />
      )
    }

    return (
      <MenuCardDefault
        key={menuItem.code}
        testID={itemTestId}
        item={menuItem}
        onClick={menuItem.type === 'Variety' ? goToVariety : clickProduct}
        isFiltered={isFiltered}
      />
    )
  },
)
