import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 49,
  height: 73,
}

export const Gauge8 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 49 73'>
      <defs>
        <path
          d='M9.5925527,34.7 L6.8925527,32.1 C5.1925527,30.5 3.9925527,28 3.9925527,25.4 L3.9925527,9 C3.9925527,4.9 7.3925527,1.5 11.4925527,1.5 L37.5925527,1.5 C41.6925527,1.5 45.0925527,4.9 45.0925527,9 L45.0925527,25.4 C45.0925527,28 43.8925527,30.4 42.0925527,32.1 L39.3925527,34.7 C39.1925527,34.9 39.1925527,35.2 39.3925527,35.3 L43.0925527,38.6 C45.5925527,40.8 47.0925527,43 47.0925527,46.3 L47.0925527,64 C47.0925527,68.1 43.6925527,71.5 39.5925527,71.5 L9.4925527,71.5 C5.3925527,71.5 1.9925527,68.1 1.9925527,64 L1.9925527,46.2 C1.9925527,43 3.4925527,40.7 5.8925527,38.6 L9.5925527,35.3 C9.7925527,35.2 9.7925527,34.9 9.5925527,34.7 Z M31.9925527,42.4 L16.9925527,42.4 C16.6925527,42.4 16.4925527,42.6 16.4925527,42.9 L16.4925527,57.6 C16.4925527,57.9 16.6925527,58.1 16.9925527,58.1 L31.9925527,58.1 C32.2925527,58.1 32.4925527,57.9 32.4925527,57.6 L32.4925527,42.9 C32.4925527,42.6 32.2925527,42.4 31.9925527,42.4 Z M30.0925527,14.9 L18.9925527,14.9 C18.6925527,14.9 18.4925527,15.1 18.4925527,15.4 L18.4925527,28 C18.4925527,28.3 18.6925527,28.5 18.9925527,28.5 L30.0925527,28.5 C30.3925527,28.5 30.5925527,28.3 30.5925527,28 L30.5925527,15.4 C30.5925527,15.1 30.3925527,14.9 30.0925527,14.9 Z'
          id='gauge-path-8'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='8' fillRule='nonzero'>
          <use fill={fill} href='#gauge-path-8' />
          <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-8' />
        </g>
      </g>
    </svg>
  )
}
