import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 25,
  height: 25,
}

const TrashCanIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 17 18'>
      <g stroke='none' strokeWidth='1' fill={fill} fillRule='nonzero'>
        <path d='M4.39453125,17.4023438 L11.2280273,17.4023438 C12.6269531,17.4023438 13.3447266,16.7431641 13.4106445,15.402832 L13.8574219,5.29541016 L14.5239258,5.29541016 C15.1611328,5.29541016 15.6225586,4.87060547 15.6225586,4.23339844 C15.6225586,3.61083984 15.1611328,3.18603516 14.5239258,3.18603516 L11.71875,3.18603516 L11.71875,2.18261719 C11.71875,0.76171875 10.847168,0 9.20654297,0 L6.41601562,0 C4.77539062,0 3.90380859,0.76171875 3.90380859,2.18261719 L3.90380859,3.18603516 L1.09863281,3.18603516 C0.461425781,3.18603516 0,3.61083984 0,4.23339844 C0,4.87060547 0.461425781,5.29541016 1.09863281,5.29541016 L1.76513672,5.29541016 L2.21191406,15.402832 C2.27050781,16.7431641 2.99560547,17.4023438 4.39453125,17.4023438 Z M6.24755859,2.2265625 C6.24755859,2.05810547 6.35742188,1.97021484 6.58447266,1.97021484 L9.03808594,1.97021484 C9.26513672,1.97021484 9.375,2.05810547 9.375,2.2265625 L9.375,3.18603516 L6.24755859,3.18603516 L6.24755859,2.2265625 Z M5.34667969,14.5458984 C4.93652344,14.5458984 4.64355469,14.2675781 4.63623047,13.8500977 L4.41650391,6.86279297 C4.40917969,6.43798828 4.67285156,6.15234375 5.10498047,6.15234375 C5.54443359,6.15234375 5.83740234,6.43798828 5.84472656,6.85546875 L6.05712891,13.828125 C6.06445312,14.2602539 5.79345703,14.5458984 5.34667969,14.5458984 Z M7.81494141,14.5458984 C7.39013672,14.5458984 7.10449219,14.2749023 7.10449219,13.8574219 L7.10449219,6.84814453 C7.10449219,6.43066406 7.39013672,6.15234375 7.81494141,6.15234375 C8.23242188,6.15234375 8.51806641,6.43066406 8.51806641,6.84814453 L8.51806641,13.8574219 C8.51806641,14.2749023 8.23242188,14.5458984 7.81494141,14.5458984 Z M10.2758789,14.5458984 C9.82910156,14.5458984 9.55810547,14.2602539 9.56542969,13.828125 L9.77783203,6.85546875 C9.79248047,6.43798828 10.078125,6.15234375 10.5175781,6.15234375 C10.949707,6.15234375 11.2133789,6.43798828 11.2060547,6.86279297 L10.9936523,13.8500977 C10.9790039,14.2675781 10.6860352,14.5458984 10.2758789,14.5458984 Z' />
      </g>
    </svg>
  )
}

export { TrashCanIcon }
