import { ErrorHandler } from '@apollo/client/link/error'
import { NetworkError } from '@apollo/client/errors'
import { ServerParseError } from '@apollo/client/link/http'
import { B2BAuth } from '@dominos/components/login-b2b/B2BAuth'

export const b2BErrorHandler: ErrorHandler = ({ networkError, forward, operation }) => {
  const forwarded = forward(operation)
  const application = operation?.getContext()?.headers?.['dpe-application']

  if (!isB2BApplication(application)) return

  if (!hasStatus(networkError) || [401, 403].indexOf(networkError?.statusCode) === -1) return

  forwarded.subscribe(
    () => {},
    () => B2BAuth.logout(),
  )
}

function hasStatus(error?: NetworkError): error is ServerParseError {
  return !!(error as ServerParseError)?.statusCode
}

function isB2BApplication(application: string): boolean {
  return application == 'B2BDesktopWeb' || application == 'B2BMobileWeb'
}
