import { useServiceSubTypes } from '@dominos/hooks-and-hocs'
import { ServiceMethodSubTypes } from '@dominos/interfaces/checkout'
import React from 'react'
import { ServiceMethodType } from './service-method-type'

export const DEFAULT_DELIVERY_SUBTYPE: ServiceMethodSubTypes = 'ReceiveDirectly'

export const DeliveryType = (props: {
  checkedOption?: ServiceMethodSubTypes
  onChecked: (x?: Bff.Stores.ServiceMethodSubTypes) => void
}) => {
  const { deliverySubTypes } = useServiceSubTypes()

  return deliverySubTypes.length > 0 ? (
    <ServiceMethodType
      fieldId='delivery-type'
      fieldName='deliverySubType'
      serviceMethod='Delivery'
      defaultOption={DEFAULT_DELIVERY_SUBTYPE}
      subTypes={[DEFAULT_DELIVERY_SUBTYPE, ...deliverySubTypes]}
      checkedOption={props.checkedOption}
      onChecked={props.onChecked}
    />
  ) : null
}
