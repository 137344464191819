const SECTION_DECIMAL_PLACES = 2

/**
 * Replaces `.` with `~` for all characters after `decimals`.
 *
 * @param decimals number of `.` before converting characters
 * @param productCode (eg. `Variety.buttermillk.chicken.kickers`)
 * @return eg. `buttermillk~chicken~kickers` | `productCode`
 */
export const formatProductCode = (productCode: string, decimals: number = 1) => {
  const split = productCode.split('.')

  return split.length > decimals ? split.slice(decimals).join('~') : productCode
}

/**
 * Replaces all occurrences of `.` with `~` (for products converted by `formatProductCode`).
 *
 * @param text (eg. `buttermillk~chicken~kickers`)
 * @returns eg. `buttermillk.chicken.kickers` || `text`
 */
export const convertSpecialCharacters = (text: string) => text.replace(/~/g, '.')

export const formatSectionName = (name: string) => formatProductCode(name, SECTION_DECIMAL_PLACES).toLowerCase()

export const formatCategory = (code: string) => formatProductCode(code).toLowerCase()

export const formatPage = (pageCode: string) => {
  const segments = pageCode.split(/\./gi)

  return segments.length > 0 ? segments[segments.length - 1].toLowerCase() : pageCode
}
