import { formatCategory } from '@dominos/business/functions/menu'
import { MenuNavigationProps } from '@dominos/components'
import { COUPON_MENU_CODE } from '@dominos/hooks-and-hocs/menu/use-menu-with-coupon-page'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import React from 'react'
import { getSelectedPage } from '../../functions'
import css from '../menu-category-tabs.less'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { categoryIcons, categoryIconsName } from '@dominos/res'

type MenuPage = Bff.Menu.old.MenuPage

export const MenuCategoryTab = (props: MenuNavigationProps & { menuPage: MenuPage }) => {
  const { menu, menuPage } = props

  const {
    colours: { hyperlink, web },
  } = useDominosTheme()

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (menuPage.code !== COUPON_MENU_CODE) {
      navigate(`${NavigationConstants.menu}/${formatCategory(menuPage.code)}`)
    }
  }

  const selectedPage = getSelectedPage(props)
  const active = selectedPage
    ? selectedPage.code === menuPage.code
    : menuPage.code === (menu && menu.pages && menu.pages.length > 0 && menu.pages[0].code)

  const iconName = menuPage.code in categoryIcons ? (menuPage.code as categoryIconsName) : 'Menu.Default'
  const Icon = categoryIcons[iconName]

  return (
    <li className={active ? css.active : undefined} data-testid={`menu-category-tab-li-${menuPage.code}`}>
      <span>
        <a
          href={`${NavigationConstants.menu}/${formatCategory(menuPage.code)}`}
          onClick={onClick}
          data-testid={`menu-category-tab-${menuPage.code}`}
        >
          <Icon {...props} fill={active ? hyperlink : web.grey} />
          <span className={css.label}>{menuPage.media.name}</span>
        </a>
      </span>
    </li>
  )
}
