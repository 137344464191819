import React, { useEffect, useState } from 'react'

import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'

import styles from './collapsable-titled-card.less'

const CollapsableTitledCard: React.FC<React.PropsWithChildren<CollapsableTitledCardProps>> = (props) => {
  const [expanded, setExpanded] = useState(!props.startCollapsed)

  useEffect(() => setExpanded(!props.startCollapsed), [props.startCollapsed])

  const onHeaderClick = () => {
    if (!props.static) {
      setExpanded(!expanded)
      if (props.onToggleExpanded) {
        props.onToggleExpanded(!expanded)
      }
    }
  }

  const theme = useDominosTheme()

  return (
    <section
      className={styles.section}
      style={{
        borderRadius: theme.webLargeBorderRadius,
        backgroundColor: theme.colours.defaultBackground,
        ...props.style,
      }}
      data-testid={`${props.testID || 'CollapsableTitledCard'}-container`}
    >
      {(props.primaryTitle || props.secondaryTitle) && (
        <header
          className={styles.container}
          style={{ cursor: !!props.static ? '' : 'pointer' }}
          onClick={onHeaderClick}
          role={'title'}
          data-testid={`${props.testID || 'CollapsableTitledCard'}-header`}
        >
          <h3 className={styles.h3} data-testid={`${props.testID || 'CollapsableTitledCard'}-title`}>
            {props.primaryTitle}
            {props.secondaryTitle && (
              <p className={styles.suffix} data-testid={`${props.testID || 'CollapsableTitledCard'}-secondaryTitle`}>
                {props.secondaryTitle}
              </p>
            )}
          </h3>
          {!props.static && (
            <div className={styles.chevron}>
              <StaticSvgIcon
                name={'chevron'}
                isUnstyled={true}
                fill={theme.colours.overlayColor}
                direction={expanded ? 'down' : 'right'}
              />
            </div>
          )}
        </header>
      )}

      {props.children && (
        <div
          data-testid={`${props.testID || 'CollapsableTitledCard'}-children`}
          className={styles.content}
          style={{ display: expanded ? 'block' : 'none', padding: props.noPadding ? '0px' : '0 8px 8px 8px' }}
        >
          {props.children}
        </div>
      )}
    </section>
  )
}

export { CollapsableTitledCard }
