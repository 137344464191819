import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'
import { useConfigContext, useKiosk, useReport } from '@dominos/hooks-and-hocs'

const intervalDuration = 300000
const fetchHealth = async (graphUrl: string): Promise<string> => {
  try {
    const response = await fetch(graphUrl.replace('graphql', 'health'))
    if (!response.ok) {
      return `fail: ${response.status}`
    }

    const { status } = await response.json()

    return `${status}: ${response.status}`
  } catch {
    return 'fail: -1'
  }
}

export const KioskHeartbeat = ({ children }: React.PropsWithChildren<{}>) => {
  const { applicationEnv } = useConfigContext()
  const { isKioskOrder, kioskId, storeNo } = useKiosk()
  const { href, pathname } = useLocation()
  const { reportKioskHealth } = useReport()

  const performHeartbeat = async () => {
    const status = await fetchHealth(applicationEnv.graphUrl)
    reportKioskHealth(href, pathname, status, kioskId, storeNo)
  }

  useEffect(() => {
    if (isKioskOrder) {
      performHeartbeat()
      const interval = setInterval(performHeartbeat, intervalDuration)

      return () => clearInterval(interval)
    }
  }, [isKioskOrder])

  return <>{children}</>
}
