import { ActionButton } from '@dominos/components/buttons/action-button'
import { useNotifyArrival, useReadyToNotify } from '@dominos/hooks-and-hocs/order/order-status'
import { StaticSvgIcon } from '@dominos/res/images/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { ServiceMethodDetailsProps } from '../../service-method-details-definitions'

import css from './notify-store.less'

export const NotifyStore = ({ orderId, icon, title, message }: ServiceMethodDetailsProps) => {
  const { isReady, hasNotified } = useReadyToNotify(orderId)
  const { notify, notified: success, loading } = useNotifyArrival(orderId)

  const { t } = useTranslation('tracker')

  return (
    <div className={css.container}>
      <div className={css.messageContainer}>
        <StaticSvgIcon name={icon} isUnstyled fill={'#414141'} width={20} height={20} />
        <div className={css.notified}>
          <p data-testid='service-method-message' className={css.title}>
            {t(title.key, { defaultValue: title.defaultValue })}
          </p>
          {(hasNotified || success) && (
            <p data-testid='service-method-message-notified' className={css.notifiedMessage}>
              {t(message.key, { defaultValue: message.defaultValue })}
            </p>
          )}
        </div>
      </div>
      {!hasNotified && !success && (
        <ActionButton
          testID='i-am-here-button'
          text={t('IAmHere', { defaultValue: "I'm here" })}
          onPress={notify}
          loading={loading}
          disabled={!isReady}
          containerStyle={{ minWidth: 200 }}
          textFontWeight='bold'
        />
      )}
    </div>
  )
}
