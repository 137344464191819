import { Locale } from '@dominos/business/functions/common/get-config'
import { isKioskOrderType } from '@dominos/business/functions/common/order-type'
import { isNativeApp } from '@dominos/business/functions/native-app/is-native-app'
import {
  getDefaultLanguageForNativeApp,
  getDefaultLanguageFromBrowserLanguages,
  getDefaultLanguageFromDefaultLocale,
} from '.'

export const getDefaultLanguage = (countryLanguages: string[], defaultLocale: Locale): BffContext.Languages => {
  if (isNativeApp()) {
    return getDefaultLanguageForNativeApp(countryLanguages) as BffContext.Languages
  }

  if (isKioskOrderType()) {
    return getDefaultLanguageFromDefaultLocale(countryLanguages, defaultLocale)
  }

  return (
    getDefaultLanguageFromBrowserLanguages(countryLanguages) ||
    getDefaultLanguageFromDefaultLocale(countryLanguages, defaultLocale)
  )
}
