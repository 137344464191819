import { getFormattedPrice } from '@dominos/business/functions/text'

type PortionSize = Bff.Menu.old.PortionSize

export const getProductPrice = (type?: string, size?: ProductSize | PortionSize | null): ProductPricing => {
  switch (type) {
    case 'Product':
      const productPrice = size?.price
      const productSalePrice = (size as ProductSize)?.salePrice
      const productPromoPrice = (size as ProductSize)?.promoPrice

      return {
        formattedPrice: productPrice ? getFormattedPrice(productPrice) : null,
        formattedSalePrice: productSalePrice ? getFormattedPrice(productSalePrice) : null,
        formattedPromoPrice: productPromoPrice ? getFormattedPrice(productPromoPrice) : null,
      }
    case 'Portion':
      const portionPrice = size?.price
      const portionPromoPrice = (size as PortionSize)?.promoPrice

      return {
        formattedPrice: portionPrice ? getFormattedPrice(portionPrice) : null,
        formattedSalePrice: null,
        formattedPromoPrice: portionPromoPrice ? getFormattedPrice(portionPromoPrice) : null,
      }
    default:
      return {
        formattedPrice: null,
        formattedSalePrice: null,
        formattedPromoPrice: null,
      }
  }
}
