import React, { useState } from 'react'
import { MoreInfoPopup } from './more-info-popup'
import { MoreInfoProps } from './more-info.interface'
import { MoreInfoAccessory } from './more-info-accessory'

export const MoreInfo = (props: MoreInfoProps) => {
  const [showPopup, setShowPopup] = useState(false)
  const handleShowPopup = () => setShowPopup(true)
  const handlePopupClose = () => setShowPopup(false)

  if (!props.description) {
    return null
  }

  return (
    <>
      <MoreInfoAccessory
        testID={props.testID}
        id={props.id ?? props.testID}
        label={props.label}
        onClick={handleShowPopup}
        useInfoText={props.useInfoText}
        info={props.info}
      />
      {showPopup && (
        <MoreInfoPopup
          testID={`${props.testID}.popup`}
          id={props.id ?? props.testID}
          title={props.title}
          body={props.description}
          media={props.media}
          onDismiss={handlePopupClose}
        />
      )}
    </>
  )
}
