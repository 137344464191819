import { ApolloError } from '@apollo/client'

export const getStatusReasonFromApolloError = (error?: ApolloError) => {
  const graphErrors = error?.graphQLErrors
  const networkError = error?.networkError
  let statusReason = error?.message
  if (graphErrors?.length) {
    statusReason = graphErrors.map((err) => err.message).join('\n')
  }
  if (networkError) {
    statusReason = networkError.message
  }

  return statusReason
}
