import gql from 'graphql-tag'

export const portionMenuItemQuery = gql`
  query portionMenuItemQuery($serviceMethod: ServiceMethodEnum!, $storeNo: Int!, $productCode: String!) {
    portionMenuItem(serviceMethod: $serviceMethod, storeNo: $storeNo, productCode: $productCode) {
      code
      type
      defaultSize
      halfNHalfEnabled
      media {
        name
        description
        largeImage {
          url
          altText
        }
        smallImage {
          url
          altText
        }
        applicationImage {
          url
          altText
        }
      }
      sizes {
        code
        price
        promoPrice
        availability
        media {
          name
        }
        swaps {
          bases {
            rule {
              min
              max
              maxPerIngredient
              locked
            }
            ingredients {
              code
              media {
                name
                image
                colour
                icon
              }
              price
              nutritionals {
                carbohydrate
                energyKJ
                energyKcal
                fat
                fatSaturated
                fibre
                protein
                weight
                sodium
                sugars
              }
              allergens {
                name
                type
              }
            }
          }
          portionProductCodes
        }
        recipe {
          base {
            code
            media {
              name
              image
              colour
              icon
            }
            price
            nutritionals {
              carbohydrate
              energyKJ
              energyKcal
              fat
              fatSaturated
              fibre
              protein
              weight
              sodium
              sugars
            }
            allergens {
              name
              type
            }
          }
          portionCount
        }
        nutritionals {
          carbohydrate
          energyKJ
          energyKcal
          fat
          fatSaturated
          fibre
          protein
          weight
          sodium
          sugars
        }
        allergens {
          name
          type
        }
      }
      legends {
        code
        image {
          url
          altText
        }
        text
      }
      disclaimers
    }
  }
`
