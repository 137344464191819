import gql from 'graphql-tag'

export const customerDetailsQuery = gql`
  query customerDetailsQuery($customerId: String) {
    customer(id: $customerId) {
      id
      name
      alternateName
      email
      emailVerified
      countryCode
      phoneNumber
      identityProvider
      stores {
        store {
          storeNo
          geo {
            latitude
            longitude
          }
          timeZone
          media {
            name
            displayAddress
          }
        }
        isDefault
      }
      addresses {
        name
        address {
          buildingName
          unitNo
          streetNo
          street
          suburb
          postCode
          state
          floorNo
        }
        displayAddress
        deliveryInstructions
        dogDanger
        isDefault
      }
      loyalty {
        subscription {
          isEnrolled
        }
        balance {
          numOfFreePizzas
          pointsToNextFreePizza
          pointsPerPizza
          pointsBalance
          pendingPoints
          isEligibleToEarnPointToday
        }
      }
      emailSubscriptions {
        edmType
      }
    }
  }
`

export const customerCreateQuery = gql`
  mutation customerCreateQuery($input: UpsertCustomer!) {
    customer(input: $input) {
      id
    }
  }
`

export const customerLoyaltyQuery = gql`
  query customerLoyaltyQuery($customerId: String!) {
    customer(id: $customerId) {
      id
      loyalty {
        subscription {
          isEnrolled
        }
        balance {
          numOfFreePizzas
          pointsToNextFreePizza
          pointsPerPizza
          pointsBalance
          pendingPoints
          isEligibleToEarnPointToday
        }
      }
    }
  }
`

export const customerLoyaltyPointsHistoryQuery = gql`
  query customerLoyaltyPointsHistoryQuery($customerId: String!, $page: Int, $pageSize: Int) {
    customer(id: $customerId) {
      id
      loyalty {
        pointsHistory(page: $page, pageSize: $pageSize) {
          totalItems
          transactions {
            pointsStatus
            statusDate
            orderTotal
            points
          }
        }
      }
    }
  }
`

export const customerAddressQuery = gql`
  query customerAddressQuery($customerId: String) {
    customer(id: $customerId) {
      id
      addresses {
        name
        address {
          buildingName
          unitNo
          streetNo
          street
          suburb
          postCode
          state
          floorNo
        }
        displayAddress
      }
    }
  }
`
