import { getFullUrl } from '@dominos/business/functions/menu/get-images'
import { Checkbox } from '@dominos/components'
import { useBreakpoints, useDevToggles } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { TermsAndConditions } from '../terms-and-conditions'
import css from './multi-item-grid.less'

export const getSelectionUpdates = (
  selectedItems: Record<string, boolean>,
  name: string,
  checked: boolean,
  multiSelect: boolean,
) =>
  Object.keys(selectedItems).reduce(
    (memo, key) => {
      // Deselect all other keys when multi select disabled
      if (key !== name && !multiSelect) {
        memo[key] = false
      }

      return memo
    },
    { ...selectedItems, [name]: checked },
  )

export const MultiItemGrid = ({
  testID,
  title,
  description,
  disclaimers,
  offerItems,
  onSelectedItemsChanged,
  exclusive,
}: {
  testID: string
  title?: string
  description?: string
  disclaimers: Bff.Offers.Disclaimer[]
  offerItems: Bff.Offers.Item[]
  onSelectedItemsChanged: (items: string[]) => void
  exclusive?: boolean
}) => {
  const [selectedItems, setItemsStatus] = useState<Record<string, boolean>>({})
  const { isMobile } = useBreakpoints()
  const { isEnabled } = useDevToggles()
  const maxOfferItemsAllowed = isMobile ? 10 : 3

  useEffect(() => {
    if (offerItems && offerItems.length > 0) {
      onItemSelected(offerItems[0].id, true)
    }
  }, [offerItems])

  const getImageUrl = (item: Bff.Offers.Item) => {
    if (item.image && item.image.url) {
      if (isEnabled['test-offers-images']) {
        return `https://internetorder.dominostest.co.nz/${item.image.url}`
      }

      return getFullUrl(item.image.url)
    }

    return undefined
  }

  const onItemSelected = (name: string | null, checked: boolean) => {
    if (!name) {
      return
    }

    const changes = getSelectionUpdates(selectedItems, name, checked, !exclusive)

    setItemsStatus(changes)
    onSelectedItemsChanged(Object.keys(changes).filter((key) => changes[key]))
  }

  return (
    <div
      data-testid={testID}
      style={{
        marginBottom: '4px',
        width: isMobile ? 360 : Math.min(offerItems.length, 3) * 215,
      }}
    >
      {title && (
        <div data-testid={`${testID}.title`} className={css.title}>
          {title}
        </div>
      )}
      {description && (
        <div data-testid={`${testID}.description`} className={css.description}>
          {description}
        </div>
      )}
      <div className={css.itemsContainer}>
        {offerItems &&
          offerItems.length > 0 &&
          [...offerItems].splice(0, maxOfferItemsAllowed).map((item, index) => (
            <div
              key={item.placementCode}
              data-testid={`${testID}.item-${item.id}.container`}
              className={css.offerItem}
              style={
                index !== Math.min(offerItems.length, maxOfferItemsAllowed) - 1
                  ? isMobile
                    ? { borderBottomColor: '#979797', borderBottomStyle: 'solid' }
                    : { borderRightColor: '#979797', borderRightStyle: 'solid' }
                  : {}
              }
            >
              {item.image && item.image.url && (
                <img
                  data-testid={`${testID}.item-${item.id}.image`}
                  className={css.image}
                  src={getImageUrl(item)}
                  alt={item.image.altText ?? undefined}
                />
              )}
              <div className={css.itemDetails}>
                <div className={css.itemDetailsBody}>
                  <div data-testid={`${testID}.item-${item.id}.title`} className={css.name}>
                    {item.name || ''}
                  </div>

                  <div data-testid={`${testID}.item-${item.id}.subtitle`} className={css.subtitle}>
                    {item.price ? (
                      <span
                        data-testid={`${testID}.item-${item.id}.subtitle.price`}
                        className={css.price}
                      >{`${item.price}`}</span>
                    ) : null}

                    {item.energy ? (
                      <span data-testid={`${testID}.item-${item.id}.subtitle.kj`}>{`${item.price ? ' | ' : ' '}${
                        item.energy
                      }`}</span>
                    ) : null}
                  </div>
                </div>
                <Checkbox
                  testID={`${testID}.item-${item.id}`}
                  name={`${item.id}`}
                  onChange={onItemSelected}
                  checked={selectedItems[item.id]}
                  defaultChecked={index === 0}
                />
              </div>
            </div>
          ))}
      </div>
      <TermsAndConditions containerStyle={{ padding: '0px 6px 2px 6px' }} disclaimers={disclaimers} />
    </div>
  )
}
