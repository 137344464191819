import {
  IUseDeliveryAddressSearchErrorsContainerProps,
  useDeliveryAddressSearchErrorsContainer,
} from 'olo-feature-address'
import { AddressSearchErrorComponent } from '@dominos/components/address/address-search-error-component/address-search-error-component'
import { GenericCard } from '@dominos/components/cards/generic-card/generic-card'
import React from 'react'

export interface IDeliveryAddressErrorContainerProps extends IUseDeliveryAddressSearchErrorsContainerProps {
  displayAsCard?: boolean
  hideActionButtons?: boolean
}

export const DeliveryAddressErrorContainer = (props: IDeliveryAddressErrorContainerProps) => {
  const { errorToDisplay } = useDeliveryAddressSearchErrorsContainer(props)

  if (!errorToDisplay) return <> </>

  const style = getStyles()
  const AddressSearchErrorComponentElement = (
    <AddressSearchErrorComponent
      title={errorToDisplay.title}
      message={errorToDisplay.message}
      isLoading={false}
      icon={errorToDisplay.icon}
      iconStyle={errorToDisplay.iconStyle}
      adjustPinMessage={errorToDisplay.adjustPinMessage}
      closeButton={errorToDisplay.closeButton}
      leftButton={!props.hideActionButtons && errorToDisplay.leftButton}
      rightButton={!props.hideActionButtons && errorToDisplay.rightButton}
      errorContainerStyle={props.displayAsCard ? undefined : style.noCardErrorContainer}
    />
  )

  if (props.displayAsCard)
    return (
      <GenericCard testID={'DeliveryAddressErrorContainer'} title={undefined}>
        {AddressSearchErrorComponentElement}
      </GenericCard>
    )

  return <div style={style.noCardContainer}>{AddressSearchErrorComponentElement}</div>
}

interface Style {
  noCardContainer: React.CSSProperties
  noCardErrorContainer: React.CSSProperties
}

const getStyles = (): Style => ({
  noCardContainer: {
    margin: '-15px 0 0 0',
  },
  noCardErrorContainer: {
    padding: '10px 0 10px 0',
  },
})
