import classnames from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import OtherTipAmount from '../other-tip-amount'
import css from './tip-toggles.less'

export interface ToggleSetValue {
  label: string
  value: string | number
}

type ToggleSetProps = React.PropsWithChildren<BaseProps> & {
  defaultValue?: number | string
  values?: ToggleSetValue[]
  onChange: (value: number) => void
  minTip: number
  maxTip: number
}

export const TipToggles = ({ testID, defaultValue, values = [], onChange, minTip, maxTip }: ToggleSetProps) => {
  const { t } = useTranslation('checkout')
  const [selectedToggleValue, setSelectedToggleValue] = useState<string | undefined>(defaultValue?.toString())

  const handleToggleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    const targetValue = event.currentTarget.value

    if (targetValue === selectedToggleValue) {
      setSelectedToggleValue(undefined)
      onChange(0)
    } else {
      setSelectedToggleValue(targetValue)

      const newValue = targetValue === 'Other' ? 0 : Number.parseFloat(targetValue)
      onChange(newValue)
    }
  }

  const valuesWithOther = [
    ...values,
    {
      value: 'Other',
      label: t('OtherTipAmountLabel', { defaultValue: 'Other' }),
    },
  ]

  return (
    <>
      <div className={css.tipToggles}>
        {valuesWithOther.map(({ label, value }) => (
          <button
            data-testid={`${testID}.toggle.${value}`}
            key={value}
            className={classnames(css.tipToggle, {
              [css.selected]: selectedToggleValue?.toString() === value.toString(),
            })}
            value={value}
            onClick={handleToggleChange}
          >
            {label}
          </button>
        ))}
      </div>

      {selectedToggleValue === 'Other' && <OtherTipAmount onChange={onChange} min={minTip} max={maxTip} />}

      <p className={css.tipTheDriverDisclaimer}>{t('TipDriverDisclaimer')}</p>
    </>
  )
}
