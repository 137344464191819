import { useFos } from '@dominos/hooks-and-hocs'
import { useLocation } from '@reach/router'
import { FosEventInfoType, FosInteractionEventType } from 'olo-feature-fos'

export const useFosOrderButtonClickedEvent = () => {
  const { sendFosEvent } = useFos()
  const { pathname } = useLocation()

  return () => {
    sendFosEvent({
      type: FosEventInfoType.InteractionEvent,
      locationPath: pathname,
      eventType: FosInteractionEventType.PlaceOrderButtonClicked,
    })
  }
}
