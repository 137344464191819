import { ErrorDefinitions } from '@dominos/interfaces'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'

const menuPageErrors: ErrorDefinitions = {
  NETWORK_ERROR: {
    translation: {
      key: 'NetworkErrorMessage',
      options: {
        defaultValue: 'A network error has occurred. Please try again after waiting a few moments.',
      },
    },
    display: 'popup',
    callback: () => navigate(NavigationConstants.home),
  },
}

export default menuPageErrors
