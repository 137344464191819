import React from 'react'
import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { AddressProvider } from '@dominos/hooks-and-hocs/address/providers/address-provider'
import { DeliveryAddressSearchAutoComplete } from '@dominos/components/address/features/delivery/delivery-address-search-autocomplete'
import { useCurrentLocationService } from '@dominos/hooks-and-hocs'
import { INearByLocation } from 'olo-feature-address'
import { useFeatures } from '@dominos/hooks-and-hocs'

export const DeliveryAddressSearchAutoCompleteScene = () => {
  const [currentLocationEnabled] = useFeatures('DeliveryAddressSearch-UseCurrentLocation')
  const { location, isLoading } = useCurrentLocationService(currentLocationEnabled)

  const nearBylocation: INearByLocation = {
    location: location,
    isLoading,
  }

  return (
    <StackNavigationBase
      testID={'address-search-scene'}
      header={<SimpleNavigationTitle showAccount showBackButton />}
      modification='map-view'
    >
      <AddressProvider>
        <DeliveryAddressSearchAutoComplete detectedLocation={nearBylocation} />
      </AddressProvider>
    </StackNavigationBase>
  )
}
