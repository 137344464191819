import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 52,
  height: 52,
}

const Face1Active = (props: SvgIconProps) => Face1({ ...props, fill: '#D0021B' })

const Face1Inactive = (props: SvgIconProps) => Face1({ ...props, fill: '#9B9B9B' })

const Face1 = (props: SvgIconProps) => {
  const fill = props.fill || '#9B9B9B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 52 52'>
      <g id='Face-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle fill={fill} cx='26.1714781' cy='26.1824296' r='24.5088093' />
        <path
          fill='#000000'
          d='M26.1718318,0 C11.7407009,0 0,11.7407009 0,26.1718318 C0,40.6029626 11.7407009,52.3436635 26.1718318,52.3436635 C40.6029626,52.3436635 52.3436635,40.6029626 52.3436635,26.1718318 C52.3436635,11.7407009 40.6031394,0 26.1718318,0 L26.1718318,0 Z M26.1718318,48.1718318 C20.1289069,48.1718318 14.6478238,45.7224613 10.6677635,41.765068 C9.07840609,40.1847107 7.72921899,38.3633498 6.67870307,36.3616528 C5.07884549,33.31344 4.17183175,29.8467208 4.17183175,26.1718318 C4.17183175,14.0409813 14.0409813,4.17183175 26.1718318,4.17183175 C31.9257523,4.17183175 37.1698317,6.39319874 41.0942245,10.0222537 C43.128922,11.9036156 44.8091141,14.1629831 46.0206325,16.6883547 C47.3989867,19.5615649 48.1718318,22.7781137 48.1718318,26.1718318 C48.1718318,38.3026822 38.3026822,48.1718318 26.1718318,48.1718318 L26.1718318,48.1718318 Z'
        />
        <path
          fill='#000000'
          d='M25.3178758,29.2038396 C31.4377462,28.8858829 37.3415192,32.1314108 40.3516742,37.4700061 C40.6144571,37.9359771 40.8569035,38.4208699 41.0722935,38.910891 L41.0722935,38.910891 L38.4819545,40.0490274 C38.3042313,39.6447732 38.1044031,39.2447632 37.8872447,38.8599613 C35.399826,34.4485334 30.5190671,31.7672991 25.4639449,32.0295515 C20.4420684,32.2895049 15.8877544,35.4374176 13.8610016,40.0493811 L13.8610016,40.0493811 L11.2706626,38.910891 C13.7237745,33.3286114 19.2376173,29.5184364 25.3178758,29.2038396 Z M17.4182968,16 C18.9809453,16 20.2477222,17.5670034 20.2477222,19.5 C20.2477222,21.4329966 18.9809453,23 17.4182968,23 C15.8556482,23 14.5888713,21.4329966 14.5888713,19.5 C14.5888713,17.5670034 15.8556482,16 17.4182968,16 Z M34.9253667,16 C36.4880153,16 37.7547922,17.5670034 37.7547922,19.5 C37.7547922,21.4329966 36.4880153,23 34.9253667,23 C33.3627182,23 32.0959413,21.4329966 32.0959413,19.5 C32.0959413,17.5670034 33.3627182,16 34.9253667,16 Z'
        />
      </g>
    </svg>
  )
}

export { Face1Active, Face1Inactive, Face1 }
