import { addressMappingsByCountry } from './address-mapping-by-country'
import { TFunction } from 'i18next'

export const mapAddressAttributesToTranslation: Record<string, string> = {
  unitNo: 'Unit Number',
  streetNo: 'Street Number',
  floorNo: 'Floor number',
  buildingName: 'NewBuildingNameFieldPlaceholder',
  streetName: 'Street',
  suburb: 'Suburb',
  state: 'State',
  postCode: 'Post Code',
}

export const convertToReadableSentence = (values: string[], t: TFunction) => {
  if (values.length === 1) {
    return values[0]
  }
  const last = values.pop()

  return values.join(', ') + t('And', { defaultValue: ' and ' }) + last
}

export const validateGoogleAddressComponent = (
  t: TFunction,
  addressComponents?: AddressComponent[],
  countryCode?: BffContext.Countries,
): ValidateAddressComponent => {
  if (!countryCode) {
    return { isValid: false, reason: 'CountryCode' }
  }

  const addressTypeMap = addressMappingsByCountry[countryCode]

  if (!addressTypeMap || addressTypeMap.length === 0) {
    return { isValid: false, reason: 'AddressMapping' }
  }
  const requiredAttributes = addressTypeMap.filter((atm) => atm.required).map(({ attribute }) => attribute)

  const missingAttributes = requiredAttributes
    .filter((attribute) => !addressComponents?.some(({ type, value }) => !!value && type.includes(attribute)))
    .map((key) => t(mapAddressAttributesToTranslation[key]))

  const isValid = missingAttributes.length === 0

  return { isValid, reason: isValid ? undefined : convertToReadableSentence(missingAttributes, t) }
}
