import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultFill = '#007AFF'
const defaultDimensions = {
  width: '39',
  height: '54',
}

export const NoLocationIcon = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height
  const fill = props.fill || defaultFill

  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${defaultDimensions.width} ${defaultDimensions.height}`}
    >
      <title>No Location</title>
      <g id='iOS-/-MVP-V3' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Delivery-Address-/-Failure-/-Generic-Error'
          transform='translate(-175, -286)'
          fill={fill}
          fillRule='nonzero'
        >
          <g id='No-Location' transform='translate(175, 286)'>
            <path
              d='M35.5012547,51.4216216 C35.9090339,51.827027 36.3942911,52.0297297 36.9570263,52.0297297 C37.5197616,52.0297297 38.0050188,51.827027 38.412798,51.4216216 C38.804266,51.0324324 39,50.5540541 39,49.9864865 C39,49.4189189 38.804266,48.9324324 38.412798,48.527027 L3.52321205,13.8405405 C3.13174404,13.4513514 2.64648683,13.2567568 2.0674404,13.2567568 C1.48839398,13.2567568 0.994981179,13.4513514 0.587202008,13.8405405 C0.195734003,14.2297297 0,14.7121622 0,15.2878378 C0,15.8635135 0.195734003,16.3459459 0.587202008,16.7351351 L35.5012547,51.4216216 Z'
              id='Shape'
              fillOpacity='0.4'
            ></path>
            <path
              d='M20.772271,0 C22.7459222,0 24.5279172,0.474324324 26.118256,1.42297297 C27.7085947,2.37162162 28.9727102,3.64054054 29.9106023,5.22972973 C30.8484944,6.81891892 31.3174404,8.57837838 31.3174404,10.5081081 C31.3174404,12.0972973 30.9952949,13.572973 30.3510038,14.9351351 C29.7067127,16.2972973 28.8218319,17.4689189 27.6963614,18.45 C26.5708908,19.4310811 25.2823087,20.1324324 23.8306148,20.5540541 L23.8306148,29.3837838 L17.7139272,23.3027027 L17.7139272,20.5540541 C16.2785445,20.1162162 14.9981179,19.4067568 13.8726474,18.4256757 C12.7471769,17.4445946 11.8622961,16.277027 11.218005,14.922973 C10.5737139,13.5689189 10.2515684,12.0972973 10.2515684,10.5081081 C10.2515684,8.57837838 10.7245922,6.81891892 11.6706399,5.22972973 C12.6166876,3.64054054 13.8848808,2.37162162 15.4752196,1.42297297 C17.0655583,0.474324324 18.8312422,0 20.772271,0 Z M17.8117942,3.94054054 C16.8657465,3.94054054 16.0297992,4.29324324 15.3039523,4.99864865 C14.5781054,5.70405405 14.2151819,6.54324324 14.2151819,7.51621622 C14.2151819,8.47297297 14.5781054,9.31216216 15.3039523,10.0337838 C16.0297992,10.7554054 16.8657465,11.1162162 17.8117942,11.1162162 C18.8067754,11.1162162 19.6590339,10.7554054 20.3685696,10.0337838 C21.0781054,9.31216216 21.4328733,8.47297297 21.4328733,7.51621622 C21.4328733,6.54324324 21.0781054,5.70405405 20.3685696,4.99864865 C19.6590339,4.29324324 18.8067754,3.94054054 17.8117942,3.94054054 Z'
              id='Combined-Shape'
            ></path>
            <path
              d='M17.7139272,43.9540541 C17.7139272,45.3810811 17.8240276,46.7027027 18.0442284,47.9189189 C18.2644291,49.1351351 18.5376412,50.1972973 18.8638645,51.1054054 C19.1900878,52.0135135 19.5244668,52.722973 19.8670013,53.2337838 C20.2095358,53.7445946 20.5112923,54 20.772271,54 C21.0821832,54 21.4451066,53.6594595 21.8610414,52.9783784 C22.2769762,52.2972973 22.6562108,51.372973 22.9987453,50.2054054 C23.3412798,49.0378378 23.5370138,47.7243243 23.5859473,46.2648649 L23.6348808,44.2459459 L17.7139272,38.3594595 L17.7139272,43.9540541 Z'
              id='Path'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
