import { readValueFromStorage, writeValueToStorage } from '@dominos/hooks-and-hocs'
import { LocalStorageKeys } from 'packages/interfaces/state'
import { useCallback, useState } from 'react'

/*
 * NOTE: idToken should be managed with id-token-utils
 * _not_ the useLocalStorage hook.
 */

interface UseLocalStorageProps<T> {
  key: Exclude<LocalStorageKeys, 'idToken'>
  compress?: boolean
  defaultValue?: T
}

const selectedStorage = 'localStorage'

export const useLocalStorage = <T,>({ key, defaultValue, compress = false }: UseLocalStorageProps<T>) => {
  const [storedValue, setStoredValue] = useState(
    readValueFromStorage(key as string, defaultValue, compress, selectedStorage) ?? null,
  )

  const storeValue = (valueToStore: T) => setStoredValue(valueToStore)

  const setValue = useCallback(
    (value: T) => {
      writeValueToStorage(key, value, storedValue, compress, storeValue, selectedStorage)
    },
    [key, setStoredValue],
  )

  const clearValue = useCallback(() => {
    window.localStorage.removeItem(key as string)
    setStoredValue(null)
  }, [key, setStoredValue])

  return {
    storedValue,
    setValue,
    clearValue,
  }
}
