export const getBasketLineDataForProductWithoutChanges = (
  productCode: string | null,
  sizeCode: string | undefined,
): BasketLineData => ({
  productCode: productCode ?? undefined,
  toppings: undefined,
  base: undefined,
  sauce: undefined,
  options: undefined,
  sizeCode: sizeCode ?? undefined,
})
