import * as React from 'react'

const defaultDimensions = {
  width: 20,
  height: 20,
  circleWidth: 14,
  circleOpacity: 0.899999976,
  pathOpacity: 0.7,
}

export const CrossCloseDarkIcon = () => {
  const { width, height, circleWidth, circleOpacity, pathOpacity } = defaultDimensions

  return (
    <svg width={width} height={height} viewBox='0 0 28 28'>
      <g id='UI/Buttons/Controls/Close-Button-/-Dark' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Close-Icon'>
          <circle
            id='Oval'
            fillOpacity={circleOpacity}
            fill='#FFFFFF'
            cx={circleWidth}
            cy={circleWidth}
            r={circleWidth}
          ></circle>
          <path
            d='M19.3786797,8.43933983 C19.9644661,9.02512627 19.9644661,9.97487373 19.3786797,10.5606602 L16.03,13.909 L19.3786797,17.2573593 C19.9644661,17.8431458 19.9644661,18.7928932 19.3786797,19.3786797 C18.7928932,19.9644661 17.8431458,19.9644661 17.2573593,19.3786797 L13.909,16.03 L10.5606602,19.3786797 C9.97487373,19.9644661 9.02512627,19.9644661 8.43933983,19.3786797 C7.85355339,18.7928932 7.85355339,17.8431458 8.43933983,17.2573593 L11.788,13.909 L8.43933983,10.5606602 C7.85355339,9.97487373 7.85355339,9.02512627 8.43933983,8.43933983 C9.02512627,7.85355339 9.97487373,7.85355339 10.5606602,8.43933983 L13.909,11.788 L17.2573593,8.43933983 C17.8431458,7.85355339 18.7928932,7.85355339 19.3786797,8.43933983 Z'
            id='Combined-Shape'
            fillOpacity={pathOpacity}
            fill='#4A4A4A'
          ></path>
        </g>
      </g>
    </svg>
  )
}
