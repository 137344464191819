import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 50,
  height: 45,
}

const TrackerScooterIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 470.9 396.5'>
      <path
        fill={fill}
        d={`M396.9,364c-16.4,0-30-13.3-30-30c0-16.8,13.3-30,30-30c16.8,0,30.1,13.3,30.1,30
C426.9,350.8,413.6,364,396.9,364z M470.9,295.9c-9.8-33.5-40.5-57.6-77.6-57.6c-10.8,0-21,2.1-30.1,5.6c2.4-10.1,3.8-20.6,3.8-31.4
c0-26.6-8-51-21.3-71.3c2.4,0.3,4.9,0.7,7.7,0.7h14.3V63.9h-14.3c-18.2,0-33.2,12.2-37.7,28.6h-45.1c-5.9,0-10.5,4.5-10.5,10.5
c0,5.9,4.5,10.5,10.5,10.5h44.7c0.7,2.8,1.7,5.6,3.1,8v150.6c0,10.8-8.7,19.9-19.6,19.9h-34.6c-54.9,0-52.1-52.8-53.1-80.4H106.2
C69.5,211.7,14.7,258.9,0,320h36.7h7.7h33.2h57.6h33.2h8h128.2h31.1c-1.1,4.5-1.4,9.1-1.4,13.6c0,34.6,27.9,62.9,62.9,62.9
c34.9,0,62.9-27.9,62.9-62.9c0-14.3-4.9-27.3-12.9-37.7H470.9z`}
      />
      <path
        fill={fill}
        d={`M129.9,118.2l-41.6,41.6c-2.3,2.3-5.9,2.3-8.2,0l-37.4-37.4c-2.3-2.3-2.3-6,0-8.2l41.6-41.6L129.9,118.2z
M91.8,110.6c-4.6,4.6-4.6,12.2,0,16.8c4.6,4.6,12.2,4.6,16.8,0c4.6-4.6,4.6-12.2,0-16.8C104,106,96.4,106,91.8,110.6z M59.2,110.1
c-4.6,4.6-4.6,12.2,0,16.8c4.6,4.6,12.2,4.6,16.8,0c4.6-4.6,4.6-12.2,0-16.8C71.4,105.4,63.8,105.4,59.2,110.1z M94.1,64.1
l38.7-38.8c2.3-2.3,5.9-2.3,8.2,0l37.4,37.4c2.3,2.3,2.3,5.9,0,8.2l-38.7,38.8L94.1,64.1z M129.9,57c-4.6,4.6-4.6,12.2,0,16.8
c4.6,4.6,12.2,4.6,16.8,0c4.6-4.6,4.6-12.2,0-16.8C142.1,52.4,134.6,52.4,129.9,57z M20.3,169.2c0,11.9,9.7,21.6,21.5,21.6h148
c11.9,0,21.6-9.7,21.6-21.6V21.6c0-11.9-9.7-21.6-21.6-21.6h-148C29.9,0,20.3,9.7,20.3,21.6V169.2z`}
      />
      <path
        fill={fill}
        d={`M133.8,341.3c-4.9,10.1-15,17.1-27.3,17.1c-12.2,0-22.4-7-27.2-17.1H45.1c5.9,28.6,31.1,50,61.5,50
s55.5-21.7,61.5-50H133.8z`}
      />
    </svg>
  )
}

export { TrackerScooterIcon }
