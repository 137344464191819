import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useValidation } from '@dominos/business/functions'
import {
  AddressFieldName,
  AddressFieldsDependency,
  AddressInputFieldSettings,
} from '@dominos/interfaces/delivery-address'
import { TFunction } from 'i18next'

export type AddressInputField = Omit<AddressInputFieldConfiguration, 'validationRules'>
export type AddressFieldSize = 'small' | 'medium' | 'large'
interface AddressInputFieldConfiguration extends AddressInputFieldSettings {
  addressFieldSize: AddressFieldSize
  value: string | undefined
  placeHolder: string
  isValid?: boolean
  currentValidationRules?: ValidationFunction<string>[]
}

export const useAddressFields = () => {
  const { t } = useTranslation('delivery-address')
  const [addressFieldsDisplay, setAddressFieldsDisplay] = useState<AddressInputFieldConfiguration[] | null>(null)
  const [addressFieldsDependencyGroup, setAddressFieldsDependencyGroup] = useState<AddressFieldsDependency[]>([])
  const { isRequired } = useValidation()

  const setAddressInputFields = (
    addressInputFields: AddressInputFieldSettings[],
    searchAddress?: SearchAddress,
    addressFieldDependencies?: AddressFieldsDependency[],
  ) => {
    setAddressFieldsDependencyGroup(addressFieldDependencies ?? [])
    setAddressFieldsDisplay(
      addressInputFields ? getAddressInputFieldsToDisplay(addressInputFields, t, searchAddress) : null,
    )
  }

  const addressInputFields = useMemo(() => addressFieldsDisplay as AddressInputField[], [addressFieldsDisplay])

  const updateAddressField = (updatedFields: Partial<TAddress>) => {
    if (!addressFieldsDisplay) return

    const fieldNames = getAddressFieldNames(updatedFields)
    let updatedDisplayInputFields = getFieldsWithUpdatedValues(updatedFields, addressFieldsDisplay)
    if (addressFieldsDependencyGroup && addressFieldsDisplay)
      updatedDisplayInputFields = updateFieldDependencyRequirements(
        fieldNames,
        addressFieldsDisplay,
        addressFieldsDependencyGroup,
        isRequired,
      )

    setAddressFieldsDisplay(updatedDisplayInputFields)
  }

  return {
    setAddressInputFields,
    addressInputFields,
    updateAddressField,
  }
}
const hasValidStatus = (
  value: string | undefined,
  validationRules: ValidationFunction<string>[] | undefined,
): boolean => {
  if (!validationRules || validationRules.length == 0) return true

  return validationRules.every((validationFunction) => validationFunction(value) == null)
}

const getAddressInputFieldsToDisplay = (
  addressInputFieldsSettings: AddressInputFieldSettings[],
  translateFunction: TFunction,
  searchAddress?: SearchAddress,
): AddressInputFieldConfiguration[] =>
  addressInputFieldsSettings
    .sort((n1, n2) => n1.displayOrder - n2.displayOrder)
    .map((addressInputField) => getAddressFieldDisplay(addressInputField, translateFunction, searchAddress))
    .filter((addressInputField) => addressInputField) as AddressInputFieldConfiguration[]

const getAddressFieldDisplay = (
  addressInputField: AddressInputFieldSettings,
  t: TFunction,
  searchAddress?: SearchAddress,
): AddressInputFieldConfiguration | undefined => {
  switch (addressInputField.addressFieldName) {
    case 'unitNo':
      return {
        ...addressInputField,
        addressFieldSize: 'small',
        value: searchAddress?.rawAddress?.unitNo || undefined,
        placeHolder: t('Unit Number'),
        currentValidationRules: addressInputField.validationRules,
        isValid: hasValidStatus(searchAddress?.rawAddress?.unitNo || undefined, addressInputField.validationRules),
      }
    case 'floorNo':
      return {
        ...addressInputField,
        addressFieldSize: 'small',
        value: searchAddress?.rawAddress?.floorNo || undefined,
        placeHolder: t('Floor Number'),
        currentValidationRules: addressInputField.validationRules,
        isValid: hasValidStatus(searchAddress?.rawAddress?.floorNo || undefined, addressInputField.validationRules),
      }
    case 'buildingName':
      return {
        ...addressInputField,
        addressFieldSize: 'large',
        value: searchAddress?.rawAddress?.buildingName || undefined,
        placeHolder: t('BuildingNameFieldPlaceholder'),
        currentValidationRules: addressInputField.validationRules,
        isValid: hasValidStatus(
          searchAddress?.rawAddress?.buildingName || undefined,
          addressInputField.validationRules,
        ),
      }
    case 'streetNo':
      return {
        ...addressInputField,
        addressFieldSize: 'small',
        value: searchAddress?.rawAddress?.streetNo || undefined,
        placeHolder: t('Street Number'),
        currentValidationRules: addressInputField.validationRules,
        isValid: hasValidStatus(searchAddress?.rawAddress?.streetNo || undefined, addressInputField.validationRules),
      }
    case 'street':
      return {
        ...addressInputField,
        addressFieldSize: 'large',
        value: searchAddress?.rawAddress?.street || undefined,
        placeHolder: t('Street'),
        currentValidationRules: addressInputField.validationRules,
        isValid: hasValidStatus(searchAddress?.rawAddress?.street || undefined, addressInputField.validationRules),
      }
    case 'suburb':
      return {
        ...addressInputField,
        addressFieldSize: 'medium',
        value: searchAddress?.rawAddress?.suburb || undefined,
        placeHolder: t('Suburb'),
        currentValidationRules: addressInputField.validationRules,
        isValid: hasValidStatus(searchAddress?.rawAddress?.suburb || undefined, addressInputField.validationRules),
      }
    case 'state':
      return {
        ...addressInputField,
        addressFieldSize: 'medium',
        value: searchAddress?.rawAddress?.state || undefined,
        placeHolder: t('State'),
        currentValidationRules: addressInputField.validationRules,
        isValid: hasValidStatus(searchAddress?.rawAddress?.state || undefined, addressInputField.validationRules),
      }
    case 'postCode':
      return {
        ...addressInputField,
        addressFieldSize: 'medium',
        value: searchAddress?.rawAddress?.postCode || undefined,
        placeHolder: t('Post Code', { defaultValue: 'Post Code' }),
        currentValidationRules: addressInputField.validationRules,
        isValid: hasValidStatus(searchAddress?.rawAddress?.postCode || undefined, addressInputField.validationRules),
      }
    default:
      return undefined
  }
}

const getValidationRulesWhenDependencyFieldChange = (
  dependencyFieldHasValue: boolean,
  addressFieldDependency: AddressInputFieldConfiguration,
  isRequired: ValidationFunction<string>,
): ValidationFunction<string>[] | undefined => {
  if (!dependencyFieldHasValue)
    return addressFieldDependency.validationRules ? [...addressFieldDependency.validationRules] : undefined

  if (dependencyFieldHasValue && !addressFieldDependency.validationRules) return [isRequired]

  if (dependencyFieldHasValue && addressFieldDependency.validationRules)
    return [...addressFieldDependency.validationRules, isRequired]

  return addressFieldDependency.validationRules ? [...addressFieldDependency.validationRules] : undefined
}

const getFieldsWithUpdatedValues = (
  updatedFields: Partial<TAddress>,
  existingAddressInputFields: AddressInputFieldConfiguration[],
): AddressInputFieldConfiguration[] => {
  const updatedDisplayFields: AddressInputFieldConfiguration[] = []
  // eslint-disable-next-line guard-for-in
  for (const fieldName in updatedFields) {
    const field = updatedFields[fieldName as AddressFieldName]
    const matchingAddressFieldsDisplay = getAddressInputFieldDisplay(
      fieldName as AddressFieldName,
      existingAddressInputFields,
    )
    if (!matchingAddressFieldsDisplay || !field) continue

    matchingAddressFieldsDisplay.value = field.value ?? ''
    matchingAddressFieldsDisplay.isValid = field.isValid
    updatedDisplayFields.push(matchingAddressFieldsDisplay)
  }

  return updatedDisplayFields
}

const updateFieldDependencyRequirements = (
  updatedFieldNames: AddressFieldName[],
  existingAddressInputFields: AddressInputFieldConfiguration[],
  addressFieldDependenciesGroups: AddressFieldsDependency[],
  isRequired: ValidationFunction<string>,
): AddressInputFieldConfiguration[] => {
  const fieldsDependencyGroupsRequireUpdating = addressFieldDependenciesGroups.filter((addressFieldDependency) =>
    addressFieldDependency.some((addressFieldName) =>
      updatedFieldNames.some((updatedAddressFieldName) => updatedAddressFieldName == addressFieldName),
    ),
  )

  if (!fieldsDependencyGroupsRequireUpdating) return []

  const updatedAddressInputFields: AddressInputFieldConfiguration[] = []
  for (const fieldsDependency of fieldsDependencyGroupsRequireUpdating) {
    const addressFieldsDependencies = fieldsDependency.map((fieldName) =>
      getAddressInputFieldDisplay(fieldName as AddressFieldName, existingAddressInputFields),
    )

    const dependencyFieldContainsValue = addressFieldsDependencies.some(
      (addressFieldsDependency) => addressFieldsDependency?.value ?? false,
    )

    for (const addressField of addressFieldsDependencies) {
      if (!addressField) continue
      addressField.currentValidationRules = getValidationRulesWhenDependencyFieldChange(
        dependencyFieldContainsValue,
        addressField,
        isRequired,
      )
      updatedAddressInputFields.push(addressField)
    }
  }

  return mergeUpdatedAddressFields(existingAddressInputFields, updatedAddressInputFields)
}

const getAddressInputFieldDisplay = (
  deliveryAddressFieldName: AddressFieldName,
  addressInputFieldConfigurations: AddressInputFieldConfiguration[],
): AddressInputFieldConfiguration | undefined => {
  if (!addressInputFieldConfigurations) return undefined

  for (const addressInoutFieldDisplay of addressInputFieldConfigurations) {
    const deliveryAddressField = addressInoutFieldDisplay?.addressFieldName
    if (deliveryAddressField && deliveryAddressField == deliveryAddressFieldName) return addressInoutFieldDisplay
  }

  return undefined
}

const mergeUpdatedAddressFields = (
  existingAddressFieldsDisplay: AddressInputFieldConfiguration[],
  updatedDisplayFields: AddressInputFieldConfiguration[],
) => {
  const existingAddressFieldsExcludingUpdated = existingAddressFieldsDisplay.filter((addressFieldDisplay) =>
    updatedDisplayFields.every(
      (updatedDisplayField) => addressFieldDisplay.addressFieldName != updatedDisplayField.addressFieldName,
    ),
  )

  return [...existingAddressFieldsExcludingUpdated, ...updatedDisplayFields].sort(
    (n1, n2) => n1.displayOrder - n2.displayOrder,
  )
}

const getAddressFieldNames = (updatedFields: Partial<TAddress>) => {
  const addressFieldsName: AddressFieldName[] = []
  // eslint-disable-next-line guard-for-in
  for (const addressField in updatedFields) {
    addressFieldsName.push(addressField as AddressFieldName)
  }

  return addressFieldsName
}
