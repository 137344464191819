import { ErrorDefinitions } from '@dominos/interfaces'

export const createAccountErrors: ErrorDefinitions = {
  CREATE_ACCOUNT_FAILED: {
    translation: {
      key: 'CreateAccountByEmailAccountExistsError',
      options: 'Account may already exist. Please try to log in, or change the email address and try again',
    },
    display: 'errorText',
  },
  BAD_USER_INPUT: {
    translation: {
      key: 'CreateAccountBackEndValidationErrorText',
      options: 'You have entered incorrect information. Please change your details and try again',
    },
    display: 'alert',
  },
  UNABLE_TO_RESOLVE: {
    translation: {
      key: 'CreateAccountGenericErrorText',
      options: 'Something has gone wrong. Please try again.',
    },
    display: 'alert',
  },
}
