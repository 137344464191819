/* eslint-disable sonarjs/prefer-single-boolean-return */
import { rootActions } from '@dominos/business'
import { usePaymentMethod, useServiceMethod, useServiceMethodSubType, useTipAmount } from '@dominos/hooks-and-hocs'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

const offlinePayments: BffContext.PaymentMethods[] = ['Cash', 'MobileEftpos', 'MobileCard', 'CreditCardLater']

interface TipTheDriver {
  tipAmount: number
  shouldHideTipTheDriver: boolean
  setTipValue: (tipValue: number) => void
  settings?: {
    presets: Bff.Stores.TipTheDriverSetting['presets']
    minimumTip: Bff.Stores.TipTheDriverSetting['minimumTip']
    maximumTip: Bff.Stores.TipTheDriverSetting['maximumTip']
  } | null
}

const useTipTheDriver: (store?: Bff.Stores.Store) => TipTheDriver = (store?: Bff.Stores.Store) => {
  const dispatch = useDispatch()
  const tipAmount = useTipAmount()
  const serviceMethod = useServiceMethod()
  const serviceMethodSubType = useServiceMethodSubType()
  const paymentMethod = usePaymentMethod()
  const tipTheDriver = (store?.features ?? []).find(({ featureName }) => featureName === 'TipTheDriver') ?? {
    featureName: 'TipTheDriver',
    isEnabled: false,
    settings: null,
  }

  const setTip = (value: number = 0) => dispatch(rootActions.setTipAmount(value))

  const shouldHideTipTheDriver = useMemo(() => {
    if (!tipTheDriver || !tipTheDriver?.isEnabled) {
      return true
    }

    if (serviceMethod !== 'Delivery' || serviceMethodSubType === 'RobotDelivery') {
      return true
    }

    if (paymentMethod && offlinePayments.includes(paymentMethod)) {
      return true
    }

    return false
  }, [serviceMethod, paymentMethod, tipTheDriver])

  useEffect(() => {
    if (shouldHideTipTheDriver) {
      setTip(0)
    }
  }, [shouldHideTipTheDriver])

  return {
    tipAmount,
    shouldHideTipTheDriver,
    settings: tipTheDriver.settings,
    setTipValue: setTip,
  }
}

export { useTipTheDriver }
