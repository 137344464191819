import { useEffect, useState } from 'react'
import { SdkNames } from './use-sdk-available'
import { Config, getCountryConfig, loadScript, LoadScriptProps } from '@dominos/business/functions'

interface SdkOptions<Sdk> {
  loopMaxTime?: number
  loopIncrement?: number
  onSdkLoaded?: (sdk: NonNullable<Sdk>) => void
  onSdkReady?: (sdk: NonNullable<Sdk>) => void
  onSdkTimeout?: () => void
  loadSdk: (applicationConfig: Config, loadScript: (props: LoadScriptProps) => void) => void
}
interface Window {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [arg: string]: any
}

export const useLazyLoadSdk = <T extends SdkNames>(
  sdkName: T,
  { loopMaxTime = 10000, loopIncrement = 500, onSdkReady, onSdkTimeout, onSdkLoaded, loadSdk }: SdkOptions<Window[T]>,
) => {
  const applicationConfig = getCountryConfig()
  const [lazyLoading, setLazyLoading] = useState(false)
  const [sdkSetup, setSdkSetup] = useState(false)

  const checkSdk = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any)[sdkName] instanceof HTMLScriptElement) {
      return false
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any)[sdkName] !== undefined
  }

  useEffect(() => {
    let handler: NodeJS.Timeout
    let loopTimer = 0

    if (lazyLoading) {
      if (!checkSdk()) {
        loadSdk(applicationConfig, loadScript)
      }

      const loop = () => {
        loopTimer += loopIncrement

        if (checkSdk()) {
          if (onSdkLoaded && !sdkSetup) {
            setSdkSetup(true)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSdkLoaded((window as any)[sdkName]!)
          } else {
            clearInterval(handler)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSdkReady && onSdkReady((window as any)[sdkName]!)
            setLazyLoading(false)
          }
        } else if (loopTimer === loopMaxTime) {
          clearInterval(handler)
          onSdkTimeout && onSdkTimeout()
          setLazyLoading(false)
        }
      }

      handler = setInterval(loop, loopIncrement)
    }

    return () => clearInterval(handler)
  }, [lazyLoading, sdkSetup])

  const lazyLoadSdk = () => {
    setLazyLoading(true)
  }

  return {
    lazyLoadSdk,
    isLazyLoading: lazyLoading,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sdk: (window as any)[sdkName],
  }
}
