import { Spinner, TextAreaField } from '@dominos/components'
import React, { FunctionComponent, useState } from 'react'
import css from './fancy-paragraph-text-field.less'

export const FancyParagraphTextField: FunctionComponent<FancyParagraphTextFieldProps> = (props) => {
  const [focus, setFocus] = useState(false)
  const [hasValue, setHasValue] = useState(!!props.prefix || !!props.value)

  const onFocus = () => {
    setFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
  }
  const onChange = (value: string) => {
    setHasValue(!!props.prefix || !!value)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const styleToggle = (defaultStyle: string, focusStyle: string, hasValueStyle?: string): string => {
    if (focus) {
      return focusStyle
    }

    if (hasValue && hasValueStyle) {
      return hasValueStyle
    }

    return defaultStyle
  }

  return (
    <div data-testid={props.testID} className={props.disabled ? css.wrapperDisabled : css.wrapper} style={props.style}>
      <div className={css.textareaContainer} style={props.textFieldStyle ?? {}}>
        <label className={css.prefix}>{props.prefix}</label>
        <label
          className={styleToggle(css.placeholder, css.placeholderHasFocus)}
          data-testid={`${props.testID}.placeholder`}
        >
          {`${!hasValue ? props.placeholder : ''}`}
        </label>
        <TextAreaField
          testID={`${props.testID}.fancy-paragraph-text-field`}
          accessibilityLabel={props.accessibilityLabel}
          className={styleToggle(css.textarea, css.textareaHasFocus)}
          maxLength={props.maxLength ? props.maxLength : undefined}
          disabled={props.disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          tabIndex={props.tabIndex}
          value={props.value}
          onChange={onChange}
          rows={props.rows}
        />
        {props.isLoading && <Spinner testID={`${props.testID}.loading`} />}
      </div>
      <div className={styleToggle(css.underline, css.underlineHasFocus)} />
      {props.children}
      <div>{props.info}</div>
    </div>
  )
}
