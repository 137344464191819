import { NotificationCard } from '@dominos/components/cards/notification-card'
import { useFeatures } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'

export type DeliverySearchInformationContainerProps = Partial<BaseProps>

export const DeliverySearchInformationContainer = ({
  testID = 'DeliverySearchInformationContainer',
}: DeliverySearchInformationContainerProps) => {
  const { t } = useTranslation('delivery-address')
  const [showDeliverySearchInformation] = useFeatures('AutoCompleteDeliverySearchInformationPanel')

  if (!showDeliverySearchInformation) return <></>

  return (
    <NotificationCard
      testID={testID}
      heading={t('DeliverySearchInformationHeading')}
      message={t('DeliverySearchInformationMessage')}
      icon={{ name: 'searchResultIcon', width: 45, height: 45 }}
    />
  )
}
