import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 75,
  height: 35,
}

export const LoyaltyIconFR = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 35 35'>
      <g data-testid='Loyalty.Icon.FR' fill='none' fillRule='evenodd'>
        <g fill='#0073AB' fillRule='nonzero'>
          <g>
            <g>
              <path
                d='M30.747 15.857l-2.424-.352c-.17-.025-.316-.132-.393-.285l-1.083-2.197c-.192-.387-.744-.387-.936 0l-1.084 2.197c-.076.153-.222.26-.392.285l-2.424.352c-.428.062-.598.588-.289.89l1.754 1.709c.123.12.179.292.15.461l-.414 2.414c-.073.426.374.75.756.55l2.168-1.14c.151-.08.333-.08.485 0l2.167 1.14c.383.2.83-.124.757-.55l-.414-2.414c-.03-.169.027-.341.15-.461l1.753-1.71c.31-.301.14-.827-.287-.889z'
                transform='translate(-797.000000, -170.000000) translate(789.000000, 161.000000) translate(8.000000, 9.000000)'
              />
              <path
                d='M17.487.007C7.835.007.01 7.83.01 17.484S7.835 34.96 17.487 34.96c9.653 0 17.478-7.824 17.478-17.477S27.14.007 17.487.007zm-4.169 13.11c0 .4-.21.63-.63.63h-5.29c-.398 0-.629.21-.629.63v.672c0 .398.231.63.63.63h3.463c.42 0 .63.21.63.629v2.35c0 .4-.21.63-.63.63H7.4c-.399 0-.63.21-.63.63V24.2c0 .399-.21.63-.63.63H3.664c-.4 0-.63-.231-.63-.63V10.767c0-.42.23-.63.63-.63h9.026c.42 0 .63.21.63.63v2.35zm4.828 11.084c0 .399-.21.63-.63.63h-2.309c-.398 0-.63-.231-.63-.63V10.767c0-.42.232-.63.63-.63h2.31c.42 0 .63.21.63.63V24.2zm9.188.63h-6.459c-.398 0-.63-.231-.63-.63V10.788c0-.42.21-.63.63-.65h6.46c3.946 0 5.248 1.595 5.248 5.037v4.597c0 3.442-1.302 5.059-5.249 5.059z'
                transform='translate(-797.000000, -170.000000) translate(789.000000, 161.000000) translate(8.000000, 9.000000)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
