const magic = {
  iconSize: 34,
  limitPollTime: 1000,
  webviewPadding: 16,
  webviewPolling: 1500,
  min1: 60000,
  min3: 180000,
  min9: 540000,
  min10: 600000,
}

export const trackerSteps: TrackerStep[] = [
  {
    id: 'PlaceOrder',
    text: 'Your Order has been placed',
    duration: magic.min1,
    started: false,
    finished: false,
    startOn: ['New', 'Pending', 'Unknown', 'SentToStore'],
    endOn: ['Complete', 'Cooking', 'Leaving', 'Making', 'Ready', 'Timed'],
    methods: ['Delivery', 'Pickup'],
    icon: 'trackerReceipt',
  },
  {
    id: 'Making',
    text: 'Your Order is being made',
    duration: magic.min3,
    started: false,
    finished: false,
    startOn: ['Making'],
    endOn: ['Complete', 'Cooking', 'Leaving', 'Ready'],
    methods: ['Delivery', 'Pickup'],
    icon: 'trackerChef',
  },
  {
    id: 'Cooking',
    text: 'Your Order is in the oven',
    duration: magic.min9,
    started: false,
    finished: false,
    startOn: ['Cooking'],
    endOn: ['Complete', 'Leaving', 'Ready'],
    methods: ['Delivery', 'Pickup'],
    icon: 'trackerCooking',
  },
  {
    id: 'Ready',
    text: 'Your Order is Ready in Store',
    duration: magic.min1,
    started: false,
    finished: false,
    startOn: ['Ready'],
    endOn: ['Complete', 'Leaving'],
    methods: ['Delivery', 'Pickup'],
    icon: 'trackerStore',
  },
  {
    id: 'Delivering',
    text: 'Your Order is being Delivered',
    duration: magic.min10,
    started: false,
    finished: false,
    startOn: ['Leaving'],
    endOn: ['Complete'],
    methods: ['Delivery'],
    icon: 'trackerScooter',
  },
  {
    id: 'Rate',
    text: 'Rate Your Order',
    duration: magic.min1,
    started: false,
    finished: false,
    startOn: ['Complete'],
    methods: ['Delivery', 'Pickup'],
    icon: 'trackerStar',
  },
]
