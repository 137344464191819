import { ErrorDefinitions } from '@dominos/interfaces'

export const MySavedPaymentErrors: ErrorDefinitions = {
  UNABLE_TO_RESOLVE: {
    translation: {
      key: 'PaymentMethodNotRemoved',
      options: {},
    },
    display: 'alert',
  },
  INTERNAL_SERVER_ERROR: {
    translation: {
      key: 'PaymentMethodNotRemoved',
      options: {},
    },
    display: 'alert',
  },
}
