export const createStoresAlternativeDistanceInput = (
  storeNo: number,
  address: CustomerAddressRequest,
  countryCode?: BffContext.Countries,
): MutationWrapper<Bff.Stores.StoresAlternativeDistanceRequest> => ({
  input: {
    countryCode: countryCode!,
    storeNo: storeNo,
    customerAddress: {
      streetName: address.streetName!,
      streetNumber: address.streetNo!,
      suburb: address.suburb!,
      postalCode: address.postCode!,
      state: address.state!,
      unitNumber: address.unitNo!,
      buildingName: address.buildingName!,
    },
  },
})
