import { debounce } from 'lodash'
import React, { useMemo, useState } from 'react'

import { FancyTextField } from '..'

import css from './fancy-dropdown-field.less'

const DEFAULT_DEBOUNCE_DELAY = 300
const DEFAULT_FETCH_DATA_MIN_LENGTH = 3
const DEFAULT_MAX_ITEM_LENGTH = 30

export const FancyDropdownField = <K,>(props: FancyDropdownFieldProps<K>) => {
  const [value, setValue] = useState<string | undefined>()
  const [valueSelected, setValueSelected] = useState(false)
  const [dropdownItems, setDropdownValues] = useState<KeyValuePair<K>[] | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const debounceDelay = props.debounceDelay || DEFAULT_DEBOUNCE_DELAY
  const maxItemLength = props.maxItemLength || DEFAULT_MAX_ITEM_LENGTH

  const debouncedFetchData = useMemo(
    () =>
      debounce((newValue: string) => {
        setIsLoading(true)
        props.fetchData(newValue, setDropdownValuesCallback)
      }, debounceDelay),
    [props.fetchData],
  )
  const fetchDataMinLength = props.fetchDataMinLength || DEFAULT_FETCH_DATA_MIN_LENGTH

  const setDropdownValuesCallback = (data?: KeyValuePair<K>[]) => {
    setDropdownValues(data && data.slice(0, maxItemLength))
    setIsLoading(false)
  }

  const onChange = (newValue: string) => {
    setValueSelected(false)
    setValue(newValue)
    if (props.onChange) {
      props.onChange(newValue)
    }

    if (newValue.length >= fetchDataMinLength) {
      debouncedFetchData(newValue)
    } else {
      debouncedFetchData.cancel()
      setDropdownValues(undefined)
    }
  }

  const onItemClicked = (dropdownValue: KeyValuePair<K>) => () => {
    debouncedFetchData.cancel()
    setValueSelected(true)
    setValue(dropdownValue.value)
    setDropdownValues(undefined)

    if (props.onItemSelect) {
      props.onItemSelect(dropdownValue)
    }
  }

  return (
    <FancyTextField
      disabled={props.disabled}
      errorMessage={!valueSelected && value !== undefined ? props.errorMessage || ' ' : undefined}
      isLoading={isLoading}
      onChange={onChange}
      placeholder={props.placeholder}
      prefix={props.prefix}
      style={props.style}
      tabIndex={props.tabIndex}
      testID={props.testID}
      value={props.value === undefined ? value : props.value}
      info={props.info}
    >
      {dropdownItems && !valueSelected && value && value.length >= fetchDataMinLength && (
        <div>
          {dropdownItems.map((dropdownItem, index) => (
            <div
              key={index}
              data-testid={`${props.testID}.fancy-dropdown-field.item.${dropdownItem.value}`}
              role='dropdown item'
              className={css.item}
              onClick={onItemClicked(dropdownItem)}
            >
              <div className={css.itemText}>{dropdownItem.value}</div>
            </div>
          ))}
        </div>
      )}
    </FancyTextField>
  )
}
