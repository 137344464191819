import React, { useContext } from 'react'
import { Config, getApplicationEnvironmentConfig, getCountryConfig } from '@dominos/business/functions'
import { GeoService } from '@dominos/business/services'
import { useDevToggles } from '@dominos/hooks-and-hocs'
import { ApplicationEnvironment } from '@dominos/interfaces'
import { init as i18NextInit } from '@dominos/plugins'

interface ConfigContextProps {
  applicationConfig: Config
  applicationEnv: ApplicationEnvironment
  hasLocationPermission: boolean
}

const ConfigContext = React.createContext<ConfigContextProps>({
  applicationConfig: {} as Config,
  applicationEnv: {} as ApplicationEnvironment,
  hasLocationPermission: false,
})

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const { isEnabled } = useDevToggles()
  const enforceKioskMTLSEnabled = isEnabled['enforce-kiosk-mtls']

  const applicationConfig = getCountryConfig()
  window.__olo_web_app_version__ = applicationConfig.BUILD_BUILDNUMBER

  const { defaultLanguage, availableLanguages } = i18NextInit()
  const applicationEnv = getApplicationEnvironmentConfig(
    applicationConfig,
    defaultLanguage,
    availableLanguages,
    enforceKioskMTLSEnabled,
  )

  document.documentElement.lang = defaultLanguage

  let hasLocationPermission: boolean | undefined = false
  GeoService.checkLocationPermission().then((permitted: boolean | undefined = undefined) => {
    hasLocationPermission = permitted
  })

  return (
    <ConfigContext.Provider
      value={{
        applicationConfig,
        applicationEnv,
        hasLocationPermission,
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfigContext = () => useContext(ConfigContext)
