import { useQuery } from '@apollo/client'
import { orderStatusQuery } from '@dominos/business/queries'

export const useOrderStatusToggles = (orderId: string, statusConditions: BffContext.Orders.Status[][]) => {
  const { data } = useQuery(orderStatusQuery, {
    variables: {
      id: orderId,
    },
  })

  return statusConditions.map((statusCondition) => statusCondition.includes(data?.order?.status?.status))
}
