import { isProductMenuItemNew } from '@dominos/components'
import { ApplicationConfiguration, SwapType } from '@dominos/interfaces'
import { IEventTelemetry } from '@microsoft/applicationinsights-common'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { useSecurityContext } from './use-security-context'

export type SecurityEvents = LogSecurityEventFunctionOverload
export type SecurityEventNames = keyof SecurityEvents
export type SecuritySender = <K extends SecurityEventNames>(
  name: K,
  data: SecurityEvents[K],
  forceFlush?: boolean,
) => Promise<{}>

/* eslint-disable @typescript-eslint/no-explicit-any */
export const security = (
  applicationInsights: ApplicationInsights | null,
  common?: SecurityCommonParams | Promise<SecurityCommonParams | void>,
) => {
  const sendSecurity: SecuritySender = async <K extends SecurityEventNames>(
    name: K,
    data: SecurityEvents[K],
    forceFlush: boolean = false,
  ) => {
    let commonParams: SecurityCommonParams | undefined | void

    if (common) {
      if (common instanceof Promise) {
        commonParams = await common
      } else {
        commonParams = common
      }
    }

    const eventName = `OLO.WEB.${name}`
    const payload: IEventTelemetry = {
      name: eventName,
      properties: {
        ...commonParams,
        ...data,
      },
    }
    if (!applicationInsights) {
      return {}
    }

    applicationInsights.trackEvent(payload)
    if (forceFlush) {
      applicationInsights.flush(false)
    }

    return data
  }

  return {
    sendSecurity,
  }
}

export const useSecurity = () => {
  const settings: ApplicationConfiguration = useSelector((store: RootReducer) => store.applicationReducer)
  const { client } = useSecurityContext()
  const commonParams = getSecurityCommonParams(settings)

  return security(client, commonParams)
}

export const getSecurityCommonParams = async (settings: {
  countryCode?: BffContext.Countries
  version: Promise<string>
  sessionID?: string
}): Promise<SecurityCommonParams | void> => {
  if (!settings) {
    return Promise.resolve()
  }

  return {
    AppVersion: settings ? await settings.version : await Promise.resolve(''),
    CountryCode: settings ? settings.countryCode : '',
    DeviceType: navigator.appVersion, // 5.0 (Macintosh; Intel Mac OS X 10_15_3) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.5 Safari/605.1.15
    // DeviceName: '',
    DeviceId: window.nativeAppStartupData?.analytics.deviceId || settings.sessionID,
    DeviceTimezone: DateTime.local().zoneName,
    // DeviceCarrier: '',
    UserAgent: navigator.userAgent,
    // DeviceIPAddress: '',
  }
}

export const stripWorthy = (key: string, obj: Dict<unknown>) =>
  obj.hasOwnProperty(key) && (obj[key] === undefined || obj[key] === null)

export const stripEmpty = (obj: Dict<unknown>) => {
  for (const key in obj) {
    if (stripWorthy(key, obj)) {
      delete obj[key]
    }
  }

  return obj
}

export const toProduct = (
  line: BasketLine | Bff.Orders.OrderDetails.Basket.ProductLine | ProductMenuItem | MenuItemDependents,
  couponCode?: string,
  swapType: SwapType = null,
): SecurityProductParams => {
  const isMenuItem =
    line.type === 'Product' || line.type === 'Variety' || line.type === 'Portion' || line.type === 'Voucher'
  const base = {
    item_id: isMenuItem && !isProductMenuItemNew(line) ? line.code : line.productCode,
    item_name: isMenuItem && isProductMenuItemNew(line) ? undefined : line.media?.name,
    item_category: line.type,
    currency: 'AUD',
    coupon: couponCode,
    swap_type: swapType,
  }

  return isMenuItem
    ? stripEmpty(base)
    : stripEmpty({
        ...base,
        price: line.totalPrice,
        index: line.itemNo,
        quantity: line.quantity,
      })
}

export const toProducts = (
  lines:
    | Bff.Orders.OrderDetails.Basket.ProductLine[]
    | ProductMenuItem[]
    | BasketLineUnionDependancy[]
    | MenuItemDependents[],
  couponCode?: string,
  swapType: SwapType = null,
): SecurityProductParams[] => {
  const items = []
  for (const line of lines) {
    if (line.type !== 'BasketCoupon') {
      items.push(toProduct(line, couponCode, swapType))
    } else {
      line.items.forEach((item) => {
        toProducts(item.lines, line.couponNo.toString(), swapType).forEach((sub) => {
          items.push(sub)
        })
      })
    }
  }

  return items
}
