import React from 'react'

import css from './card.less'
import classnames from 'classnames'

type CardProps = BaseProps & {
  className?: string
}

const Card = ({ testID, className, children }: React.PropsWithChildren<CardProps>) => (
  <div data-testid={testID} className={classnames(css.card, className)}>
    {children}
  </div>
)

export { Card }
