import { getFormattedPrice } from '@dominos/business/functions/text/text-functions'
import { ActionButton } from '@dominos/components'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLabelText } from './functions'
import css from './order-button.less'
import classnames from 'classnames'

const DEBOUNCE_TIME = 2000

/**
 * Extends the standard `ActionButton` with additional
 * basket `itemCount` count and `price` label.
 */
export const OrderButton = ({
  testID,
  loading,
  disabled,
  itemCount,
  text,
  price,
  onPress,
  fill,
  hideLabel,
}: OrderButtonProps) => {
  const { t } = useTranslation('menu')
  const labelText = getLabelText(itemCount, price, t)
  const shortLabelText = getFormattedPrice(price)
  const labelContainerRef = useRef<HTMLDivElement>(null)
  const shouldDisable = disabled || loading

  const [displayLabel, setDisplayLabel] = useState<string>(labelText)
  const [labelUpdating, setNewLabelUpdating] = useState<boolean>(false)

  useEffect(() => {
    setDisplayLabel(labelText)
    setNewLabelUpdating(true)
  }, [labelText])

  useEffect(() => {
    if (labelUpdating && labelContainerRef.current) {
      const { clientWidth, scrollWidth } = labelContainerRef.current
      setNewLabelUpdating(false)
      if (scrollWidth > clientWidth) {
        setDisplayLabel(shortLabelText)
      }
    }
  }, [labelUpdating, labelContainerRef])

  return (
    <ActionButton
      className={classnames(css.button, fill && css.fill)}
      testID={testID}
      disabled={shouldDisable}
      loading={loading}
      onPress={onPress}
      debounce={DEBOUNCE_TIME}
      textFontWeight={'semibold'}
    >
      <div data-testid={`${testID}.container`} className={css.container} ref={labelContainerRef}>
        {!hideLabel && displayLabel && <div data-testid={`${testID}.label`}>{displayLabel}</div>}
        <div data-testid={`${testID}.text`}>{text}</div>
      </div>
    </ActionButton>
  )
}
