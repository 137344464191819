import React from 'react'
import css from './product-nutritional-line.less'
import { ProductNutritionalLineViewProps } from './product-nutritional-line.interface'

export const ProductNutritionalLineView = ({ viewModel }: ProductNutritionalLineViewProps) => {
  const { keyName, nutritionalName, nutritionalUnit, nutritionalValue } = viewModel

  return (
    <>
      {nutritionalValue && (
        <div className={css.nutritionalLine} data-testid={`nutritional-line-${keyName}`}>
          <div className={css.nutritionalName}>{nutritionalName}</div>
          <div className={css.nutritionalValue}>{nutritionalValue}</div>
          <div className={css.nutritionalUnit}>{nutritionalUnit}</div>
        </div>
      )}
    </>
  )
}
