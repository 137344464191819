import gql from 'graphql-tag'

export const customerMutation = gql`
  mutation customer($input: UpsertCustomer!) {
    customer(input: $input) {
      id
      name
      alternateName
      email
      phoneNumber
      idToken
      shouldDisplayRecaptchaV2
      recaptchaSuccess
      emailSubscriptions {
        edmType
        frequency
      }
      smsSubscriptions {
        smsSubscriptionType
      }
    }
  }
`
