type CookieCategory = StrictlyNecessary | Analytic | Performance | Functional | AdvertAndSocialMedia
type StrictlyNecessary = '1'
type Analytic = '109'
type Performance = '2'
type Functional = '3'
type AdvertAndSocialMedia = '4'

export const processScriptForOneTrust = (
  script: HTMLScriptElement,
  category: CookieCategory,
  ONE_TRUST_KEY: string,
) => {
  const oneTrustKeyProvided = ONE_TRUST_KEY && ONE_TRUST_KEY !== 'null'

  if (oneTrustKeyProvided) {
    script.className = `optanon-category-${category}`
    script.type = 'text/plain'
  } else {
    script.type = 'text/javascript'
  }
}
