import { convertStoresToAddressLines } from '@dominos/business/functions/address'
import { GeoLocationInterface } from '@dominos/interfaces'

const SAVED_STORE_LIMIT = 1
const SAVED_AND_RECENT_STORE_LIMIT = 6

export const convertSuffixToNumber = (value: 'caret' | number | undefined) => (!value || value == 'caret' ? 0 : value)
export const sortAddressLineByLocation = (addressLine: AddressLine[]) =>
  addressLine.sort((a, b) => {
    const convertedA = convertSuffixToNumber(a.suffix)
    const convertedB = convertSuffixToNumber(b.suffix)
    if (convertedA < convertedB) {
      return -1
    } else if (convertedA > convertedB) {
      return 1
    }

    return 0
  })

export const combineCustomerStoresAndRecentStores = (
  customerStores: Bff.Customers.CustomerStore[],
  recentSavedStores: Bff.Stores.Store[],
  location: GeoLocationInterface | undefined,
) => {
  const convertedCustomerStores =
    customerStores.length > 0
      ? convertStoresToAddressLines(
          customerStores.map((customerStore) => customerStore.store),
          'savedIcon',
          location,
          SAVED_STORE_LIMIT,
        )
      : []

  const convertedRecentSavedStores = sortAddressLineByLocation(
    convertStoresToAddressLines(recentSavedStores, 'recentIcon', location),
  )

  return [...convertedCustomerStores, ...convertedRecentSavedStores].splice(0, SAVED_AND_RECENT_STORE_LIMIT)
}
