import gql from 'graphql-tag'

export const socialLoginQuery = gql`
  query socialLogin(
    $provider: SocialLoginProvider!
    $authenticationCode: String
    $providerToken: String
    $orderId: String
    $enableLongLived: Boolean
  ) {
    socialLogin(
      provider: $provider
      authenticationCode: $authenticationCode
      providerToken: $providerToken
      orderId: $orderId
      enableLongLived: $enableLongLived
    ) {
      customerId
      identityProviderId
      idToken
      firstName
      lastName
      email
    }
  }
`
