import React, { useState } from 'react'

import { LinkButton } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

import { useTranslation } from 'react-i18next'
import { DetailRow } from '../detail-row'
import css from './detail-row-list.less'
import { getShowMoreText, shouldDisplayToggleButton } from './functions'

const MAX_VISIBLE_CONTRACTED = 2

export const DetailRowList = ({
  testID,
  label,
  description,
  items,
  price,
  expanded: isExpanded = false,
  noLabel,
}: DetailRowListProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('menu')

  const [expanded, setExpanded] = useState<boolean>(isExpanded)
  if (!items || items.length === 0) {
    return null
  }

  const didToggleChanges = () => {
    setExpanded((prevState) => !prevState)
  }

  return (
    <div data-testid={testID}>
      {!noLabel && <DetailRow testID={`${testID}.header`} label={label} description={description} price={price} />}
      <div className={css.list}>
        {items.slice(0, expanded ? items.length : MAX_VISIBLE_CONTRACTED).map((item, idx, arr) => (
          <RowListItem
            key={item.key || `${item.label}${idx}`}
            testID={`${testID}.item`}
            theme={theme}
            item={item}
            showToggle={shouldDisplayToggleButton(idx, arr.length - 1, items.length, MAX_VISIBLE_CONTRACTED)}
            toggleText={getShowMoreText(idx + 1, items.length, expanded, t)}
            onToggle={didToggleChanges}
          />
        ))}
      </div>
    </div>
  )
}

const RowListItem = ({ testID, item, theme, showToggle, toggleText, onToggle }: RowListItemProps) => (
  <div data-testid={testID}>
    <span data-testid={`${testID}.label`}>{item.label}</span>
    {showToggle && toggleText && (
      <LinkButton
        testID={`${testID}.toggle`}
        text={toggleText}
        weight={'semibold'}
        style={{ paddingLeft: 8, color: theme.colours.actionAlternative }}
        onPress={onToggle}
      />
    )}
  </div>
)
