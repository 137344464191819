import { isProductMenuItemNew } from '@dominos/components'

type Menu = Bff.Menu.old.Menu
type MenuPage = Bff.Menu.old.MenuPage
type MenuSection = Bff.Menu.old.MenuSection

const codeForPasta = 'Menu.Pasta'
const getPageByCode = (menu: Menu, code: string) => (menu.pages || []).find((page) => page.code === code)
const getSectionsByCode = (page: MenuPage | undefined, code: string) => {
  if (!page || !page.sections) {
    return []
  }

  return page.sections.filter((section) => section.code.includes(code))
}
const flattenItemsInSections = (sections: MenuSection[]) =>
  sections.flatMap((section) => section.items).map((x) => !isProductMenuItemNew(x) && x.code)
export const verifyIfProductIsPasta = (menu: Menu, itemCode: string): boolean => {
  const pastaItems = flattenItemsInSections(getSectionsByCode(getPageByCode(menu, codeForPasta), codeForPasta))

  return pastaItems.includes(itemCode)
}
