import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import { BasketContainer } from '@dominos/components'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'
import styles from './place-order-container.less'
import { useFreeOrder } from './use-free-order'

export const PlaceOrderContainer = (_: RouteComponentProps<{}>) => {
  const onPlaceOrderCompleted = () =>
    navigate(isNativeApp() ? NavigationConstants.nativeAppCheckoutProcessing : NavigationConstants.checkoutProcessing)
  const { basketHeaderData: orderData } = useCurrentOrderDetails()
  const { process, isProcessing } = useFreeOrder(onPlaceOrderCompleted, () => {})

  useEffect(() => {
    notifyNativeApp('place-order')
  }, [])

  return (
    <div className={styles.basketContainer}>
      <BasketContainer
        testID={`basket-container`}
        orderData={orderData}
        hideOrderButton
        hideEditButton
        readonly
        loading={isProcessing}
        showSubmitButton
        onSubmit={process}
        breadcrumbFlowDepth={2}
      />
    </div>
  )
}
