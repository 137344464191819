import React from 'react'
import { GoogleMapWrapper } from './providers/google'
import { GeoLocationInterface } from '@dominos/interfaces'

export const MapProvider: React.FC<MapComponentProps & { provider: 'google' | undefined }> = ({
  provider,
  ...props
}) => {
  const getMapComponent = () => {
    switch (provider) {
      case 'google':
        return <GoogleMapWrapper {...props} />
      default:
        throw 'Unknown Map Provider'
    }
  }

  return getMapComponent()
}
export interface MapComponentProps {
  testID: string
  className?: string
  zoom?: number
  styles?: React.CSSProperties
  mapCenterLocation?: GeoLocationInterface
  deliveryMarkerLocation?: GeoLocationInterface | undefined
  stores?: Array<FakeStore> | undefined
  storesToZoomIn?: Array<FakeStore>
  mapMode: BffContext.ServiceMethods
  onMarkerDrop?: (location: GeoLocationInterface) => void

  boundaries?: Array<GeoLocationInterface | undefined>
}

export interface FakeStore {
  name: string
  storeNo: number
  lat: number
  lng: number
}
