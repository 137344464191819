import { getCountryConfig, isNativeApp } from '@dominos/business/functions'
import { NavigationConstants } from '@dominos/navigation'
import { useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'

type EdenredRedirectUriType = 'checkout' | 'saved-payment'

const EDENRED_AUTH_SCOPE: string = 'openid edg-xp-mealdelivery-api offline_access'

export const useEdenredURL = () => {
  const config = getCountryConfig()
  const { i18n } = useTranslation('checkout')
  const { origin } = useLocation()

  const getRedirectUri = (pathType: EdenredRedirectUriType) =>
    pathType === 'saved-payment'
      ? `${origin}${isNativeApp() ? NavigationConstants.nativeAppSavedPayment : NavigationConstants.savedPayment}`
      : `${origin}${isNativeApp() ? NavigationConstants.nativeAppCheckoutPayment : NavigationConstants.checkoutPayment}`

  const getEdenredURL = (orderId: string | undefined, nonce: string | undefined, pathType: EdenredRedirectUriType) => {
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: `${config.EDENRED_CLIENT_ID}`,
      scope: EDENRED_AUTH_SCOPE,
      redirect_uri: getRedirectUri(pathType),
      state: `${orderId}`,
      nonce: `${nonce}`,
      acr_values: `tenant:${config.EDENRED_ACR_VALUES}`,
      ui_locales: `${i18n.language}`,
    })

    return `${config.EDENRED_URL}/connect/authorize?${params.toString()}`
  }

  const isEdenredAuthScope = (scope: string | null) => scope === EDENRED_AUTH_SCOPE

  return {
    getEdenredURL,
    isEdenredAuthScope,
  }
}
