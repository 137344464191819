import { postWebViewMessage, sendTokenToNativeApp, SocialAuthMessage } from '@dominos/business/functions/native-app'
import { NavigationConstants } from '@dominos/navigation'
import { AppleIdAuthSuccessUser } from '@dominos/components/login/apple'

export interface AuthResponse {
  provider: BffContext.SocialLogins.Providers
  providerToken: string
  user?: AppleIdAuthSuccessUser
}

export const AuthMessageHelper = {
  postAuthRequest: (provider: BffContext.SocialLogins.Providers) => {
    postWebViewMessage<SocialAuthMessage>({ provider, route: NavigationConstants.nativeAppLogin })
  },

  postIdToken: (idToken: string) => {
    sendTokenToNativeApp(idToken)
  },

  parseAuthResp: (message: MessageEvent) => {
    try {
      const r = JSON.parse(message.data)
      const authResp = r as AuthResponse
      if (!authResp.provider || !authResp.providerToken) {
        return null
      }

      return authResp
    } catch (e) {
      return null
    }
  },
}
