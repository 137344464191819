import { APP_START_FROM_NATIVE_APP, CheckoutContainer, CheckoutContext } from '@dominos/components'
import { useNativeAppBasketData } from '@dominos/hooks-and-hocs'
import React from 'react'

export const NativeAppCheckoutScene = () => {
  const { initialLoading } = useNativeAppBasketData('checkout')

  // Please make sure Edenred is tested on both
  // iOS and Android apps if any changes.
  return initialLoading ? (
    <></>
  ) : (
    <CheckoutContext.Provider
      value={{
        appStartFrom: APP_START_FROM_NATIVE_APP,
      }}
    >
      <CheckoutContainer testID='native-app-checkout-container' />
    </CheckoutContext.Provider>
  )
}
