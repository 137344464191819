import { Operation } from '@apollo/client'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'

export const AKAMAI_CACHE_STATUS_HEADER = 'akamai-cache-status'

export const akamaiCacheStatusHandler = (
  headerCacheStatus: string,
  operation: Operation,
  securitySender: SecuritySender,
) => {
  const akamaiCacheStatuses = headerCacheStatus.split(',')

  securitySender('Akamai.Cache.Status', {
    header: AKAMAI_CACHE_STATUS_HEADER,
    message: headerCacheStatus,
    child: extractAkamaiCacheStatus(akamaiCacheStatuses, 'child'),
    parent: extractAkamaiCacheStatus(akamaiCacheStatuses, 'parent'),
    graphOperationName: operation.operationName,
    variables: operation.variables,
  })
}

/**
 * Value set by Akamai
 * ref: https://techdocs.akamai.com/property-mgr/docs/return-cache-status
 *
 * @param statuses format: {status} from {type}
 * @param type 'child' or 'parent'
 *
 * @returns string | undefined
 */
const extractAkamaiCacheStatus = (statuses: string[], type: 'child' | 'parent'): string | undefined =>
  statuses
    .find((status) => status.toLocaleLowerCase().trim().endsWith(`from ${type}`))
    ?.replace(`from ${type}`, '')
    .trim()
