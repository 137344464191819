import { useApolloClient } from '@apollo/client'
import { useMenu } from '@dominos/hooks-and-hocs'

export const useApolloCacheManager = () => {
  const client = useApolloClient()
  const { menu, menuLoading, refetchMenu } = useMenu()

  const clearCache = () => {
    client.cache.evict({ fieldName: 'availablePayments' })
    client.cache.evict({ fieldName: 'offers' })
    {
      !!menu && !menuLoading && refetchMenu()
    }
  }

  return { clearCache }
}
