import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { useAllOffers } from '@dominos/hooks-and-hocs/offers'
import { useMemo } from 'react'
import { getAutoPopupOffer } from './get-auto-popup-offer'

const allowedPopupLayouts = ['SingleItem', 'MultiItemGrid', 'MultiItemGridExclusive']

export const useAutoPopupOffer = (locationCode: string = '') => {
  const { offers } = useAllOffers()
  const { offersShown, viewedProductOnMobile } = useCurrentOrderDetails()

  return useMemo(
    () => getAutoPopupOffer(offers, locationCode, offersShown, allowedPopupLayouts, viewedProductOnMobile),
    [offers, locationCode, viewedProductOnMobile],
  )
}
