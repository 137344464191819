import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const EntreesIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='0 0 10.626 0 10.626 5.5184 0 5.5184' />
        <polygon id='path-3' points='-8.8817842e-16 0 17.2730936 0 17.2730936 8.075 -8.8817842e-16 8.075' />
        <polygon id='path-5' points='0 3.55271368e-15 13.8019786 3.55271368e-15 13.8019786 13.1600889 0 13.1600889' />
        <polygon id='path-7' points='0 0 3.59411526 0 3.59411526 3.59373228 0 3.59373228' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-19' transform='translate(-0.000000, -0.000000)'>
          <path
            d='M14.0863,5.5184 L8.6163,0.0484 C7.1093,1.5554 7.0883,3.9724 8.5313,5.5184 L14.0863,5.5184 Z'
            id='Fill-1'
            fill={fill}
          />
          <g id='Group-5' transform='translate(12.603000, 0.000000)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-4' />
            <path
              d='M3.531,5.3364 L3.531,3.1534 C3.531,2.7184 3.884,2.3654 4.319,2.3654 C4.755,2.3654 5.108,2.7184 5.108,3.1534 L5.108,5.4194 L6.915,3.6114 C7.223,3.3034 7.722,3.3034 8.03,3.6114 C8.338,3.9194 8.338,4.4184 8.03,4.7264 L7.238,5.5184 L10.515,5.5184 C10.58,5.2654 10.626,5.0044 10.626,4.7304 C10.626,2.9884 9.214,1.5764 7.473,1.5764 C7.331,1.5764 7.195,1.6004 7.058,1.6184 C6.518,0.6574 5.501,-0.0006 4.319,-0.0006 C3.138,-0.0006 2.121,0.6574 1.581,1.6184 C1.443,1.6004 1.308,1.5764 1.166,1.5764 C0.753,1.5764 0.361,1.6614 0,1.8054 L2.703,4.5084 L3.531,5.3364 Z'
              id='Fill-3'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <g id='Group-8' transform='translate(6.708906, 7.095100)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-7' />
            <path
              d='M16.5202936,0 L0.753293604,0 C0.309293604,0 -0.0377063958,0.384 0.00329360422,0.826 C0.0472936042,1.289 0.114293604,1.738 0.196293604,2.177 C0.531293604,2.072 0.879293604,1.988 1.2572936,1.949 C1.5312936,1.921 1.8052936,1.908 2.0772936,1.908 C3.0412936,1.908 3.9352936,2.073 4.6062936,2.216 C6.7942936,2.685 8.7142936,3.549 10.3122936,4.785 C11.1272936,5.415 11.7752936,6.039 12.2902936,6.693 C12.6392936,7.134 12.9052936,7.6 13.1332936,8.075 C15.6712936,6.907 16.9572936,4.138 17.2702936,0.826 C17.3112936,0.384 16.9632936,0 16.5202936,0'
              id='Fill-6'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
          <g id='Group-11' transform='translate(5.205700, 10.502911)'>
            <mask id='mask-6' fill='white'>
              <use xlinkHref='#path-5' />
            </mask>
            <g id='Clip-10' />
            <path
              d='M11.541,7.5114889 C12.408,7.4634889 12.789,6.7544889 12.407,6.1444889 C12.288,5.9554889 12.113,5.7954889 11.941,5.6504889 C11.002,4.8594889 10.055,4.0784889 9.111,3.2934889 C8.848,3.0754889 8.601,2.8294889 8.315,2.6454889 C7.755,2.2864889 7.051,2.5774889 6.902,3.2114889 C6.804,3.6274889 7.019,3.9434889 7.307,4.1874889 C8.524,5.2234889 9.751,6.2494889 10.989,7.2604889 C11.174,7.4124889 11.439,7.4664889 11.541,7.5114889 M0,4.3774889 C0.059,3.7014889 0.043,3.0064889 0.194,2.3504889 C0.511,0.967488904 1.498,0.179488904 2.914,0.0334889039 C3.891,-0.0675110961 4.85,0.0734889039 5.795,0.275488904 C7.654,0.673488904 9.388,1.3954889 10.898,2.5634889 C11.524,3.0474889 12.128,3.5964889 12.617,4.2144889 C14.282,6.3224889 14.235,9.1074889 12.205,11.2024889 C11.161,12.2804889 9.89,12.8864889 8.38,13.0894889 C5.48,13.4804889 3.425,12.2234889 1.873,9.9134889 C0.753,8.2444889 0.12,6.4074889 0,4.3774889'
              id='Fill-9'
              fill={fill}
              mask='url(#mask-6)'
            />
          </g>
          <path
            d='M2.357,7.9917 C3.077,8.6747 4.214,8.6447 4.898,7.9257 C5.581,7.2057 5.551,6.0677 4.831,5.3847 C4.111,4.7017 2.974,4.7317 2.291,5.4507 C1.607,6.1707 1.637,7.3087 2.357,7.9917'
            id='Fill-12'
            fill={fill}
          />
          <g id='Group-16' transform='translate(0.000000, 6.884034)'>
            <mask id='mask-8' fill='white'>
              <use xlinkHref='#path-7' />
            </mask>
            <g id='Clip-15' />
            <path
              d='M0.56,3.10036614 C1.28,3.78336614 2.417,3.75336614 3.1,3.03436614 C3.784,2.31436614 3.754,1.17636614 3.034,0.493366142 C2.314,-0.189633858 1.177,-0.159633858 0.494,0.559366142 C-0.19,1.27936614 -0.16,2.41736614 0.56,3.10036614'
              id='Fill-14'
              fill={fill}
              mask='url(#mask-8)'
            />
          </g>
          <path
            d='M6.568,9.5203 L4.157,7.1263 L2.273,9.1123 L4.661,11.4843 C5.027,10.5783 5.687,9.9063 6.568,9.5203'
            id='Fill-17'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}
