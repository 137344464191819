import { GenericCardPopup, useNewVouchersCount, useSavedVouchers } from '@dominos/hooks-and-hocs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SavedVoucher } from '../../my-account/my-saved-vouchers/saved-voucher'
import { Spinner } from '../../spinners'

const ActiveSavedVouchersModal = (props: {
  onClickVoucher: (voucherCode: string) => void
  onClose: () => void
  testID?: string
}) => {
  const { activeVouchers, loading } = useSavedVouchers()
  const { markNewVouchersAsSeen } = useNewVouchersCount()

  useEffect(() => {
    if (!loading) markNewVouchersAsSeen()
  }, [loading])

  const { t } = useTranslation('saved-vouchers')
  const styles = {
    loadingSpinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100px',
    },
  }

  return (
    <GenericCardPopup
      testID={'saved-vouchers-modal'}
      popupTitle={t('ActiveVouchers')}
      onClose={props.onClose}
      suppressBackgroundScroll={true}
    >
      {loading ? (
        <div style={styles.loadingSpinner}>
          <Spinner testID='active-vouchers-spinner' color={'rgb(0, 122, 175)'} />
        </div>
      ) : activeVouchers.length > 0 ? (
        <div>
          <p>
            {t('ActiveVouchersDescription', {
              defaultValue: 'Select a voucher to add it to your order.',
            })}
          </p>
          <div style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
            {activeVouchers.map((voucher: Bff.Vouchers.Voucher) => (
              <SavedVoucher active key={voucher.voucherCode} voucher={voucher} onClick={props.onClickVoucher} />
            ))}
          </div>
        </div>
      ) : (
        <div>
          <p>{t('NoActiveVouchers', { defaultValue: 'Unfortunately, you do not currently have any vouchers.' })}</p>
        </div>
      )}
    </GenericCardPopup>
  )
}

export { ActiveSavedVouchersModal }
