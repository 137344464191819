type SwapSetProduct = Bff.Menu.old.SwapSetProduct
type SwapSetProductSize = Bff.Menu.old.SwapSetProductSize

interface SurchargeInfo {
  hasSurcharge: boolean
  surcharge: number | null | undefined
}

const getSurchargeInfo = (menuItem: SwapSetProduct | undefined, size: SwapSetProductSize | null): SurchargeInfo =>
  size?.hasSurcharge != null
    ? { hasSurcharge: size.hasSurcharge ?? false, surcharge: size.surcharge }
    : { hasSurcharge: menuItem?.hasSurcharge ?? false, surcharge: menuItem?.surcharge }

export const getExtraSurcharge = (
  getBaseProductPrice: (baseProduct: Bff.Menu.old.SwapSetBaseProduct | undefined) => number | null | undefined,
  menuItem: SwapSetProduct | undefined,
  size: SwapSetProductSize | null,
) => {
  const surchargeInfo = getSurchargeInfo(menuItem, size)

  if (!surchargeInfo.hasSurcharge) {
    return 0
  }

  if (surchargeInfo.surcharge != null) {
    return surchargeInfo.surcharge
  }

  const baseProductPrice = getBaseProductPrice(menuItem?.baseProduct)

  if (size?.price && baseProductPrice) {
    const calculatedExtraSurcharge = size.price - baseProductPrice

    return Math.max(0, calculatedExtraSurcharge)
  }

  return 0
}
