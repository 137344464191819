import { useAutoPopupOffer, useBannerOffers, usePopup } from '@dominos/hooks-and-hocs/offers'
import { useSpotlightContext } from '@dominos/hooks-and-hocs/spotlight'
import { BannerInteractionDetails } from '@dominos/hooks-and-hocs/logging/report-event-banners'
import React, { useEffect, useState } from 'react'
import { BannerContainer } from './banner'
import OfferPopup from './offer-popup'
import { useReport } from '@dominos/hooks-and-hocs/logging/report'

const OfferManager = ({ locationCode }: { locationCode: string }) => {
  const [offerPopupShown, setOfferPopupShown] = useState(false)
  const autoPopupOffer = useAutoPopupOffer(locationCode)
  const bannerOffers = useBannerOffers(locationCode)
  const { spotlightKey } = useSpotlightContext()
  const { isShowPopup, openPopup, closePopup, acceptItems } = usePopup(autoPopupOffer)
  const { reportBannerInteraction } = useReport()

  const bannerInteractionDetails: BannerInteractionDetails = {
    elementType: 'Automatic Popup',
    elementId: autoPopupOffer?.offerId ?? 'no-offer',
    elementName: autoPopupOffer?.media?.name ?? 'No Offer',
    elementSource: 'Auto popup',
    interactionType: '',
  }

  useEffect(() => {
    if (spotlightKey === '' && !offerPopupShown && autoPopupOffer) {
      const openPopupDetails: BannerInteractionDetails = {
        ...bannerInteractionDetails,
        interactionType: 'Auto popup',
      }
      openPopup()
      reportBannerInteraction(openPopupDetails)
      setOfferPopupShown(true)
    }
  }, [autoPopupOffer, spotlightKey])

  const handleAcceptItems = (items: Bff.Offers.Item[], interactionType: string) => {
    const acceptItemsDetails: BannerInteractionDetails = {
      ...bannerInteractionDetails,
      interactionType: interactionType,
      items: items,
    }
    acceptItems(items)
    reportBannerInteraction(acceptItemsDetails)
  }

  const handleClosePopup = (interactionType: string) => {
    const closePopupDetails: BannerInteractionDetails = {
      ...bannerInteractionDetails,
      interactionType: interactionType,
    }
    closePopup()
    reportBannerInteraction(closePopupDetails)
  }

  return (
    <>
      <BannerContainer testID='banners' offers={bannerOffers} />
      {isShowPopup && autoPopupOffer && (
        <OfferPopup show={isShowPopup} offer={autoPopupOffer} onAccept={handleAcceptItems} onClose={handleClosePopup} />
      )}
    </>
  )
}

export { OfferManager }
