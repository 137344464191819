import React from 'react'
import styles from './selectable-product-card.less'
import { Card, Checkbox, Legends } from '@dominos/components'
import { SelectableProductCardProps } from '../../types'
import { useKiosk } from '@dominos/hooks-and-hocs'

const SelectableProductCard = ({ testID, offerItem, onSelected }: SelectableProductCardProps) => {
  const { isKioskOrder } = useKiosk()

  return (
    <Card testID={`${testID}.card`} className={offerItem.isSelected ? styles.containerOutline : styles.container}>
      <div onClick={onSelected} data-testid={testID} className={styles.innerContainer}>
        <div className={styles.checkboxContainerRow}>
          <div className={styles.checkboxContainer}>
            <Checkbox
              testID={`${testID}.checkbox`}
              onChange={() => {}}
              checked={offerItem.isSelected}
              unCheckedCheckboxColor='white'
              unCheckedBorderColor='#C8C7CC'
              diameter={isKioskOrder ? 30 : 20}
            />
          </div>
        </div>
        <div className={styles.imageContainer}>
          <Legends testID={`${testID}.menuLegend`} className={styles.legends} legends={offerItem.legends || []} />
          <img data-testid={`${testID}.image`} className={styles.image} src={offerItem.image.url || ''} alt='' />
        </div>
        <div data-testid={`${testID}.text-container`} className={styles.textContainer}>
          <p data-testid={`${testID}.title`} className={styles.title}>
            {offerItem.name}
          </p>
          <p data-testid={`${testID}.price`} className={styles.price}>
            {`+ ${offerItem.price}`}
          </p>
        </div>
      </div>
    </Card>
  )
}

export default SelectableProductCard
