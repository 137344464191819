import { BasketLineData } from '..'

type Ingredient = Bff.Menu.old.Ingredient

const getSauces = (sizes: ProductSize[] | undefined, sizeCode: string | undefined) =>
  sizes?.find((s) => s.code === sizeCode)?.swaps?.sauces?.ingredients

const getDefaultSauce = (sizes: ProductSize[] | undefined, sizeCode: string | undefined) =>
  sizes?.find((s) => s.code === sizeCode)?.recipe?.sauce

export const getHalfNHalfSharedSauces = (
  sizeCode: string | undefined,
  itemLeft: ChangeableProductItem,
  itemRight: ChangeableProductItem,
): Ingredient[] | undefined => {
  const leftSauces = getSauces(itemLeft.sizes, sizeCode)
  const rightSauces = getSauces(itemRight.sizes, sizeCode)

  return leftSauces?.filter((leftSauce) => rightSauces?.some((rightSauce) => leftSauce.code === rightSauce.code)) ?? []
}

export const getHalfNHalfPresetSauceCode = (
  sizeCode: string | null | undefined,
  itemLeft: ChangeableProductItem,
  itemRight: ChangeableProductItem,
  sharedSauces: Ingredient[],
): string | undefined => {
  if (!sharedSauces || !sharedSauces.length) {
    return undefined
  }

  const leftDefaultSauce = getDefaultSauce(itemLeft.sizes, sizeCode ?? undefined)
  const rightDefaultSauce = getDefaultSauce(itemRight.sizes, sizeCode ?? undefined)

  if (sharedSauces.some((s) => s.code === leftDefaultSauce?.code)) {
    return leftDefaultSauce?.code
  }
  if (sharedSauces.some((s) => s.code === rightDefaultSauce?.code)) {
    return rightDefaultSauce?.code
  }

  return sharedSauces[0].code
}

export const getHalfNHalfLineSauceChangeCode = (
  product: BasketLineData,
  sizeCode: string | null | undefined,
  sharedSauces: Ingredient[],
  halfLeft?: BasketLineData,
): string | undefined => {
  // any change should be in the shared sauce
  // otherwise, get the preset one from the above function
  if (!sharedSauces || !sharedSauces?.length) {
    return undefined
  }
  // if product sauce changes, apply the change first
  if (!!product.sauce?.add && sharedSauces.some((s) => s.code === product.sauce?.add)) {
    return product.sauce.add
  }
  // left and right should be on same sauce, we'll just base on left half here
  // if left has sauce change, use the change sauce
  // if left has no sauce change, use its default sauce
  if (!!halfLeft?.sauce?.add && sharedSauces.some((s) => s.code === halfLeft.sauce?.add)) {
    return halfLeft.sauce.add
  }

  const itemLeft = halfLeft?.item as ChangeableProductItem
  if (!itemLeft) {
    return undefined
  }

  const leftDefaultSauce = getDefaultSauce(itemLeft.sizes, sizeCode ?? undefined)
  if (!leftDefaultSauce) {
    return undefined
  }

  return sharedSauces.some((s) => s.code === leftDefaultSauce.code) ? leftDefaultSauce.code : undefined
}
