import { CheckoutContainer, SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { useBreakpoints, useKiosk, usePrevious } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, useLocation } from '@reach/router'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const shouldShowBackButtonForPath = (path: string) => !path.includes(`${NavigationConstants.checkout}/processing`)

interface KeyValuePair {
  key: string
  defaultValue: string
}

const routeMap = (isMobileLayout: boolean): Record<string, KeyValuePair> => ({
  [NavigationConstants.checkoutDetails]: isMobileLayout
    ? {
        key: 'BackToBasket',
        defaultValue: 'Basket',
      }
    : {
        key: 'BackToMenu',
        defaultValue: 'Menu',
      },
  [NavigationConstants.checkoutPayment]: {
    key: 'BackToDetails',
    defaultValue: 'Details',
  },
  [NavigationConstants.checkoutPlaceOrder]: {
    key: 'BackToDetails',
    defaultValue: 'Details',
  },
})

const defaultBackButton: KeyValuePair = {
  key: 'BackButton',
  defaultValue: 'Back',
}

const getBackButtonTextByPath = (path: string, t: TFunction, isMobileLayout: boolean) => {
  const { key, defaultValue } = routeMap(isMobileLayout)[path] || defaultBackButton

  return t(key, { defaultValue })
}

export const CheckoutScene = () => {
  const { pathname } = useLocation()
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const { t } = useTranslation('navigation')

  const [showBackButton, setShowBackButton] = useState(true)
  const [backButtonText, setBackButtonText] = useState('Back')
  const previousPath = usePrevious(pathname)

  const handleOnBack = () => {
    let handled = false
    if (pathname === NavigationConstants.checkoutPlaceOrder && previousPath === NavigationConstants.checkoutPayment) {
      navigate(-2)
      handled = true
    }

    return handled
  }

  useEffect(() => {
    setShowBackButton(shouldShowBackButtonForPath(pathname))
    setBackButtonText(getBackButtonTextByPath(pathname, t, isMobile || isKioskOrder))
  }, [pathname])

  return (
    <StackNavigationBase
      testID='checkout-scene-stack'
      header={
        <SimpleNavigationTitle showBackButton={showBackButton} backScreen={backButtonText} onBack={handleOnBack} />
      }
    >
      <CheckoutContainer testID={'checkout-container'} />
    </StackNavigationBase>
  )
}
