export * from './slice-1'
export * from './slice-2'
export * from './slice-3'
export * from './slice-4'
export * from './slice-5'
export * from './slice-6'
export * from './slice-7'
export * from './slice-8'

export * from './slices'
