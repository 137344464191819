import { GeoLocationInterface } from '@dominos/interfaces'
import { svgIconsName } from '@dominos/res/images/icons'
import { quickCalculateDistance } from './geo'

export const convertAddressLineToStore = (line: AddressLine): Bff.Stores.Store => ({
  storeNo: line.uid,
  serviceMethods: {
    pickup: false,
    delivery: false,
    dineIn: false,
    message: 'test',
  },
  media: {
    name: line.name,
    displayAddress: line.address || '',
  },
  timeZone: line.timeZone || '',
  geo: {
    longitude: (line.geo && line.geo.longitude) || NaN,
    latitude: (line.geo && line.geo.latitude) || NaN,
  },
  loyalty: {
    isEnabled: !!(line.loyalty && line.loyalty.isEnabled),
  },
  priceInfo: {
    taxRate: line.priceInfo && line.priceInfo.taxRate,
    minOrderPricePickup: line.priceInfo && line.priceInfo.minOrderPricePickup,
    minOrderPriceDelivery: line.priceInfo && line.priceInfo.minOrderPriceDelivery,
  },
  features: [],
  onlineOrderingEnabled: true,
})

export const convertStoreToAddressLine = (
  icon: svgIconsName,
  store: Bff.Stores.Store,
  geo?: GeoLocationInterface,
): AddressLine => {
  const distance = quickCalculateDistance(
    store.geo.latitude,
    store.geo.longitude,
    geo && geo.latitude,
    geo && geo.longitude,
  )

  return {
    icon,
    name: store.media?.name,
    address: (store.media?.displayAddress && store.media.displayAddress.replace(/(?:\\[rn]|[\r\n]+)+/g, ' ')) || '',
    uid: store.storeNo,
    geo: {
      longitude: store.geo.longitude,
      latitude: store.geo.latitude,
    },
    timeZone: store.timeZone,
    suffix: distance === undefined ? 'caret' : distance,
    loyalty: {
      isEnabled: store.loyalty !== undefined && store.loyalty.isEnabled,
    },
    priceInfo: {
      taxRate: store.priceInfo && store.priceInfo.taxRate,
      minOrderPricePickup: store.priceInfo && store.priceInfo.minOrderPricePickup,
      minOrderPriceDelivery: store.priceInfo && store.priceInfo.minOrderPriceDelivery,
    },
  }
}
