import { ApolloError, useQuery } from '@apollo/client'
import { orderStatusQuery } from '@dominos/business/queries'
import { rootActions } from '@dominos/business/root.actions'
import { OrderStatus } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import OrderModel from '../model/order-model'

const NetworkErrorHandlerKey = 'NETWORK_ERROR'
const GqlErrorHandlerKey = 'GQL_ERROR'

type THandler = (response: { orderModel?: OrderModel; error?: ApolloError }) => void

export const useOrderStatus = (orderId: string, updateCache: boolean = false) => {
  const [statusHandlers, setStatusHandlers] = useState<Map<OrderStatus | string, THandler[]>>(new Map())
  const [fallbackHandlers, setFallbackHandlers] = useState<[THandler]>()
  const [firstStatusHandler, setFirstStatusHandler] = useState<{ handler: THandler }>()
  const [handledFirstStatusCall, setHandledFirstStatusCall] = useState(false)
  const dispatch = useDispatch()

  const {
    data: orderStatusResult,
    startPolling,
    stopPolling,
    error,
  } = useQuery(orderStatusQuery, {
    variables: {
      id: orderId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: updateCache ? 'cache-and-network' : 'no-cache',
    skip: !orderId,
  })

  const addStatusHandlerInternal = (status: OrderStatus | string, handler: THandler) => {
    if (!statusHandlers.has(status)) {
      statusHandlers.set(status, [handler])
    } else {
      statusHandlers.get(status)?.push(handler)
    }
    setStatusHandlers(statusHandlers)
  }

  const addStatusHandler = (status: OrderStatus | string | (OrderStatus | string)[], handler: THandler) => {
    if (Array.isArray(status)) {
      status.forEach((s) => addStatusHandlerInternal(s, handler))
    } else {
      addStatusHandlerInternal(status, handler)
    }
  }

  const addUnknownStatusHandler = (handler: THandler) => {
    if (fallbackHandlers) {
      fallbackHandlers.push(handler)
    } else {
      setFallbackHandlers([handler])
    }
  }

  const addNetworkErrorHandler = (handler: THandler) => {
    addStatusHandlerInternal(NetworkErrorHandlerKey, handler)
  }

  const addGqlErrorHandler = (handler: THandler) => {
    addStatusHandlerInternal(GqlErrorHandlerKey, handler)
  }

  const addInitialStatusHandler = (handler: THandler) => {
    setFirstStatusHandler({ handler })
  }

  useEffect(() => {
    if (orderStatusResult && orderStatusResult.order) {
      const orderModel = new OrderModel(orderStatusResult.order)
      const updatedStatus = orderModel.status

      if (updatedStatus) {
        if (!handledFirstStatusCall && !!firstStatusHandler) {
          firstStatusHandler.handler({ orderModel })
          setHandledFirstStatusCall(true)
        }
        if (isKnownStatus(updatedStatus)) {
          dispatch(rootActions.setOrderStatus(updatedStatus))
          statusHandlers.get(updatedStatus)?.forEach((h) => h({ orderModel }))
        } else {
          fallbackHandlers?.forEach((h) => h({ orderModel }))
        }
      }
    }
  }, [orderStatusResult])

  useEffect(() => {
    const errors = error?.graphQLErrors
    if (errors && errors.length > 0) {
      statusHandlers.get(GqlErrorHandlerKey)?.forEach((h) => h({ error }))
    }
  }, [error?.graphQLErrors])

  useEffect(() => {
    if (error?.networkError) {
      statusHandlers.get(NetworkErrorHandlerKey)?.forEach((h) => h({ error }))
    }
  }, [error?.networkError])

  return {
    addInitialStatusHandler,
    addStatusHandler,
    addUnknownStatusHandler,
    addNetworkErrorHandler,
    addGqlErrorHandler,
    startPolling,
    stopPolling,
  }
}

const isKnownStatus = (receivedStatus: string) => Object.values(OrderStatus).find((os) => os.includes(receivedStatus))
