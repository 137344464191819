import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'
import { SvgIconsProps } from '../../../interfaces/icon.interfaces'

const chevronDirection = (direction?: SvgIconsProps['direction']) => {
  switch (direction) {
    case 'right':
      return '0 0 0'
    case 'left':
      return '180 7 4'
    case 'up':
      return '-90 7 4'
    case 'down':
      return '90 7 4'
    default:
      return '0 0 0'
  }
}

const defaultDimensions = {
  width: 14,
  height: 16,
}

const Chevron = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const angle = chevronDirection(props.direction)
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} className={props.className} viewBox={`0 0 14 8`}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' transform={`rotate(${angle})`}>
        <polygon fill={fill} points='3 -1 4.5 -2.5 11 4 4.5 10.5 3 9 8 4' />
      </g>
    </svg>
  )
}

export { Chevron }
