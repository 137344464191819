import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'
import { ErrorScope, kioskErrors, useErrorContext } from '@dominos/components'

export const useKioskErrors = () => {
  const { notifyError } = useErrorContext()

  const notifyKioskError = (kioskError: ApolloError | keyof typeof kioskErrors) => {
    const error =
      kioskError instanceof ApolloError
        ? kioskError
        : new ApolloError({
            graphQLErrors: [
              new GraphQLError('Kiosk is not ready - Kiosk not enabled', [], null, null, null, null, {
                code: 'KIOSK_NOT_ENABLED',
              }),
            ],
          })

    notifyError({
      scope: ErrorScope.Kiosk,
      handlers: {},
      definitions: kioskErrors,
      error,
    })
  }

  return { notifyKioskError }
}
