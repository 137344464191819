import { BasketLineData } from '@dominos/business/functions/basket'
import {
  getDefaultProductPrice as defaultPrice,
  getMenuProductPrice as menuPrice,
} from '@dominos/business/functions/pricing'
import { useDynamicPricing, useProductPricing } from '@dominos/hooks-and-hocs'
import { useEffect, useRef } from 'react'
import { mapBasketLineDataToHalfNHalfPriceProduct, mapBasketLineDataToPriceProduct } from './map-product'

export const usePricing = (): ProductPricing & {
  getPrice: (product: BasketLineData, halfLeft?: BasketLineData, halfRight?: BasketLineData) => void
} & {
  getPriceUnconditionally: (product: Bff.PriceProduct.PriceProduct) => void
} => {
  const [productPricing, setProductPricing] = useProductPricing()
  const fallbackPrice = useRef<ProductPricing>(productPricing)
  const { shouldFetchPrice, fetchPrice, dynamicPricing, error } = useDynamicPricing()

  const getPriceUnconditionally = (product: Bff.PriceProduct.PriceProduct) => {
    fetchPrice(product)
  }

  const getPrice = (product: BasketLineData, halfLeft?: BasketLineData, halfRight?: BasketLineData) => {
    const menuPricing = menuPrice(product)
    const shouldUseMenuPrice =
      menuPricing.formattedPrice !== null && (product.item?.type !== 'Portion' || !halfLeft?.item || !halfRight?.item)

    if (shouldUseMenuPrice) {
      setProductPricing(menuPricing)

      // when managed to get the price from the menu, no need to run to the api or extract default
      return
    }

    fallbackPrice.current = defaultPrice(product)
    if (product.item?.type === 'Portion' || shouldFetchPrice(product, productPricing)) {
      const mappedProduct =
        product.item?.type === 'Portion'
          ? mapBasketLineDataToHalfNHalfPriceProduct(product, [halfLeft, halfRight])
          : mapBasketLineDataToPriceProduct(product)
      fetchPrice(mappedProduct)
    } else {
      setProductPricing(fallbackPrice.current)
    }
  }

  useEffect(() => {
    if (dynamicPricing) {
      setProductPricing(dynamicPricing)
    }
  }, [dynamicPricing])

  useEffect(() => {
    if (!!error) {
      setProductPricing(fallbackPrice.current)
    }
  }, [error])

  return {
    ...productPricing,
    getPrice,
    getPriceUnconditionally,
  }
}
