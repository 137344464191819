import { ErrorDefinition, getDefaultValue } from '@dominos/components/error'

export const kioskErrors: PartialRecord<Bff.Errors.Kiosk | 'UNABLE_TO_RESOLVE', ErrorDefinition> = {
  KIOSK_NOT_ENABLED: {
    displayType: 'modal',
    translation: {
      title: { key: 'KioskNotEnabledTitle', getOptions: getDefaultValue },
      message: { key: 'KioskNotEnabledMessage', getOptions: getDefaultValue },
    },
    icon: 'exclamation',
    testID: 'kiosk-error.kiosk-not-enabled',
  },
  INVALID_KIOSK_ID: {
    displayType: 'modal',
    translation: {
      title: { key: 'InvalidKioskIdTitle', getOptions: getDefaultValue },
      message: { key: 'InvalidKioskIdMessage', getOptions: getDefaultValue },
    },
    icon: 'exclamation',
    testID: 'kiosk-error.invalid-kiosk-id',
  },
  PICKUP_ASAP_UNAVAILABLE: {
    displayType: 'modal',
    translation: {
      title: { key: 'PickupASAPUnavailableTitle', getOptions: getDefaultValue },
      message: { key: 'PickupASAPUnavailableMessage', getOptions: getDefaultValue },
    },
    icon: 'exclamation',
    testID: 'kiosk-error.pickup-asap-unavailable',
  },
  UNABLE_TO_RESOLVE: {
    displayType: 'modal',
    translation: {
      title: { key: 'UnableToResolveKioskTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToResolveKioskMessage', getOptions: getDefaultValue },
    },
    icon: 'exclamation',
    testID: 'kiosk-error.unable-to-resolve',
  },
}
