import React from 'react'

import { svgIcons } from '@dominos/res/images/icons'
import { EmptyPortionProductProps } from './empty-portion-product.interface'
import css from './empty-portion-product.less'

const EmptyPortionProduct = ({ portionKey, testID }: EmptyPortionProductProps): JSX.Element => {
  const EmptyPortionIcon = svgIcons[`portionEmptyIcon_${portionKey}`]

  return (
    EmptyPortionIcon && (
      <div className={css.iconWrapper} data-testid={`${testID}.empty-portion`}>
        <EmptyPortionIcon className={css.iconStyle} />
      </div>
    )
  )
}

export { EmptyPortionProduct }
