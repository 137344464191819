import React from 'react'
import { MyDetailsForm } from '../../checkout-container'
import { isNativeApp } from '@dominos/business/functions'
import Markdown from 'markdown-to-jsx'
import { useTranslation } from 'react-i18next'
import {
  ActionButton,
  InlineSingleItemOffer,
  MDTargetBlankAnchor,
  OrderDetailsPill,
  RoundUpForCharityCheckout,
} from '@dominos/components'
import { GenericCardPopup, useCheckoutMyDetails, useFeatures, usePaymentAmount } from '@dominos/hooks-and-hocs'
import css from './express-checkout-order-popup.less'

interface ExpressCheckoutOrderPopupProps extends BaseProps {
  onClose: () => void
  donationEnabled: boolean
}

// TODO: Replace ActionButton with adyen payment methods
// TODO: Add the Delivery instruction component
export const ExpressCheckoutOrderPopup = ({ onClose, testID, donationEnabled }: ExpressCheckoutOrderPopupProps) => {
  const [checkoutOffersEnabled, showOrderDetailsPill] = useFeatures('CheckoutMyDetailsOffers', 'ShowOrderDetailsPill')
  const { roundUpAmount } = usePaymentAmount(donationEnabled)
  const { setIsMyDetailsValid, rememberMyDetailsState, handleMyDetailsChange, customerDetails } = useCheckoutMyDetails()
  const { t } = useTranslation('checkout')

  return (
    <GenericCardPopup testID='express-checkout-popup' popupTitle='' suppressBackgroundScroll={true} onClose={onClose}>
      <h2 className={css.popupTitle} data-testid={`${testID}.title`}>
        {t(`CheckoutOrderDetails`, `Order Details`)}
      </h2>
      <hr className={css.hr} />
      <div className={css.wrapper}>
        <h2 className={css.sectionTitle} data-testid={`${testID}.my-details.title`}>
          {t(`CheckoutMyDetails`, `My Details`)}
        </h2>
        <MyDetailsForm
          onFormValidationChange={setIsMyDetailsValid}
          initialDetails={customerDetails}
          initialRememberDetails={rememberMyDetailsState}
          onChange={handleMyDetailsChange}
        />
      </div>
      <div className={css.wrapper}>
        {!isNativeApp() && showOrderDetailsPill && (
          <OrderDetailsPill testID={testID} disableClick={true} isExpressCheckout={true} />
        )}
      </div>
      {checkoutOffersEnabled && (
        <div className={css.wrapper}>
          <InlineSingleItemOffer
            testID={`${testID}.checkout-offers`}
            filterLocation={{
              locationType: 'Page',
              locationCode: 'PaymentSelection',
              showBanner: true,
            }}
            compactView
          />
        </div>
      )}
      {donationEnabled && (
        <div className={css.wrapper}>
          <RoundUpForCharityCheckout testID={`${testID}`} amount={roundUpAmount} />
        </div>
      )}
      <div className={css.wrapper}>
        <Markdown className={css.link} options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
          {t(
            'ExpressCheckoutT&C',
            'By ordering you agree to our [Terms And Conditions](https://www.dominos.com.au/about-us/contact-us/terms-conditions?contentonly=true) and [Privacy Policy](https://www.dominos.com.au/about-us/contact-us/privacy-policy?contentonly=true).',
          )}
        </Markdown>
      </div>
      <div className={css.wrapper}>
        <ActionButton
          testID={`${testID}.applePay`}
          onPress={() => {}}
          text={'Apple Pay'}
          textStyle={{ color: 'white' }}
          containerStyle={{ backgroundColor: 'black', width: '100%' }}
          textFontWeight={'semibold'}
        />
      </div>
    </GenericCardPopup>
  )
}
