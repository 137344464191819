import React from 'react'

import { ActionButton } from '@dominos/components/buttons'
import { AuthButtonProps } from './auth-button.interface'

const styles: CommonViewStyle = {
  width: '54px',
  height: '54px',
  borderRadius: '50%',
  display: 'grid',
  padding: 0,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  cursor: 'pointer',
}

const nativeStyles: CommonViewStyle = {
  ...styles,
  display: 'flex',
}

export const AuthButton = ({
  testID,
  backgroundColor = 'inherit',
  children,
  label,
  loading,
  onPress,
  isNative = false,
}: React.PropsWithChildren<AuthButtonProps>) => (
  <ActionButton
    testID={testID}
    loading={loading}
    disabled={loading}
    onPress={onPress}
    containerStyle={{
      ...(isNative ? nativeStyles : styles),
      backgroundColor,
    }}
    label={label}
  >
    {children}
  </ActionButton>
)
