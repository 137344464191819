import { TFunction } from 'i18next'
import { IListGroup, IListGroupCollection, IListGroupCollectionItem } from '@dominos/components'

interface UsePickupStoresPreviewComponentProps {
  t: TFunction
}

const ICON_NAME = 'mapArrowIconSolidGrey'
const SUFFIX_NAME = 'caret'
const PREFIX_NAME = 'address'

export const usePickupStoresPreviewComponent = (props: UsePickupStoresPreviewComponentProps) => {
  const mapAddressData = (data: AddressLine[]): IListGroup | null => {
    if (!data || data.length === 0) return null
    const groupedList: IListGroup = {
      title: props.t('SelectAStore', { defaultValue: 'Select a Store' }),
      items: [],
    }

    const nearbyCollection: IListGroupCollection = {
      title: props.t('Nearby', { defaultValue: 'Nearby' }),
      prefix: PREFIX_NAME,
      items: [],
    }

    const nearbyCollectionItems: IListGroupCollectionItem[] = data.map((item, index) => ({
      title: item.name ?? '',
      subtitle: item.address ?? '',
      id: item.uid.toString(),
      index: index,
      suffix: SUFFIX_NAME,
      icon: ICON_NAME,
    }))

    nearbyCollection.items = nearbyCollectionItems

    groupedList.items.push(nearbyCollection)

    return groupedList
  }

  return {
    mapAddressData,
  }
}
