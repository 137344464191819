import React from 'react'
import { GenericCard } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

import { ListGroupCollection } from './list-group-collection'
import { IListGroup } from './interfaces'

interface ListGroupProps extends BaseProps {
  data: IListGroup
  isLoading?: boolean
  itemLoadingKey?: string
  iconStyling?: SvgIconDimension
  hideBorderAboveFirstGroup?: boolean
  onItemSelect: (id: string) => void
}

export const ListGroup = (props: ListGroupProps) => {
  const theme = useDominosTheme()

  return (
    <GenericCard testID={`${props.testID}.listGroup`} title={props.data.title} style={{ marginTop: theme.marginTop }}>
      {props.data.items.map((item, index) => (
        <ListGroupCollection
          testID={`${props.testID}.listGroup.collection.${item?.title?.toLowerCase()}`}
          key={`${item.title}.${index}`}
          collection={item}
          onPress={props.onItemSelect}
          isLoading={item.isLoading}
          itemLoadingKey={props.itemLoadingKey}
          showBorderAboveTitle={showBoarderAboveTitle(index, props.hideBorderAboveFirstGroup)}
          showBorderOnFirstItem
          iconStyling={props.iconStyling ?? { width: 30, height: 30 }}
        />
      ))}
    </GenericCard>
  )
}

const showBoarderAboveTitle = (index: number, hideBorderAboveFirstGroup?: boolean) =>
  hideBorderAboveFirstGroup ? index !== 0 : true
