import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const PizzaIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='4.4408921e-16 0 22.8028173 0 22.8028173 5.5089 4.4408921e-16 5.5089' />
        <polygon id='path-3' points='0 0 18.626 0 18.626 17.99995 0 17.99995' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-7' transform='translate(1.000000, 0.000000)'>
          <g id='Group-3'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-2' />
            <path
              d='M21.6215173,5.5089 L22.6445173,3.5489 C23.0525173,2.7679 22.6385173,1.8209 21.7965173,1.5619 C15.0375173,-0.5211 7.76551734,-0.5211 1.00651734,1.5619 C0.164517342,1.8209 -0.249482658,2.7679 0.158517342,3.5489 L1.18151734,5.5089 C7.83251734,3.4969 14.9695173,3.4969 21.6215173,5.5089'
              id='Fill-1'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <g id='Group-6' transform='translate(2.088417, 6.000050)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-5' />
            <path
              d='M12.313,6.98625 C11.761,6.98625 11.313,6.53825 11.313,5.98625 C11.313,5.43425 11.761,4.98625 12.313,4.98625 C12.865,4.98625 13.313,5.43425 13.313,5.98625 C13.313,6.53825 12.865,6.98625 12.313,6.98625 M8.841,11.98625 C8.013,11.98625 7.341,11.31425 7.341,10.48625 C7.341,9.65825 8.013,8.98625 8.841,8.98625 C9.67,8.98625 10.341,9.65825 10.341,10.48625 C10.341,11.31425 9.67,11.98625 8.841,11.98625 M3.841,4.48625 C3.841,3.10525 4.96,1.98625 6.341,1.98625 C7.722,1.98625 8.841,3.10525 8.841,4.48625 C8.841,5.86725 7.722,6.98625 6.341,6.98625 C4.96,6.98625 3.841,5.86725 3.841,4.48625 M0,1.24725 L8.492,17.51425 C8.83,18.16225 9.796,18.16225 10.134,17.51425 L18.626,1.24725 C12.539,-0.41575 6.087,-0.41575 0,1.24725'
              id='Fill-4'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
