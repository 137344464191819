import { ActionButton } from '@dominos/components/buttons/action-button'
import OfferPopup from '@dominos/components/offers/offer-popup'
import { usePopup } from '@dominos/hooks-and-hocs/offers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useReport } from '@dominos/hooks-and-hocs/logging/report'
import { BannerInteractionDetails } from '@dominos/hooks-and-hocs/logging/report-event-banners'

const buttonTextStyleOverrides = { fontSize: 16, color: 'white' }
const buttonContainerStyleOverrides = { padding: '11px', width: '100%', backgroundColor: '#007AAF' }

const SpecialOffers = ({ offer }: { offer: Bff.Offers.Offer }) => {
  const { t } = useTranslation('menu')
  const { isShowPopup, openPopup, closePopup, acceptItems } = usePopup(offer)
  const { reportBannerInteraction } = useReport()
  const specialOfferButtonText = t('SpecialOffers')

  const bannerInteractionDetails: BannerInteractionDetails = {
    elementType: 'Special Offers',
    elementId: offer.offerId,
    elementName: offer.media.name,
    elementSource: 'User clicked on special offers button',
    interactionType: '',
  }

  const handleOpenPopup = () => {
    const openPopupDetails: BannerInteractionDetails = {
      ...bannerInteractionDetails,
      interactionType: specialOfferButtonText,
    }
    openPopup()
    reportBannerInteraction(openPopupDetails)
  }

  const handleAcceptItems = (items: Bff.Offers.Item[], interactionType: string) => {
    const acceptItemsDetails: BannerInteractionDetails = {
      ...bannerInteractionDetails,
      interactionType: interactionType,
      items: items,
    }
    acceptItems(items)
    reportBannerInteraction(acceptItemsDetails)
  }

  const handleClosePopup = (interactionType: string) => {
    const closePopupDetails: BannerInteractionDetails = {
      ...bannerInteractionDetails,
      interactionType: interactionType,
    }
    closePopup()
    reportBannerInteraction(closePopupDetails)
  }

  return (
    <div style={{ marginTop: 8 }}>
      <ActionButton
        testID='special-offers-button'
        text={specialOfferButtonText}
        textFontWeight='bold'
        textStyle={buttonTextStyleOverrides}
        containerStyle={buttonContainerStyleOverrides}
        onPress={handleOpenPopup}
      />
      {isShowPopup && (
        <OfferPopup offer={offer} show={isShowPopup} onAccept={handleAcceptItems} onClose={handleClosePopup} />
      )}
    </div>
  )
}

export default SpecialOffers
