import React, { memo } from 'react'
import { GenericCard, TimedOrderPickerContainerProps } from '@dominos/components/'
import { StoreDetails } from './partials'
import { useStoreDetails } from '@dominos/hooks-and-hocs/ordering/use-store-details'

type StoreDetailsContainerProps = Pick<
  TimedOrderPickerContainerProps,
  'testID' | 'currentStore' | 'serviceMethod' | 'openingHours' | 't' | 'language'
>

export const StoreDetailsContainer = memo(
  ({ testID, currentStore, serviceMethod, openingHours, t, language }: StoreDetailsContainerProps) => {
    const {
      storeNo,
      storeAddress,
      storeInformation,
      storeStatusMessage,
      extraStoreStatusDetails,
      storeOpensAtMessage,
      storeType,
      storeTypeMessage,
    } = useStoreDetails(serviceMethod, currentStore, openingHours, t, language)

    return (
      <div data-testid={testID}>
        <GenericCard testID={testID} title={t('Store Details')}>
          <StoreDetails
            testID={`${testID}.store-status`}
            storeNo={storeNo}
            storeAddress={storeAddress}
            storeInformation={storeInformation}
            storeStatusMessage={storeStatusMessage}
            extraStoreStatusDetails={extraStoreStatusDetails}
            storeOpensAtMessage={storeOpensAtMessage}
            serviceMethod={serviceMethod}
            storeType={storeType}
            storeTypeMessage={storeTypeMessage}
          />
        </GenericCard>
      </div>
    )
  },
)
