import { ApolloError, useMutation } from '@apollo/client'
import { updatePasswordMutation } from '@dominos/business/queries/update-password'
import { ActionButton } from '@dominos/components'
import { Error } from '@dominos/components/error'
import { NotificationType, PopUpNotification } from '@dominos/components/notification'
import { useCurrentOrderDetails, useCustomer, useFeatures, useReport } from '@dominos/hooks-and-hocs'
import { RouteComponentProps } from '@reach/router'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { myPasswordErrors } from './my-password-errors'
import styles from './my-password.less'
import { PasswordFields, PasswordFieldValues } from './password-fields'
import { MyPasswordForgeRock } from './my-password-forgerock'

interface UpdatePasswordType {
  input: {
    oldPassword: string
    newPassword: string
  }
}
interface PasswordTypes {
  password?: string
  newPassword?: string
}

const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)

const MyPassword = (props: RouteComponentProps) => {
  const [changeCustomerPassword, { loading }] = useMutation<{}, UpdatePasswordType>(updatePasswordMutation, {
    onError: (error: ApolloError) => handleError(error),
    onCompleted: () => handleComplete(),
  })
  const [customerPasswords, setCustomerPasswords] = useState<PasswordTypes>()
  const [validationPassed, setValidationPassed] = useState(false)
  const [errorForHandler, setErrorForHandler] = useState<ApolloError | null>(null)
  const { customer } = useCustomer()
  const [showPopup, setShowPopup] = useState(false)
  const { t } = useTranslation('login')
  const { reportPasswordChanged } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const [forgeRockIdentityProviderEnabled] = useFeatures('ForgeRockIdentityProvider')

  const handleComplete = () => {
    setShowPopup(true)
    reportPasswordChanged({ orderId, success: true })
    setErrorForHandler(null)
  }

  const handleError = (error: ApolloError) => {
    reportPasswordChanged({ orderId, success: false })
    setErrorForHandler(error)
  }

  const setPasswords = (passwordValues: PasswordFieldValues) => {
    setCustomerPasswords({
      password: passwordValues.oldPassword.value,
      newPassword: passwordValues.newPassword.value,
    })
  }

  const onPasswordChange = async () => {
    if (validationPassed && customerPasswords && customerPasswords.password && customerPasswords.newPassword) {
      await changeCustomerPassword({
        variables: {
          input: {
            oldPassword: customerPasswords.password,
            newPassword: customerPasswords.newPassword,
          },
        },
      })
    }
  }

  const onPopupClose = () => setShowPopup(false)

  if (!customer) {
    return <></>
  }

  return (
    <div className={styles.wrapper}>
      {customer.identityProvider !== null ? (
        <div className={styles.content}>
          <h2>{t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}</h2>
          <p>
            {t('ChangePasswordUnavailableSocialAccount', {
              identityProvider: customer.identityProvider
                ? capitalizeFirstLetter(customer.identityProvider)
                : 'social media',
              defaultValue:
                'You signed up with a {{identityProvider}} account. To change your password please go to the {{identityProvider}} website.',
            })}
          </p>
        </div>
      ) : !forgeRockIdentityProviderEnabled ? (
        <>
          <div className={styles.content}>
            <h2>{t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}</h2>
            <p>
              {t('MyAccountPasswordDescription', {
                defaultValue: 'To change your password, please fill out the form:',
              })}
            </p>
          </div>

          <PasswordFields onPasswordsChanged={setPasswords} onPasswordsValidation={setValidationPassed} />

          <div className={styles.content}>
            <div className={styles.centerButton}>
              <ActionButton
                loading={loading}
                disabled={loading || !validationPassed}
                onPress={onPasswordChange}
                testID={'change-password-action-button'}
              >
                {t('ChangePassword', { defaultValue: 'Change Password' })}
              </ActionButton>
            </div>

            {errorForHandler && <Error tns={t} errorDefinitions={myPasswordErrors} error={errorForHandler} />}
          </div>

          {showPopup && (
            <PopUpNotification
              notificationType={NotificationType.confirmation}
              heading={t('ChangePasswordSuccessful', { defaultValue: 'Password changed successfully' })}
              onClose={onPopupClose}
            />
          )}
        </>
      ) : (
        <MyPasswordForgeRock />
      )}
    </div>
  )
}

export { MyPassword }
