import { ErrorDefinition, getDefaultValue } from '@dominos/components/error'
export const forgotPasswordErrors: PartialRecord<Bff.Errors.Customer | 'UNABLE_TO_RESOLVE' | string, ErrorDefinition> =
  {
    UNABLE_TO_RESOLVE: {
      displayType: 'modal',
      translation: {
        title: {
          key: 'ForgottenPasswordErrorTitle',
          getOptions: () => getDefaultValue('Failed to Reset Password'),
        },
        message: {
          key: 'ForgottenPasswordError',
          getOptions: () =>
            getDefaultValue(
              'Sorry, there was an error processing your reset password request. Please try again later.',
            ),
        },
      },
      icon: 'infoIcon',
      testID: 'unable-to-resolve-error',
    },
  }
