import gql from 'graphql-tag'

export const deleteCustomer = gql`
  mutation deleteCustomer {
    deleteCustomer
  }
`
export interface DeleteCustomerResponse {
  deleteCustomer: Boolean
}
