import gql from 'graphql-tag'

export const fetchPriceQuery = gql`
  query priceProductQuery(
    $storeNo: Int!
    $orderTime: DateTime
    $delivered: Boolean!
    $application: String
    $showSecretMenus: Boolean
    $product: PriceProduct!
    $halfNHalfPricingBehaviour: PriceHalfNHalfPricingBehaviourEnum
  ) {
    priceProduct(
      storeNo: $storeNo
      orderTime: $orderTime
      delivered: $delivered
      application: $application
      showSecretMenus: $showSecretMenus
      product: $product
      halfNHalfPricingBehaviour: $halfNHalfPricingBehaviour
    ) {
      displayTotal
      salePrice
      displayPromoTotal
    }
  }
`
