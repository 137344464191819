export class PollingTimer {
  private timer: NodeJS.Timeout
  private startPolling: (pollingInterval: number) => void
  private stopPolling: () => void

  constructor(startCb: (pollingInterval: number) => void, stopCb: () => void) {
    this.startPolling = startCb
    this.stopPolling = stopCb
    this.timer = this.start()
  }

  public reset() {
    this.clear()
    this.timer = this.start()
  }

  public start() {
    return setTimeout(this.startPolling, 0) as unknown as NodeJS.Timeout
  }

  public stop() {
    this.stopPolling()
  }

  public clear() {
    this.stop()
    clearTimeout(this.timer)
  }
}
