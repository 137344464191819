import { useApplicationSettings, useFeatures } from '@dominos/hooks-and-hocs'
import { OrderStatus } from '@dominos/hooks-and-hocs/order/order-types'
import IframeResizer from 'iframe-resizer-react'
import React, { useEffect, useState } from 'react'
import { useGPSTracker } from '../../use-gps-tracker'
import styles from './nextgen-gps.less'
import { getGPSTrackerUrl } from './utils'

interface NextGenGPSProps {
  id?: string
  order: Bff.Orders.Order
  url: string
}
const START_TRACKING_STATUSES: OrderStatus[] = [OrderStatus.Leaving]
const STOP_TRACKING_STATUS = OrderStatus.Complete

const NextGenGPS = ({ id, order, url }: NextGenGPSProps) => {
  const [gpsDriverTrackerProgressBarTracker] = useFeatures('GPSDriverTrackerProgressBarTracker')

  const displayGPS = url !== 'null'
  const { startTrack } = useGPSTracker(displayGPS, order.id, START_TRACKING_STATUSES, STOP_TRACKING_STATUS)

  const address = order.details?.header.deliveryAddress?.address
  const store = {
    number: order.store?.storeNo,
    longitude: order.store?.geo?.longitude,
    latitude: order.store?.geo?.latitude,
  }

  const settings = useApplicationSettings()

  const [GPSTrackerUrl, setGPSTrackerUrl] = useState(() =>
    getGPSTrackerUrl(
      url,
      address,
      store,
      order.id,
      settings.countryCode,
      settings.language,
      false,
      gpsDriverTrackerProgressBarTracker,
    ),
  )

  useEffect(() => {
    if (startTrack) {
      setGPSTrackerUrl(
        getGPSTrackerUrl(
          url,
          address,
          store,
          order.id,
          settings.countryCode,
          settings.language,
          true,
          gpsDriverTrackerProgressBarTracker,
        ),
      )
    }
  }, [startTrack])

  if (!displayGPS) {
    return null
  }

  const origin = new URL(url).origin

  return (
    <div id={id} className={styles.iframeWrapper}>
      <IframeResizer checkOrigin={[origin]} src={GPSTrackerUrl} data-testid='nextgen-gps-iframe' />
    </div>
  )
}

export { NextGenGPS }
