import { useMemo, useState } from 'react'
import { useLanguages } from '@dominos/hooks-and-hocs/languages'
import { VirtualKeyboardLayoutMapping } from './layouts'
import { KeyboardLayout, Layouts } from './layout.interfaces'
import { getLayoutDisplayName } from './get-layout-display-name'
import { getLayoutItems } from './get-layout-items'

const baseDisplay = {
  '{close}': ' ',
  '{shift}': 'Shift',
  '{lock}': 'Caps',
  '{bksp}': 'Backspace',
  '{enter}': 'Enter',
  '{tab}': 'Tab',
  '{space}': ' ',
}

export const useVirtualKeyboardLayout = () => {
  const { language, languages } = useLanguages()
  const hasMultiLayouts =
    languages
      .map(({ langCode }) => Object.keys(VirtualKeyboardLayoutMapping[langCode] ?? {}).length)
      .reduce((prev, curr) => prev + curr, 0) > 1

  const keyboardLayouts: KeyboardLayout[] = useMemo(
    () =>
      languages
        .map((lang) =>
          getLayoutItems(VirtualKeyboardLayoutMapping[lang.langCode]!, hasMultiLayouts).map((layoutItem) => {
            const { short, displayName } = getLayoutDisplayName(lang, layoutItem.layoutName)

            return {
              ...layoutItem,
              language: { ...lang },
              display: {
                ...baseDisplay,
                '{switchLanguage}': `<div class='switchIcon'></div><span>${short}</span>`,
              },
              displayName,
            }
          }),
        )
        .reduce((prev, curr) => [...prev, ...curr], []),
    [],
  )

  const defaultSelectedLayout = useMemo(() => {
    const defaultSelected = keyboardLayouts.find(
      ({ language: { langCode }, layoutName }) =>
        langCode === language && layoutName === (langCode === 'ja' ? Layouts.Romaji : Layouts.Default),
    )

    return defaultSelected ?? keyboardLayouts[0]
  }, [])

  const [selectedLayout, setSelectedLayout] = useState<KeyboardLayout>(defaultSelectedLayout)

  const switchLayout = (index: number) => {
    setSelectedLayout(keyboardLayouts[index])
  }

  return { keyboardLayouts, selectedLayout, switchLayout }
}
