import { NO_STORENO } from '@dominos/business/constants'

export const getSelectedDeliveryAddress = (order: Bff.Orders.Order): StoredCustomerAddress | undefined => {
  if (!order.details?.header.deliveryAddress) {
    return undefined
  }

  const address: Bff.Orders.OrderDetails.Header.DeliveryAddress = order.details.header.deliveryAddress
  const { streetNo, street, suburb, postCode, state, unitNo, floorNo } = address.address

  return {
    displayAddress: address.displayAddress,
    storeNo: order.store?.storeNo ?? NO_STORENO,
    customerAddress: {
      streetNo,
      suburb,
      postCode,
      state,
      unitNo,
      floorNo,
      buildingName: address.buildingName,
      streetName: street,
    },
    geo: {
      latitude: address.address?.location?.latitude ?? 0,
      longitude: address.address?.location?.longitude ?? 0,
    },
    lastUsed: 0,
    bewareOfAnimal: false,
    locale: address.locale,
  }
}
