import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { SecuritySender } from './security'

export const StartUserJourneyOnMenuFeature = 'StartUserJourneyOnMenu'

export type Features = typeof StartUserJourneyOnMenuFeature

export const reportFeature = (sendSecurity: SecuritySender, currentOrder?: CurrentOrderDetailsReducerProps) => {
  const reportFeatureTimeout = (features: Features, value?: boolean) => {
    sendSecurity('FeatureResponse.TIMEOUT', {
      'Order ID': currentOrder?.orderId,
      Feature: features,
      Value: value,
    })
  }

  return {
    reportFeatureTimeout,
  }
}
