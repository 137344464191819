import gql from 'graphql-tag'

const feedbackQuestionsQuery = gql`
  query feedbackQuestions($serviceMethod: ServiceMethodEnum!, $feedbackTypes: [FeedbackType!]!) {
    feedbackQuestions(serviceMethod: $serviceMethod, feedbackTypes: $feedbackTypes) {
      questionId
      externalQuestionId
      questionSetId
      shortName
      questionText
      sortSequence
      displaySettings {
        ratingMin
        ratingMax
        ratingStyle
      }
      feedbackReason {
        externalSubQuestionId
        enableOnRatingLowerThan
        followupQuestionText
        reasonOptions {
          reasonCode
          reasonText
          allowFreeText
        }
      }
    }
  }
`

export { feedbackQuestionsQuery }
