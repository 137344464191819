import { GMOFormPostHandler, GMOFormQueryParams } from '@dominos/components/form-resolver/gmo-form-post-handler'
import { useAlert } from '@dominos/hooks-and-hocs/application/alert'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, useLocation } from '@reach/router'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import React from 'react'

export const FormResolverHandlerConstants = {
  gmoFormPost: 'gmo-form-post',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FormResolverPayload = JwtPayload & { query: any; body: any }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getHandler = (id: string, body: any, query: GMOFormQueryParams) => {
  switch (id) {
    case FormResolverHandlerConstants.gmoFormPost:
      return GMOFormPostHandler(body, query)

    default:
      return null
  }
}

export const FormResolver = () => {
  const { showAlert } = useAlert()
  const search = useLocation().search
  let payload: FormResolverPayload | null = null

  const alertAndNavigate = () => {
    showAlert('Sorry, Something went wrong')
    navigate(NavigationConstants.home)

    return <></>
  }

  try {
    payload = jwt_decode<FormResolverPayload>(search)
  } catch {
    return alertAndNavigate()
  }

  const handler = getHandler(payload?.query.handler, payload?.body, payload.query)

  if (!handler) {
    return alertAndNavigate()
  }

  return <>{!!handler && handler.resolve()}</>
}
