/* eslint-disable max-lines */
/*eslint max-lines: ["error", {"max": 335}]*/
/* eslint-disable sonarjs/max-switch-cases */
import { addOrderToLocalHistory } from '@dominos/business/functions/customer/add-order-to-local-history'
import { rootActions } from '@dominos/business/root.actions'
import { CookieCategory } from '@dominos/hooks-and-hocs/one-trust/one-trust-provider'
import {
  AccountUpdateStatus,
  AuthErrorType,
  CustomerAddressForDisplay,
  CustomerAuthAndPersonalData,
  CustomerAuthError,
  EmailPasswordPayload,
  OrderHistoryProps,
} from '@dominos/interfaces'
import { IDeliveryAddress } from 'olo-feature-address'
import { Reducer } from 'redux'
import { getType } from 'typesafe-actions'
import uuid from 'uuid'

export interface CustomerInitialState {
  customerToken?: string
  customerId?: string
  providerId?: string
  isLoggingIn: boolean
  isLoginFailed?: boolean
  authErrorType: AuthErrorType
  isLogged: boolean
  addresses: StoredCustomerAddress[]
  deliveryAddresses: IDeliveryAddress[]
  email?: string
  accountUpdate: AccountUpdateStatus
  launchDarklyID: string
  localOrderHistory: OrderHistoryProps[]
  newVouchersCount: number
  searchAddress?: DeliveryAddress
  searchAddressInput?: string
  searchPickupStoreLocation?: StoreGeo
  searchPickupStoreLocationId?: string
}

const updateInitStatus: AccountUpdateStatus = {
  working: false,
  done: false,
  error: undefined,
  message: null,
}

const customerInitState: CustomerInitialState = {
  customerId: undefined,
  customerToken: undefined,
  isLoggingIn: false,
  isLogged: false,
  addresses: [],
  deliveryAddresses: [],
  isLoginFailed: false,
  authErrorType: 'none',
  accountUpdate: updateInitStatus,
  email: undefined,
  launchDarklyID: uuid(),
  localOrderHistory: [],
  newVouchersCount: 0,
  searchAddress: undefined,
}

const setRequestState = (state: CustomerInitialState, emailPasswordPayload: EmailPasswordPayload) => ({
  ...state,
  isLogged: false,
  isLoggingIn: true,
  isLoginFailed: undefined,
  email: emailPasswordPayload.email,
  providerId: undefined,
  customerId: undefined,
  customerToken: undefined,
  authErrorType: getAuthErrorType(null),
})

const setSuccessState = (state: CustomerInitialState, customerAuthData: CustomerAuthData) => ({
  ...state,
  isLogged: true,
  isLoggingIn: false,
  isLoginFailed: false,
  authErrorType: getAuthErrorType(null),
  customerToken: customerAuthData.customerToken,
  providerId: customerAuthData.providerId,
  customerId: customerAuthData.customerId,
})

const setPersonalData = (state: CustomerInitialState, customerPersonalData: CustomerAuthAndPersonalData) => ({
  ...state,
  email: customerPersonalData.email,
  name: customerPersonalData.name,
})

const setFailureState = (state: CustomerInitialState, error: CustomerAuthError | string | null) => ({
  error,
  ...state,
  isLogged: false,
  isLoggingIn: false,
  isLoginFailed: true,
  authErrorType: getAuthErrorType(error),
  customerId: undefined,
  customerToken: undefined,
})

const getAuthErrorType = (error: CustomerAuthError | string | null): AuthErrorType => {
  if (!error || typeof error === 'string') {
    return 'none'
  }

  switch (error.code) {
    case 'auth/invalid-email':
      return 'invalid-email'
    case 'auth/user-not-found':
      return 'user-not-found'
    case 'auth/wrong-password':
      return 'wrong-password'
    case 'auth/email-already-in-use':
      return 'email-already-in-use'
    case 'auth/weak-password':
      return 'weak-password'
    case 'auth/operation-not-allowed':
      return 'operation-not-allowed'
    case 'auth/user-disabled':
      return 'user-disabled'
    default:
      return 'none'
  }
}

const setChangePasswordRequestState = (state: CustomerInitialState) => ({
  ...state,
  accountUpdate: {
    working: true,
    done: false,
    error: undefined,
    message: null,
  },
})

const setchangeDetailsClearState = (state: CustomerInitialState) => ({
  ...state,
  accountUpdate: {
    working: false,
    done: false,
    error: undefined,
    message: null,
  },
})

const setChangePasswordSuccessState = (state: CustomerInitialState) => ({
  ...state,
  accountUpdate: {
    working: false,
    done: true,
    error: undefined,
    message: {
      title: 'Password changed',
      message: `We've signed you in to your account with your new password.`,
    },
  },
})

const setChangePasswordFailureState = (state: CustomerInitialState, error: CustomerAuthError) => ({
  ...state,
  accountUpdate: {
    error,
    working: false,
    done: false,
    message: null,
  },
})

const setChangeNameRequestState = (state: CustomerInitialState) => ({
  ...state,
  accountUpdate: {
    working: true,
    done: false,
    error: undefined,
    message: '',
  },
})

const setChangeNameSuccessState = (state: CustomerInitialState) => ({
  ...state,
  accountUpdate: {
    working: false,
    done: true,
    error: undefined,
    message: {
      title: 'Name changed',
      message: `We've updated your account with your name.`,
    },
  },
})

const setChangePhoneNumberRequestState = (state: CustomerInitialState) => ({
  ...state,
  accountUpdate: {
    working: true,
    done: false,
    error: undefined,
    message: '',
  },
})

const setChangePhoneNumberSuccessState = (state: CustomerInitialState) => ({
  ...state,
  accountUpdate: {
    working: false,
    done: true,
    error: undefined,
    message: {
      title: 'Phone number changed',
      message: `We've updated your account with your new phone number.`,
    },
  },
})

const importLocalOrderHistory = (state: CustomerInitialState, action: RootActions) => ({
  ...state,
  localOrderHistory: action.payload.localOrderHistory?.length
    ? action.payload.localOrderHistory
    : state.localOrderHistory,
})

const resetAutoCompleteScenario = (state: CustomerInitialState) => ({
  ...state,
  searchAddress: undefined,
  searchAddressInput: undefined,
  searchPickupStoreLocation: undefined,
  searchPickupStoreLocationId: undefined,
})

const addAddressActions = (state: CustomerInitialState, address: StoredCustomerAddress) => {
  address.lastUsed = Date.now()

  const newDisplayAddress = address.displayAddress?.toLowerCase()
  const addresses = state.addresses.filter(
    (addr) =>
      'string' === typeof addr.displayAddress &&
      addr.displayAddress !== '' &&
      addr.displayAddress?.toLowerCase() !== newDisplayAddress,
  )

  return {
    ...state,
    addresses: [address, ...addresses],
  }
}

const deleteCustomerAddress = (state: CustomerInitialState, addressToDelete: CustomerAddressForDisplay) => {
  const { streetNo, street, suburb } = addressToDelete.address
  const updatedAddrs = state.addresses.filter((addr) => addr.displayAddress !== `${streetNo} ${street} ${suburb}`)

  return {
    ...state,
    addresses: updatedAddrs,
  }
}

const setDeliveryAddresses = (state: CustomerInitialState, addresses: IDeliveryAddress[]) => ({
  ...state,
  deliveryAddresses: [...addresses],
})

export const customerReducer: Reducer<CustomerInitialState> = (
  state: CustomerInitialState = customerInitState,
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.restartOrder):
      return resetAutoCompleteScenario(state)
    case getType(rootActions.loginUsingEmailPassword.request):
      return setRequestState(state, action.payload)
    case getType(rootActions.createUserWithEmailAndPassword.request):
      return setRequestState(state, action.payload.emailPasswordPayload)
    case getType(rootActions.loginUsingEmailPassword.success):
      return setSuccessState(state, action.payload)
    case getType(rootActions.createUserWithEmailAndPassword.success):
      return setSuccessState(state, action.payload)
    case getType(rootActions.loginUsingEmailPassword.failure):
      return setFailureState(state, action.payload)
    case getType(rootActions.createUserWithEmailAndPassword.failure):
      return setFailureState(state, action.payload)
    case getType(rootActions.logout):
    case getType(rootActions.deleteUserAccount.success):
      return {
        ...state,
        ...customerInitState,
      }
    case getType(rootActions.clearLoginFailed):
      return {
        ...state,
        isLoginFailed: false,
      }
    case getType(rootActions.updateNewVouchersCount):
      return {
        ...state,
        newVouchersCount: action.payload,
      }
    case getType(rootActions.orderFlowSuccess):
      return addOrderToLocalHistory(state, action.payload)
    case getType(rootActions.saveCustomerAddress):
      return addAddressActions(state, action.address)
    case getType(rootActions.deleteCustomerAddress):
      return deleteCustomerAddress(state, action.addressToDelete)
    case getType(rootActions.setDeliveryAddresses):
      return setDeliveryAddresses(state, action.deliveryAddresses)
    case getType(rootActions.addSearchAddress):
      return {
        ...state,
        searchAddress: action.address,
      }
    case getType(rootActions.resetAutoCompleteScenario):
      return resetAutoCompleteScenario(state)
    case getType(rootActions.saveSearchState):
      return {
        ...state,
        searchAddressInput: action.searchInput,
        searchPickupStoreLocation: action.searchLocation,
      }
    case getType(rootActions.savePickupStoreLocationId):
      return {
        ...state,
        searchPickupStoreLocationId: action.locationId,
      }
    case getType(rootActions.consentNotGiven):
      if (action.payload === CookieCategory.Functional) {
        return {
          ...state,
          addresses: [],
        }
      }

      return state
    case getType(rootActions.changeDetailsClear):
      return setchangeDetailsClearState(state)
    case getType(rootActions.changePassword.request):
      return setChangePasswordRequestState(state)
    case getType(rootActions.changePassword.success):
      return setChangePasswordSuccessState(state)
    case getType(rootActions.changeName.request):
      return setChangeNameRequestState(state)
    case getType(rootActions.changeName.success):
      return setChangeNameSuccessState(state)
    case getType(rootActions.changePhoneNumber.request):
      return setChangePhoneNumberRequestState(state)
    case getType(rootActions.changePhoneNumber.success):
      return setChangePhoneNumberSuccessState(state)
    case getType(rootActions.changePassword.failure):
      return setChangePasswordFailureState(state, action.payload)
    case getType(rootActions.loginUsingPayPal.success):
      return setPersonalData(setSuccessState(state, action.payload), action.payload)
    case getType(rootActions.loginUsingGoogle.success):
      return setPersonalData(setSuccessState(state, action.payload), action.payload)
    case getType(rootActions.loginUsingFacebook.success):
      return setPersonalData(setSuccessState(state, action.payload), action.payload)
    case getType(rootActions.importFosSessionFromNativeApp):
      return importLocalOrderHistory(state, action)
    default:
      return state
  }
}
