import ExpireOrderPopup from '@dominos/components/order-state/expire-order-popup'
import ResetOrderPopup from '@dominos/components/order-state/reset-order-popup'
import React from 'react'
import useExpireCurrentOrder from './expire-current-order'
import { useTrackOrResetOrder } from './track-or-reset-order'

export const OrderGuard = ({ children }: { children: React.ReactNode }) => {
  const { prompt } = useTrackOrResetOrder()
  const { notifyOrderExpired, onExpiry } = useExpireCurrentOrder()

  return (
    <>
      {children}
      {notifyOrderExpired && <ExpireOrderPopup onClose={onExpiry} />}
      {prompt && <ResetOrderPopup />}
    </>
  )
}
