import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import RadioInputField from '@dominos/components/inputs/radio-input-field'
import { ServiceMethodSubTypeProps } from '@dominos/interfaces/checkout'
import { StaticSvgIcon } from '@dominos/res'

import css from '../../service-method-type.less'
import { DropNGoPopup } from './drop-n-go-popup'

const iconWidth = 22
const iconHeight = 22
const icon = 'question'

const modalId = 'drop-n-go-popup'

export const DropNGo = ({ name, value, onChecked, labelText, testID, selectedSubType }: ServiceMethodSubTypeProps) => {
  const { t } = useTranslation('checkout')
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleChecked = (_: string, targetValue: string | number) => {
    onChecked(targetValue as Bff.Stores.ServiceMethodSubTypes)
  }

  const showModalPopup = () => setShowModal(true)
  const closeModalPopup = () => setShowModal(false)

  const onOptIn = () => {
    onChecked(value)
    setShowModal(false)
  }

  const onOptOut = () => {
    if (value === selectedSubType) {
      onChecked(undefined)
    }
    setShowModal(false)
  }

  return (
    <div className={css.flexBoxWrapper}>
      <RadioInputField
        fieldName={name}
        value={value}
        fieldId={testID}
        onChecked={handleChecked}
        labelText={labelText}
        testID={`${testID}.radio-input-field`}
        selectedOption={selectedSubType}
      />
      <div
        data-testid={`${testID}.${icon}`}
        role='button'
        aria-label={t('learnMoreButton', { defaultValue: 'Learn More' })}
        aria-controls={modalId}
        className={css.learnMoreButton}
        onClick={showModalPopup}
      >
        <StaticSvgIcon name={icon} width={iconWidth} height={iconHeight} isUnstyled />
      </div>
      {showModal && (
        <DropNGoPopup id={modalId} testID={testID} onClose={closeModalPopup} onOptIn={onOptIn} onOptOut={onOptOut} />
      )}
    </div>
  )
}
