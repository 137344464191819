export const duplicateChanges = <T extends { quantity: number }>(changes: T[] | undefined = []) => {
  const result: T[] = []

  changes.forEach((change) => {
    for (let i = 0; i < change.quantity; i++) {
      result.push({ ...change, quantity: 1 })
    }
  })

  return result
}
