import { useQuery } from '@apollo/client'
import { customerAddressQuery } from '@dominos/business/queries'
import { getCustomerId } from '@dominos/business/functions/customer'
interface CustomerAddressQueryResults {
  id: string
  addresses: Bff.Customers.CustomerAddress[]
}

export interface CustomerAddressData {
  customer: CustomerAddressQueryResults | null
}

export const useCustomerAddressData = (isCustomerLoggedIn: boolean | undefined) => {
  const customerId = getCustomerId()
  const { data } = useQuery<CustomerAddressData>(customerAddressQuery, {
    fetchPolicy: 'no-cache',
    variables: { customerId },
    skip: !isCustomerLoggedIn || !customerId,
  })

  if (!data?.customer?.addresses) {
    return []
  }

  return data.customer.addresses.reverse()
}
