import { ActionButton } from '@dominos/components'
import { useForgeRock } from '@dominos/hooks-and-hocs'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './my-password.less'
import { NavigationConstants } from '@dominos/navigation'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'

const MyPasswordForgeRock = (props: RouteComponentProps) => {
  const { t } = useTranslation('login')
  const { updatePasswordForgeRock } = useForgeRock()

  const onForgeRockPasswordChange = async () => {
    if (isNativeApp()) {
      notifyNativeApp('update-oauth-password')

      return
    }
    await updatePasswordForgeRock(`${NavigationConstants.myAccount}/password`)
  }

  return (
    <div className={styles.wrapper}>
      <>
        <div className={styles.content}>
          <h2>{t('MyAccountPasswordHeading', { defaultValue: 'My Password' })}</h2>
          <p>
            {t('MyAccountForgeRockPasswordDescription', {
              defaultValue: 'To change your password, please click on the button to be redirected',
            })}
          </p>
        </div>
        <div className={styles.content}>
          <div className={styles.centerButton}>
            <ActionButton onPress={onForgeRockPasswordChange} testID={'change-password-forgerock-action-button'}>
              {t('ChangePassword', { defaultValue: 'Change Password' })}
            </ActionButton>
          </div>
        </div>
      </>
    </div>
  )
}

export { MyPasswordForgeRock }
