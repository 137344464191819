import { ActionButton, Modal } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import css from './surcharge-info-popup.less'
import { useTranslation } from 'react-i18next'

interface SurchargePopupProps {
  testID: string
  name: string
  description: string
  onClose: () => void
}

const iconWidth = 21
const iconHeight = 21
const icon = 'cross'

const SurchargeInfoPopup = ({ testID, name, description, onClose }: SurchargePopupProps) => {
  const { t } = useTranslation('basket')
  const { colours } = useDominosTheme()

  return (
    <>
      <Modal testID={testID} onModalClose={onClose}>
        <div data-testid={testID} className={css.cardContainer}>
          <div
            data-testid={`${testID}.close-button`}
            role='button'
            aria-label={t('surchargeInfoCloseButton', { defaultValue: 'Close Button' })}
            className={css.closeButton}
            onClick={onClose}
          >
            <StaticSvgIcon name={icon} width={iconWidth} height={iconHeight} isUnstyled />
          </div>
          <div className={css.contentContainer}>
            <h4 data-testid={`${testID}.name`} className={css.contentHeader}>
              {name}
            </h4>

            <div className={css.contentBody}>
              <div data-testid={`${testID}.description`}>{description}</div>
            </div>

            <div className={css.contentFooter}>
              <ActionButton
                text={t('Got it', { defaultValue: 'Got it' })}
                onPress={onClose}
                testID={`${testID}.close-button-2`}
                containerStyle={{ width: '100%', backgroundColor: colours.actionStandard }}
                disabledBackgroundColor={colours.web.actionDisabled}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SurchargeInfoPopup
