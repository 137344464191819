import { getCustomerId } from '@dominos/business/functions/customer'
import { DateTime } from 'luxon'
import { deviceType, isAndroid, isDesktop, isIOS, isMobile, isSmartTV, isTablet, osName } from 'react-device-detect'

export const createFosSessionMapper = (rootReducer: RootReducer) => (): Bff.Fos.Session => {
  const currentDevice = isAndroid
    ? 'android'
    : isIOS
    ? 'ios'
    : isDesktop
    ? 'desktop'
    : isMobile
    ? 'mobile'
    : isTablet
    ? 'tablet'
    : isSmartTV
    ? 'smartTV'
    : deviceType

  return {
    device: currentDevice,
    isSavedOrder: false,
    loggedIn: !!getCustomerId(),
    os: window.navigator?.webdriver ? 'regression-chrome-driver' : osName,
    startTime: rootReducer.currentOrderDetailsReducer.advanceOrder.startedAt,
    timeZoneOffSet: DateTime.local().offset,
  }
}
