import React, { useEffect, useState } from 'react'
import { isKioskOrderType } from '@dominos/business/functions'
import { LoadableComponent } from 'loadable__component'
import { useBreakpoints } from '@dominos/hooks-and-hocs'

export type DynamicComponentProps<T> = { default: React.ComponentType<T> }
export type DynamicComponent<T> = () => Promise<DynamicComponentProps<T>>

type ImportDynamicComponentProps<T> = {
  desktop: LoadableComponent<T>
  touch?: LoadableComponent<T>
  kiosk?: LoadableComponent<T>
  children: (Component: LoadableComponent<T>) => React.ReactElement
}

export function ImportDynamicComponent<T>({ touch, desktop, kiosk, children }: ImportDynamicComponentProps<T>) {
  const [Component, setComponent] = useState<LoadableComponent<T>>()
  const { isMobile } = useBreakpoints()

  useEffect(() => {
    // Assign a callback with an import() call
    let Component: LoadableComponent<T>

    if (isMobile && !!touch) {
      Component = touch as LoadableComponent<T>
    } else if (isKioskOrderType() && !!kiosk) {
      Component = kiosk as LoadableComponent<T>
    } else {
      Component = desktop
    }

    setComponent(Component)
  }, [desktop, touch, kiosk])

  // The actual component is assigned to the 'Component' prop
  return Component ? children(Component) : null
}
