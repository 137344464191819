import { getFullUrl } from '@dominos/business/functions/menu/get-images'

const MAXIMUM_QUANTITY: number = 9

export const mapToppingItemFromIngredient = ({
  ingredient,
  quantity = 0,
  min: minQuantity,
  max: maxQuantity,
  allowAdd,
  allowRemove,
}: LimitedRecipeIngredient): IngredientItemProps => ({
  quantity,
  allowAdd,
  allowRemove,
  code: ingredient.code,
  title: ingredient.media?.name || '',
  thumb: (ingredient.media?.image && getFullUrl(ingredient.media.image)) || '',
  value: ingredient.price || 0,
  minimumReached: (minQuantity || 0) === quantity,
  maximumReached: (maxQuantity || MAXIMUM_QUANTITY) === quantity,
})
