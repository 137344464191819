import { useProductsBatched } from '@dominos/hooks-and-hocs'
import {
  flattenIngredientsForDimensionSet,
  flattenIngredientTypeRulesForDimensionSet,
} from '@dominos/hooks-and-hocs/menu/functions'
import { useEffect, useMemo } from 'react'

type IngredientTypeCode = Bff.Common.IngredientTypeCode
type DimensionSet = Bff.Dimensions.DimensionSet

const useProductIngredientsDictionary = (productCodes: string[]) => {
  const { fetchProductsByCodes, products: fetchedProducts, loading } = useProductsBatched()

  useEffect(() => {
    fetchProductsByCodes(productCodes)
  }, [productCodes])

  const getIngredientTypeRule = useMemo(
    () =>
      (
        ingredientType: IngredientTypeCode,
        dimensionSet?: DimensionSet,
      ): Map<string, Bff.Products.ProductIngredientTypeRule | undefined> => {
        const transformedIngredientTypeRules = new Map<string, Bff.Products.ProductIngredientTypeRule | undefined>()
        fetchedProducts.forEach((product) => {
          transformedIngredientTypeRules.set(
            product.code,
            flattenIngredientTypeRulesForDimensionSet(ingredientType, dimensionSet, product),
          )
        })

        return transformedIngredientTypeRules
      },
    [fetchedProducts],
  )

  const getIngredients = useMemo(
    () =>
      (ingredientType: IngredientTypeCode, dimensionSet?: DimensionSet): Map<string, IngredientModel[]> => {
        const transformedIngredients = new Map<string, IngredientModel[]>()
        fetchedProducts.forEach((product) => {
          transformedIngredients.set(
            product.code,
            flattenIngredientsForDimensionSet(ingredientType, dimensionSet, product),
          )
        })

        return transformedIngredients
      },
    [fetchedProducts],
  )

  return {
    get loading() {
      return loading
    },
    getIngredients,
    getIngredientTypeRule,
  }
}

export { useProductIngredientsDictionary }
