import { TFunction } from 'i18next'

export const useOrderLaterOrNowDescriptions = (t: TFunction) => {
  const simplifiedOrderTitle = t('SimplifiedOrderSchedule', {
    defaultValue: 'Schedule for Later',
  })
  const nowButtonTitle = t('SimplifiedOrderNow', {
    defaultValue: 'Now',
  })
  const laterButtonTitle = t('SimplifiedOrderLater', {
    defaultValue: 'Later',
  })

  return {
    simplifiedOrderTitle,
    nowButtonTitle,
    laterButtonTitle,
  }
}
