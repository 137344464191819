import { useSelector } from 'react-redux'

export const CHARITY_SURCHARGE_CODE = 'Order.SmallChange.BigChange'

export interface RoundUpForCharityDetails {
  amount: number
}

export const useRoundUpForCharityDetails = () =>
  useSelector((store: RootReducer) => store.currentOrderDetailsReducer.roundUpForCharity)
