import { getQuantityWithChange } from './get-quantity-with-change'

type Ingredient = Bff.Menu.old.Ingredient
type ProductRule = Bff.Menu.old.ProductRule

/**
 * Applies the `change` to the matching recipe `ingredients`
 * or adds an additional topping change where the ingredient
 * `code` is not found in the provided recipe `ingredients`.
 *
 * @param ingredients Collection of ingredients
 * @param change Collection of ingredient changes
 * @param toppings Possible topping swaps
 * @param rules (optional) Ingredient swap rules
 */
export const getIngredientRecipeWithChange = (
  ingredients: LimitedRecipeIngredient[],
  change: BasketLineChange,
  toppings: Ingredient[],
  rules?: ProductRule,
): LimitedRecipeIngredient[] => {
  // Find any matching ingredient swaps and/or ingredient matching the change code
  const topping = toppings.find(({ code }) => change.code === code)
  const recipeIndex = ingredients.findIndex(({ ingredient }) => change.code === ingredient.code)

  // Apply change to recipe topping
  if (recipeIndex >= 0) {
    const recipeIngredient = ingredients[recipeIndex]
    const quantity = getQuantityWithChange(recipeIngredient.quantity, change)
    ingredients[recipeIndex] = { ...recipeIngredient, quantity }
  }

  // Apply change to additional topping, not in the recipe
  if (recipeIndex < 0 && topping && change.action === 'Add') {
    ingredients.push({
      ingredient: topping,
      quantity: getQuantityWithChange(0, change),
      max: rules?.maxPerIngredient,
      allowAdd: true,
      allowRemove: true,
    })
  }

  return ingredients
}
