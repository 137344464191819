export const getProductItemSignature = (
  item?: BasketLine | BasketCoupon | Bff.Orders.OrderDetails.Basket.ProductLine | null,
) => {
  if (!item) {
    return '{}'
  }

  switch (item.type) {
    case 'BasketCoupon':
      return `${item.code}_${item.couponNo}`
    case 'BasketLine':
      return JSON.stringify({
        productCode: item.productCode,
        sizeCode: item.sizeCode,
        sauce: item.sauce,
        base: item.base,
        toppings: item.toppings,
        portions: item.portions,
        options: item.options,
        itemNo: item.itemNo,
      })
    case 'ProductLine':
      return JSON.stringify({
        productCode: item.productCode,
        sizeCode: item.sizeCode,
        sauce: item.sauce,
        toppings: item.toppings,
        options: item.options,
      })
  }
}
