import { useGeocoder } from '@dominos/hooks-and-hocs'

import { mapGoogleGeoToDeliveryAddressRequestWithEmptyPrediction } from '@dominos/hooks-and-hocs/location/use-auto-complete/providers/google/map-google-geo-to-delivery-address-request'
import { AddressValidationResult } from '@dominos/hooks-and-hocs/location/use-validate-address'
import * as helpers from './use-corrected-address.helpers'

type UseCorrectedAddressProps = {
  countryCode: BffContext.Countries
}

/**
 * Hook for getting corrected address, used in delivery address component
 */
export const useCorrectedAddress = (props: UseCorrectedAddressProps) => {
  const geocoder = useGeocoder()

  const getCorrectedAddress = async (
    correctedFields: AdditionalFields,
    prevAddress: DeliveryAddressRequest,
    validationRules: AddressValidationResult,
  ) => {
    const excludeAddition = validationRules.invalidFields?.filter((field) => field.rules.includes('additional'))

    const correctedFieldsAsArray = helpers.convertAdditionalFieldsToArray(correctedFields)

    const googleFields = correctedFieldsAsArray.filter((field) =>
      excludeAddition?.every((addition) => addition.attribute !== field.type),
    )

    const additionFields = correctedFieldsAsArray.filter((field) =>
      excludeAddition?.some((addition) => addition.attribute === field.type),
    )

    let addressResult: DeliveryAddressRequest

    if (googleFields && googleFields.length > 0) {
      const addressAsString = helpers.mapAdditionalFieldsToDisplayAddress(googleFields, prevAddress, props.countryCode)
      if (!addressAsString) return undefined

      const result = await geocoder.geocodeAddress(addressAsString)
      const googleAddressResponse = mapGoogleGeoToDeliveryAddressRequestWithEmptyPrediction(result, props.countryCode)

      if (!googleAddressResponse) return undefined

      const invalidFields: string[] = [
        ...helpers.validateAddressComponents(
          prevAddress.addressComponents,
          googleAddressResponse.addressComponents,
          props.countryCode,
        ),
        ...helpers.validateAddressComponents(googleFields, googleAddressResponse.addressComponents, props.countryCode),
      ]

      if (invalidFields.length > 0) return undefined

      addressResult = googleAddressResponse
    } else {
      addressResult = prevAddress
    }

    if (additionFields.length > 0) {
      additionFields.forEach((field) => {
        const element = addressResult.addressComponents.filter((el) => el.type === field.type)

        if (element && element.length > 0) {
          element[0].value = field.value
        } else {
          addressResult.addressComponents.push(field)
        }
      })
    }

    return addressResult
  }

  return {
    getCorrectedAddress,
  }
}
