import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 26,
  height: 31,
}

const RobotDeliveryIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#000000'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 26 31'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-22.000000, -280.000000)'>
          <g transform='translate(21.714286, 280.750000)'>
            <g transform='translate(0.921745, 10.681588)' fill={fill}>
              <rect x='0' y='0' width='23.6207949' height='2.16408848'></rect>
              <circle cx='4.23608779' cy='16.6435952' r='2.87638704'></circle>
              <circle cx='11.8103975' cy='16.5012961' r='2.87638704'></circle>
              <circle cx='19.3847071' cy='16.5012961' r='2.87638704'></circle>
              <path d='M0.161505415,3.29357602 C-0.0538351383,3.739495 -0.0538351383,4.9124545 0.161505415,6.81245451 C0.376845968,8.71245452 0.806240088,10.6067768 1.44968778,12.4954215 L22.3510812,12.4954215 C22.9641732,10.2405008 23.3485137,8.34617847 23.504103,6.81245451 C23.6596922,5.27873055 23.6596922,4.10577105 23.504103,3.29357602 L19.1618765,3.29357602 L0.161505415,3.29357602 Z'></path>
            </g>
            <path d='M20.7696623,12.5729554 L21.0008282,-3.93336157e-15' stroke={fill}></path>
            <path
              d='M21.5272959,-0.299920428 C22.092675,0.403170797 22.6424846,0.905596359 23.1767249,1.20735626 C23.7109653,1.50911616 24.530431,1.78011114 25.6351223,2.0203412 C24.5722069,2.87223477 23.6683067,3.40457928 22.9234218,3.61737473 C22.1785369,3.83017019 21.2407313,3.82395612 20.110005,3.59873251 L21.5272959,-0.299920428 Z'
              fill={fill}
              transform='translate(22.872564, 1.736261) rotate(-6.000000) translate(-22.872564, -1.736261) '
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { RobotDeliveryIcon }
