import React from 'react'

import { StaticSvgIcon } from '@dominos/res'
import { Spinner } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

import css from './address-list.less'

export const AddressLineSuffix = ({ testID, content, isLoading }: AddressLineSuffixProps) => {
  const theme = useDominosTheme()

  if (!content) {
    return null
  }

  if (isLoading) {
    return <Spinner testID={`${testID}.loading`} color={theme.colours.lightSlate} />
  }

  if (content === 'caret') {
    return (
      <div data-testid={`${testID}.caret`} className={css.suffix}>
        <StaticSvgIcon name='chevron' isUnstyled fill={theme.colours.overlayColor} />
      </div>
    )
  }

  return (
    <p className={css.storeDetailsDistance} data-testid={`${testID}.distance`}>
      {`${content.toFixed(1)} KM`}
    </p>
  )
}
