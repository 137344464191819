import { storeQuery } from '@dominos/business/queries'
import { useCallback, useMemo } from 'react'

import { rootActions } from '@dominos/business'
import { ErrorScope, useErrorContext } from '@dominos/components'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'

interface StoreResponse {
  store: Bff.Stores.Store
}

export const useStoreSelect = () => {
  const dispatch = useDispatch()
  const { notifyError } = useErrorContext()

  const handleStoreSearchError = useCallback((error: ApolloError) => {
    dispatch(rootActions.resetCurrentStore())
    notifyError({
      error,
      definitions: {},
      handlers: {},
      scope: ErrorScope.SelectStore,
    })
  }, [])

  const [storeSearch, { data, loading, error }] = useLazyQuery<
    StoreResponse,
    { storeNo: number; orderTime?: string | null; locationId?: string }
  >(storeQuery, {
    onError: handleStoreSearchError,
  })

  const store = useMemo(() => data?.store, [data])

  const getStore = (storeNo: number, orderTime?: string | null, locationId?: string) =>
    storeSearch({ variables: { storeNo, orderTime, locationId } })

  return {
    getStore,
    store,
    loading,
    error,
  }
}
