export const getNamesFromCard = (cardName: string | undefined) => {
  const names = {
    first: '',
    last: '',
  }
  if (cardName) {
    const titles = ['DR', 'MR', 'MRS', 'MS']
    const parts = cardName.split(' ')
    if (parts.length > 0) {
      if (titles.includes(parts[0].toUpperCase())) {
        parts.shift()
      }
      names.first = parts[0] || ''
      names.last = (parts.length > 1 && parts[parts.length - 1]) || ''
    }
  }

  return names
}
