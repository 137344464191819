import { useSelector } from 'react-redux'
import { useKioskConfig } from '@dominos/hooks-and-hocs'

export const useKiosk = () => {
  const { application } = useSelector((state: RootReducer) => state.applicationReducer)
  const kioskConfig = useKioskConfig()

  return {
    isKioskOrder: application === 'KioskWeb',
    storeNo: kioskConfig?.storeNo,
    kioskId: kioskConfig?.kioskId,
    terminalId: kioskConfig?.terminalId,
    isValidKioskOrder:
      application === 'KioskWeb' && !!kioskConfig?.storeNo && kioskConfig?.storeNo > 0 && !!kioskConfig?.kioskId,
    appStarted: !!kioskConfig,
    supportedSubServiceMethods: kioskConfig?.supportedSubServiceMethods,
  }
}
