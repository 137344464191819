import classnames from 'classnames'
import { BasketLineData, getDefaultSizeAndEnergy } from '@dominos/business/functions/basket'
import { getImages } from '@dominos/business/functions/menu'
import { Legends, ProductPricingDetails } from '@dominos/components'
import { useFeatures, usePricing } from '@dominos/hooks-and-hocs'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMenuStyles } from '../menu-card.styles'
import { MenuCardDefaultProps } from './menu-card-default.interfaces'
import css from './menu-card-default.less'

const IGNORE = ['kJ.Pizza', 'kJ.Standard']

export const MenuCardDefault = ({
  testID,
  item: menuItem,
  children,
  onClick,
  isFiltered = false,
}: MenuCardDefaultProps) => {
  const styles = useMenuStyles(!!children)
  const { t } = useTranslation('menu')
  const [editEnabled, vpimUpgradeButtonEnabled, leftNavMenuEnabled] = useFeatures(
    'menu-card-edit-button',
    'VoucherPricingInMenuUpgradeButton',
    'LeftNavMenu',
  )

  const imageUrl = getImages(menuItem).menuImage.fullUrl
  const kj = getDefaultSizeAndEnergy(menuItem).energyKJ

  const { formattedPrice, formattedPromoPrice, getPrice } = usePricing()
  const legends: MenuLegend[] =
    'legends' in menuItem ? (menuItem.legends || []).filter((items) => !IGNORE.includes(items.code)) : []

  const showPrice = !!formattedPrice && (!isFiltered || !vpimUpgradeButtonEnabled)

  const onItemClick = () => {
    if (onClick) {
      onClick(menuItem, undefined)
    }
  }

  useEffect(() => {
    getPrice({
      item: menuItem,
    } as BasketLineData)
  }, [])

  return (
    <div
      data-testid={testID}
      className={classnames(css.card, leftNavMenuEnabled && css.leftNavMenu)}
      style={styles.card}
      onClick={onItemClick}
    >
      <div>
        {editEnabled && (
          <button data-testid={`${testID}.button`} className={css.viewButton} aria-label={t('View')}>
            {t('View')}
          </button>
        )}

        <div className={css.imageContainer}>
          <Legends
            testID={'MenuLegend'}
            containerStyle={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              right: 2,
              bottom: 2,
            }}
            imageStyle={{ width: 22 }}
            legends={legends}
          />

          <img data-testid={`${testID}.image`} className={css.image} style={styles.image} src={imageUrl} alt='' />
        </div>

        <div className={css.cardContent} style={styles.cardContent}>
          <p data-testid={`${testID}.title`} className={css.title}>
            {(menuItem.media && menuItem.media.name) || ''}
          </p>

          <div data-testid={`${testID}.subtitle`} className={css.subtitle}>
            <ProductPricingDetails
              testID={`${testID}.subtitle`}
              showPrice={showPrice}
              price={formattedPrice}
              promoPrice={formattedPromoPrice}
              kj={kj}
              showEnergyDividerForPromo={false}
            />
          </div>

          {menuItem.media && menuItem.media.description && (
            <p data-testid={`${testID}.description`} className={css.description}>
              {menuItem.media.description}
            </p>
          )}
        </div>
      </div>
      {children}
    </div>
  )
}
