import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { getFeatureFlags } from './feature-flag-helper'
import { defaultFeatures } from '@dominos/hooks-and-hocs/features/default-features'
import { FeatureFlagType } from '../feature-flag.interface'

export const useLaunchDarklyFlags = (): { flags: FeatureFlagType } => {
  const sdkFlags = useFlags()
  const flags = useMemo(() => getFeatureFlags(sdkFlags) ?? defaultFeatures ?? {}, [sdkFlags])

  return { flags }
}
