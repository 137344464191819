import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const AppOnlyIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <g id='Mobile-App' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M16,28 C14.896,28 14,27.104 14,26 C14,24.896 14.896,24 16,24 C17.104,24 18,24.896 18,26 C18,27.104 17.104,28 16,28 M21.3333333,1 L10.6666667,1 C9.19644444,1 8,2.261875 8,3.8125 L8,28.1875 C8,29.738125 9.19644444,31 10.6666667,31 L21.3333333,31 C22.8035556,31 24,29.738125 24,28.1875 L24,3.8125 C24,2.261875 22.8035556,1 21.3333333,1'
          id='Fill-1'
          fill={fill}
        />
      </g>
    </svg>
  )
}
