import { useLazyQuery } from '@apollo/client'
import { productQuery } from '@dominos/business/queries/product'

interface FetchProductProps {
  productCode: string
  storeNo: number
  serviceMethod: BffContext.ServiceMethods
  requestTime: string | null
}

export const useFetchProduct = () => {
  const [fetch, { loading, error, data }] = useLazyQuery<ProductResponse>(productQuery, {
    notifyOnNetworkStatusChange: true,
  })

  const fetchProduct = ({ productCode, storeNo, serviceMethod, requestTime }: FetchProductProps) =>
    fetch({
      variables: {
        productCode,
        storeNo,
        serviceMethod,
        requestTime,
      },
    })

  return {
    fetchProduct,
    loading,
    product: data?.product,
    error,
  }
}
