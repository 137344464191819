import React from 'react'

import { calcWebFontWeight } from '@dominos/business/functions/text/text-functions'

import css from './link-button.less'

export const LinkButton = ({ testID, weight, style, text, onPress }: LinkButtonProps) => {
  const styles = {
    ...style,
    fontWeight: calcWebFontWeight(weight),
  }

  return (
    <button data-testid={testID} className={css.link} style={styles} onClick={onPress}>
      {text}
    </button>
  )
}
