import { NativeAppLoginContainer } from '@dominos/components/login'
import { ErrorNotifier } from '@dominos/components'
import { useNativeAppData } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import React, { useEffect } from 'react'
import css from './login-scene.less'
import { getNativeFeatures, LoginSceneMessage, postWebViewMessage } from '@dominos/business/functions/native-app'
import { NativeAppAuth } from '@dominos/components/universal-auth/native-app-auth'

export const NativeAppLoginScene = () => {
  const enableNativeUniversalAuth = getNativeFeatures('UniversalAuthentication')

  useNativeAppData()
  useEffect(() => {
    postWebViewMessage<LoginSceneMessage>({ route: NavigationConstants.nativeAppLogin })
  }, [])

  const NativeLoginScene = () => (
    <div className={css.wrapper}>
      <div className={css.main}>
        <ErrorNotifier namespace={'login'}>
          <NativeAppLoginContainer />
        </ErrorNotifier>
      </div>
    </div>
  )

  return enableNativeUniversalAuth ? <NativeAppAuth initial={'login'} /> : <NativeLoginScene />
}
