import { RoundUpForCharityDetails } from '@dominos/components/charity/round-up'
import { MyDetailsModelProps } from '@dominos/interfaces'
import { BasketReducerProps } from '../../reducers/basket.reducer'
import { CurrentOrderDetailsReducerProps } from '../../reducers/current-order-details.reducer'
import { platform } from '../common/get-platform'
import { flattenBasketToInitialise } from './flatten-basket'
import { getOrderHeaderTime } from './get-order-header-time'

export interface CreateInitialiseOrderPayloadProps {
  payment: Bff.Orders.InitialiseOrderPayment
  basket: BasketReducerProps
  currentOrder: CurrentOrderDetailsReducerProps
  details: MyDetailsModelProps
  customerId?: string
  roundUpForCharityDetails?: RoundUpForCharityDetails
  tipAmount?: number
  adyenGivingEnabled?: boolean
  roundUpForCharityEnabled?: boolean
  isKioskOrder?: boolean
  donationDetails?: Bff.Orders.InitialiseOrderDonation[]
  passiveSaleFromStore?: number | null
}

export const createInitialiseOrderPayload = ({
  payment,
  basket,
  currentOrder,
  details,
  customerId,
  roundUpForCharityDetails,
  tipAmount = 0,
  isKioskOrder = false,
  passiveSaleFromStore = null,
}: CreateInitialiseOrderPayloadProps): Bff.Orders.InitialiseOrderInput | null => {
  const header = createOrderHeader(
    basket,
    currentOrder,
    details,
    customerId,
    !!roundUpForCharityDetails,
    tipAmount,
    isKioskOrder,
    passiveSaleFromStore,
  )

  if (basket == null || header == null) {
    return null
  }

  const flattenedBasket = flattenBasketToInitialise(basket.basket)

  if (flattenedBasket == null) {
    return null
  }

  return {
    header,
    payment,
    basket: flattenedBasket,
    id: currentOrder.orderId,
    advanceOrderId: currentOrder.advanceOrder.id,
  }
}

export const createOrderHeader = (
  basket: BasketReducerProps,
  currentOrder: CurrentOrderDetailsReducerProps,
  details: MyDetailsModelProps,
  customerId?: string,
  roundUpForCharity: boolean = false,
  tipAmount: number = 0,
  isKioskOrder: boolean = false,
  passiveSaleFromStore: number | null = null,
): Bff.Orders.InitialiseOrderHeader | null => {
  if (currentOrder == null || basket == null || currentOrder.basketHeaderData == null) {
    return null
  }
  const customer = createCustomer(details, customerId, isKioskOrder)
  // TODO: Revisit, seems like a workaround for a bug that might have existed at that point in time
  if (details.provider && details.provider.paymentMethod === 'MobileEftpos' && customer && customer.customerId) {
    delete customer.customerId
  }
  const deliveryAddress =
    currentOrder.selectedDeliveryAddress &&
    currentOrder.selectedDeliveryAddress.customerAddress &&
    mapDeliveryAddressToInitiateOrder(currentOrder.selectedDeliveryAddress, details)

  const { time, storeNo, serviceMethod, serviceMethodSubType, carMakeAndModel, carColour, requireExtraChange } =
    currentOrder.basketHeaderData

  if (storeNo == null || customer == null || serviceMethod == null) {
    return null
  }

  const firstAppOrderOptIn = window?.nativeAppStartupData?.firstAppOrderOptIn

  return {
    storeNo,
    serviceMethod,
    serviceMethodSubType,
    deliveryAddress,
    customer,
    orderTime: getOrderHeaderTime(time),
    channel: `${platform} application`,
    eclub: details.eclub ?? undefined,
    ...(serviceMethodSubType === 'CarParkDelivery' ? { carMakeAndModel, carColour } : {}),
    ...((!!details.loyaltyEnrolled?.isEnrolled && { loyaltyEnroll: details.loyaltyEnrolled?.isEnrolled }) || {}),
    charitySurcharge: roundUpForCharity,
    ...(tipAmount !== 0 ? { tip: tipAmount } : {}),
    ...(requireExtraChange ? { requireExtraChange } : {}),
    ...(firstAppOrderOptIn ? { firstAppOrderOptIn } : {}),
    properties: passiveSaleFromStore
      ? [{ key: 'passiveSaleFromStore', value: passiveSaleFromStore.toString() }]
      : undefined,
  }
}

const mapDeliveryAddressToInitiateOrder = (
  address: StoredCustomerAddress,
  details: MyDetailsModelProps,
): Bff.Orders.InitialiseOrderDeliveryAddress => {
  const { buildingName, unitNo, streetName, streetNo, postCode, state, suburb, floorNo } = address.customerAddress
  const beware = details.bewareOfAnimal ? ' - BEWARE OF ANIMAL' : ''
  const instructions = details.deliveryInstructions || ''

  return {
    address: {
      buildingName: buildingName ?? undefined,
      unitNo: unitNo ?? undefined,
      streetNo: streetNo ?? undefined,
      street: streetName ?? undefined,
      suburb: suburb ?? undefined,
      state: state ?? undefined,
      postCode: postCode ?? undefined,
      floorNo: floorNo ?? undefined,
    },
    latitude: address?.geo?.latitude,
    longitude: address?.geo?.longitude,
    deliveryInstructions: (address.additionalInformation ?? '') + instructions + beware,
    displayAddress: address.displayAddress,
    locale: address.locale ?? undefined,
  }
}

/**
 * Creates a formatted customer object by mapping an optional `customerId`
 * and required customer `details` together, otherwise returns `null`.
 * Requires customer `mobileNumber`, `emailAddress` and `name` details.
 * @param details Customer details object
 * @param customerId (optional) Customer GUID or Firebase ID
 */
const createCustomer = (
  details: MyDetailsModelProps,
  customerId?: string,
  isKioskOrder?: boolean,
): Bff.Orders.InitialiseOrderCustomer | null => {
  const invalidMobileAndEmailForRegularOrder =
    !isKioskOrder && (details.mobileNumber == null || details.emailAddress == null)
  if (invalidMobileAndEmailForRegularOrder || details.name == null) {
    return null
  }

  return {
    customerId,
    phoneNumber: details.mobileNumber,
    email: details.emailAddress,
    name: details.name,
    alternateName: details.alternateName,
    organisationName: details.organisationName,
  }
}
