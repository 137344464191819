import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const DrinksIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='0 0 2.82478578 0 2.82478578 6.6594 0 6.6594' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-12' transform='translate(3.000000, 0.000000)'>
          <path
            d='M17,7.0415 L1,7.0415 C0.448,7.0415 0,6.5935 0,6.0415 C0,5.4895 0.448,5.0415 1,5.0415 L17,5.0415 C17.552,5.0415 18,5.4895 18,6.0415 C18,6.5935 17.552,7.0415 17,7.0415'
            id='Fill-1'
            fill={fill}
          />
          <path
            d='M5.8394,22.042 L12.1124,22.042 L14.8044,7.042 L3.1924,7.042 L5.8394,22.042 Z M12.9484,24.042 L5.0004,24.042 C4.5154,24.042 4.1004,23.694 4.0154,23.216 L1.0154,6.215 C0.9644,5.924 1.0444,5.625 1.2344,5.399 C1.4234,5.172 1.7044,5.042 2.0004,5.042 L16.0004,5.042 C16.2964,5.042 16.5774,5.173 16.7674,5.4 C16.9574,5.627 17.0364,5.927 16.9844,6.218 L13.9334,23.219 C13.8474,23.695 13.4334,24.042 12.9484,24.042 L12.9484,24.042 Z'
            id='Fill-3'
            fill={fill}
          />
          <path
            d='M16,9.1018 L13.701,23.0648 L4.345,23.0648 L2,9.1018 L16,9.1018 Z M12.207,11.329 C11.664,11.225 11.141,11.579 11.036,12.121 L11.036,12.121 L9.4799,20.1067 C9.3749,20.6487 9.7299,21.1727 10.2729,21.2777 C10.3369,21.2897 10.3999,21.2957 10.4629,21.2957 C10.9329,21.2957 11.3509,20.9627 11.4439,20.4847 L11.4439,20.4847 L13,12.5 C13.104,11.958 12.749,11.433 12.207,11.329 Z'
            id='Combined-Shape'
            fill={fill}
          />
          <g id='Group-11' transform='translate(11.996607, 0.000000)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-10' />
            <path
              d='M1.00139289,6.6594 C0.943392889,6.6594 0.885392889,6.6544 0.826392889,6.6434 C0.282392889,6.5474 -0.0806071111,6.0284 0.0153928889,5.4854 L0.839392889,0.8254 C0.936392889,0.2814 1.45539289,-0.0806 1.99839289,0.0154 C2.54239289,0.1114 2.90539289,0.6304 2.80939289,1.1734 L1.98439289,5.8334 C1.89939289,6.3184 1.47739289,6.6594 1.00139289,6.6594'
              id='Fill-9'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
