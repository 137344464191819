import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioInputField from '@dominos/components/inputs/radio-input-field'
import { ServiceMethodSubTypeProps } from '@dominos/interfaces/checkout'
import css from '../../service-method-type.less'
import { MoreInfo } from '@dominos/components/more-info'
import { getSpeedPickupImage } from './speed-pickup-images'
import { useCountryCode } from '@dominos/hooks-and-hocs'

const defaultPopupContent = {
  title: 'SPEED PICKUP - Collect your order from the pickup rack',
  body: `You can speedily collect your order from the pickup rack with zero contact with the store staff.
  How it works:
  (1) Place your order and complete your payment online.
  (2) When you arrive at the store, go straight to the Speed Pickup rack inside the store.
  (3) Check your name on your order (If you ordered chilled items, please notify the store staff).
  (4) Please collect your order from the rack.`,
  imgAlt: "Collect your Domino's Order from our Speed Pickup rack",
}

export const SpeedPickup = ({
  name,
  value,
  onChecked,
  labelText,
  testID,
  selectedSubType,
}: ServiceMethodSubTypeProps) => {
  const { t } = useTranslation('checkout')
  const countryCode = useCountryCode()
  const imageSrc = getSpeedPickupImage(countryCode)

  const handleChecked = (_: string, targetValue: string | number) => {
    onChecked(targetValue as Bff.Stores.ServiceMethodSubTypes)
  }

  return (
    <div className={css.flexBoxWrapper}>
      <RadioInputField
        fieldName={name}
        value={value}
        fieldId={testID}
        onChecked={handleChecked}
        labelText={labelText}
        testID={`${testID}.radio-input-field`}
        selectedOption={selectedSubType}
      />
      <MoreInfo
        testID={`${testID}.info`}
        id={'speed-pickup'}
        title={t('SpeedPickupPopupTitle', { defaultValue: defaultPopupContent.title })}
        label={t('learnMoreButton', { defaultValue: 'Learn More' })}
        description={t('SpeedPickupPopupBody', { defaultValue: defaultPopupContent.body })}
        media={{
          src: imageSrc,
          alt: t('SpeedPickupPopupImgAlt', { defaultValue: defaultPopupContent.imgAlt }),
        }}
      />
    </div>
  )
}
