import React from 'react'

import { GenericCard, Modal } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import css from './generic-card-popup.less'

export interface GenericCardPopupProps extends BaseProps {
  closeModalOnClick?: boolean
  displayCloseButton?: boolean
  genericCardStyles?: object
  onClose?: () => void
  popupTitle: string
  suppressBackgroundScroll?: boolean
  width?: string
}

const iconWidth = 25
const iconHeight = 25

export const GenericCardPopup: React.FC<GenericCardPopupProps> = ({
  children,
  closeModalOnClick,
  displayCloseButton = true,
  genericCardStyles = { position: 'relative' },
  onClose,
  popupTitle,
  suppressBackgroundScroll,
  width,
  testID = 'generic-card-popup',
}) => (
  <Modal
    testID={testID}
    {...(closeModalOnClick && onClose ? { onModalClose: onClose } : {})}
    suppressBackgroundScroll={suppressBackgroundScroll}
  >
    <GenericCard testID={testID} title={popupTitle} width={width} style={{ zIndex: 2, ...genericCardStyles }}>
      {displayCloseButton && onClose && (
        <div role='close' className={css.close} onClick={onClose}>
          <div role='button' data-testid={`${testID}.close`}>
            <StaticSvgIcon name='cross' isUnstyled={true} width={iconWidth} height={iconHeight} />
          </div>
        </div>
      )}
      {children}
    </GenericCard>
  </Modal>
)
