import React from 'react'

import { StackNavigationBase } from '@dominos/components'
import { LoginContainer } from '@dominos/components/login'

import css from './login-scene.less'

export const LoginScene = () => (
  <StackNavigationBase testID='login-scene-stack'>
    <div className={css.wrapper}>
      <div className={css.main}>
        <LoginContainer />
      </div>
    </div>
  </StackNavigationBase>
)
