import { GenericCard } from '@dominos/components'
import React from 'react'
import { TermsAndConditionsGeneric } from '../terms-and-conditions-generic'

export const TermsAndConditionsGenericWithCard = ({
  testID,
  t,
  countryCode,
  hasCheckedBox,
  checked,
  setOnChange,
  textColor,
}: TermsAndConditionsGenericWithCardProps) => (
  <GenericCard testID={`${testID}.generic`} title={t('Terms and Conditions')}>
    <TermsAndConditionsGeneric
      testID={`${testID}.generic.container`}
      text={t(`Terms and Conditions Content ${countryCode}`)}
      textStyle={{ color: textColor, fontSize: 12 }}
      weight='bold'
      hasCheckedBox={hasCheckedBox}
      checked={checked}
      onChange={setOnChange}
    />
  </GenericCard>
)
