import React, { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AddressList,
  GenericCard,
  SearchBar,
  SearchListProps,
  Spinner,
  StoreSearchPickupStoreContainerProps,
} from '@dominos/components'
import { DominosTheme, useDominosTheme } from '@dominos/hooks-and-hocs'

export const StoreSearchPickupStoreContainer = ({
  addresses,
  loading,
  onSearchTextChanged,
  onLinePress,
  autoFocus,
  searchText,
}: StoreSearchPickupStoreContainerProps) => {
  const [search, setSearch] = useState('')
  const { t } = useTranslation('store-search')

  const onChangeText = (text: string) => {
    setSearch(text)
    if (onSearchTextChanged) {
      onSearchTextChanged(text)
    }
  }

  return (
    <GenericCard title={t('Select Store')} testID='store-search'>
      <SearchBar
        placeholder={t('Search Bar Placeholder Text', { defaultValue: 'Search by Postcode or Store Name...' })}
        onChangeText={onChangeText}
        value={search}
        testID='searchText'
        // autoFocus={autoFocus}
      />

      <SearchList isLoading={loading} addresses={addresses} onLinePress={onLinePress} searchText={searchText} />
    </GenericCard>
  )
}

export const SearchList = ({ addresses, isLoading, onLinePress, searchText }: SearchListProps) => {
  const styles = getStyles(useDominosTheme())
  const { t } = useTranslation('store-search')

  if (isLoading) {
    return (
      <div data-testid='search-list.spinner' style={styles.textContainer}>
        <Spinner testID={'search-list.spinner.child'} color={'#000'} />
      </div>
    )
  }

  if (!searchText && (!addresses || addresses.length === 0)) {
    return null
  }

  if (addresses && addresses.length > 0) {
    return <AddressList testID='pickup-store-search' addresses={addresses} onPress={onLinePress} />
  }

  return (
    <div style={styles.textContainer}>
      <p data-testid='search-list.no-results' style={styles.text}>
        {t('No stores found for this search term')}
      </p>
    </div>
  )
}

interface StoreSearchPickupStoreContainerStyles {
  spinner: CSSProperties
  textContainer: CSSProperties
  text: CSSProperties
}

const getStyles = (theme: DominosTheme): StoreSearchPickupStoreContainerStyles => ({
  spinner: {
    margin: 20,
  },
  textContainer: {
    textAlign: 'center',
    margin: '20px 0 12px',
  },
  text: {
    margin: 0,
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'italic',
  },
})
