import { useEffect, useState } from 'react'
import { isNativeApp } from '@dominos/business/functions/native-app'
import { PaymentSetting, useEdenredURL, useLocationExtended } from '@dominos/hooks-and-hocs'
import { SelectedPaymentSetting } from './payment-method/payment-method'
import { useCorrectOrderId } from './use-correct-order-id'

const convertToSelectedPaymentSetting = (
  paymentSetting: PaymentSetting,
  adyenPaymentMethodType?: string,
): SelectedPaymentSetting => {
  const methodId =
    paymentSetting.providerCode === 'Adyen'
      ? `${paymentSetting.paymentMethod}-${adyenPaymentMethodType}`
      : paymentSetting.paymentMethod

  return {
    methodId,
    paymentMethod: paymentSetting.paymentMethod,
    providerCode: paymentSetting.providerCode,
    donationEnabled: paymentSetting.donationEnabled,
  } as SelectedPaymentSetting
}

export const useSelectedPaymentSetting = (paymentSettings: PaymentSetting[], hasOutstandingBalance: boolean) => {
  const { orderId } = useCorrectOrderId()
  const { pathname, search, searchParams } = useLocationExtended()
  const { isEdenredAuthScope } = useEdenredURL()

  const [selectedPaymentSetting, setSelectedPaymentSetting] = useState<SelectedPaymentSetting>()
  const [edenredAuthCode, setEdenredAuthCode] = useState<string | null>(null)

  const preSelectPaymentSetting = (paymentMethod?: BffContext.PaymentMethods, adyenPaymentMethodType?: string) => {
    if (paymentMethod) {
      const paymentSetting = paymentSettings.find((p) => p.paymentMethod === paymentMethod)
      if (paymentSetting) {
        setSelectedPaymentSetting(convertToSelectedPaymentSetting(paymentSetting, adyenPaymentMethodType))
      }
    }
  }

  useEffect(() => {
    if (!paymentSettings.length) {
      return
    }

    if (
      !search ||
      hasOutstandingBalance ||
      !isEdenredAuthScope(searchParams.get('scope')) ||
      searchParams.get('state') !== orderId
    ) {
      return
    }

    setEdenredAuthCode(searchParams.get('code'))
    preSelectPaymentSetting('Edenred')

    if (!isNativeApp()) {
      window.history.replaceState({}, '', pathname)
    }
  }, [orderId, searchParams, paymentSettings.length, hasOutstandingBalance])

  return {
    selectedPaymentSetting,
    setSelectedPaymentSetting,
    edenredAuthCode,
  }
}
