import { useApolloClient } from '@apollo/client'
import { b2bConfigQuery } from '@dominos/business/queries/b2b-config'
import { useEffect } from 'react'
import { useIsAuthenticated } from '@dominos/components/login-b2b/AuthProviderB2B'
import { useAlert } from '@dominos/hooks-and-hocs'
import React, { createContext, ReactNode, useContext } from 'react'
import { useState } from 'react'
import { B2BCONFIG_SESSIONSTORAGEKEY } from '@dominos/business/constants'

const B2BConfigContext = createContext(false)

interface IB2BConfigProviderProps {
  children: ReactNode
}

export const B2BConfigProvider = ({ children }: IB2BConfigProviderProps) => {
  const client = useApolloClient()
  const isAuthenticated = useIsAuthenticated()
  const { showAlert } = useAlert()
  const [isB2BConfigLoaded, setIsB2BConfigLoaded] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        const key = 'B2BConfig'

        try {
          const response = await client.query<B2BConfigResponse>({
            query: b2bConfigQuery,
            fetchPolicy: 'no-cache',
          })

          if (response) {
            window.sessionStorage.setItem(B2BCONFIG_SESSIONSTORAGEKEY, JSON.stringify(response.data))

            setIsB2BConfigLoaded(true)
          }
        } catch (error) {
          window.sessionStorage.removeItem(key as string)
          setIsB2BConfigLoaded(false)

          showAlert(error as string)
        }
      }
    }

    fetchData()
  }, [isAuthenticated])

  return <B2BConfigContext.Provider value={isB2BConfigLoaded}>{children}</B2BConfigContext.Provider>
}

export const useIsB2BConfigLoaded = () => useContext(B2BConfigContext)

export interface B2BConfigResponse {
  b2BConfig: B2BConfig
}

export interface B2BConfig {
  serviceMethods: ServiceMethodB2B[]
  stores: StoreB2B[]
}

export interface ServiceMethodB2B {
  code: string
}

export interface StoreB2B {
  storeNo: number
  serviceMethodOverride: string[]
}
