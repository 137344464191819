import React, { useRef } from 'react'
import { createStyles } from '../product-card/functions'
import { useBreakpoints, useDominosTheme, useKiosk } from '@dominos/hooks-and-hocs'
import css from './product-container.less'
export const ProductContainer = ({ testID, children, styles }: ProductContainerProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const theme = useDominosTheme()
  const { isMobile } = useBreakpoints()
  const { isKioskOrder } = useKiosk()
  const isMobileLayout = isMobile || isKioskOrder
  const themeStyles = createStyles(theme, isMobileLayout, false)

  return (
    <React.Fragment>
      <div
        ref={scrollRef}
        data-testid={`${testID}.modal`}
        className={`${css.modalContent}`}
        style={{ ...themeStyles.modal, ...styles, boxShadow: isMobileLayout ? '' : theme.defaultShadow }}
      >
        {children}
      </div>
    </React.Fragment>
  )
}
