import gql from 'graphql-tag'

export const paymentBalanceQuery = gql`
  query paymentBalanceQuery(
    $providerCode: String!
    $paymentMethod: String!
    $orderId: String!
    $authorisationCode: String
    $nonce: String
    $redirectUri: String
  ) {
    paymentBalance(
      providerCode: $providerCode
      paymentMethod: $paymentMethod
      orderId: $orderId
      authorisationCode: $authorisationCode
      nonce: $nonce
      redirectUri: $redirectUri
    ) {
      success
      amount
      currency
      message
    }
  }
`
