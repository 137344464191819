type DimensionSet = Bff.Dimensions.DimensionSet
type DimensionTypeCode = Bff.Common.DimensionTypeCode
export const createDimensionSet = (
  previousDimensionSet: DimensionSet,
  dimensionType: DimensionTypeCode,
  code: string,
  forced: boolean,
  defaultDimensionSet: DimensionSet | undefined,
  isDimensionSetValid: (dimensionSet: DimensionSet) => boolean,
  getDimensionFilteredValues: (
    dimensionType: DimensionTypeCode,
    currentDimensionSet: DimensionSet | undefined,
  ) => string[],
) => {
  if (
    dimensionType === 'Size' &&
    (previousDimensionSet?.type === 'Size' || previousDimensionSet?.type === 'SizeBase')
  ) {
    const desiredDimensionSet = { ...previousDimensionSet, size: code }
    const isValid = isDimensionSetValid(desiredDimensionSet)

    if (isValid) {
      return desiredDimensionSet
    }
    if (!isValid && forced && previousDimensionSet.type === 'SizeBase') {
      if (defaultDimensionSet?.type == 'SizeBase' && code === defaultDimensionSet?.size) {
        return defaultDimensionSet
      }
      const bases = getDimensionFilteredValues('Base', desiredDimensionSet)

      return { ...desiredDimensionSet, base: bases[0] }
    }
  }
  if (dimensionType === 'Base' && previousDimensionSet?.type === 'SizeBase') {
    const desiredDimensionSet = { ...previousDimensionSet, base: code }
    const isValid = isDimensionSetValid(desiredDimensionSet)

    if (isValid) {
      return desiredDimensionSet
    }
    if (!isValid && forced) {
      if (defaultDimensionSet?.type == 'SizeBase' && code === defaultDimensionSet?.base) {
        return defaultDimensionSet
      }
      const sizes = getDimensionFilteredValues('Size', desiredDimensionSet)

      return { ...desiredDimensionSet, size: sizes[0] }
    }
  }

  return previousDimensionSet
}
