import { readValueFromStorage, writeValueToStorage } from '@dominos/hooks-and-hocs/browser-storage'
import { SessionStorageKeys } from 'packages/interfaces/state/session-storage'
import { useCallback, useState } from 'react'

interface UseSessionStorageProps<T> {
  key: SessionStorageKeys
  compress?: boolean
  defaultValue?: T
}

const selectedStorage = 'sessionStorage'

export const useSessionStorage = <T,>({ key, defaultValue, compress = false }: UseSessionStorageProps<T>) => {
  const [storedValue, setStoredValue] = useState(
    readValueFromStorage(key as string, defaultValue, compress, selectedStorage) ?? null,
  )

  const storeValue = (valueToStore: T) => setStoredValue(valueToStore)

  const setValue = useCallback(
    (value: T) => {
      writeValueToStorage(key, value, storedValue, compress, storeValue, selectedStorage)
    },
    [key, setStoredValue],
  )

  const clearValue = useCallback(() => {
    window.sessionStorage.removeItem(key as string)
    setStoredValue(null)
  }, [key, setStoredValue])

  return {
    storedValue,
    setValue,
    clearValue,
  }
}
