import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 31,
  height: 31,
}

const RecentIconLight = (props: SvgIconProps) => {
  const fill = props.fill || '#DBDBDB'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height}>
      <g fill='none' fillRule='evenodd' transform='translate(1 1)'>
        <ellipse cx='15' cy='14.6' fill={fill} rx='15' ry='14.6' />
        <ellipse cx='15' cy='14.6' stroke='#4A4A4A' strokeWidth='2' rx='8.3' ry='8.1' />
        <path stroke='#4A4A4A' strokeLinecap='round' strokeLinejoin='bevel' strokeWidth='2' d='M15 11V15l1.9 2' />
      </g>
    </svg>
  )
}

export { RecentIconLight }
