import { AddressComponentUpdate } from '@dominos/components/address/delivery-address-additional-fields'

export const mergeAddressComponentUpdates = (
  updatedAddressComponents: AddressComponentUpdate[],
  existingAddressComponents: AddressComponentUpdate[] | undefined,
): AddressComponentUpdate[] => {
  if (!existingAddressComponents) return updatedAddressComponents

  const addressComponents = [...existingAddressComponents]
  for (const updatedAddressComponent of updatedAddressComponents) {
    const existingAddressComponent = addressComponents.find((c) => c.type === updatedAddressComponent.type)
    if (existingAddressComponent) {
      existingAddressComponent.value = updatedAddressComponent.value
      existingAddressComponent.isValid = updatedAddressComponent.isValid
    } else {
      addressComponents.push(updatedAddressComponent)
    }
  }

  return addressComponents
}

export const mergeAddressComponents = (
  updatedAddressComponents: AddressComponent[],
  existingAddressComponents: AddressComponent[] | undefined,
): AddressComponent[] => {
  if (!existingAddressComponents) return updatedAddressComponents

  const addressComponents = [...existingAddressComponents]
  for (const updatedAddressComponent of updatedAddressComponents) {
    const existingAddressComponent = addressComponents.find((c) => c.type === updatedAddressComponent.type)
    if (existingAddressComponent) {
      existingAddressComponent.value = updatedAddressComponent.value
    } else {
      addressComponents.push({
        type: updatedAddressComponent.type,
        value: updatedAddressComponent.value,
      })
    }
  }

  return addressComponents
}
