import { ApolloError, useMutation } from '@apollo/client'
import { useState } from 'react'

import { customerMutation } from '@dominos/business/queries'
import { useReport } from '@dominos/hooks-and-hocs'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs/selectors/use-current-order-details'
import { getProviderId, getProviderType } from '../logging/helpers/get-external-provider-details'
import { useLocation } from '@reach/router'
import { AuthenticationSource } from '@dominos/interfaces'

export interface AccountCreateEmailResponse {
  customer: {
    id: string
    idToken: string
    shouldDisplayRecaptchaV2?: boolean
    recaptchaSuccess?: boolean
  }
}

interface UseAccountCreateEmailResponse {
  createAccount: (
    accountDetails: Bff.Customers.UpsertCustomer,
    authenticationSource: AuthenticationSource,
  ) => Promise<void>
  loading: boolean
  data?: AccountCreateEmailResponse | null
  error?: ApolloError
  useRecaptchaV2: boolean
  shouldResetCheckbox: boolean
}

export const useAccountCreateEmail = (
  onSuccessCallBack: (idToken: string, customerId: string) => Promise<void>,
): UseAccountCreateEmailResponse => {
  const [createAccountMutation, { data, error }] = useMutation<
    AccountCreateEmailResponse,
    { input: Bff.Customers.UpsertCustomer }
  >(customerMutation)
  const { reportRegistration } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const { href } = useLocation()
  const [loadingState, setLoadingState] = useState<boolean>(false)
  const [useRecaptchaV2, setUseRecaptchaV2] = useState<boolean>(false)
  const [shouldResetCheckbox, setShouldResetCheckbox] = useState<boolean>(false)

  const createAccount = async (
    accountDetails: Bff.Customers.UpsertCustomer,
    authenticationSource: AuthenticationSource,
  ) => {
    setLoadingState(true)

    let response: AccountCreateEmailResponse | undefined | null
    try {
      const { data: responseTemp } = await createAccountMutation({ variables: { input: accountDetails } })
      response = responseTemp
    } catch {
      // Bug in Apollo where the error gets thrown as well as passed back in the error variable.
      // This catch will just throw away the error thrown.
      null
    } finally {
      setShouldResetCheckbox(false)
      if (!!response) {
        if (response.customer.idToken) {
          const { customer } = response
          reportRegistration({
            success: true,
            orderId: orderId,
            url: href,
            customerId: customer.id,
            identityProvider: getProviderType(accountDetails.identityProvider),
            identityProviderId: getProviderId(accountDetails.identityProvider),
            loyaltyOptIn: accountDetails.loyalty?.isEnrolled as boolean,
            authenticationSource,
          })
          await onSuccessCallBack(customer.idToken, customer.id)
        } else if (response.customer.shouldDisplayRecaptchaV2) {
          useRecaptchaV2 ? setShouldResetCheckbox(true) : setUseRecaptchaV2(true)
        }
      }
      setLoadingState(false)
    }
  }

  return {
    createAccount,
    data,
    error,
    useRecaptchaV2,
    shouldResetCheckbox,
    loading: loadingState,
  }
}
