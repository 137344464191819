import {
  convertFilterProductItemToDimensionFilter,
  DimensionFilter,
  FilterProductItem,
} from '@dominos/business/functions/menu'
import { getStateFromNavigation } from '@dominos/navigation'
import { useLocation } from '@reach/router'
import { useEffect, useState } from 'react'

const useFilterProductItems = () => {
  const location = useLocation()
  const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const [filterProductItems, setFilterProductItems] = useState<FilterProductItem[] | undefined>()
  const [voucherNo, setVoucherNo] = useState<number | undefined>()
  const [voucherItemNo, setVoucherItemNo] = useState<number | undefined>()

  const getDimensionFilter = (productCode: string): DimensionFilter | undefined =>
    convertFilterProductItemToDimensionFilter(filterProductItems?.find((item) => item.code === productCode))

  useEffect(() => {
    const filterProductItems = getStateFromNavigation<FilterProductItem[]>(location, 'filterList')
    const voucherNo = getStateFromNavigation<number | undefined>(location, 'voucherNo')
    const voucherItemNo = getStateFromNavigation<number | undefined>(location, 'voucherItemNo')
    setIsFiltered(!!filterProductItems)
    setVoucherNo(voucherNo)
    setVoucherItemNo(voucherItemNo)
    setFilterProductItems(filterProductItems)
  }, [location])

  return {
    isFiltered,
    voucherNo,
    voucherItemNo,
    getDimensionFilter,
  }
}

export { useFilterProductItems }
