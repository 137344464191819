import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 45,
  height: 74,
}

export const Gauge2 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 45 74'>
      <defs>
        <path
          d='M67.9732174,97.0788198 L67.9732174,88.3788198 C67.9732174,84.2788198 71.3732174,80.8788198 75.4732174,80.8788198 L100.873217,80.8788198 C104.973217,80.8788198 108.373217,84.2788198 108.373217,88.3788198 L108.373217,114.27882 C108.373217,118.57882 105.273217,122.37882 100.973217,123.27882 L83.9732174,126.67882 C83.6732174,126.77882 83.4732174,126.97882 83.4732174,127.27882 L83.4732174,136.47882 C83.4732174,136.77882 83.6732174,136.97882 83.9732174,136.97882 L106.273217,136.97882 C107.673217,136.97882 108.773217,138.07882 108.773217,139.47882 L108.773217,148.37882 C108.773217,149.77882 107.673217,150.87882 106.273217,150.87882 L70.4732174,150.87882 C69.0732174,150.87882 67.9732174,149.77882 67.9732174,148.37882 L67.9732174,122.37882 C67.9732174,118.07882 70.8732174,114.37882 75.3732174,113.47882 L92.3732174,110.07882 C92.6732174,109.97882 92.8732174,109.77882 92.8732174,109.47882 L92.8732174,95.2788198 C92.8732174,94.9788198 92.6732174,94.7788198 92.3732174,94.7788198 L83.9732174,94.7788198 C83.6732174,94.7788198 83.4732174,94.9788198 83.4732174,95.2788198 L83.4732174,103.57882 C83.4732174,104.77882 82.2732174,105.57882 81.1732174,105.07882 L70.2732174,100.57882 C68.8732174,99.9788198 67.9732174,98.5788198 67.9732174,97.0788198 Z'
          id='gauge-path-2'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Points2' transform='translate(-66.000000, -79.000000)' fillRule='nonzero'>
          <g id='Path'>
            <use fill={fill} href='#gauge-path-2' />
            <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-2' />
          </g>
        </g>
      </g>
    </svg>
  )
}
