const timeThresholdMins = 15
const secondsInAMinute = 60000

export const getOrderHeaderTime = (orderTime: string | null | undefined): string | undefined => {
  if (!orderTime) {
    return undefined
  }

  const differenceInMinutes = (new Date(orderTime).getTime() - new Date().getTime()) / secondsInAMinute

  if (differenceInMinutes < timeThresholdMins) {
    return undefined
  }

  return orderTime
}
