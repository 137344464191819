import { DimensionSetState } from '@dominos/hooks-and-hocs'

export const transformDimensionSetStateToBasketLinePartial = (dimensionSetState: DimensionSetState) => {
  const possibleBases = dimensionSetState.getDimensionValuesByType('Base')
  const defaultBase = possibleBases?.length && possibleBases[0]
  const selectedBase = dimensionSetState.getSelectedDimension('Base')
  const selectedSize = dimensionSetState.getSelectedDimension('Size')
  const hasBaseChanged = selectedBase !== defaultBase

  return {
    sizeCode: selectedSize ? selectedSize : null,
    ...(hasBaseChanged && selectedBase
      ? {
          base: { add: selectedBase, remove: defaultBase } as BasketLineSwap,
        }
      : {}),
  }
}
