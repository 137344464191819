import { useApolloClient } from '@apollo/client'
import { ApolloError } from '@apollo/client/errors'
import { productQuery } from '@dominos/business/queries/product'
import { useState } from 'react'

interface FetchProductsBatchedProps {
  productCode: string
  storeNo: number | undefined
  serviceMethod: BffContext.ServiceMethods | undefined
  requestTime?: string | null
}

interface FetchProductsBatchedState {
  products: Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>[]
  loading: boolean
  errors: ApolloError[] | undefined
}

export const useFetchProductsBatched = () => {
  const client = useApolloClient()
  const [fetchProductsBatchedState, setFetchProductsBatchedState] = useState<FetchProductsBatchedState>({
    products: [],
    loading: false,
    errors: undefined,
  })

  const fetchProduct = async ({ productCode, storeNo, serviceMethod, requestTime }: FetchProductsBatchedProps) =>
    client.query({
      query: productQuery,
      variables: {
        productCode,
        storeNo,
        serviceMethod,
        requestTime,
      },
    })

  const fetchProducts = (
    productCodes: string[],
    serviceMethod: BffContext.ServiceMethods | undefined,
    storeNo: number | undefined,
    requestTime?: string | null,
  ) => {
    setFetchProductsBatchedState({
      products: [],
      loading: true,
      errors: undefined,
    })
    const fetchedProducts: Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>[] = []
    const errors: ApolloError[] = []
    Promise.all(
      productCodes.map((productCode) =>
        fetchProduct({ productCode, storeNo, serviceMethod, requestTime }).then((result) => {
          if (!result.loading && !result.error && result.data?.product) {
            fetchedProducts.push(result.data?.product)
          }
          if (result.error) {
            errors.push(result.error)
          }
        }),
      ),
    ).finally(() => {
      setFetchProductsBatchedState({
        products: fetchedProducts,
        loading: false,
        errors: errors.length ? errors : undefined,
      })
    })
  }

  return {
    products: fetchProductsBatchedState.products,
    loading: fetchProductsBatchedState.loading,
    errors: fetchProductsBatchedState.errors,
    fetchProducts,
  }
}
