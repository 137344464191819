import { DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const Edge = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR
  const selectedColorLight = color ? color : '#4A4A4A'

  return (
    <svg width='51px' height='49px' viewBox='0 0 51 49' key={color}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-49.000000, -932.000000)'>
          <g transform='translate(6.000000, 309.000000)'>
            <g transform='translate(1.000000, 474.000000)'>
              <g transform='translate(47.500000, 152.230846)'>
                <ellipse
                  stroke={selectedColor}
                  strokeWidth='3'
                  cx='19.953125'
                  cy='19.6051924'
                  rx='19.4921875'
                  ry='18.7566333'
                />
                <g transform='translate(4.500000, 3.849057)'>
                  <polygon
                    fill={selectedColorLight}
                    stroke={selectedColorLight}
                    points='10.5 6.43091281 15.5 0.824831405 20.5 6.43091281 17.5 6.43091281 18.5 15.589337 12.5 15.589337 13.5 6.43091281'
                  />
                  <polygon
                    fill={selectedColorLight}
                    stroke={selectedColorLight}
                    transform='translate(15.500000, 22.971590) scale(1, -1) translate(-15.500000, -22.971590) '
                    points='10.5 21.1954184 15.5 15.589337 20.5 21.1954184 17.5 21.1954184 18.5 30.3538427 12.5 30.3538427 13.5 21.1954184'
                  />
                  <polygon
                    fill={selectedColorLight}
                    stroke={selectedColorLight}
                    transform='translate(7.828247, 15.589337) rotate(-90.000000) translate(-7.828247, -15.589337) '
                    points='3.01692632 13.7435118 7.82824707 7.91758411 12.6395678 13.7435118 9.75277537 13.7435118 10.7150395 23.26109 4.94145462 23.26109 5.90371877 13.7435118'
                  />
                  <polygon
                    fill={selectedColorLight}
                    stroke={selectedColorLight}
                    transform='translate(23.171753, 15.589337) scale(1, -1) rotate(90.000000) translate(-23.171753, -15.589337) '
                    points='18.3604322 13.7435118 23.1717529 7.91758411 27.9830737 13.7435118 25.0962812 13.7435118 26.0585454 23.26109 20.2849605 23.26109 21.2472246 13.7435118'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
