import Markdown from 'markdown-to-jsx'
import React from 'react'

import { ActionButton, MDTargetBlankAnchor } from '@dominos/components'
import { GenericCardPopup } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import css from './kiosk-order-popup.less'

export const KioskOrderPopup = ({
  testId,
  popupTitle,
  popupMessage,
  onConfirm,
  onConfirmText,
  closeModalOnClick,
  onDismiss,
  onDismissText,
  icon,
}: {
  testId: string
  popupTitle: string
  popupMessage: string
  onConfirm: () => void
  onConfirmText: string
  onDismiss?: () => void
  onDismissText?: string
  closeModalOnClick?: boolean
  displayCloseButton?: boolean
  icon?: svgIconsName
}) => (
  <GenericCardPopup
    testID={`${testId}.kiosk-order-popup`}
    popupTitle={''}
    onClose={onDismiss}
    genericCardStyles={styles.popUpCard}
    displayCloseButton={false}
    closeModalOnClick={closeModalOnClick}
    suppressBackgroundScroll
  >
    <div className={css.wrapper}>
      {icon && (
        <div className={css.icon} data-testid={`${testId}.kiosk-order-popup.icon`}>
          <StaticSvgIcon name={icon} width='7vw' height='7vw' isUnstyled />
        </div>
      )}
      {popupTitle && (
        <h2 className={css.title} data-testid={`${testId}.kiosk-order-popup.title`}>
          {popupTitle}
        </h2>
      )}
      <Markdown
        className={css.message}
        data-testid={`${testId}.kiosk-order-popup.message`}
        options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}
      >
        {popupMessage}
      </Markdown>
      <div className={css.actionButtonsContainer}>
        {onDismiss && onDismissText && (
          <ActionButton
            testID={`${testId}.kiosk-order-popup.on-dismiss-button`}
            containerStyle={{ ...styles.buttonContainer, ...styles.dismissButtonContainer }}
            text={onDismissText}
            textStyle={styles.buttonText}
            onPress={onDismiss}
          />
        )}
        <ActionButton
          testID={`${testId}.kiosk-order-popup.on-confirm-button`}
          containerStyle={onDismiss && onDismissText ? styles.buttonContainer : styles.largeButtonContainer}
          textStyle={onDismiss && onDismissText ? styles.buttonText : styles.largeButtonText}
          text={onConfirmText}
          onPress={onConfirm}
        />
      </div>
    </div>
  </GenericCardPopup>
)

const styles: Record<string, React.CSSProperties> = {
  popUpCard: {
    maxWidth: 'unset',
  },
  buttonContainer: {
    width: '30vw',
    height: '8vw',
    borderRadius: 15,
  },
  largeButtonContainer: {
    width: '70vw',
    height: '9vw',
    borderRadius: 18,
  },
  dismissButtonContainer: {
    backgroundColor: 'black',
  },
  buttonText: {
    fontSize: '3vw',
  },
  largeButtonText: {
    fontSize: '4vw',
    fontWeight: 'bold',
  },
}
