import { AccountButtonProps } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AccountContainer } from '../account-container/account-container'

export const LoginButton = (props: AccountButtonProps) => {
  const { t } = useTranslation('login')

  return (
    <AccountContainer
      testID='log-in'
      title={t('LogIn', { defaultValue: 'Log In' })}
      subtitle={t('or Create an Account')}
      onClick={props.onClick}
    >
      <StaticSvgIcon name='accountNoNeck' isUnstyled />
    </AccountContainer>
  )
}
