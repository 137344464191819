import React from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { GenericCardTitle } from './generic-card-title'

import css from './generic-card.less'

export const GenericCard = (props: GenericCardProps) => {
  const { testID, title, children, style, width, noPadding, TitleTriggerComponent } = props

  const theme = useDominosTheme()

  const styles = {
    card: {
      maxWidth: width ? width : '360px',
      boxShadow: theme.defaultShadow,
      borderRadius: theme.webBorderRadius,
      backgroundColor: theme.colours.defaultBackground,
    },
  }

  return (
    <div className={css.card} style={{ ...styles.card, ...style }} data-testid={`generic-card.${testID}`}>
      {title && <GenericCardTitle title={title} testID={testID} TitleTriggerComponent={TitleTriggerComponent} />}

      <div
        data-testid={`generic-card.children.${testID}`}
        className={noPadding ? css.childrenNoPaddingWidth : css.children}
      >
        {children}
      </div>
    </div>
  )
}
