import { useEffect } from 'react'
import { useLocalStorage } from '@dominos/hooks-and-hocs/browser-storage'
import { NavigationConstants } from '@dominos/navigation'

interface UseTooltipOptions {
  maxTooltipShowCount: number
  currentPath: string
  skip?: boolean
}

const ALLOWED_PATH_NAMES = [NavigationConstants.home]

export const useTooltip = ({ maxTooltipShowCount, currentPath, skip = false }: UseTooltipOptions) => {
  const { storedValue, setValue } = useLocalStorage({
    key: 'sign-up-button-tool-tip',
    defaultValue: { showCount: 0, dismissed: false, closedInSession: false },
  })

  const isAllowedPath = ALLOWED_PATH_NAMES.includes(currentPath)

  const shouldShowTooltip =
    isAllowedPath &&
    storedValue.showCount < maxTooltipShowCount &&
    !storedValue.dismissed &&
    !storedValue.closedInSession

  const closeTooltip = (permanent = false) => {
    if (skip) {
      return
    }

    setValue({
      ...storedValue,
      dismissed: storedValue.dismissed || permanent,
      closedInSession: true,
    })
  }

  useEffect(() => {
    if (skip) {
      return
    }
    const incrementShowCount = () => {
      // Don't increment showCount if it's already reached the maximum or permanently dismissed
      if (storedValue.showCount >= maxTooltipShowCount || storedValue.dismissed) {
        return
      }
      setValue({
        showCount: storedValue.showCount + 1,
        dismissed: storedValue.dismissed,
        closedInSession: false, // reset closedInSession flag on reload
      })
    }

    window.addEventListener('beforeunload', incrementShowCount)

    return () => {
      window.removeEventListener('beforeunload', incrementShowCount)
    }
  }, [storedValue, skip])

  if (skip) {
    return { shouldShowTooltip: false, closeTooltip: () => {} }
  }

  return { shouldShowTooltip, closeTooltip }
}
