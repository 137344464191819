import React from 'react'
import { ActionButton, AddressInfoComponent, ClearButton, GenericCard } from '@dominos/components'
import css from './confirm-address-component.less'
import { IUseConfirmAddressComponentProps, useConfirmAddressComponent } from 'olo-feature-address'
import { AddressFieldsContainer } from '../address-fields'

const testID = 'confirm-address'

export interface ConfirmAddressComponentProps extends IUseConfirmAddressComponentProps {
  testID: string
}

export const ConfirmAddressComponent = (props: ConfirmAddressComponentProps) => {
  const {
    hideAdditionalFields,
    deliveryAddress,
    confirmAddressTitle,
    confirmAddressButtonLabel,
    confirmButtonEnabled,
    onClose,
    onConfirmDeliveryAddress,
    onAddressFieldsChange,
    isLoading,
  } = useConfirmAddressComponent(props)

  return (
    <div data-testid={testID} className={css.wrapper}>
      <GenericCard
        testID={testID}
        title={confirmAddressTitle}
        TitleTriggerComponent={
          <div style={{ marginBottom: '-10px', cursor: 'pointer' }}>
            <ClearButton testID={`${testID}.clearInputButton`} onClick={onClose} tabIndex={0} />
          </div>
        }
      >
        <AddressInfoComponent testID={`${testID}.info`} address={props.deliveryAddress} showClearButton />

        <AddressFieldsContainer
          deliveryAddress={deliveryAddress}
          onAddressFieldsChange={onAddressFieldsChange}
          hideOptionalFields={hideAdditionalFields}
        />

        <ActionButton
          testID={`${testID}.button`}
          onPress={onConfirmDeliveryAddress}
          loading={isLoading}
          text={confirmAddressButtonLabel}
          containerStyle={{ width: '100%', marginTop: '8px', height: '40px' }}
          textFontWeight={'semibold'}
          disabled={!confirmButtonEnabled}
        />
      </GenericCard>
    </div>
  )
}
