import { BasketLineData } from '@dominos/business/functions'
import { Portion } from '@dominos/components'
import { DimensionSetState, ProductData } from '@dominos/hooks-and-hocs'
import React from 'react'

export type ProductContextProps = {
  currentBasketLineData: BasketLineData | undefined
  productData: ProductData | undefined
  portions: Portion[] | undefined
  dimensionSetState: DimensionSetState | undefined
  initialQuantity: number
  isValidForBasket: boolean
  formattedPrice: string | null | undefined
  formattedPromoPrice: string | null | undefined

  handlePortionChange: (index: number, productCode: string | undefined) => void
  createBasketLine: (quantity: number) => BasketLine
  saveBasketLineDataChanges: (
    key: 'toppings' | 'sauce' | 'options',
    changes: BasketLineChange[] | BasketLineSwap | string | undefined,
  ) => void
  switchProduct: (code: string, previousCode: string) => void
  resetPortion: (portionIndex: number) => void
  handlePortionCustomisations: (
    index: number,
    key: 'sauce' | 'toppings',
    changes: BasketLineChange[] | BasketLineSwap | undefined,
  ) => void
}

export const ProductContext = React.createContext<ProductContextProps>({
  currentBasketLineData: undefined,
  productData: undefined,
  portions: undefined,
  dimensionSetState: undefined,
  initialQuantity: 1,
  isValidForBasket: false,
  formattedPrice: undefined,
  formattedPromoPrice: undefined,

  handlePortionChange: () => {},
  saveBasketLineDataChanges: () => {},
  switchProduct: () => {},
  createBasketLine: () => ({} as BasketLine),
  resetPortion: () => {},
  handlePortionCustomisations: () => {},
})
