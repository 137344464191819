import { useAlert } from '@dominos/hooks-and-hocs'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// placeholder for pop-up component that does not use window.alert
export const ExpireOrderPopup = ({ onClose }: { onClose: () => void }) => {
  const { showAlert } = useAlert(onClose)
  const { t } = useTranslation('checkout')
  const translationKey = 'OrderExpiredMessage'

  useEffect(() => {
    showAlert(t(translationKey, { defaultValue: 'Your order has expired. Please start a new order.' }), translationKey)
    if (isNativeApp()) {
      notifyNativeApp('error')
    }
  }, [])

  return <></>
}
