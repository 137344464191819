import { FacebookIcon } from '@dominos/res/images/icons/components/facebook-icon'
import React from 'react'

import { useLoginTranslations } from '../use-login-translations'
import { AuthButton } from '@dominos/components'

interface FacebookNativeProps {
  loading: boolean
  onSignIn: () => void
  testID: string
}

export const FacebookNative = ({ loading, onSignIn, testID }: FacebookNativeProps) => {
  const t = useLoginTranslations()
  const [title] = t.getSocials('Facebook')

  return (
    <AuthButton
      testID={testID}
      backgroundColor={'#1778f2'}
      label={title}
      loading={loading}
      onPress={onSignIn}
      isNative={true}
    >
      <FacebookIcon data-testid={`${testID}.icon`} fill={'#ffffff'} />
    </AuthButton>
  )
}
