import React from 'react'

import { FlatList } from '@dominos/components'

import css from './touchable-grid.less'

const PERCENT = 100

export const TouchableGrid = <T extends TouchableGridElement>(props: TouchableGridProps<T>) => {
  const { selectedItem, onPressItem, numColumns, testID, data } = props
  const keyExtractor = (item: T, index: number) => index.toString()

  const calcWidth = () => PERCENT / numColumns

  const onPressItemHandler = (item: T) => () => {
    onPressItem(item)
  }

  const renderItem = (item: T, index: number) => (
    <div key={item.code} style={{ width: `${calcWidth()}%` }}>
      <div
        role='renderItemClickHandler'
        data-testid={`${testID}.touchableGrid.renderItem`}
        className={css.renderItem}
        onClick={onPressItemHandler(item)}
      >
        <props.renderItem {...item} selected={item.code === selectedItem} />
      </div>
    </div>
  )

  return (
    <div className={css.flatList}>
      <FlatList data-testid={`${testID}.flat-list`} data={data} renderItem={renderItem} keyExtractor={keyExtractor} />
    </div>
  )
}
