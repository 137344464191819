import { newTracker } from '@snowplow/browser-tracker'
import { Config } from '../common'

export const setupSnowplow = (applicationConfig: Config) => {
  if (applicationConfig.FOS_COLLECTION_URL !== '' && applicationConfig.FOS_COLLECTION_URL !== 'null') {
    window._SNOWPLOW_ = 'snowplow'
    newTracker('FOS_Event_Tracker', applicationConfig.FOS_COLLECTION_URL, {
      appId: '',
      respectDoNotTrack: false,
      cookieSameSite: 'Lax',
      eventMethod: 'get',
      withCredentials: false,
    })
  }
}
