import gql from 'graphql-tag'

export const removeSavedPaymentMethodMutation = gql`
  mutation removeSavedPaymentMethodMutation(
    $token: String!
    $providerCode: String
    $paymentMethod: String
    $orderId: String
  ) {
    deleteTokenisedPayment(token: $token, providerCode: $providerCode, paymentMethod: $paymentMethod, orderId: $orderId)
  }
`

export const savedPaymentDetailsQuery = gql`
  query savedPaymentDetailsQuery($customerId: String) {
    customer(id: $customerId) {
      paymentMethods {
        tokenId
        name
        paymentMethod
        paymentProvider
        isDefault
      }
    }
  }
`
