import { useMutation } from '@apollo/client'
import { notifyArrivalMutation } from '@dominos/business/queries/notify-store'
import { useReport } from '@dominos/hooks-and-hocs'
import { ErrorScope, useErrorContext } from '@dominos/components/error'
import { useMemo, useState } from 'react'
import { notifyStoreErrorDefinitions } from './notify-store-errors'

export const useNotifyArrival = (orderId: string) => {
  const [loading, setLoading] = useState(false)
  const { reportNotifyArrival } = useReport()
  const { notifyError } = useErrorContext()
  const [notifyStore, { data }] = useMutation(notifyArrivalMutation)

  const notify = async () => {
    setLoading(true)
    reportNotifyArrival()

    await notifyStore({
      variables: {
        orderId,
      },
    })
      .catch((error) =>
        notifyError({
          error,
          definitions: notifyStoreErrorDefinitions,
          handlers: {},
          scope: ErrorScope.Tracker,
        }),
      )
      .finally(() => setLoading(false))
  }

  const notified = useMemo(() => data?.notifyArrival || false, [data])

  return { notified, notify, loading }
}
