import { CountryToggle } from '@dominos/components'
import {
  LoyaltyIconAU,
  LoyaltyIconDE,
  LoyaltyIconDefault,
  LoyaltyIconFR,
  LoyaltyIconNL,
} from '@dominos/res/images/icons/components/loyalty'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getLoyaltyTranslations } from './loyalty-translations'
import css from './loyalty.less'

export const JoinLoyaltyBanner = ({ testID, children }: React.PropsWithChildren<BaseProps>) => {
  const { t } = useTranslation('loyalty')
  const translations = getLoyaltyTranslations(t)

  return (
    <div className={css.loyaltySlice} data-testid={testID}>
      <CountryToggle
        AU={<LoyaltyIconAU />}
        NL={<LoyaltyIconNL />}
        FR={<LoyaltyIconFR />}
        DE={<LoyaltyIconDE />}
        default={<LoyaltyIconDefault />}
      />
      <div className={css.loyaltySliceText}>
        <div className={css.title}>{translations.LoyaltyTitle}</div>
        {children}
      </div>
    </div>
  )
}
