import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import { ActionButton } from '@dominos/components/buttons'
import css from './product-upsell-popup-view.less'
import { ProductUpsellPopupViewProps } from './create-product-upsell-popup-view-model'
import { AnimatePresence, motion } from 'framer-motion'

const ProductUpsellPopupView: React.FC<ProductUpsellPopupViewProps> = ({
  testID,
  viewModel: { yesButtonText, noButtonText, popupImageUrl },
  onYesClicked,
  onNoClicked,
}) => {
  const buttons = (
    <div style={{}} className={css.buttonsContainer}>
      <ActionButton
        className={css.yesButtonContainer}
        text={yesButtonText}
        onPress={onYesClicked}
        testID='product-upsell-popup-upgrade-button'
      />
      <ActionButton
        className={css.noButtonContainer}
        text={noButtonText}
        onPress={onNoClicked}
        testID='product-upsell-popup-no-thanks-button'
      />
    </div>
  )

  return (
    <AnimatePresence>
      <motion.div
        data-testid={testID}
        className={css.popupBackground}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className={css.popupContent}>
          <img src={popupImageUrl} className={css.image} />
          <div data-testid={`${testID}-close-button`} className={css.closeIconContainer} onClick={onNoClicked}>
            <StaticSvgIcon name={'crossCloseDark'} isUnstyled width={34} height={34} />
          </div>
          <div className={css.buttons}>{buttons}</div>
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export { ProductUpsellPopupView }
