import { isProductMenuItemNew } from '@dominos/components'

export type OptionBasketLineChange = BasketLineChange & { index: number }

export interface BasketLineData {
  item?: MenuItemDependents | null
  toppings?: BasketLineChange[]
  base?: BasketLineSwap
  sauce?: BasketLineSwap
  sizeCode?: string
  options?: OptionBasketLineChange[]
}

/**
 * Converts Product with changes to a basket line
 * @param item ProductMenuItem to be added to basket
 * @param quantity number of pizzas
 * @param toppings (optional) array of topping changes as BasketLineChange[]
 * @param base (optional) customised base as BasketLineSwap
 * @param sauce (optional) customised sauce as BasketLineSwap
 * @return BasketLine | undefined
 */
export const createBasketLine = (
  quantity?: number,
  product?: BasketLineData,
  portionLeft?: BasketLineData,
  portionRight?: BasketLineData,
): BasketLine | void => {
  if (!quantity || !product || !product.item || isProductMenuItemNew(product.item)) {
    return
  }

  return {
    quantity,
    base: product.base,
    toppings: product.item.type === 'Portion' ? undefined : product.toppings,
    sauce: product.item.type === 'Portion' ? undefined : product.sauce,
    productCode: product.item.code,
    itemNo: 0,
    type: 'BasketLine',
    sizeCode: product.item.type !== 'Variety' ? product.sizeCode : undefined,
    media: { name: (product.item.media && product.item.media.name) || '' },
    totalPrice: null,
    portions:
      product.item.type === 'Portion' && portionLeft && portionRight
        ? [
            {
              productCode: (portionLeft.item && !isProductMenuItemNew(portionLeft.item) && portionLeft.item.code) || '',
              toppings: portionLeft.toppings,
              sauce: portionLeft.sauce,
            },
            {
              productCode:
                (portionRight.item && !isProductMenuItemNew(portionRight.item) && portionRight.item.code) || '',
              toppings: portionRight.toppings,
              sauce: portionRight.sauce,
            },
          ]
        : undefined,
    options: product.options,
  }
}
