import { sortAddressLineByLocation } from '@dominos/hooks-and-hocs/ordering/use-store-search/helpers'
import { TFunction } from 'i18next'
import { toPascalCase } from '@dominos/business/functions'

const findValue = (attributes: AddressAttributesKeyValuePair[], key: string) =>
  attributes.find((a) => a.key === key)?.value
const transformAttributesToAddress = (attributes: AddressAttributesKeyValuePair[]): string => {
  const joinUnitNumberStreetNumber = [findValue(attributes, 'unitNumber'), findValue(attributes, 'streetNumber')]
    .filter((val) => !!val)
    .join('/')
  const fullStreetName = [joinUnitNumberStreetNumber, findValue(attributes, 'streetName')]
    .filter((val) => !!val)
    .join(' ')
  const postcodeAndSuburb = [findValue(attributes, 'postCode'), findValue(attributes, 'suburb')]
    .filter((val) => !!val)
    .join(' ')

  return [fullStreetName, postcodeAndSuburb, findValue(attributes, 'state')].filter((val) => !!val).join(', ')
}

const transformPickupStoreDetailsToAddressLine = (
  t: TFunction,
  pickupStoreDetails: PickupStoreDetails[],
  limit: number,
) => {
  const addressLines: AddressLine[] = []
  pickupStoreDetails.forEach((pickupStoreDetail) => {
    const storeNameText = t('DominosStore', {
      storeName: toPascalCase(pickupStoreDetail.name ?? ''),
      defaultValue: "Domino's {{storeName}}",
      interpolation: { escapeValue: false },
    })
    pickupStoreDetail.locations.forEach((location) => {
      const address = location ? transformAttributesToAddress(location.address.attributes) : ''
      const storeGeolocation = location?.address.geoLocation
      const geolocation = storeGeolocation
        ? { longitude: storeGeolocation.latitude, latitude: storeGeolocation.latitude }
        : undefined
      const addressLine = {
        icon: pickupStoreDetail.storeType === 'Fixed' ? 'storeDetailsPickUp' : 'mobileMPKIcon',
        address: address,
        name: storeNameText,
        suffix: location.distance as number,
        uid: pickupStoreDetail.storeNo,
        geo: geolocation,
        structuredAddress: {
          title: pickupStoreDetail.name,
          subtitle: address,
        },
        additionalData: { ...pickupStoreDetail, locations: [location] },
      } as AddressLine
      addressLines.push(addressLine)
    })
  })
  const hardcodeSuffixToCaret: 'caret' | number = 'caret'

  return sortAddressLineByLocation(addressLines)
    .slice(0, limit)
    .map((pickupStoreDetail) => ({ ...pickupStoreDetail, suffix: hardcodeSuffixToCaret }))
}

export const transformPickupSearchResponse = (
  t: TFunction,
  pickupStoreDetails: PickupStoreDetails[] | undefined,
  limit: number,
): AddressLine[] => (pickupStoreDetails ? transformPickupStoreDetailsToAddressLine(t, pickupStoreDetails, limit) : [])
