import { useLocalStorage } from '@dominos/hooks-and-hocs/browser-storage/use-local-storage'
import { getLastOrderDetails } from './get-last-order-details'
import { defaultLastOrderDetails } from './default-last-order-details'

export const useLastOrderDetails = (): UseWebLastOrderResponse => {
  const { storedValue: lastOrder, setValue } = useLocalStorage<LastOrderDetails>({
    key: 'sujm:last-order',
    defaultValue: defaultLastOrderDetails,
  })

  const saveAsLastOrder = (
    basketHeaderData?: Partial<BasketData>,
    deliveryAddress?: Bff.Orders.OrderDetails.Header.DeliveryAddress | null,
  ) => {
    const valueToStore = getLastOrderDetails(lastOrder, basketHeaderData, deliveryAddress)
    setValue(valueToStore)
  }

  return {
    lastServiceUsed: lastOrder.lastServiceUsed,
    lastPickupOrder: lastOrder.Pickup,
    lastDeliveryOrder: lastOrder.Delivery,
    saveAsLastOrder,
  }
}
