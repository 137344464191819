import { rootActions } from '@dominos/business'
import { useReport } from '@dominos/hooks-and-hocs'
import { NativeAppStartupData } from '@dominos/interfaces'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

const validateNativeAppData = (data?: NativeAppStartupData): { isValid: boolean; missingFields: string[] } => {
  const missingFields = new Set<string>()
  if (!data) {
    return {
      missingFields: ['language', 'deviceId'],
      isValid: false,
    }
  }

  if (!data.language) {
    missingFields.add('language')
  }

  if (!data.analytics || !data.analytics.deviceId) {
    missingFields.add('deviceId')
  }

  return {
    missingFields: Array.from(missingFields),
    isValid: missingFields.size === 0,
  }
}

export const useNativeAppData = () => {
  const { reportInsufficientDataFromMobile } = useReport()
  const dispatch = useDispatch()
  const nativeAppStartupData = window.nativeAppStartupData as NativeAppStartupData

  useEffect(() => {
    const { isValid, missingFields } = validateNativeAppData(nativeAppStartupData)

    if (isValid) {
      dispatch(rootActions.appStartedFromNativeApp(nativeAppStartupData))
    } else {
      reportInsufficientDataFromMobile(missingFields)
    }
  }, [])
}
