import { getSelectedProductSize } from '@dominos/business/functions/menu/get-selected-product-size'
import { isProductMenuItemNew } from '@dominos/components'

type ProductSize = Bff.Menu.old.ProductSize
type MenuItemDependents = Bff.Menu.old.MenuItemDependents

export const getProductSize = (
  product?: MenuItemDependents | null,
  sizeCode?: string | null,
): ProductSize | undefined => {
  const productWithSizes =
    product?.type === 'Variety' || product?.type === 'Voucher' || (product && isProductMenuItemNew(product))
      ? undefined
      : product
  const value = sizeCode ?? productWithSizes?.defaultSize

  if (productWithSizes?.sizes?.length && value) {
    const tempMenuCustomisation: MenuCustomisationItem = {
      value,
      type: 'size',
      label: '',
    }

    return getSelectedProductSize(productWithSizes, [tempMenuCustomisation]) as ProductSize
  }

  return productWithSizes?.sizes ? (productWithSizes.sizes[0] as ProductSize) : undefined
}
