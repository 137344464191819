export const getProcessingTranslations = (t: import('i18next').TFunction) => ({
  recoverableError: t('Order Recoverable Error Message', {
    defaultValue: 'Sorry, there was a problem placing your order. Try again. If the problem persists, please call us.',
  }),
  unrecoverableError: t('Order Unrecoverable Error Message', {
    defaultValue: `Sorry, something's gone wrong. Please call us to complete your order.`,
  }),
  orderInterrupted: t('InterruptedDuringOrderProcessing', {
    defaultValue:
      'Are you sure you want to leave this page? Refreshing the page or closing this window may cause your order to be lost.',
  }),
})
