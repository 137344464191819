import React, { useState } from 'react'
import { IframeCardPopup, useCountryCode } from '@dominos/hooks-and-hocs'

interface KioskLegalLinkProps extends BaseProps {
  link: string
  title: string
}

const getLegalLink = (link: string, countryCode: BffContext.Countries | undefined): string => {
  const url = new URL(link)
  url.host = window.location.host
  url.pathname = `/${countryCode?.toLocaleLowerCase()}${url.pathname}`

  return url.href
}

export const KioskLegalLink = (props: KioskLegalLinkProps) => {
  const countryCode = useCountryCode()
  const [showLegalLinksFrame, setShowLegalLinks] = useState(false)

  const onPress = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    setShowLegalLinks(true)
  }

  const onLoad = (event: React.SyntheticEvent<HTMLIFrameElement, Event>) => {
    const anchors = event.currentTarget.contentWindow?.document.getElementsByTagName('a') ?? []
    for (const anchor of Array.from(anchors)) {
      anchor.addEventListener('click', (e: MouseEvent) => {
        e.preventDefault()
      })
    }
  }

  return (
    <>
      <a data-testid={`${props.testID}.legal-links.${props.title}`} href={props.link} onClick={onPress}>
        {props.title}
      </a>

      {showLegalLinksFrame && (
        <IframeCardPopup
          onDismiss={() => setShowLegalLinks(false)}
          testID={`${props.testID}.legal-links.${props.title}`}
          src={getLegalLink(props.link, countryCode)}
          onLoad={onLoad}
        />
      )}
    </>
  )
}
