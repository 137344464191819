import { FilterProductItem } from '@dominos/business/functions/menu/filter-product-item.interface'
import { isProductMenuItemNew } from '@dominos/components'

type ProductMenuItem = Bff.Menu.old.ProductMenuItem
type SwapSetProduct = Bff.Menu.old.SwapSetProduct
type PortionMenuItem = Bff.Menu.old.PortionMenuItem
type MenuItemDependents = Bff.Menu.old.MenuItemDependents
type MenuSection = Bff.Menu.old.MenuSection
type Menu = Bff.Menu.old.Menu
type MenuPage = Bff.Menu.old.MenuPage

const getSizesFromItem = (item: ProductMenuItem | SwapSetProduct | PortionMenuItem, productFilter: FilterProductItem) =>
  item.sizes &&
  (item.sizes as { code: string | null }[])
    .filter(
      (size: { code: string | null }) =>
        !productFilter.sizes?.length ||
        !productFilter.filterSizes ||
        productFilter.sizes?.find((filteredSize) => filteredSize.code === size.code),
    )
    .map((size) => {
      const sizeFilter = productFilter.sizes?.find((filteredSize) => filteredSize.code === size.code)

      return {
        ...size,
        hasSurcharge: sizeFilter ? sizeFilter.hasSurcharge : productFilter.hasSurcharge,
        surcharge: sizeFilter ? sizeFilter.surcharge : productFilter.surcharge,
      }
    })

export const getItemToPush = (item: MenuItemDependents, productFilters: FilterProductItem[]) => {
  if (!isProductMenuItemNew(item) && (item.type === 'Portion' || item.type === 'Product')) {
    const foundItemCodeInFilter = productFilters.find((itemToPush) => itemToPush.code === item.code)

    if (foundItemCodeInFilter) {
      const filteredSizes = getSizesFromItem(item, foundItemCodeInFilter)

      return foundItemCodeInFilter.sizes?.length && !filteredSizes
        ? undefined
        : ({
            ...item,
            sizes: filteredSizes,
            hasSurcharge: foundItemCodeInFilter.hasSurcharge,
            surcharge: foundItemCodeInFilter.surcharge,
            baseProduct: foundItemCodeInFilter.baseProduct,
          } as SwapSetProduct)
    }
  }
}

export const getFilteredItems = (products: MenuItemDependents[], productFilters: FilterProductItem[]) => {
  const filteredItems: { [key: string]: SwapSetProduct } = {}

  for (const item of products) {
    if (item.type === 'Variety') {
      item.items.forEach((subItem) => {
        const itemToPush = getItemToPush(subItem, productFilters)
        if (itemToPush) {
          filteredItems[subItem.code] = itemToPush
        }
      })
    } else {
      if (!isProductMenuItemNew(item)) {
        const itemToPush = getItemToPush(item, productFilters)
        if (itemToPush) {
          filteredItems[item.code] = itemToPush
        }
      }
    }
  }

  return Object.values(filteredItems)
}

export const getFilteredMenuSections = (sections: MenuSection[] | undefined, productFilters: FilterProductItem[]) =>
  sections &&
  sections
    .map((section: MenuSection) => ({
      ...section,
      items: getFilteredItems(section.items, productFilters),
    }))
    .filter((sect: MenuSection) => sect.items.length)

export const filterMenuByProductFilters = (menu: Menu | undefined, productFilters: FilterProductItem[]) => {
  if (!menu) {
    return undefined
  }

  if (!productFilters) {
    return menu
  }

  return {
    ...menu,
    pages: menu.pages
      .map((page: MenuPage) => ({
        ...page,
        sections: getFilteredMenuSections(page.sections, productFilters),
      }))
      .filter((page: MenuPage) => page.sections?.length),
  }
}
