type DimensionTypeCode = Bff.Common.DimensionTypeCode
type DimensionSet = Bff.Dimensions.DimensionSet

export const getDimensionFromDimensionSet = (
  dimensionType: DimensionTypeCode,
  dimensionSet: DimensionSet | undefined,
): string | undefined => {
  if (dimensionType === 'Size' && (dimensionSet?.type === 'Size' || dimensionSet?.type === 'SizeBase')) {
    return dimensionSet.size
  }
  if (dimensionType === 'Base' && dimensionSet?.type === 'SizeBase') {
    return dimensionSet.base
  }
}
