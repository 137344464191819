import React, { useEffect } from 'react'
import { FancyTextField, ValidationTextField } from '../../inputs'
import { AddressFieldsContainerProps, AddressFieldsUpdate } from './additional-address-fields.interface'
import {
  AddressFieldSize,
  AddressInputField,
  useAddressFields,
} from '@dominos/hooks-and-hocs/delivery-address/useAddressFields'

export const AdditionalAddressFieldsComponent = ({
  testID,
  searchAddress,
  addressInputFieldsSettings,
  onChange,
  addressFieldDependencies,
  hasClearInputButton,
}: AddressFieldsContainerProps) => {
  const { addressInputFields, setAddressInputFields, updateAddressField } = useAddressFields()

  useEffect(() => {
    setAddressInputFields(addressInputFieldsSettings, searchAddress, addressFieldDependencies)
  }, [addressInputFieldsSettings, searchAddress])

  useEffect(() => {
    if (onChange && addressInputFields) {
      const addressFieldsUpdate = getAddressFieldUpdate(addressInputFields)
      if (addressFieldsUpdate) onChange(addressFieldsUpdate)
    }
  }, [addressInputFields])

  return !addressInputFields || addressInputFieldsSettings.length == 0 ? (
    <></>
  ) : (
    <div data-testid={testID}>
      {addressInputFields.map((addressFieldDisplay) =>
        !addressFieldDisplay || (addressFieldDisplay.disabled && !addressFieldDisplay.value) ? (
          <></>
        ) : addressFieldDisplay.disabled ? (
          <FancyTextField
            key={addressFieldDisplay.addressFieldName}
            testID={`${addressFieldDisplay.addressFieldName}.field`}
            value={addressFieldDisplay.value}
            style={getStyle(addressFieldDisplay.addressFieldSize)}
            placeholder={addressFieldDisplay.placeHolder}
            disabled={true}
            name={addressFieldDisplay.addressFieldName}
          />
        ) : (
          <ValidationTextField
            key={addressFieldDisplay.addressFieldName}
            testID={`${addressFieldDisplay.addressFieldName}.field`}
            defaultValue={addressFieldDisplay.value}
            fieldName={addressFieldDisplay.addressFieldName}
            style={getStyle(addressFieldDisplay.addressFieldSize)}
            validationRules={addressFieldDisplay.currentValidationRules}
            placeholder={addressFieldDisplay.placeHolder}
            disabled={addressFieldDisplay.disabled}
            onChange={updateAddressField}
            name={addressFieldDisplay.addressFieldName}
            hasClearInputButton={hasClearInputButton}
          />
        ),
      )}
    </div>
  )
}

const getAddressFieldUpdate = (addressInputFields: AddressInputField[]): AddressFieldsUpdate | undefined => ({
  isValid: addressInputFields.every((a) => a.isValid ?? true),
  Address: getTAddress(addressInputFields),
})

const getTAddress = (addressInputFields: AddressInputField[]): TAddress => {
  let tAddress: TAddress = {}
  for (const addressInputField of addressInputFields) {
    const mappedTAddress = mapAddressFieldToTAddress(addressInputField)
    if (!mappedTAddress) continue
    tAddress = {
      ...tAddress,
      ...mappedTAddress,
    }
  }

  return tAddress
}

const mapAddressFieldToTAddress = (addressInputField: AddressInputField): TAddress => ({
  [addressInputField.addressFieldName]: {
    isValid: addressInputField.isValid ?? false,
    value: addressInputField.value,
  },
})

const getStyle = (addressFieldSize: AddressFieldSize) => {
  switch (addressFieldSize) {
    case 'large':
      return magicStyles.largeAddressField
    case 'medium':
      return magicStyles.mediumAddressField
    case 'small':
      return magicStyles.smallAddressField
    default:
      return magicStyles.largeAddressField
  }
}

const magicStyles = {
  smallAddressField: {
    width: 150,
    paddingRight: 10,
  },
  mediumAddressField: {
    width: 300,
    paddingRight: 10,
  },
  largeAddressField: {
    width: '100%',
  },
}
