import { useMutation, useQuery } from '@apollo/client'
import { feedbackQuestionsQuery, submitFeedbackMutation } from '@dominos/business/queries'
import { ActionButton, CollapsableTitledCard, Error, PopUpNotification } from '@dominos/components'
import { AutoScroll, useBreakpoints } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { FaceSymbolFeedback } from './face-symbol/face-symbol-feedback'
import { FeedbackSubmitted } from './feedback-submitted'
import { NumberFeedback } from './number-feedback'
import { loadingQuestionsErrors, submitQuestionsErrors } from './order-feedback-errors'
import styles from './order-feedback.less'
import { TextFeedback } from './text-feedback'

import { questionWasAnswered } from './utils'
import { isNativeApp, postWebViewMessage } from '@dominos/business/functions/native-app'

export const getValidationErrorMessage = (t: TFunction<'tracker'>, question: Bff.Feedback.FeedbackQuestion): string => {
  switch (question.displaySettings.ratingStyle) {
    case 'FaceSymbol': {
      return t('PleaseSelectRating', { defaultValue: 'Please select a value.' })
    }
    case 'Number': {
      return t('PleaseSelectRating', { defaultValue: 'Please select a value.' })
    }
    default:
      return ''
  }
}

export const selectFeedbackMechanism = (
  question: Bff.Feedback.FeedbackQuestion,
  onClick: (val: Bff.Feedback.FeedbackQuestionResponse) => void,
  error?: string,
): React.ReactElement | null => {
  switch (question.displaySettings.ratingStyle) {
    case 'FaceSymbol': {
      return <FaceSymbolFeedback key={question.questionId} question={question} onClick={onClick} error={error} />
    }
    case 'Number': {
      return <NumberFeedback key={question.questionId} question={question} onClick={onClick} error={error} />
    }
    case 'Text': {
      return <TextFeedback key={question.questionId} question={question} onEdit={onClick} error={error} />
    }
    default:
      return null
  }
}

const OrderFeedback = (props: { orderId: string; serviceMethod: string; autoScroll?: boolean }) => {
  const { t } = useTranslation('tracker')
  const { isMobile } = useBreakpoints()

  const [customerFeedback] = useState<Map<string, Bff.Feedback.FeedbackQuestionResponse>>(new Map())
  const [feedbackQuestions, setFeedbackQuestions] = useState<Bff.Feedback.FeedbackQuestion[]>()
  const [validateQuestions, setValidateQuestions] = useState<boolean>(false)
  const [alertFeedbackSubmitted, setAlertFeedbackSubmitted] = useState<boolean>(false)
  const isNative = isNativeApp()

  const FEEDBACK_TYPE = 'MealService'

  const {
    data,
    loading: questionsLoading,
    error: fetchQuestionsError,
  } = useQuery<{
    feedbackQuestions: Bff.Feedback.FeedbackQuestion[]
  }>(feedbackQuestionsQuery, {
    variables: {
      serviceMethod: props.serviceMethod,
      feedbackTypes: [FEEDBACK_TYPE],
    },
    fetchPolicy: 'no-cache',
  })

  const [sendFeedback, { loading, error: submitError, data: submitResponse }] = useMutation<{
    submitFeedback: boolean
  }>(submitFeedbackMutation, { errorPolicy: 'all', onError: () => {} })

  useEffect(() => {
    if (data?.feedbackQuestions) {
      setFeedbackQuestions(data.feedbackQuestions)
    }
  }, [data])

  useEffect(() => {
    if (submitResponse?.submitFeedback) {
      setAlertFeedbackSubmitted(true)
      if (isNative) {
        postWebViewMessage({
          type: 'tracking',
          data: { event: 'feedback-submit-success' },
        })
      }
    }
  }, [submitResponse])

  const feedbackSelected = (val: Bff.Feedback.FeedbackQuestionResponse) => {
    customerFeedback.set(val.questionId, val)
  }

  const allQuestionsCompleted = () =>
    feedbackQuestions?.every((question) => questionWasAnswered(question, customerFeedback.get(question.questionId)))

  const submitFeedback = () => {
    setValidateQuestions(true)

    if (allQuestionsCompleted()) {
      const questionSetId = feedbackQuestions?.find((fq) => fq.questionSetId)?.questionSetId ?? ''
      const mutationVars: Bff.Feedback.FeedbackResponse = {
        questionSetId,
        orderId: props.orderId,
        feedbackType: FEEDBACK_TYPE,
        feedback: Array.from(customerFeedback, ([_, feedbackResponse]) => feedbackResponse),
      }
      sendFeedback({
        variables: { input: mutationVars },
      })
    }
  }

  const closePopupNotification = () => setAlertFeedbackSubmitted(false)

  const showFeedback = !questionsLoading && !fetchQuestionsError

  const feedbackCardStyle: CommonViewStyle = {
    marginTop: '8px',
    textAlign: isMobile ? 'left' : 'center',
    paddingBottom: '8px',
  }

  const submitButtonStyle: CommonViewStyle = {
    minWidth: '200px',
  }

  return showFeedback ? (
    <div>
      {props.autoScroll && <AutoScroll id={'feedback'} />}
      {alertFeedbackSubmitted ? (
        <PopUpNotification
          heading={t('FeedbackSubmittedNotification', { defaultValue: 'Feedback Submitted' })}
          onClose={closePopupNotification}
        />
      ) : null}
      <Error tns={t} errorDefinitions={loadingQuestionsErrors} error={fetchQuestionsError ?? null} />
      <Error tns={t} errorDefinitions={submitQuestionsErrors} error={submitError ?? null} />
      <CollapsableTitledCard
        style={feedbackCardStyle}
        startCollapsed={false}
        primaryTitle={t('OrderFeedbackCardTitle', { defaultValue: 'Rate My Order' })}
        testID={'order-feedback-card'}
        noPadding
      >
        {submitResponse?.submitFeedback ? (
          <FeedbackSubmitted />
        ) : (
          <>
            <p className={styles.orderFeedbackByLine}>
              {t('OrderFeedbackByLine', {
                defaultValue:
                  'We love hearing what our customers have to say because it helps us improve your next experience.',
              })}
            </p>
            {(feedbackQuestions ? [...feedbackQuestions] : [])
              ?.sort((q1, q2) => q1.sortSequence - q2.sortSequence)
              ?.map((question) => {
                const errorMessage =
                  validateQuestions && !questionWasAnswered(question, customerFeedback.get(question.questionId))
                    ? getValidationErrorMessage(t, question)
                    : undefined

                return selectFeedbackMechanism(question, feedbackSelected, errorMessage)
              })}
            <div className={styles.buttonWrapper}>
              <ActionButton
                testID='order-feedback-submit-button'
                containerStyle={submitButtonStyle}
                text={t('FeedbackButtonDefaultText', { defaultValue: 'Submit Feedback' })}
                onPress={submitFeedback}
                loading={loading}
                disabled={submitResponse?.submitFeedback}
              />
            </div>
          </>
        )}
      </CollapsableTitledCard>
    </div>
  ) : null
}

export { OrderFeedback }
