import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { detailsFieldNames } from '../details-container-config'
import styles from './vip-offers.less'
import { VipOffersCheckbox, VipOffersProps, VipOffersTC } from '.'

export const VipOffersOptOutIndividual = (props: VipOffersProps) => {
  const { t } = useTranslation('checkout')

  useEffect(() => {
    props.onChange(detailsFieldNames.vipOffersOptOutEmail, !!props.initialVipOffersOptOutEmail)
    props.onChange(detailsFieldNames.vipOffersOptOutSMS, !!props.initialVipOffersOptOutSMS)
  }, [])

  return (
    <div className={styles.container}>
      <VipOffersCheckbox
        testID={`vip-offers-opt-out.${detailsFieldNames.vipOffersOptOutEmail}`}
        onChange={props.onChange}
        defaultChecked={props.initialVipOffersOptOutEmail}
        name={detailsFieldNames.vipOffersOptOutEmail}
        label={t('VIPOffersOptOutEmail', { defaultValue: 'I do not wish to receive any offers by email' })}
        labelClassName={styles.checkboxLabel}
      />
      <VipOffersCheckbox
        testID={`vip-offers-opt-out.${detailsFieldNames.vipOffersOptOutSMS}`}
        onChange={props.onChange}
        defaultChecked={props.initialVipOffersOptOutSMS}
        name={detailsFieldNames.vipOffersOptOutSMS}
        label={t('VIPOffersOptOutSMS', { defaultValue: 'I do not wish to receive any offers by SMS' })}
        labelClassName={styles.checkboxLabel}
      />

      {props.vipOffersTCEnabled && <VipOffersTC />}
    </div>
  )
}
