import { useTranslation } from 'react-i18next'
import { getAdyenPaymentMethods } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/adyen'
import { useCurrentOrderDetails, usePaymentsAvailableAtStore } from '@dominos/hooks-and-hocs'

// TODO: determined based on device/browser capabilities and user preferences
const EXPRESS_CHECKOUT_PREFERENCES = ['ApplePay']

// TODO: check payment method availability based on Adyen SDK `isAvailable()` method
export const useExpressCheckout = () => {
  const { t } = useTranslation('checkout')
  const { basketHeaderData } = useCurrentOrderDetails()
  const { paymentSettings } = usePaymentsAvailableAtStore(
    basketHeaderData?.storeNo,
    basketHeaderData?.serviceMethod,
    basketHeaderData?.serviceMethodSubType,
    basketHeaderData?.time ?? undefined,
  )

  const paymentSetting = paymentSettings?.find((s) => EXPRESS_CHECKOUT_PREFERENCES.includes(s.paymentMethod))

  if (!paymentSetting) {
    return {
      isAvailable: false,
      paymentMethodLabel: undefined,
    }
  }

  const adyenPaymentMethods = getAdyenPaymentMethods(paymentSetting)
  const translationKey =
    adyenPaymentMethods?.length > 0
      ? `${paymentSetting.paymentMethod}-${adyenPaymentMethods[0].type}`
      : paymentSetting.paymentMethod
  const translationDefault =
    adyenPaymentMethods?.length > 0 ? adyenPaymentMethods[0].name : paymentSetting.paymentMethod

  return {
    isAvailable: !!paymentSetting,
    paymentMethodLabel: t(translationKey, { defaultValue: translationDefault }),
    paymentSetting,
  }
}
