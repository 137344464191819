import { rootActions } from '@dominos/business'
import { createBasketLine } from '@dominos/business/functions/basket'
import { CollapsableTitledCard, Switch } from '@dominos/components'
import { ToggleDictionary, useBasket, useMultiToggleExclusiveSelector } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import css from './delivery-guarantee.less'
import { GuaranteeDisclaimers } from './guarantee-disclaimers/guarantee-disclaimers'
import useDeliveryGuaranteeOffer from './use-delivery-guarantee-offer'

const getProductsInBasket = (productCodes: string[], basket: Basket) => {
  if (basket.lines.length === 0) {
    return undefined
  }

  return basket.lines.find(
    (line) => line.type === 'BasketLine' && productCodes.some((code) => code === line.productCode),
  ) as BasketLine | undefined
}

export const DeliveryGuarantee = ({ testID }: { testID: string }) => {
  const { t } = useTranslation('checkout')
  const {
    toggles,
    set: setToggle,
    initialize: initializeToggles,
    reset: resetToggles,
  } = useMultiToggleExclusiveSelector()
  const { offer } = useDeliveryGuaranteeOffer()
  const dispatch = useDispatch()
  const { basket } = useBasket()
  const [togglesInitialized, setTogglesInitialized] = useState(false)

  const synchronizeWithBasket = (basketGuaranteeCode?: string) => {
    if (toggles && Object.keys(toggles).length > 0) {
      if (basketGuaranteeCode) {
        setToggle(basketGuaranteeCode, true)
      } else {
        resetToggles()
      }
    }
  }

  const initializeToggleData = (deliveryOffer: Bff.Offers.Offer, basketGuaranteeCode?: string) => {
    const transformedData = [...deliveryOffer.items]
      .sort((item1, item2) => item1.placementCode.localeCompare(item2.placementCode))
      .reduce((result: ToggleDictionary, item: Bff.Offers.Item) => {
        result[item.linkedItem.itemCode] = {
          title: `${item.name} - ${item.price}`,
          value: basketGuaranteeCode === item.linkedItem.itemCode,
        }

        return result
      }, {} as ToggleDictionary)

    if (Object.keys(transformedData).length > 0) {
      initializeToggles(transformedData)
    }
  }

  useEffect(() => {
    if (offer) {
      const foundBasketLine = getProductsInBasket(
        offer.items.map((item) => item.linkedItem.itemCode),
        basket,
      )
      if (basket && !togglesInitialized) {
        initializeToggleData(offer, foundBasketLine?.productCode)
        setTogglesInitialized(true)
      } else if (basket && togglesInitialized) {
        synchronizeWithBasket(foundBasketLine?.productCode)
      }
    }
  }, [offer])

  const onClicked = (itemCode: string, toggleValue: boolean) => {
    setToggle(itemCode, toggleValue)
    const existingBasketLine = getProductsInBasket(Object.keys(toggles!), basket)
    if (toggleValue) {
      const offerItemName = offer!.items.find((item) => item.linkedItem.itemCode === itemCode)!.name
      const lineItemToAdd = createBasketLine(1, {
        item: { type: 'Product', code: itemCode, media: { name: offerItemName, description: null } },
      })!
      if (!existingBasketLine) {
        dispatch(rootActions.addLinesToBasket({ add: [lineItemToAdd] }))
      } else if (existingBasketLine.productCode !== itemCode && lineItemToAdd) {
        dispatch(rootActions.replaceBasketLine({ remove: existingBasketLine, add: lineItemToAdd }))
      }
    } else if (existingBasketLine) {
      dispatch(rootActions.removeLineFromBasket(existingBasketLine))
    }
  }

  if (!offer || !toggles) {
    return null
  }

  return (
    <CollapsableTitledCard
      primaryTitle={t('DeliveryGuarantee', { defaultValue: 'Delivery Guarantee' })}
      startCollapsed={false}
      testID={`${testID}.card`}
      style={{ margin: '0px 0px 8px' }}
    >
      <div className={css.container}>
        {offer.media.name && (
          <div data-testid={`${testID}.title`} className={css.title}>
            {offer.media.name}
          </div>
        )}
        {offer.media.description && <div data-testid={`${testID}.description`}>{offer.media.description}</div>}
        <GuaranteeDisclaimers
          testID={`${testID}.fullDetails`}
          title={t('FullDetails', { defaultValue: 'Full Details' })}
          disclaimers={offer.disclaimers}
        />

        <div className={css.itemsContainer}>
          {toggles &&
            Object.keys(toggles).map((itemCode) => (
              <div key={itemCode} className={css.offerItem}>
                <Switch
                  testID={`${testID}.switch-${itemCode}`}
                  name={itemCode}
                  checked={toggles[itemCode].value}
                  onChange={onClicked}
                >
                  {`${toggles[itemCode].title}`}
                </Switch>
              </div>
            ))}
        </div>
      </div>
    </CollapsableTitledCard>
  )
}
