import React, { KeyboardEvent } from 'react'

import { onArrowKeys, onButtonClick } from '@dominos/business/functions'

import css from './address-list.less'

export const AddressLineRenderWrapper = ({
  testID,
  prefix,
  address,
  children,
  onPress,
}: AddressLineRenderWrapperProps) => {
  const onClick = () => {
    onPress(address)
  }

  const onKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    onButtonClick(event, () => onPress(address))

    if (onArrowKeys(event, prefix, address.uid, 5)) {
      return
    }
  }

  return (
    <div
      data-testid={testID}
      id={`${prefix}.${address.uid}`}
      className={css.wrapper}
      onClick={onClick}
      role='option'
      tabIndex={0}
      onKeyPress={onKeyUp}
      onKeyDown={onKeyUp}
    >
      {children}
    </div>
  )
}
