import React from 'react'
import { FlatList } from '@dominos/components/'
import { toPascalCase } from '@dominos/business/functions/text'
import { StoreItemRenderProps, StoreListProps } from './alternative-store-list.interface'
import css from './alternative-store-list.less'

const keyExtractor = (item: Bff.Stores.Store) => `${item.storeNo}`

export const AlternativeStoreList = (props: StoreListProps) => {
  const renderItem = (info: Bff.Stores.Store, key: string) => (
    <StoreItem key={key} store={info} onPress={props.onPress} selected={props.initalStoreSelected == info.storeNo} />
  )

  return (
    <div data-testid={props.testID}>
      <FlatList data={props.stores} renderItem={renderItem} keyExtractor={keyExtractor} />
    </div>
  )
}

const StoreItem = ({ store, onPress, selected }: StoreItemRenderProps) => {
  const storeLabel = `Domino's ${toPascalCase(store.media.name || '')}`

  return (
    <div className={css.storeContainer}>
      <input
        type='radio'
        value={store.storeNo}
        name='alternative-store'
        role='button'
        onClick={() => onPress(store)}
        id={store.storeNo.toString()}
        defaultChecked={selected}
      />
      <label htmlFor={store.storeNo.toString()} className={css.textContainer}>
        {storeLabel}
      </label>
    </div>
  )
}
