interface ItemWithLegends extends Bff.Offers.Item {
  legends?: (
    | {
        code: string
        image: {
          url: string
          altText: string
        }
        text: string
      }
    | {
        code: string
        text: string
      }
  )[]
}
export interface ExtendedOffer extends Bff.Offers.Offer {
  items: ItemWithLegends[]
}

export const mockBasketAddons: ExtendedOffer = {
  offerId: '15552',
  name: 'Test Basket Menu',
  disclaimers: [],
  isPretotypeOffer: false,
  items: [
    {
      id: '29013',
      name: 'Offre découverte : -3 € sur les pizzas Signatures',
      price: '$5.50',
      energy: '2652kJ',
      image: {
        url: 'https://order.dominos.com.au/ManagedAssets/AU/product/S00576/AU_S00576_en_hero_13029.jpg?v1300531314',
        altText: '@dominos/res/images/hardupsell/image_1.png',
      },
      placementCode: 'Placement 1',
      linkedItem: {
        itemType: 'Product',
        itemCode: '13707',
        subItemCode: null,
        componentType: null,
      },
      description: null,
      quantity: 0,
      autoAdd: false,
      conditions: [],
      legends: [
        {
          code: 'Hot',
          image: {
            url: '/ManagedAssets/AU/legend/Hot/AU_Hot_en_8590.png?v898687699',
            altText: 'Hot',
          },
          text: 'Chilli Level Hot',
        },
        {
          code: 'New',
          image: {
            url: '/ManagedAssets/AU/legend/New/AU_New_en_3739.png?v684283830',
            altText: 'NEW',
          },
          text: 'NEW',
        },
        {
          code: 'kJ.Standard',
          text: 'The average adult daily energy intake is 8700kJ',
        },
      ],
    },
    {
      id: '290136',
      name: 'Simply Mac & Cheese Pasta',
      price: '$5.50',
      energy: '2652kJ',
      image: {
        url: 'https://order.dominos.com.au/ManagedAssets/AU/product/N011/AU_N011_en_hero_13200.jpg?v1320813374',
        altText: '@dominos/res/images/hardupsell/image_1.png',
      },
      placementCode: 'Placement 1',
      linkedItem: {
        itemType: 'Product',
        itemCode: 'S00001',
        subItemCode: null,
        componentType: null,
      },
      description: null,
      quantity: 0,
      autoAdd: false,
      conditions: [],
      legends: [
        {
          code: 'Hot',
          image: {
            url: '/ManagedAssets/AU/legend/Hot/AU_Hot_en_8590.png?v898687699',
            altText: 'Hot',
          },
          text: 'Chilli Level Hot',
        },
        {
          code: 'New',
          image: {
            url: '/ManagedAssets/AU/legend/New/AU_New_en_3739.png?v684283830',
            altText: 'NEW',
          },
          text: 'NEW',
        },
        {
          code: 'kJ.Standard',
          text: 'The average adult daily energy intake is 8700kJ',
        },
      ],
    },
    {
      id: '29014',
      name: 'Simply Mac & Cheese Pasta',
      price: '$6.50',
      energy: '3040kJ',
      image: {
        url: 'https://order.dominos.com.au/ManagedAssets/AU/product/S00547/AU_S00547_en_menu_11861.png?v1010283043',
        altText: '@dominos/res/images/hardupsell/image_1.png',
      },
      placementCode: 'Placement 1',
      linkedItem: {
        itemType: 'Product',
        itemCode: 'S00263',
        subItemCode: null,
        componentType: null,
      },
      description: null,
      quantity: 0,
      autoAdd: false,
      conditions: [],
    },
    {
      id: '29015',
      name: 'Triple Cheese Bites',
      price: '$5.00',
      energy: '3060kJ',
      image: {
        url: 'https://order.dominos.com.au/ManagedAssets/AU/product/S00021/AU_S00021_en_menu_3970.jpg?v-528712723',
        altText: '@dominos/res/images/hardupsell/image_2.png',
      },
      placementCode: 'Placement 1',
      linkedItem: {
        itemType: 'Product',
        itemCode: 'S00419',
        subItemCode: null,
        componentType: null,
      },
      description: null,
      quantity: 0,
      autoAdd: false,
      conditions: [],
    },
    {
      id: '29033',
      name: 'Cheesy Loaded Fries',
      price: '$5.50',
      energy: '2652kJ',
      image: {
        url: 'https://order.dominos.com.au/ManagedAssets/AU/product/S00016/AU_S00016_en_menu_12569.jpg?v1149919752',
        altText: '@dominos/res/images/hardupsell/image_3.png',
      },
      placementCode: 'Placement 1',
      linkedItem: {
        itemType: 'Product',
        itemCode: 'S000012',
        subItemCode: null,
        componentType: null,
      },
      description: null,
      quantity: 0,
      autoAdd: false,
      conditions: [],
    },
    {
      id: '29089',
      name: 'Meatlovers Pasta',
      price: '$5.00',
      energy: '3060kJ',
      image: {
        url: 'https://order.dominos.com.au/ManagedAssets/AU/product/S00554/AU_S00554_en_menu_11861.png?v-92284608',
        altText: '@dominos/res/images/hardupsell/image_4.png',
      },
      placementCode: 'Placement 1',
      linkedItem: {
        itemType: 'Product',
        itemCode: 'S004192',
        subItemCode: null,
        componentType: null,
      },
      description: null,
      quantity: 0,
      autoAdd: false,
      conditions: [],
    },
    {
      id: '290134',
      name: 'Simply Mac & Cheese Pasta',
      price: '$5.50',
      energy: '2652kJ',
      image: {
        url: 'https://order.dominos.com.au/ManagedAssets/AU/product/S00566/AU_S00566_en_menu_12740.jpg?v547147567',
        altText: '@dominos/res/images/hardupsell/image_1.png',
      },
      placementCode: 'Placement 1',
      linkedItem: {
        itemType: 'Product',
        itemCode: 'S000013',
        subItemCode: null,
        componentType: null,
      },
      description: null,
      quantity: 0,
      autoAdd: false,
      conditions: [],
      legends: [
        {
          code: 'Hot',
          image: {
            url: '/ManagedAssets/AU/legend/Hot/AU_Hot_en_8590.png?v898687699',
            altText: 'Hot',
          },
          text: 'Chilli Level Hot',
        },
        {
          code: 'New',
          image: {
            url: '/ManagedAssets/AU/legend/New/AU_New_en_3739.png?v684283830',
            altText: 'NEW',
          },
          text: 'NEW',
        },
        {
          code: 'kJ.Standard',
          text: 'The average adult daily energy intake is 8700kJ',
        },
      ],
    },
    {
      id: '29014',
      name: 'Cheese & Garlic Scrolls',
      price: '$6.50',
      energy: '3040kJ',
      image: {
        url: 'https://order.dominos.com.au/ManagedAssets/AU/product/S00435/AU_S00435_en_menu_3970.jpg?v-1072907251',
        altText: '@dominos/res/images/hardupsell/image_5.png',
      },
      placementCode: 'Placement 1',
      linkedItem: {
        itemType: 'Product',
        itemCode: 'S002632',
        subItemCode: null,
        componentType: null,
      },
      description: null,
      quantity: 0,
      autoAdd: false,
      conditions: [],
    },
  ],
  layout: 'SingleItem',
  locations: [
    {
      locationType: 'ProductDetails',
      locationCode: 'Menu.Basket',
      showBanner: false,
      showPopUp: true,
      autoPopUp: true,
    },
  ],
  media: {
    name: 'Test Basket Menu',
    description: 'Test Basket Menu Desc',
    banner: {
      url: null,
      altText: null,
    },
    popUp: {
      url: null,
      altText: null,
    },
    tile: {
      url: null,
      altText: null,
    },
  },
  showBanner: false,
  showPopup: false,
  autoPopup: false,
  conditions: [],
  secondaryOfferId: null,
  additionalData: [],
}

export const mockLegends = [
  {
    code: 'Hot',
    image: {
      url: '/ManagedAssets/AU/legend/Hot/AU_Hot_en_8590.png?v898687699',
      altText: 'Hot',
    },
    text: 'Chilli Level Hot',
  },
  {
    code: 'Veg',
    image: {
      url: '/ManagedAssets/AU/legend/Vegetarian/AU_Vegetarian_en_3739.png?v635682497',
      altText: 'Hot',
    },
    text: 'Veg Level',
  },
]
