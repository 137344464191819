export const getDefaultLanguageFromBrowserLanguages = (
  countryLanguages: string[],
): BffContext.Languages | undefined => {
  const browserLanguages = navigator.languages || [
    navigator.language || (navigator as { userLanguage?: string }).userLanguage,
  ]

  for (const browserLanguage of browserLanguages) {
    for (const countryLanguage of countryLanguages) {
      if (browserLanguage.toLowerCase().startsWith(countryLanguage.toLowerCase())) {
        return countryLanguage as BffContext.Languages
      }
    }
  }
}
