import { createContext, useContext } from 'react'
import { SpotlightKey } from '@dominos/components/spotlight'

export interface SpotlightContextProps {
  spotlightKey?: SpotlightKey
  isSpotlightOn: (key?: SpotlightKey) => boolean
  registerToSpotlight: (element: HTMLElement, content: JSX.Element) => SpotlightKey
  unregisterFromSpotlight: (key: SpotlightKey) => void
  displaySpotlight: (key: SpotlightKey) => void
  hideSpotlight: () => void
}

export const SpotlightContext = createContext<SpotlightContextProps>({
  spotlightKey: undefined,
  isSpotlightOn: () => false,
  registerToSpotlight: () => '',
  unregisterFromSpotlight: () => {},
  displaySpotlight: () => {},
  hideSpotlight: () => {},
})

export const useSpotlightContext = () => useContext(SpotlightContext)
