import { ActionButton, Modal } from '@dominos/components'
import { PaymentSetting } from '@dominos/hooks-and-hocs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './payment-adyen.less'
import { PaymentSavedCreditCard } from './payment-saved-creditcard'

interface PaymentSavedAdyenCreditCardProps {
  testID: string
  selectedPaymentSetting: PaymentSetting
  savedCreditCardSettings: PaymentSetting[]
  onSelect: (paymentSetting: PaymentSetting) => void
  onClose: () => void
}

export const PaymentSavedAdyenCreditCard = ({
  testID,
  selectedPaymentSetting,
  savedCreditCardSettings,
  onSelect,
  onClose,
}: PaymentSavedAdyenCreditCardProps) => {
  const { t } = useTranslation('checkout')
  const contentTestID = `${testID}.popup`
  const [selectedSavedCreditCard, setSelectedSavedCreditCard] = useState<PaymentSetting>(selectedPaymentSetting)
  const onPress = (paymentSetting: PaymentSetting) => {
    setSelectedSavedCreditCard(paymentSetting)
  }
  const handleClick = () => {
    if (selectedSavedCreditCard) {
      onSelect(selectedSavedCreditCard)
    }
  }

  return (
    <Modal testID='payment-saved-adyen-credit-card-popup' onModalClose={onClose}>
      <div data-testid={contentTestID} className={css.cardContainer}>
        <div className={css.contentContainer}>
          <h4 className={css.contentHeader}>{t('savedCreditCardTitle', { defaultValue: 'Saved Payment Methods' })}</h4>
          {savedCreditCardSettings.map((cc, idx) => (
            <PaymentSavedCreditCard
              testID={`${contentTestID}.card.${idx}`}
              key={idx}
              idx={idx}
              selected={selectedSavedCreditCard === cc}
              paymentSetting={cc}
              onPress={onPress}
            />
          ))}
          <div className={css.buttonContainer}>
            <ActionButton
              text={t('Cancel')}
              onPress={onClose}
              testID={`${testID}.close-button`}
              containerStyle={{ backgroundColor: '#4a4a4a', width: '48%' }}
              textFontWeight='bold'
            />
            <ActionButton
              text={t('Select')}
              onPress={handleClick}
              testID={`${testID}.select-button`}
              containerStyle={{ width: '48%' }}
              textFontWeight='bold'
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
