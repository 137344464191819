import gql from 'graphql-tag'

export const individualStoreQuery = gql`
  query individualStoreWithTime($storeNo: Int!, $orderTime: String, $serviceMethod: ServiceMethodEnum!) {
    store(storeNo: $storeNo, orderTime: $orderTime) {
      storeNo
      onlineOrderingEnabled
      media {
        name
        displayAddress
      }
      geo {
        longitude
        latitude
      }
      timeZone
      loyalty {
        isEnabled
      }
      priceInfo {
        taxRate
        minOrderPricePickup
        minOrderPriceDelivery
      }
      pickupSubTypes
      deliverySubTypes
      serviceMethods {
        pickup
        delivery
        dineIn
        message
      }
      features {
        featureName
        isEnabled
        settings {
          ... on TipTheDriverSettings {
            minimumTip
            maximumTip
            presets {
              value
              label
            }
          }
        }
      }
    }
    orderingTimes(serviceMethod: $serviceMethod, storeNo: $storeNo) {
      isAsapAvailable
      isWithinTradingHours
      isAlternateServiceMethodAvailable
      dates {
        date
        message
        slotsWithAvailability {
          slot
          isAvailable
        }
      }
    }
  }
`
