import { BasketLineData } from '@dominos/business/functions/basket'
import { isProductMenuItemNew } from '@dominos/components'

type ProductNutritionals = Bff.Menu.old.ProductNutritionals
type ProductAllergen = Bff.Menu.old.ProductAllergen
type Ingredient = Bff.Menu.old.Ingredient
export interface NutritionalInfo {
  productNutritionals: ProductNutritionals | null
  productAllergens: ProductAllergen[]
}

export const getNutritionalInfo = (currentProduct?: BasketLineData | null): NutritionalInfo => {
  const result: NutritionalInfo = {} as NutritionalInfo

  const size = getCurrentSize(currentProduct)
  const base: Ingredient | undefined | null = size ? getSelectedBase(size, currentProduct) : null

  result.productNutritionals = base?.nutritionals || size?.nutritionals || null
  result.productAllergens = base?.allergens || size?.allergens || []

  return result
}

const getSelectedBase = (size: ProductSize, currentProduct?: BasketLineData | null) => {
  if (currentProduct?.item?.type === 'Product') {
    const selectedBaseCode = currentProduct.base ? currentProduct.base?.add : size?.recipe?.base?.code

    return size?.swaps?.bases?.ingredients.find((item) => item.code === selectedBaseCode)
  }

  return null
}

const getCurrentSize = (currentProduct?: BasketLineData | null) => {
  if (
    currentProduct?.item &&
    !isProductMenuItemNew(currentProduct?.item) &&
    currentProduct?.item?.type === 'Product' &&
    currentProduct.item.sizes
  ) {
    return (
      currentProduct.item.sizes.find((item) => item.code === currentProduct.sizeCode) || currentProduct.item.sizes[0]
    )
  }

  return null
}
