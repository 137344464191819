import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const SetMenuIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='1.77635684e-15 0 13.9994 0 13.9994 2 1.77635684e-15 2' />
        <polygon id='path-3' points='0 -1.77635684e-15 16.5775347 -1.77635684e-15 16.5775347 4.5598 0 4.5598' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-15' transform='translate(0.000000, -0.000000)'>
          <path
            d='M20.0035,6.00005127 C19.9455,6.00005127 19.8865,5.99505127 19.8275,5.98405127 C19.2875,5.88705127 18.9275,5.37105127 19.0245,4.83105127 L19.7425,0.819051272 C19.8385,0.279051272 20.3565,-0.0819487284 20.8955,0.0160512716 C21.4355,0.112051272 21.7955,0.629051272 21.6985,1.16905127 L20.9805,5.18105127 C20.8945,5.66205127 20.4755,6.00005127 20.0035,6.00005127'
            id='Fill-1'
            fill={fill}
          />
          <g id='Group-5' transform='translate(10.000600, 3.999851)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-4' />
            <path
              d='M13.005,2 L0.994,2 C0.445,2 1.77635684e-15,1.552 1.77635684e-15,1 C1.77635684e-15,0.448 0.445,0 0.994,0 L13.005,0 C13.554,0 14,0.448 14,1 C14,1.552 13.554,2 13.005,2'
              id='Fill-3'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <path
            d='M22.7667,4.35775127 C22.5767,4.13075127 22.2957,3.99975127 21.9997,3.99975127 L10.9997,3.99975127 C10.4477,3.99975127 9.9997,4.44775127 9.9997,4.99975127 C9.9997,5.55175127 10.4477,5.99975127 10.9997,5.99975127 L20.8057,5.99975127 L20.4497,7.99575127 L13.5657,7.99575127 C12.8607,7.99575127 12.4127,8.75075127 12.7507,9.36975127 L18.0107,18.9887513 L18.6457,18.9887513 C18.6657,18.9897513 18.6827,18.9997513 18.7027,18.9997513 L19.6797,18.9997513 C20.1647,18.9997513 20.5797,18.6527513 20.6647,18.1757513 L22.9847,5.17575127 C23.0367,4.88375127 22.9567,4.58475127 22.7667,4.35775127'
            id='Fill-6'
            fill={fill}
          />
          <g id='Group-10' transform='translate(0.000000, 19.440051)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-9' />
            <path
              d='M15.7838,0.072 C13.4718,0.87 10.9938,1.31 8.4148,1.31 C5.7568,1.31 3.2058,0.845 0.8338,-1.77635684e-15 L0.1828,1.172 C-0.0282,1.552 -0.0582,2.001 0.1008,2.406 C0.2608,2.811 0.5898,3.12 1.0028,3.254 C3.7148,4.133 6.0978,4.56 8.2888,4.56 C10.4798,4.56 12.8628,4.133 15.5748,3.254 C15.9878,3.12 16.3168,2.811 16.4758,2.406 C16.6358,2.001 16.6058,1.552 16.3948,1.172 L15.7838,0.072 Z'
              id='Fill-8'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
          <path
            d='M5.8521,14.0442513 C6.3021,14.0442513 6.6681,14.4092513 6.6681,14.8602513 C6.6681,15.3102513 6.3021,15.6752513 5.8521,15.6752513 C5.4021,15.6752513 5.0361,15.3102513 5.0361,14.8602513 C5.0361,14.4092513 5.4021,14.0442513 5.8521,14.0442513 M8.1361,10.3692513 C8.8871,10.3692513 9.4951,10.9782513 9.4951,11.7292513 C9.4951,12.4802513 8.8871,13.0882513 8.1361,13.0882513 C7.3851,13.0882513 6.7761,12.4802513 6.7761,11.7292513 C6.7761,10.9782513 7.3851,10.3692513 8.1361,10.3692513 M11.5981,15.6752513 C11.5981,16.7272513 10.7461,17.5792513 9.6951,17.5792513 C8.6431,17.5792513 7.7911,16.7272513 7.7911,15.6752513 C7.7911,14.6242513 8.6431,13.7722513 9.6951,13.7722513 C10.7461,13.7722513 11.5981,14.6242513 11.5981,15.6752513 M8.4151,18.7502513 C10.6411,18.7502513 12.7821,18.3852513 14.7931,17.7292513 L8.7641,6.87725127 C8.6681,6.70425127 8.4861,6.59725127 8.2891,6.59725127 C8.0911,6.59725127 7.9091,6.70425127 7.8131,6.87725127 L1.8271,17.6532513 C3.8991,18.3532513 6.1091,18.7502513 8.4151,18.7502513'
            id='Fill-11'
            fill={fill}
          />
          <path
            d='M18.0714,14.2308513 C18.0084,14.2308513 17.9454,14.2248513 17.8814,14.2128513 C17.3384,14.1078513 16.9834,13.5838513 17.0884,13.0418513 L17.5014,10.8978513 C17.6064,10.3558513 18.1324,10.0028513 18.6724,10.1058513 C19.2144,10.2098513 19.5694,10.7338513 19.4654,11.2768513 L19.0524,13.4198513 C18.9594,13.8978513 18.5404,14.2308513 18.0714,14.2308513'
            id='Fill-13'
            fill='#FFFFFF'
          />
        </g>
      </g>
    </svg>
  )
}
