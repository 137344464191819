import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 26,
  height: 24,
}

const Calendar = (props: SvgIconProps) => {
  const fill = props.fill || '#454545'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 25 23'>
      <path
        fill={fill}
        fillRule='nonzero'
        stroke='none'
        strokeWidth='1'
        d='M16.567 38.67h15.895c2.57 0 3.831-1.263 3.831-3.787V20.55c0-2.524-1.262-3.786-3.83-3.786H16.566c-2.554 0-3.831 1.262-3.831 3.786v14.333c0 2.524 1.277 3.786 3.83 3.786zm-.21-2.179c-.932 0-1.458-.496-1.458-1.472V23.72c0-.977.526-1.473 1.457-1.473h16.301c.932 0 1.473.496 1.473 1.473v11.298c0 .976-.541 1.472-1.473 1.472h-16.3zM22.23 26.5h.69c.452 0 .602-.15.602-.586v-.691c0-.436-.15-.586-.601-.586h-.691c-.436 0-.586.15-.586.586v.691c0 .436.15.586.586.586zm3.876 0h.691c.436 0 .586-.15.586-.586v-.691c0-.436-.15-.586-.586-.586h-.691c-.45 0-.601.15-.601.586v.691c0 .436.15.586.601.586zm3.861 0h.691c.436 0 .601-.15.601-.586v-.691c0-.436-.165-.586-.6-.586h-.692c-.45 0-.6.15-.6.586v.691c0 .436.15.586.6.586zM18.37 30.301h.69c.451 0 .602-.15.602-.586v-.69c0-.437-.15-.587-.601-.587h-.691c-.436 0-.601.15-.601.586v.691c0 .436.165.586.6.586zm3.86 0h.692c.45 0 .6-.15.6-.586v-.69c0-.437-.15-.587-.6-.587h-.691c-.436 0-.586.15-.586.586v.691c0 .436.15.586.586.586zm3.877 0h.691c.436 0 .586-.15.586-.586v-.69c0-.437-.15-.587-.586-.587h-.691c-.45 0-.601.15-.601.586v.691c0 .436.15.586.601.586zm3.861 0h.691c.436 0 .601-.15.601-.586v-.69c0-.437-.165-.587-.6-.587h-.692c-.45 0-.6.15-.6.586v.691c0 .436.15.586.6.586zM18.37 34.102h.69c.451 0 .602-.15.602-.586v-.69c0-.437-.15-.587-.601-.587h-.691c-.436 0-.601.15-.601.586v.691c0 .436.165.586.6.586zm3.86 0h.692c.45 0 .6-.15.6-.586v-.69c0-.437-.15-.587-.6-.587h-.691c-.436 0-.586.15-.586.586v.691c0 .436.15.586.586.586zm3.877 0h.691c.436 0 .586-.15.586-.586v-.69c0-.437-.15-.587-.586-.587h-.691c-.45 0-.601.15-.601.586v.691c0 .436.15.586.601.586z'
        transform='translate(-12 -16)'
      />
    </svg>
  )
}

export { Calendar }
