import React from 'react'

import {
  EmptyFooter,
  ErrorNotifier,
  MyAccountLayout,
  SimpleNavigationTitle,
  StackNavigationBase,
} from '@dominos/components'

export const MyAccountScene = () => (
  <StackNavigationBase
    testID='my-account-layout'
    header={<SimpleNavigationTitle showBackButton showAccount />}
    footer={<EmptyFooter />}
  >
    <ErrorNotifier>
      <MyAccountLayout />
    </ErrorNotifier>
  </StackNavigationBase>
)
