import { BasketLineData, createBasketLine } from '@dominos/business/functions/basket'
import { isProductMenuItemNew } from '@dominos/components'

type MenuItemDependents = Bff.Menu.old.MenuItemDependents

export const getBasketLineToAdd = (
  linkedItemToAdd: Bff.Offers.LinkedItem,
  products: Map<string, MenuItemDependents>,
) => {
  const product = products.get(linkedItemToAdd.itemCode)
  if (product && !isProductMenuItemNew(product) && product.type === 'Product') {
    return createBasketLine(1, {
      item: product,
      sizeCode: linkedItemToAdd.subItemCode ?? product.defaultSize ?? undefined,
    } as BasketLineData)
  }

  return null
}

export const getBasketLinesToAdd = (itemsToAdd: Bff.Offers.Item[], products: Map<string, MenuItemDependents>) =>
  itemsToAdd
    .filter((item) => item.linkedItem.itemType === 'Product')
    .map((item) => item.linkedItem)
    .reduce<BasketLine[]>((acc, currentItem) => {
      const basketLine = getBasketLineToAdd(currentItem, products)

      if (basketLine) {
        acc.push(basketLine)
      }

      return acc
    }, [])

export const getCouponsToAdd = (itemsToAdd: Bff.Offers.Item[]) =>
  itemsToAdd.filter((item) => item.linkedItem.itemType === 'Voucher').map(({ linkedItem }) => linkedItem.itemCode)

export const extractProductCodesFromOffer = (offer?: Some<Bff.Offers.Offer, 'items'>): string[] => {
  if (!offer) {
    return []
  }

  return offer.items
    .filter((item) => !!item.linkedItem && item.linkedItem.itemType === 'Product')
    .map((item) => item.linkedItem.itemCode)
    .filter((value, index, self) => self.indexOf(value) === index)
}
