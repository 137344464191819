import React from 'react'
import { BasketLine } from '../../../basket-line'
import { CouponLine } from '../../../coupon-line'

interface OrderDetailsBasketItemProps {
  testID: string
  removeLine?: (lineItem: BasketCoupon | BasketLineItem) => void
  editLine?: BasketLineProps['onEdit']
  hideEditButton?: boolean
  hideSwapButton?: boolean
  hideRemoveButton?: boolean
  line: BasketLine | BasketCoupon
  showVoucherNotBestValueErrorMessage: boolean
}

export const OrderDetailsBasketItem = ({
  testID,
  removeLine,
  editLine,
  hideEditButton = false,
  hideSwapButton = false,
  hideRemoveButton = false,
  line,
  showVoucherNotBestValueErrorMessage,
}: OrderDetailsBasketItemProps) => {
  if (line.type === 'BasketLine') {
    return (
      <BasketLine
        testID={`${testID}.line`}
        lineData={line}
        onRemove={removeLine}
        onEdit={editLine}
        hideEditButton={hideEditButton}
        hideRemoveButton={hideRemoveButton}
        hideSwapButton
      />
    )
  }

  if (line.type === 'BasketCoupon') {
    return (
      <CouponLine
        testID={`${testID}.coupon.${line.code}`}
        couponLine={line}
        onEditLine={editLine}
        onRemoveLine={removeLine}
        onRemove={removeLine}
        hideEditButton={hideEditButton}
        hideRemoveButton={hideRemoveButton}
        hideSwapButton={hideSwapButton}
        showVoucherNotBestValueErrorMessage={showVoucherNotBestValueErrorMessage}
      />
    )
  }

  return null
}
