/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react'

import { GeoLocationInterface } from '@dominos/interfaces'
import { getMapCenterAndZoomLevelByCounty, MapProvider } from '@dominos/components'

import css from '../delivery-address.less'

interface MapContainerProps {
  testID: string
  deliveryLocation: GeoLocationInterface | undefined
  detectedLocation?: GeoLocationInterface | undefined
  locationDetectionPermitted: boolean | undefined
  onMarkerDrop: (addressLine: GeoLocationInterface) => void
}

const DEFAULT_ZOOM_LEVEL = 20
const DELIVERY_SERVICE_METHOD = 'Delivery'

export const MapProviderContainer = (props: MapContainerProps) => {
  const { testID, deliveryLocation, detectedLocation, locationDetectionPermitted, onMarkerDrop } = props

  const [mapCenterState, setMapCenterState] = useState<GeoLocationInterface | undefined>()
  const [zoomState, setZoomState] = useState<number>()
  const [deliveryMarkerLocation, setDeliveryMarkerLocation] = useState<GeoLocationInterface | undefined>()

  useEffect(() => {
    if (typeof locationDetectionPermitted === 'undefined') return

    if (locationDetectionPermitted && detectedLocation) {
      setMapCenterState(detectedLocation)
      setZoomState(DEFAULT_ZOOM_LEVEL)
    }

    if (!locationDetectionPermitted && !detectedLocation) {
      const mapCenterResponse = getMapCenterAndZoomLevelByCounty()
      setMapCenterState({ latitude: mapCenterResponse.latitude, longitude: mapCenterResponse.longitude })
      setZoomState(mapCenterResponse.zoomLevel)
    }
  }, [detectedLocation, locationDetectionPermitted])

  useEffect(() => {
    if (!deliveryLocation?.latitude || !deliveryLocation?.longitude) return

    setZoomState(DEFAULT_ZOOM_LEVEL)
    setDeliveryMarkerLocation(deliveryLocation)
    setMapCenterState(deliveryLocation)
  }, [deliveryLocation])

  const handleMarkerDrop = (location: GeoLocationInterface) => {
    onMarkerDrop(location)
  }

  return (
    <MapProvider
      testID={testID}
      provider={'google'}
      className={css.mapProvider}
      mapCenterLocation={mapCenterState}
      deliveryMarkerLocation={deliveryMarkerLocation}
      zoom={zoomState}
      onMarkerDrop={handleMarkerDrop}
      mapMode={DELIVERY_SERVICE_METHOD}
    />
  )
}
