import { ErrorDefinitions } from '@dominos/interfaces'

const storeErrorDefinitions: ErrorDefinitions = {
  UNABLE_TO_RESOLVE: {
    translation: {
      key: 'GenericError',
      options: {
        defaultValue: 'Something has gone wrong. Please try again.',
      },
    },
    display: 'popup',
  },
  NETWORK_ERROR: {
    translation: {
      key: 'GenericError',
      options: {
        defaultValue: 'Something has gone wrong. Please try again.',
      },
    },
    display: 'popup',
  },
}

export { storeErrorDefinitions }
