/* eslint-disable no-redeclare */
import { useCountryCode } from '@dominos/hooks-and-hocs'

/**
 * CountryToggle is used to make the toggle between countries more
 * explicit, and it centralizes the toggle logic so it's easier to
 * update if that logic changes. Another side benefit is that we can
 * use find all references to find all the places that have country
 * specific implementations.
 *
 * NOTE:
 *
 * * When all the countries are specified or a default is provided,
 * the return type will be T. Otherwise, the return type will be T | null
 *
 * * This can also be used like a normal function if needed
 *
 * @param props expect to receive a ReactElement for each country
 */
export function CountryToggle<T>(props: CountryObject<T> | (Partial<CountryObject<T>> & { default: T })): T
export function CountryToggle<T>(props: Partial<CountryObject<T>>): T | null
export function CountryToggle<T>(props: Partial<CountryObject<T>> & { default?: T }) {
  const countryCode = useCountryCode()

  if (!countryCode) {
    return null
  }

  const countryElement = props[countryCode]

  if (countryElement) {
    return countryElement
  }

  if (props.default) {
    return props.default
  }

  return null
}
