import React from 'react'
import { ActionButton, CollapsableTitledCard } from '@dominos/components'
import { ProductNutritionalViewProps } from './product-nutritional.interface'
import { AllergenInfo, ProductNutritionalLine } from './partials'
import css from './product-nutritional-view.less'

export const ProductNutritionalView = ({ testID, viewModel, t }: ProductNutritionalViewProps): JSX.Element | null => {
  const {
    productNutritionalTypes,
    productNutritionals,
    productAllergens,
    showContainsAllergensOnly,
    title,
    url,
    buttonText,
    description,
    nutritionalTitle,
    showInfoButton,
  } = viewModel

  const onPress = () => {
    window.open(url)
  }

  return (
    <>
      {productNutritionals || showInfoButton ? (
        <CollapsableTitledCard testID={`${testID}.card`} primaryTitle={title} noPadding={false} startCollapsed={true}>
          {productNutritionals ? (
            <div className={css.nutritionalContainer} data-testid={`${testID}.nutritionals-container`}>
              <div>{description}</div>
              <div className={css.nutritionalTitle}>{nutritionalTitle}</div>
              {productNutritionalTypes.map((productNutritionalType, index) => (
                <ProductNutritionalLine
                  key={index}
                  info={productNutritionals[productNutritionalType.keyName]}
                  keyName={productNutritionalType.keyName}
                  defaultUnit={productNutritionalType.defaultUnit}
                  t={t}
                />
              ))}
              <AllergenInfo
                info={productAllergens}
                testID={testID}
                t={t}
                showContainsAllergensOnly={showContainsAllergensOnly}
              />
            </div>
          ) : null}

          {showInfoButton ? (
            <div className={css.buttonContainer}>
              <ActionButton
                data-testid={`${testID}.button`}
                testID={`${testID}.button`}
                text={buttonText}
                onPress={onPress}
                containerStyle={{ width: '100%' }}
                textFontWeight='bold'
              />
            </div>
          ) : null}
        </CollapsableTitledCard>
      ) : null}
    </>
  )
}
