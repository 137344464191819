import { getETARangeInMinutes } from './get-eta-range-in-minutes'
import { isTimeParsable } from './is-time-parsable'
import { DateTime } from 'luxon'

export const getETAReportLabel = (from: DateTime, to: DateTime): `${number}-${number}` | null => {
  if (isTimeParsable(from, to)) {
    const rangeInMinutes = getETARangeInMinutes(from, to)

    return `${rangeInMinutes.from}-${rangeInMinutes.to}`
  }

  return null
}
