import { useLazyQuery } from '@apollo/client'
import { customerLoyaltyPointsHistoryQuery } from '@dominos/business/queries'
import { useCustomer } from '@dominos/hooks-and-hocs'
import { useCallback, useEffect, useMemo, useState } from 'react'

const RECORD_COUNT = 20

interface LoyaltyHistoryOptions {
  pageSize?: number
}

export const useLoyaltyHistory = (options?: LoyaltyHistoryOptions) => {
  const { customer, loading: loadingCustomer, error: errorCustomer } = useCustomer()
  const [page, setPage] = useState(0)
  const [history, setHistory] = useState<Bff.Customers.CustomerLoyaltyPointsHistory['transactions']>([])
  const customerId = useMemo(() => customer?.id, [customer?.id])
  const [reload, { data, loading: loadingHistory, error: errorHistory }] = useLazyQuery<{
    customer: Bff.Customers.Customer | null
  }>(customerLoyaltyPointsHistoryQuery, { errorPolicy: 'all' })

  useEffect(() => {
    reload({ variables: { page, customerId, pageSize: options?.pageSize || RECORD_COUNT } })
  }, [])

  useEffect(() => {
    if (data) {
      setPage((previous) => previous + 1)
      setHistory((prevHistory) => [...prevHistory, ...(data.customer?.loyalty?.pointsHistory?.transactions ?? [])])
    }
  }, [data])

  const hasMore = useMemo(() => {
    if (data?.customer?.loyalty?.pointsHistory && history) {
      return data.customer.loyalty.pointsHistory.totalItems > history.length
    }

    return false
  }, [history])

  const loadMore = useCallback(
    () => hasMore && reload({ variables: { customerId, page, pageSize: options?.pageSize || RECORD_COUNT } }),
    [hasMore, page],
  )

  const loading = useMemo(() => loadingCustomer || loadingHistory, [loadingCustomer, loadingHistory])

  return {
    history,
    hasMore,
    loadMore,
    loading,
    error: errorHistory || errorCustomer,
  }
}
