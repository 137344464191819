import { useLDClient } from 'launchdarkly-react-client-sdk'

const useFeatureEvent = () => {
  const LaunchDarklyClient = useLDClient()

  const trackEvent = async (eventName: string, metricValue?: number) => {
    LaunchDarklyClient?.track(eventName, undefined, metricValue)
  }

  return { trackEvent }
}

export default useFeatureEvent
