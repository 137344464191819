import React from 'react'
import { CreateProductBuildYourOwnViewProps } from './create-product-build-your-own-view-model'
import css from './product-build-your-own-view.less'
import { CollapsableTitledCard } from '@dominos/components/cards/collapsable-titled-card'

export const ProductBuildYourOwnView = ({
  testID,
  viewModel: {
    buildYourOwnTitle,
    createHalfHalfText,
    createQuattroText,
    color,
    isCreateQuattroEnabled,
    isCreateHalfHalfEnabled,
  },
  onCreateHalfHalfClick,
  onCreateQuattroClick,
  onToggleExpanded,
}: CreateProductBuildYourOwnViewProps) => (
  <CollapsableTitledCard
    testID={`${testID}.card`}
    primaryTitle={buildYourOwnTitle}
    noPadding={false}
    startCollapsed={true}
    style={{ color }}
    onToggleExpanded={onToggleExpanded}
  >
    <div className={css.container}>
      {isCreateHalfHalfEnabled && (
        <div onClick={onCreateHalfHalfClick} className={css.createAction}>
          <span className={css.imageHalfHalf}></span>
          <span>{createHalfHalfText}</span>
        </div>
      )}
      {isCreateQuattroEnabled && (
        <div onClick={onCreateQuattroClick} className={css.createAction}>
          <span className={css.imageQuattro}></span>
          <span>{createQuattroText}</span>
        </div>
      )}
    </div>
  </CollapsableTitledCard>
)
