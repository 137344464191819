import React from 'react'

const PortionEmptyBottomRightSideIcon = () => (
  <svg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23 11.5027C23 9.92402 22.7005 8.44059 22.1016 7.05243C21.5026 5.66426 20.674 4.44213 19.6159 3.38604C18.5577 2.32994 17.3353 1.50158 15.9486 0.900948C14.5619 0.300316 13.0791 0 11.5 0C9.92093 0 8.43805 0.300316 7.05137 0.900948C5.66469 1.50158 4.44227 2.32994 3.38411 3.38604C2.32595 4.44213 1.4974 5.66426 0.898437 7.05243C0.299479 8.44059 0 9.92402 0 11.5027C0 13.0778 0.299479 14.5603 0.898437 15.9503C1.4974 17.3403 2.32595 18.5633 3.38411 19.6194C4.44227 20.6755 5.66469 21.503 7.05137 22.1018C8.43805 22.7006 9.92093 23 11.5 23C13.0791 23 14.5619 22.7006 15.9486 22.1018C17.3353 21.503 18.5577 20.6746 19.6159 19.6167C20.674 18.5588 21.5026 17.3357 22.1016 15.9476C22.7005 14.5594 23 13.0778 23 11.5027Z'
      fill='#0A7AFF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.3676 11.3575L11.3676 20.8839C10.0672 20.8839 8.85059 20.6426 7.71769 20.1599C6.58479 19.6772 5.58981 19.0063 4.73275 18.1473C3.87569 17.2882 3.20383 16.2923 2.71718 15.1594C2.23052 14.0265 1.9872 12.8098 1.9872 11.5095C1.9872 10.2091 2.22954 8.99245 2.71422 7.85955C3.19891 6.72666 3.86978 5.73168 4.72684 4.87461C4.77397 4.82749 4.82152 4.78092 4.86948 4.73492C4.87355 4.73085 4.87763 4.72677 4.8817 4.72271C5.74074 3.86564 6.7367 3.19379 7.8696 2.70713C9.0025 2.22048 10.2191 1.97715 11.5195 1.97715C12.8199 1.97715 14.0365 2.21949 15.1694 2.70418C16.3023 3.18886 17.2973 3.85973 18.1543 4.71679C19.0114 5.57386 19.6823 6.56982 20.167 7.70469C20.6516 8.83956 20.894 10.0572 20.894 11.3575L11.3676 11.3575Z'
      fill='white'
    />
  </svg>
)

export { PortionEmptyBottomRightSideIcon }
