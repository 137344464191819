import { rootActions } from '@dominos/business'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

export interface Language extends GenericSelectItem {
  name: string
  flag: string
  langCode: BffContext.Languages
}

const langFlags: Record<string, string> = {
  en: require('../../../res/assets/pngs/English.png'),
  nl: require('../../../res/assets/pngs/Dutch.png'),
  fr: require('../../../res/assets/pngs/French.png'),
  de: require('../../../res/assets/pngs/German.png'),
  ja: require('../../../res/assets/pngs/Japanese.png'),
}

export const useLanguages = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('footer')
  const { language, availableLanguages } = useSelector((state: RootReducer) => state.applicationReducer)
  const languages: Language[] = (availableLanguages ?? []).map((lang) => ({
    value: lang.code,
    label: t(`Language${lang.name}`, { defaultValue: lang.name }),
    name: t(`Language${lang.name}`, { defaultValue: lang.name, lng: lang.code }),
    flag: langFlags[lang.code.split('-')[0]],
    langCode: lang.code.split('-')[0] as BffContext.Languages,
  }))

  const setLanguage = (value: string) => {
    if (languages.map((lang) => lang.value.toString()).includes(value)) {
      dispatch(
        rootActions.updateLanguage({
          language: value.split('-')[0] as BffContext.Languages,
        }),
      )
    }
  }

  return {
    language,
    languages,
    setLanguage,
  }
}
