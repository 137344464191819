import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const SignatureIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-9' fill={fill} transform='translate(2.000000, 0.000000)'>
          <path
            d='M14,10 L14,22 L2,22 L2,4 L10,4 L10,2 L2,2 C0.895,2 0,2.895 0,4 L0,22 C0,23.105 0.895,24 2,24 L14,24 C15.105,24 16,23.105 16,22 L16,10 L14,10 Z'
            id='Fill-1'
          />
          <path d='M18,0 L12,0 L12,2 L18,2 L18,8 L20,8 L20,2 C20,0.895 19.105,0 18,0' id='Fill-3' />
          <path
            d='M10.2017,16.7494 C9.3737,16.7494 8.7017,16.0774 8.7017,15.2494 C8.7017,14.4214 9.3737,13.7494 10.2017,13.7494 C11.0297,13.7494 11.7017,14.4214 11.7017,15.2494 C11.7017,16.0774 11.0297,16.7494 10.2017,16.7494 L10.2017,16.7494 Z M6.4997,20.0004 C5.6717,20.0004 4.9997,19.3284 4.9997,18.5004 C4.9997,17.6714 5.6717,17.0004 6.4997,17.0004 C7.3287,17.0004 7.9997,17.6714 7.9997,18.5004 C7.9997,19.3284 7.3287,20.0004 6.4997,20.0004 L6.4997,20.0004 Z M5.9747,11.5484 C6.5277,11.5484 6.9747,11.9964 6.9747,12.5484 C6.9747,13.1014 6.5277,13.5484 5.9747,13.5484 C5.4227,13.5484 4.9747,13.1014 4.9747,12.5484 C4.9747,11.9964 5.4227,11.5484 5.9747,11.5484 L5.9747,11.5484 Z M6.1237,6.6994 C6.9527,6.6994 7.6237,7.3704 7.6237,8.1994 C7.6237,9.0274 6.9527,9.6994 6.1237,9.6994 C5.2957,9.6994 4.6237,9.0274 4.6237,8.1994 C4.6237,7.3704 5.2957,6.6994 6.1237,6.6994 L6.1237,6.6994 Z M9.9997,10.0004 L9.9997,5.0004 L2.9997,5.0004 L2.9997,21.0004 L12.9997,21.0004 L12.9997,10.0004 L9.9997,10.0004 Z'
            id='Fill-5'
          />
          <path
            d='M14.5,6.6315 C13.875,6.6315 13.368,6.1245 13.368,5.4995 C13.368,4.8755 13.875,4.3685 14.5,4.3685 C15.125,4.3685 15.632,4.8755 15.632,5.4995 C15.632,6.1245 15.125,6.6315 14.5,6.6315 L14.5,6.6315 Z M12,7.9995 L17,7.9995 L17,2.9995 L12,2.9995 L12,7.9995 Z'
            id='Fill-7'
          />
        </g>
      </g>
    </svg>
  )
}
