const findMostCommonRecipeIngredient = (frequencyMap: Map<string, number>) => {
  if (frequencyMap.size === 0) return undefined

  let [highestInRecipeIngredient, highestInRecipeCount] = frequencyMap.entries().next().value

  frequencyMap.forEach((value, key) => {
    if (value > highestInRecipeCount) {
      highestInRecipeCount = value
      highestInRecipeIngredient = key
    }
  })

  return highestInRecipeIngredient
}

const getCommonIngredients = (ingredientsMap: Map<string, IngredientModel[]>) => {
  const valuesArray = Array.from(ingredientsMap.values())
  const allIngredients = valuesArray.flat()
  const inRecipeFrequencyMap = new Map<string, number>()

  allIngredients.forEach((ingredient) => {
    const isCommon = valuesArray.every((ingredientArray) =>
      ingredientArray.some((item) => item.code === ingredient.code),
    )
    if (isCommon) {
      const inRecipeIncrement = ingredient.inRecipe ? 1 : 0
      inRecipeFrequencyMap.set(ingredient.code, (inRecipeFrequencyMap.get(ingredient.code) || 0) + inRecipeIncrement)
    }
  })

  const mostCommonRecipeIngredient = findMostCommonRecipeIngredient(inRecipeFrequencyMap)

  return Array.from(inRecipeFrequencyMap.keys())
    .map((code) => {
      const ingredient = allIngredients.find((ingredient) => ingredient.code === code)

      return ingredient ? { ...ingredient, inRecipe: code === mostCommonRecipeIngredient } : undefined
    })
    .filter((ingredient): ingredient is IngredientModel => ingredient !== undefined)
}

export { getCommonIngredients }
