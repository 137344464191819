import { calcWebFontWeight } from '@dominos/business/functions/text'
import { Checkbox, Hyperlink, MDTargetBlankAnchor } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import Markdown from 'markdown-to-jsx'
import React from 'react'

export const TermsAndConditionsGeneric = (props: TermsAndConditionsGenericProps) => {
  const theme = useDominosTheme()

  const {
    hasCheckedBox = false,
    checked = false,
    onChange = () => void 0,
    testID,
    text,
    textStyle,
    weight,
    hyperlinkColor = theme.colours.hyperlink,
  } = props

  const styles = { ...textStyle, fontWeight: calcWebFontWeight(weight) }

  const Content = () => (
    <Markdown
      style={{ ...styles }}
      children={text}
      options={{
        overrides: {
          Hyperlink: {
            component: Hyperlink,
            props: {
              style: {
                color: hyperlinkColor,
              },
              weight: 'bold',
            },
          },
          a: MDTargetBlankAnchor,
        },
      }}
    />
  )

  return (
    <div data-testid={testID}>
      {hasCheckedBox ? (
        <Checkbox testID={testID} defaultChecked={checked} onChange={onChange} ignoreLabelOnClick={true}>
          {<Content />}
        </Checkbox>
      ) : (
        <Content />
      )}
    </div>
  )
}
