import { ErrorDefinitionsType } from '../interfaces'
import { getDefaultValue } from '../helpers'
import { Bff as BffErrors } from 'bff-errors'

export const genericErrors: ErrorDefinitionsType = {
  UNKNOWN_ERROR: {
    translation: {
      title: {
        key: 'UnknownErrorTitle',
        getOptions: () => getDefaultValue('Unknown Error'),
      },
      message: {
        key: 'UnknownErrorMessage',
        getOptions: () => getDefaultValue('Something has gone wrong. Please try again later.'),
      },
    },
    displayType: 'popup',
    icon: 'infoIcon',
    testID: 'unknown-error',
  },
  [BffErrors.Errors.UNABLE_TO_RESOLVE]: {
    translation: {
      title: { key: 'UnableToResolveErrorTitle', getOptions: getDefaultValue },
      message: { key: 'UnableToResolveErrorMessage', getOptions: getDefaultValue },
    },
    displayType: 'modal',
    icon: 'infoIcon',
    testID: 'unable-to-resolve-error',
  },
}
