import { DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const NoGluten = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR
  const selectedColorLight = color ? color : '#4A4A4A'

  return (
    <svg width='50px' height='49px' viewBox='0 0 50 49' key={color}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-271.000000, -930.000000)'>
          <g transform='translate(6.000000, 309.000000)'>
            <g transform='translate(1.000000, 474.000000)'>
              <g transform='translate(269.000000, 150.069981)'>
                <ellipse
                  stroke={selectedColor}
                  strokeWidth='3'
                  cx='19.953125'
                  cy='19.6051924'
                  rx='19.4921875'
                  ry='18.7566333'
                />
                <path
                  d='M20.8326953,21.1008531 C20.8326953,24.8703844 18.4303516,27.2141344 14.5826953,27.2141344 C10.3932422,27.2141344 7.82488281,24.4016344 7.82488281,19.7922594 C7.82488281,15.2512437 10.4127734,12.3996812 14.5143359,12.3996812 C17.8444141,12.3996812 20.2955859,14.3235094 20.7057422,17.2531969 L17.7760547,17.2531969 C17.3366016,15.8274156 16.1549609,14.9973375 14.5143359,14.9973375 C12.2389453,14.9973375 10.8424609,16.8039781 10.8424609,19.7629625 C10.8424609,22.7805406 12.3073047,24.6164781 14.6315234,24.6164781 C16.5651172,24.6164781 17.9030078,23.4739 17.9616016,21.7746812 L17.9713672,21.520775 L14.9147266,21.520775 L14.9147266,19.3039781 L20.8326953,19.3039781 L20.8326953,21.1008531 Z M26.2841797,26.8528062 L23.3349609,26.8528062 L23.3349609,12.7610094 L32.5634766,12.7610094 L32.5634766,15.2805406 L26.2841797,15.2805406 L26.2841797,18.9524156 L32.0166016,18.9524156 L32.0166016,21.364525 L26.2841797,21.364525 L26.2841797,26.8528062 Z'
                  fill={selectedColorLight}
                  fillRule='nonzero'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
