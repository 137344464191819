import { ToggleDictionary } from './interfaces'

const defaultValue = false
export const resetToggleValues = (togglesToReset?: ToggleDictionary) =>
  togglesToReset
    ? Object.keys(togglesToReset).reduce((result: ToggleDictionary, name: string) => {
        result[name] = { ...togglesToReset[name], value: defaultValue }

        return result
      }, {} as ToggleDictionary)
    : {}
