import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge2 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M14.5885714,15.0914286 L14.5885714,12.3571429 C14.5885714,11.0685714 15.6571429,10 16.9457143,10 L24.9285714,10 C26.2171429,10 27.2857143,11.0685714 27.2857143,12.3571429 L27.2857143,20.4971429 C27.2857143,21.8485714 26.3114286,23.0428571 24.96,23.3257143 L19.6171429,24.3942857 C19.5228571,24.4257143 19.46,24.4885714 19.46,24.5828571 L19.46,27.4742857 C19.46,27.5685714 19.5228571,27.6314286 19.6171429,27.6314286 L26.6257143,27.6314286 C27.0657143,27.6314286 27.4114286,27.9771429 27.4114286,28.4171429 L27.4114286,31.2142857 C27.4114286,31.6542857 27.0657143,32 26.6257143,32 L15.3742857,32 C14.9342857,32 14.5885714,31.6542857 14.5885714,31.2142857 L14.5885714,23.0428571 C14.5885714,21.6914286 15.5,20.5285714 16.9142857,20.2457143 L22.2571429,19.1771429 C22.3514286,19.1457143 22.4142857,19.0828571 22.4142857,18.9885714 L22.4142857,14.5257143 C22.4142857,14.4314286 22.3514286,14.3685714 22.2571429,14.3685714 L19.6171429,14.3685714 C19.5228571,14.3685714 19.46,14.4314286 19.46,14.5257143 L19.46,17.1342857 C19.46,17.5114286 19.0828571,17.7628571 18.7371429,17.6057143 L15.3114286,16.1914286 C14.8714286,16.0028571 14.5885714,15.5628571 14.5885714,15.0914286 Z'
          id='2'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
