import { clearTaxAuthTokenQuery } from '@dominos/business/queries'
import { useQuery } from '@apollo/client'

export const useClearTaxAuthToken = () => {
  const { data, error } = useQuery<{ clearTaxAuthToken: Bff.ClearTax.AuthTokenResponse }>(clearTaxAuthTokenQuery)

  const sessionData = data?.clearTaxAuthToken ?? {
    consumerAuthTokenDetails: { authToken: null },
    supplierTin: null,
    validUntil: null,
    environment: null,
    error,
  }

  return {
    authToken: sessionData?.consumerAuthTokenDetails.authToken,
    supplierTin: sessionData?.supplierTin,
    environment: sessionData?.environment,
    validUntil: sessionData?.validUntil,
    error,
  }
}
