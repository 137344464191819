import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge1 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M18.4425,14.459 L15.7145,14.459 C15.3425,14.459 15.0945,14.087 15.2495,13.746 L16.4275,10.863 C16.6135,10.429 17.0475,10.15 17.5125,10.15 L22.6275,10.15 C23.0615,10.15 23.4025,10.491 23.4025,10.925 L23.4025,27.386 C23.4025,27.479 23.4645,27.541 23.5575,27.541 L26.0375,27.541 C26.4715,27.541 26.8125,27.882 26.8125,28.316 L26.8125,31.075 C26.8125,31.509 26.4715,31.85 26.0375,31.85 L15.9625,31.85 C15.5285,31.85 15.1875,31.509 15.1875,31.075 L15.1875,28.316 C15.1875,27.882 15.5285,27.541 15.9625,27.541 L18.4425,27.541 C18.5355,27.541 18.5975,27.479 18.5975,27.386 L18.5975,14.614 C18.5975,14.521 18.5355,14.459 18.4425,14.459 Z'
          id='1'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
