import { useTranslation } from 'react-i18next'

type ProviderType = BffContext.SocialLogins.Providers

const subtitle = 'ADDITIONAL DETAILS'
const defaultApple = {
  SignUpAppleTitle: 'Sign in with Apple',
  SignUpAppleSubtitle: subtitle,
}
const defaultGoogle = {
  signInGoogleTitle: 'Sign in with Google',
  signInGoogleSubtitle: subtitle,
}
const defaultFB = {
  signUpFacebookTitle: 'Sign in with Facebook',
  signUpFacebookSubtitle: subtitle,
}
const defaultPayPal = {
  signInPayPalTitle: 'Sign in with PayPal',
  signInPayPalSubtitle: subtitle,
}
const defaultValues: { [k: string]: string } = {
  LogIn: 'Log in',
  SignUpSocialDescription: 'Almost there! We just need a few more details to create your account',
  CreateAccountGenericErrorText: 'Something has gone wrong. Please try again',
}
const defaultByProviders: Record<ProviderType, Record<string, string>> = {
  Apple: defaultApple,
  Google: defaultGoogle,
  Facebook: defaultFB,
  PayPal: defaultPayPal,
}

export const useLoginTranslations = () => {
  const { t } = useTranslation('login')
  const getSocials = (provider: ProviderType) => {
    const tmap = defaultByProviders[provider]

    return Object.keys(tmap).map((k) => t(k, { defaultValue: tmap[k] }))
  }

  const getOthers = (): { [k: string]: string } =>
    Object.keys(defaultValues).reduce((o, k) => ({ ...o, [k]: t(k, { defaultValue: defaultValues[k] }) }), {})

  return { getSocials, getOthers }
}
