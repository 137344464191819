import { ApolloError, useMutation } from '@apollo/client'
import { customerMutation } from '@dominos/business/queries'
import { ActionButton } from '@dominos/components'
import { NotificationType, PopUpNotification } from '@dominos/components/notification'
import { useCountryCode, useCustomer, useLanguages, useReport } from '@dominos/hooks-and-hocs'
import { RouteComponentProps } from '@reach/router'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs/selectors/use-current-order-details'
import { AccountDetails } from './account-details'
import { ErrorHandler } from './error-handler'
import styles from './my-details.less'

const MyDetails = (props: RouteComponentProps) => {
  const oldAccountDetails = useRef<Bff.Customers.Customer | undefined>()
  const [accountDetailsValid, setAccountDetailsValid] = useState(false)
  const [errorForHandler, setErrorForHandler] = useState<ApolloError | undefined>()
  const [showPopup, setShowPopup] = useState(false)
  const [accountDetails, setAccountDetails] = useState<
    Pick<Bff.Customers.UpsertCustomer, 'name' | 'phoneNumber' | 'alternateName'>
  >({
    name: 'Name',
    phoneNumber: 'Phone number',
    alternateName: 'Alternate name',
  })
  const { reportAccountWasUpdated } = useReport()
  const { orderId } = useCurrentOrderDetails()
  const { t } = useTranslation('login')
  const { customer } = useCustomer()
  const [updateCustomerAccount, { loading, error, data }] = useMutation<
    { customer: Bff.Customers.Customer },
    { input: Bff.Customers.UpsertCustomer }
  >(customerMutation)
  const countryCode = useCountryCode()
  const { language } = useLanguages()
  const shouldShowAlternateName = useMemo(() => countryCode === 'JP' && language === 'ja', [countryCode, language])

  useEffect(() => {
    if (customer) {
      oldAccountDetails.current = customer
      setAccountDetails({
        name: customer.name,
        phoneNumber: customer.phoneNumber,
        alternateName: customer.alternateName,
      })
    }
  }, [customer])

  useEffect(() => {
    if (data) {
      setShowPopup(true)
      reportAccountWasUpdated({ orderId, success: true, fields: {} })
    }

    if (!!error) {
      setShowPopup(false)
      setErrorForHandler(error)
      reportAccountWasUpdated({ orderId, success: false, fields: {} })
    }
  }, [data, error])

  if (!customer) {
    return <></>
  }

  const detailsChanged =
    accountDetails.name !== oldAccountDetails.current?.name ||
    accountDetails.phoneNumber !== oldAccountDetails.current?.phoneNumber ||
    accountDetails.alternateName !== oldAccountDetails.current?.alternateName

  const onFormValidationChange = (isValid: boolean) => setAccountDetailsValid(isValid)

  const onDetailsChange = (field: string, value: string | undefined) => {
    setErrorForHandler(undefined)
    setAccountDetails((prev) => ({ ...prev, [field]: value }))
  }

  const onPopupClose = () => setShowPopup(false)

  const updateDetails = async () => {
    if (accountDetailsValid) {
      await updateCustomerAccount({
        variables: {
          input: {
            id: customer.id,
            name: accountDetails.name,
            alternateName: accountDetails.alternateName,
            email: customer.email,
            phoneNumber: accountDetails.phoneNumber,
          },
        },
      }).catch(() => null)
      // ^ see https://github.com/apollographql/react-apollo/issues/2614#issuecomment-530803601
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <h2>{t('AccountDetailsHeading', { defaultValue: 'My Details' })}</h2>
        <p className={styles.paragraph}>
          {t('AccountDetailsDescription', { defaultValue: 'To update your details, edit the information below:' })}
        </p>
      </div>

      <AccountDetails
        details={{ ...accountDetails, email: customer.email }}
        shouldShowAlternateName={shouldShowAlternateName}
        onValidationChange={onFormValidationChange}
        onChange={onDetailsChange}
      />

      <div className={styles.centerButton}>
        <ActionButton
          loading={loading}
          disabled={!accountDetailsValid || loading || !detailsChanged}
          onPress={updateDetails}
          testID={'update-account-action-button'}
        >
          {t('SaveChanges', { defaultValue: 'Save Changes' })}
        </ActionButton>
      </div>
      <ErrorHandler apolloError={errorForHandler} />

      {showPopup && (
        <PopUpNotification
          notificationType={NotificationType.confirmation}
          heading={t('AccountDetailsUpdated', { defaultValue: 'Account details updated successfully' })}
          onClose={onPopupClose}
        />
      )}
    </div>
  )
}

export { MyDetails }
