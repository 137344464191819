import { isVoucherDiscount } from './is-voucher-discount'

/**
 * Calculates the total surcharge amount for an order.
 *
 * @param surcharges - An array of `BasketSurcharge` objects representing the surcharges for the order.
 * @returns The total surcharge amount for the order.
 */
export const calculateOrderSurchargeTotal = (surcharges: BasketSurcharge[]): number =>
  surcharges.filter(isVoucherDiscount).reduce((total, surcharge) => total + surcharge.price, 0)
