import React from 'react'

import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 25,
  height: 25,
}

const XMark = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none'>
      <path d='M20 20L4 4.00003M20 4L4.00002 20' stroke={fill} strokeWidth='3' strokeLinecap='round' />
    </svg>
  )
}
export { XMark }
