import { Config } from '../common/get-config'
import { LoadScriptProps } from '../common/load-script'

export const setupRecaptcha = (applicationConfig: Config, loadScript: (props: LoadScriptProps) => void) => {
  if (applicationConfig.RECAPTCHA_V3_SITE_KEY !== '' && applicationConfig.RECAPTCHA_V3_SITE_KEY !== 'null') {
    window._RECAPTCHA_ = 'recaptcha'
    loadScript({
      id: 'recaptcha_v3',
      setupScriptFunc: (script) => {
        script.type = 'text/javascript'
        script.src = `https://www.google.com/recaptcha/enterprise.js?render=${applicationConfig.RECAPTCHA_V3_SITE_KEY}`
      },
    })
  }
}
