import { useCustomer, useLocalStorage } from '@dominos/hooks-and-hocs'
import { CustomerDetails, MyDetailsModelProps } from '@dominos/interfaces'
import { useMemo } from 'react'

export const useRememberedCustomerDetails = () => {
  const { customer } = useCustomer()

  const {
    storedValue: localValue,
    setValue: setLocalStorage,
    clearValue: clearLocalStorage,
  } = useLocalStorage({ key: 'customer-details' })

  const saveDetails = (customerData?: MyDetailsModelProps | false) => {
    if (!customerData || Object.keys(customerData).length === 0) {
      return clearLocalStorage()
    }

    setLocalStorage(customerData)
  }

  const customerDetails = useMemo<CustomerDetails>(() => {
    if (customer) {
      return {
        name: customer.name,
        alternateName: customer.alternateName,
        emailAddress: customer.email,
        mobileNumber: customer.phoneNumber,
      }
    }

    return localValue
  }, [localValue, customer])

  return {
    customerDetails,
    saveDetails,
  }
}
