import { Modal } from '@dominos/components'
import { useDevToggles } from '@dominos/hooks-and-hocs'
import React, { useState } from 'react'
import { Offer } from './offer'

interface OfferPopupProps {
  offer: Bff.Offers.Offer
  show: boolean
  onAccept: (offerItems: Bff.Offers.Item[], interactionType: string) => void
  onClose: (interactionType: string) => void
}

const OfferPopup = ({ offer, show, onAccept, onClose }: OfferPopupProps) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const { isEnabled } = useDevToggles()

  const onImageLoaded = () => {
    setImageLoaded(true)
  }

  return (
    <>
      {isEnabled['upsell-popups'] && show && (
        <div data-testid='popup' style={!imageLoaded ? { display: 'none' } : undefined}>
          <Modal testID='offer-popup' onModalClose={() => onClose('Clicked background to close')}>
            <Offer
              testID='offer-popup'
              onOfferAccepted={onAccept}
              onClose={onClose}
              onImageLoaded={onImageLoaded}
              offerDetails={offer}
            />
          </Modal>
        </div>
      )}
    </>
  )
}

export default OfferPopup
