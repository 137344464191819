const getTotalLineItems = (lines: Bff.Orders.OrderDetails.Basket.BasketLineDependancy[] = []): number =>
  lines.reduce((total, line) => {
    if (line.type === 'ProductLine') {
      return total + line.quantity
    }

    if (line.type === 'CouponLine') {
      return total + getTotalLineItems(line.items)
    }

    return total
  }, 0)

export { getTotalLineItems }
