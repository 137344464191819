import gql from 'graphql-tag'

export const resetPasswordRequestQuery = gql`
  query resetPasswordRequestQuery($email: String!, $recaptchaToken: String, $useRecaptchaV3: Boolean) {
    resetPasswordRequest(email: $email, recaptchaToken: $recaptchaToken, useRecaptchaV3: $useRecaptchaV3) {
      eventSent
      recaptchaSuccess
      shouldDisplayRecaptchaV2
    }
  }
`

export interface ResetPasswordResponse {
  resetPasswordRequest: Bff.Customers.ResetPasswordRequest
}
