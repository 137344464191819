import { RouteComponentProps } from '@reach/router'
import React from 'react'

import { StackNavigationBase } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { getStateFromNavigation } from '@dominos/navigation'
import { StaticSvgIcon } from '@dominos/res'

import css from './coming-soon.less'

const LOGO_SIZE = 80

export const ComingSoonScene = (props: RouteComponentProps & ComingSoonSceneProps) => {
  const theme = useDominosTheme()

  const { title, icon } = props

  const titleFromNavigation = getStateFromNavigation(props.location, 'comingSoonTitle') || 'new features'
  const iconFromNavigation =
    getStateFromNavigation<import('@dominos/res').svgIconsName>(props.location, 'comingSoonIcon') || 'dominosLogoIcon'
  const iconColourFromNavigation = getStateFromNavigation<string>(props.location, 'comingSoonIconColour')

  const heading = title || titleFromNavigation
  const svg = icon || iconFromNavigation

  return (
    <StackNavigationBase testID='coming-soon-scene'>
      <div className={css.wrapper}>
        <div>
          <div data-testid={`coming-soon-scene.icon.${svg}`}>
            <StaticSvgIcon
              name={svg}
              includePadding={false}
              height={LOGO_SIZE}
              width={LOGO_SIZE}
              fill={iconColourFromNavigation || theme.colours.delivery}
            />
          </div>

          <h1 data-testid='coming-soon-scene.title'>{`Get ready for ${heading}`}</h1>
          <p>Coming Soon</p>
        </div>
      </div>
    </StackNavigationBase>
  )
}
