import React from 'react'
import { getFormattedPrice } from '@dominos/business/functions/text'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import css from '../product-ingredient-item.less'
import { IngredientPriceProps } from '../product-ingredient-item.interface'

export const IngredientPrice = ({
  value,
  showPrice,
  testID,
  children,
}: React.PropsWithChildren<IngredientPriceProps>) => {
  const theme = useDominosTheme()
  const styles = {
    price: { color: theme.colours.web.grey },
  }

  return (
    <div className={css.leftButtonPriceWrapper} data-testid={`${testID}.wrapper`}>
      {showPrice === false ? null : (
        <p style={styles.price} className={css.price} data-testid={testID}>
          {(value && `+${getFormattedPrice(value)}`) || ''}
        </p>
      )}
      {children}
    </div>
  )
}
