import React from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { AddressList, AddressSearchErrorItem, GenericCard, Spinner } from '@dominos/components'

import { useTranslation } from 'react-i18next'
interface PickupStoreSearchResultsProps extends BaseProps {
  isLoading: boolean
  addresses: AddressLine[] | undefined
  onSelectAddress: (line: AddressLine) => void
  onRetry: () => void
  itemLoadingKey: number | undefined
}

export const PickupStoreSearchResults = ({
  testID,
  addresses,
  isLoading,
  itemLoadingKey,
  onSelectAddress,
  onRetry,
}: PickupStoreSearchResultsProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('pickup-search')

  if (!isLoading && addresses) {
    if (addresses.length == 0) {
      return (
        <GenericCard
          testID={`${testID}.pickupSearchStoreError`}
          title={t('NoStoreFound', { defaultValue: 'No Store Found' })}
          style={{ marginTop: theme.marginTop }}
        >
          <AddressSearchErrorItem
            testID={`${testID}.pickupSearchStoreError`}
            title={''}
            subtitle={t('NoStoreFoundSubtitle', {
              defaultValue: "We don't currently have a store within 50km of your selected location",
            })}
            isLoading={isLoading}
            buttons={[{ type: 'Retry', onPress: onRetry }]}
          />
        </GenericCard>
      )
    }

    return (
      <GenericCard
        testID={`${testID}.selectPickupStore`}
        title={t('SelectAStore', { defaultValue: 'Select a Store' })}
        style={{ marginTop: theme.marginTop }}
      >
        <AddressList
          testID={`${testID}.results`}
          addresses={addresses}
          onPress={onSelectAddress}
          isLoading={isLoading}
          itemLoadingKey={itemLoadingKey}
          showBorderOnFirstItem
          narrowHeight
          defaultTitleColor
          iconStyling={{ width: 30, height: 30 }}
        />
      </GenericCard>
    )
  }

  return (
    <div data-testid={testID}>
      <Spinner testID={testID} />
    </div>
  )
}
