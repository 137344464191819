import React from 'react'

import { StaticSvgIcon } from '@dominos/res'
import { useTranslation } from 'react-i18next'

import css from './product-card-closer.less'

const BackButton = ({ testID, onClick }: { testID: string; onClick: () => void }) => {
  const { t } = useTranslation('navigation')

  return (
    <div data-testid={`${testID}.dismiss-button`} role='button' onClick={onClick} className={css.back}>
      <span data-testid={`${testID}.back-button`}>
        <StaticSvgIcon name='chevron' direction='left' isUnstyled />
        <span className={css.backText}>{t('BackButton', { defaultValue: 'Back' })}</span>
      </span>
    </div>
  )
}

export default BackButton
