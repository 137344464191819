import * as React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import { ClearButton } from '@dominos/components'
import css from './current-location-display-field.less'

export const CurrentLocationDisplayField = ({ testID, location, onClearInput }: CurrentLocationDisplayFieldProps) => (
  <div data-testid={`${testID}.currentLocation`}>
    <div className={css.currentLocationField}>
      <div className={css.currentLocationIcon}>
        <StaticSvgIcon name={'mapArrowIcon'} />
      </div>
      <div data-testid={`${testID}.currentLocation.value`} className={css.currentLocationDisplayField}>
        {location}
      </div>
      <div className={css.currentLocationClearButton}>
        <ClearButton
          testID={`${testID}.currentLocation.clearInputButton`}
          onKeyPress={onClearInput}
          onClick={onClearInput}
          tabIndex={0}
        />
      </div>
    </div>
    <div className={css.currentLocationUnderline}></div>
  </div>
)

export interface CurrentLocationDisplayFieldProps {
  testID: string
  location: string | null | undefined
  onClearInput: () => void
}
