import classnames from 'classnames'
import React, { useCallback, useEffect, useState } from 'react'
import { SwitchProps } from './switch.interface'
import css from './switch.less'

const SPACE_KEYS = [' ', 'Spacebar']

export const Switch = React.forwardRef(
  (
    { testID, checked, defaultChecked, name, position = 'right', onChange, children, ...props }: SwitchProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [selected, setSelected] = useState(checked ?? defaultChecked ?? false)

    useEffect(() => {
      if (checked !== undefined) {
        setSelected(checked)
      }
    }, [checked])

    const handleSelection = useCallback(() => {
      if (checked === undefined) {
        setSelected(!selected)
      }
      if (onChange) {
        onChange(name, !selected)
      }
    }, [selected, checked, setSelected, onChange])

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (SPACE_KEYS.includes(event.key)) {
        handleSelection()
      }
    }

    return (
      <div
        data-testid={`${testID}.container`}
        className={classnames(css.container, position === 'left' && css.reversed)}
      >
        {children}
        <label data-testid={`${testID}.wrapper`} htmlFor={name}>
          <input
            data-testid={testID}
            name={name}
            ref={ref}
            role={'switch'}
            type={'checkbox'}
            checked={selected}
            defaultChecked={undefined}
            className={classnames(css.checkbox)}
            onClick={handleSelection}
            onKeyPress={handleKeyPress}
            readOnly={!handleSelection}
            {...props}
          />
        </label>
      </div>
    )
  },
)
