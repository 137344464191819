import React from 'react'

const PortionEmptyTopLeftSideIcon = () => (
  <svg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 11.4973C0 13.076 0.299479 14.5594 0.898438 15.9476C1.4974 17.3357 2.32596 18.5579 3.38412 19.614C4.44227 20.6701 5.66469 21.4984 7.05137 22.0991C8.43805 22.6997 9.92093 23 11.5 23C13.0791 23 14.5619 22.6997 15.9486 22.0991C17.3353 21.4984 18.5577 20.6701 19.6159 19.614C20.674 18.5579 21.5026 17.3357 22.1016 15.9476C22.7005 14.5594 23 13.076 23 11.4973C23 9.92221 22.7005 8.43968 22.1016 7.0497C21.5026 5.65972 20.674 4.43669 19.6159 3.38059C18.5577 2.3245 17.3353 1.49704 15.9486 0.898225C14.5619 0.299408 13.0791 0 11.5 0C9.92093 0 8.43805 0.299408 7.05137 0.898225C5.66469 1.49704 4.44227 2.3254 3.38412 3.38331C2.32596 4.44122 1.4974 5.66426 0.898438 7.05243C0.299479 8.44059 0 9.92221 0 11.4973Z'
      fill='#0A7AFF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6324 11.6425V2.11606C12.9328 2.11606 14.1494 2.35741 15.2823 2.84013C16.4152 3.32284 17.4102 3.99371 18.2672 4.85275C19.1243 5.71178 19.7962 6.70774 20.2828 7.84064C20.7695 8.97354 21.0128 10.1902 21.0128 11.4905C21.0128 12.7909 20.7704 14.0075 20.2858 15.1404C19.8011 16.2733 19.1302 17.2683 18.2731 18.1254C18.226 18.1725 18.1785 18.2191 18.1305 18.2651C18.1264 18.2692 18.1224 18.2732 18.1183 18.2773C17.2592 19.1344 16.2633 19.8062 15.1304 20.2929C13.9975 20.7795 12.7809 21.0228 11.4805 21.0228C10.1801 21.0228 8.96348 20.7805 7.83058 20.2958C6.69768 19.8111 5.7027 19.1403 4.84564 18.2832C3.98858 17.4261 3.31771 16.4302 2.83302 15.2953C2.34834 14.1604 2.106 12.9428 2.106 11.6425L11.6324 11.6425Z'
      fill='white'
    />
  </svg>
)

export { PortionEmptyTopLeftSideIcon }
