type ProductAllergen = Bff.Menu.old.ProductAllergen

import React from 'react'
import { useTranslation } from 'react-i18next'
import { CountryToggle } from '@dominos/components'

import css from './allergen-info.less'

export const AllergenInfo = ({ info, testID }: { info: ProductAllergen[]; testID: string }) => {
  const { t } = useTranslation('menu')
  if (!info || !info.length) {
    return null
  }

  const allergenInfoFilter = CountryToggle({
    FR: info.filter(({ type }) => type === 'Contains'),
    default: info,
  })

  const allergensTranslated = allergenInfoFilter.map((item) => t(item.name)).join(', ')

  return (
    <>
      <div className={css.title} data-testid={`${testID}.allergies-header`}>
        {t('AllergiesTitle', { defaultValue: 'Allergies' })}
      </div>
      <div className={css.allergensList} data-testid={`${testID}.allergies`}>
        {allergensTranslated}
      </div>
    </>
  )
}
