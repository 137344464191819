import { convertQueryStringToObject } from '@dominos/business/functions/text'
import {
  useAllowedLastOrderNavigationPath,
  useHasUserStartedNewJourneyOnMenu,
} from '@dominos/hooks-and-hocs/last-order-handler'
import {
  ConsentNotGiven,
  DeeplinkNotSupported,
  FeatureNotEnabled,
  NavigationPathNotSupported,
  SJMFailedReason,
  UserChangedOrderDetails,
} from '@dominos/hooks-and-hocs/logging'
import { useOneTrust } from '@dominos/hooks-and-hocs/one-trust'
import { useLocation } from '@reach/router'
import { useFeatures } from '@dominos/hooks-and-hocs/features'

export const useAllowedToUseLastOrder = (): {
  isAllowedToUseLastOrder: boolean
  unsuccessfulReason: SJMFailedReason | undefined
} => {
  const consents = useOneTrust()
  const [startUserJourneyOnMenuEnabled] = useFeatures('StartUserJourneyOnMenu')
  const userStartedNewUserJourney = useHasUserStartedNewJourneyOnMenu()

  const { allowedLastOrderNavigationPath } = useAllowedLastOrderNavigationPath()
  const { search } = useLocation()

  const { storeNo } = convertQueryStringToObject(search) ?? { storeNo: '' }

  const isAllowedToUseLastOrder =
    consents.Functional &&
    startUserJourneyOnMenuEnabled &&
    allowedLastOrderNavigationPath &&
    !storeNo &&
    !userStartedNewUserJourney

  let unsuccessfulReason: SJMFailedReason | undefined
  if (isAllowedToUseLastOrder === false) {
    unsuccessfulReason =
      (!allowedLastOrderNavigationPath && NavigationPathNotSupported) ||
      (!consents.Functional && ConsentNotGiven) ||
      (!startUserJourneyOnMenuEnabled && FeatureNotEnabled) ||
      (userStartedNewUserJourney && UserChangedOrderDetails) ||
      DeeplinkNotSupported
  }

  return {
    isAllowedToUseLastOrder,
    unsuccessfulReason,
  }
}
