import { applicationName, getVersion, isB2BOrderType, isKioskOrderType } from '@dominos/business/functions/common'
import { ApplicationEnvironment, AvailableLanguage } from '@dominos/interfaces'

interface EnvConfigs {
  ENVIRONMENT: string
  GRAPH_URL: string
  B2B_GRAPH_URL: string
  COUNTRY: string
  KIOSK_GRAPH_URL: string
}

const getGraphUrl = (RNConfig: EnvConfigs, enforceKioskMTLSEnabled?: boolean): string => {
  if (isKioskOrderType() && enforceKioskMTLSEnabled) {
    return RNConfig.KIOSK_GRAPH_URL
  }

  return isB2BOrderType() ? RNConfig.B2B_GRAPH_URL : RNConfig.GRAPH_URL
}

export const getApplicationEnvironmentConfig = (
  RNConfig: EnvConfigs,
  language?: BffContext.Languages,
  availableLanguages?: AvailableLanguage[],
  enforceKioskMTLSEnabled?: boolean,
): ApplicationEnvironment => ({
  application: applicationName(),
  version: Promise.resolve(getVersion()),
  applicationVersion: '1.0',
  isDevelopment: RNConfig.ENVIRONMENT === 'DEV',
  environment: RNConfig.ENVIRONMENT || 'DEV',
  graphUrl: getGraphUrl(RNConfig, enforceKioskMTLSEnabled) || '',
  countryCode: (RNConfig.COUNTRY || '').toUpperCase() as BffContext.Countries,
  language: language || 'en',
  availableLanguages: availableLanguages || [],
})
