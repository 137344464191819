import { useQuery } from '@apollo/client'
import { orderStatusQuery } from '@dominos/business/queries'
import { rootActions } from '@dominos/business/root.actions'
import { OrderStatus } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import OrderModel from '../model/order-model'

const isKnownStatus = (receivedStatus: OrderStatus) =>
  Object.values(OrderStatus).find((os) => os.includes(receivedStatus))

const useSimpleOrderStatus = (orderId: string) => {
  const [orderStatus, setOrderStatus] = useState<OrderModel>()
  const dispatch = useDispatch()

  const { data, startPolling, stopPolling, error } = useQuery(orderStatusQuery, {
    variables: {
      id: orderId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (data && data.order) {
      const model = new OrderModel(data.order)
      const status = model.status
      if (isKnownStatus(status)) {
        dispatch(rootActions.setOrderStatus(status))
      }

      setOrderStatus(model)
    }
  }, [data])

  return {
    orderStatus,
    error,
    startPolling,
    stopPolling,
  }
}

export default useSimpleOrderStatus
