import { NavigationConstants } from '@dominos/navigation'
import { useLocation } from '@reach/router'

const DEEP_LINK_PATH_NAMES = [
  NavigationConstants.home,
  NavigationConstants.storeSearch,
  NavigationConstants.deliveryAddressList,
  NavigationConstants.tracker,
  NavigationConstants.login,
  NavigationConstants.createAccount,
  NavigationConstants.pickupAddressAutoComplete,
  NavigationConstants.deliveryAddressAutoComplete,
]

export const isDeepLinkPathName = (pathname?: string) => DEEP_LINK_PATH_NAMES.includes(pathname)

export const useDeepLinkAddress = () => {
  const { search, pathname } = useLocation()

  return {
    isDeepLinkAddress: isDeepLinkPathName(pathname),
    search,
  }
}
