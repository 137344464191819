import { svgIconsName } from '@dominos/res'

interface PaymentCardFormat {
  icon: svgIconsName
  iconColor?: string
  iconWidth?: number
  iconHeight?: number
}

export const getPaymentCardFormat = (paymentMethod?: string): PaymentCardFormat => {
  switch (paymentMethod as BffContext.PaymentMethods) {
    case 'GiftCard':
      return {
        icon: 'giftCardPartialPaymentIcon',
        iconColor: undefined,
        iconWidth: 24,
        iconHeight: 24,
      }
    case 'Edenred':
      return {
        icon: 'edenred',
        iconColor: '#e30613',
        iconWidth: 37,
        iconHeight: 25,
      }
    default:
      return {
        icon: 'giftCardPartialPaymentIcon',
        iconColor: undefined,
        iconWidth: 0,
        iconHeight: 0,
      }
  }
}
