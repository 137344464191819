import { useEffect } from 'react'
import {
  useAllowedToUseLastOrder,
  useGetLastOrderServiceMethod,
  useLastOrderAddressForStoreSearch,
  useLastOrderDetails,
} from '@dominos/hooks-and-hocs/last-order-handler'
import { useCustomerAddressSearch } from '@dominos/hooks-and-hocs/customer'
import { useStoreSelect, useTimedOrder } from '@dominos/hooks-and-hocs/ordering'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { useTrackOrResetOrder } from '@dominos/hooks-and-hocs/order/order-guard'
import {
  convertAddressLineToStore,
  mapDeliveryAddressToStoredCustomerAddress,
  mapSearchAddressToAddressLine,
} from '@dominos/business/functions/address'
import { useCountryCode } from '@dominos/hooks-and-hocs/selectors'
import {
  AddressNotFound,
  ASAPNotAvailable,
  ErrorOccurred,
  LastOrderNotFound,
  NavigationPathNotSupported,
  StoreNotFound,
  StoreOnlineOrderingDisabled,
  useReport,
} from '@dominos/hooks-and-hocs/logging'

export const useLastOrderCheck = () => {
  const dispatch = useDispatch()
  const countryCode = useCountryCode()
  const { reportStartJourneyOnMenuUnsuccessful, reportStartJourneyOnMenuSuccessful, reportReturningCustomerOrder } =
    useReport()

  const { isAllowedToUseLastOrder, unsuccessfulReason } = useAllowedToUseLastOrder()
  const { serviceMethod } = useGetLastOrderServiceMethod()
  const isPickup = serviceMethod === 'Pickup'
  const isDelivery = serviceMethod === 'Delivery'
  const { lastDeliveryOrder, lastPickupOrder } = useLastOrderDetails()
  const { prompt: promptTrackerOrResetOrder } = useTrackOrResetOrder()
  const addressForStoreSearch = useLastOrderAddressForStoreSearch(lastDeliveryOrder?.deliveryAddress)
  const { getStore, store, error: errorStoreSearch } = useStoreSelect()
  const { getAddress, data: address, errors: errorAddressSearch } = useCustomerAddressSearch()
  const { fetchOrderingTimeSlots, openingHours, error: errorOpeningHours } = useTimedOrder()

  const error = errorStoreSearch || errorAddressSearch || errorOpeningHours
  const startOnMenu = openingHours?.currentStoreStatus === 'Available'

  const canStartFetchingStore: boolean =
    !!isAllowedToUseLastOrder &&
    !promptTrackerOrResetOrder &&
    serviceMethod &&
    ((isPickup && !!lastPickupOrder) || (isDelivery && !!lastDeliveryOrder))
  const storeNotFound = store === null
  const addressNotFound = address === null || (!!address && (address.deliverySearch || []).length === 0)
  const storeDisabled = store && !store.onlineOrderingEnabled
  const loading: boolean =
    canStartFetchingStore && !(error || storeNotFound || addressNotFound || openingHours || storeDisabled)

  useEffect(() => {
    if (canStartFetchingStore) {
      if (isDelivery && addressForStoreSearch) {
        getAddress(addressForStoreSearch)
      }

      if (isPickup) {
        getStore(lastPickupOrder.storeNo)
      }
    }
  }, [canStartFetchingStore])

  useEffect(() => {
    if (!!address && address.deliverySearch.length > 0) {
      getStore(address.deliverySearch[0].storeNo)
    }
  }, [address])

  useEffect(() => {
    if (store && store.storeNo && store.onlineOrderingEnabled) {
      fetchOrderingTimeSlots(store.storeNo, serviceMethod)
    }
  }, [store])

  useEffect(() => {
    if (startOnMenu) {
      if (isDelivery && !!address && !!address.deliverySearch[0]) {
        const storedCustomerAddress = mapDeliveryAddressToStoredCustomerAddress(lastDeliveryOrder.deliveryAddress)
        const addressLines = mapSearchAddressToAddressLine(countryCode, address?.deliverySearch)
        const addressStore = convertAddressLineToStore(addressLines[0])

        dispatch(rootActions.storeSelected(addressStore))
        dispatch(
          rootActions.addCustomerAddressToOrder({
            ...storedCustomerAddress!,
            storeNo: address.deliverySearch[0].storeNo,
          }),
        )
      } else if (isPickup && !!store) {
        dispatch(rootActions.storeSelected(store))
      }

      dispatch(rootActions.selectServiceMethod(serviceMethod))
      dispatch(rootActions.selectOrderTime({ orderTime: null }))
    }
  }, [startOnMenu])

  useEffect(() => {
    if (loading) return
    if (!isAllowedToUseLastOrder && unsuccessfulReason) {
      if (unsuccessfulReason !== NavigationPathNotSupported) {
        reportStartJourneyOnMenuUnsuccessful(unsuccessfulReason)
      }
    } else if (!serviceMethod || (isPickup && !lastPickupOrder) || (isDelivery && !lastDeliveryOrder)) {
      reportStartJourneyOnMenuUnsuccessful(LastOrderNotFound)
    } else if (!!error) {
      reportStartJourneyOnMenuUnsuccessful(ErrorOccurred)
    } else if (storeNotFound) {
      reportStartJourneyOnMenuUnsuccessful(StoreNotFound)
    } else if (addressNotFound) {
      reportStartJourneyOnMenuUnsuccessful(AddressNotFound)
    } else if (storeDisabled) {
      reportStartJourneyOnMenuUnsuccessful(StoreOnlineOrderingDisabled)
    } else if (!!openingHours && openingHours?.currentStoreStatus !== 'Available') {
      reportStartJourneyOnMenuUnsuccessful(ASAPNotAvailable)
    } else if (startOnMenu) {
      reportStartJourneyOnMenuSuccessful(serviceMethod)
      reportReturningCustomerOrder('Start journey on menu')
    }
  }, [
    loading,
    isAllowedToUseLastOrder,
    unsuccessfulReason,
    openingHours,
    startOnMenu,
    storeNotFound,
    addressNotFound,
    error,
    storeDisabled,
  ])

  return {
    startOnMenu,
    loading,
  }
}
