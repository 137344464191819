import { useCurrentOrderDetails, useCustomer, useReport } from '@dominos/hooks-and-hocs'
import { useLocation } from '@reach/router'
import { useEffect } from 'react'

export const usePageView = (eventCode: Parameters<ReturnType<typeof useReport>['reportTrackerView']>['0']) => {
  const { reportTrackerView, reportScreenView } = useReport()
  const { customer } = useCustomer()
  const { basketHeaderData } = useCurrentOrderDetails()
  const location = useLocation()

  useEffect(() => {
    reportScreenView(location.href, 'RouterHistory')
    reportTrackerView(eventCode, {
      email: customer?.email,
      customerId: customer?.id,
      storeNo: basketHeaderData?.storeNo,
      serviceMethod: basketHeaderData?.serviceMethod,
    })
  }, [])
}
