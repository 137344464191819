import { basketToProducts, useBasket, useCurrentOrderDetails, useCurrentStore, useMenu } from '@dominos/hooks-and-hocs'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { EnhancedEcommercePurchaseEvent } from '@dominos/hooks-and-hocs'
import { SHA256 } from 'crypto-js'

export const usePurchaseEvent = (): EnhancedEcommercePurchaseEvent => {
  const { basket } = useBasket()
  const currentStore = useCurrentStore()
  const { customerDetailsData, ...currentOrder } = useCurrentOrderDetails()
  const { CURRENCY_CODE } = getCountryConfig()
  const { emailAddress, mobileNumber } = customerDetailsData ?? {}
  const { menu } = useMenu()

  const storedOrderDetails = JSON.parse(localStorage.getItem('orderCheckoutDetails') || '{}')

  const orderBasket = basket?.id ? basket : storedOrderDetails?.basket
  const orderStore = currentStore?.storeNo ? currentStore : storedOrderDetails?.currentStore
  const orderDetails =
    currentOrder?.basketHeaderData?.serviceMethod && currentOrder.paymentMethod?.paymentMethod
      ? currentOrder
      : storedOrderDetails?.currentOrder
  const eventDetails: EnhancedEcommercePurchaseEvent = {
    'Reported Via ServerSide': false,
    Order: {
      Id: orderBasket?.id?.toUpperCase() ?? '',
      Affiliation: orderStore?.storeNo ?? '',
      Products: basketToProducts(orderBasket!, menu?.pages!, CURRENCY_CODE),
      Currency: CURRENCY_CODE,
      Revenue: orderBasket?.total ?? 0,
      Coupon: orderBasket?.lines
        .filter((line: { type: string }): line is BasketCoupon => line.type === 'BasketCoupon')
        .map((line: { code: string }) => line.code)
        .join(','),
    },
    Payment: {
      'Store Name': orderStore?.media?.name ?? '',
      'Store Id': orderStore?.storeNo ?? '',
      'Service Method': orderDetails?.basketHeaderData?.serviceMethod ?? '',
      'Service Method Subtype': orderDetails?.basketHeaderData?.serviceMethodSubType ?? '',
      'Payment Method': orderDetails?.paymentMethod?.paymentMethod ?? '',
      'Payment Provider': orderDetails?.paymentMethod?.providerCode ?? '',
    },
    'Checkout Hashed Email': emailAddress ? SHA256(emailAddress.toLowerCase()).toString() : undefined,
    'Checkout Hashed Phone': mobileNumber ? SHA256(mobileNumber.toLowerCase()).toString() : undefined,
  }

  return eventDetails
}
