import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const PortionEmptyLeftSideIcon = (props: SvgIconProps) => (
  <svg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 11.4973C0 13.076 0.299479 14.5594 0.898438 15.9476C1.4974 17.3357 2.32596 18.5579 3.38412 19.614C4.44227 20.6701 5.66469 21.4984 7.05137 22.0991C8.43805 22.6997 9.92093 23 11.5 23C13.0791 23 14.5619 22.6997 15.9486 22.0991C17.3353 21.4984 18.5577 20.6701 19.6159 19.614C20.674 18.5579 21.5026 17.3357 22.1016 15.9476C22.7005 14.5594 23 13.076 23 11.4973C23 9.92221 22.7005 8.43968 22.1016 7.0497C21.5026 5.65972 20.674 4.43669 19.6159 3.38059C18.5577 2.3245 17.3353 1.49704 15.9486 0.898225C14.5619 0.299408 13.0791 0 11.5 0C9.92093 0 8.43805 0.299408 7.05137 0.898225C5.66469 1.49704 4.44227 2.3254 3.38412 3.38331C2.32596 4.44122 1.4974 5.66426 0.898438 7.05243C0.299479 8.44059 0 9.92221 0 11.4973Z'
      fill='#0A7AFF'
    />
    <path
      d='M11.6324 20.865L11.6324 2.11606C12.9328 2.11606 14.1494 2.35741 15.2823 2.84013C16.4152 3.32284 17.4102 3.99371 18.2672 4.85274C19.1243 5.71178 19.7962 6.70774 20.2828 7.84064C20.7695 8.97354 21.0128 10.1902 21.0128 11.4905C21.0128 12.7909 20.7705 14.0075 20.2858 15.1404C19.8011 16.2733 19.1302 17.2683 18.2732 18.1254C17.4161 18.9824 16.4201 19.6533 15.2853 20.138C14.1504 20.6227 12.9328 20.865 11.6324 20.865Z'
      fill='white'
    />
  </svg>
)

export { PortionEmptyLeftSideIcon }
