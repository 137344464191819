import React from 'react'
import { useTranslation } from 'react-i18next'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'

export const PaymentTerminal = ({ paymentSetting, ...props }: PaymentMethodProps) => {
  const { t } = useTranslation('kiosk')

  return (
    <PaymentMethod
      paymentSetting={paymentSetting}
      methodId={paymentSetting.paymentMethod}
      paymentButtonTitle={t('Pay Now')}
      paymentButtonIncludeTotal={true}
      {...props}
    ></PaymentMethod>
  )
}
