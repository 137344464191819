import React from 'react'
import { TFunction } from 'react-i18next'
import { ProductUpsellData } from '@dominos/components/product/product-upsell/product-upsell.interface'
import { ProductUpsellPopupView } from '@dominos/components/product/product-upsell/product-upsell-popup/product-upsell-popup-view'
import { createProductUpsellPopupViewModel } from '@dominos/components/product/product-upsell/product-upsell-popup/create-product-upsell-popup-view-model'
interface ProductUpsellPopupProps {
  t: TFunction<'menu'>
  upsellData: ProductUpsellData
  onYesClicked: () => void
  onNoClicked: () => void
  isTestImgDevToggle: boolean
  productComponentUpsellVariation: string
}

const ProductUpsellPopup: React.FC<ProductUpsellPopupProps> = (props) => (
  <ProductUpsellPopupView
    testID='product-upsell-popup'
    viewModel={createProductUpsellPopupViewModel({ ...props })}
    onYesClicked={props.onYesClicked}
    onNoClicked={props.onNoClicked}
  />
)

export default ProductUpsellPopup
