import { B2BCONFIG_SESSIONSTORAGEKEY } from '@dominos/business/constants'

export const validatePickupDeliveryButtons = (isB2BOrderEnabled: boolean) => {
  let localShowPickUp = false
  let localShowDelivery = false

  const b2BConfig = window.sessionStorage.getItem(B2BCONFIG_SESSIONSTORAGEKEY)

  if (isB2BOrderEnabled && b2BConfig) {
    const b2BConfigObject = JSON.parse(b2BConfig)
    const { serviceMethods, stores } = b2BConfigObject.b2BConfig

    if (stores) {
      for (const store of stores) {
        if (store.serviceMethodOverride && store.serviceMethodOverride.length > 0) {
          store.serviceMethodOverride.forEach((serviceMethod: string) => {
            if (serviceMethod === 'Delivery') {
              localShowDelivery = true
            }
            if (serviceMethod === 'Pickup') {
              localShowPickUp = true
            }
          })
        }
      }
    }

    if (serviceMethods) {
      for (const serviceMethod of serviceMethods) {
        if (serviceMethod.code) {
          if (serviceMethod.code === 'Delivery') {
            localShowDelivery = true
          }
          if (serviceMethod.code === 'Pickup') {
            localShowPickUp = true
          }
        }
      }
    }
  }

  return { localShowPickUp, localShowDelivery }
}
