import gql from 'graphql-tag'

export const storesAlternativeStoresQuery = gql`
  query storeAlternativeStores($input: StoreAlternativeStoresRequest!) {
    storeAlternativeStores(input: $input) {
      stores {
        storeNo
        media {
          name
          displayAddress
        }
        geo {
          latitude
          longitude
        }
      }
    }
  }
`
