import { ErrorDefinitions } from '@dominos/interfaces'

const loadingQuestionsErrors: ErrorDefinitions = {}

const submitQuestionsErrors: ErrorDefinitions = {
  FEEDBACK_ALREADY_EXISTS_FOR_ORDER: {
    translation: {
      key: 'OrderFeedbackAlreadySubmitted',
      options: {
        defaultValue: 'Sorry, we can only accept feedback once per order.',
      },
    },
    display: 'alert',
  },
  UNABLE_TO_RESOLVE: {
    translation: {
      key: 'PleaseTrySubmitFeedbackAgainLater',
      options: {
        defaultValue:
          'Sorry, there was a problem submitting your feedback. Please try again later and if the problem persists, please call us.',
      },
    },
    display: 'alert',
  },
}

export { loadingQuestionsErrors, submitQuestionsErrors }
