import React from 'react'
import { ActionButton, CardHeader } from '@dominos/components'
import { useDominosTheme, useQuickUniversalAccountComponent } from '@dominos/hooks-and-hocs'
import css from './prompt.less'
import { Divider } from '../../cards/divider/divider'
import { useTranslation } from 'react-i18next'
import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'

export const Prompt = ({ testID }: BaseProps) => {
  const { t } = useTranslation('login')
  const { triggerAction } = useQuickUniversalAccountComponent()

  const handleRegister = () => triggerAction('register')
  const handleLogin = () => triggerAction('login')

  const nativeHandleRegister = () => notifyNativeApp('register')
  const nativeHandleLogin = () => notifyNativeApp('login')

  const isNative = isNativeApp()

  const { colours } = useDominosTheme()

  return (
    <div data-testid={testID}>
      <CardHeader testID={`${testID}.header`} title={t('PromptLabel', { defaultValue: `Got a Domino's Account?` })} />
      <div data-testid={`${testID}.section`} className={css.section}>
        <div data-testid={`${testID}.description`}>
          {t('PromptDescription', {
            defaultValue: `Unlock faster ordering and easier checkout with a Domino's account`,
          })}
        </div>
        <ActionButton
          testID={`${testID}.register`}
          onPress={isNative ? nativeHandleRegister : handleRegister}
          text={t('PromptCreateAnAccountButtonLabel', { defaultValue: `Create an Account` })}
        />
        <Divider testID={`${testID}.divider`} label={t('PromptDividerLabel', { defaultValue: `OR` })} />
        <ActionButton
          testID={`${testID}.login`}
          onPress={isNative ? nativeHandleLogin : handleLogin}
          containerStyle={{ backgroundColor: colours.actionDeemphasized }}
          textStyle={{ color: '#3478F6' }}
          text={t('PromptLoginButtonLabel', { defaultValue: `Log In` })}
        />
      </div>
    </div>
  )
}
