import React from 'react'
import styles from './combo-upsell-product-card.less'
import { Checkbox, Legends } from '@dominos/components'
import { ComboUpsellProductCardProps } from '../types'

const ComboUpsellProductCard = ({ testID, offerItem, onSelected }: ComboUpsellProductCardProps) => (
  <div data-testid={`${testID}.card`} className={offerItem.isSelected ? styles.containerOutline : styles.container}>
    <div onClick={onSelected} data-testid={testID} className={styles.innerContainer}>
      <div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            testID={`${testID}.checkbox`}
            onChange={() => {}}
            checked={offerItem.isSelected}
            unCheckedCheckboxColor='white'
            unCheckedBorderColor='#DCDBDE'
            diameter={20}
          />
        </div>
      </div>
      <div className={styles.imageContainer}>
        <Legends testID={`${testID}.menuLegend`} className={styles.legends} legends={offerItem.legends || []} />
        <img data-testid={`${testID}.image`} className={styles.image} src={offerItem.image.url || ''} alt='' />
      </div>
      <div data-testid={`${testID}.text-container`} className={styles.textContainer}>
        <p data-testid={`${testID}.title`} className={styles.title}>
          {offerItem.name}
        </p>
        <p data-testid={`${testID}.price`} className={styles.price}>
          {`+ ${offerItem.price}`}
        </p>
      </div>
    </div>
  </div>
)

export default ComboUpsellProductCard
