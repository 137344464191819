import { ActionButton, StandardInputField } from '@dominos/components'
import { noop } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useVoucherInput } from './use-voucher-input'
import { VoucherErrors } from './voucher-input-errors'
import css from './voucher-input.less'
import classnames from 'classnames'

export const VoucherInput = ({
  testID,
  validationErrors,
  basketLines,
  autoFocus = false,
  onApply = noop,
  onClear = noop,
}: VoucherInputProps) => {
  const { t } = useTranslation('menu')

  const { voucherCode, setVoucherCode, voucherErrors } = useVoucherInput(basketLines, validationErrors)

  const didChangeInput = (input: string) => {
    if (!input || input === '') {
      onClear()
    }
    setVoucherCode(input)
  }

  const didPressApply = () => {
    if (voucherCode) {
      onApply(voucherCode)
    }
  }

  return (
    <div data-testid={testID}>
      <div data-testid={`${testID}.container`} className={css.row}>
        <StandardInputField
          className={classnames(!!voucherErrors.length && css.inputError)}
          testID={`${testID}.input`}
          placeholder={t('Enter Voucher Code')}
          height='2.25rem'
          onChange={didChangeInput}
          onSubmitEditing={didPressApply}
          autoFocus={autoFocus}
          fill
          value={voucherCode}
          fontStyle={!!voucherErrors.length ? 'italic' : undefined}
        />
        <ActionButton
          className={css.button}
          testID={`${testID}.apply-button`}
          text={t('Apply')}
          onPress={didPressApply}
          textFontWeight={'semibold'}
          textSize='1rem'
        />
      </div>
      <VoucherErrors voucherErrors={voucherErrors} testID={testID} />
    </div>
  )
}
