import React from 'react'

import { ActionButton } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { GenericBottomBarButtonProps } from './generic-bottom-bar-button.interface'
import css from './generic-bottom-bar-button.less'

export const GenericBottomBarButton = ({ testID, buttonText, onClick }: GenericBottomBarButtonProps) => {
  const theme = useDominosTheme()

  const styles = {
    container: {
      boxShadow: theme.defaultShadow,
      borderTopLeftRadius: theme.webLargeBorderRadius,
      borderTopRightRadius: theme.webLargeBorderRadius,
    },
    actionButtonGenericBottomBarButton: {
      flex: 1,
    },
  }

  return (
    <div className={css.container} style={styles.container} data-testid={testID}>
      <ActionButton
        containerStyle={styles.actionButtonGenericBottomBarButton}
        testID={`${testID}.generic-bottom-bar-button`}
        text={buttonText}
        onPress={() => onClick()}
      />
    </div>
  )
}
