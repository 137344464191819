import { ToppingProps } from '@dominos/components/product/functions/interfaces'
import { getIngredientRecipeWithChange } from './get-ingredient-recipe-with-change'
import { getIngredientSwapsWithChange } from './get-ingredient-swaps-with-change'
import { mapToppingItemFromIngredient } from './map-topping-item-from-ingredient'

type Ingredient = Bff.Menu.old.Ingredient
type RecipeIngredient = Bff.Menu.old.RecipeIngredient
type ProductRule = Bff.Menu.old.ProductRule

/**
 * Normalizes a recipe ingredient and applies topping
 * change rules when the ingredient is an additional
 * topping.
 *
 * @param toppings Collection of possible product swaps
 * @param rules (optional) Product ingredient swap rules
 */
const normalizeRecipeWithToppingRules =
  (toppings: Ingredient[], rules?: ProductRule) => (ingredient: RecipeIngredient) => {
    const isFoundInPossibles = toppings.some(({ code }) => ingredient.ingredient.code === code)

    return {
      ...ingredient,
      max: isFoundInPossibles ? rules?.maxPerIngredient : 1,
      allowAdd: rules?.allowAdd,
      allowRemove: rules?.allowRemove,
    }
  }

/**
 * Normalizes an additional topping ingredient, applying topping
 * rules and a default quantity when the ingredient is also a
 * recipe ingredient.
 *
 * @param recipe Collection of product recipe ingredients
 * @param rules (optional) Product ingredient swap rules
 */
const normalizeToppingWithRecipeRules =
  (recipe: RecipeIngredient[] = [], rules?: ProductRule) =>
  (topping: Ingredient) => {
    const ingredient = recipe.find((existing) => existing.ingredient.code === topping.code)

    return {
      ingredient: topping,
      quantity: ingredient?.quantity || 0,
      max: rules?.maxPerIngredient,
      allowAdd: rules?.allowAdd,
      allowRemove: rules?.allowRemove,
    }
  }

/**
 * Creates ingredient sections containing the changed recipe
 * ingredients and possible topping changes.
 *
 * Section one contains the recipe ingredients, updated according to
 * the `action` and `quantity` from the matching `toppingChanges`
 * ingredient. When the `toppingChanges` ingredient is not in the
 * recipe, then the additional topping (swaps) are checked for a
 * matching ingredient.
 *
 * Section two simply contains a collection of the additional ingredient
 * (swaps), with `toppingChanges` and default `recipe` quantities applied.
 *
 * @param toppingChanges Collection of basket line topping changes
 * @param currentSize Currently selected product size
 */
export const productIngredientsTransform = (
  toppingChanges: BasketLineChange[],
  currentSize?: ProductSize,
): ToppingProps => {
  if (!currentSize) {
    return {
      recipeIngredientCodes: [],
      currentIngredients: [],
      possibleIngredients: [],
      ingredientRules: undefined,
      changes: [],
    }
  }

  const recipe = currentSize.recipe?.toppings || []
  const toppings = currentSize.swaps?.toppings?.ingredients || []
  const rules = currentSize.swaps?.toppings?.rule

  const currents = toppingChanges.reduce<LimitedRecipeIngredient[]>(
    (ingredients, change) => getIngredientRecipeWithChange(ingredients, change, toppings, rules),
    [...recipe].map(normalizeRecipeWithToppingRules(toppings, rules)),
  )

  const possibles = toppingChanges.reduce<LimitedRecipeIngredient[]>(
    getIngredientSwapsWithChange,
    [...toppings].map(normalizeToppingWithRecipeRules(recipe, rules)),
  )

  const recipeIngredientCodes = recipe.map((ingredient) => ingredient?.ingredient.code) || []

  const ingredientRules: IngredientRules | undefined =
    (rules && {
      min: rules?.min || 0,
      max: rules?.max || 0,
      maxPerIngredient: rules?.maxPerIngredient || 0,
      allowAdd: rules?.allowAdd || false,
    }) ??
    undefined

  return {
    recipeIngredientCodes,
    currentIngredients: currents.map(mapToppingItemFromIngredient),
    possibleIngredients: possibles.map(mapToppingItemFromIngredient),
    ingredientRules,
    changes: toppingChanges,
  }
}
