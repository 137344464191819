import React, { useEffect } from 'react'
import css from './native-app-auth.less'
import { UniversalAuth } from '@dominos/components/universal-auth'
import { useOrderIdFromQuery, useQuickUniversalAccountComponent } from '@dominos/hooks-and-hocs'

interface INativeAppAuthProps {
  initial: 'login' | 'register'
}

export const NativeAppAuth = ({ initial }: INativeAppAuthProps) => {
  const { current, reset, setData } = useQuickUniversalAccountComponent()
  const { finished, order } = useOrderIdFromQuery()

  useEffect(() => reset(initial), [initial])

  useEffect(() => {
    if (current === 'registerEmail' && finished && !!order) {
      setData({ customer: order.details?.header.customer })
    }
  }, [current, finished])

  return (
    <div className={css.outer} data-testid={'native-app-auth.container'}>
      <UniversalAuth testID={'native-app-auth'} current={current} />
    </div>
  )
}
