import React from 'react'

export const SectionList = (props: SectionListProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const section: any[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let list: any[] = []

  const data = props.data !== null ? props.data : []

  data.forEach((result, sectionIndex: number) => {
    for (const innerResult of result.data) {
      const key = props.keyExtractor(innerResult, list.length)
      const item = props.renderItem(innerResult, key, sectionIndex)
      list.push(item)
    }
    section.push(list)
    list = []
    section.push(props.sectionHeader)
  })
  section.pop()

  return <>{section}</>
}
