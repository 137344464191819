import React, { useState } from 'react'
import PizzaSlice1 from './slice-1'
import PizzaSlice2 from './slice-2'
import PizzaSlice3 from './slice-3'
import PizzaSlice4 from './slice-4'
import PizzaSlice5 from './slice-5'
import PizzaSlice6 from './slice-6'
import PizzaSlice7 from './slice-7'
import PizzaSlice8 from './slice-8'

import useInterval from './use-interval'

export const paymentOverlayPizzaSlices = [
  PizzaSlice1,
  PizzaSlice2,
  PizzaSlice3,
  PizzaSlice4,
  PizzaSlice5,
  PizzaSlice6,
  PizzaSlice7,
  PizzaSlice8,
]

const PaymentOverlayAnimation = () => {
  const [index, setIndex] = useState<number>(0)

  useInterval(() => {
    setIndex(index + 1)
  }, 300)

  const Slice = paymentOverlayPizzaSlices[index % 8]

  return (
    <div>
      <Slice width={100} height={100} />
    </div>
  )
}

export { PaymentOverlayAnimation }
