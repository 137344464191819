import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { rootActions } from '@dominos/business'
import { Checkbox, MDTargetBlankAnchor } from '@dominos/components'
import { isKioskOrderType } from '@dominos/business/functions/common'
import { useCountryCode, useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import Markdown from 'markdown-to-jsx'

import { getFormattedRoundUpAmount } from './get-round-up-display-amount'
import { getRoundUpForCharityImages } from '../get-round-up-for-charity-images'
import { RoundUpForCharityPopup } from '../popup'
import { useRoundUpForCharityDetails } from '../use-round-up-for-charity'
import css from './round-up-for-charity-checkout.less'

interface RoundUpForCharityProps {
  testID: string
  amount: number
}

const icon = 'question'

const legalText = '100% of your donation will go directly to the charity.'

export const RoundUpForCharityCheckout = ({ testID, amount }: RoundUpForCharityProps) => {
  const { t } = useTranslation('checkout')
  const theme = useDominosTheme()
  const countryCode = useCountryCode()
  const roundUpForCharityDetails = useRoundUpForCharityDetails()
  const dispatch = useDispatch()
  const [charityLegalTextEnabled] = useFeatures('CharityLegalText')
  const [showModal, setShowModal] = useState<boolean>(false)
  const roundUpTestID = `${testID}.round-up-for-charity`
  const image = getRoundUpForCharityImages(countryCode, 'logo')
  const onCheckboxChange = (name: string | null, checked: boolean) => {
    if (checked) {
      dispatch(
        rootActions.setRoundUpForCharityDetails({
          amount,
        }),
      )
    } else {
      dispatch(rootActions.removeRoundUpForCharityDetails())
    }
  }

  useEffect(() => {
    if (roundUpForCharityDetails) {
      dispatch(
        rootActions.updateRoundUpForCharityDetails({
          amount,
        }),
      )
    }
  }, [amount])

  const displayAmount = useMemo(() => getFormattedRoundUpAmount(amount, countryCode, t), [amount, countryCode])

  const showModalPopup = () => setShowModal(true)
  const closeModalPopup = () => setShowModal(false)

  const styles = {
    unCheckedCheckbox: {
      backgroundColor: 'transparent',
    },
    unCheckedCheckboxLabel: {
      color: theme.colours.defaultButtonText,
    },
  }

  return (
    <div className={css.container} data-testid={roundUpTestID}>
      <div className={css.header}>
        <h4 className={css.title} data-testid={`${roundUpTestID}.title`}>
          {t('roundUpForCharityTitle')}
        </h4>
        <div
          role='button'
          data-testid={`${roundUpTestID}.${icon}`}
          className={css.learnMoreButton}
          onClick={showModalPopup}
        >
          <StaticSvgIcon name={icon} isUnstyled />
        </div>
      </div>
      {showModal && <RoundUpForCharityPopup testID={roundUpTestID} onClose={closeModalPopup} />}
      <div className={css.body}>
        {image && (
          <div className={css.image}>
            <img data-testid={`${roundUpTestID}.image`} src={image} alt={t('roundUpForCharityImageAlt')} />
          </div>
        )}
        <Markdown className={css.description} options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
          {t('roundUpForCharityDescription')}
        </Markdown>
      </div>
      <div className={css.checkbox} style={!!roundUpForCharityDetails ? styles.unCheckedCheckbox : {}}>
        <Checkbox
          testID={`${roundUpTestID}.input`}
          defaultChecked={!!roundUpForCharityDetails}
          diameter={isKioskOrderType() ? 35 : 20}
          onChange={onCheckboxChange}
          unCheckedBorderColor={theme.colours.defaultButtonText}
          unCheckedCheckboxColor={theme.colours.defaultButtonText}
        >
          <div className={css.label} style={!roundUpForCharityDetails ? styles.unCheckedCheckboxLabel : {}}>
            {t(`roundUpForCharity${!!roundUpForCharityDetails ? 'Checked' : 'Unchecked'}Label`, {
              amount: displayAmount,
            })}
          </div>
        </Checkbox>
      </div>
      {charityLegalTextEnabled && !!roundUpForCharityDetails && (
        <Markdown className={css.legalText} options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
          {t(`roundUpForCharityLegalText`, { defaultValue: legalText })}
        </Markdown>
      )}
    </div>
  )
}
