import React from 'react'
import 'react-simple-keyboard/build/css/index.css'
import css from './switch-layout.less'
import { KeyboardLayout, Language } from '@dominos/hooks-and-hocs'
import { GlobeIcon } from '@dominos/res/images/icons/components/globe-icon'
import { StaticSvgIcon } from '@dominos/res'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Modal } from '@dominos/components/notification'

export const SwitchLayout: React.FC<{
  handleChange: (index: number) => void
  handleDismiss: () => void
  testID: string
  layouts: KeyboardLayout[]
  selectedLayout: KeyboardLayout | undefined
}> = (props) => {
  const { t } = useTranslation('footer')

  const isSelected = (language: Language, layoutName: string) =>
    props.selectedLayout &&
    language.value === props.selectedLayout.language.value &&
    layoutName === props.selectedLayout.layoutName

  return (
    <Modal
      testID={`${props.testID}-modal`}
      childrenContainerClassNames={css.switchLayoutDialogWrapper}
      onModalClose={props.handleDismiss}
      transparentBackground
      suppressBackgroundScroll
    >
      <div data-testid={`${props.testID}-modal-inner`} className={css.dialog}>
        <div className={css.changeLanguage} data-testid={`${props.testID}-header`}>
          <GlobeIcon width={30} height={30} fill='black' />
          <span>{t('ChangeLanguage')}</span>
        </div>
        {props.layouts.map(({ language, layoutName, displayName }, index: number) => (
          <div
            key={index}
            data-testid={`${props.testID}-option-${language.value}`}
            className={classNames(css.container, isSelected(language, layoutName) && css.selectedLayout)}
            onClick={() => props.handleChange(index)}
          >
            <img src={language.flag} alt={language.value} className={css.flag} />
            <span>{displayName}</span>
            {isSelected(language, layoutName) && (
              <span data-testid={`${language.name}-tick`} className={css.tick}>
                <StaticSvgIcon name={'selectionTick'} />
              </span>
            )}
          </div>
        ))}
      </div>
    </Modal>
  )
}
