import React from 'react'
import { useTranslation } from 'react-i18next'
import { useValidation, useValidationJP } from '@dominos/business/functions/validation'

import { CollapsableTitledCard, ValidationTextField } from '@dominos/components'

import styles from './address-makeup-container.less'

import { useCountryCode } from '@dominos/hooks-and-hocs'

const fieldNames = {
  address: 'address',
  buildingName: 'buildingName',
} as { [key: string]: string }

type AddressDetails = {
  [key in 'address' | 'buildingName']?: string
}

export const AddressMakeUpContainer = ({
  addressDetails,
  onChange = () => {},
}: {
  addressDetails: AddressDetails
  onChange?: (fieldName: string | null, value: string | boolean | undefined) => void
}) => {
  const { t } = useTranslation('delivery-address')

  const { isRequired } = useValidation()
  const { validateHouseNumber } = useValidationJP()

  const countryCode = useCountryCode()
  const isJapan = !!countryCode && countryCode === 'JP'

  if (!isJapan) {
    return null
  }

  const handleChange = (key: string) => (updatedField: { [K: string]: ValidationField }) =>
    onChange(key, updatedField[key].value)

  return (
    <div style={{ marginBottom: 10, marginTop: 10 }}>
      <CollapsableTitledCard
        testID='address-container.makeup.card'
        primaryTitle={t('AddressDetails', { defaultValue: 'Address Details' })}
        startCollapsed={false}
        noPadding={true}
      >
        <div className={styles.titledCardContent}>
          <ValidationTextField
            testID={`${fieldNames.address}.field`}
            fieldName={fieldNames.address}
            placeholder={t('AddressFieldPlaceholder', { defaultValue: 'Rest Of Address' })}
            validationRules={[isRequired, validateHouseNumber]}
            style={{ width: '100%' }}
            defaultValue={addressDetails.address}
            onChange={handleChange(fieldNames.address)}
          />
          <ValidationTextField
            testID={`${fieldNames.buildingName}.field`}
            fieldName={fieldNames.buildingName}
            placeholder={t('BuildingNameFieldPlaceholder', { defaultValue: 'Building Name' })}
            style={{ width: '100%' }}
            defaultValue={addressDetails.buildingName}
            onChange={handleChange(fieldNames.buildingName)}
          />
        </div>
      </CollapsableTitledCard>
    </div>
  )
}
