type ProductNutritionals = Bff.Menu.old.ProductNutritionals

import React from 'react'
import { useTranslation } from 'react-i18next'

import css from './product-nutritional-line.less'

interface Props {
  info: ProductNutritionals
  keyName: keyof ProductNutritionals
  defaultUnit: string
}

export const ProductNutritionalLine = ({ info: nutritionalInfo, keyName, defaultUnit }: Props) => {
  const { t } = useTranslation('menu')

  return nutritionalInfo[keyName] ? (
    <div className={css.nutritionalLine} data-testid={`nutritional-line-${keyName}`}>
      <div className={css.nutritionalName}>{t(keyName)}</div>
      <div className={css.nutritionalValue}>{Math.round(nutritionalInfo[keyName]! * 100) / 100}</div>
      <div className={css.nutritionalUnit}>{t(`${keyName}-unit`, { defaultValue: defaultUnit })}</div>
    </div>
  ) : null
}
