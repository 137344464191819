/**
 * this function adds up two numbers,  with up to 3 decimal places each, without floating point issues
 *
 * Javascript uses floating points for their numbers,
 * this means adding up two fractional numbers can result to the numbers being slightly of
 * for example: 0.1 + 0.2 === 0.30000000000000004
 */
export function addUpWithHighPrecision(left: number, right: number) {
  return (left * 1000 + right * 1000) / 1000
}
