import React from 'react'

export const CheesyCrust = ({ color }: CrustSVGProps) => {
  const selectedColorLight = color ? color : '#4A4A4A'

  return (
    <svg width='51px' height='49px' viewBox='0 0 250 250'>
      <g fill={selectedColorLight}>
        <path
          d='M125,14c61.2,0,111,49.8,111,111s-49.8,111-111,111S14,186.2,14,125S63.8,14,125,14 M125,0
		C56.1,0,0,56.1,0,125c0,68.9,56.1,125,125,125c68.9,0,125-56.1,125-125C250,56.1,193.9,0,125,0L125,0z'
        />
      </g>
      <g stroke={selectedColorLight} fill={'none'} strokeWidth={7.136}>
        <path d='M184.7,128.3c0,5.9,4.8,10.7,10.7,10.8v33.6H54.6V99.4h140.8v18C189.5,117.5,184.7,122.3,184.7,128.3z' />
        <circle cx='154.5' cy='148.4' r='10.8' />
        <path d='M147.9,99.4c0,7.6-6.2,13.8-13.8,13.8c-7.6,0-13.8-6.2-13.8-13.8H147.9z' />
        <circle cx='91.4' cy='136' r='17.5' />
        <path d='M195.4,99.4l-91.6-27c0,0-33,0-49.2,27' />
      </g>
    </svg>
  )
}
