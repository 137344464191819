import { ActionButton, MDTargetBlankAnchor } from '@dominos/components'
import { GenericCardPopup, useKiosk } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './error-card-popup.less'
import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import Markdown from 'markdown-to-jsx'

export const ErrorCardPopup = ({
  title,
  message,
  confirmLabel,
  onClose,
  testID,
  icon,
}: {
  title: string
  message: string
  confirmLabel?: string
  onClose: () => void
  testID: string
  icon?: svgIconsName
}) => {
  const { t } = useTranslation('home')
  const { isKioskOrder } = useKiosk()
  const styles = getStyles(isKioskOrder)

  return (
    <GenericCardPopup testID={testID} popupTitle={''} genericCardStyles={styles.popUpCard}>
      <div className={css.wrapper}>
        {icon && (
          <div className={css.icon} data-testid={`${testID}.icon`}>
            <StaticSvgIcon
              name={icon}
              includePadding={true}
              isUnstyled={true}
              height={styles.iconSize}
              width={styles.iconSize}
            />
          </div>
        )}
        <h2 className={css.title} data-testid={`${testID}.title`}>
          {title}
        </h2>
        <Markdown
          className={css.text}
          data-testid={`${testID}.message`}
          options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}
        >
          {message}
        </Markdown>
        <div className={css.actionButtonsContainer}>
          <ActionButton
            testID={`${testID}.confirm`}
            containerStyle={styles.buttonContainer}
            textStyle={styles.buttonText}
            text={
              confirmLabel ||
              t('ErrorConfirm', {
                defaultValue: 'Ok',
              })
            }
            onPress={onClose}
          />
        </div>
      </div>
    </GenericCardPopup>
  )
}

const getStyles = (isKioskOrder: boolean) => ({
  buttonText: {
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  buttonContainer: {
    width: '100%',
    height: '5vh',
    borderRadius: isKioskOrder ? 18 : 10,
    backgroundColor: '#4A4A4A',
  },
  popUpCard: {
    maxWidth: isKioskOrder ? '75vw' : '300px',
  },
  iconSize: isKioskOrder ? 100 : 75,
})
