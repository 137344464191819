import { useTranslation } from 'react-i18next'
import { useLegalUrl } from './use-legal-url'

export const useFooterTranslations = () => {
  const { t } = useTranslation('footer')
  const phone = t('Phone Number', { defaultValue: '131 888' })
  const legalUrl = useLegalUrl()

  return {
    legalLinks: [
      {
        title: t('Privacy Policy'),
        link: t('Privacy Policy Url', {
          defaultValue: 'https://www.dominos.com.au/about-us/contact-us/privacy-policy',
        }),
      },
      {
        title: t('Terms & Conditions'),
        link: t('Terms & Conditions Url', {
          defaultValue: 'https://www.dominos.com.au/about-us/contact-us/terms-conditions',
        }),
      },
    ],
    phone: phone,
    legal: t('Legal'),
    legalUrl: legalUrl,
    language: t('Language'),
    callText: t('Call (before phone number)', { defaultValue: 'Call' }),
    contactUs: t('Contact Us'),
    restartOrder: t('Restart Order'),
    storeHealth: t('StoreHealth'),
    storeHealthReport: t('StoreHealthReport'),
    nutritionalInformation: t('Nutritional Information'),
    nutritionalInformationText: t('Nutritional Information Text'),
    copyRight: t('Copyright', {
      defaultValue: "Copyright \u00A9 Domino's Pizza Enterprises Ltd {{year}}",
      year: new Date().getFullYear(),
    }),
  }
}
