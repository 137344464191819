import { useOrderIdFromQuery } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

interface UseOrderIdProps {
  inspectQuery?: boolean
  useIdFromBasket?: boolean
}

export const useOrderId = ({ inspectQuery = false, useIdFromBasket = false }: UseOrderIdProps = {}) => {
  const [orderId, setOrderId] = useState<string | undefined | null>()
  const [storedOrderId, setStoredOrderId] = useState<string | undefined | null>()
  const basketId = useSelector((state: RootReducer) => state.basketReducer.basket.id)
  const currentOrderId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderId)
  const { id: queryId, finished, errors, order } = useOrderIdFromQuery()

  useEffect(() => {
    setStoredOrderId(!!useIdFromBasket ? basketId : currentOrderId)
  }, [basketId, currentOrderId])

  useEffect(() => {
    if (!inspectQuery) {
      setOrderId(storedOrderId ? storedOrderId : null)
    }
  }, [storedOrderId])

  useEffect(() => {
    if (inspectQuery && finished) {
      setOrderId(!!queryId ? queryId : !!storedOrderId ? storedOrderId : null)
    }
  }, [queryId, finished, storedOrderId])

  return {
    errors,
    order,
    id: orderId,
  }
}
