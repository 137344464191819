import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 12,
  height: 25,
}

const FacebookIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#000000'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 12 25' {...props}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-29.000000, -256.000000)' fill={fill}>
          <g transform='translate(11.750867, 246.000000)'>
            <path
              d='M20.4434861,35 L24.8480975,35 L24.8480975,22.5061111 L28.1523622,22.5061111 L28.8058526,18.6022222 L24.8480975,18.6022222 L24.8480975,15.7733333 C24.8480975,14.8613889 25.4190955,13.9038889 26.2351524,13.9038889 L28.4844815,13.9038889 L28.4844815,10 L25.7278375,10 C21.4145857,10.1791667 20.5283968,12.7588889 20.4515472,15.4686111 L20.4434861,18.6022222 L18.2401055,18.6022222 L18.2401055,22.5061111 L20.4434861,22.5061111 L20.4434861,35 Z'
              id='Fill-4'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export { FacebookIcon }
