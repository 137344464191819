export const mapGooglePredictionsToAddressLines = (
  autoComplete: google.maps.places.AutocompleteResponse | undefined,
  locale?: string | undefined,
): AddressLine[] | undefined => {
  if (!autoComplete) {
    return undefined
  }

  // TODO: validate this address
  const results = autoComplete.predictions.map((prediction, index): AddressLine => {
    const title = prediction.structured_formatting.main_text
    const subtitle = (prediction.structured_formatting.secondary_text as string | undefined) || prediction.description

    return {
      icon: 'pinIcon',
      suffix: 'caret',
      address: title,
      name: subtitle,
      uid: index,
      geo: {
        id: prediction.place_id,
      },
      structuredAddress: {
        title,
        subtitle,
      },
      additionalData: { ...prediction },
      locale: locale,
    }
  })

  return results.length > 0 ? results : undefined
}
