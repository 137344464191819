import { useApolloClient } from '@apollo/client'
import { idTokenUtils } from '@dominos/business/functions/customer'
import { SwrveEventKeys, useAuthentication, useCustomer, useSwrve } from '@dominos/hooks-and-hocs'

export const useSetLoggedInUser = () => {
  const client = useApolloClient()
  const { saveToken, deleteToken } = idTokenUtils()
  const { getCustomer } = useCustomer()
  const { reset, event } = useSwrve()
  const { setLoggedInUser: setLoggedInUserInProvider } = useAuthentication()

  const handleInvalidUser = () => {
    deleteToken()
    setLoggedInUserInProvider(false)
  }

  const setLoggedInUser = async (
    idToken: string,
    customerId: string,
    newUser?: boolean,
    loginProvider?: string,
  ): Promise<boolean> => {
    saveToken(idToken)
    reset(customerId, () => event(getEventName(newUser, loginProvider)))

    try {
      const response = await getCustomer(customerId)
      if (response && response.data?.customer) {
        client.cache.evict({ fieldName: 'availablePayments' })
        setLoggedInUserInProvider(true)

        return true
      } else {
        handleInvalidUser()

        return false
      }
    } catch (error) {
      handleInvalidUser()

      return false
    }
  }

  const getEventName = (newUser?: boolean, loginProvider?: string) =>
    (newUser ? 'account_created' : `user_login_${loginProvider?.toLocaleLowerCase() || 'email'}`) as SwrveEventKeys

  return {
    setLoggedInUser,
  }
}
