import {
  ProductComponentUpsellLDVariation,
  ProductUpsellData,
} from '@dominos/components/product/product-upsell/product-upsell.interface'
import { TFunction } from 'react-i18next'
import { getImageUrl } from '@dominos/components/product/product-upsell/utils'

export type ProductUpsellPopupViewProps = BaseProps & {
  viewModel: ProductUpsellPopupViewModel
  onYesClicked: () => void
  onNoClicked: () => void
}

type ProductUpsellPopupViewModelProps = {
  t: TFunction<'menu'>
  upsellData: ProductUpsellData
  isTestImgDevToggle: boolean
  productComponentUpsellVariation: string
}

type ProductUpsellPopupViewModel = {
  yesButtonText: string
  noButtonText: string
  popupImageUrl: string | undefined
}

export const createProductUpsellPopupViewModel = ({
  t,
  upsellData,
  isTestImgDevToggle,
  productComponentUpsellVariation,
}: ProductUpsellPopupViewModelProps): ProductUpsellPopupViewModel => {
  const yesButtonText =
    productComponentUpsellVariation == ProductComponentUpsellLDVariation.VariationPriceEnable && upsellData.price
      ? upsellData.price
      : t('ProductComponentUpsellYes')
  const noButtonText = t('ProductComponentUpsellNo')
  const popupImageUrl = getImageUrl(upsellData.media.popupImage.uri ?? '', isTestImgDevToggle)

  return { yesButtonText, noButtonText, popupImageUrl }
}
