import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const VeganIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <g id='iOS-HIG' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Element-|-Icons' transform='translate(-978.000000, -621.000000)' fill={fill} fillRule='nonzero'>
          <g id='Germany' transform='translate(812.000000, 474.000000)'>
            <g id='Set-Menu-Copy' transform='translate(149.000000, 147.369636)'>
              <g id='Group' transform='translate(16.000172, 0.000000)'>
                <g id='Group-2' transform='translate(1.528302, 0.000000)'>
                  <path
                    d='M11.6936701,17.2108763 C15.0783372,12.9968563 18.1043371,7.65545128 18.3517899,5.58138651 C16.9733078,8.68550061 3.44476593,26.9261321 4.26538956,19.4062863 C6.0956976,18.2084064 7.92628341,17.0106993 9.57941124,15.5519683 C4.69363464,6.17494843 17.1992772,3.66996826 19.9909505,2.21947301 C22.7336483,5.95875531 20.9033287,19.0936276 11.6936701,17.2108763 Z'
                    id='path'
                    transform='translate(12.693694, 11.714251) rotate(-19.000000) translate(-12.693694, -11.714251) '
                  />
                  <path
                    d='M0,9.43009928 C3.04194921,8.6178882 11.298889,12.6787033 7.93104574,17.7545318 C5.9755529,14.8104919 3.15089497,11.7650109 1.84706158,11.1556422 C3.47666038,12.6783827 12.3853454,24.9623894 7.60519458,22.7288389 C7.27927482,21.4091011 6.84467512,19.9877617 6.30153269,18.6679838 C-0.868907844,19.5816361 0.326022661,11.4601262 0.000171500611,9.42969865 L0,9.43009928 Z'
                    id='path'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
