import React, { useEffect, useRef, useState } from 'react'
import { GoogleMapMarkerProps } from './google-map.interface'
import { googleDeliveryLocationPinIcon } from '../iconHelper'

const Marker: React.FC<GoogleMapMarkerProps> = (props, ref) => {
  const { map, position, onMarkerClick, onMarkerDragEnd } = props
  const markerRef = useRef<google.maps.Marker | null>(null)

  const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral>()
  const [positionCache, setPositionCache] = useState<google.maps.LatLngLiteral>(position)

  useEffect(() => {
    markerRef.current = new google.maps.Marker({
      position: positionCache,
      icon: googleDeliveryLocationPinIcon,
      map: map,
      draggable: true,
    })

    return () => {
      if (markerRef.current) {
        markerRef.current.setMap(null)
      }
    }
  }, [])

  const setCurrentPosition = () => {
    const pos = markerRef.current?.getPosition()
    if (pos) {
      setMarkerPosition(pos?.toJSON())
    }
  }

  useEffect(() => {
    if (typeof onMarkerDragEnd === 'function' && markerPosition)
      onMarkerDragEnd({ lat: markerPosition.lat, lng: markerPosition.lng })
  }, [markerPosition])

  useEffect(() => {
    if (positionCache.lat !== position.lat || positionCache.lng !== position.lng) {
      markerRef.current?.setPosition(position)
      setPositionCache(position)
    }
  }, [position])

  const click = () => {
    if (typeof onMarkerClick === 'function') {
      onMarkerClick()
    }
  }

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.setMap(map)

      const listeners: Array<google.maps.MapsEventListener> = []

      if (typeof onMarkerClick === 'function') {
        listeners.push(markerRef.current.addListener('click', click))
      }

      if (typeof onMarkerDragEnd === 'function') {
        listeners.push(markerRef.current.addListener('dragend', setCurrentPosition))
      }

      return () => {
        if (listeners.length > 0) {
          listeners.forEach((listener) => listener.remove())
        }
      }
    }
  }, [map, onMarkerClick, onMarkerDragEnd])

  return null
}

export { Marker }
