/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import { useTranslation } from 'react-i18next'

import { rootActions } from '@dominos/business'
import { NavigationConstants } from '@dominos/navigation'
import {
  convertDeliveryAddressRequestToIAddress,
  GoogleAutoComplete,
  useAddressSearchDetails,
  useAutoComplete,
  useCountryCode,
  useErrorCode,
  useFeatures,
  useSearchAddress,
} from '@dominos/hooks-and-hocs'

import { DeliveryAddressSearch } from '../delivery-address-search'
import { MapProviderContainer } from './map-provider-container'
import { ConfirmAddressContainer } from './confirm-address-container'
import { DeliveryAddressProps } from './delivery-address.interface'

import { useValidateAddress } from '@dominos/hooks-and-hocs/location/use-validate-address'

import css from './delivery-address.less'
import { GeoLocationInterface } from '@dominos/interfaces'

import { AddressValidationResult } from '@dominos/hooks-and-hocs/location/use-validate-address'
import { useCorrectedAddress } from './hooks/use-corrected-address'
import { useReport } from '@dominos/hooks-and-hocs'
import { mapDeliveryAddressWithAdditionalFieldsToStoredCustomerAddress } from '@dominos/business/functions'

import { ERROR_USER_ADDRESS_MANUAL_CORRECTION_FAILED } from '@dominos/components'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

const DELIVERY_SERVICE_METHOD = 'Delivery'

// eslint-disable-next-line max-lines-per-function
export const DeliveryAddress = (props: DeliveryAddressProps) => {
  const { testID, searchMode, detectedLocation, locationDetectionPermitted, onMapStateChange } = props
  const { t } = useTranslation('delivery-address')

  const [
    autoCompleteDisplayAdditionalFieldsCheckout,
    autoCompleteDeliveryAddressFieldsHidden,
    autoCompleteDeliveryAddressUserManualAddressEntry,
  ] = useFeatures(
    'AutoCompleteDeliveryAddressFieldsDisplayCheckout',
    'AutoCompleteDeliveryAddressFieldsHidden',
    'AutoCompleteDeliveryAddressUserManualAddressEntry',
  )
  const { reportDeliveryAddressSelected } = useReport()

  const { validateAddress } = useValidateAddress()

  const countryCode = useCountryCode() as BffContext.Countries

  const reportAutoComplete = useReportAutoCompleteDelivery()

  const [displayAddressSearch, setDisplayAddressSearch] = useState<boolean>(true)
  const [selectedAddress, setSelectedAddress] = useState<DeliveryAddressRequest | undefined>()
  const [correctedFields, setCorrectedFields] = useState<AdditionalFields | undefined>()
  const [deliveryCoordinates, setDeliveryCoordinates] = useState<GeoLocationInterface | undefined>()
  const [searchIsCompleted, setSearchIsCompleted] = useState<boolean>(false)
  const [formError, setFormError] = useState<string | undefined>()

  const [addressForPulse, setAddressForPulse] = useState<DeliveryAddressRequest | undefined>()

  const [addressValidationResult, setAddressValidationResult] = useState<AddressValidationResult>({ isValid: true })

  const dispatch = useDispatch()
  const searchAddress = useSearchAddress()

  const addressSearchDetails = useAddressSearchDetails(DELIVERY_SERVICE_METHOD)
  const autoComplete = useAutoComplete(GoogleAutoComplete, DELIVERY_SERVICE_METHOD)
  const errorCode = useErrorCode({
    type: DELIVERY_SERVICE_METHOD,
    predictions: autoComplete.predictions,
    storeDetailsServerError: addressSearchDetails.error,
    storeDetails: addressSearchDetails.storeDetails,
    autoCompleteError: autoComplete.error,
    input: autoComplete.input,
    searchIsCompleted: searchIsCompleted,
    formError: formError,
    geoLocation: deliveryCoordinates && {
      latitude: deliveryCoordinates.latitude,
      longitude: deliveryCoordinates.longitude,
    },
  })

  const correctedAddressHook = useCorrectedAddress({ countryCode })

  const isLoading = useMemo(
    () => autoComplete.isLoading || addressSearchDetails.isLoading,
    [autoComplete.isLoading, addressSearchDetails.isLoading],
  )

  const navigateTimedOrder = (deliveryAddress: DeliveryAddress) => {
    if (!deliveryAddress || !countryCode) {
      return
    }
    const customerAddress = mapDeliveryAddressWithAdditionalFieldsToStoredCustomerAddress(
      t,
      deliveryAddress,
      undefined,
      countryCode,
    )

    dispatch(rootActions.selectServiceMethod('Delivery'))
    dispatch(rootActions.addCustomerAddressToOrder(customerAddress))
    reportDeliveryAddressSelected()

    navigate(NavigationConstants.timedOrder)
  }

  useEffect(() => {
    addressSearchDetails.reset()
    dispatch(rootActions.resetCurrentStore())
    dispatch(rootActions.resetAutoCompleteScenario())
    reportAutoComplete.reportLandedDeliveryAutoCompletePage()
  }, [])

  useEffect(() => {
    reportAutoComplete.reportAddressInput(autoComplete.input)
  }, [autoComplete.input])

  useEffect(() => {
    if (searchMode === 'List') {
      setSearchIsCompleted(false)
    }
  }, [searchMode])

  // Step 1
  useEffect(() => {
    if (!autoComplete.address) return
    if (autoComplete.predictions && autoComplete.predictions.length > 0) autoComplete.resetPredictions()

    const validationResult = validateAddress(autoComplete.address)
    setAddressValidationResult(validationResult)

    setSelectedAddress(autoComplete.address)
    setDeliveryCoordinates(autoComplete.address.geo)

    if (
      searchMode === 'List' &&
      validationResult.isValid &&
      autoComplete.validateDeliveryAddressRequest(autoComplete.address)
    ) {
      setAddressForPulse(autoComplete.address)
    }
  }, [autoComplete.address])

  useEffect(() => {
    if (!addressForPulse) return

    const fetchStores = async () => {
      await addressSearchDetails.fetchStoreDetails(addressForPulse)
    }

    fetchStores()
  }, [addressForPulse])

  // Step 2
  useEffect(() => {
    if (!addressSearchDetails.storeDetails?.storeNo || !addressForPulse) return

    const addressLine: DeliveryAddress = {
      ...addressForPulse,
      storeNo: addressSearchDetails.storeDetails.storeNo,
    }
    dispatch(rootActions.addSearchAddress(addressLine))
    dispatch(rootActions.saveSearchState(autoComplete.input))
  }, [addressForPulse, addressSearchDetails.storeDetails])

  // Step 3
  useEffect(() => {
    if (!searchAddress || !addressSearchDetails.storeDetails) return

    if (!addressSearchDetails.storeDetails?.errorCode) {
      reportAutoComplete.reportCompleteCanDeliver(autoComplete.input)

      if (autoCompleteDeliveryAddressFieldsHidden || autoCompleteDisplayAdditionalFieldsCheckout) {
        navigateTimedOrder(searchAddress)
      } else {
        navigate(NavigationConstants.confirmAddress)
      }
    }
  }, [searchAddress, addressSearchDetails.storeDetails])

  useEffect(() => {
    if (errorCode) {
      setDisplayAddressSearch(true)
      setSelectedAddress(undefined)
      setAddressValidationResult({ isValid: true })
    }
  }, [errorCode])

  useEffect(() => {
    if (selectedAddress && searchMode === 'Map') {
      setDisplayAddressSearch(false)
    }
  }, [selectedAddress])

  const mapDetectedGeolocation = useMemo(() => {
    if (!autoComplete.input && detectedLocation) return detectedLocation

    return undefined
  }, [autoComplete.input, detectedLocation])

  const handleSearchChange = async (value: string | undefined, isValid: boolean) => {
    if (errorCode) {
      addressSearchDetails.reset()
      autoComplete.resetError()
      setFormError(undefined)
    }

    await autoComplete.getPredictions(value, isValid)
  }

  const handleSelectAddress = async (address: AddressLine) => {
    setSearchIsCompleted(true)
    if (searchMode === 'List') {
      await autoComplete.getAddress(address)
    } else {
      await autoComplete.getAddress(address, false)
    }
  }

  const handleLocationMarkerChange = async (location: GeoLocationInterface) => {
    if (errorCode) {
      addressSearchDetails.reset()
      autoComplete.resetError()
    }

    const addressCoordinates: AddressLine = {
      geo: {
        latitude: location.latitude,
        longitude: location.longitude,
      },
      name: '',
      uid: 0,
    }

    setDeliveryCoordinates(location)
    const address = await autoComplete.getAddress(addressCoordinates, false)
    reportAutoComplete.reportPinMove(
      { latitude: location.latitude, longitude: location.longitude },
      address ? convertDeliveryAddressRequestToIAddress(address, countryCode) : undefined,
    )
  }

  const handleConfirmAddressClick = async () => {
    if (!selectedAddress) return
    let addressRequest = selectedAddress

    if (autoCompleteDeliveryAddressUserManualAddressEntry && correctedFields) {
      const correctedAddress = await correctedAddressHook.getCorrectedAddress(
        correctedFields,
        selectedAddress,
        addressValidationResult,
      )

      if (!correctedAddress) {
        setFormError(ERROR_USER_ADDRESS_MANUAL_CORRECTION_FAILED)

        return
      }

      addressRequest = correctedAddress
    }

    if (autoComplete.validateDeliveryAddressRequest(addressRequest)) {
      setAddressForPulse(addressRequest)
      reportAutoComplete.reportConfirmAddressMaps(convertDeliveryAddressRequestToIAddress(addressRequest, countryCode))
    }
  }

  const handleClearAddress = () => {
    setDisplayAddressSearch(true)
    setSelectedAddress(undefined)
    setSearchIsCompleted(false)
    setAddressValidationResult({ isValid: true })
    setFormError(undefined)
    autoComplete.reset(undefined)
  }

  const handleAddressCorrection = async (fields: AdditionalFields) => {
    setCorrectedFields({ ...correctedFields, ...fields })
  }

  const shouldDisplayAddressSearch = displayAddressSearch && addressValidationResult.isValid

  return (
    <div data-testid={testID} className={css.deliveryAddressMapViewWrapper}>
      {searchMode === 'Map' && (
        <MapProviderContainer
          testID={`${testID}.map`}
          deliveryLocation={deliveryCoordinates}
          detectedLocation={mapDetectedGeolocation}
          locationDetectionPermitted={locationDetectionPermitted}
          onMarkerDrop={handleLocationMarkerChange}
        />
      )}
      {shouldDisplayAddressSearch && (
        <div className={css.addressSearchContainer}>
          <DeliveryAddressSearch
            testID={`${testID}.delivery-address-search`}
            title={t('AddressSearchTitle')}
            placeholder={t('AddressSearchPlaceholder')}
            detectedLocation={detectedLocation}
            onTitleTriggerClick={() => onMapStateChange()}
            searchMode={searchMode}
            input={autoComplete.input}
            predictions={autoComplete.predictions}
            isLoading={isLoading}
            isError={!!errorCode}
            onSearchChange={handleSearchChange}
            onSelectAddress={handleSelectAddress}
            onResetStoreDetails={addressSearchDetails.reset}
          />
        </div>
      )}
      <ConfirmAddressContainer
        testID={testID}
        address={selectedAddress}
        errorCode={errorCode}
        isLoading={isLoading}
        searchMode={searchMode}
        addressValidationResult={addressValidationResult}
        onAddressConfirm={handleConfirmAddressClick}
        onAddressClear={handleClearAddress}
        onAddressCorrection={handleAddressCorrection}
      />
    </div>
  )
}
