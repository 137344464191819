import { CountryToggle } from '@dominos/components'
import { useState } from 'react'

export const useTermsAndConditionsInitialState = (): [boolean, (valid: boolean) => void] => {
  const getInitialState = CountryToggle({
    AU: () => true,
    BE: () => false,
    DE: () => true,
    DK: () => true,
    FR: () => false,
    JP: () => true,
    LU: () => true,
    NL: () => true,
    NZ: () => true,
    SG: () => true,
    MY: () => true,
    TW: () => true,
    KH: () => true,
    default: () => false,
  })

  const [isValid, setIsValid] = useState<boolean>(getInitialState())

  const handleTermsAndConditionsChange = (isValid: boolean) => setIsValid(isValid)

  return [isValid, handleTermsAndConditionsChange]
}
