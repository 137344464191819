import { getCountryConfig } from '@dominos/business/functions/common'
import { PaymentSetting } from '@dominos/hooks-and-hocs'
import { getAdyenPaymentMethods } from './get-adyen-payment-methods'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './payment-adyen.less'

const config = getCountryConfig()

interface SavedCreditCardProps {
  testID: string
  idx: number
  selected: boolean
  paymentSetting: PaymentSetting
  onPress: (paymentSetting: PaymentSetting) => void
}

export const PaymentSavedCreditCard = ({ testID, idx, selected, paymentSetting, onPress }: SavedCreditCardProps) => {
  const { t } = useTranslation('checkout')
  const adyenPaymentMethod = getAdyenPaymentMethods(paymentSetting)[0]
  const handleClick = () => {
    onPress(paymentSetting)
  }

  return (
    <div
      data-testid={testID}
      className={[css['adyen-saved-payment-container'], css.selectable, selected ? css.selected : ''].join(' ')}
      role='button'
      onClick={handleClick}
      tabIndex={idx}
    >
      <img className={css['brand-icon']} src={`${config.ADYEN_LOGO_URL}${adyenPaymentMethod.brand}.svg`} />
      <div className={css['adyen-saved-card-wrapper']}>
        <div className={css['adyen-saved-card']}>
          {idx === 0
            ? t('DefaultCard', { defaultValue: 'DEFAULT CARD' })
            : t(`Saved${adyenPaymentMethod.brand}`, {
                defaultValue: `SAVED ${adyenPaymentMethod.name?.toUpperCase()}`,
              })}
        </div>
        <div className={css['adyen-saved-card-digits']}>{`XXXX-${adyenPaymentMethod.lastFour}`}</div>
      </div>
    </div>
  )
}
