import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { isNativeApp } from '@dominos/business/functions/native-app'

export const useMenuStyles = (hasChildren: boolean) => {
  const theme = useDominosTheme()

  return {
    card: {
      backgroundColor: theme.colours.defaultBackground,
      borderRadius: theme.webBorderRadius,
      boxShadow: theme.defaultShadow,
      cursor: isNativeApp() ? 'none' : 'pointer',
    },
    cardContent: {
      paddingBottom: hasChildren ? '0' : '10px',
    },
    image: {
      borderTopLeftRadius: theme.webBorderRadius,
      borderTopRightRadius: theme.webBorderRadius,
    },
  }
}
