import { ErrorDefinitionsType } from '../interfaces'
import { getDefaultValue } from '../helpers'
import { genericErrors, networkErrors } from './'

export const paymentBalanceErrors: ErrorDefinitionsType = {
  INVALID_OR_EXPIRED_TOKEN: {
    translation: {
      message: {
        key: 'PaymentBalanceTokenExpiredOrInvalidMessage',
        getOptions: () => getDefaultValue('Your login session has expired. Please try to login again.'),
      },
    },
    displayType: 'boxed-text',
    icon: 'exclamation',
    testID: 'payment-balance-token-error',
    id: 'payment-balance-error',
  },
  FAILED_TO_GET_PAYMENT_BALANCE: {
    translation: {
      message: {
        key: 'PaymentBalanceGenericErrorMessage',
        getOptions: () => getDefaultValue('Sorry, there was a problem getting your balance. Please try again later.'),
      },
    },
    displayType: 'boxed-text',
    icon: 'exclamation',
    testID: 'payment-balance-generic-error',
    id: 'payment-balance-error',
  },
  PAYMENT_PROVIDER_ERROR: {
    translation: {
      message: {
        key: 'PaymentBalanceProviderErrorMessage',
        getOptions: () => getDefaultValue('Sorry, something went wrong. Please try again later.'),
      },
    },
    displayType: 'boxed-text',
    icon: 'exclamation',
    testID: 'payment-balance-provider-error',
    id: 'payment-balance-error',
  },
  BAD_USER_INPUT: {
    ...genericErrors.UNKNOWN_ERROR!,
    displayType: 'boxed-text',
    icon: 'exclamation',
    id: 'payment-balance-error',
  },
  UNKNOWN_ERROR: {
    ...genericErrors.UNKNOWN_ERROR!,
    displayType: 'boxed-text',
    icon: 'exclamation',
    id: 'payment-balance-error',
  },
  UNABLE_TO_RESOLVE: {
    ...genericErrors.UNABLE_TO_RESOLVE!,
    translation: {
      message: {
        key: 'PaymentBalanceGenericErrorMessage',
        getOptions: () => getDefaultValue('Sorry, there was a problem getting your balance. Please try again later.'),
      },
    },
    displayType: 'boxed-text',
    icon: 'exclamation',
    id: 'payment-balance-error',
  },
  NETWORK_ERROR: {
    ...networkErrors.NETWORK_ERROR!,
    displayType: 'boxed-text',
    icon: 'exclamation',
    id: 'payment-balance-error',
  },
}
