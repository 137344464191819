import { CollapsableTitledCard } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useGPSTracker } from '../../use-gps-tracker'
import { getWhensoGpsTrackerUrl } from './get-whenso-gps-tracker-url'
import styles from './whenso-gps.less'
import { OrderStatus } from '@dominos/hooks-and-hocs/order/order-types'

interface WhensoGPSProps {
  id?: string
  order: Bff.Orders.Order
  url: string
}
const START_TRACKING_STATUSES: OrderStatus[] = [OrderStatus.Leaving]
const STOP_TRACKING_STATUS = OrderStatus.Complete

const WhensoGPS = ({ id, order, url }: WhensoGPSProps) => {
  const { t } = useTranslation('tracker')
  const displayGPS = url !== 'null'
  const { startTrack, orderCompleted } = useGPSTracker(
    displayGPS,
    order.id,
    START_TRACKING_STATUSES,
    STOP_TRACKING_STATUS,
  )

  const GPSTrackerUrl = getWhensoGpsTrackerUrl(
    url,
    order.store?.storeNo!,
    order!.details!.header!.pulseId!,
    order.details!.header.orderTime!,
  )

  if (!displayGPS || !GPSTrackerUrl || !startTrack || orderCompleted) {
    return null
  }

  return (
    <CollapsableTitledCard
      testID={'whenso-gps'}
      primaryTitle={t('GPSDeliveryTrackerTitle', { defaultValue: 'Delivery Tracker' })}
      noPadding
      style={{ marginBottom: '8px', overflow: 'hidden' }}
    >
      <div id={id} className={styles.iframeWrapper}>
        <iframe src={GPSTrackerUrl} data-testid='whenso-gps-iframe' />
      </div>
    </CollapsableTitledCard>
  )
}

export { WhensoGPS }
