import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const SaladIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4a4a4a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 32 32'>
      <defs>
        <polygon
          id='path-1'
          points='-2.22044605e-16 -3.55271368e-15 8 -3.55271368e-15 8 9.88841115 -2.22044605e-16 9.88841115'
        />
      </defs>
      <g id='Salad' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-10' transform='translate(0.000000, 3.000000)'>
          <g id='Group-3' transform='translate(1.997600, 0.000000)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-2' />
            <path
              d='M-2.22044605e-16,0.8903 C-2.22044605e-16,0.3543 0.474,-0.0597 1.005,0.0063 C4.949,0.5013 8,3.8663 8,7.9443 L8,8.9973 C8,9.5333 7.526,9.9483 6.995,9.8813 C3.051,9.3873 -2.22044605e-16,6.0213 -2.22044605e-16,1.9443 L-2.22044605e-16,0.8903 Z'
              id='Fill-1'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <path
            d='M28.9253,9.944 C29.3883,7.326 27.6423,4.828 25.0243,4.365 C24.5533,4.281 24.0723,4.269 23.5973,4.327 C21.3393,1.544 17.2513,1.119 14.4683,3.378 C12.5243,4.955 11.6613,7.511 12.2503,9.944 L28.9253,9.944 Z'
            id='Fill-4'
            fill={fill}
          />
          <path
            d='M32,12.9987 C32,12.4157 31.528,11.9437 30.945,11.9437 L1.055,11.9437 C0.472,11.9437 0,12.4157 0,12.9987 L0,13.0407 C0,13.5537 0.416,13.9687 0.928,13.9687 L31.057,13.9687 C31.578,13.9687 32,13.5467 32,13.0257 L32,12.9987 Z'
            id='Fill-6'
            fill={fill}
          />
          <path
            d='M1,15.9689 L1,17.9909 C1,22.3829 4.561,25.9439 8.953,25.9439 L23.047,25.9439 C27.439,25.9439 31,22.3839 31,17.9909 L31,16.9549 C31,16.4099 30.559,15.9689 30.014,15.9689 L1,15.9689 Z'
            id='Fill-8'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}
