import { DimensionSetState, ProductData, useMarket } from '@dominos/hooks-and-hocs'
import { useMemo } from 'react'
export const useSizeData = (productData: ProductData | undefined, dimensionSetState: DimensionSetState | undefined) => {
  const { getMediaForDimension } = useMarket()

  return useMemo(
    () => ({
      items:
        productData?.getDimensionValues('Size').map((size: string) => ({
          code: size,
          name: getMediaForDimension('Size', size)?.name?.value ?? '',
          price: productData?.getPriceDifferenceForDimensionChange(
            dimensionSetState?.selectedDimensionSet,
            'Size',
            size,
          ),
        })) ?? [],
      selectedItemCode: dimensionSetState?.getSelectedDimension('Size'),
    }),
    [dimensionSetState?.selectedDimensionSet],
  )
}
