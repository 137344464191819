import React from 'react'
import { DominosTheme, useDominosTheme } from '@dominos/hooks-and-hocs'
import { ActionButton } from '../../buttons'

export interface ToolTipNotificationProps extends BaseProps {
  heading: string
  buttonLabel: string
  message?: string
  onClose?: () => void
  image?: Image
  toolTipStyle?: ToolTipNotificationStyle
}

export type arrowPosition = 'left' | 'center' | 'right'

export interface Image {
  src: string
  alt: string
}

export interface ToolTipNotificationStyle {
  positionOffsetTop?: string
  arrowPosition?: arrowPosition
  toolTipContainerWidth?: string
  backgroundColor?: string
  textColor?: string
  buttonBackgroundColour?: string
  buttonTextColour?: string
}

export const ToolTipNotification = ({
  testID,
  heading,
  buttonLabel,
  message,
  onClose,
  image,
  toolTipStyle,
}: ToolTipNotificationProps) => {
  const theme = useDominosTheme()
  const [showToolTip, setShowToolTip] = React.useState<boolean>(true)
  const style = styleHelper(theme, toolTipStyle)

  const onCloseEvent = () => {
    if (onClose) onClose()
    setShowToolTip(false)
  }

  if (!showToolTip) return <></>

  return (
    <div role='dialog' data-testid={`${testID}.tool-tip-notification.container`} style={style.toolTipRelativePosition}>
      <div style={style.toolTipWrapper}>
        <div style={style.arrowContainer}>
          <div style={style.arrow} />
        </div>
        <div style={style.contentWrapper}>
          {!!image && <img alt={image.alt} src={image.src} style={style.image} />}
          <div style={style.message}>
            <h2 data-testid={`${testID}.tool-tip-notification.heading`} style={style.messageHeading} role='alert'>
              {heading}
            </h2>
            {!!message && (
              <div data-testid={`${testID}.tool-tip-notification.message`} style={style.messageText}>
                {message}
              </div>
            )}
          </div>
          <div style={style.closeButtonContainer}>
            <ActionButton
              testID={`${testID}.tool-tip-notification.close-button`}
              text={buttonLabel}
              onPress={onCloseEvent}
              containerStyle={style.closeButton}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

interface Style {
  toolTipRelativePosition: React.CSSProperties
  toolTipWrapper: React.CSSProperties
  contentWrapper: React.CSSProperties
  image: React.CSSProperties
  arrow: React.CSSProperties
  arrowContainer: React.CSSProperties
  message: React.CSSProperties
  messageHeading: React.CSSProperties
  messageText: React.CSSProperties
  closeButtonContainer: React.CSSProperties
  closeButton: React.CSSProperties
}

const styleHelper = (theme: DominosTheme, overrideStyle?: ToolTipNotificationStyle): Style => {
  const defaultTextColour = '#ffffff'
  const defaultButtonColor = '#3395ff'
  const defaultBackGroundColor = theme.colours.actionBlue

  return {
    toolTipRelativePosition: {
      position: 'relative',
    },
    toolTipWrapper: {
      position: 'absolute',
      top: overrideStyle?.positionOffsetTop ?? '0px',
      width: overrideStyle?.toolTipContainerWidth ?? '450px',
      zIndex: 2147483647,
    },
    contentWrapper: {
      backgroundColor: overrideStyle?.backgroundColor ?? defaultBackGroundColor,
      color: overrideStyle?.textColor ?? defaultTextColour,
      borderRadius: '15px',
      boxShadow: '0 1px 5px 0 rgba(137, 137, 137, 0.26)',
      padding: '15px',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    arrow: {
      borderBottomColor: overrideStyle?.backgroundColor ?? defaultBackGroundColor,
      display: 'block',
      width: 0,
      height: 0,
      borderWidth: '0 15px 15px 15px',
      borderStyle: 'none solid solid solid',
      borderLeftColor: 'transparent',
      borderRightColor: 'transparent',
      borderRadius: '4px',
    },
    arrowContainer: {
      display: 'flex',
      justifyContent: overrideStyle?.arrowPosition ?? 'center',
      backgroundColor: 'transparent',
      height: '12px',
      margin: '0 20px 0 20px',
    },
    message: {
      width: '100%',
      alignSelf: 'center',
    },
    messageHeading: {
      margin: '5px 0 0 0',
      fontSize: '1.2em',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.29',
      letterSpacing: '-0.12px',
      textAlign: 'left',
    },
    messageText: {
      padding: '8px 0 8px 0',
      fontSize: '0.9em',
    },
    closeButton: {
      backgroundColor: overrideStyle?.buttonBackgroundColour ?? defaultButtonColor,
      color: overrideStyle?.buttonTextColour ?? defaultTextColour,
      fontWeight: 'bold',
    },
    closeButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '5px',
    },
  }
}
