export const createStoreDistances = (
  store: Bff.Stores.Store,
  storeAlternatives: Bff.Stores.StoresAlternativeDistanceResponse,
): Bff.Stores.StoreDistance[] =>
  storeAlternatives.alternativeStoreDistances?.length
    ? [
        storeToStoreDistance(store),
        ...storeAlternatives.alternativeStoreDistances.sort((s1, s2) => s1.distanceInMeters - s2.distanceInMeters),
      ]
    : []

const storeToStoreDistance = (store: Bff.Stores.Store): Bff.Stores.StoreDistance => ({
  storeNo: store.storeNo,
  storeName: store.media?.name,
  distanceInMeters: 0,
  timeInSeconds: 0,
})
