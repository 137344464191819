/**
 * Calculates the total upgrade amount for an order based on the basket data.
 * @param basketData - The basket data containing the order details.
 * @returns The total upgrade amount for the order.
 */
export const calculateOrderUpgradeTotal = (basketData: Basket | Bff.Orders.OrderDetails.Basket): number => {
  if (!basketData.total) {
    return 0
  }

  let upgradeAmount = 0

  for (const line of basketData.lines) {
    if (line.type === 'BasketLine' || line.type === 'ProductLine') {
      upgradeAmount += line.surcharges?.reduce((sum, surcharge) => sum + surcharge.price, 0) ?? 0
    } else if (line.type === 'BasketCoupon') {
      for (const item of line.items) {
        for (const couponItemLine of item.lines ?? []) {
          upgradeAmount += couponItemLine.surcharges?.reduce((sum, surcharge) => sum + surcharge.price, 0) ?? 0
        }
      }
    } else if (line.type === 'CouponLine') {
      for (const item of line.items) {
        upgradeAmount += item.surcharges?.reduce((sum, surcharge) => sum + surcharge.price, 0) ?? 0
      }
    }
  }

  return upgradeAmount
}
