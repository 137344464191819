import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AdditionalField } from '../additional-field'

export const AdditionalFieldsLayoutFr = ({ testID, address, onInit, onChange }: AdditionalFieldsLayoutProps) => {
  const { t } = useTranslation('delivery-address')

  useEffect(() => {
    if (onInit)
      onInit({
        unitNo: {
          isValid: true,
        },
        buildingName: {
          isValid: true,
        },
        entryCode: {
          isValid: true,
        },
      })
  }, [])

  return (
    <>
      <AdditionalField
        testID={testID}
        fieldName={'unitNo'}
        placeholder={t('Unit Number', { defaultValue: 'Unit Number' })}
        onChange={onChange}
        validationRules={undefined}
        address={address}
      />
      <AdditionalField
        testID={testID}
        fieldName={'entryCode'}
        placeholder={t('Entry Code', { defaultValue: 'Entry Code' })}
        onChange={onChange}
        validationRules={undefined}
        address={address}
      />
      <AdditionalField
        testID={testID}
        fieldName={'buildingName'}
        placeholder={t('BuildingNameFieldPlaceholder', { defaultValue: 'Building Name' })}
        onChange={onChange}
        validationRules={undefined}
        address={address}
      />
    </>
  )
}
