import { useValidation, useValidationJP } from '@dominos/business/functions/validation'
import { ActionButton, GenericCard, MDTargetBlankAnchor, ValidationTextField } from '@dominos/components'
import { RouteComponentProps } from '@reach/router'
import Markdown from 'markdown-to-jsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './search-container.less'

interface SearchFormFields {
  PostCode: ValidationField
}

interface SearchContainerProps extends RouteComponentProps {
  onSearch: (searchInput: ValidationField) => void
  loading: boolean
}

export const SearchContainer = (props: SearchContainerProps) => {
  const [searchInput, setSearchInput] = useState<ValidationField>()
  const { t } = useTranslation('delivery-address')
  const { isRequired } = useValidation()
  const { validatePostalCode } = useValidationJP()

  const onChange = (updatedFields: Partial<SearchFormFields>) => {
    setSearchInput(updatedFields.PostCode)
  }

  const onSearchClicked = () => {
    if (!!searchInput) {
      props.onSearch(searchInput)
    }
  }

  return (
    <GenericCard
      testID='search-container.card'
      title={t('PostCodeSearchTitle', { defaultValue: 'Search by Postal Code' })}
    >
      <ValidationTextField
        testID={`postCode.field`}
        fieldName={'PostCode'}
        placeholder={t('PostalCodeFieldPlaceholder', { default: 'Postal Code' })}
        validationRules={[isRequired, validatePostalCode]}
        style={{ width: '100%', marginTop: '8px' }}
        onChange={onChange}
      />
      <ActionButton
        testID='search-postcode.button'
        onPress={onSearchClicked}
        text={t('SearchPostCodeButton', { default: 'Search' })}
        containerStyle={{ width: '100%', marginTop: '8px' }}
        disabled={!(searchInput && searchInput.isValid)}
        loading={props.loading}
      />
      <Markdown className={styles.searchFooter} options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
        {t('PostCodeFooter', {
          defaultValue:
            'So we can find your nearest store, enter your post code above. Your full delivery address details will be added later.',
        })}
      </Markdown>
    </GenericCard>
  )
}
