import { Locale } from '@dominos/business/functions/common/get-config'

export const getDefaultLanguageFromDefaultLocale = (
  countryLanguages: string[],
  defaultLocale: Locale,
): BffContext.Languages => {
  const defaultLanguage = defaultLocale && defaultLocale.split('-')[0]

  if (countryLanguages.includes(defaultLanguage)) {
    return defaultLanguage as BffContext.Languages
  }

  return countryLanguages[0] as BffContext.Languages
}
