import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 50,
  height: 45,
}

const TrackerChefIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 457.7 377.1'>
      <path
        fill={fill}
        d={`M228.6,0c29.7,0,58.5,10.4,81.5,29.3c-21.9,13.2-39.5,32.4-50.7,55.3c14-14.4,31.1-25.5,49.9-32.6
	c5.9-2.1,11.9-3.5,18.1-4.4c5.5,0,10.9,0,16.2,0c56.6,0,105.1,32.6,113.5,80.8c4.9,43.9-18.6,86.2-58.5,105.2l13.1,74.3
	c-46.3-5.9-85.7-5.9-118.2,0C244.5,316.8,161,352,159,354c-1.3,1.3-16.7,8.6-46.2,21.8l-2.1,1.3l-13.3-75.3
	c8.2-5.9,16.6-11.6,24.2-16c26.8-13.8,55-24.5,84.2-31.8H201c-41.9,1.9-83,11.9-121.1,29.7c-38.9-7.8-69.6-37.6-78.5-76.2
	c-8.8-46.7,24.4-91.9,75.4-113c2.5,22.9,11,44.7,24.8,63.1c-6.4-19.3-8.6-39.8-6.3-60c0.4-3.3,1.1-6.5,1.9-9.7
	c14.8-46.1,54.9-79.5,103-85.6c8.5-1.6,17.1-2.4,25.7-2.3c0.8,0,1.7,0,2.5,0H228.6z`}
      />
    </svg>
  )
}

export { TrackerChefIcon }
