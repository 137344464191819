import { useCountryCode } from '@dominos/hooks-and-hocs'
import { addressRequirementMappingsByCountry } from './mappers/address-requirements-mapping-by-country'
import { AddressRequirementMappingConfig } from './mappers/address-requirements.interface'

import { AttributeValidationRule } from './mappers/address-requirements.interface'

export interface AddressValidationResult {
  isValid: boolean
  invalidFields?: AddressValidationError[]
}

export interface AddressValidationError {
  attribute: keyof CustomerAddressRequest
  rules: AttributeValidationRule[]
}

export const useValidateAddress = () => {
  const countryCode = useCountryCode() as BffContext.Countries

  const validateAddress = (address: DeliveryAddressRequest): AddressValidationResult => {
    const validationItems = addressRequirementMappingsByCountry[countryCode] as AddressRequirementMappingConfig[]

    const cleanAddressComponent = (address.addressComponents = address.addressComponents.filter(
      (addressComponent) => addressComponent.value !== '',
    ))

    const result = validationItems
      .filter(
        (validationItem) =>
          !cleanAddressComponent.some((addressComponent) => addressComponent.type === validationItem.attribute),
      )
      .map((x) => ({ attribute: x.attribute, rules: x.validationRule }))

    if (result.length === 0) {
      return { isValid: true }
    }

    return { isValid: false, invalidFields: result }
  }

  return { validateAddress }
}
