import { useEffect, useRef } from 'react'
import { TIMEOUT_SECONDS, WINDOWS_EVENTS_TO_RESET_TIMEOUT } from '@dominos/components/kiosk/kiosk-inactivity-config'

export interface UseTimeoutProps {
  timeoutSeconds?: number
  windowEventsToResetTimeout?: string[]
  onTimeout: () => void
  startTimeout: boolean
}

const defaultTimeoutProps = {
  timeoutSeconds: TIMEOUT_SECONDS,
  windowEventsToResetTimeout: WINDOWS_EVENTS_TO_RESET_TIMEOUT,
}

export const useTimeout = (props: UseTimeoutProps) => {
  const { timeoutSeconds, windowEventsToResetTimeout, startTimeout } = {
    ...defaultTimeoutProps,
    ...props,
  }
  const timeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (startTimeout) setupTimeout()

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
        removeEventListeners()
      }
    }
  }, [startTimeout])

  const restartTimeout = (timeout: NodeJS.Timeout | null, seconds?: number, onTimeout?: () => void) => {
    if (timeout) {
      clearTimeout(timeout)
    }
    if (seconds && onTimeout) {
      timeout = setTimeout(onTimeout, 1000 * seconds)
    }

    return timeout
  }

  const resetTimeout = () => {
    timeout.current = restartTimeout(timeout.current, timeoutSeconds, onTimeout)
  }
  const onActivity = () => {
    resetTimeout()
  }

  const setupTimeout = () => {
    resetTimeout()
    addEventListeners()
  }

  const onTimeout = () => {
    props.onTimeout()
    removeEventListeners()
  }

  const addEventListeners = () => {
    windowEventsToResetTimeout.forEach((event) => {
      window.addEventListener(event, onActivity)
    })
  }

  const removeEventListeners = () => {
    windowEventsToResetTimeout.forEach((event) => {
      window.removeEventListener(event, onActivity)
    })
  }

  return { setupTimeout }
}
