import React from 'react'
import { useAuthentication, useFeatures } from '@dominos/hooks-and-hocs'
import { getNativeFeatures, isNativeApp } from '@dominos/business/functions/native-app'
import { TrackerCreateAccount } from './tracker-create-account'
import { NativeTrackerAuthPrompt } from './native-tracker-auth-prompt'
import { TrackerCreateAccountForgeRock } from './tracker-create-account-forgerock'

export const TrackerRegister = ({
  id,
  customer,
}: {
  id?: string
  customer?: Bff.Orders.OrderDetails.Header.Customer
}) => {
  const { isUserLoggedIn } = useAuthentication()
  const [universalAuthenticationTrackerEnabled, forgeRockIdentityProviderEnabled] = useFeatures(
    'UniversalAuthenticationTrackerQuickSignUp',
    'ForgeRockIdentityProvider',
  )

  if (isNativeApp()) {
    const showNativeAuthPromptQuac = getNativeFeatures('UniversalAuthenticationTrackerQuickSignUp') && !isUserLoggedIn
    if (showNativeAuthPromptQuac) {
      return <NativeTrackerAuthPrompt id={id} />
    }

    const showNativeAuthPromptForgeRock = getNativeFeatures('ForgeRockIdentityProvider') && !isUserLoggedIn
    if (showNativeAuthPromptForgeRock) {
      return <TrackerCreateAccountForgeRock id={id} />
    }

    return null
  }
  const showRegisterForgeRock = forgeRockIdentityProviderEnabled && !isUserLoggedIn
  if (showRegisterForgeRock) {
    return showRegisterForgeRock ? <TrackerCreateAccountForgeRock id={id} /> : null
  }

  const showRegisterQuac = universalAuthenticationTrackerEnabled && !isUserLoggedIn

  return showRegisterQuac ? <TrackerCreateAccount id={id} customer={customer} /> : null
}
