import {
  ActionButton,
  CountryToggle,
  DeliveryAddressAuLayout,
  DeliveryAddressBeLayout,
  DeliveryAddressDeLayout,
  DeliveryAddressDkLayout,
  DeliveryAddressFrLayout,
  DeliveryAddressLuLayout,
  DeliveryAddressMyLayout,
  DeliveryAddressNlLayout,
  DeliveryAddressNzLayout,
  GenericCard,
} from '@dominos/components'
import { useAlert, useBreakpoints, useCustomerAddressSearch } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

const isFormValid = (address: TAddress) => {
  // eslint-disable-next-line guard-for-in
  for (const fieldName in address) {
    const field = address[fieldName as keyof DeliveryAddressToValidate]

    if (field && !field.isValid) {
      return false
    }
  }

  return true
}

export const DeliveryAddressContainer = () => {
  const [address, setAddress] = useState<TAddress>({})
  const [errorDisplayed, setErrorDisplayed] = useState(false)
  const [initialised, setInitialised] = useState(false)
  const { isMobile } = useBreakpoints()
  const { t } = useTranslation('delivery-address')
  const { getAddress, data, loading, errors } = useCustomerAddressSearch()
  const { reportFallBackAddressSearchNoAddressFound, reportFallBackAddressSearchAddressSearched } =
    useReportAutoCompleteDelivery()

  const { showAlert } = useAlert()

  const onInitialise: DeliveryAddressOnInitialise = (initialState) => {
    if (!initialised) {
      setAddress(initialState)
      setInitialised(true)
    }
  }

  const onFindAddress = () => {
    setErrorDisplayed(false)
    const addressMapped = {
      unitNo: (address.unitNo && address.unitNo.value) || '',
      streetNo: (address.streetNo && address.streetNo.value) || '',
      streetName: (address.street && address.street.value) || '',
      suburb: (address.suburb && address.suburb.value) || '',
      postCode: (address.postCode && address.postCode.value) || '',
      state: (address.state && address.state.value) || '',
    }
    getAddress(addressMapped)
    reportFallBackAddressSearchAddressSearched(addressMapped)
  }

  const onChange: DeliveryAddressOnChange = (updatedFields: Partial<TAddress>) => {
    setAddress({
      ...address,
      ...updatedFields,
    })
  }

  useEffect(() => {
    if (data) {
      if (data.deliverySearch.length === 0) {
        reportFallBackAddressSearchNoAddressFound(address)
        if (!errorDisplayed) {
          showAlert(
            t('Empty Address Result Alert', {
              defaultValue:
                'We’re sorry, this delivery address is not recognised in our system. Please check the address you have entered and try searching again. You can also call us on 131 888 to place an order.',
            }),
            'Empty Address Result Alert',
          )
          setErrorDisplayed(true)
        }
      } else {
        navigate(NavigationConstants.addressSearchResults, {
          state: data,
        })
      }
    }

    if (errors && errors.length > 0 && !errorDisplayed) {
      showAlert(errors[0].message)
      setErrorDisplayed(true)
    }
  }, [data, errors])

  return (
    <>
      <GenericCard
        testID='delivery-address.Card'
        title={t('Delivery Address')}
        width={isMobile ? magicStyles.mobile.staticCardWidth : magicStyles.desktop.staticCardWidth}
      >
        <div style={{ marginTop: '14px' }}>
          <CountryToggle
            AU={<DeliveryAddressAuLayout onChange={onChange} onInitialise={onInitialise} />}
            BE={<DeliveryAddressBeLayout onChange={onChange} onInitialise={onInitialise} />}
            DE={<DeliveryAddressDeLayout onChange={onChange} onInitialise={onInitialise} />}
            DK={<DeliveryAddressDkLayout onChange={onChange} onInitialise={onInitialise} />}
            FR={<DeliveryAddressFrLayout onChange={onChange} onInitialise={onInitialise} />}
            LU={<DeliveryAddressLuLayout onChange={onChange} onInitialise={onInitialise} />}
            MY={<DeliveryAddressMyLayout onChange={onChange} onInitialise={onInitialise} />}
            NL={<DeliveryAddressNlLayout onChange={onChange} onInitialise={onInitialise} />}
            NZ={<DeliveryAddressNzLayout onChange={onChange} onInitialise={onInitialise} />}
          />
        </div>
      </GenericCard>
      <ActionButton
        testID='find-address-button'
        onPress={onFindAddress}
        text={t('Find Address')}
        containerStyle={{ width: '100%', marginTop: '8.6px' }}
        textFontWeight='semibold'
        disabled={!isFormValid(address)}
        loading={loading}
      />
    </>
  )
}

const magicStyles = {
  mobile: {
    staticCardWidth: '360px',
  },
  desktop: {
    staticCardWidth: '376px',
  },
}
