import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { getOrderId } from './helpers'
import { SecuritySender } from './security'

export const reportKiosk = (sendSecurity: SecuritySender, currentOrder?: CurrentOrderDetailsReducerProps) => {
  const reportKioskHealth = (
    url: string,
    component: string,
    healthStatus?: string,
    kioskId?: string,
    storeNo?: number,
  ) => {
    if (!kioskId) {
      return
    }

    const orderId = getOrderId(currentOrder)

    sendSecurity('Kiosk.Health', {
      ScreenName: url,
      ScreenClass: component,
      HealthStatus: healthStatus,
      'Order ID': orderId,
      KioskId: kioskId,
      StoreNo: storeNo,
    })
  }

  const reportResourceLoadingError = (
    file: string,
    status: number,
    reloadAttempted: boolean,
    kioskId?: string,
    storeNo?: number,
    countryCode?: string,
  ) => {
    sendSecurity('Kiosk.ResourceLoading', {
      FileName: file,
      Status: status,
      ReloadAttempted: reloadAttempted,
      KioskId: kioskId,
      StoreNo: storeNo,
      CountryCode: countryCode,
    })
  }

  return {
    reportKioskHealth,
    reportResourceLoadingError,
  }
}
