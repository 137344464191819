import {
  ProductTermsAndConditionsViewModel,
  ProductTermsAndConditionsViewModelProps,
} from './product-terms-and-conditions.interface'

export const createProductTermsAndConditionsViewModel = ({
  t,
  isFeatureEnabled,
}: ProductTermsAndConditionsViewModelProps): ProductTermsAndConditionsViewModel => {
  const title = t('TermsAndConditionsTitle', { defaultValue: 'Terms & Conditions' })
  const content = t('TermsAndConditionsBody', { defaultValue: '' })

  return {
    isEnabled: isFeatureEnabled,
    title,
    content,
  }
}
