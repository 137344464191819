import React, { useState } from 'react'

import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'

import styles from './collapsible-card.less'
import { CollapsibleCardProps } from './collapsible-card.interface'
import { AnimatePresence, motion } from 'framer-motion'

const CollapsibleCard = (props: CollapsibleCardProps) => {
  const [expanded, setExpanded] = useState(props.startExpanded ?? true)
  const onHeaderClick = () => {
    setExpanded(!expanded)

    if (props.onToggleChanged) {
      props.onToggleChanged(!expanded)
    }
  }

  const theme = useDominosTheme()

  const expandedStyle = { width: '100%', height: 'initial' }
  const collapsedStyle = { width: '100%', height: '0px' }

  return (
    <section
      className={expanded ? `${styles.section} ${styles.sectionExpanded}` : styles.section}
      style={{
        backgroundColor: theme.colours.defaultBackground,
        ...props.style,
      }}
      data-testid={`${props.testID || 'CollapsibleCard'}-container`}
    >
      <div onClick={props.disableClick ? undefined : onHeaderClick}>
        <div
          className={props.chevronPositionStyle ?? styles.chevron}
          data-testid={`${props.testID || 'CollapsibleCard'}-chevron`}
        >
          <StaticSvgIcon
            onClick={props.disableClick ? undefined : onHeaderClick}
            name={'chevron'}
            isUnstyled={true}
            fill={theme.colours.overlayColor}
            direction={expanded ? 'down' : 'right'}
          />
        </div>
        {props.noncollapsibleContent && <span>{props.noncollapsibleContent}</span>}
      </div>

      <AnimatePresence>
        <motion.div
          data-testid={`${props.testID || 'CollapsibleCard'}-motion-div`}
          className={styles.wrapperExpanded}
          key='card-wrapper'
          initial={expanded ? expandedStyle : collapsedStyle}
          animate={expanded ? expandedStyle : collapsedStyle}
          exit={expanded ? expandedStyle : collapsedStyle}
        >
          {props.collapsibleContent && <span>{props.collapsibleContent}</span>}
        </motion.div>
      </AnimatePresence>
    </section>
  )
}

export { CollapsibleCard }
