import React from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { calcWebFontWeight, getFormattedPrice } from '@dominos/business/functions/text/text-functions'
import { useTranslation } from 'react-i18next'
import { QuestionIcon } from '@dominos/res/images/icons/components'

import css from './detail-row.less'

/**
 * Renders a single detail row of a basket line item.
 * Contains a primary label with optional trailing description,
 * and optional price label
 */
export const DetailRow = ({
  testID,
  label,
  description,
  price,
  textStyle,
  fontWeight,
  noLabel,
  popup,
  isPriceStrikethrough,
  amountPrefix,
  onPopupClicked,
}: DetailRowProps) => {
  const {
    colours: { actionAlternative },
  } = useDominosTheme()

  const { t } = useTranslation('basket')

  return (
    <div data-testid={testID} className={css.container} style={textStyle}>
      <div data-testid={`${testID}.details`} className={css.details}>
        {!noLabel && (
          <div
            data-testid={`${testID}.label`}
            className={css.label}
            style={{ fontWeight: calcWebFontWeight(fontWeight || 'bold') }}
          >
            {label}
          </div>
        )}
        {description && (
          <div data-testid={`${testID}.description`} className={css.description}>
            {description}
          </div>
        )}
        {popup && (
          <div
            data-testid={`${testID}.popupButton`}
            className={css.popupButton}
            style={{ color: actionAlternative }}
            onClick={onPopupClicked}
            title={t('whatIsThis', { defaultValue: "What's this?" })}
          >
            <QuestionIcon width={22} height={22} />
          </div>
        )}
      </div>
      {'number' === typeof price && (
        <div
          data-testid={`${testID}.price`}
          className={css.price}
          style={{
            fontWeight: calcWebFontWeight(fontWeight || 'bold'),
            textDecoration: isPriceStrikethrough ? 'line-through' : 'none',
          }}
        >
          {`${amountPrefix ? amountPrefix : ''}${getFormattedPrice(price)}`}
        </div>
      )}
    </div>
  )
}
