import { LayoutItem } from 'simple-keyboard-layouts/build/interfaces'

/**
 * Layout Items: Japanese Katakana
 */
export default {
  layout: {
    default: [
      '1 2 3 4 5 6 7 8 9 0 \u30DA \u30FF \u30FC \u30DB {bksp}',
      '\u30A1 \u30A3 \u30A5 \u30A7 \u30A9 \u30AB \u30C3 \u30E3 \u30E5 \u30E7 \u30EE \u30F6 \u30F8 \u30F9 \u30F4 \u30F5 \u30FA',
      '{tab} \u30BF \u30C6 \u30B9 \u30F3 \u30CA \u30D9 \u30BB \u30AF \u30C1 \u30BD \u30A0 \u3099 \u30FB',
      '{lock} \u30C8 \u30B7 \u30CF \u30AD \u30DE \u30CE \u30EA \u30EC \u30ED \u30F0 \u30E9 \u30FD \u30FE {enter}',
      '{shift} \u30C4 \u30B5 \u30D2 \u30B3 \u30Df \u30E2 \u30CD \u30EB \u30E1 \u30E0 \u30D7 {shift}',
      '.com @ {space}',
    ],
    shift: [
      '! " # $ % & \' ( ) \u30B1 = ~ | {bksp}',
      '\u30A2 \u30A4 \u30A6 \u30A8 \u30AA \u30AC \u30C4 \u30E4 \u30E6 \u30E8 \u30EF \u30F7 \u30DD \u30B2 \u30C5 \u30CC \u30D1',
      '{tab} \u30C0 \u30C7 \u30BA \u30F2 \u30CB \u30D8 \u30BC \u30B0 \u30C2 \u30BE \u003D \u3E40 \u30D5',
      '{lock} \u30C9 \u30B8 \u30D0 \u30AE \u304f \u307e \u306e \u308a \u308c \u3051 \u30F1 \u30D6 \u30DC {enter}',
      '{shift} \u3063 \u30B6 \u30D3 \u3055 \u30B4 \u305d \u3072 \u3053 \u307f \u3082 \u30D4 {shift}',
      '.com @ {space}',
    ],
  },
} as LayoutItem
