import { GeoLocationInterface, GeoServiceInterface } from '@dominos/interfaces'

const checkLocationPermission = async () => {
  if (navigator.permissions) {
    const result = await navigator.permissions.query({ name: 'geolocation' })

    return result.state === 'granted' || result.state === 'prompt'
  }

  return undefined
}

const requestLocationPermission = async () => {
  let result = false
  await getLocationLatest().then(
    () => (result = true),
    () => (result = false),
  )

  return result
}

const getLocationLatest = async (): Promise<GeoLocationInterface | null> => {
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude,
        })
      }, reject)
    })
  }

  return Promise.reject()
}

export const GeoService: GeoServiceInterface = {
  checkLocationPermission,
  requestLocationPermission,
  getLocationLatest,
}
