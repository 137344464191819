import { FakeStore } from '@dominos/components'

export const getDistance = (lat1: number, lng1: number, lat2: number, lng2: number): number => {
  const lat = lat2 - lat1
  const lng = lng2 - lng1

  return Math.sqrt(lat * lat + lng * lng)
}

export const sortClosestStoreByDistanceAndReturnedClosest3Coordinates = (
  origin: FakeStore,
  stores: FakeStore[],
): google.maps.LatLngLiteral[] =>
  stores
    .sort((a, b) => {
      const { lat: lat_a, lng: lng_a } = a
      const { lat: lat_b, lng: lng_b } = b
      const distanceA = getDistance(origin.lat, origin.lng, lat_a, lng_a)
      const distanceB = getDistance(origin.lat, origin.lng, lat_b, lng_b)

      return distanceA - distanceB
    })
    .slice(0, 3)
    .map((store) => ({ lat: store.lat, lng: store.lng }))

export const calculateDistanceInMetres = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const earthRadius = 6371e3 // Earth radius in meters
  const phi1 = (lat1 * Math.PI) / 180 // Latitude 1 in radians
  const phi2 = (lat2 * Math.PI) / 180 // Latitude 2 in radians
  const deltaPhi = ((lat2 - lat1) * Math.PI) / 180 // Difference in latitudes in radians
  const deltaLambda = ((lon2 - lon1) * Math.PI) / 180 // Difference in longitudes in radians

  const a =
    Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
    Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  return earthRadius * c
}

const CLOSEST_DISTANCE_M = 40

export const findClosestGeocoderLocation = (
  lat: number,
  lng: number,
  geocoderResults: google.maps.GeocoderResult[],
  deliveryAddressSearchMatchRadius: number = CLOSEST_DISTANCE_M,
): google.maps.GeocoderResult | null => {
  const nearbyLocations = geocoderResults
    .map((result) => ({
      result,
      distance: calculateDistanceInMetres(lat, lng, result.geometry.location.lat(), result.geometry.location.lng()),
    }))
    .filter(
      ({ distance, result }) => !result.types.includes('plus_code') && distance <= deliveryAddressSearchMatchRadius,
    )
    .sort((a, b) => a.distance - b.distance)

  return nearbyLocations.length > 0 ? nearbyLocations[0].result : null
}
