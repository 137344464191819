import React from 'react'
import { ReactElement } from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs/'
import { AdjustmentButton } from './adjustment-button'
import { IngredientAdjustmentButtonProps } from '../product-ingredient-item.interface'

export const DecrementButton = ({ onChange, testID, disabled }: IngredientAdjustmentButtonProps): ReactElement => {
  const theme = useDominosTheme()

  const styles = {
    downButton: { backgroundColor: theme.colours.lightSlate },
  }

  return (
    <AdjustmentButton
      testID={`${testID}.button`}
      role='decrement'
      adjustmentQuantity={-1}
      onChange={onChange}
      icon='minus'
      styles={styles.downButton}
      disabled={disabled}
    />
  )
}
