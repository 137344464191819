import { useLazyQuery } from '@apollo/client'
import { storeSearchQuery } from '@dominos/business/queries'
import { useDebounce } from '@dominos/hooks-and-hocs'

const DELAY_MS = 700

export const useStoreSearchQuery = () => {
  const [fetch, { data, error, loading }] = useLazyQuery(storeSearchQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const debouncedQuery = useDebounce({ fn: fetch, delay: DELAY_MS })

  return { debouncedQuery, data, error, loading }
}
