import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { getOrderId } from './helpers'
import { SecuritySender } from './security'

export const reportTracker = (sendSecurity: SecuritySender, currentOrder?: CurrentOrderDetailsReducerProps) => {
  const reportNotifyArrival = () => {
    const orderId = getOrderId(currentOrder)

    sendSecurity('Flow.Tracker.NotifyArrival', {
      'Order ID': orderId,
    })
  }

  return { reportNotifyArrival }
}
