import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

const Clock = (props: SvgIconProps) => {
  const fill = props.fill || '#454545'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 23 23'>
      <path
        fill={fill}
        fillRule='nonzero'
        stroke='none'
        strokeWidth='1'
        d='M24.438 39.036c5.975 0 10.9-4.933 10.9-10.899 0-5.966-4.934-10.9-10.908-10.9-5.966 0-10.883 4.934-10.883 10.9s4.925 10.9 10.891 10.9zm-5.536-9.668a.737.737 0 01-.744-.752c0-.413.322-.743.744-.743h4.792v-6.43c0-.412.323-.735.736-.735a.73.73 0 01.752.736v7.172a.74.74 0 01-.752.752h-5.528z'
        transform='translate(-13 -17)'
      />
    </svg>
  )
}

export { Clock }
