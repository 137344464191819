import React from 'react'
import { StrikeThroughPrice } from './partials/strike-through-price'
import { ProductPrice } from './partials/product-price'
import { EnergyKJUnit } from './partials/energy-kj-unit'
import { FromPriceLabel } from './partials/from-price-label'
import { useDisplayStrikeThroughPrice } from '@dominos/hooks-and-hocs'

import css from './product-pricing-details.less'

interface ProductPricingDetailsProps extends BaseProps {
  kj?: number | null
  price?: string | null
  salePrice?: string | null
  promoPrice?: string | null
  showPrice?: boolean
  showEnergyDividerForPromo?: boolean
}

export const ProductPricingDetails = ({
  testID,
  kj,
  price,
  salePrice,
  promoPrice,
  showPrice = true,
  showEnergyDividerForPromo = true,
}: ProductPricingDetailsProps) => {
  const shouldShowPrice = showPrice && !!price
  const { displayStrikeThroughPrice, alternatePrice } = useDisplayStrikeThroughPrice({
    price,
    salePrice,
    promoPrice,
  })

  const priceDisplay = (
    <ProductPrice
      key={`${testID}.price`}
      testID={`${testID}.price`}
      price={alternatePrice}
      variant={displayStrikeThroughPrice ? 'promo' : 'default'}
    />
  )

  const strikeThroughPriceDisplay =
    displayStrikeThroughPrice && !!price ? (
      <StrikeThroughPrice key={`${testID}.strike-through`} testID={`${testID}.strike-through`} price={price} />
    ) : null

  /**
   * Energy info divider (when feature is ON):
   *  Displayed:
   *    - on product-details-card (showEnergyDividerForPromo=true)
   *    - on menu-product-card, menu-card-default (showEnergyDividerForPromo=false)
   *      - when no salePrice or promoPrice (strikeThroughPriceDisplay=null)
   *  Hidden:
   *    - on menu-product-card, menu-card-default (showEnergyDividerForPromo=false)
   *      - when salePrice or promoPrice is displayed (strikeThroughPriceDisplay=React.ReactNode)
   */
  const showEnergyUnitDivider = showPrice && (showEnergyDividerForPromo || !strikeThroughPriceDisplay)

  return (
    <div className={css.productPricingDetails} data-testid={testID}>
      {shouldShowPrice && <FromPriceLabel testID={`${testID}.price.from`} />}
      {shouldShowPrice && [strikeThroughPriceDisplay, priceDisplay]}
      <EnergyKJUnit
        kj={kj}
        testID={`${testID}.price.kj`}
        divider={shouldShowPrice ? '|' : ''}
        showEnergyUnitDivider={showEnergyUnitDivider}
      />
    </div>
  )
}
