import { ApolloError, useLazyQuery } from '@apollo/client'
import { timedOrderSlotsQuery } from '@dominos/business/queries'
import { useState } from 'react'

interface TimeSlotResponse {
  orderingTimeSlots: StoreOrderTimeSlots
}

export const useTimedOrder = () => {
  const [openingHours, setOpeningHours] = useState<StoreOrderTimeSlots | undefined>(undefined)
  const [error, setError] = useState<ApolloError | undefined>()
  const [query, { loading }] = useLazyQuery<TimeSlotResponse | undefined>(timedOrderSlotsQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.orderingTimeSlots) {
        setOpeningHours(data.orderingTimeSlots)
      }
    },
    onError: (exception) => {
      setError(exception)
    },
  })

  const fetchOrderingTimeSlots = (storeNo: number, serviceMethod: string, locationId?: string) =>
    query({ variables: { storeNo, serviceMethod, locationId } })

  return {
    fetchOrderingTimeSlots,
    openingHours,
    loading,
    error,
  }
}
