import _ from 'lodash'
import { useContext } from 'react'
import { useApplicationSettings } from '../application/use-application-settings'
import { DominosThemeProps, ThemeContext } from './theme-context'
import countryThemeData from './theme-data/country-theme.json'
import languageThemeData from './theme-data/language-theme.json'
import { DominosTheme } from './theme-data/standard-theme'

/**
 * @deprecated Avoid using this hook, instead add what you need from JS theme files to LESS files in the themes folder
 * e.g. '/hooks-and-hocs/theme/theme-data/standard-theme.less' and then import and use it in your LESS files
 */
export const useDominosTheme = (): DominosTheme => {
  const settings = useApplicationSettings()
  const country = settings.countryCode || 'AU'
  const language = settings.language
  let outputTheme = useContext(ThemeContext).theme
  const countryTheme = countryThemeData as unknown as Dict<string>
  const languageTheme = languageThemeData as unknown as Dict<string>

  outputTheme = _.merge({}, outputTheme, countryTheme[country])
  outputTheme = _.merge({}, outputTheme, languageTheme[language])

  return outputTheme
}
export const useUpdatableDominosTheme = (): DominosThemeProps => useContext(ThemeContext)
