import { useSelector } from 'react-redux'
import { MyDetailsModelProps } from '@dominos/interfaces'
import { useNativeAppStoredData } from '@dominos/hooks-and-hocs'

export const useCustomerCheckoutDetails = (): MyDetailsModelProps => {
  const customerCheckoutDetails = useSelector(
    (store: RootReducer) => store.currentOrderDetailsReducer.customerDetailsData,
  )
  const { retrieveNativeAppCustomerDetails } = useNativeAppStoredData()

  if (customerCheckoutDetails) {
    return customerCheckoutDetails
  }

  const { customerDetails: nativeAppCustomerDetails } = retrieveNativeAppCustomerDetails()

  return nativeAppCustomerDetails ?? {}
}
