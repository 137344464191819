import { ErrorDefinitions } from '@dominos/interfaces'

const saveVoucherErrorDefinitions: ErrorDefinitions = {
  VOUCHER_ALREADY_SAVED: {
    translation: {
      key: 'VoucherAlreadySaved',
      options: {
        defaultValue: 'Sorry, this voucher has previously been saved.',
      },
    },
    display: 'popup',
  },
  VOUCHER_NOT_FOUND: {
    translation: {
      key: 'VoucherNotFound',
      options: {
        defaultValue: 'Sorry, this voucher could not be found.',
      },
    },
    display: 'popup',
  },
  UNABLE_TO_RESOLVE: {
    translation: {
      key: 'SaveVoucherGenericError',
      options: {
        defaultValue: 'Something has gone wrong. Please try again.',
      },
    },
    display: 'popup',
  },
}

export { saveVoucherErrorDefinitions }
