import { compareDimensionSets } from '@dominos/hooks-and-hocs/menu/functions'

type DimensionSet = Bff.Dimensions.DimensionSet

export const findEntityByDimensionSet = <TEntity, TDimensionSet extends DimensionSet>(
  dimensionalEntities: Array<Bff.Dimensions.Dimensional<TEntity, TDimensionSet>>,
  targetDimensionSet?: TDimensionSet,
) => {
  const exactMatch = dimensionalEntities.find((item) =>
    item.dimensionSets.some((dimensionSet) => compareDimensionSets(dimensionSet, targetDimensionSet)),
  )

  if (exactMatch) {
    return exactMatch.value
  }

  const defaultMatch = dimensionalEntities.find((item) => item.dimensionSets.length === 0)

  return defaultMatch?.value
}
