import { FosCurrentOrder } from 'olo-feature-fos'

export const createFosCurrentOrderMapper =
  ({ currentOrderDetailsReducer, basketReducer }: RootReducer) =>
  (): FosCurrentOrder => ({
    advanceOrderId: currentOrderDetailsReducer.advanceOrder.id,
    orderStartedAt: currentOrderDetailsReducer.advanceOrder.startedAt,
    deliveryInstructions: currentOrderDetailsReducer.customerDetailsData?.deliveryInstructions,
    displayAddress: currentOrderDetailsReducer.selectedDeliveryAddress?.displayAddress,
    paymentMethod: currentOrderDetailsReducer.paymentMethod?.paymentMethod,
    hasSelectedDeliveryAddress: !!currentOrderDetailsReducer.selectedDeliveryAddress,
    isBasketEmpty: basketReducer.basket.lines.length === 0,
    basketHeaderData: currentOrderDetailsReducer.basketHeaderData,
  })
