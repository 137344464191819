import { ClosablePopUpNotification } from '@dominos/components/notification'
import { RouteComponentProps } from '@reach/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useIdleTimer } from './use-idle-timer'

export const UserInactivityMonitor: React.FC<RouteComponentProps> = ({ children }) => {
  const { t } = useTranslation('login')
  const { showPopup, togglePopup } = useIdleTimer()

  return (
    <>
      {showPopup && (
        <ClosablePopUpNotification
          testID='session-expired'
          heading={t('SessionExpiredMessageHeading', { defaultValue: 'Your session has expired' })}
          message={t('SessionExpiredMessageText', {
            defaultValue: 'The items in your basket have been removed due to inactivity. Please start a new order.',
          })}
          onClose={togglePopup}
          icon='sessionExpiredIcon'
        />
      )}
      {children}
    </>
  )
}
