import { ApolloError } from '@apollo/client'
import { useReport } from '@dominos/hooks-and-hocs/logging'
import { ApolloErrorHandlers } from '../interfaces'

export const useNetworkErrorHandlers = (): Pick<ApolloErrorHandlers, 'networkErrorHandler'> => {
  const { reportGenericError } = useReport()

  const networkErrorHandler = (error: ApolloError) => ({
    handleErrorDisplayed: () => reportGenericError(error as unknown as Record<string, unknown>),
  })

  return {
    networkErrorHandler,
  }
}
