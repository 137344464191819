/**
 * Rounds the `value` to the desired number of decimal places,
 * as defined by the `precision` value.
 *
 * @param precision decimal places for output
 */
export const round = (value: number, decimals: number): number => {
  const valueFormatted = Number.parseFloat(`${value}e${decimals}`)

  return Number(`${Math.round(valueFormatted)}e-${decimals}`)
}
