import React from 'react'
import { Card } from '../cards'
import { mapNameToComponent, StateName } from '@dominos/hooks-and-hocs'
import css from './universal-auth.less'
import { ErrorNotifier } from '../error'

export const UniversalAuth = ({ testID, current }: BaseProps & { current: StateName }) => {
  const CurrentPage = mapNameToComponent[current]

  return (
    <ErrorNotifier namespace={'login'}>
      <Card testID={`${testID}.current`} className={css.popup}>
        <CurrentPage testID={testID} />
      </Card>
    </ErrorNotifier>
  )
}
