import { useMutation } from '@apollo/client'
import { rootActions } from '@dominos/business'
import { saveVoucher } from '@dominos/business/queries/save-voucher'
import { useAuthenticationPopup, useCustomer, useFeatures } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export interface HandleVoucherDeepLinkHandlerProps {
  voucherBox?: string
  voucherCode: string
}

export const useVoucherDeepLinkHandler = ({ setNotify }: { setNotify: (title: string) => void }) => {
  const { loading, isLoggedIn } = useCustomer()
  const dispatch = useDispatch()
  const [savedVoucher, setSaveVoucher] = useState<string | undefined>(undefined)
  const [savedVouchersFeatureToggle, customersFeatureToggle, universalAuthenticationRedirectToggle] = useFeatures(
    'saved-vouchers',
    'customers',
    'UniversalAuthenticationRedirectHome',
  )
  const [saveVoucherMutation, { data, error }] = useMutation(saveVoucher, {
    onError: () => {},
  })
  const { showPopup } = useAuthenticationPopup()

  const handle = ({ voucherBox = 'false', voucherCode }: HandleVoucherDeepLinkHandlerProps) => {
    const customerUsingVoucherBox = voucherBox?.toLowerCase() === 'true'
    if (voucherCode) {
      if (customerUsingVoucherBox) {
        if (savedVouchersFeatureToggle && customersFeatureToggle) {
          setSaveVoucher(voucherCode)
        }
      } else {
        dispatch(rootActions.storeDeepLinkCoupon(voucherCode))
        setNotify('DeepLinkVoucherAdded')
      }
    }
  }

  useEffect(() => {
    if (data?.saveVoucher?.success) {
      setNotify('DeepLinkVoucherSaved')
    }
  }, [data])

  useEffect(() => {
    if (savedVoucher && !loading) {
      if (isLoggedIn) {
        saveVoucherMutation({
          variables: {
            voucherCode: savedVoucher,
          },
        })
      } else {
        if (!universalAuthenticationRedirectToggle) {
          navigate(`${NavigationConstants.login}/?vc=${savedVoucher}&vb=true`)
        }
      }
    }
  }, [savedVoucher, loading, isLoggedIn])

  useEffect(() => {
    if (savedVoucher && !loading && !isLoggedIn && universalAuthenticationRedirectToggle) {
      showPopup('login')
    }
  }, [savedVoucher, loading])

  return {
    handle,
    error,
  }
}
