import { isDimensionalRecipeProduct } from '@dominos/components'
import { findEntityByDimensionSet } from '@dominos/hooks-and-hocs/menu/functions'

const flattenIngredientsForDimensionSet = (
  ingredientType: Bff.Common.IngredientTypeCode,
  dimensionSet: Bff.Dimensions.DimensionSet | undefined,
  product?: Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>,
) => {
  if (!isDimensionalRecipeProduct(product)) {
    return []
  }
  const ingredients: IngredientModel[] = []
  const ingredientsBasedOnIngredientType = product.ingredients.filter(
    (ingredient) => ingredient.type === ingredientType,
  )
  ingredientsBasedOnIngredientType.forEach((ingredient) => {
    const status = findEntityByDimensionSet(ingredient.statuses, dimensionSet)
    const inRecipe = findEntityByDimensionSet(ingredient.inRecipe, dimensionSet) || false
    const canAdd = findEntityByDimensionSet(ingredient.canAdd, dimensionSet) || false
    const price = findEntityByDimensionSet(ingredient.currencyPrices?.[0]?.prices || [], dimensionSet)?.[0]?.price || 0

    if (status?.isAvailable) {
      ingredients.push({
        code: ingredient.code,
        inRecipe: inRecipe,
        canAdd: canAdd,
        price,
      })
    }
  })

  return ingredients
}

export { flattenIngredientsForDimensionSet }
