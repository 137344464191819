import React from 'react'

import { CreateAccount, GenericCard, StackNavigationBase } from '@dominos/components'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { AuthenticationSource } from '@dominos/interfaces'

import css from './create-account-scene.less'

export type SuccessHandler = (idToken: string) => void
export type FailHandler = () => void
export type LoginHandler = () => void

export interface CreateAccountSceneProps extends Partial<BaseProps> {
  title?: string
  description?: string
  subtitle?: string
  name?: string
  alternateName?: string
  email?: string
  phoneNumber?: string
  identityProvider?: Bff.Customers.UpsertCustomer['identityProvider']
  onLogin?: LoginHandler
  onSuccess: SuccessHandler
  onFail: FailHandler
  loginRoute?: string
  redirectTo?: string
  authenticationSource?: AuthenticationSource
}

export const CreateAccountScene = (props: CreateAccountSceneProps) => {
  const location = useLocation()
  const { t } = useTranslation('login')
  const redirectTo = (location?.state as { redirectTo?: string })?.redirectTo ?? undefined

  const onSuccess = () => {
    redirectTo ? navigate(redirectTo) : navigate(NavigationConstants.home)
  }

  const onFail = () => {
    navigate(NavigationConstants.login, redirectTo ? { state: { redirectTo } } : undefined)
  }

  return (
    <StackNavigationBase testID='create-account-scene'>
      <div className={css.outer}>
        <GenericCard
          testID='create-account.card'
          title={props.title || t('CreateAccountTitle', { defaultValue: 'Create an Account' })}
          noPadding
        >
          <CreateAccount {...props} onSuccess={onSuccess} onFail={onFail} loginRoute={NavigationConstants.login} />
        </GenericCard>
      </div>
    </StackNavigationBase>
  )
}
