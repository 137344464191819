import { GenericSelect } from '@dominos/components'
import { useLanguages } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const LanguageSelector = ({
  testID,
  showOnlyForMultiLanguages = false,
}: {
  testID: string
  showOnlyForMultiLanguages?: boolean
}) => {
  const { i18n, t } = useTranslation('footer')
  const { languages, setLanguage } = useLanguages()
  const selectedLanguage = languages.find((language: GenericSelectItem) => language.value === (i18n && i18n.language))

  return (
    <>
      {showOnlyForMultiLanguages && languages.length <= 1 ? null : (
        <GenericSelect
          testID={testID}
          options={languages}
          onChange={setLanguage}
          selectedValue={selectedLanguage && selectedLanguage.value}
          accessibilityLabel={t('ChangeLanguage')}
        />
      )}
    </>
  )
}
