import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const BurgerIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='0 0 20.4683873 0 20.4683873 5.937 0 5.937'></polygon>
      </defs>
      <g id='UI/Symbols/Navigation/Menu-Bar/Burger' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-8' transform='translate(1.765550, 4.930410)'>
          <path
            d='M17.9100628,10.0695897 L16.6239497,11.5775897 C16.3919717,11.8495897 16.0167133,11.8495897 15.7847353,11.5775897 L14.4986222,10.0695897 L11.9400418,10.0695897 L10.6590459,11.5725897 C10.4245094,11.8465897 10.0441338,11.8465897 9.8104501,11.5725897 L8.52860132,10.0695897 L5.97002092,10.0695897 L4.67282066,11.5915897 C4.44681273,11.8555897 4.08178859,11.8555897 3.85663352,11.5915897 L2.5585804,10.0695897 L1.2792902,10.0695897 C0.573122009,10.0695897 0,10.7415897 0,11.5695897 C0,12.3975897 0.573122009,13.0695897 1.2792902,13.0695897 L19.189353,13.0695897 C19.8955212,13.0695897 20.4686432,12.3975897 20.4686432,11.5695897 C20.4686432,10.7415897 19.8955212,10.0695897 19.189353,10.0695897 L17.9100628,10.0695897 Z'
            id='Fill-1'
            fill={fill}
          ></path>
          <path
            d='M18.228606,16.0695897 L2.24003714,16.0695897 C1.47416874,16.0695897 0.853286562,15.3415897 0.853286562,14.4425897 L0.853286562,14.0695897 L19.6162095,14.0695897 L19.6162095,14.4425897 C19.6162095,15.3415897 18.9944744,16.0695897 18.228606,16.0695897'
            id='Fill-3'
            fill={fill}
          ></path>
          <g id='Group-7' transform='translate(0.000256, 3.132090)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1'></use>
            </mask>
            <g id='Clip-6'></g>
          </g>
          <path
            d='M0.00025585804,6.10108973 C0.00025585804,7.74008973 1.13370697,9.06908973 2.53239759,9.06908973 C2.54092619,9.06908973 2.54945479,9.06708973 2.55883625,9.06608973 L17.9103186,9.06908973 C17.9188472,9.06708973 17.9273758,9.06908973 17.9367573,9.06908973 C19.334595,9.06908973 20.468899,7.74008973 20.468899,6.10108973 C20.468899,4.60264533 19.7570555,0.669241751 12.4780018,0.00138674573 C12.0195749,-0.0406740378 11.2718236,0.874060722 10.234748,2.74559103 C9.13881973,0.876797029 8.35060705,-0.0341324025 7.87010997,0.0128027295 C0.703800417,0.712810493 0.00025585804,4.61095844 0.00025585804,6.10108973 Z'
            id='Fill-5'
            fill={fill}
          ></path>
        </g>
      </g>
    </svg>
  )
}
