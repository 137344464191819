import { PasswordInput, PasswordInputProps } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './password-form.less'

export const PasswordForm = (props: Pick<PasswordInputProps, 'onPasswordChanged' | 'onPasswordValid'>) => {
  const { t } = useTranslation('login')

  return (
    <>
      <div className={css.inputHeader}>{t('CreateAccountPasswordTitle', { defaultValue: 'Password' })}</div>
      <PasswordInput
        {...props}
        passwordPlaceholder={t('CreateAccountPasswordLabel', { defaultValue: 'Password' })}
        passwordConfirmPlaceholder={t('CreateAccountConfirmPasswordLabel', {
          defaultValue: 'Confirm Password',
        })}
      />
    </>
  )
}
