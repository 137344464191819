import React from 'react'
import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

export const TerminalIcon = (props: SvgIconProps) => {
  const stroke = props.stroke || '#ffffff'

  return (
    <svg data-testid='terminal-icon' width='240' height='240' viewBox='0 0 240 240'>
      <g
        stroke={stroke}
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        transform='translate(3, 3)'
      >
        <path
          d='M175.547 202.095C148.381 202.095 126 179.714 126 152.547C126 125.334 148.334 103 175.547 103C202.714 103 225.095 125.334 225.095 152.547C225.095 179.714 202.761 202.095 175.547 202.095ZM196.896 152.594C196.896 142.319 193.94 132.278 188.591 123.926C187.184 121.721 184.462 121.111 182.304 122.472C180.099 123.832 179.583 126.648 181.084 129.041C185.494 135.844 187.84 144.008 187.84 152.594C187.84 161.087 185.494 169.298 181.084 176.101C179.583 178.541 180.099 181.309 182.304 182.67C184.462 184.031 187.184 183.421 188.591 181.216C193.94 172.911 196.896 162.87 196.896 152.594ZM181.882 152.594C181.882 144.946 179.723 137.58 175.688 131.715C174.281 129.604 171.7 128.994 169.542 130.307C167.29 131.668 166.773 134.53 168.369 137.064C171.231 141.381 172.826 146.87 172.826 152.594C172.826 158.272 171.231 163.761 168.369 168.078C166.773 170.612 167.29 173.474 169.542 174.834C171.7 176.148 174.281 175.538 175.688 173.427C179.723 167.515 181.882 160.148 181.882 152.594ZM166.867 152.594C166.867 147.809 165.366 142.976 162.785 139.363C161.425 137.439 158.797 136.923 156.733 138.19C154.433 139.644 154.011 142.46 155.653 145.087C157.014 147.105 157.812 149.779 157.812 152.594C157.812 155.316 157.014 157.99 155.653 160.055C154.011 162.682 154.433 165.45 156.733 166.952C158.797 168.219 161.471 167.703 162.785 165.779C165.366 162.307 166.867 157.427 166.867 152.594Z'
          fill='#494A4B'
        />
        <path
          d='M44.7969 124.638C44.7969 128.564 47.5312 131.368 51.6677 131.368H68.8447C72.9812 131.368 75.7155 128.564 75.7155 124.638V111.667C75.7155 107.671 72.9812 104.937 68.8447 104.937H51.6677C47.5312 104.937 44.7969 107.671 44.7969 111.667V124.638Z'
          fill='#494A4B'
        />
        <path
          d='M39.749 161.095C23.7637 161.095 15 152.401 15 136.486V61.6087C15 45.6937 23.7637 37 39.749 37H158.165C174.15 37 182.914 45.7638 182.914 61.6087V98.7306C180.369 98.3696 177.767 98.1829 175.122 98.1829C171.603 98.1829 168.161 98.5134 164.826 99.145V85.4462H33.0884V134.313C33.0884 140.272 36.1033 143.007 41.7119 143.007H121.061C120.444 146.305 120.122 149.706 120.122 153.183C120.122 155.87 120.314 158.511 120.687 161.095H39.749ZM33.0884 63.8522V68.199H164.826V63.8522C164.826 57.8928 161.741 55.0884 156.132 55.0884H41.7119C36.103 55.0884 33.0884 57.8928 33.0884 63.8522Z'
          fill='#494A4B'
        />
      </g>
    </svg>
  )
}
