import gql from 'graphql-tag'

export const clearTaxAuthTokenQuery = gql`
  query clearTaxAuthToken {
    clearTaxAuthToken {
      consumerAuthTokenDetails {
        authToken
      }
      validUntil
      supplierTin
      environment
    }
  }
`
