import { mapCountryToDisplayAddress } from './map-country-display-address'
import { getAddressComponentsFromCustomerAddressRequest } from './get-address-component'
import { mergeAddressComponents } from './merge-address-component'
import { getOriginalAddress } from '../jp/convert-to-stored-customer-address'

const mapValidationFieldsToAddressComponents = (
  fields: AdditionalFields | undefined,
): AddressComponent[] | undefined => {
  if (!fields) {
    return undefined
  }

  return Object.entries(fields).map(
    ([key, field]): AddressComponent => ({
      value: field.value || '',
      type: key,
    }),
  )
}

export const concatElementsInAddressComponent = (
  addressComponents: AddressComponent[],
  keys: string[],
  combinedKeyType: string,
  separator: string = ' ',
) => {
  const combineStrings: string[] = []
  keys.forEach((key) => {
    const index = addressComponents.findIndex(({ type }) => type === key)
    if (index >= 0) {
      combineStrings.push(addressComponents[index].value)
      addressComponents.splice(index, 1)
    }
  })

  const insertAddressComponent: AddressComponent = {
    type: combinedKeyType,
    value: combineStrings.join(separator),
  }
  addressComponents.push(insertAddressComponent)

  return addressComponents
}

export const overrideDeliveryAddressComponent = (
  addressComponents: AddressComponent[],
  countryCode: BffContext.Countries,
) => {
  if (
    countryCode === 'MY' &&
    addressComponents.find((e) => (e.type === 'blockNo' || e.type === 'floorNo') && e.value !== '')
  ) {
    addressComponents = concatElementsInAddressComponent(
      addressComponents,
      ['blockNo', 'floorNo', 'unitNo'],
      'unitNo',
      '/',
    )
  }

  return addressComponents
}

export const combineAddressComponents = (
  baseAddressComponents: AddressComponent[],
  additionalFieldsAddressComponents: AddressComponent[] | undefined,
  countryCode: BffContext.Countries,
) => {
  if (!additionalFieldsAddressComponents) {
    return baseAddressComponents
  }

  const combinedAddressComponent: AddressComponent[] = []

  // Add all baseAddressComponents that do not have additionalFieldsAddressComponents overrides with values
  baseAddressComponents.forEach((c) =>
    !additionalFieldsAddressComponents.some(
      (additional) => c.type === additional.type && (additional.value ?? '') !== '',
    )
      ? combinedAddressComponent.push(c)
      : null,
  )

  // Add all additionalFieldsAddressComponents that have values, or do not exist in baseAddressComponents
  additionalFieldsAddressComponents.forEach((additional) =>
    (additional.value ?? '') !== '' || !baseAddressComponents.some((b) => b.type === additional.type)
      ? combinedAddressComponent.push(additional)
      : null,
  )

  return overrideDeliveryAddressComponent(combinedAddressComponent, countryCode)
}

export const mapAddressComponentsToDisplayAddress = (
  addressComponents: AddressComponent[],
  countryCode: BffContext.Countries,
): string | undefined => {
  const component: { [K in keyof CustomerAddressRequest]?: string } = Object.assign(
    {},
    ...addressComponents.map((customerAddressKey) => ({
      [customerAddressKey.type]: customerAddressKey.value,
    })),
  )

  return mapCountryToDisplayAddress(component, countryCode)
}

export const fetchAdditionalInformation = (
  t: (key: string) => string,
  address: AddressComponent[],
  countryCode: BffContext.Countries,
): string | undefined => {
  switch (countryCode) {
    case 'FR':
      return fetchElementsInAddressComponentAsString(t, address, [
        { fieldName: 'entryCode', translationKey: 'Entry Code' },
      ])
    case 'BE':
      return fetchElementsInAddressComponentAsString(t, address, [
        { fieldName: 'deliveryInstructionsFieldOne', translationKey: 'Unit Number' },
        { fieldName: 'deliveryInstructionsFieldTwo', translationKey: 'Floor Number' },
      ])
    default:
      return undefined
  }
}

export const fetchElementsInAddressComponentAsString = (
  t: (key: string) => string,
  addressComponents: AddressComponent[],
  keys: { fieldName: string; translationKey: string }[],
) => {
  const filteredComponents = addressComponents.filter(({ type }) => keys.some(({ fieldName }) => fieldName === type))

  if (filteredComponents.length === 0) return undefined

  const result = filteredComponents
    .map(({ type, value }) => {
      const translationKey = keys.find(({ fieldName }) => fieldName === type)

      return translationKey ? `${t(translationKey.translationKey)}: ${value}` : ''
    })
    .join(', ')

  return result ? `${result}. ` : undefined
}

export const mapDeliveryAddressWithAdditionalFieldsToStoredCustomerAddress = (
  t: (key: string) => string,
  address: DeliveryAddress,
  additionalFields: AdditionalFields | undefined,
  countryCode: BffContext.Countries,
): StoredCustomerAddress => {
  const additionalFieldsAddressComponents: AddressComponent[] | undefined =
    mapValidationFieldsToAddressComponents(additionalFields)

  const addressComponents = combineAddressComponents(
    address.addressComponents,
    additionalFieldsAddressComponents,
    countryCode,
  )

  const customerAddress: CustomerAddressRequest = Object.assign(
    {},
    ...addressComponents.map((item) => ({
      [item.type]: item.value,
    })),
  ) as CustomerAddressRequest

  // TODO: should be done in `olo.graph`
  const transformedDisplayAddress = mapAddressComponentsToDisplayAddress(addressComponents, countryCode)

  const { storeNo, geo, displayAddress, locale } = address

  const additionalInformation = fetchAdditionalInformation(t, addressComponents, countryCode)

  return {
    storeNo,
    displayAddress: transformedDisplayAddress || displayAddress,
    customerAddress,
    lastUsed: Date.now(),
    bewareOfAnimal: false,
    geo,
    addressComponents,
    additionalInformation,
    locale,
  }
}

export const mapAddressComponentToStoredCustomerAddressUpdate = (
  t: (key: string) => string,
  existingCustomerAddress: StoredCustomerAddress,
  additionalAddressComponents: AddressComponent[],
  countryCode: BffContext.Countries,
): StoredCustomerAddressUpdate => {
  const existingAddressComponents = existingCustomerAddress.customerAddress
    ? getAddressComponentsFromCustomerAddressRequest(existingCustomerAddress.customerAddress)
    : existingCustomerAddress.addressComponents

  const customerAddressHasChanged = addressComponentsRequiresUpdating(
    additionalAddressComponents,
    existingAddressComponents,
  )

  if (!customerAddressHasChanged) return { hasBeenUpdated: customerAddressHasChanged, address: existingCustomerAddress }

  const addressComponents = mergeAddressComponents(additionalAddressComponents, existingAddressComponents || [])
  const displayAddress =
    mapAddressComponentsToDisplayAddress(addressComponents, countryCode) || existingCustomerAddress.displayAddress
  const customerAddress: CustomerAddressRequest = Object.assign(
    {},
    ...addressComponents.map((item) => ({
      [item.type]: item.value,
    })),
  )

  const additionalInformation = fetchAdditionalInformation(t, addressComponents, countryCode)

  return {
    hasBeenUpdated: customerAddressHasChanged,
    address: {
      ...existingCustomerAddress,
      customerAddress,
      displayAddress,
      addressComponents,
      additionalInformation,
      storeNo: existingCustomerAddress.storeNo,
      locale: existingCustomerAddress.locale,
      geo: existingCustomerAddress.geo,
      lastUsed: Date.now(),
      rawAddress: getOriginalAddress({ ...existingCustomerAddress, rawAddress: existingCustomerAddress.rawAddress }),
    },
  }
}

const addressComponentsRequiresUpdating = (
  additionalAddressComponents: AddressComponent[],
  existingAddressComponents: AddressComponent[] | undefined,
): boolean => {
  if (!existingAddressComponents) return true

  const existingFieldUpdated = existingAddressComponents?.some((existingComponent) =>
    additionalAddressComponents.some(
      (additionalComponent) =>
        existingComponent.type === additionalComponent.type && existingComponent.value != additionalComponent.value,
    ),
  )

  if (existingFieldUpdated) return true

  return additionalAddressComponents.some((additionalAddressComponent) =>
    existingAddressComponents.every(
      (existingAddressComponent) =>
        existingAddressComponent.type !== additionalAddressComponent.type && additionalAddressComponent.value,
    ),
  )
}
