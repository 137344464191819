import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActionButton, MDTargetBlankAnchor, Modal } from '@dominos/components'
import { useCountryCode } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import Markdown from 'markdown-to-jsx'

import { getRobotDeliveryImage } from './robot-delivery-static-images'
import css from './robot-delivery-popup.less'

const iconWidth = 21
const iconHeight = 21
const icon = 'cross'

const defaultContent = {
  title: 'About Robot Delivery',
  subtitle: 'Your order may qualify for robot delivery.',
  body:
    'To have your order delivered by robot, please make sure you provide a valid mobile number at checkout. ' +
    'You will receive a text message with instructions and the code required to unlock your delivery robot.',
  optIn: 'I Understand',
  optOut: 'No Thanks',
  imgAlt: "Domino's Robot Delivery",
}

export const RobotDeliveryPopup = (props: {
  id: string
  testID: string
  onClose: () => void
  onOptIn: () => void
  onOptOut: () => void
}) => {
  const { t } = useTranslation('checkout')
  const countryCode = useCountryCode()

  const testID = `${props.testID}.popup`
  const image = getRobotDeliveryImage(countryCode)

  return (
    <Modal testID={props.id} onModalClose={props.onClose}>
      <div data-testid={testID} className={css.cardContainer}>
        <div
          data-testid={`${testID}.close-button`}
          role='button'
          aria-label={t('closeButton', { defaultValue: 'Close Button' })}
          className={css.closeButton}
          onClick={props.onClose}
        >
          <StaticSvgIcon name={icon} width={iconWidth} height={iconHeight} isUnstyled />
        </div>
        <div className={css.contentContainer}>
          {image && (
            <div data-testid={`${testID}.image`} className={css.contentBannerImage}>
              <img src={image} alt={t('robotDeliveryPopupImgAlt', { defaultValue: defaultContent.imgAlt })} />
            </div>
          )}
          <h4 className={css.contentHeader}>{t('robotDeliveryPopupTitle', { defaultValue: defaultContent.title })}</h4>
          <p className={css.contentSubheader}>
            {t('robotDeliveryPopupSubtitle', { defaultValue: defaultContent.subtitle })}
          </p>
          <Markdown className={css.contentBody} options={{ forceBlock: true, overrides: { a: MDTargetBlankAnchor } }}>
            {t('robotDeliveryPopupBody', { defaultValue: defaultContent.body })}
          </Markdown>
          <div className={css.contentFooter}>
            <ActionButton
              text={t('robotDeliveryPopupOptOut', { defaultValue: defaultContent.optOut })}
              onPress={props.onOptOut}
              testID={`${testID}.opt-out`}
              containerStyle={{ backgroundColor: '#4a4a4a' }}
              textFontWeight='bold'
            />
            <ActionButton
              text={t('robotDeliveryPopupOptIn', { defaultValue: defaultContent.optIn })}
              onPress={props.onOptIn}
              testID={`${testID}.opt-in`}
              containerStyle={{ backgroundColor: '#007aff' }}
              textFontWeight='bold'
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}
