import React from 'react'

const PortionEmptyTopRightSideIcon = () => (
  <svg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.5027 0C9.92402 0 8.44059 0.299479 7.05243 0.898438C5.66426 1.4974 4.44213 2.32596 3.38604 3.38412C2.32994 4.44227 1.50158 5.66469 0.900948 7.05137C0.300316 8.43805 0 9.92093 0 11.5C0 13.0791 0.300316 14.5619 0.900948 15.9486C1.50158 17.3353 2.32994 18.5577 3.38604 19.6159C4.44213 20.674 5.66426 21.5026 7.05243 22.1016C8.44059 22.7005 9.92402 23 11.5027 23C13.0778 23 14.5603 22.7005 15.9503 22.1016C17.3403 21.5026 18.5633 20.674 19.6194 19.6159C20.6755 18.5577 21.503 17.3353 22.1018 15.9486C22.7006 14.5619 23 13.0791 23 11.5C23 9.92093 22.7006 8.43805 22.1018 7.05137C21.503 5.66469 20.6746 4.44227 19.6167 3.38412C18.5588 2.32596 17.3357 1.4974 15.9476 0.898438C14.5594 0.299479 13.0778 0 11.5027 0Z'
      fill='#0A7AFF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.3575 11.6324L20.8839 11.6324C20.8839 12.9328 20.6426 14.1494 20.1599 15.2823C19.6772 16.4152 19.0063 17.4102 18.1473 18.2672C17.2882 19.1243 16.2923 19.7962 15.1594 20.2828C14.0265 20.7695 12.8098 21.0128 11.5095 21.0128C10.2091 21.0128 8.99245 20.7704 7.85955 20.2858C6.72666 19.8011 5.73168 19.1302 4.87461 18.2731C4.82749 18.226 4.78092 18.1785 4.73492 18.1305C4.73085 18.1264 4.72677 18.1224 4.72271 18.1183C3.86564 17.2592 3.19379 16.2633 2.70713 15.1304C2.22048 13.9975 1.97715 12.7809 1.97715 11.4805C1.97715 10.1801 2.21949 8.96348 2.70418 7.83058C3.18886 6.69768 3.85973 5.7027 4.71679 4.84564C5.57386 3.98858 6.56982 3.31771 7.70469 2.83302C8.83956 2.34834 10.0572 2.106 11.3575 2.106V11.6324Z'
      fill='white'
    />
  </svg>
)

export { PortionEmptyTopRightSideIcon }
