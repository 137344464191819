import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 100,
  height: 100,
}

export const PizzaGaugeBackground = (props: SvgIconProps) => {
  const fill = props.fill || '#E8E8E8'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 226 228'>
      <g id='Points0' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Group-3'
          transform='translate(112.588112, 114.378820) rotate(30.000000) translate(-112.588112, -114.378820) translate(-9.911888, 3.378820)'
          fill={fill}
          fillRule='nonzero'
        >
          <g
            id='Group-2'
            transform='translate(122.596370, 110.901245) rotate(120.000000) translate(-122.596370, -110.901245) translate(70.596370, 0.401245)'
          >
            <g id='Slice' transform='translate(0.344389, 116.352135)'>
              <g id='Group'>
                <path
                  d='M95.3539683,77.9601325 L51.5999587,0.412202999 L7.85762467,77.9422106 C34.483647,92.0467219 67.1167242,92.996581 95.3539683,77.9601325 M38.4182971,58.1445187 C43.1176938,58.1445187 46.9297509,62.0455123 46.9297509,66.8545473 C46.9297509,71.6635823 43.1176938,75.558602 38.4182971,75.558602 C33.7189005,75.558602 29.9126811,71.6576084 29.9126811,66.8545473 C29.9126811,62.0455123 33.7247383,58.1445187 38.4182971,58.1445187 M60.1055747,41.8356175 C60.1055747,46.6446524 56.2935176,50.5396721 51.5999587,50.5396721 C46.9005621,50.5396721 43.088505,46.6386785 43.088505,41.8356175 C43.088505,37.0265825 46.9005621,33.1255889 51.5999587,33.1255889 C56.2993554,33.1255889 60.1055747,37.0265825 60.1055747,41.8356175 M73.2813986,66.8545473 C73.2813986,71.6635823 69.4693414,75.558602 64.7757826,75.558602 C60.0763859,75.558602 56.2643288,71.6576084 56.2643288,66.8545473 C56.2643288,62.0455123 60.0763859,58.1445187 64.7757826,58.1445187 C69.4751792,58.1445187 73.2813986,62.0455123 73.2813986,66.8545473'
                  id='Shape'
                />
                <path
                  d='M97.9926357,82.6377404 C68.0624413,98.6598918 33.4328503,97.6383452 5.21895725,82.6138446 L0.472858542,91.0251754 C31.5063896,107.722384 69.6970141,108.845488 102.715383,91.0072535 L97.9926357,82.6377404 Z'
                  id='Path'
                />
              </g>
            </g>
            <g
              id='Slice'
              transform='translate(52.209471, 52.908988) scale(1, -1) translate(-52.209471, -52.908988) translate(0.709471, 0.908988)'
            >
              <g id='Group'>
                <path
                  d='M95.3539683,77.9601325 L51.5999587,0.412202999 L7.85762467,77.9422106 C34.483647,92.0467219 67.1167242,92.996581 95.3539683,77.9601325 M38.4182971,58.1445187 C43.1176938,58.1445187 46.9297509,62.0455123 46.9297509,66.8545473 C46.9297509,71.6635823 43.1176938,75.558602 38.4182971,75.558602 C33.7189005,75.558602 29.9126811,71.6576084 29.9126811,66.8545473 C29.9126811,62.0455123 33.7247383,58.1445187 38.4182971,58.1445187 M60.1055747,41.8356175 C60.1055747,46.6446524 56.2935176,50.5396721 51.5999587,50.5396721 C46.9005621,50.5396721 43.088505,46.6386785 43.088505,41.8356175 C43.088505,37.0265825 46.9005621,33.1255889 51.5999587,33.1255889 C56.2993554,33.1255889 60.1055747,37.0265825 60.1055747,41.8356175 M73.2813986,66.8545473 C73.2813986,71.6635823 69.4693414,75.558602 64.7757826,75.558602 C60.0763859,75.558602 56.2643288,71.6576084 56.2643288,66.8545473 C56.2643288,62.0455123 60.0763859,58.1445187 64.7757826,58.1445187 C69.4751792,58.1445187 73.2813986,62.0455123 73.2813986,66.8545473'
                  id='Shape'
                />
                <path
                  d='M97.9926357,82.6377404 C68.0624413,98.6598918 33.4328503,97.6383452 5.21895725,82.6138446 L0.472858542,91.0251754 C31.5063896,107.722384 69.6970141,108.845488 102.715383,91.0072535 L97.9926357,82.6377404 Z'
                  id='Path'
                />
              </g>
            </g>
          </g>
          <g
            id='Group-2'
            transform='translate(122.246598, 110.929225) rotate(60.000000) translate(-122.246598, -110.929225) translate(69.746598, 1.929225)'
          >
            <g id='Slice' transform='translate(1.516694, 113.320579)'>
              <g id='Group'>
                <path
                  d='M95.3539683,77.9601325 L51.5999587,0.412202999 L7.85762467,77.9422106 C34.483647,92.0467219 67.1167242,92.996581 95.3539683,77.9601325 M38.4182971,58.1445187 C43.1176938,58.1445187 46.9297509,62.0455123 46.9297509,66.8545473 C46.9297509,71.6635823 43.1176938,75.558602 38.4182971,75.558602 C33.7189005,75.558602 29.9126811,71.6576084 29.9126811,66.8545473 C29.9126811,62.0455123 33.7247383,58.1445187 38.4182971,58.1445187 M60.1055747,41.8356175 C60.1055747,46.6446524 56.2935176,50.5396721 51.5999587,50.5396721 C46.9005621,50.5396721 43.088505,46.6386785 43.088505,41.8356175 C43.088505,37.0265825 46.9005621,33.1255889 51.5999587,33.1255889 C56.2993554,33.1255889 60.1055747,37.0265825 60.1055747,41.8356175 M73.2813986,66.8545473 C73.2813986,71.6635823 69.4693414,75.558602 64.7757826,75.558602 C60.0763859,75.558602 56.2643288,71.6576084 56.2643288,66.8545473 C56.2643288,62.0455123 60.0763859,58.1445187 64.7757826,58.1445187 C69.4751792,58.1445187 73.2813986,62.0455123 73.2813986,66.8545473'
                  id='Shape'
                />
                <path
                  d='M97.9926357,82.6377404 C68.0624413,98.6598918 33.4328503,97.6383452 5.21895725,82.6138446 L0.472858542,91.0251754 C31.5063896,107.722384 69.6970141,108.845488 102.715383,91.0072535 L97.9926357,82.6377404 Z'
                  id='Path'
                />
              </g>
            </g>
            <g
              id='Slice'
              transform='translate(51.948310, 52.154015) scale(1, -1) translate(-51.948310, -52.154015) translate(0.448310, 0.154015)'
            >
              <g id='Group'>
                <path
                  d='M95.3539683,77.9601325 L51.5999587,0.412202999 L7.85762467,77.9422106 C34.483647,92.0467219 67.1167242,92.996581 95.3539683,77.9601325 M38.4182971,58.1445187 C43.1176938,58.1445187 46.9297509,62.0455123 46.9297509,66.8545473 C46.9297509,71.6635823 43.1176938,75.558602 38.4182971,75.558602 C33.7189005,75.558602 29.9126811,71.6576084 29.9126811,66.8545473 C29.9126811,62.0455123 33.7247383,58.1445187 38.4182971,58.1445187 M60.1055747,41.8356175 C60.1055747,46.6446524 56.2935176,50.5396721 51.5999587,50.5396721 C46.9005621,50.5396721 43.088505,46.6386785 43.088505,41.8356175 C43.088505,37.0265825 46.9005621,33.1255889 51.5999587,33.1255889 C56.2993554,33.1255889 60.1055747,37.0265825 60.1055747,41.8356175 M73.2813986,66.8545473 C73.2813986,71.6635823 69.4693414,75.558602 64.7757826,75.558602 C60.0763859,75.558602 56.2643288,71.6576084 56.2643288,66.8545473 C56.2643288,62.0455123 60.0763859,58.1445187 64.7757826,58.1445187 C69.4751792,58.1445187 73.2813986,62.0455123 73.2813986,66.8545473'
                  id='Shape'
                />
                <path
                  d='M97.9926357,82.6377404 C68.0624413,98.6598918 33.4328503,97.6383452 5.21895725,82.6138446 L0.472858542,91.0251754 C31.5063896,107.722384 69.6970141,108.845488 102.715383,91.0072535 L97.9926357,82.6377404 Z'
                  id='Path'
                />
              </g>
            </g>
          </g>
          <g id='Group-2' transform='translate(70.456291, -0.000000)'>
            <g id='Slice' transform='translate(0.466051, 117.021029)'>
              <g id='Group'>
                <path
                  d='M95.3539683,77.9601325 L51.5999587,0.412202999 L7.85762467,77.9422106 C34.483647,92.0467219 67.1167242,92.996581 95.3539683,77.9601325 M38.4182971,58.1445187 C43.1176938,58.1445187 46.9297509,62.0455123 46.9297509,66.8545473 C46.9297509,71.6635823 43.1176938,75.558602 38.4182971,75.558602 C33.7189005,75.558602 29.9126811,71.6576084 29.9126811,66.8545473 C29.9126811,62.0455123 33.7247383,58.1445187 38.4182971,58.1445187 M60.1055747,41.8356175 C60.1055747,46.6446524 56.2935176,50.5396721 51.5999587,50.5396721 C46.9005621,50.5396721 43.088505,46.6386785 43.088505,41.8356175 C43.088505,37.0265825 46.9005621,33.1255889 51.5999587,33.1255889 C56.2993554,33.1255889 60.1055747,37.0265825 60.1055747,41.8356175 M73.2813986,66.8545473 C73.2813986,71.6635823 69.4693414,75.558602 64.7757826,75.558602 C60.0763859,75.558602 56.2643288,71.6576084 56.2643288,66.8545473 C56.2643288,62.0455123 60.0763859,58.1445187 64.7757826,58.1445187 C69.4751792,58.1445187 73.2813986,62.0455123 73.2813986,66.8545473'
                  id='Shape'
                />
                <path
                  d='M97.9926357,82.6377404 C68.0624413,98.6598918 33.4328503,97.6383452 5.21895725,82.6138446 L0.472858542,91.0251754 C31.5063896,107.722384 69.6970141,108.845488 102.715383,91.0072535 L97.9926357,82.6377404 Z'
                  id='Path'
                />
              </g>
            </g>
            <g
              id='Slice'
              transform='translate(51.500000, 52.863288) scale(1, -1) translate(-51.500000, -52.863288) translate(0.000000, 0.863288)'
            >
              <g id='Group'>
                <path
                  d='M95.3539683,77.9601325 L51.5999587,0.412202999 L7.85762467,77.9422106 C34.483647,92.0467219 67.1167242,92.996581 95.3539683,77.9601325 M38.4182971,58.1445187 C43.1176938,58.1445187 46.9297509,62.0455123 46.9297509,66.8545473 C46.9297509,71.6635823 43.1176938,75.558602 38.4182971,75.558602 C33.7189005,75.558602 29.9126811,71.6576084 29.9126811,66.8545473 C29.9126811,62.0455123 33.7247383,58.1445187 38.4182971,58.1445187 M60.1055747,41.8356175 C60.1055747,46.6446524 56.2935176,50.5396721 51.5999587,50.5396721 C46.9005621,50.5396721 43.088505,46.6386785 43.088505,41.8356175 C43.088505,37.0265825 46.9005621,33.1255889 51.5999587,33.1255889 C56.2993554,33.1255889 60.1055747,37.0265825 60.1055747,41.8356175 M73.2813986,66.8545473 C73.2813986,71.6635823 69.4693414,75.558602 64.7757826,75.558602 C60.0763859,75.558602 56.2643288,71.6576084 56.2643288,66.8545473 C56.2643288,62.0455123 60.0763859,58.1445187 64.7757826,58.1445187 C69.4751792,58.1445187 73.2813986,62.0455123 73.2813986,66.8545473'
                  id='Shape'
                />
                <path
                  d='M97.9926357,82.6377404 C68.0624413,98.6598918 33.4328503,97.6383452 5.21895725,82.6138446 L0.472858542,91.0251754 C31.5063896,107.722384 69.6970141,108.845488 102.715383,91.0072535 L97.9926357,82.6377404 Z'
                  id='Path'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
