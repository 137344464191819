import { getFormattedPrice } from '@dominos/business/functions/text'
import { TFunction } from 'i18next'

export const getMinimumOrderError = (
  total: number | null,
  method: BffContext.ServiceMethods = 'Pickup',
  store?: Bff.Stores.Store,
  minimumPriceFromBasket?: number,
  t?: TFunction,
) => {
  const minimumValue = minimumPriceFromBasket != null ? minimumPriceFromBasket : getMinimumOrderForMethod(method, store)

  return minimumValue > (total || 0) ? formatMinimumErrorText(method, minimumValue, t) : ''
}

export const getMinimumOrderForMethod = (method: BffContext.ServiceMethods, store?: Bff.Stores.Store) => {
  const priceInfo = store && store.priceInfo
  if (priceInfo) {
    return method === 'Delivery' ? priceInfo.minOrderPriceDelivery || 0 : priceInfo.minOrderPricePickup || 0
  }

  return 0
}

export const formatMinimumErrorText = (method: BffContext.ServiceMethods, minimumValue: number, t?: TFunction) =>
  t
    ? t('Minimum order error message', {
        defaultValue: 'Sorry, your order does not meet the minimum {{method}} amount of {{minimumValue}}',
        method: t && t(method) && t(method).toLowerCase(),
        minimumValue: getFormattedPrice(minimumValue),
      })
    : `Sorry, your order does not meet the minimum ${method.toLowerCase()} amount of ${getFormattedPrice(minimumValue)}`
