import French from 'simple-keyboard-layouts/build/layouts/french'
import English from 'simple-keyboard-layouts/build/layouts/english'
import German from 'simple-keyboard-layouts/build/layouts/german'
import { LayoutMapping, Layouts } from './layout.interfaces'
import Katakana from './custom-layouts/japanese-katakana'
import Hiragana from './custom-layouts/japanese-hiragana'

export const VirtualKeyboardLayoutMapping: PartialRecord<BffContext.Languages, LayoutMapping> = {
  ja: { [Layouts.Romaji]: English, [Layouts.Hiragana]: Hiragana, [Layouts.Katakana]: Katakana },
  fr: { [Layouts.Default]: French },
  en: { [Layouts.Default]: English },
  de: { [Layouts.Default]: German },
}
