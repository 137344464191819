import { Operation } from '@apollo/client'
import { idTokenUtils } from '@dominos/business/functions/customer/id-token-utils'
import { SecuritySender } from '@dominos/hooks-and-hocs/logging/security'

export const DPE_TOKEN_HEADER = 'dpe-token'

export const dpeTokenHandler = (headers: Headers, operation: Operation, securitySender: SecuritySender) => {
  const newToken: string | null = headers.get(DPE_TOKEN_HEADER)
  if (!newToken) return

  const { saveToken, getDecodedToken, decodeToken } = idTokenUtils()
  const decodedToken = decodeToken(newToken)
  const savedToken = getDecodedToken()

  if (!savedToken) {
    // We received a token when we aren't logged in
    securitySender(
      'ApolloClientError.AuthenticationTokenGhost',
      {
        message: 'Unexpected token received',
        graphOperationName: operation.operationName,
        tokenReceived: decodedToken,
      },
      true,
    )
  } else if (decodedToken?.customerId === savedToken?.customerId) {
    // Update existing token
    saveToken(newToken)
  } else {
    // We received a token for another customer
    securitySender(
      'ApolloClientError.AuthenticationTokenMismatch',
      {
        message: 'Mismatched customer token',
        graphOperationName: operation.operationName,
        tokenReceived: decodedToken,
      },
      true,
    )
  }
}
