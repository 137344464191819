import { useContext } from 'react'
import { MenuContext } from './menu-provider'

type DimensionTypeCode = Bff.Common.DimensionTypeCode
type DimensionMedia = Bff.Dimensions.DimensionMedia
type IngredientMedia = Bff.Ingredients.IngredientMedia
type IngredientTypeCode = Bff.Common.IngredientTypeCode
type LegendMedia = Bff.Common.LegendMedia

interface UseMarketResponse {
  getMediaForDimension: (dimensionType: DimensionTypeCode, code: string) => DimensionMedia | undefined
  getMediaForIngredient: (ingredientType: IngredientTypeCode, code: string) => IngredientMedia | undefined
  getMediaForLegend: (code: string) => LegendMedia | undefined
}

const useMarket = (): UseMarketResponse => {
  const context = useContext(MenuContext)
  if (!context) {
    throw new Error("'useMarket' must be a child of 'MenuProvider'")
  }

  const { dimensionMediaByCode, ingredientMediaByCode, legendMediaByCode } = context.data

  return {
    getMediaForDimension: (dimensionType: DimensionTypeCode, code: string) =>
      dimensionMediaByCode?.[`${dimensionType}-${code}`],
    getMediaForIngredient: (ingredientType: IngredientTypeCode, code: string) =>
      ingredientMediaByCode?.[`${ingredientType}-${code}`],
    getMediaForLegend: (code: string) => legendMediaByCode?.[code],
  }
}

export { UseMarketResponse, useMarket }
