import { useMemo } from 'react'
import { toPascalCase } from '@dominos/business/functions/text/text-functions'
import { DateTime } from 'luxon'
import { TFunction } from 'i18next'
import { StoreStatusMessage, UseStoreDetailsResponse } from '@dominos/interfaces/delivery-address'

const SECONDS_IN_DAY = 86400

export const useStoreDetails = (
  serviceMethod: BffContext.ServiceMethods,
  currentStore: Bff.Stores.Store,
  openingHours: StoreOrderTimeSlots | undefined,
  t: TFunction,
  language: string,
): UseStoreDetailsResponse => {
  const storeName = currentStore.media?.name
  const storeNo = currentStore.storeNo
  const storeAddress = currentStore.media?.displayAddress
  const storeType = currentStore.storeType

  const storeTypeMessage = useMemo((): string | undefined => {
    if (storeType && storeType === 'Mobile' && serviceMethod === 'Pickup') {
      return t('MPKDisclaimer')
    }

    return undefined
  }, [storeType])

  /** defaultValue: `Domino's {{storeName}}` */
  const storeInformation = t('DominosStore', {
    storeName: toPascalCase(storeName),
    interpolation: { escapeValue: false },
  })

  const storeStatusMessage = useMemo((): StoreStatusMessage => {
    if (!openingHours) {
      return { value: t('Open'), theme: 'open' }
    }

    if (openingHours.currentStoreStatus === 'Busy') {
      return { value: t('Busy Now'), theme: 'alert' }
    }

    if (openingHours.currentStoreStatus === 'ClosingSoonServiceMethodUnavailable') {
      return {
        value: t('ClosingInStatus', { minutes: openingHours.closesInMinutes }),
        theme: 'closingSoon',
      }
    }

    /** defaultValue: `Currently {{storeStatus}}.` */
    if (
      openingHours.currentStoreStatus === 'Closed' ||
      openingHours.currentStoreStatus === 'ClosedNoOrderingAvailable'
    ) {
      const storeStatus = t('Closed')

      return {
        value: t('StoreStatus', { storeStatus }),
        theme: 'closed',
      }
    }

    if (openingHours.currentStoreStatus === 'ServiceMethodUnavailable') {
      const storeStatus = t('Unavailable')

      return {
        value: t('StoreStatus', { storeStatus }),
        theme: 'closed',
      }
    }

    /** defaultValue: `Currently {{storeStatus}}.` */
    const storeStatus = t('Open')

    return {
      value: t('StoreStatus', { storeStatus }),
      theme: 'open',
    }
  }, [openingHours, openingHours?.currentStoreStatus, openingHours?.closesInMinutes, language])

  const extraStoreStatusDetails = useMemo(() => {
    if (!openingHours) return undefined

    if (openingHours.currentStoreStatusMessage && openingHours.currentStoreStatus !== 'ServiceMethodDiverted') {
      return getDetailsWhenCurrentStatusMessageAvailable(
        serviceMethod,
        t,
        openingHours,
        openingHours.currentStoreStatusMessage!,
      )
    }

    if (getNextDayOfTradeTimeSlots(openingHours)?.tradingDayStatus == 'Busy') {
      return t('StoreBusyToday', { defaultValue: 'No available order times today' })
    }

    if (getNextDayOfTradeTimeSlots(openingHours)?.tradingDayStatus == 'ServiceMethodUnavailable') {
      const isPickup = serviceMethod === 'Pickup'
      const serviceText = isPickup ? t('Pick Up') : t('Delivery')

      return t('ServiceMethodUnavailableToday', {
        deliveryOrPickup: serviceText,
        defaultValue: '{{deliveryOrPickup}} is unavailable today',
      })
    }

    if (nextOrderDateHasSomeTimeSlotsWithStatus(openingHours, 'Busy')) {
      return t('StoreHasSomeBusyTimes')
    }

    if (nextOrderDateHasSomeTimeSlotsWithStatus(openingHours, 'ServiceMethodUnavailable')) {
      const isPickup = serviceMethod === 'Pickup'
      const serviceText = isPickup ? t('Pick Up') : t('Delivery')

      return t('ServiceMethodSomeTimesUnavailable', {
        deliveryOrPickup: serviceText,
        defaultValue: '{{deliveryOrPickup}} available at selected times',
      })
    }

    return undefined
  }, [openingHours, language])

  const storeOpensAtMessage = useMemo(() => {
    if (openingHours?.currentStoreStatus === 'Closed') {
      /** defaultValue: Opens at {{storeOpensAt}}` */
      const storeOpensAt = getStoreOpensAtTime(language, t, openingHours)

      return storeOpensAt ? t('StoreOpensAt', { storeOpensAt: `${storeOpensAt}` }) : undefined
    }

    if (openingHours?.currentStoreStatus === 'ClosingSoonServiceMethodUnavailable') {
      const storeOpensAt = getStoreOpensAtTime(language, t, openingHours)

      return storeOpensAt ? t('StoreResumesAt', { storeResumesAt: `${storeOpensAt}` }) : undefined
    }

    if (openingHours && openingHours?.currentStoreStatus === 'ClosedNoOrderingAvailable') {
      const dateSuffix = getDateSuffix(language, openingHours)

      return t('OrdersResumeAt') + dateSuffix
    }

    return undefined
  }, [openingHours?.currentStoreStatus, language])

  return {
    storeNo,
    storeAddress,
    storeInformation,
    storeStatusMessage,
    extraStoreStatusDetails,
    storeOpensAtMessage,
    storeType,
    storeTypeMessage,
  }
}

const getDetailsWhenCurrentStatusMessageAvailable = (
  serviceMethod: BffContext.ServiceMethods,
  t: TFunction,
  openingHours: StoreOrderTimeSlots,
  message: string,
) => {
  const isPickup = serviceMethod === 'Pickup'
  const serviceText = isPickup ? t('Pick Up') : t('Delivery')

  if (getNextDayOfTradeTimeSlots(openingHours)?.tradingDayStatus === 'ServiceMethodUnavailable') {
    return t('ServiceMethodUnavailableTodayWithReason', {
      deliveryOrPickup: serviceText,
      reason: message,
      defaultValue: '{{deliveryOrPickup}} is unavailable today: {{reason}}',
    })
  } else if (nextOrderDateHasSomeTimeSlotsWithStatus(openingHours, 'ServiceMethodUnavailable')) {
    return t('ServiceMethodSomeTimesUnavailableWithReason', {
      deliveryOrPickup: serviceText,
      reason: message,
      defaultValue: '{{deliveryOrPickup}} available at selected times: {{reason}}',
    })
  }

  return message
}

const getStoreOpensAtTime = (language: string, t: TFunction, openingHours: StoreOrderTimeSlots | undefined) => {
  if (
    !openingHours ||
    openingHours.currentStoreStatus === 'Available' ||
    openingHours.currentStoreStatus === 'Busy' ||
    openingHours.currentStoreStatus === 'ServiceMethodUnavailable' ||
    openingHours.currentStoreStatus === 'ServiceMethodDiverted'
  ) {
    return null
  }

  const nextOpenDateTime = convertToDateTimeIso(openingHours.nextOpenDateTime)
  const dateSuffix = getDateSuffix(language, openingHours)

  if (dateSuffix == null) return null

  return (
    nextOpenDateTime!
      .setLocale(language)
      .toFormat(t('STORE_OPENS_AT_TIME_FORMAT', { defaultValue: 'h:mm a' }))
      .toUpperCase() + dateSuffix
  )
}

const nextOrderDateHasSomeTimeSlotsWithStatus = (
  openingHours: StoreOrderTimeSlots,
  timeSlotStatus: 'Available' | 'ServiceMethodUnavailable' | 'Busy' | 'Closure' | 'ServiceMethodDiverted',
): boolean => {
  const currentDayOfTradeTimeSlots = getNextDayOfTradeTimeSlots(openingHours)

  return (
    currentDayOfTradeTimeSlots?.tradingDayStatus === 'Open' &&
    currentDayOfTradeTimeSlots.timeSlotAvailabilities.some((timeSlot) => timeSlot.timeSlotStatus === timeSlotStatus)
  )
}

const getNextDayOfTradeTimeSlots = (openingHours: StoreOrderTimeSlots) =>
  openingHours.dayOfTradeTimeSlots.length !== 0 ? openingHours.dayOfTradeTimeSlots[0] : undefined

const convertToDateTimeIso = (dateTime?: string) =>
  dateTime ? DateTime.fromISO(dateTime, { setZone: true }) : undefined

const getDateSuffix = (language: string, openingHours: StoreOrderTimeSlots | undefined): string | null => {
  const nextOpenDateTime = convertToDateTimeIso(openingHours!.nextOpenDateTime)
  const currentStoreDateTime = convertToDateTimeIso(openingHours!.currentStoreDateTime)

  if (!nextOpenDateTime || !currentStoreDateTime || currentStoreDateTime == undefined) return null

  const numberSecondsDifferent = currentStoreDateTime
    ? Math.abs(nextOpenDateTime.toSeconds() - currentStoreDateTime.toSeconds())
    : 0

  return numberSecondsDifferent > SECONDS_IN_DAY
    ? ', ' + nextOpenDateTime.toLocaleString({ day: 'numeric', month: 'long', locale: language })
    : ''
}
