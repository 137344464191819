import React from 'react'
import { useAuthenticationPopup } from '@dominos/hooks-and-hocs'
import { Card } from '@dominos/components'
import { AccountRegister } from '@dominos/components/universal-auth'
import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'

interface TrackerCreateAccountProps {
  id?: string
  customer?: Bff.Orders.OrderDetails.Header.Customer
}

export const TrackerCreateAccount = ({ id, customer }: TrackerCreateAccountProps) => {
  const { showPopup } = useAuthenticationPopup()
  const handleLogin = async () => {
    showPopup('login', { customer })
  }

  const handleRegister = async () => {
    showPopup('registerEmail', { customer })
  }

  const handleAdditional = (socialCustomer?: Partial<CreateAccountSceneProps>) => {
    showPopup('registerAdditional', { customer, socialCustomer })
  }

  const handlePopupBlocked = () => {
    showPopup('trackerRegisterPopupWarning')
  }

  return (
    <div id={id} style={{ marginTop: 8 }}>
      <Card testID={`account-register.card`}>
        <AccountRegister
          testID='account-register'
          onLogin={handleLogin}
          onRegister={handleRegister}
          onAdditional={handleAdditional}
          onPopupBlocked={handlePopupBlocked}
          inline
        ></AccountRegister>
      </Card>
    </div>
  )
}
