import { getProductItemSignature } from './get-product-item-signature'

export const getLineUpdatesWithChanges = (
  lines: BasketLine[] = [],
  changes: BasketLineUnionDependancy[] = [],
): BasketLine[] =>
  changes.reduce((memo, change) => {
    if (change.type === 'BasketLine') {
      const itemSignature = getProductItemSignature(change)
      const existingIndex = memo.findIndex((item) => getProductItemSignature(item) === itemSignature)

      if (memo[existingIndex] && memo[existingIndex].quantity + change.quantity === 0) {
        memo.splice(existingIndex, 1)

        return memo
      }

      if (existingIndex >= 0) {
        memo[existingIndex].quantity += change.quantity

        return memo
      }

      memo.push(change)
    }

    if (change.type === 'BasketCoupon') {
      change.items.forEach((couponItem) => {
        couponItem.lines.forEach((line) => {
          const itemSignature = getProductItemSignature(line)
          const lineIndex = memo.findIndex((appliedItem) => getProductItemSignature(appliedItem) === itemSignature)

          if (memo[lineIndex] && memo[lineIndex].quantity + line.quantity === 0) {
            memo.splice(lineIndex, 0)

            return memo
          }

          if (lineIndex >= 0) {
            memo[lineIndex].quantity += line.quantity

            return memo
          }

          memo.push(line)
        })
      })
    }

    return memo
  }, lines)
