import { TFunction } from 'i18next'

export const useOrderNowDescriptions = (serviceMethod: BffContext.ServiceMethods | undefined, t: TFunction) => {
  const serviceMethodTitle = serviceMethod === 'Pickup' ? t('Pick Up') : t('Deliver')

  const nowOrAsap = serviceMethod === 'Delivery' ? 'ASAP' : 'Now'
  const primaryTitle = t(`(method) ${nowOrAsap}`, {
    method: serviceMethodTitle,
    defaultValue: `{{method}} ${nowOrAsap}`,
  })

  const startOrderButtonText = t('Start Order', { defaultValue: 'Start Order' })

  return {
    primaryTitle,
    serviceMethodTitle,
    startOrderButtonText,
  }
}
