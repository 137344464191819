import { isProductMenuItemNew } from '@dominos/components'

export const getMenuItemByCode = (sections: ResolvedMenuSection[] | null, productCode?: string) => {
  if (!productCode || !sections?.length) {
    return undefined
  }

  return sections
    .flatMap((section) => section.data)
    .find(
      (item) => !isProductMenuItemNew(item) && item.type === 'Product' && item.code === productCode,
    ) as ProductMenuItem
}
