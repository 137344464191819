class PaymentDetailModel {
  constructor(private payment: Bff.Orders.OrderDetails.Payment) {}

  get paymentMethod() {
    return this.payment.paymentMethod || 'Cash'
  }

  get providerCode() {
    return this.payment.providerCode || 'Cash'
  }

  get amount() {
    return this.payment.amount
  }

  get orderPaymentId() {
    return this.payment.orderPaymentId
  }
}

export default PaymentDetailModel
