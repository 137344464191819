import { useCallback } from 'react'
import debounce from 'lodash/debounce'

import { ProviderAutoComplete } from './providers'

export const useAutoComplete = (
  Provider: (type: BffContext.ServiceMethods) => ProviderAutoComplete,
  type: BffContext.ServiceMethods,
) => {
  const {
    getPredictions,
    getAddress,
    reset,
    isLoading,
    predictions,
    address,
    input,
    error,
    resetError,
    resetPredictions,
    validateDeliveryAddressRequest,
  } = Provider(type)

  const getDebouncedPredictions = useCallback(
    debounce(
      (input: string | undefined, isValid: boolean) => {
        if (error) {
          resetError()
        }
        if (!input || !isValid) {
          reset(input)

          return Promise.resolve()
        }

        return getPredictions(input)
      },
      500,
      {
        leading: false,
        trailing: true,
      },
    ),
    [error],
  )

  return {
    /**
     * Retrieves place autocomplete predictions based on the supplied `input`,
     * and will reset `predictions` and other data when called with no request data.
     *
     * * Due to the `debounce` wrapping this function calling `await` against this will not pause code execution.
     */
    getPredictions: getDebouncedPredictions,
    /**
     * Retrieves details about the place identified by the given `geo.id`.
     */
    getAddress,
    /**
     * Combined loading state for all functions within this hook.
     */
    isLoading,
    /**
     * Result from calling `getPredictions`.
     */
    predictions,
    /**
     * Initial search result, returned back when appropriate to display.
     */
    input,
    /**
     * Result from calling `getAddress`.
     */
    address,
    /**
     * Return any Error.
     */
    error,
    /**
     * Reset autocomplete with input, errors, predictions.
     */
    reset,
    /**
     * Reset any Error.
     */
    resetError,
    /**
     * Reset any predictions.
     */
    resetPredictions,
    /**
     * Validate the delivery address request
     */
    validateDeliveryAddressRequest,
  }
}
