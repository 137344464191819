import React from 'react'
import { ActionButton } from '@dominos/components/buttons'
import { AuthenticationButtonProps } from '@dominos/components'

export const AuthenticationButton = (props: AuthenticationButtonProps) => {
  const { onAuthButtonClick, name, containerStyle, children, ...restProps } = props

  return (
    <div style={{ position: 'relative' }}>
      <ActionButton
        onPress={() => onAuthButtonClick(name)}
        containerStyle={{
          ...containerStyle,
          padding: '0 6px',
          minHeight: 30,
          minWidth: 87,
          height: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        {...restProps}
      />
      {children}
    </div>
  )
}
