import React from 'react'
import { DetailRow } from '@dominos/components/basket/detail-row'
import { useTranslation } from 'react-i18next'

interface OrderSubtotalProps {
  amount: number
  testId?: string
}

const OrderSubtotal = ({ amount, testId }: OrderSubtotalProps) => {
  const { t } = useTranslation('basket')

  return <DetailRow testID={`${testId}.order-subtotal`} label={t('Subtotal')} price={amount} fontWeight={'bold'} />
}

export { OrderSubtotal }
