import { useDevToggles } from '@dominos/hooks-and-hocs'
import { useMemo } from 'react'

type SetAttributesInput = {
  email?: string
  amount?: number
  currency?: string
  quantity?: number
  orderId?: string
  name?: string
  mobile?: string
  postCode?: string
  city?: string
  state?: string
  experience?: string
  menuCategories?: string[]
  menuSections?: string[]
  payments?: Bff.Orders.OrderDetails.Payment[]
  delivery?: boolean
  country?: string
}

export const useRokt = () => {
  const { isEnabled } = useDevToggles()
  const rokt = useMemo(() => window.roktClient ?? null, [window.roktClient])

  const available = useMemo(() => !!rokt && !isEnabled['disable-rokt-advertisements'], [rokt, isEnabled])

  const setAttributes = ({ menuCategories = [], menuSections = [], payments = [], ...attrs }: SetAttributesInput) =>
    available &&
    rokt?.setAttributes({
      email: attrs.email,
      amount: attrs.amount,
      currency: attrs.currency,
      quantity: attrs.quantity,
      confirmationref: attrs.orderId,
      firstname: attrs.name,
      mobile: attrs.mobile,
      zipcode: attrs.postCode,
      city: attrs.city,
      state: attrs.state,
      experience: attrs.experience,
      majorcat: menuCategories.join('/'),
      minorcat: menuSections.join('/'),
      paymenttype: payments[0]?.paymentMethod,
      delivery: attrs.delivery ? 'True' : 'False',
      country: attrs.country,
    })

  const changePage = (pageIdentifier: string) =>
    available &&
    rokt?.triggerPageChange({
      pageIdentifier,
    })

  const close = () => available && rokt?.closeAll()

  return {
    available,
    setAttributes,
    changePage,
    close,
  }
}
