import { AccountContainerProps } from '@dominos/components'
import React from 'react'
import css from './account-container.less'

export const AccountContainer = (props: AccountContainerProps) => (
  <div data-testid={props.testID} className={css.accountContainer} role='button' onClick={props.onClick}>
    {props.children}

    <div className={css.textContainer}>
      <p data-testid={`${props.testID}.title`} className={css.title}>
        {props.title}
      </p>
      {props.subtitle && (
        <p data-testid={`${props.testID}.subtitle`} className={css.subtitle}>
          {props.subtitle}
        </p>
      )}
    </div>
  </div>
)
