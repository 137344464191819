import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBasketValidation, useExpressCheckout } from '@dominos/hooks-and-hocs'
import { ExpressCheckoutButton } from './express-checkout-button'
import { ExpressCheckoutOrderPopup } from './express-checkout-order-popup'
import css from './express-checkout.less'

interface ExpressCheckoutProps {
  loading?: boolean
  testID?: string
  disabled?: boolean
}

export const ExpressCheckout = ({ loading, testID, disabled }: ExpressCheckoutProps) => {
  const { t } = useTranslation('checkout')
  const { isBasketValid } = useBasketValidation(t)
  const { isAvailable, paymentMethodLabel, paymentSetting } = useExpressCheckout()

  const [isModalVisible, setModalVisible] = useState<boolean>(false)

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onButtonPress = () => {
    if (!isBasketValid()) return
    showModal()
  }

  return isAvailable ? (
    <div>
      <ExpressCheckoutButton
        testID={`${testID}.button`}
        onPress={onButtonPress}
        disabled={disabled}
        text={t('ExpressCheckoutButtonText', { defaultValue: 'Express Checkout' })}
        fill
        loading={loading}
      />
      <p className={css.buttonInfo}>
        {t('ExpressCheckoutInfoText', {
          defaultValue: 'with {{paymentMethodLabel}}',
          paymentMethodLabel: paymentMethodLabel,
        })}
      </p>
      {isModalVisible && (
        <ExpressCheckoutOrderPopup
          onClose={hideModal}
          testID={`${testID}.popup`}
          donationEnabled={paymentSetting?.donationEnabled || false}
        />
      )}
    </div>
  ) : null
}
