import React from 'react'
import { CollapsableTitledCard } from '@dominos/components/cards'

export const ProductTermsAndConditionsViewContentWrapper = ({
  title,
  testID,
  children,
}: ProductTermsAndConditionsViewContentWrapperProps): JSX.Element => (
  <CollapsableTitledCard testID={`${testID}`} primaryTitle={title} startCollapsed>
    {children}
  </CollapsableTitledCard>
)
