import { FosEventInfoType } from 'olo-feature-fos'
import {
  useCurrentOrderDetails,
  useCurrentStore,
  useCustomer,
  useFeatures,
  useKiosk,
  useLaunchDarklyFlags,
  useReport,
} from '@dominos/hooks-and-hocs'
import { RouteComponentProps, useLocation } from '@reach/router'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NavigationConstants } from '../navigation-constants'
import { createFosEventsHandler, isNativeApp } from '@dominos/business/functions'
import { useSecurity } from '@dominos/hooks-and-hocs/logging/security'

const EXCLUDED_REPORT_SCREEN_VIEW_PATHS = [NavigationConstants.tracker]

export const HistoryListener: React.FC<RouteComponentProps> = ({ children }) => {
  const location = useLocation()
  const { customer } = useCustomer()
  const { reportScreenView, reportPageLoad } = useReport()
  const { flags } = useLaunchDarklyFlags()
  const { advanceOrder, basketHeaderData } = useCurrentOrderDetails()
  const currentStore = useCurrentStore()
  const [isFosFeatureEnabled] = useFeatures('IsFosVersionThreeEnabled')
  const rootReducer = useSelector((state: RootReducer) => state)
  const { sendSecurity } = useSecurity()
  const { sendFosEvents } = createFosEventsHandler(rootReducer, sendSecurity, flags, isFosFeatureEnabled)
  const { isKioskOrder, kioskId, storeNo, terminalId } = useKiosk()

  useEffect(() => {
    reportPageLoad(location.href, customer?.id, customer?.email, customer?.phoneNumber)
  }, [])

  useEffect(() => {
    if (EXCLUDED_REPORT_SCREEN_VIEW_PATHS.indexOf(location.pathname) < 0) {
      reportScreenView(
        location.href,
        location.pathname,
        customer?.id,
        customer?.email,
        customer?.phoneNumber,
        isKioskOrder ? { kioskId, storeNo, terminalId } : undefined,
      )
    }
  }, [location])

  // The webview needs to import the fos id from the app before sending the event
  const isAppRequireOrderData = location.pathname.startsWith(NavigationConstants.nativeAppCheckout)
  const isAppHasAllNeededData = !isAppRequireOrderData || !!(currentStore?.storeNo && basketHeaderData?.serviceMethod)
  const shouldSendNavigationEventFromApp =
    window.nativeAppFosData && window.nativeAppFosData.id === advanceOrder.id && isAppHasAllNeededData
  useEffect(() => {
    if (!isNativeApp() || shouldSendNavigationEventFromApp) {
      sendFosEvents({
        location: location.pathname,
        type: FosEventInfoType.NavigationEvent,
      })
    }
  }, [location.pathname, isFosFeatureEnabled, shouldSendNavigationEventFromApp])

  return <>{children}</>
}
