import { useDeliverySearchToolTip } from '@dominos/hooks-and-hocs/address/use-delivery-search-tool-tip/use-delivery-search-tool-tip'
import { ToolTipNotification } from '@dominos/components/notification/tool-tip-notification/tool-tip-notification'
import React from 'react'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

export const DeliverySearchToolTip = () => {
  const { showToolTip, onClose, heading, message, buttonLabel, imageDetails } = useDeliverySearchToolTip()
  const { reportAddressSearchToolTipShown } = useReportAutoCompleteDelivery()

  if (!showToolTip) return <></>

  reportAddressSearchToolTipShown()

  return (
    <ToolTipNotification
      heading={heading}
      message={message}
      buttonLabel={buttonLabel}
      testID={'delivery-search'}
      onClose={onClose}
      image={imageDetails}
      toolTipStyle={{ toolTipContainerWidth: '344px', positionOffsetTop: '-10px', arrowPosition: 'left' }}
    />
  )
}
