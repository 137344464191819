import { useValidation, validate } from '@dominos/business/functions/validation'
import { ActionButton, FancyTextField } from '@dominos/components'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import css from './other-tip-amount.less'

interface OtherTipAmountProps {
  onChange?: (value: number) => void
  min: number
  max: number
}

export const OtherTipAmount = ({ onChange, min, max }: OtherTipAmountProps) => {
  const { t } = useTranslation('checkout')
  const [tipAmount, setTipAmount] = useState('')
  const [validationError, setValidationError] = useState<string | null>(null)
  const { isFloat, overMinimum, underMaximum } = useValidation()

  const parseEUSafeFloat = (value: string) => Number(value.replace(',', '.'))

  const validateTip = useCallback(() => {
    let error = validate<string>(tipAmount, isFloat)
    error = !error ? validate<number>(parseEUSafeFloat(tipAmount), overMinimum(min), underMaximum(max)) : error

    setValidationError(error)
  }, [tipAmount])

  useEffect(() => {
    if (tipAmount === undefined) {
      setValidationError(null)

      return
    }

    validateTip()
  }, [tipAmount])

  const saveTip = () => {
    if (onChange && !validationError) {
      const parsedFloat = tipAmount ? parseEUSafeFloat(tipAmount) : 0
      onChange(parsedFloat)
    }
  }

  return (
    <div>
      <FancyTextField
        wrapperClassName={css.input}
        onChange={setTipAmount}
        testID='save-tip-input'
        placeholder={t('OtherTipAmountPlaceholderText', { defaultValue: 'Enter other amount' })}
        errorMessage={validationError}
      />
      <ActionButton
        testID='save-tip-button'
        textFontWeight='semibold'
        onPress={saveTip}
        disabled={!!validationError}
        containerStyle={{ backgroundColor: '#007aff', width: '100%', marginBottom: '10px' }}
        text={t('AddTipToOrderLabel', { defaultValue: 'Add tip to order' })}
      />
    </div>
  )
}
