import { ApolloError, useMutation } from '@apollo/client'
import { rootActions } from '@dominos/business'
import { placeOrderMutation } from '@dominos/business/queries/place-order'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useReport } from '../logging'
import { NextStep, PlaceOrderProps } from './place-order.interface'
import { useNativeAppStoredData } from '@dominos/hooks-and-hocs'

export const usePlaceOrder = ({
  handlePlaceOrderError,
  onOrderConfirmed,
  onPaymentNextStep,
}: {
  handlePlaceOrderError?: (error: ApolloError) => void
  onOrderConfirmed?: (orderId: string) => void
  onPaymentNextStep?: (nextStep: NextStep) => void
}) => {
  const orderPaymentId = useSelector((state: RootReducer) => state.currentOrderDetailsReducer.orderPaymentId)
  const { reportInfo } = useReport()
  const [confirmedOrderId, setConfirmedOrderId] = useState<string>()
  const [isPurchaseReported, setIsPurchaseReported] = useState(false)
  const { removeNativeAppCustomerDetails, storeNativeAppPurchaseEvent } = useNativeAppStoredData()

  const [sendRequest, { data, error, loading }] = useMutation<PlaceOrderProps>(placeOrderMutation, {
    errorPolicy: 'all',
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (data?.placeOrder?.orderId) {
      setConfirmedOrderId(data.placeOrder.orderId)

      if (data.placeOrder.nextStep) {
        onPaymentNextStep && onPaymentNextStep(data.placeOrder.nextStep)
      } else {
        if (!isPurchaseReported) {
          setIsPurchaseReported(true)
        }

        dispatch(rootActions.setOrderTime())
        onOrderConfirmed && onOrderConfirmed(data.placeOrder.orderId)
        removeNativeAppCustomerDetails()
      }
    }
  }, [data])

  useEffect(() => {
    if (error && handlePlaceOrderError) {
      handlePlaceOrderError(error)
    }
  }, [error])

  const placeOrder = async (orderId: string, payment: Bff.Orders.PlaceOrder.Payment) => {
    storeNativeAppPurchaseEvent()
    setConfirmedOrderId(undefined)

    const variables = {
      input: {
        orderId,
        saveDeliveryAddress: true,
        favouritePickupStore: true,
        payment: {
          ...payment,
          orderPaymentId: payment?.orderPaymentId || orderPaymentId,
        },
      },
    }

    if (payment && orderPaymentId !== payment.orderPaymentId) {
      reportInfo('orderPaymentId in the state does not match parameter payment.orderPaymentId')
    }

    await sendRequest({
      variables,
      update: (cache) => {
        cache.evict({ fieldName: 'availablePayments' })
      },
    }).catch(() => null)
  }

  return { confirmedOrderId, loading, isPurchaseReported, placeOrder }
}
