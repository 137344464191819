import React, { FunctionComponent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react'
import classnames from 'classnames'

import { ClearButton, Spinner } from '@dominos/components'
import { onButtonClick } from '@dominos/business/functions'

import { InputField } from '../input-field'
import css from './fancy-text-field.less'

export const FancyTextField: FunctionComponent<FancyTextFieldProps> = (props) => {
  const [focus, setFocus] = useState(false)
  const [hasValue, setHasValue] = useState(!!props.prefix || !!props.value || !!props.defaultValue)

  const inputRef = useRef<HTMLInputElement>(null)

  const onFocus = () => {
    setFocus(true)
  }

  const onBlur = () => {
    setFocus(false)
  }

  const onChange = (value: string) => {
    setHasValue(!!props.prefix || !!value)
    if (props.onChange) {
      props.onChange(value)
    }
  }

  const onClearInput = () => {
    if (props.value) {
      onChange('')
      inputRef.current && inputRef.current.focus()
    }
  }

  useEffect(() => {
    setHasValue(!!props.prefix || !!props.value || !!props.defaultValue)
  }, [props.prefix, props.value, props.defaultValue])

  const styleToggle = (
    defaultStyle: string,
    focusStyle: string,
    errorStyle: string,
    hasValueStyle?: string,
  ): string => {
    if (props.errorMessage) {
      return errorStyle
    }
    if (focus) {
      return focusStyle
    }

    if (hasValue && hasValueStyle) {
      return hasValueStyle
    }

    return defaultStyle
  }

  const showClearInputButton = useMemo(
    () => props.hasClearInputButton && props.value,
    [props.hasClearInputButton, props.value],
  )

  const onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    onButtonClick(event, onClearInput)
  }

  return (
    <div
      data-testid={`${props.testID}.fancy-text-field.wrapper`}
      className={classnames(
        {
          [css.wrapperDisabled]: props.disabled,
          [css.wrapper]: !props.disabled,
        },
        props.wrapperClassName,
      )}
      style={props.style}
    >
      <div className={props.hasClearInputButton ? css.inputContainerWithClearButton : css.inputContainer}>
        <label className={css.prefix}>{props.prefix}</label>
        <label
          className={styleToggle(
            css.placeholder,
            css.placeholderHasFocus,
            css.placeholderHasError,
            css.placeholderHasValue,
          )}
        >
          {`${props.placeholder}${props.errorMessage ? '*' : ''}`}
        </label>
        <InputField
          testID={`${props.testID}.fancy-text-field`}
          name={props.name}
          className={styleToggle(css.input, css.inputHasFocus, css.inputHasError)}
          disabled={props.disabled}
          defaultValue={props.defaultValue}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          tabIndex={props.tabIndex}
          value={props.value}
          secureTextEntry={props.secureTextEntry}
          autoComplete={props.autoComplete}
          autoCapitalize={props.autoCapitalize}
          type={props.type}
          inputMode={props.inputMode}
          ref={inputRef}
        />
        {props.isLoading && <Spinner testID={`${props.testID}.loading`} />}
        {showClearInputButton && (
          <div className={css.clearButtonContainer}>
            <ClearButton
              testID={`${props.testID}.clearInputButton`}
              onKeyPress={onKeyPress}
              onClick={onClearInput}
              tabIndex={0}
            />
          </div>
        )}
      </div>
      <div className={styleToggle(css.underline, css.underlineHasFocus, css.underlineHasError)} />
      {props.children}
      {props.info && <div>{props.info}</div>}
      {!props.hideEmptyErrorContainer ? (
        <div className={css.errorContainer}>{props.errorMessage}</div>
      ) : (
        props.errorMessage && <div className={css.errorContainer}>{props.errorMessage}</div>
      )}
    </div>
  )
}
