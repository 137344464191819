import React from 'react'

const PortionEmptyBottomLeftSideIcon = () => (
  <svg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.4973 23C13.076 23 14.5594 22.7005 15.9476 22.1016C17.3357 21.5026 18.5579 20.674 19.614 19.6159C20.6701 18.5577 21.4984 17.3353 22.0991 15.9486C22.6997 14.5619 23 13.0791 23 11.5C23 9.92093 22.6997 8.43805 22.0991 7.05137C21.4984 5.66469 20.6701 4.44227 19.614 3.38411C18.5579 2.32595 17.3357 1.4974 15.9476 0.898438C14.5594 0.299479 13.076 0 11.4973 0C9.92221 0 8.43968 0.299479 7.0497 0.898438C5.65972 1.4974 4.43669 2.32595 3.38059 3.38411C2.3245 4.44227 1.49704 5.66469 0.898225 7.05137C0.299408 8.43805 0 9.92093 0 11.5C0 13.0791 0.299408 14.5619 0.898225 15.9486C1.49704 17.3353 2.3254 18.5577 3.38331 19.6159C4.44122 20.674 5.66426 21.5026 7.05243 22.1016C8.44059 22.7005 9.92221 23 11.4973 23Z'
      fill='#0A7AFF'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.6425 11.3676H2.11606C2.11606 10.0672 2.35741 8.85059 2.84013 7.71769C3.32284 6.58479 3.99371 5.58981 4.85275 4.73275C5.71178 3.87569 6.70774 3.20383 7.84064 2.71718C8.97354 2.23052 10.1902 1.9872 11.4905 1.9872C12.7909 1.9872 14.0075 2.22954 15.1404 2.71422C16.2733 3.19891 17.2683 3.86978 18.1254 4.72684C18.1725 4.77397 18.2191 4.82152 18.2651 4.86948C18.2692 4.87355 18.2732 4.87763 18.2773 4.8817C19.1344 5.74074 19.8062 6.7367 20.2929 7.8696C20.7795 9.0025 21.0228 10.2191 21.0228 11.5195C21.0228 12.8199 20.7805 14.0365 20.2958 15.1694C19.8111 16.3023 19.1403 17.2973 18.2832 18.1543C17.4261 19.0114 16.4302 19.6823 15.2953 20.167C14.1604 20.6516 12.9428 20.894 11.6425 20.894V11.3676Z'
      fill='white'
    />
  </svg>
)

export { PortionEmptyBottomLeftSideIcon }
