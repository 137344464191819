import gql from 'graphql-tag'

export const deliverySearchStoreQuery = gql`
  query deliverySearchStoreQuery($latitude: Float, $longitude: Float, $deliveryLocationDateTime: String) {
    deliverySearchStore(
      latitude: $latitude
      longitude: $longitude
      deliveryLocationDateTime: $deliveryLocationDateTime
    ) {
      deliveryStoresAvailable {
        storeNo
      }
      canDeliver
      canNotDeliverCode
    }
  }
`
