import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 10,
  height: 10,
}

const Bell = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 10 10' data-testid='bell-icon'>
      <path
        fill={fill}
        d='M4.5 9a1.125 1.125 0 0 0 1.125 -1.125H3.375a1.125 1.125 0 0 0 1.125 1.125zm0.56 -8.382a0.563 0.563 0 1 0 -1.119 0A2.814 2.814 0 0 0 1.688 3.375c0 0.618 -0.281 3.375 -1.125 3.938h7.875c-0.844 -0.563 -1.125 -3.32 -1.125 -3.938 0 -1.361 -0.968 -2.498 -2.253 -2.757z'
        transform='translate(0.5 0)'
      />
    </svg>
  )
}

export { Bell }
