import { ActionButton, GenericCard, LinkButton } from '@dominos/components'
import { useAuthenticationPopup, useDominosTheme, useFeatures, useForgeRock } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { useLocation, useNavigate } from '@reach/router'
import { getNativeFeatures, isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './signup-prompt.less'

export const SignupPrompt = () => {
  const theme = useDominosTheme()
  const { t } = useTranslation('checkout')
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { showPopup } = useAuthenticationPopup()
  const [universalAuthEnabled, forgeRockIdentityProviderEnabled] = useFeatures(
    'UniversalAuthentication',
    'ForgeRockIdentityProvider',
  )
  const nativeUniversalAuthEnabled = getNativeFeatures('UniversalAuthentication')
  const isNative = isNativeApp()
  const { loginForgeRock, registerForgeRock } = useForgeRock()
  const nativeOnClickLogIn = () => nativeUniversalAuthEnabled && notifyNativeApp('login')
  const nativeOnClickCreateAccount = () => nativeUniversalAuthEnabled && notifyNativeApp('register')

  // origin of redirectTo
  const onClickLogIn = async () => {
    if (forgeRockIdentityProviderEnabled) {
      await loginForgeRock()
    } else {
      universalAuthEnabled
        ? showPopup('login')
        : navigate(NavigationConstants.login, { state: { redirectTo: pathname } })
    }
  }
  const onClickCreateAccount = async () => {
    if (forgeRockIdentityProviderEnabled) {
      await registerForgeRock()
    } else {
      universalAuthEnabled
        ? showPopup('register')
        : navigate(NavigationConstants.createAccount, { state: { redirectTo: pathname } })
    }
  }

  return (
    <GenericCard
      testID='Checkout.SignupPrompt'
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.15) 0 0.5px 10px 0px',
        border: 'solid 0.1px rgba(151, 151, 151, 0.4)',
        marginBottom: '10px',
        paddingTop: '14px',
      }}
    >
      <div className={css.title}>{t('GuestCheckoutTitle', { defaultValue: "New to Domino's?" })}</div>
      <ActionButton
        containerStyle={{ width: '100%', marginTop: '16px', backgroundColor: theme.colours.actionAlternative }}
        text={t('GuestCheckoutButton', { defaultValue: 'Create my account' })}
        testID={'create-account.button'}
        textFontWeight={'semibold'}
        onPress={isNative ? nativeOnClickCreateAccount : onClickCreateAccount}
      />
      <div className={css.loginContainer}>
        <div className={css.alreadyText}>{t('GuestCheckoutSubtext', { defaultValue: 'Already got an account?' })}</div>
        <LinkButton
          text={`${t('GuestCheckoutLink', {
            defaultValue: 'Log in',
          })}`}
          weight={'semibold'}
          style={{
            color: theme.colours.actionAlternative,
            fontSize: theme.webBorderRadius,
          }}
          testID={'log-in.button'}
          onPress={isNative ? nativeOnClickLogIn : onClickLogIn}
        />
      </div>
    </GenericCard>
  )
}
