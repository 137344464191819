import React from 'react'
import { useTranslation } from 'react-i18next'

import { AuthButton } from '@dominos/components'
import { EmailIcon } from '@dominos/res/images/icons/components'
import { AuthButtonProps } from '../auth-button-group.interface'

export const EmailButton = ({ testID, onReady }: AuthButtonProps & BaseProps) => {
  const { t } = useTranslation('login')

  const handlePress = () => onReady('Email')

  return (
    <AuthButton
      testID={testID}
      backgroundColor={'#3478F633'}
      label={t('signUpEmailTitle', { defaultValue: 'Sign in with email' })}
      onPress={handlePress}
    >
      <EmailIcon data-testid={`${testID}.icon`} fill={'#007AFF'} />
    </AuthButton>
  )
}
