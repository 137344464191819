import { useCallback, useEffect } from 'react'

const useEscape = (onDismiss: () => void) => {
  const handleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event && event.keyCode === 27) {
        onDismiss()
      }
    },
    [onDismiss],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleEscape, false)

    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [])
}

export { useEscape }
