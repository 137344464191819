import React, { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import { AddressSearchErrorItem } from './address-search-error-item'

type SearchErrorProps = ComponentProps<typeof AddressSearchErrorItem>

interface SearchErrorsProps extends BaseProps {
  // TODO: type to keys of server errors below
  errorCode: string | undefined
  isLoading: SearchErrorProps['isLoading']
  serviceMethod: 'Delivery' | 'Pickup'
  onRetry: () => void
}

// local AddressSearch errors
export const ERROR_NO_RESULTS_DELIVERY = 'NoResultsDelivery'
export const ERROR_NO_RESULTS_PICKUP = 'NoResultsPickup'
export const ERROR_INVALID_GOOGLE_ADDRESS_COMPONENT = 'InvalidGoogleAddressComponent'
export const ERROR_NO_CLOSEST_GEO_CODED_LOCATION = 'NoClosestGeoCodedLocation'
export const ERROR_USER_ADDRESS_MANUAL_CORRECTION_FAILED = 'UserAddressManualCorrectionFailed'

// server errors
export const ERROR_SERVER_ERROR_DELIVERY = 'GraphErrorDelivery'
export const ERROR_SERVER_ERROR_PICKUP = 'GraphErrorPickup'
export const ERROR_UNSAFE_AREA = 'UnSafeArea'
export const ERROR_OUT_OF_DELIVERY_AREA = 'OutOfDeliveryArea'

export const AddressSearchErrors = ({ testID, errorCode, isLoading, serviceMethod, onRetry }: SearchErrorsProps) => {
  const { t } = useTranslation('delivery-address')

  if (!errorCode) {
    return null
  }

  const genericErrorCode = errorCode.toLowerCase()
  const defaultButtons: SearchErrorProps['buttons'] = serviceMethod === 'Delivery' ? [{ type: 'ManualDelivery' }] : []

  if (genericErrorCode.startsWith(ERROR_INVALID_GOOGLE_ADDRESS_COMPONENT.toLowerCase())) {
    const reason = genericErrorCode.split(':')[1]

    return (
      <AddressSearchErrorItem
        testID={testID}
        title={t('AddressSearchMissingComponentString', { defaultValue: 'Address is incomplete' })}
        subtitle={t('AddressSearchMissingComponentStringSubtitle', {
          defaultValue:
            'It looks like the address selected is not a complete address. Please try another address that includes {{missingComponent}}.',
          missingComponent: reason,
        })}
        icon={'noLocationIcon'}
        isLoading={isLoading}
        buttons={defaultButtons}
      />
    )
  }

  if (genericErrorCode.startsWith(ERROR_NO_CLOSEST_GEO_CODED_LOCATION.toLowerCase())) {
    return (
      <AddressSearchErrorItem
        testID={testID}
        title={t('DeliveryAddressSearchNotMatchRadiusTitle', { defaultValue: 'No Address Found' })}
        subtitle={t('DeliveryAddressSearchNotMatchRadiusTitleSubtitle', {
          defaultValue:
            'It looks the location selected is not a valid address. Please try another address by moving the pin.',
        })}
        icon={'noLocationIcon'}
        isLoading={isLoading}
        buttons={defaultButtons}
      />
    )
  }

  if (
    genericErrorCode === ERROR_OUT_OF_DELIVERY_AREA.toLowerCase() ||
    genericErrorCode === ERROR_UNSAFE_AREA.toLowerCase()
  ) {
    return (
      <AddressSearchErrorItem
        testID={testID}
        title={t('AddressSearchOutOfDeliveryArea')}
        subtitle={t('AddressSearchOutOfDeliveryAreaSubtitle')}
        icon={'outsideDeliveryAreaIcon'}
        isLoading={isLoading}
        buttons={[{ type: 'ManualPickup' }]}
      />
    )
  }

  // TODO: handle error for pickup
  if (genericErrorCode === ERROR_NO_RESULTS_DELIVERY.toLowerCase()) {
    return (
      <AddressSearchErrorItem
        testID={testID}
        title={t('AddressSearchExcessString')}
        subtitle={t('AddressSearchExcessStringSubtitle')}
        icon={'backspaceIcon'}
        isLoading={isLoading}
        buttons={[{ type: 'ManualDelivery' }]}
      />
    )
  }

  if (genericErrorCode === ERROR_NO_RESULTS_PICKUP.toLowerCase()) {
    return (
      <AddressSearchErrorItem
        testID={testID}
        title={t('AddressSearchExcessString')}
        subtitle={t('AddressSearchExcessStringSubtitle')}
        icon={'backspaceIcon'}
        isLoading={isLoading}
        buttons={defaultButtons}
      />
    )
  }

  // TODO: handle error for pickup
  if (genericErrorCode === ERROR_SERVER_ERROR_PICKUP.toLowerCase()) {
    return (
      <AddressSearchErrorItem
        testID={testID}
        title={t('AddressSearchGenericError')}
        subtitle={t('AddressSearchGenericErrorSubtitle')}
        icon={'noLocationIcon'}
        isLoading={isLoading}
        buttons={[{ type: 'Retry', onPress: onRetry }]}
      />
    )
  }

  if (genericErrorCode === ERROR_USER_ADDRESS_MANUAL_CORRECTION_FAILED.toLowerCase()) {
    return (
      <AddressSearchErrorItem
        testID={testID}
        title={t('AddressSearchUserManualCorrectionError', { defaultValue: 'Street number not recognized' })}
        subtitle={t('AddressSearchUserManualCorrectionErrorSubtitle', {
          defaultValue: 'Try searching again, or switch to manual search to continue.',
        })}
        icon={'noLocationIcon'}
        isLoading={isLoading}
        buttons={[{ type: 'ManualDelivery' }]}
      />
    )
  }

  return (
    <AddressSearchErrorItem
      testID={testID}
      title={t('AddressSearchGenericError')}
      subtitle={t('AddressSearchGenericErrorSubtitle')}
      icon={'noLocationIcon'}
      isLoading={isLoading}
      buttons={[
        { type: 'ManualDelivery', secondary: true },
        { type: 'Retry', onPress: onRetry },
      ]}
    />
  )
}
