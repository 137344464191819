import { NavigationConstants } from '@dominos/navigation'
import { useLocation } from '@reach/router'
import { useLastOrderDetails } from '@dominos/hooks-and-hocs/last-order-handler'

const PICKUP_PATH_NAMES = [NavigationConstants.storeSearch]
const DELIVERY_PATH_NAMES = [NavigationConstants.deliveryAddressList]

export const isPickup = (pathname?: string) => PICKUP_PATH_NAMES.includes(pathname)
export const isDelivery = (pathname?: string) => DELIVERY_PATH_NAMES.includes(pathname)

export const useGetLastOrderServiceMethod = () => {
  const { pathname } = useLocation()
  const { lastServiceUsed } = useLastOrderDetails()

  const serviceMethod: BffContext.ServiceMethods = isPickup(pathname)
    ? 'Pickup'
    : isDelivery(pathname)
    ? 'Delivery'
    : lastServiceUsed

  return {
    serviceMethod,
  }
}
