import React from 'react'

import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'

import { ConfirmAddress } from './confirm-address'
import css from './confirm-address-scene.less'

// TODO: rename to `ConfirmDeliveryScene`
export const ConfirmAddressScene = () => (
  <StackNavigationBase testID={'confirm-address-scene'} header={<SimpleNavigationTitle showAccount showBackButton />}>
    <div className={css.wrapper}>
      <ConfirmAddress testID={'confirm-address'} />
    </div>
  </StackNavigationBase>
)
