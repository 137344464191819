import { ApolloError } from '@apollo/client'
import { useAlert } from '@dominos/hooks-and-hocs'
import { useReport } from '@dominos/hooks-and-hocs/logging'
import { ErrorDefinition, ErrorDefinitions } from '@dominos/interfaces/errors'
import { GraphQLError } from 'graphql'
import { TFunction } from 'i18next'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationType, PopUpNotification } from '../notification'
import css from './error.less'
import { genericErrorDefinitions } from './generic-errors'

enum GenericErrors {
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
}

export interface ErrorProps {
  tns: TFunction
  errorDefinitions: ErrorDefinitions
  error: ApolloError | null
  onClose?: () => void
}

interface ErrorType extends ErrorDefinition {
  translatedError: string
}

interface ErrorTypes {
  [key: string]: ErrorType[]
}

export const Error = ({ tns, errorDefinitions, error, onClose }: ErrorProps) => {
  const { showAlert } = useAlert()
  const { reportValidationMessage } = useReport()
  const { t } = useTranslation('validation')
  const [showPopup, setShowPopup] = useState(false)
  const [popupText, setPopupText] = useState<string[]>([])
  const [errorText, setErrorText] = useState<string[]>([])
  const [errorMessages, setErrorMessages] = useState<ErrorTypes>({
    alert: [],
    popup: [],
    errorText: [],
  })

  const mapErrorToDefinition = (code: string, source: 'Graph' | 'Network'): ErrorType => {
    const errorCode = code

    // has custom error defined
    if (errorDefinitions[errorCode]) {
      const mappedError: ErrorDefinition = errorDefinitions[errorCode]

      return {
        ...mappedError,
        translatedError: tns(mappedError.translation.key, mappedError.translation.options),
      }
    }

    // matches generic error code
    if (genericErrorDefinitions[errorCode]) {
      const mappedError: ErrorDefinition = genericErrorDefinitions[errorCode]

      return {
        ...mappedError,
        translatedError: t(mappedError.translation.key, mappedError.translation.options),
      }
    }

    // does not match known error
    const otherError: ErrorDefinition =
      genericErrorDefinitions[source === 'Graph' ? GenericErrors.UNKNOWN_ERROR : GenericErrors.NETWORK_ERROR]

    return {
      ...otherError,
      translatedError: t(otherError.translation.key, otherError.translation.options),
    }
  }

  const mapErrorToDisplay = useCallback(
    (apolloError) => {
      const tErrors: ErrorTypes = {
        alert: [],
        popup: [],
        errorText: [],
      }

      apolloError?.graphQLErrors?.forEach((err: GraphQLError) => {
        const mappedError: ErrorType = mapErrorToDefinition(err.extensions?.code, 'Graph')

        if (mappedError.display) {
          tErrors[mappedError.display].push(mappedError)
        }
      })

      if (apolloError?.networkError) {
        const mappedError: ErrorType = mapErrorToDefinition('NETWORK_ERROR', 'Network')

        if (mappedError.display) {
          tErrors[mappedError.display].push(mappedError)
        }
      }

      return tErrors
    },
    [error],
  )

  useEffect(() => {
    const mappedErrors = mapErrorToDisplay(error)
    setErrorMessages(mappedErrors)
  }, [error])

  useEffect(() => {
    errorMessages.alert.forEach(({ translation: { key }, translatedError, onError, callback }) => {
      if (onError !== undefined) {
        onError()
      }

      showAlert(translatedError, key)

      if (callback !== undefined) {
        callback()
      }
    })
  }, [errorMessages.alert])

  useEffect(() => {
    setPopupText(errorMessages.popup.map(({ translatedError }) => translatedError))

    if (errorMessages.popup.length > 0) {
      setShowPopup(true)

      errorMessages.popup.forEach(({ translation: { key }, translatedError }) => {
        reportValidationMessage(translatedError, key)
      })
    }
  }, [errorMessages.popup])

  useEffect(() => {
    setErrorText(errorMessages.errorText.map(({ translatedError }) => translatedError))
  }, [errorMessages.errorText])

  const onPopupClose = () => {
    if (onClose) {
      onClose()
    }

    setShowPopup(false)
  }

  return (
    <>
      {showPopup && (
        <PopUpNotification
          notificationType={NotificationType.info}
          heading={popupText.join('<br />')}
          onClose={onPopupClose}
        />
      )}

      {errorText.length > 0 && (
        <div data-testid='error-message' className={css.errorMessage}>
          {errorText.map((msg) => (
            <p key={`${msg}-1`}>{msg}</p>
          ))}
        </div>
      )}
    </>
  )
}
