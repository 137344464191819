import { useApolloClient } from '@apollo/client'
import { rootActions } from '@dominos/business'
import { swapSetProductsQuery } from '@dominos/business/queries'
import { SwapSetProductCacheState } from '@dominos/interfaces'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useSwapSetProductsCaching = (
  storeNo: number | undefined,
  serviceMethod: BffContext.ServiceMethods | undefined,
  orderTime?: string | undefined,
) => {
  const dispatch = useDispatch()
  const swapSetCache = useSelector((state: RootReducer) => state.voucherReducer.swapSets)
  const client = useApolloClient()

  const swapSetToPrefetch = swapSetCache.filter((cache) => !cache.cached)

  const prefetchSwapSetProducts = async (voucherCode: string, itemNo: number) =>
    client.query({
      query: swapSetProductsQuery,
      variables: { storeNo, serviceMethod, voucherCode, orderTime, itemNo },
    })

  useEffect(() => {
    if (!swapSetToPrefetch.length) {
      return
    }

    const successfullyPrefetched: SwapSetProductCacheState[] = []

    Promise.all(
      swapSetToPrefetch.map((swapSet) =>
        prefetchSwapSetProducts(swapSet.voucherCode, swapSet.itemNo).then((result) => {
          if (!result.loading && !result.error && result.data?.swapSetProducts) {
            successfullyPrefetched.push(swapSet)
          }
        }),
      ),
    ).finally(() => {
      dispatch(rootActions.swapSetProductsCached(successfullyPrefetched))
    })
  }, [JSON.stringify(swapSetToPrefetch)])
}
