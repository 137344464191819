import { rootActions } from '@dominos/business/root.actions'
import { getType } from 'typesafe-actions'

import { UpsellProps } from '@dominos/components/product/product-upsell/product-upsell.interface'

const productUpsellInitState: UpsellProps = {
  productUpsellPopupDismissed: false,
}

export const upsellReducer: import('redux').Reducer<UpsellProps> = (
  state: UpsellProps = productUpsellInitState,
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.storeSelected):
    case getType(rootActions.logout):
    case getType(rootActions.placeOrderSuccess):
    case getType(rootActions.restartOrder):
      return {
        productUpsellPopupDismissed: false,
      }

    case getType(rootActions.updateProductUpsell):
      return {
        productUpsellPopupDismissed: action.payload.productUpsellPopupDismissed,
      }
    default:
      return state
  }
}
