import { useBasket } from '@dominos/hooks-and-hocs'
import { useEffect, useState } from 'react'

export const useIsValidatingBasket = () => {
  const [isValidating, setIsValidating] = useState<boolean>(false)
  const { loadedAt, pending } = useBasket()

  useEffect(() => {
    if (pending) {
      setIsValidating(true)
    }
  }, [pending])

  useEffect(() => {
    if (isValidating) {
      setIsValidating(false)
    }
  }, [loadedAt])

  const waitForValidation = () => setIsValidating(true)

  return {
    isValidating,
    waitForValidation,
  }
}
