import { BasketLineData } from '@dominos/business/functions/basket'
import { mapMenuItemToOptionItemData, mapMenuItemToSauceItemData } from '@dominos/business/functions/menu'
import { productIngredientsTransform } from '@dominos/business/functions/product'
import { isProductMenuItemNew, OptionIcon, ProductIngredientCard, SauceIcon } from '@dominos/components'
import { useBreakpoints } from '@dominos/hooks-and-hocs'
import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { getScrollProvider, scrollTo } from '../../functions/get-scroll-provider'
import { CustomisationCard } from '../customisation-card'
import { NUM_COLUMNS } from '../customisations.constants'

export const CustomisationHalfNHalfSide = ({
  currentProduct,
  onRecipeSelected,
  onIngredientSelected,
  currentSize,
}: {
  currentProduct: BasketLineData
  onRecipeSelected: (key: 'sauce' | 'base' | 'options') => (change: TouchableGridElement) => void
  onIngredientSelected: (changes: BasketLineChange[]) => void
  currentSize?: ProductSize
}) => {
  const { isMobile } = useBreakpoints()
  const scrollRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation('menu')
  const setScrollOffset = (distance: number) => scrollTo(distance, getScrollProvider(isMobile, scrollRef.current))

  // TODO: Ideally we find a way of typing the currentProduct (basketLineData) to the menuItem Type to save having to do these checks
  const currentProductItem =
    currentProduct.item &&
    !isProductMenuItemNew(currentProduct.item) &&
    (currentProduct.item.type === 'Product' || currentProduct.item.type === 'Portion')
      ? currentProduct.item
      : undefined

  const {
    recipeIngredientCodes: recipeToppingCodes,
    currentIngredients: currentToppings,
    possibleIngredients: possibleToppings,
    ingredientRules: toppingRules,
  } = useMemo<{
    recipeIngredientCodes: string[]
    currentIngredients: IngredientItemProps[]
    possibleIngredients: IngredientItemProps[]
    ingredientRules: IngredientRules | undefined
  }>(
    () => productIngredientsTransform(currentProduct?.toppings || [], currentSize),
    [currentSize, currentProduct.toppings],
  )

  const calculateCurrentIngredientCount = (toppings: BasketLineChange[]): number => {
    const { currentIngredients } = productIngredientsTransform(toppings || [], currentSize)

    return currentIngredients.length
  }

  if (!currentProductItem) {
    return null
  }

  return (
    <>
      <CustomisationCard
        testID={'Sauce-Selector'}
        primaryTitle={t('Sauce')}
        numColumns={NUM_COLUMNS}
        mapMenuItemToItemData={mapMenuItemToSauceItemData}
        renderItem={SauceIcon}
        item={currentProductItem}
        onItemSelected={onRecipeSelected('sauce')}
        basketLineEdit={currentProduct.sauce}
        sizeCode={currentSize?.code || undefined}
      />

      {currentSize?.swaps?.options?.rule.max &&
        Array(currentSize?.swaps?.options?.rule.max)
          .fill(0)
          .map((__, index) => (
            <CustomisationCard
              key={index}
              testID={'Option-Selector'}
              primaryTitle={t('Option')}
              numColumns={NUM_COLUMNS}
              mapMenuItemToItemData={mapMenuItemToOptionItemData(index, t)}
              renderItem={OptionIcon}
              item={currentProductItem}
              onItemSelected={onRecipeSelected('options')}
              basketLineEdit={
                currentProduct.options?.find((op) => op.index === index && op.action === 'Add') ||
                currentProduct.options?.find((op) => op.index === index && op.action === 'Remove')
              }
              sizeCode={currentSize?.code || undefined}
            />
          ))}

      <ProductIngredientCard
        primaryTitle={t('Ingredients on this Pizza')}
        recipeIngredientCodes={recipeToppingCodes}
        currentIngredients={currentToppings}
        possibleIngredients={possibleToppings}
        rules={toppingRules}
        onItemChange={onIngredientSelected}
        toppingLineChange={currentProduct.toppings || []}
        onSectionChange={setScrollOffset}
        calculateCurrentIngredientCount={calculateCurrentIngredientCount}
      />
    </>
  )
}
