import React from 'react'
import { getCountryConfig } from '@dominos/business/functions'
import css from './diagnostics-info.less'

export const DiagnosticsInfo = () => {
  const { BUILD_BUILDNUMBER } = getCountryConfig()

  return (
    <div data-testid={'diagnostics-info.build-number'} className={css.container}>
      {BUILD_BUILDNUMBER}
    </div>
  )
}
