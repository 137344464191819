import { createContext } from 'react'

export type SwrveEventKeys =
  | 'account_status'
  | 'account_created'
  | 'user_login_email'
  | 'user_login_google'
  | 'user_login_apple'
  | 'user_login_facebook'

export interface IPayLoad {
  [key: string]: string
}

export interface SwrveClient {
  reset: (customerId?: string, onReset?: () => void, autoPushSubscribe?: boolean) => void
  event: (eventName: SwrveEventKeys, payload?: IPayLoad) => void
}

const defaultClient: SwrveClient = {
  reset: () => {},
  event: () => {},
}

export const SwrveContext = createContext<SwrveClient>(defaultClient)
