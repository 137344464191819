import React from 'react'
import css from './kiosk-language-button.less'

interface KioskLanguageButtonProps {
  isSelected: boolean
  language: string
  image: string
  label: string
  onSelect: (value: string) => void
  testId?: string
}

export const KioskLanguageButton = ({
  isSelected,
  language,
  image,
  label,
  onSelect,
  testId,
}: KioskLanguageButtonProps) => (
  <div
    data-testid={`${testId}-kiosk-language-button`}
    onClick={() => onSelect(language)}
    className={isSelected ? `${css.container} ${css.selected}` : css.container}
  >
    <img src={image} alt={language} className={css.flag} />
    <span>{label}</span>
    {isSelected && (
      <span data-testid={`${testId}-tick`} className={css.tick}>
        ✔
      </span>
    )}
  </div>
)
