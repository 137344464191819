import { useMenu } from '@dominos/hooks-and-hocs'
import { useMemo, useState } from 'react'

type MenuItemDependents = Bff.Menu.old.MenuItemDependents

const useFetchProducts = (
  productCodes?: string[],
): {
  products: Map<string, MenuItemDependents>
  fetchProducts: (productCodes: string[]) => void
} => {
  const [requestedCodes, setRequestedCodes] = useState<string[]>(productCodes ?? [])
  const [products, setProducts] = useState(new Map<string, MenuItemDependents>())
  const { itemsByCode } = useMenu()

  useMemo(() => {
    requestedCodes?.forEach((productCode) => {
      const product = itemsByCode && itemsByCode[productCode]
      if (product) {
        setProducts(new Map(products.set(productCode, product)))
      }
    })
  }, [requestedCodes])

  const fetchProducts = (productCodesToFetch: string[]) => {
    setRequestedCodes((prev) => [...prev, ...productCodesToFetch])
  }

  return {
    products,
    fetchProducts,
  }
}

export { useFetchProducts }
