import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 20,
  height: 15,
}

const SelectionTick = (props: SvgIconProps) => {
  const fill = props.fill || 'none'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 20 15' fill={fill} xmlns='http://www.w3.org/2000/svg'>
      <path d='M18 2L7 13L2 8' stroke='#4A4A4A' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  )
}

export { SelectionTick }
