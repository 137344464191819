import gql from 'graphql-tag'

export const menuPageHeadersQuery = gql`
  query menuPageHeaders($storeNo: Int!, $serviceMethod: ServiceMethodEnum!, $requestTime: DateTime) {
    menuTransitional(storeNo: $storeNo, serviceMethod: $serviceMethod, requestTime: $requestTime) {
      pages {
        code
        media {
          name
          colour
          logo {
            url
          }
        }
      }
    }
  }
`
