import { useApolloClient, useMutation } from '@apollo/client'
import { idTokenUtils } from '@dominos/business/functions/customer'
import { notifyNativeApp } from '@dominos/business/functions/native-app'
import { deleteCustomer, DeleteCustomerResponse } from '@dominos/business/queries/delete-customer'
import { useCurrentOrderDetails, useReport } from '@dominos/hooks-and-hocs'

export const useAccountDelete = () => {
  const client = useApolloClient()
  const { deleteToken } = idTokenUtils()
  const { reportAccountDelete } = useReport()
  const { orderId } = useCurrentOrderDetails()

  const [deleteAccount, { loading: inProgress, error }] = useMutation<DeleteCustomerResponse>(deleteCustomer, {
    onCompleted(data) {
      if (data && data.deleteCustomer) {
        deleteToken()
        reportAccountDelete({ orderId, success: true })
        client.clearStore()
        notifyNativeApp('delete-account-successful')
      }
    },
    onError(error) {
      reportAccountDelete({ orderId, success: false })
    },
  })

  return {
    deleteAccount,
    error,
    inProgress,
  }
}
