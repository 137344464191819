import gql from 'graphql-tag'

export const individualStoreQueryV2 = gql`
  query individualStoreWithTimeSlots(
    $storeNo: Int!
    $orderTime: String
    $serviceMethod: ServiceMethodEnum!
    $locationId: String
  ) {
    store(storeNo: $storeNo, orderTime: $orderTime) {
      storeNo
      onlineOrderingEnabled
      media {
        name
        displayAddress
      }
      geo {
        longitude
        latitude
      }
      timeZone
      loyalty {
        isEnabled
      }
      priceInfo {
        taxRate
        minOrderPricePickup
        minOrderPriceDelivery
      }
      pickupSubTypes
      deliverySubTypes
      serviceMethods {
        pickup
        delivery
        dineIn
        message
      }
      features {
        featureName
        isEnabled
        settings {
          ... on TipTheDriverSettings {
            minimumTip
            maximumTip
            presets {
              value
              label
            }
          }
        }
      }
    }
    orderingTimeSlots(serviceMethod: $serviceMethod, storeNo: $storeNo, locationId: $locationId) {
      storeNo
      currentStoreStatus
      currentStoreStatusMessage
      nextOpenDateTime
      leadTime
      dayOfTradeTimeSlots {
        dayOfTrade
        tradingDayStatus
        timeSlotAvailabilities {
          slotTime
          timeSlotStatus
        }
      }
    }
  }
`
