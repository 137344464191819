import { DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const ExtraLarge = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR

  return (
    <svg width='63px' height='61px' viewBox='0 0 63 68' key={color}>
      <g>
        <ellipse fill='#FFFFFF' stroke={selectedColor} cx='31.6' cy='30.6' rx='20.3' ry='19.5' />
      </g>
      <g>
        <ellipse fill='#FFFFFF' stroke={selectedColor} cx='31.6' cy='30.6' rx='29.5' ry='28.4' />
      </g>
      <g transform='translate(-169.000000, -62.000000)'>
        <g transform='translate(170.631226, 64.070312)'>
          <ellipse fill='#FFFFFF' stroke={selectedColor} strokeWidth='3' cx='29.9' cy='28.5' rx='29.5' ry='28.4' />
        </g>
      </g>
      <g>
        <path
          fill='none'
          stroke={selectedColor}
          strokeWidth='3'
          strokeDasharray='2, 2'
          d='M31.6,11.1c5.6,0,20.3,5,20.3,19.5c0,10.8-9.1,19.5-20.3,19.5s-20.3-8.7-20.3-19.5S20.4,11.1,31.6,11.1z'
        />
      </g>
    </svg>
  )
}
