import React, { ComponentProps, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'

import { GenericCard } from '@dominos/components'

// TODO: extract into `@dominos/components`
import { AddressInfo } from '@dominos/scenes/confirm-address-scene/confirm-address/address-info'
import css from './confirm-pickup.less'

interface ConfirmPickupProps extends BaseProps {
  address?: ComponentProps<typeof SelectedAddress>['address']
}

export const ConfirmPickup = ({ testID, address }: ConfirmPickupProps) => {
  useEffect(() => {
    if (!address) {
      navigate(-1)
    }
  }, [address])

  if (!address) {
    return null
  }

  return (
    <div data-testid={testID} className={css.wrapper}>
      <SelectedAddress testID={`${testID}.address`} address={address} />
      <StoreResults testID={`${testID}.results`} />
    </div>
  )
}

interface SelectedAddressProps extends BaseProps {
  address: DeliveryAddressRequest
}

const SelectedAddress = ({ testID, address }: SelectedAddressProps) => (
  <GenericCard testID={testID} title={undefined}>
    <AddressInfo testID={`${testID}.info`} address={address} showClearButton />
  </GenericCard>
)

type StoreResultsProps = BaseProps

const StoreResults = ({ testID }: StoreResultsProps) => {
  const { t } = useTranslation('store-search')

  return (
    <GenericCard testID={testID} title={t('PickupSearchTitle')}>
      <p>TODO: store results</p>
    </GenericCard>
  )
}
