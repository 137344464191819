import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { StaticSvgIcon } from '@dominos/res'
import React, { useLayoutEffect, useState } from 'react'

import { Portal } from '../portal'
import css from './pop-up-notification.less'

const DefaultNotificationTimeout = getCountryConfig().NOTIFICATION_TIMEOUT
const iconWidth = 45
const iconHeight = 45

export enum NotificationType {
  confirmation = 'Confirmation',
  info = 'Info',
}
export interface PopUpNotificationProps extends Partial<BaseProps> {
  heading: string
  message?: string
  infinite?: boolean
  timeout?: number
  onClose?: () => void
  notificationType?: 'Confirmation' | 'Info'
}

export const PopUpNotification = ({
  testID = 'notification',
  heading,
  message,
  infinite = false,
  timeout,
  onClose,
  notificationType = NotificationType.confirmation,
}: PopUpNotificationProps) => {
  const [transitionOut, setTransitionOut] = useState<boolean>(false)
  const notificationLifetime = timeout ?? DefaultNotificationTimeout
  const icon = notificationType === NotificationType.confirmation ? 'confirmationTick' : 'infoIcon'

  useLayoutEffect(() => {
    let timer: NodeJS.Timeout

    if (!infinite) {
      timer = setTimeout(() => {
        setTransitionOut(true)
      }, notificationLifetime)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  const onAnimEnd = () => {
    onClose?.()
  }

  return (
    <Portal id={testID} zIndex={6}>
      <div
        data-testid={testID}
        role='dialog'
        onClick={onClose}
        className={transitionOut ? css.fadeOut : undefined}
        onTransitionEnd={onAnimEnd}
      >
        <div className={css.wrapper}>
          <div data-testid={icon} aria-hidden={true} className={css.icon}>
            <StaticSvgIcon name={icon} isUnstyled={true} width={iconWidth} height={iconHeight} />
          </div>
          <div className={css.content}>
            <div data-testid='notification-title' className={css.heading} role='alert'>
              {heading}
            </div>
            {message && (
              <div data-testid={`${testID}.message`} className={css.message}>
                {message}
              </div>
            )}
          </div>
        </div>
      </div>
    </Portal>
  )
}
