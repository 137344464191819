import { useEffect } from 'react'
import { AdyenPaymentMethodExtended } from '../payment-adyen.interface'
import { getCountryConfig, isNativeApp, postWebViewMessage } from '@dominos/business/functions'
import Core from '@adyen/adyen-web/dist/types/core'
import { AdyenState } from '../payment-adyen'

// Payment methods which should trigger native module in mobile app
const NATIVE_PAYMENT_METHODS = ['googlepay']

interface Props {
  setAdyenComponentState: (data: AdyenState) => void
  setTriggerAutoPlaceOrder: (trigger: boolean) => void
  adyenPaymentMethod: AdyenPaymentMethodExtended
  adyenCheckout?: Core
  isMethodAvailable: boolean
  isSelected: boolean
  adyenAmount: number
}

const useAdyenNativePayment = ({
  setAdyenComponentState,
  setTriggerAutoPlaceOrder,
  adyenPaymentMethod,
  adyenCheckout,
  isMethodAvailable,
  isSelected,
  adyenAmount,
}: Props) => {
  const config = getCountryConfig()
  const useNativePayment =
    isNativeApp() &&
    adyenCheckout &&
    isMethodAvailable &&
    isSelected &&
    NATIVE_PAYMENT_METHODS.includes(adyenPaymentMethod.type)

  useEffect(() => {
    if (!useNativePayment) return

    postWebViewMessage({
      type: 'adyen-config',
      adyenConfig: {
        ...adyenCheckout.options,
        amount: {
          currency: config.CURRENCY_CODE,
          value: adyenAmount,
        },
      },
    })

    const onNativeAdyenSubmit = (event: CustomEvent) => {
      setAdyenComponentState({
        data: {
          paymentMethod: event.detail,
        },
        isValid: true,
      })
      setTriggerAutoPlaceOrder(true)
    }
    window.addEventListener('native-adyen-submit', onNativeAdyenSubmit)

    return () => window.removeEventListener('native-adyen-submit', onNativeAdyenSubmit)
  }, [useNativePayment])

  const startNativePayment = () => {
    postWebViewMessage({ type: 'start-native-payment', paymentMethodType: adyenPaymentMethod.type })
  }

  return useNativePayment ? startNativePayment : undefined
}

export { useAdyenNativePayment }
