import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web'
import { Config, getDomainFromUrl } from '../common'
import { APPINSIGHTS_CONFIG_STORAGEPREFIX } from '@dominos/business/constants'

export const setupAI = (config: Config) => {
  if (!config.APPINSIGHTS_INSTRUMENTATION_KEY) {
    return null
  }

  const graphDomainName = getDomainFromUrl(config.GRAPH_URL)

  const applicationInsights = new ApplicationInsights({
    config: {
      instrumentationKey: config.APPINSIGHTS_INSTRUMENTATION_KEY,
      distributedTracingMode: DistributedTracingModes.AI,
      enableCorsCorrelation: true,
      correlationHeaderDomains: graphDomainName ? [graphDomainName] : undefined,
      storagePrefix: APPINSIGHTS_CONFIG_STORAGEPREFIX,
    },
  })
  applicationInsights.loadAppInsights()
  applicationInsights.addTelemetryInitializer((envelope) => {
    envelope.tags!['ai.cloud.role'] = config.APPINSIGHTS_ROLE_NAME
  })

  return applicationInsights
}
