import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AboutPricingPopup from './about-pricing-popup'
import { StaticSvgIcon } from '@dominos/res'
import css from './about-pricing-popup.less'

interface AboutPricingProps {
  testID: string
}

export const AboutPricing = ({ testID }: AboutPricingProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('menu')
  const [showAboutPricingPopup, setShowAboutPricingPopup] = useState(false)
  const handleAboutPricingClick = () => setShowAboutPricingPopup(!showAboutPricingPopup)

  return (
    <div
      className={css.aboutPricingContainer}
      style={{ color: theme.colours.hyperlink }}
      data-testid={`${testID}.about-pricing`}
      onClick={handleAboutPricingClick}
    >
      <StaticSvgIcon name={'infoIconSolid'} isUnstyled fill={theme.colours.hyperlink} width={17} height={17} />
      <span style={{ marginLeft: 5 }}>{t('AboutOurPricing', { defaultValue: 'About Our Pricing' })}</span>
      {showAboutPricingPopup && (
        <AboutPricingPopup testID={`${testID}.about-pricing`} onClose={handleAboutPricingClick} />
      )}
    </div>
  )
}
