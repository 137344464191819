import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { storeQuery } from '@dominos/business/queries'
import { useApolloClient } from '@apollo/client'
import { setSavedStores } from '@dominos/business/actions'

// Returns saved stores after updating with latest data
// then filtering out any which are no longer available
export const useSavedStores = () => {
  const recentStores = useSelector((state: RootReducer) => state.storeReducer.savedStores)
  const dispatch = useDispatch()
  const client = useApolloClient()
  const [stores, setStores] = useState<TimeAugmentedStore[]>([])

  useEffect(() => {
    ;(async () => {
      const updatedStores = await Promise.all(
        recentStores.map(async (store) => {
          const { data } = await client.query<{ store: Bff.Stores.Store }>({
            query: storeQuery,
            variables: {
              storeNo: store.storeNo,
            },
          })

          return {
            ...store,
            ...data.store,
          }
        }),
      )
      const availableStores = updatedStores.filter(({ onlineOrderingEnabled }) => onlineOrderingEnabled)
      dispatch(setSavedStores(availableStores))
      setStores(availableStores)
    })()
  }, [client, setStores])

  return stores
}
