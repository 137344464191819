import { Config } from '../common/get-config'
import { LoadScriptProps } from '../common/load-script'

export const setupGoogleApi = (applicationConfig: Config, loadScript: (props: LoadScriptProps) => void) => {
  if (applicationConfig.GOOGLE_CLIENT_ID !== '' && applicationConfig.GOOGLE_CLIENT_ID !== 'null') {
    loadScript({
      id: 'google_sdk',
      setupScriptFunc: (script) => {
        script.async = true
        script.defer = true
        script.src = 'https://accounts.google.com/gsi/client'
      },
    })
  }
}
