import React from 'react'

import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 25,
  height: 25,
}

const QuestionIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#979797'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 19 19'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M9.07910156,0 C14.0625,0 18.1845703,4.11328125 18.1845703,9.09667969 C18.1845703,14.0712891 14.0712891,18.1845703 9.08789062,18.1845703 C4.11328125,18.1845703 0,14.0712891 0,9.09667969 C0,4.11328125 4.10449219,0 9.07910156,0 Z M8.94726562,11.6367188 C8.35839844,11.6367188 7.875,12.0498047 7.875,12.6386719 C7.875,13.2275391 8.3671875,13.640625 8.94726562,13.640625 C9.53613281,13.640625 10.0195312,13.2363281 10.0195312,12.6386719 C10.0195312,12.0410156 9.53613281,11.6367188 8.94726562,11.6367188 Z M9.20214844,4.35058594 C7.62011719,4.35058594 6.52148438,5.0625 6.2578125,5.93261719 C6.20507812,6.09082031 6.16992188,6.24902344 6.16992188,6.41601562 C6.16992188,6.86425781 6.52148438,7.13671875 6.94335938,7.13671875 C7.48828125,7.13671875 7.61132812,6.86425781 7.89257812,6.54785156 C8.16503906,6.09082031 8.578125,5.82714844 9.11425781,5.82714844 C9.85253906,5.82714844 10.3183594,6.24023438 10.3183594,6.84667969 C10.3183594,7.41796875 9.94042969,7.72558594 9.15820312,8.27050781 C8.49902344,8.71875 8.02441406,9.19335938 8.02441406,10.0195312 L8.02441406,10.0195312 L8.02441406,10.125 C8.02441406,10.6523438 8.34082031,10.9160156 8.91210938,10.9160156 C9.421875,10.9160156 9.72949219,10.6171875 9.76464844,10.2392578 C9.7734375,10.2041016 9.7734375,10.1689453 9.7734375,10.1337891 C9.79980469,9.65917969 10.1425781,9.3515625 10.7314453,8.95605469 C11.6455078,8.34960938 12.2255859,7.82226562 12.2255859,6.75878906 C12.2255859,5.22949219 10.8457031,4.35058594 9.20214844,4.35058594 Z'
          fill={fill}
        />
      </g>
    </svg>
  )
}

export { QuestionIcon }
