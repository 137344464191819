const findSauceSwapFromPortions = (portions: Portion[] | undefined): BasketLineSwap | undefined => {
  if (!portions || !portions.length) {
    return undefined
  }

  for (const portion of portions) {
    if (portion.sauce?.add || (portion.sauce?.add === null && portion.sauce?.remove)) {
      return portion.sauce
    }
  }

  return undefined
}

export { findSauceSwapFromPortions }
