import { useContext } from 'react'
import { ProductContext } from './product-context'

export const useProductContext = () => {
  const context = useContext(ProductContext)

  if (!context) {
    throw new Error("'useProductContext' must be a child of 'ProductContext'")
  }

  return context
}
