import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import cssList from '../list-group/list-group.less'
import 'react-loading-skeleton/dist/skeleton.css'

interface ListLineSkeletonProps {
  testId: string
}

export const ListLineSkeleton = (props: ListLineSkeletonProps) => (
  <div className={cssList.container}>
    <div data-testid={`${props.testId}.icon.skeleton`} className={cssList['line-skeleton']}>
      <Skeleton circle width={25} height={25} />
    </div>

    <div className={cssList.textContainer}>
      <div className={cssList.highlightTitle} data-testid={`${props.testId}.title`}>
        <div data-testid={`${props.testId}.title.skeleton`} className={cssList['line-skeleton']}>
          <Skeleton />
        </div>
      </div>
      <div className={cssList.subtitle} data-testid={`${props.testId}.subtitle`}>
        <div data-testid={`${props.testId}.subtitle.skeleton`} className={cssList['line-skeleton']}>
          <Skeleton />
        </div>
      </div>
    </div>
  </div>
)
