import { useValidation } from '@dominos/business/functions'
import { useCountryCode } from '@dominos/hooks-and-hocs'

type ValidationRules = ValidationFunction<string>[]

export const useAddressSearchValidation = (): { validationRules: ValidationRules | undefined } => {
  const country = useCountryCode()
  const { equalLength, isNumeric } = useValidation()

  const getSGValidation = (): ValidationTextFieldProps['validationRules'] => {
    const postcodeLength = 6

    return [isNumeric, equalLength(postcodeLength)]
  }

  const countryValidationRules: CountryObject<ValidationRules> = {
    SG: getSGValidation(),
  }

  const validationRules = country ? countryValidationRules[country] : undefined

  return { validationRules }
}
