import gql from 'graphql-tag'

export const preOrderEstimateQuery = gql`
  query preOrderEstimate($storeNo: Int!, $serviceMethod: ServiceMethodEnum!, $deliveryAddress: DeliveryAddressInput) {
    preOrderEstimate(storeNo: $storeNo, serviceMethod: $serviceMethod, deliveryAddress: $deliveryAddress) {
      estimated
      min
      max
    }
  }
`
