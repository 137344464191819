import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 23,
  height: 25,
}

const PaypalIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 23 25'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-28.000000, -368.000000)' fill={fill}>
          <g transform='translate(12.000000, 358.000000)'>
            <g transform='translate(16.240106, 10.000000)'>
              <path
                d='M10.9091718,14.0422445 L8.57583887,14.0422445 C8.01536735,14.0422445 7.54317944,14.416937 7.4560461,14.9257186 L6.26119789,21.8994322 L5.92301702,23.8761648 C5.86636598,24.2100358 6.14588282,24.5114659 6.51224542,24.5114659 L10.6506475,24.5114659 C11.1406647,24.5114659 11.5570642,24.1835424 11.6341327,23.7388316 L11.6746799,23.5452675 L12.4539912,18.9938083 L12.5040282,18.7442836 C12.5802339,18.2979508 12.9974962,17.9700273 13.4875134,17.9700273 L14.1063613,17.9700273 C18.1159326,17.9700273 21.2544581,16.4715278 22.1718024,12.1358006 C22.5551316,10.3245166 22.3567092,8.81195932 21.3427417,7.74843825 C21.0359058,7.42754362 20.6551648,7.16152818 20.2100083,6.94444444 C20.1861401,7.08421068 20.1591087,7.22695068 20.1286264,7.37374578 C19.0798631,12.3299053 15.4918676,14.0422445 10.9091718,14.0422445'
                id='Fill-87'
              />
              <path
                d='M12.397214,0 L4.44449987,0 C3.88489106,0 3.40752691,0.374422134 3.32125628,0.884014713 L0.00846412122,20.2109553 C-0.0568139881,20.5932174 0.263825183,20.9381723 0.682812872,20.9381723 L5.59247437,20.9381723 L6.82528166,13.7389944 L6.78703502,13.9644587 C6.87445592,13.4556772 7.34664383,13.081255 7.90711535,13.081255 L10.2404483,13.081255 C14.8228566,13.081255 18.4111396,11.3681048 19.4599029,6.41275627 C19.4912479,6.26596117 19.5174166,6.12295084 19.5409973,5.98345494 C19.2758589,5.85396165 19.2767216,5.85450233 19.54186,5.98345494 C19.8541596,4.15162506 19.5395594,2.90454253 18.4634771,1.77532863 C17.2772559,0.532301214 15.1365941,0 12.397214,0'
                id='Fill-88'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { PaypalIcon }
