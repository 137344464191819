import { DEFAULT_SLATE_COLOUR } from '@dominos/components/product/style-constants'
import React from 'react'

export const Classic = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : DEFAULT_SLATE_COLOUR

  return (
    <svg width='55px' height='22px' viewBox='0 0 55 22' key={color}>
      <g stroke={'none'} strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-159.000000, -849.000000)' fill={selectedColor} stroke={selectedColor}>
          <g transform='translate(6.000000, 309.000000)'>
            <g transform='translate(1.000000, 474.000000)'>
              <path d='M179.5,81.5932926 C192.202549,81.5932926 202.5,78.6541703 202.5,75.0285792 C202.5,71.4029881 192.202549,68.4638658 179.5,68.4638658 C166.797451,68.4638658 156.5,71.4029881 156.5,75.0285792 C156.5,78.6541703 166.797451,81.5932926 179.5,81.5932926 Z M179.5,79.1053956 C190.892127,79.1053956 200.12727,76.723208 200.12727,73.7846307 C200.12727,70.8460534 190.892127,68.4638658 179.5,68.4638658 C168.107873,68.4638658 158.87273,70.8460534 158.87273,73.7846307 C158.87273,76.723208 168.107873,79.1053956 179.5,79.1053956 Z' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
