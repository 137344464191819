import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge6 = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-6' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M25.03,31.85 L16.97,31.85 C15.699,31.85 14.645,30.796 14.645,29.525 L14.645,12.475 C14.645,11.204 15.699,10.15 16.97,10.15 L25.185,10.15 C26.394,10.15 27.355,11.111 27.355,12.32 L27.355,14.056 C27.355,14.521 27.076,14.955 26.673,15.141 L23.294,16.722 C22.953,16.846 22.581,16.629 22.581,16.257 L22.581,14.614 C22.581,14.521 22.519,14.459 22.426,14.459 L19.605,14.459 C19.512,14.459 19.45,14.521 19.45,14.614 L19.45,18.117 C19.45,18.21 19.512,18.272 19.605,18.272 L25.03,18.272 C26.301,18.272 27.355,19.326 27.355,20.597 L27.355,29.525 C27.355,30.796 26.301,31.85 25.03,31.85 Z M22.705,22.581 L19.605,22.581 C19.512,22.581 19.45,22.643 19.45,22.736 L19.45,27.386 C19.45,27.479 19.512,27.541 19.605,27.541 L22.705,27.541 C22.798,27.541 22.86,27.479 22.86,27.386 L22.86,22.736 C22.86,22.643 22.798,22.581 22.705,22.581 Z'
          id='6'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
