export const generalAutoPopupFilter = (offers: Bff.Offers.Offer[], locationCode: string, allowedLayouts: string[]) =>
  offers.filter(
    (offer: Bff.Offers.Offer) =>
      allowedLayouts.findIndex((layout) => offer.layout === layout) >= 0 &&
      !!offer.locations.find(
        (location) =>
          location.locationCode.toLowerCase() === locationCode.toLowerCase() &&
          location.autoPopUp &&
          location.showPopUp,
      ),
  )

export const reOrderOfferItemsByPlacementCode = (offers: Bff.Offers.Offer[]) =>
  offers.map((offer) => ({
    ...offer,
    items: [...offer.items].sort((offer1, offer2) => offer1.placementCode.localeCompare(offer2.placementCode)),
  }))

export const removeOffersAlreadyShown = (offers: Bff.Offers.Offer[], offersShown: string[]) =>
  offers.filter((offer) => offersShown.indexOf(offer.offerId) === -1)

export const getAutoPopupOffer = (
  offers: Bff.Offers.Offer[],
  locationCode: string,
  popupOffersAlreadyShown: string[],
  allowedPopupLayouts: string[],
  viewedProductOnMobile?: boolean,
) => {
  if (offers.length > 0 && !viewedProductOnMobile) {
    const popupsForLocationCode = generalAutoPopupFilter(offers, locationCode, allowedPopupLayouts)
    const reOrderedByPlacementCode = reOrderOfferItemsByPlacementCode(popupsForLocationCode)

    const filteredByShownStatus =
      popupOffersAlreadyShown.length > 0
        ? removeOffersAlreadyShown(reOrderedByPlacementCode, popupOffersAlreadyShown)
        : reOrderedByPlacementCode

    return filteredByShownStatus.length > 0 ? filteredByShownStatus[0] : undefined
  } else {
    return undefined
  }
}
