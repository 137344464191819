import React from 'react'

import { StandardInputField } from '@dominos/components'

import css from './labelled-input-field.less'

const DEFAULT_CONTAINER_WIDTH = '112px'
const FILL_CONTAINER_WIDTH = '100%'

export const LabelledInputField = (props: LabelledInputFieldProps) => {
  const containerStyles = buildContainerStyles(props)

  return (
    <div data-testid={props.testID} className={css.container} style={containerStyles}>
      <label data-testid={`${props.testID}.label`} className={css.label}>
        {props.label || props.placeholder}
      </label>
      <StandardInputField {...props} testID={`${props.testID}.input`} />
    </div>
  )
}

const buildContainerStyles = (props: LabelledInputFieldProps) => ({
  width: (props.fill && FILL_CONTAINER_WIDTH) || (props.style && props.style.width) || DEFAULT_CONTAINER_WIDTH,
  ...flattenMargins(props.style),
})

// TODO: Make this smart enough to populate all margin when a top level `margin` is provided
const flattenMargins = (style?: React.CSSProperties) =>
  style && {
    marginRight: (style && style.marginRight) || 0,
  }
