import { ApplicationConfiguration } from '@dominos/interfaces'
import { platform, platformVersion, version } from './get-platform'

export const applicationContextBuilder: ApplicationConfiguration = {
  version,
  isDevelopment: false,
  environment: '',
  language: 'en',
  availableLanguages: [],
  graphUrl: '',
  hasLocationPermission: false,
  application: platform,
  applicationVersion: platformVersion,
  sessionID: '',
  countryCode: 'AU',
  webPushNotificationClientId: '',
}
