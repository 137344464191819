import { Spinner } from '@dominos/components'
import { PizzaGaugeBadge } from '@dominos/res/images/icons/components/rewards'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './redeem-loyalty-points.less'

export const RedeemLoyaltyPoints = ({
  freePizzas,
  onRedeem,
  loading,
}: {
  freePizzas: number
  onRedeem: () => void
  loading: boolean
}) => {
  const { t: tMenu } = useTranslation('menu')
  const { t: tLoyalty } = useTranslation('loyalty')

  return (
    <div className={css.container}>
      <div className={css.inner}>
        <div className={css.iconWrapper}>
          <PizzaGaugeBadge count={freePizzas} size={36} />
        </div>
        <div data-testid='loyalty-points-label' className={css.availablePizzaLabel}>
          {`${tMenu('AvailablePizzasLabel', {
            count: freePizzas,
            defaultValue: 'Free Pizzas Available',
          })}`}
        </div>
      </div>
      <button
        disabled={loading}
        className={[css.redeemButton, loading ? css.loading : ''].join(' ')}
        onClick={onRedeem}
      >
        {loading ? <Spinner testID='loyalty-spinner' color='#007aff' size='small' /> : tLoyalty('RedeemLoyaltyButton')}
      </button>
    </div>
  )
}
