import React, { useState } from 'react'

import { ActionButton, QuantityChanger } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

import { useTranslation } from 'react-i18next'
import css from './bottom-bar.less'

const MAX_VALUE = 99

export const BottomBar = ({
  testID,
  isPortionProduct,
  onComplete,
  initialQuantity,
  isEditing,
  forceDisabled,
}: BottomBarProps) => {
  const theme = useDominosTheme()
  const [quantity, setQuantity] = useState<number>(initialQuantity || 1)
  const { t } = useTranslation('menu')

  const styles = {
    container: {
      boxShadow: theme.defaultShadow,
      borderTopLeftRadius: theme.webLargeBorderRadius,
      borderTopRightRadius: theme.webLargeBorderRadius,
    },
    actionButtonAddToOrder: {
      borderRadius: theme.webBorderRadius,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 30,
      paddingRight: 30,
      minHeight: 40,
    },
    actionButtonSaveChanges: {
      flex: 1,
    },
  }

  const didPressAddToOrder = () => onComplete && onComplete(quantity)

  return (
    <div className={css.container} style={styles.container} data-testid={testID}>
      {isPortionProduct ? (
        <ActionButton
          containerStyle={styles.actionButtonSaveChanges}
          testID={`${testID}.add-to-basket-portion`}
          text={t('Save Changes')}
          onPress={didPressAddToOrder}
          disabled={forceDisabled || quantity < 1}
        />
      ) : (
        <>
          <QuantityChanger
            testID={`${testID}.quantity-changer`}
            quantity={quantity}
            minQuantity={1}
            maxQuantity={MAX_VALUE}
            onQuantityChange={setQuantity}
          />
          <ActionButton
            className={css.addButton}
            testID={`${testID}.add-button`}
            text={isEditing ? t('Save Changes') : t('Add To Order')}
            onPress={didPressAddToOrder}
            disabled={forceDisabled || quantity < 1}
            containerStyle={styles.actionButtonAddToOrder}
            textFontWeight='semibold'
          />
        </>
      )}
    </div>
  )
}
