import { KeyboardEvent } from 'react'

const isButtonClickEvent = (event: KeyboardEvent<HTMLDivElement>) =>
  event.key === 'Enter' || event.key === ' ' || event.code === 'Spacebar'

/**
 * Will enable keyboard interaction for elements that handle an `onClick`,
 * but are NOT a `<button />` element.
 *
 * @example
 * ```
 * ...
 * const onKey = (event: KeyboardEvent<HTMLDivElement>) => onButtonClick(event, onPress)
 *
 * return (
 *  <div
 *    data-testid={testID}
 *    tabIndex={0}
 *    onKeyPress={onKey}
 *    onKeyDown={onKey}
 *  >
 * )
 *
 * ```
 */
export const onButtonClick = (event: KeyboardEvent<HTMLDivElement>, fn: () => void) => {
  if (isButtonClickEvent(event)) {
    return fn()
  }
}

/**
 * Will focus onto the appropriate next element when pressing up/down arrow keys.
 *
 * @param prefix normally corresponds to the `testID`
 *
 * @example
 * ```
 * ...
 * const onKey = (event: KeyboardEvent<HTMLDivElement>) => onArrowKeys(event, testID, data.id, data.length)
 *
 * return (
 *  <div
 *    data-testid={testID}
 *    id={`${testID}.${stuff.id}`}
 *    role='option'
 *    tabIndex={0}
 *    onKeyPress={onKey}
 *    onKeyDown={onKey}
 *  >
 * )
 *
 * ```
 *
 */
export const onArrowKeys = (event: KeyboardEvent<HTMLDivElement>, prefix: string, index: number, limit: number) => {
  const firstIndex = 0
  const lastIndex = limit - 1

  if (event.key === 'ArrowUp') {
    const nextIndex = index - 1
    const indexWithinBounds = nextIndex < firstIndex ? lastIndex : nextIndex

    const nextElement = document.getElementById(`${prefix}.${indexWithinBounds}`)

    nextElement?.focus()

    return !!nextElement
  }

  if (event.key === 'ArrowDown') {
    const nextIndex = index + 1
    const indexWithinBounds = nextIndex > lastIndex ? firstIndex : nextIndex

    const nextElement = document.getElementById(`${prefix}.${indexWithinBounds}`)

    nextElement?.focus()

    return !!nextElement
  }
}
