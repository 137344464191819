import { OrderStatus, useWindowSize } from '@dominos/hooks-and-hocs'
import { svgIconsName } from '@dominos/res'
import React, { useEffect, useRef } from 'react'
import { TrackerStatus, TrackerStatusPositionType } from '../tracker-status'

import { Chevron } from '../constants'
import css from './tracker-bar.less'

interface TrackerBarProps {
  allStatuses: {
    id: OrderStatus | string
    icon: svgIconsName
    displayTextTranslationKey: string
  }[]
  currentStatus: OrderStatus | string
}

export const TrackerBar = ({ allStatuses, currentStatus }: TrackerBarProps) => {
  const trackerBarRef = useRef<HTMLDivElement>(null)
  const currentStatusRef = useRef<HTMLDivElement>(null)
  const offsetRef = useRef<HTMLDivElement>(null)
  const windowSize = useWindowSize()

  useEffect(() => {
    if (trackerBarRef.current && offsetRef.current) {
      const trackerBarBounds = trackerBarRef.current.getBoundingClientRect()
      let offsetAmount = 0
      switch (currentStatus) {
        case OrderStatus.SentToStore:
          break
        case OrderStatus.Complete:
          offsetAmount =
            trackerBarBounds?.width! - (Chevron.boundingWidth - Chevron.xOverlap) * 3 + Chevron.outlineWidth / 2
          break
        default:
          offsetAmount = (trackerBarBounds?.width! - Chevron.boundingWidth) / 2 - Chevron.width + Chevron.xOverlap
      }
      offsetRef.current.setAttribute('style', `left: ${offsetAmount}px`)
    }
  }, [currentStatus, windowSize[0]])

  const statusesToDisplay: {
    id: OrderStatus | string
    icon: svgIconsName
    displayTextTranslationKey: string
    isCurrentStatus?: boolean
    isCompletedStatus?: boolean
    statusPositionType?: TrackerStatusPositionType
  }[] = []

  const currentStatusIndex = allStatuses.findIndex((status) => status.id === currentStatus)
  const currentStatusDetails = { ...allStatuses[currentStatusIndex], isCurrentStatus: true }

  const arrangeBeginningStatuses = () => {
    statusesToDisplay.push(currentStatusDetails)
    const nextStatus = allStatuses[currentStatusIndex + 1]
    if (nextStatus) {
      statusesToDisplay.push(nextStatus)
    }
    const additionalStatus = allStatuses[currentStatusIndex + 2]
    if (additionalStatus) {
      statusesToDisplay.push(additionalStatus)
    }
  }

  const arrangeEndStatuses = () => {
    const previousStatus = allStatuses[currentStatusIndex - 2]
    if (previousStatus) {
      statusesToDisplay.push({ ...previousStatus, isCompletedStatus: true })
    }
    const priorStatus = allStatuses[currentStatusIndex - 1]
    if (priorStatus) {
      statusesToDisplay.push({ ...priorStatus, isCompletedStatus: true })
    }
    statusesToDisplay.push({ ...currentStatusDetails, statusPositionType: TrackerStatusPositionType.RightEnd })
  }

  const arrangeIntermediateStatuses = () => {
    statusesToDisplay.push({ ...allStatuses[currentStatusIndex - 1], isCompletedStatus: true })
    statusesToDisplay.push(currentStatusDetails)
    statusesToDisplay.push(allStatuses[currentStatusIndex + 1])
  }

  switch (currentStatusIndex) {
    case 0:
      arrangeBeginningStatuses()
      break
    case allStatuses.length - 1:
      arrangeEndStatuses()
      break
    default:
      arrangeIntermediateStatuses()
  }

  return (
    <div className={css.trackerBar} ref={trackerBarRef} data-testid='tracker-bar'>
      <div className={css.trackerSlider} ref={offsetRef}>
        {statusesToDisplay.map((status) => (
          <TrackerStatus
            key={status.id}
            id={status.id}
            displayTextTranslationKey={status.displayTextTranslationKey}
            iconName={status.icon}
            isCurrentStatus={status.isCurrentStatus}
            isCompletedStatus={status.isCompletedStatus}
            positionType={status.statusPositionType}
            forwardedRef={status.isCurrentStatus ? currentStatusRef : null}
          />
        ))}
      </div>
    </div>
  )
}
