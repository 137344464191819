import { useCurrentOrderStore, useFeatures } from '@dominos/hooks-and-hocs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DetailRow } from '../detail-row/detail-row'
import SurchargeInfoPopup from './surcharge-info-popup'
import { isVoucherDiscount } from '@dominos/business/functions/basket/is-voucher-discount'

interface SurchargesProps {
  surcharges?: BasketLineSurcharge[] | Bff.Orders.OrderDetails.Basket.Surcharge[]
  testID: string
  textStyle?: CommonTextStyle
  showPrefix?: boolean
  excludeVoucherDiscount?: boolean
}

const Surcharges = ({ surcharges, testID, textStyle, showPrefix, excludeVoucherDiscount }: SurchargesProps) => {
  const { t } = useTranslation('basket')
  const [isSurchargeDescriptionPopupEnabled] = useFeatures('SurchargeDescriptionPopup')
  const currentStore = useCurrentOrderStore()
  const [showSurchargePopup, setShowSurchargePopup] = useState(false)

  return (
    <>
      {surcharges &&
        surcharges
          .filter((surcharge) => !excludeVoucherDiscount || isVoucherDiscount(surcharge)) //Excludes voucher surcharges that have a code beginning with 'Voucher' and a price of 0 or less.
          .map((surcharge, idx) => {
            const storeSurcharge = currentStore?.surcharges?.find(
              (storeSurcharge) => storeSurcharge.surchargeCode == surcharge.code,
            )

            const storeSurchargeName = storeSurcharge?.media?.find(
              (media) => media.mediaName.toLowerCase() == 'name',
            )?.content

            const storeSurchargeDescription = storeSurcharge?.media?.find(
              (media) => media.mediaName.toLowerCase() == 'description',
            )?.content

            const name = storeSurchargeName || surcharge.name || t('Surcharge')
            const description = storeSurchargeDescription

            return (
              <div key={`${surcharge.code}.${idx}`}>
                <DetailRow
                  testID={`${testID}.${surcharge.code}.${idx}`}
                  label={name}
                  price={surcharge.price}
                  popup={isSurchargeDescriptionPopupEnabled && !!description}
                  onPopupClicked={() => setShowSurchargePopup(true)}
                  fontWeight={'normal'}
                  textStyle={textStyle}
                  amountPrefix={showPrefix ? '+' : ''}
                />
                {showSurchargePopup && description && (
                  <SurchargeInfoPopup
                    testID='surcharge-popup'
                    name={name}
                    description={description}
                    onClose={() => setShowSurchargePopup(false)}
                  />
                )}
              </div>
            )
          })}
    </>
  )
}

export default Surcharges
