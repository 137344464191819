import { rootActions } from '@dominos/business/root.actions'
import { ActionsObservable, Epic, StateObservable } from 'redux-observable'
import { Observable, of, pipe } from 'rxjs'
import { catchError, filter, map, switchMap } from 'rxjs/operators'
import { getType, isActionOf } from 'typesafe-actions'
import { createConfig } from '../functions/epics/create-config'
import { storeQuery } from '../queries'

interface StoreResponse {
  store: Bff.Stores.Store
}

export const getStoreEpic: Epic = (
  action$: ActionsObservable<RootActions>,
  store$: StateObservable<RootReducer>,
  { graphQLQuery, securitySender, features }: IReduxInjected,
) =>
  action$.pipe(
    filter(
      isActionOf([
        rootActions.addCustomerAddressToOrder,
        rootActions.alternativeStoreSelected,
        rootActions.updateLanguage,
      ]),
    ),
    switchMap((action) => {
      let storeNo
      switch (action.type) {
        case getType(rootActions.addCustomerAddressToOrder): {
          storeNo = action.address.storeNo!
          break
        }
        case getType(rootActions.alternativeStoreSelected): {
          storeNo = action.storeNo!
          break
        }
        default: {
          storeNo = store$.value.storeReducer.currentStore?.storeNo
          break
        }
      }

      if (!storeNo) {
        return new Observable()
      }

      const storeConfig = createConfig(store$.value, features, { storeNo })
      if (!storeConfig) {
        return of(rootActions.selectStoreFail('could not build config'))
      }

      return graphQLQuery<StoreResponse>(storeQuery, storeConfig, securitySender).pipe(
        map((response) => rootActions.retrievedStoreInformation(response.store, false)),
        catchError(pipe(rootActions.selectStoreFail, of)),
      )
    }),
  )
