import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import { IAddress } from 'olo-feature-address'

interface AddressInfoProps extends BaseProps {
  address: IAddress
  showClearButton?: boolean
}

export const AddressInfoComponent = ({ testID, address }: AddressInfoProps) => {
  if (!address) return <></>

  const styles = getStyles()

  return (
    <div data-testid={testID} style={styles.wrapper}>
      <div data-testid={`${testID}.icon`} style={styles.icon}>
        <StaticSvgIcon name={'pinIcon'} isUnstyled width={30} height={30} />
      </div>

      <div>
        <div data-testid={`${testID}.subtitle`} style={styles.title}>
          {address.displayAddress.title}
        </div>
        <div data-testid={`${testID}.subtitle`} style={styles.subtitle}>
          {address.displayAddress.subtitle}
        </div>
      </div>
    </div>
  )
}

export interface Style {
  wrapper: React.CSSProperties
  icon: React.CSSProperties
  title: React.CSSProperties
  subtitle: React.CSSProperties
}

const getStyles = (): Style => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    display: 'flex',
    margin: '0',
    gap: '7px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '15px',
    margin: '0',
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: '15px',
    margin: '0',
  },
})
