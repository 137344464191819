export const getShowMoreText = (
  visibleItemCount: number,
  totalItemCount: number,
  expanded: boolean,
  t?: (...params: unknown[]) => string,
) =>
  expanded || visibleItemCount > totalItemCount
    ? t
      ? t('hide')
      : 'hide'
    : `${totalItemCount - visibleItemCount} ${t ? t('more') : 'more'}`
