// Importing directly from '@forgerock/javascript-sdk/src/index' due to unit test failures when using the package-level import.
import { FRUser, TokenStorage } from '@forgerock/javascript-sdk/src/index'
import { IForgeRockClient } from './interfaces/IForgerockClient'
import { buildForgerockUrl, getTokenOptions } from '@dominos/business/functions/forgerock'

export const ForgeRockClient: IForgeRockClient = {
  login: async (urlParams: IForgerockUrlParams) => {
    await getTokenOptions({
      login: 'redirect',
      query: {
        action: 'login',
        market: urlParams.countryCode,
        locale: `${urlParams.language}-${urlParams.countryCode}`,
        application: urlParams.application,
        orderId: urlParams.orderId,
      },
      forceRenew: true,
    })
  },
  register: async (urlParams: IForgerockUrlParams) => {
    await getTokenOptions({
      login: 'redirect',
      query: {
        action: 'register',
        market: urlParams.countryCode,
        locale: `${urlParams.language}-${urlParams.countryCode}`,
        application: urlParams.application,
        orderId: urlParams.orderId,
      },
      forceRenew: true,
    })
  },
  getAuthToken: async (code, state) => await getTokenOptions({ query: { code, state } }),
  logout: async () => await FRUser.logout(),
  getTokenFromStorage: async () => await TokenStorage.get(),
  fetchAuthToken: async () =>
    await getTokenOptions({
      login: 'redirect',
    }),
  updatePassword: (urlParams: IForgerockUrlParams) => {
    window.location.href = buildForgerockUrl(urlParams, 'DPE-UpdatePassword-Global')
  },
  deletAccount: async (urlParams: IForgerockUrlParams) => {
    window.location.href = buildForgerockUrl(urlParams, 'DPE-DeleteUser-Global')
  },
}
