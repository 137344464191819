import { DominosTheme } from '@dominos/hooks-and-hocs'

const MOBILE_LEGEND_OFFSET = -54
const DESKTOP_LEGEND_OFFSET = -34
const NON_MOBILE_CHILD_WRAPPER_TOP = -40

export const createStyles = (
  theme: DominosTheme,
  isMobileLayout: boolean,
  isPortion: boolean,
): Dictionary<CommonViewStyle> => ({
  modal: {
    borderRadius: isMobileLayout ? undefined : theme.webBorderRadius,
    backgroundColor: theme.colours.backgroundColor,
    boxShadow: isMobileLayout ? '' : theme.defaultShadow,
  },
  modalChildren: {
    marginTop: !isPortion ? (isMobileLayout ? '60%' : NON_MOBILE_CHILD_WRAPPER_TOP) : undefined,
  },
  legend: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: isMobileLayout ? MOBILE_LEGEND_OFFSET : DESKTOP_LEGEND_OFFSET,
  },
  legendImage: { width: 29 },
})
