import { MenuPage, RetryDialog, SplashScreen, TopLevelMenuContainer } from '@dominos/components'
import { MenuPageHeader, useHasTopMenuWrapped, useMenu } from '@dominos/hooks-and-hocs/menu'
import { useParams } from '@reach/router'
import React from 'react'
import css from './menu-viewport-container.less'

interface MenuViewportContainerProps {
  clickNavigation: (item: string) => void
  isEditing?: boolean
  setMenuFilterList?: (items: ProductMenuItem[]) => void
  currentLine?: BasketLine | Bff.Orders.OrderDetails.Basket.ProductLine
  currentProduct?: MenuItemDependents
  toggleProductCard: (
    editing: boolean,
    item?: MenuItemDependents | undefined,
    lineData?: BasketLine | Bff.Orders.OrderDetails.Basket.ProductLine | undefined,
    swapping?: BasketLine | undefined,
  ) => void
  filteredMenu?: Menu
  menuPages?: MenuPageHeader[]
  isProductVisible: boolean
  swapping?: BasketLine
  addDirectlyToBasket?: boolean
}

export const MenuViewportContainer = ({
  isEditing,
  currentLine,
  clickNavigation,
  currentProduct,
  toggleProductCard,
  filteredMenu,
  menuPages,
  isProductVisible,
  swapping,
  addDirectlyToBasket,
}: MenuViewportContainerProps) => {
  const params = useParams()
  const { menuCategory } = params

  const isTopMenuHidden = useHasTopMenuWrapped()

  const { errorOccurred: error, refetchMenu, menuLoading } = useMenu()

  if (menuLoading) {
    return <SplashScreen testID='menu-loading-splash' />
  }

  if (!filteredMenu && error) {
    return <RetryDialog testID='menu-loading-retry' onRetry={refetchMenu} />
  }

  if (menuPages && isTopMenuHidden && !menuCategory) {
    return (
      <div className={css.container}>
        <div className={css.content}>
          <TopLevelMenuContainer menuPages={menuPages} testID='top-level-menu-scene' />
        </div>
      </div>
    )
  }

  if (filteredMenu) {
    return (
      <MenuPage
        menu={filteredMenu}
        testID={menuCategory ? `menu-scene.${menuCategory}` : 'menu-scene.no-category'}
        isProductVisible={isProductVisible}
        toggleProductCard={toggleProductCard}
        currentProduct={currentProduct}
        currentLine={currentLine}
        setSection={clickNavigation}
        isEditing={isEditing}
        swapping={swapping}
        addDirectlyToBasket={addDirectlyToBasket}
      />
    )
  }

  return <SplashScreen testID='menu-loading-splash' />
}
