import React, { KeyboardEvent } from 'react'
import classnames from 'classnames'

import { FlatList, Spinner } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import { onArrowKeys, onButtonClick, toPascalCase } from '@dominos/business/functions'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

import css from './address-list.less'

export const AddressList = ({
  testID,
  addresses,
  defaultTitleColor,
  showBorderOnFirstItem,
  showBorderOnLastItem,
  swapNameAndAddress,
  narrowHeight,
  itemLoadingKey,
  isLoading,
  iconStyling,
  onPress,
}: AddressListProps) => {
  const renderItem = (info: AddressLine, key: string) => (
    <Wrapper
      key={key}
      prefix={`address`}
      address={info}
      onPress={onPress}
      testID={
        info.icon === 'savedIcon'
          ? `${testID}.address.wrapper.customerSaved.${info.name.toLowerCase()}`
          : `${testID}.address.wrapper.${info.name.toLowerCase()}`
      }
    >
      <div className={!!narrowHeight ? css.narrowContainer : css.container}>
        {info.icon ? (
          <div data-testid={`${testID}.icon.${info.icon}`} className={css.iconContainer}>
            <StaticSvgIcon name={info.icon} isUnstyled width={iconStyling?.width} height={iconStyling?.height} />
          </div>
        ) : null}
        {swapNameAndAddress ? (
          <div className={css.textContainer}>
            <p
              className={info.name && !defaultTitleColor ? css.highlightTitle : css.title}
              data-testid={`${testID}.address.info`}
            >
              {toPascalCase(info.address || '')}
            </p>
            <p className={css.subtitle} data-testid={`${testID}.address.name`}>
              {info.name}
            </p>
          </div>
        ) : (
          <div className={css.textContainer}>
            <p
              className={info.name && !defaultTitleColor ? css.highlightTitle : css.title}
              data-testid={`${testID}.address.name`}
            >
              {toPascalCase(info.name)}
            </p>
            <p className={css.subtitle} data-testid={`${testID}.address.info`}>
              {info.address}
            </p>
          </div>
        )}

        <AddressLineSuffix
          content={info.suffix}
          testID={`${testID}.suffix`}
          isLoading={isLoading && info.uid === itemLoadingKey}
        />
      </div>
    </Wrapper>
  )

  const keyExtractor = (item: AddressLine, index: number) => `${item.address}.${item.name}.${item.uid}.${index}`

  return (
    <div
      data-testid={testID}
      className={classnames(
        !showBorderOnFirstItem && css.removeBorderOnFirstItem,
        showBorderOnLastItem && css.addBorderOnLastItem,
      )}
    >
      <FlatList data={addresses} renderItem={renderItem} keyExtractor={keyExtractor} />
    </div>
  )
}

const Wrapper = ({ testID, prefix, address, children, onPress }: AddressLineRenderWrapperProps) => {
  const onClick = () => {
    onPress(address)
  }

  const onKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    onButtonClick(event, () => onPress(address))

    if (onArrowKeys(event, prefix, address.uid, 5)) {
      return
    }
  }

  return (
    <div
      data-testid={testID}
      id={`${prefix}.${address.uid}`}
      className={css.wrapper}
      onClick={onClick}
      role='option'
      tabIndex={0}
      onKeyPress={onKeyUp}
      onKeyDown={onKeyUp}
    >
      {children}
    </div>
  )
}

const AddressLineSuffix = ({ testID, content, isLoading }: AddressLineSuffixProps) => {
  const theme = useDominosTheme()

  if (!content) {
    return null
  }

  if (isLoading) {
    return <Spinner testID={`${testID}.loading`} color={theme.colours.lightSlate} />
  }

  if (content === 'caret') {
    return (
      <div data-testid={`${testID}.caret`} className={css.suffix}>
        <StaticSvgIcon name='chevron' isUnstyled fill={theme.colours.overlayColor} />
      </div>
    )
  }

  return (
    <p className={css.storeDetailsDistance} data-testid={`${testID}.distance`}>
      {`${content.toFixed(1)} KM`}
    </p>
  )
}
