import React from 'react'
import classnames from 'classnames'
import { ListGroupCollectionLine } from './list-group-collection-line'
import { IListGroupCollection, IListGroupCollectionItem } from './interfaces'
import cssList from './list-group.less'

interface ListGroupCollectionProps extends BaseProps {
  collection: IListGroupCollection
  isLoading?: boolean
  itemLoadingKey?: string
  showBorderAboveTitle?: boolean
  showBorderOnFirstItem?: boolean
  showBorderOnLastItem?: boolean
  iconStyling?: SvgIconDimension
  onPress: (id: string) => void
}

export const ListGroupCollection = (props: ListGroupCollectionProps) => {
  const keyExtractor = (item: IListGroupCollectionItem) => `${item.title}.${item.subtitle}.${item.id}.${item.index}`
  const testIdExtractor = (props: ListGroupCollectionProps, item: IListGroupCollectionItem) => {
    if (!item.title) return `${props.testID}.line.${normalize(item.subtitle)}`

    if (!item.subtitle) return `${props.testID}.line.${normalize(item.title)}`

    return `${props.testID}.line.${normalize(item.title)}${normalize(item.subtitle)}`
  }
  const normalize = (value: string) => value.replace(/,/g, '').toLowerCase()

  return (
    <div
      data-testid={props.testID}
      className={classnames(
        cssList.listContainer,
        !props.showBorderOnFirstItem && cssList.removeBorderOnFirstItem,
        props.showBorderOnLastItem && cssList.addBorderOnLastItem,
        props.collection.title && cssList.removeBorderAfterListTitle,
      )}
    >
      {props.collection.title && (
        <div
          data-testid={`${props.testID}.title`}
          className={classnames(
            cssList.listTitleContainer,
            !props.showBorderAboveTitle && cssList.removeBorderAboveTitle,
          )}
        >
          <div className={cssList.listTitle}>{props.collection.title}</div>
        </div>
      )}

      {props.collection.items.map((item, index) => (
        <ListGroupCollectionLine
          testID={testIdExtractor(props, item)}
          key={keyExtractor(item)}
          item={item}
          iconStyling={props.iconStyling}
          isLoading={props.isLoading}
          itemLoadingKey={props.itemLoadingKey}
          onPress={props.onPress}
        />
      ))}
    </div>
  )
}
