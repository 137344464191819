import { useCallback, useEffect, useState } from 'react'
import { IGeolocation } from 'olo-feature-address'

export const useCurrentLocationService = (currentLocationEnabled: boolean) => {
  const [location, setLocation] = useState<IGeolocation | undefined>(undefined)
  const [isLoading, setLoading] = useState<boolean>(false)

  const attemptGetGeolocation = useCallback(() => {
    setLoading(true)
    if (!navigator.geolocation) {
      setLoading(false)

      return
    }

    try {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          setLoading(false)
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          })
        },
        (error: GeolocationPositionError) => {
          setLoading(false)
        },
        { enableHighAccuracy: true, maximumAge: 0 },
      )
    } catch (error) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!currentLocationEnabled) return

    attemptGetGeolocation()
  }, [currentLocationEnabled])

  return {
    isLoading,
    location,
  }
}
