type SwapSetProduct = Bff.Menu.old.SwapSetProduct
type PortionMenuItem = Bff.Menu.old.PortionMenuItem

export const getSelectedProductSize = (
  product?: ProductMenuItem | SwapSetProduct | PortionMenuItem,
  productCustomisations?: MenuCustomisationItem[],
) => {
  if (!product || !product.sizes || product.sizes.length === 0) {
    return null
  }

  const selectedSizeCode = productCustomisations?.find((pc) => pc?.type === 'size')?.value

  for (const size of product.sizes) {
    if (size.code === selectedSizeCode) {
      return size
    }
  }

  return product.sizes[0]
}
