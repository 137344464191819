import { useMutation } from '@apollo/client'
import { marketingOptInMutation } from '@dominos/business/queries'

export interface MarketingOptInResponse {
  marketingOptIn: boolean
}

export const useLoyaltyMarketing = () => {
  const [enrollCustomerInLoyaltyMarketing] = useMutation(marketingOptInMutation, {
    variables: {
      edmType: 'LoyaltyNotification',
      frequency: 'OncePerWeek',
    },
  })

  return {
    enrollCustomerInLoyaltyMarketing,
  }
}
