import React, { useRef, useState } from 'react'
import { VirtualKeyboard } from '@dominos/components/virtual-keyboard'
import { useFeatures } from '@dominos/hooks-and-hocs'
import { VirtualKeyboardContext } from './context'

export const VirtualKeyboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [isVirtualKeyboardEnabled] = useFeatures('VirtualKeyboard')
  const [displayVirtualKeyboard, setDisplayVirtualKeyboard] = useState<boolean>(false)
  const onChange = useRef<({ target }: React.ChangeEvent<HTMLInputElement>) => void>()
  const onPress = useRef<(event: React.KeyboardEvent<HTMLInputElement> & { target: { value: string } }) => void>()
  const handleClose = () => {
    setDisplayVirtualKeyboard(false)
  }
  const [inputName, setInputName] = useState('default')

  const toggleVirtualKeyboard = (
    displayKeyboard: boolean,
    didChangeTextInput?: ({ target }: React.ChangeEvent<HTMLInputElement>) => void,
    didPressKey?: (event: React.KeyboardEvent<HTMLInputElement> & { target: { value: string } }) => void,
    inputFieldName?: string,
  ) => {
    if (displayKeyboard && !!didChangeTextInput) {
      onChange.current = didChangeTextInput
    } else {
      onChange.current = undefined
    }

    if (displayKeyboard && !!didPressKey) {
      onPress.current = didPressKey
    } else {
      onPress.current = undefined
    }

    if (inputFieldName && inputName !== inputFieldName) {
      setInputName(inputFieldName)
    }

    setDisplayVirtualKeyboard(displayKeyboard)
  }

  return (
    <VirtualKeyboardContext.Provider value={{ toggleVirtualKeyboard }}>
      {isVirtualKeyboardEnabled && !!displayVirtualKeyboard && (
        <VirtualKeyboard
          didChangeTextInput={onChange.current}
          didPressKey={onPress.current}
          handleClose={handleClose}
          inputFieldName={inputName}
        />
      )}

      {children}
    </VirtualKeyboardContext.Provider>
  )
}
