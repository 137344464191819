import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 46,
  height: 73,
}

export const Gauge0 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 46 73'>
      <defs>
        <path
          d='M148.95,150.5 L121.75,150.5 C117.65,150.5 114.25,147.1 114.25,143 L114.25,88 C114.25,83.9 117.65,80.5 121.75,80.5 L148.95,80.5 C153.05,80.5 156.45,83.9 156.45,88 L156.45,143 C156.45,147.1 153.05,150.5 148.95,150.5 Z M140.45,94.4 L130.25,94.4 C129.95,94.4 129.75,94.6 129.75,94.9 L129.75,136.1 C129.75,136.4 129.95,136.6 130.25,136.6 L140.45,136.6 C140.75,136.6 140.95,136.4 140.95,136.1 L140.95,94.9 C140.95,94.6 140.75,94.4 140.45,94.4 Z'
          id='gauge-path-0'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Points1' transform='translate(-112.000000, -79.000000)' fillRule='nonzero'>
          <g id='0'>
            <use fill={fill} href='#gauge-path-0' />
            <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-0' />
          </g>
        </g>
      </g>
    </svg>
  )
}
