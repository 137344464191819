export const interpolateBracketVariables = (
  text?: string | null,
  variables: PartialRecord<string, string | number | undefined> = {},
) =>
  text?.replace(/\[([\w]+)\]/gi, (match, key) => {
    if (key && variables[key]) {
      const value = variables[key]

      return 'number' === typeof value ? value.toString() : value ?? match
    }

    return match
  }) ?? undefined
