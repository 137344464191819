import gql from 'graphql-tag'

export const loyaltyEnrollMutation = gql`
  mutation LoyaltyEnroll($marketingConsent: Boolean) {
    loyaltyEnroll(marketingOptIn: $marketingConsent) {
      ... on LoyaltyEnroll {
        success
      }
      ... on LoyaltyEnrollError {
        code
        message
      }
    }
  }
`
