import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '@dominos/components'
import css from './iframe-card-popup.less'
import { GenericCardPopup } from '..'
interface IframeCardPopupProps extends React.IframeHTMLAttributes<HTMLIFrameElement>, BaseProps {
  onDismiss: () => void
}

export const IframeCardPopup = ({ onDismiss, testID, ...props }: IframeCardPopupProps) => {
  const { t } = useTranslation('translation')

  return (
    <GenericCardPopup
      testID={`${testID}.iframe-popup`}
      popupTitle={''}
      genericCardStyles={styles.popUpCard}
      displayCloseButton
      width='100vw'
      suppressBackgroundScroll
    >
      <iframe data-testid={`${testID}.iframe-popup.iframe`} className={css.frame} {...props} />

      <ActionButton
        testID={`${testID}.iframe-popup.on-dismiss-button`}
        containerStyle={styles.dismissButtonContainer}
        text={t('Close', { defaultValue: 'Close' })}
        onPress={onDismiss}
        textSize={'2.1rem'}
      />
    </GenericCardPopup>
  )
}
const styles: Record<string, React.CSSProperties> = {
  popUpCard: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    padding: '0.5rem 1rem',
    borderRadius: '30px',
  },
  dismissButtonContainer: {
    width: '84vw',
    height: '4.5vh',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    backgroundColor: '#4A4A4A',
    alignSelf: 'center',
    borderRadius: '16px',
  },
}
