import gql from 'graphql-tag'

export const orderStatusQualityAssuranceQuery = gql`
  query orderStatusQualityAssuranceQuery($id: String!) {
    order(id: $id) {
      id
      status {
        status
        estimatedDuration
        elapsedTime
      }
      eta {
        min
        max
      }
      qualityAssurance {
        isValidationCompleted
        productQualityValidations {
          productCode
          productName
          isSuccessful
          smallImageUrl
          largeImageUrl
        }
      }
    }
  }
`
