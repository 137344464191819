import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Chevron } from '../constants'

import css from './tracker-status.less'

export enum TrackerStatusPositionType {
  RightEnd,
  Center,
}

interface TrackerStatusProps {
  id: string
  displayTextTranslationKey: string
  iconName: svgIconsName
  isCurrentStatus?: boolean
  isCompletedStatus?: boolean
  positionType?: TrackerStatusPositionType
  xOffset?: number
  forwardedRef?: React.Ref<HTMLDivElement>
}

export const TrackerStatus = ({
  displayTextTranslationKey: displayText,
  iconName,
  isCurrentStatus,
  isCompletedStatus,
  positionType,
  forwardedRef,
}: TrackerStatusProps) => {
  const { t } = useTranslation('tracker')
  const { width, height, inset, outlineWidth } = Chevron
  const defaultBackground = '#888'
  const activeStatusColor = '#cc0000'
  const nextStatusColor = '#4a4a4a'
  const outlineColor = 'black'
  const outlineOpacity = 0.1
  const [iconWidth, iconHeight] = [60, 60]

  let shapePoints = []
  switch (positionType) {
    case TrackerStatusPositionType.RightEnd:
      shapePoints = [
        [0, 0],
        [width + inset, 0],
        [width + inset, height],
        [0, height],
        [inset, height / 2],
      ]
      break
    case TrackerStatusPositionType.Center:
    default:
      shapePoints = [
        [0, 0],
        [width, 0],
        [width + inset, height / 2],
        [width, height],
        [0, height],
        [inset, height / 2],
      ]
  }

  const edgePath = shapePoints.map((edge, idx) => {
    const moveOrLine = idx === 0 ? 'M' : 'L'

    return [moveOrLine + edge[0].toString(), edge[1]]
  })

  const shapeEdge = edgePath
    .slice(0, edgePath.length - 1)
    .map((pair) => pair.join(','))
    .join(' ')

  const shape = shapePoints.map((pair) => pair.join(' ')).join(' ')

  const backgroundColor = isCurrentStatus || isCompletedStatus ? activeStatusColor : nextStatusColor

  return (
    <div ref={forwardedRef} className={css.statusWrapper}>
      <svg width={width + inset + 5} height={height}>
        <defs>
          <mask id={`completion-${positionType}`}>
            <polygon fill='white' points={shape} />
          </mask>
        </defs>
        <g>
          <polygon fill={defaultBackground} points={shape} />
          <polygon fill={backgroundColor} mask={`url(#completion-${positionType})`} points={shape} />
          <path
            d={shapeEdge}
            fill={'none'}
            stroke={outlineColor}
            strokeWidth={outlineWidth}
            strokeOpacity={outlineOpacity}
          />
        </g>
      </svg>
      <div className={css.statusContent}>
        <div>
          <p className={css.trackerSubTitle}>{t('Order Tracker Title')}</p>
          <p className={css.trackerStatusText} role={isCurrentStatus ? 'status' : undefined}>
            {t(displayText)}
          </p>
        </div>
        <StaticSvgIcon name={iconName} isUnstyled={true} width={iconWidth} height={iconHeight} />
      </div>
    </div>
  )
}
