import React from 'react'

import { ActionButton } from '@dominos/components'
import { useDevToggles } from '@dominos/hooks-and-hocs'
import classNames from 'classnames'
import { EmptyPortionProduct } from './empty-portion-product'
import { PortionActionButton } from './portion-action-button'
import { PortionProduct } from './portion-product'
import { PortionTileViewProps } from './portion-tile-view.interface'
import css from './portion-tile-view.less'

export const PortionTileView = ({
  viewModel: {
    portionIndex,
    portionKey,
    products,
    selectPlaceholderText,
    selectedProductCode,
    disabled,
    customiseButtonText,
    customisable,
    showReset,
    resetButtonText,
  },
  onReset,
  onCustomise,
  onProductListOpen,
}: PortionTileViewProps): JSX.Element | null => {
  const testPrefix = `portion.tile-${portionIndex}`
  const selectedProduct = products.find((p) => p.code === selectedProductCode)

  const { isEnabled } = useDevToggles()

  const handleReset = () => {
    onReset(portionIndex)
  }
  const handleCustomise = () => {
    onCustomise()
  }

  const handleOnChange = () => {
    onProductListOpen(portionIndex)
  }

  return (
    <div
      className={classNames(css.cardWrapper, { [css.selected]: selectedProduct })}
      data-testid={`${testPrefix}.wrapper`}
    >
      {showReset && (
        <button onClick={handleReset} data-testid={`${testPrefix}.reset-button`} className={css.resetButton}>
          {resetButtonText}
        </button>
      )}

      {selectedProductCode ? (
        <PortionProduct
          imageUri={selectedProduct?.imageUri ?? ''}
          testID={`${testPrefix}.portion-product-image`}
          portionKey={portionKey}
        />
      ) : (
        <EmptyPortionProduct testID={`${testPrefix}`} portionKey={portionKey} />
      )}
      {selectedProductCode && (
        <div data-testid={`${testPrefix}.product-name`} className={css.selectedPortionText}>
          {selectedProduct?.name}
        </div>
      )}
      <div className={css.openProductListButtonWrapper}>
        <ActionButton
          disabled={disabled}
          testID={`${testPrefix}.open-product-list-button`}
          text={selectPlaceholderText}
          onPress={handleOnChange}
          className={css.openProductListButton}
        />
      </div>
      {isEnabled['show-portion-customize-button'] && customisable && selectedProductCode && (
        <div className={css.actionButtonWrapper}>
          <PortionActionButton
            text={customiseButtonText}
            onPress={handleCustomise}
            testID={`${testPrefix}.customise-button`}
          />
        </div>
      )}
    </div>
  )
}
