import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './breadcrumb.less'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

interface CrumbProps extends BaseProps {
  isCrumbActive: boolean
  isLastCrumb: boolean
  text: string
  onCrumbPress: () => void
  textNamespace?: string[]
}

export const Crumb = ({ isCrumbActive, isLastCrumb, text, onCrumbPress, textNamespace, testID }: CrumbProps) => {
  const { t } = useTranslation(textNamespace)
  const { colours } = useDominosTheme()

  const styles = {
    activeCrumbText: {
      color: colours.defaultBackground,
    },
    inactiveCrumb: {
      backgroundColor: colours.defaultBackground,
    },
    activeCrumb: {
      backgroundColor: colours.actionBlue,
    },
  }

  return (
    <div
      data-testid={`${testID}-crumb`}
      className={css.crumb}
      style={isCrumbActive ? styles.activeCrumb : styles.inactiveCrumb}
      onClick={onCrumbPress}
    >
      <div className={css.crumbText} style={isCrumbActive ? styles.activeCrumbText : {}}>
        {t(text)}
      </div>
      {!isLastCrumb ? (
        <div
          className={css.innerCurve}
          style={isCrumbActive && !isLastCrumb ? styles.activeCrumb : styles.inactiveCrumb}
        />
      ) : null}
    </div>
  )
}
