export const mapAddressToCustomerAddress = (
  address?: Address | Bff.Addresses.Address | null,
): CustomerAddressRequest | null => {
  if (!address) {
    return null
  }

  const { street, ...rest } = address

  return {
    ...rest,
    streetName: street,
  } as CustomerAddressRequest
}

export const mapDeliveryAddressToStoredCustomerAddress = (
  deliveryAddress?: Bff.Orders.OrderDetails.Header.DeliveryAddress | null,
): StoredCustomerAddress | null => {
  if (!deliveryAddress) {
    return null
  }
  const customerAddress = mapAddressToCustomerAddress(deliveryAddress.address)
  const geo = deliveryAddress.address.location
    ? { latitude: deliveryAddress.address.location.latitude, longitude: deliveryAddress.address.location.longitude }
    : undefined

  return {
    customerAddress: {
      ...customerAddress,
      buildingName: deliveryAddress.buildingName,
    },
    displayAddress: deliveryAddress.displayAddress,
    locale: deliveryAddress.locale,
    geo: geo,
    lastUsed: Date.now(),
  } as StoredCustomerAddress
}

export const mapSearchAddressToStoredCustomerAddress = (
  address?: SearchAddress | null,
): StoredCustomerAddress | null => {
  if (!address) {
    return null
  }

  const customerAddress = mapAddressToCustomerAddress(address.rawAddress)

  return {
    customerAddress,
    storeNo: address.storeNo,
    displayAddress: address.media.displayAddress,
    lastUsed: Date.now(),
  } as StoredCustomerAddress
}
type nameKey = { key: string; includeSeparator?: boolean; prefix?: string }
type CountryAddressDef = {
  [K in BffContext.Countries]: { nameKeys: nameKey[]; addressKeys: string[]; separator?: string }
}

const countryAddressDef: Partial<CountryAddressDef> & {
  default: { nameKeys: nameKey[]; addressKeys: string[]; separator?: string }
} = {
  NL: { nameKeys: [{ key: 'street' }, { key: 'streetNo/unitNo' }], addressKeys: ['postCode', 'suburb', 'state'] },
  BE: { nameKeys: [{ key: 'street' }, { key: 'streetNo/unitNo' }], addressKeys: ['postCode', 'suburb', 'state'] },
  DK: { nameKeys: [{ key: 'street' }, { key: 'streetNo unitNo' }], addressKeys: ['postCode', 'suburb', 'state'] },
  DE: { nameKeys: [{ key: 'street' }, { key: 'streetNo' }], addressKeys: ['postCode', 'suburb', 'state'] },
  FR: { nameKeys: [{ key: 'streetNo' }, { key: 'street' }], addressKeys: ['postCode', 'suburb', 'state'] },
  LU: { nameKeys: [{ key: 'streetNo' }, { key: 'street' }], addressKeys: ['postCode', 'suburb', 'state'] },
  SG: {
    nameKeys: [
      { key: 'streetNo' },
      { key: 'street', includeSeparator: true },
      { key: 'floorNo-unitNo', includeSeparator: true, prefix: '#' },
      { key: 'buildingName', includeSeparator: true },
      { key: 'suburb' },
      { key: 'postCode', includeSeparator: true },
    ],
    addressKeys: ['postCode', 'state'],
    separator: ',',
  },
  MY: {
    nameKeys: [
      { key: 'buildingName', includeSeparator: true },
      { key: 'unitNo', includeSeparator: true },
      { key: 'streetNo' },
      { key: 'street', includeSeparator: true },
    ],
    addressKeys: ['suburb', 'postCode', 'state'],
    separator: ',',
  },
  default: { nameKeys: [{ key: 'unitNo/streetNo' }, { key: 'street' }], addressKeys: ['suburb', 'state', 'postCode'] },
}

export const mapSearchAddressToAddressLine = (
  countryCode: BffContext.Countries = 'AU',
  customerAddresses?: SearchAddress[] | null,
): AddressLine[] => {
  if (!customerAddresses || customerAddresses.length === 0) {
    return []
  }

  const addressDef = countryAddressDef[countryCode] || countryAddressDef.default

  return customerAddresses.map((line): AddressLine => {
    const mapName = () => {
      const parts = addressDef.nameKeys.map((k, i) => {
        const unitSplitter = getUnitSplitter(k.key)
        if (unitSplitter) {
          const mapName: string | undefined = k.key
            .split(unitSplitter)
            .map((splitKey) => line.rawAddress[splitKey as keyof Address])
            // in case of empty unitNo
            .filter((x) => !!x)
            .join(unitSplitter)

          const mapNameWithPrefix = k.prefix && mapName ? `${k.prefix}${mapName}` : mapName

          return getAddressPartWithSeparator(mapNameWithPrefix, k.includeSeparator ? addressDef.separator : undefined)
        }

        return getAddressPartWithSeparator(
          line.rawAddress[k.key as keyof Address] as string,
          k.includeSeparator ? addressDef.separator : undefined,
        )
      })

      return getAddressDisplayName(parts, addressDef.separator)
    }

    const mapAddress = () =>
      addressDef.addressKeys
        .map((k) => line.rawAddress[k as keyof Address])
        .filter((address) => address)
        .join(' ')

    return {
      name: mapName(),
      addressId: '',
      address: mapAddress(),
      suffix: 'caret',
      uid: line.storeNo,
      additionalData: line,
    }
  })
}

export const mapCustomerAddressToAddress = (
  address: CustomerAddressRequest | null | undefined,
): Bff.Addresses.Address | null => {
  if (!address) {
    return null
  }

  return {
    buildingName: address.buildingName,
    unitNo: address.unitNo,
    streetNo: address.streetNo,
    street: address.streetName,
    suburb: address.suburb,
    state: address.state,
    postCode: address.postCode,
  } as Bff.Addresses.Address
}

const getAddressPartWithSeparator = (addressPart: string, separator?: string) => {
  if (!addressPart) return ''

  return separator ? `${addressPart}${separator}` : addressPart
}

const getAddressDisplayName = (parts: string[], separator?: string) => {
  const name = parts.filter((x) => x).join(' ')
  if (!separator) return name

  return name.endsWith(separator) ? name.slice(0, name.length - 1) : name
}

const getUnitSplitter = (key: string) => {
  if (key.includes('/')) return '/'

  if (key.includes(' ')) return ' '

  if (key.includes('-')) return '-'

  return null
}
