import { FeedbackSubmittedIcon } from '@dominos/res/images/icons/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './feedback-submitted.less'

const FeedbackSubmitted = () => {
  const { t } = useTranslation('tracker')

  return (
    <div className={styles.feedbackSubmitted}>
      <FeedbackSubmittedIcon />
      <h3>{t('FeedbackSubmittedNotification', { defaultValue: 'Feedback Submitted' })}</h3>
      <p>
        {t('ThanksForFeedbackSubmitted', {
          defaultValue: 'Thanks for taking the time to rate your Domino’s Experience.',
        })}
      </p>
    </div>
  )
}

export { FeedbackSubmitted }
