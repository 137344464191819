import css from './virtual-keyboard.less'

export const buttonTheme = [
  {
    class: css.closeButton,
    buttons: '{close}',
  },
  {
    class: css.switchLayoutButton,
    buttons: '{switchLanguage}',
  },
]
