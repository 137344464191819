import { isNativeApp, notifyNativeApp } from '@dominos/business/functions/native-app'
import jwt_decode from 'jwt-decode'
import { idTokenKey } from './id-token-key'
import { ForgeRockClient } from '@dominos/hooks-and-hocs'

// to prevent the token expiring between request and hitting the graph
export const EXPIRY_BUFFER_SEC = 60
export interface AuthenticationJwt {
  customerId: string
  exp: number
  longLived: boolean
  iat: number
  sub: string
}

export const idTokenUtils = () => {
  const getTokenFromLocalStorage = () => window?.localStorage?.getItem(idTokenKey)

  const decodeToken = (token: string | null): AuthenticationJwt | null => {
    if (token) {
      try {
        return jwt_decode<AuthenticationJwt>(token)
      } catch {
        // Decoding failed: Token invalid
        deleteToken()

        return null
      }
    }

    return null
  }

  const getDecodedTokenFromLocalStorage = (): AuthenticationJwt | null => {
    try {
      const token = getTokenFromLocalStorage()

      return decodeToken(token)
    } catch {
      return null
    }
  }

  const isExpired = (token: AuthenticationJwt | null): boolean => {
    if (token) {
      const currentTime = Math.round(new Date().getTime() / 1000) + EXPIRY_BUFFER_SEC

      return token.exp < currentTime
    }

    // don't try to calculate expiry of missing tokens
    return true
  }

  const isTokenLongLived = (): boolean => {
    const token = getDecodedToken()

    if (!token) {
      return true
    }

    return token.longLived
  }

  const deleteToken = (): void => {
    window.localStorage.removeItem(idTokenKey)
  }

  const getToken = () => {
    const jwtToken = getTokenFromLocalStorage()
    const decodedToken = getDecodedTokenFromLocalStorage()

    return decodedToken && jwtToken ? JSON.parse(jwtToken) : null
  }

  const getDecodedToken = (): AuthenticationJwt | null => {
    const token = getDecodedTokenFromLocalStorage()
    if (!token) {
      return null
    }

    if (isExpired(token)) {
      deleteToken()

      if (isNativeApp()) {
        notifyNativeApp('invalid-webview-token')
      }

      ForgeRockClient.getTokenFromStorage().then((tokens) => {
        if (tokens) {
          ForgeRockClient.logout()
        }
      })

      return null
    }

    return token
  }

  const saveToken = (token: string): void => {
    if (token) {
      try {
        window.localStorage.setItem(idTokenKey, JSON.stringify(token))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Error setting ${idTokenKey}:`, error)
      }
    }
  }

  return {
    getToken,
    getDecodedToken,
    decodeToken,
    saveToken,
    deleteToken,
    isTokenLongLived,
  }
}
