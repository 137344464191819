import { CheesyCrust } from './cheesy-crust'
import { Classic } from './classic'
import { Deep } from './deep'
import { DoubleDecadence } from './double-decadence'
import { Edge } from './edge'
import { ExtraLarge } from './extra-large'
import { FreshThin } from './fresh-thin'
import { GarlicBread } from './garlic-bread'
import { GarlicCheesy } from './garlic-cheesy'
import { NoGluten } from './no-gluten'
import { ThreeCheese } from './three-cheese-stacker'
import { CheeseBurstCrust } from './cheese-burst'
import { PuffPastry } from './puff-pastry'

export const ANZCrustIcons: { [code: string]: React.ComponentType<CrustSVGProps> } = {
  CLASSIC: Classic,
  '3CHEESE': ThreeCheese,
  CHEESYCRUST: CheesyCrust,
  DEEP: Deep,
  EDGE: Edge,
  XLCLASS: ExtraLarge,
  FRESHTHN: FreshThin,
  GBREAD: GarlicBread,
  NOGLUTEN: NoGluten,
  GARCHE: GarlicCheesy,
  C053: DoubleDecadence,
  C046: DoubleDecadence,
  C004L: CheeseBurstCrust,
  PUFF: PuffPastry,
}

export type ANZCrustIcon = keyof typeof ANZCrustIcons
