import { CountryToggle } from '@dominos/components'

export const useLastOrderAddressForStoreSearch = (deliveryAddress?: Bff.Orders.OrderDetails.Header.DeliveryAddress) => {
  if (deliveryAddress) {
    const { street, postCode, ...restAddress } = deliveryAddress?.address

    return CountryToggle({
      SG: { postCode },
      default: {
        ...restAddress,
        postCode,
        streetName: street,
      },
    })
  } else {
    return undefined
  }
}
