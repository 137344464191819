import { createMigrate, PersistConfig } from 'redux-persist'
import expireReducer from 'redux-persist-expire'
import createCompressor from 'redux-persist-transform-compress'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/es/storage'

const currentOrderStorageTime = 3600

const migrations = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  0: (state: any) => ({
    ...state,
    varietyReducer: undefined,
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  1: (state: any) => ({
    ...state,
    currentOrderDetailsReducer: state.currentOrderDetailsReducer
      ? state.currentOrderDetailsReducer
      : state.basketDetailsReducer,
  }),
}

export const basePersistConfig: PersistConfig = {
  storage,
  key: 'dominosApplication',
  version: 1, // update
  stateReconciler: autoMergeLevel2, // so it doesn't crash when we update state
  whitelist: [
    'applicationReducer',
    'basketReducer',
    'basketMetaReducer',
    'currentOrderDetailsReducer',
    'varietyReducer',
    'customerReducer',
    'sessionReducer',
    'storeReducer',
    'voucherReducer',
  ],
  blacklist: ['basketDetailsReducer'],
  transforms: [
    expireReducer('basketReducer', {
      persistedAtKey: 'loadedAt',
      expireSeconds: currentOrderStorageTime,
    }),
    createCompressor(),
  ],
  migrate: createMigrate(migrations, { debug: false }),
}
export const clearPersistedState = () => {
  storage.removeItem(`persist:${basePersistConfig.key}`)
}
