import { DateTime } from 'luxon'

export const getETARangeInMinutes = (min: DateTime, max: DateTime) => {
  const now = DateTime.local()

  return {
    from: Math.ceil(min.diff(now, 'minutes').minutes),
    to: Math.ceil(max.diff(now, 'minutes').minutes),
  }
}
