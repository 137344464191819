export const MY_ORDER_TITLE = 'basket:my order header'
export const MY_DETAILS_TITLE = 'My Details'
export const PAYMENT_TITLE = 'Payment'
export const CONFIRM_TITLE = 'BreadcrumbConfirm'

export const getBreadcrumbTitlesMyOrder = (orderAmount: number | null, itemsCount: number) => [
  MY_ORDER_TITLE,
  MY_DETAILS_TITLE,
  orderAmount === 0 && itemsCount ? CONFIRM_TITLE : PAYMENT_TITLE,
]
