import { useLayoutEffect } from 'react'

export const hideOverflow = (isNotMobile: boolean) => {
  const originalStyle = window.getComputedStyle(document.body).overflow
  if (isNotMobile && originalStyle) {
    document.body.style.overflow = 'hidden'
  }

  return originalStyle
}

const useOverflow = (isNotMobile: boolean) => {
  useLayoutEffect(() => {
    const originalStyle = hideOverflow(isNotMobile)

    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [])
}

export { useOverflow }
