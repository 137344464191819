import { isNativeApp } from '@dominos/business/functions'
import { MenuNavigation, SimpleFooter, StackNavigationBase } from '@dominos/components'
import { useKiosk, useMenuWithCouponPage } from '@dominos/hooks-and-hocs'
import { RouteComponentProps } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { MenuViewportContainer } from './menu-viewport-container'
import { postWebViewMessage } from '@dominos/business/functions'

interface MenuSceneProps extends RouteComponentProps {
  /**
   * Obtained from Router url parameter `:menuCategory`
   */
  menuCategory?: string
  /**
   * Obtained from Router url parameter `:selection`
   */
  selection?: string
  /**
   * Obtained from Router url parameter `:variety`
   */
  variety?: string
}

export const MenuScene = (props: MenuSceneProps) => {
  const { menuCategory, variety } = props
  const [isProductVisible, setIsProductVisible] = useState(false)
  const [currentProduct, setCurrentProduct] = useState<MenuItemDependents>()
  const [currentLine, setCurrentLine] = useState<BasketLineItem>()
  const [isEditing, setIsEditing] = useState<boolean>()
  const [section, setSection] = useState<string>('')
  const [addDirectlyToBasket, setAddDirectlyToBasket] = useState<boolean>(false)
  const { loadMenu, menu, menuPages, storeNo } = useMenuWithCouponPage()
  const { isKioskOrder } = useKiosk()
  const [swapping, setSwapping] = useState<BasketLine>()

  const toggleProductCard = (
    editing: boolean,
    item?: MenuItemDependents,
    lineData?: BasketLineItem,
    swapping?: BasketLine,
    addDirectlyToBasket?: boolean,
  ) => {
    setIsProductVisible(!isProductVisible)
    setCurrentProduct(item)
    setCurrentLine(lineData)
    setIsEditing(editing)
    setSwapping(swapping)
    setAddDirectlyToBasket(addDirectlyToBasket || false)
  }
  const clickNavigation = (item: string) => {
    if (!item) {
      return
    }
    setSection(item)
  }

  useEffect(() => {
    loadMenu()
  }, [storeNo])

  useEffect(() => {
    if (!isNativeApp() || !menu) return
    postWebViewMessage({
      type: 'web-menu-loaded',
    })
  }, [!!menu])

  return (
    <StackNavigationBase
      testID='menu-scene-stack'
      header={
        <MenuNavigation
          showMenu={!!menu}
          showBackButton={!!menuCategory}
          menu={menu}
          section={section}
          variety={variety}
          menuCategory={menuCategory}
        />
      }
      footer={
        isNativeApp() || isProductVisible ? null : (
          <SimpleFooter
            testID='menu-scene-stack'
            hasRestart={true}
            showTermsConditionsDisclaimers={!!menu && !isKioskOrder}
          />
        )
      }
    >
      <MenuViewportContainer
        clickNavigation={clickNavigation}
        isEditing={isEditing}
        currentLine={currentLine}
        currentProduct={currentProduct}
        toggleProductCard={toggleProductCard}
        filteredMenu={menu}
        menuPages={menuPages}
        isProductVisible={isProductVisible}
        swapping={swapping}
        addDirectlyToBasket={addDirectlyToBasket}
      />
    </StackNavigationBase>
  )
}
