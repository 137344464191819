import { mapBasketLineChangesToOptionBasketLineChanges } from '@dominos/business/functions/basket'
import { isProductMenuItemNew } from '@dominos/components'
import { BasketLineData } from './create-basket-line'
import { getBasketLineHalfProduct } from './get-basket-line-half-product'
import { getBasketLineRecipe } from './get-basket-line-receipt'

export const getBasketLineData = (
  flatMenu?: MenuItemDependentsDictionary | null,
  basketLine?: BasketLine | BasketLineData,
  side?: HalfSides,
  item?: MenuItemDependents,
): BasketLineData => {
  let product: MenuItemDependents | undefined = item
  let sizeCode: string | null | undefined = basketLine?.sizeCode
  const recipe = getBasketLineRecipe(basketLine as BasketLine, side)

  if (item?.type === 'Portion' || item?.type === 'Product') {
    if (item.type === 'Portion' && side) {
      product = getBasketLineHalfProduct(flatMenu, basketLine as BasketLine, side)
    }
    sizeCode = sizeCode ?? (!isProductMenuItemNew(item) ? item.defaultSize : undefined)
  }

  return {
    item: product,
    toppings: recipe && recipe.toppings,
    base: side ? undefined : recipe && (recipe as BasketLine).base,
    sauce: recipe && recipe.sauce,
    options: mapBasketLineChangesToOptionBasketLineChanges(recipe as BasketLine, product as ProductMenuItem),
    sizeCode: sizeCode ?? undefined,
  }
}
