import React from 'react'

const SUBMIT_KEY = 'Enter'
const SUBMIT_KEY_CODE = 13

export const TextAreaField = ({
  testID,
  accessibilityLabel,
  className,
  style,
  value,
  defaultValue,
  placeholder,
  maxLength,
  disabled,
  tabIndex,
  rows,
  onChange,
  onSubmitEditing,
  onFocus,
  onBlur,
}: TextAreaFieldProps) => {
  const didChangeTextInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value: inputValue = '' } = event.target
    if (onChange) {
      onChange(inputValue)
    }
  }

  const didPressKey = (event: React.KeyboardEvent<HTMLTextAreaElement> & { target: { value: string } }) => {
    // Handle enter key press by cancelling input and notifying submit listener
    if ((event.key === SUBMIT_KEY || event.charCode === SUBMIT_KEY_CODE) && onSubmitEditing) {
      event.preventDefault()
      onSubmitEditing(event.target.value)
    }
  }

  const preventSubmit = (event: React.FormEvent<HTMLTextAreaElement>) => event.preventDefault()

  return (
    <textarea
      aria-label={accessibilityLabel}
      data-testid={testID}
      onChange={didChangeTextInput}
      onSubmit={preventSubmit}
      className={className}
      onKeyPress={didPressKey}
      value={value}
      defaultValue={defaultValue}
      style={style}
      placeholder={placeholder}
      maxLength={maxLength}
      disabled={disabled}
      tabIndex={tabIndex}
      onFocus={onFocus}
      onBlur={onBlur}
      rows={rows}
    />
  )
}
