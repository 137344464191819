import { useLazyQuery } from '@apollo/client'
import { paymentBalanceQuery } from '@dominos/business/queries'
import { useDevToggles } from '@dominos/hooks-and-hocs'
import { PaymentBalance, PaymentBalanceResponse } from '@dominos/interfaces'
import { useMemo } from 'react'

export const usePaymentBalance = () => {
  const { isEnabled } = useDevToggles()
  const limitPaymentBalanceEnabled = isEnabled['limit-payment-balance']

  const [query, { data, error, loading }] = useLazyQuery<PaymentBalanceResponse>(paymentBalanceQuery, {
    fetchPolicy: 'no-cache',
  })

  const fetchPaymentBalance = async (
    providerCode?: string,
    paymentMethod?: BffContext.PaymentMethods,
    orderId?: string,
    authorisationCode?: string,
    nonce?: string,
    redirectUri?: string,
  ) => query({ variables: { providerCode, paymentMethod, orderId, authorisationCode, nonce, redirectUri } })

  const paymentBalance = useMemo<PaymentBalance | undefined>(() => {
    if (!data?.paymentBalance) {
      return undefined
    }

    return {
      ...data.paymentBalance,
      amount:
        limitPaymentBalanceEnabled && data.paymentBalance.amount && data.paymentBalance.amount > 1
          ? 1
          : data.paymentBalance.amount,
    }
  }, [data, limitPaymentBalanceEnabled])

  return {
    fetchPaymentBalance,
    loading,
    error,
    paymentBalance,
  }
}
