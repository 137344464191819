import React from 'react'
import { useTranslation } from 'react-i18next'
import { usePickupStoresPreviewComponent } from './use-pickup-stores-preview-component'
import { ListGroup } from '@dominos/components'

interface PickupStoreSearchResultsProps extends BaseProps {
  isLoading: boolean
  addresses: AddressLine[] | undefined
  onSelectStore: (id: string) => void
  itemLoadingKey: number | undefined
}

export const PickupStoresPreview = ({
  testID,
  addresses,
  isLoading,
  itemLoadingKey,
  onSelectStore,
}: PickupStoreSearchResultsProps) => {
  const { t } = useTranslation('pickup-search')
  const { mapAddressData } = usePickupStoresPreviewComponent({ t })

  if (!addresses) return null

  const data = mapAddressData(addresses)

  if (!data) return null

  return (
    <ListGroup
      testID={testID}
      data={data}
      onItemSelect={onSelectStore}
      isLoading={isLoading}
      itemLoadingKey={itemLoadingKey?.toString()}
      iconStyling={{ width: 30, height: 30 }}
    />
  )
}

export const AddressListGroupe = () => {}
