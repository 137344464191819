import { useLocalStorage } from '@dominos/hooks-and-hocs'
import React, { useCallback, useContext, useEffect } from 'react'
import { togglesConfig } from './toggles'

export type DevToggles = typeof togglesConfig
export type DevToggleKey = keyof DevToggles

const DevTogglesContext = React.createContext<[DevToggles, (flag: string, enabled: boolean) => void] | null>(null)

const DevTogglesProvider = ({ children }: { children: React.ReactNode }) => {
  const { storedValue, setValue } = useLocalStorage<DevToggles>({ key: 'dev-toggles', defaultValue: togglesConfig })

  useEffect(() => {
    const keysDoNotMatch = Object.keys(togglesConfig).join() !== Object.keys(storedValue).join()
    setValue(
      setTogglesFromParams(keysDoNotMatch ? togglesConfig : storedValue, new URLSearchParams(window.location.search)),
    )
  }, [window.location.search])

  const setDevToggle = (flag: string, enabled: boolean) => {
    setValue({ ...storedValue, [flag]: enabled })
  }

  return <DevTogglesContext.Provider value={[storedValue, setDevToggle]}>{children}</DevTogglesContext.Provider>
}

const useDevToggles = () => {
  const context = useContext(DevTogglesContext)

  if (!context) {
    throw new Error("'useDevToggles' must be a child of 'DevTogglesProvider'")
  }

  const [toggles, setDevToggle] = context

  const setToggle = useCallback(
    (flag: DevToggleKey, enabled: boolean) => setDevToggle(flag, enabled),
    [toggles, setDevToggle],
  )

  return {
    setToggle,
    isEnabled: toggles,
  }
}

const setTogglesFromParams = (toggles: DevToggles, query: URLSearchParams): DevToggles =>
  Object.fromEntries(
    Object.entries(toggles).map(([flag, isEnabled]) => [
      flag,
      query.has(flag) ? query.get(flag) === 'true' : isEnabled,
    ]),
  ) as DevToggles

export { DevTogglesProvider, useDevToggles }
