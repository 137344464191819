import { CountrySpecificMapping } from './google.interface'
/**
 * Used to support multiple address mapping per country
 * in conjunction with the google types.
 *
 * Allows the transforming of `GoogleAddressTypes`
 * to the one address attribute `CustomerAddressRequest`.
 * Based on `ProcessOrderAddressFromGooglePlaceIdAsync` in this:
 *
 * https://dev.azure.com/dominos-au/OneDigital/_git/Services.Aggregators.UberEats?_a=contents&version=GBmaster&path=/Dominos.Services.Aggregators.UberEats.App/Services/TransformService
 */
export const addressMappingsByCountry: CountrySpecificMapping = {
  AU: [
    { attribute: 'unitNo', googleTypes: [{ type: 'subpremise' }] },
    { attribute: 'streetNo', googleTypes: [{ type: 'street_number' }], required: true },
    { attribute: 'floorNo', googleTypes: [{ type: 'floor' }] },
    { attribute: 'buildingName', googleTypes: [{ type: 'building' }] },
    { attribute: 'streetName', googleTypes: [{ type: 'route' }], required: true },
    { attribute: 'suburb', googleTypes: [{ type: 'locality' }], required: true },
    { attribute: 'postCode', googleTypes: [{ type: 'postal_code' }], required: true },
    { attribute: 'state', googleTypes: [{ type: 'administrative_area_level_1' }], required: true },
    { attribute: 'nickname', googleTypes: [{ type: 'route' }] },
  ],
  FR: [
    { attribute: 'unitNo', googleTypes: [{ type: 'subpremise' }] },
    { attribute: 'streetNo', googleTypes: [{ type: 'street_number' }], required: true },
    { attribute: 'streetName', googleTypes: [{ type: 'route' }], required: true },
    { attribute: 'suburb', googleTypes: [{ type: 'locality' }], required: true },
    { attribute: 'postCode', googleTypes: [{ type: 'postal_code' }], required: true },
  ],
  DE: [
    { attribute: 'unitNo', googleTypes: [{ type: 'subpremise' }] },
    { attribute: 'streetNo', googleTypes: [{ type: 'street_number' }], required: true },
    { attribute: 'streetName', googleTypes: [{ type: 'route' }], required: true },
    { attribute: 'suburb', googleTypes: [{ type: 'locality' }], required: true },
    { attribute: 'postCode', googleTypes: [{ type: 'postal_code' }], required: true },
  ],
  JP: [
    {
      attribute: 'streetNo',
      googleTypes: [{ type: 'sublocality_level_4' }, { type: 'premise', rule: 'isNumeric' }],
      separator: '-',
      replaceStringRule: { searchValue: '−', replaceValue: '-' },
      required: true,
    },
    { attribute: 'floorNo', googleTypes: [{ type: 'floor' }] },
    { attribute: 'buildingName', googleTypes: [{ type: 'premise', rule: 'isString' }] },
    {
      attribute: 'streetName',
      googleTypes: [{ type: 'sublocality_level_2' }, { type: 'sublocality_level_3' }],
      separator: '',
      replaceStringRule: { searchValue: '丁目', replaceValue: '-' },
      required: true,
    },
    {
      attribute: 'suburb',
      googleTypes: [{ type: 'locality' }, { type: 'sublocality_level_1' }],
      separator: '',
      required: true,
    },
    { attribute: 'postCode', googleTypes: [{ type: 'postal_code' }], required: true },
    { attribute: 'state', googleTypes: [{ type: 'administrative_area_level_1' }], required: true },
    { attribute: 'nickname', googleTypes: [{ type: 'route' }] },
  ],
  LU: [
    { attribute: 'unitNo', googleTypes: [{ type: 'subpremise' }] },
    { attribute: 'streetNo', googleTypes: [{ type: 'street_number' }], required: true },
    { attribute: 'streetName', googleTypes: [{ type: 'route' }], required: true },
    { attribute: 'suburb', googleTypes: [{ type: 'locality' }], required: true },
    { attribute: 'postCode', googleTypes: [{ type: 'postal_code' }], required: true },
  ],
  NL: [
    { attribute: 'unitNo', googleTypes: [{ type: 'subpremise' }] },
    { attribute: 'streetNo', googleTypes: [{ type: 'street_number' }] },
    { attribute: 'streetName', googleTypes: [{ type: 'route' }], required: true },
    { attribute: 'suburb', googleTypes: [{ type: 'locality' }], required: true },
    {
      attribute: 'postCode',
      googleTypes: [{ type: 'postal_code' }],
      replaceStringRule: { searchValue: ' ', replaceValue: '' },
      required: true,
    },
  ],
  NZ: [
    { attribute: 'unitNo', googleTypes: [{ type: 'subpremise' }] },
    { attribute: 'streetNo', googleTypes: [{ type: 'street_number' }], required: true },
    { attribute: 'floorNo', googleTypes: [{ type: 'floor' }] },
    { attribute: 'buildingName', googleTypes: [{ type: 'building' }] },
    { attribute: 'streetName', googleTypes: [{ type: 'route' }], required: true },
    { attribute: 'suburb', googleTypes: [{ type: 'locality' }], required: true },
    { attribute: 'postCode', googleTypes: [{ type: 'postal_code' }], required: true },
    { attribute: 'state', googleTypes: [{ type: 'administrative_area_level_1' }], required: true },
    { attribute: 'nickname', googleTypes: [{ type: 'route' }] },
  ],
  BE: [
    { attribute: 'unitNo', googleTypes: [{ type: 'subpremise' }] },
    { attribute: 'streetNo', googleTypes: [{ type: 'street_number' }] },
    { attribute: 'streetName', googleTypes: [{ type: 'route' }], required: true },
    { attribute: 'suburb', googleTypes: [{ type: 'locality' }], required: true },
    { attribute: 'postCode', googleTypes: [{ type: 'postal_code' }], required: true },
  ],
  DK: [],
  SG: [],
  TW: [],
  MY: [
    { attribute: 'streetNo', googleTypes: [{ type: 'street_number' }] },
    { attribute: 'buildingName', googleTypes: [{ type: 'premise' }] },
    { attribute: 'streetName', googleTypes: [{ type: 'route' }] },
    {
      attribute: 'suburb',
      googleTypes: [{ type: 'sublocality_level_1' }, { type: 'locality', required: true }],
      separator: ', ',
      required: true,
    },
    { attribute: 'postCode', googleTypes: [{ type: 'postal_code' }], defaultValue: '00000', required: true },
    { attribute: 'state', googleTypes: [{ type: 'administrative_area_level_1' }], required: true },
  ],
  KH: [],
}
