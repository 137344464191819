import React from 'react'
import { AllergenInfoProps } from './allergen-info.interface'
import { AllergenInfoView } from './allergen-info-view'
import { createAllergenInfoViewModel } from './create-allergen-info-view-model'

export const AllergenInfo = ({ info, testID, t, showContainsAllergensOnly }: AllergenInfoProps): JSX.Element => {
  const viewModel = createAllergenInfoViewModel({
    t,
    info,
    showContainsAllergensOnly,
  })

  return <>{viewModel && <AllergenInfoView testID={`${testID}`} viewModel={viewModel}></AllergenInfoView>}</>
}
