import { useLazyQuery, useQuery } from '@apollo/client'
import { getCustomerId } from '@dominos/business/functions/customer'
import { customerDetailsQuery } from '@dominos/business/queries'
import { useEffect, useMemo } from 'react'
import { useAuthentication, useLanguages } from '@dominos/hooks-and-hocs'

export const useCustomer = () => {
  const customerId = getCustomerId()
  const { language } = useLanguages()
  const { setLoggedInUser } = useAuthentication()
  const { data, loading, error } = useQuery<{ customer: Bff.Customers.Customer | null }>(customerDetailsQuery, {
    variables: {
      customerId,
    },
    skip: !customerId,
  })

  const [getCustomerQuery] = useLazyQuery<{ customer: Bff.Customers.Customer | null }>(customerDetailsQuery, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data?.customer) {
      setLoggedInUser(true)
    }
  }, [data])

  const isLoggedIn = useMemo(() => (loading ? undefined : !!data?.customer?.id), [data?.customer])
  const isLoyaltyEnrolled = useMemo(() => data?.customer?.loyalty?.subscription?.isEnrolled || null, [data?.customer])
  const isLoyaltyMarketingEnrolled = useMemo(
    () => data?.customer?.emailSubscriptions?.find(({ edmType }) => edmType === 'LoyaltyNotification') || null,
    [data?.customer],
  )

  const getCustomer = (id?: string | null) => {
    if (id || customerId) {
      return getCustomerQuery({ variables: { customerId: id || customerId } })
    }
  }

  useEffect(() => {
    getCustomer()
  }, [language])

  return {
    customer: data?.customer,
    loading,
    error,
    getCustomer,
    isLoggedIn,
    isLoyaltyEnrolled,
    isLoyaltyMarketingEnrolled,
  }
}
