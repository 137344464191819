import { WindowLocation } from '@reach/router'

/**
 * Gets the value passed through the Reach Router `navigate` function, as per below:
 *
 * ```
 * const sectionTitle = getStateFromNavigation(props, 'categoryTitle')
 * ```
 *
 * @param location
 * @param navigationState name of state (eg. 'categoryTitle' above)
 */
export const getStateFromNavigation = <R = unknown>(location: WindowLocation | undefined, navigationState: string): R =>
  (location && location.state && (location.state as { [K: string]: unknown })[navigationState]
    ? (location.state as { [K: string]: unknown })[navigationState]
    : null) as R
