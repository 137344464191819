import React from 'react'
import { useSelector } from 'react-redux'
import { ApplicationContext } from './application-context'

export const ApplicationProvider = (props: PropsWithChildren) => {
  const applicationConfiguration = useSelector((state: RootReducer) => state.applicationReducer)

  if (applicationConfiguration) {
    return <ApplicationContext.Provider value={applicationConfiguration}>{props.children}</ApplicationContext.Provider>
  }

  return props.children as JSX.Element
}
