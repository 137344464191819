import { BESizeCode, BESizeIcons } from '@dominos/components/product/size-icon/size-icons/BE'
import { DESizeCode, DESizeIcons } from '@dominos/components/product/size-icon/size-icons/DE'
import { DKSizeCode, DKSizeIcons } from '@dominos/components/product/size-icon/size-icons/DK'
import { FRSizeCode, FRSizeIcons } from '@dominos/components/product/size-icon/size-icons/FR'
import { JPSizeCode, JPSizeIcons } from '@dominos/components/product/size-icon/size-icons/JP'
import { LUSizeCode, LUSizeIcons } from '@dominos/components/product/size-icon/size-icons/LU'
import { NLSizeCode, NLSizeIcons } from '@dominos/components/product/size-icon/size-icons/NL'

export const mapCountryAndSizeCodeToIcon = (countryCode: string, sizeCode: string) => {
  if (!countryCode || !sizeCode) {
    return null
  }

  let icon = null
  switch (countryCode) {
    case 'BE':
      icon = BESizeIcons[sizeCode as BESizeCode]
      break
    case 'DE':
      icon = DESizeIcons[sizeCode as DESizeCode]
      break
    case 'DK':
      icon = DKSizeIcons[sizeCode as DKSizeCode]
      break
    case 'FR':
      icon = FRSizeIcons[sizeCode as FRSizeCode]
      break
    case 'JP':
      icon = JPSizeIcons[sizeCode as JPSizeCode]
      break
    case 'LU':
      icon = LUSizeIcons[sizeCode as LUSizeCode]
      break
    case 'NL':
      icon = NLSizeIcons[sizeCode as NLSizeCode]
      break
    default:
      return null
  }

  return icon ?? null
}
