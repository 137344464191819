import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { rootActions } from '@dominos/business'
import { convertAddressLineToStore, convertToStoredCustomerAddress } from '@dominos/business/functions/address'
import { useAlert, useCustomerAddressSearch } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { navigate, Redirect, Router } from '@reach/router'

import { DeliveryAddressNavigationConstants } from './delivery-address-container-jp.navigation'
import { mapJPSearchAddressToAddressLine } from './map-jp-search-address-to-address-line'
import { SearchContainer } from './search-container'
import { SearchListContainer } from './search-list-container'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

export const DeliveryAddressContainerJP = () => {
  const { t } = useTranslation('delivery-address')
  const { showAlert } = useAlert()

  const dispatch = useDispatch()
  const setStore = (store: Bff.Stores.Store) => dispatch(rootActions.storeSelected(store, true))
  const { getAddress, data, loading, errors } = useCustomerAddressSearch()
  const [addresses, setAddresses] = useState<AddressLine[]>([])
  const {
    reportFallBackAddressSearchNoAddressFound,
    reportFallBackAddressSearchAddressSearched,
    reportFallBackAddressSearchAddressSelected,
  } = useReportAutoCompleteDelivery()

  const selectCustomerAddress = (address: StoredCustomerAddress) => {
    reportFallBackAddressSearchAddressSelected(address)
    dispatch(rootActions.addCustomerAddressToOrder(address))
    dispatch(rootActions.selectServiceMethod('Delivery'))
  }

  const onSearch = (searchInput: ValidationField) => {
    reportFallBackAddressSearchAddressSearched({ postCode: searchInput.value })
    getAddress({ postCode: searchInput.value })
  }

  const onAddressClick = (addressLine: AddressLine) => {
    setStore(convertAddressLineToStore(addressLine))

    selectCustomerAddress(
      convertToStoredCustomerAddress({
        storeNo: addressLine.additionalData.storeNo,
        name: addressLine.name,
        rawAddress: addressLine.additionalData.rawAddress,
      }),
    )

    navigate(NavigationConstants.timedOrder)
  }

  useEffect(() => {
    if (errors && errors.length > 0) {
      showAlert(errors[0].message)
    }
  }, [errors])

  useEffect(() => {
    if (!data) {
      return
    }

    const addressLines = mapJPSearchAddressToAddressLine(data ? data.deliverySearch : [])

    setAddresses(addressLines)
    if (!!addressLines && addressLines.length > 0) {
      navigate(NavigationConstants.deliveryAddressSearchList)
    } else {
      reportFallBackAddressSearchNoAddressFound()
      showAlert(t(`PostalCodeNotFoundAlertText`, { default: 'Postal code not found' }), 'PostalCodeNotFoundAlertText')
    }
  }, [data])

  return (
    <Router>
      <Redirect noThrow from={'/'} to={DeliveryAddressNavigationConstants.search} />
      <SearchContainer path={DeliveryAddressNavigationConstants.search} onSearch={onSearch} loading={loading} />
      <SearchListContainer
        path={DeliveryAddressNavigationConstants.searchList}
        addresses={addresses}
        onLinePress={onAddressClick}
      />
    </Router>
  )
}
