import { Config } from '../common/get-config'
import { LoadScriptProps } from '../common/load-script'

export const setupFacebook = (applicationConfig: Config, loadScript: (props: LoadScriptProps) => void) => {
  if (applicationConfig.FACEBOOK_SDK_APP_ID !== '' && applicationConfig.FACEBOOK_SDK_APP_ID !== 'null') {
    loadScript({
      id: 'facebook_sdk',
      setupScriptFunc: (script) => {
        script.async = true
        script.defer = true
        script.crossOrigin = 'anonymous'
        script.src = `https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v17.0&appId=${applicationConfig.FACEBOOK_SDK_APP_ID}&autoLogAppEvents=1`
      },
    })
  }
}
