import { StaticSvgIcon } from '@dominos/res'
import React, { useEffect, useState } from 'react'
import css from './product-upsell-confirmation.less'
import { AnimatePresence, motion } from 'framer-motion'

export interface ProductUpsellConfirmationProps extends BaseProps {
  title: string
  description?: string
}

const confirmationDisplayDuration = 1500

export const ProductUpsellConfirmation = ({ testID, title, description = '' }: ProductUpsellConfirmationProps) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, confirmationDisplayDuration)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key='productUpsellConfirmation'
          data-testid={testID}
          className={css.animationView}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.8 } }}
        >
          <div className={css.confirmationContent}>
            <div className={css.imgColumn}>
              <StaticSvgIcon name={'confirmationTick'} />
            </div>
            <div className={css.textColumn}>
              <div className={css.confirmationTitle}>{title}</div>
              <div className={css.confirmationDescription}>{description}</div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default ProductUpsellConfirmation
