import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 35,
  height: 35,
}

const ConfirmationTick = (props: SvgIconProps) => {
  const fill = props.fill || '#1fc36a'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 35 35'>
      <circle id='Oval' fill={fill} cx='17.5' cy='17.5' r='17.5' />
      <path
        id='Path'
        fill='#FFFFFF'
        fillRule='nonzero'
        stroke='none'
        strokeWidth='1'
        d='M14.6664815,27.2762687 C13.9593674,27.2762687 13.4011455,26.959924 12.8615208,26.3272727 L7.50243022,19.7958465 C7.16748944,19.3864771 7,18.9212778 7,18.4746758 C7,17.4698497 7.78154118,16.706903 8.7305337,16.706903 C9.30739098,16.706903 9.79118585,16.9488204 10.2563835,17.5070443 L14.6106517,22.9964119 L23.0028446,9.59865547 C23.4122124,8.92877146 23.9518371,8.61242676 24.5286944,8.61242676 C25.4590897,8.61242676 26.3522905,9.26371346 26.3522905,10.2499422 C26.3522905,10.6965061 26.1289713,11.1617055 25.8684575,11.5711129 L16.4156125,26.2714427 C15.9690502,26.9413267 15.3735957,27.2762687 14.6664815,27.2762687 Z'
      />
    </svg>
  )
}

export { ConfirmationTick }
