/**
 * Gets the surcharge matching the specified code
 * @param surchargeCode Surcharge code to match
 * @param surcharges Collection of surcharges
 */
export const getSurchargeWithCode = (surchargeCode: string, surcharges?: BasketLineSurcharge[]) =>
  surcharges && surcharges.find(({ code }) => code === surchargeCode)

/**
 * Gets the surcharge price from a list of surcharges
 * @param surchargeCode Surcharge code to match
 * @param surcharges Collection of surcharges
 */
export const getSurchargePriceWithCode = (surchargeCode: string, surcharges?: BasketLineSurcharge[]) => {
  const surcharge = getSurchargeWithCode(surchargeCode, surcharges)

  return (surcharge && surcharge.price) || undefined
}
