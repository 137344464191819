import { useLocalStorage } from '@dominos/hooks-and-hocs/browser-storage'

export const MAX_TIMES_TO_SHOW = 2

export const useOrderDetailsPillInformationPreference = () => {
  const { storedValue, setValue } = useLocalStorage({
    key: 'sujm:order-details-pill-information',
    defaultValue: { showCount: 0, neverShowAgain: false },
  })

  const dismissInformation = (neverShowAgain: boolean = false) =>
    setValue({ ...storedValue, showCount: storedValue.showCount + 1, neverShowAgain })

  return {
    isOrderDetailsPillInformationAllowed: storedValue.showCount < MAX_TIMES_TO_SHOW && !storedValue.neverShowAgain,
    dismissInformation,
  }
}
