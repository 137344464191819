import React from 'react'
import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 23,
  height: 34,
}

export const TouchIcon = (props: SvgIconProps) => {
  const stroke = props.stroke || '#ffffff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  const computedWidth = typeof width === 'number' ? `${width}px` : width
  const computedHeight = typeof height === 'number' ? `${height}px` : height

  return (
    <svg data-testid='touch-icon' width={computedWidth} height={`${computedHeight}`} viewBox='0 0 89 133'>
      <g
        stroke={stroke}
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
        transform='translate(3, 3)'
      >
        <path
          d='M68.5762328,86.0395001 C68.5762328,86.0395001 68.5762328,79.1869995 68.5762328,73.57797 C68.5762328,69.6154555 71.8048892,66.4033851 75.8233885,66.4033851 L76.004284,66.4033851 C79.86809,66.4033851 83,69.5192062 83,73.3631635 L83,86.7947063 C83,96.5076165 82.1285273,103.467896 80.9917581,108.415713 C79.5105821,114.861911 75.0547088,120.234077 69.0131029,122.990718 L69.0131029,122.990718 C58.0628812,127.98716 45.5387682,128.327542 34.3308082,123.93366 L34.3308082,123.93366 C27.639188,121.310365 21.9427448,116.665583 18.0434147,110.653008 L1.98478595,85.8918676 C-0.679013013,82.3875401 -0.0887093176,77.4161623 3.3226061,74.6256839 L3.3226061,74.6256839 C6.38447585,72.1211964 10.8058329,72.1430029 13.8425083,74.677819 L25.1915566,84.1513591 C25.1915566,84.1513591 25.1915566,48.3227995 25.1913047,32.6690002 C25.1913047,28.7064857 28.420213,25.4944152 32.4384604,25.4944152 L32.6196078,25.4944152 C36.4831618,25.4944152 39.6150719,28.6104871 39.6150719,32.4541937 L39.7289503,75.8438394 L39.7289503,61.9977227 C39.7289503,58.0352083 42.9578586,54.8228872 46.9408339,54.8228872 L46.9408339,54.8228872 C50.9238092,54.8228872 54.1527175,58.0352083 54.1527175,61.9977227 L54.1527175,79.9975992 L54.1527175,67.0325147 C54.1527175,63.070251 57.3813739,59.8579298 61.3643492,59.8579298 L61.3643492,59.8579298 C65.3473245,59.8579298 68.5762328,63.070251 68.5762328,67.0325147 L68.5762328,86.0395001 Z'
          id='Stroke-1'
          strokeWidth='5.77525'
        ></path>
        <path
          d='M15.1050225,59.6577864 C6.02548151,53.9224296 0,43.8302871 0,32.3382684 C0,14.4782542 14.5530142,0 32.5049987,0 C50.4569831,0 65.0099973,14.4782542 65.0099973,32.3382684 C65.0099973,37.9676005 63.5640934,43.2610626 61.0217312,47.8712548'
          id='Stroke-3'
          strokeWidth='5.77525'
        ></path>
        <path
          d='M15.2611015,41.1230995 C13.9023701,38.4887756 13.1357059,35.5025401 13.1357059,32.338093 C13.1357059,21.6957753 21.8076023,13.0684272 32.5048727,13.0684272 C43.202395,13.0684272 51.8742914,21.6957753 51.8742914,32.338093 C51.8742914,35.8887906 50.9090957,39.2151572 49.2253571,42.0715564'
          id='Stroke-5'
          strokeWidth='3.76425'
        ></path>
      </g>
    </svg>
  )
}
