import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 73,
}

export const Gauge1 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 73'>
      <defs>
        <path
          d='M80.9351054,94.4 L72.1351054,94.4 C70.9351054,94.4 70.1351054,93.2 70.6351054,92.1 L74.4351054,82.8 C75.0351054,81.4 76.4351054,80.5 77.9351054,80.5 L94.4351054,80.5 C95.8351054,80.5 96.9351054,81.6 96.9351054,83 L96.9351054,136.1 C96.9351054,136.4 97.1351054,136.6 97.4351054,136.6 L105.435105,136.6 C106.835105,136.6 107.935105,137.7 107.935105,139.1 L107.935105,148 C107.935105,149.4 106.835105,150.5 105.435105,150.5 L72.9351054,150.5 C71.5351054,150.5 70.4351054,149.4 70.4351054,148 L70.4351054,139.1 C70.4351054,137.7 71.5351054,136.6 72.9351054,136.6 L80.9351054,136.6 C81.2351054,136.6 81.4351054,136.4 81.4351054,136.1 L81.4351054,94.9 C81.4351054,94.6 81.2351054,94.4 80.9351054,94.4 Z'
          id='gauge-path-1'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Points1' transform='translate(-68.000000, -79.000000)' fillRule='nonzero'>
          <g id='Path'>
            <use fill={fill} href='#gauge-path-1' />
            <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-1' />
          </g>
        </g>
      </g>
    </svg>
  )
}
