import { rootActions } from '@dominos/business/root.actions'
import { CookieCategory } from '@dominos/hooks-and-hocs/one-trust/one-trust-provider'
import { Settings } from 'luxon'
import { getType } from 'typesafe-actions'

export interface PassiveSalesContext {
  alternativeStores: Bff.Stores.Store[]
  originalStore?: Bff.Stores.Store
}

export interface StoreReducerState {
  savedStores: TimeAugmentedStore[]
  currentStore?: Bff.Stores.Store
  passiveSales: PassiveSalesContext
  loadingCurrentStore?: boolean
}

// Todo Update when we bring in timed order and order selection
export const storeReducer: import('redux').Reducer<StoreReducerState> = (
  state: StoreReducerState = {
    savedStores: [],
    currentStore: undefined,
    passiveSales: { alternativeStores: [] },
    loadingCurrentStore: undefined,
  },
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.addCustomerAddressToOrder):
    case getType(rootActions.retrievingStoreInformation):
      return {
        ...state,
        loadingCurrentStore: true,
      }
    case getType(rootActions.retrievedStoreInformation):
    case getType(rootActions.storeSelected):
      if (action.store == null) {
        return state
      }

      Settings.defaultZoneName = action.store.timeZone
      if (action.remember) {
        let stores = [...state.savedStores]
        const index = state.savedStores.findIndex((store) => action.store.storeNo === store.storeNo)
        if (index > -1) {
          stores[index].lastUsed = Date.now()
        } else {
          stores = [...state.savedStores, { ...action.store, lastUsed: Date.now() }]
        }

        return {
          ...state,
          savedStores: stores.sort((a, b) => (b.lastUsed || 0) - (a.lastUsed || 0)),
          currentStore: action.store,
          loadingCurrentStore: false,
        }
      }

      return { ...state, currentStore: action.store, loadingCurrentStore: false }
    case getType(rootActions.retrievedStoreAlternativeStores):
      return { ...state, passiveSales: { ...state.passiveSales, alternativeStores: action.storeAlternativeStores } }
    case getType(rootActions.consentNotGiven):
      if (action.payload === CookieCategory.Functional) {
        return {
          ...state,
          savedStores: [],
        }
      }

      return state
    case getType(rootActions.saveOrder):
      return { ...state, currentStore: action.payload.order.store }
    case getType(rootActions.saveOrderToLocalState):
      return { ...state, currentStore: action.payload.store }
    case getType(rootActions.restartOrder):
    case getType(rootActions.resetCurrentStore):
      return {
        ...state,
        currentStore: undefined,
        passiveSales: { alternativeStores: [] },
      }
    case getType(rootActions.setSavedStores):
      return {
        ...state,
        savedStores: action.savedStores,
      }
    case getType(rootActions.appStartedForKioskWeb):
      return { ...state, currentStore: { storeNo: action.payload.storeNo } }
    case getType(rootActions.alternativeStoreSelected):
      if (!state.passiveSales.originalStore) {
        return { ...state, passiveSales: { ...state.passiveSales, originalStore: state.currentStore } }
      }

      if (action.storeNo === state.passiveSales.originalStore?.storeNo) {
        return { ...state, passiveSales: { ...state.passiveSales, originalStore: undefined } }
      }

      return state
    default:
      return state
  }
}
