import { rootActions } from '@dominos/business/root.actions'
import { ApplicationConfiguration } from '@dominos/interfaces'
import { getType } from 'typesafe-actions'
import { applicationContextBuilder } from '../functions/common'

export const applicationReducer: import('redux').Reducer<ApplicationConfiguration> = (
  state: ApplicationConfiguration = applicationContextBuilder,
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.applicationStarted):
    case getType(rootActions.applicationLocationPermission):
      return {
        ...state,
        hasLocationPermission: action.payload.hasLocationPermission,
      }
    case getType(rootActions.applicationEnvironment):
      return {
        ...state,
        ...action.payload,
      }
    case getType(rootActions.checkoutFromNativeApp):
    case getType(rootActions.appStartedFromNativeApp):
    case getType(rootActions.updateLanguage):
      return {
        ...state,
        language: action.payload.language,
      }
    case getType(rootActions.updateWebPushNotification):
      return {
        ...state,
        webPushNotificationClientId: action.payload.webPushNotificationClientId,
      }
    case getType(rootActions.appStartedForKioskWeb):
      return {
        ...state,
        kioskConfig: action.payload,
      }
    default:
      return state
  }
}
