import {
  ApplicationEnvironment,
  ApplicationLocationPermission,
  KioskConfigData,
  NativeAppBasketData,
  NativeAppFosData,
  NativeAppStartupData,
} from '@dominos/interfaces'
import { createStandardAction } from 'typesafe-actions'

export interface UpdateLanguageActionProps {
  language: BffContext.Languages
}
export interface UpdateWebPushNotificationProps {
  webPushNotificationClientId: string
}

export const applicationStarted = createStandardAction('APPLICATION:STARTED')<ApplicationLocationPermission>()
export const applicationLocationPermission = createStandardAction(
  'APPLICATION:location-permission',
)<ApplicationLocationPermission>()
export const applicationEnvironment = createStandardAction('APPLICATION:environment')<ApplicationEnvironment>()
export const updateLanguage = createStandardAction('APPLICATION:update-language')<UpdateLanguageActionProps>()
export const updateWebPushNotification = createStandardAction(
  'APPLICATION:update-web-push-notification-client-id',
)<UpdateWebPushNotificationProps>()
export const checkoutFromNativeApp = createStandardAction('APPLICATION:CHECKOUT_FROM_NATIVE_APP')<NativeAppBasketData>()
export const importFosSessionFromNativeApp = createStandardAction(
  'APPLICATION:FOS_SESSION_FROM_NATIVE_APP',
)<NativeAppFosData>()
export const appStartedFromNativeApp = createStandardAction(
  'APPLICATION:APP_STARTED_FROM_NATIVE_APP',
)<NativeAppStartupData>()
export const appStartedForKioskWeb = createStandardAction('APPLICATION:STARTED_FOR_KIOSK_WEB')<KioskConfigData>()
