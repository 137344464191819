export interface RequestedFields {
  name: string
  id: string
  email?: string
}

export interface GoogleLoginError {
  error: string
  details: string
}

export interface CustomLoginButtonProps {
  disabled?: boolean
  onClick: () => void
}

export interface GoogleAuthClient {
  requestAccessToken: () => void
}

export interface GoogleAuthResponse {
  access_token: string
}

export interface GoogleAuthErrorResponse {
  message: string
  type: string
}

export enum GoogleLoginErrorCode {
  POPUP_FAILED_TO_OPEN = 'popup_failed_to_open',
}
