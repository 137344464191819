import React from 'react'
import { useTranslation } from 'react-i18next'
import { DetailRow } from '../../detail-row'

interface SauceDetailProps extends BaseProps {
  sauce?: BasketLineSwapMedia | null
  price?: number
  noLabel?: boolean
  testID: string
}

const Sauce = ({ sauce, price, testID, noLabel }: SauceDetailProps) => {
  const { t } = useTranslation('menu')

  if (sauce) {
    return (
      <DetailRow
        testID={testID}
        label={t('Sauce')}
        noLabel={noLabel}
        description={sauce.add || t('No sauce')}
        price={price}
      />
    )
  }

  return null
}

export default Sauce
