import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import css from './delivery-address-search.less'

export interface MapListToggleProps {
  testID: string
  searchMode: string
  onTitleTriggerClick: () => void
}

export const MapListToggle = (props: MapListToggleProps) => (
  <div
    data-testid={`map-list-toggle.${props.testID}`}
    className={css.mapListButton}
    onClick={props.onTitleTriggerClick}
  >
    {props.searchMode === 'List' ? <StaticSvgIcon name={'mapIcon'} /> : <StaticSvgIcon name={'listIcon'} />}
  </div>
)
