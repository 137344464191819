import { useCountryCode, useKiosk, useLocalStorage, useReport } from '@dominos/hooks-and-hocs'

const getCssFile = (hostname: string) =>
  ([].slice.call(document.head.getElementsByTagName('link')) as HTMLLinkElement[]).find((l) =>
    // eslint-disable-next-line security/detect-non-literal-regexp
    new RegExp(`${hostname}\/main\.(.+)\.css`).test(l.href),
  )?.href

export const useResourceLoader = () => {
  const { reportResourceLoadingError } = useReport()
  const { storeNo, kioskId } = useKiosk()
  const countryCode = useCountryCode()
  const {
    storedValue: cssSource,
    setValue: setCssSource,
    clearValue: clearCssSource,
  } = useLocalStorage<string>({ key: 'olo-main-css' })

  const cssFile = getCssFile(window.location.hostname)
  const handleHardReload = () => {
    fetch(window.location.href, { cache: 'reload' }).finally(() => window.location.reload())
  }

  const loadCssResource = () => {
    if (!cssFile) return

    fetch(cssFile).then((e) => {
      if (e.status != 200) {
        reportResourceLoadingError(cssFile, e.status, cssFile == cssSource, kioskId, storeNo, countryCode)
        if (cssSource != cssFile) {
          setCssSource(cssFile)
          handleHardReload()
        }
      } else {
        clearCssSource()
      }
    })
  }

  return { loadCssResource }
}
