import gql from 'graphql-tag'

export const storeSearchQuery = gql`
  query storeSearchQuery($searchString: String!) {
    storeSearch(searchString: $searchString) {
      storeNo
      serviceMethods {
        delivery
        dineIn
        message
        pickup
      }
      media {
        name
        displayAddress
      }
      geo {
        longitude
        latitude
      }
      timeZone
      loyalty {
        isEnabled
      }
      priceInfo {
        taxRate
        minOrderPricePickup
        minOrderPriceDelivery
      }
    }
  }
`
