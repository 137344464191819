/**
 * Combine an array of nullable strings by filtering out
 * undefined | null elements and then join by '. '
 * NOTE: join by '. ' is used to make screen reader pause
 * between elements
 * @param textArray an array of nullable text used to
 * generate accessibleLable
 */
export const accessibilityLabelGenerator = (textArray: (string | undefined | null)[]) =>
  textArray.filter((v) => typeof v === 'string').join('. ')
