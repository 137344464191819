import React from 'react'
import { ActionButton, CollapsableTitledCard } from '@dominos/components'
import externalStyles from '../timed-order-picker-container.less'
import { OrderNowContainerProps } from '../../interfaces'
import { PreOrderETA } from './partials'
import { useOrderNowDescriptions } from '@dominos/hooks-and-hocs/ordering/use-order-now-descriptions'

export const OrderNowContainer = ({
  currentStore,
  isAsapAvailable,
  serviceMethod,
  expanded,
  onToggleExpanded,
  loading,
  onStartOrderAction,
  t,
}: OrderNowContainerProps) => {
  const { primaryTitle, serviceMethodTitle, startOrderButtonText } = useOrderNowDescriptions(serviceMethod, t)

  return (
    <CollapsableTitledCard
      primaryTitle={primaryTitle}
      startCollapsed={!expanded}
      onToggleExpanded={onToggleExpanded}
      noPadding={false}
      testID={'order-now'}
    >
      <div className={externalStyles.pickersContainer}>
        <PreOrderETA
          testID='pre-order-eta'
          loading={loading!}
          serviceMethodTitle={serviceMethodTitle!}
          serviceMethod={serviceMethod}
          currentStore={currentStore}
          t={t}
        />
        {isAsapAvailable && expanded && (
          <ActionButton
            text={startOrderButtonText}
            disabled={loading}
            onPress={onStartOrderAction}
            testID='start-order-now-button'
            loading={loading}
            disabledBackgroundColor='rgba(116, 116, 128, 0.18)'
            disabledTextColor='black'
            textFontWeight='bold'
            {...buttonStyles}
          />
        )}
      </div>
    </CollapsableTitledCard>
  )
}

const buttonStyles = { containerStyle: { minHeight: '50' } }
