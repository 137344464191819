import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 50,
  height: 45,
}

const TrackerStarIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 410.4 390.3'>
      <path
        fill={fill}
        d={`M307.8,249.1l102.6-100l-141.8-20.6L205.2,0l-63.4,128.5L0,149.1l102.6,100L78.4,390.3l126.8-66.7L332,390.3
	L307.8,249.1z M205.2,283l-79.1,41.6l15.1-88l-64-62.3l88.4-12.8l39.5-80.1l39.5,80.1l88.4,12.8l-63.9,62.3l15.1,88L205.2,283z`}
      />
    </svg>
  )
}

export { TrackerStarIcon }
