import gql from 'graphql-tag'

export interface EmailLoginResponse {
  emailLogin: {
    id: string
    name: string
    idToken?: string
    shouldDisplayRecaptchaV2?: boolean
    recaptchaSuccess?: boolean
  }
}

export const emailLoginMutation = gql`
  mutation emailLogin(
    $email: String!
    $password: String!
    $enableLongLived: Boolean
    $recaptchaToken: String
    $useRecaptchaV3: Boolean
    $orderId: String
    $recaptchaAction: RecaptchaAction
  ) {
    emailLogin(
      email: $email
      password: $password
      enableLongLived: $enableLongLived
      recaptchaToken: $recaptchaToken
      useRecaptchaV3: $useRecaptchaV3
      orderId: $orderId
      recaptchaAction: $recaptchaAction
    ) {
      id
      name
      idToken
      shouldDisplayRecaptchaV2
      recaptchaSuccess
    }
  }
`
