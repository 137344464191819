import React, { useMemo } from 'react'
import { DominosTheme, useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import { ActionButton, ClearButton } from '@dominos/components/buttons'
import { Spinner } from '@dominos/components/spinners/spinner/spinner'

export interface AddressSearchErrorComponentProps {
  title: string
  message: string
  icon: svgIconsName
  iconStyle: React.CSSProperties
  adjustPinMessage?: string
  isLoading?: boolean
  closeButton?: CloseButtonProps
  leftButton?: AddressSearchButtonProps
  rightButton?: AddressSearchButtonProps
  errorContainerStyle?: React.CSSProperties
}

export interface AddressSearchButtonProps {
  buttonStyle: 'AlternativeAction' | 'MainAction'
  text: string
  onPress?: () => void
}

export interface CloseButtonProps {
  onPress: () => void
}

interface Style {
  componentWrapper: React.CSSProperties
  columnsContainer: React.CSSProperties
  columnOne: React.CSSProperties
  columnTwo: React.CSSProperties
  adjustPinMessageColumnsContainer: React.CSSProperties
  adjustPinMessageColumnOne: React.CSSProperties
  adjustPinMessageColumnTwo: React.CSSProperties
  lineSeparator: React.CSSProperties
  contentWrapper: React.CSSProperties
  iconWrapper: React.CSSProperties
  titleCloseContainer: React.CSSProperties
  titleContainer: React.CSSProperties
  titleText: React.CSSProperties
  closeContainer: React.CSSProperties
  messageText: React.CSSProperties
  buttonsContainer: React.CSSProperties
  leftButton: React.CSSProperties
  leftButtonContainer: React.CSSProperties
  rightButton: React.CSSProperties
  rightButtonContainer: React.CSSProperties
}

export const AddressSearchErrorComponent = (props: AddressSearchErrorComponentProps) => {
  const {
    title,
    message,
    icon,
    iconStyle,
    adjustPinMessage,
    isLoading,
    leftButton,
    rightButton,
    closeButton,
    errorContainerStyle,
  } = props

  const theme = useDominosTheme()
  const style = useMemo(
    () => styleHelper(theme, leftButton, rightButton, errorContainerStyle),
    [theme, leftButton, rightButton],
  )

  if (isLoading) {
    return (
      <div className='wrapper'>
        <Spinner testID={getElementId('loading')} />
      </div>
    )
  }

  return (
    <div style={style.componentWrapper}>
      {adjustPinMessage && (
        <>
          <div style={style.adjustPinMessageColumnsContainer}>
            <div style={style.adjustPinMessageColumnOne}> {adjustPinMessage}</div>
            <div style={style.adjustPinMessageColumnTwo}>
              {closeButton && (
                <div style={{ marginLeft: 'auto' }}>
                  <ClearButton
                    testID='clearInputButton'
                    onClick={closeButton.onPress}
                    tabIndex={0}
                    includePadding={false}
                  />
                </div>
              )}
            </div>
          </div>
          <div style={style.lineSeparator} />
        </>
      )}
      <div style={style.columnsContainer}>
        <div style={style.columnOne}>
          <div style={style.iconWrapper}>
            <StaticSvgIcon name={icon} width={iconStyle.width} height={iconStyle.height} isUnstyled />
          </div>
        </div>
        <div style={style.columnTwo}>
          <div style={style.contentWrapper}>
            <div style={style.titleCloseContainer}>
              <div style={style.titleContainer}>
                <div style={style.titleText}>{title}</div>
              </div>
              {!adjustPinMessage && closeButton && (
                <ClearButton
                  testID='clearInputButton'
                  onClick={closeButton.onPress}
                  tabIndex={0}
                  includePadding={false}
                />
              )}
            </div>
            <p style={style.messageText}>{message}</p>
          </div>
        </div>
      </div>
      <div style={style.buttonsContainer}>
        {leftButton && (
          <ActionButton
            text={leftButton.text}
            onPress={leftButton.onPress}
            testID='left-button'
            containerStyle={style.leftButtonContainer}
            textStyle={style.leftButton}
          />
        )}
        {rightButton && (
          <ActionButton
            text={rightButton.text}
            onPress={rightButton.onPress}
            testID='right-button'
            containerStyle={style.rightButtonContainer}
            textStyle={style.rightButton}
          />
        )}
      </div>
    </div>
  )
}

const styleHelper = (
  theme: DominosTheme,
  leftButton?: AddressSearchButtonProps,
  rightButton?: AddressSearchButtonProps,
  errorContainerStyle?: React.CSSProperties,
): Style => ({
  componentWrapper: {
    width: '100%',
    backgroundColor: 'white',
    color: theme.colours.black,
  },
  columnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    ...errorContainerStyle,
  },
  adjustPinMessageColumnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '15px',
    padding: '10px 10px 0 10px',
  },
  adjustPinMessageColumnOne: {
    width: '95%',
  },
  adjustPinMessageColumnTwo: {
    width: '5%',
    marginTop: '-15px',
  },
  lineSeparator: {
    borderBottom: '1px solid lightgrey',
    margin: '0 -10px 10px -10px',
  },
  columnOne: {
    display: 'flex',
    padding: '0 20px 0 20px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  columnTwo: {
    width: '100%',
    display: 'flex',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  titleCloseContainer: {
    display: 'flex',
    width: '100%',
  },
  titleContainer: {
    whiteSpace: 'normal',
    flexGrow: 1,
    alignItems: 'center',
  },
  titleText: {
    fontWeight: 'bold',
    textAlign: 'left',
    margin: '7px 0 0 0',
  },
  closeContainer: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'flex-end',
    textAlign: 'right',
    padding: '0',
  },
  messageText: {
    fontWeight: 'normal',
    textAlign: 'left',
    margin: '0',
    paddingRight: '20px',
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: '10px',
    gap: '10px',
  },
  rightButtonContainer: {
    ...getButtonContainerStyle(theme, rightButton),
  },
  rightButton: {
    ...getButtonStyle(theme, rightButton),
  },
  leftButtonContainer: {
    ...getButtonContainerStyle(theme, leftButton),
  },
  leftButton: {
    ...getButtonStyle(theme, leftButton),
  },
})

const getButtonStyle = (theme: DominosTheme, button?: AddressSearchButtonProps): React.CSSProperties => {
  if (!button) return {}
  const textColour =
    button.buttonStyle === 'MainAction' ? theme.colours.defaultButtonText : theme.colours.alternativeButtonText

  return {
    color: textColour,
    fontWeight: 'bold',
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
  }
}

const getButtonContainerStyle = (theme: DominosTheme, button?: AddressSearchButtonProps): React.CSSProperties => {
  if (!button) return {}
  const buttonColour =
    button.buttonStyle === 'MainAction' ? theme.colours.selectButtonColor : theme.colours.alternativeButtonBackground

  return {
    backgroundColor: buttonColour,
    flexShrink: 1,
    flexGrow: 1,
    padding: '10px',
    textAlign: 'center',
    boxSizing: 'border-box',
    minWidth: 'calc(50% - 10px)',
  }
}

const addressSearchComponentId = 'address-search-error-component'
const getElementId = (elementId: string) => `${addressSearchComponentId}.${elementId}`
