import gql from 'graphql-tag'

export const deliveryStreetsQuery = gql`
  query deliveryStreetsQuery($suburb: String, $postCode: String, $street: String!) {
    deliveryStreets(suburb: $suburb, postCode: $postCode, street: $street) {
      name
      postCode
      region
      suburb
    }
  }
`
