import { BREAKPOINTS } from '@dominos/hooks-and-hocs/utils/breakpoints'
import { isB2BOrderType, isKioskOrderType } from '@dominos/business/functions/common'

export const applicationName: () => BffContext.ApplicationTypes = () => {
  const { nativeAppPlatform } = window

  if (nativeAppPlatform) {
    return nativeAppPlatform as BffContext.ApplicationTypes
  }

  if (isKioskOrderType()) {
    return 'KioskWeb'
  }

  const isMobile = window.innerWidth <= (BREAKPOINTS.MOBILE.MAX ?? 772)
  if (isB2BOrderType()) {
    return isMobile ? 'B2BMobileWeb' : 'B2BDesktopWeb'
  }

  return isMobile ? 'MobileWeb' : 'DesktopWeb'
}
