import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 30,
  height: 30,
}

const UpgradeIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='24.692 24.543 20.731 20.96'>
      <path
        fill={fill}
        d='m 35.058 24.543 c 5.724 0 10.365 4.692 10.365 10.48 s -4.64 10.48 -10.365 10.48 c -5.725 0 -10.366 -4.692 -10.366 -10.48 s 4.641 -10.48 10.366 -10.48 z m 0.435 5.522 h -0.797 v 0.783 c -1.696 0.138 -2.698 1.047 -2.698 2.463 c 0 1.203 0.734 1.997 2.1 2.32 l 0.598 0.143 v 2.14 c -0.711 -0.074 -1.195 -0.5 -1.235 -1.07 h -1.628 c 0.006 1.405 1.082 2.314 2.863 2.417 v 0.72 h 0.797 v -0.731 c 1.782 -0.15 2.79 -1.059 2.79 -2.526 c 0 -1.244 -0.718 -2.009 -2.18 -2.325 l -0.61 -0.127 v -2.049 c 0.598 0.087 1.025 0.518 1.042 1.042 h 1.588 c -0.023 -1.33 -1.048 -2.267 -2.63 -2.417 v -0.783 z m 0 5.853 c 0.797 0.15 1.173 0.472 1.173 1.001 c 0 0.581 -0.433 0.955 -1.173 1.001 z m -0.797 -3.7 v 1.887 c -0.689 -0.138 -1.047 -0.472 -1.047 -0.955 c 0 -0.512 0.432 -0.898 1.047 -0.932 z'
      />
    </svg>
  )
}

export { UpgradeIcon }
