import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useCountryCode, useLanguages } from '@dominos/hooks-and-hocs'

export interface LDClientContext {
  kind: 'user'
  key: string
  oloApplication: Lowercase<BffContext.ApplicationTypes>
  oloMarket: Lowercase<BffContext.Countries>
  oloVersion: string
  oloLanguage: `${BffContext.Languages}-${BffContext.Countries}`
}

export const useIdentifyContext = () => {
  const ldClient = useLDClient()
  const countryCode = useCountryCode()
  const { language } = useLanguages()

  const oloLanguage = `${language}-${countryCode}`

  useEffect(() => {
    if (!ldClient || (ldClient.getContext() as LDClientContext).oloLanguage === oloLanguage) {
      return
    }

    ldClient.identify({
      ...ldClient.getContext(),
      oloLanguage,
    })
  }, [oloLanguage])
}
