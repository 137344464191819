import { Modal } from '@dominos/components'
import { PaymentOverlayAnimation } from '@dominos/res/images/icons/components/payment/overlay'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { styles } from './styles'

export const PaymentOverlay = ({ showProcessing = false }: { showProcessing?: boolean }) => {
  const { t } = useTranslation('checkout')

  const ProcessingContents = () => (
    <div style={styles.modalContent} data-testid='payment-overlay-container'>
      <h2 style={styles.processingTitle}>{t('PaymentProcessingTitle', { defaultValue: 'Processing...' })}</h2>
      <div style={styles.loadingContainer}>
        <PaymentOverlayAnimation />
      </div>
      <h3 style={styles.processingSubTitle}>
        {t('PaymentProcessingSubTitle', {
          defaultValue: 'Thank you for placing your order',
        })}
      </h3>
      <p style={styles.messageContainer}>
        {t('PaymentProcessingContent', {
          defaultValue:
            "Your order is being sent to the store. Please don't close this window until we've confirmed they have received it",
        })}
      </p>
    </div>
  )

  return <Modal testID='payment-overlay'>{showProcessing ? <ProcessingContents /> : null}</Modal>
}
