import { RouteComponentProps } from '@reach/router'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business'
import { isKioskSingleURL } from '@dominos/business/functions'
import { useFeatures, useKiosk, useKioskConfigQuery, useLocationExtended } from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'

const KIOSK_HOME_PATH_NAMES = [
  '/index.html', // to support PR branch home route
  NavigationConstants.home,
]

const KIOSK_COUNTRY_KEY = '__DPE_KIOSK_COUNTRY__'

const setCountryForSingleURL = (kioskConfig: Partial<Bff.Kiosk.Configuration>) => {
  if (
    isKioskSingleURL() &&
    kioskConfig?.countryCode &&
    localStorage.getItem(KIOSK_COUNTRY_KEY)?.toLocaleLowerCase() !== kioskConfig.countryCode.toLocaleLowerCase()
  ) {
    localStorage.setItem(KIOSK_COUNTRY_KEY, kioskConfig.countryCode)
    window.location.reload()
  }
}

const setNewRelicCustomAttributes = (kioskConfig: Partial<Bff.Kiosk.Configuration>) => {
  if (window.newrelic?.setCustomAttribute && kioskConfig?.kioskId) {
    window.newrelic.setCustomAttribute('country', kioskConfig.countryCode)
    window.newrelic.setCustomAttribute('storeno', kioskConfig.storeNo)
    window.newrelic.setCustomAttribute('kioskId', kioskConfig.kioskId)
  }
}

export const KioskOrderHandler: React.FC<RouteComponentProps> = ({ children }) => {
  const dispatch = useDispatch()
  const [isKioskEnabled, injectKioskHeaderEnabled] = useFeatures('KioskWebOrdering', 'InjectKioskHeader')
  const { isKioskOrder } = useKiosk()
  const { pathname } = useLocationExtended()
  const isHomePath = KIOSK_HOME_PATH_NAMES.includes(pathname)

  const { fetchKioskConfig, called, kioskConfig, error } = useKioskConfigQuery(injectKioskHeaderEnabled)

  useEffect(() => {
    document.body.parentElement?.classList.toggle('kiosk-web', isKioskOrder)
  }, [])

  useEffect(() => {
    if (isKioskOrder && isKioskEnabled) {
      fetchKioskConfig()
    }
  }, [isKioskEnabled])

  useEffect(() => {
    if (!isKioskOrder || !isHomePath || !called || (!kioskConfig.kioskId && !error)) {
      return
    }

    dispatch(rootActions.appStartedForKioskWeb(kioskConfig))
    setCountryForSingleURL(kioskConfig)
    setNewRelicCustomAttributes(kioskConfig)
  }, [called, kioskConfig])

  return <>{children}</>
}
