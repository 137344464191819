import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions'
import { GenericSelect } from '@dominos/components'
import { AdditionalField } from '../additional-field'

const SELECT_HEIGHT = 44

export const AdditionalFieldsLayoutSg = ({ testID, address, onInit, onChange }: AdditionalFieldsLayoutProps) => {
  const { t } = useTranslation('delivery-address')
  const { isNumeric, isRequired, isAlphaNumeric } = useValidation()

  const [addressType, setAddressType] = useState<'landed' | 'nonLanded'>()

  useEffect(() => {
    if (addressType && onInit) {
      onInit({
        floorNo: {
          isValid: addressType === 'landed',
        },
        unitNo: {
          isValid: addressType === 'landed',
        },
        buildingName: {
          isValid: true,
        },
      })
    }
  }, [addressType])

  const options: GenericSelectItem[] = [
    {
      label: t('landed'),
      value: 'landed',
    },
    {
      label: t('nonLanded'),
      value: 'nonLanded',
    },
  ]

  const onSelectChange = (value: string) => {
    // workaround for `GenericSelect` not providing option to type `options`
    const hasAddressType = value === 'landed' || value === 'nonLanded'
    hasAddressType && setAddressType(value)
  }

  return (
    <>
      <GenericSelect
        testID={`${testID}.addressType`}
        options={options}
        placeholder={t('addressType')}
        selectedValue={addressType}
        height={SELECT_HEIGHT}
        onChange={onSelectChange}
      />

      {addressType === 'nonLanded' && (
        <>
          <AdditionalField
            testID={testID}
            fieldName={'floorNo'}
            placeholder={t('Floor Number')}
            onChange={onChange}
            validationRules={[isNumeric, isRequired]}
            address={address}
          />
          <AdditionalField
            testID={testID}
            fieldName={'unitNo'}
            placeholder={t('Unit Number')}
            onChange={onChange}
            validationRules={[isAlphaNumeric, isRequired]}
            address={address}
          />
        </>
      )}

      {/* Non-Landed & Landed */}
      {addressType && (
        <AdditionalField
          testID={testID}
          fieldName={'buildingName'}
          placeholder={t('BuildingNameFieldPlaceholder')}
          onChange={onChange}
          validationRules={undefined}
          address={address}
        />
      )}
    </>
  )
}
