import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 235,
  height: 236,
}

export const RewardsEnrollFR = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg
      data-testid='MyRewards.Icon.FR'
      version='1.1'
      id='Calque_1'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 736.95 248.23'
      xmlSpace='preserve'
    >
      <g>
        <path
          style={{ fill: '#DD052B' }}
          d='M103.42,102.37H27.29c-3.36,0-5.31,1.77-5.31,5.31v113.31c0,3.36,1.95,5.31,5.31,5.31h20.89
		c3.54,0,5.31-1.95,5.31-5.31v-36.12c0-3.54,1.95-5.31,5.31-5.31h29.21c3.54,0,5.31-1.95,5.31-5.31v-19.83
		c0-3.54-1.77-5.31-5.31-5.31H58.8c-3.36,0-5.31-1.95-5.31-5.31v-5.67c0-3.54,1.95-5.31,5.31-5.31h44.62c3.54,0,5.31-1.95,5.31-5.31
		v-19.83C108.73,104.14,106.96,102.37,103.42,102.37z'
        />
        <path
          style={{ fill: '#DD052B' }}
          d='M144.14,102.37h-19.48c-3.36,0-5.31,1.77-5.31,5.31v113.31c0,3.36,1.95,5.31,5.31,5.31h19.48
		c3.54,0,5.31-1.95,5.31-5.31V107.68C149.45,104.14,147.68,102.37,144.14,102.37z'
        />
        <path
          style={{ fill: '#DD052B' }}
          d='M226.95,102.37h-39.11h-15.37c-3.54,0.18-5.31,1.95-5.31,5.49v113.13c0,3.36,1.95,5.31,5.31,5.31h15.37h39.11
		c33.29,0,44.26-13.63,44.26-42.67v-38.77C271.21,115.83,260.24,102.37,226.95,102.37z M258.17,158.12l-14.79,14.42
		c-1.04,1.01-1.51,2.47-1.26,3.89l3.49,20.36c0.62,3.59-3.15,6.33-6.38,4.64l-18.28-9.61c-1.28-0.67-2.81-0.67-4.09,0l-18.28,9.61
		c-3.23,1.7-7-1.04-6.38-4.64l3.49-20.36c0.24-1.43-0.23-2.88-1.26-3.89l-14.79-14.42c-2.61-2.54-1.17-6.98,2.44-7.5l20.44-2.97
		c1.43-0.21,2.67-1.11,3.31-2.41l9.14-18.52c1.61-3.27,6.27-3.27,7.89,0l9.14,18.52c0.64,1.3,1.88,2.2,3.31,2.41l20.44,2.97
		C259.34,151.14,260.78,155.58,258.17,158.12z'
        />
        <path
          style={{ fill: '#DD052B' }}
          d='M366.82,102.37h-74.36c-3.36,0-5.31,1.77-5.31,5.31v113.31c0,3.36,1.95,5.31,5.31,5.31h74.36
		c3.54,0,5.31-1.77,5.31-5.31v-19.83c0-3.54-1.77-5.31-5.31-5.31h-42.85c-3.36,0-5.31-1.95-5.31-5.31v-5.67
		c0-3.54,1.95-5.31,5.31-5.31h27.44c3.54,0,5.31-1.95,5.31-5.31v-19.83c0-3.54-1.77-5.31-5.31-5.31h-27.44
		c-3.36,0-5.31-1.95-5.31-5.31v-5.67c0-3.54,1.95-5.31,5.31-5.31h42.85c3.54,0,5.31-1.95,5.31-5.31v-19.83
		C372.13,104.14,370.36,102.37,366.82,102.37z'
        />
        <path
          style={{ fill: '#DD052B' }}
          d='M461.01,195.85h-39.48c-3.36,0-5.31-1.95-5.31-5.31v-82.86c0-3.54-1.77-5.31-5.31-5.31h-19.83
		c-3.36,0-5.31,1.77-5.31,5.31v113.31c0,3.36,1.95,5.31,5.31,5.31h69.93c3.54,0,5.31-1.77,5.31-5.31v-19.83
		C466.32,197.62,464.55,195.85,461.01,195.85z'
        />
        <path
          style={{ fill: '#DD052B' }}
          d='M502.09,102.37h-19.47c-3.36,0-5.31,1.77-5.31,5.31v113.31c0,3.36,1.95,5.31,5.31,5.31h19.47
		c3.54,0,5.31-1.95,5.31-5.31V107.68C507.4,104.14,505.63,102.37,502.09,102.37z'
        />
        <path
          style={{ fill: '#DD052B' }}
          d='M613.99,102.37h-90.65c-3.36,0-5.31,1.77-5.31,5.31v19.83c0,3.36,1.95,5.31,5.31,5.31h24.79
		c3.54,0,5.31,1.77,5.31,5.31v82.86c0,3.36,1.95,5.31,5.31,5.31h19.83c3.54,0,5.31-1.95,5.31-5.31v-82.86
		c0-3.54,1.95-5.31,5.31-5.31h24.79c3.54,0,5.31-1.95,5.31-5.31v-19.83C619.3,104.14,617.53,102.37,613.99,102.37z'
        />
        <path
          style={{ fill: '#DD052B' }}
          d='M666.75,132.82h42.84c3.54,0,5.31-1.95,5.31-5.31v-19.83c0-3.54-1.77-5.31-5.31-5.31h-27.12l12.07-9.66
		c3.9-3.19,2.83-6.55-2.3-6.55h-18.59c-2.83,0-4.6,0.88-6.55,2.83l-12.02,13.38h-19.84c-3.36,0-5.31,1.77-5.31,5.31v113.31
		c0,3.36,1.95,5.31,5.31,5.31h74.36c3.54,0,5.31-1.77,5.31-5.31v-19.83c0-3.54-1.77-5.31-5.31-5.31h-42.84
		c-3.36,0-5.31-1.95-5.31-5.31v-5.67c0-3.54,1.95-5.31,5.31-5.31h27.44c3.54,0,5.31-1.95,5.31-5.31v-19.83
		c0-3.54-1.77-5.31-5.31-5.31h-27.44c-3.36,0-5.31-1.95-5.31-5.31v-5.67C661.44,134.6,663.38,132.82,666.75,132.82z'
        />
      </g>
      <g>
        <path
          style={{ fill: '0073AB' }}
          d='M48.01,22.05H23.45c-0.82,0-1.47,0.65-1.47,1.47v55.3c0,0.8,0.66,1.46,1.47,1.46h24.64
		c19.36,0,31.88-11.44,31.88-29.16C79.97,33.46,67.42,22.05,48.01,22.05z M48.01,64.93h-8.39V37.42h8.47
		c8.75,0,13.97,5.12,13.97,13.71C62.06,57.98,57.24,64.93,48.01,64.93z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M188.86,37.07c-6.78,0-12.29,3.73-14.75,7.42c-1.8-4.92-5.89-7.42-12.14-7.42c-6.91,0-12.15,3.5-13.97,5.85
		v-3.33c0-0.82-0.66-1.47-1.47-1.47h-12.78c-0.81,0-1.46,0.65-1.46,1.47v39.24c0,0.8,0.66,1.46,1.46,1.46h12.78
		c0.81,0,1.47-0.66,1.47-1.46V54.27l0-0.01c0.93-1.07,2.85-3.31,6.37-3.31c3.1,0,4.8,1.68,4.8,4.72v23.16c0,0.8,0.66,1.46,1.47,1.46
		h12.78c0.81,0,1.47-0.66,1.47-1.46V54.27c0.92-1.08,2.84-3.32,6.45-3.32c3.06,0,4.81,1.72,4.81,4.72v23.16
		c0,0.8,0.65,1.46,1.47,1.46h12.78c0.81,0,1.47-0.66,1.47-1.46V49.74C201.88,41.57,197.26,37.07,188.86,37.07z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M222.53,38.12h-12.78c-0.81,0-1.47,0.66-1.47,1.47v39.24c0,0.8,0.66,1.46,1.47,1.46h12.78
		c0.81,0,1.47-0.66,1.47-1.46V39.59C224,38.78,223.34,38.12,222.53,38.12z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M216.52,17.43c-4.86,0-8.83,3.96-8.83,8.82c0,4.87,3.96,8.82,8.83,8.82c4.86,0,8.82-3.95,8.82-8.82
		C225.34,21.38,221.38,17.43,216.52,17.43z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M260.86,37.07c-8.15,0-12.58,3.66-14.49,5.85v-3.33c0-0.82-0.66-1.47-1.47-1.47h-12.78
		c-0.81,0-1.47,0.65-1.47,1.47v39.24c0,0.8,0.66,1.46,1.47,1.46h12.78c0.81,0,1.47-0.66,1.47-1.46V54.27
		c1.02-1.16,2.92-3.32,6.72-3.32c4.06,0,6.12,2.03,6.12,6.02v21.86c0,0.8,0.66,1.46,1.47,1.46h12.78c0.81,0,1.47-0.66,1.47-1.46
		V50.35C274.93,42.03,269.67,37.07,260.86,37.07z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M105.31,36.88c-12.3,0-22.26,9.97-22.26,22.25c0,12.31,9.97,22.27,22.26,22.27c12.3,0,22.26-9.96,22.26-22.27
		C127.57,46.85,117.6,36.88,105.31,36.88z M105.31,66.42c-4.2,0-7.59-3.4-7.59-7.61c0-4.19,3.39-7.59,7.59-7.59
		c4.2,0,7.6,3.39,7.6,7.59C112.9,63.02,109.5,66.42,105.31,66.42z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M301.85,36.95c-12.29,0-22.26,9.97-22.26,22.26c0,12.3,9.97,22.27,22.26,22.27c12.3,0,22.27-9.97,22.27-22.27
		C324.12,46.91,314.15,36.95,301.85,36.95z M301.85,66.49c-4.19,0-7.59-3.41-7.59-7.61c0-4.19,3.4-7.58,7.59-7.58
		c4.2,0,7.6,3.4,7.6,7.58C309.45,63.08,306.05,66.49,301.85,66.49z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M336.39,24.49C336.39,24.49,336.39,24.48,336.39,24.49c0-0.01,0-0.01,0-0.02c-0.01-0.04-0.01-0.08-0.02-0.11
		l0,0c-0.98-4.39-5.76-7.56-10.46-6.59c-4.75,0.98-7.87,5.41-6.95,9.86c0.76,3.67,4.03,6.23,7.83,6.46
		c-0.24,1.5-1.57,2.85-2.68,3.47c-0.74,0.42-0.3,1.21-0.3,1.21l0.98,1.45c0.28,0.49,0.71,0.74,1.22,0.53
		C335.74,36.77,337.41,28.45,336.39,24.49z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M347.73,50.67c-0.04-1.57,2-2.27,4.56-2.27c5.09,0,7.98,1.45,11.23,3.59c0.34,0.23,0.75,0.3,1.15,0.21
		c0.39-0.09,0.73-0.35,0.93-0.71l4.32-7.5c0.39-0.69,0.16-1.57-0.52-1.98c-3.55-2.12-8.74-4.85-17.76-4.85
		c-13.01,0-19.97,6.12-18.8,14.92c2.05,15.47,26.1,10.17,25.72,15.45c-0.09,1.22-2.21,2.13-5.9,2.13c-4.76,0-10.17-2.38-13.58-4.5
		c-0.34-0.21-0.75-0.27-1.13-0.17c-0.39,0.1-0.72,0.35-0.91,0.7l-4.99,8.31c-0.38,0.67-0.18,1.52,0.45,1.95
		c4.79,3.23,12.51,5.4,19.22,5.4c13.02,0,20.53-5.59,20.53-14.78C372.25,49.6,347.83,54.86,347.73,50.67z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M378.45,40.19c-0.09-0.08-0.22-0.15-0.41-0.23c0.45-0.11,0.78-0.28,0.99-0.52c0.21-0.24,0.31-0.52,0.31-0.84
		c0-0.42-0.14-0.75-0.41-0.99c-0.27-0.25-0.72-0.37-1.33-0.37h-2.16v4.84h1.2v-1.9c0.06-0.01,0.11-0.02,0.15-0.02
		c0.16,0,0.32,0.09,0.47,0.27c0.16,0.18,0.47,0.73,0.93,1.65h1.42c-0.51-0.92-0.77-1.38-0.78-1.4
		C378.7,40.47,378.57,40.3,378.45,40.19z M377.17,39.3h-0.52v-1.21h0.57c0.33,0,0.56,0.06,0.7,0.17c0.14,0.11,0.2,0.26,0.2,0.44
		c0,0.18-0.07,0.32-0.21,0.44C377.76,39.24,377.51,39.3,377.17,39.3z'
        />
        <path
          style={{ fill: '0073AB' }}
          d='M381.41,37.4c-0.41-0.7-0.96-1.25-1.66-1.66c-0.7-0.4-1.45-0.6-2.26-0.6c-0.81,0-1.56,0.2-2.26,0.6
		c-0.7,0.4-1.25,0.96-1.66,1.66c-0.41,0.7-0.61,1.46-0.61,2.26c0,0.81,0.2,1.57,0.61,2.26c0.41,0.7,0.96,1.25,1.66,1.65
		c0.7,0.4,1.45,0.6,2.26,0.6c0.81,0,1.57-0.2,2.26-0.6c0.7-0.4,1.25-0.95,1.66-1.65c0.41-0.7,0.61-1.45,0.61-2.26
		C382.02,38.86,381.82,38.11,381.41,37.4z M380.07,42.25c-0.71,0.71-1.57,1.07-2.58,1.07s-1.87-0.36-2.58-1.07
		c-0.71-0.71-1.07-1.57-1.07-2.58s0.36-1.87,1.07-2.58c0.71-0.71,1.57-1.07,2.58-1.07s1.87,0.36,2.58,1.07
		c0.71,0.71,1.07,1.57,1.07,2.58S380.79,41.53,380.07,42.25z'
        />
      </g>
    </svg>
  )
}
