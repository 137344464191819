import React from 'react'
import { ImportDynamicComponent } from '@dominos/components'
import loadable from '@loadable/component'

export const TermsAndConditions = React.memo((props: TermsAndConditionsProps) => (
  <ImportDynamicComponent
    kiosk={loadable(() => import('./kiosk/terms-and-conditions'))}
    desktop={loadable(() => import('./desktop/terms-and-conditions'))}
  >
    {(TermsAndConditions) => <TermsAndConditions {...props} />}
  </ImportDynamicComponent>
))
