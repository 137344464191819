import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 70,
  height: 35,
}

export const MessageIcon = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 35 35'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#4A4A4A' fillRule='nonzero'>
          <g>
            <path
              d='M17.5 35C27.079 35 35 27.079 35 17.5S27.079 0 17.483 0C7.903 0 0 7.921 0 17.5S7.921 35 17.5 35zm0-2.25c-8.445 0-15.232-6.805-15.232-15.25S9.055 2.268 17.483 2.268c8.444 0 15.249 6.787 15.266 15.232 0 8.445-6.804 15.25-15.249 15.25zm0-14.15c.314 0 .68-.14 1.012-.454l7.834-7.852c-.21-.21-.75-.384-1.535-.384H10.172c-.785 0-1.309.175-1.535.384l7.834 7.852c.349.331.715.453 1.029.453zm-9.561 5.164l6.211-6.212-6.264-6.246c-.104.175-.174.61-.174 1.221v9.928c0 .646.07 1.082.227 1.309zm19.035 0c.157-.227.227-.663.227-1.309v-9.928c0-.61-.07-1.046-.175-1.22l-6.263 6.245 6.211 6.212zm-2.163 1.308c.75 0 1.238-.104 1.448-.331l-6.386-6.386-.646.61c-.61.576-1.116.803-1.744.803-.611 0-1.117-.227-1.728-.802l-.663-.611-6.386 6.386c.227.227.716.331 1.466.331h14.639z'
              transform='translate(-1165.000000, -316.000000) translate(1157.000000, 161.000000) translate(8.000000, 155.000000)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
