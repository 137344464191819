import { OrderStatus } from '@dominos/hooks-and-hocs/order'
import { ErrorDefinition, getDefaultValue } from '@dominos/components/error'
import { TranslationType } from '@dominos/components/error'

export const orderAlreadyPlacedTranslation: TranslationType = {
  title: {
    key: 'OrderAlreadyPlacedTitle',
    getOptions: () => getDefaultValue('Order already placed'),
  },
  message: {
    key: 'OrderAlreadyPlacedMessage',
    getOptions: () =>
      getDefaultValue('Sorry, your order is already placed. We will take you to the tracker page to track your order.'),
  },
}

export const unrecoverableOrderTranslation: TranslationType = {
  title: {
    key: 'PlaceOrderFatalTitle',
    getOptions: () => getDefaultValue('Unable to place order'),
  },
  message: {
    key: 'PlaceOrderFatalMessage',
    getOptions: () =>
      getDefaultValue(
        'Sorry, there was a problem placing your order and we are unable to proceed. Please contact the store to place your order.',
      ),
  },
}

export const recoverableOrderTranslation: TranslationType = {
  title: {
    key: 'PlaceOrderFailedTitle',
    getOptions: () => getDefaultValue('Payment failed'),
  },
  message: {
    key: 'PlaceOrderFailedMessage',
    getOptions: () =>
      getDefaultValue(
        'Sorry, there was a problem placing your order. Try again. If the problem persists, please call us.',
      ),
  },
}

export const unknownReasonOrderTranslation: TranslationType = {
  title: {
    key: 'UnknownReasonOrderFailedTitle',
    getOptions: () => getDefaultValue('Failed to place order'),
  },
  message: {
    key: 'UnknownReasonOrderFailedMessage',
    getOptions: () =>
      getDefaultValue(
        'Sorry, there was a problem placing your order and we are unable to proceed. Please contact the store to place your order.',
      ),
  },
}

export const orderAlreadyPlacedStatusError: ErrorDefinition = {
  displayType: 'modal',
  translation: orderAlreadyPlacedTranslation,
  icon: 'paymentError',
  testID: 'order-already-placed-error',
}

export const unrecoverableOrderStatusError: ErrorDefinition = {
  displayType: 'modal',
  translation: unrecoverableOrderTranslation,
  icon: 'orderFailed',
  testID: 'unrecoverable-error',
}

export const recoverableOrderStatusError: ErrorDefinition = {
  displayType: 'modal',
  translation: recoverableOrderTranslation,
  icon: 'paymentError',
  testID: 'recoverable-error',
}

export const unknownOrderStatusError: ErrorDefinition = {
  displayType: 'modal',
  translation: unknownReasonOrderTranslation,
  icon: 'orderFailed',
  testID: 'unknown-unrecoverable-error',
}

export const statusErrors = (orderStatus: keyof typeof OrderStatus): ErrorDefinition => {
  switch (orderStatus) {
    case OrderStatus.Timed:
    case OrderStatus.Making:
    case OrderStatus.Remaking:
    case OrderStatus.Cooking:
    case OrderStatus.Ready:
    case OrderStatus.Leaving:
    case OrderStatus.Complete:
    case OrderStatus.SentToStore:
      return { ...orderAlreadyPlacedStatusError }
    case OrderStatus.Failed:
    case OrderStatus.Cancelled:
      return { ...unrecoverableOrderStatusError }
    default:
      return { ...recoverableOrderStatusError }
  }
}

export const processingStatusErrors = (orderStatus: keyof typeof OrderStatus): ErrorDefinition => {
  switch (orderStatus) {
    case OrderStatus.Failed:
    case OrderStatus.Cancelled:
      return { ...unrecoverableOrderStatusError }
    case OrderStatus.Unknown:
      return { ...unknownOrderStatusError }
    case OrderStatus.PaymentFailed:
    default:
      return { ...recoverableOrderStatusError }
  }
}
