import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 30,
  height: 30,
}

const PinIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#007AFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 30 30'>
      <g id='Mobile' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Store-Search-B' transform='translate(-23, -357)'>
          <g id='Location' transform='translate(23, 357)'>
            <ellipse id='Background' fill={fill} cx='15' cy='14.6341463' rx='15' ry='14.6341463'></ellipse>
            <g id='Group' transform='translate(9, 6)' fill='#FFFFFF' fillRule='nonzero'>
              <path
                d='M6,0 C2.68286335,0 0,2.66050019 0,5.94999222 C0,7.42899406 0.428577434,8.81449036 1.20857043,10.0639584 C2.02286755,11.3729916 3.09428872,12.4950359 3.91715066,13.8039579 C4.32001121,14.441521 4.61143938,15.0365058 4.92000841,15.724985 C5.14286195,16.1924571 5.32286895,17 6,17 C6.67715347,17 6.85716047,16.1924571 7.07141556,15.724985 C7.38856062,15.0365058 7.67140155,14.441521 8.07430693,13.8039579 C8.89715767,12.5034849 9.9685452,11.3814405 10.782876,10.0639584 C11.5714226,8.81449036 12,7.42899406 12,5.94999222 C12,2.66050019 9.31710302,0 6,0 Z M6.24997057,8.25714286 C5.00798851,8.25714286 4,7.27794377 4,6.07142285 C4,4.86491337 5.00798851,3.88571429 6.24997057,3.88571429 C7.49194086,3.88571429 8.5,4.86491337 8.5,6.07142285 C8.5,7.27794377 7.49194086,8.25714286 6.24997057,8.25714286 Z'
                id='Shape'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { PinIcon }
