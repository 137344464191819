import { LayoutItem } from 'simple-keyboard-layouts/build/interfaces'

/**
 * Layout: Japanese
 */
export default <LayoutItem>{
  layout: {
    default: [
      '1 2 3 4 5 6 7 8 9 0 - ^ \u00a5 \u309A {bksp}',
      '\u306C \u3075 \u3042 \u3046 \u3048 \u304A \u3084 \u3086 \u3088 \u308F \u307B \u3078',
      '{tab} \u305f \u3066 \u3044 \u3059 \u304b \u3093 \u306a \u306b \u3089 \u305b \u309b \u309c \u3080',
      '{lock} \u3061 \u3068 \u3057 \u306f \u304D \u304f \u307e \u306e \u308a \u308c \u3051 \u3077 \u309D {enter}',
      '{shift} \u3064 \u3055 \u305d \u3072 \u3053 \u307f \u3082 \u306d \u308b \u3081 {shift}',
      '.com @ {space}',
    ],
    shift: [
      '! " # $ % & \' ( ) \u0301 = ~ | {bksp}',
      '\u3092 \u3076 \u3041 \u3045 \u3047 \u3049 \u3083 \u3085 \u3087 \u308E \u307C \u3079',
      '{tab} \u3060 \u3067 \u3043 \u305A \u304C \u3095 \u3094 \u3071 \u3090 \u305C \u300c \u300d \u3065',
      '{lock} \u3062 \u3069 \u3058 \u3070 \u304E \u3050 \u307D \u3074 \u3099 \u308d \u3096 \u3052 \u309E {enter}',
      '{shift} \u3063 \u3056 \u305E \u3073 \u3054 \u307A \u3091 \u3001 \u3002 \u30fb {shift}',
      '.com @ {space}',
    ],
  },
}
