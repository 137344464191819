import { NavigationConstants } from '@dominos/navigation'
const EXCLUDED_ROUTES = [
  NavigationConstants.checkoutProcessing,
  NavigationConstants.nativeAppCheckoutProcessing,
  NavigationConstants.tracker,
  NavigationConstants.nativeAppTracker,
  NavigationConstants.nativeAppLogin,
  NavigationConstants.nativeAppCreateAccount,
  NavigationConstants.nativeAppMyAccount,
]

export const isExcludedRoute = () => EXCLUDED_ROUTES.some((route) => window.location.pathname.includes(route))
