import React from 'react'
import { getDeviceTheme } from './device-theme-picker'
import { DominosTheme } from './theme-data/standard-theme'

export interface DominosThemeProps {
  theme: DominosTheme
  updateTheme: (dark: boolean) => void
}

const theme: DominosThemeProps = {
  theme: getDeviceTheme(),
  updateTheme: (dark: boolean) => {},
}

export const ThemeContext = React.createContext<DominosThemeProps>(theme)
