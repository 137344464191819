import { CountryToggle } from '@dominos/components'
import {
  TermsAndConditionsGenericWithCard,
  TermsAndConditionsWithMessage,
} from '@dominos/components/terms-and-conditions'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default (props: TermsAndConditionsProps) => {
  const { onTermsChange, testID, isValid } = props
  const { t } = useTranslation('checkout')
  const theme = useDominosTheme()

  const handleTermsChange = () => {
    onTermsChange(!isValid)
  }

  return (
    <div style={{ marginTop: '10px' }}>
      <CountryToggle
        BE={
          <TermsAndConditionsGenericWithCard
            testID={`${testID}.card`}
            hasCheckedBox={true}
            checked={isValid}
            setOnChange={handleTermsChange}
            textColor={theme.colours.web.grey}
            t={t}
            countryCode={'BE'}
          />
        }
        FR={
          <TermsAndConditionsGenericWithCard
            testID={`${testID}.card`}
            hasCheckedBox={true}
            checked={isValid}
            setOnChange={handleTermsChange}
            textColor={theme.colours.web.grey}
            t={t}
            countryCode={'FR'}
          />
        }
        NL={
          <TermsAndConditionsGenericWithCard
            testID={`${testID}.card`}
            hasCheckedBox={false}
            checked={isValid}
            setOnChange={handleTermsChange}
            textColor={theme.colours.web.grey}
            t={t}
            countryCode={'NL'}
          />
        }
        LU={
          <TermsAndConditionsGenericWithCard
            testID={`${testID}.card`}
            hasCheckedBox={false}
            checked={isValid}
            setOnChange={handleTermsChange}
            textColor={theme.colours.web.grey}
            t={t}
            countryCode={'LU'}
          />
        }
        DE={<TermsAndConditionsWithMessage testID={`${testID}.withMessage`} />}
        DK={
          <TermsAndConditionsGenericWithCard
            testID={`${testID}.card`}
            hasCheckedBox={false}
            checked={isValid}
            setOnChange={handleTermsChange}
            textColor={theme.colours.web.grey}
            t={t}
            countryCode={'DK'}
          />
        }
      />
    </div>
  )
}
