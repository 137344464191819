import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const strokeWidth = 2.3

const defaultDimensions = {
  width: '132px',
  height: '133px',
}

const PortionEmptyLeft = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg data-testid='half-empty-left-icon' width={`${width}`} height={`${height}`} viewBox='0 0 152 153'>
      <g transform='translate(-22.000000, -12.000000)'>
        <g transform='translate(22.000000, 12.000000)'>
          <g transform='translate(75.799997, 76.449997) rotate(-180.000000) translate(-75.799997, -76.449997) translate(-0.200003, -0.050003)'>
            <path
              fill='none'
              stroke='#BFBFBF'
              strokeWidth={strokeWidth}
              d='M68.4,150.8V1.4c-38,3.7-67.3,36-67.3,74.7S30.5,147,68.4,150.8z'
            />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              fill='#ffffff'
              d='M82.2,152c39-3.2,69.6-36,69.6-76s-30.6-72.8-69.6-76V152z'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export { PortionEmptyLeft }
