import React from 'react'

import { NOT_SELECTED_OPTION_CODE } from '@dominos/business/functions/menu'
import { StaticSvgIcon } from '@dominos/res'
import { DEFAULT_SLATE_COLOUR, FONT_WEIGHT, HIGHLIGHT_COLOUR } from '../style-constants'
import css from './option-icon.less'

const ICON_SIZE = 50

export const OptionIcon = ({ name, code, selected, imageLink }: OptionIconProps) => {
  const color = selected ? HIGHLIGHT_COLOUR : DEFAULT_SLATE_COLOUR

  return (
    <div className={css.container}>
      <div className={css.iconContainer}>
        {(code === NOT_SELECTED_OPTION_CODE && (
          <StaticSvgIcon name='cross' fill='#454545' width={ICON_SIZE} height={ICON_SIZE} isUnstyled />
        )) ||
          (imageLink && <img alt={`${name} Icon`} className={css.icon} src={imageLink} />)}
      </div>
      <p
        data-testid={`crust.name.${name}`}
        className={css.name}
        style={{ color, fontWeight: selected ? FONT_WEIGHT : 'normal' }}
      >
        {name}
      </p>
    </div>
  )
}
