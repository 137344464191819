const handlePortionChanges = (
  defaultPortions: Bff.Products.Portion[],
  desiredPortions: Portion[],
  maxPortionSwapCount: number,
  setter: (portions: Portion[]) => void,
) => {
  let swapCount = 0
  for (let i = 0; i < desiredPortions.length; i++) {
    if (desiredPortions[i].productCode !== (defaultPortions[i].defaultProductCode || undefined)) {
      if (!defaultPortions[i].canSwap) {
        return
      }

      swapCount++
    }
  }

  const remainingSwaps = maxPortionSwapCount - swapCount

  if (remainingSwaps < 0) {
    return
  }

  const updatedPortions = desiredPortions.map((portion, index) => {
    const isSwapped = portion.productCode !== (defaultPortions[index].defaultProductCode || undefined)

    return {
      ...portion,
      canSwap: remainingSwaps === 0 ? isSwapped : defaultPortions[index].canSwap,
      isSwapped,
    }
  })

  setter(updatedPortions)
}

export { handlePortionChanges }
