import React from 'react'
import { BasketLine } from '../../basket-line'
import { CouponPrompt } from '../../coupon-prompt'

interface CouponItemsProps {
  testID: string
  items?: BasketCouponItem[] | []
  onEditLine?: (item: MenuItemDependents, lineData?: BasketLineItem) => void
  onRemoveLine?: (item: BasketLineItem) => void
  hideEditButton?: boolean
  hideRemoveButton?: boolean
  hideSwapButton?: boolean
  hidePrice?: boolean
  voucherCode?: string
  voucherNo?: number
}

const CouponItems = ({
  items = [],
  voucherCode,
  voucherNo,
  testID,
  onEditLine,
  onRemoveLine,
  hideEditButton,
  hideRemoveButton,
  hideSwapButton,
  hidePrice,
}: CouponItemsProps) => (
  <>
    {items.map((item) => (
      <div key={item.itemNo}>
        {item.lines.map((line) => (
          <BasketLine
            key={`${line.productCode}-${line.itemNo}`}
            testID={`${testID}.line.${line.productCode}-${line.itemNo}`}
            lineData={line}
            onEdit={onEditLine}
            onRemove={onRemoveLine}
            hideEditButton={hideEditButton}
            hideRemoveButton={hideRemoveButton}
            hideSwapButton={hideSwapButton || !item.hasSwaps}
            hidePrice={hidePrice}
            voucherItemNo={item.itemNo}
            voucherCode={voucherCode}
            voucherNo={voucherNo}
          />
        ))}
        {item.hasPrompt && (
          <CouponPrompt
            testID={`${testID}`}
            prompt={item.media.prompt}
            voucherItemNo={item.itemNo}
            voucherCode={voucherCode}
            voucherNo={voucherNo}
          />
        )}
      </div>
    ))}
  </>
)

export default CouponItems
