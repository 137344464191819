import gql from 'graphql-tag'

export const storeQuery = gql`
  query storeQuery($storeNo: Int!, $orderTime: String, $locationId: String) {
    store(storeNo: $storeNo, orderTime: $orderTime, locationId: $locationId) {
      storeType
      storeNo
      media {
        name
        displayAddress
      }
      geo {
        longitude
        latitude
      }
      timeZone
      loyalty {
        isEnabled
      }
      priceInfo {
        taxRate
        minOrderPricePickup
        minOrderPriceDelivery
      }
      pickupSubTypes
      deliverySubTypes
      features {
        featureName
        isEnabled
        settings {
          ... on TipTheDriverSettings {
            minimumTip
            maximumTip
            presets {
              value
              label
            }
          }
        }
      }
      surcharges {
        surchargeCode
        media {
          mediaName
          content
          language
        }
      }
      serviceMethods {
        delivery
        pickup
        dineIn
        message
      }
      onlineOrderingEnabled
    }
  }
`
