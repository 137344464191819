import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions/validation'
import { ValidationTextField } from '@dominos/components'

const postCodeMaxLength = 6
const streetNoMaxLength = 6
const unitNoMaxLength = 9

export const DeliveryAddressNlLayout = (props: DeliveryAddressLayoutBaseProps) => {
  const { t } = useTranslation('delivery-address')
  const { isAlphaNumeric, isRequired, matchRegex, maxLength } = useValidation()

  useEffect(() => {
    props.onInitialise({
      postCode: {
        isValid: false,
      },
      streetNo: {
        isValid: false,
      },
      unitNo: {
        isValid: true,
      },
    })
  }, [])

  return (
    <>
      <ValidationTextField
        key='postCode'
        fieldName='postCode'
        placeholder={t('Post Code')}
        testID={'postCode.field'}
        showAsteriskWhenRequired
        validationRules={[
          isRequired,
          isAlphaNumeric,
          // translation done at ValidationTextField
          matchRegex(/^[0-9]{4}[a-zA-Z]{2}$/, t('Required 4 digits followed by 2 letters')),
          maxLength(postCodeMaxLength),
        ]}
        style={{ width: '100%' }}
        onChange={props.onChange}
      />
      <ValidationTextField
        key='streetNo'
        fieldName='streetNo'
        placeholder={t('Street Number')}
        testID={'streetNo.field'}
        showAsteriskWhenRequired
        validationRules={[
          isRequired,
          isAlphaNumeric,
          // translation done at ValidationTextField
          matchRegex(/^[0-9]{1}/, t('Must start with a number')),
          maxLength(streetNoMaxLength),
        ]}
        style={{ width: '100%' }}
        onChange={props.onChange}
      />
      <ValidationTextField
        key='unitNo'
        fieldName='unitNo'
        placeholder={t('Unit Number')}
        testID={'unitNo.field'}
        showAsteriskWhenRequired
        validationRules={[maxLength(unitNoMaxLength)]}
        style={{ width: '100%' }}
        onChange={props.onChange}
      />
    </>
  )
}
