export * from './app-only-icon'
export * from './bundle-icon'
export * from './calzone-icon'
export * from './chicken-icon'
export * from './chicken-pieces-icon'
export * from './dessert-and-drinks-icon'
export * from './dessert-icon'
export * from './drinks-icon'
export * from './entrees-icon'
export * from './for-one-icon'
export * from './pasta-icon'
export * from './pizza-icon'
export * from './pizza-rolls-icon'
export * from './pizza-sandwich-icon'
export * from './promo-icon'
export * from './salad-icon'
export * from './salad-and-soup-icon'
export * from './sandwich-icon'
export * from './set-menu-icon'
export * from './shake-icon'
export * from './side-icon'
export * from './sides-desserts-icon'
export * from './signature-icon'
export * from './snacks-icon'
export * from './snacks-salad-icon'
export * from './tile-icon'
export * from './vegan-icon'
export * from './burger-icon'
export * from './condiments-icon'
