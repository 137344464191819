type DimensionSet = Bff.Dimensions.DimensionSet

const compareDimensionSets = <TDimensionSet extends DimensionSet>(a?: TDimensionSet, b?: TDimensionSet) => {
  if (!a || !b) {
    return false
  }
  switch (a.type) {
    case 'SizeBase': {
      if (b.type !== 'SizeBase') {
        return false
      }

      return a.size === b.size && a.base === b.base
    }

    case 'Size':
      if (b.type !== 'Size') {
        return false
      }

      return a.size === b.size

    case 'NonDimensional':
      return true

    default:
      return false
  }
}

export { compareDimensionSets }
