import React, { useState } from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'

import css from './quantity-changer.less'
import classnames from 'classnames'

const MIN_VALUE = 0
const MAX_VALUE = 9999999
const ICON_ACTIVE_COLOR = '#FFFFFF'

export const QuantityChanger = ({
  testID,
  quantity: initialQuantity,
  minQuantity = MIN_VALUE,
  maxQuantity = MAX_VALUE,
  onQuantityChange,
}: QuantityChangerProps) => {
  const [quantity, setQuantity] = useState<number>(initialQuantity)
  const theme = useDominosTheme()

  const offsetQuantity = (offset: number) => () => {
    const newValue = quantity + offset
    if (newValue >= minQuantity && newValue <= maxQuantity) {
      updateQuantity(newValue)
    }
  }

  const didBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const value = Number(event.target.value)
    if (isNaN(value) || value <= minQuantity) {
      event.target.value = minQuantity.toString()
      updateQuantity(minQuantity)
    }
  }

  const didChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value)
    if (isNaN(newValue) || (newValue !== 0 && (newValue < minQuantity || newValue > maxQuantity))) {
      event.preventDefault()
      event.target.value = quantity.toString()
    } else {
      updateQuantity(newValue)
    }
  }

  const updateQuantity = (value: number) => {
    setQuantity(value)
    if (onQuantityChange) {
      onQuantityChange(value)
    }
  }

  return (
    <div className={css.controls} data-testid={testID}>
      <div
        data-testid={`${testID}.decrement-quantity`}
        className={classnames(css.button, quantity <= minQuantity && css.disabled)}
        role={`decrement`}
        onClick={offsetQuantity(-1)}
      >
        <StaticSvgIcon
          name={'minus'}
          fill={quantity <= minQuantity ? theme.colours.actionStandard : ICON_ACTIVE_COLOR}
        />
      </div>
      <input
        data-testid={`${testID}.input-quantity`}
        className={css.text}
        type={'text'}
        value={quantity.toString()}
        onChange={didChangeValue}
        onBlur={didBlur}
        aria-label='Quantity'
      />
      <div
        data-testid={`${testID}.increment-quantity`}
        className={css.button}
        role={`increment`}
        onClick={offsetQuantity(1)}
      >
        <StaticSvgIcon name={'plus'} fill={ICON_ACTIVE_COLOR} />
      </div>
    </div>
  )
}
