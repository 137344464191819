import { CollapsableTitledCard, Spinner } from '@dominos/components'
import { OrderStatus, useReport } from '@dominos/hooks-and-hocs'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './order-eta.less'
import {
  ETA_UNAVAILABLE,
  formatETA,
  getETAForTracker,
  isTimeParsable,
  parseETATimeRange,
} from '@dominos/business/functions'

interface OrderEtaProps extends Bff.Orders.OrderEta {
  timezone?: string
  status: OrderStatus
  serviceMethod: BffContext.ServiceMethods
}

const OrderEta = ({ min, max, timezone, status, serviceMethod }: OrderEtaProps) => {
  const { t, i18n } = useTranslation('tracker')
  const { reportEstimatedOrderTime } = useReport()
  const [loading, setLoading] = useState<boolean>(true)

  const [displayText, setDisplayText] = useState<string>(t('ETAUnavailable', { defaultValue: ETA_UNAVAILABLE }))

  useEffect(() => {
    const { from, to } = parseETATimeRange(min, max, timezone)

    if (loading && isTimeParsable(from, to)) {
      setLoading(false)
    }

    const { text: timeRange, reportLabel } = formatETA(getETAForTracker, from, to, status, i18n.language, t)
    setDisplayText(timeRange)

    if ([OrderStatus.Making, OrderStatus.Cooking, OrderStatus.Leaving].includes(status)) {
      reportEstimatedOrderTime(reportLabel, status)
    }
  }, [min, max, status])

  const titleText =
    serviceMethod === 'Pickup'
      ? t('EstimatedPickupTime', { defaultValue: 'Estimated Pickup Time' })
      : t('EstimatedDeliveryTime', { defaultValue: 'Estimated Delivery Time' })

  return (
    <CollapsableTitledCard testID='order-eta' primaryTitle={titleText} style={{ marginBottom: '8px' }}>
      <div className={styles.orderEta}>
        {loading ? <Spinner testID={'order-eta-spinner'} color={'#1fc36a'} /> : <p>{displayText}</p>}
      </div>
    </CollapsableTitledCard>
  )
}

export { OrderEta }
