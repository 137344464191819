import React from 'react'
import { MoreInfo } from '@dominos/components/more-info'
import { useTranslation } from 'react-i18next'

interface VipOffersOptOutAllTCProps {
  testId?: string
}

export const VipOffersOptOutAllTC = ({ testId }: VipOffersOptOutAllTCProps) => {
  const { t } = useTranslation('checkout')

  return (
    <div data-testid='offers-t-and-c'>
      <MoreInfo
        testID={`${testId}.info`}
        id={'vipOffersOptOutAllTCInfo'}
        label={t('learnMoreButton', { defaultValue: 'Learn More' })}
        title={t('CheckoutOfferPopupTitle', { defaultValue: `What is this?` })}
        description={t('VIPOffersOptOutT&C', {
          defaultValue:
            'We use your information for our own advertising purposes for similar products and services. This means that if you are an existing customer, we may send you information about similar products or services via email or text message, unless you have objected to this. If you do not wish to receive further information from us, you can let us know and object at any time. You will not incur any costs for the objection apart from the transmission costs according to the basic tariffs. You can find more information in [Privacy Policy](https://www.dominos.com.au/about-us/contact-us/privacy-policy)',
        })}
      />
    </div>
  )
}
