import React, { useEffect, useRef } from 'react'
import { useBasket, useNativeAppBasketData } from '@dominos/hooks-and-hocs'
import { postWebViewMessage } from '@dominos/business/functions'
import { useDispatch } from 'react-redux'
import { rootActions } from '@dominos/business/root.actions'

declare global {
  interface WindowEventMap {
    'native-basket-updated': CustomEvent
  }
}

const withNativeAppBasket = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

  const ComponentWithNativeAppBasket = (props: P) => {
    const { initialLoading } = useNativeAppBasketData('menu')
    const { loadedAt, basket } = useBasket()
    const lastLoadedRef = useRef(loadedAt)
    const dispatch = useDispatch()

    useEffect(() => {
      if (!lastLoadedRef.current) {
        lastLoadedRef.current = loadedAt
      } else if (lastLoadedRef.current !== loadedAt) {
        postWebViewMessage({
          type: 'web-basket-updated',
          data: basket,
        })
        lastLoadedRef.current = loadedAt
      }
    }, [loadedAt])

    useEffect(() => {
      const onBasketUpdated = (event: CustomEvent) => {
        dispatch(rootActions.replaceBasket(event.detail))
      }
      window.addEventListener('native-basket-updated', onBasketUpdated)

      return () => window.removeEventListener('native-basket-updated', onBasketUpdated)
    }, [])

    return initialLoading ? <></> : <WrappedComponent {...props} />
  }

  ComponentWithNativeAppBasket.displayName = `withNativeAppBasket(${displayName})`

  return ComponentWithNativeAppBasket
}
export { withNativeAppBasket }
