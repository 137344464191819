import { useLazyQuery } from '@apollo/client'
import { useCurrentOrderDetails } from '@dominos/hooks-and-hocs'
import { useEffect } from 'react'
import { offersQuery } from '../../business/queries'
import { prepareQueryVariables } from './offers-helpers'

export const useAllOffers = () => {
  const [sendRequest, { data }] = useLazyQuery<{ offers: Bff.Offers.Offer[] }, Bff.Offers.OffersQueryArgs>(offersQuery)
  const { basketHeaderData: header } = useCurrentOrderDetails()

  const fetchOffers = () => {
    const variables = prepareQueryVariables(header!)

    sendRequest({
      variables,
    })
  }

  useEffect(() => {
    if (!!header) {
      fetchOffers()
    }
  }, [])

  return { offers: data?.offers ?? [] }
}
