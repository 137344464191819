import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon } from '@dominos/res'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LinkButton } from '../../buttons/link-button/link-button'
import css from './action-header.less'

interface ActionHeaderProps {
  onRemove: () => void
  hideRemove: boolean
  testID: string
}

const ICON_SIZE = 17

const ActionHeader = ({ onRemove, hideRemove, testID }: ActionHeaderProps) => {
  const { t } = useTranslation('menu')
  const {
    colours: { actionDanger },
  } = useDominosTheme()

  return (
    <div data-testid={`${testID}.buttons`} className={css.buttons}>
      {!hideRemove && (
        <React.Fragment>
          <StaticSvgIcon name={'trashCan'} fill={actionDanger} width={ICON_SIZE} height={ICON_SIZE} isUnstyled />
          <LinkButton
            testID={`${testID}.remove`}
            text={t('RemoveVoucher')}
            weight={'semibold'}
            style={{ color: actionDanger }}
            onPress={onRemove}
          />
        </React.Fragment>
      )}
    </div>
  )
}

export default ActionHeader
