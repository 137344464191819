/* eslint-disable rxjs/no-internal */
import React from 'react'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createEpicMiddleware } from 'redux-observable'
import { Persistor, persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ajax } from 'rxjs/internal/observable/dom/ajax'
import { graphQLMutation, graphQLQuery } from '@dominos/apollo'
import { rootActions, rootEpic, rootReducer } from '@dominos/business'
import { useConfigContext, useLaunchDarklyFlags, useVendorsContext } from '@dominos/hooks-and-hocs'
import { basePersistConfig } from './base-persistant-config'

export let persistor: Persistor

export let store: Store<RootReducer>

export const WithPersistentStore = ({ children }: { children: React.ReactElement }) => {
  const { applicationEnv, hasLocationPermission } = useConfigContext()
  const { sendSecurity } = useVendorsContext()
  const { flags } = useLaunchDarklyFlags()

  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      graphQLMutation,
      graphQLQuery,
      securitySender: sendSecurity,
      getJSON: ajax.getJSON,
      features: flags,
    },
  })
  const persistedReducer = persistReducer(basePersistConfig, rootReducer)
  store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(epicMiddleware)))
  epicMiddleware.run(rootEpic)

  persistor = persistStore(store, undefined, () => {
    store.dispatch(rootActions.applicationEnvironment(applicationEnv))
    store.dispatch(rootActions.applicationStarted({ hasLocationPermission }))
  })

  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>{children}</Provider>
    </PersistGate>
  )
}
