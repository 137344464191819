import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 135,
  height: 135,
}

const PizzaSlice2 = (props: SvgIconProps = defaultDimensions) => {
  const { width, height } = props

  return (
    <svg width={width} height={height} viewBox='0 0 135 135' version='1.1'>
      <title>Pizza Ghost</title>
      <g id='Payment-Loader-|-Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Pizza-Ghost' transform='translate(0.002636, 0.000000)'>
          <g id='Slice-2' transform='translate(73.001196, 22.000906)' fill='#E21836'>
            <path
              d='M57.6121846,44 C58.9439859,44.0023883 60.0459211,42.8996025 59.998526,41.5576008 C59.4795501,26.8529932 53.9485462,12.2966996 43.4031447,0.747351063 C42.5002687,-0.24061102 40.9409712,-0.24300318 39.9978094,0.704292183 L35,5.72304387 C45.4126954,16.2868225 50.5977153,30.1422132 50.562169,43.983251 L57.6121846,44 Z'
              id='Fill-14'
            />
            <path
              d='M15.9369834,36.1317829 C16.6250284,36.9649448 16.5099547,38.1990807 15.6780675,38.8905811 C14.8461803,39.5796803 13.6139324,39.4644303 12.9258874,38.6312684 C12.235445,37.7981066 12.3505187,36.5639706 13.1848033,35.8724703 C14.0166905,35.1833711 15.2489384,35.2986211 15.9369834,36.1317829 M34.9241494,25.1374082 C36.5327843,27.0822529 36.2618815,29.9611031 34.3200123,31.5722028 C32.3805404,33.1833025 29.5036971,32.9119846 27.8950622,30.96714 C26.2888247,29.0246964 26.55733,26.1434452 28.4991993,24.5323455 C30.4410685,22.9236468 33.3155145,23.1925636 34.9241494,25.1374082 M28.587902,39.4932428 C27.199825,40.6433423 25.1452794,40.4512588 23.9969395,39.0634561 C22.8485995,37.6732524 23.0427864,35.6155588 24.428466,34.4678603 C25.8141455,33.3153598 27.8686911,33.5098443 29.0170311,34.897647 C30.165371,36.2854497 29.9735815,38.3431433 28.587902,39.4932428 M37.2879556,35.1569596 C37.9760007,35.9901214 37.8609269,37.2266584 37.0290398,37.9157577 C36.1971526,38.607258 34.9649047,38.4896069 34.2744623,37.6564451 C33.5864172,36.8232833 33.701491,35.5891473 34.5333782,34.900048 C35.3652654,34.2085477 36.5975133,34.3261988 37.2879556,35.1569596 M1.67742998,43.889552 L46.9998504,44 C47.0308651,31.3465048 42.2409209,18.6786033 32.6250721,9 L0.497924217,41.0155039 C-0.561713076,42.0719627 0.181471451,43.8847499 1.67742998,43.889552'
              id='Fill-16'
            />
          </g>
          <g id='Slice-1' transform='translate(68.001196, 0.000000)' fill='#007AAF'>
            <path
              d='M43.2900495,20.0439468 C44.240979,19.1081822 44.2433743,17.5619314 43.2565154,16.6638803 C32.4489736,6.81124551 18.1754493,0.590885913 2.45996131,0.00161349916 C1.11620445,-0.0478853836 0,1.04816131 0,2.37284569 L0,9.38518741 C14.9202522,9.38518741 28.4512371,15.3580526 38.2527577,25.0009064 L43.2900495,20.0439468 Z'
              id='Fill-18'
            />
            <path
              d='M7.4508996,44.9362929 C8.53007829,45.0345766 9.32323857,45.9862502 9.22469441,47.0625763 C9.12615025,48.1365053 8.1719544,48.9299662 7.09517923,48.8316825 C6.01600055,48.7333988 5.22043675,47.7817252 5.31898091,46.7053991 C5.41752507,45.629073 6.37172092,44.8380092 7.4508996,44.9362929 M13.075127,23.7357847 C15.5916083,23.9659123 17.4447191,26.1856851 17.2163851,28.6955146 C16.9856476,31.2053441 14.7599918,33.0535567 12.2435105,32.823429 C9.72702925,32.5933014 7.87391842,30.3735286 8.10465595,27.8636991 C8.33298997,25.3538696 10.5610493,23.5056571 13.075127,23.7357847 M18.7834775,38.3369036 C18.6176349,40.1299814 17.0265073,41.4508181 15.2310809,41.2854139 C13.4332509,41.1224068 12.1113171,39.5378822 12.2747562,37.7448044 C12.4381953,35.9517265 14.0293229,34.6308898 15.8247493,34.7962941 C17.6225793,34.9593011 18.9469166,36.5462229 18.7834775,38.3369036 M21.8575745,29.1174153 C22.9367532,29.215699 23.7299135,30.1673726 23.6313693,31.2436987 C23.5328252,32.3200248 22.5786293,33.1110886 21.5018541,33.0128049 C20.4226755,32.9145212 19.6271117,31.9628476 19.7256558,30.8865215 C19.8242,29.8101953 20.7783958,29.0191316 21.8575745,29.1174153 M2.8697981,60.5058656 L35,28.4605927 C26.0420959,19.5263667 13.6687955,14.0009064 0,14.0009064 L0,59.31927 C0,60.8150996 1.81225106,61.5630144 2.8697981,60.5058656'
              id='Fill-21'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default PizzaSlice2
