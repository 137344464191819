import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatures } from '@dominos/hooks-and-hocs/features'
import css from './from-price-label.less'

export const FromPriceLabel = ({ testID }: BaseProps) => {
  const { t } = useTranslation('menu')
  const [fromEnabled] = useFeatures('menu-card-from-price')
  const fromText = t('From')

  return fromEnabled && !!fromText ? (
    <div data-testid={`${testID}`} className={css.from}>
      {fromText}
    </div>
  ) : null
}
