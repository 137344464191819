import { ErrorDefinitions } from '@dominos/interfaces'

export const myPasswordErrors: ErrorDefinitions = {
  INCORRECT_PASSWORD: {
    translation: {
      key: 'ChangePasswordIncorrectCurrentPassword',
      options: 'Your current password is incorrect.',
    },
    display: 'alert',
  },
  INVALID_INPUT: {
    translation: {
      key: 'ChangePasswordInvalidNewPassword',
      options: 'New password contains invalid characters or does not meet length requirements. Please try again.',
    },
    display: 'alert',
  },
  FAILED_TO_UPDATE: {
    // when the downstream customer account service returns an error
    translation: {
      key: 'ChangePasswordUpdatePasswordFailure',
      options: 'There was a problem updating your password. Please try again later.',
    },
    display: 'alert',
  },
  UNABLE_TO_RESOLVE: {
    // when the downstream service (user accounts) is down
    translation: {
      key: 'DownstreamFailure',
      options: 'There was a problem reaching our servers. Please try again later.',
    },
    display: 'alert',
  },
}
