import React from 'react'
import { createProductNutritionalLineViewModel } from './create-product-nutritional-line-view-model'
import { ProductNutritionalLineView } from './product-nutritional-line-view'
import { ProductNutritionalLineProps } from './product-nutritional-line.interface'

export const ProductNutritionalLine = ({
  info: nutritionalInfo,
  keyName,
  defaultUnit,
  t,
}: ProductNutritionalLineProps): JSX.Element => {
  const viewModel = createProductNutritionalLineViewModel({
    t,
    nutritionalInfo,
    keyName,
    defaultUnit,
  })

  return <ProductNutritionalLineView viewModel={viewModel} />
}
