import { ProductPricingDetails } from '@dominos/components'
import React from 'react'
import css from './portion-product-card-title.less'
export const PortionProductCardTitle = ({ title, testID, price, promoPrice }: PortionProductCardTitleProps) => (
  <div className={css.headerDetailBox}>
    <div data-testid={`${testID}.portion-product-card-title`} className={css.headerTitle}>
      {title}
    </div>
    <div className={css.headerPrice}>
      <ProductPricingDetails
        testID={`${testID}.subtitle`}
        price={price}
        promoPrice={promoPrice}
        showPrice={true}
        showEnergyDividerForPromo={false}
      />
    </div>
  </div>
)
