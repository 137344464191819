import { DateTime } from 'luxon'

export const convertOrderTime = (orderTime?: string, timeZone?: string): string | null => {
  if (!orderTime || !timeZone) {
    return null
  }

  const date = DateTime.fromISO(orderTime, { zone: timeZone })

  return date.isValid ? date.toString() : null
}

export const getBasketHeaderData = (order: Bff.Orders.Order): Partial<BasketData> => ({
  storeNo: order.store?.storeNo,
  serviceMethod: order.details?.header.serviceMethod,
  serviceMethodSubType: order.details?.header.serviceMethodSubType,
  time: convertOrderTime(order.details?.header.orderTime, order.store?.timeZone),
})
