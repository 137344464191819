export const Chevron = {
  width: 330,
  height: 200,
  inset: 50,
  xOverlap: 56,
  boundingWidth: 385,
  outlineWidth: 10,
}

const ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS: BffContext.Orders.Status[] = [
  'SentToStore',
  'Timed',
  'Making',
  'Remaking',
  'Cooking',
]
export const ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS_PICKUP = ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS
export const ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS_DELIVERY: BffContext.Orders.Status[] = [
  ...ALLOWED_ONLINE_EXPERIENCE_FEEDBACK_STATUS,
  'Ready',
  'Leaving',
]
