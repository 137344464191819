import { convertQueryStringToObject } from '@dominos/business/functions/text'
import { useOrder } from '@dominos/hooks-and-hocs'
import { useLocation } from '@reach/router'
import { useEffect, useState } from 'react'

export const useOrderIdFromQuery = () => {
  const [id, setId] = useState<string | undefined>()
  const [finished, setFinished] = useState<boolean>(false)
  const { fetchOrder, order, errors } = useOrder()
  const { search } = useLocation()

  useEffect(() => {
    const qs = convertQueryStringToObject(search) as { order: string }
    if (qs?.order) {
      fetchOrder(qs.order)
    } else {
      setFinished(true)
    }
  }, [search])

  useEffect(() => {
    if (order?.id) {
      setId(order.id)
      setFinished(true)
    }
  }, [order])

  useEffect(() => {
    if (errors) {
      setFinished(true)
    }
  }, [errors])

  return {
    finished,
    id,
    order,
    errors,
  }
}
