import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { ActionButton } from '@dominos/components'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const ResetPasswordConfirmation = ({ testID, onClick }: { onClick: () => void } & BaseProps) => {
  const { t } = useTranslation('login')
  const theme = useDominosTheme()
  const styles = useMemo(
    () => ({
      resetButton: { width: '100%' },
      forgottenPasswordMessage: {
        padding: '0 0 8px 0',
        fontSize: '10pt',
        fontWeight: 700,
        cursor: 'pointer',
        color: '#007aff',
      },
      contentMessage: { padding: '0 0 8px 0', fontWeight: 400 } as React.CSSProperties,
      socialsContentMessage: {
        padding: '0 0 14px 0',
        fontSize: '9pt',
        color: theme.colours.web.grey,
        textAlign: 'center',
      } as React.CSSProperties,
    }),
    [theme],
  )

  return (
    <div data-testid={testID} style={{ padding: 8 }}>
      <div style={{ ...styles.contentMessage, padding: '8px 0' }}>
        {t('ForgottenPasswordResultPopupContent1', {
          defaultValue:
            "If your email has a Domino's account associated with it you will receive an email with instructions on how to reset your password. Check your inbox and click on the link provided.",
        })}
      </div>
      <div style={styles.contentMessage}>
        {t('ForgottenPasswordResultPopupContent2', {
          defaultValue: 'Check your inbox and click on the link provided.',
        })}
      </div>
      <div style={styles.socialsContentMessage}>
        {t('ForgottenPasswordResultPopupSocialsContent', {
          defaultValue:
            'If you signed up using Apple, Facebook, Google or PayPal you will need to reset your password with that service.',
        })}
      </div>
      <ActionButton
        onPress={onClick}
        testID={`${testID}.close`}
        containerStyle={styles.resetButton}
        textStyle={{ color: theme.colours.tooltipText }}
        textFontWeight={'semibold'}
        text={t('ForgottenPasswordResultCloseButton', { defaultValue: 'Close' })}
      />
    </div>
  )
}

export { ResetPasswordConfirmation }
