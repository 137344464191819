import { ActionButton } from '@dominos/components/buttons'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const CreateAccountButton = ({ onSignUpClicked }: CreateAccountButtonProps) => {
  const { t } = useTranslation('login')

  return (
    <>
      <div>{t('UnregisteredUserPrompt', { defaultValue: 'No account?' })}</div>
      <ActionButton
        testID='create-account'
        onPress={onSignUpClicked}
        containerStyle={{
          width: '100%',
          marginTop: '8px',
          backgroundColor: '#007aff',
          fontSize: '17px',
          color: 'white',
          fontFamily: 'inherit',
          height: '54px',
          fontWeight: 600,
        }}
      >
        {t('createAnAccount', { defaultValue: 'Create an Account' })}
      </ActionButton>
    </>
  )
}

interface CreateAccountButtonProps {
  onSignUpClicked: () => void
}
