import { Card } from '@dominos/components'
import React from 'react'
import { Prompt } from '@dominos/components/universal-auth'

export const NativeTrackerAuthPrompt = ({ id }: { id?: string }) => {
  const testID = 'native-tracker-create-account'

  return (
    <div id={id} style={{ marginTop: 8, marginBottom: 8 }}>
      <Card testID={`${testID}.card`}>
        <Prompt testID={testID} />
      </Card>
    </div>
  )
}
