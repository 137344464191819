import React from 'react'
import { SvgIconProps } from 'res/interfaces/icon.interfaces'
const defaultDimensions = {
  width: 22,
  height: 22,
}
const CircleHalfEmptyRight = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <title>􀀂</title>
      <g id='Half-and-Half-|-App' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='Half-&amp;-Half-|-Product-Card'
          transform='translate(-335.000000, -409.000000)'
          fill='#0A7AFF'
          fillRule='nonzero'
        >
          <g id='Create-Half-and-Half' transform='translate(13.000000, 391.000000)'>
            <g id='􀀂' transform='translate(322.748047, 18.641602)'>
              <path
                d='M10.3125,20.625 C11.7285156,20.625 13.059082,20.3564453 14.3041992,19.8193359 C15.5493164,19.2822266 16.6455078,18.5392253 17.5927734,17.590332 C18.5400391,16.6414388 19.2830404,15.5452474 19.8217773,14.3017578 C20.3605143,13.0582682 20.6298828,11.7285156 20.6298828,10.3125 C20.6298828,8.89648438 20.3605143,7.56673177 19.8217773,6.32324219 C19.2830404,5.0797526 18.5400391,3.9835612 17.5927734,3.03466797 C16.6455078,2.08577474 15.5493164,1.34277344 14.3041992,0.805664062 C13.059082,0.268554688 11.7285156,0 10.3125,0 C8.89973958,0 7.56998698,0.268554688 6.32324219,0.805664062 C5.0764974,1.34277344 3.97949219,2.08577474 3.03222656,3.03466797 C2.08496094,3.9835612 1.34277344,5.0797526 0.805664062,6.32324219 C0.268554688,7.56673177 0,8.89648438 0,10.3125 C0,11.7285156 0.268554688,13.0582682 0.805664062,14.3017578 C1.34277344,15.5452474 2.08577474,16.6414388 3.03466797,17.590332 C3.9835612,18.5392253 5.08056641,19.2822266 6.32568359,19.8193359 C7.57080078,20.3564453 8.89973958,20.625 10.3125,20.625 Z M10.3125,18.0566406 L10.3125,2.56835938 C11.3867188,2.56835938 12.3917643,2.76774089 13.3276367,3.16650391 C14.2635091,3.56526693 15.0854492,4.11946615 15.793457,4.82910156 C16.5014648,5.53873698 17.0564779,6.36149089 17.4584961,7.29736328 C17.8605143,8.23323568 18.0615234,9.23828125 18.0615234,10.3125 C18.0615234,11.3867188 17.8613281,12.3917643 17.4609375,13.3276367 C17.0605469,14.2635091 16.5063477,15.0854492 15.7983398,15.793457 C15.090332,16.5014648 14.2675781,17.0556641 13.3300781,17.4560547 C12.3925781,17.8564453 11.3867188,18.0566406 10.3125,18.0566406 Z'
                id='Shape'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export { CircleHalfEmptyRight }
