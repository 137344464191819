import React from 'react'
import { PortionProductProps } from './portion-product.interface'
import css from './portion-product.less'
import { svgIcons } from '@dominos/res'

export const PortionProduct = ({ imageUri, testID, portionKey }: PortionProductProps) => {
  const SidePortionIcon = svgIcons[`portionEmptySideIcon_${portionKey}`]

  return (
    <div className={css.portionWrapper}>
      <img src={imageUri} className={css.image} data-testid={`${testID}.image`} />
      {SidePortionIcon && (
        <div className={css.iconWrapper}>
          <SidePortionIcon />
        </div>
      )}
    </div>
  )
}
