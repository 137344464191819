import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 44,
  height: 73,
}

export const Gauge3 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 44 73'>
      <defs>
        <path
          d='M91.7351054,94.4 L70.5351054,94.4 C69.1351054,94.4 68.0351054,93.3 68.0351054,91.9 L68.0351054,83 C68.0351054,81.6 69.1351054,80.5 70.5351054,80.5 L100.235105,80.5 C104.335105,80.5 107.735105,83.9 107.735105,88 L107.735105,104.8 C107.735105,107.7 106.435105,109.7 104.335105,111.7 L101.135105,114.7 C100.935105,114.9 100.935105,115.2 101.135105,115.3 L104.335105,118.1 C106.435105,119.9 107.735105,121.9 107.735105,124.6 L107.735105,143 C107.735105,147.1 104.335105,150.5 100.235105,150.5 L70.5351054,150.5 C69.1351054,150.5 68.0351054,149.4 68.0351054,148 L68.0351054,139.1 C68.0351054,137.7 69.1351054,136.6 70.5351054,136.6 L91.7351054,136.6 C92.0351054,136.6 92.2351054,136.4 92.2351054,136.1 L92.2351054,122.2 C92.2351054,121.9 92.0351054,121.7 91.7351054,121.7 L78.6351054,121.7 C77.2351054,121.7 76.1351054,120.6 76.1351054,119.2 L76.1351054,111.3 C76.1351054,109.9 77.2351054,108.8 78.6351054,108.8 L91.7351054,108.8 C92.0351054,108.8 92.2351054,108.6 92.2351054,108.3 L92.2351054,94.9 C92.2351054,94.6 92.0351054,94.4 91.7351054,94.4 Z'
          id='gauge-path-3'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Points3' transform='translate(-66.000000, -79.000000)' fillRule='nonzero'>
          <g id='Path'>
            <use fill={fill} href='#gauge-path-3' />
            <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-3' />
          </g>
        </g>
      </g>
    </svg>
  )
}
