import { ServiceMethodSubTypeProps, ServiceMethodSubTypes } from '@dominos/interfaces/checkout'
import { DropNGo, ReceiveDirectly, RobotDelivery } from './delivery-subtype-components'
import { CarParkDelivery, InStorePickUp, SpeedPickup } from './pick-up-subtype-components'

interface TranslationType {
  key: string
  defaultValue: string
}

interface ServiceMethodTypeDefinitions {
  primaryTitle: TranslationType
  secondaryTitle: TranslationType
  label: TranslationType
  component?: React.FC<ServiceMethodSubTypeProps>
}

const DefaultPickUpDefinition: Omit<ServiceMethodTypeDefinitions, 'secondaryTitle' | 'label'> = {
  primaryTitle: {
    key: 'PickUpType',
    defaultValue: 'Pick Up Type',
  },
}
const DefaultDeliveryDefinition: Omit<ServiceMethodTypeDefinitions, 'secondaryTitle' | 'label'> = {
  primaryTitle: {
    key: 'DeliveryType',
    defaultValue: 'Delivery Type',
  },
}

export const unknownSubTypeDefinition = (
  serviceMethod: BffContext.ServiceMethods,
  key: string,
): ServiceMethodTypeDefinitions => ({
  ...(serviceMethod === 'Pickup' ? DefaultPickUpDefinition : DefaultDeliveryDefinition),
  secondaryTitle: {
    key,
    defaultValue: key,
  },
  label: {
    key,
    defaultValue: key,
  },
})

export const pickUpSubTypeDefinitions: PartialRecord<ServiceMethodSubTypes, ServiceMethodTypeDefinitions> = {
  InStorePickUp: {
    ...DefaultPickUpDefinition,
    secondaryTitle: {
      key: 'InStorePickUpTitle',
      defaultValue: 'In-Store',
    },
    label: {
      key: 'InStorePickUp',
      defaultValue: 'Collect from the store',
    },
    component: InStorePickUp,
  },
  SpeedPickup: {
    ...DefaultPickUpDefinition,
    secondaryTitle: {
      key: 'SpeedPickupTitle',
      defaultValue: 'SPEED PICKUP',
    },
    label: {
      key: 'SpeedPickup',
      defaultValue: 'Collect from SPEED PICKUP rack',
    },
    component: SpeedPickup,
  },
  ZeroContactMandatoryPickup: {
    ...DefaultPickUpDefinition,
    secondaryTitle: {
      key: 'ZeroContactMandatoryPickupTitle',
      defaultValue: 'Zero Contact',
    },
    label: {
      key: 'ZeroContactMandatoryPickup',
      defaultValue: 'Mandatory zero contact pick up from the store',
    },
  },
  CarParkDelivery: {
    ...DefaultPickUpDefinition,
    secondaryTitle: {
      key: 'CarParkDeliveryTitle',
      defaultValue: 'Bring to Car',
    },
    label: {
      key: 'CarParkDelivery',
      defaultValue: 'Bring my order to my car',
    },
    component: CarParkDelivery,
  },
  PickUpPoint: {
    ...DefaultPickUpDefinition,
    secondaryTitle: {
      key: 'PickUpPointTitle',
      defaultValue: 'Bring to Outside',
    },
    label: {
      key: 'PickUpPoint',
      defaultValue: 'Bring my order to me outside the store',
    },
  },
}

export const deliverySubTypeDefinitions: PartialRecord<ServiceMethodSubTypes, ServiceMethodTypeDefinitions> = {
  ReceiveDirectly: {
    ...DefaultDeliveryDefinition,
    secondaryTitle: {
      key: 'ReceiveDirectlyTitle',
      defaultValue: 'Receive Directly',
    },
    label: {
      key: 'ReceiveDirectly',
      defaultValue: "I'll receive directly",
    },
    component: ReceiveDirectly,
  },
  DropNGoDelivery: {
    ...DefaultDeliveryDefinition,
    secondaryTitle: {
      key: 'DropNGoDeliveryTitle',
      defaultValue: 'Drop & Go',
    },
    label: {
      key: 'DropNGoDelivery',
      defaultValue: 'Notify me and leave my order',
    },
    component: DropNGo,
  },
  RobotDelivery: {
    ...DefaultDeliveryDefinition,
    secondaryTitle: {
      key: 'RobotDeliveryTitle',
      defaultValue: 'Robot Delivery',
    },
    label: {
      key: 'RobotDelivery',
      defaultValue: 'Robot Delivery',
    },
    component: RobotDelivery,
  },
}

export const serviceMethodSubTypeDefinitions: PartialRecord<ServiceMethodSubTypes, ServiceMethodTypeDefinitions> = {
  ...pickUpSubTypeDefinitions,
  ...deliverySubTypeDefinitions,
}
