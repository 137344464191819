import React from 'react'

import ActionRow from '../action-row/action-row'
import Sauce from '../basket-line/sauce/sauce'
import { DetailRow } from '../detail-row'

export const PortionLine = ({ testID, portion }: PortionLineProps) => (
  <div data-testid={testID} style={{ marginBottom: 6 }}>
    <DetailRow testID={`${testID}.header`} label={portion.media && portion.media.name} />
    <Sauce testID={`${testID}.sauce`} noLabel={true} sauce={portion.sauce && portion.sauce.media} />
    <ActionRow testID={`${testID}.toppings`} noLabel={true} items={portion.toppings} />
  </div>
)
