import { Checkbox } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './subscriptions.less'

export const Subscriptions = (props: SubscriptionsProps) => {
  const { t } = useTranslation('login')

  return (
    <div className={styles.subscriptions} data-testid='subscriptions'>
      {props.specialOffersEnabled && (
        <div className={styles.checkbox}>
          <Checkbox
            testID='marketing-consent'
            defaultChecked={false}
            onChange={props.onChange}
            ignoreLabelOnClick={false}
            name='marketing'
          >
            <p className={styles.checkboxText}>
              {t('MarketingConsentCheckbox', { defaultValue: 'Receive emails with exclusive offers' })}
            </p>
          </Checkbox>
        </div>
      )}
      {props.voucherNotificationEnabled && (
        <div className={styles.checkbox}>
          <Checkbox
            testID='voucher-expiry'
            defaultChecked={false}
            onChange={props.onChange}
            ignoreLabelOnClick={false}
            name='vouchers'
          >
            <p className={styles.checkboxText}>
              {t('VoucherExpiryNotificationConsentCheckbox', { defaultValue: 'Email me voucher expiry notifications' })}
            </p>
          </Checkbox>
        </div>
      )}
    </div>
  )
}
