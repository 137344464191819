import gql from 'graphql-tag'

export const PRODUCT_NUTRITIONAL_FIELDS = gql`
  fragment ProductNutritionalFields on ProductNutritionalsOld {
    energyKJ
    carbohydrate
    energyKcal
    fat
    fatSaturated
    fibre
    protein
    salt
    sodium
    sugars
    weight
  }
`

export const PORTION_NUTRITIONAL_FIELDS = gql`
  fragment PortionNutritionalFields on PortionNutritionalsOld {
    energyKJ
    carbohydrate
    energyKcal
    fat
    fatSaturated
    fibre
    protein
    salt
    sodium
    sugars
    weight
  }
`

export const PRODUCT_MEDIA_FIELDS = gql`
  fragment ProductMediaFields on ProductMediaOld {
    name
    description
    largeImage {
      url
      altText
    }
    smallImage {
      url
      altText
    }
    applicationImage {
      url
      altText
    }
  }
`

export const INGREDIENT_MEDIA = gql`
  fragment IngredientMedia on IngredientMediaOld {
    name
    image
    colour
    icon
  }
`

export const PRODUCT_RULE = gql`
  fragment ProductRuleFields on ProductRuleOld {
    min
    max
    maxPerIngredient
    locked
    allowAdd
    allowRemove
  }
`

export const INGREDIENT = gql`
  ${INGREDIENT_MEDIA}
  fragment IngredientFields on IngredientOld {
    code
    media {
      ...IngredientMedia
    }
    price
  }
`

export const INGREDIENT_SET = gql`
  ${PRODUCT_RULE}
  ${INGREDIENT}
  fragment IngredientSetFields on IngredientSetOld {
    rule {
      ...ProductRuleFields
    }
    ingredients {
      ...IngredientFields
    }
  }
`

export const LEGEND_FIELDS = gql`
  fragment LegendFields on MenuLegendOld {
    code
    image {
      url
      altText
    }
    text
  }
`

export const PRODUCT_MENU_ITEM = gql`
  ${PRODUCT_NUTRITIONAL_FIELDS}
  ${PRODUCT_MEDIA_FIELDS}
  ${INGREDIENT_SET}
  ${INGREDIENT}
  ${LEGEND_FIELDS}
  fragment ProductMenuItemFields on ProductMenuItemOld {
    code
    type
    defaultSize
    halfNHalfEnabled
    media {
      ...ProductMediaFields
    }
    sizes {
      code
      price
      salePrice
      promoPrice
      availability
      media {
        name
      }
      swaps {
        bases {
          rule {
            ...ProductRuleFields
          }
          ingredients {
            ...IngredientFields
            nutritionals {
              ...ProductNutritionalFields
            }
            allergens {
              name
              type
            }
          }
        }
        sauces {
          ...IngredientSetFields
        }
        toppings {
          ...IngredientSetFields
        }
        options {
          ...IngredientSetFields
        }
      }
      recipe {
        base {
          ...IngredientFields
          nutritionals {
            ...ProductNutritionalFields
          }
        }
        sauce {
          ...IngredientFields
        }
        toppings {
          ingredient {
            ...IngredientFields
          }
          quantity
        }
        options {
          ingredient {
            ...IngredientFields
          }
          quantity
        }
      }
      nutritionals {
        ...ProductNutritionalFields
      }
      allergens {
        name
        type
      }
      upgrades {
        ...IngredientFields
      }
    }
    legends {
      ...LegendFields
    }
    disclaimers
    features {
      inMenuCustomisation
    }
  }
`

export const PORTION_MENU_ITEM = gql`
  ${PORTION_NUTRITIONAL_FIELDS}
  ${PRODUCT_MEDIA_FIELDS}
  ${INGREDIENT_SET}
  ${INGREDIENT}
  ${LEGEND_FIELDS}
  fragment PortionMenuItemFields on PortionMenuItemOld {
    code
    type
    defaultSize
    media {
      name
      description
      largeImage {
        url
        altText
      }
      smallImage {
        url
        altText
      }
      applicationImage {
        url
        altText
      }
    }
    sizes {
      code
      price
      promoPrice
      availability
      media {
        name
      }
      swaps {
        bases {
          rule {
            ...ProductRuleFields
          }
          ingredients {
            ...IngredientFields
            nutritionals {
              ...ProductNutritionalFields
            }
            allergens {
              name
              type
            }
          }
        }
        portionProductCodes
      }
      recipe {
        base {
          ...IngredientFields
        }
        portionCount
      }
      nutritionals {
        ...PortionNutritionalFields
      }
      allergens {
        name
        type
      }
    }
    legends {
      ...LegendFields
    }
    disclaimers
    features {
      inMenuCustomisation
    }
  }
`
