import { ApolloError } from '@apollo/client'
import {
  addItemsToBasket,
  getProductItemSignature,
  getValidateBasketFailureState,
  getValidateBasketSuccessState,
  removeCouponFromBasket,
  removeItemFromBasket,
} from '@dominos/business/functions/basket'
import { rootActions } from '@dominos/business/root.actions'
import { getType } from 'typesafe-actions'

export interface BasketReducerProps {
  validationErrors: ValidateBasketValidationError[]
  basket: Basket
  error?: ApolloError
  loadedAt?: string
  pending: boolean
  pendingCoupons: string[]
  pendingDeepLinkCoupon: string | null
  pendingLoyaltyCoupons: number
  showSalePrice?: boolean
  success: boolean
  hardUpsellOrderId?: string
}

const basketInitState: BasketReducerProps = {
  validationErrors: [],
  basket: { surcharges: [], totalDiscount: 0, id: '', lines: [], total: 0 },
  pending: false,
  pendingCoupons: [],
  pendingDeepLinkCoupon: null,
  pendingLoyaltyCoupons: 0,
  success: false,
  hardUpsellOrderId: '',
}

// eslint-disable-next-line max-lines-per-function
export const basketReducer: import('redux').Reducer<BasketReducerProps> = (
  state: BasketReducerProps = basketInitState,
  action: RootActions,
) => {
  switch (action.type) {
    case getType(rootActions.clearValidationErrors):
      return { ...state, validationErrors: [] }
    case getType(rootActions.validateBasket.success):
      return getValidateBasketSuccessState(state, action)
    case getType(rootActions.removeCoupon):
      return {
        ...state,
        pending: true,
        basket: {
          ...state.basket,
          totalDiscount: 0,
          surcharges: [],
          lines: removeCouponFromBasket(state.basket.lines, action.payload),
        },
      }

    case getType(rootActions.validateBasket.request):
      return {
        ...state,
        pending: false,
        error: undefined,
      }

    case getType(rootActions.addLinesToBasket):
      const newLines = addItemsToBasket(
        state.basket.lines,
        action.payload.add,
        action.payload.voucherNo,
        action.payload.voucherItemNo,
      )
      const basket: Basket = {
        ...state.basket,
        lines: newLines,
      }

      return {
        ...state,
        basket,
        pending: true,
      }

    case getType(rootActions.selectServiceMethod):
    case getType(rootActions.storeSelected):
      return {
        ...state,
        ...basketInitState,
        error: undefined,
        pendingCoupons: state.pendingCoupons,
        pendingDeepLinkCoupon: state.pendingDeepLinkCoupon,
        pendingLoyaltyCoupons: state.pendingLoyaltyCoupons,
      }

    case getType(rootActions.logout):
    case getType(rootActions.placeOrderSuccess):
    case getType(rootActions.appStartedForKioskWeb):
      return {
        ...state,
        ...basketInitState,
        pending: false,
        error: undefined,
        pendingCoupons: [],
      }
    case getType(rootActions.restartOrder):
      return {
        ...state,
        ...basketInitState,
        pending: false,
        error: undefined,
        pendingDeepLinkCoupon: state.pendingDeepLinkCoupon,
        pendingCoupons: [],
      }

    case getType(rootActions.replaceBasketLine):
      const removeProductSignature = getProductItemSignature(action.payload.remove)
      const replacedLines = addItemsToBasket(
        removeItemFromBasket(state.basket.lines, removeProductSignature),
        [action.payload.add],
        action.payload.voucherNo,
        action.payload.voucherItemNo,
      )

      return {
        ...state,
        basket: { ...state.basket, lines: replacedLines },
        pending: true,
      }

    case getType(rootActions.removeLineFromBasket):
      const lines = removeItemFromBasket(state.basket.lines, getProductItemSignature(action.payload))
      const newBasket = {
        ...state.basket,
        lines,
        total: lines.length === 0 ? 0 : state.basket.total,
      }

      return {
        ...state,
        basket: newBasket,
        pending: true,
      }

    case getType(rootActions.validateBasket.failure):
      return getValidateBasketFailureState(state, action)

    case getType(rootActions.addCoupons):
      return {
        ...state,
        basket: { ...state.basket, total: null },
        pending: true,
        pendingCoupons: [...state.pendingCoupons, ...action.payload],
      }

    case getType(rootActions.storeDeepLinkCoupon):
      return {
        ...state,
        pendingDeepLinkCoupon: action.payload,
      }

    case getType(rootActions.addDeepLinkCoupon):
      return {
        ...state,
        pending: true,
        pendingDeepLinkCoupon: null,
        pendingCoupons: [...state.pendingCoupons, state.pendingDeepLinkCoupon as string],
      }

    case getType(rootActions.addLoyaltyCoupon):
      const pendingLoyaltyCoupons = state.pendingLoyaltyCoupons + 1

      return {
        ...state,
        pendingLoyaltyCoupons,
        pending: true,
      }

    case getType(rootActions.basketFatal):
      return { ...state, basket: { ...state.basket, id: '' } }

    case getType(rootActions.checkoutFromNativeApp):
      return { ...state, basket: { ...state.basket, id: action.payload.basketId } }

    case getType(rootActions.clearError):
      return {
        ...state,
        error: undefined,
      }

    case getType(rootActions.replaceBasket):
      return {
        ...state,
        basket: action.payload,
      }

    case getType(rootActions.addHardUpsellOrderId):
      return {
        ...state,
        hardUpsellOrderId: action.orderId,
      }

    default:
      return state
  }
}
