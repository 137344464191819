import { TFunction } from 'react-i18next'

export type CreateProductBuildYourOwnViewProps = {
  testID: string
  viewModel: ProductBuildYourOwnViewModel
  onCreateHalfHalfClick: () => void
  onCreateQuattroClick: () => void
  onToggleExpanded?: (expanded: boolean) => void
}

type CreateProductBuildYourOwnViewModelProps = {
  t: TFunction<'menu'>
  color: string
  isCreateQuattroEnabled: boolean
  isCreateHalfHalfEnabled: boolean
}

type ProductBuildYourOwnViewModel = {
  buildYourOwnTitle: string
  createHalfHalfText: string
  createQuattroText: string
  color: string
  isCreateQuattroEnabled: boolean
  isCreateHalfHalfEnabled: boolean
}

export const createProductBuildYourOwnViewModel = ({
  t,
  color,
  isCreateQuattroEnabled,
  isCreateHalfHalfEnabled,
}: CreateProductBuildYourOwnViewModelProps): ProductBuildYourOwnViewModel => {
  const buildYourOwnTitle = t('ProductCard.BuildYourOwn')
  const createHalfHalfText = t('ProductCard.CreateHalfHalf')
  const createQuattroText = t('ProductCard.CreateQuattro')

  return {
    buildYourOwnTitle,
    createHalfHalfText,
    createQuattroText,
    color,
    isCreateQuattroEnabled,
    isCreateHalfHalfEnabled,
  }
}
