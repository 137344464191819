import React from 'react'
import { useTranslation } from 'react-i18next'

import { CHARITY_SURCHARGE_CODE } from '@dominos/components/charity/round-up/use-round-up-for-charity'
import { useDominosTheme, useFeatures } from '@dominos/hooks-and-hocs'
import { DetailRow } from '../../basket/detail-row'
import { OrderAdjustments } from '../../basket/order-adjustments/order-adjustments'
import { OrderSubtotal } from '../../basket/order-subtotal/order-subtotal'
import { OrderTaxes } from '../../basket/order-taxes/order-taxes'
import Surcharges from '@dominos/components/basket/surcharges/surcharges'

interface OrderTotalProps {
  basket: Bff.Orders.OrderDetails.Basket
  roundUpForCharityEnabled?: boolean
  driverTip?: number
}

const OrderTotal = ({ basket, roundUpForCharityEnabled, driverTip }: OrderTotalProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('menu')
  const [showOrderTaxes] = useFeatures('ShowOrderTaxes')
  const { surcharges, total, totalDiscount } = basket

  const charitySurcharge = surcharges.find((surcharge: BasketSurcharge) => surcharge.code === CHARITY_SURCHARGE_CODE)
  const displayTotal = roundUpForCharityEnabled && charitySurcharge ? total - charitySurcharge.price : total

  const filteredSurcharges = !roundUpForCharityEnabled
    ? surcharges
    : surcharges.filter((surcharge: BasketSurcharge) => surcharge.code !== CHARITY_SURCHARGE_CODE)

  const adjustments = basket.adjustments || []
  const taxes = basket.taxes || []
  const shouldShowOrderTaxDetails = !!basket.priceExcludingTaxes && !!taxes?.length && showOrderTaxes

  return (
    <>
      {filteredSurcharges && <Surcharges testID={`order-total.surcharges`} surcharges={filteredSurcharges} />}
      {driverTip && (
        <DetailRow
          testID={`order-total.driver-tip-value`}
          label={t('DRIVER TIP')}
          price={driverTip}
          textStyle={{ color: theme.colours.dominosSlate }}
        />
      )}
      {shouldShowOrderTaxDetails && <OrderSubtotal testId={'order-total'} amount={basket.priceExcludingTaxes || 0} />}
      {shouldShowOrderTaxDetails && <OrderTaxes testId={'order-total'} taxes={taxes} />}
      {!!adjustments?.length && <OrderAdjustments testId={'order-total'} adjustments={adjustments} />}
      <DetailRow testID={`order-total.total-value`} label={t('TOTAL')} price={displayTotal} />
      {totalDiscount > 0 && (
        <DetailRow
          testID={`order-total.discount-value`}
          label={t('YOU SAVED')}
          price={totalDiscount}
          textStyle={{ color: theme.colours.actionStandard }}
        />
      )}
    </>
  )
}

export { OrderTotal }
