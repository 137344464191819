import gql from 'graphql-tag'
import { mapCustomerAddressToAddress } from '@dominos/business/functions/address/address-mapping'

export const addressSearchQuery = gql`
  query addressSearchQuery(
    $buildingName: String
    $floorNo: String
    $unitNo: String
    $streetNo: String
    $streetName: String
    $suburb: String
    $postCode: String!
  ) {
    deliverySearch(
      buildingName: $buildingName
      floorNo: $floorNo
      unitNo: $unitNo
      streetNo: $streetNo
      streetName: $streetName
      suburb: $suburb
      postCode: $postCode
    ) {
      storeNo
      media {
        displayAddress
      }
      rawAddress {
        buildingName
        unitNo
        streetNo
        street
        suburb
        postCode
        state
        floorNo
      }
    }
  }
`

export const generateBatchAddressSearchQuery = (count: number) => {
  if (count === 0) {
    return gql(`
    query bulkAddressSearchQuery(
      $buildingName: String
      $floorNo: String
      $unitNo: String
      $streetNo: String
      $streetName: String
      $suburb: String
      $postCode: String!
    ) {
      query: deliverySearch(
        buildingName: $buildingName
        floorNo: $floorNo
        unitNo: $unitNo
        streetNo: $streetNo
        streetName: $streetName
        suburb: $suburb
        postCode: $postCode
      ) {
        storeNo
      }
    }`)
  }

  let params = ''
  let query = ''

  for (let i = 0; i < count; ++i) {
    params += `
      $buildingName${i}: String
      $floorNo${i}: String
      $unitNo${i}: String
      $streetNo${i}: String
      $streetName${i}: String
      $suburb${i}: String
      $postCode${i}: String!`

    query += `
      query${i}: deliverySearch(
        buildingName: $buildingName${i}
        floorNo: $floorNo${i}
        unitNo: $unitNo${i}
        streetNo: $streetNo${i}
        streetName: $streetName${i}
        suburb: $suburb${i}
        postCode: $postCode${i}
      ) {
        storeNo
        rawAddress {
          buildingName
          unitNo
          streetNo
          street
          suburb
          postCode
          state
          floorNo
        }
      }`
  }

  return gql(`
    query bulkAddressSearchQuery(
      ${params}
    ) {
      ${query}
    }
  `)
}

export const generateBatchAddressSearchVariables = (
  addresses: (Bff.Customers.CustomerAddress | StoredCustomerAddress)[],
  countryCode: BffContext.Countries | undefined,
) => {
  if (addresses.length === 0) {
    return {
      streetName: '',
      postCode: '',
    }
  }
  return addresses
    .map((address) => {
      if (address.hasOwnProperty('customerAddress')) {
        const { customerAddress, ...rest } = address as unknown as StoredCustomerAddress
        return {
          ...rest,
          address: mapCustomerAddressToAddress(customerAddress),
        } as unknown as Bff.Customers.CustomerAddress
      } else {
        return address as Bff.Customers.CustomerAddress
      }
    })
    .map(({ address }, i) => {
      switch (countryCode) {
        case 'JP':
          return {
            [`streetName${i}`]: address?.street,
            [`postCode${i}`]: address?.postCode,
          }
        case 'SG':
          return {
            [`postCode${i}`]: address?.postCode,
          }
        default:
          return {
            [`unitNo${i}`]: address?.unitNo,
            [`streetNo${i}`]: address?.streetNo,
            [`streetName${i}`]: address?.street,
            [`suburb${i}`]: address?.suburb,
            [`postCode${i}`]: address?.postCode,
          }
      }
    })
    .reduce((prev, curr) => ({ ...prev, ...curr }), {})
}
