import { CountrySpecificAddressRequirementsMapping } from './address-requirements.interface'

export const addressRequirementMappingsByCountry: CountrySpecificAddressRequirementsMapping = {
  AU: [],
  FR: [],
  DE: [],
  JP: [],
  LU: [],
  NL: [
    {
      attribute: 'streetNo',
      validationRule: ['isRequired', 'manual'],
    },
    {
      attribute: 'unitNo',
      validationRule: ['manual', 'additional'],
    },
  ],
  NZ: [],
  BE: [
    {
      attribute: 'streetNo',
      validationRule: ['isRequired', 'manual'],
    },
    {
      attribute: 'unitNo',
      validationRule: ['manual', 'additional'],
    },
  ],
  DK: [],
  SG: [],
  TW: [],
  MY: [],
  KH: [],
}
