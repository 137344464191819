import { useEffect, useMemo, useRef, useState } from 'react'
import { OrderStatus } from '../order-types'
import useSimpleOrderStatus from './use-simple-order-status'

const INTERVAL = 30 * 1000
const THIRTY_MINUTES = 1800000
const READY_STATES = [OrderStatus.Making, OrderStatus.Cooking, OrderStatus.Ready, OrderStatus.Remaking]
const COMPLETE_STATES = [OrderStatus.Complete, OrderStatus.Cancelled]

export const useReadyToNotify = (id: string) => {
  const [hasExtendedTimer, setHasExtendedTimer] = useState(false)
  const completionTimer = useRef<NodeJS.Timeout | null>()
  const { orderStatus, stopPolling, startPolling } = useSimpleOrderStatus(id)

  useEffect(() => {
    startPolling(INTERVAL)

    return () => {
      if (completionTimer.current) {
        clearTimeout(completionTimer.current)
      }

      stopPolling()
    }
  }, [])

  useEffect(() => {
    if (!orderStatus) return

    if (orderStatus.status === OrderStatus.Complete) {
      createCompletionTimer(orderStatus.timeElapsed)
    }

    if (COMPLETE_STATES.includes(orderStatus.status)) {
      stopPolling()
    }
  }, [orderStatus])

  const createCompletionTimer = (timeElapsed: number) => {
    const duration = Math.max(THIRTY_MINUTES - timeElapsed, 0)
    if (completionTimer.current) {
      clearTimeout(completionTimer.current)
    }

    setHasExtendedTimer(!!duration)

    completionTimer.current = setTimeout(() => {
      setHasExtendedTimer(false)
    }, duration)
  }

  const isReady = useMemo(
    () => (orderStatus && READY_STATES.includes(orderStatus.status)) || hasExtendedTimer,
    [orderStatus, hasExtendedTimer],
  )
  const hasNotified = useMemo(() => (orderStatus && orderStatus.hasNotifiedStore) || false, [orderStatus])

  return {
    isReady,
    hasNotified,
  }
}
