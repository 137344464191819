import React from 'react'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { Spinner } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'

import cssList from './list-group.less'

interface ListGroupCollectionLineSuffixProps extends BaseProps {
  /**
   * Either the distance (in km) from the users location to the store, or 'caret' if geolocation is declined.
   */
  content: 'caret' | number
  isLoading?: boolean
}

export const ListGroupCollectionLineSuffix = ({ testID, content, isLoading }: ListGroupCollectionLineSuffixProps) => {
  const theme = useDominosTheme()

  const renderItem = () => {
    if (isLoading) {
      return <Spinner testID={`${testID}.spinner`} color={theme.colours.lightSlate} />
    }

    if (content === 'caret') {
      return (
        <div data-testid={`${testID}.caret`} className={cssList.suffix}>
          <StaticSvgIcon name='chevron' isUnstyled fill={theme.colours.overlayColor} />
        </div>
      )
    }

    return (
      <p className={cssList.storeDetailsDistance} data-testid={`${testID}.distance`}>
        {`${content.toFixed(1)} KM`}
      </p>
    )
  }

  return <div className={cssList.suffixContainer}>{renderItem()}</div>
}
