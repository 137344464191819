import { RoundUpForCharityDetails } from '@dominos/components/charity/round-up/use-round-up-for-charity'
import { OrderStatus } from '@dominos/hooks-and-hocs/order'
import { MyDetailsModelProps, OrderHistoryProps, PaymentDetails, SaveOrderProps } from '@dominos/interfaces'
import { createStandardAction } from 'typesafe-actions'

export const saveCustomerDetails = createStandardAction('Checkout:SaveCustomerDetails')<MyDetailsModelProps>()
export const clearCustomerDetails = createStandardAction('Checkout:ClearCustomerDetails')()
export const placeOrderSuccess = createStandardAction('PLACEORDER:Success')()
export const placeOrderFailed = createStandardAction('PLACEORDER:Failed')()
export const resetOrder = createStandardAction('PLACEORDER:reset')()
export const updatePaymentMethod = createStandardAction('PAYMENTMETHOD:UpdatePaymentMethod')<PaymentDetails>()
export const setTransactionToken = createStandardAction('PAYMENT:SetTransactionToken')<string | undefined>()
export const setEdenredAuthCode = createStandardAction('PAYMENT:SetEdenredAuthCode')<string | undefined>()
export const setOrderPaymentId = createStandardAction('PAYMENT:setOrderPaymentId')<string>()
export const setOrderStatus = createStandardAction('ORDER:setOrderStatus')<OrderStatus | undefined>()
export const setOrderTime = createStandardAction('ORDER:setOrderTime')()
export const setOfferToShown = createStandardAction('OFFER:SetOfferToShown')<string>()
export const saveOrder = createStandardAction('ORDER:SAVE_ORDER')<SaveOrderProps>()
export const saveOrderToLocalState = createStandardAction('ORDER:SAVE_ORDER_TO_STATE')<Bff.Orders.Order>()
export const orderFlowSuccess = createStandardAction('ORDER:Placed')<OrderHistoryProps>()
export const setRoundUpForCharityDetails = createStandardAction(
  'ORDER:SetRoundUpForCharityDetails',
)<RoundUpForCharityDetails>()
export const updateRoundUpForCharityDetails = createStandardAction('ORDER:UpdateRoundUpForCharityDetails')<{
  amount: number
}>()
export const removeRoundUpForCharityDetails = createStandardAction('ORDER:RemoveRoundUpForCharityDetails')()
export const setTipAmount = createStandardAction('ORDER:SetTipAmount')<number>()
export const setRequireExtraChange = createStandardAction('ORDER:SetRequireExtraChange')<boolean>()
export const setIsInitiatingOrder = createStandardAction('ORDER:SetIsInitiatingOrder')<boolean>()
