import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions/validation'
import { ValidationTextField } from '@dominos/components'

const unitNoMaxLength = 9
const streetNoMaxLength = 9
const postCodeLength = 4

export const DeliveryAddressNzLayout = (props: DeliveryAddressLayoutBaseProps) => {
  const { t } = useTranslation('delivery-address')
  const { equalLength, isNumeric, isRequired, maxLength } = useValidation()

  useEffect(() => {
    props.onInitialise({
      unitNo: {
        isValid: true,
      },
      streetNo: {
        isValid: false,
      },
      street: {
        isValid: false,
      },
      suburb: {
        isValid: false,
      },
      postCode: {
        isValid: true,
      },
    })
  }, [])

  return (
    <>
      <ValidationTextField
        key='unitNo'
        fieldName='unitNo'
        placeholder={t('Unit Number')}
        testID={'unitNo.field'}
        showAsteriskWhenRequired
        validationRules={[maxLength(unitNoMaxLength)]}
        style={{ width: '50%', paddingRight: '4px' }}
        onChange={props.onChange}
      />
      <ValidationTextField
        key='streetNo'
        fieldName='streetNo'
        placeholder={t('Street Number')}
        testID={'streetNo.field'}
        showAsteriskWhenRequired
        validationRules={[isRequired, maxLength(streetNoMaxLength)]}
        style={{ width: '50%', paddingLeft: '4px' }}
        onChange={props.onChange}
      />
      <ValidationTextField
        key='street'
        fieldName='street'
        placeholder={t('Street')}
        testID={'street.field'}
        showAsteriskWhenRequired
        validationRules={[isRequired]}
        style={{ width: '100%' }}
        onChange={props.onChange}
      />
      <ValidationTextField
        key='suburb'
        fieldName='suburb'
        placeholder={t('Suburb')}
        testID={'suburb.field'}
        showAsteriskWhenRequired
        validationRules={[isRequired]}
        style={{ width: '50%', paddingRight: '4px' }}
        onChange={props.onChange}
      />
      <ValidationTextField
        key='postCode'
        fieldName='postCode'
        placeholder={t('Post Code')}
        testID={'postCode.field'}
        showAsteriskWhenRequired
        validationRules={[isNumeric, equalLength(postCodeLength)]}
        style={{ width: '50%', paddingLeft: '4px' }}
        onChange={props.onChange}
      />
    </>
  )
}
