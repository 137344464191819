import { HomeContainer, SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import React from 'react'
import { useTriggerAuthPopup } from './trigger-auth-popup/use-trigger-auth-popup'

export const HomeScene = ({ showAuthPopup }: MenuSceneProps) => {
  useTriggerAuthPopup(showAuthPopup)

  return (
    <StackNavigationBase testID='home-scene' header={<SimpleNavigationTitle showAccount />}>
      <HomeContainer />
    </StackNavigationBase>
  )
}
