import { formatProductCode } from './format-name'

type VarietyItemDependents = Bff.Menu.old.VarietyItemDependents
type VarietyMenuItem = Bff.Menu.old.VarietyMenuItem

interface Variety {
  name: string
  code: string
  items: VarietyItemDependents[]
}
interface VarietyParent {
  sectionName: string
  sectionCode: string
  varietyItems: Variety[]
}

export interface FormattedVarietyItem {
  title: string | null
  code: string | null
  data: VarietyItemDependents[] | null
}

/**
 * Returns all Variety parent products (unformatted) from a list of products.
 *
 * @param products
 * @returns Variety parent products (unformatted)
 */
const getVarietyParents = (products: readonly MenuItemDependents[]) => {
  const filtered = products.filter((product) => product.type === 'Variety') as VarietyMenuItem[]

  return filtered.map((product) => ({
    name: product.media ? product.media.name : '',
    code: product.code,
    items: product.items,
  }))
}

/**
 * Combines all the VarietyItemDependents from a list of VarietyParent products.
 *
 * @param varietyItems list of VarietyParent products (eg. Dipping Sauces)
 * @returns list of variety products (eg. Aioli and Ranch Dipping Sauce)
 */
const flattenVarietyItems = (varietyItems?: VarietyParent[] | null) => {
  let memo: Variety[] = []

  if (!varietyItems) {
    return null
  }

  if (varietyItems) {
    varietyItems.forEach((innerItem) => {
      memo = [...memo, ...innerItem.varietyItems]
    })
  }

  return memo
}

/**
 * Returns all the variety products that match `varietyCode` within `varietyParent`.
 *
 * @param varietyCode eg. 'DippingSauce' text
 * @param varietyParent eg. `DippingSauce` object which contains child variety products
 */
const getVarietyFromCode = (varietyCode: string, varietyParent?: VarietyParent[]) => {
  if (!varietyParent) {
    return null
  }

  const flattened = flattenVarietyItems(varietyParent)

  return flattened && flattened.filter((item) => formatProductCode(item.code) === varietyCode)
}

/**
 * Converts variety product into format for `FlatList`.
 *
 * @param variety
 * @returns variety in `FlatList` format
 */
const formatVariety = (variety?: '' | Variety[] | null) => {
  if (!variety) {
    return null
  }

  return [
    {
      title: variety && variety.length > 0 ? variety[0].name : null,
      code: variety && variety.length > 0 ? variety[0].code : null,
      data: variety && variety.length > 0 ? variety[0].items : null,
    } as FormattedVarietyItem,
  ]
}

/**
 * Gets all the variety products within the supplied category.
 *
 * @param currentCategory
 * @returns a list of `VarietyParents` | all the child products within a `VarietyProduct`
 */
export const getVarieties = (currentCategory?: ResolvedMenuSection[] | null) => {
  const varieties: VarietyParent[] = []

  if (!currentCategory) {
    return null
  }

  currentCategory.forEach((section) => {
    const varietyItems = getVarietyParents(section.data)

    if (varietyItems.length === 0) {
      return null
    }

    return varieties.push({
      varietyItems,
      sectionName: section.title,
      sectionCode: section.code,
    })
  })

  return {
    /**
     * Returns all variety parent products (formatted) from the current category.
     */
    varieties,
    /**
     * Returns all the variety products that match `varietyCode` (eg. 'DippingSauce') within the current category.
     */
    getFromCode: (code?: string) => (code ? formatVariety(getVarietyFromCode(code, varieties)) : null),
  }
}
