import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const PastaIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon
          id='path-1'
          points='-4.4408921e-16 -1.77635684e-15 20 -1.77635684e-15 20 6.9997 -4.4408921e-16 6.9997'
        />
        <polygon id='path-3' points='0 3.55271368e-15 20.0512108 3.55271368e-15 20.0512108 11.998 0 11.998' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-13' transform='translate(0.000000, 0.000000)'>
          <path
            d='M23,12.998 L22.002,12.998 L22,12.998 L2,12.998 L1.998,12.998 L1,12.998 C0.448,12.998 0,13.446 0,13.998 C0,14.551 0.448,14.998 1,14.998 L1.35,14.998 L22.65,14.998 L23,14.998 C23.552,14.998 24,14.551 24,13.998 C24,13.446 23.552,12.998 23,12.998'
            id='Fill-1'
            fill={fill}
          />
          <g id='Group-5' transform='translate(1.999600, 16.998300)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-4' />
            <path
              d='M2.07,4.656 C2.703,6.08 4.118,7 5.676,7 L14.325,7 C15.883,7 17.298,6.08 17.931,4.656 L20,-1.77635684e-15 L-4.4408921e-16,-1.77635684e-15 L2.07,4.656 Z'
              id='Fill-3'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <g id='Group-8' transform='translate(2.000000, 0.000000)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-7' />
            <path
              d='M1,11.998 C1.553,11.998 2,11.55 2,10.998 C2,8.792 3.794,6.998 6,6.998 C7.035,6.998 8,7.413 8.74,8.117 C8.269,8.972 8,9.954 8,10.998 C8,11.55 8.447,11.998 9,11.998 C9.553,11.998 10,11.55 10,10.998 C10,8.792 11.794,6.998 14,6.998 C16.206,6.998 18,8.792 18,10.998 C18,11.55 18.447,11.998 19,11.998 C19.553,11.998 20,11.55 20,10.998 C20,9.031 19.044,7.287 17.578,6.192 L19.314,4.409 C20.551,3.14 20.18,1.032 18.585,0.261 C16.992,-0.51 15.111,0.505 14.881,2.259 L14.517,5.024 C14.346,5.009 14.175,4.998 14,4.998 C12.463,4.998 11.064,5.584 10.001,6.537 C8.907,5.555 7.494,4.998 6,4.998 C2.691,4.998 0,7.689 0,10.998 C0,11.55 0.447,11.998 1,11.998'
              id='Fill-6'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
          <path
            d='M15.9951,7.998 C14.3421,7.998 12.9961,9.343 12.9961,10.997 C12.9961,11.549 13.4431,11.997 13.9961,11.997 C14.5491,11.997 14.9961,11.549 14.9961,10.997 C14.9961,10.446 15.4441,9.998 15.9951,9.998 C16.5461,9.998 16.9931,10.446 16.9931,10.997 C16.9931,11.549 17.4401,11.997 17.9931,11.997 C18.5461,11.997 18.9931,11.549 18.9931,10.997 C18.9931,9.343 17.6481,7.998 15.9951,7.998'
            id='Fill-9'
            fill={fill}
          />
          <path
            d='M4.9883,10.9965 C4.9883,11.5485 5.4353,11.9965 5.9883,11.9965 C6.5413,11.9965 6.9883,11.5485 6.9883,10.9965 C6.9883,10.4455 7.4363,9.9975 7.9873,9.9975 C8.5403,9.9975 8.9873,9.5505 8.9873,8.9975 C8.9873,8.4455 8.5403,7.9975 7.9873,7.9975 C6.3343,7.9975 4.9883,9.3435 4.9883,10.9965'
            id='Fill-11'
            fill={fill}
          />
        </g>
      </g>
    </svg>
  )
}
