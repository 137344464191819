import { getFullUrl } from '@dominos/business/functions/menu/get-images'
import { DevToggles, useDevToggles, useDominosTheme } from '@dominos/hooks-and-hocs'
import { usePopup } from '@dominos/hooks-and-hocs/offers'
import { useParams } from '@reach/router'
import { BannerInteractionDetails } from '@dominos/hooks-and-hocs/logging/report-event-banners'
import React, { useState } from 'react'
import OfferPopup from '../offer-popup'
import css from './banner-offer.less'
import { useReport } from '@dominos/hooks-and-hocs/logging/report'

interface BannerOfferProps extends BaseProps {
  offer: Bff.Offers.Offer
}

const isPopupOffer = (offer: Bff.Offers.Offer, locationCode: string) => {
  const location = offer.locations.find((l) => l.locationCode.toLowerCase() === locationCode.toLowerCase())

  return location?.showPopUp
}

const getImageUrl = (offer: Bff.Offers.Offer, isEnabled: DevToggles) =>
  offer.media.banner.url
    ? isEnabled['test-offers-images']
      ? `https://internetorder.dominostest.co.nz${offer.media.banner.url}`
      : getFullUrl(offer.media.banner.url)
    : null

export const BannerOffer = ({ offer, testID }: BannerOfferProps) => {
  const theme = useDominosTheme()
  const [imageLoaded, setImageLoaded] = useState(false)
  const { reportBannerInteraction } = useReport()
  const { isEnabled } = useDevToggles()
  const { isShowPopup, openPopup, closePopup, acceptItems } = usePopup(offer)

  const params = useParams()
  const locationCode = `Menu.${params.menuCategory || 'Pizza'}`

  const styles = {
    image: {
      borderRadius: theme.webBorderRadius,
    },
  }

  const onImageLoaded = () => {
    setImageLoaded(true)
  }

  const handleClick = () => {
    if (isPopupOffer(offer, locationCode)) {
      handleOpenPopup()
    } else {
      acceptItems(offer.items)
    }
  }

  const bannerInteractionDetails: BannerInteractionDetails = {
    elementType: 'OLO Banner Offers',
    elementId: offer.offerId,
    elementName: offer.media.name,
    elementSource: 'User clicked on the banner',
    interactionType: '',
  }

  const handleOpenPopup = () => {
    const openPopupDetails: BannerInteractionDetails = {
      ...bannerInteractionDetails,
      interactionType: 'Clicked on the banner',
    }
    openPopup()
    reportBannerInteraction(openPopupDetails)
  }

  const handleAcceptItems = (items: Bff.Offers.Item[], interactionType: string) => {
    const acceptItemsDetails: BannerInteractionDetails = {
      ...bannerInteractionDetails,
      interactionType: interactionType,
      items: items,
    }
    acceptItems(items)
    reportBannerInteraction(acceptItemsDetails)
  }

  const handleClosePopup = (interactionType: string) => {
    const closePopupDetails: BannerInteractionDetails = {
      ...bannerInteractionDetails,
      interactionType: interactionType,
    }
    closePopup()
    reportBannerInteraction(closePopupDetails)
  }

  const imageUrl = getImageUrl(offer, isEnabled)

  if (!imageUrl) {
    return null
  }

  return (
    <>
      <div data-testid={testID} role='banner' onClick={handleClick}>
        <img
          data-testid={`${testID}-${offer.offerId}`}
          src={imageUrl}
          alt={offer.media.banner.altText ?? ''}
          className={!imageLoaded ? css.hidden : css.image}
          style={imageLoaded ? styles.image : {}}
          onLoad={onImageLoaded}
        />
      </div>
      {isShowPopup && (
        <OfferPopup offer={offer} show={isShowPopup} onAccept={handleAcceptItems} onClose={handleClosePopup} />
      )}
    </>
  )
}
