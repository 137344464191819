/**
 * Determines if the toggle more items button should display
 * @param currentItemIndex Current row index
 * @param visibleItems Number of visible items
 * @param totalItems Number of total possible items
 * @param maxContractedItems Maximum number of contracted items
 * @returns Returns true when the index is the last row item,
 * and total number of items exceed the max contracted items
 */
export const shouldDisplayToggleButton = (
  currentItemIndex: number,
  visibleItems: number,
  totalItems: number,
  maxContractedItems: number,
) => totalItems > maxContractedItems && currentItemIndex === visibleItems
