import React from 'react'
import css from './strike-through.less'

interface StrikeThroughProps extends BaseProps {
  text: React.ReactNode
}

export const StrikeThrough = ({ testID, text }: StrikeThroughProps) => (
  <>
    {text && (
      <span className={css.strikeThrough} data-testid={testID}>
        {text}
      </span>
    )}
  </>
)
