import gql from 'graphql-tag'

export const savedVouchersQuery = gql`
  query savedVouchers {
    savedVouchers {
      voucherCode
      type
      value
      expiryDate
      firstSeenDate
      displayExpiryDate
      conditions {
        forPickup
        forDelivery
        isRestrictedUse
        restrictions {
          remainingUses
        }
      }
      media {
        name
        description
        disclaimer
      }
    }
  }
`
