import React, { useMemo } from 'react'
import { ValidationTextField } from '@dominos/components'
import { getAddressComponentFromDeliveryAddress } from '@dominos/business/functions/address/autocomplete'

export const AdditionalField = ({
  testID,
  fieldName,
  placeholder,
  validationRules,
  address,
  initialValue,
  onChange,
  disableWhenHasValue,
  hideWhenHasValue,
  containerStyle,
}: AdditionalFieldProps) => {
  const addressFieldValue = useMemo(
    () => getAddressComponentFromDeliveryAddress(address, fieldName)?.value,
    [address, fieldName],
  )

  const showDisabled = useMemo(
    () => (disableWhenHasValue ?? false) && !!addressFieldValue,
    [address, addressFieldValue],
  )

  const dontShowField = useMemo(
    () => (hideWhenHasValue ?? false) && !!addressFieldValue,
    [addressFieldValue, disableWhenHasValue],
  )

  if (dontShowField) return <></>

  return (
    <ValidationTextField
      testID={`${testID}.${fieldName}`}
      fieldName={fieldName}
      placeholder={placeholder}
      hasClearInputButton={!showDisabled}
      hideEmptyErrorContainer
      onChange={onChange}
      validationRules={validationRules}
      /* If the initial value is _undefined_ in this component, and there's no match of `fieldName` in getAddressComponentFromDeliveryAddress 
        (which is derived from Google's structure?), the AdditionalField component will not result in an AddressComponent object being added for
        this field to the containing address object, and therefore it will not be beholden to the validation rules. Setting an empty initial value
        fixes this.
        */
      initialValue={addressFieldValue ?? initialValue}
      style={containerStyle ?? { width: '100%' }}
      showAsteriskWhenRequired={true}
      disabled={showDisabled}
    />
  )
}
