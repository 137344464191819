/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions/validation'
import { ValidationTextField } from '@dominos/components'
import { GenericSelect } from '@dominos/components/select/generic-select'
import {
  MYDeliveryAddressOnChange,
  MYDeliveryAddressOnInitialise,
} from '@dominos/components/delivery-address/delivery-address-container-my'

const fieldMaxLength = 40
const SELECT_HEIGHT = 44
const postCodeLength = 6

interface MYDeliveryAddressLayoutBaseProps {
  onChange: MYDeliveryAddressOnChange
  onInitialise: MYDeliveryAddressOnInitialise
}

const addressFieldTypeOptions = [
  {
    optionLabel: 'landed',
    optionValue: 'Landed',
    addressFieldNames: ['buildingName'],
  },
  {
    optionLabel: 'nonLanded',
    optionValue: 'Non-Landed',
    addressFieldNames: ['unitNo', 'floorNo', 'buildingName'],
  },
]

const streetTypeOptions = [
  {
    label: 'Jalan',
    value: 'jln',
  },
  {
    label: 'Lorong',
    value: 'lrg',
  },
  {
    label: 'Changkat',
    value: 'ckt',
  },
  {
    label: 'Pinggiran',
    value: 'pgr',
  },
  {
    label: 'Medan',
    value: 'mdn',
  },
  {
    label: 'Solok',
    value: 'slk',
  },
  {
    label: 'Persiaran',
    value: 'psn',
  },
  {
    label: 'Leboh',
    value: 'lbh',
  },
  {
    label: 'Simpangan',
    value: 'spn',
  },
  {
    label: 'Selekoh',
    value: 'skh',
  },
  {
    label: 'Pintasan',
    value: 'pts',
  },
  {
    label: 'Persisiran',
    value: 'pss',
  },
  {
    label: 'Perkarangan',
    value: 'pkn',
  },
  {
    label: 'Pengkalan',
    value: 'pkn',
  },
  {
    label: 'Lurah',
    value: 'lrh',
  },
  {
    label: 'Lintang',
    value: 'ltg',
  },
  {
    label: 'Lingkungan',
    value: 'lkn',
  },
  {
    label: 'Lingkaran',
    value: 'lkr',
  },
  {
    label: 'Lengkung',
    value: 'lkg',
  },
  {
    label: 'Lengkok',
    value: 'lkk',
  },
  {
    label: 'Lebuhraya',
    value: 'lbr',
  },
  {
    label: 'Lebuh',
    value: 'lbh',
  },
  {
    label: 'Langgak',
    value: 'lgk',
  },
  {
    label: 'Laman',
    value: 'lmn',
  },
  {
    label: 'Kampung',
    value: 'kg',
  },
  {
    label: 'Halaman',
    value: 'hln',
  },
  {
    label: 'Gerbang',
    value: 'grb',
  },
  {
    label: 'Dataran',
    value: 'dtn',
  },
  {
    label: 'Bulatan',
    value: 'btn',
  },
  {
    label: 'Laluan',
    value: 'lln',
  },
  {
    label: 'Tingkat',
    value: 'tkt',
  },
  {
    label: 'Other',
    value: '',
  },
]

export const DeliveryAddressMyLayout = (props: MYDeliveryAddressLayoutBaseProps) => {
  const { t } = useTranslation('delivery-address')
  const { isNumeric, isRequired, maxLength } = useValidation()
  const [selectedAddressType, setSelectedAddressType] = useState<string | undefined>()
  const [selectedStreetType, setSelectedStreetType] = useState<string>(streetTypeOptions[0].value)

  useEffect(() => {
    props.onInitialise({
      buildingName: {
        isValid: selectedAddressType === 'Landed',
      },
      streetNo: {
        isValid: selectedAddressType === 'Non-Landed',
      },
      street: {
        isValid: false,
      },
      streetType: {
        value: selectedStreetType,
        isValid: true,
      },
      suburb: {
        isValid: false,
      },
      postCode: {
        isValid: true,
      },
    })
  }, [selectedAddressType])

  const selectOptions: GenericSelectItem[] | undefined = addressFieldTypeOptions.map((addressOptions) => ({
    label: t(addressOptions.optionLabel),
    value: addressOptions.optionValue,
  }))

  const onSelectChange = (value: string) => {
    setSelectedAddressType(value)
  }

  const onStreetTypeChange = (value: string) => {
    setSelectedStreetType(value)
    props.onChange({ streetType: { value: value, isValid: true } })
  }

  return (
    <>
      <div style={{ marginBottom: '15px' }}>
        <GenericSelect
          testID={'address-types.selector'}
          options={selectOptions}
          placeholder={t('addressType')}
          selectedValue={selectedAddressType}
          height={SELECT_HEIGHT}
          onChange={onSelectChange}
        />
      </div>
      {selectedAddressType === 'Landed' && (
        <>
          <div>
            <ValidationTextField
              key='streetNo'
              fieldName='streetNo'
              placeholder={t('Unit Number')}
              testID={'streetNo.field'}
              showAsteriskWhenRequired
              validationRules={[isRequired, maxLength(fieldMaxLength)]}
              style={{ width: '33%', paddingRight: '4px' }}
              onChange={props.onChange}
            />
          </div>
          <div style={{ marginBottom: '15px', display: 'inline-block', width: '33%', paddingRight: '4px' }}>
            <GenericSelect
              testID={'street-type.selector'}
              options={streetTypeOptions}
              height={SELECT_HEIGHT}
              selectedValue={selectedStreetType}
              onChange={onStreetTypeChange}
            />
          </div>
          <ValidationTextField
            key='street'
            fieldName='street'
            placeholder={t('Street')}
            testID={'street.field'}
            showAsteriskWhenRequired
            validationRules={[isRequired]}
            style={{ width: '66%' }}
            onChange={props.onChange}
          />
          <ValidationTextField
            key='buildingName'
            fieldName='buildingName'
            placeholder={t('Building Name')}
            testID={'buildingName.field'}
            showAsteriskWhenRequired
            validationRules={[maxLength(fieldMaxLength)]}
            style={{ width: '100%', paddingRight: '4px' }}
            onChange={props.onChange}
          />
          <ValidationTextField
            key='suburb'
            fieldName='suburb'
            placeholder={t('Suburb')}
            testID={'suburb.field'}
            showAsteriskWhenRequired
            validationRules={[isRequired]}
            style={{ width: '50%', paddingRight: '4px' }}
            onChange={props.onChange}
          />
          <ValidationTextField
            key='postCode'
            fieldName='postCode'
            placeholder={t('Post Code')}
            testID={'postCode.field'}
            showAsteriskWhenRequired
            validationRules={[isNumeric, maxLength(postCodeLength)]}
            style={{ width: '50%', paddingLeft: '4px' }}
            onChange={props.onChange}
          />
        </>
      )}
      {selectedAddressType === 'Non-Landed' && (
        <>
          <div>
            <ValidationTextField
              key='streetNo'
              fieldName='streetNo'
              placeholder={t('Unit Number')}
              showAsteriskWhenRequired
              testID={'streetNo.field'}
              validationRules={[isRequired, maxLength(fieldMaxLength)]}
              style={{ width: '33%', paddingRight: '4px' }}
              onChange={props.onChange}
            />
          </div>
          <ValidationTextField
            key='blockNo'
            fieldName='blockNo'
            placeholder={t('Block Number')}
            testID={'blockNo.field'}
            showAsteriskWhenRequired
            validationRules={[maxLength(fieldMaxLength)]}
            style={{ width: '31%' }}
            onChange={props.onChange}
          />
          <div style={{ margin: '12px 2px', display: 'inline-block' }}>/</div>
          <ValidationTextField
            key='floorNo'
            fieldName='floorNo'
            placeholder={t('Floor number')}
            testID={'floorNo.field'}
            showAsteriskWhenRequired
            validationRules={[maxLength(fieldMaxLength)]}
            style={{ width: '31%' }}
            onChange={props.onChange}
          />
          <div style={{ margin: '12px 2px', display: 'inline-block' }}>/</div>
          <ValidationTextField
            key='unitNo'
            fieldName='unitNo'
            placeholder={t('Unit')}
            testID={'unitNo.field'}
            showAsteriskWhenRequired
            validationRules={[maxLength(fieldMaxLength)]}
            style={{ width: '31%' }}
            onChange={props.onChange}
          />
          <div style={{ marginBottom: '15px', display: 'inline-block', width: '33%', paddingRight: '4px' }}>
            <GenericSelect
              testID={'street-type.selector'}
              options={streetTypeOptions}
              height={SELECT_HEIGHT}
              selectedValue={selectedStreetType}
              onChange={onStreetTypeChange}
            />
          </div>
          <ValidationTextField
            key='street'
            fieldName='street'
            placeholder={t('Street')}
            testID={'street.field'}
            showAsteriskWhenRequired
            validationRules={[isRequired]}
            style={{ width: '66%' }}
            onChange={props.onChange}
          />
          <ValidationTextField
            key='buildingName'
            fieldName='buildingName'
            placeholder={t('Building Name')}
            testID={'buildingName.field'}
            showAsteriskWhenRequired
            validationRules={[isRequired, maxLength(fieldMaxLength)]}
            style={{ width: '100%', paddingRight: '4px' }}
            onChange={props.onChange}
          />
          <ValidationTextField
            key='suburb'
            fieldName='suburb'
            placeholder={t('Suburb')}
            testID={'suburb.field'}
            showAsteriskWhenRequired
            validationRules={[isRequired]}
            style={{ width: '50%', paddingRight: '4px' }}
            onChange={props.onChange}
          />
          <ValidationTextField
            key='postCode'
            fieldName='postCode'
            placeholder={t('Post Code')}
            testID={'postCode.field'}
            showAsteriskWhenRequired
            validationRules={[isNumeric, maxLength(postCodeLength)]}
            style={{ width: '50%', paddingLeft: '4px' }}
            onChange={props.onChange}
          />
        </>
      )}
    </>
  )
}
