const styles: { [k: string]: CommonViewStyle } = {
  button: {
    width: '100%',
    height: '54px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '10px 0',
    cursor: 'pointer',
    backgroundColor: '#efefef',
  },
}

export default styles
