import { AdyenTextField } from '@dominos/components/inputs'
import { useDevToggles } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AdyenPaymentMethodExtended } from './payment-adyen.interface'

interface AdyenAdditionalFieldsProps {
  adyenPaymentMethod: AdyenPaymentMethodExtended
  onChange: ({ target }: React.ChangeEvent<HTMLInputElement>) => void
}

const AdyenAdditionalFields = ({ adyenPaymentMethod, onChange }: AdyenAdditionalFieldsProps) => {
  const { t } = useTranslation('checkout')
  const { isEnabled } = useDevToggles()
  const giftCardHolderNameEnabled = isEnabled['gift-card-holder-name']

  return adyenPaymentMethod.type === 'giftcard' && giftCardHolderNameEnabled ? (
    <AdyenTextField
      testID={`adyen-giftcard-holderName`}
      id='adyen-giftcard-holderName'
      label={t('giftCard-holderName-title', { defaultValue: 'Card Holder Name' })}
      name='holderName'
      onChange={onChange}
      placeholder={t('giftCard-holderName-placeholder', { defaultValue: 'J. Smith' })}
    />
  ) : null
}

export { AdyenAdditionalFields }
