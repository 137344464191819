import React from 'react'
import css from './energy-kj-unit-divider.less'

interface EnergyKJUnitDividerProps extends BaseProps {
  divider?: string
}

export const EnergyKJUnitDivider = ({ divider = '', testID }: EnergyKJUnitDividerProps) => (
  <span data-testid={testID} className={css.divider}>
    {divider}
  </span>
)
