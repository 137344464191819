import React, { useState } from 'react'
import { useApplicationSettings } from '../application/use-application-settings'
import { getCountryTheme } from './country-theme-picker'
import { DominosThemeProps, ThemeContext } from './theme-context'

export const DominosThemeProvider = (props: PropsWithChildren) => {
  const settings = useApplicationSettings()
  const country = settings.countryCode || 'AU'
  const [darkTheme, setDarkThemeVar] = useState(false)
  const [currentheme, setTheme] = useState(getCountryTheme(country, darkTheme))

  const setDarkTheme = (dark: boolean) => {
    setDarkThemeVar(dark)
    setTheme(getCountryTheme(country, dark))
  }

  const theme: DominosThemeProps = {
    theme: currentheme,
    updateTheme: setDarkTheme,
  }

  return <ThemeContext.Provider value={theme}>{props.children}</ThemeContext.Provider>
}
