import RadioInputField from '@dominos/components/inputs/radio-input-field'
import { ServiceMethodSubTypeProps } from '@dominos/interfaces/checkout'
import React from 'react'

export const InStorePickUp = ({
  name,
  value,
  onChecked,
  labelText,
  testID,
  selectedSubType,
  onFormValidationChange,
}: ServiceMethodSubTypeProps) => {
  const handleChecked = () => {
    onChecked(undefined)
    if (onFormValidationChange) {
      onFormValidationChange(true)
    }
  }

  return (
    <RadioInputField
      fieldName={name}
      value={value}
      fieldId={testID}
      onChecked={handleChecked}
      labelText={labelText}
      testID={`${testID}.radio-input-field`}
      selectedOption={selectedSubType || value}
    />
  )
}
