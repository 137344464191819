import { useAuthenticationPopup, useQuickUniversalAccountComponent } from '@dominos/hooks-and-hocs'
import { AuthenticationButtonGroup, CardHeader, NativeAuthenticationButtonGroup } from '@dominos/components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { isNativeApp, LoginSceneMessage, postWebViewMessage } from '@dominos/business/functions'
import { NavigationConstants } from '@dominos/navigation'

import css from './sign-in.less'
import { CreateAccountSceneProps } from '@dominos/scenes/create-account-scene'

export const AccountSignIn = ({ testID }: BaseProps) => {
  const { t } = useTranslation('login')
  const { previousStep, triggerAction, hasPrevious, data } = useQuickUniversalAccountComponent()
  const { hidePopup } = useAuthenticationPopup()

  const handleSuccess = () => hidePopup('SignInSuccess')

  const handlePrevious = hasPrevious ? () => previousStep() : undefined

  const handlePopupBlocked = () => triggerAction('warning')

  const handleSignIn = () => triggerAction('next')

  const handleAdditional = (socialCustomer: Partial<CreateAccountSceneProps>) =>
    triggerAction('additional', { ...data, socialCustomer })

  const handleAlternativeOption = () => triggerAction('alternative')

  const isNative = isNativeApp()

  useEffect(() => {
    if (isNative) {
      postWebViewMessage<LoginSceneMessage>({ route: NavigationConstants.nativeAppLogin })
    }
  }, [])

  return (
    <div data-testid={testID}>
      {!isNative && (
        <CardHeader
          testID={`${testID}.header`}
          title={t('SignInTitle', { defaultValue: 'Log in' })}
          onAction={handlePrevious}
        />
      )}
      <div data-testid={`${testID}.description`} className={css.section}>
        {t('SignInDescription', {
          defaultValue: `Unlock faster ordering and easier checkout with a Domino's account`,
        })}
      </div>
      <div data-testid={`${testID}.section`} className={css.section}>
        {isNative ? (
          <NativeAuthenticationButtonGroup
            testID={`${testID}.native-auth-button-group`}
            onEmail={handleSignIn}
            onRegister={handleAdditional}
          />
        ) : (
          <AuthenticationButtonGroup
            testID={`${testID}.social`}
            onEmail={handleSignIn}
            onComplete={handleSuccess}
            onRegister={handleAdditional}
            onPopupBlocked={handlePopupBlocked}
          />
        )}
        <div data-testid={`${testID}.alternative`} className={css.alternative}>
          {t('SignInAlternativeLabel', { defaultValue: `Don't have an account?` })}
          <a data-testid={`${testID}.link`} onClick={handleAlternativeOption}>
            {t('SignInAlternativeButtonText', { defaultValue: `Create an Account` })}
          </a>
        </div>
      </div>
    </div>
  )
}
