import { getQuantityWithChange } from './get-quantity-with-change'

/**
 * Updates the additional ingredient/s (swaps)
 * quantity where the change matches the an ingredient
 * swap `code`.
 *
 * @param ingredients Collection of recipe ingredients.
 * @param change Basket line change with `code` and `quantity` to apply.
 */
export const getIngredientSwapsWithChange = (
  ingredients: LimitedRecipeIngredient[] = [],
  change: BasketLineChange,
): LimitedRecipeIngredient[] => {
  const topping = ingredients.find(({ ingredient }) => ingredient.code === change.code)
  if (topping) {
    topping.quantity = getQuantityWithChange(topping?.quantity, change)
    topping.allowAdd = true
    topping.allowRemove = true
  }

  return ingredients
}
