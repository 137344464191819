import { getCountryConfig, setupGoogleApi } from '@dominos/business/functions'
import { ErrorScope, getStatusReasonFromApolloError, useErrorContext } from '@dominos/components/error'
import { useCurrentOrderDetails, useLazyLoadSdk, useReport, useSocialAuth } from '@dominos/hooks-and-hocs'
import { GoogleIcon } from '@dominos/res/images/icons/components'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton } from '../../buttons'
import { InlineError } from '../inline-error'
import { GoogleAuthClient, GoogleAuthErrorResponse, GoogleAuthResponse, GoogleLoginErrorCode } from './google.interface'
import css from './google.less'
import styles from './styles'
import { loginErrors } from '../login-errors'
import useNavigation from './use-navigation'
import { useLocation } from '@reach/router'

interface Props extends Partial<BaseProps> {
  enableLongLived: boolean
  handlePopupBlock: () => void
}

const Google = ({ testID = 'google-login-button', enableLongLived, handlePopupBlock }: Props) => {
  const { t } = useTranslation('login')
  const { reportLogin } = useReport()
  const location = useLocation()

  const { signIn, authCustomerInfo, error: signInError, pending } = useSocialAuth()

  const applicationConfig = getCountryConfig()
  const [loginFailed, setLoginFailed] = useState<boolean>(false)
  const { notifyError } = useErrorContext()

  const { updateCustomerForNavigation } = useNavigation()
  const { orderId } = useCurrentOrderDetails()

  const [authClient, setAuthClient] = useState<GoogleAuthClient>()

  const onSuccess = (token: string) => {
    signIn({ orderId, enableLongLived, providerToken: token, provider: 'Google' })
  }

  const onFailure = async (reason: string, error: string) => {
    await reportLogin({
      enableLongLived,
      status: 'fail',
      order_id: orderId,
      authenticationSource: 'Legacy',
      customerId: authCustomerInfo?.customerId,
      url: location.href,
      identityProvider: 'Google',
      status_reason: reason,
      status_error: error,
    })

    if (reason === GoogleLoginErrorCode.POPUP_FAILED_TO_OPEN) {
      handlePopupBlock()
    }

    setLoginFailed(true)
  }

  const onGoogleLogin = () => {
    setLoginFailed(false)
    authClient ? authClient.requestAccessToken() : lazyLoadSdk()
  }

  const onGoogleInitFailure = (error: GoogleAuthErrorResponse) => {
    onFailure(error.type, error.message)
  }

  const { lazyLoadSdk, isLazyLoading } = useLazyLoadSdk('default_gsi', {
    loadSdk: setupGoogleApi,
    onSdkLoaded: () => {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: applicationConfig.GOOGLE_CLIENT_ID,
        scope: `openid profile email`,
        callback: (tokenResponse: GoogleAuthResponse) => {
          onSuccess(tokenResponse.access_token)
        },
        error_callback: (error: GoogleAuthErrorResponse) => {
          onGoogleInitFailure(error)
        },
      })
      client.requestAccessToken()
      setAuthClient(client)
    },
    onSdkTimeout: async () => {
      await reportLogin({
        enableLongLived,
        status: 'fail',
        order_id: orderId,
        authenticationSource: 'Legacy',
        customerId: authCustomerInfo?.customerId,
        url: location.href,
        identityProvider: 'Google',
        status_reason: 'Load SDK timeout',
      })
    },
  })

  useEffect(() => {
    updateCustomerForNavigation(authCustomerInfo, enableLongLived)
  }, [authCustomerInfo])

  useEffect(() => {
    if (signInError) {
      const statusReason = getStatusReasonFromApolloError(signInError)

      if (statusReason) {
        reportLogin({
          enableLongLived,
          status: 'fail',
          order_id: orderId,
          authenticationSource: 'Legacy',
          customerId: authCustomerInfo?.customerId,
          url: location.href,
          identityProvider: 'Google',
          status_reason: statusReason,
        })
      }

      notifyError({
        error: signInError,
        definitions: loginErrors,
        handlers: {},
        scope: ErrorScope.CreateAccount,
      })
    }
  }, [signInError])

  return (
    <>
      <ActionButton
        testID={testID}
        loading={isLazyLoading || pending}
        disabled={isLazyLoading || pending}
        onPress={onGoogleLogin}
        containerStyle={styles.button}
        textFontWeight='bold'
      >
        <div className={css.googleIcon}>
          <GoogleIcon fill={'#ffffff'} />
        </div>
        <label className={css.buttonLabel}>{t('signInGoogleTitle', { defaultValue: 'Sign in with Google' })}</label>
      </ActionButton>
      <InlineError
        showError={loginFailed}
        message={t('CreateAccountGenericErrorText', { defaultValue: 'Something has gone wrong. Please try again' })}
      />
    </>
  )
}

export { Google }
