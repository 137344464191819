import { svgIconsName } from '@dominos/res'
import css from './kiosk-order-button.less'

interface TranslationType {
  key: string
  defaultValue: string
}

interface KioskButtonTypeDefinitions {
  testID: string
  subServiceMethod?: Bff.Stores.PickupServiceMethodSubTypes
  label: TranslationType
  icon?: svgIconsName
  containerClass?: string
}

export const kioskButtonTypes: Record<'Default' | 'TakeAway', KioskButtonTypeDefinitions> &
  PartialRecord<Bff.Stores.PickupServiceMethodSubTypes, KioskButtonTypeDefinitions> = {
  TakeAway: {
    testID: 'take-away-button',
    label: {
      key: 'TakeAwayOrderButton',
      defaultValue: 'Take-Away',
    },
    icon: 'delivery',
    containerClass: css.takeAwayContainer,
  },
  DineIn: {
    testID: 'dine-in-button',
    subServiceMethod: 'DineIn',
    label: {
      key: 'DineInOrderButton',
      defaultValue: 'Dine-In',
    },
    icon: 'pickUp',
    containerClass: css.dineInContainer,
  },
  Default: {
    testID: 'begin-order-button',
    label: {
      key: 'BeginOrderButton',
      defaultValue: 'Start Order',
    },
  },
}
