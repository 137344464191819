import React from 'react'
import { StaticSvgIcon } from '@dominos/res'

import css from './address-info.less'
interface AddressInfoProps extends BaseProps {
  address: DeliveryAddressRequest
  showClearButton?: boolean
}

export const AddressInfo = ({ testID, address }: AddressInfoProps) => {
  const { displayAddress, structuredAddress } = address

  const addressInfoToDisplay = structuredAddress
    ? [structuredAddress.title, structuredAddress.subtitle].join(' ')
    : displayAddress

  return (
    <div data-testid={testID} className={css.wrapper}>
      <div data-testid={`${testID}.icon`} className={css.icon}>
        <StaticSvgIcon name={'pinIcon'} isUnstyled width={30} height={30} />
      </div>

      <div>
        <p data-testid={`${testID}.subtitle`} className={css.subtitle}>
          {addressInfoToDisplay}
        </p>
      </div>
    </div>
  )
}
