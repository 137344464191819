import { TFunction } from 'react-i18next'

import { duplicateChanges } from '@dominos/components/basket/basket-line/functions'

import {
  getHalfNHalfPresetSauceCode,
  getHalfNHalfSharedSauces,
} from '@dominos/business/functions/product/get-half-n-half-sauces'
import { getFullUrl } from './get-images'
import { getProductDefaultSize } from './get-product-default-size'

type ProductMenuItem = Bff.Menu.old.ProductMenuItem
type PortionMenuItem = Bff.Menu.old.PortionMenuItem
type PortionSize = Bff.Menu.old.PortionSize
type Ingredient = Bff.Menu.old.Ingredient
type IngredientSet = Bff.Menu.old.IngredientSet

const getProductSize = (
  item?: ProductMenuItem | PortionMenuItem,
  sizeCode?: string,
): PortionSize | ProductSize | null => {
  if (!item || !item.sizes || item.sizes.length === 0) {
    return null
  }

  if (!sizeCode) {
    return getProductDefaultSize(item)
  }

  const sizes: { code: string | null }[] = item.sizes
  const foundSize = sizes.findIndex((size: { code: string | null }) => size?.code === sizeCode)
  if (foundSize >= 0) {
    return item.sizes[foundSize]
  }

  return item.sizes[0]
}

const priceSortLowToHigh = (a: { price?: number | null }, b: { price?: number | null }) =>
  (a.price || 0) - (b.price || 0)

const calculatePrice = (defaultPrice: number | null, otherPrice: number | null) =>
  otherPrice && defaultPrice ? otherPrice - defaultPrice : 0

export const mapMenuItemToSizeItemData = (
  item?: ChangeableProductItem | ChangeablePortionItem,
  sizeCode?: string,
): ItemData<SizeIconProps> => {
  const transformed: ItemData<SizeIconProps> = { elements: [], selected: undefined, defaultItem: undefined }

  if (!item || !item.sizes || item.sizes.length === 0) {
    return transformed
  }

  const foundDefaultSize = (item.sizes as ProductSize[]).find(
    (size: ProductSize | PortionSize) => size.code === item.defaultSize,
  )

  const sortedSizes = [...item.sizes].sort(priceSortLowToHigh)
  sortedSizes.forEach((size: ProductSize | PortionSize) => {
    const isDefaultSize = item.defaultSize === size.code

    const sizeToAdd = {
      code: size.code || '',
      icon: '',
      name: (size.media && size.media.name) || '',
      price: (!isDefaultSize && calculatePrice(foundDefaultSize?.price ?? null, size.price)) || undefined,
    }
    transformed.elements.push(sizeToAdd)

    // select the sizeCode if provided else select the default
    if ((sizeCode || item.defaultSize) === size.code) {
      transformed.selected = sizeToAdd
    }

    if (foundDefaultSize?.code === sizeToAdd.code) {
      transformed.defaultItem = sizeToAdd
    }
  })

  return transformed
}

export const mapMenuItemToCrustItemData = (
  item?: ChangeableProductItem | ChangeablePortionItem,
  sizeCode?: string,
): ItemData<CrustIconProps> => {
  const transformed: ItemData<CrustIconProps> = { elements: [], selected: undefined }

  if (!item) {
    return transformed
  }

  const size = getProductSize(item, sizeCode)

  if (!size) {
    return transformed
  }

  const crusts = size.swaps && size.swaps.bases ? [...size.swaps.bases.ingredients] : []
  const defaultCrust =
    item.changes && item.changes.base && item.changes.base.add
      ? item.changes.base.add
      : size.recipe && size.recipe.base
      ? size.recipe.base.code
      : null

  crusts.sort(priceSortLowToHigh)

  crusts.forEach((ingredient: Ingredient) => {
    const crust = {
      code: ingredient?.code || '',
      icon: ingredient?.media?.icon || '',
      image: getFullUrl(ingredient?.media?.image || ''),
      name: ingredient?.media?.name || '',
      price: ingredient?.price || undefined,
    }
    transformed.elements.push(crust)
    if (defaultCrust && defaultCrust === crust.code) {
      transformed.selected = crust
      transformed.defaultItem = crust
    }
  })

  return transformed
}

export const mapMenuItemToSauceItemData = (
  item?: ChangeableProductItem | ChangeablePortionItem,
  sizeCode?: string,
): ItemData<SauceIconProps> => {
  const transformed: ItemData<SauceIconProps> = { elements: [], selected: undefined }

  if (!item || item.type !== 'Product') {
    return transformed
  }

  const size = getProductSize(item, sizeCode) as ProductSize

  if (!size) {
    return transformed
  }

  const noSauce: Ingredient = { code: 'no-sauce', media: { name: 'No Sauce' } }
  const getSauces = ({ rule, ingredients }: IngredientSet) =>
    rule.min === 0 ? [...ingredients, noSauce] : [...ingredients]
  const sauces = size.swaps?.sauces ? getSauces(size.swaps.sauces) : []

  let presetSauce = size.recipe && size.recipe.sauce ? size.recipe.sauce.code : null
  if (item.changes && item.changes.sauce) {
    presetSauce = !item.changes.sauce.add && item.changes.sauce.remove ? 'no-sauce' : item.changes.sauce.add
  }

  sauces.forEach((ingredient: Ingredient) => {
    const sauce = {
      code: ingredient.code,
      color: (ingredient.media && ingredient.media.colour) || '',
      name: (ingredient.media && ingredient.media.name) || '',
    }
    transformed.elements.push(sauce)

    if (presetSauce === sauce.code) {
      transformed.selected = sauce
    }
  })

  return transformed
}

export const mapMenuItemToPortionSauceItemData =
  (leftItem?: ChangeableProductItem, rightItem?: ChangeableProductItem) =>
  (item?: ChangeableProductItem | ChangeablePortionItem, sizeCode?: string): ItemData<SauceIconProps> => {
    const transformed: ItemData<SauceIconProps> = { elements: [], selected: undefined }

    if (!item || !leftItem || !rightItem) {
      return transformed
    }

    let size: PortionSize | null = null
    if (item?.type === 'Portion') {
      size = getProductSize(item, sizeCode) as PortionSize
    }

    if (!size) {
      return transformed
    }

    const sauces = getHalfNHalfSharedSauces(size.code ?? undefined, leftItem, rightItem)
    if (!sauces?.length) {
      return transformed
    }

    const presetSauce = getHalfNHalfPresetSauceCode(size.code, leftItem, rightItem, sauces)

    sauces.forEach((ingredient: Ingredient) => {
      const sauce = {
        code: ingredient.code,
        color: ingredient.media?.colour ?? '',
        name: ingredient.media?.name ?? '',
      }
      transformed.elements.push(sauce)

      if (presetSauce === sauce.code) {
        transformed.selected = sauce
      }
    })

    return transformed
  }

export const NOT_SELECTED_OPTION_CODE = 'not-selected-option'

export const mapMenuItemToOptionItemData =
  (optionNo: number, t: TFunction<'menu'>) =>
  (item?: ChangeableProductItem | ChangeablePortionItem, sizeCode?: string): ItemData<OptionIconProps> => {
    const transformed: ItemData<OptionIconProps> = { elements: [], selected: undefined }

    if (!item) {
      return transformed
    }

    const size = getProductSize(item, sizeCode) as ProductSize

    if (!size) {
      return transformed
    }

    const options: Ingredient[] = []

    if (size.swaps?.options?.rule?.min === undefined || size.swaps?.options?.rule.min <= optionNo) {
      options.push({
        code: NOT_SELECTED_OPTION_CODE,
        media: {
          name: t('NotSelectedOption'),
        },
      })
    }
    if (size.swaps?.options?.ingredients) {
      options.push(...size.swaps?.options?.ingredients)
    }

    const expandedOptions = size.recipe?.options && duplicateChanges(size.recipe.options)
    const presetOptionCode =
      expandedOptions && expandedOptions.length > optionNo && expandedOptions[optionNo].ingredient.code

    if (options.length > 0) {
      options.forEach((ingredient) => {
        const option: OptionIconProps = {
          name: ingredient.media?.name || '',
          code: ingredient.code,
          imageLink: (ingredient.media?.image && getFullUrl(ingredient.media?.image)) || '',
          index: optionNo,
        }
        transformed.elements.push(option)

        if (presetOptionCode === option.code) {
          transformed.selected = option
        }
      })
    }

    return transformed
  }
