import React from 'react'

import { ResetPassword, StackNavigationBase } from '@dominos/components'

export const ResetPasswordScene = () => (
  <StackNavigationBase testID='reset-password-layout'>
    <div style={{ margin: '8px 8px 40px', display: 'flex', justifyContent: 'center' }}>
      <ResetPassword />
    </div>
  </StackNavigationBase>
)
