import { useEffect, useRef, useState } from 'react'
export interface UseCountDownProps {
  startCountDown: boolean
  seconds: number
  onCountDownEnd: () => void
}

export const useCountDown = (props: UseCountDownProps) => {
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const interval = useRef<NodeJS.Timer | null>(null)
  const [secondsLeft, setSecondsLeft] = useState(props.seconds)
  const [startCountDown, setStartCountDown] = useState(props.startCountDown)

  const clearTimers = () => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }

    if (interval.current) {
      clearInterval(interval.current)
    }
  }

  const kickOffCountDown = () => setStartCountDown(true)

  const endCountDown = () => setStartCountDown(false)

  const handleCountDownEnd = () => {
    endCountDown()
    props.onCountDownEnd()
  }

  useEffect(() => {
    if (startCountDown) {
      interval.current = setInterval(() => {
        setSecondsLeft((prevSecondsLeft) => prevSecondsLeft - 1)
      }, 1000)

      timeout.current = setTimeout(handleCountDownEnd, props.seconds * 1000)
    } else {
      clearTimers()
      setSecondsLeft(props.seconds)
    }

    return () => {
      clearTimers()
    }
  }, [startCountDown])

  return { secondsLeft, startCountDown, kickOffCountDown, endCountDown }
}
