import { ApolloLink } from '@apollo/client'

declare global {
  interface Window {
    bmak?: {
      get_telemetry: () => string
    }
  }
}

const getAkamaiTelemetryHeader = () => {
  try {
    if (!window.bmak?.get_telemetry) {
      return {}
    }

    const telemetry = window.bmak.get_telemetry()

    return telemetry ? { 'Akamai-BM-Telemetry': telemetry } : {}
  } catch (error) {
    return {}
  }
}

export const akamaiHeadersLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...getAkamaiTelemetryHeader(),
    },
  }))

  return forward(operation)
})
