/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react'
import { GeoLocationInterface } from '@dominos/interfaces'
import { loader } from './index'
import { GoogleMapProperties } from './google-map.interface'

const GoogleMap: React.FC<GoogleMapProperties> = (props) => {
  const { testID, className, styles, mapCenter, zoom, boundsCoordinates, onMapCreated } = props

  const [map, setMap] = useState<google.maps.Map>()

  const createMap = (location: GeoLocationInterface | undefined) => {
    const mapElement = document.getElementById('map')

    if (mapElement) {
      const mapOptions = {
        center: { lat: location?.latitude ?? 0, lng: location?.longitude ?? 0 },
        zoom: zoom,
        draggable: true,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControlOptions: {
          mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID],
        },
        mapTypeControl: false,
      }

      const newMap = new google.maps.Map(mapElement, mapOptions)
      setMap(newMap)
    }
  }

  useEffect(() => {
    if (!map) {
      loader.load().then(() => {
        createMap(mapCenter)
      })
    }

    if (map && mapCenter && zoom) {
      map.setZoom(zoom)
      map.panTo({ lat: mapCenter?.latitude ?? 0, lng: mapCenter?.longitude ?? 0 })
    }
  }, [mapCenter])

  useEffect(() => {
    if (map) {
      onMapCreated(map)
    }
  }, [map])

  useEffect(() => {
    if (map && boundsCoordinates && boundsCoordinates.length > 0) {
      const bounds = new google.maps.LatLngBounds()
      boundsCoordinates.forEach((bound) => {
        bounds.extend(bound)
      })

      if (boundsCoordinates.length === 1) {
        map.fitBounds(bounds, { bottom: 200, left: 200, right: 200, top: 200 })
      } else {
        map.fitBounds(bounds, { bottom: 50, left: 30, right: 30, top: 120 })
      }
    }
  }, [boundsCoordinates])

  return <div id='map' data-testid={`${testID}.google`} className={className} style={styles}></div>
}

export { GoogleMap }
