import React from 'react'

import { getFullUrl } from '@dominos/business/functions/menu/get-images'
import css from './legends.less'
import classnames from 'classnames'

export const Legends = ({
  testID,
  containerStyle,
  children,
  legends,
  renderCode,
  imageStyle,
  legendWrapperStyle,
  className,
}: LegendsProps) => {
  const legendWithImage = (legends || []).filter((legend) => legend.image && legend.image.url) as MenuLegend[]

  return (
    <div
      data-testid={`${testID}-Container`}
      style={containerStyle}
      className={classnames(css.legendWrapper, className)}
    >
      {children}
      {legendWithImage.map((legend, index) => (
        <div
          style={legendWrapperStyle}
          className={css.legendContainer}
          key={`legend=${index}`}
          data-testid={`${testID}-Item-Container${index}`}
        >
          <img
            data-testid={`${testID}-Image-${index}`}
            style={imageStyle}
            className={css.legendImage}
            src={getFullUrl(legend.image!.url)}
          />
          {renderCode && (
            <p data-testid={`${testID}-Item-Code${index}`} className={css.itemCode}>
              {legend.code}
            </p>
          )}
        </div>
      ))}
    </div>
  )
}
