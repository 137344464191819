import React from 'react'

interface SimpleGuageProps {
  width?: number
  height?: number
  count: number
}

const defaultDimensions = {
  width: 36,
  height: 36,
}

export const SimpleGauge = (props: SimpleGuageProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 35 36'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <g>
            <g transform='translate(-1156.000000, -103.000000) translate(1148.000000, 89.000000) translate(8.000000, 14.500000)'>
              <path
                fill='#007AAF'
                d='M27.08 29.21l1.743 1.74c-2.698 2.273-6.093 3.745-9.818 4.05v-2.462c3.048-.292 5.832-1.492 8.076-3.328zm-11.085 3.317v2.463c-3.71-.327-7.086-1.812-9.767-4.09l1.741-1.741c2.227 1.842 4.993 3.055 8.026 3.368zm14.956-3.704l-1.741-1.742c1.836-2.244 3.036-5.028 3.328-8.076H35c-.305 3.725-1.777 7.12-4.05 9.818zM5.852 27.02L4.11 28.762C1.866 26.074.414 22.702.111 19.005h2.463c.288 3.021 1.47 5.782 3.278 8.015zM4.16 6.288L5.902 8.03c-.11.135-.219.272-.325.411-1.63 2.14-2.701 4.731-2.992 7.553H.12c.325-3.68 1.79-7.034 4.04-9.706zm30.83 9.707h-2.464c-.23-2.236-.95-4.326-2.049-6.162-.392-.655-.833-1.278-1.318-1.864l1.74-1.741c2.28 2.681 3.764 6.058 4.091 9.767zM15.995.12v2.464c-3.005.31-5.748 1.503-7.965 3.317L6.288 4.16C8.96 1.911 12.314.446 15.995.12zm12.767 3.988L27.02 5.852c-2.233-1.808-4.993-2.99-8.014-3.278V.11c3.697.303 7.069 1.755 9.757 3.998z'
              />
              <circle cx='17.493' cy='17.493' r='13' fill='#007AAF' />
              <text
                fill='#FFF'
                fontFamily='SFProDisplay-Bold, SF Pro Display'
                fontSize='17'
                fontWeight='bold'
                letterSpacing='.193'
              >
                <tspan x='11.993' y='23'>
                  {props.count}
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
