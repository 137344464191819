import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 25,
  height: 25,
}

const MinusIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 25.2 24.2'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <polygon
          fill={fill}
          points='14.1420898 14.1494141 19.1835938 14.1494141 19.1835938 11 14.1420898 11 11.0292969 11 6 11 6 14.1494141 11.0292969 14.1494141'
        />
      </g>
    </svg>
  )
}

export { MinusIcon }
