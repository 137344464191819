import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 52,
  height: 52,
}

const Face4Active = (props: SvgIconProps) => Face4({ ...props, fill: '#8ACF03' })

const Face4Inactive = (props: SvgIconProps) => Face4({ ...props, fill: '#9B9B9B' })

const Face4 = (props: SvgIconProps) => {
  const fill = props.fill || '#9B9B9B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 52 52'>
      <g id='Face-4' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          fill={fill}
          d='M9.72172901,42.7165434 C8.03539144,41.0397551 6.603879,39.1072576 5.48926222,36.9834201 C3.79178378,33.74921 2.82942545,30.0711337 2.82942545,26.1718318 C2.82942545,13.3007754 13.3007754,2.82942545 26.1718318,2.82942545 C32.2768477,2.82942545 37.8409128,5.18633685 42.0047661,9.03683122 C44.1636177,11.0329909 45.9463326,13.4302216 47.2317759,16.1098643 C48.6942352,19.1582166 49.5142381,22.5710342 49.5142381,26.1718318 C49.5142381,39.0428881 39.0428881,49.5142381 26.1718318,49.5142381 C19.7601768,49.5142381 13.9446465,46.9154108 9.72172901,42.7165434 Z'
        />
        <path
          fill='#000000'
          d='M0,26.1718318 C0,40.6029626 11.7407009,52.3436635 26.1718318,52.3436635 C40.6029626,52.3436635 52.3436635,40.6029626 52.3436635,26.1718318 C52.3436635,11.7407009 40.6031394,0 26.1718318,0 C11.7407009,0 0,11.7407009 0,26.1718318 Z M10.6677635,41.765068 C9.07840609,40.1847107 7.72921899,38.3633498 6.67870307,36.3616528 C5.07884549,33.31344 4.17183175,29.8468874 4.17183175,26.1718318 C4.17183175,14.0409813 14.0409813,4.17183175 26.1718318,4.17183175 C31.9257523,4.17183175 37.1698317,6.39319874 41.0942245,10.0222537 C43.128922,11.9036156 44.8091141,14.1629831 46.0206325,16.6885214 C47.3989867,19.5615649 48.1718318,22.7781137 48.1718318,26.1718318 C48.1718318,38.3026822 38.3026822,48.1718318 26.1718318,48.1718318 C20.1289069,48.1718318 14.6478238,45.7224613 10.6677635,41.765068 Z'
        />
        <path
          fill='#000000'
          d='M34.6581629,29.5956134 C35.3101686,30.325782 36.1688992,30.8665559 37.1427521,31.1266862 C34.2714158,34.082198 30.2996098,35.7688892 26.1714781,35.7688892 C22.0433463,35.7688892 18.071894,34.082198 15.2005577,31.1268631 C16.1744106,30.8667328 17.0331412,30.325782 17.6851469,29.5956134 C19.9810489,31.7295307 23.0193213,32.9394638 26.1714781,32.9394638 C29.3238117,32.9394638 32.3620841,31.7295307 34.6581629,29.5956134 Z M34.9253667,16 C36.4880153,16 37.7547922,17.5670034 37.7547922,19.5 C37.7547922,21.4329966 36.4880153,23 34.9253667,23 C33.3627182,23 32.0959413,21.4329966 32.0959413,19.5 C32.0959413,17.5670034 33.3627182,16 34.9253667,16 Z M17.4182968,16 C18.9809453,16 20.2477222,17.5670034 20.2477222,19.5 C20.2477222,21.4329966 18.9809453,23 17.4182968,23 C15.8556482,23 14.5888713,21.4329966 14.5888713,19.5 C14.5888713,17.5670034 15.8556482,16 17.4182968,16 Z'
        />
      </g>
    </svg>
  )
}

export { Face4Active, Face4Inactive, Face4 }
