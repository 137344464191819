import { LDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk'
import React from 'react'
import { useConfigContext } from '@dominos/hooks-and-hocs'
import { getLaunchDarklyConfig } from './feature-flag-helper'

interface LaunchDarklyStartupProps extends PropsWithChildren {
  launchDarklyID: string
}

export const LaunchDarklyStartup = ({ children, launchDarklyID }: LaunchDarklyStartupProps) => {
  const { applicationConfig } = useConfigContext()
  const config: ProviderConfig = getLaunchDarklyConfig(applicationConfig, launchDarklyID)

  return <LDProvider {...config}>{children}</LDProvider>
}
