import { ReactNode, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

import css from './portal.less'

/**
 * The <Portal> component allows for rendering of other (child) components at the root
 * level of the application, next to the <div id="app" /> in the index.html file.
 * This is useful for temporary elements that need to appear over the top of the app (such as notifications)
 * as you don't need to fight the dom hierarchy to style the overlay component.
 * Example usage:
 * <Portal id="my-portal">
 *  <ChildComponentThatNeedsToBeOnTop />
 * </Portal>
 * Code below adapted from: https://www.jayfreestone.com/writing/react-portals-with-hooks/
 * Once another component requires a portal, we should move this code to its own directory
 */

const DEFAULT_Z_INDEX = 5

interface PortalProps {
  id: string
  children: ReactNode
  suppressBackgroundScroll?: boolean
  zIndex?: number
}

export const Portal = ({ id, children, suppressBackgroundScroll, zIndex }: PortalProps) => {
  const target = usePortal(id ?? 'portal', suppressBackgroundScroll ?? false, zIndex)

  return ReactDOM.createPortal(children, target)
}

const createRootElement = (id: string) => {
  const rootContainer = document.createElement('div')
  rootContainer.setAttribute('id', id)
  rootContainer.setAttribute('class', css.portal)

  return rootContainer
}

const addRootElement = (rootElem: Element) => {
  document.body.insertBefore(rootElem, document.body.lastElementChild!.nextElementSibling)
}

export const usePortal = (id: string, suppressBackgroundScroll: boolean, zIndex: number = DEFAULT_Z_INDEX): Element => {
  const rootElemRef = useRef<Element>()

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`)
    const parentElem = existingParent || createRootElement(id)

    if (!existingParent) {
      addRootElement(parentElem)
    }

    parentElem.appendChild(rootElemRef.current!)

    if (suppressBackgroundScroll) {
      document.body.style.overflow = 'hidden'
    }

    parentElem.setAttribute('style', `z-index: ${zIndex.toString()}`)

    return () => {
      if (suppressBackgroundScroll) {
        document.body.style.overflow = 'scroll'
      }
      rootElemRef.current!.remove()
      if (parentElem.childNodes.length === -1) {
        parentElem.remove()
      }
    }
  }, [])

  const getRootElem = () => {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div')
    }

    return rootElemRef.current
  }

  return getRootElem()
}
