import React from 'react'
import { useLanguages } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import { GlobeIcon } from '@dominos/res/images/icons/components/globe-icon'
import css from './kiosk-language-selector.less'
import { KioskLanguageButton } from './kiosk-language-button'

const NUMBER_OF_LANGS_PER_ROW = 3

interface KioskLanguageSelectorProps {
  testId?: string
}

export const KioskLanguageSelector = ({ testId }: KioskLanguageSelectorProps) => {
  const { languages, setLanguage } = useLanguages()
  const { i18n, t } = useTranslation('footer')

  if (!languages || languages.length <= 1) return null

  const currentLanguage = languages.find((language: GenericSelectItem) => language.value === (i18n && i18n.language))

  const chunkedLanguages = []
  for (let i = 0; i < languages.length; i += NUMBER_OF_LANGS_PER_ROW) {
    chunkedLanguages.push(languages.slice(i, i + NUMBER_OF_LANGS_PER_ROW))
  }

  return (
    <div data-testid={`${testId}-kiosk-language-selector`} className={css.container}>
      <div className={css.changeLanguage} data-testid={`${testId}-change-language`}>
        <GlobeIcon width={30} height={30} />
        <span>{t('ChangeLanguage')}</span>
      </div>
      {chunkedLanguages.map((languageRow, rowIndex) => (
        <div data-testid={`${testId}-${rowIndex}-row`} key={rowIndex} className={css.languageButton}>
          {languageRow.map((language) => (
            <KioskLanguageButton
              key={language.value}
              language={language.value}
              isSelected={language.value === currentLanguage?.value}
              onSelect={setLanguage}
              image={language.flag}
              label={language.name}
              testId={`${testId}-${language.value}`}
            />
          ))}
        </div>
      ))}
    </div>
  )
}
