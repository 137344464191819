import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 25,
  height: 25,
}

const PlusIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 25.2 24.2'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <polygon
          fill={fill}
          fillRule='nonzero'
          points='14.1420898 14.1298828 14.1420898 19.1225586 11.0292969 19.1225586 11.0292969 14.1298828 6 14.1298828 6 10.9804688 11.0292969 10.9804688 11.0292969 6 14.1420898 6 14.1420898 10.9804688 19.1835938 10.9804688 19.1835938 14.1298828'
        />
      </g>
    </svg>
  )
}

export { PlusIcon }
