import { rootActions } from '@dominos/business/root.actions'
import { reportCustomer } from '@dominos/hooks-and-hocs/logging/report-customer'
import { ActionsObservable, Epic, StateObservable } from 'redux-observable'
import { filter, ignoreElements, take, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

export const authLogoutEpic: Epic = (
  action$: ActionsObservable<RootActions>,
  store$: StateObservable<RootReducer>,
  { securitySender, analyticSender }: IReduxInjected,
) =>
  action$.pipe(
    filter(isActionOf(rootActions.logout)),
    take(1),
    tap((action) => {
      const report = reportCustomer(securitySender, analyticSender)
      report.reportLogout(store$.value.currentOrderDetailsReducer.orderId)
    }),
    ignoreElements(),
  )
