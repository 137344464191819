import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionButton, AlternativeStoreList, Modal, Spinner } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import styles from './alternative-stores-popup.less'

interface AlternativeStoresPopupProps {
  testID: string
  isLoading: boolean
  storeNo: number
  stores: Bff.Stores.Store[]
  onClose: () => void
  handleChangeStore: (line: Bff.Stores.Store) => void
}

const closeIcon = {
  name: 'cross' as svgIconsName,
  width: 21,
  height: 21,
}

export const AlternativeStoresPopup = ({
  testID,
  onClose,
  isLoading,
  storeNo,
  stores,
  handleChangeStore,
}: AlternativeStoresPopupProps) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('timed-order')

  const [selectedStore, setSelectedStore] = useState<Bff.Stores.Store>()
  const handleDoneClick = () => {
    if (selectedStore && selectedStore.storeNo !== storeNo) {
      handleChangeStore(selectedStore)
    }
    onClose()
  }

  return (
    <Modal testID={`${testID}.alternative-stores-popup`} onModalClose={onClose}>
      <div className={styles.cardContainer}>
        <h4 className={styles.cardHeader} data-testid={`${testID}.popup-header`}>
          {t('AlternativeStoreChooseTitle')}
        </h4>
        <div
          data-testid={`${testID}.close-button.top`}
          role='button'
          aria-label='Close Button'
          className={styles.closeButton}
          onClick={onClose}
        >
          <StaticSvgIcon name={closeIcon.name} width={closeIcon.width} height={closeIcon.height} isUnstyled />
        </div>
        {isLoading ? (
          <div className={styles.loadingSpinnerContainer}>
            <Spinner testID='alternative-stores-spinner' color={'rgb(0, 122, 175)'} />
          </div>
        ) : (
          <div className={styles.contentContainer}>
            <AlternativeStoreList
              testID={`${testID}.alternative-stores`}
              onPress={(store) => setSelectedStore(store)}
              stores={stores}
              initalStoreSelected={storeNo}
            />
            {stores.length <= 1 && <div className={styles.notFoundMessage}> {t('AlternativeStoreNotFound')} </div>}
            <div className={styles.contentFooter}>
              <ActionButton
                text={t('AlternativeStoreCancel')}
                onPress={onClose}
                testID={`${testID}.cancel-button.bottom`}
                containerStyle={{ backgroundColor: 'gray' }}
              />
              <ActionButton
                text={t('AlternativeStoreDone')}
                onPress={handleDoneClick}
                testID={`${testID}.done-button.bottom`}
                containerStyle={{ backgroundColor: theme.colours.actionStandard }}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}
