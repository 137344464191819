import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'
const defaultDimensions = {
  width: '24.75',
  height: '33',
}
export const GiftCardPartialPaymentIcon = (props: SvgIconProps) => {
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg data-testid='gift-card-icon' width={`${width}px`} height={`${height}px`} viewBox={`0 0 24 24`}>
      <g id='Symbols' stroke='none' strokeWidth='2.4' fill='none' fillRule='evenodd'>
        <g
          id='Gift-Card'
          fill='#000'
          transform='translate(12.000000, 12.000000) rotate(90.000000) translate(-12.000000, -12.000000)'
        >
          <path d='M6.586,16 L8,17.414 L17.414,8 L16,6.586 L6.586,16 Z M7,8.5 C7,9.328 7.672,10 8.5,10 C9.328,10 10,9.328 10,8.5 C10,7.672 9.328,7 8.5,7 C7.672,7 7,7.672 7,8.5 L7,8.5 Z M17,15.5 C17,14.672 16.328,14 15.5,14 C14.672,14 14,14.672 14,15.5 C14,16.328 14.672,17 15.5,17 C16.328,17 17,16.328 17,15.5 L17,15.5 Z M14,1 C14,0.448 14.448,0 15,0 L20,0 C20.552,0 21,0.448 21,1 L21,23 C21,23.552 20.552,24 20,24 L15,24 C14.448,24 14,23.552 14,23 C14,21.86 13.14,21 12,21 C10.86,21 10,21.86 10,23 C10,23.552 9.552,24 9,24 L4,24 C3.448,24 3,23.552 3,23 L3,1 C3,0.448 3.448,0 4,0 L9,0 C9.552,0 10,0.448 10,1 C10,2.14 10.86,3 12,3 C13.14,3 14,2.14 14,1 L14,1 Z' />
        </g>
      </g>
    </svg>
  )
}
