import { Switch } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './email-login.less'

const KeepMeLoggedIn = ({
  keepMeLoggedIn,
  updateKeepMeLoggedIn,
}: {
  keepMeLoggedIn: boolean
  updateKeepMeLoggedIn: () => void
}) => {
  const { t } = useTranslation('login')

  return (
    <div className={css.switchContainer}>
      <Switch
        testID={'Login.KeepMeLoggedInSwitch'}
        name='updatesAccepted'
        checked={keepMeLoggedIn}
        onChange={updateKeepMeLoggedIn}
      >
        <span className={css.switchLabel}>{t('KeepMeLoggedIn', { defaultValue: 'Keep me logged in' })}</span>
      </Switch>
    </div>
  )
}

export default KeepMeLoggedIn
