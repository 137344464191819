import { Modal } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './terminal-action-overlay.less'
import { StaticSvgIcon } from '@dominos/res'

export const TerminalActionOverlay = ({ showTerminalAction = false }: { showTerminalAction?: boolean }) => {
  const { t } = useTranslation('kiosk')

  const TerminalActionOverlayContents = () => (
    <div className={css.container} data-testid='terminal-action-overlay-container'>
      <div className={css['content-wrapper']}>
        <div className={css.content}>
          <StaticSvgIcon name='terminal' isUnstyled />
        </div>
        <div className={css.content}>
          <h1>
            {t('TerminalActionHeading', {
              defaultValue: 'Please finalise payment...',
            })}
          </h1>
        </div>
      </div>
    </div>
  )

  return <Modal testID='terminal-action-overlay'>{showTerminalAction ? <TerminalActionOverlayContents /> : null}</Modal>
}
