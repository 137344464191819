import React from 'react'
import classnames from 'classnames'

import { FlatList } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import { toPascalCase } from '@dominos/business/functions'

import { AddressLineSuffix } from './address-line-suffix'
import { AddressLineRenderWrapper } from './address-line-render-wrapper'

import css from './address-list.less'

export const AddressList = ({
  testID,
  listTitle,
  addresses,
  defaultTitleColor,
  showBorderOnFirstItem,
  showBorderOnLastItem,
  swapNameAndAddress,
  narrowHeight,
  itemLoadingKey,
  isLoading,
  iconStyling,
  onPress,
}: AddressListProps) => {
  const renderItem = (info: AddressLine, key: string) => (
    <AddressLineRenderWrapper
      key={key}
      prefix={`address`}
      address={info}
      onPress={onPress}
      testID={
        info.icon === 'savedIcon'
          ? `${testID}.address.wrapper.customerSaved.${info.name.toLowerCase()}`
          : `${testID}.address.wrapper.${info.name.toLowerCase()}`
      }
    >
      <div className={!!narrowHeight ? css.smallContainer : css.container}>
        {info.icon ? (
          <div data-testid={`${testID}.icon.${info.icon}`} className={css.iconContainer}>
            <StaticSvgIcon name={info.icon} isUnstyled width={iconStyling?.width} height={iconStyling?.height} />
          </div>
        ) : null}
        {swapNameAndAddress ? (
          <div className={css.textContainer}>
            <p
              className={info.name && !defaultTitleColor ? css.highlightTitle : css.title}
              data-testid={`${testID}.address.info`}
            >
              {toPascalCase(info.address || '')}
            </p>
            <p className={css.subtitle} data-testid={`${testID}.address.name`}>
              {info.name}
            </p>
          </div>
        ) : (
          <div className={css.textContainer}>
            <p
              className={info.name && !defaultTitleColor ? css.highlightTitle : css.title}
              data-testid={`${testID}.address.name`}
            >
              {toPascalCase(info.name)}
            </p>
            <p className={css.subtitle} data-testid={`${testID}.address.info`}>
              {info.address}
            </p>
          </div>
        )}

        <AddressLineSuffix
          content={info.suffix}
          testID={`${testID}.suffix`}
          isLoading={isLoading && info.uid === itemLoadingKey}
        />
      </div>
    </AddressLineRenderWrapper>
  )

  const keyExtractor = (item: AddressLine, index: number) => `${item.address}.${item.name}.${item.uid}.${index}`

  return (
    <>
      {listTitle ? (
        <p className={css.listTitle} data-testid={`${testID}.address.title`}>
          {toPascalCase(listTitle)}
        </p>
      ) : null}

      <div
        data-testid={testID}
        className={classnames(
          !showBorderOnFirstItem && css.removeBorderOnFirstItem,
          showBorderOnLastItem && css.addBorderOnLastItem,
        )}
      >
        <FlatList data={addresses} renderItem={renderItem} keyExtractor={keyExtractor} />
      </div>
    </>
  )
}
