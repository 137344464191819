import React from 'react'
import { ThemePropsInjected } from '../context-interfaces'
import { ThemeContext } from './theme-context'
export const withTheme =
  <T extends ThemePropsInjected>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'theme'> & { forwardRef?: ForwardRefType }> =>
  ({ ...props }) =>
    (
      <ThemeContext.Consumer>
        {(theme) => {
          if (props.forwardRef) {
            const ForwardRef = React.forwardRef((forwardProps, forwardRef) => (
              <Component ref={forwardRef} {...(forwardProps as T)} theme={theme.theme} />
            ))

            return <ForwardRef ref={props.forwardRef} {...(props as T)} theme={theme.theme} />
          }

          return <Component {...(props as T)} theme={theme.theme} />
        }}
      </ThemeContext.Consumer>
    )

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ForwardRefType = React.RefObject<any>
