import { useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { TFunction } from 'i18next'
import { SelectItem } from '@dominos/components/ordering/interfaces/order-later-container-props'

export const useOrderLaterDateTimeSelector = (
  openingHours: StoreOrderTimeSlots | undefined,
  serviceMethod: string | undefined,
  t: TFunction,
  language: string,
) => {
  const [selectedDate, setSelectedOrderDate] = useState<DayOfTradeTimeSlot | undefined>(undefined)
  const [selectedTime, setTimeSlotAvailability] = useState<TimeSlotAvailability | undefined>(undefined)
  const [orderDates, setOrderDates] = useState<SelectItem[] | undefined>(undefined)
  const [selectedDateOrderTimes, setSelectedDateOrderTimes] = useState<SelectItem[] | undefined>(undefined)
  const [dateHasBeenSelected, setDateHasBeenSelected] = useState<boolean>(false)

  const setSelectedDate = (date: string) => {
    const dayOfTradeTimeSlots = openingHours && getDayOfTradeTimeSlot(date, openingHours.dayOfTradeTimeSlots)
    setSelectedOrderDate(dayOfTradeTimeSlots)
    setTimeSlotAvailability(undefined)
    if (dayOfTradeTimeSlots && !dateHasBeenSelected && orderDates) {
      setOrderDates(getDatesToDisplay(openingHours.dayOfTradeTimeSlots, language, t))
      setDateHasBeenSelected(true)
    }
  }

  const setSelectedTime = (time: string) => {
    if (!selectedDate) return
    const timeSlot = getTimeSlotAvailability(time, selectedDate)
    if (timeSlot?.timeSlotStatus === 'Available' || timeSlot?.timeSlotStatus === 'ServiceMethodDiverted')
      setTimeSlotAvailability(timeSlot)
    else setTimeSlotAvailability(undefined)
  }

  const canStartOrder = useMemo(() => !!selectedDate && !!selectedTime, [selectedDate, selectedTime])

  useEffect(() => {
    const orderDates =
      openingHours && openingHours?.dayOfTradeTimeSlots?.length > 0
        ? getDatesToDisplay(openingHours.dayOfTradeTimeSlots, language, t, undefined, true)
        : undefined
    setOrderDates(orderDates)

    if (orderDates && orderDates.length > 0) {
      setSelectedDate(orderDates[0].value)
    }
  }, [openingHours?.dayOfTradeTimeSlots, language])

  useEffect(() => {
    const orderTimes = setOrderTimes()

    if (orderTimes && orderTimes.length > 0) setSelectedTime(orderTimes[0].value)
  }, [selectedDate, language])

  useEffect(() => {
    setOrderTimes()
  }, [selectedTime])

  const setOrderTimes = () => {
    const orderTimes = selectedDate
      ? getOrderTimesToShow(selectedDate, language, selectedTime?.slotTime || '', t)
      : undefined
    setSelectedDateOrderTimes(orderTimes)

    return orderTimes
  }

  return {
    orderDates,
    selectedDate,
    setSelectedDate,
    selectedDateOrderTimes,
    selectedTime,
    setSelectedTime,
    canStartOrder,
  }
}

const getDatesToDisplay = (
  orderDates: DayOfTradeTimeSlot[],
  locale: string,
  t: TFunction,
  dateSuffix?: string,
  displayChooseTime?: boolean,
): SelectItem[] => {
  const orderDatesDropDownItems = orderDates.map((dt: DayOfTradeTimeSlot) => getOrderDate(dt, locale, t))
  if (!orderDatesDropDownItems || orderDatesDropDownItems.length == 0) return orderDatesDropDownItems

  if (displayChooseTime && orderDatesDropDownItems[0].disabled)
    orderDatesDropDownItems.unshift({
      label: t('chooseDate', { defaultValue: 'Choose Date' }),
      value: '',
      disabled: false,
    })

  return orderDatesDropDownItems
}

const getOrderDate = (dayOfTradeTimeSlot: DayOfTradeTimeSlot, locale: string, t: TFunction): SelectItem => {
  const parsedDate = DateTime.fromISO(dayOfTradeTimeSlot.dayOfTrade, { setZone: true })
  const dateStr = parsedDate.setLocale(locale).toFormat('DDDD')
  const dateSuffix = getDisabledDescriptionForDate(dayOfTradeTimeSlot, t)
  const label = dateSuffix ? `${dateStr} - ${dateSuffix}` : `${dateStr}`

  return {
    label,
    value: dayOfTradeTimeSlot.dayOfTrade,
    disabled: dayOfTradeTimeSlot.tradingDayStatus != 'Open',
  }
}

const getDisabledDescriptionForDate = (dayOfTradeTimeSlot: DayOfTradeTimeSlot, t: TFunction) => {
  switch (dayOfTradeTimeSlot.tradingDayStatus) {
    case 'Closed':
      return t('ClosedDatePicker', { defaultValue: 'Closed' })
    case 'ServiceMethodUnavailable':
      return t('UnavailableDate', { defaultValue: 'Unavailable Date' })
    case 'Busy':
      return t('UnavailableDate', { defaultValue: 'Unavailable Date' })

    default:
      return undefined
  }
}

const getOrderTimesToShow = (
  selectedDate: DayOfTradeTimeSlot,
  locale: string,
  selectedTimeValue: string,
  t: TFunction,
): SelectItem[] | undefined => {
  if (selectedDate.tradingDayStatus != 'Open') return undefined

  const timesToShow = selectedDate.timeSlotAvailabilities.map((timeSlotAvailability: TimeSlotAvailability) =>
    getOrderTime(selectedDate, timeSlotAvailability, locale, t),
  )

  if (!timesToShow || timesToShow.length == 0) return undefined

  if (!selectedTimeValue) {
    timesToShow.unshift({ label: t('ChooseTime', { defaultValue: 'Choose Time' }), value: '', disabled: false })
  }

  return timesToShow
}

const getOrderTime = (
  selectedDate: DayOfTradeTimeSlot,
  timeSlotAvailability: TimeSlotAvailability,
  locale: string,
  t: TFunction,
): SelectItem => {
  const parsedDate = DateTime.fromISO(selectedDate.dayOfTrade, { setZone: true })
  const parsedSlotDate = DateTime.fromISO(timeSlotAvailability.slotTime, { setZone: true })
  const suffix =
    parsedDate.toFormat('yyyy-MM-dd') !== parsedSlotDate.toFormat('yyyy-MM-dd') ? ` ${parsedSlotDate.weekdayLong}` : ''

  const timeFormat = t('TIME_FORMAT', { defaultValue: 'h:mm a' })
  const timeLabel = `${parsedSlotDate.setLocale(locale).toFormat(timeFormat)}${suffix}`
  const isAvailable =
    timeSlotAvailability.timeSlotStatus === 'Available' ||
    timeSlotAvailability.timeSlotStatus === 'ServiceMethodDiverted'

  return {
    label: isAvailable ? timeLabel : `${timeLabel} - ${getUnavailableLabel(timeSlotAvailability, t)}`,
    value: timeSlotAvailability.slotTime,
    disabled: !isAvailable,
  }
}

const getUnavailableLabel = (timeSlotAvailability: TimeSlotAvailability, t: TFunction) =>
  timeSlotAvailability.timeSlotStatus === 'Busy'
    ? t('TimeBusyText', { defaultValue: 'Time Slot Full' })
    : t('TimeUnavailableText', { defaultValue: 'Time Unavailable' })

const getDayOfTradeTimeSlot = (date: string, dayOfTradesTimeSlots: DayOfTradeTimeSlot[]) =>
  dayOfTradesTimeSlots
    ? dayOfTradesTimeSlots.find((dayOfTradeTimeSlots) => dayOfTradeTimeSlots.dayOfTrade == date)
    : undefined

const getTimeSlotAvailability = (time: string, dayOfTradeTimeSlots: DayOfTradeTimeSlot) =>
  dayOfTradeTimeSlots?.timeSlotAvailabilities
    ? dayOfTradeTimeSlots.timeSlotAvailabilities.find((timeSlotAvailability) => timeSlotAvailability.slotTime == time)
    : undefined
