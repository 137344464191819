import { round } from '../numbers'

const MONOCHROMATIC_SCALES = [0.299, 0.587, 0.114]

/**
 * Slightly offset colour resolution due to
 * JavaScript rounding and monochromatic scaling.
 * Simpler to offset here than to pass through
 * complex rounding functions.
 */
const COLOUR_RESOLUTION = 254.99999999999997

const PRECISION = 2

const flattenBrightness = (values: (string | number)[] = [], precision = PRECISION): number => {
  /**
   * Convert the red-green-blue pixel values to their
   * most pleasing monochromatic value and average
   */
  const hsp = Math.sqrt(
    values.reduce<number>((avg, val, idx) => avg + MONOCHROMATIC_SCALES[idx] * Math.pow(Number(val), 2), 0),
  )

  /**
   * Convert the 8bit colour value to a floating point
   * number, with the specified decimal precision
   */
  return Math.min(round(hsp / COLOUR_RESOLUTION, Math.max(precision, 0)), 1)
}

/**
 * Calculates the colours brightness as a floating point value
 * between `0` and `1`. Brightness is different to luminance in
 * that luminance is measurable as intensity, while brightness is
 * a subjective attribute. Brightness attempts to factor in the
 * change of hue and saturation when adjusting luminance for a
 * "pleasing" intensity.
 *
 * @param colour Hexadecimal, RGB, or RGBA colour definition.
 * @param precision (optional) Rounds the output to a fixed decimal precision.
 * @returns Brightness as a value between `0` and `1`
 */
export const getColourBrightness = (colour = '', precision = PRECISION): number => {
  if (colour.match(/^#/)) {
    const hex = Number(`0x${colour.slice(1, 7).replace((colour.length < 5 && /./g) || '', '$&$&')}`)

    // eslint-disable-next-line no-bitwise
    return flattenBrightness([hex >> 16, (hex >> 8) & 255, hex & 255], precision)
  }

  const rgb = colour.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/i)

  return rgb ? flattenBrightness(rgb.splice(1, 3), precision) : 0.5
}
