import { CountryToggle } from '@dominos/components'
import {
  LoyaltyIconAU,
  LoyaltyIconDE,
  LoyaltyIconDefault,
  LoyaltyIconFR,
  LoyaltyIconNL,
} from '@dominos/res/images/icons/components/loyalty'
import React from 'react'
import { useTranslation } from 'react-i18next'
import css from './view-loyalty-points.less'

export const ViewLoyaltyPoints = ({ pointsToNextFreePizza }: { pointsToNextFreePizza?: number }) => {
  const { t } = useTranslation('loyalty')

  return (
    <>
      <div className={css.iconWrapper}>
        <CountryToggle
          AU={<LoyaltyIconAU />}
          NL={<LoyaltyIconNL />}
          FR={<LoyaltyIconFR />}
          DE={<LoyaltyIconDE />}
          default={<LoyaltyIconDefault />}
        />
      </div>
      <div data-testid='loyalty-points-label' className={css.pointsLabel}>
        {t('PointsAwayFromFreePizzaLabel', {
          defaultValue: '{{points}}pts away from a Free Pizza!',
          points: pointsToNextFreePizza,
        })}
      </div>
    </>
  )
}
