import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateNewVouchersCount } from '@dominos/business/actions'
import { couponBoxItemsSeenMutation } from '@dominos/business/queries'
import { useSavedVouchers } from '@dominos/hooks-and-hocs'

export const useNewVouchersCount = () => {
  const dispatch = useDispatch()
  const { activeVouchers } = useSavedVouchers()
  const newVouchersCount = useSelector((state: RootReducer) => state.customerReducer.newVouchersCount)

  useEffect(() => {
    const count = activeVouchers.filter((v) => v.firstSeenDate === null).length
    if (count > 0) {
      dispatch(updateNewVouchersCount(count))
    }
  }, [activeVouchers])

  const [markNewVouchersAsSeenMutation] = useMutation<{
    couponBoxItemsSeen: boolean
  }>(couponBoxItemsSeenMutation, {
    onCompleted: async (data) => {
      if (data.couponBoxItemsSeen) {
        dispatch(updateNewVouchersCount(0))
      }
    },
  })

  const markNewVouchersAsSeen = async () => {
    if (newVouchersCount > 0) {
      await markNewVouchersAsSeenMutation()
    }
  }

  return {
    newVouchersCount,
    markNewVouchersAsSeen,
  }
}
