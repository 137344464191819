import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 33,
  height: 28,
}

const DeliveryIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#FFFFFF'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg
      data-testid='delivery-icon'
      width={`${width}px`}
      height={`${height}px`}
      className={props.className}
      viewBox='0 0 33 28'
    >
      <g id='Current-Revision' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Start-Active-Order' transform='translate(-28.000000, -461.000000)' fill={fill} fillRule='nonzero'>
          <g id='noun_1100943_cc' transform='translate(27.500000, 461.576172)'>
            <g id='Group' transform='translate(0.728425, 0.020246)'>
              <g id='Shape'>
                <g>
                  <g>
                    <path d='M32.0317324,20.0997346 C31.3688437,17.8269731 29.285479,16.1934257 26.7759715,16.1934257 C26.0420589,16.1934257 25.3554955,16.3354733 24.739956,16.5722193 C24.9056782,15.8856559 25.0003765,15.1754179 25.0003765,14.4415054 C25.0003765,12.6422359 24.4558608,10.985014 23.556226,9.6118872 C23.7219482,9.6355618 23.8876704,9.65923639 24.0770672,9.65923639 L25.0477257,9.65923639 L25.0477257,4.37980082 L24.0770672,4.37980082 C22.8459881,4.37980082 21.8279803,5.20841179 21.5202105,6.32111791 L18.4661872,6.32111791 C18.063719,6.32111791 17.7559492,6.62888772 17.7559492,7.03135591 L17.7559492,7.03135591 C17.7559492,7.43382409 18.063719,7.74159385 18.4661872,7.74159385 L21.4965359,7.74159385 C21.5438851,7.93099064 21.6149089,8.12038744 21.7096073,8.28610964 L21.7096073,18.4898618 C21.7096073,19.2237744 21.1177423,19.839314 20.3838297,19.839314 L18.0400445,19.839314 C14.3231324,19.839314 14.5125292,16.2644495 14.4415054,14.3941562 L7.31545107,14.3941562 C4.8296182,14.3941562 1.11270615,17.590227 0.118372995,21.7332819 L2.60420589,21.7332819 L3.12504707,21.7332819 L5.37413399,21.7332819 L9.2804428,21.7332819 L11.5295297,21.7332819 L12.0740455,21.7332819 L20.7626233,21.7332819 L22.8696626,21.7332819 C22.7986388,22.0410517 22.7749642,22.3488215 22.7749642,22.6565913 C22.7749642,25.0003765 24.6689322,26.9180191 27.036392,26.9180191 C29.4038519,26.9180191 31.2978199,25.0240511 31.2978199,22.6565913 C31.2978199,21.6859327 30.9663755,20.8099725 30.4218597,20.0997346 L32.0317324,20.0997346 L32.0317324,20.0997346 Z M27.0127174,24.7162814 C25.9000113,24.7162814 24.9767019,23.8166466 24.9767019,22.6802659 C24.9767019,21.5438851 25.8763367,20.6442503 27.0127174,20.6442503 C28.1490982,20.6442503 29.048733,21.5438851 29.048733,22.6802659 C29.048733,23.8166466 28.1490982,24.7162814 27.0127174,24.7162814 L27.0127174,24.7162814 Z' />
                    <path d='M1.49149974,11.5097271 C1.49149974,12.3182461 2.15149615,12.9736802 2.9518569,12.9736802 L12.9811482,12.9736802 C13.7876812,12.9736802 14.4415054,12.3148904 14.4415054,11.5097271 L14.4415054,1.51130229 C14.4415054,0.702783321 13.781509,0.047349198 12.9811482,0.047349198 L2.9518569,0.047349198 C2.14532391,0.047349198 1.49149974,0.706139033 1.49149974,1.51130229 L1.49149974,11.5097271 Z M8.92388695,3.91019724 C8.60928695,4.22479724 8.60928695,4.73666391 8.92388695,5.05126391 C9.23848695,5.36439724 9.75035362,5.36439724 10.0642203,5.05126391 C10.378087,4.73666391 10.378087,4.22479724 10.0642203,3.91019724 C9.75035362,3.59633058 9.23848695,3.59633058 8.92388695,3.91019724 Z M6.49362029,4.39199724 L9.11895362,1.76593058 C9.27295362,1.61193058 9.52155362,1.61193058 9.67555362,1.76593058 L12.2099536,4.30106391 C12.3639536,4.45433058 12.3639536,4.70366391 12.2099536,4.85766391 L9.58462029,7.48373058 L6.49362029,4.39199724 Z M4.12866404,7.5074827 C3.81406404,7.82134937 3.81406404,8.33394937 4.12866404,8.64781603 C4.44253071,8.96094937 4.95513071,8.96094937 5.26899738,8.6470827 C5.58213071,8.33321603 5.58286404,7.82134937 5.26973071,7.5074827 C4.95513071,7.1928827 4.44253071,7.1928827 4.12866404,7.5074827 Z M6.33673071,7.5426827 C6.02359738,7.85654937 6.02359738,8.3676827 6.33673071,8.68374937 C6.65059738,8.99614937 7.16393071,8.99614937 7.47706404,8.6822827 C7.79166404,8.3676827 7.79166404,7.85654937 7.47706404,7.5426827 C7.16393071,7.2280827 6.65059738,7.2280827 6.33673071,7.5426827 Z M8.92319738,8.05674937 L6.10353071,10.8771494 C5.95026404,11.030416 5.70093071,11.030416 5.54693071,10.8771494 L3.01179738,8.34201603 C2.85853071,8.18874937 2.85853071,7.9386827 3.01179738,7.78541603 L5.83146404,4.96501603 L8.92319738,8.05674937 Z' />
                    <path d='M9.18574443,23.1774324 C8.85430003,23.8639958 8.16773667,24.3374878 7.33912567,24.3374878 C6.51051471,24.3374878 5.82395136,23.8639958 5.49250695,23.1774324 L3.17239627,23.1774324 C3.57486445,25.1187495 5.27943557,26.5629001 7.33912567,26.5629001 C9.39881582,26.5629001 11.1033869,25.095075 11.5058551,23.1774324 L9.18574443,23.1774324 L9.18574443,23.1774324 Z' />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export { DeliveryIcon }
