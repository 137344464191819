import { useMediaQuery } from 'react-responsive'
import { BREAKPOINTS } from './breakpoints'

/*
 * @deprecated
 *
 * We are trying to avoid using this because it is very confusing
 * Please implement the breakpoints in CSS where possible using
 * @media queries. This may involve making a higher-level less file
 * that lists the various breakpoints.
 *
 * Warning: the values below overlap and do not make sense.
 */
export const useBreakpoints = () => {
  const isDesktop = useMediaQuery({ minWidth: BREAKPOINTS.DESKTOP.MIN })
  const isTablet = useMediaQuery({ minWidth: BREAKPOINTS.TABLET.MIN, maxWidth: BREAKPOINTS.TABLET.MAX })
  const isNotMobile = useMediaQuery({ minWidth: BREAKPOINTS.NOT_MOBILE.MIN })
  const isMobile = useMediaQuery({ maxWidth: BREAKPOINTS.MOBILE.MAX })
  const isSmallMobile = useMediaQuery({ maxWidth: BREAKPOINTS.SMALL_MOBILE.MAX })

  return {
    isDesktop,
    isTablet,
    isNotMobile,
    isMobile,
    isSmallMobile,
  }
}
