import gql from 'graphql-tag'

export const orderStatusQuery = gql`
  query orderStatusQuery($id: String!) {
    order(id: $id) {
      id
      status {
        status
        estimatedDuration
        elapsedTime
      }
      eta {
        min
        max
      }
      details {
        header {
          arrivalNotificationSent
          serviceMethodSubType
        }
        payments {
          amount
          orderPaymentId
          paymentMethod
          providerCode
        }
      }
    }
  }
`
