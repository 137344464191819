export const allergensMockData = [
  { name: 'egg', type: 'Contains' },
  { name: 'fish', type: 'MayContainTraces' },
  { name: 'gluten', type: 'MayContainTraces' },
  { name: 'soy', type: 'MayContainTraces' },
  { name: 'milk', type: 'MayContainTraces' },
  { name: 'peanut', type: 'Contains' },
  { name: 'lupine', type: 'MayContainTraces' },
  { name: 'mustard', type: 'MayContainTraces' },
  { name: 'sesame', type: 'MayContainTraces' },
  { name: 'celery', type: 'MayContainTraces' },
  { name: 'mollusc', type: 'MayContainTraces' },
  { name: 'crustacean', type: 'MayContainTraces' },
  { name: 'sulphite', type: 'MayContainTraces' },
  { name: 'treeNut', type: 'MayContainTraces' },
]
