import React from 'react'

export const FlatList = ({ data = [], keyExtractor, renderItem }: FlatListProps) => {
  const list = []
  for (const result of data !== null ? data : []) {
    const key = keyExtractor(result, list.length)
    const item = renderItem(result, key)
    list.push(item)
  }

  return <>{list}</>
}
