import React from 'react'

import { StaticSvgIcon } from '@dominos/res'
import classnames from 'classnames'
import css from './generic-select.less'

const DEFAULT_HEIGHT = 32
const ICON_DIMENSIONS = { width: 10, height: 10 }

export const GenericSelect = (props: GenericSelectProps) => {
  const {
    testID,
    selectedValue,
    options = [],
    onChange,
    height = DEFAULT_HEIGHT,
    accessibilityLabel,
    placeholder,
    iconDimensions = ICON_DIMENSIONS,
    disabled,
  } = props

  const selectedIndex = options.findIndex((item: GenericSelectItem) => item.value === selectedValue)
  const onValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value

    return onChange(value)
  }

  const stopEventPropagation = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()

    return false
  }

  return (
    <div className={css.wrapper} data-testid={`${testID}-select.wrapper`} role='listbox' onClick={stopEventPropagation}>
      <div
        style={{ height }}
        className={classnames(css.container, disabled && css.disabled)}
        data-testid={`${testID}-select.container`}
      >
        <div className={css.label} data-testid={`${testID}-select.label`}>
          {selectedIndex > -1 && options[selectedIndex].label
            ? options[selectedIndex].label
            : placeholder || <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
        </div>
        <div className={css.rightContainer}>
          <span className={css.seperator} />
          <div>
            <div className={css.icon}>
              <StaticSvgIcon
                width={iconDimensions.width}
                height={iconDimensions.height}
                name={'chevron'}
                isUnstyled={true}
                fill={'rgb(133, 133, 133)'}
                direction={'down'}
              />
            </div>
          </div>
        </div>
        <select
          className={css.selectBox}
          onChange={onValueChange}
          data-testid={`${testID}-select`}
          aria-label={accessibilityLabel}
          value={options.length > selectedIndex && options[selectedIndex] ? options[selectedIndex].value : ''}
          disabled={disabled}
        >
          {placeholder && selectedIndex < 0 && (
            <option key={-1} value={-1}>
              {placeholder}
            </option>
          )}
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}
