import { ApolloLink } from '@apollo/client'
import { idTokenUtils } from '@dominos/business/functions/customer/id-token-utils'
import { B2BAuth } from '@dominos/components/login-b2b/B2BAuth'

export const authLink = new ApolloLink((operation, forward) => {
  const token = B2BAuth.available ? B2BAuth.currentToken : idTokenUtils().getToken()

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...(token && { authorization: `bearer ${token}` }),
    },
  }))

  return forward(operation)
})
