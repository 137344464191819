import { FeatureToggles } from './use-payment-feature-toggles'

export const isNonSavedAdyenPaymentEnabled = (paymentMethod: string, featureToggles: FeatureToggles) =>
  (paymentMethod !== 'ApplePay' &&
    paymentMethod !== 'GooglePay' &&
    paymentMethod !== 'GiftCard' &&
    paymentMethod !== 'ClickToPay' &&
    paymentMethod !== 'Afterpay' &&
    paymentMethod !== 'GrabPay' &&
    paymentMethod !== 'FPX' &&
    paymentMethod !== 'PayPay') ||
  (paymentMethod === 'ApplePay' && featureToggles.applePayEnabled) ||
  (paymentMethod === 'GooglePay' && featureToggles.googlePayEnabled) ||
  (paymentMethod === 'GiftCard' && featureToggles.giftCardEnabled) ||
  (paymentMethod === 'ClickToPay' && featureToggles.adyenClickToPayEnabled) ||
  (paymentMethod === 'Afterpay' && featureToggles.afterpayEnabled) ||
  (paymentMethod === 'GrabPay' && featureToggles.grabPayEnabled) ||
  (paymentMethod === 'FPX' && featureToggles.fpxEnabled) ||
  (paymentMethod === 'PayPay' && featureToggles.payPayEnabled)
