import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GenericSelect } from '@dominos/components'
import { useValidation } from '@dominos/business/functions'
import { AdditionalField } from '../additional-field'
import { getAddressComponentFromDeliveryAddress } from '@dominos/business/functions/address/autocomplete'
import { validate } from '@dominos/business/functions/validation'
import { TFunction } from 'i18next'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

const SELECT_HEIGHT = 44
export type AddressType = 'landed' | 'nonLanded'

export const AdditionalFieldsLayoutMy = ({ testID, address, onInit, onChange }: AdditionalFieldsLayoutProps) => {
  const { t } = useTranslation('delivery-address')
  const { isRequired, maxLength } = useValidation()
  const [addressType, setAddressType] = useState<AddressType>()
  const { reportSelectedLandedAddress, reportSelectedNonLandedAddress } = useReportAutoCompleteDelivery()

  useEffect(() => {
    if (onInit) {
      onInit({
        streetNo: {
          isValid: addressType === 'nonLanded' || isValid('streetNo', [isRequired, maxLength(40)]),
        },
        streetName: {
          isValid: isValid('streetName', [isRequired, maxLength(50)]),
        },
        buildingName: {
          isValid: addressType === 'landed' || isValid('buildingName', [isRequired, maxLength(40)]),
        },
      })
    }
  }, [addressType])

  const onSelectChange = (value: string) => {
    // workaround for `GenericSelect` not providing option to type `options`
    const isLanded = value === 'landed'
    const isNonLanded = value === 'nonLanded'
    const hasAddressType = isLanded || isNonLanded
    hasAddressType && setAddressType(value)
    if (isLanded) reportSelectedLandedAddress()
    if (isNonLanded) reportSelectedNonLandedAddress()
  }

  const isValid = (type: keyof CustomerAddressRequest, validationRules: ValidationFunction<string>[]): boolean => {
    const initialValue = getAddressComponentFromDeliveryAddress(address, type)?.value
    if (!initialValue) return false

    return validate(initialValue, ...validationRules) === null
  }

  return (
    <>
      {/* If initial value is undefined in the AdditionalField component, and there's no match of `fieldName` in 
      getAddressComponentFromDeliveryAddress (which is derived from Google's structure?), the AdditionalField component 
      will not result in an AddressComponent being added for it to the address, and hence will not be beholden to the validation rules.
      */}
      <GenericSelect
        testID={`${testID}.addressType`}
        options={getOptions(t)}
        placeholder={t('addressType')}
        selectedValue={addressType}
        height={SELECT_HEIGHT}
        onChange={onSelectChange}
      />
      {addressType === 'landed' && (
        <>
          <AdditionalField
            testID={testID}
            fieldName={'streetNo'}
            placeholder={t('Unit Number')}
            onChange={onChange}
            validationRules={[isRequired, maxLength(40)]}
            initialValue={''}
            address={address}
          />
          <AdditionalField
            testID={testID}
            fieldName={'streetName'}
            placeholder={t('Street Name')}
            onChange={onChange}
            validationRules={[isRequired, maxLength(50)]}
            initialValue={''}
            address={address}
            hideWhenHasValue={true}
          />
          <AdditionalField
            testID={testID}
            fieldName={'buildingName'}
            placeholder={t('Building Name')}
            onChange={onChange}
            validationRules={[maxLength(40)]}
            initialValue={''}
            address={address}
          />
        </>
      )}
      {addressType === 'nonLanded' && (
        <>
          <AdditionalField
            testID={testID}
            fieldName={'streetNo'}
            placeholder={t('Unit Number')}
            onChange={onChange}
            validationRules={[isRequired, maxLength(40)]}
            address={address}
          />
          <AdditionalField
            testID={testID}
            fieldName={'streetName'}
            placeholder={t('Street Name')}
            onChange={onChange}
            validationRules={[isRequired, maxLength(50)]}
            initialValue={''}
            address={address}
            hideWhenHasValue={true}
          />
          <div style={container.fieldWrapper}>
            <AdditionalField
              testID={testID}
              fieldName={'blockNo'}
              placeholder={t('Block Number')}
              onChange={onChange}
              validationRules={[maxLength(40)]}
              initialValue={''}
              address={address}
              containerStyle={{ ...container.field, marginRight: 4 }}
            />
            <AdditionalField
              testID={testID}
              fieldName={'floorNo'}
              placeholder={t('Floor number')}
              onChange={onChange}
              validationRules={[maxLength(40)]}
              initialValue={''}
              address={address}
              containerStyle={{ ...container.field, marginLeft: 2, marginRight: 2 }}
            />
            <AdditionalField
              testID={testID}
              fieldName={'unitNo'}
              placeholder={t('Unit')}
              onChange={onChange}
              validationRules={[maxLength(40)]}
              initialValue={''}
              address={address}
              containerStyle={{ ...container.field, marginLeft: 4 }}
            />
          </div>
          <AdditionalField
            testID={testID}
            fieldName={'buildingName'}
            placeholder={t('Building Name')}
            onChange={onChange}
            validationRules={[isRequired, maxLength(40)]}
            initialValue={''}
            address={address}
          />
        </>
      )}
    </>
  )
}

const container = {
  fieldWrapper: { display: 'flex', justifyContent: 'space-between' },
  field: { flex: 1 },
}

const getOptions = (t: TFunction): GenericSelectItem[] => [
  {
    label: t('landed'),
    value: 'landed',
  },
  {
    label: t('nonLanded'),
    value: 'nonLanded',
  },
]
