import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 135,
  height: 135,
}

const PizzaSlice1 = (props: SvgIconProps = defaultDimensions) => {
  const { width, height } = props

  return (
    <svg width={width} height={height} viewBox='0 0 135 135' version='1.1'>
      <title>Pizza Copy</title>
      <g id='Payment-Loader-|-Desktop' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Slice-1' fill='#E21836' transform='translate(68.001196, 0.000000)'>
          <path
            d='M43.2900495,20.0439468 C44.240979,19.1081822 44.2433743,17.5619314 43.2565154,16.6638803 C32.4489736,6.81124551 18.1754493,0.590885913 2.45996131,0.00161349916 C1.11620445,-0.0478853836 0,1.04816131 0,2.37284569 L0,9.38518741 C14.9202522,9.38518741 28.4512371,15.3580526 38.2527577,25.0009064 L43.2900495,20.0439468 Z'
            id='Fill-18'
          />
          <path
            d='M7.4508996,44.9362929 C8.53007829,45.0345766 9.32323857,45.9862502 9.22469441,47.0625763 C9.12615025,48.1365053 8.1719544,48.9299662 7.09517923,48.8316825 C6.01600055,48.7333988 5.22043675,47.7817252 5.31898091,46.7053991 C5.41752507,45.629073 6.37172092,44.8380092 7.4508996,44.9362929 M13.075127,23.7357847 C15.5916083,23.9659123 17.4447191,26.1856851 17.2163851,28.6955146 C16.9856476,31.2053441 14.7599918,33.0535567 12.2435105,32.823429 C9.72702925,32.5933014 7.87391842,30.3735286 8.10465595,27.8636991 C8.33298997,25.3538696 10.5610493,23.5056571 13.075127,23.7357847 M18.7834775,38.3369036 C18.6176349,40.1299814 17.0265073,41.4508181 15.2310809,41.2854139 C13.4332509,41.1224068 12.1113171,39.5378822 12.2747562,37.7448044 C12.4381953,35.9517265 14.0293229,34.6308898 15.8247493,34.7962941 C17.6225793,34.9593011 18.9469166,36.5462229 18.7834775,38.3369036 M21.8575745,29.1174153 C22.9367532,29.215699 23.7299135,30.1673726 23.6313693,31.2436987 C23.5328252,32.3200248 22.5786293,33.1110886 21.5018541,33.0128049 C20.4226755,32.9145212 19.6271117,31.9628476 19.7256558,30.8865215 C19.8242,29.8101953 20.7783958,29.0191316 21.8575745,29.1174153 M2.8697981,60.5058656 L35,28.4605927 C26.0420959,19.5263667 13.6687955,14.0009064 0,14.0009064 L0,59.31927 C0,60.8150996 1.81225106,61.5630144 2.8697981,60.5058656'
            id='Fill-21'
          />
        </g>
      </g>
    </svg>
  )
}

export default PizzaSlice1
