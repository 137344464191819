import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 24,
  height: 24,
}

export const ChickenIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#494A4B'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 24 24'>
      <defs>
        <polygon id='path-1' points='0 0 4.38482404 0 4.38482404 4.38449896 0 4.38449896' />
        <polygon id='path-3' points='0 0 16.9025 0 16.9025 16.116104 0 16.116104' />
      </defs>
      <g id='Menu-Icons' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group-11' transform='translate(0.000000, 1.000000)'>
          <path
            d='M2.8756,3.78262404 C3.7536,4.61562404 5.1416,4.57962404 5.9746,3.70162404 C6.8086,2.82362404 6.7726,1.43562404 5.8936,0.602624045 C5.0156,-0.231375955 3.6276,-0.195375955 2.7946,0.683624045 C1.9606,1.56162404 1.9976,2.94962404 2.8756,3.78262404'
            id='Fill-1'
            fill={fill}
          />
          <g id='Group-5' transform='translate(0.000000, 2.431100)'>
            <mask id='mask-2' fill='white'>
              <use xlinkHref='#path-1' />
            </mask>
            <g id='Clip-4' />
            <path
              d='M0.6832,3.78262404 C1.5612,4.61562404 2.9492,4.57962404 3.7822,3.70162404 C4.6162,2.82362404 4.5802,1.43562404 3.7012,0.602624045 C2.8232,-0.231375955 1.4352,-0.195375955 0.6022,0.683624045 C-0.2318,1.56162404 -0.1948,2.94962404 0.6832,3.78262404'
              id='Fill-3'
              fill={fill}
              mask='url(#mask-2)'
            />
          </g>
          <path
            d='M8.013,5.64742404 L5.072,2.72642404 L2.773,5.14942404 L5.686,8.04342404 C6.133,6.93742404 6.938,6.11742404 8.013,5.64742404'
            id='Fill-6'
            fill={fill}
          />
          <g id='Group-10' transform='translate(6.577500, 6.784970)'>
            <mask id='mask-4' fill='white'>
              <use xlinkHref='#path-3' />
            </mask>
            <g id='Clip-9' />
            <path
              d='M14.134,9.19885422 C15.196,9.13985422 15.662,8.27185422 15.194,7.52485422 C15.049,7.29385422 14.835,7.09685422 14.624,6.91885422 C13.474,5.95085422 12.315,4.99385422 11.158,4.03385422 C10.836,3.76585422 10.534,3.46485422 10.183,3.23985422 C9.498,2.80085422 8.636,3.15685422 8.453,3.93285422 C8.333,4.44185422 8.596,4.82885422 8.948,5.12885422 C10.44,6.39685422 11.941,7.65285422 13.457,8.89185422 C13.685,9.07685422 14.009,9.14385422 14.134,9.19885422 M0,5.36085422 C0.073,4.53285422 0.053,3.68185422 0.238,2.87885422 C0.626,1.18485422 1.834,0.218854216 3.568,0.0408542162 C4.765,-0.0821457838 5.94,0.0888542162 7.097,0.336854216 C9.374,0.823854216 11.497,1.70885422 13.347,3.13885422 C14.114,3.73185422 14.853,4.40485422 15.452,5.16185422 C17.491,7.74185422 17.433,11.1528542 14.947,13.7188542 C13.668,15.0398542 12.113,15.7808542 10.263,16.0298542 C6.711,16.5078542 4.194,14.9698542 2.294,12.1408542 C0.922,10.0958542 0.148,7.84685422 0,5.36085422'
              id='Fill-8'
              fill={fill}
              mask='url(#mask-4)'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
