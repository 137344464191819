import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { kioskConfigQuery } from '@dominos/business/queries'
import { useKiosk, useLocationExtended } from '@dominos/hooks-and-hocs'

export const useKioskConfigQuery = (injectKioskHeaderEnabled: boolean | undefined) => {
  const { searchParams } = useLocationExtended()
  const { kioskId } = useKiosk()
  const [config, setConfig] = useState<Partial<Bff.Kiosk.Configuration>>({})

  const headerKioskId = (searchParams.get('kioskId') ?? kioskId)?.toLowerCase()
  const kioskHeaderOptions = injectKioskHeaderEnabled
    ? { context: { headers: { 'x-forwarded-for': headerKioskId } } }
    : {}
  const [fetchKioskConfig, { data, loading, error, called }] = useLazyQuery<{ kioskConfig: Bff.Kiosk.Configuration }>(
    kioskConfigQuery,
    {
      ...kioskHeaderOptions,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    },
  )

  useEffect(() => {
    if (data?.kioskConfig?.kioskId) {
      setConfig(data.kioskConfig)
    }
  }, [data])

  return {
    fetchKioskConfig,
    kioskConfig: config,
    loading,
    error,
    called,
  }
}
