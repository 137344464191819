import { StaticSvgIcon } from '@dominos/res'
import { useDominosTheme } from '@dominos/hooks-and-hocs/theme'
import React, { useState } from 'react'
import { SvgIconsProps } from 'res/interfaces/icon.interfaces'
import css from './notification-card.less'

export interface InformationPanelProps extends Partial<BaseProps> {
  heading: string
  message?: string
  icon?: SvgIconsProps
  onClose?: () => void
}

export const NotificationCard = ({
  testID = 'NotificationCard',
  heading,
  message,
  icon,
  onClose,
}: InformationPanelProps) => {
  const [showInformationPanel, setShowInformationPanel] = useState<boolean>(true)
  const theme = useDominosTheme()

  const onCloseInformationPanel = () => {
    setShowInformationPanel(false)
    onClose && onClose()
  }

  if (!showInformationPanel) return <> </>

  return (
    <div className={css.wrapper} data-testid={testID}>
      {icon && (
        <div aria-hidden={true} className={css.icon} data-testid={`${testID}.icon`}>
          <StaticSvgIcon {...icon} />
        </div>
      )}
      <div className={css.content}>
        <div data-testid={`${testID}.heading`} className={css.heading} role='alert'>
          {heading}
        </div>
        {message && (
          <div data-testid={`${testID}.message`} className={css.message}>
            {message}
          </div>
        )}
      </div>
      <div className={css.closeButtonWrapper} onClick={onCloseInformationPanel} data-testid={`${testID}.closeButton`}>
        <StaticSvgIcon name='cross' width={20} height={20} fill={theme.colours.dominosSlate} />
      </div>
    </div>
  )
}
