import React from 'react'
import { StaticSvgIcon } from '@dominos/res'
import { ReactElement } from 'react'
import css from '../product-ingredient-item.less'
import classnames from 'classnames'
import { SvgIconsProps } from '@dominos/res/interfaces/icon.interfaces'
import { AdjustmentButtonProps } from '../product-ingredient-item.interface'

const ICON_SIZE = 25

export const AdjustmentButton = ({
  onChange,
  styles,
  adjustmentQuantity,
  testID,
  role,
  icon,
  disabled,
}: AdjustmentButtonProps): ReactElement => {
  const handleAdjustmentButtonClick = () => !disabled && onChange(adjustmentQuantity)

  return (
    <div
      data-testid={testID}
      role={role}
      onClick={handleAdjustmentButtonClick}
      className={classnames(css.button, disabled && css.buttonDisabled)}
      style={styles}
    >
      <StaticSvgIcon
        isUnstyled
        name={icon as SvgIconsProps['name']}
        width={ICON_SIZE}
        height={ICON_SIZE}
        fill={'#FFF'}
      />
    </div>
  )
}
