import React from 'react'
import { Crumb } from './crumb'
import css from './breadcrumb.less'
import { useFeatures } from '@dominos/hooks-and-hocs'
import { isNativeApp } from '@dominos/business/functions'

interface BreadcrumbProps extends BaseProps {
  flowDepth: number
  entities: string[]
  onCrumbPress?: (index: number) => void
  textNamespace?: string[]
}

export const Breadcrumb = ({
  flowDepth,
  entities,
  onCrumbPress = () => {},
  textNamespace,
  testID,
}: BreadcrumbProps) => {
  const [showBreadcrumb] = useFeatures('ShowBreadcrumbBarOrdering')
  const handleCrumbPress = (index: number) => {
    if (flowDepth !== index) {
      onCrumbPress(index)
    }
  }

  if (isNativeApp() || !showBreadcrumb) return null

  return (
    <div data-testid={`${testID}-container`} className={css.crumbsContainer}>
      {entities.map((item, index) => (
        <Crumb
          key={index}
          isCrumbActive={flowDepth === index}
          isLastCrumb={index === entities.length - 1}
          text={item}
          onCrumbPress={() => handleCrumbPress(index)}
          textNamespace={textNamespace}
          testID={`${testID}-${index}`}
        />
      ))}
    </div>
  )
}
