import React, { useEffect, useState } from 'react'
import { ActionButton } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { useOrderLaterDescriptions } from '@dominos/hooks-and-hocs/ordering/use-order-later-descriptions'
import { OrderLaterNowContainerProps } from '../../interfaces'
import styles from './order-later-now-container.less'

import { PickerAndActionButtons, useOrderLaterOrNowDescriptions } from './partials'
import { PreOrderETA } from '../order-now-container/partials'
import { useOrderNowDescriptions } from '@dominos/hooks-and-hocs/ordering/use-order-now-descriptions'

export const OrderLaterOrNowContainer = ({
  currentStore,
  loading,
  serviceMethod,
  onStartOrderAction,
  t,
  openingHours,
  language,
  isAsapAvailable,
  hasDayOfTradeTimeSlots,
}: OrderLaterNowContainerProps) => {
  const theme = useDominosTheme()
  const { orderTimePickerTitle } = useOrderLaterDescriptions(serviceMethod, t)

  const { nowButtonTitle, laterButtonTitle } = useOrderLaterOrNowDescriptions(t)

  const { serviceMethodTitle } = useOrderNowDescriptions(serviceMethod, t)

  const [laterContentExpanded, setLaterContentExpand] = useState<boolean>(false)

  useEffect(() => {
    setLaterContentExpand(!isAsapAvailable)
  }, [isAsapAvailable])

  const laterButtonStyles = {
    containerStyle: { marginLeft: 5, flex: 1, backgroundColor: theme.colours.actionBlueBackgound, minHeight: 35 },
    testStyle: {
      color: theme.colours.actionBlue,
    },
  }

  return (
    <div
      data-testid={'order-later-now'}
      className={styles.container}
      style={{
        borderRadius: theme.webLargeBorderRadius,
        backgroundColor: theme.colours.defaultBackground,
      }}
    >
      <div>
        <h3 className={styles.h3}> {orderTimePickerTitle}</h3>
        {!laterContentExpanded && (
          <div className={styles.preOrderETAContainer}>
            <PreOrderETA
              testID='pre-order-eta'
              loading={loading!}
              serviceMethodTitle={serviceMethodTitle!}
              serviceMethod={serviceMethod}
              currentStore={currentStore}
              t={t}
            />
          </div>
        )}
        {isAsapAvailable ? (
          <div className={styles.buttonWrapper} style={container.buttonWrapper}>
            <ActionButton
              text={nowButtonTitle}
              disabled={loading || !isAsapAvailable}
              onPress={onStartOrderAction}
              testID='start-order-now-button'
              loading={loading}
              disabledBackgroundColor='rgba(31, 195, 106, 0.2)'
              disabledTextColor='#1FC36A'
              textFontWeight='bold'
              containerStyle={nowButtonStyles.containerStyle}
            />
            <ActionButton
              text={laterButtonTitle}
              disabled={loading || !hasDayOfTradeTimeSlots || !isAsapAvailable}
              onPress={() => setLaterContentExpand(true)}
              testID='order-later-expander-button'
              loading={loading}
              disabledBackgroundColor='rgba(74, 74, 74, 0.2)'
              disabledTextColor='#4A4A4A'
              textFontWeight='bold'
              containerStyle={laterButtonStyles.containerStyle}
              textStyle={laterButtonStyles.testStyle}
            />
          </div>
        ) : null}
      </div>
      {!loading && laterContentExpanded && (
        <PickerAndActionButtons
          openingHours={openingHours}
          onStartOrderAction={onStartOrderAction}
          serviceMethod={serviceMethod}
          language={language}
          t={t}
          testID={'OrderLaterContainer'}
        />
      )}
    </div>
  )
}

const container = { buttonWrapper: { display: 'flex', justifyContent: 'space-between' } }
const nowButtonStyles = { containerStyle: { marginRight: 5, flex: 1, minHeight: 35 } }
