import React from 'react'
import { Asterisk } from '../asterisk/asterisk'
import { PriceValue } from '../price-value'
import css from './product-price.less'

type colorType = Pick<typeof css, 'default' | 'promo'>

export interface ProductPriceProps extends BaseProps {
  price?: string | null
  variant?: keyof colorType
}

export const ProductPrice = ({ price, testID, variant = 'default' }: ProductPriceProps) => (
  <div data-testid={testID} className={css[variant]}>
    <PriceValue testID={`${testID}.value`} price={price} />
    <Asterisk testID={`${testID}.asterisk`} />
  </div>
)
