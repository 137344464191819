import { ActionButton } from '@dominos/components'
import { useBreakpoints, useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import css from './hard-upsell-action-buttons.less'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

export interface HardUpsellButtonsProps extends BaseProps {
  isItemSelected?: boolean
  onSkip?: () => void
  onAddToOrder?: () => void
}

export const HardUpsellActionButtons = ({ testID, isItemSelected, onSkip, onAddToOrder }: HardUpsellButtonsProps) => {
  const { colours } = useDominosTheme()
  const shouldDisable = !isItemSelected
  const { t } = useTranslation('basket')
  const isTextLong = t('AddButtonHardUpsell').length >= 21
  const { isTablet } = useBreakpoints()
  const isButtonColumn = isTextLong && !isTablet

  return (
    <div
      data-testid={`${testID}.container`}
      className={classnames(css.container, isButtonColumn && css.longTextContainer)}
    >
      <ActionButton
        testID={`${testID}.skip.button`}
        onPress={onSkip}
        textFontWeight={'semibold'}
        disabledBackgroundColor={colours.buttonDisabled}
        className={classnames(css.skipButton, isButtonColumn && css.longText)}
      >
        <div data-testid={`${testID}.place.container`} className={css.button}>
          <div>{t('SkipButtonHardUpsell')}</div>
        </div>
      </ActionButton>
      <ActionButton
        testID={`${testID}.order.button`}
        disabled={shouldDisable}
        onPress={onAddToOrder}
        textFontWeight={'semibold'}
        disabledBackgroundColor={colours.buttonDisabled}
        disabledTextColor={colours.buttonDisabledText}
        className={classnames(
          css.placeOrderButton,
          isButtonColumn && css.longText,
          !shouldDisable && css.placeOrderContainer,
        )}
      >
        <div
          data-testid={`${testID}.place.container`}
          style={{
            color: shouldDisable ? colours.buttonDisabledText : colours.defaultButtonText,
          }}
          className={css.button}
        >
          <div data-testid={`${testID}.place.text`}>{t('AddButtonHardUpsell')}</div>
        </div>
      </ActionButton>
    </div>
  )
}
