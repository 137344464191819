const orderQueryParamName = 'order'

export const getOrderIdFromQueryString = (queryString: string) => {
  if (queryString) {
    const params = new URLSearchParams(queryString)

    return params.get(orderQueryParamName) ?? undefined
  }

  return undefined
}
