import { rootActions } from '@dominos/business'
import { getCountryConfig } from '@dominos/business/functions/common/get-config'
import { FormResolverHandlerConstants } from '@dominos/components/form-resolver'
import { PaymentSetting, UseInitiateOrderProps } from '@dominos/hooks-and-hocs'
import { analytics } from '@dominos/hooks-and-hocs/logging/analytics'
import { NavigationConstants } from '@dominos/navigation'
import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { PaymentMethod } from '../payment-method'
import { PaymentMethodProps } from '../payment-method.interface'

export const getGMOReturnUrl = (): string => {
  const { getGoogleID } = analytics()
  const gid = getGoogleID()
  const graphMatches = getCountryConfig().GRAPH_URL.match(/^https?:\/\/[^#?\/]+/i)
  const graphBaseUrl = graphMatches && graphMatches[0]
  const params = new URLSearchParams({
    redirect: `${window.location.origin}/${NavigationConstants.formResolver}`,
    handler: FormResolverHandlerConstants.gmoFormPost,
  })

  if (gid) {
    params.set('ga_redirect_id', gid)
  }

  return `${graphBaseUrl}/form/reflect?${params.toString()}`
}

export const PaymentGMO = ({ paymentSetting, selectedPaymentSetting, isValidatingBasket }: PaymentMethodProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('checkout')

  const [redirectUrl, setRedirectUrl] = useState<string>()
  const [accessId, setAccessId] = useState<string>()
  const [gmoToken, setGMOToken] = useState<string>()

  const formRef = React.createRef<HTMLFormElement>()

  const initialProperties = [{ key: 'returnUrl', value: getGMOReturnUrl() }]

  useEffect(() => {
    if (redirectUrl && accessId && gmoToken) {
      formRef.current?.submit()
    }
  }, [redirectUrl, accessId, gmoToken])

  const onInitiateOrderReady: UseInitiateOrderProps['onReady'] = ({ token, properties }) => {
    dispatch(rootActions.setTransactionToken(token))
    setRedirectUrl(`${properties?.find((pp) => pp.key === 'redirectTo')?.value}`)
    setAccessId(token)
    setGMOToken(`${properties?.find((pp) => pp.key === 'token')?.value?.replace(/\s/g, '+')}`)
  }

  const isValidGMOPayment = () =>
    ['AuMobile', 'Docomo', 'LinePay', 'Softbank', 'Rakuten'].includes(paymentSetting.paymentMethod)

  const shouldShowOnDevice = () => isMobileOnly || paymentSetting.paymentMethod === 'Rakuten'

  const brandColors: Partial<Record<PaymentSetting['paymentMethod'], string>> = {
    AuMobile: '#ea5404',
    Docomo: '#cc0033',
    Rakuten: '#bf2025',
    LinePay: '#00c300',
    Softbank: '#b6bbbe',
  }

  return isValidGMOPayment() && shouldShowOnDevice() ? (
    <PaymentMethod
      methodId={`${paymentSetting.paymentMethod}`}
      paymentSetting={paymentSetting}
      invalid={false}
      transactionToken={'token'}
      properties={initialProperties}
      selectedPaymentSetting={selectedPaymentSetting}
      isValidatingBasket={isValidatingBasket}
      onInitiateOrderReady={onInitiateOrderReady}
      paymentButtonIcon={`gmo${paymentSetting.paymentMethod}`}
      paymentButtonColor={brandColors[paymentSetting.paymentMethod] ?? '#005aac'}
      paymentButtonTitle={t(`${paymentSetting?.paymentMethod} Checkout`, {
        defaultValue: `Pay with ${paymentSetting?.paymentMethod}`,
      })}
    >
      <form
        method='post'
        action={redirectUrl}
        data-testid={`gmo.${paymentSetting.paymentMethod}.auth-form`}
        ref={formRef}
        style={{ display: 'none' }}
      >
        <input type='hidden' name='AccessID' value={accessId} />
        <input type='hidden' name='Token' value={gmoToken} />
      </form>
    </PaymentMethod>
  ) : null
}
