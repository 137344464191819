import { GraphQLRequest } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import uuid from 'uuid'

export const dpeHeadersLink = (setup: GraphSetup) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (setContext as any)(async (_: GraphQLRequest, prevContext: any) => {
    const version = await setup.version

    return {
      headers: {
        ...prevContext.headers,
        'dpe-correlationid': uuid(),
        'dpe-application': setup.application,
        'dpe-application-version': setup.applicationVersion,
        'dpe-version': version,
        'dpe-language': setup.language,
        'dpe-country': setup.countryCode,
        'dpe-sessionid': setup.sessionID,
        'dpe-env': setup.environment || '',
        'dpe-pushnotificationclientid': setup.pushNotificationClientId || '',
      },
    }
  })
