import { DimensionSetState, ProductData, useMarket } from '@dominos/hooks-and-hocs'
import { getFullUrl } from '@dominos/business/functions'
import { useMemo } from 'react'
export const useBaseData = (productData: ProductData | undefined, dimensionSetState: DimensionSetState | undefined) => {
  const { getMediaForDimension } = useMarket()

  return useMemo(
    () => ({
      items:
        dimensionSetState?.getDimensionValuesByType('Base').map((base) => {
          const dimensionMedia = getMediaForDimension('Base', base)

          return {
            code: base,
            name: dimensionMedia?.name.value ?? '',
            image: dimensionMedia?.iconImage?.uri ? getFullUrl(dimensionMedia?.iconImage?.uri) : undefined,
            price: productData?.getPriceDifferenceForDimensionChange(
              dimensionSetState?.selectedDimensionSet,
              'Base',
              base,
            ),
          }
        }) ?? [],
      selectedItemCode: dimensionSetState?.getSelectedDimension('Base'),
    }),
    [dimensionSetState?.selectedDimensionSet],
  )
}
