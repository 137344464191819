import { BasketLineData } from '@dominos/business/functions/basket'
import { getDefaultPizzaToppings } from '@dominos/business/functions/product'
import { isProductMenuItemNew } from '@dominos/components'

type ProductMenuItem = Bff.Menu.old.ProductMenuItem
type RecipeIngredient = Bff.Menu.old.RecipeIngredient
type PortionMenuItem = Bff.Menu.old.PortionMenuItem

const mapTopping = ({
  code,
  quantity,
}: Pick<BasketLineChange, 'code' | 'quantity'>): Bff.PriceProduct.PriceProductComponent => ({
  quantity,
  componentCode: code,
})

const mapSauce = ({ add }: Pick<BasketLineSwap, 'add'>): Bff.PriceProduct.PriceProductComponent => ({
  componentCode: add || '',
  quantity: 1,
})

const mapCrust = ({ add }: Pick<BasketLineSwap, 'add'>): Bff.PriceProduct.PriceProductComponent => ({
  componentCode: add || '',
  quantity: 1,
})

const getToppings = (portion: BasketLineData | undefined) => {
  const basketLineToppings = portion?.toppings || []
  const defaultRecipe = getDefaultPizzaToppings(portion?.item as ProductMenuItem).map(
    mapRecipeIngredientToBasketLineChange,
  )

  const recipeToppings = defaultRecipe.map((recipeIngredient) => {
    const customisedIngredient = basketLineToppings.find((topping) => topping.code === recipeIngredient.code)

    if (customisedIngredient) {
      return {
        ...recipeIngredient,
        quantity:
          recipeIngredient.quantity +
          (customisedIngredient.action === 'Add' ? customisedIngredient.quantity : -customisedIngredient.quantity),
      }
    }

    return recipeIngredient
  })

  const recipeToppingCodes = recipeToppings.map((t) => t.code)
  const customisedToppings = basketLineToppings.filter(({ code }) => !recipeToppingCodes.includes(code))

  return [...recipeToppings, ...customisedToppings].map(mapTopping)
}

const mapPortion = (portion: BasketLineData | undefined) => ({
  productCode: portion?.item && !isProductMenuItemNew(portion.item) ? portion?.item?.code! : undefined!,
  sauces: portion?.sauce ? [portion?.sauce].map(mapSauce) : [],
  toppings: getToppings(portion),
  options: [],
})

const mapRecipeIngredientToBasketLineChange = (recipeIngredient: RecipeIngredient): BasketLineChange => ({
  action: 'Add',
  code: recipeIngredient.ingredient.code,
  quantity: recipeIngredient.quantity,
  media: {
    name: recipeIngredient.ingredient.media?.name || '',
  },
})

const mapBasketLineDataToHalfNHalfPriceProduct = (
  baseProduct: BasketLineData,
  portions: (BasketLineData | undefined)[],
): Bff.PriceProduct.PriceProduct => {
  const priceProduct = mapBasketLineDataToPriceProduct(baseProduct)

  return {
    ...priceProduct,
    portions: portions.map(mapPortion),
  }
}

const mapBasketLineDataToPriceProduct = (product: BasketLineData): Bff.PriceProduct.PriceProduct => ({
  quantity: 1,
  productCode: product.item && !isProductMenuItemNew(product.item) ? product.item?.code! : undefined!,
  productSizeCode: product.sizeCode || (product.item as ProductMenuItem | PortionMenuItem)?.defaultSize || ''!,
  crusts: product.base ? [product.base].map(mapCrust) : [],

  portions: [mapPortion(product)],
})

export { mapBasketLineDataToHalfNHalfPriceProduct, mapBasketLineDataToPriceProduct }
