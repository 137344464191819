import { MyDetailsModelProps } from '@dominos/interfaces'
import { mapCustomerToValidateBasket } from './build-validate-request'

const mapDeliveryAddress = (value: Bff.Orders.OrderDetails.Header.DeliveryAddress): ValidateBasketDeliveryAddress => ({
  displayAddress: value?.displayAddress,
  address: {
    buildingAccessCode: value.buildingAccessCode,
    buildingName: value.buildingName,
    unitNo: value.address.unitNo,
    streetNo: value.address.streetNo,
    street: value.address.street,
    suburb: value.address.suburb,
    postCode: value.address.postCode,
    state: value.address.state,
    floorNo: value.address.floorNo,
  },
  latitude: value.address?.location?.latitude,
  longitude: value.address?.location?.longitude,
  locale: value.locale,
})

const mapToBasketLineSwap = (
  sauce?: Bff.Orders.OrderDetails.Basket.ProductLineSwap,
): ValidateBasketLineSwap | undefined => {
  if (!sauce) {
    return undefined
  }

  return {
    add: sauce.add ?? null,
    remove: sauce.remove ?? '',
  }
}

const mapToBasketLineChange = (
  lineChange: Bff.Orders.OrderDetails.Basket.ProductLineChange,
): ValidateBasketLineChange => ({
  action: lineChange.action as BffContext.LineChangeActionTypes,
  code: lineChange.code,
  quantity: lineChange.quantity,
})

const mapToValidateBasketLine = (line: Bff.Orders.OrderDetails.Basket.ProductLine): ValidateBasketLine => ({
  lineNo: line.itemNo,
  productCode: line.productCode,
  sizeCode: line.sizeCode ?? null,
  quantity: line.quantity,
  base: mapToBasketLineSwap(line.base),
  sauce: mapToBasketLineSwap(line.sauce),
  toppings: line.toppings?.map(mapToBasketLineChange),
  options: line.options?.map(mapToBasketLineChange),
  portions: line.portions?.map((portion) => ({
    productCode: portion.productCode,
    sauce: mapToBasketLineSwap(portion.sauce),
    toppings: portion.toppings?.map(mapToBasketLineChange),
    options: portion.options?.map(mapToBasketLineChange),
  })),
})

const mapCouponLineToValidateBasketLine = (
  lines: Bff.Orders.OrderDetails.Basket.VoucherLine[],
): ValidateBasketCoupon[] =>
  lines.map(
    (line): ValidateBasketCoupon => ({
      couponNo: line.voucherNo,
      code: line.code,
      isLoyaltyRedemption: line.isLoyaltyRedemption as boolean,
      items: mapCouponLineProductsToVoucherItems(line.items),
    }),
  )

const mapCouponLineProductsToVoucherItems = (
  lineItems: Bff.Orders.OrderDetails.Basket.ProductLine[],
): ValidateBasketCouponItem[] => {
  const maxVoucherItemNo = Math.max(...lineItems.map((l) => l.voucherItemNo ?? 0))
  const items = []
  for (let index = 1; index <= maxVoucherItemNo; index++) {
    items.push({
      voucherItemNo: index,
      products: lineItems.filter((l) => l.voucherItemNo === index).map((l) => l.itemNo),
    })
  }

  return items
}

const mapProductLineToValidateBasketLine = (
  lines: Bff.Orders.OrderDetails.Basket.ProductLine[],
): ValidateBasketLine[] => lines.map((line): ValidateBasketLine => mapToValidateBasketLine(line))

export const buildBasketRequestFromOrder = (
  order: Bff.Orders.Order,
  advanceOrderId: string,
  customerDetails: MyDetailsModelProps | undefined,
  customerId: string | undefined,
): MutationWrapper<ValidateBasketInput> | null => {
  if (!order.store?.storeNo || !order.details) {
    return null
  }

  const couponLines = order.details.basket.lines.filter((line) => line.type === 'CouponLine')
  const allProductLines = [
    couponLines.map((line) => (line as VoucherLine).items).flat(),
    order.details.basket.lines.filter((line) => line.type === 'ProductLine'),
  ].flat()

  return {
    input: {
      id: order.id,
      advanceOrderId: advanceOrderId,
      header: {
        storeNo: order.store?.storeNo,
        serviceMethod: order.details.header.serviceMethod,
        time: order.details.header.orderTime ?? null,
        deliveryAddress: order.details.header.deliveryAddress
          ? mapDeliveryAddress(order.details.header.deliveryAddress)
          : undefined,
        customer: mapCustomerToValidateBasket(customerDetails, customerId),
      },
      body: {
        lines: mapProductLineToValidateBasketLine(allProductLines as Bff.Orders.OrderDetails.Basket.ProductLine[]),
        coupons: mapCouponLineToValidateBasketLine(couponLines as Bff.Orders.OrderDetails.Basket.VoucherLine[]),
      },
      payment: [],
    },
  }
}
