import { getPaymentCardFormat } from '@dominos/business/functions'
import { DetailRow } from '@dominos/components/basket/detail-row'
import { PaymentCard } from '@dominos/components/cards/payment-card'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './split-payment.less'

export interface SplitPaymentOutstandingBalance {
  orderTotal: number
  processedPaymentName: string
  orderOutstandingBalance: number
}

export const SplitPayment = ({
  testID,
  outstandingBalance,
  paymentMethod,
}: {
  testID: string
  outstandingBalance: SplitPaymentOutstandingBalance
  paymentMethod?: string
}) => {
  const theme = useDominosTheme()
  const { t } = useTranslation('checkout')
  const paymentCardFormat = getPaymentCardFormat(paymentMethod)
  // TODO - get gift card balance (processed payment amount) from BE
  const processedPaymentAmount = outstandingBalance.orderTotal - outstandingBalance.orderOutstandingBalance

  return (
    <section
      className={styles.section}
      style={{
        borderRadius: theme.webLargeBorderRadius,
        backgroundColor: theme.colours.defaultBackground,
      }}
      data-testid={`${testID}`}
    >
      <header className={styles.container} role={'title'} data-testid={`${testID || 'partial-payment-card'}-header`}>
        <h3 className={styles.h3} data-testid={`${testID || 'partial-payment-card'}-title`}>
          {t('Payment Method')}
        </h3>
      </header>
      <div data-testid={`${testID}.order-exceeds`} className={styles.content}>
        <div className={styles['exceeds-message']} data-id={`${testID}.`}>
          {t('OrderExceedsGiftCardBalance')}
        </div>
        <div className={styles['partial-payment']}>
          <PaymentCard
            testID={testID}
            label={outstandingBalance.processedPaymentName}
            price={processedPaymentAmount}
            icon={paymentCardFormat.icon}
            iconColor={paymentCardFormat.iconColor}
            iconWidth={paymentCardFormat.iconWidth}
            iconHeight={paymentCardFormat.iconHeight}
          />
        </div>
        <div className={styles.details}>
          <DetailRow
            testID={`${testID}.order-total`}
            price={outstandingBalance.orderTotal}
            label={t('TipDriverSummaryOrderTotal')}
            textStyle={{ fontSize: '13px' }}
          />
          <DetailRow
            testID={`${testID}.order.outstanding.balance`}
            price={outstandingBalance.orderOutstandingBalance}
            label={t('Balance')}
            textStyle={{ fontSize: '13px', color: theme.colours.selectButtonColor }}
          />
        </div>
      </div>
    </section>
  )
}
