import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 55,
  height: 73,
}

export const Gauge4 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 55 73'>
      <defs>
        <path
          d='M89.6732174,126.5 L71.9732174,126.5 C67.8732174,126.5 64.4732174,123.1 64.4732174,119 L64.4732174,83 C64.4732174,81.6 65.5732174,80.5 66.9732174,80.5 L77.4732174,80.5 C78.8732174,80.5 79.9732174,81.6 79.9732174,83 L79.9732174,112.1 C79.9732174,112.4 80.1732174,112.6 80.4732174,112.6 L89.6732174,112.6 C89.9732174,112.6 90.1732174,112.4 90.1732174,112.1 L90.1732174,83 C90.1732174,81.6 91.2732174,80.5 92.6732174,80.5 L103.173217,80.5 C104.573217,80.5 105.673217,81.6 105.673217,83 L105.673217,112.1 C105.673217,112.4 105.873217,112.6 106.173217,112.6 L112.973217,112.6 C114.173217,112.6 114.973217,113.8 114.473217,114.9 L110.373217,124.7 C109.973217,125.8 108.873217,126.5 107.773217,126.5 L106.173217,126.5 C105.873217,126.5 105.673217,126.7 105.673217,127 L105.673217,148 C105.673217,149.4 104.573217,150.5 103.173217,150.5 L92.6732174,150.5 C91.2732174,150.5 90.1732174,149.4 90.1732174,148 L90.1732174,127 C90.1732174,126.7 89.9732174,126.5 89.6732174,126.5 Z'
          id='gauge-path-4'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Points4' transform='translate(-62.000000, -79.000000)' fillRule='nonzero'>
          <g id='Path'>
            <use fill={fill} href='#gauge-path-4' />
            <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-4' />
          </g>
        </g>
      </g>
    </svg>
  )
}
