export const getDifferenceBetweenPreviousAndCurrentVoucherCodes = (
  previousVoucherCodes: string[],
  currentVoucherCodes: string[],
): string[] =>
  currentVoucherCodes.reduce((acc: string[], currentCode) => {
    const previousOccurrencesOfVoucherCode = previousVoucherCodes.filter((voucherCode) => voucherCode === currentCode)
    const currentOccurrencesOfVoucherCode = currentVoucherCodes.filter((voucherCode) => voucherCode === currentCode)

    const accumulatedOccurrencesOfVoucherCode = acc.filter((voucherCode) => voucherCode === currentCode)

    if (
      accumulatedOccurrencesOfVoucherCode.length <
      currentOccurrencesOfVoucherCode.length - previousOccurrencesOfVoucherCode.length
    ) {
      acc.push(currentCode)
    }

    return acc
  }, [])
