import { getExtraSurcharge, getSelectedProductSizeByCode } from '@dominos/business/functions/menu'
import { SwapType } from '@dominos/interfaces'

type SwapSetProductSize = Bff.Menu.old.SwapSetProductSize
type SwapSetBaseProduct = Bff.Menu.old.SwapSetBaseProduct

const getSwappingExtraSurcharge = (
  menuItem: Bff.Menu.old.ProductMenuItem | undefined,
  swappingSizeCode: string | null | undefined,
  getBaseProductPrice: (baseProduct: SwapSetBaseProduct | undefined) => number | undefined | null,
) => {
  const selectedSize = getSelectedProductSizeByCode(menuItem, swappingSizeCode) as SwapSetProductSize

  return getExtraSurcharge(getBaseProductPrice, menuItem, selectedSize)
}

export const getSwapType = (
  extraSurcharge: number | undefined,
  swapping: BasketLine,
  getMenuItem: (productCode: string) => ProductMenuItem | undefined,
  getBaseProductPrice: (baseProduct: SwapSetBaseProduct | undefined) => number | undefined | null,
): SwapType => {
  const swappingMenuItem = getMenuItem(swapping.productCode)

  const currSurcharge = extraSurcharge ?? 0
  const prevSurcharge = getSwappingExtraSurcharge(swappingMenuItem, swapping.sizeCode, getBaseProductPrice)

  if (currSurcharge > prevSurcharge) return 'upgrade'

  if (currSurcharge < prevSurcharge) return 'downgrade'

  return 'same'
}
