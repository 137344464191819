import React from 'react'

import { FormResolver, StackNavigationBase } from '@dominos/components'
import { RouteComponentProps } from '@reach/router'

export const FormResolverScene = (props: RouteComponentProps) => (
  <StackNavigationBase testID='form-resolve-scene-stack'>
    <FormResolver />
  </StackNavigationBase>
)
