import { GeoService } from '@dominos/business/services'
import { GeoLocationInterface } from '@dominos/interfaces'
import { useState } from 'react'

const { checkLocationPermission, getLocationLatest, requestLocationPermission } = GeoService

export const useGeoLocation = (
  defaultPermission: boolean | undefined = undefined,
  defaultLocation?: GeoLocationInterface,
) => {
  const [permitted, setPermitted] = useState<boolean | undefined>(defaultPermission)
  const [location, setLocation] = useState(defaultLocation)

  const onPermissionSuccess = () => {
    getLocationLatest()
      .then((geo) => {
        if (geo) {
          setPermitted(true)
          setLocation({ longitude: geo.longitude, latitude: geo.latitude })
        }
      })
      .catch(() => {
        setPermitted(false)
        setLocation(defaultLocation)
      })
  }

  const onRequestPermissionResult = (result: boolean | undefined = undefined): void => {
    setPermitted(result)

    if (result || result === undefined) {
      onPermissionSuccess()
    }
  }

  const getLocation = async () => checkLocationPermission().then(onRequestPermissionResult)

  return {
    permitted,
    location,
    getLocation,
    checkLocationPermission,
    requestLocationPermission,
  }
}
