import { convertStoresToAddressLines } from '@dominos/business/functions/address'
import { useCustomer } from '@dominos/hooks-and-hocs'
import { useSavedStores } from './use-saved-stores'
import { GeoLocationInterface } from '@dominos/interfaces'
import { useEffect, useMemo, useState } from 'react'
import { combineCustomerStoresAndRecentStores } from './helpers'
import { useStoreSearchQuery } from './use-store-search-query'

const STORE_SEARCH_LIMIT = 20

export const useStoreSearch = (minimumChars: number = 3, location?: GeoLocationInterface) => {
  const { customer, loading: loadingCustomer } = useCustomer()
  const { debouncedQuery, data, error, loading: loadingStoreSearch } = useStoreSearchQuery()
  const savedStores = useSavedStores()
  const [dataResults, setDataResults] = useState<Bff.Stores.Store[] | undefined>()
  const [searchText, setSearchText] = useState<string>('')

  const search = (searchString: string) => {
    setSearchText(searchString)
    const cleanSearchString = searchString && searchString.trim()
    if (cleanSearchString.length >= minimumChars) {
      debouncedQuery({
        variables: {
          searchString: searchString.trim(),
        },
      })
    } else {
      setDataResults(undefined)
    }
  }

  useEffect(() => {
    if (data && !loadingStoreSearch) {
      setDataResults([...data.storeSearch])
    }
  }, [data, loadingStoreSearch])

  const addresses = useMemo(() => {
    if (searchText.length == 0) {
      return combineCustomerStoresAndRecentStores(customer?.stores ?? [], savedStores, location)
    }
    if (searchText.length >= minimumChars && !loadingStoreSearch && !loadingCustomer && dataResults) {
      return convertStoresToAddressLines([...dataResults], 'searchResultIcon', location, STORE_SEARCH_LIMIT)
    }

    return []
  }, [searchText, dataResults, customer, loadingStoreSearch, location, loadingCustomer, savedStores])

  return {
    search,
    results: addresses,
    loading: loadingStoreSearch || loadingCustomer,
    error,
  }
}
