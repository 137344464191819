import React from 'react'

const PortionEmptyRightSideIcon = () => (
  <svg viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.5027 0C9.92402 0 8.44059 0.299479 7.05243 0.898438C5.66426 1.4974 4.44213 2.32596 3.38604 3.38412C2.32994 4.44227 1.50158 5.66469 0.900948 7.05137C0.300316 8.43805 0 9.92093 0 11.5C0 13.0791 0.300316 14.5619 0.900948 15.9486C1.50158 17.3353 2.32994 18.5577 3.38604 19.6159C4.44213 20.674 5.66426 21.5026 7.05243 22.1016C8.44059 22.7005 9.92402 23 11.5027 23C13.0778 23 14.5603 22.7005 15.9503 22.1016C17.3403 21.5026 18.5633 20.674 19.6194 19.6159C20.6755 18.5577 21.503 17.3353 22.1018 15.9486C22.7006 14.5619 23 13.0791 23 11.5C23 9.92093 22.7006 8.43805 22.1018 7.05137C21.503 5.66469 20.6746 4.44227 19.6167 3.38412C18.5588 2.32596 17.3357 1.4974 15.9476 0.898438C14.5594 0.299479 13.0778 0 11.5027 0Z'
      fill='#0A7AFF'
    />
    <path
      d='M11.3575 2.10601L11.3575 20.855C10.0572 20.855 8.84054 20.6136 7.70764 20.1309C6.57474 19.6482 5.57976 18.9773 4.7227 18.1183C3.86564 17.2593 3.19378 16.2633 2.70713 15.1304C2.22047 13.9975 1.97715 12.7809 1.97715 11.4805C1.97715 10.1801 2.21949 8.96349 2.70417 7.83059C3.18886 6.69769 3.85973 5.70271 4.71679 4.84565C5.57385 3.98859 6.56982 3.31772 7.70469 2.83303C8.83955 2.34835 10.0572 2.10601 11.3575 2.10601Z'
      fill='white'
    />
  </svg>
)

export { PortionEmptyRightSideIcon }
