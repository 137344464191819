import { Spinner } from '@dominos/components'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './credit-card.less'

interface CreditCardProps {
  onRemove?: (token: string) => void
  removing?: boolean
  card: Bff.Customers.CustomerPaymentMethod
}

const CreditCard = (props: CreditCardProps) => {
  const { t } = useTranslation('login')
  const theme = useDominosTheme()
  const lastFourDigits = props.card.name.slice(-4)

  const handleRemove = () => {
    if (props.onRemove) {
      props.onRemove(props.card.tokenId)
    }
  }

  return (
    <div className={styles.wrapper} data-testid={`saved-payment-${lastFourDigits}`}>
      <div className={styles.cardType} />
      <div className={styles.cardText}>
        <p className={styles.creditCardText}>{t('SavedCreditCard', { defaultValue: 'Saved Credit Card' })}</p>
        <p className={styles.cardNumber}>XXXX-{lastFourDigits}</p>
      </div>
      {props.onRemove && (
        <button
          data-testid={`saved-payment-remove-${lastFourDigits}`}
          onClick={handleRemove}
          className={styles.remove}
          style={{ color: theme.colours.actionDanger }}
          disabled={props.removing}
        >
          {props.removing ? (
            <Spinner testID='removing-spinner' color={'rgb(0, 122, 175)'} />
          ) : (
            t('RemoveButton', { defaultValue: 'Remove' })
          )}
        </button>
      )}
    </div>
  )
}

export { CreditCard }
