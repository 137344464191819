import React, { useEffect, useMemo, useState } from 'react'
import { validate } from '@dominos/business/functions/validation'
import { FancyTextField } from '../'

export const ValidationTextField = ({ validationRules = [], ...props }: ValidationTextFieldProps) => {
  const [value, setValue] = useState(props.value || props.defaultValue || props.initialValue)
  const [valueHasBeenEdited, setValueHasBeenEntered] = useState(false)

  const validationResult = useMemo(
    () => (value !== undefined && validate(value, ...validationRules)) || null,
    [value, validate, validationRules],
  )

  useEffect(() => {
    if (value !== undefined && props.onChange) {
      props.onChange({
        [props.fieldName]: {
          value,
          isValid: validationResult == null,
        },
      })
    }
  }, [value, validationResult])

  useEffect(() => {
    setValue(props.defaultValue)
  }, [props.defaultValue])

  useEffect(() => {
    if (props.initialValue || props?.allowInitialValueResetWhenUndefined) {
      setValue(props.initialValue)
      setValueHasBeenEntered(false)
    }
  }, [props.initialValue])

  const updatedPlaceHolder = useMemo(() => {
    if (
      props.showAsteriskWhenRequired &&
      props.placeholder &&
      validationRules &&
      validationRules.some((rule) => rule.name === 'isRequired')
    )
      return `${props.placeholder}*`

    return props.placeholder ?? ''
  }, [props.showAsteriskWhenRequired, validationRules, props.placeholder])

  const onChanges = (value: string) => {
    if (!valueHasBeenEdited) setValueHasBeenEntered(true)
    setValue(value)
  }

  const errorMessage = props?.allowInitialValueResetWhenUndefined
    ? valueHasBeenEdited
      ? validationResult
      : null
    : validationResult

  return (
    <FancyTextField
      testID={props.testID}
      name={props.name || props.fieldName}
      placeholder={updatedPlaceHolder}
      onChange={onChanges}
      errorMessage={errorMessage}
      style={props.style}
      prefix={props.prefix}
      secureTextEntry={props.secureTextEntry}
      value={value || ''}
      defaultValue={props.defaultValue}
      autoComplete={props.autoComplete}
      autoCapitalize={props.autoCapitalize}
      type={props.type ?? 'text'}
      inputMode={props.inputMode}
      hasClearInputButton={!props.disabled && props.hasClearInputButton}
      hideEmptyErrorContainer={props.hideEmptyErrorContainer}
      disabled={props.disabled}
    />
  )
}

export type validationResult = { [fieldName: string]: { value: string; isValid: boolean } }
