import { flattenMenuToSections } from '@dominos/business/functions/menu'

type MenuSection = Bff.Menu.old.MenuSection

/**
 * Converts `MenuSection` into desired format, for use in `convertMenuToSections` function.
 *
 * @param section data to be converted
 * @returns formatted section data
 */
export const resolveMenuSection = (section: MenuSection | null): ResolvedMenuSection | null => {
  if (!section) {
    return null
  }

  return {
    data: section.items,
    code: section.code,
    title: section.media.name,
    description: section.media.description,
  }
}

/**
 * Converts `MenuSection[]` into desired format.
 *
 * @param sections data to be converted
 * @returns formatted section data
 */
export const resolveMenuSections = (sections?: MenuSection[] | null): ResolvedMenuSection[] | null => {
  if (!sections) {
    return null
  }

  return sections.reduce<ResolvedMenuSection[]>((memo, section) => {
    const resolvedSection = resolveMenuSection(section)
    if (resolvedSection) {
      return [...memo, resolvedSection]
    }

    return memo
  }, [])
}

/**
 * Converts menu into an array of pages (`MenuSection`).
 *
 * @param menu
 * @returns all sections present within the menu
 */
export const convertMenuToSections = (menu?: Menu | null): ResolvedMenuSection[] | null => {
  if (!menu || !menu.pages || menu.pages.length === 0) {
    return null
  }

  const menuSections = flattenMenuToSections(menu.pages)

  return resolveMenuSections(menuSections)
}

/**
 * Gets sections from associated `categoryCode`.
 *
 * @param menu
 * @param categoryCode eg. `Pizza` from `Menu.Pizza.NEWYORKER`
 * @returns sections from desired category
 */
export const getSectionsFromCategoryCode = (menu: Menu, categoryCode?: string): ResolvedMenuSection[] | null => {
  const sections = convertMenuToSections(menu)

  if (!sections) {
    return null
  }

  if (categoryCode) {
    return sections.filter((section) => {
      const category = section.code.toLowerCase().split('.')

      return section.data.length > 0 && category[1] && category[1].includes(categoryCode.toLowerCase())
    })
  }

  return sections
}
