import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useValidation } from '@dominos/business/functions/validation'
import { ValidationTextField } from '@dominos/components'
import { ActionButton } from '@dominos/components/buttons'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
export const ResetPasswordForm = ({
  testID,
  initialEmail,
  onSubmit,
  isLoading,
  renderRecaptchaCheckbox,
  shouldRenderRecaptcha,
}: {
  initialEmail?: string
  onSubmit: (email: string) => void
  isLoading?: boolean
  renderRecaptchaCheckbox?: (recaptchaAction: BffContext.RecaptchaAction, containerId: string) => void
  shouldRenderRecaptcha?: boolean
} & BaseProps) => {
  const { t } = useTranslation('login')
  const theme = useDominosTheme()
  const { isEmailValid, isRequired } = useValidation()
  const [email, setEmail] = useState(initialEmail)
  const [isValid, setIsValid] = useState<boolean>()

  const styles = useMemo(
    () => ({
      resetButton: { width: '100%' },
      contentMessage: { padding: '8px 0', fontWeight: 400, lineHeight: 1 } as React.CSSProperties,
      socialsContentMessage: {
        padding: '0 0 14px 0',
        fontSize: '9pt',
        color: theme.colours.web.grey,
        textAlign: 'center',
      } as React.CSSProperties,
    }),
    [theme],
  )

  const handleEmailChange = (updatedField: { [key: string]: ValidationField }) => {
    setIsValid(updatedField.email.isValid)
    setEmail(updatedField.email.value)
  }

  const submitForgotPassword = () => {
    if (email && isValid) {
      onSubmit(email)
    }
  }

  return (
    <div data-testid={testID} style={{ padding: 8 }}>
      <div style={styles.contentMessage}>
        {t('ForgottenPasswordPopupContent', {
          defaultValue: 'Please enter your email address and we will email you instructions to reset your password.',
        })}
      </div>
      <ValidationTextField
        testID={`${testID}.email`}
        fieldName={'email'}
        placeholder={t('Email')}
        defaultValue={email}
        validationRules={[isRequired, isEmailValid]}
        onChange={handleEmailChange}
        style={{ width: '100%' }}
        autoComplete='username'
      />
      <div style={styles.socialsContentMessage}>
        {t('ForgottenPasswordPopupSocialsContent', {
          defaultValue:
            'If you signed up using Apple, Facebook, Google or PayPal you will need to reset your password with that service.',
        })}
      </div>
      {shouldRenderRecaptcha && (
        <>
          <div id='forgotPassword-recaptcha-container' />
          {renderRecaptchaCheckbox?.('ResetPassword', 'forgotPassword-recaptcha-container')}
        </>
      )}
      <ActionButton
        onPress={submitForgotPassword}
        testID={`${testID}.submit`}
        containerStyle={styles.resetButton}
        textStyle={{ color: theme.colours.tooltipText }}
        textFontWeight={'semibold'}
        text={t('ForgottenPasswordPopupButtonText', { defaultValue: 'Reset Password' })}
        disabled={isLoading || !isValid}
        loading={isLoading}
      />
    </div>
  )
}
