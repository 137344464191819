import { useReport } from '@dominos/hooks-and-hocs/logging'
import { GenericError, GenericErrorHandlers } from '../interfaces'

export const useGenericErrorHandlers = (): GenericErrorHandlers => {
  const { reportGenericError } = useReport()

  const genericErrorHandler = (error: GenericError) => ({
    handleErrorDisplayed: () => reportGenericError(error),
  })

  return {
    genericErrorHandler,
  }
}
