import React from 'react'
import css from '../product-ingredient-item.less'
import { IngredientQuantityProps } from '../product-ingredient-item.interface'

export const IngredientQuantity = ({
  testID,
  quantity,
  children,
}: React.PropsWithChildren<IngredientQuantityProps>) => (
  <div className={css.rightButtonQuantityWrapper} data-testid={`${testID}.wrapper`}>
    <p data-testid={testID} className={css.quantity}>
      {quantity.toString()}
    </p>
    {children}
  </div>
)
