import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 45,
  height: 73,
}

export const Gauge9 = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const stroke = props.stroke || '#df677c'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 45 73'>
      <defs>
        <path
          d='M9.3925527,1.5 L35.3925527,1.5 C39.4925527,1.5 42.8925527,4.9 42.8925527,9 L42.8925527,64 C42.8925527,68.1 39.4925527,71.5 35.3925527,71.5 L8.8925527,71.5 C4.9925527,71.5 1.8925527,68.4 1.8925527,64.5 L1.8925527,58.9 C1.8925527,57.4 2.7925527,56 4.0925527,55.4 L14.9925527,50.3 C16.0925527,49.9 17.2925527,50.6 17.2925527,51.8 L17.2925527,57.1 C17.2925527,57.4 17.4925527,57.6 17.7925527,57.6 L26.8925527,57.6 C27.1925527,57.6 27.3925527,57.4 27.3925527,57.1 L27.3925527,45.8 C27.3925527,45.5 27.1925527,45.3 26.8925527,45.3 L9.3925527,45.3 C5.2925527,45.3 1.8925527,41.9 1.8925527,37.8 L1.8925527,9 C1.8925527,4.9 5.2925527,1.5 9.3925527,1.5 Z M16.8925527,31.4 L26.8925527,31.4 C27.1925527,31.4 27.3925527,31.2 27.3925527,30.9 L27.3925527,15.9 C27.3925527,15.6 27.1925527,15.4 26.8925527,15.4 L16.8925527,15.4 C16.5925527,15.4 16.3925527,15.6 16.3925527,15.9 L16.3925527,30.9 C16.3925527,31.2 16.5925527,31.4 16.8925527,31.4 Z'
          id='gauge-path-9'
        />
      </defs>
      <g id='Page-2' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='9' fillRule='nonzero'>
          <use fill={fill} href='#gauge-path-9' />
          <use stroke={stroke} strokeWidth='3' fill='#FFFFFF' href='#gauge-path-9' />
        </g>
      </g>
    </svg>
  )
}
