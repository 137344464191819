import { formatCategory } from '@dominos/business/functions/menu'
import { MenuNavigationProps } from '@dominos/components'
import { COUPON_MENU_CODE } from '@dominos/hooks-and-hocs/menu/use-menu-with-coupon-page'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import React from 'react'
import { getSelectedPage } from '../../functions'
import less from '../../menu-navigation.less'

type MenuPage = Bff.Menu.old.MenuPage

export const MenuPageTab = (props: MenuNavigationProps & { menuPage: MenuPage }) => {
  const { menu, menuPage } = props

  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    if (menuPage.code !== COUPON_MENU_CODE) {
      navigate(`${NavigationConstants.menu}/${formatCategory(menuPage.code)}`)
    }
  }

  const selectedPage = getSelectedPage(props)
  const active = selectedPage
    ? selectedPage.code === menuPage.code
    : menuPage.code === (menu && menu.pages && menu.pages.length > 0 && menu.pages[0].code)

  return (
    <li className={active ? less.active : undefined} data-testid={`page-tab-li-${menuPage.code}`}>
      <span>
        <a
          href={`${NavigationConstants.menu}/${formatCategory(menuPage.code)}`}
          onClick={onClick}
          data-testid={`page-tab-${menuPage.code}`}
        >
          {menuPage.media.name}
        </a>
      </span>
    </li>
  )
}
