import { useLocation } from '@reach/router'
import React, { useEffect, useRef } from 'react'

export const useHashAutoScroll = () => {
  const { hash } = useLocation()
  const focusRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (focusRef.current && focusRef.current.id === hash?.substring(1)) {
      const focusBounds = focusRef.current.getBoundingClientRect()
      const headerBounds = document.querySelector('header')?.getBoundingClientRect()

      window.scrollTo({
        behavior: 'smooth',
        top: focusBounds.top - (headerBounds?.height || 0),
      })
    }
  }, [hash, focusRef.current])

  return {
    focusRef,
  }
}

export const AutoScroll = ({ children, id }: React.PropsWithChildren<{ id: string }>) => {
  const { focusRef } = useHashAutoScroll()

  return (
    <div data-testid={id} id={id} ref={focusRef}>
      {children}
    </div>
  )
}
