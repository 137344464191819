import React from 'react'

export const MdOpenPopup = ({ children, onClick }: { children: React.ReactNode; onClick: () => void }) => (
  <a
    href='#'
    onClick={(e) => {
      e.preventDefault()
      onClick()
    }}
  >
    {children}
  </a>
)
