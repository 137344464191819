import { getCountryConfig } from '@dominos/business/functions/common'
import { FeatureFlagType } from './feature-flag.interface'

import auFeatures from './static-data/au.json'
import beFeatures from './static-data/be.json'
import deFeatures from './static-data/de.json'
import dkFeatures from './static-data/dk.json'
import frFeatures from './static-data/fr.json'
import jpFeatures from './static-data/jp.json'
import luFeatures from './static-data/lu.json'
import myFeatures from './static-data/my.json'
import nlFeatures from './static-data/nl.json'
import nzFeatures from './static-data/nz.json'
import sgFeatures from './static-data/sg.json'

const getDefault = (country: BffContext.Countries = getCountryConfig().COUNTRY) => {
  switch (country) {
    case 'BE':
      return beFeatures
    case 'DE':
      return deFeatures
    case 'DK':
      return dkFeatures
    case 'FR':
      return frFeatures
    case 'JP':
      return jpFeatures
    case 'LU':
      return luFeatures
    case 'MY':
      return myFeatures
    case 'NL':
      return nlFeatures
    case 'NZ':
      return nzFeatures
    case 'SG':
      return sgFeatures
    default:
      return auFeatures
  }
}

export const defaultFeatures: FeatureFlagType = getDefault()
