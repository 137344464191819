import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import RadioInputField from '@dominos/components/inputs/radio-input-field'
import { ServiceMethodSubTypeProps } from '@dominos/interfaces/checkout'
import { StaticSvgIcon } from '@dominos/res'

import css from '../../service-method-type.less'
import { RobotDeliveryPopup } from './robot-delivery-popup'

const modalId = 'robot-delivery-popup'

export const RobotDelivery = ({
  name,
  value,
  onChecked,
  labelText,
  testID,
  selectedSubType,
}: ServiceMethodSubTypeProps) => {
  const { t } = useTranslation('checkout')
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleChecked = (_: string, targetValue: string | number) => {
    onChecked(targetValue as Bff.Stores.ServiceMethodSubTypes)
  }

  const showModalPopup = () => setShowModal(true)
  const closeModalPopup = () => setShowModal(false)

  const onOptIn = () => {
    onChecked(value)
    setShowModal(false)
  }

  const onOptOut = () => {
    if (value === selectedSubType) {
      onChecked(undefined)
    }
    setShowModal(false)
  }

  return (
    <div>
      <div className={css.flexBoxWrapper}>
        <RadioInputField
          fieldName={name}
          value={value}
          fieldId={testID}
          onChecked={handleChecked}
          labelText={labelText}
          testID={`${testID}.radio-input-field`}
          selectedOption={selectedSubType}
        />
        <div
          data-testid={`${testID}.learn-more`}
          role='button'
          aria-label={t('learnMoreButton', { defaultValue: 'Learn More' })}
          aria-controls={modalId}
          className={css.learnMoreButton}
          onClick={showModalPopup}
        >
          <StaticSvgIcon name='question' width={22} height={22} isUnstyled />
        </div>
        {showModal && (
          <RobotDeliveryPopup
            id={modalId}
            testID={testID}
            onClose={closeModalPopup}
            onOptIn={onOptIn}
            onOptOut={onOptOut}
          />
        )}
      </div>
      {value === selectedSubType && (
        <div className={css.extraMessage}>
          <StaticSvgIcon name='robotDelivery' width={20} height={25} isUnstyled />
          <p>
            {t('robotDeliveryMessage', { defaultValue: 'You will receive a text message confirming robot delivery' })}
          </p>
        </div>
      )}
    </div>
  )
}
