import { BEExtraLarge } from './be-pizza-extralarge'
import { BELarge } from './be-pizza-large'
import { BEMedium } from './be-pizza-medium'
import { BERegular } from './be-pizza-regular'

export const BESizeIcons: { [code: string]: React.ComponentType<SizeSVGProps> } = {
  'Pizza.Regular': BERegular,
  'Pizza.Medium': BEMedium,
  'Pizza.Large': BELarge,
  'Pizza.FamilyXXL': BEExtraLarge,
}

export type BESizeCode = keyof typeof BESizeIcons
