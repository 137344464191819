import React, { useMemo } from 'react'

import { interpolateBracketVariables } from '@dominos/business/functions/text'
import { BoxedMessage } from '@dominos/components/boxed-message'
import { useDominosTheme } from '@dominos/hooks-and-hocs'
import { useTranslation } from 'react-i18next'
import ActionHeader from '../action-header/action-header'
import { DetailRow } from '../detail-row'
import CouponItems from './coupon-items/coupon-items'
import css from './coupon-line.less'

const ComboCouponFormat = 'combo'

export const CouponLine = ({
  couponLine,
  testID,
  onEditLine,
  onRemove,
  onRemoveLine,
  hideEditButton = false,
  hideRemoveButton = false,
  hideSwapButton = false,
  showVoucherNotBestValueErrorMessage,
}: CouponLineProps) => {
  const { t } = useTranslation('basket')
  const theme = useDominosTheme()

  const couponName = useMemo(
    () =>
      interpolateBracketVariables(couponLine?.media.name, {
        Value: couponLine?.discount,
        Percentage: couponLine?.discount && `${couponLine?.discount}%`,
      }),
    [couponLine?.media.name, couponLine?.discount],
  )

  if (!couponLine) {
    return null
  }

  const didPressRemove = () => {
    if (onRemove) {
      onRemove(couponLine)
    }
  }

  const isComboCoupon = couponLine.displayFormat.toLocaleLowerCase() === ComboCouponFormat

  return (
    <div>
      <div
        data-testid={testID}
        style={{ backgroundColor: theme.colours.backgroundColor, borderRadius: theme.webBorderRadius }}
        className={css.container}
      >
        <div data-testid={`${testID}.header`} className={css.header}>
          <div data-testid={`${testID}.title`} className={css.title}>
            <div data-testid={`${testID}.title-text`} className={css.label}>
              {t('voucher', { defaultValue: 'Voucher' })}
            </div>
            {isComboCoupon && <DetailRow testID={`${testID}.discount`} price={couponLine.discount} />}
          </div>
          <div data-testid={`${testID}.header-name`} className={css.name}>
            {couponName}
          </div>
          <ActionHeader testID={testID} hideRemove={hideRemoveButton} onRemove={didPressRemove} />
          {couponLine.status === 'NotBestValue' && showVoucherNotBestValueErrorMessage ? (
            <div style={{ marginTop: theme.marginTop }}>
              <BoxedMessage testID={testID} color={'primary'} variant={'contained'} icon={'exclamation'}>
                <span>
                  {t('NotBestValueErrorMessage', {
                    defaultValue: 'These products are cheaper individually so your voucher is not currently applied.',
                  })}
                </span>
              </BoxedMessage>
            </div>
          ) : null}
        </div>

        <CouponItems
          items={couponLine.items}
          voucherCode={couponLine.code}
          voucherNo={couponLine.couponNo}
          testID={testID}
          onEditLine={onEditLine}
          onRemoveLine={onRemoveLine}
          hideEditButton={hideEditButton}
          hideRemoveButton={hideRemoveButton}
          hideSwapButton={hideSwapButton}
          hidePrice={isComboCoupon}
        />
      </div>
    </div>
  )
}
