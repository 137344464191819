import { AddressList, CollapsableTitledCard } from '@dominos/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const AddressSearchResultsContainer = (props: AddressSearchResultsContainerProps) => {
  const { t } = useTranslation('delivery-address')

  return (
    <div style={{ width: 360 }}>
      <CollapsableTitledCard
        primaryTitle={t('Select Address')}
        static
        startCollapsed={false}
        testID={'address-search-results-card'}
        noPadding={true}
      >
        <div style={{ padding: '0px 8px' }}>
          <AddressList
            testID={'delivery-address'}
            addresses={props.addresses || []}
            onPress={props.onPress}
            showBorderOnFirstItem
          />
        </div>
      </CollapsableTitledCard>
    </div>
  )
}

interface AddressSearchResultsContainerProps {
  addresses: AddressLine[]
  onPress: (addressLine: AddressLine) => void
}
