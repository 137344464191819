import React from 'react'
import { useOrderId } from '@dominos/hooks-and-hocs'
import { createTrackerPointsEarnedViewModel, TrackerPointsEarned, useOrderLoyaltyInfoStore } from 'olo-feature-loyalty'
import { useApolloClient } from '@apollo/client'
import { useTranslation } from 'react-i18next'

export const LoyaltyPointsEarned = () => {
  const { id: orderId } = useOrderId({ inspectQuery: true, useIdFromBasket: true })
  const { t } = useTranslation('loyalty')
  const orderLoyaltyInfoStore = useOrderLoyaltyInfoStore({
    client: useApolloClient(),
    orderId,
  })

  if (!orderLoyaltyInfoStore) return null

  const viewModel = createTrackerPointsEarnedViewModel({
    t,
    orderLoyaltyInfoStore,
  })

  return <TrackerPointsEarned viewModel={viewModel} />
}
