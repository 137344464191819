import { useLazyQuery } from '@apollo/client'
import { keepTokenAliveQuery } from '@dominos/business/queries'

export const useKeepTokenAlive = (customerId: string | null) => {
  const [_, { startPolling, stopPolling }] = useLazyQuery<boolean>(keepTokenAliveQuery, {
    variables: {
      customerId,
    },
    fetchPolicy: 'no-cache',
  })

  return {
    startPolling,
    stopPolling,
  }
}
