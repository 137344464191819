import { interpolateBracketVariables } from '@dominos/business/functions/text'
import { VouchersIcon } from '@dominos/res/images/icons/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './saved-voucher.less'

type CouponVariableKey = 'Expiry' | 'ServiceMethod' | 'Percentage' | 'Value'

type CouponVariables = PartialRecord<CouponVariableKey, string | number>

const getServiceMethodLabel = (
  conditions: Bff.Vouchers.VoucherConditions,
  t: (x: string) => string,
): string | undefined => {
  if (conditions.forPickup && conditions.forDelivery) {
    return `${t('Pick Up')}, ${t('Delivery')}`
  }

  if (conditions.forPickup) {
    return t('Pick Up')
  }

  if (conditions.forDelivery) {
    return t('Delivery')
  }
}

export const SavedVoucher = ({
  voucher,
  onClick,
  active = false,
}: {
  voucher: Bff.Vouchers.Voucher
  onClick?: (code: string) => void
  active?: boolean
}) => {
  const { t, i18n } = useTranslation('home')
  const expiry = new Date(voucher.displayExpiryDate).toLocaleDateString(i18n.language)
  const serviceMethod = getServiceMethodLabel(voucher.conditions, t)

  const variableMap: CouponVariables = {
    Expiry: expiry,
    ServiceMethod: serviceMethod,
    Value: voucher.value,
    Percentage: voucher.value && `${voucher.value}%`,
  }

  const voucherTitle = voucher.media
    ? `${interpolateBracketVariables(voucher.media.name, variableMap)} (${voucher.voucherCode})`
    : `(${voucher.voucherCode})`
  const description = interpolateBracketVariables(voucher.media?.description, variableMap)
  const disclaimer = interpolateBracketVariables(voucher.media?.disclaimer, variableMap)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const wrapperAttributes: Record<string, any> = {
    'data-testid': 'saved-voucher',
    className: [styles.voucher, active ? styles.active : ''].join(' '),
  }

  if (active && onClick) {
    wrapperAttributes.tabIndex = 0
    wrapperAttributes.role = 'button'
    wrapperAttributes.onClick = () => onClick(voucher.voucherCode)
  }

  return (
    <div {...wrapperAttributes}>
      <div className={styles.icon}>
        <VouchersIcon fill='#ffffff' width={14} height={14} />
      </div>
      <div
        className={styles.voucherText}
        data-testid={`saved-voucher-${voucher.voucherCode}-${active ? 'active' : 'expired'}`}
      >
        {active ? (
          <>
            <h3>{voucherTitle}</h3>
            <p className={styles.expiry}>
              <span data-testid='voucher-service-method'>{serviceMethod}</span>
              {` • `}
              <span data-testid={`voucher-expiry`} data-expires={expiry}>
                {t('VoucherExpires', {
                  defaultValue: 'Expires {{date}}',
                  date: expiry,
                  interpolation: { escapeValue: false },
                })}
              </span>
            </p>
            {description && <p>{description}</p>}
            {disclaimer && <p>{disclaimer}</p>}
          </>
        ) : (
          <>
            <p>{expiry}</p>
            <h3>{voucherTitle}</h3>
          </>
        )}
      </div>
    </div>
  )
}
