import { duplicateChanges } from '@dominos/components/basket/basket-line/functions'
import { OptionBasketLineChange } from './create-basket-line'

export const mapBasketLineChangesToOptionBasketLineChanges = (
  basketLine?: BasketLine,
  product?: ProductMenuItem,
): OptionBasketLineChange[] | undefined => {
  if (!basketLine || !product?.sizes) {
    return undefined
  }
  const basketLineSizeCode = basketLine.sizeCode?.toLowerCase() !== 'n/a' ? basketLine.sizeCode : null
  const sizeCode = basketLineSizeCode || product.defaultSize
  const size = sizeCode == null ? product.sizes[0] : product.sizes.find((s) => s.code === sizeCode)
  const defaultOptions = size?.recipe?.options
  const changedOptions = basketLine.options
  const swapOptions = size?.swaps?.options
  if (!defaultOptions || !changedOptions || !swapOptions) {
    return undefined
  }
  const processedDefaultOptions = duplicateChanges(defaultOptions)
  const processedChangedOptions = duplicateChanges(changedOptions).reduce(
    (cumulator, changedOption) => {
      cumulator[changedOption.action].push(changedOption)

      return cumulator
    },
    { Add: [] as BasketLineChange[], Remove: [] as BasketLineChange[] },
  )
  const result: OptionBasketLineChange[] = []
  for (let i = 0; i < swapOptions.rule.max; i++) {
    const defaultOption = processedDefaultOptions[i]
    if (defaultOption) {
      const removeIndex = processedChangedOptions.Remove.findIndex((r) => r.code === defaultOption.ingredient.code)
      const remove = removeIndex >= 0 ? processedChangedOptions.Remove.splice(removeIndex, 1)[0] : undefined
      if (remove) {
        const add = processedChangedOptions.Add.shift()
        if (add) {
          result.push({
            ...add,
            quantity: 1,
            index: i,
          })
        }
        result.push({
          ...remove,
          quantity: 1,
          index: i,
        })
      }
    } else {
      const add = processedChangedOptions.Add.shift()
      if (add) {
        result.push({
          ...add,
          index: i,
        })
      }
    }
  }

  return result
}
