import { isDimensionalRecipeProduct } from '@dominos/components'
import { findEntityByDimensionSet } from '.'

const flattenIngredientTypeRulesForDimensionSet = (
  ingredientType: Bff.Common.IngredientTypeCode,
  dimensionSet: Bff.Dimensions.DimensionSet | undefined,
  product?: Bff.Products.ProductBase<Bff.Dimensions.DimensionSet>,
): Bff.Products.ProductIngredientTypeRule | undefined => {
  if (!isDimensionalRecipeProduct(product)) {
    return undefined
  }
  const ingredientTypeRulesBasedOnIngredientType = product.ingredientTypeRules.find(
    (ingredient) => ingredient.type === ingredientType,
  )

  if (ingredientTypeRulesBasedOnIngredientType) {
    return findEntityByDimensionSet(ingredientTypeRulesBasedOnIngredientType.rules, dimensionSet)
  }
}

export { flattenIngredientTypeRulesForDimensionSet }
