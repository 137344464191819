import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 50,
  height: 45,
}

const TrackerStoreIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 385.6 478'>
      <g id='Store' />
      <g id='Mask'>
        <path
          fill={fill}
          d={`M191.4,72.4L248.7,15c3.4-3.4,8.8-3.4,12.2,0l55.4,55.4c3.4,3.3,3.4,8.8,0,12.2l-57.4,57.4L191.4,72.4z
		 M244.5,61.9c-6.9,6.9-6.9,18.1,0,24.9c6.9,6.8,18.1,6.8,24.9,0c6.9-6.9,6.9-18.1,0-24.9C262.5,55,251.3,55,244.5,61.9z`}
        />
        <path
          fill={fill}
          d={`M244.7,152.9l-61.6,61.6c-3.3,3.3-8.8,3.3-12.2,0l-55.4-55.4c-3.3-3.3-3.3-8.8,0-12.2l61.6-61.6L244.7,152.9z
		 M188.2,141.7c-6.8,6.9-6.8,18,0,24.9c6.9,6.8,18.1,6.8,24.9,0c6.9-6.9,6.9-18,0-24.9C206.2,134.8,195,134.8,188.2,141.7z
		 M139.9,140.9c-6.9,6.9-6.9,18.1,0,24.9c6.9,6.8,18.1,6.8,24.9,0c6.8-6.9,6.9-18,0-24.9C158,134,146.8,134,139.9,140.9z`}
        />
        <rect x='64.3' y='349.4' fill='none' width='128.5' height='85.7' />
        <path
          fill={fill}
          d={`M364.2,199.5H216l-24.1,23.9c-4,4-9.3,6.2-14.9,6.2c-5.6,0-10.9-2.2-14.9-6.1l-23.9-23.9H21.4L0,306.6v42.8
		h21.4V478h214.2V349.4h85.7V478h42.8V349.4h21.4v-42.8L364.2,199.5z M192.8,435.1H64.3v-85.7h128.5V435.1z`}
        />
        <path fill={fill} d={`M106.7,138.2l3-3H21.4V178h95.3L106.7,168C98.5,159.8,98.5,146.4,106.7,138.2z`} />
        <polygon fill={fill} points={`363.6,178 363.6,135.2 280.9,135.2 237.6,178`} />
      </g>
      <g id='Logo'>
        <path
          fill={fill}
          d={`M191.4,72.4L248.7,15c3.4-3.4,8.8-3.4,12.2,0l55.4,55.4c3.4,3.3,3.4,8.8,0,12.2l-57.4,57.4L191.4,72.4z
		 M244.5,61.9c-6.9,6.9-6.9,18.1,0,24.9c6.9,6.8,18.1,6.8,24.9,0c6.9-6.9,6.9-18.1,0-24.9C262.5,55,251.3,55,244.5,61.9z`}
        />
        <path
          fill={fill}
          d={`M244.7,152.9l-61.6,61.6c-3.3,3.3-8.8,3.3-12.2,0l-55.4-55.4c-3.3-3.3-3.3-8.8,0-12.2l61.6-61.6L244.7,152.9z
		 M188.2,141.7c-6.8,6.9-6.8,18,0,24.9c6.9,6.8,18.1,6.8,24.9,0c6.9-6.9,6.9-18,0-24.9C206.2,134.8,195,134.8,188.2,141.7z
		 M139.9,140.9c-6.9,6.9-6.9,18.1,0,24.9c6.9,6.8,18.1,6.8,24.9,0c6.8-6.9,6.9-18,0-24.9C158,134,146.8,134,139.9,140.9z`}
        />
      </g>
    </svg>
  )
}

export { TrackerStoreIcon }
