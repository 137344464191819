import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 30,
  height: 23,
}

const OutsideStore = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 30 23' version='1.1'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-22.000000, -284.000000)'>
          <g transform='translate(22.000000, 284.000000)'>
            <g transform='translate(11.000000, 0.000000)' fill={fill}>
              <path d='M9.15255617,20.8834143 L3.05085206,20.8834143 L3.05085206,16.8156116 L9.15255617,16.8156116 L9.15255617,20.8834143 Z M18.3051123,16.8156116 L18.3051123,14.7817102 L17.2881617,9.69695679 L10.2785821,9.69695679 C9.86110702,10.160947 9.50541718,10.5303038 9.2115126,10.805027 C8.93770308,11.1039915 8.66685537,11.2534738 8.39896931,11.2534738 C8.13108332,11.2534738 7.87040899,11.1039915 7.61694638,10.805027 C7.39656019,10.634488 7.03533964,10.2651312 6.53328478,9.69695679 L1.01695069,9.69695679 L7.73349638e-14,14.7817102 L7.73349638e-14,16.8156116 L1.01695069,16.8156116 L1.01695069,22.9173157 L11.1864575,22.9173157 L11.1864575,16.8156116 L15.2542603,16.8156116 L15.2542603,22.9173157 L17.2881617,22.9173157 L17.2881617,16.8156116 L18.3051123,16.8156116 Z M5.21683622,6.64610473 L1.01695069,6.64610473 L1.01695069,8.6800061 L5.59359446,8.6800061 C5.17955503,8.23717822 4.913588,7.89819466 4.79569339,7.66305542 C4.67779878,7.42791618 4.81817972,7.08893262 5.21683622,6.64610473 Z M17.2576398,6.64610473 L13.66481,6.64610473 L11.4118746,8.6800061 L17.2576398,8.6800061 L17.2576398,6.64610473 Z' />
              <path d='M11.6042232,3.1654218 C11.2778974,3.49174765 11.2778974,4.02269273 11.6042232,4.34901858 C11.9305491,4.67382309 12.4614942,4.67382309 12.7870593,4.34901858 C13.1126245,4.02269273 13.1126245,3.49174765 12.7870593,3.1654218 C12.4614942,2.83985662 11.9305491,2.83985662 11.6042232,3.1654218 Z M9.08337509,3.66517956 L11.8065605,0.941233515 C11.9663004,0.78149359 12.2241663,0.78149359 12.3839062,0.941233515 L15.012769,3.57085694 C15.1725089,3.7298362 15.1725089,3.98846275 15.012769,4.14820267 L12.2895836,6.87214872 L9.08337509,3.66517956 Z' />
              <path d='M6.64137754,6.91813802 C6.31505169,7.2437032 6.31505169,7.77540895 6.64137754,8.10097413 C6.96694272,8.42577864 7.49864847,8.42577864 7.82421365,8.10021346 C8.14901816,7.77464828 8.14977883,7.2437032 7.82497431,6.91813802 C7.49864847,6.59181217 6.96694272,6.59181217 6.64137754,6.91813802 Z M8.93174379,6.95465 C8.60693928,7.28021518 8.60693928,7.8103996 8.93174379,8.13824678 C9.25730897,8.46229063 9.78977539,8.46229063 10.1145799,8.13672545 C10.4409057,7.8103996 10.4409057,7.28021518 10.1145799,6.95465 C9.78977539,6.62832416 9.25730897,6.62832416 8.93174379,6.95465 Z M11.6146139,7.48787709 L8.68985191,10.4133997 C8.53087265,10.572379 8.2722461,10.572379 8.11250618,10.4133997 L5.48288275,7.78377628 C5.32390349,7.62479702 5.32390349,7.36540981 5.48288275,7.20643055 L8.40764471,4.28090793 L11.6146139,7.48787709 Z' />
            </g>
            <path
              d='M4.10946378,20.863419 C4.30103466,20.863419 4.45603292,20.8042062 4.57445855,20.6857806 C4.69288419,20.5673549 4.75209701,20.4210644 4.75209701,20.2469091 C4.75209701,20.1493821 4.7338107,20.0623044 4.69723807,19.9856761 C4.66066545,19.9090477 4.61277273,19.8411271 4.55355991,19.7819143 L3.38323598,18.6011411 L2.00258534,17.3415855 L3.18992355,17.4099185 L9.35502282,17.4099185 C9.55355991,17.4099185 9.71378283,17.3498349 9.83569157,17.2296677 C9.95760032,17.1095005 10.0185547,16.9518899 10.0185547,16.7568359 C10.0185547,16.5652651 9.95760032,16.409396 9.83569157,16.2892288 C9.71378283,16.1690616 9.55355991,16.108978 9.35502282,16.108978 L3.18992355,16.108978 L2.0090054,16.1745608 L3.38323598,14.9125308 L4.55355991,13.7317576 C4.61277273,13.6725447 4.66066545,13.6037534 4.69723807,13.5253835 C4.7338107,13.4470136 4.75209701,13.3608067 4.75209701,13.2667628 C4.75209701,13.0926074 4.69288419,12.946317 4.57445855,12.8278913 C4.45603292,12.7094657 4.30103466,12.6502529 4.10946378,12.6502529 C4.02935232,12.6502529 3.95011164,12.6667976 3.87174173,12.6998871 C3.79337182,12.7329767 3.71761425,12.786094 3.644469,12.8592393 L0.227541103,16.2761672 C0.0882168261,16.4085252 0.0185546875,16.5687482 0.0185546875,16.7568359 C0.0185546875,16.9449237 0.0882168261,17.1051466 0.227541103,17.2375047 L3.65491832,20.6596573 C3.79075949,20.7954984 3.94227465,20.863419 4.10946378,20.863419 Z'
              stroke={fill}
              fill={fill}
              fillRule='nonzero'
              transform='translate(5.018555, 16.756836) rotate(-90.000000) translate(-5.018555, -16.756836) '
            />
            <rect fill='#414141' x='0' y='22' width='12' height='1' />
          </g>
        </g>
      </g>
    </svg>
  )
}

export { OutsideStore }
