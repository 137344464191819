type PortionMenuItem = Bff.Menu.old.PortionMenuItem
type ProductMenuItem = Bff.Menu.old.ProductMenuItem
type PortionSize = Bff.Menu.old.PortionSize
type ProductSize = Bff.Menu.old.ProductSize

export const getProductDefaultSize = (item?: ProductMenuItem | PortionMenuItem): ProductSize | PortionSize | null => {
  if (!item || !item.sizes || item.sizes.length === 0) {
    return null
  }

  for (const size of item.sizes) {
    if (size.code === item.defaultSize) {
      return size
    }
  }

  return item.sizes[0]
}
