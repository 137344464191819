import { getCountryConfig, setupGoogleApi } from '@dominos/business/functions'
import { useLazyLoadSdk } from '@dominos/hooks-and-hocs'
import { GoogleIcon } from '@dominos/res/images/icons/components'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuthButton } from '@dominos/components'
import { AuthButtonProps } from '../auth-button-group.interface'
import { GoogleAuthClient, GoogleAuthErrorResponse, GoogleAuthResponse } from './google-button.interface'
import { SocialLoginError } from '../auth-button-group.interface'

const GoogleButton = ({ testID, onReady, onToken, onError, loading }: AuthButtonProps) => {
  const { t } = useTranslation('login')
  const applicationConfig = getCountryConfig()
  const [authClient, setAuthClient] = useState<GoogleAuthClient>()

  const onSuccess = (token: string) => onToken?.('Google', token)

  const onGoogleLogin = () => {
    onReady()
    authClient ? authClient.requestAccessToken() : lazyLoadSdk()
  }

  const onGoogleInitFailure = (error: GoogleAuthErrorResponse) => {
    if (error.type === 'popup_failed_to_open') error.type = SocialLoginError.PopupBlocked
    onError('Google', error.type, error.message)
  }

  const { lazyLoadSdk, isLazyLoading } = useLazyLoadSdk('default_gsi', {
    loadSdk: setupGoogleApi,
    onSdkLoaded: () => {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: applicationConfig.GOOGLE_CLIENT_ID,
        scope: `openid profile email`,
        callback: (tokenResponse: GoogleAuthResponse) => {
          onSuccess(tokenResponse.access_token)
        },
        error_callback: (error: GoogleAuthErrorResponse) => {
          onGoogleInitFailure(error)
        },
      })
      client.requestAccessToken()
      setAuthClient(client)
    },
    onSdkTimeout: () => {
      onError('Google', 'Load SDK timeout')
    },
  })

  return (
    <AuthButton
      testID={testID}
      backgroundColor={'#EFEFEF'}
      label={t('signInGoogleTitle', { defaultValue: 'Sign in with Google' })}
      loading={isLazyLoading || loading}
      onPress={onGoogleLogin}
    >
      <GoogleIcon fill={'#ffffff'} />
    </AuthButton>
  )
}

export { GoogleButton }
