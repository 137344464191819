/**
 * Main intention of this file - provide possibility to share the menu data from the MenuProvider
 * We need to get this data from non-component places like epics
 */
const currentMenu: CurrentMenu = {
  itemsByCode: undefined,
}

export const setCurrentMenuItems = (menuItems?: MenuItemDependentsDictionary) => {
  currentMenu.itemsByCode = menuItems
}

export const getCurrentMenu = (): CurrentMenu => currentMenu

export interface CurrentMenu {
  itemsByCode?: MenuItemDependentsDictionary
}
