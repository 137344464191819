import { getCountryConfig, loadScript, LoadScriptProps } from '@dominos/business/functions/common'
import { useSdkAvailable } from '@dominos/hooks-and-hocs'
import { useCallback, useEffect, useRef, useState } from 'react'

declare global {
  interface Window {
    grecaptcha: {
      enterprise: RecaptchaInstance
    }
    onLoadCallback: (() => void) | undefined
  }
}

interface RecaptchaInstance {
  ready: (cb: () => void) => void
  execute: (siteKey: string, options: RecaptchaExecuteOptions) => Promise<string>
  render: (container: string | HTMLElement, parameters?: Parameters) => number
  getResponse: (containerId?: string) => string
  reset: (containerId?: string) => void
}

interface Parameters {
  sitekey: string
  action: string
}
interface RecaptchaExecuteOptions {
  action: string
}

const recaptchaV2SetupScript: LoadScriptProps = {
  id: 'recaptcha_v2',
  setupScriptFunc: (script) => {
    script.async = true
    script.defer = true
    script.type = 'text/javascript'
    script.src = `https://www.google.com/recaptcha/enterprise.js?onload=onLoadCallback&render=explicit`
  },
}

const RECAPTCHA_SCRIPT_NOT_AVAILABLE = 'Recaptcha script is not available'

export const useRecaptcha = () => {
  const applicationConfig = getCountryConfig()
  const { found } = useSdkAvailable('grecaptcha')
  const [grecaptcha, setGrecaptcha] = useState<RecaptchaInstance | undefined>(undefined)
  const grecaptchaWidgetId = useRef<number | undefined>(undefined)
  const recaptchaV3SiteKey = applicationConfig.RECAPTCHA_V3_SITE_KEY
  const recaptchaV2SiteKey = applicationConfig.RECAPTCHA_V2_SITE_KEY

  useEffect(() => {
    if (found) {
      setGrecaptcha(recaptchaV3SiteKey && recaptchaV2SiteKey ? window.grecaptcha?.enterprise : undefined)
    }
  }, [found, recaptchaV2SiteKey, recaptchaV3SiteKey])

  const warnSdkMissing = useCallback(() => {
    if (!found) {
      // eslint-disable-next-line no-console
      console.warn(RECAPTCHA_SCRIPT_NOT_AVAILABLE)
    }
  }, [found])

  const executeV3 = useCallback(
    async (action: string) => {
      if (grecaptcha) {
        return new Promise<string>((resolve: (value: string) => void, reject) =>
          grecaptcha.ready(async () => {
            try {
              const token = await grecaptcha.execute(recaptchaV3SiteKey, { action })
              token ? resolve(token) : reject()
            } catch (err) {
              reject()
            }
          }),
        )
      }

      warnSdkMissing()
    },
    [grecaptcha, recaptchaV3SiteKey, warnSdkMissing],
  )
  const renderRecaptchaV2 = useCallback(
    (action: BffContext.RecaptchaAction, elementId: string) =>
      window.grecaptcha.enterprise.render(elementId, {
        action,
        sitekey: recaptchaV2SiteKey,
      }),
    [recaptchaV2SiteKey],
  )

  const renderCheckboxV2 = useCallback(
    (action: BffContext.RecaptchaAction, elementId: string) => {
      if (!recaptchaV2SiteKey) {
        return
      }

      const shouldSetupRecaptcha = grecaptchaWidgetId.current === undefined && !window.onLoadCallback
      if (shouldSetupRecaptcha) {
        window.onLoadCallback = () => {
          grecaptchaWidgetId.current = renderRecaptchaV2(action, elementId)
        }

        loadScript(recaptchaV2SetupScript)
      }
    },
    [recaptchaV2SiteKey, renderRecaptchaV2],
  )

  const getV2Token = useCallback(
    (containerId?: string) => {
      if (grecaptcha) {
        return grecaptcha.getResponse(containerId)
      } else {
        warnSdkMissing()
      }
    },
    [grecaptcha, warnSdkMissing],
  )

  const resetCheckboxV2 = useCallback(
    (containerId?: string) => {
      if (window.grecaptcha && grecaptchaWidgetId.current !== undefined) {
        window.grecaptcha.enterprise.reset(grecaptchaWidgetId?.current.toString() || containerId)
      } else {
        warnSdkMissing()
      }
    },
    [warnSdkMissing],
  )
  const dismountRecaptchaV2 = useCallback(() => {
    const container = document.getElementById('recaptcha_v2')
    grecaptchaWidgetId.current = undefined
    if (container) {
      container.remove()
    }
    window.onLoadCallback = undefined
  }, [grecaptchaWidgetId.current])

  useEffect(
    () => () => {
      dismountRecaptchaV2()
    },
    [],
  )

  return {
    executeV3,
    renderCheckboxV2,
    getV2Token,
    resetCheckboxV2,
    dismountRecaptchaV2,
  }
}
