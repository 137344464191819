import React, { ForwardedRef, forwardRef, useEffect } from 'react'
import { useVirtualKeyboardContext } from '@dominos/hooks-and-hocs'
import css from './input-field.less'

const SUBMIT_KEY = 'Enter'
const SUBMIT_KEY_CODE = 13
const E_KEY_LOWER = 'e'
const E_KEY_CODE_LOWER = 101
const E_KEY_UPPER = 'E'
const E_KEY_CODE_UPPER = 69

export const InputField = forwardRef(
  (
    { type, testID, className, onChange, onSubmitEditing, secureTextEntry, inputMode, ...props }: InputFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { toggleVirtualKeyboard } = useVirtualKeyboardContext()

    const didChangeTextInput = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
      onChange && onChange(target.value || '')

    const didPressKey = (event: React.KeyboardEvent<HTMLInputElement> & { target: { value: string } }) => {
      // Handle enter key press by cancelling input and notifying submit listener
      if ((event.key === SUBMIT_KEY || event.charCode === SUBMIT_KEY_CODE) && onSubmitEditing) {
        event.preventDefault()
        onSubmitEditing(event.target.value)
      }
      if (
        type === 'number' &&
        inputMode === 'tel' &&
        (event.key === E_KEY_LOWER ||
          event.key === E_KEY_UPPER ||
          event.charCode === E_KEY_CODE_LOWER ||
          event.charCode === E_KEY_CODE_UPPER)
      ) {
        event.preventDefault()
      }
    }

    const preventSubmit = (event: React.FormEvent<HTMLInputElement>) => event.preventDefault()

    function didFocus(event: React.FocusEvent<HTMLInputElement>): void {
      toggleVirtualKeyboard(true, didChangeTextInput, didPressKey, event.target.name)
    }

    useEffect(
      () => () => {
        toggleVirtualKeyboard(false)
      },
      [],
    )

    return (
      <input
        data-testid={testID}
        ref={ref}
        {...props}
        onChange={didChangeTextInput}
        onSubmit={preventSubmit}
        className={className || css.input}
        onKeyPress={didPressKey}
        onFocus={didFocus}
        type={secureTextEntry ? 'password' : type}
      />
    )
  },
)
