import React from 'react'

const svgCrustIcon = (selectedColor: string) => (
  <g>
    <path
      fill={selectedColor}
      strokeWidth='1px'
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M65.2,127.6c-2.7-.6-4.4-3.3-3.7-6,.6-2.7,3.3-4.4,6-3.7s4.4,3.3,3.7,6c-.6,2.7-3.3,4.4-6,3.7h0ZM66.8,121h0c-.9-.2-1.9.4-2.1,1.3s.4,1.9,1.3,2.1,1.9-.4,2.1-1.3-.4-1.9-1.3-2.1h0Z'
    />
    <path
      fill={selectedColor}
      d='M99.4,92.4c-2.7-.6-4.4-3.3-3.7-6s3.3-4.4,6-3.7,4.4,3.3,3.7,6-3.3,4.4-6,3.7h0ZM100.9,85.8h0c-.9-.2-1.9.4-2.1,1.3s.4,1.9,1.3,2.1,1.9-.4,2.1-1.3-.4-1.9-1.3-2.1h0Z'
    />
    <path
      fill={selectedColor}
      d='M122.4,107.2c-2.7-.6-4.4-3.3-3.7-6s3.3-4.4,6-3.7,4.4,3.3,3.7,6-3.3,4.4-6,3.7h0ZM124,100.7h0c-.9-.2-1.9.4-2.1,1.3s.4,1.9,1.3,2.1,1.9-.4,2.1-1.3-.4-1.9-1.3-2.1h0Z'
    />
    <path
      fill={selectedColor}
      d='M170.7,123.8c-2.7-.6-4.4-3.3-3.7-6s3.3-4.4,6-3.7,4.4,3.3,3.7,6-3.3,4.4-6,3.7h0ZM172.2,117.2h0c-.9-.2-1.9.4-2.1,1.3s.4,1.9,1.3,2.1,1.9-.4,2.1-1.3-.4-1.9-1.3-2.1h0Z'
    />
    <path
      fill={selectedColor}
      strokeWidth='1px'
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M181.3,93.1c-2.7-.6-4.4-3.3-3.7-6s3.3-4.4,6-3.7,4.4,3.3,3.7,6-3.3,4.4-6,3.7h0ZM182.8,86.6h0c-.9-.2-1.9.4-2.1,1.3s.4,1.9,1.3,2.1,1.9-.4,2.1-1.3-.4-1.9-1.3-2.1h0Z'
    />
    <path
      fill={selectedColor}
      strokeWidth='1px'
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M136.6,103.6c-.2,0-.3-.1-.5-.2-.8-.5-1-1.5-.5-2.2.7-1.1,1.6-2.1,2.7-2.9.7-.5,1.8-.4,2.3.3.5.7.4,1.8-.3,2.3-.8.6-1.4,1.2-1.9,2-.4.6-1.1.9-1.8.7h0Z'
    />
    <path
      fill={selectedColor}
      strokeWidth='1px'
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M145.3,122.5c-3.2-.8-6.3-2.7-8.5-5.7-1.8-2.4-2.8-5.1-3-7.9,0-.9.6-1.7,1.5-1.8s1.7.6,1.8,1.5c.2,2.2,1,4.3,2.4,6.2,3.5,4.7,9.7,6.2,13.6,3.2,4-3,4.4-9.2.8-14-2.2-2.9-5.4-4.7-8.6-4.7-.9,0-1.6-.8-1.6-1.7,0-.9.8-1.6,1.7-1.6,4.2.1,8.4,2.4,11.2,6,4.6,6.2,4,14.5-1.5,18.6-2.8,2.1-6.3,2.6-9.7,1.9h0Z'
    />
    <path
      fill={selectedColor}
      strokeWidth='1px'
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M174,78.7c-2.7-.6-5.2-2-7.2-4-5.5-5.4-6.1-13.7-1.4-18.6,2.2-2.3,5.4-3.5,8.8-3.4.4,0,.7.2,1,.4,3.3,2.9,8.2,7.9,13,14.8.2.3.3.7.3,1.1-.3,2.6-1.4,4.9-3.1,6.6-2.8,2.9-7.1,4-11.4,3h0ZM173.5,56h0c-2.3,0-4.3.9-5.8,2.4-3.5,3.5-2.9,9.8,1.3,14,1.6,1.6,3.6,2.7,5.7,3.2,3.2.7,6.3,0,8.3-2.1,1.1-1.1,1.8-2.5,2.1-4.1-4.2-6.1-8.6-10.5-11.6-13.3h0Z'
    />
    <path
      fill={selectedColor}
      strokeWidth='1px'
      stroke={selectedColor}
      strokeMiterlimit={10}
      d='M113.1,83c-4.5-1.1-8-4.1-9.1-8.2-.1-.4-.2-.8-.3-1.1-.1-.7.2-1.3.8-1.7,6.5-4,13.3-7.8,20.1-11.2.5-.3,1.2-.2,1.6.1,2.3,1.5,3.9,3.7,4.6,6.2,1.9,6.5-2.7,13.5-10.1,15.7-2.5.7-5.2.8-7.7.2h0ZM107.2,74.1h0c.9,2.8,3.4,4.9,6.6,5.7,2,.5,4,.4,6.1-.2,5.7-1.6,9.2-6.8,7.8-11.6-.4-1.5-1.3-2.8-2.5-3.9-6.1,3.1-12.1,6.4-18,10h0Z'
    />
    <path
      fill={selectedColor}
      d='M64.8,105.2c-.3,0-.6-.2-.8-.5-.6-.7-.6-1.7.1-2.3.7-.7,1.4-1.3,2.2-2,.7-.6,1.7-.5,2.3.1.6.7.6,1.7-.1,2.3-.7.6-1.4,1.3-2.1,2-.4.4-1,.5-1.5.4h0Z'
    />
    <path
      fill='none'
      stroke={selectedColor}
      strokeMiterlimit={10}
      strokeWidth='6.5px'
      d='M42.2,133.8s8.4-26.2,58-57.5c49.6-31.2,66.1-28.7,66.1-28.7,0,0,42,18.2,36,86.6,0,0-.8,8.1-1.8,8.1s-49.6-3.1-81.9-3.9c-24.4-.6-61.7,4.1-67.7,4.7s-11.8-2.9-8.7-9.5h0Z'
    />
  </g>
)

const svgCrustIconClipPath = (selectedColor: string) => (
  <g clipPath={'url(#clippath)'}>
    <g>
      <g>
        <path
          fill={selectedColor}
          stroke={selectedColor}
          strokeMiterlimit={10}
          strokeWidth='2.6px'
          d='M146.8,168.1c-.6-.1-1.1-.7-1.2-1.4,0-.2,0-.4-.1-.5-.7-3.2.5-5.1,1.5-6.1,1.7-1.7,4.5-2.3,7.5-1.6,2.3.5,4.3,1.7,5.7,3.4.6.7,1.1,1.5,1.8,2.4.6.9,1.4,1.9,2.1,2.9.4.5.5,1.2.2,1.8s-1,.9-1.6.8c-5-.6-10.2-1.1-15.6-1.6,0,0-.1,0-.2,0h0ZM153.8,161.7h0c-1.8-.4-3.6-.1-4.5.7-.6.6-.8,1.5-.7,2.6,3.6.4,7.2.7,10.7,1.1-.6-.8-1.1-1.6-1.6-2.1-.9-1.1-2.4-1.9-3.9-2.3h0Z'
        />
        <path
          fill={selectedColor}
          stroke={selectedColor}
          strokeMiterlimit={10}
          strokeWidth='2.6px'
          d='M104.4,166.6c-.3,0-.5-.2-.8-.4-.3-.3-.5-.8-.5-1.2,0-2.6,1.9-4.7,5.6-6.6,7-3.5,16.7-6.7,23.1-5.2,2,.5,3.6,1.4,4.7,2.8,2.9,3.6,1.5,7.6.2,10.3-.3.6-.9,1-1.6.9-2-.2-4-.3-6-.5-7.3-.5-15.2-.5-24.2,0-.2,0-.3,0-.5,0h0ZM131.1,156.4h0c-4.5-1.1-12.7.9-20.9,5-1.4.7-2.3,1.4-2.9,1.9,8-.4,15.3-.4,22,.1,1.6.1,3.3.2,4.9.4,1.1-2.6,1-4.3-.2-5.8-.6-.8-1.6-1.3-2.9-1.6h0Z'
        />
        <path
          fill={selectedColor}
          stroke={selectedColor}
          strokeMiterlimit={10}
          strokeWidth='2.6px'
          d='M82.7,168.7c-.4-.1-.8-.4-1-.7-.3-.5-.3-1.2,0-1.7,1-1.5,2.4-3,4.5-4.5,3.2-2.3,5.7-3.2,7.7-2.7,1.1.3,2,.9,2.5,1.9.9,1.7.6,3.9.1,5.4-.2.6-.8,1-1.4,1.1-3.6.3-7.4.8-11.8,1.3-.2,0-.4,0-.6,0h0ZM93.1,162.3h0c-.4,0-1.7-.2-5,2.2-.2.2-.5.4-.7.5,2.2-.2,4.3-.5,6.3-.7.1-.7,0-1.4-.1-1.8-.1-.2-.2-.3-.4-.3h0Z'
        />
        <path
          fill={selectedColor}
          stroke={selectedColor}
          strokeMiterlimit={10}
          strokeWidth='2.6px'
          d='M49.3,171.1c-.4,0-.8-.2-1.1-.3-2-.7-3.4-2-4.1-3.8-1.2-3.2,0-7.9,3.7-14.1.4-.8,1.4-1,2.2-.6s1.1,1.4.7,2.1c-.5,1-1.1,2.8-.4,3.2.9.5,1.9.9,3.1,1.1,1.9.4,4,.6,6.1.8,2.1.1,4.2.3,6.1.7,4.2,1,6.6,3.4,7.1,7.3,0,.3,0,.6,0,.9,0,.9-.6,1.6-1.4,1.7-10.7,1.3-17.7,1.9-22.1.9h0ZM48,160h0c-1,2.6-1.3,4.6-.8,5.9.2.5.6,1.3,2,1.8.2,0,.5.2.8.2,4,.9,11.6.2,19.3-.7-.5-2-1.9-3.1-4.4-3.7-1.6-.4-3.5-.5-5.6-.7-2.2-.2-4.5-.3-6.6-.8-1.6-.4-2.9-.9-4-1.5-.2-.1-.5-.3-.8-.6h0Z'
        />
        <path
          fill={selectedColor}
          stroke={selectedColor}
          strokeMiterlimit={10}
          strokeWidth='2.6px'
          d='M72,192.8c-1.4-.3-2.7-1.1-3.8-2.2-5.2-5.6-3-9.9-1-13.7,1.3-2.6,2.7-5.3,2.3-8.8-.6-4.3-4.1-4.8-10.1-5.2-3.7-.3-7.5-.5-10.6-2.3-1.1-.6-1.8-1.7-2-3-.2-1.5-.8-6.2,16.4-23.1.6-.6,1.7-.6,2.3,0,.6.6.6,1.7,0,2.3-11.8,11.6-15.7,18.2-15.4,20.4,0,.3.1.4.4.5,2.4,1.4,5.9,1.7,9.2,1.9,5.7.4,12.2.9,13.1,8,.6,4.5-1.1,7.8-2.6,10.7-2,3.8-3.1,6.1.5,10,1.3,1.4,3.4,1.8,5.6.8,2.8-1.2,5.5-4.4,5.3-9,0-1.5-.4-3-.7-4.4-.9-4.4-1.9-8.9,5.3-14,4.1-3,7.2-3.6,9.2-1.9,2,1.6,1.8,5,.7,7.2-1.1,2.4-1.5,4.4-.9,5.8.3.7,1.7,1.7,3.5,2.2.9.2,3.1.7,4.8-.6,1.7-1.3.9-4.6.6-5.1v-.3c-1.3-2.7-3.1-6.9,4.5-10.7,10.6-5.3,23.2-8.1,27.8-2.5,3.5,4.4.8,9.3-.9,12.3-.6,1.1-.8,2.1-.5,3,.6,1.5,2.5,2.6,3.1,2.8,2.2.8,4.1.7,5.4-.5,1.7-1.4,2.4-4.3,1.8-7.3-.7-3.3.4-6,3.1-7.2,3.5-1.7,8.9-.4,11.6,2.9.6.7,1.2,1.5,1.8,2.4,2.4,3.3,5.3,7.5,9.8,8,3.2.4,7-1.2,11.1-4.8,11.5-9.9,27.3-10.4,30.3-9.2,1-5.7,0-24.5-8-46.8-6.6-18.7-25.2-28.4-29-31.5-.5-.4-1.1-.9-1.5-1.2-2-1.6-3.7-3-5-4.3-15.5,4.5-53.5,17.9-91.7,50.8-.7.6-1.7.5-2.3-.2-.6-.7-.5-1.7.2-2.3,17.3-14.8,36.4-27.4,57-37.4,16.9-8.2,30.4-12.5,37-14.3.6-.2,1.3,0,1.7.5,1.1,1.3,2.9,2.8,5.2,4.6.5.4,1,.8,1.6,1.3,4,3.2,23.1,13.5,30,33,9.8,27.5,7.9,49.4,7.7,50.4,0,.3-.2.6-.5.8-1,.9-2.1.4-2.9,0-2.2-1-16.5-1.1-27.6,8.3-5,4.2-9.4,6-13.6,5.5-5.9-.7-9.4-5.7-12-9.4-.6-.8-1.1-1.6-1.6-2.2-2-2.4-5.7-3-7.7-2.1-1.3.6-1.7,1.8-1.3,3.6.9,4.2-.2,8.2-2.8,10.5-2.3,2-5.4,2.3-8.7,1-.2,0-3.8-1.5-5-4.7-.5-1.2-.7-3.2.7-5.7,1.8-3.3,3.2-6.2,1.2-8.7-2.8-3.5-13-2-23.8,3.4-4.8,2.4-4.1,3.9-3.1,6.5v.3c.8,1.5,1.9,6.4-1.5,9-2,1.5-4.8,1.9-7.6,1.2-2.7-.7-4.9-2.3-5.7-4.1-1-2.3-.6-5.2.9-8.5.6-1.3.6-2.9.1-3.3-.4-.3-2-.3-5.2,2.1-5.4,3.9-4.9,6.5-4,10.7.3,1.4.6,3.1.7,4.9.3,6.2-3.5,10.6-7.3,12.2-1.9.8-3.8,1-5.5.6h0Z'
        />
        <path
          fill={selectedColor}
          stroke={selectedColor}
          strokeMiterlimit={10}
          strokeWidth='2.6px'
          d='M69.9,131.5c-.3,0-.6-.2-.8-.5-.6-.7-.6-1.7.1-2.3.7-.7,1.4-1.3,2.2-2,.7-.6,1.7-.5,2.3.1.6.7.6,1.7-.1,2.3-.7.6-1.4,1.3-2.1,2-.4.4-1,.5-1.5.4h0Z'
        />
      </g>
      <path
        fill='none'
        stroke={selectedColor}
        strokeLinejoin='round'
        strokeWidth='5.2px'
        d='M180.8,171c-2.5.7,37.5-3.9,37.5-3.9,0,0,13.5-21.9-7.9-67.1-24.1-51-78.2-31.4-78.2-31.4'
      />
    </g>
  </g>
)

const svgCrustIconPath = (selectedColor: string) => (
  <>
    <path
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      strokeWidth='2px'
      d='M103.4,109.3l-6.7-4c-3.2-1.9-7.3-1.7-10.2.5-2.5,2-3.6,5.1-2.9,8,0,.4.2.7.4,1,.2.4.6.7,1,.8.5,0,.9,0,1.2-.4.4-.4.5-1.1.2-1.6-.4-.8-.4-1.7,0-2.5.7-1.2,2.3-1.6,3.5-.9s1.6,2.2.9,3.5l-6.2,8.4c-.3.4-.4,1-.3,1.5.1.5.4,1,.9,1.2l6.2,3.7c.5.3,1,.3,1.5.2.5-.2.9-.5,1.1-1l4.4-9.5c.7-1.2,2.3-1.6,3.5-.9s1.6,2.3.9,3.5c-.5.8-1.3,1.2-2.1,1.2s-1.1.4-1.3.9c-.1.4,0,.9.2,1.3s.8.5,1.2.5c2.4-.3,4.5-1.6,5.7-3.7s1.5-4.3.9-6.6c-.6-2.2-2-4.1-4-5.3h0ZM100.7,124c-.2,0-.4,0-.5-.2-.1-.1-.1-.3,0-.5,0-.2.3-.3.5-.3,1.2,0,2.3-.6,2.9-1.6,1-1.6.4-3.7-1.2-4.6s-3.7-.4-4.6,1.2c0,0,0,0,0,0l-4.4,9.5c-.1.3-.4.5-.6.5-.3,0-.6,0-.8-.1l-6.2-3.7c-.2-.1-.4-.4-.5-.7,0-.3,0-.6.2-.8l6.2-8.4s0,0,0,0c1-1.6.4-3.7-1.2-4.6s-3.7-.4-4.6,1.2c-.6,1-.6,2.3,0,3.3.1.2,0,.4,0,.6-.1.1-.3.2-.5.1s-.3-.1-.4-.3c-.1-.3-.2-.6-.3-.9-.7-2.6.3-5.4,2.6-7.2,2.6-2,6.3-2.2,9.2-.5l6.7,4c1.8,1,3,2.8,3.6,4.8.5,2,.2,4.1-.8,5.9s-3,3-5,3.2h0Z'
    />
    <path
      fill={selectedColor}
      stroke={selectedColor}
      strokeMiterlimit={10}
      strokeWidth='2px'
      d='M141.9,68.6l-3.1,7.2c-1.5,3.5-.7,7.5,1.8,10,2.3,2.2,5.5,2.9,8.3,1.8.3-.1.7-.3,1-.5.4-.2.6-.6.7-1.1,0-.5-.2-.9-.6-1.2-.5-.3-1.1-.3-1.6,0-.7.5-1.6.6-2.5.3-1.3-.5-1.9-2-1.3-3.3s2-1.9,3.3-1.3l9.1,5.1c.5.3,1,.3,1.5.1.5-.2.9-.5,1.1-1l2.9-6.6c.2-.5.2-1,0-1.5s-.6-.9-1.1-1l-10-3.1c-1.3-.6-1.9-2-1.3-3.3s2-1.9,3.3-1.3c.8.4,1.4,1.1,1.5,2,0,.6.5,1,1.1,1.1.5,0,.9,0,1.2-.4.3-.3.5-.8.3-1.3-.6-2.3-2.2-4.2-4.4-5.2s-4.5-.9-6.6,0c-2.1.9-3.8,2.5-4.7,4.6h0ZM156.8,69.4c0,.2,0,.4-.1.5s-.3.2-.5.1c-.2,0-.4-.2-.4-.4-.1-1.2-.9-2.2-2-2.6-1.7-.7-3.7,0-4.4,1.8s0,3.7,1.8,4.5c0,0,0,0,0,0l10,3.1c.3,0,.5.3.6.5s.1.6,0,.8l-2.9,6.6c-.1.3-.3.5-.6.6s-.6,0-.8,0l-9.1-5.1s0,0,0,0c-1.7-.7-3.7,0-4.5,1.8s0,3.7,1.8,4.4c1.1.5,2.3.3,3.3-.4.2-.1.4-.1.6,0,.1.1.2.3.2.4,0,.2-.1.3-.2.4-.3.2-.6.3-.9.4-2.5,1-5.4.4-7.4-1.6-2.3-2.3-3-5.9-1.7-9.1l3.1-7.2c.8-1.9,2.3-3.4,4.3-4.2,1.9-.8,4-.8,6,0s3.4,2.5,3.9,4.6h0Z'
    />
  </>
)

export const CheeseBurstCrust = ({ color }: CrustSVGProps) => {
  const selectedColor = color ? color : '#454545'

  return (
    <svg width='51px' height='49px' viewBox='0 0 260.1 260.2' key={color}>
      <defs>
        <clipPath id='clippath'>
          <path
            fill='none'
            d='M62.3,145.1c-1.8.3,37.1-9.5,37.1-9.5,0,0,91.9,2.4,93,2.4s8.4,2.3,9.1,0,2.2-41.3,2.2-41.3l-9.2-26.2-3-18.1,56.5-6.5,59.7,35.4,15.5,59.1-43.4,51.4-98.7,47.6-77.9,3.9-66.6-31.2-10.2-62.1,35.8-4.7h0Z'
          />
        </clipPath>
      </defs>
      <g>
        <g id='Layer_1'>
          <g id='Layer_1-2' data-name='Layer_1'>
            <circle
              fill='none'
              stroke={selectedColor}
              strokeMiterlimit={10}
              strokeWidth='18px'
              cx='130.2'
              cy='129.9'
              r='118.2'
            />
            {svgCrustIcon(selectedColor)}
            {svgCrustIconClipPath(selectedColor)}
            {svgCrustIconPath(selectedColor)}
          </g>
        </g>
      </g>
    </svg>
  )
}
