import React from 'react'
import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'

const defaultDimensions = {
  width: 17,
  height: 14,
}

const MapIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#4A4A4A'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 17 14'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(0, 0.8107)' fill={fill} fillRule='nonzero'>
          <path
            d='M16.5703118,0.124884103 C16.7607415,0.256720041 16.875,0.473516737 16.875,0.704961871 L16.875,10.5487119 C16.875,10.8416806 16.693359,11.1024226 16.4208984,11.2049617 L11.4990234,13.0799617 C11.3466793,13.1385554 11.1796877,13.1414853 11.0273436,13.0887504 L5.63964844,11.2957822 L0.952148438,13.0828916 C0.735351741,13.1649225 0.492187589,13.1356256 0.301757902,13.0037896 C0.111328214,12.8719537 0,12.655157 0,12.4237119 L0,2.57996187 C0,2.28699312 0.178711116,2.02625111 0.454101562,1.92371205 L5.37597656,0.0487120494 C5.52832022,-0.00988170065 5.69531232,-0.012811567 5.84765643,0.0399224504 L11.2353516,1.83289156 L15.9228516,0.045782183 C16.1396492,-0.0362487093 16.3828129,-0.00695183433 16.5732425,0.124884103 L16.5703118,0.124884103 Z M1.40625,3.06336031 L1.40625,11.404181 L4.921875,10.0653134 L4.921875,1.72449276 L1.40625,3.06336031 Z M10.546875,11.4481263 L10.546875,3.08679745 L6.328125,1.68054745 L6.328125,10.0418763 L10.546875,11.4481263 Z M11.953125,11.404181 L15.46875,10.0653134 L15.46875,1.72449276 L11.953125,3.06336031 L11.953125,11.404181 Z'
            id='Shape'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export { MapIcon }
