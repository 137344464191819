import React from 'react'
import uuid from 'uuid'
import { ActionButton } from '@dominos/components'
import styles from './embedded-campaign.less'

import { ButtonActionType, ICampaignStep } from '../swrve-campaign.interface'

export const EmbeddedCampaign = ({
  campaign,
  onCampaignButtonsClicked,
  testID,
}: BaseProps & {
  campaign: ICampaignStep
  onCampaignButtonsClicked: (buttonAction: ButtonActionType) => void
}) => {
  const { BackgroundImageUrl, Buttons } = campaign

  if (!BackgroundImageUrl) {
    return null
  }

  return (
    <div className={styles.container} data-testid={`${testID}.container`}>
      <img
        data-testid={'campaign.image'}
        className={styles.backgroundImg}
        src={campaign.BackgroundImageUrl}
        alt={'alt'}
      />
      <div className={styles.buttonsContainer}>
        {Buttons.length > 0 &&
          Buttons.map(({ ButtonText, ButtonImageUrl, ButtonAction }) => (
            <ActionButton
              testID='campaign.button'
              containerStyle={{
                width: '90%',
                fontWeight: 'bold',
                color: '#fff',
                ...(BackgroundImageUrl && { padding: 2 }),
              }}
              onPress={() => onCampaignButtonsClicked(ButtonAction)}
              key={uuid()}
            >
              {ButtonImageUrl ? (
                <img
                  data-testid={'campaign.button.image'}
                  className={styles.buttonBgImg}
                  src={ButtonImageUrl}
                  alt={'alt'}
                />
              ) : (
                ButtonText
              )}
            </ActionButton>
          ))}
      </div>
    </div>
  )
}
