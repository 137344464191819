import { LULarge } from './lu-pizza-large'
import { LUMedium } from './lu-pizza-medium'
import { LURegular } from './lu-pizza-regular'
import { LUXXL } from './lu-pizza-xxl'

export const LUSizeIcons: { [code: string]: React.ComponentType<SizeSVGProps> } = {
  'Pizza.Regular': LURegular,
  'Pizza.Medium': LUMedium,
  'Pizza.Large': LULarge,
  'Pizza.FamilyXXL': LUXXL,
}

export type LUSizeCode = keyof typeof LUSizeIcons
