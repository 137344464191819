import { FilterProductItem, FilterProductSize, formatPage } from '@dominos/business/functions/menu'
import {
  COUPON_MENU_CODE,
  useCurrentOrderDetails,
  useDominosTheme,
  useMenu,
  useSwapSetProducts,
} from '@dominos/hooks-and-hocs'
import { NavigationConstants } from '@dominos/navigation'
import { StaticSvgIcon } from '@dominos/res'
import { navigate } from '@reach/router'
import React, { useEffect } from 'react'
import { CouponPromptProps } from './coupon-prompt.interface'
import css from './coupon-prompt.less'

type SwapSetProductSize = Bff.Menu.old.SwapSetProductSize
type SwapSetProduct = Bff.Menu.old.SwapSetProduct

const ICON_SIZE = 40

const getFilteredSizes = (sizes: SwapSetProductSize[]) => sizes.filter((size) => size.code && size.code !== 'N/A')

const getSizes = (sizes: SwapSetProductSize[]): FilterProductSize[] =>
  sizes.map((size) => ({
    code: size.code && size.code !== 'N/A' ? size.code : undefined,
    hasSurcharge: size.hasSurcharge,
    surcharge: size.surcharge,
  }))

const convertToFilterProductItem = (item: SwapSetProduct): FilterProductItem => {
  const filteredSizes = item.sizes?.length ? getFilteredSizes(item.sizes) : undefined
  const sizes = filteredSizes?.length ? getSizes(filteredSizes) : undefined

  return {
    code: item.code,
    sizes,
    hasSurcharge: item.hasSurcharge,
    filterSizes: item.filterSizes,
    surcharge: item.surcharge,
    baseProduct: item.baseProduct,
  }
}
export const convertToFilterProductItems = (items: SwapSetProduct[]): FilterProductItem[] =>
  items.map(convertToFilterProductItem).filter((item) => item && item.code)

export const CouponPrompt = ({ testID, prompt, voucherItemNo, voucherCode, voucherNo }: CouponPromptProps) => {
  const theme = useDominosTheme()
  const { menu } = useMenu()
  const { basketHeaderData } = useCurrentOrderDetails()
  const { getSwapSetProducts, swapSetProducts } = useSwapSetProducts(
    basketHeaderData?.storeNo,
    basketHeaderData?.serviceMethod,
  )

  useEffect(() => {
    if (menu && swapSetProducts?.length) {
      const filterProducts = convertToFilterProductItems(swapSetProducts)
      const url = `${NavigationConstants.menu}/${formatPage(COUPON_MENU_CODE)}`

      navigate(url, {
        state: {
          filterList: filterProducts,
          voucherNo,
          voucherItemNo,
        },
      }).then(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      })
    }
  }, [swapSetProducts, menu])

  const onClicked = () => {
    if (voucherCode && voucherItemNo) {
      getSwapSetProducts(voucherCode, voucherItemNo)
    }
  }

  return (
    <div data-testid={testID} role={'button'} className={css.container} onClick={onClicked}>
      <div
        data-testid={`${testID}.icon-container`}
        className={css.icon}
        style={{ borderColor: theme.colours.lightSlate, borderRadius: theme.webSmallBorderRadius }}
      >
        <StaticSvgIcon name={'plus'} fill={theme.colours.lightSlate} width={ICON_SIZE} height={ICON_SIZE} />
      </div>
      <div data-testid={`${testID}.prompt`} className={css.text}>
        {prompt}
      </div>
    </div>
  )
}
