import { StaticSvgIcon, svgIconsName } from '@dominos/res'
import React from 'react'
import css from './boxed-message.less'
import { useDominosTheme } from '@dominos/hooks-and-hocs'

type variantType = Pick<typeof css, 'contained' | 'outlined'>
type colorType = Pick<typeof css, 'primary' | 'secondary' | 'tertiary' | 'information'>
type textSizeType = Pick<typeof css, 'regular' | 'small' | 'large'>
type iconColorType = Pick<typeof css, 'icon-primary' | 'icon-secondary' | 'icon-tertiary' | 'icon-information'>

type iconPropsType = {
  width: number
  height: number
  fill?: string
  defaultFill?: boolean
}

interface BoxedMessageProps extends BaseProps {
  icon?: svgIconsName
  iconProps?: iconPropsType
  role?: string
  color?: keyof colorType
  variant?: keyof variantType
  textSize?: keyof textSizeType
  children: React.ReactElement | string
}

export const BoxedMessage = ({
  testID,
  children,
  icon,
  role,
  variant,
  color,
  textSize,
  iconProps,
}: BoxedMessageProps) => {
  const theme = useDominosTheme()
  const { defaultFill, ...restIconProps } = iconProps ?? { height: 17, width: 17 }
  const iconColor: keyof iconColorType = color ? `icon-${color}` : 'icon-primary'

  return (
    <div
      role={role ?? 'alert'}
      data-testid={`boxed-message.${testID}`}
      className={`${css[variant ?? 'outlined']} ${css[color ?? 'primary']} ${css[textSize ?? 'small']}`}
      style={{
        borderRadius: theme.borderRadius,
      }}
    >
      {icon && (
        <div
          data-testid={`boxed-message.icon.${testID}`}
          className={`boxed-message-icon ${!defaultFill ? css[iconColor] : ''}`}
        >
          <StaticSvgIcon name={icon} isUnstyled {...restIconProps} />
        </div>
      )}
      <div>{children}</div>
    </div>
  )
}
