import React, { useCallback, useEffect, useState } from 'react'
import { useCountryCode, useCustomerAddressSearch, useDominosTheme } from '@dominos/hooks-and-hocs'
import { DropdownField, DropDownItem } from '@dominos/components'
import { mapSearchAddressToAddressLine } from '@dominos/business/functions'
import css from './delivery-address-search-postcode-container.less'
import { useTranslation } from 'react-i18next'

const DROPDOWN_ITEM_MARGIN_TOP = 5
const DROPDOWN_ITEM_WEIGHT = 600
const DROPDOWN_ITEM_MIN_HEIGHT = 55
const DROPDOWN_ITEM_BORDER_RADIUS = 8

export interface DeliveryAddressSearchByPostCode extends BaseProps {
  deliveryAddressSelectedCallBack: (address: SearchAddress | undefined) => void
  postCodeLength: number
  numericValesOnly?: boolean
  maxNumberResultsDisplay?: number
  hasClearInputButton?: boolean
}

export const DeliveryAddressSearchByPostCodeComponent = ({
  testID,
  deliveryAddressSelectedCallBack,
  postCodeLength,
  maxNumberResultsDisplay,
  numericValesOnly,
  hasClearInputButton,
}: DeliveryAddressSearchByPostCode) => {
  const { getAddress, data, errors } = useCustomerAddressSearch()
  const theme = useDominosTheme()
  const countryCode = useCountryCode()
  const { t } = useTranslation('delivery-address')
  const [dropDownItems, setDropDownItems] = useState<DropDownItem[] | undefined>(undefined)

  const fetchAddressesByPostCode = (searchValue: string) => {
    getAddress({
      postCode: searchValue,
    })
  }

  const setAddressItemsToDisplay = useCallback(() => {
    const warningBackgroundColour = theme.colours.backgroundColor
    const warningTextColour = theme.colours.errorMessage
    const foundBackgroundColour = '#CFE7FD'
    const foundTextColour = theme.colours.hyperlink

    if (errors) {
      setDropDownItems([
        getWarningThemedDropDownItem(t('ErrorGettingAddress'), warningTextColour, warningBackgroundColour),
      ])

      return
    }

    if (!data) {
      setDropDownItems(undefined)

      return
    }

    if (data.deliverySearch.length == 0) {
      setDropDownItems([
        getWarningThemedDropDownItem(t('NoDeliveryPostcodeFound'), warningTextColour, warningBackgroundColour),
      ])

      return
    }

    const dropDownItems = data.deliverySearch.map((address) => {
      const displayAddress = getAddressDisplay(address)

      return getFoundedThemedDropDownItem(displayAddress, foundTextColour, foundBackgroundColour, address)
    })
    setDropDownItems(dropDownItems)
  }, [data])

  const getAddressDisplay = (searchAddress: SearchAddress) => {
    const result = mapSearchAddressToAddressLine(countryCode, [searchAddress])

    return result[0]?.name
  }

  const addressSelected = (dropDownItem: DropDownItem | undefined) => {
    if (dropDownItem && dropDownItem.item) {
      deliveryAddressSelectedCallBack(dropDownItem.item)
    } else {
      deliveryAddressSelectedCallBack(undefined)
      setDropDownItems(undefined)
    }
  }

  useEffect(() => {
    if (data || errors) {
      setAddressItemsToDisplay()
    }
  }, [data, errors])

  return (
    <div
      data-testid={testID}
      className={css.dropdownWrapper}
      style={dropDownItems ? magicStyles.dropDownWrapperWithItems : undefined}
    >
      <DropdownField
        testID={`${testID}.dropdown`}
        style={magicStyles.dropDownField}
        placeholder={t('Postcode')}
        fetchDropdownItems={fetchAddressesByPostCode}
        dropDownItems={dropDownItems}
        dropDownItemSelectedCallBack={addressSelected}
        minNumberCharactersFetch={postCodeLength}
        maxNumberDropDownItemDisplay={maxNumberResultsDisplay}
        disabled={false}
        validationRules={{
          numericOnly: numericValesOnly,
          numberCharacters: postCodeLength,
        }}
        showClearInputButton={hasClearInputButton}
      />
    </div>
  )
}

const getWarningThemedDropDownItem = (
  displayValue: string,
  textColour: string,
  backgroundColour: string,
): DropDownItem => ({
  displayValue: displayValue,
  item: undefined,
  icon: 'exclamation',
  iconFill: textColour,
  style: {
    backgroundColor: backgroundColour,
    color: textColour,
    borderWidth: 'medium',
    borderStyle: 'solid',
    borderColor: textColour,
    borderRadius: DROPDOWN_ITEM_BORDER_RADIUS,
    marginTop: DROPDOWN_ITEM_MARGIN_TOP,
    fontWeight: DROPDOWN_ITEM_WEIGHT,
    minHeight: DROPDOWN_ITEM_MIN_HEIGHT,
  },
})

const getFoundedThemedDropDownItem = (
  displayValue: string,
  textColour: string,
  backgroundColour: string,
  referenceItem: SearchAddress,
): DropDownItem => ({
  displayValue: displayValue,
  item: referenceItem,
  icon: 'searchResultIcon',
  style: {
    backgroundColor: backgroundColour,
    border: 'none',
    color: textColour,
    borderRadius: DROPDOWN_ITEM_BORDER_RADIUS,
    marginTop: DROPDOWN_ITEM_MARGIN_TOP,
    fontWeight: DROPDOWN_ITEM_WEIGHT,
    minHeight: DROPDOWN_ITEM_MIN_HEIGHT,
  },
})

const magicStyles = {
  dropDownField: {
    width: '100%',
  },
  dropDownWrapperWithItems: {
    paddingBottom: 0,
  },
}
