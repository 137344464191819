import React from 'react'
import { useTranslation } from 'react-i18next'

import { useFeatures, useOrderId } from '@dominos/hooks-and-hocs'

import css from './debug-info.less'

export const DebugInfo = () => {
  const { t } = useTranslation('footer')
  const [webDebugInfoEnabled] = useFeatures('WebDebugInfo')
  const { id: orderId } = useOrderId({ inspectQuery: true })

  if (!webDebugInfoEnabled || !orderId) {
    return null
  }

  return (
    <div data-testid={`debug-info.order-id`} className={css.orderId}>
      {t('OrderID')} : {orderId}
    </div>
  )
}
