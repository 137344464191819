import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 42,
  height: 42,
}

export const Badge9Plus = (props: SvgIconProps) => {
  const fill = props.fill || '#E8B96C'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={`${width}px`} height={`${height}px`} viewBox='0 0 42 42'>
      <g id='Badge-9+' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <circle id='Oval' fill={fill} cx='21' cy='21' r='21' />
        <path
          d='M10.34,10.15 L18.4,10.15 C19.671,10.15 20.725,11.204 20.725,12.475 L20.725,29.525 C20.725,30.796 19.671,31.85 18.4,31.85 L10.185,31.85 C8.976,31.85 8.015,30.889 8.015,29.68 L8.015,27.944 C8.015,27.479 8.294,27.045 8.697,26.859 L12.076,25.278 C12.417,25.154 12.789,25.371 12.789,25.743 L12.789,27.386 C12.789,27.479 12.851,27.541 12.944,27.541 L15.765,27.541 C15.858,27.541 15.92,27.479 15.92,27.386 L15.92,23.883 C15.92,23.79 15.858,23.728 15.765,23.728 L10.34,23.728 C9.069,23.728 8.015,22.674 8.015,21.403 L8.015,12.475 C8.015,11.204 9.069,10.15 10.34,10.15 Z M12.665,19.419 L15.765,19.419 C15.858,19.419 15.92,19.357 15.92,19.264 L15.92,14.614 C15.92,14.521 15.858,14.459 15.765,14.459 L12.665,14.459 C12.572,14.459 12.51,14.521 12.51,14.614 L12.51,19.264 C12.51,19.357 12.572,19.419 12.665,19.419 Z M26.297,23.015 L23.662,23.015 C23.228,23.015 22.887,22.674 22.887,22.24 L22.887,19.822 C22.887,19.388 23.228,19.047 23.662,19.047 L26.297,19.047 C26.39,19.047 26.452,18.985 26.452,18.892 L26.452,15.637 C26.452,15.203 26.793,14.862 27.227,14.862 L29.645,14.862 C30.079,14.862 30.42,15.203 30.42,15.637 L30.42,18.892 C30.42,18.985 30.482,19.047 30.575,19.047 L33.21,19.047 C33.644,19.047 33.985,19.388 33.985,19.822 L33.985,22.24 C33.985,22.674 33.644,23.015 33.21,23.015 L30.575,23.015 C30.482,23.015 30.42,23.077 30.42,23.17 L30.42,26.425 C30.42,26.859 30.079,27.2 29.645,27.2 L27.227,27.2 C26.793,27.2 26.452,26.859 26.452,26.425 L26.452,23.17 C26.452,23.077 26.39,23.015 26.297,23.015 Z'
          id='9+'
          fill='#FFFFFF'
          fillRule='nonzero'
        />
      </g>
    </svg>
  )
}
