export const deleteVWOCookies = () => {
  const cookies = document.cookie.split(';')
  const vwoCookiePatterns = [/^_vis_opt_exp_\d+_combi$/, /^_vis_opt_exp_\d+_goal_\d+$/, /^_vwo_uuid/, /^_vwo_uuid_v2/]

  const hostnameParts = window.location.hostname.split('.')
  const domain = hostnameParts.length > 1 ? hostnameParts.slice(1).join('.') : window.location.hostname

  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim()
    if (vwoCookiePatterns.some((pattern) => pattern.test(cookieName))) {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${domain}`
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname}`
    }
  })
}
