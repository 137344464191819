import React from 'react'
import { useTranslation } from 'react-i18next'
import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { PickupAddressSearch } from './pickup-address-search'
import css from './auto-complete-pickup-scene.less'
import { navigate } from '@reach/router'
import { NavigationConstants } from '@dominos/navigation/navigation-constants'

export const AutoCompletePickupScene = () => {
  const { t } = useTranslation('store-search')

  return (
    <StackNavigationBase testID={'pickup-search-scene'} header={<SimpleNavigationTitle showAccount showBackButton />}>
      <div className={css.wrapper}>
        <PickupAddressSearch
          testID={'pickup-search'}
          title={t('PickupAddressSearchTitle')}
          placeholder={t('PickupAddressSearchPlaceholder')}
          onCompletePickup={() => navigate(NavigationConstants.timedOrder)}
        />
      </div>
    </StackNavigationBase>
  )
}
