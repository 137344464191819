import React from 'react'

import { CollapsableTitledCard, Legends } from '@dominos/components'

import { useTranslation } from 'react-i18next'
import css from './menu-legend.less'

const IGNORE = ['kJ.Pizza', 'kJ.Standard']

export const MenuLegend = ({ testID, items }: MenuLegendProps) => {
  const legends: MenuLegend[] = (items || []).filter((item) => !IGNORE.includes(item.code))
  const { t } = useTranslation('menu')

  return (
    <div data-testid={`${testID}-Titled-Card`} className={css.wrapper}>
      <CollapsableTitledCard
        primaryTitle={t('menuLegend', { defaultValue: 'Menu Legend' })}
        noPadding={true}
        startCollapsed={false}
        testID={'menu-legend-card'}
      >
        <div className={css.contentWrapper}>
          <Legends
            testID={'MenuLegend'}
            containerStyle={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginBottom: 10,
              justifyContent: 'space-around',
            }}
            legendWrapperStyle={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            imageStyle={{ width: 38 }}
            legends={legends}
            renderCode
          />
          {legends
            .filter((item) => !!item.text && (item.code || '').toUpperCase() !== (item.text || '').toUpperCase())
            .map((item, index) => (
              <div key={`${testID}-item-text-${index}`}>
                <p data-testid={`${testID}-descriptionTitle${index}`} className={css.itemCode}>
                  {item.code}
                </p>
                <p data-testid={`${testID}-description${index}`} className={css.itemText}>
                  {item.text}
                </p>
              </div>
            ))}
        </div>
      </CollapsableTitledCard>
    </div>
  )
}
