import React from 'react'
import Markdown from 'markdown-to-jsx'
import { MdOpenPopup } from '@dominos/components'
import { StaticSvgIcon } from '@dominos/res'
import { MoreInfoAccessoryProps } from './more-info-accessory.interface'

import css from './more-info-accessory.less'

export const MoreInfoAccessory = (props: MoreInfoAccessoryProps) =>
  !props.useInfoText ? (
    <div
      data-testid={props.testID}
      role={'button'}
      aria-label={props.label}
      aria-controls={props.id}
      className={css.accessory}
      onClick={props.onClick}
    >
      <StaticSvgIcon name={'question'} width={21} height={21} isUnstyled />
    </div>
  ) : (
    <Markdown
      // className={css.link}
      options={{
        forceBlock: true,
        overrides: {
          a: (p) => <MdOpenPopup {...p} onClick={props.onClick} />,
        },
      }}
    >
      {props.info}
    </Markdown>
  )
