import { DateTime } from 'luxon'

export const parseETATimeRange = (
  min: string = '',
  max: string = '',
  timezone?: string,
): { from: DateTime; to: DateTime } => {
  const parsedMinIsoTime = DateTime.fromISO(min, { zone: timezone })
  const parsedMaxIsoTime = DateTime.fromISO(max, { zone: timezone })

  return {
    from: parsedMinIsoTime,
    to: parsedMaxIsoTime,
  }
}
