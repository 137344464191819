import React from 'react'

import RadioInputField from '@dominos/components/inputs/radio-input-field'
import { ServiceMethodSubTypeProps } from '@dominos/interfaces/checkout'

export const ServiceMethodSubType = (props: ServiceMethodSubTypeProps) => {
  const { SubTypeComponent, ...restProps } = props

  const { name, value, testID, labelText, selectedSubType, onChecked, onFormValidationChange } = restProps

  if (!!SubTypeComponent) {
    return <SubTypeComponent {...{ ...restProps, fieldId: testID }} />
  }

  const handleChecked = (targetName: string, targetValue: string | number) => {
    onChecked(targetValue as Bff.Stores.PickupServiceMethodSubTypes)
    if (onFormValidationChange) {
      onFormValidationChange(true)
    }
  }

  return (
    <RadioInputField
      fieldName={name}
      value={value}
      fieldId={testID}
      onChecked={handleChecked}
      labelText={labelText}
      testID={`${testID}.radio-input-field`}
      selectedOption={selectedSubType}
    />
  )
}
