import React, { useEffect, useState } from 'react'
import { AdditionalFields } from '@dominos/components/address/additional-fields'
import { CollapsableTitledCard } from '@dominos/components/cards/collapsable-titled-card'
import styles from './delivery-address-additional-fields-container.less'
import { useTranslation } from 'react-i18next'
import {
  convertAdditionalFieldsOnInitToAdditionalFields,
  getAddressComponentsFromAdditionalFields,
  getAddressComponentsFromCustomerAddressRequest,
  mergeAddressComponentUpdates,
} from '@dominos/business/functions/address/autocomplete'

export interface AdditionalAddressDetailsUpdate {
  isValid: boolean
  additionalAddressComponents: AddressComponent[]
}

export interface DeliveryAddressAdditionalFieldsProps extends BaseProps {
  customerAddress: CustomerAddressRequest | undefined
  onChange: (additionalAddressDetailsUpdate: AdditionalAddressDetailsUpdate) => void
}

export interface AddressComponentUpdate extends AddressComponent {
  isValid: boolean
}
export const DeliveryAddressAdditionalFieldsContainer = ({
  customerAddress,
  onChange: onChangeProps,
  testID,
}: DeliveryAddressAdditionalFieldsProps) => {
  const [addressComponents, setAddressComponents] = useState<AddressComponentUpdate[] | undefined>()
  const { t } = useTranslation('delivery-address')

  useEffect(() => {
    if (addressComponents)
      onChangeProps({
        isValid: addressComponents?.every((c) => c.isValid) ?? false,
        additionalAddressComponents: addressComponents || [],
      })
  }, [addressComponents])

  const onInit: AdditionalFieldsOnInit = (initialState) => {
    onChange(convertAdditionalFieldsOnInitToAdditionalFields(initialState, customerAddress))
  }

  const onChange: AdditionalFieldsOnChange = (updatedFields: AdditionalFields) => {
    const updatedAddressComponents = getAddressComponentsFromAdditionalFields(updatedFields)
    setAddressComponents(mergeAddressComponentUpdates(updatedAddressComponents, addressComponents))
  }

  if (!customerAddress) return <></>

  return (
    <div style={{ marginBottom: 10, marginTop: 10 }}>
      <CollapsableTitledCard
        testID='delivery-address-fields-container.makeup.card'
        primaryTitle={t('AddressDetails', { defaultValue: 'Address Details' })}
        startCollapsed={false}
        noPadding={true}
      >
        <div className={styles.titledCardContent}>
          <AdditionalFields
            testID={`${testID}.additionalFields`}
            address={getAddressComponentsFromCustomerAddressRequest(customerAddress)}
            onChange={onChange}
            onInit={onInit}
          />
        </div>
      </CollapsableTitledCard>
    </div>
  )
}
