import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { FeatureFlagType } from '../feature-flag.interface'
import { getDefaultLanguage } from '@dominos/plugins/helpers/get-default-language'
import { LDClientContext } from './use-identify-context'
import { Config } from '@dominos/business/functions'
import { applicationName } from '@dominos/business/functions'
import { ProviderConfig } from 'launchdarkly-react-client-sdk'

export const getFeatureFlags = (flags: LDFlagSet): FeatureFlagType | undefined => {
  if (!Object.keys(flags).length) return undefined

  return flags as FeatureFlagType
}

const getLaunchDarklyContext = (applicationConfig: Config, launchDarklyID: string): LDClientContext => {
  const defaultLanguage = getDefaultLanguage(applicationConfig.LANGUAGES.split(','), applicationConfig.LOCALE_DEFAULT)

  return {
    kind: 'user',
    key: launchDarklyID,
    oloApplication: applicationName().toLowerCase() as Lowercase<BffContext.ApplicationTypes>,
    oloMarket: applicationConfig.COUNTRY.toLowerCase() as Lowercase<BffContext.Countries>,
    oloVersion: window.nativeAppVersion || applicationConfig.BUILD_BUILDNUMBER,
    oloLanguage: `${defaultLanguage}-${applicationConfig.COUNTRY}`,
  }
}

export const getLaunchDarklyConfig = (applicationConfig: Config, launchDarklyID: string): ProviderConfig => ({
  clientSideID: applicationConfig.LAUNCH_DARKLY_CLIENT_SIDE_ID,
  context: getLaunchDarklyContext(applicationConfig, launchDarklyID),
  options: {
    privateAttributes: ['email', 'ip'],
    sendEventsOnlyForVariation: true,
    streaming: false,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
    sendEventsOnFlagRead: true,
  },
})
