import { CreateAccount, GenericCard } from '@dominos/components'
import { NavigationConstants } from '@dominos/navigation'
import { navigate } from '@reach/router'
import React, { useEffect } from 'react'
import { CreateAccountSceneProps } from '.'
import { useTranslation } from 'react-i18next'
import css from './create-account-scene.less'
import {
  getNativeFeatures,
  LoginSceneMessage,
  postWebViewMessage,
  sendTokenToNativeApp,
} from '@dominos/business/functions/native-app'
import { useNativeAppData } from '@dominos/hooks-and-hocs'
import { NativeAppAuth } from '@dominos/components/universal-auth/native-app-auth'

const buildNavigationHandler = (route: string) => () => navigate(route)

const callBackCreateAccountSuccess = (idToken: string) => {
  sendTokenToNativeApp(idToken, NavigationConstants.nativeAppCreateAccount)
}

const NativeAppCreateAccountContainer = (props: CreateAccountSceneProps) => {
  const { t } = useTranslation('login')

  return (
    <div className={css.outer}>
      <GenericCard
        testID='create-account.card'
        title={props.title || t('CreateAccountTitle', { defaultValue: 'Create an Account' })}
        noPadding
      >
        <CreateAccount
          {...props}
          onSuccess={callBackCreateAccountSuccess}
          onFail={buildNavigationHandler(NavigationConstants.nativeAppLogin)}
          loginRoute={NavigationConstants.nativeAppLogin}
          authenticationSource='Legacy'
        />
      </GenericCard>
    </div>
  )
}

export const NativeAppCreateAccountScene = (props: CreateAccountSceneProps) => {
  const enableNativeUniversalAuth = getNativeFeatures('UniversalAuthentication')

  useNativeAppData()

  useEffect(() => {
    postWebViewMessage<LoginSceneMessage>({ route: NavigationConstants.nativeAppCreateAccount })
  }, [])

  return enableNativeUniversalAuth ? (
    <NativeAppAuth initial={'register'} />
  ) : (
    <NativeAppCreateAccountContainer {...props} />
  )
}
