type MenuPage = Bff.Menu.old.MenuPage

export const includeVouchersInSubMenu = (page: MenuPage | null | undefined): boolean => {
  if (page && page.sections) {
    return page.sections.every((s) => {
      if (s.items) {
        return s.items.every((i) => i.type === 'Voucher')
      }

      return false
    })
  }

  return false
}
