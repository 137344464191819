import React, { useEffect } from 'react'

import { useFeatures, useGeoLocation } from '@dominos/hooks-and-hocs'
import { SimpleNavigationTitle, StackNavigationBase } from '@dominos/components'
import { useDeliverySearchViewSwitcher } from '@dominos/hooks-and-hocs'

import { DeliveryAddress } from './delivery-address'
import { AddressProvider } from '@dominos/hooks-and-hocs/address/providers/address-provider'
import { useReportAutoCompleteDelivery } from 'olo-feature-address'

export const AutoCompleteDeliveryScene = () => {
  const [autoCompleteCurrentLocation] = useFeatures('AutoCompleteCurrentLocation')
  const { location, permitted, getLocation, requestLocationPermission } = useGeoLocation()
  const { currentDeliverySearchView, toggleViewChange } = useDeliverySearchViewSwitcher()
  const { reportLocationServicesOn } = useReportAutoCompleteDelivery()

  const shareLocation = async () => {
    await requestLocationPermission()
    await getLocation()
  }

  useEffect(() => {
    if (!autoCompleteCurrentLocation) return
    shareLocation()
  }, [])

  useEffect(() => {
    if (location) reportLocationServicesOn()
  }, [location])

  const onMapStateChange = () => {
    toggleViewChange()
  }

  return (
    <StackNavigationBase
      testID={'address-search-scene'}
      header={<SimpleNavigationTitle showAccount showBackButton />}
      modification='map-view'
    >
      <AddressProvider>
        <DeliveryAddress
          testID='delivery-address'
          searchMode={currentDeliverySearchView}
          locationDetectionPermitted={autoCompleteCurrentLocation && permitted}
          detectedLocation={location}
          onMapStateChange={onMapStateChange}
        />
      </AddressProvider>
    </StackNavigationBase>
  )
}
