import React from 'react'
import { useTranslation } from 'react-i18next'

import { CollapsableTitledCard } from '@dominos/components'
import { CustomerCarDetails, ServiceMethodSubTypes } from '@dominos/interfaces/checkout'

import { ServiceMethodSubType } from './service-method-subtype'
import css from './service-method-type.less'
import { serviceMethodSubTypeDefinitions, unknownSubTypeDefinition } from './service-method-types'

export const ServiceMethodType = (props: {
  fieldId: string
  fieldName: string
  serviceMethod: BffContext.ServiceMethods
  subTypes: ServiceMethodSubTypes[]
  defaultOption: ServiceMethodSubTypes
  checkedOption?: ServiceMethodSubTypes
  initialValue?: CustomerCarDetails
  onChange?: (fieldName: string | null, value: string | boolean | undefined) => void
  onChecked: (x?: Bff.Stores.ServiceMethodSubTypes) => void
  onFormValidationChange?: (x: boolean) => void
}) => {
  const { t } = useTranslation('checkout')
  const selectedOption: ServiceMethodSubTypes = props.checkedOption ?? props.defaultOption

  const selectedOptionDefinition =
    serviceMethodSubTypeDefinitions[selectedOption] ?? unknownSubTypeDefinition(props.serviceMethod, selectedOption)

  const handleChecked = (serviceMethodSubType?: Bff.Stores.ServiceMethodSubTypes) => {
    props.onChecked(serviceMethodSubType)
  }

  return props.subTypes.length > 1 ? (
    <CollapsableTitledCard
      style={{ marginTop: '10px' }}
      testID={props.fieldId}
      primaryTitle={t(selectedOptionDefinition.primaryTitle.key, {
        defaultValue: selectedOptionDefinition.primaryTitle.defaultValue,
      })}
      secondaryTitle={t(selectedOptionDefinition.secondaryTitle.key, {
        defaultValue: selectedOptionDefinition.secondaryTitle.defaultValue,
      })}
    >
      <fieldset className={css.serviceMethodOptions} id={props.fieldId}>
        {props.subTypes.map((subType) => (
          <ServiceMethodSubType
            key={`service-method-sub-type-${subType}-${selectedOption}`}
            name={props.fieldName}
            value={subType as Bff.Stores.PickupServiceMethodSubTypes}
            onChecked={handleChecked}
            onFormValidationChange={props.onFormValidationChange}
            labelText={t(serviceMethodSubTypeDefinitions[subType]?.label?.key ?? subType, {
              defaultValue: serviceMethodSubTypeDefinitions[subType]?.label?.defaultValue ?? subType,
            })}
            testID={subType}
            selectedSubType={selectedOption}
            SubTypeComponent={serviceMethodSubTypeDefinitions[subType]?.component}
            {...(selectedOption === 'CarParkDelivery'
              ? { onChange: props.onChange, initialValue: props.initialValue }
              : {})}
          />
        ))}
      </fieldset>
    </CollapsableTitledCard>
  ) : null
}
