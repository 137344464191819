import { NavigationConstants } from '@dominos/navigation'

export const WHITELIST_PATHS_INACTIVITY: string[] = [
  NavigationConstants.home,
  NavigationConstants.checkoutProcessing,
  NavigationConstants.tracker,
  '/index.html',
]
export const TIMEOUT_SECONDS = 20
export const POPUP_SECONDS = 10
export const WINDOWS_EVENTS_TO_RESET_TIMEOUT = ['mousemove', 'touchstart', 'touchmove', 'wheel', 'keydown']
