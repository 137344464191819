import { TFunction } from 'react-i18next'

export interface PortionProductViewModel {
  code: string
  imageUri: string
  name: string
}

export interface PortionTileProps {
  t: TFunction<'menu'>
  portionIndex: PortionIndex
  portionCount: number
  products: PortionProductViewModel[]
  selectedProductCode?: string | undefined
  disabled: boolean
  showReset: boolean
  customisable: boolean
  onProductListOpen: (portionIndex: number) => void
  onReset: (portionIndex: number) => void
  onCustomise: () => void
}

export interface PortionTileViewProps {
  viewModel: PortionTileViewModel
  onReset: (portionIndex: number) => void
  onCustomise: () => void
  onProductListOpen: (portionIndex: number) => void
}

export interface PortionTileViewModel {
  portionProductCode?: string
  portionIndex: PortionIndex
  portionKey: PortionKey
  products: PortionProductViewModel[]
  selectedProductCode?: string | undefined
  disabled: boolean
  showReset: boolean
  selectPlaceholderText: string
  customiseButtonText: string
  customisable: boolean
  resetButtonText: string
  isKioskOrder?: boolean
}

export const createPortionTileViewModel = ({
  portionIndex,
  portionCount,
  products,
  selectedProductCode,
  disabled,
  showReset,
  t,
  customisable,
}: PortionTileProps): PortionTileViewModel => {
  const portionKey = `${portionIndex}_${portionCount}` as PortionKey
  const selectPlaceholderText = t('PortionProductCard.Select', 'Select Pizza')
  const customiseButtonText = t('PortionProductCard.Customise', 'Customise')
  const resetButtonText = t('PortionProductCard.Reset', 'Reset')

  return {
    portionIndex,
    portionKey,
    products,
    selectedProductCode,
    disabled,
    showReset,
    selectPlaceholderText,
    customiseButtonText,
    customisable,
    resetButtonText,
  }
}
