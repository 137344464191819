import { useMutation } from '@apollo/client'
import { createInitialiseOrderPayload } from '@dominos/business/functions/basket'
import { initiateOrderMutation } from '@dominos/business/queries/initiate-order'
import { rootActions } from '@dominos/business/root.actions'
import { useRoundUpForCharityDetails } from '@dominos/components/charity/round-up/use-round-up-for-charity'
import {
  OrderStatus,
  shouldAllowImmediatePayment,
  useCurrentOrderDetails,
  useFeatures,
  useKiosk,
  useNativeAppStoredData,
  useReport,
} from '@dominos/hooks-and-hocs'
import { MyDetailsModelProps } from '@dominos/interfaces'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useOrderPolling } from './order-polling'
import { ErrorScope, useErrorContext } from '@dominos/components/error'
import { paymentErrors, statusErrors, usePaymentErrorHandlers } from '@dominos/components/checkout'
import { InitialiseOrderInputType, InitialiseOrderReceipt, UseInitiateOrderProps } from './initiate-order.interface'
import uuid from 'uuid'
import { getAdyenReturnUrl } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/adyen/get-adyen-return-url'
import { getReturnUrl } from '@dominos/components/checkout/checkout-container/payment-container/payment-method/get-return-url'
import { analytics } from '../logging/analytics'
import { isNativeApp } from '@dominos/business/functions/native-app'
const maxPoll = 120

// eslint-disable-next-line max-lines-per-function
export const useInitiateOrder = ({ onReady, handleInitiateOrderError }: UseInitiateOrderProps) => {
  const { storeNativeAppPaymentIds } = useNativeAppStoredData()
  const dispatch = useDispatch()
  const basketReducer = useSelector((state: RootReducer) => state.basketReducer)
  const customerID = useSelector((state: RootReducer) => state.customerReducer.customerId)
  const customerToken = useSelector((state: RootReducer) => state.customerReducer.customerToken)
  const roundUpForCharityDetails = useRoundUpForCharityDetails()
  const [orderTime, setOrderTime] = useState(Date.now())
  const [loading, setLoading] = useState(false)
  const [paymentToken, setPaymentToken] = useState<string>()
  const [orderId, setOrderId] = useState<string>()
  const [properties, setProperties] = useState<Bff.Orders.KeyValueProperties[] | null | undefined>()
  const [allowTakePayment, setAllowTakePayment] = useState(false)
  const [paymentForOrder, setPaymentForOrder] = useState<Bff.Orders.InitialiseOrderPayment>()
  const currentOrder = useCurrentOrderDetails()
  const { reportPaymentMethodSelected, reportInitOrderPayloadEmpty } = useReport()
  const { notifyError } = useErrorContext()
  const handlers = usePaymentErrorHandlers()
  const { getGoogleID } = analytics()
  const { isValidKioskOrder, kioskId } = useKiosk()
  const [injectKioskHeaderEnabled] = useFeatures('InjectKioskHeader')
  const storeReducer = useSelector((state: RootReducer) => state.storeReducer)

  const customSetLoading = (value: boolean) => {
    setLoading(value)
    dispatch(rootActions.setIsInitiatingOrder(value))
  }

  const handleOrderPollingError = () => {
    handleInitiateOrderError()
    customSetLoading(false)
  }

  const polling = useOrderPolling(undefined, handleOrderPollingError)
  const addKioskHeader = isValidKioskOrder && injectKioskHeaderEnabled

  const [initOrder, { data, error }] = useMutation<InitialiseOrderReceipt, InitialiseOrderInputType>(
    initiateOrderMutation,
    {
      onError: () => {},
      errorPolicy: 'all',
      context: addKioskHeader ? { headers: { 'x-forwarded-for': kioskId } } : undefined,
    },
  )

  useEffect(() => {
    if (data && data.initialiseOrder.orderId) {
      if (paymentForOrder && shouldAllowImmediatePayment(paymentForOrder.providerCode, paymentForOrder.paymentMethod)) {
        setAllowTakePayment(true)
        customSetLoading(false)
        if (onReady) {
          onReady({
            token: data.initialiseOrder.paymentDetails[0].transactionToken,
            properties: data.initialiseOrder.paymentDetails[0].properties || undefined,
          })
        }
      } else {
        polling.pollStart(data.initialiseOrder.orderId, maxPoll)
      }
      if (data.initialiseOrder.paymentDetails[0].transactionToken) {
        setPaymentToken(data.initialiseOrder.paymentDetails[0].transactionToken)
      }
      setOrderId(data.initialiseOrder.orderId)
      setProperties(data.initialiseOrder.paymentDetails[0].properties)
    }
  }, [data, paymentForOrder])

  useEffect(() => {
    if (error) {
      handleInitiateOrderError()
      customSetLoading(false)
      notifyError({ error, handlers, definitions: paymentErrors, scope: ErrorScope.Payment })
      if (error.networkError) {
        polling.pollStop()
      }
    }
  }, [error])

  const initiateOrder = async (
    payment: Bff.Orders.InitialiseOrderPayment,
    myDetails: MyDetailsModelProps,
    paymentProvider?: string,
    adyenPaymentMethodType?: string,
  ) => {
    customSetLoading(true)
    setOrderTime(Date.now())
    const orderPaymentId = uuid()
    payment.orderPaymentId = orderPaymentId
    storeNativeAppPaymentIds(currentOrder.orderId, orderPaymentId)
    if (paymentProvider === 'Adyen' && adyenPaymentMethodType !== undefined) {
      payment.properties?.push({
        key: 'returnUrl',
        value: getAdyenReturnUrl(adyenPaymentMethodType, currentOrder.orderId, orderPaymentId, false, getGoogleID()),
      })
    }
    if (isNativeApp() && paymentProvider === 'PayPal') {
      const result = payment.properties?.find((p) => {
        p.key === 'returnUrl'
      })
      result
        ? (result.value = getReturnUrl(currentOrder.orderId, orderPaymentId, getGoogleID()))
        : payment.properties?.push({
            key: 'returnUrl',
            value: getReturnUrl(currentOrder.orderId, orderPaymentId, getGoogleID()),
          })
    }

    const payload = createInitialiseOrderPayload({
      currentOrder,
      roundUpForCharityDetails,
      payment,
      basket: basketReducer,
      details: myDetails,
      customerId: customerID,
      tipAmount: currentOrder.tipAmount,
      isKioskOrder: isValidKioskOrder,
      passiveSaleFromStore: storeReducer.passiveSales?.originalStore?.storeNo,
    })

    dispatch(rootActions.setOrderPaymentId(orderPaymentId))
    dispatch(rootActions.saveCustomerDetails(myDetails))

    if (!payload) {
      reportInitOrderPayloadEmpty(payment)
      customSetLoading(false)

      return
    }

    const orderPayload: InitialiseOrderInputType = {
      input: payload,
    }
    setPaymentForOrder(payment)
    const { providerCode, paymentMethod } = payment
    reportPaymentMethodSelected(providerCode, paymentMethod)

    let context
    if (customerToken) {
      context = { headers: { authorization: `bearer ${customerToken}` } }
    }

    initOrder({ context, variables: orderPayload, fetchPolicy: 'no-cache' })
  }

  const cancel = () => {
    customSetLoading(false)
    polling.pollStop()
  }

  useEffect(() => {
    if (polling.pollResult && polling.pollResult.status) {
      if (polling.pollResult.status.status === OrderStatus.Pending) {
        customSetLoading(false)
        polling.pollStop()
        setAllowTakePayment(true)
        if (onReady) {
          onReady({ properties, token: paymentToken })
        }
      } else if (polling.pollResult.status.status !== OrderStatus.New) {
        customSetLoading(false)
        polling.pollStop()
        notifyError({
          handlers,
          definitions: statusErrors(polling.pollResult.status.status),
          scope: ErrorScope.Payment,
          orderStatus: polling.pollResult.status.status,
        })
      }
    }
  }, [orderTime, polling.pollResult && polling.pollResult.status && polling.pollResult.status.status])

  return {
    paymentToken,
    allowTakePayment,
    loading,
    initiateOrder,
    orderId,
    cancel,
  }
}
