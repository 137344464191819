import { CurrentOrderDetailsReducerProps } from '@dominos/business/reducers/current-order-details.reducer'
import { SecuritySender } from './security'

export const reportError = (sendSecurity: SecuritySender, currentOrder?: CurrentOrderDetailsReducerProps) => {
  const reportGenericError = (error: Record<string, unknown>) => {
    sendSecurity('GenericError', {
      'Order ID': currentOrder?.orderId,
      Error: error,
    })
  }

  const reportInfo = (info: string) => sendSecurity('GenericInfo', { Info: info })

  return {
    reportGenericError,
    reportInfo,
  }
}
