export const handlePushNotificationConfig = (webPushNotificationClientId?: string): string | undefined => {
  const { shouldEnablePushNotificationClientId, pushNotificationClientId: swrveId } = Object(
    window.nativeAppStartupData?.swrve,
  )

  if (!!shouldEnablePushNotificationClientId && !!swrveId) {
    return swrveId
  }

  return webPushNotificationClientId
}
