import { SvgIconProps } from '@dominos/res/interfaces/icon.interfaces'
import React from 'react'

const defaultDimensions = {
  width: 20,
  height: 17,
}

const CartIcon = (props: SvgIconProps) => {
  const fill = props.fill || '#fff'
  const width = props.width || defaultDimensions.width
  const height = props.height || defaultDimensions.height

  return (
    <svg width={width} height={height} viewBox='0 0 20 17'>
      <title>􀍪</title>
      <g id='Web' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='DuoBar-Plus-Var-/-Basket' transform='translate(-47, -757)' fill={fill} fillRule='nonzero'>
          <g id='􀍪' transform='translate(47.1813, 757.4365)'>
            <path
              d='M0,0.622558594 C0,0.962890625 0.282226563,1.25341797 0.614257813,1.25341797 L3.41992188,1.25341797 L4.74804688,10.3676758 C4.92236328,11.5463867 5.54492188,12.2851562 6.74853516,12.2851562 L16.4355469,12.2851562 C16.7592773,12.2851562 17.0415039,12.0195312 17.0415039,11.6625977 C17.0415039,11.3056641 16.7592773,11.0400391 16.4355469,11.0400391 L6.88964844,11.0400391 C6.41650391,11.0400391 6.12597656,10.7080078 6.05126953,10.2016602 L5.91845703,9.33007812 L16.4521484,9.33007812 C17.6640625,9.33007812 18.2866211,8.58300781 18.4609375,7.39599609 L19.125,3.00488281 C19.1416016,2.89697266 19.1582031,2.76416016 19.1582031,2.68945312 C19.1582031,2.29101562 18.859375,2.01708984 18.402832,2.01708984 L4.85595703,2.01708984 L4.68994141,0.962890625 C4.60693359,0.323730469 4.37451172,0 3.52783203,0 L0.614257813,0 C0.282226563,0 0,0.290527344 0,0.622558594 Z M6.05126953,14.9746094 C6.05126953,15.7299805 6.64892578,16.3193359 7.40429688,16.3193359 C8.15136719,16.3193359 8.74902344,15.7299805 8.74902344,14.9746094 C8.74902344,14.2275391 8.15136719,13.6298828 7.40429688,13.6298828 C6.64892578,13.6298828 6.05126953,14.2275391 6.05126953,14.9746094 Z M13.8125,14.9746094 C13.8125,15.7299805 14.418457,16.3193359 15.1655273,16.3193359 C15.9208984,16.3193359 16.5185547,15.7299805 16.5185547,14.9746094 C16.5185547,14.2275391 15.9208984,13.6298828 15.1655273,13.6298828 C14.418457,13.6298828 13.8125,14.2275391 13.8125,14.9746094 Z'
              id='Shape'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export { CartIcon }
